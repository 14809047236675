import { EVENT_TYPES } from '../gate-events-constants/constants';
import { HardwareWarningEvent } from './hardware-warning-event';
import { HardwarePanicEvent } from './hardware-panic-event';
import {
  getHardwareAttendedBy,
  getHardwareCondo,
  getHardwarePicture,
  getHardwareResidence,
  getHardwareUser,
  getHardwareVisitor
} from '@api/model/hardware/hardware-utils';
import { HardwareVisitorEvent } from './hardware-visitor-event';
import { HardwareUnknownPlateEvent } from './hardware-plate-recognizer-event';
import { HardwareResidentEvent } from './hardware-resident-event';

export const BuildBaseEvent = event => {
  return {
    _id: event._id || '',
    typeCode: event.typeCode || '',
    createdAt: event.createdAt || '',
    // TODO retornar linha abaixo quando resolver o problema do registeredAt
    // registeredAt: event.registeredAt || '',
    registeredAt: '',
    condo: (event.condo && getHardwareCondo(event.condo)) || null,
    label: event.label || '',
    local: event.local || '',
    eventType: event.eventType || '',
    picture: (event.picture && getHardwarePicture(event.picture)) || null,
    user: (event.user && getHardwareUser(event.user)) || null
  };
};

export const BuildWarningEvent = event => {
  const warningEvent: HardwareWarningEvent = {
    ...BuildBaseEvent(event),
    serial: event.serial,
    attendedBy: (event.attendedBy && getHardwareAttendedBy(event.attendedBy)) || null,
    attendedAt: event.attendedAt || ''
  };
  return warningEvent;
};

export const BuildPanicEvent = event => {
  const panicEvent: HardwarePanicEvent = {
    ...BuildBaseEvent(event),
    user: (event.user && getHardwareUser(event.user)) || null,
    vehicle: event.vehicle || null,
    residence: (event.residence && getHardwareResidence(event.residence)) || null,
    picture: (event.picture && getHardwarePicture(event.picture)) || null,
    serial: event.serial || ''
  };
  return panicEvent;
};

export const BuildTriggerEvent = event => {
  const triggerEvent = {
    ...BuildBaseEvent(event),
    user: event.createdBy || null
  };
  return triggerEvent;
};

export const BuildVisitorEvent = event => {
  const visitorEvent: HardwareVisitorEvent = {
    ...BuildBaseEvent(event),
    visitor: (event.user && getHardwareVisitor(event.user)) || null,
    picture: (event.picture && getHardwarePicture(event.picture)) || null,
    accessType: event.accessType || '',
    deviceType: event.deviceType || '',
    residence: (event.residence && getHardwareResidence(event.residence)) || null,
    approvedByName: event.approvedByName || '',
    vehicle: event.vehicle || null
  };
  return visitorEvent;
};

export const BuildUserEvent = event => {
  const userEvent: HardwareResidentEvent = {
    ...BuildBaseEvent(event),
    residence: (event.residence && getHardwareResidence(event.residence)) || null,
    picture: (event.picture && getHardwarePicture(event.picture)) || null,
    vehicle: event.vehicle || null,
    serial: event.serial || '',
    user: (event.user && getHardwareUser(event.user)) || null,
    deviceType: event.deviceType || ''
  };
  return userEvent;
};

export const BuildPlateRecognizerEvent = event => {
  const plateRecognizerEvent: HardwareUnknownPlateEvent = {
    ...BuildBaseEvent(event),
    serial: event.serial || ''
  };
  return plateRecognizerEvent;
};

export const BuildHardwareEvent = event => {
  const hardwareEvent = event.hardwareEvent || event;
  switch (hardwareEvent.eventType) {
    case EVENT_TYPES.WARNING:
      return BuildWarningEvent(hardwareEvent);
    case EVENT_TYPES.PANIC:
      return BuildPanicEvent(hardwareEvent);
    case EVENT_TYPES.VISITOR:
      return BuildVisitorEvent(hardwareEvent);
    case EVENT_TYPES.RESIDENT:
      return BuildUserEvent(hardwareEvent);
    case EVENT_TYPES.UNKNOWN_PLATE:
      return BuildPlateRecognizerEvent(hardwareEvent);
    case EVENT_TYPES.TRIGGER:
      return BuildTriggerEvent(hardwareEvent);
    default:
      return BuildBaseEvent(hardwareEvent);
  }
};
