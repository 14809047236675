import { Component, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CondoService } from '../../../api/service/condo.service';
import { User } from '../../../api/model/user';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * Created by Rafael on 16/02/2017.
 */

@Component({
  selector: 'residents-autocomplete',
  host: {
    '(document:click)': 'handleClick($event)'
  },
  styles: [
    `
      .list-group {
        max-height: 300px;
        overflow-y: auto;
      }

      .list-group-item {
        cursor: pointer;
      }
    `
  ],
  template: `
    <div class="component-wrapper">
      <div class="input-wrapper">
        <input
          type="text"
          class=""
          (keyup.enter)="selectUser($event)"
          [formControl]="searchTerm"
          (keyup.arrowUp)="decrementIndex($event)"
          (keyup.arrowDown)="incrementIndex($event)"
          placeholder="Usuário" />
      </div>
      <div class="list-group float-start" id="resident-autocomplete-list" style="position: absolute; z-index: 1;">
        <a *ngIf="!filteredList.length && searchTerm.value && !isLoading" class="list-group-item active"> Nenhum usuário encontrado</a>
        <a *ngIf="isLoading" class="list-group-item active">
          <i class="fa fa-spinner fa-spin fa-fw"></i>
          <span class="sr-only">Carregando...</span>Carregando...
        </a>
        <a
          (click)="select(user)"
          *ngFor="let user of filteredList; let i = index"
          class="list-group-item"
          [class.active]="i == activeIndex"
          >{{ user.firstName + ' ' + user.lastName }}</a
        >
      </div>
    </div>
  `
})
export class ResidentsAutocompleteComponent {
  @Output()
  onUserSelect = new EventEmitter();

  @Input()
  public condo;

  public activeIndex = 0;

  public filteredList = [];
  public elementRef;

  public subscription;

  public isLoading = false;

  public searchTerm: UntypedFormControl;

  constructor(myElement: ElementRef, private condoService: CondoService) {
    this.elementRef = myElement;
    this.searchTerm = new UntypedFormControl();
    this.searchTerm.setValue('');

    this.searchTerm.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
      const val = term.trim();
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (val) {
        this.isLoading = true;
        const qs = [];
        qs[qs.length] = '$populate[0][path]=picture';
        qs[qs.length] = '$populate[0][select]=url thumbnail name type format';
        qs[qs.length] = '$populate[1][path]=residencesUser';
        qs[qs.length] = '$populate[1][select]=identification';
        qs[qs.length] = '$populate[2][path]=residencesVoter';
        qs[qs.length] = '$populate[2][select]=identification';

        if (val !== '*') {
          const splitedValue = val.split(' ');
          let i = 0;
          for (const value of splitedValue) {
            qs[qs.length] = `[$or][${i}][firstName][$regex]=` + value;
            qs[qs.length] = `[$or][${i}][firstName][$options]=i`;
            i++;
            qs[qs.length] = `[$or][${i}][lastName][$regex]=` + value;
            qs[qs.length] = `[$or][${i}][lastName][$options]=i`;
            i++;
          }
        }
        qs[qs.length] = '$sort=firstName';

        this.subscription = this.condoService
          .getUsersFromCondo(this.condo._id, `?${qs.join('&')}`)
          .pipe(distinctUntilChanged())
          .subscribe(
            users => {
              this.filteredList = users;
              this.activeIndex = 0;
              this.isLoading = false;
            },
            err => {
              this.isLoading = false;
              this.filteredList = new Array<User>();
            }
          );
      } else {
        this.filteredList = new Array<User>();
        this.isLoading = false;
      }
    });
  }

  incrementIndex(event) {
    this.activeIndex < this.filteredList.length - 1 ? this.activeIndex++ : (this.activeIndex = 0);
    document.getElementById('resident-autocomplete-list').scrollTop = 42 * (this.activeIndex - 1) - this.activeIndex;
  }

  decrementIndex(event) {
    this.activeIndex > 0 ? this.activeIndex-- : (this.activeIndex = this.filteredList.length - 1);
    document.getElementById('resident-autocomplete-list').scrollTop = 42 * (this.activeIndex - 1) - this.activeIndex;
  }

  selectUser($event) {
    this.select(this.filteredList[this.activeIndex]);
  }

  select(user) {
    if (!user) {
      return;
    }
    this.searchTerm.setValue('');
    this.filteredList = [];
    this.onUserSelect.emit(user);
  }

  handleClick(event) {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.filteredList = [];
    }
  }
}
