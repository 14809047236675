import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';

@Injectable()
export class SupplierService {
  private endPoint;

  constructor(private http: HttpService, private originalHttp: HttpClient, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}suppliers/`;
  }

  createSupplier(supplier: any) {
    return this.http.post(this.endPoint + 'register', supplier);
  }
}
