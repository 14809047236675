import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { from, Observable, Subscriber } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { VehicleServiceV2 } from '@api/serviceV2/vehicle.service';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../services/util.service';
import { Vehicle } from '@api/model/vehicle';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { User } from '@api/model/user';
import { Residence } from '@api/model/interface/residence';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalCreateVehicleComponent } from '../modal-create-vehicle/modal-create-vehicle.component';

@Component({
  selector: 'app-vehicle-autocomplete',
  templateUrl: './vehicle-auto-complete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: VehicleAutoCompleteComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: VehicleAutoCompleteComponent
    }
  ]
})
export class VehicleAutoCompleteComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator {
  @Input() label = '';
  @Input() typeaheadMinLength = 1;
  @Input() customQuery: EcondosQuery;
  @Input() placeholder = 'Busque o veículo';
  @Input() user: User = null;
  @Input() residence: Residence = null;
  @Input() canCreateVehicle = false;
  @Input() shouldValidate = true;

  dataSource$: Observable<Vehicle[]>;
  textSearch;

  condo: Condo;

  status: 'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS' = 'IDLE';

  vehicle: CondoVehicle;
  noResult = false;

  touched = false;

  isDisabled = false;

  isValid = true;

  onChange = value => {};

  onTouched = () => {};

  constructor(private modalService: BsModalService, private vehicleService: VehicleServiceV2, private utilService: UtilService) {}

  ngOnInit(): void {
    this.condo = this.utilService.getLocalCondo();

    this.dataSource$ = new Observable((observer: Subscriber<string>) => observer.next(this.textSearch)).pipe(
      distinctUntilChanged(),
      tap(() => this.markAsTouched()),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'plate color model brand',
          $populate: [{ path: 'residence', select: 'identification' }],
          plate: { $regex: token.toUpperCase() },
          $sort: 'model',
          ...(this.customQuery || {})
        };
        return this.vehicleService.getVehicles(this.condo._id, query).pipe(
          map(res => res.vehicles),
          catchError(e => {
            this.status = 'ERROR';
            return from([]);
          })
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {}

  onSelect(event: TypeaheadMatch): void {
    if (!this.isDisabled) {
      this.vehicle = event.item;
      this.markAsTouched();
      this.onChange(this.vehicle);
    }
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
  }

  clearValue() {
    this.vehicle = null;
    this.textSearch = '';
    this.noResult = false;
    this.onChange(null);
  }

  // Angular form methods
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(vehicle: CondoVehicle): void {
    this.vehicle = vehicle;
    if (vehicle) {
      this.textSearch = vehicle.plate;
    } else {
      this.textSearch = '';
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const vehicle = control.value;
    if (!this.textSearch && vehicle) {
      this.isValid = false;
      return {
        vehicleNotSelected: {
          vehicle
        }
      };
    } else {
      this.isValid = true;
      return null;
    }
  }

  createVehicle(condo: Condo, user: User = null, residence: Residence = null) {
    const initialState = {
      condo,
      onCreate: (vehicle: CondoVehicle) => {
        const match = new TypeaheadMatch(vehicle);
        this.textSearch = this.buildVehicleString(vehicle);
        this.onSelect(match);
      },
      ...(residence && { vehicleResidence: residence }),
      ...(user && { vehicleUser: user })
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState });
  }

  buildVehicleString(vehicle: CondoVehicle) {
    return vehicle.model + ' ' + (vehicle.color ? ' ' + vehicle.color.toLowerCase() : '') + ' - ' + (vehicle.plate || vehicle.chassis);
  }
}
