import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FilePreviewOverlayService } from './file-preview-overlay.service';

@Directive({
  selector: '[appImageViewer]'
})
export class ImageViewerDirective implements OnInit {
  pictures;

  @Input()
  viewerTitle: string;

  @Input()
  viewerIcon: string;

  @Input()
  viewerUrl: string;

  @Input()
  openInNewWindow = false;

  @HostListener('click', ['$event']) onClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
    const url = this.viewerUrl || (this.element && this.element.nativeElement && this.element.nativeElement.src);
    if (this.openInNewWindow) {
      window.open(url);
    } else {
      this.previewDialog.open({ image: { title: this.viewerTitle, url, icon: this.viewerIcon } });
    }
  }

  constructor(private previewDialog: FilePreviewOverlayService, private element: ElementRef) {}

  ngOnInit(): void {
    if (this.element && this.element.nativeElement) {
      this.element.nativeElement.style.cursor = 'pointer';
      this.element.nativeElement.title = 'Clique para ampliar';
    }
  }
}
