import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { Residence } from '@api/model/interface/residence';
import { ResidenceService } from '@api/service/residence.service';
import { KeyValue } from '@angular/common';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { VehicleService } from '@api/service/vehicle.service';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { FileService } from '@api/service/file.service';
import swal from 'sweetalert2';
import { ModalPreviewWebcamComponent } from '../image-picker/modal-preview-webcam/modal-preview-webcam.component';
import { ModalPreviewCCTVComponent } from '../image-picker/modal-preview-cctv/modal-preview-cctv.component';
import { Subject } from 'rxjs';
import { ResidentAutoCompleteComponent } from '../resident-auto-complete/resident-auto-complete.component';
import { conditionalValidator } from '@api/util/validators';
import { User } from '@api/model/user';

@Component({
  selector: 'app-modal-create-vehicle',
  templateUrl: 'modal-create-vehicle.component.html',
  styleUrls: ['./modal-create-vehicle.component.scss']
})
export class ModalCreateVehicleComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ResidentAutoCompleteComponent) vehicleUserPicker: ResidentAutoCompleteComponent;

  VEHICLE_TYPES = CondoVehicle.TYPES;
  VEHICLE_TYPES_LABELS = CondoVehicle.TYPES_LABEL;
  VEHICLES_COLORS = CondoVehicle.VEHICLES_COLORS;
  VEHICLES_COLORS_LABEL = CondoVehicle.VEHICLES_COLORS_LABEL;
  vehicleBrandsOptions = [];

  vehicleResidence: Residence;
  vehicleUser: User;
  condo: Condo;
  vehicle: CondoVehicle;
  status: Status = new Status();

  form: UntypedFormGroup;

  plate: UntypedFormControl;
  chassis: UntypedFormControl;
  model: UntypedFormControl;
  brand: UntypedFormControl;
  color: UntypedFormControl;
  type: UntypedFormControl;
  residence: UntypedFormControl;
  user: UntypedFormControl;
  parkingSpot: UntypedFormControl;

  pictures = [];
  isUploading = false;

  uploadCallback = ([img]) => this.uploadImage(img);

  private unsubscribe: Subject<void> = new Subject();

  onCreate: (condoVehicle: CondoVehicle) => void;
  onUpdate: (vehicle: CondoVehicle) => void;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private fileService: FileService,
    private residenceService: ResidenceService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    Object.keys(CondoVehicle.VEHICLE_BRANDS).map(brand => this.vehicleBrandsOptions.push(CondoVehicle.VEHICLE_BRANDS[brand]));

    this.form = this.fb.group({
      plate: [
        '',
        Validators.compose([
          conditionalValidator(() => this.type.value !== 'BICYCLE' && !this.chassis.value, Validators.required),
          Validators.minLength(3),
          Validators.maxLength(7)
        ])
      ],
      chassis: [
        '',
        Validators.compose([
          conditionalValidator(() => !this.plate.value && this.type.value !== 'BICYCLE', Validators.required),
          Validators.minLength(7),
          Validators.maxLength(17)
        ])
      ],
      model: ['', Validators.required],
      brand: ['', Validators.required],
      color: [''],
      type: [CondoVehicle.TYPES.CAR],
      parkingSpot: [''],
      residence: [null, Validators.required],
      user: [null]
    });

    this.plate = this.form.get('plate') as UntypedFormControl;
    this.chassis = this.form.get('chassis') as UntypedFormControl;
    this.model = this.form.get('model') as UntypedFormControl;
    this.brand = this.form.get('brand') as UntypedFormControl;
    this.color = this.form.get('color') as UntypedFormControl;
    this.type = this.form.get('type') as UntypedFormControl;
    this.residence = this.form.get('residence') as UntypedFormControl;
    this.parkingSpot = this.form.get('parkingSpot') as UntypedFormControl;
    this.user = this.form.get('user') as UntypedFormControl;

    this.plate.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
    });

    this.chassis.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
    });

    this.type.valueChanges
      .pipe(
        tap(() => {
          this.brand.setValue('');
          this.plate.setValue('');
          this.chassis.setValue('');
          this.model.setValue('');
          this.color.setValue('');
        }),
        debounceTime(500)
      )
      .subscribe(() => {
        Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
      });
  }

  ngOnInit() {
    if (this.vehicle) {
      this.type.setValue(this.vehicle.type);
      this.plate.setValue(this.vehicle.plate);
      this.chassis.setValue(this.vehicle.chassis);
      this.model.setValue(this.vehicle.model);
      this.color.setValue(this.vehicle.color);
      this.residence.setValue(this.vehicle.residence);
      this.brand.setValue(this.vehicle.brand);
      this.parkingSpot.setValue(this.vehicle.parkingSpot);
      this.user.setValue(this.vehicle.user);

      this.pictures = this.vehicle.pictures;
    }
    if (this.vehicleResidence) {
      this.residence.setValue(this.vehicleResidence);
    }
    if (this.vehicleUser) {
      this.user.setValue(this.vehicleUser);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    if (this.user?.value) {
      this.vehicleUserPicker?.onTypeAheadSelect({ item: this.user.value });
    }
  }

  selectResidence(residence) {
    this.residence.setValue(residence);
  }

  submit() {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const vehicle: any = {
        plate: this.plate.value.toUpperCase(),
        chassis: this.chassis.value.toString().toUpperCase(),
        model: this.model.value,
        type: this.type.value,
        residence: this.residence.value._id,
        parkingSpot: (this.parkingSpot.value || '').toUpperCase()
      };
      if (this.brand.value) {
        vehicle.brand = this.brand.value;
      }
      if (this.color.value || this.vehicle) {
        vehicle.color = this.color.value || null;
      }
      if (this.pictures.length || this.vehicle) {
        vehicle.pictures = this.pictures || null;
      }
      if (this.user.value || this.vehicle) {
        vehicle.user = this.user.value?._id || null;
      }
      if (this.vehicle) {
        this.update(vehicle);
      } else {
        this.create(vehicle);
      }
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha os campos obrigatórios.');
    }
  }

  create(vehicle) {
    const residenceId = this.residence.value?._id || this.residence.value;
    this.residenceService.addVehicle(this.condo._id, residenceId, vehicle).subscribe(
      (res: any) => {
        const condoVehicle = new CondoVehicle({ ...vehicle, residence: this.residence.value, _id: res._id, user: this.user.value });
        this.onCreate(condoVehicle);
        this.bsModalRef.hide();
        this.toastr.success('Veículo cadastrado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        if (err.originalError?.error?.includes('Only a voter or resident')) {
          swal({
            type: 'error',
            title: 'Não foi possível efetuar o cadastro',
            text: `Você não tem permissão para criar veículos em ${this.condo.customLabels.residence.plural} das quais não pertence, contate um administrador`
          });
        } else {
          this.toastr.error('Não foi possível cadastrar o veículo, tente novamente');
        }
      }
    );
  }

  update(vehicle) {
    const residenceId = this.residence.value?._id || this.residence.value;
    this.residenceService.updateVehicle(this.condo._id, residenceId, this.vehicle._id, vehicle).subscribe(
      (res: any) => {
        const v = new CondoVehicle({
          ...this.vehicle,
          ...vehicle,
          residence: this.residence.value,
          user: this.user.value
        });
        this.onUpdate(v);
        this.bsModalRef.hide();
        this.toastr.success('Veículo atualizado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível atualizar o veículo, tente novamente');
      }
    );
  }

  onBrandSelect(event) {
    const brand = event.value;
    this.brand.setValue(brand);
  }

  onColorSelect(event) {
    const color = event.value;
    this.color.setValue(color);
  }

  defaultSortFunction = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    return 0;
  };

  onVehicleUserSelect(data) {
    if (data) {
      this.user.setValue(data);
    } else {
      this.user.setValue(null);
    }
  }

  onVehicleUserTypeaheadNoResult(text) {
    this.user.setValue(null);
  }

  uploadImage(image) {
    if (image) {
      const formData = new FormData();
      formData.append(image.title || 'image', image);
      this.isUploading = true;
      this.fileService
        .uploadFilesFromFormData(formData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          ([img]) => {
            this.isUploading = false;
            this.pictures.push(img);
          },
          err => {
            this.isUploading = false;
            swal({
              type: 'error',
              title: 'Ops...',
              text: 'Não foi possível efetuar o upload da imagem, tente novamente',
              cancelButtonColor: '#f53d3d'
            });
          }
        );
    }
  }

  uploadBase64Url(data) {
    this.isUploading = true;
    this.fileService.uploadBase64(data).subscribe(
      ([img]) => {
        this.pictures.push(img);
      },
      err => {
        console.log(err);
      },
      () => {
        this.isUploading = false;
      }
    );
  }

  openWebCam() {
    const initialState = {
      useImageCropper: true,
      callback: base64 => {
        if (base64) {
          this.uploadBase64Url(base64);
        }
      }
    };
    this.modalService.show(ModalPreviewWebcamComponent, { initialState, ignoreBackdropClick: true });
  }

  openCCTVCam() {
    const initialState = {
      defaultImage: 'assets/img/empty-vehicle-picture.png',
      useImageCropper: true,
      defaultType: '',
      callback: base64 => {
        if (base64) {
          this.uploadBase64Url(base64);
        }
      }
    };
    this.modalService.show(ModalPreviewCCTVComponent, { initialState, ignoreBackdropClick: true });
  }
}
