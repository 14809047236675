import { Injectable } from '@angular/core';
import { IpcRenderer } from 'electron';
import { Observable, Subject } from 'rxjs';

export enum EVENT_NAMES {
  PRINT_WINDOW = 'PRINT_WINDOW'
}

@Injectable({ providedIn: 'root' })
export class ElectronService {
  private ipcRenderer: IpcRenderer;

  private _onData$: Subject<any> = new Subject();
  onData$: Observable<any> = this._onData$.asObservable();

  constructor() {
    if (this.isElectron()) {
      if ((window as any).require) {
        try {
          this.ipcRenderer = (window as any).require('electron').ipcRenderer;
          this.ipcRenderer.on('data', (event, args) => this._onData$.next(args));
        } catch (e) {
          throw e;
        }
      }
    } else {
      console.log('App not running inside Electron!');
    }
  }

  emit(key: EVENT_NAMES, value: any) {
    try {
      this.ipcRenderer.send('data', { command: key, value });
    } catch (e) {
      console.log('App not emit data to Electron!');
    }
  }

  isElectron(): boolean {
    const userAgent = (window as any) && (window as any).navigator && (window as any).navigator.userAgent;
    return userAgent.toLowerCase().includes('electron');
  }
}
