/**
 * @deprecated Use /api/utils - wiegandToSerial
 */
export const wiegandToSerial = wiegand => {
  wiegand = wiegand.replace(/\D/g, '');
  if (wiegand.length === 8) {
    let firstPart = parseInt(wiegand.substring(0, 3), 10).toString(16);
    while (firstPart.length < 2) {
      firstPart = '0'.concat(firstPart);
    }
    let secondPart = parseInt(wiegand.substring(3, 8), 10).toString(16);
    while (secondPart.length < 4) {
      secondPart = '0'.concat(secondPart);
    }
    const serial = firstPart.concat(secondPart).toUpperCase().substr(-6);
    return serial;
  } else {
    throw new Error('Invalid Wiegand');
  }
};

/**
 * @deprecated Use /api/utils - serialToWiegand
 */
export const serialToWiegand = serial => {
  serial = serial.substring(serial.length - 6, serial.length);
  if (serial.toString().length !== 6) {
    throw new Error('Invalid serial number');
  }

  let partA = serial.substring(0, 2);
  partA = parseInt(partA, 16).toString(10);
  if (partA.length < 3) {
    const numberOfZeros = 3 - partA.length;
    for (let i = 0; i < numberOfZeros; i++) {
      partA = '0' + partA;
    }
  }
  let partB = serial.substring(2, 6);
  partB = parseInt(partB, 16).toString(10);
  if (partB.length < 5) {
    const numberOfZeros = 5 - partB.length;
    for (let i = 0; i < numberOfZeros; i++) {
      partB = '0' + partB;
    }
  }
  const wiegand = (partA + partB).toString(10);
  return wiegand;
};

export const getHardwareCondo = (hardwareCondo: any) => {
  const condo: { _id: string; name: string } = hardwareCondo;
  if (typeof condo === 'string') {
    return condo;
  } else if (condo?.name) {
    return { _id: condo._id, name: condo.name };
  } else {
    return { _id: condo._id };
  }
};

export const getHardwareAttendedBy = (hardwareAttendedBy: any) => {
  let attendedBy;
  if (typeof hardwareAttendedBy === 'string') {
    attendedBy = hardwareAttendedBy;
  } else if (hardwareAttendedBy?._id && hardwareAttendedBy?.firstName) {
    attendedBy = {
      _id: hardwareAttendedBy._id,
      name: hardwareAttendedBy.firstName + ' ' + (hardwareAttendedBy.lastName || '')
    };
  }
  return attendedBy;
};

export const getHardwareUser = (hardwareUser: any) => {
  const user: { _id: string; firstName: string; lastName: string; userName: string } = hardwareUser;
  if (typeof user === 'string') {
    return user;
  } else if (user?.firstName || user?.userName) {
    const name: string = user.userName || `${user.firstName} ${user.lastName || ''}`;
    return { _id: user._id, name };
  }
};

export const getHardwareResidence = (hardwareResidence: any) => {
  const residence: { _id: string; identification: string; block: string } = hardwareResidence;
  if (typeof residence === 'string') {
    return residence;
  } else if (residence?.identification) {
    return { _id: residence._id, identification: residence.identification, block: residence.block || '' };
  }
};

export const getHardwarePicture = (hardwarePicture: any) => {
  const picture: { _id: string; url: string; thumbnail: string } = hardwarePicture;
  if (typeof picture === 'string') {
    return picture;
  } else if (picture?.url && picture?.thumbnail) {
    return { _id: picture._id, url: picture.url, thumbnail: picture.thumbnail };
  }
};

export const getHardwareVisitor = (hardwareVisitor: any) => {
  const visitor: { _id: string; firstName: string; lastName: string } = hardwareVisitor;
  if (typeof visitor === 'string') {
    return visitor;
  } else if (visitor?.firstName) {
    return { _id: visitor._id, name: visitor.firstName + ' ' + (visitor.lastName || '') };
  }
};
