import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Pet, PET_SEX_LABELS, PET_SIZES_LABELS, PET_SPECIES_LABELS } from '../../api/model/pet';
import { ResidenceService } from '../../api/service/residence.service';
import { Subscription } from 'rxjs';
import { Residence } from '../../api/model/interface/residence';
import { ModalCreatePet } from '../modal-create-pet/modal-create-pet';
import swal from 'sweetalert2';
import { timeout } from 'rxjs/operators';
import { Condo } from '../../api/model/condo';
import { User } from '../../api/model/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PetService } from '../../api/service/pet.service';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';

@Component({
  selector: 'app-pet-list',
  templateUrl: 'pet-list.html',
  styleUrls: ['./pet-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PetListComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  residence: Residence;
  @Input()
  pets: Pet[] = [];
  @Input()
  condo: Condo;
  @Input()
  user: User;

  @Output()
  petsLoaded: EventEmitter<Pet[]> = new EventEmitter();

  @ViewChild('petTemplate', { static: true }) petTemplate: TemplateRef<Pet>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<Pet>;
  @ViewChild('petsTable', { static: true }) petsTable: TableComponent;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<Pet>[] = [];
  subscription: Subscription = new Subscription();

  today = new Date();

  hasWriteAccess = false;
  page = 0;
  countData: number = 0;

  PET_SIZES = PET_SIZES_LABELS;
  PET_SEX = PET_SEX_LABELS;
  PET_SPECIES = PET_SPECIES_LABELS;

  constructor(
    private residenceService: ResidenceService,
    private toastr: ToastrService,
    private petService: PetService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo && changes.residence && changes.user) {
      const user: User = changes.user.currentValue;
      const condo = changes.condo.currentValue;
      const residence: Residence = changes.residence.currentValue;
      this.hasWriteAccess =
        user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id) || residence.isOwner(user._id) || residence.isUser(user._id);
      if (this.pets.length == 0 || changes.condo || changes.residence) {
        this.getData();
      }
    }
  }
  ngOnInit() {
    this.tableColumns = [
      {
        columnId: 'note',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.petTemplate,
        sortable: false
      }
    ];
  }

  getData({ page = 0 } = {}) {
    this.status = 'LOADING';
    const { pageSize } = this.petsTable.getCurrentState();
    const petParams = [];
    petParams.push({ '$populate[0][path]': 'picture' });
    petParams.push({ '$populate[0][select]': 'url thumbnail type format name' });
    petParams.push({ $page: page });
    petParams.push({ $limit: pageSize });
    this.residenceService.getPets(this.condo._id, this.residence._id, petParams).subscribe({
      next: ({ count, pets }) => {
        this.pets = [].concat(pets);
        this.countData = count;
        this.petsLoaded.emit(pets);
        this.status = 'SUCCESS';
        this.cdr.detectChanges();
      },
      error: () => {
        this.status = 'ERROR';
      }
    });
  }
  create() {
    const initialState = {
      condo: this.condo,
      petResidence: this.residence,
      onCreate: (pet: Pet) => {
        this.countData = +this.countData + 1;
        this.pets = [].concat(pet, this.pets);
        this.cdr.detectChanges();
        this.petsLoaded.emit(this.pets);
      }
    };
    this.modalService.show(ModalCreatePet, { initialState, ignoreBackdropClick: true });
  }

  edit(pet: Pet) {
    const initialState = {
      condo: this.condo,
      petResidence: this.residence,
      pet,
      onUpdate: updated => {
        const index = this.pets.findIndex(p => p._id === pet._id);
        this.pets[index] = updated;
        this.pets = [].concat(this.pets);
        this.cdr.detectChanges();
        this.petsLoaded.emit(this.pets);
      }
    };
    this.modalService.show(ModalCreatePet, { initialState, ignoreBackdropClick: true });
  }

  delete(pet: Pet) {
    swal({
      type: 'question',
      text: `Deseja remover o pet ${pet.name} ?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.residenceService
          .removePet(this.condo._id, this.residence._id, pet._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível excluir o pet, tente novamente...');
          });
      }
    }).then(
      () => {
        this.toastr.success('Pet removido com sucesso');
        this.countData = this.countData - 1;
        const index = this.pets.findIndex(p => p._id === pet._id);
        this.pets.splice(index, 1);
        this.pets = [].concat(this.pets);
        this.cdr.detectChanges();
        this.petsLoaded.emit(this.pets);
      },
      error => {
        console.log(error);
      }
    );
  }
}
