import { Condo } from './condo';
import { Residence } from './interface/residence';
import { ResidenceBuilder } from './residence/residence.builder';
import { User } from './user';

enum CHAT_TYPE {
  GATE = 'GATE',
  ADMIN = 'ADMIN'
}

enum CHAT_TYPE_LABEL {
  GATE = 'Portaria',
  ADMIN = 'Síndico'
}

export interface ChatRoom {
  _id?: string;
  condo?: Partial<Condo>;
  residence?: Partial<Residence>;
  user?: Partial<User>;
  type?: CHAT_TYPE;
  type_label?: CHAT_TYPE_LABEL;
  identification?: string;
  agentUnreadMessagesCount?: number;
  userUnreadMessagesCount?: number;
  createdBy?: Partial<User>;
  createdAt?: string;
  updatedAt?: string;
  lastMessageAt?: string;
  lastMessageText?: string;
}

export const BuildChatRoom = (chatRoom): ChatRoom => {
  if (typeof chatRoom == 'string') {
    return {
      _id: chatRoom
    };
  } else if (chatRoom) {
    return {
      _id: chatRoom._id || '',
      condo: chatRoom.condo ? new Condo(chatRoom.condo) : null,
      residence: chatRoom.residence ? ResidenceBuilder.build(chatRoom.residence) : null,
      user: chatRoom.user ? new User(chatRoom.user) : null,
      type: chatRoom.type ? CHAT_TYPE[chatRoom.type] : null,
      type_label: chatRoom.type ? CHAT_TYPE_LABEL[chatRoom.type] : null,
      identification: chatRoom.identification || '',
      agentUnreadMessagesCount: chatRoom.agentUnreadMessagesCount || 0,
      userUnreadMessagesCount: chatRoom.userUnreadMessagesCount || 0,
      createdBy: chatRoom.createdBy ? new User(chatRoom.createdBy) : null,
      createdAt: chatRoom.createdAt || null,
      updatedAt: chatRoom.updatedAt || null,
      lastMessageAt: chatRoom.lastMessageAt || '',
      lastMessageText: chatRoom.lastMessageText || ''
    };
  }
};
