import { ElementRef, Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

// Models
import { Condo } from '../../api/model/condo';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { UtilService } from '../../services/util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'condos-autocomplete',
  host: {
    '(document:click)': 'handleClick($event)'
  },
  template: `
    <div class="condos-autocomplete">
      <div class="input-group">
        <span class="input-group-text" id="basic-addon1">
          <i class="fa fa-search" aria-hidden="true"></i>
        </span>

        <input
          type="text"
          [formControl]="search"
          placeholder="Pesquisar..."
          (keyup.enter)="selectCondo()"
          (keyup.arrowUp)="decrementIndex()"
          (keyup.arrowDown)="incrementIndex()"
          aria-describedby="basic-addon1"
          class="form-control" />
      </div>

      <div *ngIf="filteredList.length > 0" class="dropdown-list list-group" id="dropdown-list">
        <a
          *ngFor="let item of filteredList; let i = index"
          class="list-group-item"
          (click)="select(item)"
          [class.active]="i === activeIndex">
          {{ item.name }} {{ getCondoInfo(item) }}
        </a>
      </div>
    </div>
  `
})
export class CondosAutocompleteComponent implements OnDestroy {
  public elementRef;
  public filteredList = [];
  public activeIndex: number = -1;
  public wasCondoNotFound: boolean;

  public search: UntypedFormControl = new UntypedFormControl('');

  @Input()
  public condos: Array<Condo> = new Array<Condo>();

  @Output()
  public onCondoSelect = new EventEmitter();

  subscriptions: Subscription = new Subscription();

  constructor(myElement: ElementRef, private utilService: UtilService) {
    this.elementRef = myElement;
    this.subscriptions.add(
      this.search.valueChanges.pipe(debounceTime(300)).subscribe(val => {
        if (val !== '') {
          this.filteredList = this.condos.filter((c: Condo) => {
            const name = this.utilService.removeAccents(c.name);
            const query = this.utilService.removeAccents(val);
            return name.includes(query);
          });
          this.wasCondoNotFound = this.filteredList.length == 0 ? true : false;
        } else {
          this.activeIndex = -1;
          this.filteredList = [];
          this.wasCondoNotFound = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCondoInfo(condo) {
    if (condo.city && condo.state) {
      return `(${condo.city} - ${condo.state})`;
    } else if (condo.city) {
      return `(${condo.city})`;
    } else if (condo.state) {
      return `(${condo.state})`;
    } else {
      return '';
    }
  }

  selectCondo() {
    this.select(this.filteredList[this.activeIndex]);
  }

  incrementIndex() {
    if (!this.filteredList.length) {
      return;
    }

    this.activeIndex < this.filteredList.length - 1 ? this.activeIndex++ : (this.activeIndex = 0);
    document.getElementById('dropdown-list').scrollTop = 42 * (this.activeIndex - 1) - this.activeIndex;
  }

  decrementIndex() {
    if (!this.filteredList.length) {
      return;
    }

    this.activeIndex > 0 ? this.activeIndex-- : (this.activeIndex = this.filteredList.length - 1);
    document.getElementById('dropdown-list').scrollTop = 42 * (this.activeIndex - 1) - this.activeIndex;
  }

  select(condo) {
    if (condo) {
      this.search.setValue(condo.name, { emitEvent: false });
      this.filteredList = [];
      this.wasCondoNotFound = false;
      this.onCondoSelect.emit(condo);
    }
  }

  handleClick(event) {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.filteredList = [];
    }
  }

  clearSearchTerm() {
    this.search.setValue('', { emitEvent: false });
  }
}
