import { Error } from './error';
import { TimeoutError } from './timeout.error';
import { ResponseError } from './response.error';
import { ConnectionError } from './connection.error';
import { throwError } from 'rxjs';
export class ErrorBuilder {
  static build(error): Error {
    console.log(error);
    if (error && error.name && error.name == 'TimeoutError') {
      return new TimeoutError();
    }
    if (error instanceof ProgressEvent) {
      return new ConnectionError();
    } else {
      return new ResponseError(error.error || error);
    }
  }

  static throwTimeoutError() {
    return throwError(new TimeoutError());
  }
}
