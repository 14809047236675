import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { CondoUser } from '@api/model/condo-user';
import { EcondosQuery } from '@api/model/query';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { finalize, map, mergeMap, retry, scan, tap, timeout } from 'rxjs/operators';
import { parseInt } from 'lodash';
import { environment } from '@environment';
import { WhiteLabel } from '@api/model/white-label';

export const CONDO_CHANNEL = (condo: Condo) => {
  return `private:linear:${condo._id}`;
};

export const wiegandToSerial = wiegand => {
  wiegand = wiegand.replace(/\D/g, '').padStart(8, '0');
  let firstPart = parseInt(wiegand.substring(0, 3), 10).toString(16);
  while (firstPart.length < 2) {
    firstPart = '0'.concat(firstPart);
  }
  let secondPart = parseInt(wiegand.substring(3, 8), 10).toString(16);
  while (secondPart.length < 4) {
    secondPart = '0'.concat(secondPart);
  }
  const serial = firstPart.concat(secondPart).toUpperCase().substr(-6);
  return serial;
};

export const serialToWiegand = serial => {
  serial = serial.substring(serial.length - 6, serial.length);
  if (serial.toString().length !== 6) {
    throw new Error('Invalid serial number');
  }

  let partA = serial.substring(0, 2);
  partA = parseInt(partA, 16).toString(10);
  if (partA.length < 3) {
    const numberOfZeros = 3 - partA.length;
    for (let i = 0; i < numberOfZeros; i++) {
      partA = '0' + partA;
    }
  }
  let partB = serial.substring(2, 6);
  partB = parseInt(partB, 16).toString(10);
  if (partB.length < 5) {
    const numberOfZeros = 5 - partB.length;
    for (let i = 0; i < numberOfZeros; i++) {
      partB = '0' + partB;
    }
  }
  const wiegand = (partA + partB).toString(10);
  return wiegand;
};

export const decimalToSerial = dec => parseInt(dec.toString(), 10).toString(16);

export const serialToDecimal = serial => parseInt(serial.toString(), 16).toString();

export const decimalToWiegand = dec => {
  const serial = decimalToSerial(dec).padStart(6, '0');
  return serialToWiegand(serial);
};

export const wiegandToDecimal = wiegand => {
  const serial = wiegandToSerial(wiegand);
  return parseInt(serial, 16).toString();
};

export const isOwnerOnCondo = (user: User, condoId: string) => {
  return user.condosOwner.some(condo => condo._id === condoId || condo === condoId);
};

export const isAdminOnCondo = (user: User, condoId: string) => {
  return user.condosAdmin.some(condo => condo._id === condoId || condo === condoId) || isOwnerOnCondo(user, condoId);
};

export const isGatekeeperOnCondo = (user: User, condoId: string) => {
  return user.condosGatekeeper.some(condo => condo._id === condoId || condo === condoId);
};

export const getUserRoleOnCondo = (u: User | CondoUser, condo: Condo) => {
  let role = '';
  if (u.condosOwner.findIndex(c => c === condo || c === condo._id || c._id === condo._id) !== -1) {
    role = 'OWNER';
  } else if (u.condosAdmin.findIndex(c => c === condo || c === condo._id || c._id === condo._id) !== -1) {
    role = 'ADMIN';
  } else if (u.condosGatekeeper.findIndex(c => c === condo || c === condo._id || c._id === condo._id) !== -1) {
    role = 'GATEKEEPER';
  } else if (u.condosJanitor.findIndex(c => c === condo || c === condo._id || c._id === condo._id) !== -1) {
    role = 'JANITOR';
  } else if (u.condos.findIndex(c => c === condo || c === condo._id || c._id === condo._id) !== -1) {
    role = 'USER';
  }
  return role;
};

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const createImageFromBlob = (image: Blob): Promise<any> => {
  if (!image) {
    return Promise.reject('No data detected');
  }
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );
    reader.readAsDataURL(image);
  });
};

export const downloadDataInChunks = <T extends object = any>(
  http,
  endpoint,
  query: EcondosQuery,
  opt: {
    model?: any;
    transformFn?: (data: any) => T;
    numberOfRequests?: number;
    timeout?: number;
    retries?: number;
    ignoreLoadingBar?: boolean;
  } = {
    model: null,
    transformFn: null,
    numberOfRequests: 4,
    retries: 3,
    timeout: 10000,
    ignoreLoadingBar: false
  }
): Observable<
  [
    Observable<{
      count: number;
      data: T[];
    }>,
    Subject<number>
  ]
> => {
  // https://medium.com/angular-in-depth/rxjs-recipes-forkjoin-with-the-progress-of-completion-for-bulk-network-requests-in-angular-5d585a77cce1
  const limit = query.$limit ? Math.min(query.$limit, 500) : 250;
  const countQuery = { ...query, $count: true };
  delete countQuery.$limit;
  delete countQuery.$populate;
  delete countQuery.$sort;
  const headers = new HttpHeaders({
    ...(opt.ignoreLoadingBar && { ignoreLoadingBar: '' })
  });
  const options = { params: new HttpParams({ fromString: qs.stringify(countQuery) }), headers };

  const percent$ = new Subject();
  return http.get(endpoint, options).pipe(
    map(res => (Array.isArray(res) ? res : [res])),
    mergeMap(([count]: any) => {
      const numberOfPages = Math.ceil(count / limit);
      const requests = [];
      if (numberOfPages === 0) {
        percent$.next(100);
        percent$.complete();
        return of([of({ count: 0, data: [] }), percent$.asObservable()]);
      } else {
        for (let i = 0; i < numberOfPages; i++) {
          const q: EcondosQuery = { ...query, $page: i, $limit: limit };
          const headers = new HttpHeaders({
            ...(opt.ignoreLoadingBar && { ignoreLoadingBar: '' })
          });
          const params = new HttpParams({ fromString: qs.stringify(q) });
          const request = http.get(endpoint, { params, headers }).pipe(
            timeout((opt && opt.timeout) || 10000),
            retry((opt && opt.retries) || 3),
            map((r: any) => ({
              index: i,
              data: r.map(d => {
                if (opt.model) {
                  return new opt.model(d);
                }

                if (opt.transformFn) {
                  return opt.transformFn(d);
                }

                return d;
              })
            }))
          );
          requests.push(request);
        }
        let counter = 0;
        const modifiedRequests = requests.map(req =>
          req.pipe(
            finalize(() => {
              const percentValue = Math.floor((++counter * 100) / (modifiedRequests.length || 1));
              percent$.next(percentValue);
            })
          )
        );

        const results$ = forkJoin(
          merge(...modifiedRequests, (opt && opt.numberOfRequests) || 4).pipe(
            scan((acc, curr: any) => {
              acc = acc.concat({ ...curr });
              return acc;
            }, [])
          )
        ).pipe(
          map(([results]) => results),
          map(t => t.sort((a, b) => a.index - b.index).map(d => d.data)),
          map(t => t.flat(1)),
          map(data => ({ count, data })),
          tap(() => {
            percent$.next(100);
            percent$.complete();
          })
        );
        return of([results$, percent$.asObservable()]);
      }
    })
  );
};

export const isObjectId = id => {
  const regexId = new RegExp('[0-9a-fA-F]{24}|[^/]+@[^/]+?');
  return regexId.test(id);
};

export const isElectron = () => {
  const userAgent = window?.navigator?.userAgent || '';
  return userAgent.toLowerCase().includes('electron');
};

export const parseHourMinuteStringToDate = (timeString: string) => {
  const [hours, minutes] = timeString.split(':');
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const createLinkToShareOccurrence = (condoId: string, occurrenceId: string, whitelabel?: WhiteLabel) => {
  let url = `${whitelabel?.qrCodePageUrl || environment.visitorUrl}/self-liberation?`;
  // verificar se a url inicia com http e https
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = `https://${url}`;
  }

  const condo = `condo=${condoId}`;
  const occurrence = `&occurrence=${occurrenceId}`;

  return url + condo + occurrence;
};
