import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { CondoService } from '@api/service/condo.service';
import { CondoUser } from '@api/model/condo-user';
import { removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { DependentService } from '@api/service/dependent.service';
import { EcondosQuery } from '@api/model/query';
import { Dependent } from '@api/model/dependent';
import { Residence } from '@api/model/interface/residence';
import { ResidenceService } from '@api/service/residence.service';

interface CondoUserAndSearchText {
  searchText: string;
  user: CondoUser;
}

@Component({
  selector: 'app-resident-auto-complete',
  templateUrl: 'resident-auto-complete.component.html',
  styleUrls: ['resident-auto-complete.component.scss']
})
export class ResidentAutoCompleteComponent implements OnInit, AfterViewInit {
  @Output() selectItem: EventEmitter<CondoUser | CondoUserAndSearchText> = new EventEmitter<CondoUser | CondoUserAndSearchText>();
  @Output() inputText: EventEmitter<string> = new EventEmitter<string>();
  @Output() newResidentClick: EventEmitter<any> = new EventEmitter();
  @Input() placeholder;
  @Input() condo: Condo;
  @Input() typeaheadHideResultsOnBlur = false;
  @Input() adaptivePosition = false;
  @Input() showAsInputGroup = false;
  @Input() disabled = false;
  @Input() searchDependents = false;
  @Input() dependentsQuery: EcondosQuery = {};
  @Input() usersQuery: EcondosQuery = {};
  @Input() limitOfItems = 10;
  @Input() canInviteUser = true;
  @Input() alsoReturnSearchText = false;
  @Input() dontShowEmptyResultAndReturnSearchText = false;
  @Input() focusOnInit = false;
  @Input() clearInputTextAfterSelect = false;
  @Input() residence: Residence;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  status: Status = new Status();

  typeAheadDataSource$: Observable<CondoUser[]>;
  selectedLabel = '';
  selectedObject;
  searchText = '';

  noResult = false;

  onTypeAheadSelect = evt => {
    this.selectedObject = evt.item;

    if (this.alsoReturnSearchText) {
      this.selectItem.emit({
        searchText: this.searchText,
        user: evt.item
      });
    } else {
      this.selectItem.emit(evt.item);
    }

    this.selectedLabel = this.getResidentLabel(evt.item);

    if (this.clearInputTextAfterSelect) {
      this.resetValue();
    }
  };

  constructor(private condoService: CondoService, private dependentService: DependentService, private residenceService: ResidenceService) {
    this.typeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.selectedLabel);
    }).pipe(
      distinctUntilChanged(),
      filter((text: string) => (text || '').trim().length > 2),
      mergeMap((token: string) => {
        this.searchText = token;

        let terms: any = token.trim().toLowerCase();
        terms = terms.split(' ');
        terms = terms.map(word => removeAccents(word));
        const originalTerms = [...terms];
        terms = terms.map(replaceVowelsToAccentedVowels);

        let usersQuery: EcondosQuery = {
          $populate: [
            { path: 'picture', select: 'thumbnail url type' },
            { path: 'residencesUser', select: 'identification' }
          ],
          $limit: 50
        };

        const hasNumber = /\d/;
        const isId = hasNumber.test(token);
        if (isId) {
          usersQuery['ids.number'] = { $regex: token };
        } else {
          usersQuery.$and = [];
          terms.forEach(term => {
            usersQuery.$and.push({ $or: [{ firstName: { $regex: term, $options: 'i' } }, { lastName: { $regex: term, $options: 'i' } }] });
          });
        }

        usersQuery = { ...usersQuery, ...this.usersQuery };

        if (this.searchDependents) {
          let dependentQS: EcondosQuery = {
            $populate: [
              { path: 'picture', select: 'thumbnail url type' },
              { path: 'residence', select: 'identification' }
            ],
            $limit: 50
          };
          if (isId) {
            dependentQS.$or = [{ cpf: { $regex: token } }, { rg: { $regex: token } }];
          } else {
            dependentQS.name = { $regex: terms.join(' '), $options: 'i' };
          }
          dependentQS = { ...dependentQS, ...this.dependentsQuery };
          return forkJoin([
            this.condoService.getCondoResidents(this.condo._id, usersQuery),
            this.dependentService.getDependents(this.condo._id, dependentQS)
          ]).pipe(
            map(([usersResponse, dependentsResponse]) => {
              const users = usersResponse.users || [];
              const dependents = (dependentsResponse.dependents || []).map(d => ({
                ...d,
                fullName: d.name,
                residences: [d.residence]
              }));
              const result = [...users, ...dependents].sort((a, b) => {
                const aName = a.fullName.toLowerCase();
                const bName = b.fullName.toLowerCase();
                const aRelevance = originalTerms.reduce((acc, term) => (aName.includes(term) ? acc + 1 : acc), 0);
                const bRelevance = originalTerms.reduce((acc, term) => (bName.includes(term) ? acc + 1 : acc), 0);
                return bRelevance - aRelevance;
              });
              return result;
            })
          );
        } else {
          let subscription: Observable<Array<any>>;
          if (this.residence) {
            subscription = this.residenceService.getUsersFromResidence(this.condo._id, this.residence._id, usersQuery).pipe(
              map(({ users }) =>
                (users || []).sort((a, b) => {
                  const aName = a.fullName.toLowerCase();
                  const bName = b.fullName.toLowerCase();
                  const aRelevance = originalTerms.reduce((acc, term) => (aName.includes(term) ? acc + 1 : acc), 0);
                  const bRelevance = originalTerms.reduce((acc, term) => (bName.includes(term) ? acc + 1 : acc), 0);
                  return bRelevance - aRelevance;
                })
              ),
              catchError(e => {
                this.status.setAsError();
                return of([]);
              })
            );
          } else {
            subscription = this.condoService.getCondoResidents(this.condo._id, usersQuery).pipe(
              map(res =>
                (res.users || []).sort((a, b) => {
                  const aName = a.fullName.toLowerCase();
                  const bName = b.fullName.toLowerCase();
                  const aRelevance = originalTerms.reduce((acc, term) => (aName.includes(term) ? acc + 1 : acc), 0);
                  const bRelevance = originalTerms.reduce((acc, term) => (bName.includes(term) ? acc + 1 : acc), 0);
                  return bRelevance - aRelevance;
                })
              ),
              catchError(e => {
                this.status.setAsError();
                return of([]);
              })
            );
          }
          return subscription;
        }
      })
    );
  }
  ngOnInit() {
    this.placeholder = this.placeholder || `Buscar ${this.condo?.customLabels?.resident?.singular || 'condômino'}`;
  }

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      this.input.nativeElement.focus();
    }
  }

  getResidentLabel(selectedPerson: any) {
    if (selectedPerson.firstName || selectedPerson.lastName) {
      return selectedPerson.firstName + ' ' + selectedPerson.lastName;
    } else if (selectedPerson.name) {
      return selectedPerson.name + ' (dependente)';
    } else {
      return '';
    }
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
    this.inputText.emit(this.searchText);
  }

  changeTypeaheadLoading(isLoading: boolean): void {
    if (isLoading) {
      this.status.setAsDownloading();
    } else {
      if (!this.status.isError()) {
        this.status.setAsSuccess();
      }
    }
  }

  resetValue() {
    this.selectedLabel = '';
    this.selectedObject = null;
    this.noResult = false;
    this.status = new Status();
    this.selectItem.emit(null);
  }

  onInputBackspace(event) {
    const targetValue = event.target.value;

    if (!targetValue) {
      this.resetValue();
    }
  }
}
