import { User } from '../user';
import { File } from '../../model/file';

export type EmergencyContact = {
  _id?: string;
  name: string;
  phone: string;
  parentage?: string;
};

export abstract class Residence {
  static TYPES = {
    HOUSE: 'HOUSE',
    APARTMENT: 'APARTMENT',
    BUSINESS: 'BUSINESS',
    OTHER: 'OTHER'
  };

  public _id: string;
  private _condo: any;
  protected _identification: string;
  public type: string;
  public number: string;
  public fraction: number;
  public defaulter: boolean;
  public block: string;
  public address: string;
  private _users: any = [];
  public qtDependents: number;
  public documents: File[] = [];
  private _requesters: any = [];
  private _voter: any;
  private _createdBy: any;
  private _updatedBy: any;
  private _residenceUsers: Array<User>;
  public extensionLine: string;
  public params: {
    residentCanUseQRCode: boolean;
    residentCanShareVisitorQRCode: boolean;
    residentCanUseVirtualKey: boolean;
    residentCanUseKeyboardApp: boolean;
    residentCanUsePanicEvent: boolean;
    lastPanicEventUpdatedBy: User;
  };
  public externalId: string;
  public obs: string;
  public emergencyContacts: EmergencyContact[];
  public rented: boolean;
  public contract: { startDate: Date; endDate: Date; status: string };
  public lessee: string;

  static createApartmentIdentification(numero: string, andar: string, bloco: string, mask?: string) {
    if (!mask || mask.length == 0) {
      return `${andar}${numero}${bloco.toUpperCase()}`;
    }

    let id = '';

    const elements = {
      n: numero,
      b: bloco,
      a: andar
    };

    mask = mask.toLowerCase();

    const applyMask = () => {
      const n = (mask.match(new RegExp(mask[0], 'g')) || []).length; // number of times it appears
      const prop = elements[mask[0]];

      if (prop) {
        if (n === prop.length || n < prop.length) {
          id = id.concat(prop);
        } else if (n > prop.length) {
          const dif = n - prop.length;
          let zeros = '';
          for (let i = 1; i <= dif; i++) {
            zeros = zeros.concat('0');
          }
          id = id.concat(zeros, prop);
        }

        mask = mask.substr(n);
      } else {
        id = id.concat(mask[0]);
        mask = mask.substr(1);
      }

      if (mask.length) {
        applyMask();
      }
    };

    // Apply mask (n = number; a = andar; b = bloco)
    applyMask();

    return id;
  }

  static createApartmentIdentificationFromNumberAndBlock(number: string, block: string) {
    return `${number}${block.toUpperCase()}`;
  }

  constructor(residence) {
    if (typeof residence == 'string') {
      this.id = residence;
    } else if (residence) {
      this.id = residence._id;
      this.condo = residence.condo;
      this.identification = residence.identification;
      this.type = residence.type;
      this.fraction = residence.fraction;
      this.defaulter = residence.defaulter || false;
      this.block = residence.block || '';
      this.number = residence.number;
      this.address = residence.address;
      this.users = residence.users ? residence.users.map(user => new User(user)) : this._users;
      this.requesters = residence.requesters ? residence.requesters.map(requester => new User(requester)) : this._requesters;
      this.voter = residence.voter ? new User(residence.voter) : null;
      this.createdBy = residence.createdBy;
      this.updatedBy = residence.updatedBy;
      this.residenceUsers = this.setResidenceUsers();
      this.qtDependents = residence.qtDependents || 0;
      this.documents = (residence.documents || []).map(f => new File(f));
      this.extensionLine = residence.extensionLine || '';
      this.params = residence.params || null;
      this.externalId = residence.externalId || null;
      this.obs = residence.obs || '';
      this.emergencyContacts = residence.emergencyContacts || [];
      this.rented = residence.rented || false;
      this.contract =
        {
          startDate: residence.contract?.startDate || undefined,
          endDate: residence.contract?.endDate || undefined,
          status: residence.contract?.status || ''
        } || undefined;
      this.lessee = residence.lessee;
    }
  }

  setResidenceUsers() {
    let residenceUsers = [].concat(this.users);
    if (this.voter && !residenceUsers.find(u => u.id == this.voter.id)) {
      residenceUsers = residenceUsers.concat([this.voter]);
    }
    return residenceUsers;
  }

  hasUsers() {
    return this.residenceUsers.length > 0;
  }

  isHouse() {
    return this.type == Residence.TYPES.HOUSE;
  }

  isApartment() {
    return this.type == Residence.TYPES.APARTMENT;
  }

  isBusiness() {
    return this.type == Residence.TYPES.BUSINESS;
  }

  isOther() {
    return this.type == Residence.TYPES.OTHER;
  }

  equals(residence: any) {
    return this._id == residence['_id'] || this._id == residence;
  }

  isRequester(userId: string) {
    if (this.requesters.find(requester => requester._id == userId)) {
      return true;
    } else {
      return false;
    }
  }

  isOwner(userId: string) {
    return this.voter && this.voter._id == userId;
  }

  isUser(userId: string) {
    if (this.users.find(user => user._id == userId)) {
      return true;
    } else {
      return false;
    }
  }

  isEmpty() {
    return !this.voter && (!this.users || !this.users.length);
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get condo(): any {
    return this._condo;
  }

  set condo(value: any) {
    this._condo = value;
  }

  get identification(): string {
    return this._identification;
  }

  set identification(value: string) {
    this._identification = value;
  }

  get users(): any {
    return this._users;
  }

  set users(value: any) {
    this._users = value;
  }

  get requesters(): any {
    return this._requesters;
  }

  set requesters(value: any) {
    this._requesters = value;
  }

  get voter(): any {
    return this._voter;
  }

  set voter(value: any) {
    this._voter = value;
  }

  get createdBy(): any {
    return this._createdBy;
  }

  set createdBy(value: any) {
    this._createdBy = value;
  }

  get updatedBy(): any {
    return this._updatedBy;
  }

  set updatedBy(value: any) {
    this._updatedBy = value;
  }

  get residenceUsers(): Array<User> {
    return this._residenceUsers;
  }

  set residenceUsers(value: Array<User>) {
    this._residenceUsers = value;
  }

  get qtPeople(): number {
    return this.residenceUsers.length + this.qtDependents;
  }
}
