import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Key, KEY_STATUS } from '@api/model/key';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { ModalKeyHistory } from '../modal-key-history/modal-key-history';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { timeout } from 'rxjs/operators';
import { KeyService } from '@api/service/key.service';

@Component({
  selector: 'app-key-card',
  templateUrl: './key-card.component.html',
  styleUrls: ['./key-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyCardComponent implements OnChanges {
  STATUS = KEY_STATUS;

  @Input()
  key: Key;

  @Input()
  condo: Condo;

  @Input()
  user: User;

  @Output()
  cardClick: EventEmitter<Key> = new EventEmitter();

  @Output()
  updateClick: EventEmitter<Key> = new EventEmitter();

  @Output()
  keyDeleted: EventEmitter<Key> = new EventEmitter();

  isAdmin;

  data: { person: string; residence: string; name: string; code: string; status: KEY_STATUS; borrowDate: string; image: string };

  constructor(private toastr: ToastrService, private keyServicce: KeyService, private modalService: BsModalService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.isAdmin = changes.user.currentValue.isAdmin() || changes.user.currentValue.isOwner();
    const k = changes.key.currentValue;
    this.data = {
      person: k.person || ' - ',
      name: k.name,
      code: k.code,
      residence: k.residence && k.residence.identification ? k.residence.identification : ' - ',
      status: k.status,
      borrowDate: k.borrowDate ? moment(k.borrowDate).format('DD/MM/YY - HH:mm') : ' - ',
      image: k.status === KEY_STATUS.AVAILABLE ? 'assets/img/key_green.png' : 'assets/img/key_red.png'
    };
  }

  showKeyHistory(key) {
    const initialState = {
      condo: this.condo,
      key
    };
    this.modalService.show(ModalKeyHistory, { initialState, class: 'modal-lg' });
  }

  deleteKey(key: Key) {
    swal({
      type: 'question',
      title: 'Excluir chave',
      text: `Deseja realmente excluir a chave ${key.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.keyServicce
          .deleteKey(this.condo._id, key._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível excluir esta chave, tente novamente.');
          });
      }
    })
      .then(() => {
        this.keyDeleted.emit(key);
      })
      .catch(() => {});
  }
}
