/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { ReservationService } from '@api/service/reservation.service';
import swal from 'sweetalert2';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { timeout } from 'rxjs/operators';
import { ModalLocalDescriptionComponent } from '../local.description.modal/local.description.modal';
import { Local, LocalExtraFee } from '@api/model/local';
import { ModalLocalTermsComponent } from '../local.terms.modal/local.terms.modal';
import { Error } from '@api/model/error/error';
import { UntypedFormControl } from '@angular/forms';
import { createLinkToShareOccurrence } from '@api/utils';
import { ModalShareLinkComponent } from '../../../components/modal-share-link/modal-share-link';
import { ThemeService } from '../../../theme';
import { SessionService } from '@api/service/session.service';
import { OtherLocalModalComponent } from '../other.local.modal/other.local.modal';
import { LocalGroup } from '@api/model/local-group';
import { Residence } from '@api/model/interface/residence';
import { lastValueFrom } from 'rxjs';
import { Reservation } from '@api/model/reservation';
import { ToastrService } from 'ngx-toastr';

interface ExtraFeeOption extends LocalExtraFee {
  selected: boolean;
  localName?: string;
}

type PeriodType = { _id: string; startHour?: string; endHour?: string; label: string };

@Component({
  selector: 'app-modal-create-reservation',
  templateUrl: './create.reservation.modal.html',
  styleUrls: ['./create.reservation.scss']
})
export class ModalCreateReservationComponent {
  @ViewChild('createReservationModal', { static: true }) public createReservationModal: ModalDirective;
  reservationLocal: Local;
  reservationLocals: Local[];
  reservationDate;

  reservationLocalGroup: LocalGroup[];
  allPeriods: Array<{ _id: string; startHour: string; endHour: string }> = [];
  availablePeriods: Array<PeriodType> = [];
  reservedPeriods: Array<PeriodType> = [];
  selectedPeriod: PeriodType = null;
  selectedPeriodToQueue: PeriodType[] = [];
  selectedResidence: UntypedFormControl = new UntypedFormControl(undefined);
  linkedReservations: Local[];
  linkedReservationsIds: string[];
  extraFees: ExtraFeeOption[] = [];
  user: User;
  condo: Condo;
  agreed;
  createNewAccessLiberation: boolean;
  triedToReservate = false;
  isReservating = false;
  observation = '';
  @Output() reservationCreated = new EventEmitter();

  isQueueEnabled = false;

  totalReservationAmount = 0;

  get isJoiningQueue() {
    return !!this.selectedPeriodToQueue.length;
  }

  constructor(
    private sessionService: SessionService,
    private reservationService: ReservationService,
    private themeService: ThemeService,
    private modalService: BsModalService,
    private toastrService: ToastrService
  ) {
    this.user = this.sessionService.userValue;
    this.condo = this.sessionService.condoValue;
    this.reservationLocal = new Local({});
  }

  closeModal(): void {
    this.createReservationModal.hide();
  }

  showModal({
    reservationLocal,
    reserveDate,
    periods,
    reservedPeriods,
    reservationLocals,
    reservationLocalGroup
  }: {
    reservationLocal: Local;
    reserveDate;
    periods: Array<{ _id: string; startHour: string; endHour: string }>;
    reservedPeriods: Array<{ _id: string; startHour: string; endHour: string }>;
    reservationLocals: Local[];
    reservationLocalGroup: LocalGroup[];
  }) {
    this.reservationLocals = reservationLocals?.filter(local => {
      const hasPeriodOnDate = local.periods.some((period: any) => period.weekDay === moment(reserveDate).day());
      const isBlockedOnDate = local.params.blockedDays.some((blockedDay: string) => blockedDay === moment(reserveDate).format('DD/MM'));
      return hasPeriodOnDate && !isBlockedOnDate;
    });
    this.reservationLocalGroup = reservationLocalGroup;
    this.initializeModal(reservationLocal, reserveDate, periods, reservedPeriods);
    this.createReservationModal.show();
  }

  initializeModal(reservationLocal: Local, reserveDate, periods, reservedPeriods) {
    this.linkedReservationsIds = [];
    this.reservationLocal = reservationLocal;
    this.allPeriods = periods;
    this.availablePeriods = periods;
    this.reservedPeriods = reservedPeriods.map(period => ({
      ...period,
      label: `${period.startHour} às ${period.endHour}`
    }));
    this.selectedPeriod = periods.length === 1 ? periods[0] : null;
    this.selectedPeriodToQueue =
      !this.selectedPeriod && !this.availablePeriods.length && reservedPeriods.length === 1 ? [reservedPeriods[0]] : [];

    this.isQueueEnabled = reservationLocal.params?.isQueueEnabled;

    let defaultResidence: Residence;
    if (this.user.isUser()) {
      defaultResidence = this.user.getResidences()[0];
    }
    this.selectedResidence.setValue(defaultResidence || null);
    this.reservationDate = reserveDate;
    this.agreed = false;
    this.createNewAccessLiberation = false;
    this.triedToReservate = false;
    this.observation = '';

    this.totalReservationAmount = this.reservationLocal.value;

    if (this.reservationLocal?.extraFees?.length) {
      this.extraFees = this.reservationLocal.extraFees.map(fee => ({ ...fee, selected: false }));
    }
  }

  createReservation() {
    if (!this.agreed || !this.selectedPeriod || !this.selectedResidence?.value) {
      this.triedToReservate = true;
      return;
    }

    const reservation = {
      condo: this.condo._id,
      reservationLocal: this.reservationLocal._id,
      residence: this.selectedResidence?.value?._id,
      date: moment(this.reservationDate).startOf('day').toDate(),
      period: this.selectedPeriod._id,
      observation: this.observation,
      extraFees: this.extraFees.filter(fee => fee.selected),
      createNewAccessLiberation: this.createNewAccessLiberation,
      otherReservationLocals: this.linkedReservationsIds
    };

    this.isReservating = true;
    this.reservationService
      .createReservation(this.condo._id, reservation)
      .pipe(timeout(10000))
      .subscribe({
        next: res => {
          this.reservationCreated.emit(reservation);
          this.isReservating = false;
          this.closeModal();

          if (res.occurrence && res.occurrence?.status === 'OPENED') {
            const link = createLinkToShareOccurrence(this.condo._id, res.occurrence._id, this.themeService.activeTheme);
            this.modalService.show(ModalShareLinkComponent, {
              initialState: { link },
              ignoreBackdropClick: true,
              class: 'modal-lg'
            });
          }
        },
        error: (err: Error) => {
          this.isReservating = false;
          swal({
            type: 'error',
            title: `${err.messageTitle || 'Ops...'} `,
            text: `${err.originalError.message || 'Não foi possível efetuar sua reserva, tente novamente'}`
          }).catch(() => {});
        }
      });
  }

  createReservationInQueue() {
    if (!this.agreed || !this.selectedPeriodToQueue.length || !this.selectedResidence?.value) {
      this.triedToReservate = true;
      this.toastrService.error('Todos os campos devem ser preenchidos', 'Um error ocorreu!');
      return;
    }

    const reservationInQueue = {
      condo: this.condo._id,
      reservationLocal: this.reservationLocal._id,
      residence: this.selectedResidence?.value?._id,
      date: moment(this.reservationDate).startOf('day').toDate(),
      period: this.selectedPeriodToQueue[0],
      observation: this.observation,
      extraFees: this.extraFees.filter(fee => fee.selected),
      createNewAccessLiberation: this.createNewAccessLiberation
    };

    this.isReservating = true;
    swal({
      type: 'question',
      title: 'Entrar na fila de espera?',
      text: 'Essa ação não garante a reserva do local, apenas permitirá sua entrada na fila de espera. Deseja continuar mesmo assim?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () =>
        lastValueFrom(this.reservationService.createReservationInQueue(this.condo._id, reservationInQueue).pipe(timeout(10000))).catch(
          err => {
            const reason = err.originalError.message || 'Não foi possível entrar na fila de espera, tente novamente...';
            return Promise.reject(reason);
          }
        )
    })
      .then((reservation: Reservation) => {
        swal({
          type: 'success',
          title: `Sua posição na fila: ${reservation.positionInQueue}`,
          text: 'Para cancelar esta reserva na fila de espera, entre em contato com a administração.'
        });
        this.isReservating = false;
        this.closeModal();
      })
      .catch(() => {
        this.isReservating = false;
      });
  }

  handleToggleExtraFeeSelection(index: number): void {
    this.extraFees[index].selected = !this.extraFees[index].selected;
    this.calcTotalReservationAmount();
  }

  calcTotalReservationAmount(): void {
    const extraFeesTotal = this.extraFees.reduce((acc, cur) => {
      if (cur.selected) {
        acc += cur.value;
      }

      return acc;
    }, 0);

    this.totalReservationAmount = this.reservationLocal.value + extraFeesTotal;
  }

  showLocalDescription(local: Local) {
    const initialState = {
      reservationLocal: local
    };
    this.modalService.show(ModalLocalDescriptionComponent, {
      initialState,
      class: 'modal-md',
      ignoreBackdropClick: true
    });
  }

  showLocalTerms(local: Local) {
    const initialState = {
      reservationLocal: local
    };
    this.modalService.show(ModalLocalTermsComponent, {
      initialState,
      class: 'modal-md',
      ignoreBackdropClick: true
    });
  }

  linkedReservationsSelected(locals: string[]) {
    const limitOfMultipleReservations = this.reservationLocalGroup[0].params.limitOfMultipleReservations;
    if (limitOfMultipleReservations !== 0 && locals.length + 1 > limitOfMultipleReservations) {
      this.linkedReservationsIds = [].concat(this.linkedReservationsIds);
      swal({
        type: 'warning',
        title: `Número máximo de locais atingido`,
        text: `Você pode selecionar até ${limitOfMultipleReservations} locais simultaneamente, contando o que está sendo feita a reserva`
      }).catch(() => {});
    } else {
      this.mergeLocalsExtraFees(locals);
    }
  }

  mergeLocalsExtraFees(locals: string[]) {
    this.linkedReservations = this.reservationLocals.filter(local => locals.includes(local._id));
    this.linkedReservationsIds = locals;

    this.extraFees = this.reservationLocal.extraFees.map(fee => ({ ...fee, selected: false })) || [];
    if (locals.length) {
      this.linkedReservations.forEach(local => {
        this.extraFees.push({ name: local.name, value: local.value, selected: true, localName: 'Local da reserva' });
        if (local.extraFees.length) {
          this.extraFees.push(...local.extraFees.map(fee => ({ ...fee, selected: false, localName: local.name })));
        }
      });
    }
    this.totalReservationAmount = this.reservationLocal.value;
    this.calcTotalReservationAmount();
  }

  openLocalsInfoModal() {
    const initialState = {
      reservationLocals: this.linkedReservations,
      callbacks: {
        success: ({ local, terms, description }) => {
          if (terms) {
            this.showLocalTerms(local);
          } else if (description) {
            this.showLocalDescription(local);
          }
        }
      }
    };
    this.modalService.show(OtherLocalModalComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }
}
