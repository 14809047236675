/**
 * Created by Rafael on 17/02/2016.
 */
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
// Services
import { Residence } from '../../../api/model/interface/residence';
import { UtilService } from '../../../services/util.service';
import { AccessService } from '../../../api/service/access.service';
import { Access } from '../../../api/model/access';
import { Condo } from '../../../api/model/condo';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '../../../api/model/error/error.builder';

@Component({
  selector: 'modal-residence-access-log',
  templateUrl: 'residence.access.log.modal.html'
})
export class ModalResidenceAccessLogComponent implements OnInit {
  @ViewChild('residenceAccessLogModal', { static: true }) residenceAccessLogModal: ModalDirective;
  @ViewChild('visitorTemplate', { static: true }) visitorTemplate: TemplateRef<any>;
  @ViewChild('accessTypeTemplate', { static: true }) accessTypeTemplate: TemplateRef<any>;
  @ViewChild('typeTemplate', { static: true }) typeTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;

  @Input() residence: Residence;

  @Input() condo: Condo;

  public accesses: Array<Access> = [];
  public filteredAccesses: Array<Access> = [];
  public columns = [];
  public messages = {
    emptyMessage: 'Sem dados para exibir',
    totalMessage: 'acessos'
  };

  private ACCESS_STATUS = Access.STATUS;
  private ACCESS_STATUS_LABEL = Access.STATUS_LABEL;

  startDate: Date = moment().subtract(1, 'month').toDate();
  endDate: Date = moment().add(1, 'month').toDate();
  maxDate = moment().toDate();

  loadingStatus;
  LOADING = 'loading';
  SUCCESS = 'success';
  ERROR = 'error';

  searchTerm: UntypedFormControl;

  constructor(private accessService: AccessService, private utilService: UtilService) {
    this.searchTerm = new UntypedFormControl();

    this.searchTerm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      let val = term.trim();
      val = this.utilService.removeAccents(val);
      val = val.toLowerCase();
      const temp = this.accesses.filter(access => {
        let name = access.contact ? access.contact.fullName.toLowerCase() : '';
        name = this.utilService.removeAccents(name);
        let status = access.statusLabel ? access.statusLabel.toLowerCase() : '';
        status = this.utilService.removeAccents(status);
        let accessType = access.accessTypeLabel ? access.accessTypeLabel.toLowerCase() : '';
        accessType = this.utilService.removeAccents(accessType);
        let visitorType = access.typeLabel ? access.typeLabel.toLowerCase() : '';
        visitorType = this.utilService.removeAccents(visitorType);
        return (
          !val ||
          name.indexOf(val) !== -1 ||
          status.indexOf(val) !== -1 ||
          accessType.indexOf(val) !== -1 ||
          visitorType.indexOf(val) !== -1
        );
      });
      this.filteredAccesses = temp;
    });
  }

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'contact',
        comparator: (c1, c2) => {
          return (c1.firstName || '').localeCompare(c2.firstName || '');
        },
        name: 'Visitante',
        width: 320,
        maxWidth: 320,
        cellTemplate: this.visitorTemplate
      },
      {
        prop: 'accessDate',
        name: 'Data e hora',
        width: 210,
        maxWidth: 210,
        cellTemplate: this.dateTemplate
      },
      {
        prop: 'accessTypeLabel',
        name: 'Tipo',
        width: 130,
        minWidth: 130,
        maxWidth: 130,
        cellTemplate: this.accessTypeTemplate
      },
      {
        prop: 'typeLabel',
        name: 'Acesso de',
        width: 130,
        maxWidth: 130,
        cellTemplate: this.typeTemplate
      },
      {
        prop: 'status',
        name: 'Situação',
        width: 130,
        maxWidth: 130,
        cellTemplate: this.statusTemplate
      }
    ];
  }

  getData() {
    this.loadingStatus = this.LOADING;
    this.accessService
      .getCondoAccesses(this.condo.id, this.getQueryString())
      .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        accesses => {
          this.accesses = accesses;
          this.accesses.sort((a1, a2) => {
            const date1 = new Date(a1.inDate || a1.outDate || new Date());
            const date2 = new Date(a2.inDate || a2.outDate || new Date());
            return date2.getTime() - date1.getTime();
          });
          this.filteredAccesses = this.accesses;
          this.loadingStatus = this.SUCCESS;
        },
        err => {
          console.log(err);
          this.loadingStatus = this.ERROR;
        }
      );
  }

  getQueryString() {
    const qs: any = [];
    qs[qs.length] = `residence=${this.residence.id}`;
    qs[qs.length] = '$populate[0][path]=condoContact';
    qs[qs.length] = '$populate[0][select]=firstName lastName picture';
    qs[qs.length] = '$populate[0][populate][path]=picture';
    qs[qs.length] = '$populate[0][populate][select]=url thumbnail';

    qs[qs.length] = '$populate[1][path]=user';
    qs[qs.length] = '$populate[1][select]=firstName lastName picture';
    qs[qs.length] = '$populate[1][populate][path]=picture';
    qs[qs.length] = '$populate[1][populate][select]=url thumbnail';

    qs[qs.length] = '$populate[2][path]=userContact';
    qs[qs.length] = '$populate[2][select]=firstName lastName picture';
    qs[qs.length] = '$populate[2][populate][path]=picture';
    qs[qs.length] = '$populate[2][populate][select]=url thumbnail';

    qs[qs.length] = `latestAccess[$gte]=${moment(this.startDate).startOf('day').toISOString()}`;
    qs[qs.length] = `latestAccess[$lte]=${moment(this.endDate).endOf('day').toISOString()}`;

    return '?' + qs.join('&');
  }

  closeModal(): void {
    this.residenceAccessLogModal.hide();
  }

  showModal() {
    this.accesses = new Array<Access>();
    this.getData();
    this.residenceAccessLogModal.show();
  }
}
