import { Pipe, PipeTransform } from '@angular/core';
import { Condo } from '@api/model/condo';
import { capitalize } from '@api/util/util';
import { UtilService } from 'app/services/util.service';

@Pipe({ name: 'customLabelPipe' })
export class CondoCustomLabelPipe implements PipeTransform {
  condo: Condo;

  constructor(private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
  }

  transform(type: string = 'resident', plural: boolean = false, capitalizeLabel: boolean = false) {
    try {
      let label = '';
      switch (type) {
        case 'voter':
          if (plural) {
            label = this.condo?.customLabels?.voter?.plural || 'proprietários';
          } else {
            label = this.condo?.customLabels?.voter?.singular || 'proprietário';
          }
          break;
        case 'dependent':
          const isBusinessCondo = this.condo?.isBusinessCondo() || false;
          let defaultLabelSingular = 'dependente';
          let defaultLabelPlural = 'dependentes';
          if (isBusinessCondo) {
            defaultLabelSingular = 'funcionário';
            defaultLabelPlural = 'funcionários';
          }

          if (plural) {
            label = this.condo?.customLabels?.dependent?.plural || defaultLabelPlural;
          } else {
            label = this.condo?.customLabels?.dependent?.singular || defaultLabelSingular;
          }
          break;
        case 'resident':
          if (plural) {
            label = this.condo?.customLabels?.resident?.plural || 'condôminos';
          } else {
            label = this.condo?.customLabels?.resident?.singular || 'condômino';
          }
          break;
        case 'residence':
          if (plural) {
            label = this.condo?.customLabels?.residence?.plural || 'unidades';
          } else {
            label = this.condo?.customLabels?.residence?.singular || 'unidade';
          }
          break;
        case 'janitor':
          if (plural) {
            label = this.condo?.customLabels?.janitor?.plural || 'zeladores';
          } else {
            label = this.condo?.customLabels?.janitor?.singular || 'zelador';
          }
          break;
        case 'gatekeeper':
          if (plural) {
            label = this.condo?.customLabels?.gatekeeper?.plural || 'porteiros';
          } else {
            label = this.condo?.customLabels?.gatekeeper?.singular || 'porteiro';
          }
          break;
        case 'admin':
          if (plural) {
            label = this.condo?.customLabels?.admin?.plural || 'administradores';
          } else {
            label = this.condo?.customLabels?.admin?.singular || 'administrador';
          }
          break;
        case 'owner':
          if (plural) {
            label = this.condo?.customLabels?.owner?.plural || 'síndicos';
          } else {
            label = this.condo?.customLabels?.owner?.singular || 'síndico';
          }
          break;
        case 'condo':
          if (plural) {
            label = this.condo?.customLabels?.condo?.plural || 'condomìnios';
          } else {
            label = this.condo?.customLabels?.condo?.singular || 'condomìnio';
          }
          break;
        case 'block':
          if (plural) {
            label = this.condo?.customLabels?.block?.plural || 'blocos';
          } else {
            label = this.condo?.customLabels?.block?.singular || 'bloco';
          }
          break;
        case 'visitor':
          if (plural) {
            label = this.condo?.customLabels?.visitor?.plural || 'visitantes';
          } else {
            label = this.condo?.customLabels?.visitor?.singular || 'visitante';
          }
          break;
        default:
          throw new Error('invalid Type');
      }
      if (capitalizeLabel) {
        return capitalize(label);
      } else {
        return label;
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  }
}
