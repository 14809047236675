import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-multi-select-renderer',
  templateUrl: './multi-select-editor.component.html',
  styleUrls: ['./multi-select-editor.component.scss']
})
export class MultiSelectEditorComponent implements ICellEditorAngularComp {
  private params;
  private value = [];

  rowData: any[];
  label: string;
  customStyles;

  private currentData: any;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    const { value, rowData, label, filterPredicate, data, customStyles } = this.params;
    this.value = value || [];
    this.currentData = data || {};
    this.customStyles = customStyles || {};
    if (filterPredicate) {
      this.rowData = (rowData && rowData.filter(r => filterPredicate(r, this.currentData, this.value))) || [];
    } else {
      this.rowData = (rowData && rowData) || [];
    }
    this.label = label || 'name';
  }

  getValue(): any {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }

  onChange(event) {
    this.setValue(event.target.value);
    this.params.stopEditing();
  }

  private setValue(value) {
    const item: any = this.rowData.find(({ _id }) => _id === value);
    const index = this.value && this.value.findIndex(v => v._id === item._id);
    if (index > -1) {
      this.value.splice(index, 1);
    } else {
      this.value.push(item);
    }
  }
}
