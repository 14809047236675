import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Condo } from '@api/model/condo';
import { HttpService } from '../services/http.service';
import { noop } from 'rxjs';
import { LogViewedDataService } from '@api/service/log-viewed-data.service';
import { UtilService } from 'app/services/util.service';
import { SessionService } from '@api/service/session.service';

@Directive({
  selector: '[logViewedData]'
})
export class LogViewedDataDirective implements OnInit, OnDestroy {
  @Input()
  documentId: string;

  @Input()
  condo: Condo;

  @Input()
  model: string;

  @Input()
  trigger: 'onViewed' | 'manual' = 'onViewed';

  private observer: IntersectionObserver | null;
  private elementRef: ElementRef;

  constructor(
    elementRef: ElementRef,
    private http: HttpService,
    private logViewedDataService: LogViewedDataService,
    private sessionService: SessionService
  ) {
    if (!!!this.condo) this.condo = this.sessionService.condoValue;
    this.elementRef = elementRef;
    this.observer = null;
  }

  public ngOnInit(): void {
    if (this.trigger === 'onViewed') {
      this.initializeObserver();
    }
  }

  manualActivate() {
    if (this.trigger === 'manual') {
      this.logViewedDataService.create(this.condo._id, this.model, this.documentId).subscribe(noop);
    }
  }

  initializeObserver() {
    this.observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          this.observer.unobserve(this.elementRef.nativeElement);
          this.logViewedDataService.create(this.condo._id, this.model, this.documentId).subscribe(noop);
        }
      },
      {
        threshold: 1
      }
    );
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
    this.observer = null;
  }
}
