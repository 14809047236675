import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as qs from 'qs';
import { BuildExtension, Extension, ExtensionOnlyWithId } from '@api/model/extension';

export interface GetExtensionsResponse {
  count: number;
  extensions: Extension[];
}

export type CreateExtensionDTO = Pick<Extension, 'number' | 'name' | 'type'> & {
  sipAccount: string;
  virtualKeys?: string[];
};

@Injectable({ providedIn: 'root' })
export class ExtensionsService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;
  }

  getExtensions(condoId: string, params: EcondosQuery): Observable<GetExtensionsResponse> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/extensions`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        extensions: res.body.map(extension => BuildExtension(extension))
      }))
    );
  }

  createExtension(condoId: string, extension: CreateExtensionDTO) {
    return this.http.post(`${this.endPoint}${condoId}/extensions`, extension).pipe(map((response: ExtensionOnlyWithId) => response));
  }

  updateExtension(condoId: string, extensionId: string, extension: CreateExtensionDTO) {
    return this.http.put(`${this.endPoint}${condoId}/extensions/${extensionId}`, extension);
  }

  deleteExtension(condoId: string, extensionId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/extensions/${extensionId}`);
  }
}
