import { HttpService } from '../../../services/http.service';
import { ConstantService } from '../../../services/constant.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actuator } from '../../model/hardware/actuator';
import { HardwareSocketService } from '../../../services/hardware-socket.service';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { ControlIdService } from '@api/service/hardware/control-id.service';
import { UtechService } from '@api/service/hardware/utech.service';
import { IntelbrasIncontrolService } from './intelbras-incontrol.service';
import { AlphaDigiService } from '@api/service/hardware/alphadigi.service';
import { IntelbrasStandAloneService } from '@api/service/hardware/intelbras-stand-alone.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { ZktecoService } from '@api/service/hardware/zkteco.service';
import { GarenService } from './garen.service';
import { SdkService } from '@api/service/hardware/sdk.service';
import { IntelbrasLprService } from '@api/service/hardware/intelbras-lpr.service';
import { Deleted } from '@api/model/deleted';

type DeletedActuator = Actuator & Deleted;

@Injectable({ providedIn: 'root' })
export class ActuatorsRouteService {
  private endPoint;

  constructor(
    private http: HttpService,
    private angularHttp: HttpClient,
    private hardwareSocketService: HardwareSocketService,
    private hikvisionService: HikvisionService,
    private controlIdService: ControlIdService,
    private utechService: UtechService,
    private intelbrasIncontrolService: IntelbrasIncontrolService,
    private intelbrasService: IntelbrasStandAloneService,
    private intelbrasLprService: IntelbrasLprService,
    private zktecoService: ZktecoService,
    private sdkService: SdkService,
    private garenService: GarenService,
    private alphaDigiService: AlphaDigiService,
    private constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  get(
    condoId: string,
    params: Array<{ [actuator: string]: string }> = []
  ): Observable<{
    count: number;
    actuators: Actuator[];
  }> {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/actuators-route`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            actuators: res.body
          };
        })
      );
  }

  getActuatorsRoute(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; actuators: Actuator[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/actuators-route`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          actuators: res.body
        }))
      );
  }

  create(condoId: string, actuator: Actuator) {
    return this.http
      .post(`${this.endPoint}${condoId}/actuators-route`, actuator)
      .pipe(map((res: { _id: string }) => ({ ...actuator, _id: res._id })));
  }

  update(condoId: string, actuatorsRouteId: string, actuator: Actuator) {
    return this.http.put(`${this.endPoint}${condoId}/actuators-route/${actuatorsRouteId}`, actuator);
  }

  delete(condoId: string, actuatorsRouteId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/actuators-route/${actuatorsRouteId}`);
  }

  getById(condoId: string, actuatorsRouteId: string): Observable<Actuator> {
    return this.http.get(`${this.endPoint}${condoId}/actuators-route/${actuatorsRouteId}`) as Observable<Actuator>;
  }
}
