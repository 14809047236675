import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { CondoContact } from '@api/model/contact/condo.contact';
import { EcondosQuery } from '@api/model/query';
import { CondoContactServiceV2 } from '@api/serviceV2/condo.contact.service';
import { CondoContactServiceV3 } from '@api/serviceV3/condo.contact.service';
import { merge, noop, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-visitor-picker',
  templateUrl: 'visitor-picker.html',
  styleUrls: ['./visitor-picker.scss']
})
export class VisitorPickerComponent implements OnDestroy, AfterViewInit {
  @Input() placeholder = 'Busque aqui';
  @Input() condo: Condo;
  @Input() size: 'large' | 'default' | 'small';
  @Input() canCreateContact = true;
  @Input() showSearchButtonText = true;
  @Input() searchButtonColorClass = 'success';
  @Input() showAsInputGroup = false;
  @Input() clearInputAfterSelect = false;
  @Input() styles: any = {};
  @Input() focusOnInit = false;
  @Output() onContactSelect: EventEmitter<CondoContact> = new EventEmitter<CondoContact>();
  @Output() onNewContactClick: EventEmitter<any> = new EventEmitter();
  @Output() onClearClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('contactList') contactList;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  searchText: UntypedFormControl = new UntypedFormControl('');
  unsubscribe$ = new Subject();

  STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  };
  searchStatus;

  contacts: CondoContact[] = [];

  _search$ = new Subject();
  search$ = merge(
    this._search$.asObservable().pipe(
      throttleTime(500),
      map(value => ({ value, source: 'Enter' }))
    ),
    this.searchText.valueChanges.pipe(
      tap(value => {
        this.contacts = [];
        this.searchStatus = null;
      }),
      debounceTime(2000),
      map(value => ({ value, source: 'Search' }))
    )
  );

  activeIndex = 0;

  selectedContact: CondoContact = null;

  constructor(private condoContactServiceV2: CondoContactServiceV2, private condoContactServiceV3: CondoContactServiceV3) {
    this.search$
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((previous, current) => current.source === 'Search' && previous.value === current.value),
        map(value => value.value),
        filter(value => !!value),
        switchMap(token => {
          this.contacts = [];
          this.searchStatus = this.STATUS.LOADING;

          const query: EcondosQuery = {
            $select:
              'firstName lastName banned banReason bannedAt type company service emails birthDate phones specialNeeds specialNeedsDetails externalId obs',
            $populate: [
              { path: 'picture', select: 'thumbnail url' },
              {
                path: 'ids',
                select: 'typeLabel number',
                populate: [{ path: 'pictures', select: 'url thumbnail type name format' }]
              },
              {
                path: 'condoVehicles',
                select: 'plate model type brand color pictures',
                populate: [{ path: 'pictures', select: 'url thumbnail type name format' }]
              },
              { path: 'bannedBy', select: 'firstName lastName' }
            ],
            $sort: 'firstName lastName',
            $limit: 50,
            deleted: false
          };

          const errorCallback = (err: any) => {
            this.searchStatus = this.STATUS.ERROR;
            return of({ contacts: [], error: true });
          };

          return this.condoContactServiceV3.searchByToken(this.condo._id, token, query).pipe(
            map(v => ({ ...v, error: false })),
            catchError(errorCallback)
          );
        }),
        tap(({ contacts, error }) => {
          this.contacts = contacts;
          if (error) {
            this.searchStatus = this.STATUS.ERROR;
          } else {
            this.searchStatus = this.STATUS.SUCCESS;
          }
        })
      )
      .subscribe(noop);
  }

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      this.input.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  selectContact(contact: CondoContact) {
    this.searchStatus = null;
    this.searchText.setValue(contact.fullName, { emitEvent: false });
    this.contacts = [];
    this.selectedContact = contact;
    this.onContactSelect.emit(contact);

    if (this.clearInputAfterSelect) {
      this.resetValue();
    }
  }

  pressEnter(event) {
    if (this.contacts.length) {
      this.selectContact(this.contacts[this.activeIndex]);
    } else {
      this._search$.next(this.searchText.value);
    }
  }

  selectIndex(index) {
    this.activeIndex = index;
  }

  incrementIndex(event) {
    this.activeIndex < this.contacts.length - 1 ? this.activeIndex++ : (this.activeIndex = 0);
    this.contactList?.nativeElement?.children[this.activeIndex].scrollIntoViewIfNeeded();
  }

  decrementIndex(event) {
    this.activeIndex > 0 ? this.activeIndex-- : (this.activeIndex = this.contacts.length - 1);
    this.contactList?.nativeElement?.children[this.activeIndex].scrollIntoViewIfNeeded();
  }

  onResetValue() {
    this.resetValue();
    this.onClearClick.emit();
    this.input.nativeElement.focus();
  }

  resetValue() {
    this.searchText.setValue('');
    this.selectedContact = null;
  }
}
