import { CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'base-stepper',
  templateUrl: './base-stepper.html',
  styleUrls: ['./base-stepper.scss'],
  providers: [{ provide: CdkStepper, useExisting: BaseStepper }]
})
export class BaseStepper extends CdkStepper implements OnChanges {
  @Input()
  globals: any;

  @Input()
  index: number;

  @Output()
  changePage = new EventEmitter<number>();

  @Output()
  closeModal = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.index?.currentValue) {
      this.selectStepByIndex(changes.index?.currentValue);
    }
  }

  selectStepByIndex(index: number): void {
    if (this.globals.user) {
      this.selectedIndex = index;
      this.changePage.emit(index);
    }
  }

  forward(page) {
    if (page < this.globals.pages?.DEVICE) {
      this.selectStepByIndex(++page);
    } else {
      this.changePage.emit(++page);
    }
  }

  prev(page) {
    if (page > this.globals.pages?.USER) {
      this.selectStepByIndex(page - 1);
    }
  }
}
