import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'step-device',
  templateUrl: 'step-device.html',
  styleUrls: ['step-device.scss']
})
export class StepDeviceComponent implements OnChanges {
  @Input()
  globals: any;

  @Input()
  index: number;

  @Output()
  goToResidences: EventEmitter<number> = new EventEmitter<number>();

  user: User;
  condo: Condo;
  tabs: { residence: Residence }[] = [];

  constructor(private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.index.currentValue === this.globals.pages?.DEVICE) {
      const residences = this.globals.user?.getResidences() || [];
      if (residences.length) {
        this.filterResidences(residences);
      } else {
        this.tabs = [];
      }
    }
  }

  filterResidences(residences) {
    const residencesKey = residences.reduce((acc, curr) => {
      acc[curr._id] = { residence: curr };
      return acc;
    }, {});
    this.tabs = Object.keys(residencesKey).map(key => residencesKey[key]);
  }
}
