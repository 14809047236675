import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phonePipe' })
export class PhonePipe implements PipeTransform {
  transform(phone: string = '') {
    try {
      phone = phone.toString();
      phone = phone.replace(/[^*\d]/g, ''); //Remove tudo o que não é dígito exceto o asterisco
      if (phone.substr(0, 4) === '0800') {
        phone = phone.replace(/^(\d{4})(\d{3})(\d{4})/g, '$1 $2 $3');
      } else {
        phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2'); // Coloca parênteses em volta dos dois primeiros dígitos
        phone = phone.replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
      }
      return phone;
    } catch (e) {
      console.log(e);
      return '';
    }
  }
}
