import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalGateDeliveryConfigComponent } from './modal-gate-delivery-config.component';
import { ThemeModule } from '../../../theme';
import { PipesModule } from '../../../../app/pipe/pipes.module';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, PipesModule, ModalModule, ThemeModule],
  declarations: [ModalGateDeliveryConfigComponent]
})
export class ModalGateDeliveryConfigModule {}
