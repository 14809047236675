import { EventEmitter, Output, Input, Directive } from '@angular/core';

import { Occurrence } from '@api/model/interface/occurrence';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

@Directive()
export abstract class OccurrenceCreator {
  @Input()
  protected user;
  @Input()
  protected condo;

  @Output()
  onOccurrenceCreated = new EventEmitter();

  @Output()
  onOccurrenceUpdated = new EventEmitter();

  public occurrenceForm: UntypedFormGroup;
  public title: AbstractControl;
  public description: AbstractControl;
  public groups: AbstractControl;

  public showing = false;
  public isEditingOccurrence = false;
  public isSubmiting = false;
  public isUploading = false;
  public editingOccurrence;

  public attachments = [];

  constructor(protected formBuilder: UntypedFormBuilder) {
    this.initializeForm();
  }

  abstract createOccurrence();

  abstract updateOccurrence();

  abstract uploadFile();

  initializeForm() {
    this.occurrenceForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      description: ['', Validators.compose([Validators.minLength(3)])],
      groups: [null]
    });
    this.title = this.occurrenceForm.controls['title'];
    this.description = this.occurrenceForm.controls['description'];
    this.groups = this.occurrenceForm.controls['groups'];
    this.attachments = [];
  }

  hideOccurrenceCreator() {
    if (this.isEditingOccurrence) {
      this.cancelEditing();
    } else {
      this.showing = false;
    }
  }

  editOccurrence(occurrence: Occurrence) {
    this.initializeForm();
    this.title.setValue(occurrence.title);
    this.description.setValue(occurrence.descriptionHtml || occurrence.description);
    this.groups.setValue(occurrence?.groups?._id || occurrence?.groups);
    this.attachments = [].concat(occurrence.pictures);
    this.showing = true;
    this.isEditingOccurrence = true;
    this.editingOccurrence = occurrence;
    // Scroll to top
    document.body.scrollTop = document.documentElement.scrollTop = 100;
  }

  cancelEditing() {
    this.initializeForm();
    this.isEditingOccurrence = false;
    this.editingOccurrence = null;
    this.showing = false;
  }

  show() {
    this.showing = true;
  }

  hide() {
    this.showing = false;
  }
}
