import { Error } from './error';
export class ConnectionError extends Error {
  constructor() {
    super();
    this.messageTitle = 'Conexão não encontrada';
    this.message = 'Verifique se você está conectado á internet e tente novamente.';
    this.name = 'Connection error';
    this.type = Error.TYPES.CONNECTION_ERROR;
    this.originalErrorMessage = 'connection error';
  }
}
