import { Occurrence } from '../interface/occurrence';
import { Contact } from '../interface/contact';
import { ContactBuilder } from '../contact/contact.builder';
import { User } from '../user';
import { File } from '../file';
import { CondoContact } from '../contact/condo.contact';
import { UserContact } from '../contact/user.contact';
import * as moment from 'moment';
import { AccessGroup } from '../hardware/access-group';
import { Condo } from '@api/model/condo';

/**
 * Created by Rafael on 07/12/2016.
 */

export class GateOccurrence extends Occurrence {
  static DELIVERY_TYPE = 'DELIVERY';
  static ALLOW_ACCESS_TYPE = 'ACCESS';
  static NOISE_TYPE = 'NOISE';
  static OTHER_TYPE = 'OTHER';

  // New attributes
  public trackingNumbers: Array<string> = new Array<string>();
  public tags: Array<string> = new Array<string>();
  private _signature: File;
  public receivedBy: string;
  private _userContacts: Array<UserContact> = new Array<UserContact>();
  public condoContacts: Array<CondoContact> = new Array<CondoContact>();
  public subType;
  private _subTypeAttachments: File[];
  public startDate: Date;
  public endDate: Date;
  public obs: string;
  public shouldCall: boolean;
  private _daysAllowed: Array<{ day: string; startTime: string; endTime: string }> = [];
  public accessGroups: AccessGroup[];
  public visitorIdentification?: 'DELIVERYMAN' | 'DRIVER';

  constructor(occurrence?) {
    super(occurrence);

    if (occurrence) {
      this.trackingNumbers = occurrence.trackingNumbers || [];
      this.tags = occurrence.tags || [];
      this._signature = occurrence.signature ? new File(occurrence.signature) : null;
      this.receivedBy = occurrence.receivedBy || '';
      this._userContacts = occurrence.userContacts
        ? occurrence.userContacts.map(contact => ContactBuilder.build(contact))
        : new Array<Contact>();
      this.condoContacts = occurrence.condoContacts
        ? occurrence.condoContacts.map(contact => ContactBuilder.build(contact))
        : new Array<Contact>();
      this.subType = occurrence.subType || '';
      this._subTypeAttachments = occurrence.subTypeAttachments ? occurrence.subTypeAttachments.map(f => new File(f)) : [];
      this.startDate = occurrence.startDate || null;
      this.endDate = occurrence.endDate || null;
      this.obs = occurrence.obs || '';
      this.shouldCall = occurrence.shouldCall !== false ? true : false;
      this._daysAllowed = occurrence.daysAllowed || [];
      this.accessGroups = occurrence.accessGroups || [];
      this.visitorIdentification = occurrence.visitorIdentification || '';
    }
  }

  createBackObject() {
    const occurrence = super.createBackObject();
    if (this.isDeliveryType()) {
      occurrence.trackingNumbers = this.trackingNumbers;
      occurrence.tags = this.tags;
      occurrence.signature = this._signature;
      occurrence.receivedBy = this.receivedBy;
    }
    if (this.isAllowAccessType()) {
      occurrence.startDate = moment(this.startDate).format('YYYY-MM-DD');
      occurrence.endDate = moment(this.endDate).format('YYYY-MM-DD');
      occurrence.shouldCall = this.shouldCall;
      occurrence.daysAllowed = this._daysAllowed;
      occurrence.userContacts = [];
      this._userContacts.forEach(contact => {
        occurrence.userContacts.push(contact.id);
      });
      occurrence.condoContacts = this.condoContacts.map(contact => contact._id);
      occurrence.visitorIdentification = this.visitorIdentification;
    }
    occurrence.subType = this.subType;
    occurrence.subTypeAttachments = this._subTypeAttachments;
    occurrence.accessGroups = this.accessGroups;
    return occurrence;
  }

  getSubType() {
    return this.subType;
  }

  isAllowAccessType() {
    return this.subType == GateOccurrence.ALLOW_ACCESS_TYPE;
  }

  isDeliveryType() {
    return this.subType == GateOccurrence.DELIVERY_TYPE;
  }

  isNoiseType() {
    return this.subType == GateOccurrence.NOISE_TYPE;
  }

  isOtherType() {
    return this.subType == GateOccurrence.OTHER_TYPE;
  }

  get sendTo() {
    const temp = [];
    if (this.viewers.users.length) {
      for (const res of this.viewers.users) {
        temp.push(`${res.firstName} ${res.lastName}`);
      }
    } else if (this.viewers.residences) {
      for (const res of this.viewers.residences) {
        temp.push(res.identification);
      }
    }
    return temp.join(' - ');
  }

  get subTypeLabel() {
    switch (this.subType) {
      case GateOccurrence.DELIVERY_TYPE:
        return 'Recebimento de encomenda';
      case GateOccurrence.ALLOW_ACCESS_TYPE:
        return 'Liberação de acesso';
      case GateOccurrence.NOISE_TYPE:
        return 'Excesso de barulho';
      default:
        return 'Outros';
    }
  }

  // Condômino
  // Other Criador e não fechada
  // Access, Criador, se antes do startDate e ñ fechada
  // Porteiro
  // Delivery ninguém
  // Noise Ninguém
  // Other  Ninguém
  canDelete(user: User = new User()): boolean {
    // Fechada e cancelada ñ pode ser deletada
    if (this.isClosed() || this.isCanceled()) {
      return false;
    }
    if (this.isCreatedByUser()) {
      // Verifica se foi criada por Condômino ou portaria
      if (this.isOtherType()) {
        // Other pode ser deletada se for o criador
        return this.isCreatedBy(user.id);
      }
      if (this.isAllowAccessType()) {
        // Para liberação de acesso a data inicial deve ser menor que a data atual
        return this.isCreatedBy(user.id) && new Date() < this.startDate;
      }
    }
    return false;
  }

  // Condômino
  // Other admim, porteiro e ñ fechada
  // Access Ninguém
  // Portaria
  // Delivery Admin, owner e gatekeeper e não fechada
  // Noise Qualquer admin/porteiro se não fechada
  // Other Qualquer admin/porteiro se não fechada
  canClose(user: User = new User()): boolean {
    // Se fechada ou for de acesso ñ pode ser fechada
    if (this.isClosed() || this.isCanceled() || this.isAllowAccessType() || user.isUser()) {
      return false;
    }
    if (this.isDeliveryType()) {
      return user.isAdmin() || user.isOwner() || user.isGatekeeper(); // Para delivery apenas porteiros, admins e síndicos podem fechar
    }
    if (this.isNoiseType()) {
      return user.isAdmin() || user.isOwner() || user.isGatekeeper(); // Para noise apenas porteiros, admins e síndicos podem fechar
    }
    if (this.isOtherType()) {
      return user.isAdmin() || user.isOwner() || user.isGatekeeper(); // Para noise apenas porteiros, admins e síndicos podem fechar
    }
  }

  // Condômino
  // Other Criador e ñ fechada
  // Access criador e antes da startDate
  // Porteiro
  // Delivery Qualquer admin e gatekeeper se não fechada
  // Noise Ninguém
  // Other Admin e porteiro se não fechada
  canEdit(user: User = new User(), condo?: Condo) {
    if (this.isClosed() || this.isCanceled() || this.isNoiseType()) {
      return false;
    }
    if (this.isOtherType()) {
      if (this.isCreatedByUser()) {
        return this.isCreatedBy(user.id);
      } else {
        return user.isOwner() || user.isGatekeeper() || user.isAdmin();
      }
    }
    if (this.isAllowAccessType()) {
      return (user.isOwner() || user.isAdmin() || this.isCreatedBy(user.id)) && !condo?.isAccessLiberationEditDisabled();
    }
    return false;
  }

  // Condômino
  // Other criador e ñ fechada
  // Access Criador e dentro do range startDate e endDate e ñ fechada
  // Portaria
  // Delivery Qualquer admin e porteiro, desde q ñ fechada
  // Noise Qualquer admin/gatekeeper se ñ fechada
  // Other Qualquer admin/gatekeeper se ñ fechada
  canCancel(user: User = new User()) {
    if (this.isClosed() || this.isCanceled()) {
      return false;
    }
    if (user.isAdmin() || user.isOwner()) {
      return true;
    }
    if (this.isCreatedByUser()) {
      if (this.isOtherType()) {
        return this.isCreatedBy(user._id);
      }
      if (this.isAllowAccessType()) {
        const today = new Date().toISOString();
        const endDate = this.endDate?.toISOString ? this.endDate.toISOString() : this.endDate.toString();
        return this.isCreatedBy(user._id) && endDate > today;
      }
    } else {
      if (user.isAdmin() || user.isOwner() || user.isGatekeeper()) {
        if (this.isDeliveryType() || this.isNoiseType() || this.isOtherType()) {
          return true;
        }
      }
    }
    return false;
  }

  canPrint() {
    return !this.isCanceled() && (this.isDeliveryType() || (this.isOtherType() && !this.isCreatedByUser()));
  }

  get createdByLabel() {
    return `${this.createdBy.firstName} ${this.createdBy.lastName} - ${this.residence ? this.residence.identification : 'Portaria'}`;
  }

  get userContacts(): Array<UserContact> {
    return this._userContacts;
  }

  set userContacts(value: Array<UserContact>) {
    this._userContacts = value;
  }

  get signature(): File {
    return this._signature;
  }

  set daysAllowed(value: Array<{ day: string; startTime: string; endTime: string }>) {
    this._daysAllowed = value;
  }

  get daysAllowed(): Array<{ day: string; startTime: string; endTime: string }> {
    return this._daysAllowed;
  }

  set signature(value: File) {
    this._signature = value;
  }

  get subTypeAttachments(): File[] {
    return this._subTypeAttachments;
  }

  set subTypeAttachments(value: File[]) {
    this._subTypeAttachments = value;
  }
}
