/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ResidenceService } from '../../../../api/service/residence.service';
import { UtilService } from '../../../../services/util.service';
import { ApartmentResidence } from '../../../../api/model/residence/residence.apartment';
import { Residence } from '../../../../api/model/interface/residence';
import { ResidenceBuilder } from '../../../../api/model/residence/residence.builder';
import { User } from '../../../../api/model/user';
import { Condo } from '../../../../api/model/condo';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'modal-residence-picker',
  templateUrl: 'apartment.picker.modal.html'
})
export class ModalResidencePickerComponent {
  @ViewChild('apartmentPickerModal', { static: true }) public apartmentPickerModal: ModalDirective;

  @Output() onResidencesSelect = new EventEmitter();

  @Input() selectedResidences: Array<Residence> = new Array<Residence>();

  @Input() disabledResidences: Array<Residence> = new Array<Residence>();

  @Input() singleSelect = false;

  @Input() shouldReload = false;

  public pickedResidences: Array<Residence> = new Array<Residence>();

  public condo: Condo;
  public user: User;

  public selectedBlock;

  public residencesByBlock;
  public residencesByBlockKeys;

  public numberOfResidences;

  public loadingStatus;
  public LOADING: string = 'loading';
  public SUCCESS: string = 'success';
  public ERROR: string = 'error';

  constructor(private residenceService: ResidenceService, private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();
    this.initializeModal();
  }

  getQueryString() {
    const qs = [];
    qs[qs.length] = '?$populate[0][path]=users';
    qs[qs.length] = '$populate[0][select]=firstName lastName';
    qs[qs.length] = '$populate[1][path]=voter';
    qs[qs.length] = '$populate[1][select]=firstName lastName';
    qs[qs.length] = `$sort=block&type=${Residence.TYPES.APARTMENT}`;
    return qs.join('&');
  }

  loadResidences() {
    this.loadingStatus = this.LOADING;

    this.residenceService
      .getResidencesFromCondo(this.condo._id, this.getQueryString())
      .pipe(timeout(10000))
      .subscribe(
        residences => {
          this.residencesByBlock = [];
          this.residencesByBlockKeys = [];

          residences.sort(function (res1: any, res2: any) {
            return res1.number - res2.number;
          });

          this.residencesByBlock = residences.reduce((acc, residence) => {
            const residenceBlock = ((<ApartmentResidence>residence).block || 'BLOCO').toUpperCase();
            acc[residenceBlock] = acc[residenceBlock] || [];
            acc[residenceBlock].push(residence);
            return acc;
          }, {});

          this.residencesByBlockKeys = Object.keys(this.residencesByBlock);
          this.residencesByBlockKeys.sort();
          // Pré seleciona o bloco caso só exista um no condomínio
          if (this.residencesByBlockKeys.length === 1) {
            this.selectedBlock = this.residencesByBlockKeys[0];
          }

          this.loadingStatus = this.SUCCESS;
          this.shouldReload = false;
          this.numberOfResidences = residences.length || 0;
        },
        err => {
          this.loadingStatus = this.ERROR;
        }
      );
  }

  checkResidence(residence) {
    if (this.isDisabledResidence(residence)) return;
    let index = this.pickedResidences.findIndex(res => {
      return res.equals(residence);
    });
    if (index > -1) {
      this.pickedResidences.splice(index, 1);
    } else {
      if (this.singleSelect) {
        this.pickedResidences.splice(0, this.pickedResidences.length);
      }
      this.pickedResidences.push(residence);
    }
  }

  closeModal(): void {
    this.apartmentPickerModal.hide();
  }

  showModal(modalConfig?) {
    this.initializeModal();
    if (
      this.shouldReload ||
      this.loadingStatus != this.SUCCESS ||
      (this.loadingStatus == this.SUCCESS && !this.residencesByBlockKeys.length)
    )
      this.loadResidences();
    this.apartmentPickerModal.show();
  }

  initializeModal() {
    if (this.loadingStatus != this.SUCCESS) {
      this.residencesByBlock = [];
      this.residencesByBlockKeys = [];
      this.pickedResidences = [];
      this.selectedBlock = '';
    }
    this.pickedResidences = this.selectedResidences.map(res => {
      return ResidenceBuilder.build(res);
    });
  }

  isSelectedResidence(res) {
    return this.pickedResidences.some(residence => {
      return res.equals(residence);
    });
  }

  isDisabledResidence(res) {
    return this.disabledResidences.some(residence => {
      return res.equals(residence);
    });
  }

  selectBlock(block) {
    this.selectedBlock = block;
  }

  clearPickedResidences() {
    this.pickedResidences.splice(0, this.pickedResidences.length);
  }

  confirmSelection() {
    this.closeModal();
    this.onResidencesSelect.emit(this.pickedResidences);
  }

  selectApartmentsFromBlock(block) {
    const apartmentsFromBlock = this.residencesByBlock[block] || [];
    for (const apt of apartmentsFromBlock) {
      if (!this.isDisabledResidence(apt)) {
        const index = this.pickedResidences.findIndex(res => res.equals(apt));
        if (index === -1) {
          this.pickedResidences.push(apt);
        }
      }
    }
  }
}
