export enum USER_TYPE {
  USER = 'USER',
  DEPENDENT = 'DEPENDENT'
}

export interface IResidenceUser {
  name: string;
  type: USER_TYPE;
  _id: string;
  picture: any;
}
