import { Injectable } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import * as uTechDevice from '@econdos/econdos-utech';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { Device } from '@api/model/hardware/device';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class UtechService {
  constructor() {}

  trigger(actuator: Actuator) {
    const utechDevice = new uTechDevice(actuator);
    return from(utechDevice.openDoor());
  }

  getTime(actuator: Actuator) {
    const utechDevice = new uTechDevice(actuator);
    return from(utechDevice.getDateTime()).pipe(
      timeout(10000),
      map((data: { datetime: string; timestamp: string }) => ({ _id: actuator._id, status: true, localTime: data.datetime })),
      catchError(() => of({ _id: actuator._id, status: false }))
    );
  }

  registerDevice(condoActuators: Actuator[], device: Device) {
    const name = device?.owner?.user?.fullName || device?.owner?.condoContact?.fullName || device?.owner?.userName;
    const serial = device.serial;
    const econdosId = device._id;
    const panic = device.panic || false;
    const data: any = { name, econdosId, panic };
    if (device.hardwareAttributes?.utechPermissions?.length) {
      data.permissions = device.hardwareAttributes.utechPermissions;
    }
    if (device.validUntil) {
      const validity = moment(device.validUntil).diff(new Date(), 's');
      data.validity = validity;
    }
    switch (device.type) {
      case 'CARD': {
        data.card = serial;
        break;
      }
      case 'QR': {
        data.qrcode = serial;
        break;
      }
      case 'RF': {
        data.rfcode = serial;
        break;
      }
      case 'SN': {
        data.password = serial;
        break;
      }
      case 'BM': {
        if (device.template) {
          data.fingerprint = device.template;
        } else {
          throw new Error('Device finterprint template is required');
        }
        break;
      }
    }

    let actuatorsToCreate = [];
    let actuatorsToDelete = [];
    const deviceActuatorsIds = device.actuators.map(a => a._id || a);
    for (const actuator of condoActuators) {
      if (deviceActuatorsIds.includes(actuator._id)) {
        actuatorsToCreate.push(actuator);
      } else {
        actuatorsToDelete.push(actuator);
      }
    }
    actuatorsToCreate = actuatorsToCreate.map(actuator =>
      (device.type === 'SN' ? from(new uTechDevice(actuator).addPassword(data)) : from(new uTechDevice(actuator).addUser(data))).pipe(
        map(() => ({ actuator, ok: true })),
        catchError(e => {
          console.log(e);
          return of({ actuator, ok: false });
        })
      )
    );

    actuatorsToDelete = actuatorsToDelete.map(actuator =>
      from(new uTechDevice(actuator).removeUserByKey(econdosId)).pipe(
        map(() => ({ actuator, ok: true })),
        catchError(e => {
          console.log(e);
          return of({ actuator, ok: false });
        })
      )
    );
    const requests = [].concat(actuatorsToCreate, actuatorsToDelete);
    return forkJoin(requests) as any;
  }

  delete(device: Device, actuators?: Actuator[]): Observable<[{ actuator: Actuator; ok: boolean }]> {
    let results = [];
    if (!actuators) {
      actuators = device.actuators;
    }
    results = actuators.map(actuator => {
      const utech = new uTechDevice(actuator);
      return from(utech.removeUserByKey(device._id)).pipe(
        map(() => ({ ok: true, actuator: actuator })),
        catchError(e => of({ ok: false, actuator: actuator }))
      );
    });
    return forkJoin(results) as any;
  }

  deleteFromActuatorById(actuator: Actuator, id) {
    const utech = new uTechDevice(actuator);
    return from(utech.removeUserById(id));
  }

  async collectFingerPrint(actuator) {
    const utech = new uTechDevice(actuator);
    const tempUserId = await utech.collectFingerprint(actuator);
    return tempUserId;
  }
}
