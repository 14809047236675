import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FullReport, FullReportsService } from '@api/service/full-reports.service';

@Component({
  selector: 'app-full-reports-panel',
  templateUrl: 'full-reports-panel.component.html',
  styleUrls: ['full-reports-panel.component.scss'],
  standalone: true,
  imports: [AsyncPipe, CommonModule]
})
export class FullReportsPanelComponent implements OnDestroy {
  constructor(public fullReportsService: FullReportsService) {}

  ngOnDestroy(): void {
    this.fullReportsService.clearAllReports();
  }

  closeReport(index: number) {
    this.fullReportsService.clearReport(index);
  }

  generateExcel(report: FullReport) {
    this.fullReportsService.handleExportAsExcel(report);
  }

  generatePdf(report: FullReport) {
    this.fullReportsService.handleExportAsPdf(report);
  }
}
