import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { Actuator } from '@api/model/hardware/actuator';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import swal from 'sweetalert2';
import { HardwareSocketService } from '../../services/hardware-socket.service';
import { conditionalValidator } from '@api/util/validators';
import { combineLatest, EMPTY, noop, Subject } from 'rxjs';
import { catchError, filter, map, pairwise, startWith, switchMap, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { Camera } from '@api/model/camera';
import { CameraService } from '@api/service/camera.service';
import { User } from '@api/model/user';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { ModalSelectIntelbrasIncontrolAccessPointComponent } from '../modal-select-intelbras-incontrol-access-point/modal-select-intelbras-incontrol-access-point.component';
import { Router } from '@angular/router';
import { IntelbrasStandAloneService } from '@api/service/hardware/intelbras-stand-alone.service';
import { ControlIdService } from '@api/service/hardware/control-id.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { GarenService } from '@api/service/hardware/garen.service';
import { SdkService } from '@api/service/hardware/sdk.service';
import { DayAllowed, DAYS_OF_WEEK, DAYS_OF_WEEK_FROM_INDEX, DAYS_OF_WEEK_INDEX, DAYS_OF_WEEK_LABEL } from '@api/model/timezone';
import { KeyValue } from '@angular/common';
import * as moment from 'moment';
import { parseHourMinuteStringToDate } from '@api/utils';
import { RemoteCheckService } from '@api/serviceV3/remote-check.service';

interface CondoToShare {
  value: string;
  label: string;
  isSelected: boolean;
}

interface IDaysAllowedFormGroup {
  day: FormControl<DAYS_OF_WEEK>;
  startTime: FormControl<string>;
  endTime: FormControl<string>;
}

interface IClipboardTime {
  startTime: string;
  endTime: string;
}

@Component({
  selector: 'app-modal-add-actuator',
  templateUrl: 'modal-add-actuator.component.html',
  styleUrls: ['./modal-add-actuator.component.scss']
})
export class ModalAddActuatorComponent implements OnInit, OnDestroy {
  typeIcons = ['male', 'user', 'users', 'car', 'motorcycle', 'truck', 'volume-up', 'power-off', 'repeat'];

  directionIcons = ['arrows-v', 'arrows-h', 'long-arrow-right', 'long-arrow-left', 'long-arrow-up', 'long-arrow-down'];

  condo: Condo;
  user: User;
  isAdmin = false;
  status: Status = new Status();

  actuator: Actuator;
  callbacks: { success?: (arg) => void; error?: (err) => {} };

  MANUFACTURERS = [
    { value: 'CONTROL_ID', label: 'Control ID' },
    { value: 'HIKVISION', label: 'Hikvision' },
    { value: 'INTELBRAS', label: 'Intelbras Incontrol' },
    { value: 'INTELBRAS_LPR', label: 'Câmera LPR da Intelbras' },
    { value: 'INTELBRAS_STAND_ALONE', label: 'Intelbras' },
    { value: 'LINEAR', label: 'Linear' },
    { value: 'UTECH', label: 'uTech' },
    { value: 'TASMOTA', label: 'Sonoff (Tasmota)' },
    { value: 'ALPHADIGI', label: 'Alphadigi' },
    { value: 'ALPHADIGI_LPR', label: 'Câmera LPR da Alphadigi' },
    { value: 'GAREN', label: 'Garen' },
    { value: 'NICE_CONTROLLER', label: 'Controladora Nice' },
    { value: 'ZKTECO', label: 'ZKTeco' },
    { value: HARDWARES.BRAVAS, label: 'Bravas' },
    { value: HARDWARES.XPE, label: 'XPE' }
  ].sort((a, b) => a.label.localeCompare(b.label));
  HARDWARE_TYPES = [];
  ACCESS_TYPES = [
    { value: 'IN', label: 'Entrada' },
    { value: 'OUT', label: 'Saída' },
    { value: 'PASS', label: 'Passagem' }
  ];

  DIRECTIONS = [
    { value: 'clockwise', label: 'Horário' },
    { value: 'anticlockwise', label: 'Anti-Horário' }
  ];

  ACTION_TYPES = [
    { value: 'powerOn', label: 'Abrir' },
    { value: 'powerOff', label: 'Fechar' },
    { value: 'powerToggle', label: 'Alternar' },
    { value: 'openDoor', label: 'Abrir porta' }
  ];

  CONDOS_TO_SHARE: CondoToShare[] = [];

  form: UntypedFormGroup;

  hardware: UntypedFormControl;
  name: UntypedFormControl;
  type: UntypedFormControl;
  number: UntypedFormControl;
  output: UntypedFormControl;
  color: UntypedFormControl;
  obs: UntypedFormControl;
  registerWarningEvents: UntypedFormControl;
  directionIcon: UntypedFormControl;
  typeIcon: UntypedFormControl;
  host: UntypedFormControl;
  host2: UntypedFormControl;
  port: UntypedFormControl;
  port2: UntypedFormControl;
  username: UntypedFormControl;
  password: UntypedFormControl;
  entranceDirection: UntypedFormControl;
  exitDirection: UntypedFormControl;
  camera: UntypedFormControl;
  accessType: UntypedFormControl;
  prohibitedForVisitors: UntypedFormControl;
  remoteCheck: UntypedFormControl;
  action: UntypedFormControl;
  time: UntypedFormControl;
  macAddress: UntypedFormControl;
  clientId: UntypedFormControl;
  serial: UntypedFormControl;
  generatesActuationEvents: UntypedFormControl;
  button: UntypedFormControl = new UntypedFormControl('', [Validators.min(1), Validators.max(4)]);
  outputs: UntypedFormControl = new UntypedFormControl([]);
  enableDoubleAuthenticationWithLpr: UntypedFormControl;
  lprActuator: UntypedFormControl;
  blockPeriod: UntypedFormControl;
  daysAllowedEnabled = new FormControl<boolean>(false);
  daysAllowed: FormArray<FormGroup<IDaysAllowedFormGroup>>;
  residentParkingControl = new FormControl<boolean>(false);
  residentParkingControlBypass = new FormControl<boolean>(false);

  cameras: Camera[] = [];
  cameraStatus;

  intelbrasOutputs: number[] = [];

  selectedAccessPoint = null;

  lineares = [];

  actuatorsList: Actuator[] = [];

  private unsubscribe: Subject<void> = new Subject();

  readonly HARDWARES = HARDWARES;

  bravasAddresses = Array.from(new Array(64), (x, i) => i + 1);

  isRemoteCheckEnabled = false;
  remoteCheckStatus = 'OK';

  protected readonly DAYS_OF_WEEK_LABEL = DAYS_OF_WEEK_LABEL;
  protected readonly DAYS_OF_WEEK = DAYS_OF_WEEK;

  clipboardTime: IClipboardTime | null = null;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private cameraService: CameraService,
    private hardwareSocketService: HardwareSocketService,
    private intelbrasService: IntelbrasIncontrolService,
    private intelbrasStandAloneService: IntelbrasStandAloneService,
    private controlIdService: ControlIdService,
    private hikvisionService: HikvisionService,
    private sdkService: SdkService,
    private actuatorService: ActuatorService,
    private modalService: BsModalService,
    private router: Router,
    private garenService: GarenService,
    private toastrService: ToastrService,
    private remoteCheckService: RemoteCheckService
  ) {
    this.form = this.fb.group({
      hardware: ['', Validators.required],
      name: ['', Validators.required],
      type: ['', Validators.required],
      color: ['#000000', Validators.required],
      obs: [''],
      registerWarningEvents: [false],
      directionIcon: [''],
      typeIcon: ['male', Validators.required],
      number: ['', conditionalValidator(() => ['LINEAR', 'NICE_CONTROLLER', 'TASMOTA'].includes(this.hardware.value), Validators.required)],
      output: [
        '',
        conditionalValidator(() => ['LINEAR', 'INTELBRAS_LPR', HARDWARES.XPE].includes(this.hardware.value), Validators.required)
      ],
      host: [
        '',
        conditionalValidator(
          () =>
            !['LINEAR', 'NICE_CONTROLLER', 'ALPHADIGI', 'ALPHADIGI_LPR', HARDWARES.GAREN, HARDWARES.ZKTECO].includes(this.hardware.value),
          Validators.required
        )
      ],
      port: [
        '',
        Validators.compose([
          conditionalValidator(
            () =>
              !['LINEAR', 'NICE_CONTROLLER', 'INTELBRAS', 'ALPHADIGI', HARDWARES.GAREN, 'ALPHADIGI_LPR', HARDWARES.ZKTECO].includes(
                this.hardware.value
              ),
            Validators.required
          ),
          Validators.pattern(/^\d+$/)
        ])
      ],
      host2: [''],
      port2: ['', Validators.pattern(/^\d+$/)],
      username: [
        '',
        conditionalValidator(
          () =>
            ![
              'LINEAR',
              'NICE_CONTROLLER',
              'INTELBRAS',
              'ALPHADIGI',
              HARDWARES.GAREN,
              HARDWARES.BRAVAS,
              'ALPHADIGI_LPR',
              HARDWARES.ZKTECO,
              HARDWARES.XPE
            ].includes(this.hardware.value),
          Validators.required
        )
      ],
      password: [
        '',
        conditionalValidator(() => this.hardware.value === 'TASMOTA', Validators.compose([Validators.minLength(15), Validators.required]))
      ],
      entranceDirection: ['', conditionalValidator(() => ['Catraca'].includes(this.type.value), Validators.required)],
      exitDirection: ['', conditionalValidator(() => ['Catraca'].includes(this.type.value), Validators.required)],
      camera: [''],
      accessType: [
        '',
        conditionalValidator(
          () =>
            [
              'HIKVISION',
              'CONTROL_ID',
              'ALPHADIGI',
              HARDWARES.GAREN,
              'INTELBRAS_STAND_ALONE',
              'UTECH',
              HARDWARES.ZKTECO,
              'LINEAR'
            ].includes(this.hardware.value),
          Validators.required
        )
      ],
      macAddress: [''],
      prohibitedForVisitors: [false, conditionalValidator(() => ['HIKVISION'].includes(this.type.value), Validators.required)],
      remoteCheck: [false],
      action: ['', conditionalValidator(() => this.hardware.value === 'TASMOTA', Validators.required)],
      time: ['', conditionalValidator(() => this.action.value === 'openDoor', Validators.required)],
      clientId: ['', conditionalValidator(() => [HARDWARES.ALPHADIGI_LPR].includes(this.hardware.value), Validators.required)],
      serial: [
        '',
        conditionalValidator(
          () => [HARDWARES.ZKTECO].includes(this.hardware.value) && ['V4L', 'V5L', 'C2'].includes(this.type.value),
          Validators.required
        )
      ],
      generatesActuationEvents: [false],
      enableDoubleAuthenticationWithLpr: [false],
      lprActuator: ['', conditionalValidator(() => this.enableDoubleAuthenticationWithLpr.value, Validators.required)],
      blockPeriod: [null],
      daysAllowedEnabled: new FormControl(false),
      daysAllowed: new FormArray<FormGroup<IDaysAllowedFormGroup>>([]),
      residentParkingControl: [false],
      residentParkingControlBypass: [false]
    });
    this.hardware = this.form.get('hardware') as UntypedFormControl;
    this.name = this.form.get('name') as UntypedFormControl;
    this.type = this.form.get('type') as UntypedFormControl;
    this.number = this.form.get('number') as UntypedFormControl;
    this.output = this.form.get('output') as UntypedFormControl;
    this.directionIcon = this.form.get('directionIcon') as UntypedFormControl;
    this.typeIcon = this.form.get('typeIcon') as UntypedFormControl;
    this.color = this.form.get('color') as UntypedFormControl;
    this.obs = this.form.get('obs') as UntypedFormControl;
    this.registerWarningEvents = this.form.get('registerWarningEvents') as UntypedFormControl;
    this.host = this.form.get('host') as UntypedFormControl;
    this.port = this.form.get('port') as UntypedFormControl;
    this.host2 = this.form.get('host2') as UntypedFormControl;
    this.port2 = this.form.get('port2') as UntypedFormControl;
    this.username = this.form.get('username') as UntypedFormControl;
    this.password = this.form.get('password') as UntypedFormControl;
    this.entranceDirection = this.form.get('entranceDirection') as UntypedFormControl;
    this.exitDirection = this.form.get('exitDirection') as UntypedFormControl;
    this.camera = this.form.get('camera') as UntypedFormControl;
    this.accessType = this.form.get('accessType') as UntypedFormControl;
    this.prohibitedForVisitors = this.form.get('prohibitedForVisitors') as UntypedFormControl;
    this.remoteCheck = this.form.get('remoteCheck') as UntypedFormControl;
    this.action = this.form.get('action') as UntypedFormControl;
    this.time = this.form.get('time') as UntypedFormControl;
    this.clientId = this.form.get('clientId') as UntypedFormControl;
    this.serial = this.form.get('serial') as UntypedFormControl;
    this.macAddress = this.form.get('macAddress') as UntypedFormControl;
    this.generatesActuationEvents = this.form.get('generatesActuationEvents') as UntypedFormControl;
    this.enableDoubleAuthenticationWithLpr = this.form.get('enableDoubleAuthenticationWithLpr') as UntypedFormControl;
    this.lprActuator = this.form.get('lprActuator') as UntypedFormControl;
    this.blockPeriod = this.form.get('blockPeriod') as UntypedFormControl;
    this.daysAllowed = this.form.get('daysAllowed') as FormArray;
    this.daysAllowedEnabled = this.form.get('daysAllowedEnabled') as FormControl;
    this.residentParkingControl = this.form.get('residentParkingControl') as FormControl;
    this.residentParkingControlBypass = this.form.get('residentParkingControlBypass') as FormControl;

    const hardwareTypes = {
      CONTROL_ID: [
        { value: 'Catraca', label: 'Catraca' },
        { value: 'Coletor', label: 'Coletor' },
        { value: 'iDAccess', label: 'iDAccess' },
        { value: 'iDAccess Nano', label: 'iDAccess Nano' },
        { value: 'iDAccess Pro', label: 'iDAccess Pro' },
        { value: 'iDBox', label: 'iDBox' },
        { value: 'iDFit', label: 'iDFit' },
        { value: 'iDFlex', label: 'iDFlex' },
        { value: 'iDUHF', label: 'iDUHF' },
        { value: 'iDFace', label: 'iDFace' }
      ],
      LINEAR: [
        { value: 'RF', label: 'RF - Controle remoto' },
        { value: 'TA', label: 'TA - Tag Ativa' },
        { value: 'CT', label: 'CT - Cartão / Chaveiro' },
        { value: 'BM', label: 'BM - Biometria' },
        { value: 'TP', label: 'TP - Tag Passiva' },
        { value: 'SN', label: 'SN - Senha' }
      ],
      NICE_CONTROLLER: [
        { value: 'RF', label: 'RF - Controle remoto' },
        { value: 'TA', label: 'TA - Tag Ativa' },
        { value: 'CT', label: 'CT - Cartão / Chaveiro' },
        { value: 'BM', label: 'BM - Biometria' },
        { value: 'TP', label: 'TP - Tag Passiva' },
        { value: 'SN', label: 'SN - Senha' }
      ],
      HIKVISION: [
        { value: 'FACIAL', label: 'Facial' },
        { value: 'DEEPIN_VIEW_LPR', label: 'LPR' }
      ],
      UTECH: [
        { value: 'MPI', label: 'MPI' },
        { value: 'MLI', label: 'MLI' }
      ],
      INTELBRAS: [
        { value: 'SS 5530 MF FACE', label: 'SS 5530 MF FACE' },
        { value: 'SS 5520', label: 'SS 5520' },
        { value: 'SS 420 G2', label: 'SS 420 G2' },
        { value: 'SS 411 E', label: 'SS 411 E' },
        { value: 'SS 230', label: 'SS 230' },
        { value: 'SS 230 MF', label: 'SS 230 MF' },
        { value: 'SS 320 MF', label: 'SS 320 MF' },
        { value: 'SS 320', label: 'SS 320' },
        { value: 'SS 311 MF', label: 'SS 311 MF' },
        { value: 'SS 311 E', label: 'SS 311 E' },
        { value: 'SS 120', label: 'SS 120' },
        { value: 'SS 420 MF', label: 'SS 420 MF' },
        { value: 'SS 420', label: 'SS 420' },
        { value: 'CT 500 4PB', label: 'CT 500 4PB', output: [1, 2, 3, 4] },
        { value: 'CT 500 2PB', label: 'CT 500 2PB', output: [1, 2] },
        { value: 'CT 500 1PB', label: 'CT 500 1PB' },
        { value: 'CT 500 4P', label: 'CT 500 4P', output: [1, 2, 3, 4] },
        { value: 'CT 500 2P', label: 'CT 500 2P', output: [1, 2, 3, 4] },
        { value: 'CT 500 1P', label: 'CT 500 1P' },
        { value: 'SS 7530 FACE', label: 'SS 7530 FACE' },
        { value: 'SS 7520 FACE T', label: 'SS 7520 FACE T' },
        { value: 'SS 3430 MF BIO', label: 'SS 3430 MF BIO' },
        { value: 'SS 3430 BIO', label: 'SS 3430 BIO' },
        { value: 'SS 3530 MF FACE W', label: 'SS 3530 MF FACE W' }
      ],
      INTELBRAS_STAND_ALONE: [
        // {value: 'SS 3430 BIO', label: 'SS 3430 BIO'},
        // {value: 'SS 3430 MF BIO', label: 'SS 3430 MF BIO'},
        { value: 'SS 1530 MF W', label: 'SS 1530 MF W' },
        { value: 'SS 1540 MF W', label: 'SS 1540 MF W' },
        { value: 'SS 3530 MF FACE', label: 'SS 3530 MF FACE' },
        { value: 'SS 3530 MF FACE W', label: 'SS 3530 MF FACE W' },
        { value: 'SS 3532 MF W', label: 'SS 3532 MF W' },
        { value: 'SS 3540 MF FACE EX', label: 'SS 3540 MF FACE EX' },
        { value: 'SS 3542 MF W', label: 'SS 3542 MF W' },
        { value: 'SS 5520', label: 'SS 5520' },
        { value: 'SS 5530 MF FACE', label: 'SS 5530 MF FACE' },
        { value: 'SS 5530 FACE LITE', label: 'SS 5530 FACE LITE' },
        { value: 'SS 5531 MF W', label: 'SS 5531 MF W' },
        { value: 'SS 5541 MF W', label: 'SS 5541 MF W' },
        { value: 'SS 5532 MF W', label: 'SS 5532 MF W' },
        { value: 'SS 5542 MF W', label: 'SS 5542 MF W' },
        { value: 'SS 7520 FACE T', label: 'SS 7520 FACE T' },
        { value: 'SS 7530 FACE', label: 'SS 7530 FACE' }
      ],
      INTELBRAS_LPR: [{ value: 'VIP7250', label: 'VIP 7250' }],
      [HARDWARES.ALPHADIGI]: [
        { value: 'ARF2001', label: 'Facial ARF2001' },
        { value: 'ARF2002', label: 'Facial ARF2002' },
        { value: 'ARF2003', label: 'Facial ARF2003' }
      ],
      ALPHADIGI_LPR: [{ value: 'PCAM2020P', label: 'PCAM2020P' }],
      GAREN: [
        { value: 'GF2022CT', label: 'Facial GF2022CT' },
        { value: 'GF2022ST', label: 'Facial GF2022ST' },
        { value: 'AUTG07', label: 'Controladora AUTG07' }
      ],
      [HARDWARES.ZKTECO]: [
        { value: 'C3', label: 'Controladora C3' },
        { value: 'C2', label: 'Controladora C2' },
        { value: 'TF1700', label: 'Biometria TF1700' },
        { value: 'INBIO', label: 'inBio' },
        { value: 'V4L', label: 'SpeedFace V4L' },
        { value: 'V5L', label: 'SpeedFace V5L' }
      ],
      [HARDWARES.BRAVAS]: [
        { value: 'TURNSTILE', label: 'Módulo catraca' },
        { value: 'NATIVE', label: 'Porta nativa' },
        { value: 'PGM', label: 'Módulo PGM' },
        { value: 'DOOR', label: 'Módulo porta' },
        { value: 'RF', label: 'Módulo RF' }
      ],
      [HARDWARES.XPE]: [{ value: 'XPE 3200 IP FACE', label: 'XPE 3200 IP FACE' }]
    };

    this.daysAllowedEnabled.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: value => {
        if (value) {
          this.daysAllowed.enable({ emitEvent: false });
          if (this.daysAllowed.length === 0) {
            this.addDaysAllowed();
          }
        } else {
          this.daysAllowed.disable({ emitEvent: false });
        }
      }
    });

    this.daysAllowed.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: () => {
        this.daysAllowed.controls.sort(sortDaysAllowedFormGroups);
      }
    });

    this.remoteCheck.valueChanges
      .pipe(
        tap(() => {
          this.remoteCheck.disable({ emitEvent: false });
          this.remoteCheckStatus = 'LOADING';
        }),
        filter(() => ['HIKVISION', 'INTELBRAS_STAND_ALONE', 'CONTROL_ID'].includes(this.hardware.value)),
        switchMap(remoteCheck => {
          if (this.isRemoteCheckEnabled && ['HIKVISION', 'INTELBRAS_STAND_ALONE', 'CONTROL_ID'].includes(this.hardware.value)) {
            if (!this.condo.remoteCheck.host) {
              return this.handleRemoteCheckError({ message: 'Host not found' });
            }
            return this.remoteCheckService
              .sendConfigToRemoteCheck(this.condo?.remoteCheck.host, this.condo?.remoteCheck.port || 8090, this.actuator._id)
              .pipe(catchError(e => this.handleRemoteCheckError(e)));
          } else if (['HIKVISION'].includes(this.hardware.value)) {
            /* Deve limpar o cache, pois o valor do remoteCheck do acionador muda dinamicamente */
            this.sdkService.clearDeviceCache(this.actuator._id);
            return this.sdkService
              .configure({
                ...this.actuator,
                remoteCheck
              })
              .pipe(
                timeout(10_000),
                map(() => remoteCheck),
                catchError(e => this.handleRemoteCheckError(e)),
                tap(remoteCheck => {
                  if (remoteCheck) {
                    this.blockPeriod.enable();
                  } else {
                    this.blockPeriod.disable();
                  }
                })
              );
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next: () => {
          this.remoteCheck.enable({ emitEvent: false });
          this.remoteCheckStatus = 'OK';
        }
      });

    this.hardware.valueChanges.pipe(takeUntil(this.unsubscribe), startWith([null]), pairwise()).subscribe(([prev, next]: [any, any]) => {
      this.HARDWARE_TYPES = hardwareTypes[next] || [];
      if (prev === HARDWARES.INTELBRAS && next !== HARDWARES.INTELBRAS) {
        this.resetIntelbrasForm();
      }
      if (next === HARDWARES.INTELBRAS) {
        if (!this.intelbrasService.connected) {
          this.inControlException();
        }
      }
      if (next === HARDWARES.HIKVISION) {
        this.form.addControl('prohibitedForVisitors', this.prohibitedForVisitors);
        this.form.addControl('remoteCheck', this.remoteCheck);

        this.form.addControl('enableDoubleAuthenticationWithLpr', this.enableDoubleAuthenticationWithLpr);
        this.form.addControl('lprActuator', this.lprActuator);
        this.form.addControl('blockPeriod', this.blockPeriod);
        this.form.addControl('daysAllowed', this.daysAllowed);
        this.form.addControl('daysAllowedEnabled', this.daysAllowedEnabled);
        this.form.addControl('time', this.time);
        this.form.addControl('output', this.output);
        this.form.addControl('residentParkingControl', this.residentParkingControl);
        this.form.addControl('residentParkingControlBypass', this.residentParkingControlBypass);
      } else if ([HARDWARES.INTELBRAS_STAND_ALONE, HARDWARES.CONTROL_ID].includes(next)) {
        this.form.addControl('remoteCheck', this.remoteCheck);
      } else {
        this.form.removeControl('prohibitedForVisitors');
        this.form.removeControl('remoteCheck');

        this.form.get('enableDoubleAuthenticationWithLpr')?.reset();
        this.form.get('lprActuator')?.reset('');
        this.form.get('blockPeriod')?.reset();
        this.form.get('daysAllowed')?.reset();
        this.form.get('daysAllowedEnabled')?.reset(false);

        this.form.removeControl('enableDoubleAuthenticationWithLpr');
        this.form.removeControl('lprActuator');
        this.form.removeControl('blockPeriod');
        this.form.removeControl('daysAllowed');
        this.form.removeControl('daysAllowedEnabled');
        this.form.removeControl('residentParkingControl');
        this.form.removeControl('residentParkingControlBypass');
      }

      if (
        [
          HARDWARES.HIKVISION,
          HARDWARES.CONTROL_ID,
          HARDWARES.UTECH,
          HARDWARES.INTELBRAS_STAND_ALONE,
          HARDWARES.ALPHADIGI,
          HARDWARES.GAREN,
          HARDWARES.ZKTECO,
          HARDWARES.BRAVAS,
          HARDWARES.LINEAR,
          HARDWARES.XPE
        ].includes(next)
      ) {
        this.form.addControl('accessType', this.accessType);
      } else {
        this.form.removeControl('accessType');
      }
      if ([HARDWARES.ALPHADIGI_LPR, HARDWARES.CONTROL_ID].includes(next)) {
        this.form.addControl('clientId', this.clientId);
      } else {
        this.form.removeControl('clientId');
      }
      if (next === HARDWARES.TASMOTA) {
        this.form.patchValue({
          port: 1883,
          type: HARDWARES.TASMOTA,
          username: 'DVES_USER',
          host: 'ws.econdos.com.br',
          time: this.actuator?.time || 5
        });
      } else if (prev === HARDWARES.TASMOTA) {
        this.form.reset({ hardware: next }, { emitEvent: false });
      }

      // multiple linear
      if (this.condo?.linear?.multiple) {
        if (next === HARDWARES.LINEAR) {
          if (this.condo?.linear?.multiple) {
            this.lineares = this.condo.lineares || [this.condo.linear] || [];
            this.form.addControl('linear', new UntypedFormControl('', Validators.required));
          }
        } else if (prev === HARDWARES.LINEAR) {
          this.lineares = [];
          this.form.removeControl('linear');
        }
      }

      Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
    });

    this.type.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(type => {
      if (type === 'Catraca') {
        this.form.addControl('entranceDirection', this.entranceDirection);
        this.form.addControl('exitDirection', this.exitDirection);
      } else {
        this.form.removeControl('entranceDirection');
        this.form.removeControl('exitDirection');

        if (this.hardware.value == 'INTELBRAS') {
          this.output.setValue(1, { emitEvent: false });
          const value = this.HARDWARE_TYPES.find(t => t.output && t.value == type);
          if (value) {
            this.intelbrasOutputs = value.output;
            this.output.setValidators(Validators.required);
          } else {
            this.intelbrasOutputs = [];
            this.output.clearValidators();
          }
        }
      }
      Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
    });

    this.enableDoubleAuthenticationWithLpr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(enabled => {
      if (!enabled) {
        this.lprActuator.setValue('');
        this.lprActuator.disable();
      } else {
        this.lprActuator.enable();
      }
    });

    // Regra do hardware da BRAVAS
    combineLatest([this.hardware.valueChanges, this.type.valueChanges])
      .pipe(
        takeUntil(this.unsubscribe),
        tap(([hardware]) => {
          if (hardware !== HARDWARES.BRAVAS) {
            this.form.removeControl('button');
            this.form.removeControl('outputs');
          }
        }),
        filter(([hardware]) => hardware === HARDWARES.BRAVAS)
      )
      .subscribe(([, type]) => {
        // Adiciona o campo botão para tipo RF
        if (type === 'RF') {
          this.form.addControl('button', this.button);
        } else {
          this.form.removeControl('button');
        }

        // Adiciona o campo saídas para RF e PGM
        if (type === 'RF' || type === 'PGM') {
          this.form.addControl('outputs', this.outputs);
        } else {
          this.form.removeControl('outputs');
        }

        // Remove o tipo de acesso para catraca pois pode ser de entrada e saída de acordo com o sentido de giro e de PGM, pois o mesmo não é utilizado como leitores de controle de acesso
        if (type === 'TURNSTILE' || type === 'PGM') {
          this.form.removeControl('accessType');
        } else {
          this.form.addControl('accessType', this.accessType);
        }
      });
  }

  addDaysAllowed() {
    if (!this.daysAllowed.length) {
      return this.daysAllowed.push(this.daysAllowedFormGroup());
    }

    const day = this.getDayFromIndex(this.daysAllowed.length);
    return this.daysAllowed.push(
      this.daysAllowedFormGroup({
        day,
        startTime: null,
        endTime: null
      })
    );
  }

  getDayFromIndex(index: number): DAYS_OF_WEEK {
    return DAYS_OF_WEEK_FROM_INDEX[index];
  }

  removeAtIndex(index: number) {
    this.daysAllowed.removeAt(index);
  }

  daysAllowedFormGroup(dayAllowed?: DayAllowed) {
    return new FormGroup<IDaysAllowedFormGroup>({
      day: new FormControl(dayAllowed?.day ?? DAYS_OF_WEEK.SUNDAY, Validators.required),
      startTime: new FormControl(dayAllowed?.startTime ?? null, {
        validators: [
          (formControl: FormControl<string>) => {
            if (!formControl.value) return;

            const parent = formControl.parent as FormGroup<IDaysAllowedFormGroup>;
            if (!parent?.controls) return;

            const startTime = moment(formControl.value);
            const endTime = moment(parent.controls.endTime.value);
            if (moment(startTime).isAfter(endTime)) {
              return { startTimeBeforeEndTime: true };
            }
          },
          Validators.required
        ]
      }),
      endTime: new FormControl(dayAllowed?.endTime ?? null, {
        validators: [
          (formControl: FormControl<string>) => {
            const formattedTime = moment(formControl.value).format('HH:mm');
            if (formattedTime === '00:00') {
              return {
                invalidEndTime: true
              };
            }
          },
          (formControl: FormControl<string>) => {
            if (!formControl.value) return;

            const parent = formControl.parent as FormGroup<IDaysAllowedFormGroup>;
            if (!parent?.controls) return;

            const endTime = moment(formControl.value);
            const startTime = moment(parent.controls.startTime.value);
            if (moment(startTime).isAfter(endTime)) {
              return { startTimeBeforeEndTime: true };
            }
          },
          Validators.required
        ]
      })
    });
  }

  ngOnInit() {
    const condosSharingHardwareWithMe = this.condo.hardwareParams.condosSharingHardware
      .map(id => this.user.approvedCondos.find(c => c._id === id))
      .filter(c => !!c)
      .map(condo => {
        return {
          value: condo._id,
          label: condo.name,
          isSelected: this.actuator?.condos?.map(({ _id }) => _id).includes(condo._id) || false
        };
      });

    if (condosSharingHardwareWithMe.length) {
      this.CONDOS_TO_SHARE = condosSharingHardwareWithMe;
    }

    if (this.actuator) {
      this.hardware.setValue(this.actuator.hardware);
      this.hardware.disable();
      /* Quando o campo é desabilitado, ele passa a não fazer parte do objeto do form
          para obter o valor da propriedade é necessário chamar o value da variável */
      this.name.setValue(this.actuator.name);
      this.type.setValue(this.actuator.type);
      this.typeIcon.setValue(this.actuator.typeIcon);
      this.directionIcon.setValue(this.actuator.directionIcon);
      this.color.setValue(this.actuator.color);
      this.obs.setValue(this.actuator.obs);
      switch (this.actuator.hardware) {
        case 'CONTROL_ID': {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.password.setValue(this.actuator.password);
          this.username.setValue(this.actuator.username);
          this.accessType.setValue(this.actuator.accessType);
          this.entranceDirection.setValue(this.actuator.entranceDirection);
          this.exitDirection.setValue(this.actuator.exitDirection);
          this.output.setValue(this.actuator.output || '');
          this.clientId.setValue(this.actuator.clientId || '');
          this.remoteCheck.setValue(this.actuator.remoteCheck, { emitEvent: false });
          break;
        }
        case 'HIKVISION': {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.password.setValue(this.actuator.password);
          this.username.setValue(this.actuator.username);
          this.accessType.setValue(this.actuator.accessType);
          this.prohibitedForVisitors.setValue(this.actuator.prohibitedForVisitors);
          this.remoteCheck.setValue(this.actuator.remoteCheck, { emitEvent: false });
          this.enableDoubleAuthenticationWithLpr.setValue(this.actuator.doubleAuthenticationWithLpr.enabled);
          this.lprActuator.setValue(this.actuator.doubleAuthenticationWithLpr.lprActuator);
          this.blockPeriod.setValue(this.actuator.blockPeriod);
          this.time.setValue(this.actuator.time);
          this.output.setValue(this.actuator.output);
          this.residentParkingControl.setValue(this.actuator.residentParkingControl);
          this.residentParkingControlBypass.setValue(this.actuator.residentParkingControlBypass);
          if (this.actuator?.daysAllowed?.length) {
            while (this.daysAllowed.length) {
              this.daysAllowed.removeAt(0);
            }
            this.actuator.daysAllowed.forEach(({ day, startTime, endTime }) => {
              const dayAllowed = {
                day,
                startTime: parseHourMinuteStringToDate(startTime).toISOString(),
                endTime: parseHourMinuteStringToDate(endTime).toISOString()
              };
              const formGroup = this.daysAllowedFormGroup(dayAllowed);
              this.daysAllowed.push(formGroup);
            });
            if (this.remoteCheck.value && this.daysAllowed.length) {
              this.daysAllowedEnabled.setValue(true);
            }
          }
          break;
        }
        case 'UTECH': {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.password.setValue(this.actuator.password);
          this.username.setValue(this.actuator.username);
          this.accessType.setValue(this.actuator.accessType);
          this.output.setValue(this.actuator.output);
          break;
        }
        case 'NICE_CONTROLLER':
          this.macAddress.setValue(this.actuator.macAddress);
          this.number.setValue(this.actuator.number);
          this.output.setValue(this.actuator.output);
          break;
        case 'LINEAR': {
          this.number.setValue(this.actuator.number);
          this.output.setValue(this.actuator.output);
          this.accessType.setValue(this.actuator.accessType);
          this.registerWarningEvents.setValue(this.actuator.registerWarningEvents !== false);
          if (this.condo?.linear?.multiple && this.actuator.linear) {
            this.form.get('linear')?.setValue(this.actuator.linear);
          }
          this.generatesActuationEvents.setValue(this.actuator.generatesActuationEvents !== false);
          break;
        }
        case 'INTELBRAS': {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.password.setValue(this.actuator.password);
          this.output.setValue(this.actuator.output);
          this.accessType.setValue(this.actuator.accessType);
          this.registerWarningEvents.setValue(this.actuator.registerWarningEvents !== false);
          break;
        }
        case 'INTELBRAS_STAND_ALONE': {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.password.setValue(this.actuator.password);
          this.username.setValue(this.actuator.username);
          this.accessType.setValue(this.actuator.accessType);
          this.registerWarningEvents.setValue(this.actuator.registerWarningEvents !== false);
          this.remoteCheck.setValue(this.actuator.remoteCheck, { emitEvent: false });
          break;
        }
        case 'INTELBRAS_LPR': {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.username.setValue(this.actuator.username);
          this.password.setValue(this.actuator.password);
          this.output.setValue(this.actuator.output);
          this.accessType.setValue(this.actuator.accessType);
          break;
        }
        case 'TASMOTA': {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.number.setValue(this.actuator.number);
          this.action.setValue(this.actuator.action);
          this.username.setValue(this.actuator.username);
          this.password.setValue(this.actuator.password);
          if (this.actuator.action === 'openDoor') {
            this.time.setValue(this.actuator.time || '');
          }
          break;
        }
        case 'ALPHADIGI_LPR': {
          this.clientId.setValue(this.actuator.clientId);
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.username.setValue(this.actuator.username);
          this.password.setValue(this.actuator.password);
          break;
        }
        case HARDWARES.GAREN: {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.password.setValue(this.actuator.password);
          this.username.setValue(this.actuator.username);
          this.accessType.setValue(this.actuator.accessType);
          this.output.setValue(this.actuator.output);
          this.time.setValue(this.actuator.time);
          this.generatesActuationEvents.setValue(this.actuator.generatesActuationEvents);
          break;
        }
        case 'ALPHADIGI': {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.password.setValue(this.actuator.password);
          this.username.setValue(this.actuator.username);
          this.accessType.setValue(this.actuator.accessType);
          this.output.setValue(this.actuator.output);
          break;
        }
        case HARDWARES.ZKTECO: {
          if (['C3', 'TF1700', 'INBIO'].includes(this.actuator.type)) {
            this.host.setValue(this.actuator.host);
            this.port.setValue(this.actuator.port);
            this.password.setValue(this.actuator.password);
            this.accessType.setValue(this.actuator.accessType);
            this.output.setValue(this.actuator.output);
            this.time.setValue(this.actuator.time);
            this.generatesActuationEvents.setValue(this.actuator.generatesActuationEvents);
          } else if (['V4L', 'V5L', 'C2'].includes(this.actuator.type)) {
            this.serial.setValue(this.actuator.serial);
            this.accessType.setValue(this.actuator.accessType);
            this.output.setValue(this.actuator.output);
            this.time.setValue(this.actuator.time);
            this.generatesActuationEvents.setValue(this.actuator.generatesActuationEvents);
          }
          break;
        }
        case HARDWARES.BRAVAS: {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.password.setValue(this.actuator.password);
          this.accessType.setValue(this.actuator.accessType);
          this.number.setValue(this.actuator.number);
          this.output.setValue(this.actuator.output);
          this.outputs.setValue(this.actuator.outputs);
          this.time.setValue(this.actuator.time);
          this.button.setValue(this.actuator.button);
          break;
        }
        case HARDWARES.XPE: {
          this.host.setValue(this.actuator.host);
          this.port.setValue(this.actuator.port);
          this.host2.setValue(this.actuator.host2);
          this.port2.setValue(this.actuator.port2);
          this.username.setValue(this.actuator.username);
          this.password.setValue(this.actuator.password);
          this.accessType.setValue(this.actuator.accessType);
          this.output.setValue(this.actuator.output);
          break;
        }
        default: {
          break;
        }
      }
    }

    this.getCameras();
    this.isAdmin = this.user.isAdmin();
    this.isRemoteCheckEnabled = this.condo?.remoteCheck.enabled;
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  handleRemoteCheckError(e) {
    this.remoteCheck.setValue(!this.remoteCheck.value, { emitEvent: false });
    this.remoteCheck.enable({ emitEvent: false });
    this.remoteCheckStatus = 'OK';
    const errorMessages = {
      'Empty password': 'Senha obrigatória',
      'Network Error': 'Erro de rede',
      'Host not found': 'Endereço de IP não preenchido nas configurações do serviço'
    };

    const errorMessage = errorMessages[e.message] || '';

    if (errorMessage) {
      this.toastrService.error(`Erro de Verificação Remota: ${errorMessage}`);
    } else {
      this.toastrService.error(`Não foi possivel se conectar com o serviço de verificação remota`);
    }
    console.error(e);
    return EMPTY;
  }

  getCameras() {
    this.cameraStatus = 'DOWNLOADING';
    this.cameraService
      .get(this.condo._id)
      .pipe(timeout(10000))
      .subscribe(
        (res: any) => {
          this.cameraStatus = 'SUCCESS';
          this.cameras = res.cameras;
          if (this.actuator && this.actuator.camera) {
            const camera = this.cameras.find(c => c._id === (this.actuator.camera as any) || c._id === this.actuator.camera._id);
            if (camera) {
              this.camera.setValue(camera);
            }
          }
        },
        err => {
          console.log(err);
          this.cameraStatus = 'ERROR';
        }
      );
  }

  save(values) {
    if (this.form.valid) {
      if (!values.camera) {
        values.camera = null;
      }

      let subscription;

      if ([this.HARDWARES.ALPHADIGI_LPR, this.HARDWARES.HIKVISION].includes(this.hardware.value)) {
        const selectedCondosToShare = this.CONDOS_TO_SHARE.filter(option => option.isSelected).map(option => ({
          _id: option.value
        }));
        values.condos = selectedCondosToShare || [];
      }
      if (this.hardware.value === HARDWARES.HIKVISION) {
        if (this.remoteCheck.value && this.daysAllowedEnabled.value) {
          values.daysAllowed = values.daysAllowed.map(({ endTime, startTime, day }) => ({
            day,
            startTime: moment(startTime).format('HH:mm'),
            endTime: moment(endTime).format('HH:mm')
          }));
        } else {
          values.daysAllowed = [];
          values.blockPeriod = false;
        }
      }

      if (this.enableDoubleAuthenticationWithLpr.value && this.lprActuator.value) {
        values.doubleAuthenticationWithLpr = {
          enabled: this.enableDoubleAuthenticationWithLpr.value,
          lprActuator: this.lprActuator.value
        };
      } else {
        values.doubleAuthenticationWithLpr = {
          enabled: false,
          lprActuator: null
        };
      }
      delete values.enableDoubleAuthenticationWithLpr;
      delete values.lprActuator;

      if (this.actuator) {
        // update
        subscription = this.actuatorService.update(this.condo._id, this.actuator._id, values);
      } else {
        // create
        subscription = this.actuatorService.create(this.condo._id, values);
      }

      this.status.setAsProcessing();
      subscription.subscribe(
        (res: { _id: string }) => {
          let storedDevice;
          if (this.actuator) {
            // Limpa os caches do service ao fazer uma edição de dispositivo
            if (this.hardware.value === 'INTELBRAS_STAND_ALONE') {
              this.intelbrasStandAloneService.clearDeviceCache(this.actuator._id);
            } else if (this.hardware.value === 'CONTROL_ID') {
              this.controlIdService.clearDeviceCache(this.actuator._id);
            } else if (this.hardware.value === HARDWARES.HIKVISION) {
              this.hikvisionService.clearDeviceCache(this.actuator._id);
              this.sdkService.clearDeviceCache(this.actuator._id);
            } else if (this.hardware.value === HARDWARES.GAREN) {
              this.garenService.clearDeviceCache(this.actuator);
            } else if (this.hardware.value === HARDWARES.BRAVAS) {
              this.sdkService.clearDeviceCache(this.actuator._id);
            }
            this.toastrService.success('Acionador atualizado com sucesso');
            storedDevice = { ...this.actuator, ...values };
          } else {
            this.toastrService.success('Acionador registrado com sucesso');
            const condo = { _id: this.condo._id, name: this.condo.name };
            storedDevice = { ...values, condo, _id: res._id };
          }
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(storedDevice);
          }
          this.status.setAsSuccess();
          this.bsModalRef.hide();
        },
        err => {
          console.log(err);
          this.status.setAsError();
        }
      );
    } else {
      this.toastrService.warning('Preencha todos os campos');
      this.form.markAllAsTouched();
    }
  }

  intelbrasSave(values) {
    if (this.form.valid) {
      if (this.selectedAccessPoint) {
        values = {
          ...values,
          clientId: this.selectedAccessPoint.clientId
        };
        swal({
          title: 'Ponto de acesso selecionado',
          text: 'Deseja criar um acionador a partir do ponto de acesso selecionado?',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          confirmButtonColor: '#32DB64',
          cancelButtonColor: '#f53d3d',
          cancelButtonText: 'Não',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return Promise.resolve(true);
          }
        }).then(
          () => {
            this.save(values);
          },
          error => {
            console.log(error);
          }
        );
      } else {
        swal({
          title: 'Criar acionador e ponto de acesso',
          text: 'Deseja criar um acionador e um ponto de acesso no InControl?',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          confirmButtonColor: '#32DB64',
          cancelButtonColor: '#f53d3d',
          cancelButtonText: 'Não',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return Promise.resolve(true);
          }
        }).then(
          () => {
            this.saveOnIncontrol(values);
          },
          error => {
            console.log(error);
          }
        );
      }
    } else {
      this.toastrService.warning('Preencha todos os campos');
      this.form.markAllAsTouched();
    }
  }

  saveOnIncontrol(values) {
    if (!values.camera) {
      values.camera = null;
    }
    if (!values.port) {
      values.port = '4370';
    }
    if (!values.output) {
      values.output = 1;
    }
    this.status.setAsProcessing();
    let subscription;
    if (this.actuator) {
      subscription = this.intelbrasService.updateActuator(this.actuator.clientId, values).pipe(
        switchMap(({ id: clientId }) =>
          this.actuatorService
            .update(this.condo._id, this.actuator._id, {
              ...values,
              clientId
            })
            .pipe(timeout(10000))
        )
      );
    } else {
      subscription = this.intelbrasService.createActuator(values).pipe(
        switchMap(({ id: clientId }) =>
          this.actuatorService
            .create(this.condo._id, {
              ...values,
              clientId
            })
            .pipe(timeout(10000))
        )
      );
    }
    subscription.subscribe(
      (res: { _id: string }) => {
        let storedDevice;
        if (this.actuator) {
          this.toastrService.success('Acionador atualizado com sucesso');
          storedDevice = { ...this.actuator, ...values };
        } else {
          this.toastrService.success('Acionador registrado com sucesso');
          storedDevice = { ...values, _id: res._id };
        }
        if (this.callbacks && this.callbacks.success) {
          this.callbacks.success(storedDevice);
        }
        this.status.setAsSuccess();
        this.bsModalRef.hide();
      },
      err => {
        console.log(err);
        this.status.setAsError();
      }
    );
  }

  actuate() {
    if (this.form.valid) {
      swal({
        title: `Testar acionamento`,
        text: 'Clique em sim para acionar o dispositivo',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      })
        .then(() => {
          const actuator = { ...this.actuator, ...this.selectedAccessPoint, ...this.form.value };
          actuator.condo = this.condo._id;
          actuator.hardware = this.hardware.value;
          this.actuatorService.trigger(actuator).subscribe(
            () => {
              // TODO fazer algo no sucesso
            },
            err => {
              // TODO fazer algo no erro
            }
          );
        })
        .catch(e => swal.noop());
    } else {
      this.form.markAllAsTouched();
      this.toastrService.warning('Preencha todos os campos obrigatórios');
    }
  }

  selectAccessPoint() {
    const initialState = {
      callbacks: {
        success: ap => {
          if (ap) {
            this.selectedAccessPoint = ap;
            this.name.setValue(this.selectedAccessPoint.name);
            this.type.setValue(this.selectedAccessPoint.type);
            this.host.setValue(this.selectedAccessPoint.host);
            this.port.setValue(this.selectedAccessPoint.port);
            this.password.setValue(this.selectedAccessPoint.password);
            this.output.setValue(this.selectedAccessPoint.output);
          }
        }
      }
    };
    this.modalService.show(ModalSelectIntelbrasIncontrolAccessPointComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  resetIntelbrasForm() {
    this.selectedAccessPoint = null;
    this.name.setValue('');
    this.type.setValue('');
    this.host.setValue('');
    this.port.setValue('');
    this.password.setValue('');
    this.output.setValue('');
  }

  inControlException() {
    swal({
      title: 'Erro InControl',
      text: `O servidor InControl não foi encontrado, configure-o antes de ${this.actuator ? 'editar' : 'criar'} um acionador!`,
      type: 'error',
      showCancelButton: true,
      confirmButtonText: 'Configurar servidor',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Promise.resolve(true);
      }
    }).then(
      async () => {
        this.bsModalRef.hide();
        await this.router.navigateByUrl('hardware/config');
      },
      error => {
        this.bsModalRef.hide();
      }
    );
  }

  collectControlIdIdentification() {
    if (!this.output.value) {
      swal({ type: 'warning', title: 'Selecione uma saída antes de coletar sua identificação' });
      return;
    }
    const unsubscribe$ = new Subject();
    swal({
      type: 'info',
      title: 'Aguardando identificação',
      text: `Digite uma senha no teclado instalado na saída ${this.output.value} para identificá-lo no sistema.`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(() => {
      unsubscribe$.next(true);
      unsubscribe$.complete();
    });

    this.hardwareSocketService.onData$
      .pipe(
        takeUntil(unsubscribe$),
        take(1),
        timeout(60000),
        filter(data => data?.command === 'eventSentAutomatically' && data.hardwareData?.identifier_id),
        map(data => data.hardwareData.identifier_id)
      )
      .subscribe(
        identifier_id => {
          this.clientId.setValue(identifier_id);
          this.toastrService.success('Dado lido com sucesso');
          swal.clickConfirm();
        },
        err => {
          swal.close();
          swal({
            type: 'error',
            title: 'Erro na leitura',
            text: 'Não foi possível realizar a leitura do equipamento'
          });
        }
      );
  }

  selectCondoToShareActuator(condoOption: CondoToShare): void {
    this.CONDOS_TO_SHARE = this.CONDOS_TO_SHARE.map(opt => {
      if (opt.value === condoOption.value) {
        opt.isSelected = !opt.isSelected;
      }

      return opt;
    });
  }

  get actuatorsForDoubleAuthentication(): Actuator[] {
    const actuators = this.actuatorsList.filter(actuator => ['ALPHADIGI_LPR', 'ALPHADIGI', 'GAREN'].includes(actuator.hardware));

    if (this.actuator?._id) {
      return actuators.filter(actuator => actuator._id !== this.actuator._id);
    } else {
      return actuators;
    }
  }

  get canUserDoubleAuthenticationWithLpr(): boolean {
    return (
      this.condo?.hardwareParams?.visitorsLprFromUser === 'ENABLED' &&
      this.condo?.generalParams?.alprParams?.visitorEnabled &&
      this.condo?.hardwareParams?.visitorsDoubleAuthenticationWithLpr &&
      (this.condo?.alphadigi?.enabled || this.condo?.garen?.enabled)
    );
  }

  get doubleAuthenticationHelpMessage(): string {
    return (
      'Essa opção habilita/desabilita o recurso de dupla autenticação com outro acionador LPR. ' +
      'Exemplo: o visitante irá passar pelo reconhecimento de placa do veículo e em seguida ' +
      'precisará passar por uma facial e, assim, finalizar a dupla autenticação.'
    );
  }

  sortDaysOfWeek = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    const a = DAYS_OF_WEEK_INDEX[akv.key as DAYS_OF_WEEK];
    const b = DAYS_OF_WEEK_INDEX[bkv.key as DAYS_OF_WEEK];
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  handleTimePickerChange(formControl: FormControl<string>, siblingFormControl: FormControl<string>) {
    formControl.markAsTouched();
    siblingFormControl.updateValueAndValidity();
  }

  setFullDay(dayAllowed: FormGroup<IDaysAllowedFormGroup>) {
    dayAllowed.controls.startTime.setValue(moment().startOf('day').toISOString());
    dayAllowed.controls.endTime.setValue(moment().endOf('day').toISOString());
  }

  canCopy(dayAllowed: FormGroup<IDaysAllowedFormGroup>) {
    const { startTime, endTime } = dayAllowed.controls;
    return startTime.valid && endTime.valid;
  }

  copyTime(dayAllowed: FormGroup<IDaysAllowedFormGroup>) {
    const { startTime, endTime } = dayAllowed.controls;
    this.clipboardTime = {
      startTime: startTime.value,
      endTime: endTime.value
    };
  }

  pasteTime(dayAllowed: FormGroup<IDaysAllowedFormGroup>, clipboardTime: IClipboardTime) {
    dayAllowed.controls.startTime.setValue(clipboardTime.startTime);
    dayAllowed.controls.endTime.setValue(clipboardTime.endTime);
  }

  canPaste(dayAllowed: FormGroup<IDaysAllowedFormGroup>, clipboardTime: IClipboardTime) {
    const { endTime, startTime } = dayAllowed.controls;
    return endTime.value !== clipboardTime.endTime || startTime.value !== clipboardTime.startTime;
  }
}

function sortDaysAllowedFormGroups(a: FormGroup<IDaysAllowedFormGroup>, b: FormGroup<IDaysAllowedFormGroup>) {
  const dayA = DAYS_OF_WEEK_INDEX[a.controls.day.value];
  const dayB = DAYS_OF_WEEK_INDEX[b.controls.day.value];
  if (dayA > dayB) {
    return 1;
  }
  if (dayB > dayA) {
    return -1;
  }
  return 0;
}
