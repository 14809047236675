import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { timeout } from 'rxjs/operators';
import { ModalCreateParkingSpot } from '../../../components/modal-create-parking-spot/modal-create-parking-spot';
import { BsModalService } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import { EcondosFilter } from '@api/model/filter';
import { EcondosQuery } from '@api/model/query';
import { ParkingSpotService } from '@api/service/parking-spot.service';
import { ParkingSpot } from '@api/model/parking-spot';
import { capitalize } from '@api/util/util';
import { parkingIcon } from 'assets/svg/custom-icons';
import {
  TableColumnDefinition,
  TableComponent,
  TablePageChangeEventData,
  TableSortChangeEventData,
  TableSortOrder,
  TableStatus
} from 'app/components/table/table.component';
import { ClearFiltersEventData, RemoveFilterEventData } from 'app/components/applied-filters/applied-filters.component';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'app-condo-parking-spot',
  templateUrl: 'condo-parking-spot.html',
  styleUrls: ['condo-parking-spot.scss']
})
export class CondoParkingSpotComponent implements OnInit, AfterViewInit {
  @ViewChild('parkingSpotsTable', { static: false }) parkingSpotsTable: TableComponent;
  @ViewChild('updateStatusParkingSpotTemplate', { static: true }) updateStatusParkingSpotTemplate: TemplateRef<any>;
  @ViewChild('timerCellTemplate', { static: true }) timerCellTemplate: TemplateRef<any>;

  user: User;
  condo: Condo;

  displayMode: 'CARD' | 'TABLE' = 'TABLE';
  parkingIcon = parkingIcon;

  parkingSpots: ParkingSpot[] = [];
  filteredParkingSpots: ParkingSpot[] = [];
  typeSpotFilter = '';

  messages = {
    emptyMessage: '',
    totalMessage: 'vagas(s)'
  };

  initialQuery: EcondosQuery = {
    $populate: [
      { path: 'residence', select: 'identification' },
      { path: 'condoContact', select: 'firstName lastName fullName' },
      { path: 'device', select: 'owner', populate: { path: 'owner.condoVehicle', select: 'plate' } }
    ],
    $and: [],
    $or: []
  };

  customQuery = { ...this.initialQuery };

  statusTable: TableStatus = 'LOADING';
  statusCard: 'LOADING' | 'SUCCESS' | 'ERROR' = 'LOADING';
  tableColumns: TableColumnDefinition<ParkingSpot>[] = [];

  totalParkingSpotCount = 0;

  filters: EcondosFilter[] = [];
  isFiltered = false;
  isAdmin = false;

  currentPage = 1;
  pageSize = 15;
  sortedColumn = '';
  sortOrder: TableSortOrder = 'desc';

  constructor(
    private sessionService: SessionService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private parkingSpotService: ParkingSpotService
  ) {
    this.user = this.sessionService.userValue;
    this.condo = this.sessionService.condoValue;
    this.isAdmin = this.user.isAdmin() || this.user.isOwner();
    this.displayMode = this.getLocalDisplayMode();

    this.filters = [
      {
        element: 'residence-picker',
        label: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        name: 'residence',
        params: {
          condo: this.condo,
          showAsInputGroup: true,
          typeaheadHideResultsOnBlur: true,
          placeholder: 'Digite um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade',
          adaptivePosition: true,
          clearInputTextAfterSelect: false
        },
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'name',
        label: 'Nome',
        placeholder: 'Buscar por nome',
        searchType: 'regex'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'isAvailable',
        selectOptions: [
          { label: 'Todas', value: '' },
          { label: 'Disponiveis', value: 'true' },
          { label: 'Ocupadas', value: 'false' }
        ],
        label: 'Status',
        searchType: 'match'
      }
    ];
  }

  ngOnInit() {
    this.tableColumns = [
      {
        columnId: 'residence',
        headerLabel: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        valueFn: spot => (!spot.residence ? '-' : spot.residence?.identification),
        linkUrl: spot => (!spot.isVisitorSpot && spot.residence?._id ? `/condo/residences/${spot.residence._id}` : ''),
        show: true,
        sortable: true
      },
      {
        columnId: 'name',
        headerLabel: 'Nome',
        valueFn: spot => spot.name || 'Não Informado',
        sortable: true
      },
      {
        columnId: 'obs',
        headerLabel: 'Observação',
        valueFn: spot => spot.obs || 'Não Informado',
        sortable: true
      },
      {
        columnId: 'isVisitorSpot',
        headerLabel: 'Tipo',
        valueFn: spot => (spot?.isVisitorSpot ? 'Visitantes' : capitalize(this.condo.customLabels.resident.plural) || 'Condôminos'),
        sortable: true
      },
      {
        columnId: 'isAvailable',
        headerLabel: 'Status',
        valueTemplate: this.updateStatusParkingSpotTemplate,
        sortable: true
      },
      {
        columnId: 'occupiedAt',
        headerLabel: 'Tempo de permanência',
        valueTemplate: this.timerCellTemplate,
        sortable: true
      },
      {
        columnId: 'device',
        headerLabel: 'Ocupada por',
        valueKey: 'device',
        valueFn: spot => (spot.device?.owner ? spot.device?.owner?.condoVehicle?.plate : 'Não Informado'),
        sortable: true
      }
    ];
    if (this.user.isOwner() || this.user.isAdmin()) {
      this.tableColumns.push({
        type: 'actions',
        headerLabel: 'Ações',
        actionsButtons: [
          { icon: 'fa-pencil', title: 'Editar vaga', handler: (spot: ParkingSpot) => this.edit(spot) },
          { icon: 'fa-trash', title: 'Remover vaga', handler: (spot: ParkingSpot) => this.delete(spot) }
        ]
      });
    }
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  getData({ page = 0 } = {}) {
    this.statusTable = 'LOADING';
    this.statusCard = 'LOADING';
    const query = { ...this.customQuery };

    query.$page = page;
    query.$limit = this.pageSize;
    query.$sort = '-createdAt';

    if (this.sortedColumn) {
      query.$sort = `${this.sortOrder === 'desc' ? '-' : ''}${this.sortedColumn}`;
    }

    if (this.typeSpotFilter) {
      query.isVisitorSpot = this.typeSpotFilter === 'visitors';
    }

    if (this.typeSpotFilter === 'visitors') {
      this.tableColumns = this.tableColumns.map(column =>
        column.columnId === 'residence'
          ? {
              ...column,
              show: false
            }
          : column
      );
    } else {
      this.tableColumns = this.tableColumns.map(column =>
        column.columnId === 'residence'
          ? {
              ...column,
              show: true
            }
          : column
      );
    }

    this.parkingSpotService
      .get(this.condo._id, query)
      .pipe(timeout(10000))
      .subscribe({
        next: ({ count, parkingSpots }) => {
          this.totalParkingSpotCount = count;
          this.parkingSpots = parkingSpots;
          this.filteredParkingSpots = [].concat(this.parkingSpots);
          this.statusTable = 'SUCCESS';
          this.statusCard = 'SUCCESS';
          if (parkingSpots.length == 0) {
            this.messages.emptyMessage = `Nenhuma vaga cadastrada neste(a) ${this.condo?.customLabels?.condo?.singular}.`;
          }
        },
        error: err => {
          this.statusTable = 'ERROR';
          this.statusCard = 'ERROR';
          this.messages.emptyMessage = 'Não foi possível buscar vagas. Verifique sua conexão e tente novamente!';
          this.parkingSpots = [];
          this.filteredParkingSpots = [];
        }
      });
  }

  create() {
    const initialState = {
      condo: this.condo,
      onCreate: () => {
        this.getData({ page: (this.currentPage = 0) });
      }
    };
    this.modalService.show(ModalCreateParkingSpot, { initialState });
  }

  edit(spot: ParkingSpot) {
    const initialState = {
      condo: this.condo,
      parkingSpot: spot,
      onUpdate: () => {
        this.getData({ page: (this.currentPage = 0) });
      }
    };
    this.modalService.show(ModalCreateParkingSpot, { initialState });
  }

  toggleDisplayMode(displayMode: typeof this.displayMode) {
    this.displayMode = displayMode;
    this.saveLocalDisplayMode();

    if (this.displayMode === 'TABLE') {
      setTimeout(() => {
        this.parkingSpotsTable.setCurrentPage(this.currentPage);
      }, 0);
    }
  }

  saveLocalDisplayMode() {
    localStorage.setItem('econdos.parkingSpotDisplayMode', this.displayMode);
  }

  getLocalDisplayMode() {
    try {
      const displayMode = localStorage.getItem('econdos.parkingSpotDisplayMode');
      return displayMode === 'TABLE' ? 'TABLE' : 'CARD';
    } catch (err) {
      return 'TABLE';
    }
  }

  delete(spot: ParkingSpot) {
    swal({
      type: 'question',
      text: `Deseja remover a vaga ${spot.name} ?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.parkingSpotService
          .delete(this.condo._id, spot._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível excluir a vaga, tente novamente...');
          });
      }
    }).then(
      () => {
        this.toastr.success('Vaga removida com sucesso');
        let index = this.parkingSpots.findIndex(d => d._id === spot._id);
        this.parkingSpots.splice(index, 1);
        this.parkingSpots = [].concat(this.parkingSpots);
        index = this.filteredParkingSpots.findIndex(d => d._id === spot._id);
        this.filteredParkingSpots.splice(index, 1);
        this.filteredParkingSpots = [].concat(this.filteredParkingSpots);
      },
      error => {
        console.log(error);
      }
    );
  }

  updateStatus(parkingSpot) {
    const spotIndex = this.filteredParkingSpots.findIndex(spot => parkingSpot._id === spot._id);

    parkingSpot.isAvailable = !parkingSpot.isAvailable;

    this.parkingSpotService.update(this.condo._id, parkingSpot._id, parkingSpot).subscribe({
      next: () => {
        if (parkingSpot.isAvailable) {
          parkingSpot.occupiedAt = null;
          parkingSpot.occupiedBy = null;
        }

        this.filteredParkingSpots[spotIndex] = parkingSpot;

        this.handleRefreshData();
        this.toastr.success('Vaga atualizada com sucesso.');
      },
      error: err => {
        console.log(err);
        this.statusTable = 'ERROR';
        this.statusCard = 'ERROR';
        this.toastr.error('Não foi possível atualizar a vaga, tente novamente');
      }
    });
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        this.filters = filters;
        this.isFiltered = this.filters.some(filter => !!filter.value);
        this.customQuery = query;
        this.getData();
      }
    };

    this.modalService.show(ModalFilterComponent, {
      initialState,
      class: 'modal-md'
    });
  }

  removeIndividualFilter({ filters, removedFilterName }: RemoveFilterEventData) {
    this.filters = filters;
    this.isFiltered = this.filters.some(filter => !!filter.value);

    delete this.customQuery[removedFilterName];
    this.getData();
  }

  clearFilters({ filters }: ClearFiltersEventData) {
    this.filters = filters;
    this.isFiltered = false;
    this.customQuery = { ...this.initialQuery };
    this.getData();
  }

  handleTablePageChange({ queryPageNumber, tablePageNumber, pageSize }: TablePageChangeEventData) {
    this.currentPage = tablePageNumber;
    this.pageSize = pageSize;

    const { sortedColumn, sortOrder } = this.parkingSpotsTable.getCurrentState();

    this.sortedColumn = typeof sortedColumn === 'string' ? sortedColumn : '';
    this.sortOrder = sortOrder;

    this.getData({ page: queryPageNumber });
  }

  handleCardViewPageChange(page: number) {
    this.currentPage = page;
    this.getData({ page: page - 1 });
  }

  handleRefreshData() {
    const page = this.currentPage > 0 ? this.currentPage - 1 : 0;
    this.getData({ page });
  }

  handleTableSortChange({ column, order }: TableSortChangeEventData) {
    this.sortOrder = order;
    this.sortedColumn = typeof column === 'string' ? column : '';
    this.getData();
  }
}
