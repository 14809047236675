import { Component, ElementRef, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { cpfValidator, emailValidator } from '../../../api/util/validators';
import { UF_LIST } from '../../../api/util/constants';
import { formatCep, formatCnpj, formatCpf, formatPhone } from '../../../api/util/formatters';
import { HttpService } from '../../../services/http.service';
import { CondoService, CondoSubscription } from '../../../api/service/condo.service';
import { UtilService } from '../../../services/util.service';
import { Error } from '../../../api/model/error/error';
import { Status } from '../../../api/model/status';
import { Coupon } from '../../../api/model/coupon';
import { CouponService } from '../../../api/service/coupons.service';
import { Condo } from '../../../api/model/condo';
import { forkJoin } from 'rxjs';
import { ResidenceService } from '../../../api/service/residence.service';
import { UserService } from '../../../api/service/user.service';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '../../../api/model/error/error.builder';

@Component({
  selector: 'subscription-page',
  templateUrl: 'subscription.html'
})
export class SubscriptionPage {
  @ViewChild('numberInput') numberInput: ElementRef;

  public TYPES = {
    PJ: 'PJ',
    PF: 'PF'
  };

  public selectedType: string = this.TYPES.PF;
  public form;
  public name: AbstractControl;
  public coupon: AbstractControl;
  public numberOfResidences: AbstractControl;
  public phone: AbstractControl;
  public cpf: AbstractControl;
  public cnpj: AbstractControl;
  public email: AbstractControl;
  public zipcode: AbstractControl;
  public address: AbstractControl;
  public number: AbstractControl;
  public city: AbstractControl;
  public state: AbstractControl;
  public neighborhood: AbstractControl;
  public complement: AbstractControl;

  public defaultPrice: number;
  public minimumPrice: number;
  public minimumNumberOfResidences: number;
  public numberOfCondoResidences: number;
  public priceStatus: Status = new Status();

  public appliedCoupon: Coupon;
  public applyCouponStatus: Status = new Status();

  public zipcodeStatus: Status = new Status();

  public submitStatus: Status = new Status();

  public condo: Condo;

  public ufList = UF_LIST;

  public couponCode = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private couponService: CouponService,
    private residenceService: ResidenceService,
    private route: ActivatedRoute,
    private condoService: CondoService,
    private userService: UserService,
    private httpService: HttpService
  ) {
    this.condo = this.utilService.getLocalCondo();
    this.couponCode = this.route.snapshot.params['couponCode'];
    this.initialize();
  }

  setupForm() {
    const sale: any = this.condo.sale || {};

    this.form = this.formBuilder.group({
      name: [sale.name || '', Validators.compose([Validators.required, Validators.minLength(3)])],
      coupon: ['', Validators.compose([Validators.minLength(9), Validators.maxLength(20)])],
      numberOfResidences: [
        sale.numberOfResidences || this.numberOfCondoResidences || '',
        Validators.compose([Validators.required, Validators.min(this.numberOfCondoResidences || 1)])
      ],
      phone: [sale.phone || '', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])],
      cpf: [
        sale.document ? formatCpf(sale.document.number) : '',
        Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15), cpfValidator])
      ],
      email: [sale.email || '', Validators.compose([Validators.required, emailValidator])],
      zipcode: [formatCep(sale.zipcode) || '', Validators.compose([Validators.required])],
      address: [sale.address || '', Validators.compose([Validators.required])],
      number: ['', Validators.compose([Validators.required])],
      neighborhood: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      complement: ['']
    });

    this.name = this.form.get('name');
    this.coupon = this.form.get('coupon');
    this.numberOfResidences = this.form.get('numberOfResidences');
    this.phone = this.form.get('phone');
    this.cpf = this.form.get('cpf');
    this.email = this.form.get('email');
    this.zipcode = this.form.get('zipcode');
    this.address = this.form.get('address');
    this.number = this.form.get('number');
    this.city = this.form.get('city');
    this.state = this.form.get('state');
    this.neighborhood = this.form.get('neighborhood');
    this.complement = this.form.get('complement');

    const shouldDisable = !!this.condo.sale;
    if (shouldDisable) {
      // TODO enable coupon GET after back fixed
      this.coupon.setValue(this.condo.sale.coupon);
      // if(this.condo.sale.coupon) this.applyUsedCoupon();

      this.selectedType = this.condo.sale.document.type == 'CPF' ? this.TYPES.PF : this.TYPES.PJ;
      Object.keys(this.form.controls).forEach(key => this.form.get(key).disable());
    } else {
      if (this.couponCode) {
        this.coupon.setValue(this.couponCode);
        this.applyCoupon(this.couponCode);
      }
    }

    this.cnpj = new UntypedFormControl(
      {
        value: sale.document ? formatCpf(sale.document.number) : '',
        disabled: shouldDisable
      },
      Validators.compose([Validators.minLength(18), Validators.maxLength(18)])
    );

    this.cpf.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(cpf => {
      if (!cpf) {
        return;
      }
      const val = cpf.trim();
      this.cpf.setValue(formatCpf(cpf), { emitEvent: false });
    });
    this.cnpj.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(cnpj => {
      if (!cnpj) {
        return;
      }
      cnpj = cnpj.trim();
      this.cnpj.setValue(formatCnpj(cnpj), { emitEvent: false });
    });
    this.phone.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(phone => {
      if (!phone) {
        return;
      }
      const val = phone.trim();
      this.phone.setValue(formatPhone(phone), { emitEvent: false });
    });
    this.zipcode.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(zipcode => {
      if (!zipcode) {
        return;
      }
      const val = zipcode.trim();
      this.searchZipcode(val);
      this.zipcode.setValue(formatCep(zipcode), { emitEvent: false });
    });
    this.numberOfResidences.valueChanges.pipe(debounceTime(50)).subscribe(numberOfResidences => {
      if (!numberOfResidences) {
        return;
      }
      const val = numberOfResidences
        .toString()
        .trim()
        .replace(/[^0-9.]/g, '');
      this.numberOfResidences.setValue(val, { emitEvent: false });
    });
  }

  initialize() {
    this.priceStatus.setAsDownloading();
    forkJoin(this.condoService.getSubscriptionPrices(this.condo.id), this.residenceService.countCondoResidences(this.condo.id))
      .pipe(timeoutWith(15000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        responses => {
          this.defaultPrice = responses[0].defaultPrice;
          this.minimumPrice = responses[0].minimumPrice;
          this.minimumNumberOfResidences = Math.trunc(100 / (this.defaultPrice || 100));
          this.numberOfCondoResidences = responses[1];
          this.priceStatus.setAsSuccess();
          this.setupForm();
        },
        err => {
          this.priceStatus.setAsError();
        }
      );
  }

  searchZipcode(code) {
    if (code.length < 9) {
      return;
    }
    this.zipcodeStatus.setAsDownloading();
    this.disableAddress();
    this.httpService.consultarCEP(code.replace('-', '')).subscribe(
      data => {
        if (!('erro' in data)) {
          this.address.setValue(data.logradouro || '');
          this.city.setValue(data.localidade || '');
          this.state.setValue(data.uf || '');
          this.neighborhood.setValue(data.bairro || '');
          this.zipcodeStatus.setAsSuccess();
          this.enableAddress();
          setTimeout(() => this.numberInput.nativeElement.focus(), 50);
        } else {
          this.address.setValue('');
          this.city.setValue('');
          this.state.setValue('');
          this.neighborhood.setValue('');
          this.zipcodeStatus.setAsError();
          this.enableAddress();
        }
        this.address.markAsTouched();
        this.city.markAsTouched();
        this.state.markAsTouched();
        this.neighborhood.markAsTouched();
      },
      err => {
        this.address.setValue('');
        this.city.setValue('');
        this.state.setValue('');
        this.neighborhood.setValue('');
        this.zipcodeStatus.setAsError();
        this.enableAddress();
      }
    );
  }

  disableAddress() {
    this.address.disable();
    this.number.disable();
    this.neighborhood.disable();
    this.zipcode.disable();
    this.city.disable();
    this.state.disable();
  }

  enableAddress() {
    this.address.enable();
    this.number.enable();
    this.neighborhood.enable();
    this.zipcode.enable();
    this.city.enable();
    this.state.enable();
  }

  applyUsedCoupon() {
    // let qs = [];
    // qs[qs.length] = '$populate[0][path]=sale.coupon';
    // qs[qs.length] = '$populate[0][select]=couponCode discount vendorCode status';
    //
    // this.applyCouponStatus.setAsDownloading();
    // this.condoService.getCondoById(this.condo.id,`?${qs.join('&')}`).subscribe(condo => {
    //   this.appliedCoupon = condo.sale.coupon;
    //   this.applyCouponStatus.setAsSuccess();
    //   this.coupon.disable();
    // }, err => {
    //   this.applyCouponStatus.setAsError();
    //   this.appliedCoupon = null;
    // })
  }

  applyCoupon(couponCode) {
    if (!couponCode) {
      return;
    }

    this.applyCouponStatus.setAsDownloading();
    this.couponService.getCouponByCode(this.condo.id, couponCode).subscribe(
      coupon => {
        this.appliedCoupon = coupon;
        this.applyCouponStatus.setAsSuccess();
        this.coupon.disable();
      },
      err => {
        this.applyCouponStatus.setAsError();
        this.appliedCoupon = null;
      }
    );
  }

  submit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).markAsTouched());
      return;
    }

    this.submitStatus.setAsProcessing();
    const address = this.buildAddress();

    let documentNumber = this.selectedType === this.TYPES.PF ? this.cpf.value : this.cnpj.value;
    documentNumber = documentNumber.toString().replace(/[^0-9]/g, '');
    const document = { number: documentNumber, type: this.selectedType === this.TYPES.PF ? 'CPF' : 'CNPJ' };
    const subscriotionData: CondoSubscription = {
      name: this.name.value,
      numberOfResidences: this.numberOfResidences.value,
      email: this.email.value,
      phone: this.phone.value.toString().replace(/[^0-9]/g, ''),
      zipcode: this.zipcode.value,
      document,
      address
    };
    if (this.appliedCoupon) {
      subscriotionData.coupon = this.appliedCoupon.id;
    }
    this.condoService
      .subscribeCondo(this.condo.id, subscriotionData)
      .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        res => {
          swal({
            type: 'success',
            title: 'Agora falta pouco...',
            text: 'Solicitação enviada com sucesso, em breve entraremos em contato e você poderá desfrutar de todas as funcionalidades do sistema'
          });
          this.submitStatus.setAsSuccess();
        },
        (err: Error) => {
          let msg = '';
          if (err.originalErrorMessage.includes('Condo already subscribed')) {
            msg = `Este ${this.condo?.customLabels?.condo?.singular} já possui assinatura do sistema`;
          } else {
            msg =
              err.message ||
              'Não foi possível realizar sua solicitação, tente novamente. Se o problema persistir, entre em contato com a nossa equipe';
          }
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: msg
          });
          this.submitStatus.setAsError();
        }
      );
  }

  buildAddress() {
    let address = '';
    address += this.address.value;
    address += ', Nº ';
    address += this.number.value;
    if (this.complement.value) {
      address += ' ';
      address += this.complement.value;
    }
    address += ', ';
    address += this.neighborhood.value;
    address += ' - ';
    address += this.city.value;
    address += ' - ';
    address += this.state.value;
    return address;
  }

  setType(type: string) {
    if (this.condo.sale) {
      return;
    }

    this.selectedType = type;
    if (type === this.TYPES.PJ) {
      this.form.removeControl('cpf');
      this.form.addControl('cnpj', this.cnpj);
    }
    if (type === this.TYPES.PF) {
      this.form.removeControl('cnpj');
      this.form.addControl('cpf', this.cpf);
    }
  }

  getPriceWithoutDiscount() {
    let value = this.numberOfResidences.value * this.defaultPrice;
    value = Math.max(value, this.minimumPrice);
    return value;
  }

  getPriceWithDiscount() {
    let value;
    if (this.appliedCoupon) {
      value = this.numberOfResidences.value * this.defaultPrice * (1 - this.appliedCoupon.discount / 100);
    } else {
      value = this.getPriceWithoutDiscount();
    }
    value = Math.max(value, this.minimumPrice);
    return value;
  }
}
