import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ThemeModule } from '../../../theme';
import { PipesModule } from '../../../../app/pipe/pipes.module';

import { ModalConfigDeliveryTrackingComponent } from './modal-config-delivery-tracking.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, PipesModule, ModalModule, ThemeModule, TooltipModule, BsDropdownModule],
  declarations: [ModalConfigDeliveryTrackingComponent]
})
export class ModalConfigDeliveryTrackingModule {}
