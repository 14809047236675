import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Dependent } from '../model/dependent';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { downloadDataInChunks } from '@api/utils';

@Injectable()
export class DependentService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /** @Deprecated Use getDependents instead */
  get(condoId: string, params: Array<{ [key: string]: string }> = []): Observable<{ count: number; dependents: Dependent[] }> {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/dependents`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            dependents: res.body.map(dep => new Dependent(dep))
          };
        })
      );
  }

  getDependents(condoId: string, query: EcondosQuery): Observable<{ count: number; dependents: Dependent[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/dependents`, options).pipe(
      map((res: any) => {
        if (query.$count) {
          return {
            count: res.body[0],
            dependents: []
          };
        } else {
          return {
            count: res.headers.get('count'),
            dependents: res.body.map(dep => new Dependent(dep))
          };
        }
      })
    );
  }

  getDependentUnmaskedField(condoId: string, dependentId: string, field: string, params: EcondosQuery = {}): Observable<{ data: any }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/dependents/${dependentId}/lgpd/${field}`, options).pipe(
      map((res: any) => ({
        data: res.body[field]
      }))
    );
  }

  create(condoId: string, dependent: Dependent) {
    return this.http.post(`${this.endPoint}${condoId}/dependents`, dependent);
  }

  update(condoId: string, dependentId: string, dependent: any) {
    return this.http.put(`${this.endPoint}${condoId}/dependents/${dependentId}`, dependent);
  }

  delete(condoId: string, dependentId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/dependents/${dependentId}`);
  }

  getById(condoId: string, dependentId: string): Observable<Dependent> {
    return this.http.get(`${this.endPoint}${condoId}/dependents/${dependentId}`).pipe(map(v => new Dependent(v)));
  }

  getDependentsReport(condoId: string, query: EcondosQuery) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/dependents`, query, { model: Dependent });
  }

  //promote um dependent para usuário, retorna o id do usuário promovido se necessário
  convertDependentToUser(condoId: string, dependentId: string) {
    return this.http.put(`${this.endPoint}${condoId}/dependents/${dependentId}/convertToUser`, {});
  }
  getRooms(condoId: string) {
    const options = {
      headers: new HttpHeaders(),
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/dependents/rooms`, options).pipe(
      map((res: any) => ({
        rooms: res.body
      }))
    );
  }
}
