import { Injectable } from '@angular/core';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { debounceTime, expand, map, reduce, scan, switchMap } from 'rxjs/operators';
import { defer, EMPTY, iif, merge, Observable, of, Subject, timer } from 'rxjs';
import { Actuator } from '@api/model/hardware/actuator';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import * as moment from 'moment';
import { SdkService } from '@api/service/hardware/sdk.service';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { IntelbrasStandAloneService } from '@api/service/hardware/intelbras-stand-alone.service';

const TIME_IN_MILLI = {
  SECOND: 1_000,
  MINUTE: 60_000,
  HOUR: 3_600_000,
  DAY: 86_400_000
};

const FIFTEEN_MINUTES = TIME_IN_MILLI.MINUTE * 15;
const SEVENTEEN_HOURS = TIME_IN_MILLI.HOUR * 17;
const TEN_SECONDS = TIME_IN_MILLI.SECOND * 10;

interface IActuatorWithStatus {
  actuator: Actuator;
  success: boolean;
}

interface IStoredActuator {
  actuator: Actuator;
  lastChecked: Date;
}

@Injectable({ providedIn: 'root' })
export class WatchActuatorsService {
  private condo: Condo;

  private validActuators: IStoredActuator[] = [];

  private _onRun$: Subject<void> = new Subject();
  private onRun$ = this._onRun$
    .asObservable()
    .pipe(
      debounceTime(1_000),
      switchMap(() => timer(FIFTEEN_MINUTES, SEVENTEEN_HOURS)),
      switchMap(() => {
        const storedActuators = this.getLocalStorageActuators(this.condo._id);
        this.validActuators = this.getValidActuators(storedActuators);
        return this.getAllActuators(
          this.condo._id,
          this.validActuators.map(({ actuator }) => actuator._id)
        );
      }),
      switchMap(actuators => {
        const requests = actuators.map(a => this.checkConnectionAndDisablePictureUpload(a));
        return merge(...requests).pipe(scan((acc, value) => [...acc, value], [] as IActuatorWithStatus[]));
      })
    )
    .subscribe({
      next: actuators => {
        const disabledActuators = actuators
          .filter(({ success }) => success)
          .map(({ actuator }) => ({
            actuator,
            lastChecked: new Date()
          }));

        disabledActuators.forEach(value => {
          const index = this.validActuators.findIndex(a => a.actuator._id === value.actuator._id);
          if (index === -1) {
            this.validActuators.push(value);
          }
        });

        localStorage.setItem(`econdos.condo.${this.condo._id}.actuators`, JSON.stringify(this.validActuators));
      }
    });

  constructor(
    private actuatorService: ActuatorService,
    private sdkService: SdkService,
    private hikvisionService: HikvisionService,
    private intelbrasService: IntelbrasStandAloneService
  ) {}

  initialize(user: User, condo: Condo) {
    const isValidUser = user.isOwnerOnCondo(condo?._id) || user.isAdminOnCondo(condo?._id) || user.isGatekeeperOnCondo(condo?._id);
    if (!isValidUser) return;
    this._onRun$.next();
    this.condo = condo;
  }

  private checkActuatorConnection(actuator: Actuator): Observable<boolean> {
    let request: Observable<{ status: boolean; _id: string }>;
    switch (actuator.hardware) {
      case HARDWARES.HIKVISION: {
        request = defer(() => this.hikvisionService.getTime(actuator));
        break;
      }
      case HARDWARES.INTELBRAS_STAND_ALONE:
        request = defer(() => this.intelbrasService.checkConnection(actuator));
        break;
    }
    return request.pipe(map(({ status }) => status));
  }

  private checkConnectionAndDisablePictureUpload(actuator: Actuator): Observable<IActuatorWithStatus> {
    return this.checkActuatorConnection(actuator).pipe(
      switchMap(status => iif(() => status, this.disablePictureUpload(actuator), of({ actuator, success: false })))
    );
  }

  private disablePictureUpload(actuator: Actuator): Observable<IActuatorWithStatus> {
    let observable: Observable<{ success: boolean }>;
    switch (actuator.hardware) {
      case HARDWARES.HIKVISION:
        observable = this.sdkService.disableUploadPicture(actuator);
        break;
      case HARDWARES.INTELBRAS_STAND_ALONE:
        observable = this.intelbrasService.disableUploadPicture(actuator);
        break;
    }
    return observable.pipe(map(({ success }) => ({ actuator, success })));
  }

  private getValidActuators(storedActuators: IStoredActuator[]): IStoredActuator[] {
    return storedActuators.filter(({ lastChecked }) => {
      const expireDate = moment(lastChecked).add(5, 'days');
      const now = moment();
      return now.isBefore(expireDate);
    });
  }

  private getLocalStorageActuators(condoId: string): IStoredActuator[] {
    const savedActuatorsString = localStorage.getItem(`econdos.condo.${condoId}.actuators`);
    try {
      const savedActuators = JSON.parse(savedActuatorsString.toString());
      return savedActuators.map(({ actuator, lastChecked }) => ({
        actuator: actuator as Actuator,
        lastChecked: new Date(lastChecked)
      }));
    } catch (err) {
      return [];
    }
  }

  private getAllActuators(condoId: string, exceptions: string[] = []): Observable<Actuator[]> {
    const getActuators$ = (page = 0, limit = 1_000) =>
      this.actuatorService
        .getActuators(condoId, {
          hardware: { $in: [HARDWARES.HIKVISION, HARDWARES.INTELBRAS_STAND_ALONE] },
          type: { $ne: 'DEEPIN_VIEW_LPR' },
          $page: page,
          $limit: limit,
          ...(exceptions.length && { _id: { $nin: exceptions } })
        })
        .pipe(map(({ actuators }) => ({ actuators, next: actuators?.length === limit, page })));

    return getActuators$().pipe(
      expand(({ next, page }) => {
        if (next) {
          return getActuators$(page + 1);
        }
        return EMPTY;
      }),
      reduce((acc, { actuators = [] }) => acc.concat(actuators), [])
    );
  }

  terminate() {
    this.onRun$.unsubscribe();
  }
}
