import { Component, OnInit } from '@angular/core';
import { UserService } from '../../api/service/user.service';
import { ActivatedRoute, Router } from '@angular/router';

import swal from 'sweetalert2';

@Component({
  template: ''
})
export class UserActivation {
  private token: string;

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) {
    this.token = this.route.snapshot.params['activationKey'];

    if (!(this.token && this.token.length)) {
      this.goToLoginPage();
    } else {
      this.activateUser();
    }
  }

  activateUser() {
    this.userService.activateUserWithToken(this.token).subscribe(
      () => {
        swal({
          type: 'success',
          title: 'Parabéns!',
          text: 'Seu usuário foi ativado com sucesso!\nInsira seu e-mail e senha para começar a usar nossa plataforma!'
        });
      },
      err => {
        swal({
          title: 'Ops...',
          text: 'Ocorreu um erro ao tentar ativar seu usuário.\nPor favor, tente novamente.'
        });
      }
    );

    this.goToLoginPage();
  }

  goToLoginPage() {
    this.router.navigate(['/']);
  }
}
