import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { VirtualKey } from '../model/virtual-key';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { ConstantService } from '../../services/constant.service';
import { HttpService } from '../../services/http.service';

@Injectable({ providedIn: 'root' })
export class VirtualKeyService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; virtualKeys: VirtualKey[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/virtual-keys`, { params: httpParams }).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count'),
          virtualKeys: res.body
        };
      })
    );
  }

  create(condoId: string, virtualKey: VirtualKey) {
    return this.http
      .post(`${this.endPoint}${condoId}/virtual-keys`, virtualKey)
      .pipe(map((res: { _id: string }) => ({ ...virtualKey, _id: res._id })));
  }

  update(condoId: string, virtualKeyId: string, virtualKey: VirtualKey) {
    return this.http.put(`${this.endPoint}${condoId}/virtual-keys/${virtualKeyId}`, virtualKey);
  }

  delete(condoId: string, virtualKeyId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/virtual-keys/${virtualKeyId}`);
  }

  getById(condoId: string, virtualKeyId: string): Observable<VirtualKey> {
    return this.http.get(`${this.endPoint}${condoId}/virtual-keys/${virtualKeyId}`) as Observable<VirtualKey>;
  }

  triggerKey(condoId: string, virtualKeyId: string, latitude?: number, longitude?: number) {
    return this.http.put(`${this.endPoint}${condoId}/virtual-keys/${virtualKeyId}/trigger`, { latitude, longitude });
  }
}
