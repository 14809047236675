import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Camera, CAMERA_PROTOCOLS } from '@api/model/camera';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import PlayerControl from '@libs/dahua-camera/src';
import { from } from 'rxjs';
import { createImageFromBlob, isElectron } from '@api/utils';

@Component({
  selector: 'app-dahua-rtsp-camera-viewer',
  templateUrl: './dahua-rtsp-camera-viewer.component.html',
  styleUrls: ['./dahua-rtsp-camera-viewer.component.scss']
})
export class DahuaRtspCameraViewerComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('canvasElement', { static: false }) canvasElement;

  @Input() camera: Camera | null = null;
  @Input() fitParentSize = true;
  @Input() displayCameraName = true;

  @Input() placeholderHeight = '100%';
  @Input() placeholderWidth = '100%';

  @Input() containerStyle: { [key: string]: string } = { height: '100%', 'max-height': '100%' };

  @Output() handleSnapshot: EventEmitter<string> = new EventEmitter();
  @Output() handleSuccessLoad = new EventEmitter();
  @Output() handleError: EventEmitter<any> = new EventEmitter();
  @Output() handleClick: EventEmitter<Camera> = new EventEmitter();

  public status: 'PROCESSING' | 'SUCCESS' | 'ERROR' = 'PROCESSING';

  CAMERA_PROTOCOLS = CAMERA_PROTOCOLS;

  player: PlayerControl;

  numberOfErrors = 0;

  maxNumberOfErrors = 2;

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.canvasElement) {
      if (changes.camera?.currentValue) {
        if (this.status !== 'PROCESSING') {
          this.status = 'PROCESSING';
          this.loadRtspPlayer(changes.camera.currentValue);
        }
      } else {
        this.destroy();
      }
    }
  }

  ngAfterViewInit(): void {
    this.loadRtspPlayer(this.camera);
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  private destroy() {
    if (this.player) {
      this.player.close();
    }
  }

  private loadCamera(camera: Camera) {
    if (this.status !== 'PROCESSING') {
      this.status = 'PROCESSING';
      this.loadRtspPlayer(camera);
    }
  }

  private loadRtspPlayer(camera: Camera) {
    this.destroy();
    // https://github.com/TeamDotworld/dahua-rtsp-web
    const websocketUrl = `${camera.host}:${camera.port}/rtspoverwebsocket`;
    let wsURL = '';
    if (isElectron()) {
      wsURL = `ws://${websocketUrl}`;
    } else {
      wsURL = `ws://localhost:5053?${btoa(websocketUrl)}`;
    }

    const options = {
      wsURL,
      rtspURL: camera.rtspUrl,
      username: camera.user,
      password: camera.password
    };

    const player = new PlayerControl(options);
    this.renderer.setStyle(this.canvasElement.nativeElement, 'display', 'none');
    player.on('PlayStart', () => {
      if (this.fitParentSize) {
        this.renderer.setStyle(this.canvasElement.nativeElement, 'max-height', '100%');
        this.renderer.setStyle(this.canvasElement.nativeElement, 'max-width', '100%');
        this.renderer.setStyle(this.canvasElement.nativeElement, 'aspect-ratio', '16/9');
      }
      this.renderer.setStyle(this.canvasElement.nativeElement, 'display', 'inline-block');
      this.player = player;
      this.status = 'SUCCESS';
      this.handleSuccessLoad.emit();
      this.cdr.markForCheck();
    });

    player.on('Error', error => {
      if (error) {
        console.log(error);
        console.log(error.errorCode);
        this.numberOfErrors++;
        if (this.numberOfErrors < this.maxNumberOfErrors) {
          this.loadRtspPlayer(camera);
        } else {
          this.status = 'ERROR';
          this.handleError.emit();
        }
      }
    });

    player.init(this.canvasElement.nativeElement);
    player.connect();
  }

  public async takeSnapshot() {
    const base64Image = await this.http
      .get(`http://localhost:5050/${this.camera.snapshotUrl}`, {
        responseType: 'blob',
        headers: { ignoreLoadingBar: '' }
      })
      .pipe(switchMap((response: any) => from(createImageFromBlob(response))))
      .toPromise()
      .catch(e => {
        console.log(e);
        return '';
      });
    this.handleSnapshot.next(base64Image);
    return base64Image;
  }

  public click() {
    this.handleClick.next(this.camera);
  }
}
