import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { ResidenceService } from '@api/service/residence.service';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { Dependent } from '@api/model/dependent';
import { User } from '@api/model/user';
import { UtilService } from '../../services/util.service';
import { OccurrenceService } from '@api/service/occurrence.service';
import { EcondosQuery } from '@api/model/query';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ParamsService } from '@api/service/params.service';
import { DependentServiceV2 } from '@api/serviceV2/dependent.service';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { CacheService } from '@api/service/cache/cache.service';

@Component({
  selector: 'app-gate-residence-details-modal',
  templateUrl: 'gate-residence-details.modal.component.html',
  styleUrls: ['gate-residence-details.modal.component.scss']
})
export class GateResidenceDetailsModalComponent implements OnInit, OnDestroy {
  user: User;
  condo: Condo;
  residenceId: string;
  residence: Residence;
  residenceUsers: User[];
  isBusinessCondo = false;

  today = moment();

  status: 'LOADING' | 'ERROR' | 'SUCCESS';

  vehicles: CondoVehicle[] = [];
  VEHICLE_TYPES = CondoVehicle.TYPES;
  dependents: Dependent[] = [];

  permissions = [];

  private subscriptions: Subscription = new Subscription();

  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
  activeTab: string = '1';

  constructor(
    public bsModalRef: BsModalRef,
    private condoService: CondoService,
    private residenceService: ResidenceService,
    private utilService: UtilService,
    private router: Router,
    private cacheService: CacheService
  ) {
    this.user = this.utilService.getLocalUser();
  }

  ngOnInit() {
    if (!this.residence && this.residenceId) {
      this.getResidence(this.residenceId);
    }
    this.isBusinessCondo = this.condo?.isBusinessCondo();
  }

  ngOnDestroy(): void {
    this.cacheService.clearCache();
    this.subscriptions.unsubscribe();
  }

  getResidence(residenceId: string) {
    const econdosQuery: EcondosQuery = {
      $populate: [
        {
          path: 'voter',
          select: 'firstName lastName picture phones specialNeeds specialNeedsDetails condoResidenceAttributes',
          populate: [{ path: 'picture', select: 'url thumbnail' }]
        }
      ],
      $select: 'users voter extensionLine identification type documents',
      $sort: 'createdAt'
    };

    this.status = 'LOADING';
    return this.residenceService
      .getResidenceByIdWithParams(this.condo._id, residenceId, econdosQuery)
      .pipe(timeout(15000))
      .subscribe({
        next: residence => {
          this.residence = residence;
          this.status = 'SUCCESS';
        },
        error: err => (this.status = 'ERROR')
      });
  }

  navigateToAccessControl() {
    this.bsModalRef.hide();
    this.router.navigate(['gate', 'access']);
  }

  revealData(field: string, user) {
    user.isDataMasked[field] = false;

    let isFieldAlreadyUnmasked: boolean;

    if (field === 'ids') {
      isFieldAlreadyUnmasked = !user.ids
        .map(id => id.number)
        .toString()
        .includes('*');
    } else {
      isFieldAlreadyUnmasked = !user[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    if (field === 'ids') {
      query.$populate = [{ path: 'ids.pictures', select: 'url thumbnail' }];
    }

    const callback = ({ data }) => (user[field] = data);

    this.condoService.getCondoResidentUnmaskedField(this.condo._id, query, user._id, field).pipe(timeout(10000)).subscribe(callback);
  }

  tabSelected(tabDirective: TabDirective) {
    this.activeTab = tabDirective.id;
  }

  async navigateToResidence() {
    this.bsModalRef.hide();
    await this.router.navigate(['condo', 'residences', this.residence._id]);
  }
}
