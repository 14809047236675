/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, ViewChild, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { ReservationService } from '@api/service/reservation.service';
import { PrivateCreatorComponent } from '../../../occurrence.creator/private/private.occurrence.creator';
import { Reservation } from '@api/model/reservation';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ReservationChecklistModalComponent } from '../../reservation-checklist-modal/reservation-checklist-modal.component';
import { Status } from '@api/model/status';
import { EcondosQuery } from '@api/model/query';
import { ReservationServiceV3 } from '@api/serviceV3/reservation.service';
import { CalendarEvent } from '@api/model/calendar-reservation-links';

@Component({
  selector: 'reservation-detail-modal',
  templateUrl: 'reservation.detail.modal.html',
  styleUrls: ['reservation.detail.modal.scss']
})
export class ModalReservationDetailComponent {
  @ViewChild('reservationDetailModal', { static: true }) public reservationDetailModal: ModalDirective;
  @ViewChild(PrivateCreatorComponent) privateCreatorComponent: PrivateCreatorComponent;

  public selectedReservation: Reservation;
  @Input()
  public user: User;
  @Input()
  public condo: Condo;

  @Output()
  onReservationCancel = new EventEmitter();

  @Output()
  onReservationApprove = new EventEmitter();

  checklistStatus: Status = new Status();

  isAdmin = false;

  eventCalendar: CalendarEvent;

  public STATUS_COLORS: Record<keyof typeof Reservation.STATUS, string> = {
    APPROVED: 'success',
    CANCELED: 'danger',
    CANCELED_WITH_PENALTY: 'danger',
    DONE: 'primary',
    PENDING: 'warning',
    IN_QUEUE: 'gray'
  };

  constructor(
    private reservationService: ReservationService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private reservationServiceV3: ReservationServiceV3
  ) {}

  setEventCalendar() {
    this.eventCalendar = {
      title: this.selectedReservation?.reservationLocal.name || '',
      start: new Date(this.selectedReservation?.startDate) || new Date(),
      end: new Date(this.selectedReservation?.endDate) || new Date(),
      location: this.selectedReservation?.reservationLocal.name || '',
      description: this.selectedReservation?.observation || ''
    };
  }

  closeModal(): void {
    this.reservationDetailModal.hide();
  }

  showModal(reservation: Reservation) {
    this.selectedReservation = reservation;
    this.isAdmin = this.user.isAdmin() || this.user.isOwner();
    this.setEventCalendar();
    this.reservationDetailModal.show();
  }

  onOccurrenceCreated(occurrence) {
    swal({ title: 'Ocorrência criada com sucesso', type: 'success' });
  }

  approveReservation() {
    swal({
      type: 'question',
      title: `Aprovar reserva de ${this.selectedReservation.reservationLocal.name}`,
      text: 'Deseja realmente aprovar a reserva deste local?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.reservationService
          .approveReservation(this.condo.id, this.selectedReservation.reservationLocal._id, this.selectedReservation.id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            swal({
              title: `${err.messageTitle || 'Ops...'} `,
              text: `${err.message || 'Não foi possível aprovar esta reserva, tente novamente...'}`,
              type: 'error',
              cancelButtonColor: '#f53d3d'
            });
            console.log(err);
            return Promise.reject('Não foi possível aprovar a reserva, tente novamente...');
          });
      }
    }).then(
      success => {
        console.log(success);
        this.onReservationApprove.emit();
        this.selectedReservation.status = Reservation.APPROVED_STATUS;
        swal({
          type: 'success',
          title: 'Reserva aprovada!',
          text: 'A reserva foi aprovada com sucesso'
        });
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  cancelReservation() {
    const warningText = this.selectedReservation.reservationLocal.penaltyWarningText
      ? this.selectedReservation.reservationLocal.penaltyWarningText
      : `Ao cancelar a reserva você estará sujeito as multas de seu ${this.condo?.customLabels?.condo?.singular}! Deseja realmente efetuar o cancelamento?`;

    swal({
      type: 'warning',
      title: `Cancelar reserva de ${this.selectedReservation.reservationLocal.name}`,
      text: warningText,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.reservationService
          .cancelReservation(this.condo._id, this.selectedReservation.reservationLocal._id, this.selectedReservation.id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            swal({
              title: `${err.messageTitle || 'Ops...'} `,
              text: `${err.message || 'Não foi possível cancelar esta reserva, tente novamente...'}`,
              type: 'error',
              cancelButtonColor: '#f53d3d'
            });
            console.log(err);
            return Promise.reject('Não foi possível cancelar a reserva, tente novamente...');
          });
      }
    }).then(
      success => {
        this.toastr.success('Reserva cancelada com sucesso');
        this.onReservationCancel.emit();
        this.closeModal();
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  createPrivaceOccurrence(reservation) {
    this.closeModal();
    this.privateCreatorComponent.setUsers([reservation.createdBy]);
    this.privateCreatorComponent.show();
  }

  checkIn(reservation: Reservation) {
    if (reservation.checkIn.checkedAt) {
      this.checklistStatus.setAsDownloading();
      const qs: EcondosQuery = {
        $select: 'observation',
        $populate: [
          { path: 'checkIn', select: 'items checkedAt' },
          { path: 'checkIn.signature', select: 'url thumbnail type format name' },
          { path: 'checkIn.checkedBy', select: 'firstName lastName' }
        ]
      };

      this.reservationServiceV3
        .getById(this.condo._id, reservation.reservationLocal._id, reservation._id, qs)
        .pipe(timeout(10000))
        .subscribe(
          res => {
            reservation.checkIn = res.checkIn;
            const initialState = {
              reservation,
              type: 'CHECK-IN',
              condo: this.condo
            };
            this.checklistStatus.setAsSuccess();
            this.modalService.show(ReservationChecklistModalComponent, { initialState, class: 'modal-xl' });
          },
          err => {
            this.toastr.error('Não foi possível obter o check-in desta reserva. Verifique sua conexão e tente novamente.');
            this.checklistStatus.setAsError();
          }
        );
    } else {
      const initialState = {
        reservation,
        type: 'CHECK-IN',
        condo: this.condo
      };
      this.modalService.show(ReservationChecklistModalComponent, { initialState, class: 'modal-xl' });
    }
  }

  checkOut(reservation) {
    if (reservation.checkOut.checkedAt) {
      this.checklistStatus.setAsDownloading();
      const qs: EcondosQuery = {
        $select: 'observation',
        $populate: [
          { path: 'checkOut', select: 'items checkedAt' },
          { path: 'checkOut.signature', select: 'url thumbnail type format name' },
          { path: 'checkOut.checkedBy', select: 'firstName lastName' }
        ]
      };

      this.reservationServiceV3
        .getById(this.condo._id, reservation.reservationLocal._id, reservation._id, qs)
        .pipe(timeout(10000))
        .subscribe(
          res => {
            reservation.checkOut = res.checkOut;
            const initialState = {
              reservation,
              type: 'CHECK-OUT',
              condo: this.condo
            };
            this.checklistStatus.setAsSuccess();
            this.modalService.show(ReservationChecklistModalComponent, { initialState, class: 'modal-xl' });
          },
          err => {
            this.toastr.error('Não foi possível obter o check-out desta reserva. Verifique sua conexão e tente novamente.');
            this.checklistStatus.setAsError();
          }
        );
    } else {
      const initialState = {
        reservation,
        type: 'CHECK-OUT',
        condo: this.condo
      };
      this.modalService.show(ReservationChecklistModalComponent, { initialState, class: 'modal-xl' });
    }
  }

  checkAll(reservation) {
    this.checklistStatus.setAsDownloading();
    const qs: EcondosQuery = {
      $select: 'observation',
      $populate: [
        { path: 'checkIn', select: 'items checkedAt' },
        { path: 'checkOut', select: 'items checkedAt' },
        { path: 'checkIn.signature', select: 'url thumbnail type format name' },
        { path: 'checkIn.checkedBy', select: 'firstName lastName' },
        { path: 'checkOut.signature', select: 'url thumbnail type format name' },
        { path: 'checkOut.checkedBy', select: 'firstName lastName' }
      ]
    };

    this.reservationServiceV3
      .getById(this.condo._id, reservation.reservationLocal._id, reservation._id, qs)
      .pipe(timeout(10000))
      .subscribe(
        res => {
          reservation.checkIn = res.checkIn;
          reservation.checkOut = res.checkOut;
          const initialState = {
            reservation,
            type: 'CHECK-ALL',
            condo: this.condo
          };
          this.checklistStatus.setAsSuccess();
          this.modalService.show(ReservationChecklistModalComponent, { initialState, class: 'modal-xl' });
        },
        () => {
          this.toastr.error('Não foi possível obter o check-in desta reserva. Verifique sua conexão e tente novamente.');
          this.checklistStatus.setAsError();
        }
      );
  }
}
