import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { map, retry, shareReplay, takeUntil, tap, timeout } from 'rxjs/operators';
import { rotateImageBase64 } from '@api/util/util';
import { Camera, CAMERA_PROTOCOLS } from '@api/model/camera';
import { CameraService } from '@api/service/camera.service';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CameraViewerComponent } from '../../camera-viewer/camera-viewer.component';
import { isElectron } from '@api/utils';

@Component({
  templateUrl: 'modal-preview-cctv.component.html',
  styleUrls: ['modal-preview-cctv.component.scss']
})
export class ModalPreviewCCTVComponent implements OnInit, OnDestroy {
  @ViewChild(CameraViewerComponent) cameraViewer: CameraViewerComponent;

  defaultImage = 'assets/img/empty-user-picture.png';

  defaultType = '';
  useImageCropper: Boolean = true;
  defaultCamera = '';

  selectedCamera: UntypedFormControl = new UntypedFormControl('');
  faceCrop: UntypedFormControl = new UntypedFormControl(false);

  showInstructions = false;

  status;

  croppedImage;

  angle = {
    ORIGIN: 0,
    FLIP: 180
  };
  angleValue = 0;
  angleClass = 'rotateDefault';
  snapshotPreview: string;

  snapshotPreviewStyle = {};
  cropperStyle = {};

  camera$;

  private unsubscribe: Subject<void> = new Subject();

  callback: (base64, faceCrop) => void;

  constructor(
    public bsModalRef: BsModalRef,
    private http: HttpClient,
    private toastrService: ToastrService,
    private renderer: Renderer2,
    private cameraService: CameraService,
    private utilService: UtilService
  ) {
    this.defaultCamera = this.utilService.getLocalCamera();
    this.selectedCamera.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((camera: Camera) => {
      this.angleClass = 'rotateDefault';
      this.snapshotPreviewStyle = { 'max-width': '100%' };
      this.cropperStyle = {};
    });
  }

  ngOnInit() {
    const condo = this.utilService.getLocalCondo();
    this.camera$ = this.cameraService.get(condo._id, { $sort: 'name' }).pipe(
      timeout(10000),
      retry(3),
      map(res => res.cameras),
      tap(cameras => {
        if (cameras.length === 0) {
          swal({
            type: 'info',
            text:
              'Não existem câmeras cadastradas em seu(sua) ' +
              condo?.customLabels?.condo?.singular +
              '.\nEntre em contato com o responsável do(a) ' +
              condo?.customLabels?.condo?.singular +
              ' para que ele possa cadastrar as câmeras necessárias.'
          });
        } else if (this.defaultType) {
          const camera = cameras.find(c => c.use === this.defaultType);
          this.selectedCamera.setValue(camera || '');
        } else if (this.defaultCamera) {
          const savedCamera = cameras.find(c => c._id === this.defaultCamera);
          this.selectedCamera.setValue(savedCamera || '');
        }
      }),
      shareReplay(1)
    );
    if (this.useImageCropper) {
      const localImageCroppper = this.utilService.getLocalUseImageCropper() || false;
      this.faceCrop.setValue(localImageCroppper);
      this.faceCrop.valueChanges.subscribe(value => {
        this.utilService.saveLocalUseImageCropper(value);
        if (!value) {
          this.croppedImage = null;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public async handleSnapshot(base64, faceCrop = false) {
    const shouldRotateImage = base64 && this.angleValue === this.angle.FLIP && base64 !== this.croppedImage;
    if (shouldRotateImage) {
      base64 = await rotateImageBase64(base64, this.angleValue);
    }
    if (this.callback) {
      this.callback(base64, faceCrop);
    }
    this.bsModalRef.hide();
  }

  async takeSnapshot() {
    this.snapshotPreview = await this.cameraViewer.handleTakeSnapshot();
    this.selectedCamera.disable({ emitEvent: false });
  }

  async handleDirectSnapshot() {
    const snapshot = await this.cameraViewer.handleTakeSnapshot();
    await this.handleSnapshot(snapshot);
  }

  removeSnapshot() {
    this.snapshotPreview = '';
    this.selectedCamera.enable({ emitEvent: false });
  }

  checkIfProxyIsRunning(protocol) {
    let url = '';
    if (protocol === CAMERA_PROTOCOLS.RTSP) {
      url = 'http://localhost:5051/';
    } else {
      url = 'http://localhost:5050/api.econdos.com.br';
    }
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.http
      .get(url, { headers, responseType: 'text' })
      .pipe(timeout(8000))
      .subscribe(
        res => {
          swal({
            type: 'success',
            text: 'O proxy está funcionando corretamente, verifique se as câmeras estão configuradas corretamente e tente novamente'
          });
        },
        err => {
          console.log(err);
          swal({
            type: 'error',
            text: 'Parece que o proxy não está em execução, verifique as instruções para executá-lo e tente novamente'
          });
        }
      );
  }

  rotatePreview(direction) {
    this.angleValue = this.angleValue += direction;
    if (this.angleValue === this.angle.FLIP + this.angle.FLIP) {
      this.angleValue = this.angle.ORIGIN;
    }
    if (this.angleValue === this.angle.FLIP) {
      this.angleClass = 'rotateFlip';
    } else if (this.angleValue === this.angle.ORIGIN) {
      this.angleClass = 'rotateDefault';
    }
  }

  handleCameraError(error) {
    if (error && error.name === 'HttpErrorResponse' && !isElectron()) {
      this.showInstructions = true;
    }
    this.selectedCamera.enable({ emitEvent: false });
    this.status = 'ERROR';
    this.toastrService.error('Não foi possível capturar a imagem da câmera selecionada.');
    this.selectedCamera.setValue('');
  }
}
