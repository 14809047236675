import { Component } from '@angular/core';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { Status } from '@api/model/status';
import { UtilService } from '../../../../services/util.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { forkJoin, lastValueFrom } from 'rxjs';
import { map, tap, timeout } from 'rxjs/operators';
import { Occurrence } from '@api/model/interface/occurrence';
import { OccurrenceService } from '@api/service/occurrence.service';

@Component({
  selector: 'app-modal-close-multiple-deliveries',
  templateUrl: 'modal-close-multiple-deliveries-component.html',
  styleUrls: ['modal-close-multiple-deliveries-component.scss']
})
export class ModalCloseMultipleDeliveriesComponent {
  occurrences: GateOccurrence[] = [];
  onSuccess: (occurrences: GateOccurrence[]) => void;

  occurrenceForm = new FormGroup({
    receivedBy: new FormControl(
      { value: 'Não Registrado', disabled: true },
      Validators.compose([Validators.required, Validators.minLength(3)])
    ),
    closingDetails: new FormControl(
      'A encomenda/objeto foi retirada(o).',
      Validators.compose([Validators.required, Validators.minLength(3)])
    )
  });

  condo: Condo;
  user: User;
  isSubmitting = false;

  status: Status = new Status();

  constructor(
    private occurrenceService: OccurrenceService,
    private utilService: UtilService,
    private modalRef: BsModalRef,
    private toastrService: ToastrService
  ) {
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();
  }
  closeModal() {
    this.modalRef.hide();
  }

  closeOccurrences() {
    if (this.occurrenceForm.valid) {
      swal({
        type: 'question',
        title: `Recebimento de encomendas`,
        text: 'Deseja realmente encerrar este recebimento de encomendas?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        preConfirm: () =>
          lastValueFrom(this.saveMultipleOccurrences())
            .catch(() => Promise.reject('Não foi possível encerrar a encomendas, tente novamente...'))
            .then((occurrences: GateOccurrence[]) => {
              if (this.onSuccess) {
                this.onSuccess(occurrences);
              }
              this.closeModal();
              this.toastrService.success('Encomendas encerradas com sucesso');
            })
      });
    } else {
      this.occurrenceForm.markAsTouched();
    }
  }

  private saveMultipleOccurrences() {
    const updatedOccurrences = this.occurrences.map(occurrence => {
      const updateData = {
        status: Occurrence.STATUS.CLOSED,
        closingDetails: this.occurrenceForm.controls.closingDetails.value,
        receivedBy: this.occurrenceForm.controls.receivedBy.value
      };
      return {
        _id: occurrence._id,
        updateData,
        updatedOccurrence: { ...occurrence, ...updateData }
      };
    });

    const requests = updatedOccurrences.map(occurrence =>
      this.occurrenceService.updateOcurrence(this.condo._id, occurrence._id, occurrence.updateData).pipe(
        timeout(10000),
        map(() => occurrence.updatedOccurrence)
      )
    );

    this.status.setAsProcessing();
    return forkJoin(requests).pipe(tap(() => this.status.setAsSuccess()));
  }
}
