import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Camera, CAMERA_PROTOCOLS } from '@api/model/camera';
import { readFileAsDataURL, rotateBase64Image90Deg } from '@api/util/util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { cctvIcon, webcamIcon } from '../../../assets/svg/custom-icons';
import { ModalPreviewCCTVComponent } from '../image-picker/modal-preview-cctv/modal-preview-cctv.component';
import { ModalPreviewWebcamComponent } from '../image-picker/modal-preview-webcam/modal-preview-webcam.component';
import { ModalImageCropperComponent } from '../modal-image-cropper/modal-image-cropper.component';
import { UploadButton } from '../upload-button';
import { HttpCameraViewerComponent } from './http-camera-viewer/http-camera-viewer.component';
import { RtspCameraViewerComponent } from './rtsp-camera-viewer/rtsp-camera-viewer.component';
import { DahuaRtspCameraViewerComponent } from './dahua-rtsp-camera-viewer/dahua-rtsp-camera-viewer.component';
import { EcameraCameraViewerComponent } from './ecamera-camera-viewer/ecamera-camera-viewer.component';
import { SystemLocalSettingsService } from '@api/serviceV2/system-local-settings.service';

interface CCTVPreviewOptions {
  defaultImage?: string;
  defaultType?: string;
}

const defaultCctvPreviewOptions: CCTVPreviewOptions = {
  defaultImage: 'assets/img/empty-user-picture.png',
  defaultType: 'FACE'
};

@Component({
  selector: 'app-camera-viewer',
  templateUrl: './camera-viewer.component.html',
  styleUrls: ['./camera-viewer.component.scss']
})
export class CameraViewerComponent implements OnInit, OnDestroy {
  @ViewChild('rtspCameraViewer') rtspCameraViewer: RtspCameraViewerComponent;
  @ViewChild('dahuaRtspCameraViewer') dahuaRtspCameraViewer: DahuaRtspCameraViewerComponent;
  @ViewChild('httpCameraViewer') httpCameraViewer: HttpCameraViewerComponent;
  @ViewChild('ecameraCameraViewer') ecameraCameraViewer: EcameraCameraViewerComponent;

  @ViewChild(UploadButton, { static: true }) uploadBtn: UploadButton;

  @Input() camera: Camera | null = null;
  @Input() initialImage = '';
  @Input() fitParentSize = true;
  @Input() displayCameraName = true;

  @Input() placeholderHeight = '100%';
  @Input() placeholderWidth = '100%';

  @Input() showActionButtons = true;
  @Input() allowRemoveSnapshot = true;
  @Input() allowRemoveInitialImage = true;

  @Input() cctvPreviewOptions: CCTVPreviewOptions = {};

  @Input() viewerStyle: { [key: string]: string } = { height: '100%', 'max-height': '100%' };
  @Input() rotate = 0;

  @Output() handleSnapshot: EventEmitter<string> = new EventEmitter();
  @Output() handleSuccessLoad = new EventEmitter();
  @Output() handleError: EventEmitter<any> = new EventEmitter();
  @Output() handleClick: EventEmitter<Camera> = new EventEmitter();

  public snapshotImage = '';

  public hasLoadedSuccessfully = false;

  public cctvIcon = cctvIcon;
  public webcamIcon = webcamIcon;

  private unsubscribe: Subject<void> = new Subject();

  public unableToLoadCameraImage = false;

  public CAMERA_PROTOCOLS = CAMERA_PROTOCOLS;

  public uploadButtonCallback = async ([image]: [File]) => await this.onPickImageFromFileSystem(image);
  public RTSP_SERVER_TYPE = 'V1';

  constructor(private modalService: BsModalService, private systemLocalSettingsService: SystemLocalSettingsService) {}

  public ngOnInit(): void {
    this.RTSP_SERVER_TYPE = this.systemLocalSettingsService.getSystemLocalSettings()?.rtspServer?.type || 'V1';
    if (this.initialImage) {
      this.hasLoadedSuccessfully = true;
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public async handleTakeSnapshot(): Promise<string> {
    switch (this.camera.protocol) {
      case CAMERA_PROTOCOLS.HTTP: {
        return this.httpCameraViewer.takeSnapshot();
      }
      case CAMERA_PROTOCOLS.RTSP: {
        if (this.RTSP_SERVER_TYPE === 'V1') {
          return this.rtspCameraViewer.takeSnapshot();
        } else {
          return this.ecameraCameraViewer.takeSnapshot();
        }
      }
      case CAMERA_PROTOCOLS.DAHUA_WEBSOCKET: {
        return await this.dahuaRtspCameraViewer.takeSnapshot();
      }
    }
  }

  public handleRemoveSnapshot(): void {
    if (this.allowRemoveSnapshot) {
      this.snapshotImage = '';
      this.handleSnapshot.emit(null);
      this.hasLoadedSuccessfully = false;
    }

    if (this.allowRemoveInitialImage) {
      this.initialImage = '';
    }
  }

  public onTakeSnapshot(snapshot: string): void {
    this.snapshotImage = snapshot;
    this.handleSnapshot.emit(snapshot);
  }

  public onSuccessLoad(data): void {
    this.hasLoadedSuccessfully = true;
    this.handleSuccessLoad.emit(data);
  }

  public onError(err: any): void {
    this.unableToLoadCameraImage = true;
    this.handleError.emit(err);
  }

  public onSelectCamera(camera: Camera): void {
    this.handleClick.emit(camera);
  }

  public async onPickImageFromFileSystem(image: File) {
    const imageUrl = await readFileAsDataURL(image);
    this.onTakeSnapshot(imageUrl);
  }

  public handleOpenPreviewCCTVModal() {
    const cctvOptions = { ...defaultCctvPreviewOptions, ...this.cctvPreviewOptions };

    const initialState = {
      defaultImage: cctvOptions.defaultImage,
      useImageCropper: false,
      defaultType: cctvOptions.defaultType,
      callback: (base64: string) => {
        if (base64) {
          this.onTakeSnapshot(base64);
        }
      }
    };

    this.modalService.show(ModalPreviewCCTVComponent, { initialState, ignoreBackdropClick: true });
  }

  public handleOpenPreviewWebcamModal() {
    const initialState = {
      useImageCropper: false,
      callback: (base64: string) => {
        if (base64) {
          this.onTakeSnapshot(base64);
        }
      }
    };

    this.modalService.show(ModalPreviewWebcamComponent, { initialState, ignoreBackdropClick: true });
  }

  public handlePickImageFromFileSystem() {
    this.uploadBtn.triggerClick();
  }

  public async handleRotateSnapshotImage(degrees: 90 | 180): Promise<void> {
    let rotatedImage = await rotateBase64Image90Deg(this.snapshotImage);

    if (degrees === 180) {
      rotatedImage = await rotateBase64Image90Deg(rotatedImage);
    }

    this.onTakeSnapshot(rotatedImage);
  }

  public handleCropSnapshotImage(): void {
    const initialState = {
      base64ImageToCrop: this.snapshotImage,
      onCropImage: (croppedBase64Image: string) => {
        if (croppedBase64Image) {
          this.onTakeSnapshot(croppedBase64Image);
        }
      }
    };

    this.modalService.show(ModalImageCropperComponent, { initialState, ignoreBackdropClick: true });
  }

  public handleRetryToLoadCameraImage(): void {
    this.unableToLoadCameraImage = false;
  }

  public get showInitialImage() {
    return !!this.initialImage && !this.snapshotImage;
  }

  public get showSnapshotImage() {
    return !!this.snapshotImage;
  }

  public get showCameraViewer() {
    return !this.initialImage && !this.snapshotImage;
  }

  public get showTakeSnapshotOptionsButtons() {
    return !this.snapshotImage;
  }

  public get showEditSnapshotOptionsButtons() {
    return !!this.snapshotImage;
  }

  public get showTakeSnapshotButton() {
    return !this.snapshotImage && !this.initialImage;
  }

  public get showRemoveSnapshotButton() {
    return (!!this.snapshotImage && this.allowRemoveSnapshot) || (!!this.initialImage && this.allowRemoveInitialImage);
  }
}
