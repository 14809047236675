import { File } from '../file';
import { ContactID } from '../contact/contact.id';
import { User } from '../user';
import { BackObject } from './back.object';

export abstract class Contact implements BackObject {
  static TYPES = {
    RESIDENT: 'RESIDENT',
    VISITOR: 'VISITOR',
    PROVIDER: 'PROVIDER',
    SUPPLIER: 'SUPPLIER',
    DELIVERYMAN: 'DELIVERYMAN',
    PUBLIC_AGENT: 'PUBLIC_AGENT',
    DRIVER: 'DRIVER',
    EMPLOYEE: 'EMPLOYEE',
    RELATIVE: 'RELATIVE',
    AIRBNB: 'AIRBNB',
    TENANT: 'TENANT',
    OTHER: 'OTHER'
  };

  static TYPES_LABEL = {
    RESIDENT: 'Condômino',
    VISITOR: 'Visitante',
    PROVIDER: 'Prestador',
    SUPPLIER: 'Fornecedor',
    DELIVERYMAN: 'Entregador',
    PUBLIC_AGENT: 'Agente público',
    DRIVER: 'Motorista (Aplicativos/Taxi)',
    EMPLOYEE: 'Funcionário',
    RELATIVE: 'Parente',
    AIRBNB: 'Airbnb',
    TENANT: 'Locatário',
    OTHER: 'Outros'
  };

  public _id: string;
  public firstName: any;
  public lastName: any;
  public birthDate: string;
  public ids: ContactID[];
  private _emails: string[];
  public phones: string[];
  public type: string;
  private _picture: File;
  public createdBy: User;
  public createdAt: string;
  private _updatedBy: User;
  private maskedValues;
  public isDataMasked: {
    phones: boolean;
    emails: boolean;
    birthDate: boolean;
    ids: boolean;
  };

  constructor(contact?) {
    if (contact) {
      if (typeof contact === 'string') {
        this._id = contact;
      } else {
        this._id = contact._id || '';
        this.firstName = contact.firstName || '';
        this.lastName = contact.lastName || '';
        this.birthDate = contact.birthDate || '';
        this.ids = contact.ids ? contact.ids.map(id => new ContactID(id)) : [];
        this._emails = contact.emails || [];
        this.phones = contact.phones || [];
        this.type = contact.type || '';
        this._picture = contact.picture ? new File(contact.picture) : null;
        this.createdBy = contact.createdBy ? new User(contact.createdBy) : null;
        this.createdAt = contact.createdAt || this.createdAt;
        this._updatedBy = contact.updatedBy ? new User(contact.updatedBy) : null;
        this.maskedValues = {
          emails: contact.emails ? [...contact.emails] : [],
          phones: contact.phones ? [...contact.phones] : [],
          ids: contact.ids ? [...contact.ids] : [],
          birthDate: contact.birthDate
        };
        this.isDataMasked = {
          phones: contact.phones?.length ? contact.phones.some(phone => phone.includes('*')) : [],
          emails: contact.emails?.length ? contact.emails.some(email => email.includes('*')) : [],
          birthDate: contact.birthDate?.includes('3000'),
          ids: contact.ids?.length ? contact.ids.some(id => id.number?.includes('*')) : []
        };
      }
    } else {
      this.construcEmptyObject();
    }
  }

  protected construcEmptyObject() {
    this._id = '';
    this.firstName = '';
    this.lastName = '';
    this.birthDate = '';
    this.ids = [];
    this._emails = [];
    this.phones = [];
    this.type = '';
    this._picture = null;
    this.createdBy = null;
    this.createdAt = null;
    this._updatedBy = null;
  }

  public createBackObject() {
    const contactToSend = {
      firstName: this.firstName,
      lastName: this.lastName,
      birthDate: this.birthDate,
      ids: this.ids.map(id => {
        if (id.createBackObject) {
          return id.createBackObject();
        } else {
          return new ContactID(id).createBackObject();
        }
      }),
      emails: this.emails,
      phones: this.phones,
      type: this.type,
      picture: this.picture ? this.picture.id : null
    };
    return contactToSend;
  }

  public maskSensitiveData(key?) {
    if (key) {
      this[key] = this.maskedValues[key];
    } else {
      Object.keys(this.maskedValues).forEach(key => (this[key] = this.maskedValues[key]));
    }
  }

  get fullName(): string {
    return ''.concat(this.firstName, ' ', this.lastName).trim();
  }

  get shortFullName(): string {
    return ''.concat(this.firstName, ' ', this.lastName.split(' ').pop());
  }

  get typeLabel(): string {
    return Contact.TYPES_LABEL[this.type] || '';
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get emails(): string[] {
    return this._emails;
  }

  set emails(value: string[]) {
    const emails = [];
    value.forEach(email => {
      if (email.includes('*') || this.isEmailValid(email)) {
        emails.push(email);
      }
    });
    this._emails = emails;
  }

  get picture(): File {
    return this._picture;
  }

  set picture(value: File) {
    this._picture = value;
  }

  get updatedBy(): User {
    return this._updatedBy;
  }

  set updatedBy(value: User) {
    this._updatedBy = value;
  }

  isEmailValid(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  get phone() {
    return this.phones && this.phones.length ? this.phones[0] : null;
  }

  get email() {
    return this.emails && this.emails.length ? this.emails[0] : null;
  }
}
