import { File } from './file';

export interface LostAndFound {
  _id?: string;
  pictures: Array<File>;
  title?: string;
  foundAt?: string;
  local?: string;
  observation?: string;
  withdrawAt?: string;
  withdrawBy?: string;
  status?: LOST_STATUS;
}

export enum LOST_STATUS {
  PENDING = 'PENDING',
  CLOSED = 'CLOSED'
}

export const lostAndFoundToBackObject = (res: LostAndFound) => {
  const data: any = {
    title: res.title,
    foundAt: res.foundAt,
    local: res.local,
    observation: res.observation
  };

  if (res.pictures && res.pictures.length) {
    data.pictures = res.pictures.map(f => f._id || f);
  } else {
    data.pictures = [];
  }

  return data;
};
