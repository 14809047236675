import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as qs from 'qs';
import { BuildSipAccount, SipAccount } from '@api/model/sip-account';

export interface GetSipAccountsResponse {
  count: number;
  sipAccounts: SipAccount[];
}

export type SaveSipAccountDTO = Pick<SipAccount, 'name' | 'host' | 'port' | 'prefix'>;

@Injectable({ providedIn: 'root' })
export class SipAccountsService {
  protected endPoint = `${this.constantService.getV2Endpoint()}condos/`;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {}

  getSipAccounts(condoId: string, params: EcondosQuery = {}): Observable<GetSipAccountsResponse> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/sip-accounts`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        sipAccounts: res.body.map(sipAccount => BuildSipAccount(sipAccount))
      }))
    );
  }

  createSipAccount(condoId: string, sipAccount: SaveSipAccountDTO) {
    return this.http.post(`${this.endPoint}${condoId}/sip-accounts`, sipAccount).pipe(map((response: Pick<SipAccount, '_id'>) => response));
  }

  updateSipAccount(condoId: string, sipAccountId: string, sipAccount: SaveSipAccountDTO) {
    return this.http.put(`${this.endPoint}${condoId}/sip-accounts/${sipAccountId}`, sipAccount);
  }

  deleteSipAccount(condoId: string, sipAccountId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/sip-accounts/${sipAccountId}`);
  }
}
