/**
 * Created by Rafael on 24/12/2016.
 */
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Modals
import { CondoPickerModalComponent } from '../modal/condo-picker-modal.component';
// Models
import { User } from '@api/model/user';
// Services
import { UtilService } from '../../services/util.service';
import { AuthService } from '../../services/auth.service';
import { NavbarService } from './navbar.service';
import { UserService } from '@api/service/user.service';
import swal from 'sweetalert2';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { NotificationPanelComponent } from '../../components/notification.panel/notification.panel';
import { AdminCondoPickerModal } from '../login/admin.condo.picker.modal/admin.condo.picker.modal';
import { Error } from '@api/model/error/error';
import { AppState, LogoutAction } from '../../reducers';
import { Store } from '@ngrx/store';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  retry,
  switchMap,
  takeUntil,
  tap,
  timeout,
  timeoutWith
} from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { HardwareSocketService } from '../../services/hardware-socket.service';
import { ThemeService } from '../../theme';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CallService } from '../../services/call.service';
import { capitalize, removeAccents } from '@api/util/util';
import { ChatServiceV2 } from '@api/serviceV2/chat.service';
import { ModalUseTermsComponent } from '../../components/modal-terms-of-service/modal-use-terms.component';
import { AcceptedTermsService } from '@api/service/accepted-terms.service';
import { UntypedFormControl } from '@angular/forms';
import { SystemLocalSettingsService } from '@api/serviceV2/system-local-settings.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { SessionService } from '@api/service/session.service';
import { CondoRequestService } from '@api/service/condo-request.service';
import { CondoRequest } from '@api/model/condo-request';
import { EcondosQuery } from '@api/model/query';
import { ModalPendingCondoApprovalComponent } from '../signup/modal-pending-condo-approval/modal-pending-condo-approval';
import { WatchActuatorsService } from '../../services/watch-actuators.service';

declare function require(moduleName: string): any;

const { version: appVersion } = require('../../../../package.json');

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.html',
  styleUrls: ['navbar.scss']
})
export class NavbarComponent implements OnDestroy {
  @ViewChild(CondoPickerModalComponent, { static: true }) condoPickerModal: CondoPickerModalComponent;
  @ViewChild(NotificationPanelComponent, { static: true }) notificationPanel: NotificationPanelComponent;

  public user: User;
  public userRolesLabel: string;
  public showNavBar = false;
  isAdmin = false;
  isGatekeeper = false;

  public showSideMenu = false;

  public selectedCondo: Condo;
  public userCondos: Condo[] = [];
  public userCondosRequester: Condo[] = [];
  public changingDefaultCondo: boolean;
  public version;

  ROLES_LABEL = {
    OWNER: 'Síndico',
    GATEKEEPER: 'Porteiro',
    USER: 'Condômino',
    ADMIN: 'Administrador',
    REQUESTER: 'Solicitante',
    JANITOR: 'Zelador'
  };

  subscriptions: Subscription = new Subscription();
  searchInput = new UntypedFormControl();
  filteredUserCondos: Condo[] = [];
  filteredUserCondosRequester: Condo[] = [];

  userCondoRequests: CondoRequest[] = [];

  menu: MenuItem[] = [];

  logoUrl = '';
  private _destroy$ = new Subject();
  private _onLogout$ = new Subject();

  private canCheckUseTerms = true;
  showCameraPanel = false;

  constructor(
    private utilService: UtilService,
    private authService: AuthService,
    private navbarService: NavbarService,
    private router: Router,
    private intelbrasService: IntelbrasIncontrolService,
    private themeService: ThemeService,
    private hardwareSocketService: HardwareSocketService,
    private store: Store<AppState>,
    private userService: UserService,
    private modalService: BsModalService,
    private condoService: CondoService,
    private chatService: ChatServiceV2,
    private acceptedTermsService: AcceptedTermsService,
    private systemLocalSettingsService: SystemLocalSettingsService,
    public callService: CallService,
    private sessionService: SessionService,
    private condoRequestService: CondoRequestService,
    private watchActuatorsService: WatchActuatorsService
  ) {
    this.version = appVersion;

    this.navbarService.showNavBarEmitter.subscribe(mode => {
      // mode will be null the first time it is created, so you need to igonore it when null
      if (mode !== null) {
        this.showNavBar = mode;
      } else {
        this.showNavBar = true;
      }
      if (this.showNavBar && this.notificationPanel) {
        this.notificationPanel.loadNotifications();
      }
    });

    combineLatest([this.sessionService.user$, this.sessionService.selectedCondo$]).subscribe(([user, condo]) => {
      // mode will be null the first time it is created, so you need to ignore it when null
      if (user !== null) {
        const userCondos = user.getAllCondos();
        let selectedCondo: Condo;
        const userHasLocalCondo = userCondos.some(c => c._id === condo?._id);
        if (userHasLocalCondo) {
          selectedCondo = condo;
        } else {
          selectedCondo = user.defaultCondo;
        }
        this.user = user;
        this.selectedCondo = selectedCondo;
        this.userRolesLabel = this.user?.getUserRoleLabel(selectedCondo).join(', ') || '';
        this.setUserCondos(user);
        this.createMenu(user, selectedCondo);
        this.callService.initialize(user, selectedCondo);
        this.chatService.initialize(user, selectedCondo);
        this.watchActuatorsService.initialize(user, selectedCondo);
        this.isAdmin = this.user.isAdminOnCondo(selectedCondo?._id) || user.isOwnerOnCondo(selectedCondo?._id);
        this.isGatekeeper = this.user.isGatekeeperOnCondo(selectedCondo?._id);
      }
    });

    this.systemLocalSettingsService.systemLocalSettings$.subscribe(systemLocalSettings => {
      this.showCameraPanel = systemLocalSettings.rtspServer.type === 'V2';
    });

    this.sessionService.user$
      .pipe(
        takeUntil(this._destroy$),
        filter(v => !!v),
        distinctUntilChanged(),
        debounceTime(400),
        switchMap(() => {
          const invalidRoutes = ['/', 'signup'];
          if (!invalidRoutes.includes(this.router.url)) {
            return of(true);
          }
          return this.router.events.pipe(filter(value => value instanceof NavigationEnd && !invalidRoutes.includes(value.url)));
        }),
        filter(() => !!this.selectedCondo && this.canCheckUseTerms),
        switchMap(() => this.getUseTerms())
      )
      .subscribe(useTerms => {
        this.modalService.show(ModalUseTermsComponent, {
          keyboard: false,
          ignoreBackdropClick: true,
          initialState: {
            onRejectTerms: () => {
              this.logout();
            },
            onAcceptTerms: () => {
              this.canCheckUseTerms = false;
            },
            useTerms
          }
        });
      });

    this.themeService
      .getActiveTheme()
      .pipe(takeUntil(this._destroy$))
      .subscribe(theme => {
        if (theme && (theme.banner || theme.logomarca || theme.logo)) {
          this.logoUrl = theme.banner || theme.logomarca || theme.logo;
        } else {
          this.logoUrl = 'assets/img/logomarca.png';
        }
      });

    this.subscriptions.add(
      this.searchInput.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
        this.filteredUserCondos = this.userCondos.filter(c => {
          const condoName = removeAccents(c.name.toString().toLowerCase());
          return condoName.includes(removeAccents(value));
        });
        this.filteredUserCondosRequester = this.userCondosRequester.filter(c => {
          const condoName = c.name.toString().toLowerCase();
          return condoName.includes(value.toLowerCase());
        });
      })
    );
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  toggleNotificationPanel() {
    this.notificationPanel.toggle();
  }

  pickACondo() {
    const condosToHide = [];

    const condos = [].concat([this.selectedCondo], this.userCondos, this.userCondosRequester);
    condos.forEach(condo => {
      condosToHide.push(condo._id);
    });

    this.condoPickerModal.showModal(condosToHide);
  }

  pickACondoAsSystemAdmin() {
    const callback = (condo: Condo) => {
      this.changingDefaultCondo = true;
      const user = this.utilService.getLocalUser();
      user.defaultCondo = condo;

      this.userService
        .updateUser(user._id, { defaultCondo: condo })
        .pipe(timeout(15000))
        .subscribe(
          () => {
            this.user.defaultCondo = condo;
            this.utilService.saveLocalUser(this.user);
            this.utilService.saveLocalCondo(condo);
            window.location.reload();
          },
          err => {
            this.changingDefaultCondo = false;
            swal({
              type: 'error',
              text: 'Não foi possível atualizar seus dados de usuário, tente novamente.'
            });
          }
        );
    };

    this.modalService.show(AdminCondoPickerModal, { initialState: { callback } });
  }

  onCondoSelection(condoRequest: CondoRequest) {
    const condo = condoRequest.condo;
    if (condoRequest.condo.isCreatedByUser(this.user.id)) {
      this.changeSelectedCondo(condoRequest.condo);
    } else {
      this.condoService
        .addCondoUser(condoRequest.condo._id, this.user._id)
        .pipe(
          timeoutWith(15000, ErrorBuilder.throwTimeoutError()),
          mergeMap(() => {
            this.condoPickerModal.closeModal();
            if (condo.needAccessRequest()) {
              return this.condoRequestService.create(condoRequest.condo._id, condoRequest).pipe(
                map(res => ({
                  condoRequestId: res.condoRequestId,
                  condo
                }))
              );
            } else {
              this.changeSelectedCondo(condo);
              return of({
                condoRequestId: null,
                condo: null
              });
            }
          })
        )
        .subscribe({
          next: ({ condoRequestId, condo }) => {
            if (!!condoRequestId) {
              this.userCondosRequester.push(condo);
              this.filteredUserCondosRequester.push(condo);
              condoRequest._id = condoRequestId;
              this.userCondoRequests.push(condoRequest);
              this.redirect(condo);
            }
          },
          error: (err: Error) => {
            this.condoPickerModal.closeModal();
            swal({
              type: 'error',
              title: err.messageTitle || 'Falha',
              text:
                err.message ||
                `Não foi possível te adicionar a este(a) ${condoRequest.condo?.customLabels?.condo?.singular}.\nTente novamente.`
            });
          }
        });
    }
  }

  redirect(condo: Condo) {
    const condoRequest = this.userCondoRequests.find(userCondoRequest => userCondoRequest.condo._id === condo._id);
    const initialState = {
      condo,
      user: this.user,
      condoRequest: condoRequest
    };
    this.modalService.show(ModalPendingCondoApprovalComponent, { initialState, class: 'modal-md' });
  }

  setUserCondos(user: User) {
    let condos = user.approvedCondos;
    condos = condos.filter(condo => {
      return this.selectedCondo ? condo._id !== this.selectedCondo._id : true;
    });

    condos = condos.filter((condo, index, self) => index === self.findIndex(c => c._id === condo._id));

    condos.sort((c1, c2) => (c1.name || '').localeCompare(c2.name || ''));

    this.userCondos = condos;
    const query: EcondosQuery = {
      $select: 'condo residence role status',
      $populate: [
        {
          path: 'condo',
          select: 'name address city state'
        },
        {
          path: 'residence',
          select: 'identification condo'
        }
      ],
      status: 'PENDING'
    };

    this.userService.getCondoRequests(user._id, query).subscribe({
      next: ({ condoRequests }) => {
        this.userCondoRequests = condoRequests;
        if (!condoRequests.length) {
          this.filteredUserCondosRequester = [];
        }
        condoRequests.forEach(condoRequest => {
          const hasCondoRequestInUserCondos = this.userCondosRequester.some(c => c._id === condoRequest.condo._id);
          const hasCondoRequestInFilteredUserCondos = this.filteredUserCondosRequester.some(c => c._id === condoRequest.condo._id);
          if (!hasCondoRequestInUserCondos) {
            this.userCondosRequester.push(condoRequest.condo);
          }
          if (!hasCondoRequestInFilteredUserCondos) {
            this.filteredUserCondosRequester.push(condoRequest.condo);
          }
        });
      }
    });

    this.filteredUserCondos = condos;
  }

  changeSelectedCondo(condo: Condo) {
    if (this.changingDefaultCondo) {
      return;
    }

    this.changingDefaultCondo = true;
    this.userService
      .changeUserDefaultCondo(this.user._id, condo.id)
      .pipe(
        timeout(10000),
        mergeMap(_ => this.userService.getMe().pipe(timeout(10000)))
      )
      .subscribe(
        me => {
          this.setUserCondos(me);
          this.selectedCondo = me.defaultCondo;
          this.user = me;
          this.utilService.saveLocalUser(me);
          this.utilService.saveLocalCondo(me.defaultCondo);
          this.changingDefaultCondo = false;
          let routeToRedirect = '';
          if (
            this.user.isOwnerOnCondo(this.selectedCondo._id) ||
            this.user.isAdminOnCondo(this.selectedCondo._id) ||
            this.user.isGatekeeperOnCondo(this.selectedCondo._id)
          ) {
            routeToRedirect = this.router.url;
          } else {
            routeToRedirect = '/feed';
          }
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([routeToRedirect]);
          });
        },
        err => {
          swal({
            type: 'error',
            text: `Não foi possível alterar o ${condo?.customLabels?.condo?.singular} visualizado.\nTente novamente.`
          });
          this.changingDefaultCondo = false;
        }
      );
  }

  logout() {
    this.notificationPanel.clearNotifications();
    this.authService.unregisterSession();
    this.hardwareSocketService.disconnect();
    this.intelbrasService.disconnect();

    this.callService.terminate();
    this.chatService.terminate();
    this.watchActuatorsService.terminate();

    this.user = null;
    this.router.navigate(['/']);
    this.store.dispatch(new LogoutAction());
    this.showSideMenu = false;

    this.canCheckUseTerms = true;
    this._onLogout$.next(null);
  }

  createMenu(user: User, condo: Condo) {
    if (user && condo) {
      const menu = [];
      // Dashboard
      if (user.isAdmin() || user.isOwner()) {
        const dashboard: MenuItem = { text: 'Dashboard', icon: 'fa-bar-chart', link: '/dashboard' };
        menu.push(dashboard);
      }

      // Feed
      const feed: MenuItem = {
        text: user.isAdmin() || user.isOwner() ? 'Feed' : 'Página inicial',
        icon: 'fa-list-alt',
        link: '/feed'
      };
      menu.push(feed);

      // FEATURES
      const featuresSubItems: MenuItem[] = this.buildFeaturesSubItems(user, condo) || [];

      if (featuresSubItems.length) {
        const features: MenuItem = {
          text: 'Funcionalidades',
          icon: 'fa-cogs',
          link: '/features'
        };
        menu.push(features);
      }

      // Reservas
      if (!condo.isReservationsDisabled() && (user.isAdmin() || user.isOwner() || user.isGatekeeper() || user.isUser())) {
        const reservations: MenuItem = { text: 'Reservas', icon: 'fa-calendar', link: '/reservations' };
        menu.push(reservations);
      }

      // Portaria
      if (
        (user.isAdmin() || user.isOwner() || user.isGatekeeper()) &&
        !(
          condo.isGateNotebookDisabled() &&
          condo.isDeliveriesDisabled() &&
          condo.isAccessLiberationDisabled() &&
          condo.isAccessControlDisabled() &&
          condo.isKeysDisabled()
        )
      ) {
        const gate: MenuItem = { text: 'Portaria', icon: 'fa-building-o', link: '/gate' };
        menu.push(gate);
      }

      // Condomínio
      const myCondo: MenuItem = {
        text: `${capitalize(condo?.customLabels?.condo?.singular)}`,
        icon: 'fa-hospital-o',
        link: '/condo'
      };
      menu.push(myCondo);

      // Minhas Residências
      if (user.isUser() || user.isAdmin() || user.isOwner()) {
        const residence: MenuItem = {
          text: 'Minhas ' + this.selectedCondo?.customLabels?.residence?.plural || 'unidades',
          icon: 'fa-home',
          link: '/residences'
        };
        menu.push(residence);
      }

      // Financeiro
      if (!condo.isFinanceDisabled() && ((user.isUser() && condo.anyoneViewFinance()) || user.isAdmin() || user.isOwner())) {
        const finance: MenuItem = { text: 'Financeiro', icon: 'fa-usd', link: '/finance/dashboard' };
        menu.push(finance);
      }

      // Manutenção
      if ((user.isAdmin() || user.isOwner() || user.isJanitor()) && !condo.isMaintenanceDisabled()) {
        const maintenance: MenuItem = { text: 'Manutenção', icon: 'fa-wrench', link: '/maintenance/plans' };
        menu.push(maintenance);
      }

      // Chamados de Manutenção
      if (!condo.isMaintenanceTicketsDisabled()) {
        const maintenanceTickets: MenuItem = {
          text: 'Chamados de manutenção',
          icon: 'fa-ticket',
          link: '/maintenance/tickets'
        };
        menu.push(maintenanceTickets);
      }

      // Hardware
      if (user.isOwner() || user.isAdmin() || user.isGatekeeper()) {
        const hardware: MenuItem = {
          text: 'Hardware',
          icon: 'fa-microchip',
          link: '/hardware'
        };
        menu.push(hardware);
      }

      // Relatórios
      if (user.isOwner() || user.isAdmin() || user.isGatekeeper()) {
        const reports: MenuItem = { text: 'Relatórios', icon: 'fa-files-o', link: '/reports' };
        menu.push(reports);
      }

      // MORE OPTIONS
      const moreOptions: MenuItem = {
        text: 'Mais opções',
        icon: 'fa-ellipsis-h',
        link: '/more-options'
      };
      menu.push(moreOptions);

      this.menu = menu;
    } else {
      this.menu = [];
    }
  }

  toggleHardwareDebug(evt) {
    if (evt.detail === 4) {
      this.hardwareSocketService.toggleDebugMode();
    }
  }

  private getUseTerms() {
    return this.acceptedTermsService.checkUserUseTerms().pipe(
      timeout(10_000),
      retry(3),
      tap(({ enabled, accepted }) => {
        this.canCheckUseTerms = enabled && !accepted;
      }),
      filter(() => this.canCheckUseTerms),
      map(({ useTerms }) => useTerms)
    );
  }

  buildFeaturesSubItems(user: User, condo: Condo) {
    const isAdmin = user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id);
    const isGatekeeper = user.isGatekeeperOnCondo(condo._id);
    const isJanitor = user.isJanitorOnCondo(condo._id);
    const isUser = user.isUserOnCondo(condo._id) && !isJanitor;

    const allSubItems: Array<MenuItem & { enabled: boolean }> = [
      {
        link: '/features/device-requests',
        text: 'Solicitações de dispositivos',
        icon: 'fa-microchip',
        enabled: !condo.isDeviceRequestDisabled() && (isAdmin || isGatekeeper)
      },
      {
        link: '/features/documents',
        text: 'Documentos',
        icon: 'fa-folder',
        enabled: !condo.isDocumentsDisabled() && condo.hasDocumentsAccess() && (isAdmin || isUser)
      },
      {
        link: '/features/faq',
        text: 'Perguntas frequentes',
        icon: 'fa-question-circle',
        enabled: !condo.isCondoFaqDisabled() && user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.faq.view })
      },
      {
        link: '/features/classificados',
        text: 'Classificados',
        icon: 'fa-shopping-cart',
        enabled: !condo.isClassificadosDisabled() && condo.hasAdAccess() && (isAdmin || isUser)
      },
      {
        link: '/features/rating',
        text: 'Avaliações',
        icon: 'fa-star',
        enabled: !condo.isCondoRatingDisabled() && (isAdmin || isGatekeeper || isUser)
      },
      {
        link: '/features/lost-and-founds',
        text: 'Achados e perdidos',
        icon: 'fa-search',
        enabled:
          !condo.isLostAndFoundsDisabled() && user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.lostAndFounds.view })
      },
      {
        link: '/features/asset-management',
        text: 'Controle de ativos',
        icon: 'fa-archive',
        enabled: condo.isAssetManagementEnabled() && (isAdmin || isGatekeeper || isJanitor)
      },
      {
        link: '/features/statistics',
        text: 'Estatísticas',
        icon: 'fa-line-chart',
        enabled: isAdmin
      },
      {
        link: '/features/event-announcement-board',
        text: 'Quadro de anúncios de eventos',
        icon: 'fa-desktop',
        enabled:
          condo.isEventAnnouncementBoardEnabled() &&
          user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.eventAnnouncementBoard.view })
      },
      {
        link: '/features/admin-tools',
        text: 'Ferramentas administrativas',
        icon: 'fa-shield',
        enabled: user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.personDeletionProcess.view })
      },
      {
        link: '/features/highlights',
        text: 'Destaques e informativos',
        icon: 'fa-bookmark',
        enabled: user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.highlights.view })
      }
    ];

    return allSubItems.filter(subItem => subItem.enabled);
  }
}

interface MenuItem {
  text: string;
  link: string;
  icon: string;
}
