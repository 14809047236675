import { CanActivate, Router } from '@angular/router';
import { UtilService } from '../services/util.service';
import { Injectable } from '@angular/core';
import { Condo } from '../api/model/condo';

@Injectable()
export class GateFeatureGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();

    if (condo.hasGateAccess()) {
      return true;
    }

    this.router.navigate(['/sale', 'gate']);
    return false;
  }
}

@Injectable()
export class AdFeatureGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();

    if (condo.hasAdAccess()) {
      return true;
    }

    this.router.navigate(['/sale', 'classificados']);
    return false;
  }
}

@Injectable()
export class DocumentsFeatureGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();

    if (condo.hasDocumentsAccess()) {
      return true;
    }
    this.router.navigate(['/sale', 'documents']);
    return false;
  }
}

@Injectable()
export class FinanceFeatureGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    const user = this.utilService.getLocalUser();
    const accessPermission: boolean =
      (user.isUserOnCondo(condo._id) && condo.anyoneViewFinance()) || user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id);

    if (condo.isFinanceDisabled() || !accessPermission) {
      this.router.navigate(['/notFound']);
      return false;
    }
    if (condo.hasFinanceAccess() && accessPermission) {
      return true;
    }
    this.router.navigate(['/sale', 'finance']);
    return false;
  }
}

@Injectable()
export class ReservationFeatureGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();

    if (condo.hasReservationAccess()) {
      return true;
    }
    this.router.navigate(['/sale', 'reservations']);
    return false;
  }
}

@Injectable()
export class HardwareFeatureGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo: Condo = this.utilService.getLocalCondo();

    if (condo && condo.isHardwareEnabled()) {
      return true;
    }
    this.router.navigate(['/notFound']);
    return false;
  }
}
