import { Contact } from '../interface/contact';
import { CondoVehicle } from '../condo.vehicle';
import { User } from '@api/model/user';

export class CondoContact extends Contact {
  private _condo: any;
  private _condoVehicles: Array<CondoVehicle>;
  private _company = '';
  private _service = '';
  public banned = false;
  public bannedBy = null;
  public bannedAt = '';
  public banReason = '';
  public specialNeeds = false;
  public specialNeedsDetails = '';
  public externalId;
  public obs = '';
  public mergedBy: User = null;
  public mergedTo = null;
  public mergedAt = '';

  constructor(condoContact?) {
    super(condoContact);
    if (condoContact) {
      this._condo = condoContact.condo || null;
      this._company = condoContact.company || '';
      this._service = condoContact.service || '';
      this._condoVehicles = condoContact.condoVehicles ? condoContact.condoVehicles.map(v => new CondoVehicle(v)) : [];
      this.banned = condoContact.banned || false;
      this.bannedBy = condoContact.bannedBy || null;
      this.bannedAt = condoContact.bannedAt || '';
      this.banReason = condoContact.banReason || '';
      this.specialNeeds = condoContact.specialNeeds || false;
      this.specialNeedsDetails = condoContact.specialNeedsDetails || '';
      this.obs = condoContact.obs || '';
      this.externalId = condoContact.externalId || '';
      this.mergedBy = condoContact.mergedBy ? new User(condoContact.mergedBy) : null;
      this.mergedTo = condoContact.mergedTo || null;
      this.mergedAt = condoContact.mergedAt || '';
    } else {
      this.construcEmptyObject();
    }
  }

  construcEmptyObject() {
    super.construcEmptyObject();
    this._condo = null;
    this._company = '';
    this._service = '';
    this._condoVehicles = new Array<CondoVehicle>();
    this.banReason = '';
    this.banned = false;
    this.bannedAt = '';
    this.bannedBy = null;
    this.mergedBy = null;
    this.mergedTo = null;
    this.mergedAt = '';
  }

  createBackObject() {
    const contactToSend: any = super.createBackObject();
    contactToSend.condoVehicles = this.condoVehicles.map(vehicle => vehicle._id);
    contactToSend.company = this.company || '';
    contactToSend.service = this.service || '';
    contactToSend.specialNeeds = this.specialNeeds;
    contactToSend.specialNeedsDetails = this.specialNeedsDetails || '';
    contactToSend.obs = this.obs || '';
    return contactToSend;
  }

  ban(by, reason) {
    this.banReason = reason;
    this.bannedAt = new Date().toISOString();
    this.banned = true;
    this.bannedBy = by;
  }

  unban() {
    this.banned = false;
    this.bannedAt = '';
    this.bannedBy = null;
    this.banReason = '';
  }

  get condo(): any {
    return this._condo;
  }

  set condo(value: any) {
    this._condo = value;
  }

  get condoVehicles(): Array<CondoVehicle> {
    return this._condoVehicles;
  }

  set condoVehicles(value: Array<CondoVehicle>) {
    this._condoVehicles = value;
  }

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value;
  }

  get service(): string {
    return this._service;
  }

  set service(value: string) {
    this._service = value;
  }
}
