import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../pipe/pipes.module';
import { get } from 'lodash';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import { ToastrService } from 'ngx-toastr';
import { generateObjectId } from 'utils/generateObjectId';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { generateCsvFromTableComponent, generateReportFromTableComponent } from '../../reports/report-generator';
import { User } from '@api/model/user';
import { UtilService } from '../../services/util.service';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { TableSortByColumnEventData, TableWrapperComponent } from './components/table-wrapper/table-wrapper.component';
import { TableRowComponent } from './components/table-row/table-row.component';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import { File } from '@api/model/file';

/**
 * General table actions buttons definition, such as edit, delete, etc.
 */
type ActionButton<TData = any> = {
  /**
   * CSS class applied to the button.
   * @default "btn-default"
   */
  buttonClass?: string;

  /**
   * A function that returns a CSS class to apply to the button
   * @param rowData
   * @param index
   */
  buttonClassFn?: (rowData: TData, index: number) => string;

  /**
   * Font Awesome icon class to apply an icon to the button
   * Ex.: fa-plus
   */
  icon?: `fa-${string}`;

  /**
   * A function that returns a Font Awesome icon class to apply to the button
   * @param rowData
   * @param index
   */
  iconFn?: (rowData: TData, index: number) => string;

  /**
   * Button's text
   */
  text?: string;

  /**
   * `title` property of <button> tag
   */
  title?: string;

  /**
   * A function that returns a string to be set on `title` property of <button> tag
   * @param rowData
   * @param index
   */
  titleFn?: (rowData: TData, index: number) => string;

  /**
   * Function executed on button click
   * @param data
   * @param index
   */
  handler: (data: TData, index: number) => unknown;

  /**
   * A function that defines whether the button should be shown or not
   * @param data
   * @param index
   */
  show?: (data: TData, index: number) => boolean;

  /**
   * A function that defines whether the button are disable or not
   * @param data
   * @param index
   */
  disabled?: (data: TData, index: number) => boolean;

  /**
   * Defines where should be the tooltip placement. The default is 'top'
   */
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
};

/**
 * Avatar table column type data definition
 */
type TableColumnAvatarTypeData = {
  /**
   * A string defining the name to show.
   */
  name: string;
  /**
   * An instance of eCondos's File
   */
  picture?: File;
  /**
   * The type of the avatar. This prop defines which default image will render when there is no picture.
   * Default to `user`.
   */
  type?: 'user' | 'pet';
  /**
   * The size of the image in `rem`. Default to `small`.
   * - "small" is set to `1.5rem` (`24px`)
   * - "medium" is set to `2rem` (`32px`)
   * - "large" is set to `3rem` (`48px`)
   */
  size?: 'small' | 'medium' | 'large';
};

/**
 * Table columns config to customize how the columns will be rendered
 */
export type TableColumnDefinition<TData = any> = {
  /**
   * The id of the column. This will be used to identify the column when a sort event happens. In case this is not
   * provided, the column index will be used as a fallback.
   */
  columnId?: string;

  /**
   * Text for `<th>` tag for this column. This only applies if `headerTemplate` is not provided.
   * <br><br>
   * If TableComponent's showColumnToggler prop is set to `true`, this prop is required or else the column toggler will
   * not show this column as an option.
   * <br><br>
   * If TableComponent's allowExportAsPdf or allowExportAsExcel props are set to `true`, this prop is required or else
   * the exported report will not show this column.
   */
  headerLabel?: string;

  /**
   * A `ng-template` reference to create a custom column header
   */
  headerTemplate?: TemplateRef<any>;

  /**
   * CSS classes for the column header. This only applies if `headerTemplate` is not provided
   */
  headerClass?: string;

  /**
   * A key that will be used to access a property of the row dynamically. This is onl applied if `valueTemplate` is not
   * provided.
   * <br><br>
   * If TableComponent's allowExportAsPdf or allowExportAsExcel props are set to `true`, this prop is required or else
   * the exported report will not show this column.
   */
  valueKey?: keyof TData;

  /**
   * A function that can be used to create a custom cell value. It must return a `string`. This is only applied if
   * `valueTemplate` is not provided.
   * <br><br>
   * If TableComponent's allowExportAsPdf or allowExportAsExcel props are set to `true`, this prop is required or else
   * the exported report will not show this column.
   * @param data
   * @param index
   */
  valueFn?: (rowData: TData, index: number) => string;

  /**
   * A `ng-template` reference to create a custom cell for this column. This is applied if column type is not provided
   */
  valueTemplate?: TemplateRef<any>;

  /**
   * CSS classes for the column cell. This only applies if `valueTemplate` is not provided
   */
  cellClass?: string;

  /**
   * A function that can be used to create a custom cell class. It must return a `string`. This is only applied if
   * `valueTemplate` is not provided
   * @param data
   * @param index
   */
  cellClassFn?: (rowData: TData, index: number) => string;

  /**
   * A string that defines the column width.
   * Ex.: 100px
   */
  width?: `${number}${'%' | 'px' | 'em' | 'rem' | 'vw' | 'vh' | 'vmin' | 'vmax'}`;

  /**
   * The type of the column. Each type has a specific cell layout.
   *
   * - "index" type will show each row's index.
   * - "actions" type will show an actions button group. When using this type, you must provide `actionsButtons` array too.
   * - "badge" type will show a badge in the cell. You can control its color using `cellClass` or `cellClassFn` props.
   * - "residence" type will render the `<app-residence-link>` component. When using this type, you must provide `residence` prop too. You can provide `showResidenceIcon` as well.
   * - "avatar" type will render a picture and a name besides it. When using this type, you must provide `avatar` prop too. You can provide `linkUrl` as well.
   */
  type?: 'index' | 'actions' | 'badge' | 'residence' | 'avatar';

  /**
   * Array of action buttons that must show in case the column type is "actions"
   */
  actionsButtons?: ActionButton<TData>[];

  /**
   * A function that returns an instance of Residence. This prop is required when using "residence" column type.
   */
  residence?: (rowData: TData, index: number) => Residence;
  /**
   * Defines whether the residence type column should show de residence icon.
   */
  showResidenceIcon?: boolean;

  /**
   * A function that returns the avatar data to render. This prop is required when using "avatar" column type.
   */
  avatar?: (rowData: TData, index: number) => TableColumnAvatarTypeData;

  /**
   * A function that returns a string link. If this is provided, the cell text will be rendered as a `<a>` tag
   * @param data
   * @param index
   */
  linkUrl?: (rowData: TData, index: number) => string;

  /**
   * Defines whether the column should be sortable or not. This only takes effect if no headerTemplate is provided
   */
  sortable?: boolean;

  /**
   * Defines whether the column should be shown or not.
   */
  show?: boolean;
};

/**
 * Table's fetching data status
 */
export type TableStatus = 'LOADING' | 'SUCCESS' | 'ERROR';

/**
 * Table's page change event data definition
 */
export type TablePageChangeEventData = {
  /**
   * The page number that is sent to the backend in the search query
   */
  queryPageNumber: number;

  /**
   * The actual table page number
   */
  tablePageNumber: number;

  /**
   * The limit of data per page
   */
  pageSize: number;
};

/**
 * Table's sort order
 */
export type TableSortOrder = 'asc' | 'desc';

/**
 * Table's sort change event data definition
 */
export type TableSortChangeEventData = {
  /**
   * Defines which column the data is sorted by. It can be the `columnId` provided in columns definition or the
   * column's index.
   */
  column: string | number;

  /**
   * Defines which order the sorting is in.
   */
  order: TableSortOrder;
};

/**
 * Table's retry on error click event data definition
 */
export type TableRetryOnErrorEventData = {
  /**
   * The page number that is sent to the backend in the search query
   */
  queryPageNumber: number;
};

/**
 * Table's row click event data definition
 */
export type TableRowClickEventData<TData = any> = {
  /**
   * Row's data
   */
  rowData: TData;
  /**
   * Row's index
   */
  index: number;
};

export type TDataWithTableId<TData> = TData & { __id: string };

@Component({
  standalone: true,
  selector: 'app-table',
  templateUrl: './table.component.html',
  imports: [
    KeyValuePipe,
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    PipesModule,
    SkeletonComponent,
    BsDropdownModule,
    ScrollingModule,
    TableWrapperComponent,
    TableRowComponent
  ],
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent<TData = any> implements OnInit, OnChanges, AfterViewInit {
  private user: User;

  /**
   * Array of objects that should be rendered in the table
   */
  @Input() data: TData[] = [];

  dataWithTableId: TDataWithTableId<TData>[] = [];

  /**
   * Table columns definition
   */
  @Input() columns: TableColumnDefinition<TData>[] = [];
  /**
   * The total amount of data. It will use `data.length` as a fallback
   */
  @Input() totalData = 0;
  /**
   * Defines whether the total amount of data should be rendered on the table's footer or not
   * @default true
   */
  @Input() showTotal = true;
  /**
   * Defines the description of data to should along with the total amount of data.
   * Ex.: 15 vehicles
   * @default "itens"
   */
  @Input() totalDescription = 'itens';

  /**
   * A template ref to render a custom header on the table.
   */
  @Input() tableHeaderTemplate: TemplateRef<any>;

  /**
   * Defines whether the table should show the go-to page input or not. This only takes effect if `paginate` is `true`
   */
  @Input() showGoToPageInput = true;
  /**
   * Defines whether the table should show the page size input or not. This only takes effect if `paginate` is `true`
   */
  @Input() showPageSizeInput = true;

  /**
   * Defines whether the table should paginate the data or not
   * @default true
   */
  @Input() paginate = true;
  /**
   * Defines the max number of data items for each page in case pagination is enabled
   * @default 15
   */
  @Input() pageSize = 15;
  /**
   * Defines the options of page sizes for the user to choose
   * @default [15, 50, 100, 250]
   */
  @Input() pageSizes = [15, 50, 100, 250];
  /**
   * Defines the current page of data
   * @default 1
   */
  currentPage = 1;

  /**
   * Defines whether the table should have a hover style or not
   */
  @Input() hover = true;
  /**
   * Defines whether the table should have a smaller (condensed) size or not
   */
  @Input() condensed = false;
  /**
   * Defines whether the table should be responsive to different screen sizes or not
   */
  @Input() responsive = true;
  /**
   * Defines whether the table header should be sticky at the top or not
   */
  @Input() stickyHeader = true;

  /**
   * Defines which is the loading status of the table.
   */
  @Input() status: TableStatus = 'SUCCESS';

  sortedColumn: string | number = null;
  sortOrder: TableSortOrder = null;

  /**
   * Defines which column the data is sorted by. It can be the `columnId` provided in columns definition or the
   * column's index.
   */
  @Input() initialSortedColumn: TableColumnDefinition<TData>['columnId'] | number = null;
  /**
   * Defines which order the sorting is in.
   */
  @Input() initialSortOrder: TableSortOrder = null;
  /**
   * Defines whether the table should reset the current page to 1 when sort data event is emited.
   */
  @Input() resetPageOnSort = true;
  /**
   * Emits whenever the user clicks on a sortable column header
   */
  @Output() sortChange = new EventEmitter<TableSortChangeEventData>();

  /**
   * Defines the feedback title that will show when there is no data to show
   */
  @Input() emptyFeedbackTitle = 'Não há dados';
  /**
   * Defines the feedback message that will show when there is no data to show
   */
  @Input() emptyFeedbackMessage = 'Tente refazer a busca com outro filtro';

  /**
   * Emits whenever the user clicks on a page number
   */
  @Output() pageChange = new EventEmitter<TablePageChangeEventData>();

  /**
   * Defines whether the table should show a retry button when error feedback
   */
  @Input() showRetryButtonOnError = false;
  /**
   * Defines the error feedback title shown on error.
   */
  @Input() errorFeedbackTitle = 'Ocorreu um erro ao carregar os dados';
  /**
   * Defines the error feedback message shown on error.
   */
  @Input() errorFeedbackMessage = 'Não foi possível buscar os dados. Verifique sua conexão e tente novamente';
  /**
   * Emits whenever the user clicks on retry button on error feedback
   */
  @Output() retryOnErrorClick = new EventEmitter<TableRetryOnErrorEventData>();

  /**
   * Defines whether the table should let the user select a row and how. If not provided, no selection will be allowed
   */
  @Input() selectionMode: 'none' | 'unique' | 'multiple' = 'none';
  /**
   * Emits whenever the user select one or more rows. It emits an array of data ids
   */
  @Output() selectRows = new EventEmitter<TData[]>();
  /**
   * Defines whether the table should show button to generate full report
   */
  @Input() enableFullReport = false;
  /**
   * Emits whenever the user click to generate a full report of the data
   */
  @Output() generateFullReport = new EventEmitter<void>();
  selectedData: string[] = [];
  selectedDataJSON: Record<string, { selected: boolean }> = {};

  /**
   * Emits whenever a row is clicked
   */
  @Output() rowClick = new EventEmitter<TableRowClickEventData<TData>>();

  /**
   * Defines whether the table should show a column toggler or not.
   * Default is false.
   */
  @Input() showColumnToggler = false;
  /**
   * Defines what should be the column toggler layout.
   * Default is 'dropdown'.
   */
  @Input() columnTogglerLayout: 'dropdown' | 'checkboxes' = 'dropdown';

  /**
   * Defines the column toggler label
   */
  @Input() columnTogglerLabel = 'Colunas visíveis';

  /**
   * Defines whether the table should show the button to export the data as a PDF file.
   */
  @Input() allowExportAsPdf = false;
  /**
   * Defines whether the table should show the button to export the data as an Excel file.
   */
  @Input() allowExportAsExcel = false;
  /**
   * Defines what should be the report's title when it is exported as PDF or Excel.
   */
  @Input() reportTitle = '';

  /**
   * Defines whether the table should use virtual or not.
   * If set to `true`, you may need to pass `virtualRowSize` param as well.
   */
  @Input() useVirtualScroll = false;
  /**
   * Defines the size of the virtual row that will be rendered on the table.
   */
  @Input() virtualRowSize = '30';

  paginationId: string;

  goToPage = this.currentPage;
  pagesTotal = 1;
  dataColSpan = 5;
  skeletonCells = Array(5)
    .fill('')
    .map((_, index) => index);
  skeletonRows = Array(15)
    .fill('')
    .map((_, index) => index);

  @ViewChild(CdkVirtualScrollViewport) public viewPort: CdkVirtualScrollViewport;

  headerTop = '0px';

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private utilService: UtilService,
    private toastr: ToastrService
  ) {
    this.user = this.utilService.getLocalUser();

    this.paginationId = Math.random().toString(36).substring(2);
  }

  ngAfterViewInit(): void {
    if (this.viewPort) {
      this.viewPort.renderedRangeStream
        .pipe(
          tap(range => {
            this.headerTop = `-${parseInt(this.virtualRowSize) * range.start}px`;
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe();
    }
  }

  ngOnInit() {
    this.sortedColumn = this.initialSortedColumn;
    this.sortOrder = this.initialSortOrder;

    if (!this.reportTitle) {
      this.reportTitle = this.totalDescription ? `Relatório de ${this.totalDescription}` : 'Relatório';
    }
    // Se for usuário system admin nós permitidos a exibição de mais dados
    if (this.user?.isSystemAdmin) {
      this.pageSizes = [...this.pageSizes, 1000, 2500, 5000, 10000, 15000];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.addIdToData(changes.data.currentValue);
    }

    if (changes.columns || changes.data) {
      const columns = changes.columns?.currentValue || this.columns;
      const data = changes.data?.currentValue || this.data;

      this.dataColSpan = columns?.length || (data[0] ? Object.keys(data[0]).length : 5);

      this.skeletonCells = Array(this.dataColSpan)
        .fill('')
        .map((_, index) => index);
    }

    if (changes.data || changes.totalData || changes.pageSize) {
      const totalData = changes.totalData?.currentValue || this.totalData;
      const pageSize = changes.pageSize?.currentValue || this.pageSize;

      this.pagesTotal = Math.ceil(totalData / pageSize);
    }

    if (changes.columns) {
      this.columns.forEach(col => {
        col.show = col.show ?? true;
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  addIdToData(data: TData[]) {
    // Copia a instância do objeto que veio para a tabela, para não perder métodos, getters e setters
    this.dataWithTableId = data.map(d =>
      Object.assign(Object.create(Object.getPrototypeOf(d)), {
        ...d,
        __id: generateObjectId()
      })
    );
  }

  getValue(data: TData, key: string): unknown {
    return get(data, key);
  }

  onPageChange(page: number | string): void {
    const parsedPage = parseInt(page.toString());

    if (parsedPage >= 1) {
      this.currentPage = parsedPage;

      this.pageChange.emit({
        tablePageNumber: parsedPage,
        queryPageNumber: parsedPage - 1,
        pageSize: this.pageSize
      });

      this.clearSelections();
    }
  }

  onPageSizeChange(event): void {
    const pageSize = event.target.value;
    this.pageSize = pageSize;

    this.pageChange.emit({
      tablePageNumber: 1,
      queryPageNumber: 0,
      pageSize
    });

    this.clearSelections();
  }

  onGoToPageInputKeydown(event: KeyboardEvent): void {
    if (event.key.match(/[.,-]/)) {
      event.preventDefault();
    }
  }

  handleGoToPageClick(): void {
    if (this.goToPage === this.currentPage) {
      return;
    }

    if (this.goToPage >= 1 && this.goToPage <= this.pagesTotal) {
      this.currentPage = this.goToPage;

      this.pageChange.emit({
        tablePageNumber: this.goToPage,
        queryPageNumber: this.goToPage - 1,
        pageSize: this.pageSize
      });

      this.clearSelections();
    } else {
      this.toastr.warning(`A página que está tentando acessar não existe. Você só pode acessar entre as páginas 1 e ${this.pagesTotal}`);
    }
  }

  handleSortByColumn({ column, columnIndex }: TableSortByColumnEventData): void {
    if (column.sortable && this.status !== 'LOADING') {
      const sortedColumn = column.columnId || columnIndex;
      let sortOrder: TableSortOrder = 'asc';

      if (this.sortedColumn === sortedColumn) {
        if (this.sortOrder === 'asc') {
          sortOrder = 'desc';
        }
      }

      this.sortedColumn = sortedColumn;
      this.sortOrder = sortOrder;

      if (this.resetPageOnSort) {
        this.currentPage = 1;
      }

      this.sortChange.emit({ column: sortedColumn, order: sortOrder });

      this.clearSelections();
    }
  }

  handleRetryOnError(): void {
    this.retryOnErrorClick.emit({ queryPageNumber: this.currentPage - 1 });
  }

  handleToggleRowSelection(rowData: (typeof this.dataWithTableId)[0]): void {
    if (this.selectionMode === 'none') {
      return;
    }

    if (this.selectionMode === 'unique') {
      this.selectedData = rowData?.__id === this.selectedData[0] ? [] : [rowData.__id];
    } else if (this.selectionMode === 'multiple') {
      const isAlreadySelected = this.selectedData.includes(rowData?.__id);

      if (isAlreadySelected) {
        this.selectedData = this.selectedData.filter(data => data !== rowData?.__id);
      } else {
        this.selectedData = [...this.selectedData, rowData?.__id];
      }
    }

    this.transformSelectedDataIntoJSON();
    this.emitSelectedData();
  }

  handleToggleAllRowsSelection(): void {
    if (this.selectionMode === 'none') {
      return;
    }

    if (this.selectedData.length) {
      this.selectedData = [];
    } else {
      this.selectedData = this.dataWithTableId.map((d: any) => d.__id);
    }

    this.transformSelectedDataIntoJSON();
    this.emitSelectedData();
  }

  emitSelectedData() {
    const selectedData = this.selectedData.reduce((accumulator, currentValue) => {
      const rowData = this.dataWithTableId.find(d => d.__id === currentValue);
      accumulator.push(rowData);
      return accumulator;
    }, [] as TData[]);

    this.selectRows.emit(selectedData);
  }

  clearSelections() {
    this.selectedData = [];
    this.transformSelectedDataIntoJSON();
    this.emitSelectedData();
  }

  transformSelectedDataIntoJSON(): void {
    this.selectedDataJSON = this.selectedData.reduce(
      (acc, cur) => {
        acc[cur] = { selected: true };
        return acc;
      },
      {} as typeof this.selectedDataJSON
    );
  }

  handleRowClick(rowData: TData, index: number): void {
    this.rowClick.emit({ rowData, index });
  }

  handleToggleColumn(columnIndex: number) {
    this.columns[columnIndex].show = !this.columns[columnIndex].show;
  }

  getColumnsToExport(columns: TableColumnDefinition[]) {
    return columns
      .filter(column => column.show && column.headerLabel && (column.valueKey || column.valueFn))
      .map(column => ({ headerLabel: column.headerLabel, valueKey: column.valueKey, valueFn: column.valueFn }));
  }

  handleExportAsPdf() {
    const columnsToExport = this.getColumnsToExport(this.columns);

    generateReportFromTableComponent({
      title: this.reportTitle,
      columns: columnsToExport,
      data: this.data,
      user: this.user
    });
  }

  handleExportAsExcel() {
    const columnsToExport = this.getColumnsToExport(this.columns);

    const encodedUri = generateCsvFromTableComponent({ columns: columnsToExport, data: this.data });

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${this.reportTitle}.csv`);
    link.click();
  }

  public getCurrentState() {
    const sortedColumn = this.sortedColumn || this.initialSortedColumn;
    const sortOrder = this.sortOrder || this.initialSortOrder;

    return {
      currentPage: this.currentPage,
      pageSize: this.pageSize,
      ...(!!sortedColumn && { sortedColumn, sortOrder }),
      selectedData: this.selectedData,
      selectedDataJSON: this.selectedDataJSON
    };
  }

  public resetState({ currentPage = false, currentSort = false, selectedData = false }) {
    if (currentPage) {
      this.currentPage = 1;
    }

    if (currentSort) {
      this.sortOrder = this.initialSortOrder;
      this.sortedColumn = this.initialSortedColumn;
    }

    if (selectedData) {
      this.clearSelections();
    }
  }

  public setCurrentPage(page: number) {
    this.onPageChange(page);
  }

  handleGenerateFullReport() {
    this.generateFullReport.emit();
  }
}
