import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Local } from '../model/local';
import { Reservation } from '../model/reservation';
import { ConstantService } from '../../services/constant.service';
import { HttpService } from '../../services/http.service';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { downloadDataInChunks } from '@api/utils';
import { BuildLocalGroup, LocalGroup } from '@api/model/local-group';

@Injectable()
export class ReservationService {
  protected endPoint;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /**
   * @deprecated Use getReservationLocals instead.
   */
  getCondoReservationLocals(condoId: string, queryString = ''): Observable<Local[]> {
    return this.http
      .get(`${this.endPoint}${condoId}/reservation-locals${queryString}`)
      .pipe(map((locals: any) => locals.map(local => new Local(local))));
  }

  getReservationLocals(condoId: string, params?: EcondosQuery): Observable<{ count: number; locals: Local[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/reservation-locals`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          locals: res.body.map(local => new Local(local))
        }))
      );
  }

  createReservationLocal(condoId: string, local: any) {
    return this.http.post(`${this.endPoint}${condoId}/reservation-locals`, local);
  }

  deleteReservationLocal(condoId: string, localId: string) {
    // Chamada que faz a remoção digital do local de reserva. Permitindo que o histório de reservas mantenha consistência
    // caso um local já reservado seja apagado
    // return this.http.put(`${this.endPoint}${condoId}/reservation-locals/${localId}/delete`, {});
    // Chamada antiga que realmente deleta o local do banco
    return this.http.delete(`${this.endPoint}${condoId}/reservation-locals/${localId}`);
  }

  updateReservationLocal(condoId: string, localId: string, local: any) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-locals/${localId}`, local);
  }

  enableReservationlocal(condoId: string, reservationLocalId: string) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/enable`, {});
  }

  disableReservationlocal(condoId: string, reservationLocalId: string) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/disable`, {});
  }

  // Reservations
  createReservation(condoId: string, reservation: any) {
    const localId = reservation.reservationLocal_id || reservation.reservationLocal;
    return this.http
      .post(`${this.endPoint}${condoId}/reservation-locals/${localId}/reservations/`, reservation)
      .pipe(map((res: any) => res));
  }

  deleteReservation(condoId: string, reservationLocalId: string, reservationlId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/reservations/${reservationlId}`);
  }

  updateReservation(condoId: string, reservationLocalId: string, reservationId: string, reservation: any) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/reservations/${reservationId}`, reservation);
  }

  cancelReservation(condoId: string, reservationLocalId: string, reservationId: string) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/reservations/${reservationId}/cancel`, {});
  }

  approveReservation(condoId: string, reservationLocalId: string, reservationId: string) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/reservations/${reservationId}/approve`, {});
  }

  createReservationInQueue(condoId: string, reservationInQueue: any): Observable<Reservation> {
    const localId = reservationInQueue.reservationLocal_id || reservationInQueue.reservationLocal;
    return this.http
      .post(`${this.endPoint}${condoId}/reservation-locals/${localId}/queue/enqueue`, reservationInQueue)
      .pipe(map(res => new Reservation(res)));
  }

  /**
   * @deprecated Use getReservations instead.
   */
  getCondoReservations(condoId: string, queryString = ''): Observable<Reservation[]> {
    return this.http
      .get(`${this.endPoint}${condoId}/reservations${queryString}`)
      .pipe(map((reservations: any) => reservations.map(res => new Reservation(res))));
  }

  getReservations(condoId: string, query: EcondosQuery): Observable<{ count: number; reservations: Reservation[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/reservations`, { params }).pipe(
      map((res: any) => {
        if (query.$count) {
          return {
            count: res.body[0],
            reservations: []
          };
        } else {
          return {
            count: res.headers.get('count'),
            reservations: res.body.map(reservation => new Reservation(reservation))
          };
        }
      })
    );
  }

  getReservationsReport(condoId: string, query: EcondosQuery) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/reservations`, query, { model: Reservation });
  }

  /**
   * @deprecated use getById instead
   */
  getReservationById(condoId: string, localId: string, reservationId: string, params: Array<{ [key: string]: string }> = []) {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }

    return this.http
      .get(`${this.endPoint}${condoId}/reservation-locals/${localId}/reservations/${reservationId}`, {
        params: httpParams
      })
      .pipe(map(reservation => new Reservation(reservation)));
  }

  getById(condoId: string, localId: string, reservationId: string, query: EcondosQuery) {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .get(`${this.endPoint}${condoId}/reservation-locals/${localId}/reservations/${reservationId}`, { params: httpParams })
      .pipe(map(reservation => new Reservation(reservation)));
  }

  checkIn(
    condoId: string,
    reservationLocalId: string,
    reservationId: string,
    checklist: {
      items: Array<{ item: string; status: boolean; description: string }>;
      signature?: string;
    }
  ) {
    return this.http.put(
      `${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/reservations/${reservationId}/check-in`,
      checklist
    );
  }

  checkOut(
    condoId: string,
    reservationLocalId: string,
    reservationId: string,
    checklist: {
      items: Array<{ item: string; status: boolean; description: string }>;
      signature?: string;
    }
  ) {
    return this.http.put(
      `${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/reservations/${reservationId}/check-out`,
      checklist
    );
  }

  bulkCancelReservations(condoId: string, reservationLocalId: string, reservations: Reservation[] | string[]) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-locals/${reservationLocalId}/reservations/bulk-cancel`, { reservations });
  }

  getReservationLocalGroups(condoId: string, params?: EcondosQuery): Observable<{ count: number; localGroups: LocalGroup[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/reservation-local-groups`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          localGroups: res.body.map(localGroup => BuildLocalGroup(localGroup))
        }))
      );
  }

  getReservationLocalGroupById(condoId: string, localGroupId: string, params?: EcondosQuery): Observable<{ localGroup: LocalGroup }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .get(`${this.endPoint}${condoId}/reservation-local-groups/${localGroupId}`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          localGroup: BuildLocalGroup(res.body)
        }))
      );
  }

  createReservationLocalGroup(condoId: string, localGroup: LocalGroup): Observable<{ localGroupId: string }> {
    return this.http
      .post(`${this.endPoint}${condoId}/reservation-local-groups`, localGroup)
      .pipe(map((res: any) => ({ localGroupId: res._id })));
  }

  deleteReservationLocalGroup(condoId: string, localGroupId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/reservation-local-groups/${localGroupId}`);
  }

  updateReservationLocalGroup(condoId: string, localGroupId: string, localGroup: LocalGroup) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-local-groups/${localGroupId}`, localGroup);
  }

  bulkCancelReservationsWithGroup(condoId: string, reservationLocalGroupId: string, reservations: Reservation[] | string[]) {
    return this.http.put(`${this.endPoint}${condoId}/reservation-local-groups/${reservationLocalGroupId}/bulk-cancel-reservations`, {
      reservations
    });
  }
}
