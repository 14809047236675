/**
 * Created by Rafael on 16/05/2017.
 * Baseado no projeto https://github.com/Martaver/ng2-photobooth
 */
import { Component, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { default as QrCode } from 'qrcode-reader';

export enum State {
  Recording,
  Loading
}

@Component({
  styles: [
    `
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      canvas {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `
  ],
  selector: 'modal-qrcode',
  templateUrl: 'qrcode.modal.html'
})
export class ModalQRCodeComponent implements AfterViewInit {
  @ViewChild('qrcodeModal', { static: true }) public qrcodeModal: ModalDirective;

  @Input() useSmallModal = false;

  qr = new QrCode();

  public State = State;
  state: State = State.Loading;
  video: any;
  canvas: any;
  navigator: any;
  window: any;
  private context: CanvasRenderingContext2D;
  private interval: any;

  constructor(public element: ElementRef) {}

  ngAfterViewInit() {
    this.window = window;
    this.navigator = window.navigator;
    this.video = this.element.nativeElement.querySelector('#recorder').querySelector('video');
    this.navigator.getUserMedia =
      this.navigator.getUserMedia || this.navigator.webkitGetUserMedia || this.navigator.mozGetUserMedia || this.navigator.msGetUserMedia;
    if (this.window.stream) {
      this.releaseVideo();
      this.releaseStream();
    }
    this.canvas = this.element.nativeElement.querySelector('#recorder').querySelector('canvas');
    this.setQRCodeReader();
  }

  setQRCodeReader() {
    this.qr = new QrCode();
    this.qr.callback = function (error, result) {
      if (error) {
        console.log('falhei na leitura', error);
        return;
      }
      clearInterval(this.interval);
      alert(result.result);
      console.log('li com sucesso', result);
    };
  }

  private initCam() {
    if (this.window.stream) {
      this.releaseVideo();
      this.releaseStream();
    }

    const constraints = {
      audio: false,
      video: true
    };

    this.navigator.getUserMedia(
      constraints,
      stream => {
        this.window.stream = stream; // make stream available to console
        // Success
        if (this.navigator.mozGetUserMedia) {
          this.video.mozSrcObject = stream;
        } else {
          try {
            this.video.srcObject = stream;
          } catch (e) {
            const vendorURL = window.URL || this.window.webkitURL;
            this.video.src = vendorURL.createObjectURL(stream);
          }
        }
        this.video.play();
      },
      error => {
        // Error
        console.log('navigator.getUserMedia error: ', error);
      }
    );
  }

  private releaseStream() {
    if (this.window.stream) {
      // Old, deprecated way of stopping the stream using MediaStream object.
      if (this.window.stream.stop) {
        this.window.stream.stop();
      } else {
        // New track-based approach to managing audio & video streams.
        this.window.stream.getAudioTracks().forEach(function (track) {
          track.stop();
        });

        this.window.stream.getVideoTracks().forEach(function (track) {
          track.stop();
        });
      }

      this.window.stream = null;
    }
  }

  private releaseVideo() {
    this.video.pause();
    // this.video.src = null;
    // When video.src is null, it continues triyng to play the video and generates null requests on network.
    // In case it causes problem, just change vide.src to null again
    this.video.src = '';
    if (this.video.hasOwnProperty('mozSrcObject')) {
      this.video['mozSrcObject'] = null;
    }
  }

  closeModal(): void {
    this.qrcodeModal.hide();
    this.releaseVideo();
    this.releaseStream();
    clearInterval(this.interval);
  }

  showModal() {
    this.initCam();
    this.state = State.Loading;
    this.qrcodeModal.show();
    this.interval = setInterval(() => {
      this.decode();
    }, 1000);
  }

  onHide(event) {
    this.releaseVideo();
    this.releaseStream();
    clearInterval(this.interval);
  }

  decode() {
    console.log(this.qr);
    const image = this.getSnapshot();
    if (image) {
      this.qr.decode(image);
    }
  }

  public getSnapshot() {
    if (this.window.stream) {
      const width = this.video.videoWidth;
      const height = this.video.videoHeight;
      this.canvas.width = width;
      this.canvas.height = height;

      if (width && height) {
        this.state = State.Recording;
        this.context = this.canvas.getContext('2d');
        this.context.drawImage(this.video, 0, 0);
        // this.context.drawImage(video, 0, 0, width, height);
        return this.context.getImageData(0, 0, width, height);
      } else {
        return null;
      }
    }
  }
}
