import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { CondoService } from '@api/service/condo.service';
import { UtilService } from '../../services/util.service';
import { EmergencyContact, Residence } from '@api/model/interface/residence';
import { ResidenceService } from '@api/service/residence.service';
import { User } from '@api/model/user';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { ResidencePickerComponent } from '../modal/residence.picker/residence.picker/residence.picker.component';
import { Condo } from '@api/model/condo';
import { ModalResidenceAccessLogComponent } from '../modal/residence.access.log.modal/residence.access.log.modal';
import { Error } from '@api/model/error/error';
import { catchError, flatMap, switchMap, take, tap, timeout, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { Status } from '@api/model/status';
import { File } from '@api/model/file';
import { ModalResidentPickerComponent } from '../modal/modal-resident-picker/modal-resident-picker.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { capitalize } from '@api/util/util';
import { EcondosQuery } from '@api/model/query';
import { ModalResidenceHardwareEventsLogComponent } from 'app/components/modal-residence-hardware-events-log/modal-residence-hardware-events-log';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { Construction } from '@api/model/construction';
import { ReceiptListComponent } from 'app/components/receipt-list/receipt-list.component';
import { ParamsService } from '@api/service/params.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

@Component({
  templateUrl: 'residences.html'
})
export class ResidencesPage implements OnInit, OnDestroy {
  @ViewChild(ResidencePickerComponent, { static: true }) residencePicker: ResidencePickerComponent;
  @ViewChild(ModalResidenceAccessLogComponent, { static: true }) residenceAccessLogModal: ModalResidenceAccessLogComponent;

  selectedResidence: Residence;
  userResidences = [];
  user: User;
  condo: Condo;

  userPickerMultipleSelection = false;
  userIdsToHide: Array<string> = [];

  shouldReloadResidencesToPick = false;

  // Variável responsável por conter o callback desejado após a seleção de algum usuário no modal de seleção de usuários
  userSelectCallback;

  isDownloading: boolean;

  condoAdmins: Array<User> = [];

  defaultUserPictureUrl = 'assets/img/empty-user-picture.png';

  selectSubject: Subject<string> = new Subject();
  private subscriptions: Subscription = new Subscription();
  constructions: Construction[] = [];
  detailsStatus: Status = new Status();
  isPetsEnabled = false;
  isDependentsEnabled = false;
  isResidenceHardwareEventsHistoryEnabled = false;
  isConstructionsEnabled = false;
  isBoletoEnabled = false;
  hasVehicleViewPermission = true;
  residence: Residence;

  dependentViewPermission = false;

  ROLES_LABEL = {
    OWNER: 'Síndico',
    GATEKEEPER: 'Porteiro',
    USER: 'Condômino',
    ADMIN: 'Administrador',
    REQUESTER: 'Solicitante',
    JANITOR: 'Zelador'
  };

  constructor(
    private condoService: CondoService,
    private utilService: UtilService,
    private residenceService: ResidenceService,
    private modalService: BsModalService,
    private deviceService: HardwareDeviceService,
    private paramsService: ParamsService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();
    this.residence = this.paramsService.getAndClear('residence');
    this.getUserResidences();
    this.getCondoAdmins();

    if (this.condo) {
      this.ROLES_LABEL.OWNER = capitalize(this.condo.customLabels.owner.singular) || 'Síndico';
      this.ROLES_LABEL.GATEKEEPER = capitalize(this.condo.customLabels.gatekeeper.singular) || 'Porteiro';
      this.ROLES_LABEL.USER = capitalize(this.condo.customLabels.resident.singular) || 'Condômino';
      this.ROLES_LABEL.ADMIN = capitalize(this.condo.customLabels.admin.singular) || 'Administrador';
      this.ROLES_LABEL.JANITOR = capitalize(this.condo.customLabels.janitor.singular) || 'Zelador';
    }

    this.hasVehicleViewPermission = this.user.getPermissionValue({
      condoId: this.condo._id,
      permission: PERMISSIONS.userResidences.vehicles.view
    });
  }

  ngOnInit(): void {
    this.isPetsEnabled = !this.condo?.isPetsDisabled();
    this.isDependentsEnabled = !this.condo?.isDependentsDisabled();
    this.isResidenceHardwareEventsHistoryEnabled = !this.condo?.isResidenceHardwareEventsHistoryDisabled();
    this.isConstructionsEnabled = !this.condo?.isConstructionsDisabled();
    this.isBoletoEnabled = this.condo?.isBoletoEnabled();

    if (!!this.user.customRoles.some(role => role?.condo?._id === this.condo._id)) {
      this.dependentViewPermission = this.user.getPermissionValue({
        condoId: this.condo._id,
        permission: PERMISSIONS.userResidences.dependents.view
      });
    } else {
      this.dependentViewPermission = true;
    }

    this.subscriptions.add(
      this.selectSubject
        .pipe(
          tap(() => {
            this.detailsStatus.setAsDownloading();
          }),
          switchMap(residenceId => {
            const constructionQuery: EcondosQuery = {
              $populate: [
                { path: 'residence', select: 'identification condo block identification number' },
                { path: 'createdBy', select: 'firstName lastName' },
                { path: 'attachments', select: 'url thumbnail type format name' },
                { path: 'technicalAttachments', select: 'url thumbnail type format name' }
              ]
            };
            return forkJoin(this.residenceService.getConstructions(this.condo._id, residenceId, constructionQuery)).pipe(
              timeout(15000),
              catchError(() => {
                this.detailsStatus.setAsError();
                return of(null);
              })
            );
          })
        )
        .subscribe(res => {
          if (res) {
            this.constructions = res[0].constructions;
            this.detailsStatus.setAsSuccess();
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAdminTooltip(admin: User): string {
    return ''.concat(admin.getUserRoleLabel(this.condo).join(', '), '\n', admin.firstName, ' ', admin.lastName);
  }

  getVoterTooltip(voter: User): string {
    const voterLabel = capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário';

    return ''.concat(voterLabel, '\n', voter.firstName, ' ', voter.lastName);
  }

  getCondoAdmins() {
    const qs: Array<string> = [];
    qs.push(`?$populate[0]=picture`);
    qs.push(`$or[0][condosOwner]=${this.condo._id}`);
    qs.push(`$or[1][condosAdmin]=${this.condo._id}`);
    qs.push(`$or[2][condosGatekeeper]=${this.condo._id}`);

    this.condoService
      .getUsersFromCondo(this.condo._id, qs.join('&'))
      .pipe(timeout(10000))
      .subscribe(
        admins => {
          const condoOwner = admins.find(user => {
            return user.condosOwner.findIndex((c: Condo) => c._id === this.condo._id) > -1;
          });

          const condoAdmins = admins.filter(user => {
            return user.condosAdmin.findIndex((c: Condo) => c._id === this.condo._id) > -1;
          });

          if (condoOwner) {
            this.condoAdmins.push(condoOwner);
          }
          if (condoAdmins.length) {
            this.condoAdmins = [].concat(this.condoAdmins, condoAdmins);
          }
        },
        () => {}
      );
  }

  addNewResidence() {
    this.residencePicker.pickResidence();
  }

  getResidenceQueryString(): EcondosQuery {
    const isAdmin = this.user.isAdmin() || this.user.isOwner();
    const residentCanViewVoter = this.condo.generalParams?.residences?.residentCanViewVoter ?? true;
    const residencesVoter = this.user.residencesVoter.map(residence => residence._id || residence);
    const query: EcondosQuery = {
      $select:
        'condo identification block lot number type address fraction defaulter requesters gateOccurrences users zipCode extensionLine params cnpj cpf company phones obs lineOfWork externalId contract emails lessee emergencyContacts',
      $populate: [
        {
          path: 'requesters',
          populate: { path: 'picture' }
        },
        { path: 'params', populate: { path: 'lastPanicEventUpdatedBy' } },
        { path: 'lastPanicEventUpdatedBy', populate: { path: 'firstName lastName' } }
      ]
    };
    /**
     * Se o usuário for admin ou a configuração do condo permitir, ele poderá ver todos os "voters" das "residences".
     * Do contrário apenas será retornada a propriedade quando ele mesmo for o "voter" da "residence"
     * O match afeta unicamente a propriedade voter. Ela será populada caso a condição seja satisfeita ou será retornada como null
     * Documentação em: https://mongoosejs.com/docs/5.x/docs/populate.html#query-conditions
     * */
    if (isAdmin || residentCanViewVoter) {
      query.$select = query.$select + ' voter';
      query.$populate.push({
        path: 'voter',
        populate: { path: 'picture' }
      });
    } else if (residencesVoter?.length > 0) {
      query.$select = query.$select + ' voter';
      query.$populate.push({
        path: 'voter',
        populate: { path: 'picture' },
        match: {
          residencesVoter: { $in: residencesVoter }
        }
      });
    }
    return query;
  }

  getUserResidences() {
    this.isDownloading = true;

    const user = this.utilService.getLocalUser();
    const qs = this.getResidenceQueryString();

    this.condoService
      .getResidencesFromUser(this.condo._id, user.id, qs)
      .pipe(timeout(10000))
      .subscribe(
        residences => {
          if (!residences || residences.length == 0) {
            // Atualizando residences do Usuario
            user.residencesVoter = [];
            user.residencesUser = [];
            user.residencesRequester = [];

            this.utilService.saveLocalUser(user);

            this.selectedResidence = undefined;
            this.userResidences = [];
            this.isDownloading = false;
            return;
          }

          residences.forEach(res => {
            try {
              for (const role of this.getUserRolesOnResidence(res, user)) {
                user[role].push(res);
              }
            } catch (err) {
              console.log(err);
            }
          });

          this.user = new User(user);
          this.userResidences = residences;
          this.utilService.saveLocalUser(user);

          // Select a residence in case none has been selected yet
          if (this.selectedResidence) {
            this.selectedResidence = this.userResidences.find(res => {
              return this.selectedResidence._id == res._id;
            });

            // In case user does not belong to the residence anymore
            if (!this.selectedResidence) {
              this.selectResidence(this.userResidences[0]);
            }
          } else {
            if (this.residence) {
              this.selectResidence(this.userResidences.find(res => res._id == this.residence));
              this.showResidenceHardwareEventsLog();
            } else {
              this.selectResidence(this.userResidences[0]);
            }
          }

          this.utilService.saveLocalUser(user);
          this.isDownloading = false;
        },
        () => {
          this.isDownloading = false;
          this.presentErrorAlert(
            'Problema ao buscar ' + this.condo?.customLabels?.residence?.plural || 'unidades',
            'Por favor, clique em atualizar para tentar novamente.'
          );
        }
      );
  }

  selectResidence(res: Residence) {
    const allUsers = [].concat(res.residenceUsers, res.requesters);
    if (allUsers.find(user => user._id == this.user.id)) {
      this.selectedResidence = res;
      this.selectSubject.next(this.selectedResidence._id);
    } else {
      this.selectedResidence = undefined;
    }
  }

  getUserRolesOnResidence(residence: Residence, user: User) {
    const roles = [];

    if (user.residencesRequester.find(res => res._id == residence._id)) {
      roles.push('residencesRequester');
    }

    if (user.residencesRequester.find(res => res._id == residence._id)) {
      roles.push('residencesUser');
    }

    if (user.residencesVoter.find(res => res._id == residence._id)) {
      roles.push('residencesVoter');
    }

    return roles;
  }

  presentErrorAlert(title: string, msg: string) {
    swal({
      type: 'error',
      title: title,
      text: msg,
      cancelButtonColor: '#f53d3d'
    });
  }

  askToSetUserAsVoter(user: User, residence: Residence) {
    swal({
      type: 'question',
      title: `Definir ${this.condo?.customLabels?.voter?.singular || 'proprietário'}`,
      text: `Deseja definir ${user.firstName} ${user.lastName} como ${this.condo?.customLabels?.voter?.singular || 'proprietário'} da(o) ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      } ${residence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.setUserAsVoter(user, residence)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject(
              `Não foi possível alterar o ${this.condo?.customLabels?.voter?.singular || 'proprietário'}, tente novamente.`
            );
          });
      }
    }).then(
      () => {
        this.getUserResidences();
        this.selectResidence(residence);
        swal({
          type: 'success',
          title: `${capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário'} alterado com sucesso!`
        });
      },
      () => {}
    );
  }

  setUserAsVoter(user, residence: Residence) {
    return this.residenceService.changeResidenceVoter(this.condo._id, residence.id, user._id);
  }

  askToRemoveVoterFromResidence(residence) {
    swal({
      type: 'question',
      title: `Remover ${this.condo?.customLabels?.voter?.singular || 'proprietário'}`,
      text: 'Tem certeza que deseja remover o proprietário desta(e) ' + this.condo?.customLabels?.residence?.singular || 'unidade',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.residenceService
          .removeVoterFromResidence(this.condo._id, residence._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject(
              `Não foi possível remover o ${this.condo?.customLabels?.voter?.singular || 'proprietário'}. Tente novamente`
            );
          });
      }
    }).then(
      () => {
        this.getUserResidences();
        swal({
          type: 'success',
          text: `${capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário'} removido com sucesso`
        });
      },
      () => {}
    );
  }

  askToApproveUserOnResidence(requester: User, residence: Residence) {
    swal({
      type: 'question',
      title: `Aceitar ${this.condo?.customLabels?.resident?.singular || 'condômino'}`,
      text: `Deseja realmente aceitar ${requester.firstName} ${requester.lastName} como ${
        this.condo?.customLabels?.resident?.singular || 'condômino'
      } da(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'} ${residence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.approveUserOnResidence(requester, residence)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject(
              'Não foi possível aceitar o ' + (this.condo?.customLabels?.resident?.singular || 'condômino') + ', tente novamente.'
            );
          });
      }
    }).then(
      () => {
        this.getUserResidences();
        swal({
          type: 'success',
          title: (capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino') + ' aceito com sucesso!'
        });
      },
      () => {}
    );
  }

  approveUserOnResidence(requester: User, residence: Residence) {
    if (residence.hasUsers()) {
      return this.residenceService.approveUserOnResidence(this.condo._id, residence.id, requester.id).pipe(
        flatMap(() => {
          return this.residenceService.getResidenceByIdWithParams(this.condo._id, residence.id, this.getResidenceQueryString());
        })
      );
    } else {
      return this.setUserAsVoter(requester, residence);
    }
  }

  askToRejectUserOnResidence(requester: User, residence: Residence) {
    swal({
      type: 'question',
      title: `Rejeitar ${this.condo?.customLabels?.resident?.singular || 'condômino'}`,
      text: `Deseja realmente rejeitar o pedido de ${requester.firstName} ${requester.lastName} para ser ${
        this.condo?.customLabels?.resident?.singular || 'condômino'
      } na(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'} ${residence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.rejectRequesterOnResidence(requester, residence)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject('Não foi possível rejeitar o pedido, tente novamente.');
          });
      }
    }).then(
      () => {
        this.getUserResidences();
        swal({
          type: 'success',
          title: 'Pedido rejeitado com sucesso!'
        });
      },
      () => {}
    );
  }

  rejectRequesterOnResidence(requester: User, residence: Residence) {
    return this.residenceService.rejectUserOnResidence(this.condo._id, residence.id, requester.id).pipe(
      flatMap(() => {
        return this.residenceService.getResidenceByIdWithParams(this.condo._id, residence.id, this.getResidenceQueryString());
      })
    );
  }

  selectUserToSetAsVoter(residence: Residence) {
    this.userPickerMultipleSelection = false;
    this.userIdsToHide = residence.voter ? [residence.voter._id] : [];
    this.userSelectCallback = selectedUsers => {
      if (selectedUsers && selectedUsers[0]) {
        this.askToSetUserAsVoter(selectedUsers[0], residence);
      }
    };
    this.selectUser();
  }

  selectUser() {
    const initialState: any = {
      condo: this.condo,
      userIdsToHide: this.userIdsToHide,
      multipleSelection: this.userPickerMultipleSelection,
      callbacks: {
        success: selectedUsers => {
          if (selectedUsers.length) {
            this.userSelectCallback(selectedUsers);
          }
        }
      }
    };
    this.modalService.show(ModalResidentPickerComponent, { initialState, class: 'modal-md', ignoreBackdropClick: true });
  }

  isVoter(residence) {
    return residence.voter && residence.voter._id == this.user.id;
  }

  onResidentsUpdated(users: User[]) {
    this.selectedResidence.users = users;
  }

  onUserSetAsVoter(user: User) {
    this.selectedResidence.voter = user;
  }
  addResidenceOnUser(selectedResidences) {
    if (!selectedResidences || !selectedResidences.length) {
      swal({
        title: 'Nenhuma ' + this.condo?.customLabels?.residence?.singular || 'unidade' + ' selecionada(o)'
      });
      return;
    }

    // Obtém a instãncia nativa do sweet alert. A abstração do plugin instalado não permite controle total sobre o modal
    swal({
      title: 'Adicionando ' + this.condo?.customLabels?.residence?.plural || 'unidades',
      type: 'info',
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    swal.showLoading();

    return forkJoin(
      selectedResidences.map(residence => {
        if (this.user.isAdmin() || this.user.isOwner() || this.isVoter(residence)) {
          if (residence.hasUsers()) {
            return this.residenceService.approveUserOnResidence(this.condo._id, residence.id, this.user.id);
          } else {
            return this.residenceService.changeResidenceVoter(this.condo._id, residence._id, this.user.id);
          }
        }
        return this.residenceService.addUserToResidence(this.condo._id, residence._id, this.user.id);
      })
    )
      .pipe(timeout(10000))
      .subscribe(
        () => {
          this.getUserResidences();
          swal.close();
          swal({
            type: 'success',
            title: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade' + '(s) adicionada(s) com sucesso',
            showCancelButton: false,
            confirmButtonText: 'Fechar'
          });
        },
        () => {
          this.getUserResidences();
          swal.close();
          swal({
            type: 'error',
            title: 'Erro ao adicionar ' + this.condo?.customLabels?.residence?.singular || 'unidade',
            text:
              'Nãoi foi possível adicionar um(a) ou mais ' + this.condo?.customLabels?.residence?.plural ||
              'unidades' + ', tente adicionar novamente',
            showCancelButton: false,
            confirmButtonText: 'Fechar'
          });
        }
      );
  }

  showResidenceAccessLog() {
    this.residenceAccessLogModal.showModal();
  }
  onDependentToUser(dependentInfo: { condoId: string; userId: string; dependentId: string }) {
    const { condoId, userId } = dependentInfo;
    this.condoService
      .getCondoUserById(condoId, userId)
      .pipe(timeout(10000), take(1))
      .subscribe(user => {
        this.selectedResidence.users = [].concat(this.selectedResidence.users, user);
      });
  }

  showResidenceHardwareEventsLog() {
    const initialState: any = {
      condo: this.condo,
      residence: this.selectedResidence
    };

    this.modalService.show(ModalResidenceHardwareEventsLogComponent, { initialState, class: 'modal-full' });
  }

  updatePanicEvent(residentCanUsePanicEvent: boolean) {
    swal({
      type: 'question',
      title: `Eventos de pânico`,
      text: `${residentCanUsePanicEvent ? 'Desativar' : 'Ativar'} eventos de pânico na(o) ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      } ${this.selectedResidence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.residenceService
          .updatePanicEvent(this.condo._id, this.selectedResidence._id, !residentCanUsePanicEvent)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject(
              `Não foi possível ${residentCanUsePanicEvent ? 'desativar' : 'ativar'} eventos de pânico, tente novamente.`
            );
          });
      }
    }).then(
      () => {
        this.getUserResidences();
        swal({
          type: 'success',
          title: `Eventos de pânico ${residentCanUsePanicEvent ? 'desativado' : 'ativado'} com sucesso!`
        });
      },
      () => {}
    );
  }

  onEmergencyContactChange(emergencyContacts: EmergencyContact[]) {
    this.selectedResidence.emergencyContacts = [...emergencyContacts];
  }

  onDocumentChange(documents: File[]) {
    this.selectedResidence.documents = documents;
  }

  handleConstructionCreated(construction: Construction) {
    this.constructions = [].concat(construction, this.constructions);
  }

  handleConstructionUpdated(construction: Construction) {
    const index = this.constructions.findIndex(d => d._id === construction._id);
    this.constructions[index] = construction;
    this.constructions = [].concat(this.constructions);
  }

  handleConstructionDeleted(construction: Construction) {
    const index = this.constructions.findIndex(d => d._id === construction._id);
    this.constructions.splice(index, 1);
    this.constructions = [].concat(this.constructions);
  }

  showReceipts() {
    const initialState = {
      condo: this.condo,
      residence: this.selectedResidence
    };
    this.modalService.show(ReceiptListComponent, { initialState, class: 'modal-xl' });
  }

  protected readonly PERMISSIONS = PERMISSIONS;
}
