import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { SkeletonComponent } from '../../../skeleton/skeleton.component';
import { TableColumnDefinition, TableSortOrder, TableStatus, TDataWithTableId } from '../../table.component';
import { CommonModule } from '@angular/common';
import { FeedbackContainerComponent } from '../../../feedback-container/feedback-container.component';
import { TableRowComponent } from '../table-row/table-row.component';

export type TableSortByColumnEventData = {
  column: TableColumnDefinition;
  columnIndex: number;
};

@Component({
  standalone: true,
  selector: 'app-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  imports: [CommonModule, SkeletonComponent, FeedbackContainerComponent, TableRowComponent]
})
export class TableWrapperComponent<TData = any> {
  @Input() dataWithTableId: TDataWithTableId<TData>[] = [];
  @Input() selectedData: string[] = [];
  @Input() selectedDataJSON: Record<string, { selected: boolean }> = {};
  @Input() columns: TableColumnDefinition<TData>[] = [];
  @Input() hover = true;
  @Input() condensed = false;
  @Input() stickyHeader = true;
  @Input() status: TableStatus = 'SUCCESS';
  @Input() selectionMode: 'none' | 'unique' | 'multiple' = 'none';
  @Input() dataColSpan = 5;
  @Input() skeletonCells = Array(5)
    .fill('')
    .map((_, index) => index);
  @Input() skeletonRows = Array(15)
    .fill('')
    .map((_, index) => index);
  @Input() emptyFeedbackTitle = 'Não há dados';
  @Input() emptyFeedbackMessage = 'Tente refazer a busca com outro filtro';
  @Input() errorFeedbackTitle = 'Ocorreu um erro ao carregar os dados';
  @Input() errorFeedbackMessage = 'Não foi possível buscar os dados. Verifique sua conexão e tente novamente';
  @Input() showRetryButtonOnError = false;
  @Input() sortedColumn: TableColumnDefinition<TData>['columnId'] | number = null;
  @Input() sortOrder: TableSortOrder = null;
  @Input() headerTop: string;

  @Output() toggleAllRowsSelection = new EventEmitter<void>();
  @Output() retryOnError = new EventEmitter<void>();
  @Output() sortByColumn = new EventEmitter<TableSortByColumnEventData>();

  @ContentChild('headers') headers: TemplateRef<any> | undefined;
  @ContentChild('rows') rows: TemplateRef<any> | undefined;

  handleToggleAllRowsSelection() {
    this.toggleAllRowsSelection.emit();
  }

  handleRetryOnError() {
    this.retryOnError.emit();
  }

  handleSortByColumn(column: TableColumnDefinition, columnIndex: number): void {
    this.sortByColumn.emit({ column, columnIndex });
  }
}
