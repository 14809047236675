import { Component, OnInit } from '@angular/core';
import { CondoService } from '@api/service/condo.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Residence } from '@api/model/interface/residence';
import { Status } from '@api/model/status';
import { NavbarService } from '../../../navbar/navbar.service';
import { ResidenceService } from '@api/service/residence.service';
import { UtilService } from '../../../../services/util.service';
import { timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';

@Component({
  selector: 'app-modal-residence-config',
  templateUrl: 'modal-residence-config.component.html',
  styleUrls: ['modal-residence-config.component.scss']
})
export class ModalResidenceConfigComponent implements OnInit {
  residence: Residence;

  residenceParamsForm: UntypedFormGroup;
  residentCanUseQRCode: AbstractControl;
  residentCanShareVisitorQRCode: AbstractControl;
  residentCanUseVirtualKey: AbstractControl;
  residentCanUseKeyboardApp: AbstractControl;
  residentCanUsePanicEvent: AbstractControl;

  status: Status = new Status();

  callbacks: { success?: Function; error?: Function };

  lastPanicEventUpdatedBy: string = '';
  constructor(
    private condoService: CondoService,
    private navbarService: NavbarService,
    private formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private residenceService: ResidenceService,
    private utilService: UtilService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.residenceParamsForm = this.formBuilder.group({
      residentCanUseQRCode: [true],
      residentCanShareVisitorQRCode: [true],
      residentCanUseVirtualKey: [true],
      residentCanUseKeyboardApp: [true],
      residentCanUsePanicEvent: [true]
    });
    this.residentCanUseQRCode = this.residenceParamsForm.get('residentCanUseQRCode');
    this.residentCanShareVisitorQRCode = this.residenceParamsForm.get('residentCanShareVisitorQRCode');
    this.residentCanUseVirtualKey = this.residenceParamsForm.get('residentCanUseVirtualKey');
    this.residentCanUseKeyboardApp = this.residenceParamsForm.get('residentCanUseKeyboardApp');
    this.residentCanUsePanicEvent = this.residenceParamsForm.get('residentCanUsePanicEvent');

    if (this.residence?.params) {
      this.getData();
    }
  }

  getData() {
    const query: EcondosQuery = {
      $select: 'params',
      $populate: [
        {
          path: 'params',
          select: 'lastPanicEventUpdatedBy',
          populate: { path: 'lastPanicEventUpdatedBy', select: 'firstName lastName' }
        }
      ]
    };
    const condoId: string = this.utilService.getLocalCondo()._id;
    this.residenceService
      .getResidenceByIdWithParams(condoId, this.residence._id, query)
      .pipe(timeout(10000))
      .subscribe(
        residence => {
          this.residenceParamsForm.patchValue(residence.params);
          if (residence.params.lastPanicEventUpdatedBy) {
            this.lastPanicEventUpdatedBy =
              residence.params.lastPanicEventUpdatedBy.firstName + residence.params.lastPanicEventUpdatedBy.lastName || '';
          }
        },
        error => {
          this.toastr.error('Não foi possível buscar os as configurações atuais da unidade. Tente novamente!');
        }
      );
  }

  save(params) {
    if (params) {
      this.status.setAsProcessing();
      const condoId: string = this.utilService.getLocalCondo()._id;
      this.residenceService
        .updateResidence(condoId, this.residence._id, { params })
        .pipe(timeout(10000))
        .subscribe(
          () => {
            this.status.setAsSuccess();
            this.callbacks.success(params);
            this.toastr.success('Configurações salvas com sucesso!');
            this.bsModalRef.hide();
          },
          error => {
            this.status.setAsError();
            this.toastr.error('Não foi possível processar sua solicitação. Tente novamente!');
          }
        );
    }
  }

  setValue(formControl: AbstractControl) {
    if (formControl.value) {
      formControl.setValue(false);
    } else {
      formControl.setValue(true);
    }
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
