import { Component } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { timeout } from 'rxjs/operators';
import { capitalize } from '@api/util/util';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '@api/service/session.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

type ParamConfig = {
  icon?: string;
  label: string;
  description?: string;
  true?: any;
  false?: any;
  value?: any;
  toggleValue?: any;
  originalValue?: any;
  paramName?: string;
  isSubSectionTitle?: boolean;
};

type ParamsSection = {
  title: string;
  icon: string;
  enabled?: boolean;
};

interface Superlogica {
  enabled: FormControl<boolean>;
  superlogicaAppToken: FormControl<string>;
  superlogicaAccessToken: FormControl<string>;
  condoCode: FormControl<string>;
  integrationType: FormControl<Condo['boleto']['integrationType'] | ''>;
}

@Component({
  selector: 'app-condo-integrations',
  templateUrl: 'condo-integrations.component.html'
})
export class CondoIntegrationsComponent {
  public condo = this.utilService.getLocalCondo();

  public isSubmitting = false;

  public CONDO_SECTION_PARAMS: Record<string, ParamsSection> = {
    superlogica: {
      title: 'Superlógica',
      icon: 'plus-square',
      enabled: true
    }
    // ahreas: {
    //   title: 'Ahreas',
    //   icon: 'plus-square',
    //   enabled: false
    // }
  };

  superlogicaForm = new FormGroup<Superlogica>({
    enabled: new FormControl(false, [Validators.required]),
    integrationType: new FormControl('', [Validators.required]),
    condoCode: new FormControl('', [Validators.required]),
    superlogicaAppToken: new FormControl('', [Validators.required]),
    superlogicaAccessToken: new FormControl('', [Validators.required])
  });

  public selectedParamsSection: keyof typeof this.CONDO_SECTION_PARAMS = 'superlogica';

  constructor(
    private condoService: CondoService,
    private sessionService: SessionService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.getSuperLogicaIntegration();
  }

  setParams(superLogica) {
    this.superlogicaForm.patchValue(superLogica);
  }

  getSuperLogicaIntegration() {
    this.condoService
      .getSuperlogicaConfig(this.condo._id)
      .pipe(timeout(10_000))
      .subscribe({
        next: (response: Superlogica) => {
          this.setParams(response);
        },
        error: () => {
          swal({
            type: 'error',
            text: 'Não foi possível carregar as configurações de integração com Superlógica, tente novamente.'
          });
        }
      });
  }

  handleSelectParamsSection(sectionKey: typeof this.selectedParamsSection) {
    this.selectedParamsSection = sectionKey;
  }

  handleSuperlogicaFormSubmit() {
    this.isSubmitting = true;
    this.condoService
      .testConnectionSuperlogica(this.condo._id, this.superlogicaForm.value)
      .pipe(timeout(10_000))
      .subscribe({
        next: response => {
          this.isSubmitting = false;
          this.toastrService.success(response['message']);
        },
        error: () => {
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: 'Dados incorretos de integração, verifique e tente novamente.'
          });
        }
      });
  }

  saveSuperlogica() {
    this.isSubmitting = true;

    if (this.superlogicaForm.value.enabled) this.superlogicaForm.patchValue({ integrationType: 'SUPERLOGICA' });

    this.condoService
      .saveSuperlogicaConfig(this.condo._id, this.superlogicaForm.value)
      .pipe(timeout(10_000))
      .subscribe({
        next: () => {
          this.isSubmitting = false;
          this.toastrService.success('Integração com Superlógica realizada com sucesso.');
        },
        error: () => {
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: 'Não foi possível realizar a integração com Superlógica, tente novamente'
          });
        }
      });
  }

  defaultSortFunction(): number {
    return 0;
  }
}
