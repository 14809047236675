import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { User } from '@api/model/user';
import { Subscription } from 'rxjs';
import { Condo } from '@api/model/condo';
import { timeout, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { Pet, PET_SEX_LABELS, PET_SIZES_LABELS, PET_SPECIES_LABELS, PET_SPECIES, PET_SEX, PET_SIZES } from '@api/model/pet';
import { ModalCreatePet } from '../../../components/modal-create-pet/modal-create-pet';
import { EcondosQuery } from '@api/model/query';
import { ResidenceAutoCompleteComponent } from 'app/components/residence-auto-complete/residence-auto-complete.component';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import { EcondosFilter } from '@api/model/filter';
import { PetServiceV2 } from '@api/serviceV2/pet.service';
import { capitalize } from '@api/util/util';
import { TableColumnDefinition, TableComponent, TableStatus } from 'app/components/table/table.component';
import { ClearFiltersEventData, RemoveFilterEventData } from '../../../components/applied-filters/applied-filters.component';

@Component({
  selector: 'app-condo-pets',
  templateUrl: 'condo-pets.html',
  styleUrls: ['./condo-pets.scss']
})
export class CondoPetsComponent implements OnInit, OnDestroy {
  @ViewChild(ResidenceAutoCompleteComponent) residencesAutoComplete: ResidenceAutoCompleteComponent;

  @ViewChild('petsTable', { static: true }) petsTable: TableComponent;
  @ViewChild('petAgeCellTemplate', { static: true }) petAgeCellTemplate: TemplateRef<any>;

  user: User;
  condo: Condo;

  pets: Pet[] = [];
  totalPetsCount = 0;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<Pet>[] = [];

  today = new Date();

  private subscriptions: Subscription = new Subscription();

  PET_SPECIES_LABELS = PET_SPECIES_LABELS;
  PET_SEX_LABELS = PET_SEX_LABELS;
  PET_SIZES_LABELS = PET_SIZES_LABELS;

  PET_SEX = Object.keys(PET_SEX);
  PET_SIZES = Object.keys(PET_SIZES);
  PET_SPECIES = Object.keys(PET_SPECIES);

  initialQuery: EcondosQuery = {
    $select: 'name specie breed birthDate sex size residence picture',
    $populate: [
      { path: 'picture', select: 'url thumbnail type format name' },
      { path: 'residence', select: 'identification condo block identification number' }
    ],
    $and: [],
    $or: []
  };

  customQuery = { ...this.initialQuery };

  filters: EcondosFilter[] = [];
  isFiltered = false;

  constructor(
    private petService: PetServiceV2,
    private utilService: UtilService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();

    const specieOptions = this.PET_SPECIES.map(specie => {
      return {
        label: this.PET_SPECIES_LABELS[specie],
        value: specie
      };
    });

    const sexOptions = this.PET_SEX.map(sex => {
      return {
        label: this.PET_SEX_LABELS[sex],
        value: sex
      };
    });

    const sizeOptions = this.PET_SIZES.map(size => {
      return {
        label: this.PET_SIZES_LABELS[size],
        value: size
      };
    });

    this.filters = [
      {
        element: 'residence-picker',
        elementSize: 'medium',
        label: capitalize(this.condo?.customLabels?.residence?.singular) || 'unidade',
        name: 'residence',
        params: {
          condo: this.condo,
          showAsInputGroup: true,
          typeaheadHideResultsOnBlur: true,
          placeholder: 'Digite um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade',
          adaptivePosition: true,
          clearInputTextAfterSelect: false
        },
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        placeholder: 'Busque pelo nome',
        name: 'name',
        searchType: 'regex',
        label: 'Nome'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'specie',
        selectOptions: [{ label: 'Todas', value: '' }, ...specieOptions],
        label: 'Espécie',
        searchType: 'match'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'sex',
        selectOptions: [{ label: 'Todas', value: '' }, ...sexOptions],
        label: 'Sexo',
        searchType: 'match'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'size',
        selectOptions: [{ label: 'Todos', value: '' }, ...sizeOptions],
        label: 'Porte',
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        placeholder: 'Busque pela raça',
        name: 'breed',
        searchType: 'regex',
        label: 'Raça'
      }
    ];
  }

  ngOnInit() {
    this.tableColumns = [
      {
        columnId: 'name',
        type: 'avatar',
        headerLabel: 'Nome',
        avatar: pet => ({ name: pet.name, picture: pet.picture, type: 'pet', size: 'medium' }),
        sortable: true
      },
      {
        columnId: 'specie',
        headerLabel: 'Espécie',
        valueFn: pet => this.PET_SPECIES_LABELS[pet.specie] || 'Não informado',
        sortable: true
      },
      { columnId: 'sex', headerLabel: 'Sexo', valueFn: pet => this.PET_SEX_LABELS[pet.sex] || 'Não informado', sortable: true },
      { columnId: 'size', headerLabel: 'Porte', valueFn: pet => this.PET_SIZES_LABELS[pet.size] || 'Não informado', sortable: true },
      { columnId: 'breed', headerLabel: 'Raça', valueFn: pet => pet.breed || 'Não informado', sortable: true },
      { headerLabel: 'Idade', valueTemplate: this.petAgeCellTemplate },
      {
        columnId: 'residence',
        type: 'residence',
        headerLabel: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        residence: pet => pet.residence
      },
      {
        type: 'actions',
        headerLabel: 'Ações',
        actionsButtons: [
          { icon: 'fa-pencil-square-o', title: 'Editar pet', handler: (pet: Pet) => this.handleEditPet(pet) },
          { icon: 'fa-trash', title: 'Excluir pet', handler: (pet: Pet) => this.handleDeletePet(pet) }
        ]
      }
    ];

    this.getData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getData({ page = 0 } = {}) {
    const query = this.customQuery;

    const { pageSize, sortedColumn, sortOrder } = this.petsTable.getCurrentState();

    query.$page = page;
    query.$limit = pageSize;

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    this.status = 'LOADING';

    this.petService
      .getPets(this.condo._id, query)
      .pipe(timeoutWith(20_000, ErrorBuilder.throwTimeoutError()))
      .subscribe({
        next: response => {
          this.totalPetsCount = response.count;
          this.pets = response.pets;
          this.status = 'SUCCESS';
        },
        error: () => {
          this.status = 'ERROR';
        }
      });
  }

  handleRefreshData() {
    const { currentPage } = this.petsTable.getCurrentState();
    this.getData({ page: currentPage - 1 });
  }

  handleCreatePet() {
    const initialState = {
      condo: this.condo,
      onCreate: pet => {
        this.pets = [pet, ...this.pets];
      }
    };
    this.modalService.show(ModalCreatePet, { initialState, ignoreBackdropClick: true });
  }

  handleEditPet(petToEdit: Pet) {
    const initialState = {
      condo: this.condo,
      pet: petToEdit,
      onUpdate: updatedPet => {
        this.pets = this.pets.map(pet => (pet._id === updatedPet._id ? updatedPet : pet));
      }
    };
    this.modalService.show(ModalCreatePet, { initialState, ignoreBackdropClick: true });
  }

  handleDeletePet(petToDelete: Pet) {
    swal({
      type: 'question',
      text: `Deseja remover o pet ${petToDelete.name} ?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.petService
          .delete(this.condo._id, petToDelete._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível excluir o pet, tente novamente...');
          });
      }
    }).then(
      () => {
        this.toastr.success('Pet removido com sucesso');
        this.pets = this.pets.filter(pet => pet._id !== petToDelete._id);
      },
      error => {
        console.log(error);
      }
    );
  }

  handleOpenFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        this.filters = filters;
        this.isFiltered = this.filters.some(filter => !!filter.value);
        this.customQuery = query;
        this.getData();
      }
    };

    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  handleRemoveIndividualFilter({ filters, removedFilterName }: RemoveFilterEventData) {
    this.filters = filters;
    this.isFiltered = this.filters.some(filter => !!filter.value);

    delete this.customQuery[removedFilterName];
    this.getData();
  }

  handleClearFilters({ filters }: ClearFiltersEventData) {
    this.filters = filters;
    this.isFiltered = false;
    this.customQuery = { ...this.initialQuery };
    this.getData();
  }
}
