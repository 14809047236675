import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { User } from '@api/model/user';
import { from, of, Subject, Subscription } from 'rxjs';
import { Condo } from '@api/model/condo';
import { catchError, map, mergeAll, takeUntil, tap, timeout, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { ALLOW_VISITORS_ACCESS, Dependent } from '@api/model/dependent';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalCreateDependent } from '../../../components/modal-create-dependent/modal-create-dependent';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { EcondosQuery } from '@api/model/query';
import { DependentServiceV2 } from '@api/serviceV2/dependent.service';
import { EcondosFilter } from '@api/model/filter';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import * as moment from 'moment';
import { capitalize } from '@api/util/util';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { TableColumnDefinition, TableComponent, TableStatus } from 'app/components/table/table.component';

@Component({
  selector: 'app-condo-dependents',
  templateUrl: 'condo-dependents.html',
  styleUrls: ['./condo-dependents.scss']
})
export class CondoDependentsComponent implements OnInit, OnDestroy {
  user: User;
  condo: Condo;

  isAdminOrOwner = false;

  @ViewChild('dependentsTable', { static: true }) dependentsTable: TableComponent;
  @ViewChild('dependentNameCellTemplate', { static: true }) dependentNameCellTemplate: TemplateRef<any>;
  @ViewChild('dependentPhoneCellTemplate', { static: true }) dependentPhoneCellTemplate: TemplateRef<any>;
  @ViewChild('dependentRgCellTemplate', { static: true }) dependentRgCellTemplate: TemplateRef<any>;
  @ViewChild('dependentCpfCellTemplate', { static: true }) dependentCpfCellTemplate: TemplateRef<any>;

  dependents: Dependent[] = [];
  totalDependentsCount = 0;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<Dependent>[] = [];

  today = new Date();

  private subscriptions: Subscription = new Subscription();

  ALLOW_VISITORS_ACCESS;

  initialQuery: EcondosQuery = {
    $select: 'name birthDate phone kinship age rg cpf panicWord specialNeeds specialNeedsDetails createdAt updatedAt room allowVisitors',
    $populate: [
      { path: 'picture', select: 'url thumbnail type format name' },
      { path: 'residence', select: 'identification condo block identification number' }
    ],
    $and: [],
    $or: []
  };

  customQuery = { ...this.initialQuery };

  numberOfActiveFilters = 0;
  filters: EcondosFilter[] = [];

  customDependentLabel = 'dependente';

  constructor(
    private dependentService: DependentServiceV2,
    public utilService: UtilService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private deviceService: HardwareDeviceService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();

    this.customDependentLabel = this.condo.customLabels.dependent.singular || (this.condo.isBusinessCondo() ? 'funcionário' : 'dependente');

    this.isAdminOrOwner = this.user.isAdminOnCondo(this.condo._id) || this.user.isOwnerOnCondo(this.condo._id);

    this.filters = [
      {
        element: 'residence-picker',
        label: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        name: 'residence',
        params: {
          condo: this.condo,
          showAsInputGroup: true,
          typeaheadHideResultsOnBlur: true,
          placeholder: 'Digite um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade',
          adaptivePosition: true,
          clearInputTextAfterSelect: false
        },
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'name',
        label: 'Nome',
        placeholder: 'Busque pelo nome',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'number',
        name: 'age',
        label: 'Idade',
        placeholder: 'Busque pela idade (em anos)',
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'phone',
        label: 'Telefone',
        placeholder: 'Busque pelo telefone',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'kinship',
        label: `${this.condo.isBusinessCondo() ? 'Cargo' : 'Parentesco'}`,
        placeholder: `Busque pelo ${this.condo.isBusinessCondo() ? 'cargo' : 'parentesco'}`,
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'rg',
        label: 'RG',
        placeholder: 'Busque pelo RG',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'cpf',
        label: 'CPF',
        placeholder: 'Busque pelo CPF',
        searchType: 'regex'
      }
    ];
  }

  ngOnInit() {
    this.tableColumns = [
      { columnId: 'name', headerLabel: 'Nome', valueTemplate: this.dependentNameCellTemplate, sortable: true },
      { headerLabel: 'Idade', valueKey: 'age' },
      { columnId: 'phone', headerLabel: 'Telefone', valueTemplate: this.dependentPhoneCellTemplate, sortable: true },
      { columnId: 'kinship', headerLabel: this.condo.isBusinessCondo() ? 'Cargo' : 'Parentesco', valueKey: 'kinship', sortable: true },
      { columnId: 'rg', headerLabel: 'RG', valueTemplate: this.dependentRgCellTemplate, sortable: true },
      { columnId: 'cpf', headerLabel: 'CPF', valueTemplate: this.dependentCpfCellTemplate, sortable: true },
      {
        columnId: 'residence',
        type: 'residence',
        headerLabel: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        residence: dependent => dependent.residence
      },
      {
        columnId: 'allowVisitors',
        type: 'badge',
        headerLabel: `Pode autorizar ${this.condo.customLabels?.visitor?.plural || 'visitantes'}?`,
        valueFn: dependent => ALLOW_VISITORS_ACCESS[dependent.allowVisitors]?.label || 'Não informado',
        cellClassFn: dependent => ALLOW_VISITORS_ACCESS[dependent.allowVisitors].badgeClass || 'badge-light',
        sortable: true
      }
    ];
    if (this.condo.isEventAnnouncementBoardEnabled()) {
      this.tableColumns.push({
        columnId: 'room',
        headerLabel: this.condo.isBusinessCondo() ? 'Departamento' : 'Sala',
        valueKey: 'room',
        sortable: true
      });
    }

    if (this.isAdminOrOwner) {
      this.tableColumns.push({
        type: 'actions',
        headerLabel: 'Ações',
        actionsButtons: [
          {
            icon: 'fa-pencil-square-o',
            title: `Editar ${this.customDependentLabel}`,
            handler: dependent => this.handleEditDependent(dependent)
          },
          {
            icon: 'fa-user-plus',
            title: `Converter em ${this.condo?.customLabels?.resident?.singular || 'condômino'}`,
            handler: dependent => this.handleConvertDependentIntoUser(dependent)
          },
          {
            icon: 'fa-trash',
            title: `Excluir ${this.customDependentLabel}`,
            handler: dependent => this.handleDeleteDependent(dependent)
          }
        ]
      });
    }

    this.getData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getData({ page = 0 } = {}) {
    const query = this.customQuery;

    const { pageSize, sortedColumn, sortOrder } = this.dependentsTable.getCurrentState();

    query.$page = page;
    query.$limit = pageSize;

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    this.status = 'LOADING';

    this.dependentService
      .getDependents(this.condo._id, query)
      .pipe(timeoutWith(20000, ErrorBuilder.throwTimeoutError()))
      .subscribe({
        next: response => {
          this.totalDependentsCount = response.count;
          this.dependents = response.dependents;
          this.status = 'SUCCESS';
        },
        error: () => {
          this.status = 'ERROR';
        }
      });
  }

  handleRefreshData() {
    const { currentPage } = this.dependentsTable.getCurrentState();
    this.getData({ page: currentPage - 1 });
  }

  handleCreateDependent() {
    const initialState = {
      condo: this.condo,
      onCreate: (dependent: Dependent) => {
        this.dependents = [dependent, ...this.dependents];
      }
    };
    this.modalService.show(ModalCreateDependent, { initialState, class: 'modal-lg' });
  }

  handleEditDependent(dependentToEdit: Dependent) {
    const initialState = {
      condo: this.condo,
      dependent: dependentToEdit,
      onUpdate: (updatedDependent: Dependent) => {
        this.dependents = this.dependents.map(dependent => (dependent._id === updatedDependent._id ? updatedDependent : dependent));
      }
    };
    this.modalService.show(ModalCreateDependent, { initialState, class: 'modal-lg' });
  }

  handleConvertDependentIntoUser(dependentToConvert: Dependent) {
    this.dependentService
      .convertDependentToUser(this.condo._id, dependentToConvert._id)
      .pipe(timeout(10000))
      .subscribe(
        () => {
          this.toastr.success(`${capitalize(this.customDependentLabel)} convertido com sucesso`);
          this.getData();
        },
        error => {
          this.toastr.error(
            `Não foi possível converter o ${this.customDependentLabel} em usuário. Cheque se o ${this.customDependentLabel} tem todas as informações necessárias.`
          );
          console.log(error);
        }
      );
  }

  handleDeleteDependent(dependentToDelete: Dependent) {
    const query: EcondosQuery = {
      $or: [{ 'owner.userName': dependentToDelete.name }, { 'owner.dependent': dependentToDelete._id }],
      accessType: 'RESIDENT',
      'owner.residence': dependentToDelete.residence._id || dependentToDelete.residence,
      status: { $in: ['ACTIVE', 'SYNCED', 'UNSYNCED', 'OPENED'] }
    };

    const onPreConfirm = () => {
      return this.dependentService
        .delete(this.condo._id, dependentToDelete._id)
        .pipe(timeout(10000))
        .toPromise()
        .catch(err => {
          console.log(err);
          return Promise.reject('Não foi possível excluir o registro, tente novamente...');
        });
    };

    const onSuccess = () => {
      this.toastr.success('Registro removido com sucesso');
      this.dependents = this.dependents.filter(dependent => dependent._id !== dependentToDelete._id);
    };

    this.deviceService
      .get(this.condo._id, query)
      .pipe(timeout(10000))
      .subscribe(({ count }) => {
        if (count > 0) {
          swal({
            type: 'warning',
            title: `O ${this.customDependentLabel} não foi excluído pois está associado a um dispositivo`,
            text: `Este ${this.customDependentLabel} possui dispositivos de acesso associados a ele. Realmente deseja excluir o ${this.customDependentLabel}? Os dispositivos de acesso serão mantidos após a exclusão.`,
            confirmButtonText: 'Sim, entendo os riscos',
            showCancelButton: true,
            cancelButtonText: 'Não',
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: onPreConfirm
          }).then(onSuccess, console.error);
        } else {
          swal({
            type: 'question',
            text: `Deseja remover o ${this.customDependentLabel} ${dependentToDelete.name}?`,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: onPreConfirm
          }).then(onSuccess, console.error);
        }
      });
  }

  handleOpenFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        const age = filters.find(filter => filter.name === 'age')?.value;
        if (age) {
          const birthDate = moment().subtract(Number(age), 'years').toDate();

          delete query.age;
          query.birthDate = {
            $gte: moment(birthDate).startOf('year').utc().toISOString(),
            $lte: moment(birthDate).endOf('year').utc().toISOString()
          };
        }

        this.countActiveFilters(filters);
        this.filters = filters;
        this.customQuery = query;
        this.getData();
      }
    };

    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  countActiveFilters(filters: EcondosFilter[]) {
    this.numberOfActiveFilters = filters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }

  handleRevealData(field: string, dependent) {
    dependent.isDataMasked[field] = false;

    let isFieldAlreadyUnmasked: boolean;

    if (field === 'birthDate') {
      isFieldAlreadyUnmasked = !dependent.birthDate.toString().includes('3000');
    } else {
      isFieldAlreadyUnmasked = !dependent[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    const callback = ({ data }) => (dependent[field] = data);

    this.dependentService.getDependentUnmaskedField(this.condo._id, dependent._id, field, query).pipe(timeout(10000)).subscribe(callback);
  }

  handleRemoveIndividualFilter({ index }) {
    this.filters[index].value = '';
    this.filters[index].valueLabel = '';

    delete this.customQuery[this.filters[index].name];

    if (this.filters[index].name === 'age') {
      delete this.customQuery['birthDate'];
    }

    this.numberOfActiveFilters--;
    this.getData();
  }

  handleClearFilters() {
    this.filters = this.filters.map(filter => {
      return {
        ...filter,
        value: '',
        valueLabel: ''
      };
    });
    this.numberOfActiveFilters = 0;
    this.customQuery = { ...this.initialQuery };
    this.getData();
  }

  askToRemoveAllDependents() {
    const unsubscribe: Subject<void> = new Subject();
    const text = `Você realmente deseja excluir TODOS ${
      this.condo?.customLabels?.dependent?.plural?.toUpperCase() || 'DEPENDENTES'
    }? Digite "excluir tudo" no campo abaixo para confirmar sua ação. Lembre-se que esta ação não pode ser desfeita.`;
    swal({
      type: 'warning',
      title: 'Excluir todos ' + this.condo?.customLabels?.dependent?.plural || 'dependentes',
      text,
      showCancelButton: true,
      input: 'text',
      inputPlaceholder: 'excluir tudo',
      confirmButtonText: 'Excluir ' + this.condo?.customLabels?.dependent?.plural || 'dependentes',
      confirmButtonColor: '#f53d3d',
      cancelButtonClass: 'btn-outline-danger',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async input => {
        input = (input || '').toString().trim().toLowerCase();
        if (!input || input !== 'excluir tudo') {
          return Promise.reject(`Digite "excluir tudo" para confirmar sua ação`);
        } else {
          const dependents = await this.getDependentsRecursively();
          const results = [];
          const requests = dependents.map(dependent => {
            return this.dependentService.delete(this.condo._id, dependent._id).pipe(
              timeout(10000),
              tap(() => {
                results.push({ [dependent._id]: true });
                swal.getTitle().textContent = `Processando: ${results.length}/${requests.length}`;
              }),
              catchError(() => {
                results.push({ [dependent._id]: false });
                return of(null);
              })
            );
          });
          await from(requests).pipe(mergeAll(5), takeUntil(unsubscribe)).toPromise();
          return Promise.resolve(results);
        }
      }
    })
      .then(() => {
        this.getData();
        swal({
          type: 'success',
          title: `${capitalize(this.condo?.customLabels?.dependent?.plural) || 'dependentes'} excluídos(as) com sucesso!`
        });
      })
      .catch(() => {
        this.getData();
        unsubscribe.next(null);
        unsubscribe.complete();
      });
  }

  async getDependentsRecursively(page = 0, size = 100) {
    const dependents = await this.dependentService
      .getDependents(this.condo._id, {
        $select: '_id',
        $limit: size,
        $page: page
      })
      .pipe(map(res => res.dependents))
      .toPromise();
    if (dependents.length < size) {
      return dependents;
    } else {
      return dependents.concat(...(await this.getDependentsRecursively(++page, size)));
    }
  }
}
