import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '@api/model/condo';
import { Group } from '@api/model/group';
import { Status } from '@api/model/status';
import { UtilService } from '../../services/util.service';
import { GroupService } from '@api/serviceV2/group.service';

@Component({
  selector: 'modal-create-group',
  templateUrl: 'modal-create-group.html',
  styleUrls: ['./modal-create-group.scss']
})
export class ModalCreateGroupComponent implements OnInit {
  isAdmin = false;
  condo: Condo;
  group: Group;
  status: Status = new Status();
  icons = [
    'building-o',
    'bullhorn',
    'ban',
    'calculator',
    'calendar-check-o',
    'dollar',
    'envelope',
    'font-awesome',
    'leaf',
    'eye',
    'exclamation-triangle',
    'fire-extinguisher',
    'cutlery',
    'handshake-o',
    'gavel',
    'hourglass',
    'key',
    'lightbulb-o',
    'map-marker',
    'lock',
    'paperclip',
    'bell-o',
    'phone',
    'pencil',
    'search',
    'wrench',
    'star',
    'suitcase',
    'book',
    'heart',
    'male',
    'user',
    'users',
    'car',
    'motorcycle',
    'truck',
    'volume-up'
  ];

  form: UntypedFormGroup;

  name: AbstractControl;
  description: AbstractControl;
  icon: AbstractControl;

  onCreate: Function = () => {};

  onUpdate: Function = () => {};

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private groupService: GroupService,
    private utilService: UtilService,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      icon: ['', Validators.required]
    });

    this.name = this.form.get('name');
    this.description = this.form.get('description');
    this.icon = this.form.get('icon');

    const user = this.utilService.getLocalUser();
    this.isAdmin = user.isAdmin() || user.isOwner();
  }

  ngOnInit() {
    if (this.group) {
      this.name.setValue(this.group.name);
      this.description.setValue(this.group.description);
      this.icon.setValue(this.group.icon);
    }
  }

  submit() {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const group: any = {
        name: this.name.value,
        description: this.description.value,
        icon: this.icon.value
      };
      if (this.group) {
        this.update(group);
      } else {
        this.create(group);
      }
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha os campos obrigatórios.');
    }
  }

  create(group) {
    this.groupService.create(this.condo._id, group).subscribe(
      (res: any) => {
        this.onCreate(res);
        this.bsModalRef.hide();
        this.toastr.success('Registro cadastrado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível efetuar o cadastro, tente novamente');
      }
    );
  }

  update(group) {
    let subscription;

    subscription = this.groupService.update(this.condo._id, this.group._id, group);

    subscription.subscribe(
      (res: any) => {
        this.onUpdate({ ...this.group, ...group });
        this.bsModalRef.hide();
        this.toastr.success('Registro atualizado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível atualizar o registro, tente novamente');
      }
    );
  }
}
