import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Camera } from '../model/camera';
import { EcondosQuery } from '../model/query';
import * as qs from 'qs';
import { Deleted } from '@api/model/deleted';

type DeletedCamera = Camera & Deleted;

@Injectable({ providedIn: 'root' })
export class CameraService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; cameras: Camera[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/cameras`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          cameras: res.body as Camera[]
        }))
      );
  }

  getDeleted(condoId: string, params: EcondosQuery): Observable<{ count: number; cameras: DeletedCamera[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/cameras/deleted`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          cameras: res.body as DeletedCamera[]
        }))
      );
  }

  create(condoId: string, camera: Camera) {
    return this.http.post(`${this.endPoint}${condoId}/cameras`, camera);
  }

  update(condoId: string, cameraId: string, camera: Partial<Camera>) {
    return this.http.put(`${this.endPoint}${condoId}/cameras/${cameraId}`, camera);
  }

  delete(condoId: string, cameraId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/cameras/${cameraId}`);
  }

  restore(condoId: string, cameraId: string) {
    return this.http.put(`${this.endPoint}${condoId}/cameras/${cameraId}/restore`, {});
  }

  getById(condoId: string, cameraId: string): Observable<Camera> {
    return this.http.get(`${this.endPoint}${condoId}/cameras/${cameraId}`) as Observable<Camera>;
  }

  // MONUV
  getUrlById(condoId: string, cameraId: string): Observable<{ url: string }> {
    return this.http.get(`${this.endPoint}${condoId}/cameras/${cameraId}/url`) as Observable<{ url: string }>;
  }

  /**
   * Cameras with id will be updated, cameras without id will be created
   * @param condoId
   * @param cameras
   */
  saveMultipleCameras(condoId: string, cameras: Camera[]) {
    return this.http.post(`${this.endPoint}${condoId}/cameras/multiple`, cameras);
  }
}
