// Models
import { Residence } from './interface/residence';
import { Vehicle } from './vehicle';
import { ResidenceBuilder } from './residence/residence.builder';

export class CondoVehicle extends Vehicle {
  public residence: Residence;
  public parkingSpot: string;

  constructor(condoVehicle?) {
    super(condoVehicle);

    if (condoVehicle) {
      this.residence = condoVehicle.residence ? ResidenceBuilder.build(condoVehicle.residence) : null;
      this.parkingSpot = condoVehicle.parkingSpot;
    }
  }

  createBackObject() {
    const obj: any = super.createBackObject();
    if (this.residence) {
      obj.residence = this.residence._id;
    }
    if (this.parkingSpot) {
      obj.parkingSpot = this.parkingSpot;
    }
    return obj;
  }
}
