import { Component, OnDestroy, OnInit } from '@angular/core';
import { buildExternalLinkUrl, ExternalLink } from '@api/model/external-link';
import { EcondosQuery } from '@api/model/query';
import { SessionService } from '@api/service/session.service';
import { ExternalLinksService } from '@api/serviceV3/external-links.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { lastValueFrom, Subject, takeUntil, timeout } from 'rxjs';
import { ModalCreateExternalLinkComponent } from '../modal-create-external-link/modal-create-external-link.component';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { Status } from '@api/model/status';

type ExternalLinkWithCompleteUrl = ExternalLink & { completeUrl: string };

const defaultErrorMessage = 'Ocorreu um erro ao tentar buscar os links externos. Tente novamente, por favor.';

@Component({
  selector: 'app-condo-config-external-links',
  templateUrl: 'condo-config-external-links.component.html',
  styleUrls: ['./condo-config-external-links.component.scss']
})
export class CondoConfigExternalLinksComponent implements OnInit, OnDestroy {
  private condo = this.sessionService.condoValue;
  private user = this.sessionService.userValue;

  protected externalLinks: ExternalLinkWithCompleteUrl[] = [];
  protected totalExternalLinksCount = 0;

  protected status = new Status();

  private query: EcondosQuery = {
    $select: 'title icon picture url queryParams includeUserId preBuiltUrl',
    $populate: [{ path: 'picture', select: 'url thumbnail type format name' }],
    $limit: 500
  };

  protected errorMessage = defaultErrorMessage;

  private unsubscribe$ = new Subject();
  protected PERMISSIONS = PERMISSIONS;

  constructor(
    private sessionService: SessionService,
    private externalLinksService: ExternalLinksService,
    private modalService: BsModalService,
    private toastrService: ToastrService
  ) {}

  public ngOnInit(): void {
    this.getData();
  }

  public ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.next(null);
      this.unsubscribe$.complete();
    }
  }

  protected getData() {
    this.status.setAsDownloading();

    this.externalLinksService
      .getExternalLinks(this.condo._id, this.query)
      .pipe(timeout(10_000), takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ externalLinks, count }) => {
          this.externalLinks = externalLinks.map(externalLink => ({
            ...externalLink,
            completeUrl: buildExternalLinkUrl(externalLink, { user: this.user })
          }));

          this.totalExternalLinksCount = count;
          this.status.setAsSuccess();
        },
        error: err => {
          this.errorMessage = err?.originalError?.message || defaultErrorMessage;
          this.status.setAsError();
        }
      });
  }

  protected handleCreateExternalLink() {
    const initialState: Partial<ModalCreateExternalLinkComponent> = {
      condo: this.condo,
      onSaveExternalLink: () => {
        this.getData();
      }
    };

    this.modalService.show(ModalCreateExternalLinkComponent, { initialState, ignoreBackdropClick: true });
  }

  protected handleRefreshData() {
    this.getData();
  }

  protected handleEditExternalLink(externalLink: ExternalLink) {
    const initialState: Partial<ModalCreateExternalLinkComponent> = {
      condo: this.condo,
      externalLink,
      onSaveExternalLink: () => {
        this.getData();
      }
    };

    this.modalService.show(ModalCreateExternalLinkComponent, { initialState, ignoreBackdropClick: true });
  }

  protected handleDeleteExternalLink(externalLinkToDelete: ExternalLink) {
    swal({
      type: 'question',
      text: `Deseja excluir o link externo "${externalLinkToDelete.title}"?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          return await lastValueFrom(
            this.externalLinksService.deleteExternalLink(this.condo._id, externalLinkToDelete._id).pipe(timeout(10000))
          );
        } catch (err) {
          const errorMessage = err?.originalError?.message || 'Não foi possível excluir o link externo. tente novamente, por favor.';
          this.toastrService.success(errorMessage);
          return await Promise.reject(errorMessage);
        }
      }
    })
      .then(() => {
        this.toastrService.success('Link externo excluído com sucesso.');
        this.externalLinks = this.externalLinks.filter(externalLink => externalLink._id !== externalLinkToDelete._id);
      })
      .catch(swal.noop);
  }
}
