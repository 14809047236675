export const CAMERA_USES = {
  WATCH: 'WATCH',
  FACE: 'FACE',
  DOCUMENT: 'DOCUMENT',
  OTHER: 'OTHER'
};

export const CAMERA_USES_LABEL = {
  DOCUMENT: 'Documento',
  WATCH: 'Monitoramento',
  FACE: 'Rosto',
  OTHER: 'Outro'
};

export const CAMERA_PROTOCOLS = {
  HTTP: 'HTTP',
  RTSP: 'RTSP',
  DAHUA_WEBSOCKET: 'DAHUA_WEBSOCKET'
};

export const CAMERA_PROTOCOLS_LABELS = {
  HTTP: 'HTTP',
  RTSP: 'RTSP',
  DAHUA_WEBSOCKET: 'Websocket Intelbras'
};

export interface Camera {
  _id?: string;
  name: string;
  host: string;
  port: number;
  protocol: string;
  rtspPort: number;
  manufacturer: string;
  user: string;
  password: string;
  channel: string;
  subtype: string;
  use: string;
  directUrl?: string;
  snapshotUrl?: string;
  rtspUrl?: string;
  public?: boolean;
  resolution?: string;
  quality?: string;
}
