import { ResidenceService } from '@api/service/residence.service';

import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { map } from 'rxjs/operators';
import { ResidenceBuilder } from '@api/model/residence/residence.builder';

@Injectable({ providedIn: 'root' })
export class ResidenceServiceV3 extends ResidenceService {
  constructor(protected http: HttpService, protected constantService: ConstantService) {
    super(http, constantService);
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }
}
