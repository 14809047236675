import { timeout } from 'rxjs/operators';
import { ModalCreateConstructionComponent } from './../modal-create-construction/modal-create-construction.component';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ResidenceService } from '@api/service/residence.service';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { Construction, CONSTRUCTION_STATUS_LABEL } from '@api/model/construction';
import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import swal from 'sweetalert2';

interface ConstructionCardData extends Construction {
  expand: boolean;
}

@Component({
  selector: 'app-residence-constructions-list',
  templateUrl: './residence-constructions-list.component.html',
  styleUrls: ['./residence-constructions-list.component.scss']
})
export class ResidenceConstructionsListComponent implements OnChanges {
  @Input()
  residence: Residence;
  @Input()
  constructions: Construction[] = [];

  CONSTRUCTION_STATUS_LABEL = CONSTRUCTION_STATUS_LABEL;

  @Input()
  condo: Condo;
  @Input()
  user: User;

  @Output()
  created: EventEmitter<Construction> = new EventEmitter();

  @Output()
  updated: EventEmitter<Construction> = new EventEmitter();

  @Output()
  deleted: EventEmitter<Construction> = new EventEmitter();

  canCreate = false;
  isAdmin = false;

  callback;

  constructor(private residenceService: ResidenceService, private toastr: ToastrService, private modalService: BsModalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo && changes.residence && changes.user) {
      const user: User = changes.user.currentValue;
      const condo: Condo = changes.condo.currentValue;
      const residence: Residence = changes.residence.currentValue;
      this.canCreate =
        user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id) || residence.isOwner(user._id) || residence.isUser(user._id);
      this.isAdmin = user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id);
    }
  }

  create() {
    const initialState = {
      user: this.user,
      condo: this.condo,
      residence: this.residence,
      onCreate: (construction: Construction) => {
        this.created.emit(construction);
      }
    };
    this.modalService.show(ModalCreateConstructionComponent, { initialState, class: 'modal-lg' });
  }

  edit(construction: Construction) {
    const initialState = {
      condo: this.condo,
      construction,
      user: this.user,
      residence: this.residence,
      onUpdate: (c: Construction) => {
        this.updated.emit(c);
      }
    };
    this.modalService.show(ModalCreateConstructionComponent, { initialState, class: 'modal-lg' });
  }

  delete(construction: Construction) {
    swal({
      type: 'question',
      text: 'Deseja remover esta construção?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonText: 'Não',
      cancelButtonColor: '#F53D3D',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.residenceService
          .deleteConstruction(this.condo._id, this.residence._id, construction._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject(err?.originalError?.message || 'Não foi possível excluir a construção, tente novamente...');
          });
      }
    }).then(
      () => {
        this.toastr.success('Construção removida com sucesso');
        this.deleted.emit(construction);
      },
      error => {
        console.log(error);
      }
    );
  }
}
