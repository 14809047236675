import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TableColumnDefinition, TableComponent, TablePageChangeEventData, TableStatus } from '../../../../components/table/table.component';
import { Reservation } from '@api/model/reservation';
import { timeout } from 'rxjs/operators';
import { ReservationService } from '@api/service/reservation.service';
import { SessionService } from '@api/service/session.service';
import { EcondosQuery } from '@api/model/query';
import { capitalize } from '@api/util/util';
import { BsModalRef } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-in-queue-reservations-list-modal',
  templateUrl: 'in-queue-reservations-list-modal.component.html'
})
export class InQueueReservationsListModalComponent implements OnInit {
  @ViewChild('reservationTable', { static: true }) reservationTable: TableComponent;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<Reservation>[] = [];
  currentPage = 1;
  pageSize = 15;
  totalItemsCount = 0;

  reservations: Reservation[] = [];

  user = this.sessionService.userValue;
  condo = this.sessionService.condoValue;

  initialQuery: EcondosQuery = {
    status: Reservation.STATUS.IN_QUEUE,
    $populate: [
      { path: 'reservationLocal', select: 'name checklist penaltyWarningText' },
      { path: 'residence', select: 'identification' },
      { path: 'createdBy', select: 'firstName lastName', populate: [{ path: 'picture', select: 'url thumbnail' }] }
    ],
    $or: [],
    $sort: 'endDate'
  };
  customQuery: EcondosQuery = { ...this.initialQuery };

  isGatekeeperOrAdmin =
    this.user?.isGatekeeperOnCondo(this.condo._id) ||
    this.user?.isOwnerOnCondo(this.condo._id) ||
    this.user?.isAdminOnCondo(this.condo._id);

  constructor(
    private reservationService: ReservationService,
    private sessionService: SessionService,
    private bsModalRef: BsModalRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        headerLabel: '#',
        type: 'index'
      },
      {
        headerLabel: 'Local',
        columnId: 'reservationLocal',
        valueFn: reservation => reservation.reservationLocal.name,
        sortable: true
      },
      {
        type: 'avatar',
        headerLabel: 'Criada por',
        columnId: 'createdBy',
        avatar: reservation => ({
          name: reservation.createdBy?.firstName + ' ' + reservation.createdBy?.lastName,
          picture: reservation.createdBy?.picture
        }),
        sortable: true
      },
      {
        headerLabel: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        type: 'residence',
        columnId: 'residence',
        residence: reservation => reservation.residence,
        sortable: true
      },
      {
        headerLabel: 'Data',
        columnId: 'startDate',
        valueKey: 'reservationDate',
        sortable: true
      },
      {
        headerLabel: 'Posição',
        valueKey: 'positionInQueue'
      }
    ];

    const canCancel = this.user.isAdmin() || this.user.isOwner() || this.user.isSystemAdmin;
    if (canCancel) {
      this.tableColumns.push({
        type: 'actions',
        headerLabel: 'Ações',
        actionsButtons: [
          {
            buttonClass: 'btn-ghost-danger',
            text: 'Cancelar',
            title: 'Cancelar reserva',
            handler: (reservation: Reservation) => this.handleCancelReservation(reservation)
          }
        ]
      });
    }

    this.getData();
  }

  getData({ page = 0 } = {}) {
    this.status = 'LOADING';
    const query = this.customQuery;
    query.$page = page;
    const { sortedColumn, sortOrder } = this.reservationTable.getCurrentState();

    if (sortedColumn) {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    if (!this.isGatekeeperOrAdmin) {
      const residences = this.user.getResidences();
      query.residence = {
        $in: residences.map(residence => residence._id)
      };
    }

    this.reservationService
      .getReservations(this.condo._id, query)
      .pipe(timeout(10_000))
      .subscribe({
        next: ({ count, reservations }) => {
          this.totalItemsCount = count;
          this.reservations = reservations;
          this.status = 'SUCCESS';
        },
        error: err => {
          console.log(err);
          this.reservations = [];
          this.status = 'ERROR';
        }
      });
  }

  handleTablePageChange({ queryPageNumber, tablePageNumber, pageSize }: TablePageChangeEventData) {
    this.currentPage = tablePageNumber;
    this.pageSize = pageSize;

    this.getData({ page: queryPageNumber });
  }

  private async handleCancelReservation(reservation: Reservation) {
    swal({
      type: 'question',
      text: 'Tem certeza que deseja cancelar esta reserva na fila de espera?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () =>
        lastValueFrom(this.reservationService.cancelReservation(this.condo._id, reservation.reservationLocal._id, reservation._id))
    }).then(() => {
      swal({
        type: 'success',
        title: 'Reserva cancelada com sucesso'
      });

      this.reservations = this.reservations.filter(r => r._id !== reservation._id);
      this.totalItemsCount = this.totalItemsCount - 1;
      this.cdr.detectChanges();
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
