import { Residence } from './interface/residence';
import { ResidenceBuilder } from './residence/residence.builder';
import { File } from './file';
import { User } from './user';

export enum PET_SPECIES {
  BIRD = 'BIRD',
  DOG = 'DOG',
  CAT = 'CAT',
  FISH = 'FISH',
  REPTILE = 'REPTILE',
  RODENT = 'RODENT',
  RABBIT = 'RABBIT'
}

export enum PET_SPECIES_LABELS {
  DOG = 'Cão',
  RABBIT = 'Coelho',
  CAT = 'Gato',
  BIRD = 'Pássaro',
  FISH = 'Peixe',
  REPTILE = 'Réptil',
  RODENT = 'Roedor'
}

export enum PET_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG'
}

export enum PET_SIZES_LABELS {
  SMALL = 'Pequeno',
  MEDIUM = 'Médio',
  BIG = 'Grande'
}

export enum PET_SEX {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum PET_SEX_LABELS {
  MALE = 'Macho',
  FEMALE = 'Fêmea'
}

export class Pet {
  _id?: string;
  name?: string;
  birthDate?: string | Date;
  specie?: PET_SPECIES;
  breed?: string;
  sex?: PET_SEX;
  size?: PET_SIZES;
  picture?: File;
  residence: Residence;
  createdBy: User;
  createdAt: string;

  constructor(pet) {
    if (typeof pet == 'string') {
      this._id = pet;
    } else if (pet) {
      this._id = pet._id || '';
      this.name = pet.name || '';
      this.birthDate = pet.birthDate || '';
      this.specie = pet.specie || '';
      this.breed = pet.breed || '';
      this.sex = pet.sex || '';
      this.size = pet.size || '';
      this.picture = pet.picture ? new File(pet.picture) : null;
      this.residence = pet.residence ? ResidenceBuilder.build(pet.residence) : null;
      this.createdBy = pet.createdBy ? new User(pet.createdBy) : this.createdBy;
      this.createdAt = pet.createdAt || this.createdAt;
    }
  }
}
