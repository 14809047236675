import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { rotateImageBase64 } from '@api/util/util';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-modal-image-cropper',
  templateUrl: './modal-image-cropper.component.html'
})
export class ModalImageCropperComponent implements OnInit {
  @ViewChild('cropperContainer') cropperContainer: ElementRef;

  public base64ImageToCrop = '';

  public cropperStyle = { 'max-width': '100%' };

  public onCropImage: (base64Image: string) => void;

  private croppedBase64Image = '';

  constructor(public bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    if (this.base64ImageToCrop) {
      const image = new Image();
      image.crossOrigin = 'Anonymous';

      image.onload = () => {
        const currentCropperWidth = this.cropperContainer.nativeElement.offsetWidth;

        const cropperHeight = (image.height * currentCropperWidth) / image.width;

        this.cropperStyle['height'] = `${cropperHeight > 500 ? 500 : cropperHeight}px`;
      };

      this.getSafeBase64Image(this.base64ImageToCrop).then(() => {
        image.src = this.base64ImageToCrop;
      });
    }
  }

  private async getSafeBase64Image(base64Image: string) {
    let newBase64Image = base64Image;

    newBase64Image = await rotateImageBase64(newBase64Image, 180);
    newBase64Image = await rotateImageBase64(newBase64Image, 180);

    this.base64ImageToCrop = newBase64Image;
  }

  public handleImageCropped(event: ImageCroppedEvent) {
    this.croppedBase64Image = event.base64;
  }

  public handleCropImageClick(): void {
    if (this.onCropImage) {
      this.onCropImage(this.croppedBase64Image);
      this.bsModalRef.hide();
    }
  }
}
