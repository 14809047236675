/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { UtilService } from '../../../services/util.service';
import { UserService } from '../../../api/service/user.service';
import swal from 'sweetalert2';
import { Error } from '../../../api/model/error/error';
import { timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '../../../api/model/error/error.builder';

@Component({
  selector: 'modal-change-password',
  templateUrl: 'change.password.modal.html',
  styleUrls: ['change.password.modal.scss']
})
export class ModalChangePasswordComponent {
  @ViewChild('changePasswordModal', { static: true }) private changePasswordModal: ModalDirective;

  public form: UntypedFormGroup;
  private authorizationHeader: String;
  private userEmail: string;

  public isSubmiting: boolean;

  // Form
  private currentPassword: AbstractControl;
  private newPassword: AbstractControl;
  private newPasswordConfirmation: AbstractControl;
  private newPasswordValidator: ValidatorFn;

  showCurrentPassword = false;
  showPassword = false;
  showPasswordConfirm = false;

  constructor(private utilService: UtilService, private formBuilder: UntypedFormBuilder, private userService: UserService) {
    // Função para validação da igualdade das novas senhas
    this.newPasswordValidator = (c: UntypedFormControl) => {
      if (c.value == this.newPassword.value) {
        return null;
      }
      return { newPasswordValidator: { valid: false } };
    };
  }

  initializeForm() {
    this.userEmail = atob(this.utilService.getLocalUsername().toString());
    this.authorizationHeader = this.utilService.getLocalAuthorizationHeader().toString();

    // Cria formulário com seus validadores
    this.form = this.formBuilder.group({
      currentPassword: ['', Validators.compose([Validators.required])],
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      newPasswordConfirmation: ['']
    });

    // Atribui campos do form a variáveis locais
    this.currentPassword = this.form.controls['currentPassword'];
    this.newPassword = this.form.controls['newPassword'];
    this.newPasswordConfirmation = this.form.controls['newPasswordConfirmation'];

    // Atribuindo função da validação
    this.currentPassword.setValidators(Validators.compose([Validators.required]));
    this.newPasswordConfirmation.setValidators(Validators.compose([Validators.required, this.newPasswordValidator]));

    this.showCurrentPassword = false;
    this.showPassword = false;
    this.showPasswordConfirm = false;
  }

  onSubmit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).markAsTouched());
      return;
    }

    this.isSubmiting = true;

    this.userService
      .changePassword({
        newPassword: this.newPassword.value,
        oldPassword: this.currentPassword.value,
        confirmPassword: this.newPasswordConfirmation.value
      })
      .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        () => {
          this.utilService.saveLocalAuthorizationHeader(this.userEmail, this.newPassword.value);
          this.isSubmiting = false;
          this.closeModal();
          swal({
            type: 'success',
            text: 'Senha alterada com sucesso!'
          });
        },
        (err: Error) => {
          this.isSubmiting = false;
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: err.message || 'Não foi possível aterar sua senha, tente novamente!'
          });
        }
      );
  }

  closeModal(): void {
    this.changePasswordModal.hide();
  }

  showModal() {
    this.initializeForm();
    this.changePasswordModal.show();
  }
}
