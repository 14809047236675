import { Injectable } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import { AlphaDigiService } from '@api/service/hardware/alphadigi.service';
import { defaultIfEmpty, defer, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { Garen } from '@econdos/econdos-garen';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import { Timezone } from '@api/model/timezone';
import { EcondosQuery } from '@api/model/query';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { HttpService } from '../../../services/http.service';
import { ConstantService } from '../../../services/constant.service';

interface GarenActuator {
  [key: string]: Garen;
}

export interface GarenDeviceResult {
  actuator: Actuator;
  ok: boolean;
  error?: string;
}

export const garenActuatorTypes = ['AUTG07'];
export const garenDeviceTypes = ['CARD', 'SN', 'QR'];

@Injectable({ providedIn: 'root' })
export class GarenService extends AlphaDigiService {
  protected endPoint;

  // Variável utilizada como Singleton para instâncias da classe Garen
  private garenActuators: GarenActuator = {};

  constructor(public httpService: HttpService, public readonly constantService: ConstantService) {
    super();
    this.endPoint = `${this.constantService.getV2Endpoint()}condos`;
  }

  private getGaren(actuator: Actuator) {
    if (!this.garenActuators[actuator._id]) {
      this.garenActuators[actuator._id] = new Garen(actuator);
    }
    return this.garenActuators[actuator._id];
  }

  private getDevice(condoId: string, deviceId: string) {
    const query: EcondosQuery = {
      $select: 'serial type owner.userName sequenceId condo validFrom validUntil accessGroups',
      $populate: [
        { path: 'owner.user', select: 'firstName lastName' },
        { path: 'owner.dependent', select: 'name firstName lastName' },
        { path: 'owner.condoContact', select: 'firstName lastName' },
        {
          path: 'accessGroups',
          populate: [
            { path: 'timezone', select: 'sequenceId' },
            { path: 'actuators', select: 'name type accessType host port password hardware user serial' }
          ]
        }
      ]
    };

    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.httpService.get(`${this.endPoint}/${condoId}/devices/${deviceId}`, { params }).pipe(map(dev => new Device(dev)));
  }

  public clearDeviceCache(actuator: Actuator) {
    if (!garenActuatorTypes.includes(actuator.type)) {
      return super.clearDeviceCache(actuator);
    }
    if (actuator._id) {
      delete this.garenActuators[actuator._id];
    } else {
      this.garenActuators = {};
    }
  }

  public trigger(actuator: Actuator): Observable<boolean> {
    if (!garenActuatorTypes.includes(actuator.type)) {
      return super.trigger(actuator);
    }

    const data = { door: actuator.output, time: actuator.time };

    const garen = this.getGaren(actuator);

    return from(garen.openDoor(data)).pipe(
      map((res: any) => res.success),
      catchError(() => of(false))
    );
  }

  public addTimezone(actuator: Actuator, timezone: Timezone): Observable<{ success: boolean }> {
    const { sequenceId: econdosTimezoneId, daysAllowed } = timezone;
    const data = { econdosTimezoneId, daysAllowed };
    return defer(() => from<Observable<{ success: boolean }>>(this.getGaren(actuator).addTimezone(data))).pipe(
      catchError(() => of({ success: false }))
    );
  }

  private getDateTime(actuator: Actuator) {
    if (!garenActuatorTypes.includes(actuator.type)) {
      return super.readDateTime(actuator).pipe(
        map(({ status = false }) => ({ success: status })),
        catchError(err => of({ success: false }))
      );
    }

    const garen = this.getGaren(actuator);
    return from(garen.getDateTime());
  }

  public checkConnection(actuator: Actuator) {
    return from(this.getDateTime(actuator)).pipe(
      timeout(10000),
      map(({ success = false }) => ({ _id: actuator._id, status: success })),
      catchError(err => of({ _id: actuator._id, status: false }))
    );
  }

  public activateMonitor(actuator: Actuator) {
    if (!garenActuatorTypes.includes(actuator.type)) {
      return super.activateMonitor(actuator);
    }

    const garen = this.getGaren(actuator);
    return from(garen.configHttpEventServer()).pipe(map((res: any) => res.success));
  }

  public createUser(
    device: Device,
    actuators?: Actuator[]
  ): Observable<{
    device: Device;
    results: GarenDeviceResult[];
  }> {
    const condoId: any = device.condo?._id || device.condo;
    return this.getDevice(condoId, device._id).pipe(
      switchMap(d => {
        // Quando faz o get device não traz o template, mas na tela de adição/edição o template é sempre populado,
        // então pegamos o template que já foi enviado
        if (device.type === DEVICE_TYPES.BM && !d.template) {
          d.template = device.template;
        }

        device = { ...device, ...d };

        actuators = actuators ?? (device.accessGroups.map(group => group.actuators) || []).reduce((acc, cur) => acc.concat(cur), []);
        if (device.type === DEVICE_TYPES.QR) {
          actuators = actuators.filter(actuator => actuator.type === 'AUTG07');
        }
        const timezones = device.accessGroups.map(group => group.timezone) || [];

        let deviceUser: any = device.owner.user || device.owner.condoContact;

        if (!deviceUser) {
          if (device.owner && device.owner.userName) {
            deviceUser = { name: device.owner.userName };
          }
        }

        let firstName = deviceUser.firstName;
        let lastName = deviceUser.lastName;

        if (deviceUser.name) {
          const names = deviceUser.name.split(' ');
          firstName = names.shift();
          lastName = names.join(' ');
        }

        const data: any = {
          econdosDeviceId: device.sequenceId,
          firstName,
          lastName,
          startDate: device.validFrom,
          endDate: device.validUntil,
          timezones
        };

        if (device.type === DEVICE_TYPES.SN) {
          data.password = device.serial;
        } else if (device.type === DEVICE_TYPES.QR) {
          data.qrcode = device.serial;
        } else {
          data.serial = device.serial;
        }

        const requests = actuators.map(actuator =>
          defer(() => from(this.getGaren(actuator).addUser(data))).pipe(
            map((res: any) => ({ actuator, ok: res.success, error: res.message })),
            catchError(err => of({ actuator, ok: false, error: err.message }))
          )
        );
        return forkJoin(requests).pipe(
          map((results: any) => ({ device, results })),
          defaultIfEmpty({ device, results: [] })
        );
      })
    ) as Observable<{ device: Device; results: GarenDeviceResult[] }>;
  }

  private updateUser(device: Device, actuatorsToUpdate: Actuator[]) {
    return this.createUser(device, actuatorsToUpdate);
  }

  public createDevice(device: Device): Observable<{ device: Device; results: GarenDeviceResult[] }> {
    if (!garenDeviceTypes.includes(device.type)) {
      const accessGroups = device.accessGroups
        ?.map(ag => ag.actuators)
        .reduce((acc, curr) => {
          acc.push(...curr);
          return acc;
        }, []);
      device.actuators = accessGroups?.length ? accessGroups : device.actuators;
      return super.createDevice(device);
    } else {
      return this.createUser(device);
    }
  }

  public deleteDevice(actuator: Actuator, device: Device): Observable<GarenDeviceResult> {
    // Como a Garen usa whitelabel da alphadigi e possui equipamentos realmente deles nós temos que verificar se o equipamento é da Garen
    const isGarenActuator = garenActuatorTypes.includes(actuator.type);
    if (isGarenActuator) {
      const econdosDeviceId = device.sequenceId;
      const garen = this.getGaren(actuator);
      return from(garen.deleteUser({ econdosDeviceId })).pipe(
        map((res: any) => ({
          actuator,
          ok: res.success,
          error: res.message
        }))
      );
    } else {
      const isQRCode = device.type === DEVICE_TYPES.QR;
      // QR Code na facial da Garen (que é whitelabel da alphadigi) não tem cadastro direto no equipamento, portanto não é necessário deletar
      if (isQRCode) {
        return of({ actuator, ok: true, error: '' });
      } else {
        return this.deleteFacial(actuator, device).pipe(
          map(res => ({ actuator, ok: res.success, error: res.reason })),
          catchError(err => of({ actuator, ok: false, error: '' }))
        );
      }
    }
  }

  public syncDevice(condoActuators: Actuator[], device: Device): Observable<Required<GarenDeviceResult>>[] {
    if (!garenDeviceTypes.includes(device.type)) {
      condoActuators = condoActuators.filter(ac => !garenActuatorTypes.includes(ac.type));
      const accessGroups = device.accessGroups
        ?.map(ag => ag.actuators)
        .reduce((acc, curr) => {
          acc.push(...curr);
          return acc;
        }, []);
      device.actuators = accessGroups?.length ? accessGroups : device.actuators;
      // @ts-ignore
      return super.syncDevice(condoActuators, device);
    }

    let actuators;

    condoActuators = condoActuators.filter(ac => garenActuatorTypes.includes(ac.type));
    if (device.type === DEVICE_TYPES.QR) {
      condoActuators = condoActuators.filter(actuator => actuator.type === 'AUTG07');
    }
    if (device.accessGroups && device.accessGroups.length) {
      actuators = (device.accessGroups.map(ag => ag.actuators) || [])
        .reduce((acc, curr) => acc.concat(curr), [])
        .filter(ac => garenActuatorTypes.includes(ac?.type));
      if (device.type === DEVICE_TYPES.QR) {
        actuators = actuators.filter(actuator => actuator.type === 'AUTG07');
      }
    }

    const deviceActuatorKeys = actuators.reduce((accumulator, currentActuator) => {
      accumulator[currentActuator._id] = currentActuator;
      return accumulator;
    }, {});

    let actuatorsToUpdate, actuatorsToRemove;

    const observables = [];
    actuatorsToUpdate = condoActuators.filter(condoAc => deviceActuatorKeys[condoAc._id]);
    observables.push(this.updateUser(device, actuatorsToUpdate).pipe(map(({ results }) => results)));

    actuatorsToRemove = condoActuators
      .filter(condoAc => !deviceActuatorKeys[condoAc._id])
      .map(actuator => this.deleteDevice(actuator, device));
    observables.push(...actuatorsToRemove);

    return observables;
  }

  public updateDevice(
    condoActuators: Actuator[],
    device: Device
  ): Observable<{
    device: Device;
    results: GarenDeviceResult[];
  }> {
    if (!garenDeviceTypes.includes(device.type)) {
      condoActuators = condoActuators.filter(ac => !garenActuatorTypes.includes(ac.type));
      const accessGroups = device.accessGroups
        ?.map(ag => ag.actuators)
        .reduce((acc, curr) => {
          acc.push(...curr);
          return acc;
        }, []);
      device.actuators = accessGroups?.length ? accessGroups : device.actuators;
      return super.updateDevice(condoActuators, device);
    }

    const requests = this.syncDevice(condoActuators, device);

    // @ts-ignore
    return forkJoin(...requests).pipe(
      map(([results]) => ({ device, results })),
      defaultIfEmpty({ device, results: [] })
    );
  }
}
