import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { Condo } from '@api/model/condo';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../components/table/table.component';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { EcondosQuery } from '@api/model/query';
import { CachedRequestsService } from '../cached-requests.service';

@Component({
  selector: 'app-gate-residence-details-vehicles',
  templateUrl: './gate-residence-details-vehicles.component.html',
  styleUrls: ['./gate-residence-details-vehicles.component.scss']
})
export class GateResidenceDetailsVehiclesComponent implements OnInit {
  @Input() residence: Residence;
  @Input() condo: Condo;

  vehicles: CondoVehicle[] = [];
  count: number = 0;

  tableColumns: TableColumnDefinition<CondoVehicle>[] = [];
  status: TableStatus = 'LOADING';

  @ViewChild('vehiclesTable', { static: true }) vehiclesTable: TableComponent;
  @ViewChild('vehicleTypeCellTemplate', { static: true }) vehicleTypeCellTemplate: TemplateRef<any>;

  VEHICLE_TYPES = CondoVehicle.TYPES;
  paginate = false;

  constructor(private cachedRequestsService: CachedRequestsService) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        headerLabel: 'Tipo',
        valueTemplate: this.vehicleTypeCellTemplate
      },
      {
        headerLabel: 'Marca',
        valueFn: rowData => rowData.brand || '-'
      },
      {
        headerLabel: 'Modelo',
        valueFn: rowData => rowData.model || '-'
      },
      {
        headerLabel: 'Cor',
        valueFn: rowData => rowData.color || '-'
      },
      { headerLabel: 'Placa', valueFn: rowData => rowData.plate || '-' }
    ];

    this.getData({ page: 0 });
  }

  getData({ page = 0 }) {
    const query: EcondosQuery = {
      $page: page,
      $limit: this.vehiclesTable.getCurrentState().pageSize || 15,
      $select: 'plate model type brand color picture',
      $populate: [{ path: 'picture', select: 'url thumbnail type name format' }],
      $count: true
    };

    this.cachedRequestsService.getVehicles(this.condo._id, this.residence._id, query).subscribe({
      next: ({ vehicles, count }) => {
        this.paginate = count >= this.vehiclesTable?.getCurrentState().pageSize;
        this.vehicles = vehicles;
        this.count = count;
        this.status = 'SUCCESS';
      },
      error: () => {
        this.status = 'ERROR';
      }
    });
  }
}
