import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { User } from '@api/model/user';
import { ResidenceService } from '@api/service/residence.service';
import swal from 'sweetalert2';
import { forkJoin, noop } from 'rxjs';
import { EcondosQuery } from '@api/model/query';
import { ModalCreateResidenceComponent } from '../../../pages/modal/create-residence.modal/create-residence-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CondoService } from '@api/service/condo.service';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap, tap } from 'rxjs/operators';
import { capitalize, defaultSortFunction } from '@api/util/util';

@Component({
  selector: 'step-residence',
  templateUrl: 'step-residence.html',
  styleUrls: ['step-residence.scss']
})
export class StepResidenceComponent implements OnChanges {
  @Input()
  globals: any;

  @Input()
  index: number;

  @Output() changeResidences = new EventEmitter<Residence[]>();

  user: User;
  condo: Condo;
  isInResidence = false;

  residenceSelected: UntypedFormControl = new UntypedFormControl(null);
  residenceRole: UntypedFormControl = new UntypedFormControl('');

  residences: Residence[];
  residenceUserLabel = {};

  RESIDENCE_ROLES = {
    OWNER_AND_RESIDENT: 'OWNER_AND_RESIDENT',
    OWNER_ONLY: 'OWNER_ONLY',
    RESIDENT_ONLY: 'RESIDENT_ONLY',
    EMPLOYEE: 'EMPLOYEE',
    TENANT: 'TENANT',
    GUEST: 'GUEST',
    RESPONSIBLE_TENANT: 'RESPONSIBLE_TENANT'
  };

  RESIDENCE_ROLES_LABEL = {
    // Campos em branco são atualizados no ngOnChanges de acordo com rótulos customizados definidos
    OWNER_AND_RESIDENT: '',
    OWNER_ONLY: '',
    RESIDENT_ONLY: '',
    EMPLOYEE: 'Funcionário',
    GUEST: 'Hóspede',
    TENANT: 'Inquilino',
    RESPONSIBLE_TENANT: 'Inquilino responsável'
  };

  residenceQs: EcondosQuery = {};

  defaultSortFunction = defaultSortFunction;

  constructor(
    private residenceService: ResidenceService,
    private modalService: BsModalService,
    private condoService: CondoService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.index?.currentValue === this.globals.pages?.RESIDENCE) {
      this.residences = this.globals.residences || [];
      this.user = this.globals.user;
      this.fillResidenceUserLabels(this.residences);
      this.condo = this.globals.condo;
      this.addResidencesToQueryString();
      if (this.globals.residence) {
        this.residenceSelected.setValue(this.globals.residence);
      }

      this.RESIDENCE_ROLES_LABEL.OWNER_AND_RESIDENT =
        (capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário') +
        ' e ' +
        (this.condo?.customLabels?.resident?.singular || 'morador');
      this.RESIDENCE_ROLES_LABEL.OWNER_ONLY = capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário';
      this.RESIDENCE_ROLES_LABEL.RESIDENT_ONLY = capitalize(this.condo?.customLabels?.resident?.singular) || 'Morador';
    }
  }

  fillResidenceUserLabels(residences: Residence[], onlyLastResidence?: boolean) {
    if (onlyLastResidence) {
      const lastResidence = residences[residences.length - 1];
      this.residenceUserLabel[lastResidence.id] = this.user.condoResidenceAttributes.find(
        attr => attr.residence === lastResidence.id
      )?.userLabel;
    } else {
      this.residenceUserLabel = residences.reduce((accumulator, current) => {
        return {
          ...accumulator,
          [current.id]: this.user.condoResidenceAttributes.find(attr => attr.residence === current.id)?.userLabel
        };
      }, {});
    }
  }

  isResidenceOwner(residence: Residence, user: User) {
    return user.residencesVoter.findIndex(res => res._id === residence._id) > -1;
  }

  isResidenceUser(residence: Residence, user: User) {
    return user.residencesUser.findIndex(res => res._id === residence._id) > -1;
  }

  addResidence() {
    if (this.residenceSelected.value) {
      switch (this.residenceRole.value) {
        case this.RESIDENCE_ROLES.OWNER_AND_RESIDENT: {
          forkJoin([
            this.residenceService.changeResidenceVoter(this.condo._id, this.residenceSelected.value?._id, this.user._id),
            this.residenceService.addUserToResidence(this.condo._id, this.residenceSelected.value?._id, this.user._id)
          ]).subscribe(
            res => {
              this.user.residencesVoter.push(this.residenceSelected.value);
              this.user.residencesUser.push(this.residenceSelected.value);
              this.residencesChanged(this.residenceSelected.value);
            },
            () => {
              this.showErrorMessage();
            }
          );
          break;
        }
        case this.RESIDENCE_ROLES.RESIDENT_ONLY: {
          this.residenceService.addUserToResidence(this.condo._id, this.residenceSelected.value?._id, this.user._id).subscribe(
            res => {
              this.user.residencesUser.push(this.residenceSelected.value);
              this.residencesChanged(this.residenceSelected.value);
            },
            () => {
              this.showErrorMessage();
            }
          );
          break;
        }
        case this.RESIDENCE_ROLES.OWNER_ONLY: {
          this.residenceService.changeResidenceVoter(this.condo._id, this.residenceSelected.value?._id, this.user._id).subscribe(
            res => {
              this.user.residencesVoter.push(this.residenceSelected.value);
              this.residencesChanged(this.residenceSelected.value);
            },
            () => {
              this.showErrorMessage();
            }
          );
          break;
        }
        case this.RESIDENCE_ROLES.GUEST:
        case this.RESIDENCE_ROLES.RESPONSIBLE_TENANT:
        case this.RESIDENCE_ROLES.TENANT:
        case this.RESIDENCE_ROLES.EMPLOYEE: {
          const residenceId = this.residenceSelected.value._id;
          const userLabel = this.RESIDENCE_ROLES_LABEL[this.residenceRole.value];
          this.residenceService
            .addUserToResidence(this.condo._id, residenceId, this.user._id)
            .pipe(
              tap(() => {
                this.user.residencesUser.push(this.residenceSelected.value);
                this.residencesChanged(this.residenceSelected.value);
              }),
              switchMap(() => {
                const condoResidenceAttributes = {
                  userLabel,
                  condo: this.condo._id,
                  residence: residenceId
                };
                return this.condoService
                  .updateUserCondoResidenceAttributes(this.condo._id, this.user._id, {
                    condoResidenceAttributes
                  })
                  .pipe(tap(v => (this.residenceUserLabel[residenceId] = userLabel)));
              })
            )
            .subscribe(noop, err => {
              console.log(err);
              this.showErrorMessage();
            });
          break;
        }
        default: {
          this.residenceService.addUserToResidence(this.condo._id, this.residenceSelected.value?._id, this.user._id).subscribe(
            res => {
              this.user.residencesUser.push(this.residenceSelected.value);
              this.residencesChanged(this.residenceSelected.value);
            },
            () => {
              this.showErrorMessage();
            }
          );
          break;
        }
      }
    }
  }

  addResidencesToQueryString() {
    const $nin = [];
    this.residences.forEach((res: any) => $nin.push(res._id));
    this.residenceQs._id = { $nin };
  }

  residencesChanged(residence) {
    this.residences.push(residence);
    this.addResidencesToQueryString();
    this.residenceSelected.setValue(null);
    this.residenceRole.setValue('');
    this.changeResidences.emit(this.residences);
    this.fillResidenceUserLabels(this.residences, true);
  }

  createResidence() {
    const initialState = {
      condo: this.condo,
      callback: residence => {
        if (residence) {
          this.onResidenceCreation(residence);
        }
      }
    };
    this.modalService.show(ModalCreateResidenceComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
  }

  onResidenceCreation(residence) {
    this.onResidenceSelected(residence);
  }

  askToDeleteResidence(residence: Residence) {
    swal({
      type: 'question',
      text: `Deseja remover o usuário ${this.user?.fullName} da(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'} ${
        residence.identification
      }?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then(
      () => {
        this.removeFromResidence(residence);
      },
      error => {
        console.log(error);
      }
    );
  }

  removeFromResidence(residence) {
    const requests = [];
    if (this.isResidenceUser(residence, this.user)) {
      requests.push(this.residenceService.removeUserFromResidence(this.condo._id, residence._id, this.user._id));
    }
    if (this.isResidenceOwner(residence, this.user)) {
      requests.push(this.residenceService.removeVoterFromResidence(this.condo._id, residence._id));
    }
    if (requests.length) {
      forkJoin(requests).subscribe(
        () => {
          this.user.removeResidence(residence);
          this.residences = this.user.getResidences();
          this.fillResidenceUserLabels(this.residences);
          this.changeResidences.emit(this.user.getResidences());
        },
        e => {
          this.showErrorFailRemove(e);
        }
      );
    } else {
      this.showErrorFailRemove();
    }
  }

  onResidenceSelected(residence) {
    this.isInResidence = this.residences.some(res => res._id === residence._id);
    this.residenceSelected.setValue(residence);
    if (!this.isInResidence) {
      this.residenceSelected.setValue(residence);
    }
  }

  showErrorFailRemove(error?) {
    const message =
      error?.originalError?.message || 'Falha ao remover usuário da(o) ' + this.condo?.customLabels?.residence?.singular || 'unidade';
    swal({
      type: 'error',
      title: 'Ops...',
      text: message
    });
  }

  showErrorMessage() {
    swal({
      type: 'error',
      title: 'Ops...',
      text: 'Não foi possível realizar a ação, tente novamente'
    });
  }

  editResidenceUserLabel(residenceId) {
    swal({
      title: 'Digite a relação do usuário',
      type: 'info',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      inputValue: this.residenceUserLabel[residenceId] || '',
      inputPlaceholder: 'Ex: Locatário, funcionário, etc...',
      showLoaderOnConfirm: true,
      preConfirm: userLabel => {
        const condoResidenceAttributes = {
          condo: this.condo._id,
          residence: residenceId,
          userLabel: userLabel
        };

        return this.condoService
          .updateUserCondoResidenceAttributes(this.condo._id, this.user._id, { condoResidenceAttributes })
          .pipe(map(value => userLabel))
          .toPromise()
          .catch(error => Promise.reject('Não foi possível atualizar o tipo de usuário. Por favor, verifique sua conexão.'));
      }
    }).then(
      value => {
        this.residenceUserLabel[residenceId] = value;
        this.toastr.success('Tipo de usuário atualizado com sucesso.');
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }
}
