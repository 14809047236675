import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, mergeAll, takeUntil, tap, timeout } from 'rxjs/operators';
import { ModalCreateVehicleComponent } from '../../../components/modal-create-vehicle/modal-create-vehicle.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import { EcondosFilter } from '@api/model/filter';
import { EcondosQuery } from '@api/model/query';
import { VehicleServiceV2 } from '@api/serviceV2/vehicle.service';
import { capitalize } from '@api/util/util';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../components/table/table.component';
import { from, of, Subject } from 'rxjs';

@Component({
  selector: 'app-condo-vehicles',
  templateUrl: 'condo.vehicles.html',
  styleUrls: ['condo.vehicles.scss']
})
export class CondoVehiclesComponent implements OnInit {
  user: User;
  condo: Condo;

  @ViewChild('vehiclesTable', { static: true }) vehiclesTable: TableComponent;
  @ViewChild('vehicleTypeCellTemplate', { static: true }) vehicleTypeCellTemplate: TemplateRef<any>;

  vehicles: CondoVehicle[] = [];
  totalVehiclesCount = 0;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<CondoVehicle>[] = [];

  initialQuery: EcondosQuery = {
    $select: 'type plate model chassis brand color residence parkingSpot user pictures',
    $populate: [
      { path: 'pictures', select: 'url thumbnail type' },
      { path: 'residence', select: 'identification type' },
      { path: 'user', select: 'firstName lastName' }
    ],
    residence: { $gt: '000000000000000000000000' },
    $and: [],
    $or: []
  };

  customQuery = { ...this.initialQuery };

  numberOfActiveFilters = 0;
  filters: EcondosFilter[] = [];

  BRANDS = {
    ...CondoVehicle.VEHICLE_BRANDS,
    ...CondoVehicle.MOTORCYCLE_BRANDS,
    ...CondoVehicle.BICYCLE_BRANDS
  };

  canRegisterLprPlates = false;
  constructor(
    private utilService: UtilService,
    private vehicleService: VehicleServiceV2,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();

    const isAdminOrOwner = this.user.isAdminOnCondo(this.condo._id) || this.user.isOwnerOnCondo(this.condo._id);
    const isLprEnabled = this.condo.isLprEnabled() && this.condo.isAlphaDigiEnabled();
    const isHikvisionLprEnabled = this.condo.isLprEnabled() && this.condo.isHikvisionEnabled();
    const isOfflineLprEnabled = this.condo.generalParams?.alprParams?.registerResidentVehicleOffline || false;
    this.canRegisterLprPlates = isAdminOrOwner && ((isLprEnabled && isOfflineLprEnabled) || isHikvisionLprEnabled);

    this.filters = [
      {
        element: 'residence-picker',
        elementSize: 'default',
        label: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        name: 'residence',
        params: {
          condo: this.condo,
          showAsInputGroup: true,
          typeaheadHideResultsOnBlur: true,
          placeholder: 'Digite uma ' + this.condo?.customLabels?.residence?.singular || 'unidade',
          adaptivePosition: true,
          clearInputTextAfterSelect: false
        },
        searchType: 'match'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'type',
        selectOptions: [
          { label: 'Todos os tipos', value: '' },
          { label: 'Carro', value: 'CAR' },
          { label: 'Moto', value: 'MOTORCYCLE' },
          { label: 'Caminhão', value: 'TRUCK' },
          { label: 'Bicicleta', value: 'BICYCLE' }
        ],
        label: 'Tipo de veículo',
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'plate',
        label: 'Placa',
        placeholder: 'Busque pela placa',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'chassis',
        label: 'Chassi',
        placeholder: 'Busque pelo chassi',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'model',
        label: 'Modelo',
        placeholder: 'Busque pelo modelo',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'brand',
        label: 'Marca',
        placeholder: 'Busque pela marca',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'color',
        label: 'Cor',
        placeholder: 'Busque pela cor',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'parkingSpot',
        label: 'Vaga',
        placeholder: 'Busque pela vaga',
        searchType: 'regex'
      },
      {
        element: 'user-picker',
        elementSize: 'medium',
        label: capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino',
        name: 'user',
        params: {
          condo: this.condo,
          showAsInputGroup: true,
          typeaheadHideResultsOnBlur: true,
          placeholder: `Digite o nome de um ${this.condo?.customLabels?.resident?.singular || 'condômino'}`,
          adaptivePosition: true,
          clearInputTextAfterSelect: false
        },
        searchType: 'match'
      }
    ];
  }

  ngOnInit() {
    this.tableColumns = [
      {
        columnId: 'type',
        headerLabel: 'Tipo',
        headerClass: 'justify-content-center',
        valueTemplate: this.vehicleTypeCellTemplate,
        sortable: true
      },
      { columnId: 'plate', headerLabel: 'Placa', valueFn: vehicle => vehicle.plate?.toUpperCase() || 'Não informado', sortable: true },
      { columnId: 'chassis', headerLabel: 'Chassi', valueFn: vehicle => vehicle.chassis.toUpperCase() || 'Não informado', sortable: true },
      { columnId: 'model', headerLabel: 'Modelo', valueKey: 'model', cellClass: 'text-capitalize', sortable: true },
      {
        columnId: 'brand',
        headerLabel: 'Marca',
        valueFn: vehicle => this.BRANDS[vehicle.brand] || vehicle.brand,
        cellClass: 'text-capitalize',
        sortable: true
      },
      { columnId: 'color', headerLabel: 'Cor', valueKey: 'color', sortable: true },
      {
        columnId: 'residence',
        type: 'residence',
        headerLabel: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        residence: vehicle => vehicle.residence
      },
      { columnId: 'parkingSpot', headerLabel: 'Vaga', valueKey: 'parkingSpot', sortable: true },
      {
        columnId: 'user',
        headerLabel: capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino',
        valueFn: vehicle => (vehicle.user ? `${vehicle.user.firstName} ${vehicle.user.lastName}` : 'Não informado'),
        linkUrl: vehicle => (vehicle.user ? `/condo/residents/${vehicle.user?._id}` : ''),
        sortable: true
      },
      {
        type: 'actions',
        headerLabel: 'Ações',
        actionsButtons: [
          { icon: 'fa-pencil-square-o', title: 'Editar veículo', handler: vehicle => this.handleEditVehicle(vehicle) },
          { icon: 'fa-trash', title: 'Excluir veículo', handler: vehicle => this.handleDeleteVehicle(vehicle) }
        ]
      }
    ];

    this.getData();
  }

  getData({ page = 0 } = {}) {
    const query = this.customQuery;

    const { pageSize, sortedColumn, sortOrder } = this.vehiclesTable.getCurrentState();

    query.$page = page;
    query.$limit = pageSize;

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    this.status = 'LOADING';

    this.vehicleService
      .getVehicles(this.condo._id, query)
      .pipe(timeout(10000))
      .subscribe({
        next: response => {
          this.totalVehiclesCount = response.count;
          this.vehicles = response.vehicles;
          this.status = 'SUCCESS';
        },
        error: () => {
          this.status = 'ERROR';
        }
      });
  }

  handleRefreshData() {
    const { currentPage } = this.vehiclesTable.getCurrentState();
    this.getData({ page: currentPage - 1 });
  }

  handleCreateVehicle() {
    const initialState = {
      condo: this.condo,
      onCreate: vehicle => {
        this.vehicles = [vehicle, ...this.vehicles];
      }
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState, ignoreBackdropClick: true });
  }

  handleEditVehicle(vehicleToEdit: CondoVehicle) {
    const initialState = {
      condo: this.condo,
      vehicle: vehicleToEdit,
      onUpdate: updatedVehicle => {
        this.vehicles = this.vehicles.map(vehicle => (vehicle._id === updatedVehicle._id ? updatedVehicle : vehicle));
      }
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState, ignoreBackdropClick: true });
  }

  handleDeleteVehicle(vehicleToDelete: CondoVehicle) {
    const deleteIdentification = vehicleToDelete.plate ? `a placa ${vehicleToDelete.plate}` : `o chassi ${vehicleToDelete.chassis}`;

    swal({
      type: 'question',
      text: `Deseja remover o veículo com ${deleteIdentification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.vehicleService
          .deleteVehicleFromCondo(this.condo._id, vehicleToDelete._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            const msg = err?.originalError?.message || 'Não foi possível excluir o veículo, tente novamente...';
            return Promise.reject(msg);
          });
      }
    }).then(
      () => {
        this.toastr.success('Veículo removido com sucesso');
        this.vehicles = this.vehicles.filter(vehicle => vehicle._id !== vehicleToDelete._id);
      },
      error => {
        console.log(error);
      }
    );
  }

  handleOpenFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        this.countActiveFilters(filters);
        this.filters = filters;
        this.customQuery = query;
        this.vehiclesTable.resetState({ currentPage: true });
        this.getData();
      }
    };

    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  countActiveFilters(filters: EcondosFilter[]) {
    this.numberOfActiveFilters = filters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }

  handleRemoveIndividualFilter({ index }) {
    this.filters[index].value = '';
    this.filters[index].valueLabel = '';

    delete this.customQuery[this.filters[index].name];

    this.numberOfActiveFilters--;
    this.getData();
  }

  handleClearFilters() {
    this.filters = this.filters.map(filter => {
      return {
        ...filter,
        value: '',
        valueLabel: ''
      };
    });
    this.numberOfActiveFilters = 0;
    this.customQuery = { ...this.initialQuery };
    this.getData();
  }

  askToRemoveAllVehicles() {
    const unsubscribe: Subject<void> = new Subject();
    const text = `Você realmente deseja excluir TODOS OS VEÍCULOS
    ? Digite "excluir tudo" no campo abaixo para confirmar sua ação. Lembre-se que esta ação não pode ser desfeita.`;
    swal({
      type: 'warning',
      title: 'Excluir todos os veículos',
      text,
      showCancelButton: true,
      input: 'text',
      inputPlaceholder: 'excluir tudo',
      confirmButtonText: 'Excluir veículos',
      confirmButtonColor: '#f53d3d',
      cancelButtonClass: 'btn-outline-danger',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async input => {
        input = (input || '').toString().trim().toLowerCase();
        if (!input || input !== 'excluir tudo') {
          return Promise.reject(`Digite "excluir tudo" para confirmar sua ação`);
        } else {
          const vehicles = await this.getVehiclesRecursively();
          const results = [];
          const requests = vehicles.map(vehicle => {
            return this.vehicleService.deleteVehicleFromCondo(this.condo._id, vehicle._id).pipe(
              timeout(10000),
              tap(() => {
                results.push({ [vehicle._id]: true });
                swal.getTitle().textContent = `Processando: ${results.length}/${requests.length}`;
              }),
              catchError(() => {
                results.push({ [vehicle._id]: false });
                return of(null);
              })
            );
          });
          await from(requests).pipe(mergeAll(5), takeUntil(unsubscribe)).toPromise();
          return Promise.resolve(results);
        }
      }
    })
      .then(() => {
        this.getData();
        swal({
          type: 'success',
          title: 'Veículos excluídos com sucesso!'
        });
      })
      .catch(() => {
        this.getData();
        unsubscribe.next(null);
        unsubscribe.complete();
      });
  }

  async getVehiclesRecursively(page = 0, size = 100) {
    const vehicles = await this.vehicleService
      .getVehicles(this.condo._id, {
        $select: '_id condos',
        $limit: size,
        $page: page
      })
      .pipe(map(res => res.vehicles))
      .toPromise();
    if (vehicles.length < size) {
      return vehicles;
    } else {
      return vehicles.concat(...(await this.getVehiclesRecursively(++page, size)));
    }
  }
}
