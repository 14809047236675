import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, from, Observable, of, Subject, Subscription } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import { User } from '@api/model/user';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ResidenceService } from '@api/service/residence.service';
import { garenActuatorTypes, GarenService } from '@api/service/hardware/garen.service';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { catchError, distinctUntilChanged, filter, map, mergeMap, retry, switchMap, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { SelectAccessGroupActuatorsComponent } from '../../components/select-access-group-actuators/select-access-group-actuators.component';
import * as moment from 'moment';
import { AccessGroup } from '@api/model/hardware/access-group';
import { AccessGroupService } from '@api/service/access-group.service';
import { conditionalValidator } from '@api/util/validators';
import { HardwareSocketService } from '../../services/hardware-socket.service';
import { orderBy } from 'lodash';
import { HARDWARES } from '@api/model/hardware/hardware-constants';

interface AccessGroupItem extends AccessGroup {
  isSelected?: boolean;
}

@Component({
  selector: 'app-modal-create-garen-device',
  templateUrl: './modal-create-garen-device.component.html',
  styleUrls: ['./modal-create-garen-device.component.scss']
})
export class ModalCreateGarenDeviceComponent implements OnInit, OnDestroy {
  @ViewChild('selectAccessGroupActuatorsComponent', { static: true }) selectAccessGroupActuators: SelectAccessGroupActuatorsComponent;

  condo: Condo;
  condoActuators: Actuator[] = [];

  TYPES = [
    { label: 'Facial', value: DEVICE_TYPES.FACIAL },
    { label: 'Cartão/Chaveiro', value: DEVICE_TYPES.CARD },
    { label: 'Senha', value: DEVICE_TYPES.SN },
    { label: 'QRCode', value: DEVICE_TYPES.QR }
  ];

  status: Status = new Status();

  device: Device;
  callbacks: { success?: (arg) => void; error?: (err) => void };

  form: UntypedFormGroup;

  type: AbstractControl;
  residence: AbstractControl;
  user: AbstractControl;
  userName: AbstractControl;
  picture: AbstractControl;
  obs: AbstractControl;
  accessGroups: AbstractControl;
  validFromDate: AbstractControl;
  validUntilDate: AbstractControl;
  serial: AbstractControl;

  getAccessGroups$: Observable<AccessGroup[]>;
  condoAccessGroups: AccessGroupItem[] = [];

  checkedActuators = {};

  residenceSearch;
  residenceUsers: { name: string; type; _id: string; picture: any }[] = [];
  loadingResidences = false;

  residenceTypeAheadDataSource$: Observable<any>;

  selectedResidence;

  private subscriptions: Subscription = new Subscription();

  formIsDisabled = false;

  storageUser: { user: User; residence: Residence };

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private garenService: GarenService,
    private deviceService: HardwareDeviceService,
    private actuatorService: ActuatorService,
    private toastrService: ToastrService,
    private accessGroupService: AccessGroupService,
    private hardwareSocketService: HardwareSocketService
  ) {
    this.form = this.fb.group({
      type: ['', Validators.required],
      user: [null],
      userName: [''],
      residence: [null, Validators.required],
      picture: [null, conditionalValidator(() => this.type.value === 'FACIAL', Validators.required)],
      obs: [''],
      accessGroups: [[], Validators.required],
      validFromDate: [
        moment().startOf('d').format('YYYY-MM-DD'),
        conditionalValidator(() => this.type.value !== 'FACIAL', Validators.required)
      ],
      validUntilDate: [''],
      serial: ['', conditionalValidator(() => this.type.value !== 'FACIAL', Validators.required)]
    });

    this.type = this.form.get('type');
    this.user = this.form.get('user');
    this.userName = this.form.get('userName');
    this.residence = this.form.get('residence');
    this.picture = this.form.get('picture');
    this.obs = this.form.get('obs');
    this.accessGroups = this.form.get('accessGroups');
    this.validFromDate = this.form.get('validFromDate');
    this.validUntilDate = this.form.get('validUntilDate');
    this.serial = this.form.get('serial');

    this.type.valueChanges.subscribe(value => {
      if (value === 'FACIAL') {
        this.form.removeControl('serial');
      } else {
        this.form.addControl('serial', this.serial);
      }
      Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
    });

    this.subscriptions.add(
      this.user.valueChanges.subscribe(user => {
        if (user === 'NEW_USER') {
          swal({
            title: 'Inserir nome',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#F53D3D',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            inputPlaceholder: 'Digite o nome...',
            showLoaderOnConfirm: true,
            preConfirm: name => {
              if (!name || !name.trim().length) {
                return Promise.reject('Insira o nome');
              } else {
                name = name.trim();
                return Promise.resolve(name);
              }
            }
          }).then(
            name => {
              this.userName.setValue(name);
              this.user.setValue('', { emitEvent: false });
            },
            () => {
              this.userName.setValue('');
              this.user.setValue('', { emitEvent: false });
            }
          );
        } else if (user?.type === 'DEPENDENT') {
          this.userName.setValue(user.name);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          }
          this.user.setValue('', { emitEvent: false });
        } else if (this.checkHasDeviceOwnerPicture()) {
          this.picture.setValue(this.device.owner.picture);
        } else if (user) {
          user = this.residenceUsers.find(u => u._id === user._id);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          } else {
            this.picture.setValue(null);
          }
        } else {
          this.picture.setValue(null);
        }
      })
    );

    this.initializeTypeAheads();
  }

  ngOnInit(): void {
    if (this.device) {
      this.formIsDisabled = true;
      this.type.setValue(this.device.type);
      this.serial.setValue(this.device.serial);
      this.obs.setValue(this.device.obs);
      this.obs.enable();
      if (this.device.owner) {
        if (this.device.owner.residence && this.device.owner.residence._id) {
          this.residence.setValue(this.device.owner.residence);
          const userId: any = (this.device.owner && this.device.owner.user && this.device.owner.user._id) || '';
          this.initializeFieldsFromResidence(this.device.owner.residence, {
            userId
          });
        }
        if (this.device.owner.user && this.device.owner.user._id) {
          const user = {
            name: `${this.device.owner.user.firstName} ${this.device.owner.user.lastName}`,
            _id: this.device.owner.user._id,
            picture: this.device.owner.user.picture,
            type: 'USER'
          };
          this.residenceUsers = [user];
          this.user.setValue(user);
        } else {
          this.user.setValue('');
          if (this.device.owner.userName) {
            this.userName.setValue(this.device.owner.userName);
          } else if (this.device.hardwareAttributes && this.device.hardwareAttributes.rotulo) {
            this.userName.setValue(this.device.hardwareAttributes.rotulo);
          }
        }
        if (this.device.owner.picture && this.device.owner.picture._id) {
          this.picture.setValue(this.device.owner.picture);
        }
      }

      if (this.device.validFrom) {
        const validFrom = moment(this.device.validFrom);
        this.validFromDate.setValue(validFrom.format('YYYY-MM-DD'));
      }

      if (this.device.validUntil) {
        const validUntil = moment(this.device.validUntil);
        this.validUntilDate.setValue(validUntil.format('YYYY-MM-DD'));
      }
    } else if (this.selectedResidence) {
      this.residence.setValue(this.selectedResidence);
      this.initializeFieldsFromResidence(this.selectedResidence);
    }

    if (this.storageUser) {
      if (this.storageUser.residence) {
        this.residence.setValue(this.storageUser.residence);
        if (!this.storageUser.user) {
          this.initializeFieldsFromResidence(this.storageUser.residence);
        }
        this.user.setValue('');
      }
      if (this.storageUser.user) {
        this.user.setValue(this.storageUser.user._id);
        this.residenceUsers = [
          {
            name: this.storageUser.user.fullName,
            type: 'USER',
            _id: this.storageUser.user._id,
            picture: this.storageUser.user.picture
          }
        ];
        this.user.setValue(this.residenceUsers[0]);
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residence.setValue(residences[0]);
        }
        if (this.storageUser.user.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
      }
    }

    this.getAccessGroups$ = this.accessGroupService.get(this.condo._id, { $populate: ['actuators', 'timezone'] }).pipe(
      map(({ accessGroups }) => accessGroups),
      tap(accessGroups => (this.condoAccessGroups = accessGroups)),
      tap(() => {
        this.device?.accessGroups?.forEach(accessGroup => {
          this.toggleAccessGroupSelection(accessGroup);
        });
      }),
      timeout(10000),
      retry(3)
    );
  }

  private checkHasDeviceOwnerPicture() {
    return !!this.device?.owner?.picture;
  }

  private initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = new Observable<any>((observer: any) => {
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type voter users',
          $populate: [
            {
              path: 'users',
              select: 'firstName lastName picture phones',
              populate: {
                path: 'picture',
                select: 'url thumbnail'
              }
            },
            {
              path: 'voter',
              select: 'firstName lastName picture phones',
              populate: {
                path: 'picture',
                select: 'url thumbnail'
              }
            }
          ]
        };
        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(e => {
            this.status.setAsError();
            return from([]);
          })
        );
      })
    );
  }

  private initializeFieldsFromResidence(residence: Residence, selectedValues: { vehicleId?: string; userId?: string } = {}) {
    const residenceQuery: EcondosQuery = {
      $populate: [
        {
          path: 'users',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        },
        {
          path: 'voter',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        }
      ]
    };
    forkJoin([
      this.residenceService.getResidenceByIdWithParams(this.condo._id, residence._id, residenceQuery),
      this.residenceService.getVehicles(this.condo._id, residence._id)
    ])
      .pipe(timeout(15000))
      .subscribe(
        async ([residenceResponse, vehiclesResponse]) => {
          this.residenceUsers = this.getResidenceUsers(residenceResponse);
          const dependents = await this.getResidenceDependents(this.condo, residence);
          this.residenceUsers.push(...dependents);
          if (selectedValues.userId) {
            const user = this.residenceUsers.find(u => u._id === selectedValues.userId);
            this.user.setValue(user);
          } else {
            this.user.setValue('');
          }
        },
        async err => {
          console.log(err);
          await swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível obter os dados necessários, verifique sua conexão e tente novamente.'
          });
          this.bsModalRef.hide();
        }
      );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleAll() {
    this.selectAccessGroupActuators.toggleAll();
  }

  save(value) {
    if (this.form.valid) {
      const device = this.buildDevice();
      if (this.device) {
        this.updateDevice(device);
      } else {
        this.createDevice(device);
      }
    } else {
      this.toastrService.warning('Preencha todos os campos e adicione a foto da pessoa');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }

  clearResidence() {
    this.residenceSearch = '';
    this.residence.setValue(null);
    this.residenceUsers = [];
    this.user.setValue(null);
  }

  async onResidenceSelect(event: TypeaheadMatch) {
    const residence: Residence = event.item;
    this.residence.setValue(residence);
    const users = this.getResidenceUsers(residence);
    this.residenceUsers = [...users];
    const dependents = await this.getResidenceDependents(this.condo, residence);
    this.residenceUsers.push(...dependents);
    this.residenceUsers = orderBy(this.residenceUsers, [u => u.name.toLowerCase()]);
    this.user.setValue('');
  }

  private getResidenceUsers(residence: Residence) {
    return (residence.residenceUsers || []).map(u => ({
      type: 'USER',
      _id: u._id,
      name: u.firstName + ' ' + u.lastName,
      picture: u.picture
    }));
  }

  private async getResidenceDependents(condo: Condo, residence: Residence) {
    const dependentsParams = [];
    dependentsParams.push({ '$populate[0][path]': 'picture' });
    dependentsParams.push({
      '$populate[0][select]': 'url thumbnail type format name'
    });
    dependentsParams.push({ $sort: 'name' });
    return await this.residenceService
      .getDependents(condo._id, residence._id, dependentsParams)
      .pipe(
        map(res =>
          res.dependents.map(dep => ({
            type: 'DEPENDENT',
            _id: dep._id,
            picture: dep.picture,
            name: dep.name
          }))
        )
      )
      .toPromise();
  }

  private updateDevice(device: Device) {
    this.status.setAsProcessing();

    const updatedDevice = { ...device };

    updatedDevice.accessGroups = this.accessGroups.value.map((group: AccessGroup) => group._id);

    if (this.type.value !== 'FACIAL') {
      const validFromString = `${this.validFromDate.value}`;
      const validFrom = moment(validFromString, 'YYYY-MM-DD').toISOString();

      const validUntilString = `${this.validUntilDate.value}`;
      const validUntil = moment(validUntilString, 'YYYY-MM-DD').toISOString();

      updatedDevice.validFrom = validFrom;
      updatedDevice.validUntil = validUntil;
    }

    this.deviceService
      .update(this.condo._id, this.device._id, updatedDevice)
      .pipe(
        map(() => {
          const dev: Device = {
            ...updatedDevice,
            _id: this.device._id,
            owner: {
              user: this.user.value || null,
              picture: this.picture.value || null,
              residence: this.residence.value || null,
              userName: this.userName.value || ''
            },
            condo: this.condo
          };
          const accessGroupKeys = (this.accessGroups.value || []).reduce((acc, curr) => {
            acc[curr._id] = true;
            return acc;
          }, {});
          dev.accessGroups = this.condoAccessGroups.filter(ag => accessGroupKeys[ag._id]);

          return dev;
        }),
        switchMap((savedDevice: Device) => {
          const accessGroups = this.condoAccessGroups;
          const accessGroupsActuators = (accessGroups.map(ag => ag.actuators) || [])
            .reduce((acc, curr) => {
              acc.push(...curr);
              return acc;
            }, [])
            .filter(a => a.hardware === HARDWARES.GAREN);
          const actuators = accessGroupsActuators?.length ? accessGroupsActuators : device?.actuators;
          return this.garenService.updateDevice(actuators, savedDevice).pipe(
            switchMap(data => {
              if (data?.results?.every(res => res.ok)) {
                return this.deviceService
                  .update(this.condo._id, data.device?._id, {
                    status: 'SYNCED'
                  })
                  .pipe(
                    catchError(() => {
                      this.status.setAsError();
                      return of({ ...data.device, status: 'UNSYNCED' });
                    }),
                    map(() => ({ ...data.device, status: 'SYNCED' })),
                    tap(() => this.status.setAsSuccess())
                  );
              } else {
                const errMessages = data?.results
                  ?.filter(res => !res.ok)
                  .map(res => `${res.actuator?.name}: ${res.error}`)
                  .join('<br>');
                swal({
                  type: 'error',
                  title: 'Não foi possível atualizar nos equipamentos',
                  html: `<div>${errMessages}</div>`
                });
                return this.deviceService
                  .update(this.condo._id, data.device?._id, {
                    status: 'UNSYNCED'
                  })
                  .pipe(
                    catchError(() => {
                      this.status.setAsError();
                      return of({ ...data.device, status: 'UNSYNCED' });
                    }),
                    map(() => ({ ...data.device, status: 'UNSYNCED' })),
                    tap(() => this.status.setAsError())
                  );
              }
            })
          );
        })
      )
      .subscribe(
        (dev: any) => {
          if (dev.owner) {
            if (this.residence.value) {
              dev.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              const populatedUser = this.residenceUsers.find(u => u._id === this.user.value?._id);
              const [firstName, lastName] = populatedUser.name?.split(' ');
              dev.owner.user = {
                firstName,
                lastName,
                _id: populatedUser._id,
                picture: populatedUser.picture
              };
            }
            if (this.picture.value) {
              dev.owner.picture = this.picture.value;
            }
          }
          if (this.callbacks?.success) {
            this.callbacks.success(new Device(dev));
          }
          this.bsModalRef.hide();
        },
        err => {
          console.log(err);
          this.status.setAsError();
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível editar este dispositivo.'
          });
        }
      );
  }

  private createDevice(device: Device) {
    this.status.setAsProcessing();

    const createdDevice = { ...device };

    createdDevice.accessGroups = this.accessGroups.value.map((group: AccessGroup) => group._id);

    if (this.type.value !== 'FACIAL') {
      const validFromString = `${this.validFromDate.value}`;
      const validFrom = moment(validFromString, 'YYYY-MM-DD').toISOString();

      const validUntilString = `${this.validUntilDate.value}`;
      const validUntil = moment(validUntilString, 'YYYY-MM-DD').toISOString();

      createdDevice.validFrom = validFrom;
      createdDevice.validUntil = validUntil;
    }

    this.deviceService
      .create(this.condo._id, createdDevice)
      .pipe(
        map(({ _id }) => {
          const dev = {
            ...device,
            _id,
            owner: {
              user: this.user.value || null,
              picture: this.picture.value || null,
              residence: this.residence.value || null,
              userName: this.userName.value || ''
            },
            condo: this.condo
          };

          const accessGroupKeys = (this.accessGroups.value || []).reduce((acc, curr) => {
            acc[curr._id] = true;
            return acc;
          }, {});
          dev.accessGroups = this.condoAccessGroups.filter(ag => accessGroupKeys[ag._id]);

          return dev;
        }),
        switchMap(savedDevice =>
          this.garenService.createDevice(savedDevice).pipe(
            switchMap(data => {
              if (data?.results?.every(res => res.ok)) {
                return this.deviceService
                  .update(this.condo._id, data.device?._id, {
                    status: 'SYNCED'
                  })
                  .pipe(
                    catchError(() => {
                      this.status.setAsError();
                      return of({ ...savedDevice, ...data.device, status: 'UNSYNCED', hardware: 'GAREN' });
                    }),
                    map(() => ({ ...savedDevice, ...data.device, status: 'SYNCED', hardware: 'GAREN' })),
                    tap(() => this.status.setAsSuccess())
                  );
              } else {
                const errMessages = data?.results
                  ?.filter(res => !res.ok)
                  .map(res => `${res.actuator?.name}: ${res.error}`)
                  .join('<br>');
                swal({
                  type: 'error',
                  title: 'Não foi possível cadastrar nos equipamentos',
                  html: `<div>${errMessages}</div>`
                });
                this.status.setAsError();
                return of({ ...data.device, status: 'UNSYNCED' });
              }
            })
          )
        )
      )
      .subscribe(
        (dev: any) => {
          if (dev.accessGroups?.length) {
            const agKeys = dev.accessGroups.reduce((acc, curr) => {
              acc[curr._id] = curr;
              return acc;
            }, {});
            dev.accessGroups = this.condoAccessGroups.filter(ag => agKeys[ag._id]);
          }
          // Call status.setAsSuccess()
          if (dev.owner) {
            if (this.residence.value) {
              dev.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              const populatedUser = this.residenceUsers.find(u => u._id === this.user.value?._id);
              const [firstName, lastName] = populatedUser.name?.split(' ');
              dev.owner.user = {
                firstName,
                lastName,
                _id: populatedUser._id,
                picture: populatedUser.picture
              };
            }
            if (this.picture.value) {
              dev.owner.picture = this.picture.value;
            }
          }
          if (this.callbacks?.success) {
            this.callbacks.success(new Device(dev));
          }
          this.bsModalRef.hide();
        },
        err => {
          console.log({ err });
          this.status.setAsError();
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível cadastrar este dispositivo.'
          });
        }
      );
  }

  private buildDevice() {
    const device: any = {
      type: this.type.value,
      hardware: 'GAREN',
      obs: this.obs.value,
      serial: this.serial.value
    };

    if (this.residence.value) {
      device.owner = {
        residence: this.residence.value._id || this.residence.value
      };

      if (this.user.value) {
        device.owner.user = this.user.value._id || this.user.value;
      } else if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }

      if (this.picture.value) {
        device.owner.picture = this.picture.value._id || this.picture.value;
      }
    }

    if (this.type.value === 'FACIAL') {
      if (device.accessGroups && device.accessGroups.length) {
        const actuators = this.accessGroups.value.map(ag => ag.actuators) || [];
        device.actuators = actuators.reduce((acc, cur) => acc.concat(cur), []);
      } else {
        device.actuators = [];
      }
    } else {
      const accessGroups = this.accessGroups.value.map((group: AccessGroup) => group._id);
      device.accessGroups = accessGroups;

      const validFromString = `${this.validFromDate.value}`;
      const validFrom = moment(validFromString, 'YYYY-MM-DD').toISOString();

      const validUntilString = `${this.validUntilDate.value}`;
      const validUntil = moment(validUntilString, 'YYYY-MM-DD').toISOString();

      device.validFrom = validFrom;
      device.validUntil = validUntil;
    }

    return device;
  }

  public toggleAccessGroupSelection(accessGroup: AccessGroupItem): void {
    const accessGroupIndex = this.accessGroups.value.findIndex(group => group._id === accessGroup._id);

    if (accessGroupIndex === -1) {
      this.condoAccessGroups = this.condoAccessGroups.map(group => {
        if (group._id === accessGroup._id) {
          return { ...group, isSelected: true };
        }

        return group;
      });

      this.accessGroups.setValue([...this.accessGroups.value, accessGroup]);
    } else {
      const updatedAccessGroups = this.accessGroups.value;
      updatedAccessGroups.splice(accessGroupIndex, 1);

      this.condoAccessGroups = this.condoAccessGroups.map(group => {
        if (group._id === accessGroup._id) {
          return { ...group, isSelected: false };
        }

        return group;
      });

      this.accessGroups.setValue(updatedAccessGroups);
    }
  }

  public async collectCard() {
    const actuators = await this.actuatorService
      .getActuators(this.condo._id, { hardware: 'GAREN', type: garenActuatorTypes })
      .pipe(
        map(v => v.actuators),
        timeout(10000),
        retry(3)
      )
      .toPromise();
    if (!actuators || !actuators.length) {
      swal({
        type: 'error',
        title: 'Equipamentos não cadastrados',
        text: 'Não existem equipamentos cadastrados no seu condomínio. Antes de cadastrar um cartão é necessário você cadastrar os equipamentos'
      });
      return;
    }
    let selectedActuator;
    if (actuators.length > 1) {
      const inputOptions: any = actuators.reduce(
        (acc, curr) => {
          acc[curr._id] = curr.name;
          return acc;
        },
        { ALL: 'Todos' }
      );

      const actuatorId = await swal({
        inputOptions,
        title: 'Equipamento',
        text: 'Selecione o equipamento desejado para a leitura do cartão',
        input: 'select',
        inputPlaceholder: 'Selecione',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputValidator: async res => {
          if (res) {
            await Promise.resolve();
          } else {
            return Promise.reject('Selecione um equipamento!');
          }
        }
      });
      if (actuatorId === 'ALL') {
        selectedActuator = actuatorId;
      } else {
        selectedActuator = actuators.find(a => a._id === actuatorId);
      }
    } else {
      selectedActuator = actuators[0];
    }

    const destroy$ = new Subject();

    this.hardwareSocketService.onData$
      .pipe(
        timeout(60000),
        filter(
          ({ condoId, hardwareEvent }) =>
            condoId === this.condo._id &&
            hardwareEvent &&
            hardwareEvent.eventType === 'OTHER' &&
            hardwareEvent.serial &&
            hardwareEvent.deviceType &&
            hardwareEvent.deviceType === DEVICE_TYPES.CARD
        ),
        filter(
          data => selectedActuator === 'ALL' || (data.hardwareEvent.actuator?._id || data.hardwareEvent.actuator) === selectedActuator._id
        ),
        map(res => ({ ...res.hardwareEvent })),
        map(res => (res && res.serial) || ''),
        takeUntil(destroy$),
        take(1),
        tap(() => {
          swal.clickConfirm();
        })
      )
      .subscribe(
        serial => {
          this.toastrService.success(`Cartão ${serial} lido com sucesso`);
          this.serial.setValue(serial);
          destroy$.next(null);
          destroy$.complete();
        },
        err => {
          swal({
            type: 'error',
            title: 'Erro na leitura',
            text: 'Não foi possível realizar a leitura do cartão'
          });
          destroy$.next(null);
          destroy$.complete();
          console.log(err);
        }
      );

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Passe o cartão na leitora ${selectedActuator.name || ''}`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(e => {
      console.log(e);
      destroy$.next(null);
      destroy$.complete();
    });
  }

  public generateRandomSerial(length): void {
    const serial = [...Array(length)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('')
      .toUpperCase();
    this.serial.setValue(serial);
  }
}
