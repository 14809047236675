import { Component } from '@angular/core';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WEEK_DAYS_LONG } from '@api/util/constants';
import { CondoContactService } from '@api/service/condo.contact.service';
import { Condo } from '@api/model/condo';
import { ContactID } from '@api/model/contact/contact.id';

@Component({
  selector: 'app-modal-permission-details',
  templateUrl: 'modal-permission-details.component.html',
  styleUrls: ['modal-permission-details.component.scss']
})
export class ModalPermissionDetailsComponent {
  occurrence: GateOccurrence;
  condo: Condo;

  WEEK_DAYS = WEEK_DAYS_LONG;
  DOC_TYPES_LABEL = ContactID.TYPES_LABEL;

  constructor(private bsModalRef: BsModalRef, private condoContactService: CondoContactService) {}

  closeModal() {
    this.bsModalRef.hide();
  }
}
