export interface UserLocalSettings {
  /**
   * The cameras group's id which will be used as default.
   */
  defaultCamerasGroup?: string;

  /**
   * Defines whether the application should use the Hikvision v1 methods or not.
   * If null or undefined, use false.
   * @default false
   */
  useDeprecatedHikvision?: boolean;

  /**
   * Saves the datetime of the last update occurred.
   */
  updatedAt?: string;
}

export const defaultSettingsValues: Required<Omit<UserLocalSettings, 'updatedAt'>> = {
  defaultCamerasGroup: undefined,
  useDeprecatedHikvision: false
};
