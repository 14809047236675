import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragAndDropFile]'
})
export class DragAndDropFileDirective {
  @Input() canDropFile = true;

  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('style.background-color') private background = '#ffffff';
  @HostBinding('style.opacity') private opacity = '1';
  @HostBinding('style.border') private border = 'initial';

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    if (this.canDropFile) {
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#f4f4f4';
      this.opacity = '0.8';
      this.border = 'dashed 2px #d2d2d2';
    }
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    if (this.canDropFile) {
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#ffffff';
      this.opacity = '1';
      this.border = 'initial';
    }
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    if (this.canDropFile) {
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#ffffff';
      this.opacity = '1';
      this.border = 'initial';
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.onFileDropped.emit(files);
      }
    }
  }
}
