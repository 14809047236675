import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SkeletonComponent } from '../../../skeleton/skeleton.component';
import { TableColumnDefinition, TableStatus, TDataWithTableId } from '../../table.component';
import { get } from 'lodash';
import { RouterLinkWithHref } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SessionService } from '@api/service/session.service';
import { AppResidenceLinkComponent } from '../../../app-residence-link/app-residence-link.component';
import { DirectivesModule } from '../../../../directives/directives.module';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-table-row]',
  templateUrl: './table-row.component.html',
  imports: [
    CommonModule,
    SkeletonComponent,
    RouterLinkWithHref,
    TooltipModule,
    AppResidenceLinkComponent,
    NgOptimizedImage,
    DirectivesModule
  ]
})
export class TableRowComponent<TData = any> {
  condo = this.sessionService.condoValue;

  @Input() data: TDataWithTableId<TData>;
  @Input() index: number;

  @Input() isSelected = false;
  @Input() tableHasData = false;

  @Input() columns: TableColumnDefinition<TData>[] = [];
  @Input() status: TableStatus = 'SUCCESS';
  @Input() selectionMode: 'none' | 'unique' | 'multiple' = 'none';

  @Output() toggleRowSelection = new EventEmitter<TDataWithTableId<TData>>();

  @ContentChild('rows') rows: TemplateRef<any> | undefined;

  AVATAR_SIZES: Record<ReturnType<TableColumnDefinition['avatar']>['size'], number> = {
    small: 1.5,
    medium: 2,
    large: 3
  };

  constructor(private sessionService: SessionService) {}

  handleToggleRowSelection() {
    this.toggleRowSelection.emit(this.data);
  }

  getValue(data: TData, key: string): unknown {
    return get(data, key);
  }
}
