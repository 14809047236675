import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { ThemeModule } from '../../theme';
import { ModalCreateIntelbrasStandAloneDeviceComponent } from './modal-create-intelbras-stand-alone-device.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '../../pipe/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    TypeaheadModule,
    ComponentsModule,
    TooltipModule,
    PipesModule,
    ThemeModule,
    RouterModule
  ],
  declarations: [ModalCreateIntelbrasStandAloneDeviceComponent]
})
export class ModalCreateIntelbrasStandAloneDeviceModule {}
