import { Component, Input, OnInit } from '@angular/core';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { User } from '@api/model/user';
import { BsModalRef } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modal-create-user-steps',
  templateUrl: 'modal-create-user-steps.component.html',
  styleUrls: ['modal-create-user-steps.component.scss']
})
export class ModalCreateUserStepsComponent implements OnInit {
  @Input()
  user: User;

  @Input()
  condo: Condo;

  residence: Residence;

  index = 0;

  globals: any = {
    pages: {
      USER: 0,
      RESIDENCE: 1,
      VEHICLE: 2,
      DEVICE: 3
    }
  };

  residences: Residence[];

  callback: (arg?) => void;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.globals.user = this.user;
    this.globals.condo = this.condo;
    this.globals.index = this.globals.pages.USER;
    this.globals.residence = this.residence;
    this.globals.residences = this.user?.getResidences() || [];
  }

  closeModal() {
    if (this.globals.user && !this.globals.residences.length) {
      swal({
        type: 'question',
        title: 'Usuário sem ' + this.condo?.customLabels?.residence?.singular || 'unidade',
        text:
          'O usuário criado não está associado à nenhum(a) ' + this.condo?.customLabels?.residence?.singular ||
          'unidade' + '. Deseja continuar mesmo assim?',
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: 'var(--red-500)',
        confirmButtonText: 'Sim',
        confirmButtonColor: 'var(--green-500)'
      }).then(
        () => {
          this.closeCallback();
        },
        err => console.log(err)
      );
    } else {
      this.closeCallback();
    }
  }

  closeCallback() {
    if (this.callback) {
      this.callback(this.globals.user);
    }
    this.bsModalRef.hide();
  }

  getUserSaved(user) {
    this.globals.userIsValid = true;
    this.globals.user = user;
  }

  pageChanged(index) {
    if (this.globals.user) {
      const notStepperPage = index > this.globals.pages.DEVICE;
      if (notStepperPage) {
        this.closeModal();
      } else {
        this.globals.index = index;
        this.index = index;
      }
    }
  }

  changeResidences(residences) {
    this.globals.residences = residences;
  }
}
