import { Occurrence } from '../interface/occurrence';
import { User } from '../user';

/**
 * Created by Rafael on 23/12/2016.
 */

export interface IChoice {
  _id?: string;
  text?: string;
  residences?: Array<any>;
  users?: Array<any>;
}

export class Choice {
  public _id: string;
  public text: string;
  public residences: Array<any>;
  public users: Array<any>;

  constructor(obj?: IChoice) {
    this._id = (obj && obj._id) || '';
    this.text = (obj && obj.text) || '';
    this.residences = (obj && obj.residences) || [];
    this.users = (obj && obj.users) || [];
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
}

export class VotingOccurrence extends Occurrence {
  public votingFrom: Date;
  public votingUntil: Date;
  private _votes: Array<Choice>;
  public isVoting;
  public voteType: 'USER' | 'RESIDENCE';
  public defaulterCanVote: boolean;

  constructor(occurrence) {
    super(occurrence);
    this.votingFrom = occurrence.votingFrom || new Date().toISOString();
    this.votingUntil = occurrence.votingUntil || new Date().toISOString();
    this._votes = occurrence.votes ? occurrence.votes.map(vote => new Choice(vote)) : [];
    this.isVoting = occurrence.isVoting || false;
    this.status = this.isVoting ? Occurrence.STATUS.OPENED : Occurrence.STATUS.CLOSED;
    this.voteType = occurrence.voteType || 'RESIDENCE';
    this.defaulterCanVote = occurrence.defaulterCanVote === false ? false : true;
  }

  // Ninguém
  canEdit(user: User = new User()): boolean {
    return user.isOwner() || user.isAdmin();
  }

  // Admin/criador
  canDelete(user: User = new User()): boolean {
    return user.isOwner() || user.isAdmin() || this.isCreatedBy(user.id);
  }

  // Ninguém
  canCancel(user: User = new User()): boolean {
    return false;
  }

  // Não pode ser fechada
  canClose(user: User = new User()): boolean {
    return false;
  }

  canVote(user: User) {
    return this.voteType === 'USER' || (user && user.residencesVoter && !!user.residencesVoter.length);
  }

  get votes(): any {
    return this._votes;
  }

  set votes(value: any) {
    this._votes = value;
  }

  winner() {
    try {
      const votes = this.votes.map(c => (this.voteType === 'USER' ? c.users : c.residences)).map(arr => arr.length);
      const winnerIindex = votes.reduce((p, c, i, a) => (a[p] > c ? p : i), 0);
      // Count indexes with same amount of votes
      const count = votes.reduce((n, val) => n + (val === votes[winnerIindex]), 0);
      // If result is a draw, it returns -1
      return count === 1 ? winnerIindex : -1;
    } catch (e) {
      console.log('Catch error finding voting winner', e);
      return -1;
    }
  }

  findUserSelectedChoice(user: User): number {
    let index;
    if (this.voteType === 'USER') {
      const userId = user._id || user;
      index = this.votes.findIndex(c => c.users.some(u => u === userId));
    } else {
      let residences = user.residencesVoter.length ? user.residencesVoter : user.residencesUser;
      residences = residences.map(r => r._id || r);
      index = this.votes.findIndex(c => c.residences.some(res => residences.findIndex(r => r === res) !== -1));
    }
    return index;
  }

  totalOfVotesFromChoice(index: number): number {
    if (this.voteType === 'RESIDENCE') {
      return (this.votes[index] && this.votes[index].residences && this.votes[index].residences.length) || 0;
    } else if (this.voteType === 'USER') {
      return (this.votes[index] && this.votes[index].users && this.votes[index].users.length) || 0;
    } else {
      return 0;
    }
  }

  get totalOfVotes(): number {
    return this.votes.reduce((acc, curr) => acc + (this.voteType === 'USER' ? curr.users.length : curr.residences.length), 0);
  }

  vote(choice, user: User) {
    if (this.voteType === 'USER') {
      this.updateUsersVotes(choice, user);
    } else {
      this.updateResidenceVotes(choice, user);
    }
  }

  private updateResidenceVotes(chosenChoice, user: User) {
    const residencesVoter = user.residencesVoter.map(r => r._id || r);
    for (const choice of this.votes) {
      if (choice._id == chosenChoice._id) {
        choice.residences = choice.residences.concat(residencesVoter);
      } else {
        choice.residences = choice.residences.filter(resId => residencesVoter.indexOf(resId) < 0);
      }
    }
  }

  private updateUsersVotes(chosenChoice, user: User) {
    for (const choice of this.votes) {
      if (choice._id == chosenChoice._id) {
        if (choice.users.findIndex(u => u === user._id) === -1) {
          choice.users = choice.users.concat(user._id);
        }
      } else {
        choice.users = choice.users.filter(u => u !== user._id);
      }
    }
  }
}
