import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ConstantService } from '../services/constant.service';

@Injectable({ providedIn: 'root' })
export class EnvironmentInterceptor implements HttpInterceptor {
  constructor(private constantService: ConstantService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const dynamicEnv = this.constantService.dynamicEnvironment;
    const url = request.url || '';
    const isEcondosApiRequest = url.includes('econdos.com.br/api/') || url.includes('localhost') || url.includes('127.0.0.1');
    if (dynamicEnv && isEcondosApiRequest) {
      const [domain, rest] = url.split('/api/');
      const isAPiRequest = domain.includes('api');
      const isReportRequest = domain.includes('reports');
      const isVisitorRequest = domain.includes('visitante');
      const isSocketRequest = domain.includes('ws:') || domain.includes('wss:');

      let newUrl = '';
      if (isAPiRequest) {
        const backendUrl = dynamicEnv.backendUrl.replace('v1/', '');
        newUrl = `${backendUrl}${rest}`;
      } else if (isReportRequest) {
        const reportsUrl = dynamicEnv.reportsUrl.replace('v1/', '');
        newUrl = `${reportsUrl}${rest}`;
      } else if (isVisitorRequest) {
        newUrl = `${dynamicEnv.visitorUrl}${rest}`;
      } else if (isSocketRequest) {
        newUrl = `${dynamicEnv.socketUrl}${rest}`;
      } else {
        const backendUrl = dynamicEnv.backendUrl.replace('v1/', '');
        newUrl = `${backendUrl}${rest}`;
      }
      request = request.clone({ url: newUrl });
    }
    return next.handle(request);
  }
}
