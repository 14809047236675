import { Camera } from './camera';
import { Condo } from './condo';
import { User } from './user';

export type GroupCameraType = 'FACE' | 'DOC_FRONT' | 'DOC_BACK' | 'VEHICLE';

type GroupCameraTypeLabel = { [key in GroupCameraType]: string };

export const GROUP_CAMERA_TYPE_LABEL: GroupCameraTypeLabel = {
  FACE: 'Rosto',
  DOC_FRONT: 'Documento - frente',
  DOC_BACK: 'Documento - verso',
  VEHICLE: 'Veículo'
};

export type CamerasGroupCamerasArrayItem = {
  type: GroupCameraType;
  camera: Camera;
};

export interface CamerasGroup {
  _id: string;
  condo: Condo;
  name: string;
  cameras: CamerasGroupCamerasArrayItem[];
  createdBy?: User;
  createdAt?: string;
  updatedAt?: string;
}

export type CamerasGroupOnlyWithId = Pick<CamerasGroup, '_id'>;

export const BuildCamerasGroup = (camerasGroup): CamerasGroupOnlyWithId | CamerasGroup => {
  if (typeof camerasGroup == 'string') {
    return {
      _id: camerasGroup
    };
  }

  if (camerasGroup) {
    return {
      _id: camerasGroup._id || '',
      condo: camerasGroup.condo ? new Condo(camerasGroup.condo) : null,
      name: camerasGroup.name || '',
      cameras: camerasGroup.cameras?.length ? camerasGroup.cameras : [],
      createdBy: camerasGroup.createdBy ? new User(camerasGroup.createdBy) : null,
      createdAt: camerasGroup.createdAt || null,
      updatedAt: camerasGroup.updatedAt || null
    };
  }
};
