import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Reservation } from '../../../api/model/reservation';
import { ReservationService } from '../../../api/service/reservation.service';
import { UtilService } from '../../../services/util.service';
import { User } from '../../../api/model/user';
import swal from 'sweetalert2';
import { timeoutWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { File } from '../../../api/model/file';
import { ModalWebCam } from '../../modal/webcam/webcam.modal';
import { ErrorBuilder } from '../../../api/model/error/error.builder';
import { Condo } from '@api/model/condo';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import { CurrencyPipe } from '../../../pipe/currency.pipe';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
interface Check {
  type?: String;
  checklist?: Array<{ status: boolean; item: string; description: string }>;
  checkedAt?: any;
  checkedBy?: User;
  signature?: File[];
}

@Component({
  selector: 'reservation-checklist-modal',
  templateUrl: 'reservation-checklist-modal.component.html'
})
export class ReservationChecklistModalComponent implements OnInit {
  @ViewChild(ModalWebCam, { static: true }) public webcamModal: ModalWebCam;

  type: string; // TODO set string to enum: 'CHECK-IN' | 'CHECK-OUT';
  reservation: Reservation;
  condo: Condo;

  readonly = false;
  filterPipe = new CurrencyPipe();
  checklist: Array<{ status: boolean; item: string; description: string }> = [];

  multipleView: Check[] = [];

  singleEdit: Check;
  user: User;
  constructor(
    public bsModalRef: BsModalRef,
    private utilService: UtilService,
    private toastr: ToastrService,
    private reservationService: ReservationService
  ) {}

  ngOnInit() {
    this.user = this.utilService.getLocalUser();
    if (this.type === 'CHECK-IN' && this.reservation && this.reservation.checkIn && this.reservation.checkIn.checkedAt) {
      this.multipleView[0] = this.singleEdit = {
        type: 'CHECK-IN',
        checklist: this.reservation.checkIn.items,
        checkedAt: this.reservation.checkIn.checkedAt,
        checkedBy: this.reservation.checkIn.checkedBy,
        signature: this.reservation.checkIn.signature ? [new File(this.reservation.checkIn.signature)] : []
      };
      this.readonly = true;
    } else if (this.type === 'CHECK-OUT' && this.reservation && this.reservation.checkOut && this.reservation.checkOut.checkedAt) {
      this.multipleView[0] = this.singleEdit = {
        type: 'CHECK-OUT',
        checklist: this.reservation.checkOut.items,
        checkedAt: this.reservation.checkOut.checkedAt,
        checkedBy: this.reservation.checkOut.checkedBy,
        signature: this.reservation.checkOut.signature ? [new File(this.reservation.checkOut.signature)] : []
      };

      this.readonly = true;
    } else if (this.type === 'CHECK-ALL') {
      this.multipleView = [
        {
          type: 'CHECK-IN',
          checklist: this.reservation.checkIn.items,
          checkedAt: this.reservation.checkIn.checkedAt,
          checkedBy: this.reservation.checkIn.checkedBy,
          signature: this.reservation.checkIn.signature ? [new File(this.reservation.checkIn.signature)] : []
        },
        {
          type: 'CHECK-OUT',
          checklist: this.reservation.checkOut.items,
          checkedAt: this.reservation.checkOut.checkedAt,
          checkedBy: this.reservation.checkOut.checkedBy,
          signature: this.reservation.checkOut.signature ? [new File(this.reservation.checkOut.signature)] : []
        }
      ];
      this.readonly = true;
    } else {
      this.multipleView[0] = this.singleEdit = {
        checklist: (this.reservation.reservationLocal.checklist || []).map(v => ({ status: true, item: v, description: '' })),
        signature: []
      };
      if (this.user.isAdmin() || this.user.isJanitor() || this.user.isOwner() || this.user.isGatekeeper()) {
        this.readonly = false;
      } else {
        this.readonly = true;
      }
    }

    if (this.reservation.status === Reservation.STATUS.CANCELED_WITH_PENALTY || this.reservation.status === Reservation.STATUS.CANCELED) {
      this.readonly = true;
    }
  }

  close() {
    this.bsModalRef.hide();
  }

  save() {
    let subscription;
    const condo = this.utilService.getLocalCondo();
    const user = this.utilService.getLocalUser();
    const body: { items: any[]; signature?: string } = {
      items: this.singleEdit.checklist
    };
    if (this.singleEdit.signature && this.singleEdit.signature.length) {
      body.signature = this.singleEdit.signature.map(f => f._id)[0];
    }

    if (this.type === 'CHECK-IN') {
      subscription = this.reservationService.checkIn(condo._id, this.reservation.reservationLocal._id, this.reservation._id, body);
    } else {
      subscription = this.reservationService.checkOut(condo._id, this.reservation.reservationLocal._id, this.reservation._id, body);
    }

    swal({
      type: 'question',
      title: `Salvar ${this.type === 'CHECK-IN' ? 'check-in' : 'check-out'}`,
      text: 'Deseja salvar? Depois de salvo não poderá ser alterado.',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return subscription
          .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível processar sua solicitação, verifique sua conexão e tente novamente...');
          });
      }
    }).then(
      () => {
        if (this.type === 'CHECK-IN') {
          this.reservation.checkIn = { checkedAt: new Date(), checkedBy: user, items: this.singleEdit.checklist };
          if (this.singleEdit.signature && this.singleEdit.signature.length) {
            this.reservation.checkIn.signature = this.singleEdit.signature[0];
          }
        } else {
          this.reservation.checkOut = { checkedAt: new Date(), checkedBy: user, items: this.singleEdit.checklist };
          if (this.singleEdit.signature && this.singleEdit.signature.length) {
            this.reservation.checkOut.signature = this.singleEdit.signature[0];
          }
        }
        this.close();
        this.toastr.success(this.type === 'CHECK-IN' ? 'Check-in salvo com sucesso' : 'Check-out salvo com sucesso');
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }
  generatePDF() {
    const body = this.multipleView.map(res => [
      [res.type],
      [`Data :    ${moment(res.checkedAt).format('DD/MM/YY - HH:mm')}`],
      [`Usuário :   ${res.checkedBy.firstName + ' ' + res.checkedBy.lastName}`],
      {
        table: {
          body: [
            ['Status', 'Item', 'Obervação'],
            ...res.checklist.map(item => [item.status ? 'OK' : 'Incidente', item.item, item.description])
          ]
        }
      },
      ['\n\n\n']
    ]);
    const taxes = this.reservation?.extraFees.length > 0 ? this.reservation?.extraFees : [{ name: 'Nenhuma', value: '0' }];

    let docDefinition = {
      content: [
        { text: `Relatório de Reserva do/a ${this.reservation?.reservationLocal?.name} \n\n`, style: 'header' },
        {
          lineHeight: 1.2,
          text: `Unidade :    ${this.reservation?.residence?.identification}         Reservado por :    ${
            this.reservation?.createdBy?.firstName + ' ' + this.reservation?.createdBy?.lastName
          }`
        },
        {
          lineHeight: 1.2,
          text: `Data da reserva :    ${moment(this.reservation?.startDate).utc().format('DD/MM/YY - HH:mm')} - ${moment(
            this.reservation?.endDate
          )
            .utc()
            .format('DD/MM/YY - HH:mm')} `
        },
        {
          lineHeight: 1.2,
          text: ` Reservado em :   ${moment(this.reservation?.createdAt).format('DD/MM/YY - HH:mm')}         Situação da reserva:   ${
            this.reservation?.statusLabel
          }`
        },
        {
          lineHeight: 1.2,
          text: `Aprovada por :   ${
            this.reservation?.approvedBy?.firstName + ' ' + this.reservation?.approvedBy?.lastName
          }         Aprovada em : ${moment(this.reservation?.approvedAt).format('DD/MM/YY - HH:mm')}`
        },
        { lineHeight: 1.2, text: `Observações :    ${this.reservation?.observation || ''}` },
        { lineHeight: 1.2, text: `Valor Inicial :    ${this.filterPipe.transform(this.reservation?.value)} ` },
        { lineHeight: 1.2, text: 'Taxas extras : ' },
        {
          table: {
            body: [['Nome', 'Valor'], ...taxes?.map(item => [item?.name, this.filterPipe.transform(item?.value)])]
          }
        },
        { lineHeight: 1.2, text: `\n Valor total :   ${this.filterPipe.transform(this.reservation?.totalValue)} ` },
        { text: '\n\n\n' },
        { style: 'tableCss', columns: body }
      ],
      styles: {
        header: {
          fontSize: 25,
          bold: true,
          alignment: 'center'
        },
        tableCss: {
          gap: 5
        }
      }
    };

    pdfMake.createPdf(docDefinition).download(`Relatório de Reserva - ${this.reservation?.reservationLocal?.name}.pdf`);
  }
}
