import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs/internal/Observable';
import { BuildSmartLocker, SMART_LOCKER_TYPE, SmartLocker } from '@api/model/smart-locker';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as qs from 'qs';
import { User } from '@api/model/user';
import { CliqueRetireService, CliqueRetireUser } from './clique-retire.service';
import { HandoverService, HandoverUser } from './handover.service';
import { throwError } from 'rxjs';

export type SmartLockerCreateResidentDTO = Record<'id' | 'name' | 'email' | 'phone', string> & { pcd?: boolean };

export interface SmartLockerUser {
  id: string;
  name: string;
  email: string;
  phone: string;
  pcd?: boolean;
  externalId: string;
  residence: {
    id: string;
    externalId: string;
    name: string;
    block: {
      id?: string;
      name: string;
    };
  };
}

export interface SmartLockerGetResidentsReturnBody {
  smartLockerResidentsCount: number;
  smartLockerResidents: SmartLockerUser[];
  condoResidentsCount: number;
  condoResidents: User[];
}

@Injectable({ providedIn: 'root' })
export class SmartLockerService {
  protected endPoint;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService,
    protected cliqueRetireService: CliqueRetireService,
    protected handoverService: HandoverService
  ) {
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }

  getSmartLockers(condoId: string, params: EcondosQuery): Observable<{ count: number; smartLockers: SmartLocker[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/smart-locker`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        smartLockers: res.body.map(smartLocker => BuildSmartLocker(smartLocker))
      }))
    );
  }

  createSmartLocker(condoId: string, smartLocker: SmartLocker) {
    return this.http.post(`${this.endPoint}${condoId}/smart-locker`, smartLocker);
  }

  updateSmartLocker(condoId: string, smartLockerId: string, smartLocker: SmartLocker) {
    return this.http.put(`${this.endPoint}${condoId}/smart-locker/${smartLockerId}`, smartLocker);
  }

  deleteSmartLocker(condoId: string, smartLockerId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/smart-locker/${smartLockerId}`);
  }

  getResidents(condoId: string, smartLocker: SmartLocker): Observable<SmartLockerGetResidentsReturnBody> {
    switch (smartLocker.type) {
      case SMART_LOCKER_TYPE.CLIQUE_RETIRE:
        return this.cliqueRetireService.getResidents(condoId, smartLocker._id).pipe(
          map(response => {
            const smartLockerResidents: SmartLockerUser[] = response.cliqueRetireResidents.map(resident =>
              buildUserFromCliqueRetireUser(resident)
            );

            return {
              ...response,
              smartLockerResidents,
              smartLockerResidentsCount: response.cliqueRetireResidentsCount
            };
          })
        );

      case SMART_LOCKER_TYPE.HANDOVER:
        return this.handoverService.getResidents(condoId, smartLocker._id).pipe(
          map(response => {
            const smartLockerResidents: SmartLockerUser[] = response.handoverResidents.map(resident => buildUserFromHandoverUser(resident));

            return {
              ...response,
              smartLockerResidents,
              smartLockerResidentsCount: response.handoverResidentsCount
            };
          })
        );
    }
  }

  createResident(
    condoId: string,
    smartLocker: SmartLocker,
    residenceId: string,
    resident: SmartLockerCreateResidentDTO
  ): Observable<SmartLockerUser> {
    switch (smartLocker.type) {
      case SMART_LOCKER_TYPE.CLIQUE_RETIRE:
        return this.cliqueRetireService
          .createResident(condoId, smartLocker._id, residenceId, {
            ...resident,
            cellphone: resident.phone,
            pcd: !!resident.pcd
          })
          .pipe(map(cliqueRetireUser => buildUserFromCliqueRetireUser(cliqueRetireUser)));

      case SMART_LOCKER_TYPE.HANDOVER:
        return this.handoverService
          .createResident(condoId, smartLocker._id, residenceId, resident)
          .pipe(map(handoverUser => buildUserFromHandoverUser(handoverUser)));
    }
  }

  deleteResident(condoId: string, smartLocker: SmartLocker, smartLockerResidentId: string): Observable<User> {
    switch (smartLocker.type) {
      case SMART_LOCKER_TYPE.CLIQUE_RETIRE:
        return this.cliqueRetireService.deleteResident(condoId, smartLocker._id, smartLockerResidentId).pipe(
          map(response => {
            if (response.deleteCliqueRetireResidenceErrorMessage) {
              throwError(
                response.deleteCliqueRetireResidenceErrorMessage.smartLockerMessage ||
                response.deleteCliqueRetireResidenceErrorMessage.message
              );
            }

            if (response.deleteCliqueRetireBlockErrorMessage) {
              throwError(
                response.deleteCliqueRetireBlockErrorMessage.smartLockerMessage || response.deleteCliqueRetireBlockErrorMessage.message
              );
            }

            return new User(response.user);
          })
        );

      case SMART_LOCKER_TYPE.HANDOVER:
        return this.handoverService.deleteResident(condoId, smartLocker._id, smartLockerResidentId).pipe(map(user => new User(user)));
    }
  }
}

const buildUserFromHandoverUser = (handoverUser: HandoverUser): SmartLockerUser => ({
  id: handoverUser.id,
  name: handoverUser.name,
  email: handoverUser.email,
  phone: handoverUser.phone,
  externalId: handoverUser.partnerRefCode,
  residence: {
    id: handoverUser.residence.id,
    externalId: handoverUser.residence.partnerRefCode,
    name: handoverUser.residence.name,
    block: {
      id: handoverUser.block.id,
      name: handoverUser.block.name
    }
  }
});

const buildUserFromCliqueRetireUser = (cliqueRetireUser: CliqueRetireUser): SmartLockerUser => ({
  id: cliqueRetireUser.id,
  name: cliqueRetireUser.name,
  email: cliqueRetireUser.email,
  phone: cliqueRetireUser.cellphone,
  pcd: cliqueRetireUser.pcd,
  externalId: cliqueRetireUser.externalId,
  residence: {
    id: cliqueRetireUser.buildingLevel2.id,
    externalId: cliqueRetireUser.buildingLevel2.externalId,
    name: cliqueRetireUser.buildingLevel2.name,
    block: {
      id: cliqueRetireUser.buildingLevel2.buildingLevel1.id,
      name: cliqueRetireUser.buildingLevel2.buildingLevel1.name
    }
  }
});
