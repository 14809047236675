import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { defaultSystemLocalSettings, SystemLocalSettings } from '@api/model/system-local-settings';
import { BehaviorSubject } from 'rxjs';
import { SessionService } from '@api/service/session.service';

interface Setting {
  key: keyof Omit<SystemLocalSettings, 'updatedAt'>;
  value: any;
}

@Injectable({ providedIn: 'root' })
export class SystemLocalSettingsService {
  private readonly STORAGE_KEY = 'econdos.systemPreferences';

  private _systemLocalSettings: BehaviorSubject<SystemLocalSettings> = new BehaviorSubject(this.getSystemLocalSettings());
  public systemLocalSettings$ = this._systemLocalSettings.asObservable();

  constructor(private sessionService: SessionService) {
    const user = this.sessionService.userValue;

    if (user) {
      this._systemLocalSettings.next(this.getSystemLocalSettings());
    }

    this.sessionService.user$.subscribe(user => {
      this._systemLocalSettings.next(this.getSystemLocalSettings());
    });
  }

  public getSystemLocalSettings(): SystemLocalSettings {
    const settings = localStorage.getItem(this.STORAGE_KEY);

    if (settings) {
      return JSON.parse(settings) as SystemLocalSettings;
    }

    return defaultSystemLocalSettings;
  }

  public saveSettings({ key, value }: Setting): void {
    const systemLocalSettings = this.systemLocalSettings;
    systemLocalSettings[key] = value;
    systemLocalSettings.updatedAt = moment().toISOString();
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(systemLocalSettings));
    this._systemLocalSettings.next(systemLocalSettings);
  }

  public removeSettings({ key }: Pick<Setting, 'key'>): void {
    const systemLocalSettings = this.systemLocalSettings;
    systemLocalSettings[key] = null;
    systemLocalSettings.updatedAt = moment().toISOString();
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(systemLocalSettings));
    this._systemLocalSettings.next(systemLocalSettings);
  }

  get systemLocalSettings(): SystemLocalSettings {
    return this._systemLocalSettings.getValue();
  }
}
