/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Residence } from '../../api/model/interface/residence';
import { ResidenceService } from '../../api/service/residence.service';
import { UtilService } from '../../services/util.service';
import swal from 'sweetalert2';
import { debounceTime, timeout } from 'rxjs/operators';
import { capitalize } from '@api/util/util';
import { Condo } from '@api/model/condo';

@Component({
  selector: 'modal-create-residences',
  templateUrl: 'create.apartments.modal.html',
  styleUrls: ['create.apartments.modal.scss']
})
export class ModalCreateApartmentsComponent {
  @ViewChild('createApartmentsModal', { static: true }) public createApartmentsModal: ModalDirective;

  @Input() existingResidences: Array<any> = new Array<any>();

  @Output() onResidencesCreated = new EventEmitter();
  condo: Condo;
  public configForm: UntypedFormGroup;
  public numberOfBlocks: AbstractControl;
  public numberOfFloors: AbstractControl;
  public numberOfApartments: AbstractControl;
  public mask: AbstractControl;

  public maskForm: UntypedFormGroup;
  public blocksForm: UntypedFormGroup;

  public maskResult: string;

  public blocksFormKeys;

  public currentStep = 0;

  public residencesByBlock = [];
  public residencesByBlockKeys = [];
  public totalOfResidences = 0;

  public groundApartments: boolean;

  constructor(private formBuilder: UntypedFormBuilder, private residenceService: ResidenceService, private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
  }

  initializeMaskForm() {
    const maskValidator = (c: UntypedFormControl) => {
      const falseObj = { invalidMask: { valid: false } };
      const value: string = c.value.toLowerCase() || '';

      if (value.length === 0) {
        return null;
      }

      if ((value.match(new RegExp('n', 'g')) || []).length == 0 || (value.match(new RegExp('a', 'g')) || []).length == 0) {
        return falseObj;
      }

      return null;
    };

    this.maskForm = this.formBuilder.group({
      mask: ['', Validators.compose([maskValidator])]
    });

    this.mask = this.maskForm.controls['mask'];

    this.mask.valueChanges.pipe(debounceTime(400)).subscribe(value => {
      if (this.mask.valid) {
        this.maskResult = Residence.createApartmentIdentification('1', '2', 'A', value.toLowerCase());
      } else {
        this.maskResult = '';
      }
    });
  }

  initializeConfigForm() {
    this.configForm = this.formBuilder.group({
      numberOfBlocks: ['', Validators.compose([Validators.required, this.numericValidator])],
      numberOfFloors: ['', Validators.compose([Validators.required, this.numericValidator])],
      numberOfApartments: ['', Validators.compose([Validators.required, this.numericValidator])]
    });

    this.numberOfBlocks = this.configForm.get('numberOfBlocks');
    this.numberOfFloors = this.configForm.get('numberOfFloors');
    this.numberOfApartments = this.configForm.get('numberOfApartments');
  }

  nextStep() {
    if (this.currentStep === 1) {
      if (this.configForm.invalid) {
        for (const key of Object.keys(this.configForm.controls)) {
          this.configForm.controls[key].markAsTouched();
        }
        return;
      }
      this.generateBlocksForm(this.numberOfBlocks.value);
    }

    if (this.currentStep === 2) {
      if (this.blocksForm.invalid || this.mask.invalid) {
        for (const key of Object.keys(this.blocksForm.controls)) {
          this.blocksForm.controls[key].markAsTouched();
        }
        return;
      }
      this.generateApartemtns();
    }

    if (this.currentStep == 3) {
      this.createApartments();
      return;
    }
    this.currentStep++;
  }

  previousStep() {
    if (this.currentStep === 1) {
      return;
    }
    if (this.currentStep === 3) {
      this.residencesByBlockKeys = [];
      this.residencesByBlock = [];
    }
    this.currentStep--;
  }

  generateBlocksForm(numberOfBlocks: number) {
    const formControls = {};
    if (numberOfBlocks == 1) {
      formControls[0] = new UntypedFormControl('');
    } else {
      for (let i = 0; i < numberOfBlocks; i++) {
        formControls[i] = new UntypedFormControl('', Validators.required);
      }
    }

    this.blocksForm = this.formBuilder.group(formControls);
    this.blocksFormKeys = Object.keys(this.blocksForm.controls);
  }

  generateApartemtns() {
    const blocks = Object.keys(this.blocksForm.controls).map(key => this.blocksForm.controls[key].value);
    const floors = this.numberOfFloors.value;
    const apartments = this.numberOfApartments.value;

    const residences = [];

    for (const block of blocks) {
      const initialFloor = this.groundApartments ? 0 : 1;
      const maxFloors = this.groundApartments ? floors : floors + 1;

      for (let floor = initialFloor; floor < maxFloors; floor++) {
        for (let apartment = 1; apartment <= apartments; apartment++) {
          const residence = {
            number: `${floor}${apartment}`,
            block: block.toUpperCase(),
            identification: Residence.createApartmentIdentification(apartment.toString(), floor.toString(), block, this.mask.value),
            type: 'APARTMENT'
          };
          residences.push(residence);
        }
      }
    }
    this.separateByBlock(residences);
  }

  separateByBlock(residences) {
    residences = this.removeExistingResidences(residences);
    residences.forEach(res => {
      if (this.residencesByBlock.indexOf(res.block.toUpperCase()) == -1) {
        this.residencesByBlock[res.block.toUpperCase()] = [];
      }
    });

    residences.forEach(res => {
      this.residencesByBlock[res.block.toUpperCase()].push(res);
    });

    // Set fraction based on number of existing residences and new residences
    const numberOfTotalResidences = (this.existingResidences ? this.existingResidences.length : 0) + (residences.length || 0);
    residences.forEach(res => (res.fraction = 1 / (numberOfTotalResidences || 1)));

    this.residencesByBlockKeys = Object.keys(this.residencesByBlock);
    this.residencesByBlockKeys.sort();
  }

  removeExistingResidences(residences) {
    const residencesToAdd = [];
    for (const res of residences) {
      const exists = this.existingResidences.find(searchRes => {
        return (searchRes.number == res.number && searchRes.block == res.block) || searchRes.identification == res.identification;
      });
      if (!exists) {
        residencesToAdd.push(res);
      }
    }
    this.totalOfResidences = residencesToAdd.length;
    return residencesToAdd;
  }

  removeApartment(index, listKey) {
    this.residencesByBlock[listKey].splice(index, 1);
    this.totalOfResidences--;
  }

  closeModal(): void {
    this.createApartmentsModal.hide();
  }

  showModal() {
    this.currentStep = 1;
    this.initializeConfigForm();
    this.initializeMaskForm();
    this.createApartmentsModal.show();
  }

  createApartments() {
    const condo = this.utilService.getLocalCondo();
    const apartments = [];

    for (const key of this.residencesByBlockKeys) {
      for (const res of this.residencesByBlock[key]) {
        apartments.push(res);
      }
    }

    if (apartments.length) {
      swal({
        type: 'question',
        title: `Criar ${this.condo?.customLabels?.residence?.plural || 'unidades'}`,
        text: `Deseja continuar? Observação: Todas(os) ${
          this.condo?.customLabels?.residence?.plural || 'unidades'
        } já registradas NÃO serão criadas novamente`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.residenceService
            .createResidenceOnCondo(condo._id, apartments)
            .pipe(timeout(30000))
            .toPromise()
            .catch(err => {
              return Promise.reject(
                `Não foi possível criar as(os) ${this.condo?.customLabels?.residence?.plural || 'unidades'}, tente novamente...`
              );
            });
        }
      }).then(
        residencesCreated => {
          this.closeModal();
          this.onResidencesCreated.emit();
          swal({
            type: 'success',
            title: `${capitalize(this.condo?.customLabels?.residence?.plural) || 'Unidades'} criadas(os) com sucesso!`
          });
        },
        err => {}
      );
    } else {
      swal({
        title: `Nenhuma ${this.condo?.customLabels?.residence?.singular || 'unidade'} criada(o)`,
        text: `Todas estas ${this.condo?.customLabels?.residence?.plural || 'unidades'} já estão registradas(os)`,
        cancelButtonColor: '#f53d3d'
      });
    }
  }

  numericValidator(c: UntypedFormControl) {
    const falseObj = {
      numericValidator: {
        valid: false
      }
    };

    const number = c.value;

    if (number && !isNaN(parseInt(number, 10)) && number % 1 === 0 && number > 0) {
      return null;
    }
    return falseObj;
  }
}
