import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BuildKey, Key } from '../model/key';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';

@Injectable()
export class KeyService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  getKeys(condoId: string, params: Array<{ [key: string]: string }> = []): Observable<{ count: number; keys: Key[] }> {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/keys`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            keys: res.body.map(key => BuildKey(key))
          };
        })
      );
  }

  createKey(condoId: string, key: Key) {
    return this.http.post(`${this.endPoint}${condoId}/keys`, key);
  }

  updateKey(condoId: string, keyId: string, key: Key) {
    return this.http.put(`${this.endPoint}${condoId}/keys/${keyId}`, key);
  }

  borrowKey(condoId: string, keyId: string, data: { person: string; residence?: string; obs?: string }) {
    return this.http.put(`${this.endPoint}${condoId}/keys/${keyId}/borrow`, data);
  }

  returnKey(condoId: string, keyId: string, obs: string = '') {
    return this.http.put(`${this.endPoint}${condoId}/keys/${keyId}/return`, { obs });
  }

  getKeyLogs(condoId: string, keyId: string, params: EcondosQuery = {}): Observable<Key[]> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .get(`${this.endPoint}${condoId}/keys/${keyId}/history`, { params: httpParams })
      .pipe(map((data = []) => data as Key[]));
  }

  getKeyHistory(condoId: string, keyId: string, params: Array<{ [key: string]: string }> = []) {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }
    return this.http.get(`${this.endPoint}${condoId}/keys/${keyId}/history`, { params: httpParams });
  }

  deleteKey(condoId: string, keyId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/keys/${keyId}`);
  }

  getKeyById(condoId: string, keyId: string): Observable<Key> {
    return this.http.get(`${this.endPoint}${condoId}/keys/${keyId}`).pipe(map(key => BuildKey(key)));
  }
}
