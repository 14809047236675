import { Injectable } from '@angular/core';
import { CacheService } from '@api/service/cache/cache.service';
import { EcondosQuery } from '@api/model/query';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ResidenceService } from '@api/service/residence.service';
import { ResidenceServiceV2 } from '@api/serviceV2/residence.service';
import { ResidenceServiceV3 } from '@api/serviceV3/residence.service';
import { CondoService } from '@api/service/condo.service';
import { CondoServiceV2 } from '@api/serviceV2/condo.service';
import { User } from '@api/model/user';
import { Occurrence } from '@api/model/interface/occurrence';
import { OccurrenceService } from '@api/service/occurrence.service';
import { OccurrenceServiceV2 } from '@api/serviceV2/occurrence.service';

@Injectable({ providedIn: 'root' })
export class CachedVisitorsRequestsService {
  constructor(
    private cacheService: CacheService,
    private condoServiceV2: CondoServiceV2,
    private occurrenceServiceV2: OccurrenceServiceV2
  ) {}

  private buildCachedResponse<T>(key: string, observable: Observable<T>): Observable<T> {
    let cachedObservable$ = this.cacheService.getValue(key);
    if (!cachedObservable$) {
      cachedObservable$ = observable.pipe(shareReplay(1));
      this.cacheService.setValue(key, cachedObservable$);
    }
    return cachedObservable$;
  }

  private buildCacheKey(method: string, query: EcondosQuery) {
    const stringfield = JSON.stringify(query);
    return `${method}:${stringfield}`;
  }

  getUserById(condoId: string, query: EcondosQuery = {}) {
    const key = this.buildCacheKey('users', query);
    return this.buildCachedResponse<{ count: any; users: any }>(
      key,
      this.condoServiceV2
        .getCondoResidents(condoId, query)
        .pipe(map(response => ({ count: response.count, users: response.users.map(user => new User(user)) })))
    );
  }

  getOccurrences(condoId: string, params: EcondosQuery) {
    const key = this.buildCacheKey('occurrences', params);
    return this.buildCachedResponse<{ count: number; occurrences: Occurrence[] }>(key, this.occurrenceServiceV2.get(condoId, params));
  }
}
