import { User } from '@api/model/user';

export interface Group {
  _id?: string;
  name?: string;
  description?: string;
  icon?: string;
  createdBy?: User;
  updatedBy?: User;
  createdAt?: string;
  updatedAt?: string;
}

export const buildGroup = group => {
  return {
    _id: typeof group == 'string' ? group : group._id,
    name: group?.name || '',
    description: group?.description || '',
    icon: group?.icon || '',
    createdBy: group?.createdBy ? new User(group.createdBy) : null,
    updatedBy: group?.updatedBy ? new User(group.updatedBy) : null,
    createdAt: group.createdAt || '',
    updatedAt: group.updatedAt || ''
  };
};
