import { UntypedFormControl } from '@angular/forms';

export function EmailValidator(c: UntypedFormControl) {
  let falseObj = {
    emailValidator: {
      valid: false
    }
  };

  let email = c.value;

  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(email)) return null;

  return falseObj;
}
