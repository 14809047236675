/**
 * Created by Rafael on 25/02/2019.
 */
import { Component, Input } from '@angular/core';
import { User } from '@api/model/user';
import { UtilService } from '../../services/util.service';
import { Condo } from '../../api/model/condo';
import { capitalize } from '@api/util/util';
import { ThemeService } from '../../theme';
import { take } from 'rxjs/operators';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { SessionService } from '@api/service/session.service';

declare function require(moduleName: string): any;

const { version: APP_VERSION } = require('../../../../package.json');

@Component({
  selector: 'app-side-menu',
  templateUrl: 'side-menu.component.html',
  styleUrls: ['side-menu.component.scss']
})
export class SideMenuComponent {
  @Input()
  show: Boolean = false;

  user: User;
  condo;

  menu: MenuItem[] = [];

  public APP_VERSION = APP_VERSION;

  public customerSupportUrl = 'https://econdos.freshdesk.com';

  constructor(
    private utilService: UtilService,
    private sessionService: SessionService,
    public themeService: ThemeService
  ) {
    this.user = this.utilService.getLocalUser();
    if (this.user) {
      this.condo = this.user.defaultCondo;
      this.createMenu(this.user, this.condo);
    }

    this.themeService
      .getActiveTheme()
      .pipe(take(1))
      .subscribe(theme => (this.customerSupportUrl = theme.customerSupportUrl || this.customerSupportUrl));

    this.sessionService.user$.subscribe(user => {
      // mode will be null the first time it is created, so you need to igonore it when null
      if (user !== null) {
        this.user = user;
        this.condo = this.user.defaultCondo;
        this.createMenu(this.user, this.condo);
      }
    });
  }

  buildFeaturesSubItems(user: User, condo: Condo): MenuItem[] {
    const isAdmin = user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id);
    const isGatekeeper = user.isGatekeeperOnCondo(condo._id);
    const isJanitor = user.isJanitorOnCondo(condo._id);
    const isUser = user.isUserOnCondo(condo._id) && !isJanitor;

    const allSubItems: Array<MenuItem & { enabled: boolean }> = [
      {
        link: '/features/device-requests',
        text: 'Solicitações de dispositivos',
        icon: 'fa-microchip',
        subItems: [],
        enabled: !condo.isDeviceRequestDisabled() && (isAdmin || isGatekeeper)
      },
      {
        link: '/features/documents',
        text: 'Documentos',
        icon: 'fa-folder',
        subItems: [],
        enabled: !condo.isDocumentsDisabled() && condo.hasDocumentsAccess() && (isAdmin || isUser)
      },
      {
        link: '/features/faq',
        text: 'Perguntas frequentes',
        icon: 'fa-question-circle',
        subItems: [],
        enabled: !condo.isCondoFaqDisabled() && user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.faq.view })
      },
      {
        link: '/features/classificados',
        text: 'Classificados',
        icon: 'fa-shopping-cart',
        subItems: [],
        enabled: !condo.isClassificadosDisabled() && condo.hasAdAccess() && (isAdmin || isUser)
      },
      {
        link: '/features/rating',
        text: 'Avaliações',
        icon: 'fa-star',
        subItems: [],
        enabled: !condo.isCondoRatingDisabled() && (isAdmin || isGatekeeper || isUser)
      },
      {
        link: '/features/lost-and-founds',
        text: 'Achados e perdidos',
        icon: 'fa-search',
        subItems: [],
        enabled:
          !condo.isLostAndFoundsDisabled() && user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.lostAndFounds.view })
      },
      {
        link: '/features/asset-management',
        text: 'Controle de ativos',
        icon: 'fa-archive',
        subItems: [],
        enabled: condo.isAssetManagementEnabled() && (isAdmin || isGatekeeper || isJanitor)
      },
      {
        link: '/features/statistics',
        text: 'Estatísticas',
        icon: 'fa-line-chart',
        subItems: [],
        enabled: isAdmin
      },
      {
        link: '/features/event-announcement-board',
        text: 'Quadro de anúncios de eventos',
        icon: 'fa-desktop',
        subItems: [],
        enabled:
          condo.isEventAnnouncementBoardEnabled() &&
          user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.eventAnnouncementBoard.view })
      },
      {
        link: '/features/admin-tools',
        text: 'Ferramentas administrativas',
        icon: 'fa-shield',
        subItems: [],
        enabled: user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.personDeletionProcess.view })
      },
      {
        link: '/features/highlights',
        text: 'Destaques e informativos',
        icon: 'fa-bookmark',
        subItems: [],
        enabled: user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.highlights.view })
      }
    ];

    return allSubItems.filter(subItem => subItem.enabled);
  }

  createMenu(user: User, condo: Condo) {
    if (user && condo) {
      const hasAccessLiberationPermission = this.user.getPermissionValue({
        condoId: condo._id,
        permission: PERMISSIONS.accessLiberation.view
      });

      const menu = [];
      // Dashboard
      if (user.isAdmin() || user.isOwner()) {
        const dashboard: MenuItem = {
          text: 'Dashboard',
          icon: 'fa-bar-chart',
          link: '/dashboard',
          subItems: []
        };
        menu.push(dashboard);
      }

      // Feed
      const feed: MenuItem = {
        text: user.isAdmin() || user.isOwner() ? 'Feed' : 'Página inicial',
        icon: 'fa-list-alt',
        link: '/feed',
        subItems: []
      };
      menu.push(feed);

      // FEATURES SUB ITEMS
      const featuresSubItems: MenuItem[] = this.buildFeaturesSubItems(user, condo) || [];

      // FEATURES
      if (featuresSubItems.length) {
        const features: MenuItem = {
          text: 'Funcionalidades',
          icon: 'fa-cogs',
          link: '/features',
          className: 'word-break',
          subItems: featuresSubItems
        };
        menu.push(features);
      }

      // Reservas
      if (!condo.isReservationsDisabled() && (user.isAdmin() || user.isOwner() || user.isGatekeeper() || user.isUser())) {
        const reservations: MenuItem = {
          text: 'Reservas',
          icon: 'fa-calendar',
          link: '/reservations',
          subItems: [
            { text: 'Locais para reserva', link: '/reservations', icon: 'fa-calendar', subItems: [] },
            { text: 'Histórico de reservas', link: '/reservations/reservationHistory', icon: 'fa-list', subItems: [] }
          ]
        };
        menu.push(reservations);
      }

      // Portaria
      if (
        (user.isAdmin() || user.isOwner() || user.isGatekeeper()) &&
        !(
          condo.isGateNotebookDisabled() &&
          condo.isDeliveriesDisabled() &&
          condo.isAccessLiberationDisabled() &&
          condo.isAccessControlDisabled() &&
          condo.isKeysDisabled()
        )
      ) {
        const gate: MenuItem = {
          text: 'Portaria',
          icon: 'fa-building-o',
          link: '/gate',
          subItems: []
        };
        if (!condo.isGateNotebookDisabled()) {
          gate.subItems.push({ text: 'Livro', link: '/gate/notebook', icon: 'fa-book', subItems: [] });
        }

        if (!condo.isDeliveriesDisabled()) {
          gate.subItems.push({ text: 'Encomendas', link: '/gate/deliveries', icon: 'fa-archive', subItems: [] });
        }

        if (!condo.isKeysDisabled()) {
          gate.subItems.push({ text: 'Chaves', link: '/gate/keys', icon: 'fa-key', subItems: [] });
        }

        if (!condo.isAccessLiberationDisabled() && hasAccessLiberationPermission) {
          gate.subItems.push({
            text: `${capitalize(condo?.customLabels?.visitor?.plural) || 'Visitantes'} autorizados`,
            link: '/gate/permissions',
            icon: 'fa-id-card-o',
            subItems: []
          });
        }

        if (!condo.isAccessControlDisabled() && hasAccessLiberationPermission) {
          gate.subItems.push({ text: 'Controle de acesso', link: '/gate/access', icon: 'fa-exchange', subItems: [] });
          gate.subItems.push({
            text: `${capitalize(condo?.customLabels?.visitor?.plural) || 'Visitantes'} ativos`,
            link: '/gate/active-visitors',
            icon: 'fa-users',
            subItems: []
          });
          gate.subItems.push({
            text: `${capitalize(condo?.customLabels?.visitor?.plural) || 'Visitantes'} cadastrados`,
            link: '/gate/visitants',
            icon: 'fa-address-book-o',
            subItems: []
          });
        }
        menu.push(gate);
      } else if (user.isJanitor() && !condo.isDeliveriesDisabled()) {
        const gate: MenuItem = {
          text: 'Portaria',
          icon: 'fa-building-o',
          link: '/gate/deliveries',
          subItems: [{ text: 'Encomendas', link: '/gate/deliveries', icon: 'fa-archive', subItems: [] }]
        };
        menu.push(gate);
      }

      // Condomínio

      const myCondoSubItems = [];
      if (user.isGatekeeper() || user.isOwner() || user.isAdmin()) {
        myCondoSubItems.push({
          text: capitalize(this.condo?.customLabels?.condo?.singular),
          link: '/condo/condos',
          icon: 'fa-building',
          subItems: []
        });
      }
      if (user.isGatekeeper() || user.isOwner() || user.isAdmin()) {
        myCondoSubItems.push({
          text: capitalize(this.condo?.customLabels?.residence?.plural) || 'Unidades',
          link: '/condo/residences',
          icon: 'fa-home',
          subItems: []
        });
      }
      if (user.isGatekeeper() || user.isOwner() || user.isAdmin()) {
        myCondoSubItems.push({ text: 'Usuários', link: '/condo/residents', icon: 'fa-users', subItems: [] });
      }

      if (user.isOwner() || user.isAdmin()) {
        myCondoSubItems.push({ text: 'Fornecedores', link: '/condo/suppliers', icon: 'fa-handshake-o', subItems: [] });
      }

      if (!condo.isDependentsDisabled() && (user.isGatekeeper() || user.isOwner() || user.isAdmin())) {
        if (condo.isBusinessCondo()) {
          myCondoSubItems.push({
            text: capitalize(this.condo?.customLabels?.dependent?.plural) || 'Funcionários',
            link: '/condo/dependents',
            icon: 'fa-id-badge',
            subItems: []
          });
        } else {
          myCondoSubItems.push({
            text: capitalize(this.condo?.customLabels?.dependent?.plural) || 'Dependentes',
            link: '/condo/dependents',
            icon: 'fa-child',
            subItems: []
          });
        }
      }

      if (!condo.isConstructionsDisabled() && (this.user.isGatekeeper() || this.user.isAdmin() || this.user.isOwner())) {
        myCondoSubItems.push({ text: 'Obras', link: '/condo/constructions', icon: 'fa-exclamation-triangle', subItems: [] });
      }

      if (!condo.isPetsDisabled() && (user.isGatekeeper() || user.isOwner() || user.isAdmin())) {
        myCondoSubItems.push({ text: 'Pets', link: '/condo/pets', icon: 'fa-paw', subItems: [] });
      }

      if (user.isGatekeeper() || user.isOwner() || user.isAdmin()) {
        myCondoSubItems.push({ text: 'Veículos', link: '/condo/vehicles', icon: 'fa-car', subItems: [] });
      }

      if (user.isGatekeeper() || user.isOwner() || user.isAdmin()) {
        myCondoSubItems.push({ text: 'Vagas', link: '/condo/parking-spots', icon: 'parking', subItems: [] });
      }

      if ((user.isOwner() || user.isAdmin()) && this.condo.selfConfig === 'ENABLED') {
        myCondoSubItems.push({ text: 'Configurações', link: '/condo/settings', icon: 'fa-cog', subItems: [] });
      }

      menu.push({
        text: capitalize(this.condo?.customLabels?.condo?.singular),
        icon: 'fa-hospital-o',
        link: '/condo',
        className: 'word-break',
        subItems: myCondoSubItems
      });

      // Minhas Residências
      if (user.isUser() || user.isAdmin() || user.isOwner()) {
        const residence: MenuItem = {
          text: 'Minhas unidades',
          icon: 'fa-home',
          link: '/residences',
          subItems: []
        };
        menu.push(residence);
      }

      // Financeiro
      if (!condo.isFinanceDisabled() && ((user.isUser() && condo.anyoneViewFinance()) || user.isAdmin() || user.isOwner())) {
        const finance: MenuItem = {
          text: 'Financeiro',
          icon: 'fa-usd',
          link: '/finance/dashboard',
          subItems: [
            { text: 'Resumo financeiro', icon: 'fa-file-text', link: '/finance/dashboard', subItems: [] },
            { text: 'Movimentações', icon: 'fa-exchange', link: '/finance/transactions', subItems: [] }
            // {text: 'Orçamentos', icon: 'fa-money', link: '/finance/budget', subItems: []},
          ]
        };
        menu.push(finance);
      }

      // Manutenção
      if (
        user.getPermissionValue({
          condoId: condo._id,
          permission: PERMISSIONS.maintenance.view
        }) &&
        !condo.isMaintenanceDisabled()
      ) {
        const maintenance: MenuItem = {
          text: 'Manutenção',
          icon: 'fa-wrench',
          link: '/maintenance/plans',
          subItems: []
        };
        menu.push(maintenance);
      }

      // Chamados de Manutenção
      if (
        !condo.isMaintenanceTicketsDisabled() &&
        user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.maintenanceTickets.view })
      ) {
        const maintenanceTickets: MenuItem = {
          text: 'Chamados de manutenção',
          icon: 'fa-ticket',
          link: '/maintenance/tickets',
          subItems: []
        };
        menu.push(maintenanceTickets);
      }

      // Hardware
      const hasViewAlarmCentralPermission = user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.alarmCentrals.view });
      if (user.isOwner() || user.isAdmin() || user.isGatekeeper() || (hasViewAlarmCentralPermission && !condo.isAlarmCentralDisabled())) {
        const hardware: MenuItem = {
          text: 'Hardware',
          icon: 'fa-microchip',
          link: '/hardware',
          subItems: []
        };
        menu.push(hardware);
      }

      // Relatórios
      if (user.isOwner() || user.isAdmin() || user.isGatekeeper()) {
        const reports: MenuItem = {
          text: 'Relatórios',
          icon: 'fa-files-o',
          link: '/reports',
          subItems: []
        };
        menu.push(reports);
      }

      // MORE OPTIONS
      const moreOptions: MenuItem = {
        text: 'Mais opções',
        icon: 'fa-ellipsis-h',
        link: '/more-options',
        subItems: [{ text: 'Proteção de dados', icon: 'fa-shield', link: '/more-options/data-security', subItems: [] }]
      };
      if (user.isOwner() || user.isAdmin() || user.isGatekeeper() || user.isJanitor()) {
        const downloads = { text: 'Downloads', icon: 'fa-download', link: '/more-options/downloads', subItems: [] };
        const systemStatus = { text: 'Diagnóstico do sistema', icon: 'fa-signal', link: '/more-options/services-status', subItems: [] };
        const lprDebugger = { text: 'Testador LPR alphadigi', icon: 'fa-car', link: '/more-options/lpr-tester', subItems: [] };
        moreOptions.subItems.push(downloads, systemStatus, lprDebugger);
      }

      if (user.isOwner() || user.isAdmin()) {
        const imports: MenuItem = {
          text: 'Importações',
          icon: 'fa-upload',
          link: '/more-options/imports',
          subItems: []
        };
        moreOptions.subItems.push(imports);
      }

      if (user.isOwner()) {
        const imports: MenuItem = {
          text: 'Customizar tema',
          icon: 'fa-paint-brush',
          link: '/more-options/customize-theme',
          subItems: []
        };
        moreOptions.subItems.push(imports);
      }

      menu.push(moreOptions);

      this.menu = menu;
    } else {
      this.menu = [];
    }
  }
}

interface MenuItem {
  text: string;
  link: string;
  icon: string;
  className?: string;
  subItems: MenuItem[];
}
