import { User } from './user';
import { Residence } from './interface/residence';
import { Condo } from './condo';
import { CondoVehicle } from './condo.vehicle';
import { File } from './file';
import { ResidenceBuilder } from './residence/residence.builder';
import { DEVICE_TYPES, DEVICE_TYPES_LABEL } from './hardware/device';

export enum DEVICES_REQUEST_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED'
}

export enum DEVICES_REQUEST_STATUS_LABEL {
  PENDING = 'Pendente',
  APPROVED = 'Aprovado',
  REJECTED = 'Recusado',
  CANCELED = 'Cancelado'
}

export enum DEVICES_HARDWARES {
  LINEAR = 'LINEAR',
  ECONDOS = 'ECONDOS',
  CONTROL_ID = 'CONTROL_ID',
  HIKVISION = 'HIKVISION',
  UTECH = 'UTECH',
  INTELBRAS = 'INTELBRAS'
}

export enum DEVICES_HARDWARES_LABEL {
  LINEAR = 'Linear',
  ECONDOS = 'eCondos',
  CONTROL_ID = 'Control ID',
  HIKVISION = 'HikVision',
  UTECH = 'uTech',
  INTELBRAS = 'Intelbras'
}

export class DevicesRequest {
  _id?: string;
  id?: string;
  condo?: Condo;
  residence?: Residence;
  user?: User;
  condoVehicle?: CondoVehicle;
  picture?: File;
  hardware?: DEVICES_HARDWARES;
  hardware_label?: DEVICES_HARDWARES_LABEL;
  quantity?: number;
  type?: DEVICE_TYPES;
  type_label?: DEVICE_TYPES_LABEL;
  requestDescription?: String;
  requestStatus?: DEVICES_REQUEST_STATUS;
  requestStatus_label?: DEVICES_REQUEST_STATUS_LABEL;
  requestStatusDescription?: string;
  requestStatusUpdatedBy?: User;
  createdAt?: string;
  createdBy?: User;
  updatedAt?: string;
  updatedBy?: User;

  constructor(devicesRequest) {
    if (typeof devicesRequest == 'string') {
      this._id = devicesRequest;
    } else if (devicesRequest) {
      this._id = devicesRequest._id || '';
      this.id = devicesRequest._id || '';
      this.condo = (devicesRequest.condo && new Condo(devicesRequest.condo)) || null;
      this.residence = (devicesRequest.residence && ResidenceBuilder.build(devicesRequest.residence)) || null;
      this.user = (devicesRequest.user && new User(devicesRequest.user)) || null;
      this.condoVehicle = (devicesRequest.condoVehicle && new CondoVehicle(devicesRequest.condoVehicle)) || null;
      this.picture = devicesRequest.picture ? new File(devicesRequest.picture) : null;
      this.hardware = (devicesRequest.hardware && DEVICES_HARDWARES[devicesRequest.hardware]) || null;
      this.hardware_label = (devicesRequest.hardware && DEVICES_HARDWARES_LABEL[devicesRequest.hardware]) || null;
      this.quantity = devicesRequest.quantity || null;
      this.type = (devicesRequest.type && DEVICE_TYPES[devicesRequest.type]) || null;
      this.type_label = (devicesRequest.type && DEVICE_TYPES_LABEL[devicesRequest.type]) || null;
      this.requestDescription = devicesRequest.requestDescription || '';
      this.requestStatus = (devicesRequest.requestStatus && DEVICES_REQUEST_STATUS[devicesRequest.requestStatus]) || null;
      this.requestStatus_label = (devicesRequest.requestStatus && DEVICES_REQUEST_STATUS_LABEL[devicesRequest.requestStatus]) || null;
      this.requestStatusDescription = devicesRequest.requestStatusDescription || '';
      this.requestStatusUpdatedBy = (devicesRequest.requestStatusUpdatedBy && new User(devicesRequest.requestStatusUpdatedBy)) || null;
      this.createdBy = (devicesRequest.createdBy && new User(devicesRequest.createdBy)) || null;
      this.createdAt = devicesRequest.createdAt || null;
      this.updatedBy = (devicesRequest.updatedBy && new User(devicesRequest.updatedBy)) || null;
      this.updatedAt = devicesRequest.updatedAt || null;
    }
  }
}
