import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';

@Component({
  selector: 'app-modal-import-devices',
  templateUrl: 'modal-add-incontrol-user.component.html',
  styleUrls: ['./modal-add-incontrol-user.component.scss']
})
export class ModalAddIncontrolUserComponent implements OnInit, OnDestroy {
  callbacks: { success?: Function; error?: (err) => void };

  private subscriptions: Subscription = new Subscription();

  constructor(public bsModalRef: BsModalRef, private intelbrasService: IntelbrasIncontrolService) {}

  itemsPerPage = 10;
  p = 1;

  user;
  inControlUserList;

  isLoading = false;
  syncingDevices = {};
  SYNC_STATUS = {
    SYNCING: 'SYNCING',
    SYNCED: 'SYNCED',
    ERROR: 'ERROR'
  };

  async ngOnInit() {
    if (this.user) {
      this.inControlUserList = await this.searchInControlUser(this.user);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async searchInControlUser(user) {
    this.isLoading = true;
    return await this.intelbrasService.getUser(user).then(res => {
      this.isLoading = false;
      return res;
    });
  }

  async syncUserInControl(inControlUser) {
    this.syncingDevices[inControlUser.id] = this.SYNC_STATUS.SYNCING;
    const userToSync = {
      id: inControlUser.id,
      firstName: inControlUser.pessoa?.nome_completo,
      lastName: '',
      _id: this.user._id,
      residenceToSync: {
        block: inControlUser.departamento?.nome,
        number: inControlUser.local_especifico
      }
    };
    await this.intelbrasService
      .saveUser(userToSync, userToSync.residenceToSync, inControlUser.pessoa?.grupo?.nome_grupo)
      .then(async res => {
        this.inControlUserList = await this.searchInControlUser(this.user);
        this.syncingDevices[inControlUser.id] = this.SYNC_STATUS.SYNCED;
      });
  }

  async unsyncUserInControl(inControlUser) {
    this.syncingDevices[inControlUser.id] = this.SYNC_STATUS.SYNCING;
    const userToSync = {
      id: inControlUser.id,
      firstName: inControlUser.pessoa?.nome_completo,
      lastName: '',
      _id: null,
      residenceToSync: {
        block: inControlUser.departamento?.nome,
        number: inControlUser.local_especifico
      }
    };
    await this.intelbrasService
      .saveUser(userToSync, userToSync.residenceToSync, inControlUser.pessoa?.grupo?.nome_grupo)
      .then(async res => {
        this.inControlUserList = await this.searchInControlUser(this.user);
        this.syncingDevices[inControlUser.id] = this.SYNC_STATUS.SYNCED;
      });
  }

  done() {
    if (this.callbacks && this.callbacks.success && this.inControlUserList) {
      this.callbacks.success(
        this.inControlUserList.filter(e => e.campos_personalizados?.campo_5 && e.campos_personalizados?.campo_5 === this.user._id)
      );
    }
    this.bsModalRef.hide();
  }
}
