import { Residence } from './interface/residence';
import { ResidenceBuilder } from './residence/residence.builder';
import { Contact } from './interface/contact';
import { CondoContact } from './contact/condo.contact';
import { Occurrence } from './interface/occurrence';
import { User } from './user';
import { UserContact } from './contact/user.contact';
import { Condo } from './condo';
import { BackObject } from './interface/back.object';
import { CondoVehicle } from './condo.vehicle';

export class Access implements BackObject {
  static STATUS = {
    APPROVED: 'APPROVED',
    CANCELED: 'CANCELED',
    PENDING: 'PENDING'
  };
  static STATUS_LABEL = {
    APPROVED: 'Aprovado',
    CANCELED: 'Cancelado',
    PENDING: 'Pendente'
  };
  static SOURCES = {
    OCCURRENCE: 'OCCURRENCE',
    GATE: 'GATE'
  };

  static SOURCES_LABEL = {
    OCCURRENCE: 'Liberação',
    GATE: 'Portaria'
  };

  static TYPES = Contact.TYPES;
  static TYPES_LABEL = Contact.TYPES_LABEL;

  public _id: string;
  public condoContact: CondoContact;
  public userContact: UserContact;
  public type: string = Contact.TYPES.VISITOR;
  public source: string = Access.SOURCES.GATE;
  public condo: Condo;
  public occurrence: Occurrence;
  public startDate = null;
  public endDate = null;
  public inDate = null;
  public outDate = null;
  public status = '';
  public residence: Residence;
  public condoVehicle: CondoVehicle;
  public createdBy: User = null;
  public updatedBy: User = null;
  public approvedBy: User = null;
  public approvedByName = '';
  public obs = '';
  public place = '';
  public user: User = null;

  constructor(access?) {
    if (access) {
      this._id = access._id || this._id;
      this.condoContact = access.condoContact ? new CondoContact(access.condoContact) : this.condoContact;
      this.userContact = access.userContact ? new UserContact(access.userContact) : this.userContact;
      this.type = access.type || Access.TYPES.VISITOR;
      this.source = access.source || '';
      this.condo = access.condo || this.condo;
      this.inDate = access.inDate || this.inDate;
      this.outDate = access.outDate || this.outDate;
      this.startDate = access.startDate || this.startDate;
      this.endDate = access.endDate || this.endDate;
      this.occurrence = access.occurrence || this.occurrence;
      this.status = access.status || this.status;
      this.residence = access.residence ? ResidenceBuilder.build(access.residence) : this.residence;
      this.condoVehicle = access.condoVehicle ? new CondoVehicle(access.condoVehicle) : this.condoVehicle;
      this.obs = access.obs || this.obs;
      this.createdBy = access.createdBy ? new User(access.createdBy) : this.createdBy;
      this.updatedBy = access.updatedBy ? new User(access.updatedBy) : this.updatedBy;
      this.approvedBy = access.approvedBy ? new User(access.approvedBy) : this.approvedBy;
      this.approvedByName = access.approvedByName || '';
      this.place = access.place || '';
      this.user = access.user ? new User(access.user) : this.user;
    }
  }

  createBackObject(extraParams?: { [key: string]: any }): {} {
    const access: any = {
      type: this.type,
      source: this.source,
      status: this.status,
      obs: this.obs,
      approvedBy: this.approvedBy && this.approvedBy.id ? this.approvedBy.id : this.approvedBy,
      approvedByName: this.approvedByName,
      ...extraParams
    };

    if (this.place) {
      access.local = this.place;
    }
    if (this.inDate) {
      access.inDate = this.inDate;
    }
    if (this.outDate) {
      access.outDate = this.outDate;
    }
    if (this.condoContact) {
      access.condoContact = this.condoContact.id ? this.condoContact.id : this.condoContact;
    }
    if (this.userContact) {
      access.userContact = this.userContact.id ? this.userContact.id : this.userContact;
    }
    if (this.residence) {
      access.residence = this.residence.id ? this.residence.id : this.residence;
    }
    if (this.occurrence) {
      access.occurrence = this.occurrence.id ? this.occurrence.id : this.occurrence;
    }
    if (this.condoVehicle) {
      access.condoVehicle = this.condoVehicle.id ? this.condoVehicle.id : this.condoVehicle;
    }
    if (this.user) {
      access.user = this.user.id ? this.user.id : this.user;
    }

    return access;
  }

  isCanceled() {
    return this.status == Access.STATUS.CANCELED;
  }

  isApproved() {
    return this.status == Access.STATUS.APPROVED;
  }

  isInAccess() {
    return !!this.inDate;
  }

  isOutAccess() {
    return !!this.outDate;
  }

  isCreatedAsGatekeeper(): boolean {
    return !this.isCreatedFromOccurrence();
  }

  isCreatedFromOccurrence(): boolean {
    return this.source == Access.SOURCES.OCCURRENCE;
  }

  isRegistered() {
    return this.isInAccess() || this.isOutAccess();
  }

  // Retorna a data do acesso, independente se é de entrada ou saída
  get accessDate() {
    return this.inDate || this.outDate;
  }

  get accessTypeLabel(): string {
    if (this.isInAccess()) {
      return 'Entrada';
    }
    if (this.isOutAccess()) {
      return 'Saída';
    }
    return '';
  }

  get statusLabel(): string {
    return Access.STATUS_LABEL[this.status];
  }

  get approvedByAsString(): string {
    return this.approvedByName || (this.approvedBy ? this.approvedBy.fullName : '');
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get typeLabel(): string {
    return Access.TYPES_LABEL[this.type];
  }

  get sourceLabel(): string {
    return Access.SOURCES_LABEL[this.source];
  }

  get contact() {
    return this.condoContact || this.user || this.userContact;
  }
}
