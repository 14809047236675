import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from '../../theme';
import { ModalSelectIntelbrasIncontrolAccessPointComponent } from './modal-select-intelbras-incontrol-access-point.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ModalModule, ButtonsModule, ThemeModule, TooltipModule, NgxPaginationModule],
  declarations: [ModalSelectIntelbrasIncontrolAccessPointComponent]
})
export class ModalSelectIntelbrasIncontrolAccessPointModule {}
