import { Error } from './error';

export class ResponseError extends Error {
  private ERRORS = {
    INACTIVE_USER: {
      pt: {
        title: 'Usuário desativado',
        message:
          'Seu usuário ainda não foi ativado, para continuar usando o sistema clique no botão ativar e um email será enviado para ' +
          'você contendo o link de ativação.'
      }
    },
    INVALID_CREDENTIALS: {
      pt: {
        title: 'Email e/ou senha inválidos',
        message: 'Verifique se você digitou corretamente seu email e sua senha.'
      }
    },
    UNREGISTERED_USER: {
      pt: {
        title: 'Email e/ou senha inválidos',
        message: 'Verifique se você digitou corretamente seus dados de acesso.'
      }
    },
    INVALID_PASSWORD: {
      pt: {
        title: 'Senha inválida',
        message: 'A senha atual fornecida está incorreta.'
      }
    },
    RESERVATION_DEFAULTER_FORBIDDEN: {
      pt: {
        title: 'Ops...',
        message:
          'Parece que sua unidade está com pendências financeiras, devido a isso não foi possível registrar sua solicitação. Para maiores detalhes, procure a administração.'
      }
    },
    RESERVATION_EXCEEDS_MAX_QUOTA: {
      pt: {
        title: 'Limite de reservas atingido',
        message: 'Esta unidade atingiu a cota máxima de reservas simultâneas.'
      }
    },
    RESERVATION_EXCEEDS_MAX_ACTIVE: {
      pt: {
        title: 'Limite de reservas atingidos',
        message: 'Esta unidade atingiu a cota de reservas ativas para este local de reserva.'
      }
    },
    'E11000 duplicate key error collection': {
      pt: {
        title: 'Registro já existente',
        message: 'Esse registro já está cadastrado no sistema'
      }
    }
  };

  constructor(error) {
    super();
    this.originalError = error;
    if (error.error) {
      this.setErrorMessage(error.error);
    }
    this._name = 'Response Error';
  }

  setErrorMessage(error) {
    this.messageTitle = '';
    this.message = '';
    this.type = Error.TYPES.UNKNOW_ERROR;
    if (typeof error === 'string') {
      this.originalErrorMessage = error;
      if (error.includes(':')) {
        const errorKey = error.split(':')[0];
        if (this.ERRORS[errorKey]) {
          this.type = errorKey;
          this.messageTitle = this.ERRORS[errorKey].pt.title;
          this.message = this.ERRORS[errorKey].pt.message;
        } else {
          try {
            const errorData = JSON.parse(error);
            this.type = errorData.type;
            this.message = errorData.message;
            this.messageTitle = '';
          } catch (e) {
            console.log('UNEXPECTED ERROR', e);
          }
        }
      }
    }
  }
}
