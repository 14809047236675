/*
 params: {
 commentsModeration: {
 type: String,
 default: 'CONDO_DEFAULT',
 enum: [ 'CONDO_DEFAULT', 'NONE', 'REQUIRED' ]
 }
 },
 */
import { File } from '../file';
import { User } from '../user';
import { Residence } from './residence';
import { BackObject } from './back.object';
import { Comment } from '../comment';
import { ResidenceBuilder } from '../residence/residence.builder';
import { Dependent } from '../dependent';

export abstract class Occurrence implements BackObject {
  static STATUS = {
    OPENED: 'OPENED',
    CLOSED: 'CLOSED',
    PENDING: 'PENDING',
    CANCELED: 'CANCELED'
  };

  static STATUS_lABEL = {
    OPENED: 'Aberta',
    CLOSED: 'Encerrada',
    PENDING: 'Pendente',
    CANCELED: 'Cancelada'
  };

  static VOTING_TYPE = 'VOTING';
  static GATE_TYPE = 'GATE';
  static PUBLIC_TYPE = 'PUBLIC';
  static PRIVATE_TYPE = 'PRIVATE';
  static ADVICE_TYPE = 'ADVICE';

  static TYPES_LABEL = {
    VOTING: 'Enquete',
    GATE: 'Ocorrência Portaria',
    PUBLIC: 'Ocorrência pública',
    PRIVATE: 'Ocorrência',
    ADVICE: 'Aviso'
  };

  public _id = '';
  private _condo = {};
  public title = '';
  private _pictures = [];
  public description = '';
  public groups = null;
  public descriptionHtml = '';
  private _type = '';
  public status = Occurrence.STATUS.OPENED;
  private _hidden = false;
  private _likers = [];
  public viewers = { users: new Array<User>(), residences: new Array<Residence>() };
  private _params = {};
  public residence = null;
  public createdBy: User;
  public updatedBy: User;
  public createdAt = null;
  private _updatedAt = '';
  private _comments: Comment[] = [];
  private _commentsTotal = 0;
  private _closingDetails: string = '';
  private _protocolYear: number = null;
  private _protocolMonth: number = null;
  private _protocolSeq: number = null;
  public destUser: User;
  public destDependent: Dependent;
  public canceledBy: User;
  public canceledAt: string = null;

  abstract canEdit(user: User): boolean;

  abstract canDelete(user: User): boolean;

  abstract canCancel(user: User): boolean;

  abstract canClose(user: User): boolean;

  canPrint(user: User): boolean {
    return false;
  }

  constructor(occurrence?) {
    if (occurrence) {
      this._id = occurrence._id || this._id;
      this._condo = occurrence.condo || this._condo;
      this.title = occurrence.title || this.title;
      this._pictures = occurrence.pictures ? occurrence.pictures.map(file => new File(file)) : this._pictures;
      this.description = occurrence.description || this.description;
      this.groups = occurrence.groups || this.groups;
      this.descriptionHtml = occurrence.descriptionHtml || occurrence.description || this.descriptionHtml;
      this._type = occurrence.type || this._type;
      this.status = occurrence.status || this.status;
      this._hidden = occurrence.hidden || this._hidden;
      this._likers = occurrence.likers || this._likers;
      this._params = occurrence.params || this._params;
      this.residence = occurrence.residence || this.residence;
      this.createdBy = occurrence.createdBy || this.createdBy;
      this.createdBy = occurrence.createdBy ? new User(occurrence.createdBy) : this.createdBy;
      this.updatedBy = occurrence.updatedBy || this.updatedBy;
      this.createdAt = occurrence.createdAt || this.createdAt;
      this._updatedAt = occurrence.updatedAt || this._updatedAt;
      this._comments = occurrence.comments ? occurrence.comments.map(c => new Comment(c)) : this._comments;
      this._commentsTotal = occurrence.commentsTotal ? occurrence.commentsTotal : occurrence.comments ? occurrence.comments.length : 0;
      this._closingDetails = occurrence.closingDetails || '';
      this._protocolYear = occurrence.protocolYear;
      this._protocolMonth = occurrence.protocolMonth;
      this._protocolSeq = occurrence.protocolSeq;
      this.destUser = occurrence.destUser || this.destUser;
      this.destDependent = occurrence.destDependent || this.destDependent;
      this.canceledAt = occurrence.canceledAt || this.canceledAt;
      this.canceledBy = occurrence.canceledBy || this.canceledBy;

      this.viewers = occurrence.viewers
        ? {
            residences: (occurrence.viewers.residences || []).map(res => ResidenceBuilder.build(res)),
            users: occurrence.viewers.users
          }
        : this.viewers;
    }
  }

  isVotingType() {
    return this.type == Occurrence.VOTING_TYPE;
  }

  isAdviceType() {
    return this.type == Occurrence.ADVICE_TYPE;
  }

  isPublicType() {
    return this.type == Occurrence.PUBLIC_TYPE;
  }

  isPrivateType() {
    return this.type == Occurrence.PRIVATE_TYPE;
  }

  isGateType() {
    return this.type == Occurrence.GATE_TYPE;
  }

  isClosed() {
    return this.status == Occurrence.STATUS.CLOSED;
  }

  isPending() {
    return this.status == Occurrence.STATUS.PENDING;
  }

  isOpened() {
    return this.status == Occurrence.STATUS.OPENED;
  }

  isCanceled() {
    return this.status == Occurrence.STATUS.CANCELED;
  }

  isCreatedBy(userId) {
    return this.createdBy == userId || (this.createdBy && this.createdBy._id == userId);
  }

  get statusLabel() {
    return Occurrence.STATUS_lABEL[this.status];
  }

  isCreatedByUser() {
    return !!this.residence;
  }

  createBackObject() {
    const occurrence = <any>{};
    occurrence.condo = this.condo?._id || this.condo;
    occurrence.title = this.title;
    occurrence.pictures = this.pictures.map(pic => pic._id);
    occurrence.description = this.description;
    occurrence.type = this.type;
    occurrence.status = this.status;
    occurrence.viewers = this.viewers;
    occurrence.params = this.params;
    occurrence.comments = this.comments;
    occurrence.residence = this.residence;
    occurrence.closingDetails = this.closingDetails;
    occurrence.destUser = this.destUser ? this.destUser?._id : null;
    occurrence.destDependent = this.destDependent ? this.destDependent?._id : null;

    return occurrence;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  get condo(): any {
    return this._condo;
  }

  set condo(value: any) {
    this._condo = value;
  }

  get pictures(): any {
    return this._pictures;
  }

  set pictures(value: any) {
    this._pictures = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get hidden(): boolean {
    return this._hidden;
  }

  set hidden(value: boolean) {
    this._hidden = value;
  }

  get likers(): any {
    return this._likers;
  }

  set likers(value: any) {
    this._likers = value;
  }

  get params(): any {
    return this._params;
  }

  set params(value: any) {
    this._params = value;
  }

  get commentsTotal(): number {
    return this._commentsTotal;
  }

  set commentsTotal(value: number) {
    this._commentsTotal = value;
  }

  get comments(): any {
    return this._comments;
  }

  set comments(value: any) {
    this._comments = value;
  }

  get updatedAt(): any {
    return this._updatedAt;
  }

  set updatedAt(value: any) {
    this._updatedAt = value;
  }

  get protocolYear(): number {
    return this._protocolYear;
  }

  set protocolYear(value: number) {
    this._protocolYear = value;
  }

  get protocolMonth(): number {
    return this._protocolMonth;
  }

  set protocolMonth(value: number) {
    this._protocolMonth = value;
  }

  get protocolSeq(): number {
    return this._protocolSeq;
  }

  set protocolSeq(value: number) {
    this._protocolSeq = value;
  }

  get protocol(): string {
    const protocolMonth = this.protocolMonth
      ? this.protocolMonth.toLocaleString('pt-BR', { minimumIntegerDigits: 2, useGrouping: false })
      : '';
    const protocol = `${this.protocolYear || ''}${protocolMonth || ''}${this.protocolSeq || ''}`;
    return protocol || '';
  }

  get closingDetails(): string {
    return this._closingDetails;
  }

  set closingDetails(value: string) {
    this._closingDetails = value;
  }

  get typeLabel(): string {
    return Occurrence.TYPES_LABEL[this.type];
  }
}
