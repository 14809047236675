import { Pipe, PipeTransform } from '@angular/core';
import * as linkifyStr from 'linkifyjs/string';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string, options: LinkifyOptions = { target: '_blank' }): string {
    return value ? linkifyStr(value, options) : value;
  }
}

interface LinkifyOptions {
  attributes?: any;
  className?: string;
  defaultProtocol?: string;
  events?: any;
  ignoreTags?: Array<any>;
  nl2br?: boolean;
  tagName?: string;
  target?: string;
  validate?: boolean;

  format?(value: any, type: any): any;

  formatHref?(href: any, type: any): any;
}
