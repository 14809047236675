import { Injectable } from '@angular/core';
import { Access } from '../model/access';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { AccessGroup } from '@api/model/hardware/access-group';

@Injectable()
export class AccessService {
  protected endPoint;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /** @Deprecated Use getAccesses instead */
  getCondoAccesses(condoId: string, queryString?: string): Observable<Access[]> {
    return this.http
      .get(`${this.endPoint}${condoId}/accesses${queryString || ''}`)
      .pipe(map((accesses: Array<any>) => accesses.map(access => new Access(access))));
  }

  getAccesses(condoId: string, query: EcondosQuery = {}): Observable<{ count: number; accesses: Access[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/accesses`, { params: httpParams }).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count'),
          accesses: res.body.map(ac => new Access(ac))
        };
      })
    );
  }

  getAccessById(condoId: string, accessId: string, queryString?: string) {
    return this.http.get(`${this.endPoint}${condoId}/accesses/${accessId}${queryString || ''}`).pipe(map(access => new Access(access)));
  }

  createAccess(condoId: string, access: any) {
    return this.http.post(`${this.endPoint}${condoId}/accesses`, access);
  }

  deleteAccess(condoId: string, accessId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/accesses/${accessId}`);
  }

  updateAccess(condoId: string, accessId: string, access: any) {
    return this.http.put(`${this.endPoint}${condoId}/accesses/${accessId}`, access);
  }

  getActiveAccesses(condoId: string, days = '') {
    return this.http.get(`${this.endPoint}${condoId}/accesses/activeAccesses${days ? '?days=' + days : ''}`);
  }

  getActiveVisitors(condoId: string, query: EcondosQuery = {}): Observable<{ count: number; accesses: Access[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/accesses/activeVisitors`, { params }).pipe(
      map((res: any) => {
        if (query.$count) {
          return {
            count: res.body[0],
            accesses: []
          };
        } else {
          return {
            count: res.headers.get('count'),
            accesses: res.body.map(ac => new Access(ac))
          };
        }
      })
    );
  }

  generateVisitorDeviceFromUser(
    condoId: string,
    data: { condoContactId: string; permissionId: string; type: 'QR' | 'VIRTUAL_KEY' | 'FACIAL' | string }
  ) {
    const body = {
      condoContact: data.condoContactId,
      permission: data.permissionId,
      type: data.type
    };

    return this.http.post(`${this.endPoint}${condoId}/accesses/generateVisitorDevice/fromUser`, body);
  }

  generateVisitorDeviceFromGatekeeper(
    condoId: string,
    type: 'QR' | 'SN' | 'FACIAL' | 'VEHICLE_PLATE' | 'CARD' | 'CT',
    data: {
      condoContactId: string;
      condoVehicleId?: string;
      residenceId?: string;
      documentType?: string;
      approvedByName: string;
      obs?: string;
      accessType: string;
      actuators?: any[];
      credits: number;
      accessGroup: any;
      hardware: string;
      serial?: string;
      remoteCheck?: boolean;
      accessGroups?: AccessGroup[];
    }
  ) {
    const body: any = {
      condoContact: data.condoContactId,
      type: type,
      approvedByName: data.approvedByName,
      accessType: data.accessType,
      hardware: data.hardware
    };
    if (type === 'SN') {
      body.documentType = data.documentType;
    }
    if (data.residenceId) {
      body.residence = data.residenceId;
    }
    if (data.condoVehicleId) {
      body.condoVehicle = data.condoVehicleId;
    }
    if (data.obs) {
      body.obs = data.obs;
    }
    if (data.actuators) {
      body.actuators = data.actuators;
    }
    if (data.credits) {
      body.credits = data.credits;
    }
    if (['VEHICLE_PLATE', 'CARD', 'CT'].includes(type)) {
      body.serial = data.serial;
    }
    if (data.accessGroups) {
      body.accessGroups = data.accessGroups.map(ag => ag?._id || ag);
    }
    if (data.remoteCheck) {
      body.remoteCheck = true;
    }
    return this.http.post(`${this.endPoint}${condoId}/accesses/generateVisitorDevice/fromGatekeeper`, body);
  }
}
