import { Occurrence } from '../interface/occurrence';
import { User } from '../user';
/**
 * Created by Rafael on 23/12/2016.
 */
export class AdviceOccurrence extends Occurrence {
  public archived: boolean;
  public pin: boolean;
  constructor(occurrence?) {
    super(occurrence);

    this.archived = occurrence.archived || false;
    this.pin = occurrence.pin || false;
  }

  //Criador ou admin
  canEdit(user: User = new User()): boolean {
    return this.isCreatedBy(user.id) || user.isOwner() || user.isAdmin();
  }

  //Admin ou criador
  canDelete(user: User = new User()): boolean {
    return this.isCreatedBy(user.id) || user.isOwner() || user.isAdmin();
  }

  //Ningúem
  canCancel(user: User = new User()): boolean {
    return false;
  }

  //Ninguém
  canClose(user: User = new User()): boolean {
    return false;
  }

  //Admin
  canPin(user: User = new User()): boolean {
    return user.isOwner() || user.isAdmin();
  }

  //Admin
  canArchive(user: User = new User()): boolean {
    return user.isOwner() || user.isAdmin();
  }
}
