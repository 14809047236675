import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalGatePermissionsConfigComponent } from './modal-gate-permissions-config.component';
import { ThemeModule } from '../../../theme';
import { PipesModule } from 'app/pipe/pipes.module';
import { ComponentsModule } from 'app/components/components.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  imports: [FormsModule, CommonModule, PipesModule, ReactiveFormsModule, ModalModule, ThemeModule, ComponentsModule, TimepickerModule],
  declarations: [ModalGatePermissionsConfigComponent]
})
export class ModalGatePermissionsConfigModule {}
