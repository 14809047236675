import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Occurrence } from '../model/interface/occurrence';
import { OccurrenceBuilder } from '../model/occurrence/occurrence.builder';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { EcondosQuery } from '@api/model/query';

@Injectable()
export class OccurrenceService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /**
   * @deprecated Use getOccurrences instead.
   */
  getCondoOccurrences(condoId: string, queryString = ''): Observable<Occurrence[]> {
    return this.http
      .get(`${this.endPoint}${condoId}/occurrences${queryString}`)
      .pipe(map((occurrences: any) => occurrences.map(occur => OccurrenceBuilder.build(occur))));
  }

  getOccurrences(condoId: string, params: EcondosQuery): Observable<{ count: number; occurrences: Occurrence[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/occurrences`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          if (params.$count) {
            return {
              count: res.body[0],
              occurrences: []
            };
          } else {
            return {
              count: res.headers.get('count'),
              occurrences: res.body.map((occur: Occurrence) => OccurrenceBuilder.build(occur))
            };
          }
        })
      );
  }

  getAdvicesOccurrences(condoId: string, params: EcondosQuery): Observable<{ count: number; occurrences: Occurrence[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/occurrences/advices`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          if (params.$count) {
            return {
              count: res.body[0],
              occurrences: []
            };
          } else {
            return {
              count: res.headers.get('count'),
              occurrences: res.body.map(occur => OccurrenceBuilder.build(occur))
            };
          }
        })
      );
  }

  get(condoId: string, params: EcondosQuery): Observable<{ count: number; occurrences: Occurrence[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/occurrences`, options).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count'),
          occurrences: res.body.map(occur => OccurrenceBuilder.build(occur))
        };
      })
    );
  }

  searchByToken(condoId: string, token: string, query: EcondosQuery): Observable<{ count: number; occurrences: Occurrence[] }> {
    query.token = token;

    const params = new HttpParams({ fromString: qs.stringify(query) });

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/occurrences/searchToken`, {
        params
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          occurrences: res.body.map(occurrence => OccurrenceBuilder.build(occurrence))
        }))
      );
  }

  createOccurrence(condoId: string, occurrence: any) {
    const occur = occurrence.createBackObject ? occurrence.createBackObject() : occurrence;
    return this.http.post(`${this.endPoint}${condoId}/occurrences`, occur);
  }

  deleteOccurrence(condoId: string, occurrenceId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/occurrences/${occurrenceId}`);
  }
  /**
   * @deprecated Use getById instead.
   */
  getOccurrenceById(condoId: string, occurrenceId: string, queryString = ''): Observable<Occurrence> {
    return this.http
      .get(`${this.endPoint}${condoId}/occurrences/${occurrenceId}${queryString}`)
      .pipe(map(occurrence => OccurrenceBuilder.build(occurrence)));
  }

  getById(condoId: string, occurrenceId: string, params: EcondosQuery = {}): Observable<Occurrence> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/occurrences/${occurrenceId}`, options).pipe(
      map((response: any) => {
        return OccurrenceBuilder.build(response.body);
      })
    );
  }

  updateOcurrence(condoId: string, occurrenceId: string, occurrence: any) {
    const occur = occurrence.createBackObject ? occurrence.createBackObject() : occurrence;
    return this.http.put(`${this.endPoint}${condoId}/occurrences/${occurrenceId}`, occur);
  }

  dislikeOcurrence(condoId: string, occurrenceId: string) {
    return this.http.delete(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/like');
  }

  likeOcurrence(condoId: string, occurrenceId: string) {
    return this.http.put(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/like', {});
  }

  voteAgainstOcurrence(condoId: string, occurrenceId: string) {
    return this.http.delete(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/vote');
  }

  voteFavorOcurrence(condoId: string, occurrenceId: string) {
    return this.http.put(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/vote', {});
  }

  vote(condoId: string, occurrenceId: string, choiceId: string) {
    return this.http.put(`${this.endPoint}${condoId}/occurrences/${occurrenceId}/votes/${choiceId}/vote`, {});
  }

  enableCommentsOnOccurrence(condoId: string, occurrenceId: string) {
    return this.http.put(`${this.endPoint}${condoId}/occurrences/${occurrenceId}`, {
      params: {
        commentsModeration: 'NONE'
      }
    });
  }

  disableCommentsOnOccurrence(condoId: string, occurrenceId: string) {
    return this.http.put(`${this.endPoint}${condoId}/occurrences/${occurrenceId}`, {
      params: {
        commentsModeration: 'REQUIRED'
      }
    });
  }

  createAccessFromOccurrence(condoId: string, occurrenceId: string, access: any) {
    return this.http.post(`${this.endPoint}${condoId}/occurrences/${occurrenceId}/accesses`, access);
  }

  handleError(error) {
    return throwError(error.json().error || 'Server error');
  }

  cancelOcurrence(condoId: string, occurrenceId: string) {
    return this.http.put(`${this.endPoint}${condoId}/occurrences/${occurrenceId}/cancel`, {});
  }
}
