import { Component, OnDestroy, OnInit } from '@angular/core';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { takeUntil, timeout } from 'rxjs/operators';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { User } from '@api/model/user';
import { SessionService } from '@api/service/session.service';

type TimeType = 'hour' | 'day';

type FormData = {
  activeVisitorExpireAfterHours: FormControl<number>;
  timeType: FormControl<TimeType>;
};

@Component({
  selector: 'app-modal-active-visitors-config',
  templateUrl: 'modal-active-visitors-config.component.html',
  styleUrls: ['modal-active-visitors-config.component.scss']
})
export class ModalActiveVisitorsConfigComponent implements OnInit, OnDestroy {
  condo: Condo;
  user: User;

  form: FormGroup<FormData>;
  activeVisitorExpireAfterHours: FormData['activeVisitorExpireAfterHours'];
  timeType: FormData['timeType'];
  activeVisitorExpireAfterHoursInputErrorMessage: string;

  status: Status = new Status();

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private condoService: CondoService,
    private sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private utilService: UtilService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initializeForm() {
    let initialActiveVisitorExpireAfterHours = this.condo?.hardwareParams?.activeVisitorExpireAfterHours || 72;
    let initialTimeType: TimeType = 'hour';

    if (initialActiveVisitorExpireAfterHours % 24 === 0) {
      initialActiveVisitorExpireAfterHours = initialActiveVisitorExpireAfterHours / 24;
      initialTimeType = 'day';
    }
    this.activeVisitorExpireAfterHoursInputErrorMessage = 'Prazo maiores que 365 dias ou 8766 horas não são permitidos.';
    this.form = this.formBuilder.group({
      activeVisitorExpireAfterHours: [
        initialActiveVisitorExpireAfterHours,
        [initialTimeType === 'day' ? Validators.max(365) : Validators.max(8766), Validators.min(1)]
      ],
      timeType: [initialTimeType]
    });
    this.activeVisitorExpireAfterHours = this.form.get('activeVisitorExpireAfterHours') as UntypedFormControl;
    this.timeType = this.form.get('timeType') as UntypedFormControl;

    this.timeType.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.timeType.value === 'day') {
        this.activeVisitorExpireAfterHours.setValidators([Validators.max(365), Validators.min(1)]);
      } else {
        this.activeVisitorExpireAfterHours.setValidators([Validators.max(8766), Validators.min(1)]);
      }
      this.activeVisitorExpireAfterHours.updateValueAndValidity();
    });
  }

  submit() {
    if (this.form.valid) {
      let valueInHour = this.activeVisitorExpireAfterHours.value;
      if (this.timeType.value === 'day') {
        valueInHour = +(this.activeVisitorExpireAfterHours.value * 24).toFixed(2);
      }
      const hardwareParams = {
        ...this.condo.hardwareParams,
        activeVisitorExpireAfterHours: valueInHour || 72
      };
      this.status.setAsProcessing();
      this.condoService
        .updateCondo(this.condo._id, { hardwareParams })
        .pipe(timeout(10000), takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.condo.hardwareParams = hardwareParams;
            if (this.condo._id == this.utilService.getLocalCondo()._id) {
              this.utilService.saveLocalCondo(this.condo);
            }
            const updatedUser = this.utilService.getLocalUser();
            updatedUser.defaultCondo.hardwareParams = hardwareParams;
            this.sessionService.setUser(updatedUser);
            this.toastr.success('Parâmetros atualizados com sucesso');
            this.status.setAsSuccess();
            this.bsModalRef.hide();
          },
          error: () => {
            this.status.setAsError();
            swal({
              type: 'error',
              text: 'Não foi possível atualizar os parâmetros, tente novamente'
            });
          }
        });
    } else {
      this.form.markAllAsTouched();
    }
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
