import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { Reservation } from '@api/model/reservation';
import { ReservationService } from '@api/service/reservation.service';
import { User } from '@api/model/user';
import * as moment from 'moment';
import { Condo } from '@api/model/condo';
import { ReportService, ReservationReportData } from '@api/service/report.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ModalReservationDetailComponent } from './reservation.detail.modal/reservation.detail.modal';
import { expand, map, reduce, retry, timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { UntypedFormControl } from '@angular/forms';
import { EcondosFilter } from '@api/model/filter';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Residence } from '@api/model/interface/residence';
import { capitalize } from '@api/util/util';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';

import {
  TableColumnDefinition,
  TableComponent,
  TablePageChangeEventData,
  TableRowClickEventData,
  TableSortChangeEventData,
  TableSortOrder,
  TableStatus
} from 'app/components/table/table.component';
import { formatCurrency } from '@angular/common';
import { THEME_CONFIG, TOOLBAR_CONFIG, TRANSLATION_CONFIG } from './reservation.history.utils';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { ResidenceAutoCompleteComponent } from 'app/components/residence-auto-complete/residence-auto-complete.component';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EMPTY } from 'rxjs';
import { InQueueReservationsListModalComponent } from './in-queue-reservations-list-modal/in-queue-reservations-list-modal.component';

@Component({
  templateUrl: 'reservation.history.html',
  styleUrls: ['reservation.history.scss']
})
export class ReservationHistoryComponent implements OnInit {
  @ViewChild('calendar', { static: false }) calendar: FullCalendarComponent;
  @ViewChild('reservationTable', { static: false }) reservationTable: TableComponent;
  @ViewChild('statusReservationCellTemplate', { static: true }) statusReservationCellTemplate: TemplateRef<any>;
  @ViewChild(ModalReservationDetailComponent, { static: true }) reservationDetailModal: ModalReservationDetailComponent;
  @ViewChild('residenceAutoComplete') residenceAutoComplete: ResidenceAutoCompleteComponent;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<Reservation>[] = [];
  calendarOptions: CalendarOptions = {};

  public currentPage = 1;
  public pageSize = 15;
  public totalItemsCount = 0;
  sortedColumn = '';
  sortOrder: TableSortOrder = 'desc';
  displayMode: 'CALENDAR' | 'TABLE' = 'CALENDAR';

  condo: Condo;
  user: User;
  isGatekeeperOrAdmin = false;

  reservations: Reservation[] = new Array<Reservation>();
  selectedReservations: Array<Reservation> = [];
  selectedReservation: Reservation;
  selectedTab: string = 'current';
  currentPeriodTitle: string = moment().format('MMMM YYYY');
  startDate: string = moment().startOf('month').toISOString();
  endDate: string = moment().endOf('month').toISOString();
  selectedFilters = {};
  numberOfActiveFilters = 0;

  totalOfReservations = 0;

  reservationLocals = [];
  reservationLocalFiltersSelected = [];

  reservationStatus = [
    { label: 'Aprovada', value: 'APPROVED', color: '#008000', checked: true },
    { label: 'Concluída', value: 'DONE', color: '#0000FF', checked: true },
    { label: 'Pendente', value: 'PENDING', color: '#808080', checked: true },
    { label: 'Cancelada', value: 'CANCELED', color: '#FFA500', checked: true },
    { label: 'Cancelada com multa', value: 'CANCELED_WITH_PENALTY', color: '#FF0000', checked: true }
  ];

  reservationStatusBadge: Record<keyof typeof Reservation.STATUS, string> = {
    APPROVED: 'badge-subtle-success',
    DONE: 'badge-subtle-primary',
    PENDING: 'badge-subtle-warning',
    CANCELED: 'badge-subtle-danger',
    CANCELED_WITH_PENALTY: 'badge-subtle-danger',
    IN_QUEUE: 'badge-subtle-gray'
  };

  reservationStatusFiltersSelected = [];

  residenceFilter: Residence;

  reservationStatusSelected = [];

  selectedResidence = new UntypedFormControl('');

  initialQuery: EcondosQuery = {};

  customQuery: EcondosQuery = {};

  public currentSortData: {
    field: string;
    order: 'asc' | 'desc' | '';
  } = {
    field: '',
    order: ''
  };

  filters: EcondosFilter[] = [];
  locals = [];

  constructor(
    private reservationService: ReservationService,
    private utilService: UtilService,
    private reportService: ReportService,
    private modalService: BsModalService
  ) {
    this.initializeCalendarOptions();
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();
    this.displayMode = this.getLocalDisplayMode();
    this.isGatekeeperOrAdmin =
      this.user &&
      (this.user.isGatekeeperOnCondo(this.condo._id) ||
        this.user.isOwnerOnCondo(this.condo._id) ||
        this.user.isAdminOnCondo(this.condo._id));

    this.getReservationLocals();

    this.initialQuery = {
      $populate: [
        { path: 'reservationLocal', select: 'name checklist penaltyWarningText' },
        { path: 'residence', select: 'identification' },
        { path: 'createdBy', select: 'firstName lastName', populate: [{ path: 'picture', select: 'url thumbnail' }] },
        { path: 'canceledBy', select: 'firstName lastName', populate: [{ path: 'picture', select: 'url thumbnail' }] },
        { path: 'approvedBy', select: 'firstName lastName', populate: [{ path: 'picture', select: 'url thumbnail' }] }
      ],
      $or: [],
      $and: [
        { startDate: { $gte: moment().startOf('month').toISOString() } },
        { endDate: { $lte: moment().endOf('month').toISOString() } }
      ],
      status: {
        $in: ['APPROVED', 'DONE', 'PENDING', 'CANCELED', 'CANCELED_WITH_PENALTY']
      },
      $sort: 'endDate'
    };

    this.customQuery = { ...this.initialQuery };
    this.filters = [
      {
        element: 'input',
        elementType: 'date',
        name: 'startDate',
        label: 'Data inicial',
        placeholder: 'Busque pela descrição',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementType: 'date',
        name: 'endDate',
        label: 'Data final',
        placeholder: 'Busque pela descrição',
        searchType: 'regex'
      }
    ];
  }

  ngOnInit(): void {
    this.tableColumns = [
      {
        headerLabel: '#',
        type: 'index'
      },
      {
        headerLabel: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        columnId: 'residence',
        valueFn: reservation => reservation.residence?.identification || '-',
        sortable: true
      },
      {
        headerLabel: 'Criada por',
        columnId: 'createdBy',
        valueFn: reservation => reservation.createdBy?.firstName + ' ' + reservation.createdBy?.lastName,
        sortable: true
      },
      {
        headerLabel: 'Local',
        columnId: 'reservationLocal',
        valueFn: reservation => reservation.reservationLocal.name,
        sortable: true
      },
      {
        headerLabel: 'Valor Total',
        columnId: 'value',
        valueFn: reservation => formatCurrency(reservation.totalValue, 'pt', 'R$', 'symbol', '1.2-2'),
        sortable: true
      },
      {
        headerLabel: 'Status',
        columnId: 'status',
        valueFn: reservation => reservation.statusLabel,
        cellClassFn: reservation => this.reservationStatusBadge[reservation.status],
        type: 'badge',
        sortable: true
      },
      {
        headerLabel: 'Data',
        columnId: 'startDate',
        valueFn: reservation => reservation.reservationDate,
        sortable: true
      }
    ];
    this.getData();
  }

  getReservationLocals() {
    const qs: EcondosQuery = {
      $sort: 'name',
      deleted: false
    };
    this.reservationService
      .getReservationLocals(this.condo._id, qs)
      .pipe(retry(3))
      .subscribe(({ count, locals }) => {
        locals.forEach(local => {
          this.reservationLocals.push({ label: local.name, value: local._id, checked: true });
        });
      });
  }

  getData({ page = 0 } = {}) {
    this.status = 'LOADING';
    const query = this.customQuery;
    query.$page = page;

    if (this.displayMode === 'CALENDAR') {
      this.calendarOptions.initialDate = this.startDate;
    }

    if (this.sortedColumn) {
      query.$sort = `${this.sortOrder === 'desc' ? '-' : ''}${this.sortedColumn}`;
    }

    if (this.reservationLocalFiltersSelected.length) {
      query.reservationLocal = {
        $in: this.reservationLocalFiltersSelected.map(local => local.value)
      };
    } else {
      delete query.reservationLocal;
    }

    if (this.reservationStatusFiltersSelected.length) {
      query.status = {
        $in: this.reservationStatusFiltersSelected.map(local => local.value)
      };
    } else {
      query.status = this.initialQuery.status;
    }

    if (this.residenceFilter) {
      query.residence = this.residenceFilter._id;
    } else {
      delete query.residence;
    }

    if (!this.isGatekeeperOrAdmin && !this.residenceFilter) {
      const residences = this.user.getResidences();
      query.residence = {
        $in: residences.map(residence => residence._id)
      };
    }

    if (this.numberOfActiveFilters) {
      if (this.filters.find(filter => filter.name === 'startDate')?.value) {
        let start = moment(this.filters.find(filter => filter.name === 'startDate')?.value)
          .utc()
          .format('DD/MM/YYYY')
          .toString();
        this.currentPeriodTitle = `Reservas a partir de ${start}`;
      }

      if (this.filters.find(filter => filter.name === 'endDate')?.value) {
        let end = moment(this.filters.find(filter => filter.name === 'endDate')?.value)
          .utc()
          .format('DD/MM/YYYY')
          .toString();
        this.currentPeriodTitle = `Reservas até ${end}`;
      }

      if (
        this.filters.find(filter => filter.name === 'startDate')?.value &&
        this.filters.find(filter => filter.name === 'endDate')?.value
      ) {
        let start = moment(this.filters.find(filter => filter.name === 'startDate')?.value)
          .utc()
          .format('DD/MM/YYYY')
          .toString();
        let end = moment(this.filters.find(filter => filter.name === 'endDate')?.value)
          .utc()
          .format('DD/MM/YYYY')
          .toString();
        this.currentPeriodTitle = `${start} - ${end}`;
      }
    }

    let getReservations$;

    if (this.displayMode === 'CALENDAR') {
      const getData$ = (page = 0) => {
        const pageSize = 50;
        const newQuery = { ...query, $page: page, $limit: pageSize };
        return this.reservationService.getReservations(this.condo._id, newQuery).pipe(
          map(({ reservations, count }) => {
            const next = reservations?.length === pageSize;
            return { reservations, next, page, count };
          })
        );
      };
      getReservations$ = getData$().pipe(
        expand(({ next, page }) => {
          if (next) {
            return getData$(page + 1);
          }
          return EMPTY;
        }),
        timeout(10000),
        retry(3),
        reduce((acc, { reservations = [] }) => acc.concat(reservations), []),
        map(reservations => ({ reservations, count: reservations.length }))
      );
    } else {
      getReservations$ = this.reservationService.getReservations(this.condo._id, query).pipe(timeout(15000));
    }

    getReservations$.subscribe({
      next: res => {
        this.totalOfReservations = res.count;
        this.reservations = res.reservations;
        this.calendarOptions.events = this.generateCalendarEvents();
        this.status = 'SUCCESS';
      },
      error: err => {
        console.log(err);
        this.reservations = [];
        this.calendarOptions.events = [];
        this.status = 'ERROR';
      }
    });
  }

  initializeCalendarOptions() {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      ...TOOLBAR_CONFIG,
      ...TRANSLATION_CONFIG,
      ...THEME_CONFIG,
      eventClick: this.handleEventClick.bind(this),
      dayMaxEvents: true,
      customButtons: {
        next: {
          text: 'Próximo',
          click: () => {
            const calendarApi = this.calendar.getApi();
            calendarApi.next();
          }
        },
        prev: {
          text: 'Anterior',
          click: () => {
            const calendarApi = this.calendar.getApi();
            calendarApi.prev();
          }
        },
        today: {
          text: 'Hoje',
          click: () => {
            const calendarApi = this.calendar.getApi();
            calendarApi.today();
          }
        }
      },
      datesSet: arg => {
        const query = this.customQuery;
        const startDateEvent = moment(arg.start).utc().toISOString();
        const endDateEvent = moment(arg.end).utc().toISOString();
        this.startDate = moment(arg.view.currentStart).utc().toISOString();
        this.endDate = moment(arg.view.currentStart).utc().endOf('month').toISOString();
        this.currentPeriodTitle = moment(this.startDate).utc().format('MMMM YYYY');
        query.$and = [{ startDate: { $gte: startDateEvent } }, { endDate: { $lte: endDateEvent } }];
        this.customQuery = query;
        this.getData();
      }
    };
  }

  generateCalendarEvents() {
    const events = [];
    const days = this.reservations.map(reservation => {
      let color;
      switch (reservation.status) {
        case 'APPROVED':
          color = '#008000';
          break;
        case 'PENDING':
          color = '#808080';
          break;
        case 'CANCELED':
          color = '#FFA500';
          break;
        case 'CANCELED_WITH_PENALTY':
          color = '#FF0000';
          break;
        case 'DONE':
          color = '#0000FF';
          break;
      }
      return {
        title: reservation.reservationLocal.name,
        start: moment(reservation.startDate).utc().format('YYYY-MM-DD HH:mm'),
        end: moment(reservation.endDate).utc().format('YYYY-MM-DD HH:mm'),
        color,
        reservation
      };
    });

    events.push(...days);
    return events;
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.reservationDetailModal.showModal(clickInfo.event.extendedProps.reservation);
  }

  printReservations(reservations: Reservation[]) {
    const data = new ReservationReportData(this.startDate, this.endDate, reservations);
    const pdf = this.reportService.buildReservationReport(pdfMake, pdfFonts, data);
    pdf.getDataUrl(url => {
      const link = document.createElement('a');
      link.href = url;
      link.download = `Reservas_${moment(this.startDate).utc().format('DD/MM/YYYY')}_ate_${moment(this.endDate)
        .utc()
        .format('DD/MM/YYYY')}`;
      link.click();
    });
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        this.startDate = filters.find(filter => filter.name === 'startDate')?.value;
        this.endDate = filters.find(filter => filter.name === 'endDate')?.value;
        if (this.startDate || this.endDate) {
          query.$and = [];
          if (this.startDate) {
            query.$and.push({ startDate: { $gte: moment(this.startDate).utc().startOf('day').toISOString() } });
          }
          if (this.endDate) {
            query.$and.push({ endDate: { $lte: moment(this.endDate).utc().endOf('day').toISOString() } });
          }
        }
        delete query.startDate;
        delete query.endDate;

        this.countActiveFilters(filters);
        this.filters = filters;
        this.customQuery = query;
        this.getData();
      }
    };

    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  countActiveFilters(filters: EcondosFilter[]) {
    this.numberOfActiveFilters = filters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }

  removeIndividualFilter({ index }) {
    this.filters[index].value = '';
    this.filters[index].valueLabel = '';

    delete this.customQuery[this.filters[index].name];

    if (this.filters[index].name === 'startDate' || this.filters[index].name === 'endDate') {
      this.customQuery.$and = this.initialQuery.$and;
      this.filters = this.filters.map(filter => {
        if (filter.name === 'startDate' || filter.name === 'endDate') {
          filter.value = '';
          filter.valueLabel = '';
        }
        return filter;
      });
      this.numberOfActiveFilters--;
    }
    this.currentPeriodTitle = moment().format('MMMM YYYY');
    this.numberOfActiveFilters--;
    this.getData();
  }

  showPreviousMonth() {
    this.startDate = moment(this.startDate).utc().subtract(1, 'month').toISOString();
    this.endDate = moment(this.startDate).utc().endOf('month').toISOString();

    this.updateQueryAndGetData();
  }

  showCurrentMonth() {
    this.startDate = moment().startOf('month').toISOString();
    this.endDate = moment().endOf('month').toISOString();
    this.currentPeriodTitle = moment().format('MMMM YYYY');
    this.updateQueryAndGetData();
  }

  showNextMonth() {
    this.startDate = moment(this.startDate).utc().add(1, 'month').toISOString();
    this.endDate = moment(this.startDate).utc().endOf('month').toISOString();

    this.updateQueryAndGetData();
  }

  updateQueryAndGetData() {
    const query = this.customQuery;
    this.currentPeriodTitle = moment(this.startDate).utc().format('MMMM YYYY');
    query.$and = [{ startDate: { $gte: this.startDate } }, { endDate: { $lte: this.endDate } }];

    this.getData();
  }

  onSelect({ rowData: reservation }: TableRowClickEventData) {
    this.reservationDetailModal.showModal(reservation);
  }

  clearFilters() {
    this.reservationStatus = this.reservationStatus.map(local => {
      return { ...local, checked: true };
    });
    this.reservationLocals = this.reservationLocals.map(local => {
      return { ...local, checked: true };
    });

    this.filters = this.filters.map(filter => {
      return {
        ...filter,
        value: '',
        valueLabel: ''
      };
    });
    this.numberOfActiveFilters = 0;
    this.customQuery = { ...this.initialQuery };
    this.currentPeriodTitle = moment().format('MMMM YYYY');
    this.selectedTab = 'current';

    this.reservationLocalFiltersSelected = [];
    this.reservationStatusSelected = [];
    this.reservationStatusFiltersSelected = [];
    this.residenceAutoComplete.resetValue();
    this.customQuery = { ...this.initialQuery };

    this.getData();
  }

  onChangeReservationLocalFilter(filter, value) {
    this.reservationLocals.find(local => local.value === value).checked = filter;
    this.reservationLocalFiltersSelected = this.reservationLocals.filter(local => local.checked);
    this.getData();
  }

  onChangeReservationStatusFilter(filter, value) {
    this.reservationStatus.find(local => local.value === value).checked = filter;
    this.reservationStatusFiltersSelected = this.reservationStatus.filter(local => local.checked);
    this.getData();
  }

  handleTablePageChange({ queryPageNumber, tablePageNumber, pageSize }: TablePageChangeEventData) {
    this.currentPage = tablePageNumber;
    this.pageSize = pageSize;

    const { sortedColumn, sortOrder } = this.reservationTable.getCurrentState();

    this.sortedColumn = typeof sortedColumn === 'string' ? sortedColumn : '';
    this.sortOrder = sortOrder;

    this.getData({ page: queryPageNumber });
  }

  handleTableSortChange({ column, order }: TableSortChangeEventData) {
    this.sortOrder = order;
    this.sortedColumn = typeof column === 'string' ? column : '';
    this.getData();
  }

  toggleDisplayMode(displayMode: typeof this.displayMode) {
    this.displayMode = displayMode;
    this.saveLocalDisplayMode();

    if (this.displayMode === 'TABLE') {
      const query = this.customQuery;
      query.$and = [{ startDate: { $gte: this.startDate } }, { endDate: { $lte: this.endDate } }];

      setTimeout(() => {
        this.reservationTable.setCurrentPage(this.currentPage);
      }, 0);
    }

    if (this.displayMode === 'CALENDAR') {
      this.calendarOptions.initialDate = this.startDate;
    }
  }

  getLocalDisplayMode() {
    try {
      const displayMode = localStorage.getItem('econdos.reservationDisplayMode');
      return displayMode === 'CALENDAR' ? 'CALENDAR' : 'TABLE';
    } catch (err) {
      return 'TABLE';
    }
  }

  saveLocalDisplayMode() {
    localStorage.setItem('econdos.reservationDisplayMode', this.displayMode);
  }

  handleRefreshData() {
    const { currentPage } = this.reservationTable.getCurrentState();
    this.getData({ page: currentPage - 1 });
  }

  showInQueueReservationsModal() {
    this.modalService.show(InQueueReservationsListModalComponent, {
      class: 'modal-lg'
    });
  }
}
