import { Component, OnDestroy } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { filter, takeUntil, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { capitalize } from '@api/util/util';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-condo-plans',
  templateUrl: 'condo.plans.html',
  styleUrls: ['./condo.plans.scss']
})
export class CondoPlansComponent implements OnDestroy {
  condo: Condo;

  planForm: UntypedFormGroup;
  daysOfTrial: AbstractControl;
  plan: AbstractControl;
  camerasLimit: AbstractControl;
  licence: AbstractControl;
  lineares: UntypedFormArray;

  hardwareForm: UntypedFormGroup;

  FEATURES = [
    { value: 'VOTING', label: 'Enquetes' },
    { value: 'GATE', label: 'Portaria' },
    { value: 'RESERVATIONS', label: 'Reservas' },
    { value: 'ADS', label: 'Classificados' },
    { value: 'DOCUMENTS', label: 'Documentos' },
    { value: 'FINANCE', label: 'Financeiro' },
    { value: 'MAINTENANCE', label: 'Manutenção' }
  ];

  PLANS = [
    { value: 'FREE', label: 'Grátis/Teste' },
    { value: 'PREMIUM', label: 'Premium' },
    { value: 'GATE', label: 'Módulo Portaria' }
  ];
  featuresModel = {};

  planStatus;
  hwStatus;
  hardwareParamsStatus;
  deleteCondoStatus;
  STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  };

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private condoService: CondoService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private clipboard: ClipboardService
  ) {
    this.condo = this.utilService.getLocalCondo();

    this.FEATURES.forEach(
      f => (this.featuresModel[f.value] = this.condo.plan && this.condo.plan.features && this.condo.plan.features.indexOf(f.value) !== -1)
    );

    this.planForm = formBuilder.group({
      daysOfTrial: [this.condo?.daysOfTrial || 0, Validators.compose([Validators.required])],
      plan: [this.condo?.plan?.name || '', Validators.compose([Validators.required])],
      camerasLimit: [this.condo?.plan?.camerasLimit || 5, Validators.compose([Validators.required])]
    });

    this.daysOfTrial = this.planForm.get('daysOfTrial');
    this.plan = this.planForm.get('plan');
    this.camerasLimit = this.planForm.get('camerasLimit');

    this.hardwareForm = formBuilder.group({
      hardwareParams: formBuilder.group({
        visitorsLprFromUser: [this.condo?.hardwareParams?.visitorsLprFromUser === 'ENABLED'],
        visitorsFacialFromUser: [this.condo?.hardwareParams?.visitorsFacialFromUser === 'ENABLED'],
        selfAddFacial: [this.condo?.hardwareParams?.selfAddFacial === 'ENABLED']
      }),
      linear: formBuilder.group({
        licence: [this.condo?.linear?.licence || '', Validators.compose([Validators.maxLength(15)])],
        enabled: [this.condo?.linear?.enabled || false],
        multiple: [this.condo?.linear?.multiple || false]
      }),
      hikvision: formBuilder.group({
        enabled: [this.condo?.hikvision?.enabled || false]
      }),
      controlId: formBuilder.group({
        enabled: [this.condo?.controlId?.enabled || false]
      }),
      intelbras: formBuilder.group({
        enabled: [this.condo?.intelbras?.enabled || false]
      }),
      intelbrasStandAlone: formBuilder.group({
        enabled: [this.condo?.intelbrasStandAlone?.enabled || false]
      }),
      utech: formBuilder.group({
        enabled: [this.condo?.utech?.enabled || false]
      }),
      niceController: formBuilder.group({
        enabled: [this.condo?.niceController?.enabled || false]
      }),
      tasmota: formBuilder.group({
        enabled: [this.condo?.tasmota?.enabled || false]
      }),
      alphadigi: formBuilder.group({
        enabled: [this.condo?.alphadigi?.enabled || false]
      }),
      zkteco: formBuilder.group({
        enabled: [this.condo?.zkteco?.enabled || false]
      })
    });
    this.licence = this.hardwareForm.controls.linear.get('licence');

    this.hardwareForm
      .get('linear')
      .valueChanges.pipe(
        filter(({ enabled, multiple }) => !enabled && multiple),
        takeUntil(this.destroyed$)
      )
      .subscribe(({ multiple }) => {
        this.hardwareForm.get('linear.multiple').setValue(false);
      });

    this.hardwareForm
      .get('linear.multiple')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        if (value) {
          this.initLinearesFormArray();
        } else {
          this.hardwareForm.removeControl('lineares');
        }
      });

    if (this.condo?.linear?.multiple) {
      this.initLinearesFormArray();

      if (this.condo?.lineares?.length) {
        const lineares = this.condo?.lineares || [];
        lineares?.forEach(linear => {
          this.addLinearControl(linear);
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initLinearesFormArray() {
    this.hardwareForm.addControl('lineares', new UntypedFormArray([], [Validators.required]));
    this.lineares = this.hardwareForm.get('lineares') as UntypedFormArray;
  }

  addLinearControl({ enabled, name, licence, _id } = { enabled: true, name: '', licence: '', _id: '' }) {
    const formGroup = new UntypedFormGroup({
      enabled: new UntypedFormControl(enabled, Validators.required),
      name: new UntypedFormControl(name, Validators.required),
      licence: new UntypedFormControl(licence, [Validators.required, Validators.minLength(10), Validators.maxLength(15)])
    });
    if (_id) {
      formGroup.addControl('_id', new UntypedFormControl(_id, Validators.required));
    }
    this.lineares.push(formGroup);
  }

  removeLinearControl(index: number) {
    this.lineares.removeAt(index);
  }

  onPlanChange(event) {
    const value = event.target.value;
    if (value) {
      const allPlans = {
        FREE: ['RESERVATIONS', 'DOCUMENTS'],
        PREMIUM: ['VOTING', 'GATE', 'RESERVATIONS', 'ADS', 'DOCUMENTS', 'FINANCE', 'MAINTENANCE'],
        GATE: ['GATE', 'RESERVATIONS', 'DOCUMENTS']
      };
      const featuresOfPlan = allPlans[value];
      this.FEATURES.forEach(f => (this.featuresModel[f.value] = featuresOfPlan.indexOf(f.value) !== -1));
    }
  }

  submitPlan(values) {
    const selectedFeatures = this.FEATURES.filter(f => this.featuresModel[f.value]).map(f => f.value);
    const plan = { name: values.plan, features: selectedFeatures || [], camerasLimit: values.camerasLimit };
    values = { ...values, plan };
    this.planStatus = this.STATUS.LOADING;
    this.condoService.updateCondoPlan(this.condo.id, values).subscribe(
      async () => {
        this.condo.plan = plan;
        this.condo.daysOfTrial = values.daysOfTrial;
        this.utilService.saveLocalCondo(this.condo);
        this.planStatus = this.STATUS.SUCCESS;
        await swal({
          type: 'success',
          text: 'Plano alterado com sucesso.'
        });
      },
      async err => {
        this.planStatus = this.STATUS.ERROR;
        await swal({
          type: 'error',
          title: err.messageTitle || 'Ops...',
          text: err.message || 'Não foi possível alterar o plano, tente novamente.'
        });
      }
    );
  }

  submitHardware(values) {
    this.hwStatus = this.STATUS.LOADING;

    if (this.hardwareForm.valid) {
      this.condoService.updateHardwareConfig(this.condo._id, values).subscribe(
        () => {
          this.condo.linear = {
            ...this.condo.linear,
            licence: values.linear?.licence,
            enabled: values.linear?.enabled,
            multiple: values.linear?.multiple
          };
          this.condo.hikvision = {
            ...this.condo.hikvision,
            enabled: values.hikvision?.enabled
          };
          this.condo.controlId = {
            ...this.condo.controlId,
            enabled: values.controlId?.enabled
          };
          this.condo.intelbras = {
            ...this.condo.intelbras,
            enabled: values.intelbras?.enabled
          };
          this.condo.intelbrasStandAlone = {
            ...this.condo.intelbrasStandAlone,
            enabled: values.intelbrasStandAlone?.enabled
          };
          this.condo.utech = {
            ...this.condo.intelbras,
            enabled: values.utech?.enabled
          };
          this.condo.tasmota = {
            ...this.condo.tasmota,
            enabled: values.tasmota?.enabled
          };
          this.condo.niceController = {
            ...this.condo.niceController,
            enabled: values.niceController?.enabled
          };
          this.condo.alphadigi = {
            ...this.condo.alphadigi,
            enabled: values.alphadigi?.enabled
          };
          this.condo.zkteco = {
            ...this.condo.zkteco,
            enabled: values.zkteco?.enabled
          };
          if (values.lineares?.length) {
            this.condo.lineares = [...values.lineares];
          } else {
            this.condo.lineares = [];
          }
          this.utilService.saveLocalCondo(this.condo);
          this.hwStatus = this.STATUS.SUCCESS;
          swal({
            type: 'success',
            text: 'Configurações alteradas com sucesso.'
          });
        },
        err => {
          this.hwStatus = this.STATUS.ERROR;
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: err.message || 'Não foi possível alterar as configurações de hardware, tente novamente.'
          });
        }
      );
    } else {
      this.hwStatus = this.STATUS.ERROR;
    }
  }

  async submitHardwareParams(values: any) {
    this.hardwareParamsStatus = this.STATUS.LOADING;

    try {
      const updatedHardwareParams = {
        ...values,
        visitorsLprFromUser: values.visitorsLprFromUser ? 'ENABLED' : 'DISABLED',
        visitorsFacialFromUser: values.visitorsFacialFromUser ? 'ENABLED' : 'DISABLED',
        selfAddFacial: values.selfAddFacial ? 'ENABLED' : 'DISABLED'
      };

      await this.condoService
        .updateCondo(this.condo._id, { hardwareParams: { ...this.condo.hardwareParams, ...updatedHardwareParams } })
        .toPromise();

      this.condo.hardwareParams = { ...this.condo.hardwareParams, ...updatedHardwareParams };

      this.utilService.saveLocalCondo(this.condo);

      this.hardwareParamsStatus = this.STATUS.SUCCESS;

      swal({
        type: 'success',
        text: 'Configurações alteradas com sucesso.'
      });
    } catch (error) {
      this.hardwareParamsStatus = this.STATUS.ERROR;

      swal({
        type: 'error',
        title: 'Erro inesperado',
        text: 'Não foi possível alterar os parâmetros de hardware, tente novamente.'
      });
    }
  }

  virtuallyCondoDelete() {
    swal({
      type: 'question',
      text: `Deseja deletar virtualmente esse(a) ${this.condo?.customLabels?.condo?.singular}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.deleteCondoStatus = this.STATUS.LOADING;
        return this.condoService
          .deleteCondo(this.condo._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject(
              `Não foi possível excluir o(a) ${this.condo?.customLabels?.condo?.singular} virtualmente, tente novamente...`
            );
          });
      }
    }).then(
      () => {
        this.deleteCondoStatus = this.STATUS.SUCCESS;
        this.toastrService.success(`${capitalize(this.condo?.customLabels?.condo?.singular)} deletado com sucesso`);
      },
      error => {
        this.deleteCondoStatus = this.STATUS.ERROR;
        console.log(error);
      }
    );
  }

  private generateLicence() {
    const chars = '123456789ABCDEFGHIJKLMOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let newLicence = '';
    for (let i = 0; i < 15; i++) {
      newLicence += chars[Math.floor(Math.random() * chars.length)];
    }
    return newLicence;
  }

  getLicenceToLinear(formGroupIndex?: number) {
    const licence = this.generateLicence();
    if (!isNaN(formGroupIndex)) {
      this.lineares.at(formGroupIndex)?.get('licence')?.setValue(licence);
    } else {
      this.licence.setValue(licence);
    }
  }

  copy(value) {
    this.clipboard.copy(`ObjectId("${value}")`);
    this.toastrService.success('Valor copiado');
  }
}
