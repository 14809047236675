import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalCondoBorrowKey } from './modal.borrow.key';
import { ComponentsModule } from '../../../../components/components.module';
import { ThemeModule } from '../../../../theme';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PipesModule } from 'app/pipe/pipes.module';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ModalModule, PipesModule, ComponentsModule, TypeaheadModule, ThemeModule],
  declarations: [ModalCondoBorrowKey]
})
export class ModalBorrowKeyModule {}
