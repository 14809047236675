import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UtilService } from '../services/util.service';

@Injectable({ providedIn: 'root' })
export class FeedGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.utilService.getLocalUser();
    const condo = user?.defaultCondo;

    if (
      condo.isAccessLiberationDisabled() &&
      condo.isDeliveriesDisabled() &&
      condo.isOccurrencesDisabled() &&
      condo.isVotingDisabled() &&
      condo.isAdvicesDisabled()
    ) {
      let routePath = '';

      if (user.isGatekeeper()) {
        routePath = '/gate';
      } else if (user.isJanitor()) {
        routePath = '/maintenance';
      } else {
        routePath = '/residences';
      }

      this.router.navigate([routePath]);
      return false;
    }

    return true;
  }
}
