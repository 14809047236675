import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAddActuatorComponent } from './modal-add-actuator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ThemeModule } from '../../theme';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '../../pipe/pipes.module';
import { ComponentsModule } from '../../components/components.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    ButtonsModule,
    ColorPickerModule,
    ThemeModule,
    TooltipModule,
    PipesModule,
    ComponentsModule,
    TimepickerModule
  ],
  declarations: [ModalAddActuatorComponent]
})
export class ModalAddActuatorModule {}
