import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { catchError, map, retry, tap, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AccessGroup } from '@api/model/hardware/access-group';
import { AccessGroupService } from '@api/service/access-group.service';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../../../services/util.service';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

interface AccessGroupItem extends AccessGroup {
  isSelected?: boolean;
}

@Component({
  selector: 'app-access-group-picker',
  templateUrl: './access-group-picker.component.html',
  styleUrls: ['access-group-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AccessGroupPickerComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AccessGroupPickerComponent
    }
  ]
})
export class AccessGroupPickerComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator {
  getAccessGroups$: Observable<AccessGroup[]>;

  @Input() multipleSelect = true;

  accessGroups: AccessGroup[];
  selectedAccessGroups: { [key: string]: AccessGroup } = {};

  condo: Condo;

  touched = false;
  isDisabled = false;
  isValid = true;

  status: 'LOADING' | 'SUCCESS' | 'ERROR';

  onChange = value => {};

  onTouched = () => {};

  constructor(
    private accessGroupService: AccessGroupService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.condo = this.utilService.getLocalCondo();
    this.setObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {}

  setObservable() {
    this.status = 'LOADING';
    this.getAccessGroups$ = this.accessGroupService.get(this.condo._id, { $populate: ['actuators', 'timezone'] }).pipe(
      map(({ accessGroups }) => accessGroups),
      tap(accessGroups => {
        this.accessGroups = accessGroups;
        // Troca os valores dos grupos selecionados pelos valores que vem já populados pelo backend
        Object.keys(this.selectedAccessGroups).forEach(id => {
          this.selectedAccessGroups[id] = this.accessGroups.find(ag => ag._id === id) || this.selectedAccessGroups[id];
        });
        this.status = 'SUCCESS';
        this.emitChanges();
      }),
      timeout(10000),
      retry(3),
      catchError(e => {
        this.status = 'ERROR';
        return [];
      })
    );
  }

  toggleAccessGroupSelection(accessGroup: AccessGroup): void {
    if (this.selectedAccessGroups[accessGroup._id]) {
      delete this.selectedAccessGroups[accessGroup._id];
    } else if (this.multipleSelect) {
      this.selectedAccessGroups[accessGroup._id] = accessGroup;
    } else {
      this.selectedAccessGroups = {
        [accessGroup._id]: accessGroup
      };
    }

    this.emitChanges();
  }

  emitChanges() {
    const changedAccessGroups = Object.values(this.selectedAccessGroups);
    this.onChange(changedAccessGroups);
  }

  // Angular form methods
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(accessGroups: AccessGroup[]): void {
    this.selectedAccessGroups = accessGroups.reduce((acc, accessGroup) => {
      acc[accessGroup._id] = accessGroup;
      return acc;
    }, {}) as any;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const accessGroups = control.value;
    if (!accessGroups || this.status !== 'SUCCESS') {
      this.isValid = false;
      return {
        invalidValue: {
          accessGroups
        }
      };
    } else {
      this.isValid = true;
      return null;
    }
  }
}
