import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Camera } from '@api/model/camera';
import { retry, takeUntil } from 'rxjs/operators';
import { noop, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SystemLocalSettingsService } from '@api/serviceV2/system-local-settings.service';
import { webSocket } from 'rxjs/webSocket';
import { isElectron } from '@api/utils';

/**
 * Componente criado para exibir câmeras usando a biblioteca go2rtc
 * https://github.com/AlexxIT/go2rtc/
 * O código abaixo foi implementado com base em https://github.com/AlexxIT/go2rtc/blob/master/www/video-rtc.js
 */

@Component({
  selector: 'app-ecamera-camera-viewer',
  template: `
    <video
      #video
      autoplay
      playsinline
      preload="auto"
      muted
      (click)="click()"
      [style.display]="status === 'SUCCESS' || status === 'BUFFERING' ? 'block' : 'none'"></video>
    <p *ngIf="displayCameraName && status !== 'PROCESSING'" class="m-1 camera-name">{{ camera.name || '' }}</p>
    <app-skeleton class="placeholder" *ngIf="status === 'PROCESSING'"></app-skeleton>
  `,
  styleUrls: ['./ecamera-camera-viewer.component.scss']
})
export class EcameraCameraViewerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;

  @Input() camera: Camera = null;
  @Output() handleSnapshot: EventEmitter<string> = new EventEmitter();
  @Output() handleClick: EventEmitter<Camera> = new EventEmitter();
  @Output() handleSuccessLoad = new EventEmitter();
  @Output() handleError: EventEmitter<any> = new EventEmitter();
  @Input() displayCameraName = true;

  public status: 'PROCESSING' | 'SUCCESS' | 'ERROR' | 'BUFFERING' = 'PROCESSING';

  CODECS: string[] = [
    'avc1.640029', // H.264 high 4.1 (Chromecast 1st and 2nd Gen)
    'avc1.64002A', // H.264 high 4.2 (Chromecast 3rd Gen)
    'avc1.640033', // H.264 high 5.1 (Chromecast with Google TV)
    'hvc1.1.6.L153.B0', // H.265 main 5.1 (Chromecast Ultra)
    'mp4a.40.2', // AAC LC
    'mp4a.40.5', // AAC HE
    'flac', // FLAC (PCM compatible)
    'opus' // OPUS Chrome, Firefox
  ];

  /**
   * [config] Supported modes (webrtc, mse, mp4).
   * Podemos escolher o modo de conexão usando essa variável, até o momento não foi necessário fazer isso
   * @type {string}
   */
  mode = 'webrtc,mse,mp4';
  /**
   * [config] WebRTC configuration
   * @type {RTCConfiguration}
   */
  pcConfig = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    sdpSemantics: 'unified-plan' // important for Chromecast 1
  };

  /**
   * [info] WebRTC connection state.
   * @type {number}
   */
  pcState: number = WebSocket.CLOSED;

  /**
   * @type {RTCPeerConnection}
   */
  pc: RTCPeerConnection = null;

  /**
   * Variável usada pelo código para identificação de codecs, não remover
   * @type {string}
   */
  mseCodecs = '';

  rtspServerUrl;

  dataHandlers: Record<string, (data) => void> = {};
  processVideoImageCallback = null;

  wsConnection$ = null;
  private unsubscribe$: Subject<void> = new Subject();

  // Dependendo da tecnologia usada pelo player o video pausa quando a janela fica em segundo plano, o código abaixo detecta quando a janela volta a ser a janela ativa e dá play no video novamente
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (!document.hidden) {
      // because video autopause on disconnected from DOM
      if (this.video.nativeElement) {
        const seek = this.video.nativeElement.seekable;
        if (seek.length > 0) {
          this.video.nativeElement.currentTime = seek.end(seek.length - 1);
        }
        this.play();
      }
    }
  }

  isElectron = false;

  isWebRtcErrored = false;
  isMseErrored = false;
  lastMseMessageDate = null;

  constructor(
    private hostElement: ElementRef,
    private http: HttpClient,
    private systemLocalSettings: SystemLocalSettingsService,
    private renderer: Renderer2
  ) {
    this.isElectron = isElectron();
  }

  ngOnInit(): void {
    const settings = this.systemLocalSettings.getSystemLocalSettings();
    this.rtspServerUrl = `${settings.rtspServer.https ? 'wss' : 'ws'}://${settings.rtspServer.host?.trim()}:${settings.rtspServer.port}`;
    this.startCamera(this.camera);
  }

  ngOnDestroy(): void {
    this.pcState = WebSocket.CLOSED;
    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.camera.currentValue && this.rtspServerUrl) {
      this.startCamera(changes.camera.currentValue);
    }
  }

  startCamera(camera: Camera) {
    // this.video.nativeElement['background'] = false;
    const url = `${this.rtspServerUrl}/api/ws?src=${encodeURIComponent(camera?._id)}`;
    const proxyUrl = this.isElectron ? url : `ws://localhost:5053?${btoa(url)}`;
    this.unsubscribe$.next(null);
    this.wsConnection$ = webSocket({
      url: proxyUrl,
      binaryType: 'arraybuffer',
      deserializer: msg => msg,
      openObserver: {
        next: (e: Event) => {
          // Inicializa os handlers do socket, tem que ser declarado aqui para que a reconexão automática sempre funcione
          this.setDataHandlers();
          this.status = 'PROCESSING';
        }
      }
    });
    let timeout = null;
    this.wsConnection$.pipe(retry({ delay: 3000 }), takeUntil(this.unsubscribe$)).subscribe({
      next: ev => {
        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
          const now = new Date().getTime();

          this.isMseErrored = now - this.lastMseMessageDate >= 3000;

          if (this.isWebRtcErrored && this.isMseErrored) {
            this.setupMp4Protocol();
          }
        }, 3000);

        if (this.isWebRtcErrored && this.isMseErrored) {
          return;
        }

        if (typeof ev.data === 'string') {
          const msg = JSON.parse(ev.data);
          for (const mode in this.dataHandlers) {
            this.dataHandlers[mode](msg);
          }
        } else {
          this.processVideoImageCallback(ev.data);
        }
      }, // Called whenever there is a message from the server.
      error: err => {
        console.log(err);
      }, // Called if at any point WebSocket API signals some kind of error.
      complete: noop // Called when connection is closed (for whatever reason).
    });
  }

  getMseHandler() {
    const ms = new MediaSource();
    ms.addEventListener(
      'sourceopen',
      () => {
        URL.revokeObjectURL(this.video.nativeElement.src);
        this.wsConnection$.next({ type: 'mse', value: this.codecs('mse') } as any);
      },
      { once: true }
    );

    this.video.nativeElement.src = URL.createObjectURL(ms);
    this.video.nativeElement.srcObject = null;
    this.play();

    this.mseCodecs = '';

    const handler = msg => {
      if (msg.type !== 'mse') {
        return;
      }

      this.mseCodecs = msg.value;

      const sb = ms.addSourceBuffer(msg.value);
      sb.mode = 'segments'; // segments or sequence
      sb.addEventListener('updateend', () => {
        if (sb.updating) {
          return;
        }

        try {
          if (bufLen > 0) {
            const data = buf.slice(0, bufLen);
            bufLen = 0;
            sb.appendBuffer(data);
          } else if (sb.buffered && sb.buffered.length) {
            const end = sb.buffered.end(sb.buffered.length - 1) - 15;
            const start = sb.buffered.start(0);
            if (end > start) {
              sb.remove(start, end);
              ms.setLiveSeekableRange(end, end + 15);
            }
          }
        } catch (e) {
          // console.debug(e);
        }
      });

      const buf = new Uint8Array(2 * 1024 * 1024);
      let bufLen = 0;

      this.processVideoImageCallback = data => {
        if (sb.updating || bufLen > 0) {
          const b = new Uint8Array(data);
          buf.set(b, bufLen);
          bufLen += b.byteLength;
        } else {
          try {
            sb.appendBuffer(data);
          } catch (e) {
            // console.debug(e);
          }
        }
      };
    };
    return handler;
  }

  getWebrtcHandler() {
    const pc = new RTCPeerConnection(this.pcConfig);

    /** @type {HTMLVideoElement} */
    const webRtcVideoElement = this.renderer.createElement('video');
    webRtcVideoElement.addEventListener('loadeddata', ev => this.onPcVideo(ev), { once: true });

    pc.addEventListener('icecandidate', ev => {
      const candidate = ev.candidate ? ev.candidate.toJSON().candidate : '';
      this.wsConnection$.next({ type: 'webrtc/candidate', value: candidate });
    });

    pc.addEventListener('track', ev => {
      // when stream already init
      if (webRtcVideoElement.srcObject !== null) {
        return;
      }

      // when audio track not exist in Chrome
      if (ev.streams.length === 0) {
        return;
      }

      // when audio track not exist in Firefox
      if (ev.streams[0].id[0] === '{') {
        return;
      }

      webRtcVideoElement.srcObject = ev.streams[0];
    });

    pc.addEventListener('connectionstatechange', () => {
      if (pc.connectionState === 'failed' || pc.connectionState === 'disconnected') {
        pc.close(); // stop next events

        this.pcState = WebSocket.CLOSED;
        this.pc = null;
        // Reconecta a câmera quando a conexão do webrtc é retomada
        this.startCamera(this.camera);
      }
    });

    const handler = msg => {
      switch (msg.type) {
        case 'webrtc/candidate':
          pc.addIceCandidate({
            candidate: msg.value,
            sdpMid: '0'
          }).catch(() => console.debug);
          break;
        case 'webrtc/answer':
          pc.setRemoteDescription({
            type: 'answer',
            sdp: msg.value
          }).catch(() => console.debug);
          break;
        case 'error':
          if (msg.value.indexOf('webrtc/offer') < 0) {
            return;
          }
          pc.close();
      }
    };

    // Safari doesn't support "offerToReceiveVideo"
    pc.addTransceiver('video', { direction: 'recvonly' });
    pc.addTransceiver('audio', { direction: 'recvonly' });

    pc.createOffer().then(offer => {
      pc.setLocalDescription(offer).then(() => {
        this.wsConnection$.next({ type: 'webrtc/offer', value: offer.sdp });
      });
    });

    this.pcState = WebSocket.CONNECTING;
    this.pc = pc;
    return handler;
  }

  setMp4Handler() {
    /** @type {HTMLCanvasElement} **/
    const canvas = this.renderer.createElement('canvas');
    /** @type {CanvasRenderingContext2D} */
    let context;

    /** @type {HTMLVideoElement} */
    const videoElement = this.renderer.createElement('video');
    videoElement.autoplay = true;
    videoElement.playsInline = true;
    videoElement.muted = true;

    videoElement.addEventListener('loadeddata', ev => {
      if (!context) {
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        context = canvas.getContext('2d');
      }

      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      this.video.nativeElement.controls = false;
      this.video.nativeElement.poster = canvas.toDataURL('image/jpeg');
    });

    this.processVideoImageCallback = data => {
      videoElement.src = 'data:video/mp4;base64,' + customBtoa(data);
    };

    this.wsConnection$.next({ type: 'mp4', value: this.codecs('mp4') });
  }

  setDataHandlers() {
    if (this.mode.indexOf('mse') >= 0 && 'MediaSource' in window) {
      // iPhone
      this.dataHandlers.mse = this.getMseHandler();
    } else if (this.mode.indexOf('mp4') >= 0) {
      // Se for mp4 não precisa atribuir um handler no this.dataHandlers
      this.setMp4Handler();
    }

    if (this.mode.indexOf('webrtc') >= 0 && 'RTCPeerConnection' in window) {
      // macOS Desktop app
      this.dataHandlers.webrtc = this.getWebrtcHandler();
    }

    this.dataHandlers.stream = msg => {
      switch (msg.type) {
        case 'error':
          this.isWebRtcErrored = msg.value.startsWith('webrtc');

          if (this.isWebRtcErrored && this.isMseErrored) {
            this.setupMp4Protocol();
          }

          break;
        case 'mse':
        case 'mp4':
          this.handleSuccessLoad.emit();
          this.status = 'SUCCESS';
          break;
      }
    };
  }

  private onPcVideo(ev) {
    if (!this.pc) {
      return;
    }

    /** @type {HTMLVideoElement} */
    const videoElement = ev.target;
    const state = this.pc.connectionState;

    // Firefox doesn't support pc.connectionState
    if (state === 'connected' || state === 'connecting' || !state) {
      // Video+Audio > Video, H265 > H264, Video > Audio, WebRTC > MSE
      let rtcPriority = 0,
        msePriority = 0;

      /** @type {MediaStream} */
      const ms = videoElement.srcObject;
      if (ms.getVideoTracks().length > 0) {
        rtcPriority += 0x220;
      }
      if (ms.getAudioTracks().length > 0) {
        rtcPriority += 0x102;
      }

      if (this.mseCodecs.indexOf('hvc1.') >= 0) {
        msePriority += 0x230;
      }
      if (this.mseCodecs.indexOf('avc1.') >= 0) {
        msePriority += 0x210;
      }
      if (this.mseCodecs.indexOf('mp4a.') >= 0) {
        msePriority += 0x101;
      }

      if (rtcPriority >= msePriority) {
        this.video.nativeElement.srcObject = ms;
        this.play();

        this.pcState = WebSocket.OPEN;
        this.unsubscribe$.next();
      } else {
        this.pcState = WebSocket.CLOSED;
        this.pc.close();
        this.pc = null;
      }
    }

    videoElement.srcObject = null;
    if (this.pcState !== WebSocket.CLOSED) {
      this.handleSuccessLoad.emit();
      this.status = 'SUCCESS';
    }
  }

  /**
   * Play video. Support automute when autoplay blocked.
   * https://developer.chrome.com/blog/autoplay/
   */
  private play() {
    this.video.nativeElement.play().catch(er => {
      if (er.name === 'NotAllowedError' && !this.video.nativeElement.muted) {
        this.video.nativeElement.muted = true;
        this.video.nativeElement.play().catch(() => console.debug);
      }
    });
  }

  private codecs(type) {
    const test =
      type === 'mse'
        ? codec => MediaSource.isTypeSupported(`video/mp4; codecs="${codec}"`)
        : codec => this.video.nativeElement.canPlayType(`video/mp4; codecs="${codec}"`);
    return this.CODECS.filter(test).join();
  }

  public async takeSnapshot() {
    const canvas = this.renderer.createElement('canvas');
    canvas.width = this.video.nativeElement.videoWidth;
    canvas.height = this.video.nativeElement.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(this.video.nativeElement, 0, 0, canvas.width, canvas.height);
    // const dataURI = canvas.toDataURL('image/jpeg'); // can also use 'image/png'
    const snapshotBase64 = canvas.toDataURL('image/png'); // can also use 'image/png'
    return snapshotBase64;
  }

  public click() {
    this.handleClick.next(this.camera);
  }

  private setupMp4Protocol() {
    ['error'].forEach(event => {
      this.video.nativeElement.addEventListener(event, () => {
        this.status = 'ERROR';
        this.handleError.emit('Não foi possível carregar a imagem da câmera em MP4');
      });
    });

    ['play', 'playing'].forEach(event => {
      this.video.nativeElement.addEventListener(event, () => {
        this.status = 'SUCCESS';
        this.handleSuccessLoad.emit();
      });
    });

    ['loadstart', 'waiting'].forEach(event => {
      this.video.nativeElement.addEventListener(event, () => {
        this.status = 'BUFFERING';
      });
    });

    this.video.nativeElement.src = `http://localhost:1984/api/stream.mp4?src=${this.camera._id}`;
    this.play();
  }
}

const customBtoa = buffer => {
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  let binary = '';
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
