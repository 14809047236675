/**
 * Created by Rafael on 12/12/2016.
 */
import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { GateOccurrence } from '../../../api/model/occurrence/occurrence.gate';

import swal from 'sweetalert2';
import { UtilService } from '../../../services/util.service';
import { OccurrenceService } from '../../../api/service/occurrence.service';
import { Occurrence } from '../../../api/model/interface/occurrence';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { FileService } from '../../../api/service/file.service';

import * as _ from 'lodash';
import { File } from '../../../api/model/file';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'modal-close-noise',
  templateUrl: 'modal.close.noise.html'
})
export class ModalCloseNoiseComponent {
  @ViewChild('closeNoiseModal', { static: true }) public closeNoiseModal: ModalDirective;
  // Ocorrência original fornecida através do @Input
  @Input() public originalOccurrence: GateOccurrence;
  // Evento disparado quando o dado da ocorrência é alterado
  @Output() dataChanged = new EventEmitter();

  // Objeto responsável pelo conteúdo do modal
  public occurrence: GateOccurrence;

  public occurrenceForm: UntypedFormGroup;
  public closureDetails: AbstractControl;

  public attachments = [];

  public condo;
  public user;
  public isSubmiting = false;

  constructor(private occurrenceService: OccurrenceService, private utilService: UtilService, private formBuilder: UntypedFormBuilder) {
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();

    this.occurrenceForm = formBuilder.group({
      closureDetails: ['', Validators.compose([Validators.required, Validators.minLength(3)])]
    });
    this.closureDetails = this.occurrenceForm.controls['closureDetails'];
  }

  closeModal(): void {
    this.closeNoiseModal.hide();
  }

  showModal() {
    this.closureDetails.markAsUntouched();
    this.closureDetails.markAsPristine();
    this.closureDetails.setValue('');
    this.occurrence = _.cloneDeep(this.originalOccurrence);
    this.attachments = this.occurrence.subTypeAttachments ? this.occurrence.subTypeAttachments.map(file => new File(file)) : [];
    this.closeNoiseModal.show();
  }

  closeOccurrence() {
    if (this.closureDetails.invalid) {
      this.closureDetails.markAsTouched();
      return;
    }

    swal({
      title: `Deseja realmente fechar a ocorrência?`,
      text: 'Depois de fechada esta ocorrência não poderá ser editada, apenas visualizada',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then(
      success => {
        this.saveOccurrence();
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  saveOccurrence(customErrorCallback?) {
    this.isSubmiting = true;
    // this.occurrence.status = Occurrence.STATUS.CLOSED;
    this.occurrence.closingDetails = this.closureDetails.value;
    this.occurrence.subTypeAttachments = this.attachments;

    const updatedOccurrence = {
      status: this.occurrence.status,
      closingDetails: this.occurrence.closingDetails,
      subTypeAttachments: this.occurrence.subTypeAttachments.map(att => att.id)
    };

    this.occurrenceService
      .updateOcurrence(this.condo._id, this.occurrence.id, updatedOccurrence)
      .pipe(timeout(10000))
      .subscribe(
        resp => {
          this.isSubmiting = false;
          this.occurrence.subTypeAttachments = this.attachments;
          this.dataChanged.emit(this.occurrence);
          this.closeModal();
        },
        err => {
          this.isSubmiting = false;
          swal({
            title: `Ops...`,
            text: 'Não foi possível atualizar a ocorrência, tente novamente.',
            cancelButtonColor: '#f53d3d'
          });
        }
      );
  }
}
