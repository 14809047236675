import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '../../api/model/condo';
import { Pet, PET_SEX_LABELS, PET_SIZES_LABELS, PET_SPECIES_LABELS } from '../../api/model/pet';
import { Status } from '../../api/model/status';
import { CondoService } from '../../api/service/condo.service';
import { PetService } from '../../api/service/pet.service';
import { Residence } from '../../api/model/interface/residence';
import { ResidenceService } from '../../api/service/residence.service';
import * as moment from 'moment';
import { KeyValue } from '@angular/common';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'modal-create-pet',
  templateUrl: 'modal-create-pet.html',
  styleUrls: ['./modal-create-pet.scss']
})
export class ModalCreatePet implements OnInit {
  PET_SPECIES_LABELS = PET_SPECIES_LABELS;
  PET_SEX_LABELS = PET_SEX_LABELS;
  PET_SIZES_LABELS = PET_SIZES_LABELS;

  petResidence: Residence;

  isAdmin = false;
  condo: Condo;
  pet: Pet;
  status: Status = new Status();

  form: UntypedFormGroup;

  name: AbstractControl;
  birthDate: AbstractControl;
  specie: AbstractControl;
  breed: AbstractControl;
  size: AbstractControl;
  sex: AbstractControl;
  picture: AbstractControl;
  residence: AbstractControl;

  onCreate: Function = () => {};

  onUpdate: Function = () => {};

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private condoService: CondoService,
    private utilService: UtilService,
    private petService: PetService,
    private residenceService: ResidenceService,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group({
      name: [''],
      specie: ['', Validators.required],
      birthDate: [''],
      breed: [],
      sex: ['', Validators.required],
      size: ['', Validators.required],
      residence: [null, Validators.required],
      picture: [null]
    });

    this.name = this.form.get('name');
    this.birthDate = this.form.get('birthDate');
    this.specie = this.form.get('specie');
    this.breed = this.form.get('breed');
    this.sex = this.form.get('sex');
    this.size = this.form.get('size');
    this.residence = this.form.get('residence');
    this.picture = this.form.get('picture');

    const user = this.utilService.getLocalUser();
    this.isAdmin = user.isAdmin() || user.isOwner();
  }

  ngOnInit() {
    if (this.pet) {
      this.name.setValue(this.pet.name || '');
      this.specie.setValue(this.pet.specie || '');
      this.breed.setValue(this.pet.breed || '');
      this.size.setValue(this.pet.size || '');
      this.sex.setValue(this.pet.sex || '');
      this.birthDate.setValue(this.pet.birthDate ? moment(this.pet.birthDate).utc().format('YYYY-MM-DD') : '');
      this.residence.setValue(this.pet.residence || '');
      this.picture.setValue(this.pet.picture || '');
    } else if (this.petResidence) {
      this.residence.setValue(this.petResidence || '');
    }
  }

  selectResidence(residence) {
    this.residence.setValue(residence);
  }

  submit() {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const pet: any = {
        specie: this.specie.value,
        name: this.name.value || '',
        birthDate: this.birthDate.value || '',
        picture: this.picture.value?._id || null,
        sex: this.sex.value || '',
        breed: this.breed.value || '',
        size: this.size.value || ''
      };

      if (this.pet) {
        this.update(pet);
      } else {
        this.create(pet);
      }
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha os campos obrigatórios.');
    }
  }

  create(pet) {
    this.residenceService.addPet(this.condo._id, this.residence.value._id, pet).subscribe(
      (res: any) => {
        const p = new Pet({ ...pet, residence: this.residence.value, picture: this.picture.value, _id: res._id });
        this.onCreate(p);
        this.bsModalRef.hide();
        this.toastr.success('Pet cadastrado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível cadastrar o pet, tente novamente');
      }
    );
  }

  update(pet) {
    let subscription;
    if (this.petResidence) {
      subscription = this.residenceService.updatePet(this.condo._id, this.petResidence._id, this.pet._id, pet);
    } else {
      pet.residence = this.residence.value._id;
      subscription = this.petService.update(this.condo._id, this.pet._id, pet);
    }

    subscription.subscribe(
      (res: any) => {
        const p = new Pet({
          ...this.pet,
          ...pet,
          residence: this.residence.value,
          picture: this.picture.value
        });
        this.onUpdate(p);
        this.bsModalRef.hide();
        this.toastr.success('Pet atualizado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível atualizar o pet, tente novamente');
      }
    );
  }

  defaultSortFunction = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    return 0;
  };
}
