import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilService } from '../../services/util.service';
import { AccessGroup } from '@api/model/hardware/access-group';

@Component({
  selector: 'app-modal-select-access-group',
  templateUrl: 'modal-select-access-group.component.html',
  styleUrls: ['modal-select-access-group.component.scss']
})
export class ModalSelectAccessGroup {
  groups: AccessGroup[] = [];
  defaultGroup = null;

  callback: { success?: Function; error?: Function };

  constructor(public bsModalRef: BsModalRef, public utilService: UtilService) {
    this.defaultGroup = this.utilService.getLocalDefaultIncontrolGroup();
  }

  selectGroup(group: AccessGroup) {
    this.utilService.saveLocalDefaultIncontrolGroup(group._id);
    if (this.callback) {
      this.callback.success(group);
    }
    this.bsModalRef.hide();
  }
}
