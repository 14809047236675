import { BackObject } from './interface/back.object';
import { User } from './user';
import * as moment from 'moment';

/**
 * Created by Rafael on 11/09/2017.
 */
export class Coupon implements BackObject {
  static DISCOUNT = {
    TWENTY: 20,
    THIRTY: 30,
    FOURTY: 40,
    FIFTY: 50
  };

  static STATUS = {
    AVAILABLE: 'AVAILABLE',
    DISABLED: 'DISABLED'
  };

  private _id = '';
  private _user: User = null;
  private _vendorCode: string = '';
  private _couponCode: string = '';
  private _validUntil = '';
  private _discount: number = Coupon.DISCOUNT.TWENTY;
  private _status: string = Coupon.STATUS.AVAILABLE;
  private _availableQuantity: number = 0;
  private _usedQuantity: number = 0;
  private _createdBy: User = null;
  private _updatedBy: User = null;
  private _createdAt = '';
  private _updatedAt = '';

  constructor(coupon?) {
    if (coupon) {
      if (typeof coupon === 'string') {
        this._id = coupon;
      } else {
        this._id = coupon._id || this._id;
        this._user = coupon.user ? new User(coupon.user) : null;
        this._vendorCode = coupon.vendorCode || this._vendorCode;
        this._couponCode = coupon.couponCode || this._couponCode;
        this._validUntil = coupon.validUntil || this._validUntil;
        this._discount = coupon.discount || this._discount;
        this._status = coupon.status || this._status;
        this._availableQuantity = coupon.availableQuantity || this._availableQuantity;
        this._usedQuantity = coupon.usedQuantity || this._usedQuantity;
        this._createdBy = coupon.createdBy ? new User(coupon.createdBy) : this._createdBy;
        this._updatedBy = coupon.updatedBy ? new User(coupon.updatedBy) : this._updatedBy;
        this._createdAt = coupon.createdAt || this._createdAt;
        this._updatedAt = coupon.updatedAt || this._updatedAt;
      }
    }
  }

  createBackObject() {
    let coupon: any = {};
    coupon.user = this.user.id;
    coupon.vendorCode = this.vendorCode;
    coupon.couponCode = this.couponCode;
    coupon.validUntil = this.validUntil;
    coupon.discount = this.discount;
    coupon.status = this.status;
    coupon.availableQuantity = this.availableQuantity;
    return coupon;
  }

  isExpired() {
    return moment().isAfter(moment(this.validUntil));
  }

  isAvailable() {
    return this.status === Coupon.STATUS.AVAILABLE;
  }

  isDisabled() {
    return this.status === Coupon.STATUS.DISABLED;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
  }

  get vendorCode(): string {
    return this._vendorCode;
  }

  set vendorCode(value: string) {
    this._vendorCode = value;
  }

  get couponCode(): string {
    return this._couponCode;
  }

  set couponCode(value: string) {
    this._couponCode = value;
  }

  get validUntil(): string {
    return this._validUntil;
  }

  set validUntil(value: string) {
    this._validUntil = value;
  }

  get discount(): number {
    return this._discount;
  }

  set discount(value: number) {
    this._discount = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  get availableQuantity(): number {
    return this._availableQuantity;
  }

  set availableQuantity(value: number) {
    this._availableQuantity = value;
  }

  get usedQuantity(): number {
    return this._usedQuantity;
  }

  set usedQuantity(value: number) {
    this._usedQuantity = value;
  }

  get createdBy(): User {
    return this._createdBy;
  }

  set createdBy(value: User) {
    this._createdBy = value;
  }

  get updatedBy(): User {
    return this._updatedBy;
  }

  set updatedBy(value: User) {
    this._updatedBy = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }
}
