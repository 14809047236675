import { Residence } from './interface/residence';
import { ResidenceBuilder } from './residence/residence.builder';
import { File } from './file';
import { User } from '@api/model/user';
import * as moment from 'moment';

export const DEPENDENT_ALLOW_VISITORS = ['NO', 'ONLY_ENTRY', 'ONLY_EXIT', 'ENTRY_AND_EXIT'] as const;

export class Dependent {
  _id?: string;
  name?: string;
  birthDate?: string | Date;
  age?: string;
  kinship?: string;
  rg?: string;
  cpf?: string;
  phone?: string;
  specialNeeds?: boolean;
  specialNeedsDetails?: string;
  externalId?: string;
  picture: File;
  residence: Residence;
  allowVisitors: (typeof DEPENDENT_ALLOW_VISITORS)[number];
  maskedValues;
  isDataMasked: {
    birthDate: boolean;
    phone: boolean;
    rg: boolean;
    cpf: boolean;
  };
  createdBy: User;
  createdAt: string;
  room: string;

  constructor(dependent) {
    if (typeof dependent == 'string') {
      this._id = dependent;
    } else if (dependent) {
      this._id = dependent._id || '';
      this.name = dependent.name || '';
      this.birthDate = dependent.birthDate || '';
      this.age = dependent.age || '';
      this.kinship = dependent.kinship || '';
      this.rg = dependent.rg || '';
      this.cpf = dependent.cpf || '';
      this.phone = dependent.phone || '';
      this.specialNeeds = dependent.specialNeeds || false;
      this.specialNeedsDetails = dependent.specialNeedsDetails || '';
      this.externalId = dependent.externalId || '';
      this.picture = dependent.picture ? new File(dependent.picture) : null;
      this.residence = ResidenceBuilder.build(dependent.residence);
      this.allowVisitors = dependent.allowVisitors;
      this.maskedValues = {
        cpf: dependent.cpf,
        rg: dependent.rg,
        phone: dependent.phone,
        birthDate: dependent.birthDate
      };
      this.isDataMasked = {
        birthDate: dependent.birthDate?.includes('3000'),
        cpf: dependent.cpf?.includes('*'),
        phone: dependent.phone?.includes('*'),
        rg: dependent.rg?.includes('*')
      };
      this.createdBy = (dependent.createdBy && new User(dependent.createdBy)) || null;
      this.createdAt = dependent.createdAt || this.createdAt;
      this.room = dependent.room || '';

      if (dependent.age) {
        this.age = dependent.age;
      } else if (dependent.birthDate && !this.isDataMasked.birthDate) {
        const birthDate = moment(this.birthDate);
        const age = moment().diff(birthDate, 'years');
        this.age = age.toString();
      } else {
        this.age = '';
      }
    }
  }

  public maskSensitiveData(key?) {
    if (key) {
      this[key] = this.maskedValues[key];
    } else {
      Object.keys(this.maskedValues).forEach(key => (this[key] = this.maskedValues[key]));
    }
  }
}

export const ALLOW_VISITORS_ACCESS: Record<Dependent['allowVisitors'], Record<'label' | 'badgeClass', string>> = {
  ENTRY_AND_EXIT: { label: 'Entrada e Saída', badgeClass: 'badge-subtle-primary' },
  ONLY_ENTRY: { label: 'Entrada', badgeClass: 'badge-subtle-success' },
  ONLY_EXIT: { label: 'Saída', badgeClass: 'badge-subtle-danger' },
  NO: { label: 'Não', badgeClass: 'badge-subtle-secondary' }
};
