import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '../../../../api/model/condo';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { KeyService } from '../../../../api/service/key.service';
import { BuildKey, Key, KEY_STATUS } from '../../../../api/model/key';
import { Status } from '../../../../api/model/status';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'condo-create-keys',
  templateUrl: 'modal.create.key.html'
})
export class ModalCondoCreateKey implements OnInit {
  condo: Condo;
  key: Key;
  callbacks: { success?: Function; error?: Function };

  form: UntypedFormGroup;

  name: AbstractControl;
  code: AbstractControl;
  gate: AbstractControl;

  status: Status = new Status();

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private keyService: KeyService,
    private toastrService: ToastrService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      gate: true,
      code: ['']
    });
    this.name = this.form.get('name');
    this.gate = this.form.get('gate');
    this.code = this.form.get('code');
  }

  ngOnInit() {
    if (this.key) {
      this.name.setValue(this.key.name);
      this.code.setValue(this.key.code);
      this.gate.setValue(this.key.gate);
    }
  }

  save(value) {
    if (this.form.valid) {
      this.status.setAsProcessing();
      let subscription;
      if (this.key) {
        subscription = this.keyService.updateKey(this.condo.id, this.key.id, value);
      } else {
        subscription = this.keyService.createKey(this.condo.id, value);
      }
      subscription.subscribe(
        (res: { _id: string }) => {
          let storedKey;
          if (this.key) {
            this.toastrService.success('Chave atualizada com sucesso');
            storedKey = BuildKey({ ...this.key, ...value });
          } else {
            this.toastrService.success('Chave registrada com sucesso');
            storedKey = BuildKey({ ...value, status: KEY_STATUS.AVAILABLE, _id: res._id });
          }
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(storedKey);
          }
          this.bsModalRef.hide();
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.toastrService.warning('Preencha todos os campos');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }
}
