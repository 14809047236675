import { Occurrence } from '../interface/occurrence';
import { User } from '../user';

/**
 * Created by Rafael on 23/12/2016.
 */

export class PrivateOccurrence extends Occurrence {
  constructor(occurrence) {
    super(occurrence);
  }

  get statusLabel() {
    if (this.status === Occurrence.STATUS.CLOSED) {
      return 'Encerrada';
    } else {
      return Occurrence.STATUS_lABEL[this.status];
    }
  }

  // Criador e não fechada
  canEdit(user: User = new User()): boolean {
    return this.isCreatedBy(user.id) && !this.isClosed();
  }

  // Admin e Criador não fechada
  canDelete(user: User = new User()): boolean {
    return (user.isOwner() || user.isAdmin() || this.isCreatedBy(user.id)) && !this.isClosed();
  }

  // Ninguém
  canCancel(user: User = new User()): boolean {
    return false;
  }

  // Admin, Criador e não fechada
  canClose(user: User = new User()): boolean {
    return (user.isOwner() || user.isAdmin() || this.isCreatedBy(user.id)) && !this.isClosed();
  }
}
