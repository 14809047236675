import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { from, Observable, Subscriber } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../services/util.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Residence } from '@api/model/interface/residence';
import { ResidenceServiceV2 } from '@api/serviceV2/residence.service';

@Component({
  selector: 'app-residence-autocomplete',
  templateUrl: './residence-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ResidenceAutocompleteComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ResidenceAutocompleteComponent
    }
  ]
})
export class ResidenceAutocompleteComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator {
  @Input() label = '';
  @Input() shouldValidate = true;

  dataSource$: Observable<Residence[]>;
  textSearch;

  condo: Condo;

  status: 'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS' = 'IDLE';

  residence: Residence;
  noResult = false;

  touched = false;

  isDisabled = false;

  isValid = true;

  onChange = value => {};

  onTouched = () => {};

  constructor(private residenceService: ResidenceServiceV2, private utilService: UtilService) {}

  ngOnInit(): void {
    this.condo = this.utilService.getLocalCondo();

    this.dataSource$ = new Observable((observer: Subscriber<string>) => observer.next(this.textSearch)).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      tap(() => this.markAsTouched()),
      mergeMap((token: string) => {
        const query: EcondosQuery = { $select: 'identification' };
        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(e => {
            this.status = 'ERROR';
            return from([]);
          })
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {}

  onSelect(event: TypeaheadMatch): void {
    if (!this.isDisabled) {
      this.residence = event.item;
      this.markAsTouched();
      this.onChange(this.residence);
    }
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
  }

  clearValue() {
    this.residence = null;
    this.textSearch = '';
    this.noResult = false;
    this.onChange(null);
  }

  // Angular form methods
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(residence: Residence): void {
    this.residence = residence;
    if (residence) {
      this.textSearch = residence.identification || '';
    } else {
      this.textSearch = '';
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.shouldValidate) {
      return null;
    }
    const residence = control.value;
    if (!this.textSearch && residence) {
      this.isValid = false;
      return {
        residenceNotSelected: {
          residence
        }
      };
    } else {
      this.isValid = true;
      return null;
    }
  }
}
