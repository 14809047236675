import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { CreateCondoModal } from './create.condo.modal';
import { CondoService } from '@api/service/condo.service';
import { FormControl, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CondoRequest } from '@api/model/condo-request';
import { UtilService } from 'app/services/util.service';

@Component({
  selector: 'condo-picker-modal',
  templateUrl: 'condo-picker-modal.component.html',
  styleUrls: ['./condo-picker-modal.component.scss']
})
export class CondoPickerModalComponent {
  @ViewChild('condoPickerModal', { static: true }) condoPickerModal: ModalDirective;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCondoSelection = new EventEmitter();

  selectedCondo: FormControl<Condo> = new FormControl(null, Validators.required);

  public addingUserOnCondo: boolean;
  public shouldShowInfoCard;

  public condosToHide = [];

  public onCondoCreation: Function;

  constructor(
    private condoService: CondoService,
    private modalService: BsModalService,
    private utilService: UtilService
  ) {
    this.selectedCondo.valueChanges
      .pipe(
        switchMap(() => {
          if (this.selectedCondo.value) {
            const query = '?$select=customLabels name address city state params';
            return this.condoService.getCondoById(this.selectedCondo.value._id, query);
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: condo => {
          this.selectedCondo.setValue(condo, { emitEvent: false });
        }
      });

    this.onCondoCreation = condoData => {
      this.showSpinner();

      const addNewCondoToList = condo => {
        this.selectedCondo.setValue(condo);
        this.returnCondo(condo);
      };

      addNewCondoToList(condoData);
    };
  }

  createNewCondo() {
    const initialState = {
      onCondoCreationSuccess: condoData => this.onCondoCreation(condoData)
    };
    this.modalService.show(CreateCondoModal, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  closeModal(): void {
    this.hideSpinner();
    this.condoPickerModal.hide();
  }

  showModal(condosIdToHide = []) {
    this.showInfoCard();
    this.selectedCondo.reset();
    // this.selectedCondo = new FormControl();
    this.condosToHide = condosIdToHide;
    this.condoPickerModal.show();
  }

  returnCondo(condo) {
    this.showSpinner();
    const condoRequest: CondoRequest = {
      condo,
      status: 'PENDING',
      user: this.utilService.getLocalUser(),
      attachments: [],
      obs: ''
    };
    this.onCondoSelection.emit(condoRequest);
    this.condoPickerModal.hide();
  }

  showInfoCard() {
    this.shouldShowInfoCard = true;
  }

  hideInfoCard() {
    this.shouldShowInfoCard = false;
  }

  showSpinner() {
    this.addingUserOnCondo = true;
  }

  hideSpinner() {
    this.addingUserOnCondo = false;
  }
}
