import { Action, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { routerReducer } from '@ngrx/router-store';

export interface AppState {}

export enum ActionTypes {
  LOGOUT = '[App State] Logout'
}

export class LogoutAction implements Action {
  readonly type = ActionTypes.LOGOUT;
}

export const reducers: ActionReducerMap<any> = {
  router: routerReducer
};

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === ActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze, clearState] : [clearState];
