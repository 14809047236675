import { Component } from '@angular/core';
import { CondoContact } from '@api/model/contact/condo.contact';
import { ContactID } from '@api/model/contact/contact.id';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-select-duplicated-condo-contact',
  templateUrl: 'modal-select-duplicated-condo-contact.component.html',
  styleUrls: ['modal-select-duplicated-condo-contact.component.scss']
})
export class ModalSelectDuplicatedCondoContactComponent {
  public docType: keyof typeof ContactID.TYPES;
  public docNumber = '';
  public contacts: CondoContact[] = [];

  public DOC_TYPES_LABELS = ContactID.TYPES_LABEL;

  public onContactSelected: (selectedContact: CondoContact) => void;

  constructor(public bsModalRef: BsModalRef) {}

  public handleSelectContact(contact: CondoContact): void {
    if (this.onContactSelected) {
      this.onContactSelected(contact);
    }

    this.bsModalRef.hide();
  }
}
