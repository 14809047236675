/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'modal-confirm',
  templateUrl: 'confirm.modal.html'
})
export class ModalConfirmComponent {
  @ViewChild('confirmModal', { static: true }) public confirmModal: ModalDirective;
  public okText = 'Sim';
  public cancelText = 'Não';
  public title = 'Confirmar';
  public message = 'Deseja continuar com esta ação?';
  public param;
  public context;

  constructor() {}

  setOkText(okText) {
    this.okText = okText;
  }

  setCancelText(cancelText) {
    this.cancelText = cancelText;
  }

  setTitle(title) {
    this.title = title;
  }

  setMessage(message) {
    this.message = message;
  }

  /**
   * Define o callback do botão "SIM" do modal
   * @param yesCallback Função do callback
   * @param param Parâmetro a ser passado para o callback
   * @param context Contexto em que a função deve ser executada
   */
  setYesCallback(yesCallback, param, context) {
    this.yesCallback = yesCallback;
    this.param = param;
    this.context = context;
  }

  yesCallback() {
    console.log('clicked yes');
    this.closeModal();
  }

  noClick() {
    console.log('clicked no');
    this.closeModal();
  }

  yesClick() {
    this.yesCallback.call(this.context, this.param);
    this.closeModal();
  }

  closeModal(): void {
    this.confirmModal.hide();
  }

  showModal() {
    this.confirmModal.show();
  }
}
