import { Error } from './error';
export class TimeoutError extends Error {
  constructor() {
    super();

    this.messageTitle = 'Tempo máximo de requisição excedido';
    this.message = 'Verifique a qualidade de sua conexão e tente novamente.';
    this.name = 'Timeout Error';
    this.type = Error.TYPES.TIMEOUT_ERROR;
    this.originalErrorMessage = 'timeout';
  }
}
