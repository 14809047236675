import { Component, OnInit } from '@angular/core';
import { Status } from '@api/model/status';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ResidenceService } from '@api/service/residence.service';
import { EcondosQuery } from '@api/model/query';
import * as moment from 'moment';
import { UtilService } from '../../../../services/util.service';
import { map, timeout } from 'rxjs/operators';
import { BusinessResidence } from '@api/model/residence/residence.business';
import { formatCnpj } from '@api/util/formatters';
import { generateReport } from '../../../../reports/report-generator';
import { Condo } from '@api/model/condo';
import { capitalize } from '@api/util/util';
import { Residence } from '@api/model/interface/residence';

@Component({
  selector: 'app-modal-terminated-contracts-report',
  templateUrl: 'modal-terminated-contracts-report.component.html',
  styleUrls: ['modal-terminated-contracts-report.component.scss']
})
export class ModalTerminatedContractsReportComponent implements OnInit {
  condo: Condo;
  isBusinessCondo = false;

  expiredContractResidences: (BusinessResidence | Residence)[] = [];

  formatCnpj = formatCnpj;

  status: Status = new Status();

  callbacks: { success?: Function; error?: Function };

  constructor(public bsModalRef: BsModalRef, private residenceService: ResidenceService, private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
    this.isBusinessCondo = this.condo.isBusinessCondo();
  }

  ngOnInit(): void {
    this.getResidences();
  }

  getResidences() {
    this.status.setAsProcessing();
    const query: EcondosQuery = {
      $and: [{ 'contract.endDate': { $lte: moment().startOf('d').toDate() } }],
      $sort: 'contract.endDate'
    };
    const condoId: string = this.utilService.getLocalCondo()?._id;
    this.residenceService
      .getResidences(condoId, query)
      .pipe(
        timeout(10000),
        map(data => {
          if (this.isBusinessCondo) {
            data.residences = data.residences as BusinessResidence[];
          }
          return data;
        })
      )
      .subscribe(
        data => {
          this.status.setAsSuccess();
          this.expiredContractResidences = data.residences;
        },
        error => {
          this.status.setAsError();
          console.log(error);
        }
      );
  }

  goToResidence(residenceId) {
    this.callbacks.success(residenceId);
    this.closeModal();
  }

  printTerminatedContractsReport() {
    const condo = this.utilService.getLocalCondo();
    const user = this.utilService.getLocalUser();
    const title = `Contratos finalizados - ${condo?.name}`;
    const fields = [
      { label: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade', value: 'identification' },
      { label: 'Empresa', value: 'company' },
      { label: 'CNPJ', value: 'cnpj' },
      { label: 'Locatário', value: 'lessee' },
      { label: 'Contrato', value: 'contract' },
      { label: 'Observações', value: 'obs' }
    ];
    const data = this.expiredContractResidences.map(res => {
      let company = '';
      let cnpj = '';
      if (res instanceof BusinessResidence) {
        company = res.company || 'Não informado';
        cnpj = res.cnpj ? formatCnpj(res.cnpj) : 'Não informado';
      } else {
        company = '-';
        cnpj = '-';
      }

      return {
        identification: res.identification,
        lessee: res.lessee || 'Não informado',
        contract: `${moment(res.contract?.startDate).format('DD/MM/YYYY')} - ${moment(res.contract?.endDate).format('DD/MM/YYYY')}`,
        obs: res.obs || 'Não informado',
        company,
        cnpj
      };
    });
    generateReport(title, fields, data, user, { orientation: 'PORTRAIT' });
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
