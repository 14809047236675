/**
 * Created by Rafael on 13/12/2016.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UtilService } from '../services/util.service';

@Injectable()
export class LoggedGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.utilService.getLocalUser();
    if (user && user.signupStep != 1 && (this.utilService.getLocalAuthorizationHeader() || this.utilService.getLocalToken())) {
      return true;
    }
    const redirect = state.url || '';
    this.router.navigate(['/login', { redirect }]);
    return false;
  }
}
