import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { VehicleCreateModal } from '../vehicle.create.modal/vehicle.create.modal';
import { Status } from '../../../api/model/status';
import { VehicleService } from '../../../api/service/vehicle.service';
import { Condo } from '../../../api/model/condo';
import { UtilService } from '../../../services/util.service';
import { CondoVehicle } from '../../../api/model/condo.vehicle';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'vehicle-pick-modal',
  templateUrl: 'vehicle.pick.modal.html'
})
export class VehiclePickModal {
  @ViewChild('vehiclePickModal', { static: true }) vehiclePickModal: ModalDirective;
  @ViewChild('vehicleCreateModal', { static: true }) vehicleCreateModal: VehicleCreateModal;

  @Output() onVehicleSelected = new EventEmitter();

  public condo: Condo;
  public vehiclesList: CondoVehicle[];
  public selectedVehicle: CondoVehicle;

  vehicleCreatedCallback: Function;
  vehicleUpdatedCallback: Function;

  public status: Status = new Status();

  public noImageAvailableUrl = '../../../../assets/img/no-image.jpg';

  constructor(private vehicleService: VehicleService, private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();

    this.initializeCallbacks();
  }

  selectVehicle(vehicle: CondoVehicle) {
    if (this.selectedVehicle && this.selectedVehicle.id == vehicle.id) {
      this.selectedVehicle = undefined;
    } else {
      this.selectedVehicle = vehicle;
    }
  }

  getData(vehiclesIdToHide) {
    this.unselectVehicle();
    this.status.setAsDownloading();
    this.vehicleService
      .getVehiclesFromCondo(this.condo._id, this.getQueryString())
      .pipe(timeout(10000))
      .subscribe(
        vehicles => {
          this.vehiclesList = this.hideVehicles(vehicles, vehiclesIdToHide);
          this.status.setAsSuccess();
        },
        err => {
          this.status.setAsError();
        }
      );
  }

  hideVehicles(vehicles: Array<CondoVehicle>, vehiclesIdToHide = []) {
    return vehicles.filter(vehicle => {
      return vehiclesIdToHide.indexOf(vehicle._id) == -1;
    });
  }

  getQueryString() {
    const qs = [];

    qs[qs.length] = `?$populate[0][path]=pictures`;
    qs[qs.length] = `$populate[0][select]=url thumbnail`;

    return qs.join('&');
  }

  initializeCallbacks() {
    this.vehicleCreatedCallback = (vehicle: CondoVehicle) => {
      this.vehiclesList.unshift(vehicle);
      this.selectedVehicle = this.vehiclesList[0];
      window.scrollTo(0, 0);
    };

    this.vehicleUpdatedCallback = (vehicle: CondoVehicle) => {
      // Finds and replace vehicle on list
      const index = this.vehiclesList.findIndex(obj => vehicle._id == obj._id);
      this.vehiclesList[index] = vehicle;
      this.selectedVehicle = this.vehiclesList[index];
    };
  }

  showModal(vehiclesIdToHide?) {
    this.getData(vehiclesIdToHide);
    this.vehiclePickModal.show();
  }

  unselectVehicle() {
    this.selectedVehicle = undefined;
  }

  closeModal() {
    this.vehiclePickModal.hide();
  }

  createEditVehicle() {
    if (this.selectedVehicle) {
      this.vehicleCreateModal.editVehicle(this.selectedVehicle);
    } else {
      this.vehicleCreateModal.createVehicle();
    }
  }

  onSubmit() {
    this.onVehicleSelected.emit(this.selectedVehicle);
    this.closeModal();
  }
}
