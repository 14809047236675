export abstract class Error {
  static TYPES = {
    CONNECTION_ERROR: 'CONNECTION_FAILURE',
    TIMEOUT_ERROR: 'TIMEOUT_FAILURE',
    INACTIVE_USER: 'INACTIVE_USER',
    INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
    UNREGISTERED_USER: 'UNREGISTERED_USER',
    UNKNOW_ERROR: 'UNKNOW_ERROR',
    RESERVATION_DEFAULTER_FORBIDDEN: 'RESERVATION_DEFAULTER_FORBIDDEN',
    RESERVATION_EXCEEDS_MAX_QUOTA: 'RESERVATION_EXCEEDS_MAX_QUOTA',
    RESERVATION_EXCEEDS_MAX_ACTIVE: 'RESERVATION_EXCEEDS_MAX_ACTIVE'
  };

  protected _name = '';
  private _messageTitle = '';
  protected _message = '';
  protected _type = '';
  private _originalErrorMessage = '';
  public originalError: any;

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get messageTitle(): string {
    return this._messageTitle;
  }

  set messageTitle(value: string) {
    this._messageTitle = value;
  }

  get originalErrorMessage(): string {
    return this._originalErrorMessage;
  }

  set originalErrorMessage(value: string) {
    this._originalErrorMessage = value;
  }
}
