import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { BuildSipAccount, SipAccount } from '@api/model/sip-account';
import { Status } from '@api/model/status';
import { SaveSipAccountDTO, SipAccountsService } from '@api/serviceV2/sip-accounts.service';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-create-sip-account',
  templateUrl: 'modal-create-sip-account.html'
})
export class ModalCreateSipAccountComponent implements OnInit {
  public condo: Condo;

  public sipAccount: SipAccount;

  public status = new Status();

  public sipAccountForm = new FormGroup({
    name: new FormControl('', Validators.required),
    host: new FormControl('', Validators.required),
    port: new FormControl('5060'),
    prefix: new FormControl('')
  });

  public onCreate: (sipAccount: SipAccount) => void = () => {};
  public onUpdate: (sipAccount: SipAccount) => void = () => {};

  public defaultErrorCallback = () => {
    this.status.setAsError();
    this.toastrService.error('Não foi possível salvar a conta SIP, tente novamente.');
  };

  constructor(
    public bsModalRef: BsModalRef,
    private sipAccountsService: SipAccountsService,
    private toastrService: ToastrService
  ) {}

  public ngOnInit(): void {
    if (this.sipAccount) {
      this.sipAccountForm.setValue({
        name: this.sipAccount.name || '',
        host: this.sipAccount.host || '',
        port: this.sipAccount.port || '5060',
        prefix: this.sipAccount.prefix || ''
      });
    }
  }

  public handleSubmit(): void {
    if (this.sipAccountForm.valid) {
      this.status.setAsProcessing();

      const { name, host, port, prefix } = this.sipAccountForm.controls;

      const sipAccountToSave: SaveSipAccountDTO = {
        name: name.value || '',
        host: host.value || '',
        port: port.value || '5060',
        prefix: prefix.value || ''
      };

      if (this.sipAccount) {
        this.updateSipAccount(sipAccountToSave);
      } else {
        this.createSipAccount(sipAccountToSave);
      }
    } else {
      for (const key of Object.keys(this.sipAccountForm.controls)) {
        this.sipAccountForm.get(key).markAsTouched();
      }

      this.toastrService.warning('Preencha os campos obrigatórios.');
    }
  }

  public createSipAccount(sipAccountData: SaveSipAccountDTO): void {
    const successCallback = (response: Pick<SipAccount, '_id'>) => {
      const createdSipAccount = BuildSipAccount({ ...sipAccountData, ...response, createdAt: moment().toISOString() });
      this.onCreate(createdSipAccount as SipAccount);
      this.bsModalRef.hide();
      this.toastrService.success('Conta SIP cadastrada com sucesso.');
    };

    this.sipAccountsService
      .createSipAccount(this.condo._id, sipAccountData)
      .subscribe({ next: successCallback, error: this.defaultErrorCallback });
  }

  public updateSipAccount(sipAccountData: SaveSipAccountDTO): void {
    const successCallback = () => {
      const createdSipAccount = BuildSipAccount({ ...this.sipAccount, ...sipAccountData });
      this.onUpdate(createdSipAccount);
      this.bsModalRef.hide();
      this.toastrService.success('Conta SIP atualizada com sucesso.');
    };

    this.sipAccountsService
      .updateSipAccount(this.condo._id, this.sipAccount._id, sipAccountData)
      .subscribe({ next: successCallback, error: this.defaultErrorCallback });
  }
}
