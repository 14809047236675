import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { map } from 'rxjs/operators';
import { File } from '@api/model/file';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';

@Injectable({ providedIn: 'root' })
export class CondoFilesService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;
  }

  uploadFilesFromFormData(condoId: string, formData: FormData, params: EcondosQuery = {}) {
    const url = `${this.endPoint}${condoId}/files/upload`;

    const httpParams = qs.stringify(params);

    const fullUrl = `${url}?${httpParams}`;

    return this.http.post(fullUrl, formData).pipe(map((files: any) => files.map(file => new File(file))));
  }

  uploadFiles(condoId, files, query = null) {
    const formData = new FormData();
    for (const file of files) {
      formData.append(file.name || 'file' + new Date().getTime(), file);
    }
    return this.uploadFilesFromFormData(condoId, formData, query);
  }
}
