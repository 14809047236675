import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UtilService } from '../../app/services/util.service';

@Injectable({ providedIn: 'root' })
export class FeaturesRoutesGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const user = this.utilService.getLocalUser();
    const condo = user.defaultCondo;

    const canAccessClassificadosRoute = !user.isGatekeeperOnCondo(condo._id) && !condo.isClassificadosDisabled() && condo.hasAdAccess();
    if (canAccessClassificadosRoute) {
      return true;
    }

    const canAccessLostAndFoundRoute = !condo.isLostAndFoundsDisabled();
    if (canAccessLostAndFoundRoute) {
      return true;
    }

    const canAccessDocumentsRoute = !user.isGatekeeperOnCondo(condo._id) && !condo.isDocumentsDisabled() && condo.hasDocumentsAccess();
    if (canAccessDocumentsRoute) {
      return true;
    }

    const canAccessRatingRoute = !condo.isCondoRatingDisabled();
    if (canAccessRatingRoute) {
      return true;
    }

    const canAccessDeviceRequestsRoute = user.isGatekeeper() || user.isAdmin() || user.isOwner();
    if (canAccessDeviceRequestsRoute) {
      return true;
    }

    const canAccessFaqRoute = !condo.isCondoFaqDisabled();
    if (canAccessFaqRoute) {
      return true;
    }

    const canAccessAssetManagementRoute = condo.isAssetManagementEnabled();
    if (canAccessAssetManagementRoute) {
      return true;
    }

    this.router.navigate(['/notFound']);
    return false;
  }
}
