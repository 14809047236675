import { Observable, forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { ConstantService } from '../../../services/constant.service';
import { Actuator } from '@api/model/hardware/actuator';
import { catchError, map, mergeMap, tap, timeout } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Timezone } from '@api/model/timezone';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class ZktecoService {
  protected endPoint;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private utilService: UtilService,
    protected constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getV2Endpoint()}condos`;
  }

  public checkConnection(condoId: string, actuatorId: string) {
    const query: EcondosQuery = { $select: 'type accessType host port password hardware condo serial' };
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.httpService.get(`${this.endPoint}/${condoId}/actuators/${actuatorId}`, { params }).pipe(
      mergeMap((actuator: any) => {
        const zktecoServerUrl = this.getZKTecoServerUrl();
        const pushRequest$ = this.http.post(`${zktecoServerUrl}/check-connection`, { actuator }).pipe(map(res => ({ success: res })));
        return ['C2', 'V5L', 'V4L'].includes(actuator.type) ? pushRequest$ : this.connectDevice(actuator);
      }),
      timeout(10000),
      map((result: { success: boolean }) => ({ _id: actuatorId, status: result.success })),
      catchError(e => of({ _id: actuatorId, status: false }))
    );
  }

  public setDatetime(condoId: string, datetime: string, actuators: Actuator[]) {
    const zktecoServerUrl = this.getZKTecoServerUrl();
    const requests = actuators.map(actuator =>
      this.http
        .post(`${zktecoServerUrl}/set-datetime`, {
          actuator,
          datetime
        })
        .pipe(
          timeout(15000),
          map(res => ({ ...res, actuator })),
          catchError(e => {
            return of({ success: false, error: e, actuator });
          })
        )
    );
    return forkJoin(...requests);
  }

  public connectDevice(actuator: Actuator) {
    const zktecoServerUrl = this.getZKTecoServerUrl();
    return this.http.post(`${zktecoServerUrl}/connect`, { actuator });
  }

  public resetDevice(actuator: Actuator) {
    const zktecoServerUrl = this.getZKTecoServerUrl();
    return this.http.post(`${zktecoServerUrl}/reset`, { actuator });
  }

  public addTimezone(actuator: Actuator, timezone: Timezone) {
    const zktecoServerUrl = this.getZKTecoServerUrl();
    return this.http.post(`${zktecoServerUrl}/add-timezone`, { actuator, timezone });
  }

  public addDevice(condoId: string, device: Device) {
    const zktecoServerUrl = this.getZKTecoServerUrl();
    return this.getDevice(condoId, device._id)
      .pipe(
        tap(d => {
          // Quando faz o get device não traz o template, mas na tela de adição/edição o template é sempre populado,
          // então pegamos o template que já foi enviado
          if (device.type === DEVICE_TYPES.BM && !d.template) {
            d.template = device.template;
          }
        }),
        mergeMap(dev => this.http.post(`${zktecoServerUrl}/add-device`, { device: dev }))
      )
      .pipe(
        catchError(err => {
          if (err?.message?.includes('Unknown Error')) {
            return of(null);
          }
          throw err;
        })
      );
  }

  public deleteDevice(condoId: string, device: Device) {
    const zktecoServerUrl = this.getZKTecoServerUrl();
    return this.getDevice(condoId, device._id)
      .pipe(mergeMap(dev => this.http.post(`${zktecoServerUrl}/delete-device`, { device: dev })))
      .pipe(
        catchError(err => {
          if (err?.message?.includes('Unknown Error')) {
            return of(null);
          }
          throw err;
        })
      );
  }

  private getDevice(condoId: string, deviceId: string) {
    const query: EcondosQuery = {
      $select: 'serial type owner.userName sequenceId condo validFrom validUntil',
      $populate: [
        {
          path: 'owner.user',
          select: 'firstName lastName picture',
          populate: [{ path: 'picture', select: 'url thumbnail' }]
        },
        { path: 'owner.dependent', select: 'name firstName lastName' },
        { path: 'owner.condoContact', select: 'firstName lastName' },
        { path: 'owner.picture', select: 'url thumbnail' },
        {
          path: 'accessGroups',
          populate: [
            { path: 'timezone', select: 'sequenceId' },
            { path: 'actuators', select: 'name type accessType host port password hardware user serial' }
          ]
        }
      ]
    };

    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.httpService.get(`${this.endPoint}/${condoId}/devices/${deviceId}`, { params }).pipe(map(dev => new Device(dev)));
  }

  trigger(actuator: Actuator) {
    const zktecoServerUrl = this.getZKTecoServerUrl();
    if (zktecoServerUrl) {
      return this.http.post(`${zktecoServerUrl}/open-door`, { actuator });
    }
  }

  enrollBioFingerprint(actuator: Actuator) {
    const zktecoServerUrl = this.getZKTecoServerUrl();
    if (zktecoServerUrl) {
      const input = {
        type: 'FINGERPRINT',
        userId: 1,
        retry: 0
      };
      return this.http.post(`${zktecoServerUrl}/enroll-bio`, { actuator, input });
    }
  }

  getZKTecoServerUrl() {
    const condo = this.utilService.getLocalCondo();
    if (condo?.zkteco?.host) {
      let url = `http://${condo.zkteco.host}:${condo.zkteco.port || 30000}`;
      if (!this.isElectron()) {
        url = `http://localhost:5050/${url}`;
      }
      return url;
    } else {
      swal({ type: 'error', text: 'Servidor ZKTeco não encontrado' });
      throw new Error('Servidor ZKTeco não encontrado');
    }
  }

  private isElectron = () => {
    const userAgent = (window && window.navigator && window.navigator.userAgent) || '';
    return userAgent.toLowerCase().includes('electron');
  };
}
