import { CondoVehicle } from '../condo.vehicle';
import { Residence } from '../interface/residence';
import { CondoUser } from '../condo-user';
import { ResidenceBuilder } from '../residence/residence.builder';
import { CondoContact } from '@api/model/contact/condo.contact';
import { File } from '@api/model/file';
import { Condo } from '../condo';
import { DayAllowed } from '@api/model/authorized-people';
import { Occurrence } from '@api/model/interface/occurrence';
import { Actuator } from '@api/model/hardware/actuator';
import { AccessGroup } from '@api/model/hardware/access-group';

export type Finger =
  | 'LEFT_LITTLE_FINGER'
  | 'LEFT_RING_FINGER'
  | 'LEFT_MIDDLE_FINGER'
  | 'LEFT_INDEX_FINGER'
  | 'LEFT_THUMB'
  | 'RIGHT_LITTLE_FINGER'
  | 'RIGHT_RING_FINGER'
  | 'RIGHT_MIDDLE_FINGER'
  | 'RIGHT_INDEX_FINGER'
  | 'RIGHT_THUMB';

export enum DEVICE_TYPES {
  RF = 'RF',
  TA = 'TA',
  CT = 'CT',
  BM = 'BM',
  TP = 'TP',
  SN = 'SN',
  CARD = 'CARD',
  FACIAL = 'FACIAL',
  QR = 'QR',
  VEHICLE_PLATE = 'VEHICLE_PLATE',
  VIRTUAL_KEY = 'VIRTUAL_KEY'
}

export enum DEVICE_TYPES_LABEL {
  RF = 'Controle',
  TA = 'Tag ativa',
  CT = 'Cartão ou tag',
  BM = 'Biometria',
  TP = 'Adesivo veicular',
  SN = 'Senha',
  CARD = 'Cartão',
  FACIAL = 'Facial',
  QR = 'QR Code',
  VEHICLE_PLATE = 'Placa',
  VIRTUAL_KEY = 'Chave virtual'
}

export enum DEVICE_STATUS { // 'SYNCED', 'UNSYNCED', 'EXPIRED', 'NO_CREDITS', 'DISABLED', 'ACTIVE', 'LEAVED', 'CANCELED'
  SYNCED = 'SYNCED',
  UNSYNCED = 'UNSYNCED',
  EXPIRED = 'EXPIRED',
  NO_CREDITS = 'NO_CREDITS',
  DISABLED = 'DISABLED',
  ACTIVE = 'ACTIVE',
  LEAVED = 'LEAVED',
  CANCELED = 'CANCELED'
}

export enum DEVICE_STATUS_LABEL {
  SYNCED = 'Sincronizado',
  UNSYNCED = 'Não sincronizado',
  EXPIRED = 'Expirado',
  NO_CREDITS = 'Sem créditos',
  DISABLED = 'Desabilitado',
  ACTIVE = 'Ativo',
  LEAVED = 'Baixa na saída',
  CANCELED = 'Cancelado',
  OPENED = 'Aberta'
}

export class Device {
  public _id: string;
  public sequenceId: number;
  public condo: Condo;
  public hardware: string;
  public owner: {
    user?: CondoUser;
    dependent?: any;
    userName?: string;
    residence?: Residence;
    condoVehicle?: CondoVehicle;
    condoContact?: CondoContact;
    picture?: File;
  };
  public type: DEVICE_TYPES;
  public obs: string;
  public status: DEVICE_STATUS;
  public accessType: string;
  public syncedAt: string;
  public serial: string;
  public serialType: string;
  public template: string;
  public finger: Finger;
  public panic: boolean;
  public triggeredAt: string;
  public counter: string;
  public receptors: boolean[];
  public enabled: boolean;
  public credits: number;
  public remainingCredits: number;
  public uses: number;
  public validFrom: string;
  public validUntil: string;
  public remoteCheck: boolean;
  public createdAt: string;
  public createdBy;
  public updatedAt: string;
  public updatedBy;
  public afterSyncStatus: string;
  public permission: Occurrence;
  public hardwareAttributes: {
    tipo_disp: string;
    disp_dest: string;
    serial: string;
    serial3: string;
    serial2: string;
    serial1: string;
    serial0: string;
    contador: string;
    idBio: String;
    unid: Number;
    bloco?: string;
    grupo?: string;
    receptores?: boolean[];
    rotulo?: string;
    dispPortaria: Boolean;
    botalLeitora: number;
    statusBateria: string;
    marcaVeiculo: string;
    corVeiculo: string;
    placaVeiculo: string;
    utechPermissions?: string[];
    incontrolId?: number;
    incontrolFingerId?: number;
    incontrolStatus?: boolean;
    incontrolFingerModel?: string;
    antiPassback?: string;
    safeExit?: string;
  };

  public userLabel = '';
  public residenceLabel = '';
  public condoVehicleLabel = '';
  public condoContactLabel = '';

  public actuators: Actuator[] = [];
  public internalIds: string[] = [];
  public actuatorAttributes: [
    {
      actuator: string;
      user_id: Number;
      template_id: Number;
      card_id: Number;
    }
  ];

  public actuatorsToAdd: any[] = [];
  public actuatorsToRemove: any[] = [];
  public syncErrors: Array<{
    actuator: Actuator;
    error: string;
    date: Date;
    operation: string;
  }> = [];
  public syncSuccesses: Array<{
    actuator: Actuator;
    error: string;
    date: Date;
    operation: string;
  }> = [];
  lastExecution: string;
  nextExecution: string;
  daysAllowed: DayAllowed[];
  accessGroups: AccessGroup[];
  formerResident: boolean;
  isIrregularCause: string;
  deleted: boolean;

  constructor(device?) {
    if (device) {
      if (typeof device === 'string') {
        this._id = device;
      } else {
        this._id = device._id;
        this.sequenceId = device.sequenceId;
        this.condo = device.condo;
        this.hardware = device.hardware;
        this.receptors = device.receptors;
        this.obs = device.obs;
        this.type = device.type;
        this.status = device.status;
        this.accessType = device.accessType;
        this.syncedAt = device.syncedAt;
        this.serial = device.serial;
        this.serialType = device.serialType;
        this.counter = device.counter;
        this.finger = device.finger;
        this.hardwareAttributes = device.hardwareAttributes;
        this.actuatorAttributes = device.actuatorAttributes;
        this.triggeredAt = device.triggeredAt || '';
        this.credits = device.credits;
        this.remainingCredits = device.remainingCredits;
        this.uses = device.uses;
        this.createdAt = device.createdAt;
        this.createdBy = device.createdBy || {};
        this.updatedAt = device.updatedAt;
        this.updatedBy = device.updatedBy || null;
        this.owner = {};

        if (device?.actuators?.length) {
          if (typeof device.actuators[0] === 'string') {
            this.actuators = device.actuators.map(actuatorId => ({ _id: actuatorId }));
          }
          this.actuators = device.actuators;
        }

        this.actuatorsToAdd = device.actuatorsToAdd || [];
        this.actuatorsToRemove = device.actuatorsToRemove || [];
        this.syncSuccesses = device.syncSuccesses || [];
        this.syncErrors = device.syncErrors || [];
        this.lastExecution = device.lastExecution;
        this.nextExecution = device.nextExecution;
        this.daysAllowed = device.daysAllowed;
        this.afterSyncStatus = device.afterSyncStatus;
        this.permission = device.permission;
        this.validFrom = device.validFrom;
        this.validUntil = device.validUntil;
        this.internalIds = device.internalIds || [];
        this.panic = device.panic || false;
        this.enabled = device.enabled ?? true;
        this.remoteCheck = device.remoteCheck || false;
        this.accessGroups = device.accessGroups || [];
        if (device.owner?.user) {
          this.owner.user = new CondoUser(device.owner.user);
        } else if (device.owner?.userName) {
          // const labels = device.owner.userName.split(' ').filter(v => v);
          // const firstName = labels.shift();
          // const lastName = labels.join(' ');
          // this.owner.user = new CondoUser({firstName, lastName});
          this.owner.userName = device.owner.userName;
        } else if (device.hardwareAttributes?.rotulo) {
          const labels = device.hardwareAttributes.rotulo.split(' ').filter(v => v);
          const firstName = labels.shift();
          const lastName = labels.join(' ');
          this.owner.user = new CondoUser({ firstName, lastName });
        } else {
          this.owner.user = null;
        }
        this.owner.dependent = device.owner?.dependent;
        this.userLabel = this.owner.user
          ? `${this.owner.user.firstName} ${this.owner.user.lastName}`
          : this.owner.dependent
            ? this.owner.dependent.name
            : this.owner.userName || '';
        // Set residence with econdos residence or hardware attributes
        if (device.owner?.residence) {
          this.owner.residence = ResidenceBuilder.build(device.owner.residence);
        } else if (device.hardwareAttributes && device.hardwareAttributes.bloco && device.hardwareAttributes.unid) {
          const residence = {
            block: device.hardwareAttributes.bloco,
            number: device.hardwareAttributes.unid,
            identification: device.hardwareAttributes.unid + ' ' + device.hardwareAttributes.bloco
          };
          this.owner.residence = ResidenceBuilder.build(residence);
        } else {
          this.owner.residence = null;
        }
        this.residenceLabel = (this.owner.residence && this.owner.residence.identification) || '';

        // Set vehicle with econdos vehicle or hardware attributes
        if (device.owner?.condoVehicle) {
          this.owner.condoVehicle = new CondoVehicle(device.owner.condoVehicle);
        } else if (
          device.hardwareAttributes &&
          device.hardwareAttributes.placaVeiculo &&
          device.hardwareAttributes.placaVeiculo !== 'AAA1111' &&
          device.hardwareAttributes.marcaVeiculo !== 'SEM VEICULO'
        ) {
          const vehicle = {
            plate: device.hardwareAttributes.placaVeiculo,
            brand: device.hardwareAttributes.marcaVeiculo,
            color: device.hardwareAttributes.corVeiculo,
            type: CondoVehicle.TYPES.CAR
          };
          this.owner.condoVehicle = new CondoVehicle(vehicle);
        } else {
          this.owner.condoVehicle = null;
        }
        this.condoVehicleLabel = this.owner.condoVehicle
          ? `${this.owner.condoVehicle.model || this.owner.condoVehicle.brand} ${this.owner.condoVehicle.color} ${
              this.owner.condoVehicle.plate
            }`
          : '';

        if (device.owner?.condoContact) {
          this.owner.condoContact = new CondoContact(device.owner.condoContact);
          this.condoContactLabel = `${this.owner.condoContact.firstName} ${this.owner.condoContact.lastName}`;
        } else {
          this.condoContactLabel = '';
        }

        // Set owner picture
        if (device.owner?.picture) {
          this.owner.picture = new File(device.owner.picture);
        } else {
          // Try to use owner user picture
          if (this.owner?.user?.picture?._id) {
            this.owner.picture = this.owner.user.picture;
          }
        }
        this.formerResident = device.formerResident ? device.formerResident : false;
        this.isIrregularCause = device.isIrregularCause || '';
      }
    }

    this.deleted = device?.deleted || false;
  }
}
