import { CondoContact } from './contact/condo.contact';
import { Residence } from './interface/residence';
import { Device } from '@api/model/hardware/device';

export class ParkingSpot {
  _id?: string;
  name?: string;
  obs?: string;
  isAvailable?: boolean;
  residence?: Residence;
  condoContact?: CondoContact;
  isVisitorSpot: boolean;
  occupiedAt?: string;
  device?: Device;

  constructor(parkingSpot) {
    if (typeof parkingSpot == 'string') {
      this._id = parkingSpot;
    } else if (parkingSpot) {
      this._id = parkingSpot._id || '';
      this.name = parkingSpot.name || '';
      this.obs = parkingSpot.obs || '';
      this.isAvailable = parkingSpot.isAvailable;
      this.residence = parkingSpot.residence || null;
      this.condoContact = parkingSpot.condoContact || null;
      this.isVisitorSpot = parkingSpot.isVisitorSpot;
      this.occupiedAt = parkingSpot.occupiedAt || null;
      this.device = parkingSpot.device || null;
    }
  }
}
