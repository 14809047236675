import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import { map, timeout } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { OccurrenceServiceV2 } from '@api/serviceV2/occurrence.service';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { EcondosQuery } from '@api/model/query';
import { Occurrence } from '@api/model/interface/occurrence';
import { CondoContact } from '@api/model/contact/condo.contact';
import { WEEK_DAYS_LONG, WEEK_DAYS_SHORT } from '@api/util/constants';
import { ModalCreateAuthorizedPersonComponent } from '../modal-create-authorized-person/modal-create-authorized-person.component';
import { AccessServiceV3 } from '@api/serviceV3/access.service';
import { ThemeService } from '../../theme';
import swal from 'sweetalert2';
import { ModalShareLinkComponent } from '../modal-share-link/modal-share-link';
import { ParamsService } from '@api/service/params.service';
import { Router } from '@angular/router';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';
import { createLinkToShareOccurrence } from '@api/utils';
import { Location } from '@angular/common';

@Component({
  selector: 'app-authorized-people-list',
  templateUrl: 'authorized-people-list.component.html',
  styleUrls: ['./authorized-people-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizedPeopleListComponent implements OnChanges, OnDestroy, OnInit {
  @Input() residence: Residence;
  @Input() condo: Condo;
  @Input() user: User;
  @Input() showAddAuthorizationButton = true;
  @Input() showAuthorizationManagementButton = true;
  @Input() customQuery: EcondosQuery = null;
  @Input() listTitle = 'Pessoas autorizadas';
  @Input() emptyFeedbackTitle = 'Nenhuma autorização encontrada';
  @Input() emptyFeedbackMessage = '';

  @ViewChild('authorizationTemplate', { static: true }) authorizationTemplate: TemplateRef<any>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<any>;
  @ViewChild('authorizationTemplateTable', { static: true }) authorizationTemplateTable: TableComponent;
  authorizations: AuthorizedPerson[] = [];
  page = 0;
  countData: number;
  tableColumns: TableColumnDefinition<AuthorizedPerson>[] = [];
  status: TableStatus = 'LOADING';

  documents: { [key: string]: { rg: string; cpf: string } } = {};
  currentPageValuesCount;

  subscription: Subscription = new Subscription();

  hasWriteAccess = false;
  canUpdate = false;
  canCreate = false;

  canSelfRegistrate = false;

  WEEK_DAYS = WEEK_DAYS_SHORT;

  pageLimit = 15;

  constructor(
    private occurrenceService: OccurrenceServiceV2,
    private accessService: AccessServiceV3,
    private themeService: ThemeService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private paramsService: ParamsService,
    public router: Router,
    private location: Location
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo || changes.residence || changes.user) {
      const user: User = changes.user?.currentValue || changes.user?.previousValue || this.user;
      const condo: Condo = changes.condo?.currentValue || changes.condo?.previousValue || this.condo;
      const residence: Residence = changes.residence?.currentValue || changes.residence?.previousValue;

      this.hasWriteAccess =
        user?.isAdminOnCondo(condo?._id) ||
        user?.isOwnerOnCondo(condo?._id) ||
        residence?.isOwner(user?._id) ||
        residence?.isUser(user?._id);

      this.canUpdate = user?.isAdminOnCondo(condo?._id) || user?.isOwnerOnCondo(condo?._id);

      this.canSelfRegistrate =
        this.condo?.generalParams?.accessLiberation?.gateKeeperCanShareVisitorPermissionLink &&
        this.condo.generalParams.accessLiberation.visitorSelfRegistration;

      this.canCreate =
        user?.isAdminOnCondo(condo?._id) ||
        user?.isOwnerOnCondo(condo?._id) ||
        user?.isGatekeeperOnCondo(condo?._id) ||
        (residence?.isUser(user?._id) && this.condo?.generalParams?.accessLiberation?.residentCanRegisterAccessLiberation);
    }

    this.getData();
  }

  ngOnInit(): void {
    this.tableColumns = [
      {
        columnId: 'note',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.authorizationTemplate,
        sortable: false
      }
    ];
  }

  getData({ page = 0 } = {}) {
    this.status = 'LOADING';

    let query: EcondosQuery = {
      $select: 'description status startDate endDate condoContacts type subType residence daysAllowed createdBy shouldCall',
      $populate: [
        {
          path: 'condoContacts',
          select: 'firstName lastName type',
          populate: { path: 'picture', select: 'thumbnail url' }
        }
      ],
      $sort: '-createdAt',
      type: 'GATE',
      subType: GateOccurrence.ALLOW_ACCESS_TYPE,
      status: Occurrence.STATUS.OPENED,
      condoContacts: { $type: 'objectId' }
    };

    if (this.residence) {
      query.residence = this.residence._id;
    }

    const { pageSize } = this.authorizationTemplateTable.getCurrentState();

    query.$page = page;
    query.$limit = pageSize;

    if (this.customQuery) {
      query = { ...query, ...this.customQuery };
    }

    const isQRCodeEnabledOnCondo =
      this.condo?.generalParams?.accessLiberation?.gateKeeperCanShareVisitorPermissionLink &&
      this.condo?.hardwareParams?.visitorsFacialFromUser === 'DISABLED' &&
      (this.condo?.hardwareParams.visitorsQRCodeFromUser === 'ENABLED' ||
        this.condo?.hardwareParams.visitorPrintedQrCode ||
        this.condo?.hardwareParams.visitorsVirtualKeyFromUser === 'ENABLED');

    this.occurrenceService
      .get(this.condo._id, query)
      .pipe(
        timeout(15_000),
        map(({ count, occurrences }) => {
          const now = new Date();
          const authorizations = occurrences.reduce((acc, curr) => {
            const occurrence = curr as GateOccurrence;
            const baseAuthorizedPerson: Partial<AuthorizedPerson> = {
              _id: occurrence._id,
              startDate: occurrence.startDate,
              endDate: occurrence.endDate,
              description: occurrence.description,
              daysAllowed: occurrence.daysAllowed,
              shouldCall: occurrence.shouldCall
            };
            const isCreatedByCurrentUser = occurrence.isCreatedBy(this.user?._id);
            const isValidPeriod = now <= new Date(baseAuthorizedPerson.endDate);
            const canShareQRCode = isQRCodeEnabledOnCondo && isCreatedByCurrentUser && isValidPeriod;

            const contacts = occurrence.condoContacts;
            const elements = contacts.map(contact => {
              return {
                ...baseAuthorizedPerson,
                condoContact: contact,
                canEdit: occurrence.canEdit(this.user, this.condo),
                canCancel: occurrence.canCancel(this.user),
                canShareQRCode
              } as AuthorizedPerson;
            });

            acc.push(...elements);

            return acc;
          }, []);

          this.currentPageValuesCount = `Listando ${authorizations.length}  visitantes `;
          return { count, authorizations };
        })
      )
      .subscribe(
        ({ count, authorizations }) => {
          this.countData = count;
          this.authorizations = authorizations;
          this.status = 'SUCCESS';
          this.cdr.detectChanges();
        },
        err => {
          this.status = 'ERROR';
          this.cdr.detectChanges();
        }
      );
  }

  shareQRCodeViaWhatsapp(authorization: AuthorizedPerson) {
    const contact = authorization.condoContact;
    const type = 'QR';
    const data = {
      type,
      condoContactId: contact._id,
      permissionId: authorization._id
    };

    this.accessService
      .generateVisitorDeviceFromUser(this.condo._id, data)
      .pipe(timeout(10000))
      .subscribe(
        async (res: { serial: string }) => {
          const qrCodeUrl = this.themeService.activeTheme?.qrCodePageUrl || 'https://visitante.econdos.com.br';
          const url = `${qrCodeUrl}?serial=${res.serial}`;
          const whatsappBreakLineChar = '%0a';

          const days = authorization.daysAllowed.reduce((acc, d) => {
            acc += `${WEEK_DAYS_LONG[d.day]}: ${d.startTime}-${d.endTime}${whatsappBreakLineChar}`;
            return acc;
          }, '');

          let message = `Olá ${contact.firstName}, você recebeu esta chave de acesso de ${this.user.firstName} para o(a) ${this.condo?.customLabels?.condo?.singular} ${this.condo.name}.`;
          message += whatsappBreakLineChar + whatsappBreakLineChar;
          message += `Esta chave é VÁLIDA ${
            authorization.startDate != authorization.endDate
              ? 'entre os dias ' + authorization.startDate + ' e ' + authorization.endDate
              : 'no dia ' + authorization.startDate
          }.`;
          message += whatsappBreakLineChar + whatsappBreakLineChar;
          message += days;
          message += whatsappBreakLineChar;
          message += url;

          const shareUrl = `https://wa.me/?text=${message}`;

          try {
            window.open(shareUrl);
          } catch (e) {
            this.toastr.error('Não foi possível compartilhar o QRCode, tente novamente');
          }
        },
        err => {
          const errorMessage = err?.originalError?.message || 'Não foi possível gerar a chave de acesso, tente novamente';
          this.toastr.error(errorMessage);
        }
      );
  }

  create() {
    const initialState = {
      condo: this.condo,
      residence: this.residence,
      callback: liberation => {
        this.getData();
        if (!liberation?.condoContacts?.length && this.canSelfRegistrate) {
          const link = createLinkToShareOccurrence(this.condo._id, liberation._id, this.themeService.activeTheme);
          this.modalService.show(ModalShareLinkComponent, {
            initialState: { link },
            ignoreBackdropClick: true,
            class: 'modal-lg'
          });
        }
      }
    };
    this.modalService.show(ModalCreateAuthorizedPersonComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  cancel(authorizedPerson: AuthorizedPerson) {
    const person = authorizedPerson.condoContact;
    const name = `${person.firstName} ${person.lastName}`;
    swal({
      title: `Cancelar liberação de acesso`,
      text: `Você deseja cancelar a liberação de acesso de ${name}`,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.occurrenceService
          .updateOcurrence(this.condo._id, authorizedPerson._id, { status: 'CANCELED' })
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            const msg = err?.originalError?.message || 'Não foi possível cancelar. Tente novamente...';
            return Promise.reject(msg);
          });
      }
    })
      .then(() => {
        this.getData();
        this.toastr.success('Cancelamento realizado com sucesso');
      })
      .catch(e => swal.noop());
  }

  navigateToLiberationManagement() {
    this.paramsService.add('routeToNavigateBack', this.location.path());
    this.router.navigate(['/visitor-liberation/' + this.residence._id]);
  }
}

interface AuthorizedPerson {
  _id: string;
  condoContact: CondoContact;
  description: string;
  daysAllowed: { day: string; startTime: string; endTime: string }[];
  startDate: string | Date;
  endDate: string | Date;
  canEdit: boolean;
  canCancel: boolean;
  canShareQRCode: boolean;
  shouldCall: boolean;
}
