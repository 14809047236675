import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, from, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, takeUntil, timeout } from 'rxjs/operators';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import swal from 'sweetalert2';
import { EcondosQuery } from '@api/model/query';
import { Actuator } from '@api/model/hardware/actuator';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { conditionalValidator } from '@api/util/validators';
import { User } from '@api/model/user';
import { FileService } from '@api/service/file.service';
import { SelectAccessGroupActuatorsComponent } from '../../components/select-access-group-actuators/select-access-group-actuators.component';
import { AccessGroup } from '@api/model/hardware/access-group';
import { orderBy } from 'lodash';
import { UserLocalSettingsService } from '@api/serviceV2/user-local-settings.service';

@Component({
  selector: 'app-modal-create-hikvision-device',
  templateUrl: 'modal-create-hikvision-device.component.html',
  styleUrls: ['modal-create-hikvision-device.component.scss']
})
export class ModalCreateHikvisionDeviceComponent implements OnInit, OnDestroy {
  @ViewChild('selectAccessGroupActuatorsComponent', { static: true }) selectAccessGroupActuators: SelectAccessGroupActuatorsComponent;

  condo: Condo;

  DEVICE_TYPES = DEVICE_TYPES;

  TYPES = [
    // {label: 'Cartão ou chaveiro', value: 'CARD'},
    // {label: 'Senha', value: 'SN'},
    // {label: 'Biometria', value: 'BM'},
    { label: 'Facial', value: 'FACIAL' }
  ];

  showPassword = false;
  status: Status = new Status();

  device: Device;
  callbacks: {
    success?: (arg) => void;
    error?: (err) => void;
    onUseNewHikvisionButtonClick?: () => void;
  };

  form: UntypedFormGroup;

  type: AbstractControl;
  serial: AbstractControl;
  residence: AbstractControl;
  user: AbstractControl;
  userName: AbstractControl;
  picture: AbstractControl;
  obs: AbstractControl;

  residenceSearch;
  residenceUsers: { name: string; type: string; _id: string; picture: any }[] = [];
  loadingResidences = false;

  residenceTypeAheadDataSource$: Observable<any>;

  selectedResidence;

  private subscriptions: Subscription = new Subscription();

  formIsDisabled = false;

  storageUser: { user: User; residence: Residence };
  labels: { dependent: string };

  public actuatorsToRegister: Actuator[] = [];
  public actuatorsToRemove: Actuator[] = [];
  public storedActuators: Actuator[] = [];
  public selectedAccessGroup: AccessGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private hikvisionService: HikvisionService,
    private fileService: FileService,
    private deviceService: HardwareDeviceService,
    private actuatorService: ActuatorService,
    private toastrService: ToastrService,
    private userLocalSettingsService: UserLocalSettingsService
  ) {
    this.form = this.fb.group({
      type: ['FACIAL', Validators.required],
      serial: [
        '',
        [
          conditionalValidator(() => this.type.value !== 'FACIAL', Validators.required),
          conditionalValidator(() => this.type.value !== 'FACIAL', Validators.minLength(6))
        ]
      ],
      user: [null],
      userName: [''],
      residence: [null, [Validators.required]],
      picture: [null, Validators.required],
      obs: ['']
    });
    this.type = this.form.get('type');
    this.serial = this.form.get('serial');
    this.user = this.form.get('user');
    this.userName = this.form.get('userName');
    this.residence = this.form.get('residence');
    this.picture = this.form.get('picture');
    this.obs = this.form.get('obs');

    this.subscriptions.add(
      this.user.valueChanges.subscribe(user => {
        if (user === 'NEW_USER') {
          swal({
            title: 'Inserir nome',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            inputPlaceholder: 'Digite o nome...',
            showLoaderOnConfirm: true,
            preConfirm: name => {
              if (!name || !name.trim().length) {
                return Promise.reject(`Insira o nome`);
              } else {
                name = name.trim();
                return Promise.resolve(name);
              }
            }
          }).then(
            name => {
              this.userName.setValue(name);
              this.user.setValue('', { emitEvent: false });
            },
            () => {
              this.userName.setValue('');
              this.user.setValue('', { emitEvent: false });
            }
          );
        } else if (user?.type === 'DEPENDENT') {
          this.userName.setValue(user.name);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          }
          this.user.setValue('', { emitEvent: false });
        } else if (this.checkHasDeviceOwnerPicture()) {
          this.picture.setValue(this.device.owner.picture);
        } else if (user) {
          user = this.residenceUsers.find(u => u._id === user._id);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          } else {
            this.picture.setValue(null);
          }
        } else {
          this.picture.setValue(null);
        }
      })
    );

    this.initializeTypeAheads();
  }

  initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type voter users',
          $populate: [
            {
              path: 'users',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            },
            {
              path: 'voter',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            }
          ]
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(e => {
            this.status.setAsError();
            return from([]);
          })
        );
      })
    );
  }

  ngOnInit() {
    this.labels = {
      dependent: this.condo?.customLabels?.dependent.singular || (this.condo?.isBusinessCondo() ? 'funcionário' : 'dependente')
    };

    if (this.device) {
      this.formIsDisabled = true;
      this.type.setValue(this.device.type);
      this.serial.setValue(this.device.serial);
      this.obs.setValue(this.device.obs);
      this.obs.enable();
      if (this.device.owner) {
        if (this.device.owner.residence && this.device.owner.residence._id) {
          this.residence.setValue(this.device.owner.residence);
          const userId: any = (this.device.owner && this.device.owner.user && this.device.owner.user._id) || '';
          this.initializeFieldsFromResidence(this.device.owner.residence, { userId });
        }
        if (this.device.owner.user && this.device.owner.user._id) {
          const user = {
            name: `${this.device.owner.user.firstName} ${this.device.owner.user.lastName}`,
            _id: this.device.owner.user._id,
            picture: this.device.owner.user.picture,
            type: 'USER'
          };
          this.residenceUsers = [user];
          this.user.setValue(user);
        } else {
          this.user.setValue('');
          if (this.device.owner.userName) {
            this.userName.setValue(this.device.owner.userName);
          } else if (this.device.hardwareAttributes && this.device.hardwareAttributes.rotulo) {
            this.userName.setValue(this.device.hardwareAttributes.rotulo);
          }
        }
        if (this.device.owner.picture && this.device.owner.picture._id) {
          this.picture.setValue(this.device.owner.picture);
        }
        this.storedActuators = this.device.actuators || [];
      }
    } else if (this.selectedResidence) {
      this.residence.setValue(this.selectedResidence);
      this.residence.disable();
      this.initializeFieldsFromResidence(this.selectedResidence);
    }

    if (this.storageUser) {
      if (this.storageUser.residence) {
        this.residence.setValue(this.storageUser.residence);
        if (!this.storageUser.user) {
          this.initializeFieldsFromResidence(this.storageUser.residence);
        }
        this.user.setValue('');
      }
      if (this.storageUser.user) {
        this.user.setValue(this.storageUser.user._id);
        this.residenceUsers = [
          {
            name: this.storageUser.user.fullName,
            type: 'USER',
            _id: this.storageUser.user._id,
            picture: this.storageUser.user.picture
          }
        ];
        this.user.setValue(this.residenceUsers[0]);
        this.user.disable();
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residence.setValue(residences[0]);
        }
        if (this.storageUser.user?.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  save(value) {
    if (this.form.valid && (this.user.value || this.userName.value)) {
      const device = this.buildDevice();
      if (this.device) {
        this.updateDevice(device);
      } else {
        this.createDevice(device);
      }
    } else {
      this.toastrService.warning('Preencha todos os campos e adicione a foto da pessoa');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }

  createDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .create(this.condo._id, device)
      .pipe(
        map((res: { _id: string }) => {
          let storedDevice;
          const actuators = this.actuatorsToRegister;
          storedDevice = { ...device, _id: res._id, actuators, serial: res._id };
          if (storedDevice.owner) {
            if (this.residence.value) {
              storedDevice.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              storedDevice.owner.user = this.user.value;
            }
            if (this.picture.value) {
              storedDevice.owner.picture = this.picture.value;
            }
          }
          return storedDevice;
        }),
        switchMap((savedDevice: any) => {
          if (savedDevice && savedDevice.length > 0) {
            return this.hikvisionService
              .create(savedDevice.actuators, savedDevice)
              .pipe(map(results => ({ results, device: savedDevice })));
          } else {
            return of(savedDevice);
          }
        })
      )
      .subscribe(
        (data: any) => {
          let storedDevice;
          if (data.results && data.results.every(res => res.ok)) {
            storedDevice = data.device;
          } else {
            storedDevice = data;
          }
          if (storedDevice.owner) {
            if (this.residence.value) {
              storedDevice.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              const populatedUser = this.residenceUsers.find(u => u._id === this.user.value?._id);
              const [firstName, lastName] = populatedUser.name.split(' ');
              storedDevice.owner.user = { firstName, lastName, _id: populatedUser._id, picture: populatedUser.picture };
            }
            if (this.picture.value) {
              storedDevice.owner.picture = this.picture.value;
            }
          }
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(new Device(storedDevice));
          }
          this.status.setAsSuccess();
          this.bsModalRef.hide();
        },
        err => {
          this.status.setAsError();
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: err.message || 'Não foi possível cadastrar este dispositivo.'
          });
        }
      );
  }

  checkHasDeviceOwnerPicture() {
    return this.device?.owner?.picture ? true : false;
  }

  updateDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .update(this.condo._id, this.device._id, device)
      .pipe(
        switchMap(() => {
          device._id = this.device._id;
          if (device.owner) {
            if (this.residence.value) {
              device.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              device.owner.user = this.residenceUsers.find(u => u._id === this.user.value._id);
            }
            if (this.picture.value) {
              device.owner.picture = this.picture.value;
            }
          }
          const requests = [];
          if (this.actuatorsToRemove.length) {
            requests.push(this.hikvisionService.delete(device, this.actuatorsToRemove));
          }
          if (this.actuatorsToRegister.length) {
            requests.push(this.hikvisionService.update(device, this.actuatorsToRegister));
          }
          if (requests.length) {
            return forkJoin(requests).pipe(
              map(res => {
                const results = res.reduce((acc: [], val: []) => acc.concat(val), []);
                return { results, device };
              }),
              catchError(() => from([]))
            );
          } else {
            return from([]);
          }
        })
      )
      .subscribe(
        data => {
          const actuators = this.actuatorsToRegister;
          const storedDevice = { ...this.device, ...data.device, actuators };
          if (storedDevice.owner) {
            if (this.residence.value) {
              storedDevice.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              const populatedUser = this.residenceUsers.find(u => u._id === this.user.value._id);
              const [firstName, lastName] = populatedUser.name.split(' ');
              storedDevice.owner.user = { firstName, lastName, _id: populatedUser._id, picture: populatedUser.picture };
            }
            if (this.picture.value) {
              storedDevice.owner.picture = this.picture.value;
            }
          }
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(storedDevice);
          }
          this.status.setAsSuccess();
          this.bsModalRef.hide();
        },
        err => {
          console.log(err);
          // TODO tratar erro do fork join aqui
        }
      );
  }

  async onResidenceSelect(event) {
    const residence: Residence = event.item;
    this.residence.setValue(residence);
    const users = this.getResidenceUsers(residence);
    this.residenceUsers = [...users];
    const dependents = await this.getResidenceDependents(this.condo, residence);
    this.residenceUsers.push(...dependents);
    this.residenceUsers = orderBy(this.residenceUsers, [u => u.name.toLowerCase()]);
    this.user.setValue('');
  }

  getResidenceUsers(residence) {
    return (residence.residenceUsers || []).map(u => ({
      type: 'USER',
      _id: u._id,
      name: `${u.firstName} ${u.lastName}`,
      picture: u.picture
    }));
  }

  async getResidenceDependents(condo, residence) {
    const dependentsParams = [];
    dependentsParams.push({ '$populate[0][path]': 'picture' });
    dependentsParams.push({ '$populate[0][select]': 'url thumbnail type format name' });
    dependentsParams.push({ $sort: 'name' });
    const dependents = await this.residenceService
      .getDependents(condo._id, residence._id, dependentsParams)
      .pipe(
        map(res =>
          res.dependents.map(dep => ({
            type: 'DEPENDENT',
            _id: dep._id,
            picture: dep.picture,
            name: dep.name
          }))
        )
      )
      .toPromise();
    return dependents;
  }

  clearResidence() {
    this.residenceSearch = '';
    this.residence.setValue(null);
    this.residenceUsers = [];
    this.user.setValue(null);
  }

  buildDevice() {
    const type = this.type.value;
    const serial = this.serial.value;
    const device: any = { type, hardware: 'HIKVISION', actuators: this.actuatorsToRegister.map(ac => ac._id), obs: this.obs.value };
    if (type !== 'BM') {
      device.serial = serial;
    }

    if (this.residence.value) {
      device.owner = {
        residence: this.residence.value._id || this.residence.value
      };
      if (this.user.value) {
        device.owner.user = this.user.value._id || this.user.value;
      } else if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value._id || this.picture.value;
      }
    }

    if (this.selectedAccessGroup) {
      device['accessGroups'] = [this.selectedAccessGroup._id];
    } else {
      device['accessGroups'] = [];
    }

    return device;
  }

  initializeFieldsFromResidence(residence, selectedValues: { vehicleId?: string; userId?: string } = {}) {
    const residenceQuery: EcondosQuery = {
      $populate: [
        {
          path: 'users',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        },
        {
          path: 'voter',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        }
      ]
    };
    forkJoin([
      this.residenceService.getResidenceByIdWithParams(this.condo._id, residence._id, residenceQuery),
      this.residenceService.getVehicles(this.condo._id, residence._id)
    ])
      .pipe(timeout(15000))
      .subscribe(
        async ([residenceResponse, vehiclesResponse]) => {
          this.residenceUsers = this.getResidenceUsers(residenceResponse);
          const dependents = await this.getResidenceDependents(this.condo, residence);
          this.residenceUsers.push(...dependents);
          if (selectedValues.userId) {
            const user = this.residenceUsers.find(u => u._id === selectedValues.userId);
            this.user.setValue(user);
          } else {
            this.user.setValue('');
          }
        },
        async err => {
          console.log(err);
          await swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível obter os dados necessários, verifique sua conexão e tente novamente.'
          });
          this.bsModalRef.hide();
        }
      );
  }

  async collectFace() {
    this.actuatorService
      .getActuators(this.condo._id, { hardware: 'HIKVISION' })
      .pipe(
        timeout(10000),
        switchMap(({ actuators }) => {
          if (!actuators || !actuators.length) {
            return throwError('Actuators not found');
          }
          const actuatorsMap = actuators.reduce((acc, actuator) => {
            acc[actuator._id] = actuator;
            return acc;
          }, {});
          const inputOptions: any = actuators.reduce((acc, actuator) => {
            acc[actuator._id] = actuator.name;
            return acc;
          }, {});
          return from(
            swal({
              type: 'question',
              title: 'Selecionar equipamento',
              text: `Selecione o equipamento que você deseja utilizar para capturar a face da pessoa`,
              input: 'select',
              inputOptions,
              inputPlaceholder: 'Selecione o equipamento',
              inputValidator: value => {
                return new Promise((resolve, reject) => {
                  if (value) {
                    resolve();
                  } else {
                    reject('Selecione um equipamento para o registro e tente novamente!');
                  }
                });
              },
              showCancelButton: true,
              confirmButtonText: 'Capturar',
              confirmButtonColor: '#32DB64',
              cancelButtonColor: '#f53d3d',
              cancelButtonText: 'Cancelar',
              reverseButtons: true
            }).catch(() => swal.noop())
          ).pipe(
            switchMap(actuatorId => {
              if (!actuatorId) {
                return throwError('Canceled by user');
              }
              const actuator = actuatorsMap[actuatorId];

              const destroyed$: Subject<boolean> = new Subject<boolean>();
              swal({
                title: 'Registrando imagem...',
                text: 'A pessoa deve ficar na frente do equipamento',
                type: 'info',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
              }).catch(e => {
                destroyed$.next(true);
                if (e?.includes('cancel')) {
                  return throwError('Canceled by user');
                }
              });
              return this.hikvisionService.collectFace(actuator).pipe(
                takeUntil(destroyed$),
                timeout(60000),
                map(({ data }) => `data:image/jpeg;base64,${data}`),
                switchMap(image => this.fileService.uploadBase64(image)),
                map(([file]) => file)
              );
            })
          );
        })
      )
      .subscribe(
        (img: File) => {
          this.picture.setValue(img);
          swal.clickConfirm();
          this.toastrService.success('Facial capturada com sucesso');
        },
        err => {
          swal.close();
          const msg = err?.message || err;
          if (!msg?.includes('Canceled by user')) {
            swal({
              type: 'error',
              title: 'Ops...',
              text: (err.message || err)?.includes('Actuators not found')
                ? 'Não existem acionadores cadastrados. Antes de cadastrar uma facial é necessário você cadastrar os acionadores.'
                : 'Não foi possível realizar a captura da face. Verifique a conexão com o equipamento e tente novamente.'
            });
          }
        }
      );
  }

  public toggleAll(): void {
    this.selectAccessGroupActuators.toggleAll();
  }

  handleUseNewHikvision() {
    this.bsModalRef.hide();

    this.userLocalSettingsService.saveSetting({ key: 'useDeprecatedHikvision', value: false });

    if (this.callbacks.onUseNewHikvisionButtonClick) {
      this.callbacks.onUseNewHikvisionButtonClick();
    }
  }
}
