import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { CondoService } from '@api/service/condo.service';
import { capitalize } from '@api/util/util';

type CondoLabel = Pick<Condo, 'customLabels'>;

@Component({
  selector: 'app-modal-condo-custom-labels',
  templateUrl: 'modal-condo-custom-labels.component.html',
  styleUrls: ['./modal-condo-custom-labels.component.scss']
})
export class ModalCondoCustomLabelsComponent implements OnInit {
  condo: Condo;
  status: Status = new Status();

  form: UntypedFormGroup;
  voterSingular: AbstractControl;
  voterPlural: AbstractControl;
  dependentSingular: AbstractControl;
  dependentPlural: AbstractControl;
  residentSingular: AbstractControl;
  residentPlural: AbstractControl;
  janitorSingular: AbstractControl;
  janitorPlural: AbstractControl;
  gatekeeperSingular: AbstractControl;
  gatekeeperPlural: AbstractControl;
  adminSingular: AbstractControl;
  adminPlural: AbstractControl;
  ownerSingular: AbstractControl;
  ownerPlural: AbstractControl;
  residenceSingular: AbstractControl;
  residencePlural: AbstractControl;
  condoSingular: AbstractControl;
  condoPlural: AbstractControl;
  blockSingular: AbstractControl;
  blockPlural: AbstractControl;
  visitorSingular: AbstractControl;
  visitorPlural: AbstractControl;
  callbacks: { success?: Function };

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private condoService: CondoService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      voterSingular: [capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário', Validators.required],
      voterPlural: [capitalize(this.condo?.customLabels?.voter?.plural) || 'Proprietários', Validators.required],
      dependentSingular: [
        capitalize(this.condo?.customLabels?.dependent?.singular) || (this.condo.isBusinessCondo() ? 'Funcionário' : 'Dependente'),
        Validators.required
      ],
      dependentPlural: [
        capitalize(this.condo?.customLabels?.dependent?.plural) || (this.condo.isBusinessCondo() ? 'Funcionários' : 'Dependentes'),
        Validators.required
      ],
      residentSingular: [capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino', Validators.required],
      residentPlural: [capitalize(this.condo?.customLabels?.resident?.plural) || 'Condôminos', Validators.required],
      janitorSingular: [capitalize(this.condo?.customLabels?.janitor?.singular) || 'Zelador', Validators.required],
      janitorPlural: [capitalize(this.condo?.customLabels?.janitor?.plural) || 'Zeladores', Validators.required],
      gatekeeperSingular: [capitalize(this.condo?.customLabels?.gatekeeper?.singular) || 'Porteiro', Validators.required],
      gatekeeperPlural: [capitalize(this.condo?.customLabels?.gatekeeper?.plural) || 'Porteiros', Validators.required],
      adminSingular: [capitalize(this.condo?.customLabels?.admin?.singular) || 'Administrador', Validators.required],
      adminPlural: [capitalize(this.condo?.customLabels?.admin?.plural) || 'Administradores', Validators.required],
      ownerSingular: [capitalize(this.condo?.customLabels?.owner?.singular) || 'Síndico', Validators.required],
      ownerPlural: [capitalize(this.condo?.customLabels?.owner?.plural) || 'Síndicos', Validators.required],
      residenceSingular: [capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade', Validators.required],
      residencePlural: [capitalize(this.condo?.customLabels?.residence?.plural) || 'Unidades', Validators.required],
      condoSingular: [capitalize(this.condo?.customLabels?.condo?.singular) || 'Condomínio', Validators.required],
      condoPlural: [capitalize(this.condo?.customLabels?.condo?.plural) || 'Condomínios', Validators.required],
      blockSingular: [capitalize(this.condo?.customLabels?.block?.singular) || 'Bloco', Validators.required],
      blockPlural: [capitalize(this.condo?.customLabels?.block?.plural) || 'Blocos', Validators.required],
      visitorSingular: [capitalize(this.condo?.customLabels?.visitor?.singular) || 'Visitante', Validators.required],
      visitorPlural: [capitalize(this.condo?.customLabels?.visitor?.plural) || 'Visitantes', Validators.required]
    });
    this.voterSingular = this.form.get('voterSingular');
    this.voterPlural = this.form.get('voterPlural');
    this.dependentSingular = this.form.get('dependentSingular');
    this.dependentPlural = this.form.get('dependentPlural');
    this.residentSingular = this.form.get('residentSingular');
    this.residentPlural = this.form.get('residentPlural');
    this.janitorSingular = this.form.get('janitorSingular');
    this.janitorPlural = this.form.get('janitorPlural');
    this.gatekeeperSingular = this.form.get('gatekeeperSingular');
    this.gatekeeperPlural = this.form.get('gatekeeperPlural');
    this.adminSingular = this.form.get('adminSingular');
    this.adminPlural = this.form.get('adminPlural');
    this.ownerSingular = this.form.get('ownerSingular');
    this.ownerPlural = this.form.get('ownerPlural');
    this.residenceSingular = this.form.get('residenceSingular');
    this.residencePlural = this.form.get('residencePlural');
    this.condoSingular = this.form.get('condoSingular');
    this.condoPlural = this.form.get('condoPlural');
    this.blockSingular = this.form.get('blockSingular');
    this.blockPlural = this.form.get('blockPlural');
    this.visitorSingular = this.form.get('visitorSingular');
    this.visitorPlural = this.form.get('visitorPlural');
  }

  submit() {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const label: CondoLabel = {
        customLabels: {
          voter: {
            singular: this.voterSingular.value.toLowerCase(),
            plural: this.voterPlural.value.toLowerCase()
          },
          dependent: {
            singular: this.dependentSingular.value.toLowerCase(),
            plural: this.dependentPlural.value.toLowerCase()
          },
          resident: {
            singular: this.residentSingular.value.toLowerCase(),
            plural: this.residentPlural.value.toLowerCase()
          },
          janitor: {
            singular: this.janitorSingular.value.toLowerCase(),
            plural: this.janitorPlural.value.toLowerCase()
          },
          gatekeeper: {
            singular: this.gatekeeperSingular.value.toLowerCase(),
            plural: this.gatekeeperPlural.value.toLowerCase()
          },
          admin: {
            singular: this.adminSingular.value.toLowerCase(),
            plural: this.adminPlural.value.toLowerCase()
          },
          owner: {
            singular: this.ownerSingular.value.toLowerCase(),
            plural: this.ownerPlural.value.toLowerCase()
          },
          residence: {
            singular: this.residenceSingular.value.toLowerCase(),
            plural: this.residencePlural.value.toLowerCase()
          },
          condo: {
            singular: this.condoSingular.value.toLowerCase(),
            plural: this.condoPlural.value.toLowerCase()
          },
          block: {
            singular: this.blockSingular.value.toLowerCase(),
            plural: this.blockPlural.value.toLowerCase()
          },
          visitor: {
            singular: this.visitorSingular.value.toLowerCase(),
            plural: this.visitorPlural.value.toLowerCase()
          }
        }
      };
      this.condoService.updateCondo(this.condo._id, label).subscribe(
        res => {
          this.toastr.success('Titulos atualizados com sucesso.');
          this.callbacks.success(label.customLabels);
          this.bsModalRef.hide();
        },
        err => {
          console.error(err);
          this.status.setAsError();
          this.toastr.error('Não foi possível atualizar os titulos, tente novamente');
        }
      );
    }
  }
}
