import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

@Component({
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, ProgressbarModule],
  selector: 'app-feedback-container',
  templateUrl: './feedback-container.component.html',
  styleUrls: ['./feedback-container.component.scss']
})
export class FeedbackContainerComponent {
  @Input() type: 'info' | 'error' | 'loading' = 'info';

  @Input() faIcon = null;
  @Input() customIcon: IconDefinition = null;

  @Input() title = '';
  @Input() message = 'Mensagem do feedback';

  @Input() showRetryButton = true;
  @Input() retryButtonText = 'Tentar novamente';
  @Input() retryButtonCssClass = '';

  @Input() showLoadingProgress = false;
  @Input() loadingProgress = 0;

  @Output() handleRetry = new EventEmitter();

  public handleRetryButtonClick(): void {
    this.handleRetry.emit();
  }
}
