import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { CondoVehicle } from '@api/model/condo.vehicle';
import swal from 'sweetalert2';

@Component({
  selector: 'app-vehicle-brand-auto-complete',
  templateUrl: 'vehicle-brand-auto-complete.component.html',
  styleUrls: ['vehicle-brand-auto-complete.component.scss']
})
export class VehicleBrandAutoCompleteComponent implements OnChanges {
  @Output() selectItem: EventEmitter<Residence> = new EventEmitter<Residence>();
  @Input() placeholder = 'Buscar marca';
  @Input() disabled = false;
  @Input() container = 'body';
  @Input() vehicleType = CondoVehicle.TYPES.CAR;

  selectedBrand = '';
  VEHICLE_BRANDS: string[] = [];

  noResult = false;

  onTypeAheadSelect = evt => {
    this.selectedBrand = evt;

    if (evt.value === '+ OUTRA MARCA') {
      swal({
        title: 'Inserir marca',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Salvar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputPlaceholder: 'Digite a marca...',
        showLoaderOnConfirm: true,
        preConfirm: brand => {
          if (!brand || !brand.trim().length) {
            return Promise.reject(`Insira a marca`);
          } else {
            brand = brand.trim();
            return Promise.resolve(brand);
          }
        }
      }).then(
        brand => {
          evt.value = brand;

          this.selectItem.emit(evt);
        },
        () => {
          evt.value = '';

          this.selectItem.emit(evt);
        }
      );
    } else {
      this.selectItem.emit(evt);
    }

    this.resetValue();
  };

  constructor() {
    this.VEHICLE_BRANDS = Object.keys(CondoVehicle.VEHICLE_BRANDS).map(brand => CondoVehicle.VEHICLE_BRANDS[brand]);
    this.VEHICLE_BRANDS.unshift('+ OUTRA MARCA');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vehicleType?.currentValue) {
      if (changes.vehicleType?.currentValue === CondoVehicle.TYPES.MOTORCYCLE) {
        this.VEHICLE_BRANDS = Object.keys(CondoVehicle.MOTORCYCLE_BRANDS).map(brand => CondoVehicle.MOTORCYCLE_BRANDS[brand]);
      } else if (changes.vehicleType?.currentValue === CondoVehicle.TYPES.BICYCLE) {
        this.VEHICLE_BRANDS = Object.keys(CondoVehicle.BICYCLE_BRANDS).map(brand => CondoVehicle.BICYCLE_BRANDS[brand]);
      } else {
        this.VEHICLE_BRANDS = Object.keys(CondoVehicle.VEHICLE_BRANDS).map(brand => CondoVehicle.VEHICLE_BRANDS[brand]);
      }

      this.VEHICLE_BRANDS.unshift('+ OUTRA MARCA');
    }
  }

  resetValue() {
    this.selectedBrand = '';
  }

  typeaheadOnBlur(event: any): void {
    this.resetValue();
  }
}
