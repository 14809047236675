import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { catchError, distinctUntilChanged, filter, map, mergeMap, retry, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { ResponseError } from '@api/model/error/response.error';
import { serialToWiegand } from '@api/utils';
import { HardwareSocketService } from '../../services/hardware-socket.service';
import { EcondosQuery } from '@api/model/query';
import { User } from '@api/model/user';
import { Actuator } from '@api/model/hardware/actuator';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { orderBy } from 'lodash';
import { ModalCreateVehicleComponent } from '../../components/modal-create-vehicle/modal-create-vehicle.component';

@Component({
  selector: 'app-modal-create-nice-controller-device',
  templateUrl: 'modal-create-nice-controller-device.component.html',
  styleUrls: ['./modal-create-nice-controller-device.component.scss']
})
export class ModalCreateNiceControllerDeviceComponent implements OnInit, OnDestroy {
  condo: Condo;
  condoActuators: Actuator[] = [];

  DEVICE_TYPES = DEVICE_TYPES;

  TYPES = [
    { label: 'RF - Controle', value: DEVICE_TYPES.RF, code: 1 },
    // {label: 'TA - Tag Ativo', value: DEVICE_TYPES.TA, code: 2},
    { label: 'CT - Cartão ou chaveiro', value: DEVICE_TYPES.CT, code: 3 },
    { label: 'TP - Tag passivo', value: DEVICE_TYPES.TP, code: 6 },
    { label: 'SN - Senha', value: DEVICE_TYPES.SN, code: 7 },
    { label: 'BM - Biometria', value: DEVICE_TYPES.BM, code: 8 }
  ];

  status: Status = new Status();

  wiegand = '';

  device: Device;
  callbacks: { success?: (arg) => void; error?: (err) => void };

  form: UntypedFormGroup;

  type: AbstractControl;
  serial: AbstractControl;
  residence: AbstractControl;
  vehicle: AbstractControl;
  user: AbstractControl;
  userName: AbstractControl;
  picture: AbstractControl;
  actuators: UntypedFormArray;
  panic: AbstractControl;
  antiPassback: AbstractControl;
  safeExit: AbstractControl;
  obs: AbstractControl;

  residenceSearch;
  residenceUsers = [];
  loadingResidences = false;
  residenceVehicles: CondoVehicle[] = [];

  residenceTypeAheadDataSource$: Observable<any>;

  storageUser: { user: User; residence: Residence };

  fingerprints = [];

  passwordPattern;

  selectedResidence;

  // Used on view to easily check if an actuator is checked or not
  checkedActuators = {};

  public readonly antiPassbackTypes = ['Desligado', 'Entrou', 'Saiu', 'Indeterminado'];

  public readonly safeExitTypes = ['Leitora 2 e 3', 'Leitora 3'];

  private subscriptions: Subscription = new Subscription();

  userPicture;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private hardwareSocketService: HardwareSocketService,
    private deviceService: HardwareDeviceService,
    private toastrService: ToastrService,
    private readonly actuatorService: ActuatorService,
    private modalService: BsModalService
  ) {
    this.form = this.fb.group({
      type: ['', Validators.required],
      serial: ['', [Validators.required, Validators.minLength(6)]],
      // counter: [''],
      // idBio: [''],
      user: [null],
      userName: [''],
      residence: [null, [Validators.required]],
      vehicle: [''],
      picture: [null],
      actuators: this.fb.array([], [Validators.required]),
      panic: [false],
      antiPassback: [''],
      safeExit: ['Leitora 2 e 3'],
      obs: ['']
      // receptors: [[false, false, false, false, false, false, false, false]]
    });
    this.type = this.form.get('type');
    this.serial = this.form.get('serial');
    // this.counter = this.form.get('counter');
    // this.idBio = this.form.get('idBio');
    this.user = this.form.get('user');
    this.userName = this.form.get('userName');
    this.residence = this.form.get('residence');
    this.vehicle = this.form.get('vehicle');
    this.picture = this.form.get('picture');
    this.actuators = this.form.get('actuators') as UntypedFormArray;
    this.panic = this.form.get('panic');
    this.antiPassback = this.form.get('antiPassback');
    this.safeExit = this.form.get('safeExit');
    this.obs = this.form.get('obs');
    // this.receptors = this.form.get('receptors');

    this.subscriptions.add(
      this.type.valueChanges.subscribe(val => {
        if (val === DEVICE_TYPES.BM) {
          // Set any value to ignore validation, because serial field is not visible to the user when selected type is BM
          this.serial.setValue('000000', { emitEvent: false });
        } else {
          this.serial.setValue('', { emitEvent: false });
        }

        if (val === DEVICE_TYPES.SN) {
          if (this.condo && this.condo.linear && this.condo.linear.residentPasswordFormat === 'ONLY-PASS') {
            this.serial.setValidators([Validators.required, Validators.minLength(6)]);
          } else {
            this.serial.setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(6)]);
          }
        } else {
          this.serial.setValidators([Validators.required, Validators.minLength(6)]);
        }

        this.wiegand = '';
      })
    );

    this.subscriptions.add(
      this.serial.valueChanges.subscribe(val => {
        if (this.type.value === DEVICE_TYPES.SN) {
          // Remove zeros from begin and non numerical values
          const serial = (val || '').replace(/^[0|\D]*|[^\d]/g, '');
          this.serial.setValue(serial, { emitEvent: false });
        } else {
          // Replaces all non hexadecimal characters
          const serial = (val || '').replace(/[^abcdefABCDEF\d]/g, '');
          this.serial.setValue(serial, { emitEvent: false });
          if (this.type.value === DEVICE_TYPES.CT) {
            try {
              if (serial.length === 6) {
                const w = serialToWiegand(serial);
                this.wiegand = w.slice(0, 3) + ' ' + w.slice(3);
              } else if (serial.length > 6) {
                this.wiegand = serial.slice(0, 3) + ' ' + serial.slice(3);
                const s = this.wiegandToSerial(serial);
                this.serial.setValue(s, { emitEvent: false });
              } else {
                this.wiegand = '';
              }
            } catch (e) {
              this.wiegand = '';
            }
          }
        }
      })
    );

    this.subscriptions.add(
      this.user.valueChanges.subscribe(userId => {
        if (userId === 'NEW_USER') {
          swal({
            title: 'Inserir nome',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            inputPlaceholder: 'Digite o nome...',
            showLoaderOnConfirm: true,
            preConfirm: name => {
              if (!name || !name.trim().length) {
                return Promise.reject(`Insira o nome`);
              } else {
                name = name.trim();
                return Promise.resolve(name);
              }
            }
          }).then(
            name => {
              this.userName.setValue(name);
              this.user.setValue('');
            },
            () => {
              this.userName.setValue('');
              this.user.setValue('');
            }
          );
        } else {
          const user = this.residenceUsers?.find(u => u._id === userId) || null;
          this.userPicture = user?.picture;
          if (this.checkHasDeviceOwnerPicture()) {
            this.picture.setValue(this.device.owner.picture);
          } else if (this.device?.owner?.picture && this.device?.owner?.user._id === userId) {
            this.picture.setValue(this.device.owner.picture);
          } else if (user && user.picture && user.picture._id) {
            this.picture.setValue(user.picture);
          } else {
            this.picture.setValue(null);
          }
        }
      })
    );

    this.initializeTypeAheads();
  }

  initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter((text: string) => !!(text || '').trim()),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type voter users',
          $populate: [
            { path: 'users', select: 'firstName lastName picture phones', populate: { path: 'picture', select: 'url thumbnail' } },
            { path: 'voter', select: 'firstName lastName picture phones', populate: { path: 'picture', select: 'url thumbnail' } }
          ]
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(e => {
            this.status.setAsError();
            return of([]);
          })
        );
      })
    );
  }

  checkHasDeviceOwnerPicture() {
    return this.device?.owner?.picture ? true : false;
  }

  loadNiceControllerReceivers() {
    this.actuatorService
      .getActuators(this.condo._id, { hardware: 'NICE_CONTROLLER' })
      .pipe(retry(3))
      .subscribe({
        next: actuatorsResponse => {
          const actuators = [];
          actuatorsResponse.actuators.forEach(actuator => {
            /* Verifica se existe outros acionadores com o mesmo endereço MAC */
            const index = actuators.findIndex(a => a.macAddress === actuator.macAddress);
            if (index === -1) {
              actuators.push(actuator);
            } else {
              let name = actuators[index].name;
              name += `, ${actuator.name}`;
              actuators[index].name = name;
            }
          });
          this.condoActuators = actuators;
        },
        error: err => {
          // TODO tratar casos de erro
          console.log(err);
        }
      });
  }

  ngOnInit() {
    this.loadNiceControllerReceivers();
    if (this.device) {
      this.type.setValue(this.device.type);
      this.serial.setValue(this.device.serial);
      this.panic.setValue(this.device.panic);
      this.obs.setValue(this.device.obs);
      if (this.device.owner) {
        if (this.device.owner.residence && this.device.owner.residence._id) {
          this.residence.setValue(this.device.owner.residence);
          const vehicleId: any = this.device.owner && this.device.owner.condoVehicle && this.device.owner.condoVehicle._id;
          const userId: any = (this.device.owner && this.device.owner.user && this.device.owner.user._id) || '';
          this.initializeFieldsFromResidence(this.device.owner.residence, { userId, vehicleId });
        }
        if (this.device.owner.condoVehicle && this.device.owner.condoVehicle._id) {
          this.residenceVehicles = [this.device.owner.condoVehicle];
          this.vehicle.setValue(this.device.owner.condoVehicle._id);
        } else {
          this.vehicle.setValue('');
        }
        if (this.device.owner.user && this.device.owner.user._id) {
          this.residenceUsers = [this.device.owner.user];
          this.user.setValue(this.device.owner.user._id);
        } else {
          this.user.setValue('');
          if (this.device.owner.userName) {
            this.userName.setValue(this.device.owner.userName);
          } else if (this.device.hardwareAttributes && this.device.hardwareAttributes.rotulo) {
            this.userName.setValue(this.device.hardwareAttributes.rotulo);
          }
        }
        if (this.device.owner.picture && this.device.owner.picture._id) {
          this.picture.setValue(this.device.owner.picture);
        }
        if (this.device.actuators) {
          this.device.actuators.forEach(actuator => {
            const actuatorId = actuator?._id;
            const fc = new UntypedFormControl(actuatorId);
            this.actuators.push(fc);
            this.checkedActuators[actuatorId] = true;
          });
        }
      }
      if (this.device.hardwareAttributes) {
        this.antiPassback.setValue(this.device.hardwareAttributes.antiPassback || '');
        this.safeExit.setValue(this.device.hardwareAttributes.safeExit || 'Leitora 2 e 3');
      }
      this.userPicture = this.device.owner?.picture?._id;
    } else if (this.selectedResidence) {
      this.residence.setValue(this.selectedResidence);
      this.residence.disable();
      this.initializeFieldsFromResidence(this.selectedResidence);
    }

    this.passwordPattern = this.condo && this.condo.linear && this.condo.linear.residentPasswordFormat;

    if (this.storageUser) {
      if (this.storageUser.residence) {
        this.residence.setValue(this.storageUser.residence);
        this.residence.disable();
        if (!this.storageUser.user) {
          this.initializeFieldsFromResidence(this.storageUser.residence);
        }
        this.user.setValue('');
      }
      if (this.storageUser.user) {
        this.user.setValue(this.storageUser.user._id);
        this.residenceUsers = [this.storageUser.user];
        this.user.setValue(this.residenceUsers[0]?._id);
        this.user.disable();
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residence.setValue(residences[0]);
          this.getResidenceVehicles(residences[0].id);
        }
        if (this.storageUser.user.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  readFromUsb(device) {
    const deviceType = this.TYPES.find(t => t.code === device.type);
    if (deviceType) {
      this.type.setValue(deviceType.value || 2);
      this.serial.setValue(device.serial);
    }
  }

  save(value) {
    if (this.form.valid) {
      const device = this.buildDevice();
      let subscription;
      if (this.device) {
        // update
        subscription = this.deviceService.update(this.condo._id, this.device._id, device);
      } else {
        // create
        subscription = this.deviceService.create(this.condo._id, device);
      }
      this.status.setAsProcessing();
      subscription.subscribe({
        next: (res: Device) => {
          let storedDevice;
          if (this.device) {
            this.toastrService.success('Dispositivo atualizado com sucesso');
            storedDevice = { ...this.device, ...res };
          } else {
            this.toastrService.success('Dispositivo registrado com sucesso');
            storedDevice = { ...device, ...res };
          }
          if (storedDevice.owner) {
            if (this.residence.value) {
              storedDevice.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              storedDevice.owner.user = this.residenceUsers?.find(u => u._id === this.user.value) || null;
            }
            if (this.vehicle.value) {
              storedDevice.owner.condoVehicle = this.residenceVehicles.find(v => v._id === this.vehicle.value._id);
            }
            if (this.picture.value) {
              storedDevice.owner.picture = this.picture.value;
            }
          }
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(storedDevice);
          }
          this.status.setAsSuccess();
          this.bsModalRef.hide();
        },
        error: err => {
          this.status.setAsError();
          if (
            err instanceof ResponseError &&
            !err.message &&
            err.originalError &&
            err.originalError.error &&
            err.originalError.error.includes('Device is already registered')
          ) {
            swal({
              type: 'error',
              title: 'Serial já cadastrado',
              text: `Já existe um dispositivo com este serial cadastrado em seu(sua) ${this.condo?.customLabels?.condo?.singular}.`
            });
          } else {
            swal({
              type: 'error',
              title: err.messageTitle || 'Ops...',
              text: err.message || 'Não foi possível cadastrar este dispositivo.'
            });
          }
        }
      });
    } else {
      this.toastrService.warning('Preencha todos os campos');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }

  onResidenceSelect(event) {
    const residence: Residence = event.item;
    this.residence.setValue(residence);
    this.residenceUsers = residence.residenceUsers;
    this.residenceUsers = orderBy(this.residenceUsers, [u => u.fullName.toLowerCase()]);
    this.user.setValue('');
    this.vehicle.setValue('');
    this.getResidenceVehicles(residence.id);
  }

  getResidenceVehicles(residenceId: string) {
    this.residenceService.getVehicles(this.condo._id, residenceId).subscribe({
      next: res => {
        this.residenceVehicles = res.vehicles;
      },
      error: err => {
        console.log(err);
      }
    });
  }

  clearResidence() {
    this.residenceSearch = '';
    this.residence.setValue(null);
    this.residenceUsers = [];
    this.user.setValue(null);
    this.residenceVehicles = [];
  }

  buildDevice() {
    const type = this.type.value;
    let serial = this.serial.value.toUpperCase();
    const panic = this.panic.value;
    const hardwareAttributes = {
      antiPassback: this.antiPassback.value || 'Desligado',
      safeExit: this.safeExit.value || 'Leitora 2 ou 3'
    };
    if (type === 'RF' && serial.length < 8) {
      while (serial.length < 8) {
        serial = '0'.concat(serial);
      }
    }
    const device: any = {
      hardware: 'NICE_CONTROLLER',
      type,
      serial,
      panic,
      hardwareAttributes,
      obs: this.obs.value
    };

    if (this.residence.value) {
      device.owner = {
        residence: this.residence.value._id
      };
      if (this.user.value) {
        device.owner.user = this.user.value;
      } else if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }
      if (this.vehicle.value) {
        device.owner.condoVehicle = this.vehicle.value._id || this.vehicle.value;
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value._id;
      }
    }
    if (this.actuators.controls.length) {
      device.actuators = this.actuators.controls.filter(v => v).map(v => v.value);
    }
    return device;
  }

  saveBio() {
    const hasFingerPrints = this.fingerprints.length === 4;
    if (this.form.valid && hasFingerPrints) {
      this.status.setAsProcessing();
      const device = this.buildDevice();
      const template = this.fingerprints.map(f => f.template).join('');
      device.template = template;
      this.deviceService
        .create(this.condo._id, device)
        .pipe(
          map((res: any) => {
            let storedDevice;
            storedDevice = { ...device, ...res };
            if (storedDevice.owner) {
              if (this.residence.value) {
                storedDevice.owner.residence = this.residence.value;
              }
              if (this.user.value) {
                storedDevice.owner.user = this.residenceUsers.find(u => u._id === this.user.value);
              }
              if (this.vehicle.value) {
                storedDevice.owner.condoVehicle = this.residenceVehicles.find(v => v._id === this.vehicle.value);
              }
              if (this.picture.value) {
                storedDevice.owner.picture = this.picture.value;
              }
            }
            return storedDevice;
          })
        )
        .subscribe({
          next: (res: any) => {
            if (this.callbacks && this.callbacks.success) {
              this.callbacks.success(res);
            }
            this.status.setAsSuccess();
            this.bsModalRef.hide();
          },
          error: err => {
            this.status.setAsError();
          }
        });
    } else {
      const message = `${hasFingerPrints ? 'Preencha todos os campos' : 'Você deve coletar as digitais antes de salvar uma biometria'}`;
      const title = `${hasFingerPrints ? '' : 'Preencha todos os campos'}`;
      this.toastrService.warning(message, title);
      this.form.markAllAsTouched();
    }
  }

  async collectFingerprint() {
    const destroy$ = new Subject();

    this.fingerprints = [];

    const steps: SweetAlertOptions[] = [
      {
        title: 'Dedo 1',
        text: 'Coloque o dedo na leitora',
        showConfirmButton: false,
        showCancelButton: true,
        progressSteps: ['1', '2', '3', '4'],
        imageUrl: 'assets/svg/fingerprint.svg',
        imageWidth: 200,
        imageHeight: 200
      },
      {
        title: 'Confirme o dedo 1',
        text: 'Coloque o mesmo dedo novamente na leitora',
        showConfirmButton: false,
        showCancelButton: true,
        progressSteps: ['1', '2', '3', '4'],
        imageUrl: 'assets/svg/fingerprint.svg',
        imageWidth: 200,
        imageHeight: 200
      },
      {
        title: 'Dedo 2',
        text: 'Agora coloque o segundo dedo na leitora',
        showConfirmButton: false,
        showCancelButton: true,
        progressSteps: ['1', '2', '3', '4'],
        imageUrl: 'assets/svg/fingerprint.svg',
        imageWidth: 200,
        imageHeight: 200
      },
      {
        title: 'Confirme o dedo 2',
        text: 'Confirme o dedo 2 colocando-o novamente na leitora',
        showConfirmButton: false,
        showCancelButton: true,
        progressSteps: ['1', '2', '3', '4'],
        imageUrl: 'assets/svg/fingerprint.svg',
        imageWidth: 200,
        imageHeight: 200
      }
    ];

    swal
      .queue(steps)
      .then(res => {
        swal({ type: 'success', title: 'Dados registrados com sucesso', confirmButtonText: 'Ok!', showCancelButton: false });
      })
      .catch(err => {
        destroy$.next(null);
        console.log(err);
      });

    const subscription = new Subscription();
    subscription.add(
      this.hardwareSocketService.onData$
        // timer(1500, 1000)
        .pipe(
          // map(() => this.getRandomFInger()),
          filter(eventToFront => eventToFront?.command === 'unregisteredBioSentAutomatically' && eventToFront?.condoId === this.condo._id),
          tap(res => console.log(res)),
          map(eventToFront => ({
            ...eventToFront.result,
            percentage: Math.round((parseInt(eventToFront?.result?.template?.substr(0, 2), 16) / 42) * 100)
          })),
          takeUntil(destroy$),
          tap(finger => {
            if (finger.percentage > 35) {
              this.toastrService.success('Digital registrada');
              this.fingerprints.push(finger);
              swal.clickConfirm();
            } else {
              this.toastrService.warning('Digital fraca');
              swal.showValidationError('Lido apenas ' + finger.percentage + '% da digital, insira o dedo novamente');
            }
          })
        )
        .subscribe({
          next: () => {
            if (this.fingerprints.length === 4) {
              destroy$.next(null);
            }
          },
          error: err => {
            console.log(err);
          }
        })
    );
  }

  // getRandomFInger() {
  //   const fingers = [
  //     '{"command":"unregisteredFingerCtw","result":{"num_disp":1,"tipo_disp":"CT","leitora":"Leitora 1","tamanho_template":169,"template":"1b0e275c61178a698126149bc12a0911c12b0f1921390ddde13a8690213b949f413e09e5414f96a041528bce815c22c8416b1b4621719fc5a1788b94a17ea242413317c6a243981e6244926082450fe1c25495628258258842608d11c2691742427226054274ad0402759c04e215ac0fa81b1f07f90e481e580bd911e81e9fffffffff081016d8041505a10bb01360ffffffffffff571a00000000ef15000000000000000100000000"},"bytes":[0,58,1,3,0,0,169,27,14,39,92,97,23,138,105,129,38,20,155,193,42,9,17,193,43,15,25,33,57,13,221,225,58,134,144,33,59,148,159,65,62,9,229,65,79,150,160,65,82,139,206,129,92,34,200,65,107,27,70,33,113,159,197,161,120,139,148,161,126,162,66,65,51,23,198,162,67,152,30,98,68,146,96,130,69,15,225,194,84,149,98,130,88,37,136,66,96,141,17,194,105,23,66,66,114,38,5,66,116,173,4,2,117,156,4,226,21,172,15,168,27,31,7,249,14,72,30,88,11,217,17,232,30,159,255,255,255,255,8,16,22,216,4,21,5,161,11,176,19,96,255,255,255,255,255,255,87,26,0,0,0,0,239,21,0,0,0,0,0,0,0,1,0,0,0,0,222],"condoId":"5d84d6f1673d510bc6404856"}',
  //     '{"command":"unregisteredFingerCtw","result":{"num_disp":1,"tipo_disp":"CT","leitora":"Leitora 1","tamanho_template":169,"template":"1b0e275c61178a698126149bc12a0911c12b0f1921390ddde13a8690213b949f413e09e5414f96a041528bce815c22c8416b1b4621719fc5a1788b94a17ea242413317c6a243981e6244926082450fe1c25495628258258842608d11c2691742427226054274ad0402759c04e215ac0fa81b1f07f90e481e580bd911e81e9fffffffff081016d8041505a10bb01360ffffffffffff571a00000000ef15000000000000000100000000"},"bytes":[0,58,1,3,0,0,169,27,14,39,92,97,23,138,105,129,38,20,155,193,42,9,17,193,43,15,25,33,57,13,221,225,58,134,144,33,59,148,159,65,62,9,229,65,79,150,160,65,82,139,206,129,92,34,200,65,107,27,70,33,113,159,197,161,120,139,148,161,126,162,66,65,51,23,198,162,67,152,30,98,68,146,96,130,69,15,225,194,84,149,98,130,88,37,136,66,96,141,17,194,105,23,66,66,114,38,5,66,116,173,4,2,117,156,4,226,21,172,15,168,27,31,7,249,14,72,30,88,11,217,17,232,30,159,255,255,255,255,8,16,22,216,4,21,5,161,11,176,19,96,255,255,255,255,255,255,87,26,0,0,0,0,239,21,0,0,0,0,0,0,0,1,0,0,0,0,222],"condoId":"5d84d6f1673d510bc6404856"}',
  //     '{"command":"unregisteredFingerCtw","result":{"num_disp":1,"tipo_disp":"CT","leitora":"Leitora 1","tamanho_template":169,"template":"250c2f9bc11b14aa41291f5b212e1391a12f1918213b9764813d8ae0c13e9f9f0141118e214214a46146a45ec14b97a3c15421df4156ab5f615a8a4d015b1290e15d2b09015e2e87c16218d0a164a3c6816da240616da7056174ac058175274341062143e235a2c60237981842418d4b42471d5fe2489b21224d8ce3c25720a1a259b147e272b204a277a5c1627a1093827c169422571a00000000ef15000000000000000100000000"},"bytes":[0,58,1,3,0,0,169,37,12,47,155,193,27,20,170,65,41,31,91,33,46,19,145,161,47,25,24,33,59,151,100,129,61,138,224,193,62,159,159,1,65,17,142,33,66,20,164,97,70,164,94,193,75,151,163,193,84,33,223,65,86,171,95,97,90,138,77,1,91,18,144,225,93,43,9,1,94,46,135,193,98,24,208,161,100,163,198,129,109,162,64,97,109,167,5,97,116,172,5,129,117,39,67,65,6,33,67,226,53,162,198,2,55,152,24,66,65,141,75,66,71,29,95,226,72,155,33,34,77,140,227,194,87,32,161,162,89,177,71,226,114,178,4,162,119,165,193,98,122,16,147,130,124,22,148,34,87,26,0,0,0,0,239,21,0,0,0,0,0,0,0,1,0,0,0,0,188],"condoId":"5d84d6f1673d510bc6404856"}',
  //     '{"command":"unregisteredFingerCtw","result":{"num_disp":1,"tipo_disp":"CT","leitora":"Leitora 1","tamanho_template":169,"template":"0e382f1b21551e5b215a9797c15fa245a165981aa16a9e9e616b9720c1741ae1c17722df21192443826b14e4627297a28273118da2731c20421ca822a7288bffffffff12f71e5f1abf1c2725ef2373226f1eee14540fa31e9423ef17f40ba30e40249c0f600e48136824f716d815ac0fa81b1f07f90e481e580bd911e81e9fffffffff081016d8041505a10bb01360ffffffffffff571a00000000ef15000000000000000100000000"},"bytes":[0,58,1,3,0,0,169,14,56,47,27,33,85,30,91,33,90,151,151,193,95,162,69,161,101,152,26,161,106,158,158,97,107,151,32,193,116,26,225,193,119,34,223,33,25,36,67,130,107,20,228,98,114,151,162,130,115,17,141,162,115,28,32,66,28,168,34,167,40,139,255,255,255,255,18,247,30,95,26,191,28,39,37,239,35,115,34,111,30,238,20,84,15,163,30,148,35,239,23,244,11,163,14,64,36,156,15,96,14,72,19,104,36,247,22,216,21,172,15,168,27,31,7,249,14,72,30,88,11,217,17,232,30,159,255,255,255,255,8,16,22,216,4,21,5,161,11,176,19,96,255,255,255,255,255,255,87,26,0,0,0,0,239,21,0,0,0,0,0,0,0,1,0,0,0,0,68],"condoId":"5d84d6f1673d510bc6404856"}'
  //   ]
  //   const finger = fingers[Math.floor(Math.random() * fingers.length)];
  //   return JSON.parse(finger);
  // }

  initializeFieldsFromResidence(residence, selectedValues: { vehicleId?: string; userId?: string } = {}) {
    const residenceQuery: EcondosQuery = {
      $populate: [
        { path: 'users', select: 'firstName lastName picture', populate: { path: 'picture', select: 'url thumbnail format name' } },
        { path: 'voter', select: 'firstName lastName picture', populate: { path: 'picture', select: 'url thumbnail format name' } }
      ]
    };
    forkJoin({
      residences: this.residenceService.getResidenceByIdWithParams(this.condo._id, residence._id, residenceQuery),
      vehicles: this.residenceService.getVehicles(this.condo._id, residence._id)
    })
      .pipe(timeout(15000))
      .subscribe({
        next: ({ residences, vehicles }) => {
          this.residenceUsers = residences.residenceUsers;
          this.residenceVehicles = vehicles.vehicles;
          this.vehicle.setValue(selectedValues.vehicleId || '');
          this.user.setValue(selectedValues.userId || '');
        },
        error: async err => {
          console.log(err);
          await swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível obter os dados necessários, verifique sua conexão e tente novamente.'
          });
          this.bsModalRef.hide();
        }
      });
  }

  async collectCard() {
    const destroy$ = new Subject();

    this.hardwareSocketService.onData$
      .pipe(
        timeout(60000),
        filter(
          data =>
            data.condoId === this.condo._id &&
            data.hardwareEvent &&
            data.hardwareEvent.eventType === 'OTHER' &&
            data.hardwareEvent.type === 'CT' &&
            data.hardwareEvent.serial &&
            data.hardwareData &&
            data.hardwareData.command === 'eventSentAutomatically' &&
            data.hardwareData.result &&
            data.hardwareData.result.event &&
            data.hardwareData.result.event.eventType === 'Dispositivo não cadastrado' &&
            data.hardwareData.result.event.deviceType === 'CT'
        ),
        map(res => ({ ...res.hardwareEvent })),
        map(res => (res && res.serial) || ''),
        takeUntil(destroy$),
        take(1),
        tap(() => {
          swal.clickConfirm();
        })
      )
      .subscribe({
        next: serial => {
          this.toastrService.success(`Cartão ${serial} lido com sucesso`);
          this.serial.setValue(serial);
          destroy$.next(null);
          destroy$.complete();
        },
        error: err => {
          swal({
            type: 'error',
            title: 'Erro na leitura',
            text: 'Não foi possível realizar a leitura do cartão'
          });
          destroy$.next(null);
          destroy$.complete();
          console.log(err);
        }
      });

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Passe o cartão na leitora`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(e => {
      destroy$.next(null);
      destroy$.complete();
    });
  }

  toggleActuator(actuator: Actuator) {
    const checkArray: UntypedFormArray = this.actuators;
    const isChecked = checkArray.value.includes(actuator._id);
    if (isChecked) {
      let i = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == actuator._id) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    } else {
      checkArray.push(new UntypedFormControl(actuator._id));
    }
    this.checkedActuators = checkArray.value.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }

  toggleAll() {
    if (this.condoActuators.every(ac => this.checkedActuators[ac._id])) {
      this.condoActuators.forEach(ac => {
        if (!this.checkedActuators[ac._id]) {
          return;
        }
        this.toggleActuator(ac);
      });
    } else {
      this.condoActuators.forEach(ac => {
        if (this.checkedActuators[ac._id]) {
          return;
        }
        this.toggleActuator(ac);
      });
    }
  }

  // Precisa usar esse método por causa da conversão que existe na tela
  private wiegandToSerial = wiegand => {
    wiegand = wiegand.replace(/\D/g, '');
    if (wiegand.length === 8) {
      let firstPart = parseInt(wiegand.substring(0, 3), 10).toString(16);
      while (firstPart.length < 2) {
        firstPart = '0'.concat(firstPart);
      }
      let secondPart = parseInt(wiegand.substring(3, 8), 10).toString(16);
      while (secondPart.length < 4) {
        secondPart = '0'.concat(secondPart);
      }
      const serial = firstPart.concat(secondPart).toUpperCase().substr(-6);
      return serial;
    } else {
      throw new Error('Invalid Wiegand');
    }
  };

  createVehicle(condo: Condo, user: User = null, residence: Residence = null) {
    const initialState = {
      condo,
      onCreate: (vehicle: CondoVehicle) => {
        this.residenceVehicles = [...this.residenceVehicles, vehicle];
        this.vehicle.setValue(vehicle._id);
      },
      ...(residence && { vehicleResidence: residence }),
      ...(user && { vehicleUser: user })
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState });
  }
}
