import { Injectable } from '@angular/core';

import { UtilService } from './util.service';
import { UserService } from '../api/service/user.service';
import { ConstantService } from './constant.service';

import { Observable } from 'rxjs';

// Model
import { User } from '../api/model/user';
import { HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SessionService } from '@api/service/session.service';

@Injectable()
export class AuthService {
  private endPoint;
  private queryString;

  constructor(
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private userService: UserService,
    private constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}users/me`;
    this.queryString = this.userService.getUserQS();
  }

  login(userName, password): Observable<User> {
    let headers = new HttpHeaders();
    const authHeader = 'Basic ' + btoa(userName.trim().toLowerCase() + ':' + password);
    headers = headers.append('Authorization', authHeader);
    return this.httpService.getWithCustomHeader(`${this.endPoint}?${this.queryString}`, headers).pipe(map(user => new User(user)));
  }

  loginWithToken(token): Observable<User> {
    let headers = new HttpHeaders();
    const authHeader = 'Bearer ' + token;
    headers = headers.append('Authorization', authHeader);
    return this.httpService.getWithCustomHeader(`${this.endPoint}?${this.queryString}`, headers).pipe(map(user => new User(user)));
  }

  registerSession(user: User, username = '', password = '') {
    if (!user.defaultCondo) {
      user.defaultCondo = [].concat(user.condosAdmin, user.condosOwner, user.condosGatekeeper, user.condosJanitor, user.condos)[0] || null;
    }

    if (!user.defaultResidence) {
      user.defaultResidence = [].concat(user.residencesUser, user.residencesVoter, user.residencesRequester)[0] || null;
    }

    if (username && password) {
      this.utilService.saveLocalAuthorizationHeader(username, password);
    }
    if (user.token) {
      this.utilService.saveLocalToken(user.token);
    }

    this.utilService.saveLocalUser(user);
    this.utilService.saveLocalCondo(user.defaultCondo);
  }

  unregisterSession() {
    this.sessionService.logout();
    this.utilService.removeLocalAuthorizationHeader();
    this.utilService.removeLocalToken();
    return new Promise(resolve => {
      resolve('');
    });
  }
}
