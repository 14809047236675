import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { Group } from '@api/model/group';
import { EcondosQuery } from '@api/model/query';
import { GroupService } from '@api/serviceV2/group.service';
import { merge, noop, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-groups-picker',
  templateUrl: 'groups-picker.component.html',
  styleUrls: ['./groups-picker.component.scss']
})
export class GroupsPickerComponent implements OnDestroy {
  @Input() placeholder = 'Busque pelo grupo';
  @Input() condo: Condo;
  @Output() onGroupSelect: EventEmitter<Group> = new EventEmitter<Group>();
  @Input() showIcon = false;

  @ViewChild('groupsList') groupsList;

  searchStatus: 'LOADING' | 'SUCCESS' | 'ERROR' = null;

  searchText: UntypedFormControl = new UntypedFormControl('');

  groups: Group[] = [];

  activeIndex = 0;

  unsubscribe$ = new Subject();

  _search$ = new Subject();

  search$ = merge(
    this._search$.asObservable().pipe(
      throttleTime(500),
      map(value => ({ value, source: 'Enter' }))
    ),
    this.searchText.valueChanges.pipe(
      tap(() => {
        this.groups = [];
        this.searchStatus = null;
      }),
      debounceTime(5000),
      map(value => ({ value, source: 'Search' }))
    )
  );

  isGroupSelected = false;

  constructor(private groupService: GroupService) {
    this.search$
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((previous, current) => current.source === 'Search' && previous.value === current.value),
        map(value => value.value),
        filter(value => !!value),
        switchMap(token => {
          this.groups = [];
          this.searchStatus = 'LOADING';

          const query: EcondosQuery = {
            $select: 'name description icon',
            $sort: 'name',
            name: { $regex: this.searchText.value, $options: 'i' }
          };

          return this.groupService.get(this.condo._id, query).pipe(
            map(v => ({ ...v, error: false })),
            catchError(err => {
              this.searchStatus = 'ERROR';

              return of({ groups: [], error: true });
            })
          );
        }),
        tap(({ groups, error }) => {
          this.groups = groups;

          if (error) {
            this.searchStatus = 'ERROR';
          } else {
            this.searchStatus = 'SUCCESS';
          }
        })
      )
      .subscribe(noop);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  selectGroup(group: Group) {
    this.searchText.setValue(group.name, { emitEvent: false });
    this.onGroupSelect.emit(group);
    this.groups = [];
    this.isGroupSelected = true;

    this.searchText.setValue('');
    this.isGroupSelected = false;
  }

  pressEnter(event) {
    if (this.groups.length) {
      this.selectGroup(this.groups[this.activeIndex]);
    } else {
      this._search$.next(this.searchText.value);
    }
  }

  selectIndex(index) {
    this.activeIndex = index;
  }

  incrementIndex(event) {
    this.activeIndex < this.groups.length - 1 ? this.activeIndex++ : (this.activeIndex = 0);
    this.groupsList?.nativeElement?.children[this.activeIndex].scrollIntoViewIfNeeded();
  }

  decrementIndex(event) {
    this.activeIndex > 0 ? this.activeIndex-- : (this.activeIndex = this.groups.length - 1);
    this.groupsList?.nativeElement?.children[this.activeIndex].scrollIntoViewIfNeeded();
  }
}
