import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'person-chip-component',
  template: `
    <style>
      .chip {
        display: inline-block;
        padding: 0 14px;
        height: 40px;
        font-size: var(--font-size-lg);
        line-height: 40px;
        border-radius: 20px;
        background-color: #f1f1f1;
        margin-bottom: 5px;
      }

      .chip .highlight {
        float: left;
        text-align: center;
        background-color: lightgray;
        margin: 0 10px 0 -20px;
        height: 40px;
        width: 40px;
        font-size: var(--font-size-2xl);
        line-height: 40px;
        border-radius: 50%;
      }

      .closebtn,
      .editbtn {
        padding-left: 10px;
        color: #888;
        font-weight: bold;
        float: right;
        font-size: var(--font-size-2xl);
        cursor: pointer;
      }

      .closebtn:hover,
      .editbtn:hover {
        color: #000;
      }

      .isLink:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    </style>
    <div class="chip">
      <span class="highlight" *ngIf="!person?.picture">
        <i class="fa fa-user"></i>
      </span>
      <img class="highlight isLink" [src]="person?.picture?.thumbnail" *ngIf="person?.picture" (click)="imageClick()" />
      <span>
        {{ this.shortFullName() }}
      </span>
      <span *ngIf="canClose" class="closebtn" (click)="close()">
        <i class="fa fa-times"></i>
      </span>
    </div>
  `
})
export class PersonChipComponnent {
  @Input()
  public person: any;
  @Input()
  canClose = false;
  @Output()
  onCloseClick = new EventEmitter();
  @Output()
  onImageClick = new EventEmitter();

  close() {
    this.onCloseClick.emit(this.person);
  }

  imageClick() {
    this.onImageClick.emit(this.person);
  }

  shortFullName(): string {
    return this.person ? ''.concat(this.person.firstName, ' ', this.person.lastName.split(' ').pop()) : '';
  }
}
