import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@api/model/user';

export interface CliqueRetireResidence {
  id: string;
  externalId: string;
  name: string;
  buildingLevel1: {
    id?: string;
    name: string;
  };
}

export interface CliqueRetireUser {
  id: string;
  externalId: string;
  name: string;
  email: string;
  cellphone: string;
  pcd: boolean;
  buildingLevel2: CliqueRetireResidence;
}

export interface CliqueRetireGetResidentsReturnBody {
  cliqueRetireResidentsCount: number;
  cliqueRetireResidents: CliqueRetireUser[];
  condoResidentsCount: number;
  condoResidents: User[];
}

export type CliqueRetireCreateResidentDTO = Pick<CliqueRetireUser, 'id' | 'name' | 'email' | 'cellphone' | 'pcd'>;

interface ResponseMessage {
  message: string;
  smartLockerMessage: string;
}

export interface DeleteResidentResponse {
  user: User;
  wasCliqueRetireResidenceDeleted: boolean;
  deleteCliqueRetireResidenceErrorMessage: ResponseMessage;
  wasCliqueRetireBlockDeleted: boolean;
  deleteCliqueRetireBlockErrorMessage: ResponseMessage;
}

@Injectable({ providedIn: 'root' })
export class CliqueRetireService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }

  getResidents(condoId: string, smartLockerId: string): Observable<CliqueRetireGetResidentsReturnBody> {
    const options = {
      headers: new HttpHeaders(),
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/smart-locker/clique-retire/${smartLockerId}/residents`, options).pipe(
      map((res: any) => {
        const condoResidents = res.body.condoResidents.map(resident => new User(resident));

        return { ...res.body, condoResidents };
      })
    );
  }

  createResident(
    condoId: string,
    smartLockerId: string,
    residenceId: string,
    resident: CliqueRetireCreateResidentDTO
  ): Observable<CliqueRetireUser> {
    return this.http
      .post(`${this.endPoint}${condoId}/smart-locker/clique-retire/${smartLockerId}/residents/${residenceId}`, resident)
      .pipe(map((res: CliqueRetireUser) => res));
  }

  deleteResident(condoId: string, smartLockerId: string, cliqueRetireResidentId: string): Observable<DeleteResidentResponse> {
    return this.http
      .delete(`${this.endPoint}${condoId}/smart-locker/clique-retire/${smartLockerId}/residents/${cliqueRetireResidentId}`)
      .pipe(
        map((res: DeleteResidentResponse) => ({
          ...res,
          user: new User(res.user)
        }))
      );
  }
}
