import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../pipe/pipes.module';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface MultiSelectionItem {
  title: string;
  icon: string;
  cssClasses?: string;
  action: () => void;
}

@Component({
  selector: 'app-modal-multi-selection',
  templateUrl: 'modal-multi-selection.component.html',
  styleUrls: ['modal-multi-selection.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, PipesModule]
})
export class ModalMultiSelectionComponent {
  title: string;
  items: MultiSelectionItem[];

  constructor(public bsModalRef: BsModalRef) {}

  handleClick(item: MultiSelectionItem) {
    item.action();
    this.bsModalRef.hide();
  }
}
