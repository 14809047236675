import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { Condo } from '../../api/model/condo';
import { UtilService } from '../../services/util.service';
import { User } from '../../api/model/user';

@Component({
  selector: 'sale-page',
  templateUrl: 'sale.html'
})
export class SalePage {
  public featureWithNames: any;
  public features: Array<any>;

  public featureRequested: string;
  public condo: Condo;
  public user: User;

  constructor(private route: ActivatedRoute, private router: Router, private utilService: UtilService) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();
    this.featureRequested = this.route.snapshot.params['feature'];

    this.featureWithNames = {
      communication: {
        icon: 'fa-bullhorn',
        title: 'Comunicação',
        description:
          'Comunique-se com a administração e/ou com os ' +
          (this.condo?.customLabels?.resident?.plural || 'condôminos') +
          ' de maneira formal via ocorrência pública e privada',
        premium: false
      },
      reservations: {
        icon: 'fa-calendar',
        title: 'Reservas',
        description: 'Reserve de qualquer lugar, a qualquer hora',
        premium: false
      },
      documents: {
        icon: 'fa-file-text',
        title: 'Documentos online',
        description: `Armazene e compartilhe os principais documentos do(a) ${this.condo?.customLabels?.condo?.singular} de maneira com facilidade`,
        premium: false
      },
      voting: {
        icon: 'fa-check-square',
        title: 'Enquetes',
        description: `Crie enquetes e descubra de maneira fácil e eficiente a opinião geral do(a) ${this.condo?.customLabels?.condo?.singular}`,
        premium: true
      },
      finance: {
        icon: 'fa-usd',
        title: 'Financeiro',
        description: `Gerencie com eficiência e transparência as finanças do(a) ${this.condo?.customLabels?.condo?.singular} com geração de relatórios`,
        premium: true
      },
      classificados: {
        icon: 'fa-shopping-cart',
        title: 'Classificados',
        description: 'Publique e busque por anúncios de forma simples e rápida',
        premium: true
      },
      gate: {
        icon: 'fa-building-o',
        title: 'Portaria',
        description:
          'Comunique diretamente com ' +
          (this.condo?.customLabels?.resident?.plural || 'condôminos') +
          ' e portaria, libere visitantes de forma prática com o controle de acesso',
        premium: true
      },
      futureFeatures: {
        icon: 'fa-refresh',
        title: 'Futuras funcionalidades premium',
        description: 'Tenha acesso a todas funcionalidades premium que forem adicionadas futuramente',
        premium: true
      },
      support: {
        icon: 'fa-question-circle',
        title: 'Suporte técnico',
        description: 'Tire todas suas dúvidas com nossa equipe',
        premium: false
      }
    };

    let keys = Object.keys(this.featureWithNames);
    this.features = keys.map(f => this.featureWithNames[f]);
    if (keys.indexOf(this.featureRequested) == -1) this.featureRequested = null;
  }

  goToSubscriptionPage() {
    if (!(this.user.isAdmin() || this.user.isOwner())) return;

    this.router.navigate(['/subscription']);
  }
}
