import { Component } from '@angular/core';
import { UseTerms } from '@api/model/useTerms';
import { WhiteLabelService } from '@api/service/white-label.service';
import swal from 'sweetalert2';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AcceptedTermsService } from '@api/service/accepted-terms.service';
import { retry, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-use-terms',
  templateUrl: './modal-use-terms.component.html',
  styleUrls: ['./modal-use-terms.component.scss']
})
export class ModalUseTermsComponent {
  STATUS = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
  };

  status;

  onRejectTerms: () => void;
  onAcceptTerms: () => void;
  readOnly = false;
  defaultPageIndex = 0;

  useTerms: UseTerms;

  constructor(
    private whiteLabelService: WhiteLabelService,
    private acceptedTerms: AcceptedTermsService,
    private bsModalRef: BsModalRef,
    private toastrService: ToastrService
  ) {}

  onClose() {
    if (this.readOnly) {
      this.bsModalRef.hide();
    } else {
      swal({
        type: 'warning',
        title: 'Tem certeza que discorda dos termos de uso e da política de privacidade?',
        text: 'Para continuar utilizado a aplicação e necessário concordar com os termos de uso e política de privacidade',
        confirmButtonText: 'Sim',
        confirmButtonColor: '#F53D3D',
        showCancelButton: true,
        cancelButtonColor: '#32DB64',
        cancelButtonText: 'Não'
      })
        .then(() => {
          if (this.onRejectTerms) {
            this.onRejectTerms();
          }
          this.bsModalRef.hide();
        })
        .catch(c => console.log(c));
    }
  }

  handleAcceptTerms() {
    this.acceptedTerms
      .acceptUseTerms(this.useTerms._id)
      .pipe(timeout(10000), retry(3))
      .subscribe(
        () => {
          if (this.onAcceptTerms) {
            this.onAcceptTerms();
          }
          this.bsModalRef.hide();
        },
        () =>
          this.toastrService.error('Um error ocorreu ao tentar realizar a operação.' + ' Verifique a sua conexão e tente novamente', 'Erro')
      );
  }
}
