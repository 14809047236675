import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilService } from '../../../services/util.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@api/model/user';
import { Contact } from '@api/model/interface/contact';
import swal from 'sweetalert2';
import { UploadButton } from '../../../components/upload-button';
import { FileService } from '@api/service/file.service';
import { ContactID } from '@api/model/contact/contact.id';
import { filter, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { emailValidator, emptyOrValidCpfValidator, fullNameValidator, personBirthDateValidator } from '@api/util/validators';
import { Condo } from '@api/model/condo';
import { formatCpf, formatPhone } from '@api/util/formatters';
import { CondoContactServiceV3 } from '@api/serviceV3/condo.contact.service';
import { Subject } from 'rxjs';
import { CondoContact } from '@api/model/contact/condo.contact';
import { EcondosQuery } from '@api/model/query';
import { ModalSelectDuplicatedCondoContactComponent } from 'app/components/modal-select-duplicated-condo-contact/modal-select-duplicated-condo-contact.component';

@Component({
  selector: 'app-modal-create-user-contact',
  templateUrl: 'create-user-contact-modal.component.html',
  styleUrls: ['create-user-contact-modal.component.scss']
})
export class CreateUserContactModalComponent implements OnInit, OnDestroy {
  @ViewChild(UploadButton, { static: false }) userImageUploadButton: UploadButton;

  condo: Condo;
  callback: (condoContact: CondoContact) => void;

  public user: User;

  public contact: CondoContact;

  public form: UntypedFormGroup;

  public uploadContactImageCallback;
  public isUploadingContactImage = false;

  public isSubmitting: boolean;
  public isVerifyingContactByDoc = false;

  public contactTypes: { key: string; value: string }[] = [];

  availableFieldsFormData = {
    cpf: {
      validators: [Validators.minLength(14), Validators.maxLength(14), emptyOrValidCpfValidator],
      formatter: formatCpf
    },
    rg: {
      validators: [Validators.minLength(5)],
      formatter: (value: string) => value.replace(/\D/g, '')
    },
    phone: { validators: [Validators.minLength(14), Validators.maxLength(15)], formatter: formatPhone },
    email: { validators: [emailValidator], formatter: null },
    birthDate: { validators: [personBirthDateValidator], formatter: null }
  };

  unsubscribe$ = new Subject();
  isSearchingForContacts = false;

  constructor(
    private utilService: UtilService,
    private formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private fileService: FileService,
    private toastr: ToastrService,
    private condoContactService: CondoContactServiceV3,
    private modalService: BsModalService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.user.defaultCondo;

    this.uploadContactImageCallback = files => {
      this.uploadContactImage(files);
    };
  }

  ngOnInit(): void {
    const isGatekeeperOrAdmin = this.user.isGatekeeper() || this.user.isAdmin() || this.user.isOwner();
    const { availableVisitorTypes } = this.condo.generalParams.accessLiberation;
    if (isGatekeeperOrAdmin) {
      this.contactTypes = Object.keys(availableVisitorTypes).map(key => ({ key, value: CondoContact.TYPES_LABEL[key] }));
    } else {
      this.contactTypes = Object.keys(availableVisitorTypes)
        .filter(key => availableVisitorTypes[key])
        .map(key => ({ key, value: CondoContact.TYPES_LABEL[key] }));
    }

    this.setUpForm();
    this.initializeFormValues();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  setUpForm() {
    this.isSubmitting = false;

    this.form = this.formBuilder.group({
      name: [
        this.contact?.firstName && this.contact?.lastName ? this.contact.firstName + ' ' + this.contact.lastName : '',
        [Validators.required, Validators.minLength(3), fullNameValidator]
      ],
      type: [this.contact?.type || '', Validators.required]
    });

    const availableFields = Object.entries(this.condo.generalParams.accessLiberation?.availableFields);

    for (const availableField of availableFields) {
      const [fieldKey, field] = availableField;

      if (field.available) {
        let validators = field.required ? [Validators.required] : [];
        validators = validators.concat(this.availableFieldsFormData[fieldKey]?.validators || []);

        let formControl: UntypedFormControl;
        // Essa regra faz com que o change values do RG só seja disparado no blur do campo, como não sabemos o formato do RG, é melhor aguardar a pessoa digitar por completo
        if (fieldKey === 'rg') {
          formControl = new UntypedFormControl('', { validators, updateOn: 'blur' });
        } else {
          formControl = new UntypedFormControl('', validators);
        }

        const formatter = this.availableFieldsFormData[fieldKey]?.formatter;

        if (formatter) {
          formControl.valueChanges
            .pipe(
              takeUntil(this.unsubscribe$),
              tap(value => formControl.setValue(formatter(value), { emitEvent: false })),
              filter(value => (fieldKey === 'cpf' || fieldKey === 'rg') && formControl.valid && value.length),
              tap(() => (this.isSearchingForContacts = true)),
              switchMap(value => this.verifyCondoContactByDoc(fieldKey.toUpperCase() as keyof typeof ContactID.TYPES, value))
            )
            .subscribe(({ contacts }) => {
              this.isSearchingForContacts = false;
              if (contacts.length) {
                if (fieldKey === 'cpf') {
                  this.form.get('cpf').setValue('');
                }
                const initialState: Partial<ModalSelectDuplicatedCondoContactComponent> = {
                  docType: fieldKey.toUpperCase() as keyof typeof ContactID.TYPES,
                  docNumber: formControl.value,
                  contacts,
                  onContactSelected: selectedContact => {
                    this.bsModalRef.hide();

                    if (this.callback) {
                      this.callback(selectedContact);
                    }
                  }
                };

                this.modalService.show(ModalSelectDuplicatedCondoContactComponent, { initialState, ignoreBackdropClick: true });
              }

              this.isVerifyingContactByDoc = false;
            });
        }

        this.form.addControl(fieldKey, formControl);
      }
    }
  }

  getUnmaskedField(field: string) {
    return this.condoContactService.getCondoContactUnmaskedField(this.condo._id, this.contact._id, field).pipe(timeout(15_000)).toPromise();
  }

  async initializeFormValues(): Promise<void> {
    if (this.contact?._id) {
      const canUnmaskFields =
        this.user.isGatekeeperOnCondo(this.condo._id) ||
        this.user.isAdminOnCondo(this.condo._id) ||
        this.user.isOwnerOnCondo(this.condo._id);

      try {
        const { availableFields } = this.condo.generalParams.accessLiberation;

        if (availableFields.phone.available && this.contact.phones?.length) {
          if (canUnmaskFields) {
            const { data: phones } = await this.getUnmaskedField('phones');

            const phone = (phones || []).find(item => !!item);

            this.form.get('phone').setValue(phone ? this.utilService.formatPhone(phone) : '', { emitEvent: false });
          } else {
            const phone = (this.contact.phones || []).find(item => !!item);
            this.form.get('phone').setValue(phone || '', { emitEvent: false });
          }
        }

        if (availableFields.email.available && this.contact.emails?.length) {
          if (canUnmaskFields) {
            const { data: emails } = await this.getUnmaskedField('emails');

            const email = (emails || []).find(item => !!item);

            this.form.get('email').setValue(email || '', { emitEvent: false });
          } else {
            const email = (this.contact.emails || []).find(item => !!item);
            this.form.get('email').setValue(email || '', { emitEvent: false });
          }
        }

        if ((availableFields.cpf.available || availableFields.rg.available) && this.contact.ids?.length) {
          let { ids } = this.contact;

          if (canUnmaskFields) {
            const { data } = await this.getUnmaskedField('ids');
            ids = data;
          }

          if (availableFields.cpf.available) {
            const cpf = (ids || []).find(id => id.type === ContactID.TYPES.CPF);

            if (canUnmaskFields) {
              this.form.get('cpf').setValue(cpf && cpf.number ? formatCpf(cpf.number) : '', { emitEvent: false });
            } else {
              this.form.get('cpf').setValue(cpf && cpf.number ? cpf.number : '', { emitEvent: false });
            }
          }

          if (availableFields.rg.available) {
            const rg = (ids || []).find(id => id.type === ContactID.TYPES.RG);

            if (canUnmaskFields) {
              this.form.get('rg').setValue(rg && rg.number ? rg.number.replace(/\D/g, '') : '', { emitEvent: false });
            } else {
              this.form.get('rg').setValue(rg && rg.number ? rg.number : '', { emitEvent: false });
            }
          }
        }

        if (availableFields.birthDate.available && this.contact.birthDate) {
          let { birthDate } = this.contact;

          if (canUnmaskFields) {
            const { data } = await this.getUnmaskedField('birthDate');
            birthDate = data;
          }

          this.form.get('birthDate').setValue(birthDate || '', { emitEvent: false });
        }

        if (availableFields.picture.available) {
          this.form.get('picture').setValue(this.contact.picture, { emitEvent: false });
        }
      } catch (err) {
        console.log(err);
        this.toastr.error('Não foi possível desmascarar os dados do visitante.');
      }
    }
  }

  verifyCondoContactByDoc(docType: keyof typeof ContactID.TYPES, docNumber: string) {
    this.isVerifyingContactByDoc = true;

    const query: EcondosQuery = {
      $select: 'firstName lastName ids phones emails picture type birthDate',
      $populate: [
        { path: 'picture', select: 'url thumbnail type name format' },
        { path: 'ids.pictures', select: 'url thumbnail type name format' }
      ],
      $sort: 'firstName lastName'
    };

    return this.condoContactService.searchByDocument(this.condo._id, docType, docNumber, query);
  }

  onSubmit() {
    if (this.form.valid) {
      this.isSubmitting = true;
      const values = this.form.value;
      const names = values.name.split(' ');
      const firstName = names.shift();
      const lastName = names.join(' ');
      const availableFields = this.condo.generalParams.accessLiberation.availableFields;
      const ids: { type: string; number: string | number }[] = [];
      if (availableFields.rg.available && values.rg) {
        ids.push({ type: ContactID.TYPES.RG, number: values.rg });
      }
      if (availableFields.cpf.available && values.cpf) {
        ids.push({ type: ContactID.TYPES.CPF, number: values.cpf });
      }
      const data = {
        firstName,
        lastName,
        ids,
        type: values.type,
        ...(availableFields.picture.available && { picture: values.picture?._id || undefined }),
        ...(availableFields.phone.available && { phones: values.phone ? [values.phone] : [] }),
        ...(availableFields.email.available && { emails: values.email ? [values.email] : [] }),
        ...(availableFields.birthDate.available && { birthDate: values.birthDate || undefined })
      };

      if (this.contact && this.contact._id) {
        this.updateCondoContact(data);
      } else {
        this.createCondoContact(data);
      }
    } else {
      this.form.markAllAsTouched();
      this.toastr.warning('Preencha todos os campos');
    }
  }

  createCondoContact(data) {
    this.condoContactService
      .createCondoContact(this.condo._id, data)
      .pipe(timeout(10_000))
      .subscribe(
        (res: { _id: string }) => {
          this.isSubmitting = false;
          this.bsModalRef.hide();
          if (this.callback) {
            this.callback(
              new CondoContact({ ...data, _id: res._id, ...(this.form.value.picture && { picture: this.form.value.picture }) })
            );
          }
        },
        err => {
          console.log(err);
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: err?.originalError?.message || 'Não foi possível cadastrar esta pessoa.\nTente novamente.'
          });
        }
      );
  }

  updateCondoContact(data) {
    this.condoContactService
      .updateCondoContact(this.condo._id, this.contact._id, data)
      .pipe(timeout(10_000))
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.bsModalRef.hide();
          if (this.callback) {
            this.callback(
              new CondoContact({
                ...data,
                _id: this.contact._id,
                picture: this.form.get('picture').value
              })
            );
          }
        },
        err => {
          console.log(err);
          this.isSubmitting = false;
          swal({
            type: 'error',
            title: 'Erro ao salvar esta pessoa.',
            text: err?.originalError?.message || 'Não foi possível salvar esta pessoa.\nTente novamente.'
          });
        }
      );
  }

  pickContactImage() {
    this.userImageUploadButton.triggerClick();
  }

  uploadContactImage(files) {
    this.isUploadingContactImage = true;
    this.fileService.uploadFiles(files).subscribe(
      response => {
        this.form.get('picture').markAsTouched();
        const image = response[0];
        this.form.get('picture').setValue(image);
        this.isUploadingContactImage = false;
      },
      err => {
        console.log(err);
        this.isUploadingContactImage = false;
      }
    );
  }
}
