import { ResidenceService } from '@api/service/residence.service';

import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { map } from 'rxjs/operators';
import { User } from '@api/model/user';

@Injectable({ providedIn: 'root' })
export class ResidenceServiceV2 extends ResidenceService {
  constructor(protected http: HttpService, protected constantService: ConstantService) {
    super(http, constantService);
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;
  }

  getUsersFromResidence(condoId: string, residenceId: string, params: EcondosQuery = {}) {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/residences/${residenceId}/users`, options).pipe(
      map((response: any) => {
        if (params.$count) {
          return {
            count: response.body,
            users: []
          };
        } else {
          return {
            users: response.body.map(u => new User(u))
          };
        }
      })
    );
  }
}
