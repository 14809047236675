import { Component, Input, OnInit, ViewChild } from '@angular/core';

import swal from 'sweetalert2';
import { OccurrenceService } from '@api/service/occurrence.service';
import { OccurrenceBuilder } from '@api/model/occurrence/occurrence.builder';
import { Occurrence } from '@api/model/interface/occurrence';
import { UntypedFormBuilder } from '@angular/forms';
import { OccurrenceCreator } from '../occurrence.creator';
import { OccurrenceFileUploader } from '../occurrence.creator.file.list/files.scroll';
import { FileService } from '@api/service/file.service';

import * as moment from 'moment';
import { timeout } from 'rxjs/operators';
import { Group } from '@api/model/group';
import { Subject } from 'rxjs';
import { EcondosQuery } from '@api/model/query';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { GroupService } from '@api/serviceV2/group.service';

@Component({
  selector: 'advice-creator',
  inputs: ['user', 'condo'],
  outputs: ['onOccurrenceCreated', 'onOccurrenceUpdated'],
  templateUrl: 'advice.occurrence.creator.html'
})
export class AdviceCreatorComponent extends OccurrenceCreator implements OnInit {
  @ViewChild(OccurrenceFileUploader) occurrenceFileUploader: OccurrenceFileUploader;

  condoGroups: Array<Group> = [];
  tinymceInit: any;
  editor: any;

  unsubscribe$ = new Subject<void>();

  constructor(
    formBuilder: UntypedFormBuilder,
    private occurrenceService: OccurrenceService,
    private fileService: FileService,
    private groupService: GroupService
  ) {
    super(formBuilder);

    this.tinymceInit = {
      menubar: false,
      branding: false,
      statusbar: false,
      language: 'pt_BR',
      language_url: 'assets/tinymce/pt_BR.js',
      placeholder: 'O que você gostaria de reportar?',
      plugins: 'fullscreen template table hr link paste',
      paste_as_text: true,
      toolbar:
        'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor removeformat imgCustom videoCustom audioCustom fileCustom | link | fontsizeselect | fullscreen ',
      setup: editor => {
        this.editor = editor;
        editor.ui.registry.addButton('fileCustom', {
          text: `<i style='font-family:FontAwesome' class='fa fa-paperclip'></i>`,
          onAction: () => {
            this.uploadFile();
          }
        });
      }
    };
  }
  ngOnInit() {
    if (this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper() || this.user.isJanitor()) {
      this.getGroups();
    }
  }

  getGroups() {
    const query: EcondosQuery = {
      $select: 'name',
      $sort: 'name'
    };

    this.groupService
      .get(this.condo._id, query)
      .pipe(timeout({ each: 20000, with: () => ErrorBuilder.throwTimeoutError() }))
      .subscribe({
        next: ({ groups }) => {
          this.condoGroups = groups;
        },
        error: err => {
          console.log(err);
        }
      });
  }

  canSubmit() {
    if (!this.occurrenceForm.valid) {
      this.title.markAsTouched();
      this.description.markAsTouched();
      return false;
    }
    return true;
  }

  updateOccurrence() {
    if (!this.canSubmit()) {
      return;
    }

    if (!this.editingOccurrence) {
      return;
    }
    this.isSubmiting = true;
    const occurrence: any = {
      title: this.title.value,
      description: this.editor?.getContent({ format: 'text' }) || this.description.value,
      groups: this.groups.value === 'null' ? null : this.groups.value,
      descriptionHtml: this.editor?.getContent() || this.description.value,
      type: Occurrence.ADVICE_TYPE,
      pictures: this.attachments.map(att => att._id)
    };
    const occurrenceId = this.editingOccurrence.id;
    this.occurrenceService
      .updateOcurrence(this.condo._id, occurrenceId, occurrence)
      .pipe(timeout(10000))
      .subscribe({
        next: resp => {
          this.editingOccurrence.title = this.title.value;
          this.editingOccurrence.description = occurrence.description;
          this.editingOccurrence.groups = this.condoGroups.find(group => this.groups.value === group._id);
          this.editingOccurrence.descriptionHtml = occurrence.descriptionHtml;
          this.editingOccurrence.pictures = this.attachments;
          this.onOccurrenceUpdated.emit(this.editingOccurrence);
          this.isSubmiting = false;
          this.cancelEditing();
        },
        error: err => {
          console.log(err);
          this.isSubmiting = false;
          swal({
            text: 'Não foi possível editar o aviso, tente novamente'
          });
        }
      });
  }

  createOccurrence() {
    if (!this.canSubmit()) {
      return;
    }
    this.isSubmiting = true;
    const occurrence: any = {
      title: this.title.value,
      description: this.editor?.getContent({ format: 'text' }) || this.description.value,
      descriptionHtml: this.editor?.getContent() || this.description.value,
      groups: this.groups.value,
      type: Occurrence.ADVICE_TYPE,
      pictures: this.attachments.map(att => att._id)
    };

    this.occurrenceService
      .createOccurrence(this.condo._id, occurrence)
      .pipe(timeout(10000))
      .subscribe({
        next: (resp: any) => {
          occurrence._id = resp._id;
          occurrence.condo = this.condo;
          occurrence.createdBy = this.user;
          occurrence.createdAt = moment().utc().toDate();
          occurrence.pictures = this.attachments;
          occurrence.groups = this.condoGroups.find(group => this.groups.value === group._id);
          this.onOccurrenceCreated.emit(OccurrenceBuilder.build(occurrence));
          this.initializeForm();
          this.showing = false;
          this.isSubmiting = false;
        },
        error: err => {
          console.log(err);
          this.isSubmiting = false;
          swal({
            text: 'Não foi possível criar o aviso, tente novamente'
          });
        }
      });
  }

  uploadFile() {
    this.occurrenceFileUploader.pickFile();
  }

  uploadFileCallback(files) {
    this.isUploading = false;
    const file = files[0];
    const type = file.type.split('/');
    switch (type[0]) {
      case 'image':
        this.description.setValue(`${this.description.value}<a id='${file._id}' href='${file.url}' target='_blank'>
          <img src='${file.url}' width='150px' alt='${file.name}'/></a><br/>`);
        break;
      case 'video':
        this.description.setValue(`${this.description.value}
          <video id='${file._id}' width='320' height='240' controls>
            <source src='${file.url}' type='video/mp4'>
          </video><br/>`);
        break;
      case 'audio':
        this.description.setValue(`${this.description.value}
          <audio id='${file._id}' controls='controls'>
            <source src='${file.url}' type='audio/mp3'/>
            <source src='${file.url}' type='audio/wav'/>
          </audio><br/>`);
        break;
      default:
        this.description.setValue(`${this.description.value}<a id='${file._id}' href='${file.url}' target='_blank'>
          ${file.name}</a><br/>`);
        break;
    }
  }

  handlePaste($event) {
    const { editor, event } = $event;

    const description = editor.getContent();

    const dataText = event.clipboardData.getData('text').trim();
    const dataFiles = event.clipboardData.files;

    if (dataText.includes(';base64,')) {
      this.isUploading = true;
      this.fileService.uploadBase64(dataText).subscribe({
        next: res => {
          this.description.setValue(`${description}`);

          this.attachments.push(res[0]);
          this.uploadFileCallback(res);
        },
        error: e => {
          this.isUploading = false;
          console.log(e);
        }
      });
    }

    if (dataFiles.length) {
      this.isUploading = true;

      this.fileService.uploadFiles(dataFiles).subscribe({
        next: res => {
          this.description.setValue(`${description}`);

          this.attachments = [...this.attachments, ...res];
          this.uploadFileCallback(res);
        },
        error: e => {
          this.isUploading = false;
          console.log(e);
        }
      });
    }
  }

  removeFileFromEditor(file: { _id: string; type: string; url: string; thumbnail: string }): void {
    const content: Document = this.editor?.contentDocument;
    const contentBody: HTMLElement = content.body;
    const fileType = file.type.split('/')[0];
    const renderedFileChild = getRenderedFileChildFromParent(contentBody, fileType, file._id, file.url);
    const lineBreakChild = renderedFileChild?.nextSibling;
    renderedFileChild?.remove();
    lineBreakChild?.remove();
    checkEmptyParagraphs(contentBody);
    this.description.setValue(this.editor.getContent());
  }
}

const checkEmptyParagraphs = (parent: HTMLElement): void => {
  const paragraphChildren = parent.querySelectorAll('p');
  paragraphChildren.forEach(paragraph => {
    if (paragraph.children.length == 0 && paragraph.innerHTML == '') {
      paragraph.remove();
    }
  });
};

const getRenderedFileChildFromParent = (parent: HTMLElement, fileType: string, fileId: string, fileUrl: string): Element | null => {
  const childTypesKey = {
    image: 'a',
    video: 'video',
    audio: 'audio'
  };
  /*
   * Para refletir a mudança da remoção do arquivo do componente "file-picker" no editor é necessário buscar o elemento através do "querySelector"
   * A query é feita para procurar o elemento (<a>, <video> ou <audio>) no <p> com o atributo "id" ou "href"
   * Também é removido a quebra de linha adicionada junto com o arquivo usando a propriedade "nextSibling"
   * */
  const childType = childTypesKey[fileType] || 'a';
  let elementQuery = `${childType}[id="${fileId}"]`;
  let renderedFileChild: Element = parent.querySelector(`p > ${elementQuery}`);

  if (!renderedFileChild && ['video', 'audio'].includes(fileType)) {
    /*
     * Quando são arquivos do tipo vídeo ou áudio não é possível buscar pelo "href" direto da tag <audio> ou <video>
     * Por isso é feito a busca pelos elementos filhos das tags, o <source> que possui o atributo "href", se encontrar é retornado o elemento
     *  */
    elementQuery = childType;
    const sourceMediaParents = parent.querySelectorAll(`p > ${elementQuery}`);
    if (sourceMediaParents.length) {
      sourceMediaParents.forEach(sourceMediaParent => {
        const sourceMediaChild = sourceMediaParent.querySelector(`source[src="${fileUrl}"]`);
        if (sourceMediaChild) {
          renderedFileChild = sourceMediaParent;
        }
      });
    }
  }

  if (!renderedFileChild) {
    /*
     * Os antigos arquivos não usam o "id" como atributo, por isso é necessário tentar encontrar pelo "href" usando a URL do arquivo
     *  */
    elementQuery = `${childType}[href="${fileUrl}"]`;
    renderedFileChild = parent.querySelector(`p > ${elementQuery}`);
  }
  return renderedFileChild;
};
