import { Injectable } from '@angular/core';
import { Condo } from '@api/model/condo';
import { Capabilities } from '@econdos/econdos-base-sdk';
import { SdkService } from '@api/service/hardware/sdk.service';
import { AccessGroup } from '@api/model/hardware/access-group';

@Injectable({ providedIn: 'root' })
export class CapabilitiesService {
  constructor() {}

  getCondoCapabilities(condo: Condo): Capabilities {
    return SdkService.getCapabilities(condo.getEnabledHardwares());
  }

  getAccessGroupCapabilities(accessGroup: AccessGroup) {
    const hardwares = new Set<string>(accessGroup.actuators.map(a => a.hardware));
    return SdkService.getCapabilities(Array.from(hardwares));
  }
}
