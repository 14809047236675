import { Component, Input } from '@angular/core';

@Component({
  selector: '[disableForm]',
  styles: [
    `
      fieldset {
        display: contents;
        margin: unset;
        padding: unset;
        border: unset;
      }
    `
  ],
  template: `
    <fieldset [disabled]="disableForm">
      <ng-content></ng-content>
    </fieldset>
  `
})
/* https://medium.com/@rubenvermeulen/angular-disable-a-form-while-preserving-state-of-controls-4212d8faad0e */
export class DisableHtmlFormDirective {
  @Input() disableForm = false;

  constructor() {}
}
