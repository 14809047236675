import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Condo } from '../../../api/model/condo';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Occurrence } from '../../../api/model/interface/occurrence';
import { OccurrenceFileUploader } from '../../occurrence.creator/occurrence.creator.file.list/files.scroll';
import { File } from '@api/model/file';
import { Comment } from '@api/model/comment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ParamsService } from '@api/service/params.service';
import { Status } from '@api/model/status';
import { CommentService } from '@api/service/comment.service';

@Component({
  selector: 'app-modal-edit-comment',
  templateUrl: './modal-edit-comment.component.html',
  styleUrls: ['./modal-edit-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalEditCommentComponent implements OnInit {
  @ViewChild(OccurrenceFileUploader, { static: true }) occurrenceFileUploader: OccurrenceFileUploader;

  condo: Condo;
  occurrence: Occurrence;

  status: Status = new Status();

  comment: Comment;

  form: UntypedFormGroup;
  commentDescription: AbstractControl;
  commentFiles: Array<File>;

  enableCommentFiles = false;
  showAsMessages = false;

  isUploading = false;
  callbacks: { success?: (arg) => void; error?: (err) => {} };

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private paramsService: ParamsService,
    private commentService: CommentService,
    private toastrService: ToastrService
  ) {
    this.form = this.fb.group({
      commentDescription: ['', Validators.required]
    });
    this.commentDescription = this.form.get('commentDescription');
  }

  ngOnInit() {
    if (this.comment) {
      this.commentDescription.setValue(this.comment.description);
      this.commentFiles = this.comment.pictures;
    }
  }

  save() {
    if (this.form.valid) {
      const comment: any = {
        description: this.commentDescription.value,
        pictures: this.commentFiles || []
      };
      this.status.setAsProcessing();
      this.commentService.updateComment(this.condo._id, this.occurrence._id, this.comment._id, comment).subscribe(
        data => {
          let storedComment;
          if (data) {
            this.toastrService.success('Comentário atualizado com sucesso');
            storedComment = { ...this.comment, ...comment };
          }
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(storedComment);
          }
          this.status.setAsSuccess();
          this.bsModalRef.hide();
        },
        error => {
          this.status.setAsError();
          this.toastrService.error('Não foi possível editar este comentário, tente novamente', 'Ops...');
        }
      );
    } else {
      this.toastrService.warning('Preencha o comentário e tente novamente');
      for (const key of Object.keys(this.form)) {
        this.form.get(key).markAsTouched();
      }
    }
  }
}
