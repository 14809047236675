import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '../../api/model/condo';
import { Status } from '../../api/model/status';
import { EmergencyContact, Residence } from '../../api/model/interface/residence';
import { ResidenceService } from '../../api/service/residence.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { formatPhone } from '@api/util/formatters';

@Component({
  selector: 'modal-create-emergency-contact',
  templateUrl: 'modal-create-emergency-contact.html',
  styleUrls: ['./modal-create-emergency-contact.scss']
})
export class ModalCreateEmergencyContact implements OnInit {
  condo: Condo;
  residence: Residence;
  emergencyContacts: EmergencyContact[];
  index: number;
  status: Status = new Status();

  form: UntypedFormGroup;
  name: AbstractControl;
  phone: AbstractControl;
  parentage: AbstractControl;

  onCreate: Function = () => {};

  onUpdate: Function = () => {};

  private subscriptions: Subscription = new Subscription();

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      phone: ['', [Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])]],
      parentage: ['']
    });

    this.name = this.form.get('name');
    this.phone = this.form.get('phone');
    this.parentage = this.form.get('parentage');

    this.subscriptions.add(
      this.phone.valueChanges.pipe(filter(v => v)).subscribe(v => {
        const formatted = formatPhone(v);
        this.phone.setValue(formatted, { emitEvent: false });
      })
    );
  }

  ngOnInit() {
    if (this.index > -1) {
      const data = this.emergencyContacts[this.index];
      this.name.setValue(data.name);
      this.phone.setValue(data.phone);
      this.parentage.setValue(data?.parentage || '');
    }
  }

  submit() {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const emergencyContact: EmergencyContact = {
        name: this.name.value,
        phone: this.phone.value,
        parentage: this.parentage.value
      };

      if (this.index > -1) {
        this.update(emergencyContact);
      } else {
        this.create(emergencyContact);
      }
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha os campos obrigatórios.');
    }
  }

  create(emergencyContact: EmergencyContact) {
    this.emergencyContacts = [...this.emergencyContacts, emergencyContact];
    this.residenceService.updateEmergencyContact(this.condo._id, this.residence._id, this.emergencyContacts).subscribe(
      (res: any) => {
        this.onCreate(this.emergencyContacts);
        this.bsModalRef.hide();
        this.toastr.success('Contato cadastrado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível cadastrar o contato, tente novamente');
      }
    );
  }

  update(emergencyContact: EmergencyContact) {
    this.emergencyContacts.splice(this.index, 1, emergencyContact);
    this.residenceService.updateEmergencyContact(this.condo._id, this.residence._id, this.emergencyContacts).subscribe(
      (res: any) => {
        this.onUpdate(this.emergencyContacts);
        this.bsModalRef.hide();
        this.toastr.success('Contato atualizado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível atualizar o contato, tente novamente');
      }
    );
  }
}
