import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../../services/util.service';
import { CondoService } from '@api/service/condo.service';
import swal from 'sweetalert2';
import { UserService } from '@api/service/user.service';
import { AuthService } from '../../../services/auth.service';
import { FileService } from '@api/service/file.service';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../../services/http.service';
import { forkJoin, Observable } from 'rxjs';
import { Error } from '@api/model/error/error';
import { debounceTime, distinctUntilChanged, timeout, timeoutWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { ModalResidentPickerComponent } from '../../modal/modal-resident-picker/modal-resident-picker.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalCondoCustomLabelsComponent } from 'app/components/modal-condo-custom-labels/modal-condo-custom-labels.component';
import { capitalize } from '@api/util/util';
import { SessionService } from '@api/service/session.service';

@Component({
  templateUrl: 'condo.list.html',
  selector: 'app-condo-list'
})
export class CondoListComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  onDataChanged = new EventEmitter();
  public user: User;
  public defaultCondo;
  public userCondos;
  public selectedCondo: Condo;
  public isUploading: boolean;
  public isEditing: boolean;
  public uploadFileCallback;

  // Residents Picker vars
  public condoToPickResident;
  public userSelectedCallback: Function;
  public usersToHideOnPicker = [];

  // Form
  private form;
  private name: AbstractControl;
  private cnpj: AbstractControl;
  private address: AbstractControl;
  private number: AbstractControl;
  private extensionLine: AbstractControl;
  private city: AbstractControl;
  private zipCode: AbstractControl;
  private phone: AbstractControl;
  private state: AbstractControl;
  private type: AbstractControl;
  private picture: AbstractControl;
  private generalInfo: AbstractControl;
  private extensionLines: UntypedFormArray;

  private condoOwner: User = null;
  private condoAdmins: Array<User> = new Array<User>();
  private condoGatekeepers: Array<User> = new Array<User>();
  private condoJanitors: Array<User> = new Array<User>();

  private uf_list;
  private tipos;
  private tiposKeys;

  public getDataStatus;

  public ERROR = 1;
  public DOWNLOADING = 2;
  public SUCCESS = 3;

  public isAdminOrOwner;

  constructor(
    private utilService: UtilService,
    private sessionService: SessionService,
    private condoService: CondoService,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private authService: AuthService,
    private fileService: FileService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private httpService: HttpService
  ) {
    this.user = this.sessionService.userValue;
    this.condoToPickResident = this.sessionService.condoValue;
    this.tipos = Condo.condoTypes;
    this.tiposKeys = Object.keys(this.tipos);

    this.uploadFileCallback = files => {
      if (!files) {
        return;
      }

      this.isUploading = true;
      this.setEditing(true);
      this.fileService.uploadFiles(files).subscribe(
        resp => {
          this.isUploading = false;
          this.picture.setValue(resp[0]);
        },
        err => {
          this.isUploading = false;
          this.setEditing(false);
          swal({
            type: 'error',
            text: 'Ocorreu um problema ao enviar esta imagem, tente novamente.'
          });
        }
      );
    };
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.getDataStatus = this.DOWNLOADING;
    const condo = this.sessionService.condoValue;
    this.getCondoAndAdmins(condo)
      .pipe(timeout(15000))
      .subscribe(
        ([user, admins]) => {
          const defaultCondo = condo || user.defaultCondo;

          this.user = user;
          this.sessionService.setUser(user);

          this.selectedCondo = defaultCondo;
          this.setUpForm(this.selectedCondo);
          this.userCondos = [].concat(
            user.condosRequester,
            user.condosOwner,
            user.condosGatekeeper,
            user.condosAdmin,
            user.condosJanitor,
            user.condos
          );

          this.setCondoAdmins(admins, this.user);

          this.isAdminOrOwner = this.user.isAdminOnCondo(this.selectedCondo._id) || this.user.isOwnerOnCondo(this.selectedCondo._id);

          this.getDataStatus = this.SUCCESS;
        },
        err => {
          this.getDataStatus = this.ERROR;
        }
      );
  }

  setCondoAdmins(admins, user: User) {
    const condo = user.defaultCondo || { _id: '' };

    this.condoOwner = admins.find(admin => admin.condosOwner.find(condoOwner => condoOwner._id === condo._id)) || {};
    this.condoAdmins = admins.filter(admin => admin.condosAdmin.find(condoAdmin => condoAdmin._id === condo._id));
    this.condoGatekeepers = admins.filter(admin => admin.condosGatekeeper.find(condoGatekeeper => condoGatekeeper._id === condo._id));
    this.condoJanitors = admins.filter(admin => admin.condosJanitor.find(condoGatekeeper => condoGatekeeper._id === condo._id));
  }

  getCondoAndAdmins(condo: Condo): Observable<any> {
    let qs: any = [];
    qs.push(`$or[0][condosOwner]=${condo._id}`);
    qs.push(`$or[1][condosAdmin]=${condo._id}`);
    qs.push(`$or[2][condosGatekeeper]=${condo._id}`);
    qs.push(`$or[3][condosJanitor]=${condo._id}`);
    qs.push(`$populate=picture`);
    qs = `?${qs.join('&')}`;

    return forkJoin(this.userService.getMe(), this.condoService.getUsersFromCondo(condo._id, qs));
  }

  setUpForm(condo: Condo) {
    const extensionLines = condo.extensionLines.map(
      extensionLine => new UntypedFormGroup({ extension: new UntypedFormControl(extensionLine) })
    );

    const picture = condo && condo.pictures && condo.pictures.length ? condo.pictures[0] : null;
    this.form = this.formBuilder.group({
      name: [{ value: condo.name, disabled: true }, Validators.compose([Validators.required, Validators.minLength(4)])],
      cnpj: [
        {
          value: this.utilService.formatCnpjToShow(condo.cnpj),
          disabled: true
        },
        Validators.compose([Validators.minLength(18), Validators.maxLength(18)])
      ],
      zipCode: [
        {
          value: this.utilService.formatCep(condo.zipCode),
          disabled: true
        },
        Validators.compose([Validators.required])
      ],
      address: [{ value: condo.address, disabled: true }, Validators.compose([Validators.required])],
      number: [{ value: condo.number, disabled: true }, Validators.compose([Validators.required])],
      extensionLine: [{ value: condo.extensionLine, disabled: true }],
      city: [{ value: condo.city, disabled: true }, Validators.compose([Validators.required])],
      state: [{ value: condo.state, disabled: true }, Validators.compose([Validators.required])],
      type: [{ value: condo.type, disabled: true }, Validators.compose([Validators.required])],
      phone: [
        {
          value: this.utilService.formatPhoneToShow(condo.phone),
          disabled: true
        },
        Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])
      ],
      picture: [{ value: picture, disabled: true }],
      generalInfo: [condo.generalInfo || []],
      extensionLines: this.formBuilder.array(extensionLines)
    });
    this.uf_list = this.utilService.getUfList();

    this.name = this.form.get('name');
    this.cnpj = this.form.get('cnpj');
    this.zipCode = this.form.get('zipCode');
    this.address = this.form.get('address');
    this.number = this.form.get('number');
    this.extensionLine = this.form.get('extensionLine');
    this.city = this.form.get('city');
    this.state = this.form.get('state');
    this.type = this.form.get('type');
    this.phone = this.form.get('phone');
    this.picture = this.form.get('picture');
    this.generalInfo = this.form.get('generalInfo');
    this.extensionLines = this.form.get('extensionLines') as UntypedFormArray;

    this.cnpj.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(cnpj => {
      if (!cnpj) {
        return;
      }
      const val = cnpj.trim();
      this.cnpj.setValue(this.utilService.formatCnpj(cnpj), { emitEvent: false });
    });

    this.phone.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(phone => {
      if (!phone) {
        return;
      }
      const val = phone.trim();
      this.phone.setValue(this.utilService.formatPhone(phone), { emitEvent: false });
    });
  }

  public addExtensionLines(): void {
    const attendanceExtension = new UntypedFormGroup({
      extension: new UntypedFormControl('')
    });

    this.extensionLines.push(attendanceExtension);
  }

  public removeExtensionLines(index: number): void {
    this.extensionLines.removeAt(index);
  }

  selectType(key) {
    this.type.setValue(key);
  }

  setEditing(bool) {
    this.isEditing = bool;

    const keys = Object.keys(this.form.controls);

    if (bool) {
      for (const key of keys) {
        this.form.get(key).enable();
        this.form.get(key).markAsTouched();
      }
    } else {
      for (const key of keys) {
        this.form.get(key).disable();
      }

      this.setUpForm(this.selectedCondo);
    }
  }

  selectUF(uf) {
    this.state.setValue(uf);
  }

  updateCondo(condo: Condo) {
    const extensionLines = this.extensionLines.value.map(value => value.extension.trim());

    const condoData: any = {
      name: this.name.value,
      address: this.address.value,
      number: this.number.value,
      extensionLine: this.extensionLine.value,
      city: this.city.value,
      zipCode: this.zipCode.value,
      phones: [this.phone.value.replace(/[^0-9]/g, '')],
      state: this.state.value,
      type: this.type.value,
      generalInfo: this.generalInfo.value,
      extensionLines
    };

    if (this.cnpj.value) {
      condoData.cnpj = this.cnpj.value.replace(/[^0-9]/g, '');
    }

    if (this.picture.value) {
      condoData.pictures = [this.picture.value.id];
    }

    swal({
      type: 'question',
      title: `Atualizar ${condo?.customLabels?.condo?.singular}`,
      text: `Gostaria realmente de atualizar os dados do(a) ${condo?.customLabels?.condo?.singular}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.condoService
          .updateCondo(condo._id, condoData)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            return Promise.reject(`Não foi possível atualizar os dados do(a) ${condo?.customLabels?.condo?.singular}.`);
          });
      }
    }).then(
      () => {
        const condoToUpdate = this.sessionService.condoValue;

        condoToUpdate.name = condoData.name;
        condoToUpdate.cnpj = condoData.cnpj;
        condoToUpdate.address = condoData.address;
        condoToUpdate.number = condoData.number;
        condoToUpdate.extensionLine = condoData.extensionLine;
        condoToUpdate.city = condoData.city;
        condoToUpdate.zipCode = condoData.zipCode;
        condoToUpdate.phones = condoData.phones;
        condoToUpdate.state = condoData.state;
        condoToUpdate.type = condoData.type;
        condoToUpdate.generalInfo = condoData.generalInfo;
        condoToUpdate.extensionLines = extensionLines;
        if (this.picture.value) {
          condoToUpdate.pictures = [this.picture.value];
        }

        this.sessionService.setCondo(condoToUpdate);
        this.selectedCondo = this.sessionService.condoValue;
        this.setUpForm(this.selectedCondo);

        this.setEditing(false);
        this.toastr.success(`Dados do(a) ${condo?.customLabels?.condo?.singular} atualizados com sucesso`);
      },
      err => {
        console.log('Clicked cancel');
      }
    );
  }

  formatPhone() {
    let phoneNumber = this.phone.value;
    phoneNumber = this.utilService.formatPhone(phoneNumber);
    this.phone.setValue(phoneNumber);
  }

  formatCep() {
    const zipCode = this.zipCode.value;

    if (zipCode.toString().length > 0) {
      const formattedZipCode = this.utilService.formatCep(zipCode);
      this.zipCode.setValue(formattedZipCode);
    }
  }

  consultarCEP() {
    if (this.zipCode.value.length < 9) {
      return;
    }

    this.httpService.consultarCEP(this.zipCode.value.replace('-', '')).subscribe(
      data => {
        if (!('erro' in data)) {
          this.address.setValue(data.logradouro + ', ' + data.bairro);
          this.city.setValue(data.localidade);
          this.state.setValue(data.uf);
        } else {
          this.address.setValue('');
          this.city.setValue('');
          this.state.setValue('');
        }
      },
      err => {
        this.address.setValue('');
        this.city.setValue('');
        this.state.setValue('');
      }
    );
  }

  changeCondoOwner(condo) {
    this.usersToHideOnPicker = [].concat([this.condoOwner], this.condoAdmins, this.condoGatekeepers).map(user => user._id);
    this.condoToPickResident = condo;

    this.userSelectedCallback = resp => {
      const user = resp[0];
      const thisCondo = this.sessionService.condoValue;

      if (!user) {
        return;
      }

      this.condoService.changeCondoOwner(condo._id, user._id).subscribe(
        () => {
          this.onDataChanged.emit();
          // In case has changed condo owner of current condo
          if (thisCondo._id == condo._id) {
            this.userService
              .getMe()
              .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
              .subscribe(
                serverUser => {
                  this.user = serverUser;
                  this.getCondoAdmins(condo);
                  this.sessionService.setUser(serverUser);

                  swal({
                    type: 'success',
                    text: `Usuário ${serverUser.firstName} ${serverUser.lastName} definiu ${user.firstName} ${user.lastName} como ${
                      condo.customLabels.owner.singular || 'síndico'
                    }.`
                  });
                },
                err => {
                  this.authService.unregisterSession();
                  this.router.navigate(['/']);

                  swal({
                    title: 'Ops...',
                    text: `${
                      capitalize(condo.customLabels.owner.singular) || 'Síndico'
                    } alterado com sucesso, porém não foi possível atualizar seus dados locais.\nPor favor, entre novamente com seu usuário e senha.`
                  });
                }
              );
          } else {
            this.getCondoAdmins(condo);
          }
        },
        (err: Error) => {
          let msg;

          if (err.originalErrorMessage.includes('You must be a condo admin to execute this action')) {
            msg = 'Você não tem privilégio suficiente para alterar o ' + (condo.customLabels.owner.singular || 'síndico') + '.';
          } else {
            msg =
              err.message ||
              `Não foi possível definir o usuário ${user.firstName} ${user.lastName} como ${
                condo.customLabels.owner.singular || 'síndico'
              }.`;
          }

          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: msg
          });
        }
      );
    };

    this.showResidentPickerModal();
  }

  showResidentPickerModal() {
    const initialState: any = {
      condo: this.condoToPickResident,
      userIdsToHide: this.usersToHideOnPicker,
      callbacks: {
        success: selectedUser => {
          if (selectedUser.length) {
            this.userSelectedCallback(selectedUser);
          }
        }
      }
    };
    this.modalService.show(ModalResidentPickerComponent, { initialState, class: 'modal-md', ignoreBackdropClick: true });
  }

  addAdminToCondo(condo) {
    this.usersToHideOnPicker = [].concat([this.condoOwner], this.condoAdmins, this.condoGatekeepers).map(user => user._id);
    this.condoToPickResident = condo;

    this.userSelectedCallback = resp => {
      const user = resp[0];

      if (!user) {
        return;
      }

      this.condoService
        .addAdminToCondo(condo._id, user._id)
        .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
        .subscribe(
          () => {
            this.onDataChanged.emit();

            swal({
              type: 'success',
              text: `Usuário ${user.firstName} ${user.lastName} adicionado como ${
                condo.customLabels.admin.singular || 'administrador'
              } com sucesso.`
            });
            this.getCondoAdmins(condo);
          },
          (err: Error) => {
            let msg;

            if (err.originalErrorMessage.includes('You must be a condo admin to execute this action')) {
              msg = `Você não tem privilégio suficiente para adicionar um ${condo.customLabels.admin.singular || 'administrador'}.`;
            } else {
              msg =
                err.message ||
                `Não foi possível adicionar o usuário ${user.firstName} ${user.lastName} como ${
                  condo.customLabels.admin.singular || 'administrador'
                } deste(a) ${condo?.customLabels?.condo?.singular}.`;
            }

            swal({
              type: 'error',
              title: err.messageTitle || 'Ops...',
              text: msg
            });
          }
        );
    };

    this.showResidentPickerModal();
  }

  addGatekeeperToCondo(condo: Condo) {
    this.usersToHideOnPicker = [].concat([this.condoOwner], this.condoAdmins, this.condoGatekeepers).map(user => user._id);
    this.condoToPickResident = condo;

    this.userSelectedCallback = resp => {
      const user = resp[0];

      if (!user) {
        return;
      }

      this.condoService
        .addGatekeeperToCondo(condo._id, user._id)
        .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
        .subscribe(
          () => {
            this.onDataChanged.emit();

            swal({
              type: 'success',
              text: `Usuário ${user.firstName} ${user.lastName} adicionado como ${
                condo.customLabels.gatekeeper.singular || 'porteiro'
              } com sucesso.`
            });
            this.getCondoAdmins(condo);
          },
          (err: Error) => {
            let msg;

            if (err.originalErrorMessage.includes('You must be a condo admin to execute this action')) {
              msg = `Você não tem privilégio suficiente para adicionar um ${condo.customLabels.gatekeeper.singular || 'porteiro'}.`;
            } else {
              msg =
                err.message ||
                `Não foi possível adicionar o usuário ${user.firstName} ${user.lastName} como ${
                  condo.customLabels.gatekeeper.singular || 'porteiro'
                } deste(a) ${condo?.customLabels?.condo?.singular}.`;
            }
            swal({
              type: 'error',
              title: err.messageTitle || 'Ops...',
              text: msg
            });
          }
        );
    };

    this.showResidentPickerModal();
  }

  addJanitorToCondo(condo: Condo) {
    this.usersToHideOnPicker = [].concat([this.condoOwner]).map(user => user._id);
    // this.usersToHideOnPicker = [].concat([this.condoOwner], this.condoAdmins, this.condoGatekeepers);
    this.condoToPickResident = condo;

    this.userSelectedCallback = ([user]) => {
      if (!user) {
        return;
      }

      this.condoService
        .addJanitorToCondo(condo._id, user._id)
        .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
        .subscribe(
          () => {
            this.onDataChanged.emit();
            this.toastr.success(
              `Usuário ${user.firstName} ${user.lastName} foi adicionado como ${condo.customLabels.janitor.singular || 'zelador'}.`
            );
            this.getCondoAdmins(condo);
          },
          (err: Error) => {
            let msg;

            if (err.originalErrorMessage.includes('You must be a condo admin to execute this action')) {
              msg = `Você não tem privilégio suficiente para adicionar um ${condo.customLabels.janitor.singular || 'zelador'}.`;
            } else {
              msg =
                err.message ||
                `Não foi possível adicionar o usuário ${user.firstName} ${user.lastName} como ${
                  condo.customLabels.janitor.singular || 'zelador'
                } deste(a) ${condo?.customLabels?.condo?.singular || 'condomínio'}.`;
            }
            swal({
              type: 'error',
              title: err.messageTitle || 'Ops...',
              text: msg
            });
          }
        );
    };

    this.showResidentPickerModal();
  }

  getCondoAdmins(condo) {
    let qs: any = [];
    qs.push(`$or[0][condosOwner]=${condo._id}`);
    qs.push(`$or[1][condosAdmin]=${condo._id}`);
    qs.push(`$or[2][condosGatekeeper]=${condo._id}`);
    qs.push(`$or[3][condosJanitor]=${condo._id}`);
    qs.push(`$populate=picture`);
    qs = `?${qs.join('&')}`;

    this.getDataStatus = this.DOWNLOADING;

    this.condoService
      .getUsersFromCondo(condo._id, qs)
      .pipe(timeout(10000))
      .subscribe(
        admins => {
          this.setCondoAdmins(admins, this.user);
          this.getDataStatus = this.SUCCESS;
        },
        err => {
          this.getDataStatus = this.ERROR;
        }
      );
  }

  setAsUser(condo, user) {
    swal({
      type: 'question',
      title: `Definir como ${condo?.customLabels?.resident?.singular || 'condômino'}`,
      text: `Deseja realmente definir ${user.firstName} ${user.lastName} como ${condo?.customLabels?.resident?.singular || 'condômino'}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.condoService
          .addCondoUser(condo._id, user._id)
          .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject(`Não foi possível definir este usuário como ${condo?.customLabels?.resident?.singular || 'condômino'}.`);
          });
      }
    }).then(
      success => {
        this.onDataChanged.emit();

        // In case has changed condo owner of current condo
        if (user._id == this.user.id) {
          this.userService
            .getMe()
            .pipe(timeout(10000))
            .subscribe(
              user => {
                this.user = user;
                this.getCondoAdmins(condo);
                this.sessionService.setUser(user);

                swal({
                  type: 'success',
                  text: `Você foi definido como ${condo?.customLabels?.resident?.singular || 'condômino'}.`
                });
              },
              err => {
                this.authService.unregisterSession();
                this.router.navigate(['/']);

                swal({
                  title: 'Ops...',
                  text: `Seu perfil foi alterado com sucesso, porém não foi possível atualizar seus dados locais.\nPor favor, entre novamente com seu usuário e senha.`
                });
              }
            );
        } else {
          this.getCondoAdmins(condo);
          this.toastr.success(
            `Usuário ${user.firstName} ${user.lastName} definido ${condo?.customLabels?.resident?.singular || 'condômino'}.`
          );
        }
      },
      err => {}
    );
  }

  askToRemoveUser(condo, user) {
    if (user.isOwnerOnCondo(condo)) {
      return;
    }

    const msgs: any = {};

    if (user._id == this.user.id) {
      msgs.title = `Deixar ${condo?.customLabels?.condo?.singular}`;
      msgs.text = `Gostaria realmente de sair?\nVocê perderá todos os seus acessos e registros referente a este(a) ${condo?.customLabels?.condo?.singular}.`;
      msgs.reject = `Não foi possível deixar o(a) ${condo?.customLabels?.condo?.singular}.`;
      msgs.titleSuccess = `${capitalize(condo?.customLabels?.condo?.singular)} deixado(a) como sucesso.`;
      msgs.textSuccess = 'Você será redirecionado para a página de login.';
    } else {
      msgs.title = `Remover usuário ${user.firstName} ${user.lastName}`;
      msgs.text = `Deseja realmente removê-lo do(a) ${condo?.customLabels?.condo?.singular}?`;
      msgs.reject = 'Não foi possível remover o usuário.';
      msgs.titleSuccess = `Usuário removido do(a) ${condo?.customLabels?.condo?.singular} com sucesso!`;
    }

    swal({
      type: 'warning',
      title: msgs['title'],
      text: msgs['text'],
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.condoService
          .removeUserFromCondo(condo._id, user.id, true)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject(msgs['reject']);
          });
      }
    }).then(
      success => {
        this.onDataChanged.emit();

        if (user._id == this.user.id) {
          this.authService.unregisterSession();
          this.router.navigate(['/']);

          swal({
            type: 'success',
            title: msgs['titleSuccess'],
            text: msgs['textSuccess']
          });
        } else {
          this.getCondoAdmins(condo);

          swal({
            type: 'success',
            title: msgs['titleSuccess']
          });
        }
      },
      err => {
        console.log('Clicked cancel');
      }
    );
  }

  addGeneralInfo(generalInfoTitle, generalInfoContent) {
    if (generalInfoTitle.value && generalInfoContent.value) {
      this.generalInfo.value.push({ label: generalInfoTitle.value, content: generalInfoContent.value });
      generalInfoTitle.value = '';
      generalInfoContent.value = '';
      generalInfoTitle.focus();
    } else {
      this.toastr.warning('Preencha o título e a informação');
    }
  }

  openCondoLabelModal(selectedCondo) {
    const initialState = {
      condo: selectedCondo,
      callbacks: {
        success: labels => {
          this.selectedCondo.customLabels = labels;
          this.sessionService.setCondo(this.selectedCondo);
          location.reload();
        }
      }
    };
    this.modalService.show(ModalCondoCustomLabelsComponent, { initialState, class: 'modal-lg' });
  }
}
