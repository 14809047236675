import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalCommentComponent } from '../../modal/comment.modal';
import { Residence } from '@api/model/interface/residence';
import { Occurrence } from '@api/model/interface/occurrence';
import { UntypedFormControl } from '@angular/forms';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { Access } from '@api/model/access';
import * as moment from 'moment';
import { CondoContact } from '@api/model/contact/condo.contact';
import { map, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { Status } from '@api/model/status';
import { ParamsService } from '@api/service/params.service';
import { Router } from '@angular/router';
import { AccessInfo } from '../access.control/access.control';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalGatePermissionsConfigComponent } from '../modal-gate-permissions-config/modal-gate-permissions-config.component';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { EcondosQuery } from '@api/model/query';
import { OccurrenceServiceV2 } from '@api/serviceV2/occurrence.service';
import { Contact } from '@api/model/interface/contact';
import { ModalCreateAuthorizedPersonComponent } from 'app/components/modal-create-authorized-person/modal-create-authorized-person.component';
import { Condo } from '@api/model/condo';
import { TableColumnDefinition, TableComponent, TableStatus } from 'app/components/table/table.component';
import { ConstantService } from 'app/services/constant.service';
import { WEEK_DAYS_LONG } from '@api/util/constants';
import { capitalize } from '@api/util/util';
import { ModalShareLinkComponent } from '../../../components/modal-share-link/modal-share-link';
import { VisitorSelfLiberationService } from '@api/serviceV3/visitor-self-liberation-service';
import { createLinkToShareOccurrence } from '@api/utils';
import { ThemeService } from '../../../theme';
import { AccessService } from '@api/service/access.service';
import { SessionService } from '@api/service/session.service';
import { ResidenceAutoCompleteComponent } from '../../../components/residence-auto-complete/residence-auto-complete.component';
import { VisitorPickerComponent } from '../../../components/visitor-picker/visitor-picker';
import { of, Subject } from 'rxjs';
import { ModalNewCondoContactComponent } from '../access.control/new.condo.contact/new.condo.contact.modal';

@Component({
  templateUrl: './gate.permissions.list.html',
  selector: 'gate-permissions-list',
  styleUrls: ['./gate.permissions.list.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GatePermissionsListPage implements OnInit {
  @ViewChild(ModalCommentComponent, { static: true }) commentModal: ModalCommentComponent;
  @ViewChild('authorizedVisitorsTable', { static: true }) authorizedVisitorsTable: TableComponent;
  @ViewChild('vehiclePlate', { static: true }) vehiclePlate: TemplateRef<any>;
  @ViewChild('residencePicker', { static: true }) residencePicker: ResidenceAutoCompleteComponent;
  @ViewChild('contactPicker', { static: true }) contactPicker: VisitorPickerComponent;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<GateOccurrence>[] = [];

  public residence: Residence;
  public condo: Condo;
  public user;

  public detailStatus: Status = new Status();

  public selectedOccurrences = [];
  public selectedOccurrence;

  WEEK_DAYS = WEEK_DAYS_LONG;
  public occurrences: Occurrence[] = [];

  public TYPES_LABEL = Contact.TYPES_LABEL;

  TODAY_WEEK_DAY;

  isAdmin = false;
  isEnabledRoute: boolean;

  canCancel = false;
  canEdit = false;

  itemsPerPage = 15;
  p = 1;
  totalData = 0;

  startDate: Date = new Date();
  endDate: Date = new Date();
  range: Date[] = [this.startDate, this.endDate];

  activeFilters = false;

  searchCondoContact: UntypedFormControl = new UntypedFormControl(null);
  searchResidence: UntypedFormControl = new UntypedFormControl(null);

  canVisitorSelfRegistrate = false;
  isAllowedToShareVisitorPermissionLink = false;

  constructor(
    private occurrenceService: OccurrenceServiceV2,
    private paramsService: ParamsService,
    private router: Router,
    private modalService: BsModalService,
    public constantService: ConstantService,
    private toastr: ToastrService,
    private themeService: ThemeService,
    private visitorService: VisitorSelfLiberationService,
    private accessService: AccessService,
    private sessionService: SessionService
  ) {
    this.user = this.sessionService.userValue;
    this.condo = this.sessionService.condoValue;
    this.isAdmin = this.user.isAdminOnCondo(this.condo._id) || this.user.isOwnerOnCondo(this.condo._id);

    this.canVisitorSelfRegistrate =
      this.condo?.generalParams?.accessLiberation?.gateKeeperCanShareVisitorPermissionLink &&
      this.condo.generalParams.accessLiberation.visitorSelfRegistration;

    this.isAllowedToShareVisitorPermissionLink =
      this.condo?.generalParams?.accessLiberation?.gateKeeperCanShareVisitorPermissionLink &&
      this.condo?.hardwareParams?.visitorsFacialFromUser === 'DISABLED' &&
      (this.condo?.hardwareParams?.visitorsVirtualKeyFromUser === 'ENABLED' ||
        this.condo?.hardwareParams?.visitorsQRCodeFromUser === 'ENABLED' ||
        this.condo?.hardwareParams?.visitorPrintedQrCode);
  }

  getData({ page = 0 } = {}) {
    this.tableConstructor();
    const { pageSize, sortedColumn, sortOrder } = this.authorizedVisitorsTable.getCurrentState();

    const weekDays = {
      0: 'SUNDAY',
      1: 'MONDAY',
      2: 'TUESDAY',
      3: 'WEDNESDAY',
      4: 'THURSDAY',
      5: 'FRIDAY',
      6: 'SATURDAY'
    };
    const date = moment(this.startDate);
    this.TODAY_WEEK_DAY = weekDays[date.day()];

    if (page === 0) {
      this.p = 1;
    }

    const query: EcondosQuery = {
      $select: 'residence status startDate endDate condoContacts type accessGroups visitorIdentification description',
      $populate: [
        { path: 'residence', select: 'identification', options: { withDeleted: false } },
        {
          path: 'condoContacts',
          select: 'firstName lastName type condoVehicles',
          populate: [
            {
              path: 'condoVehicles',
              select: 'plate model type brand color pictures',
              populate: [
                {
                  path: 'pictures',
                  select: 'url thumbnail type name format'
                }
              ]
            }
          ]
        },
        { path: 'accessGroups', select: 'name' }
      ],
      $page: page,
      $limit: pageSize,
      type: 'GATE',
      subType: GateOccurrence.ALLOW_ACCESS_TYPE,
      'daysAllowed.day': this.TODAY_WEEK_DAY,
      status: Occurrence.STATUS.OPENED
    };

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    const startDate = moment(this.startDate).startOf('day').toISOString();
    const endDate = moment(this.endDate).endOf('day').toISOString();

    query.$or = [
      { startDate: { $lte: endDate, $gte: startDate } },
      { endDate: { $lte: endDate, $gte: startDate } },
      { startDate: { $lte: startDate }, endDate: { $gte: endDate } }
    ];

    if (this.searchCondoContact.value) {
      query.condoContacts = this.searchCondoContact.value;
    }

    if (this.searchResidence.value) {
      query.residence = this.searchResidence.value;
    }

    const today = moment().startOf('day');
    const filterDateActive = !(moment(this.startDate).isSame(today, 'day') && moment(this.endDate).isSame(today, 'day'));

    if (filterDateActive || this.searchCondoContact.value || this.searchResidence.value) {
      this.activeFilters = true;
    } else {
      this.activeFilters = false;
    }

    this.status = 'LOADING';

    this.occurrenceService
      .get(this.condo._id, query)
      .pipe(
        timeout(15_000),
        map(({ count, occurrences }) => {
          const reducedOccurrences = occurrences.reduce((acc, curr) => {
            const occurrence = curr as GateOccurrence;

            if (occurrence.condoContacts && occurrence.condoContacts?.length > 1) {
              let contacts;

              if (this.searchCondoContact.value) {
                contacts = occurrence.condoContacts.filter(contact => {
                  return contact._id === this.searchCondoContact.value;
                });
              } else {
                contacts = occurrence.condoContacts;
              }

              const elements = contacts.map(contact => {
                const clone = Object.assign(Object.create(Object.getPrototypeOf(occurrence)), occurrence);
                clone.condoContacts = [contact];
                return clone;
              });
              acc.push(...elements);
            } else if (occurrence.condoContacts && occurrence.condoContacts?.length === 1) {
              acc.push(occurrence);
            } else if (['DELIVERYMAN', 'DRIVER'].includes(occurrence.visitorIdentification)) {
              acc.push(occurrence);
            }
            return acc;
          }, []);

          return { count, occurrences: reducedOccurrences };
        })
      )
      .subscribe(
        ({ count, occurrences }) => {
          occurrences.sort(this.sortPermissions);

          this.occurrences = occurrences;
          this.status = 'SUCCESS';

          this.totalData = count;
        },
        err => {
          this.status = 'ERROR';
        }
      );
  }

  sortPermissions(p1: GateOccurrence, p2: GateOccurrence) {
    const status1 = p1.status;
    const status2 = p2.status;
    const name1 = p1.condoContacts && p1.condoContacts?.length && p1.condoContacts[0].fullName;
    const name2 = p2.condoContacts && p2.condoContacts?.length && p2.condoContacts[0].fullName;
    if (status2 < status1) {
      return -1;
    } else if (status2 > status1) {
      return 1;
    } else if (name1 < name2) {
      return -1;
    } else if (name1 > name2) {
      return 1;
    } else {
      return 0;
    }
  }

  getOccurrenceDetails(occurrence: GateOccurrence) {
    this.detailStatus.setAsDownloading();

    const query: EcondosQuery = {
      $select:
        'condo residence viewers protocolYear title accessGroups protocolMonth protocolSeq description condoContacts shouldCall status type subType startDate endDate daysAllowed pictures createdBy createdAt comments commentsTotal accessGroups updatedAt updatedBy visitorIdentification',
      $populate: [
        'viewers.residences',
        { path: 'residence', select: 'identification', options: { withDeleted: false } },
        {
          path: 'pictures',
          select: 'url thumbnail type name format'
        },
        {
          path: 'createdBy',
          select: 'firstName lastName picture',
          populate: [
            {
              path: 'picture',
              select: 'url thumbnail type name format'
            }
          ]
        },
        {
          path: 'updatedBy',
          select: 'firstName lastName picture',
          populate: [
            {
              path: 'picture',
              select: 'url thumbnail type name format'
            }
          ]
        },
        '_commentsTotal',
        {
          path: 'userContacts',
          select: 'firstName lastName picture',
          populate: [
            {
              path: 'picture',
              select: 'url thumbnail type name format'
            }
          ]
        },
        {
          path: 'condoContacts',
          select:
            'firstName lastName condoVehicles picture ids phones type emails condo company service banned banReason bannedAt bannedBy externalId',
          populate: [
            {
              path: 'picture',
              select: 'url thumbnail type name format'
            },
            {
              path: 'condoVehicles',
              select: 'plate model type brand color'
            },
            {
              path: 'ids.pictures',
              select: 'url thumbnail type format name'
            },
            {
              path: 'bannedBy',
              select: 'firstName lastName'
            }
          ]
        },
        { path: 'accessGroups', select: 'name' }
      ],
      _id: occurrence._id
    };

    this.occurrenceService
      .getById(this.condo._id, occurrence._id, query)
      .pipe(
        timeout(10_000),
        tap(response => {
          const occurrence = response as GateOccurrence;
          if (occurrence.condoContacts && occurrence.condoContacts?.length > 1) {
            const [contactToFilter] = this.selectedOccurrence.condoContacts;
            const otherContactsRemoved = occurrence.condoContacts.filter(contact => contact._id === contactToFilter._id);
            occurrence.condoContacts = otherContactsRemoved;
          }
          return occurrence;
        }),
        switchMap((response: GateOccurrence) => {
          this.selectedOccurrence = response;
          this.canCancel =
            !(this.selectedOccurrence.status === 'CANCELED' || this.selectedOccurrence.status === 'CLOSED') &&
            (this.selectedOccurrence?.createdBy?._id === this.user._id || this.isAdmin);
          this.canEdit = response.canEdit(this.user, this.condo);

          if (this.selectedOccurrence.visitorIdentification) {
            return of([]);
          }

          const visitorQuery: EcondosQuery = {
            $select: 'condoContact approvedBy approvedAt',
            $populate: [
              {
                path: 'approvedBy',
                select: 'firstName lastName picture',
                populate: [{ path: 'picture', select: 'url thumbnail type ' }]
              }
            ],
            occurrence: response._id,
            condoContact: response.condoContacts[0]?._id
          };
          return this.visitorService.getVisitorRequest(this.condo._id, visitorQuery);
        })
      )
      .subscribe(
        response => {
          if (response.length === 1) {
            this.selectedOccurrence.createdBy = response[0]?.approvedBy;
            this.selectedOccurrence.createdAt = response[0]?.approvedAt;
          }

          this.detailStatus.setAsSuccess();
        },
        error => {
          this.detailStatus.setAsError();
        }
      );
  }

  ngOnInit() {
    this.getData();
  }

  tableConstructor() {
    const { accessGroups } = this.condo.generalParams?.accessLiberation?.availableFields || {};
    this.isEnabledRoute = accessGroups?.available;

    this.tableColumns = [
      {
        headerLabel: 'Pessoa',
        valueFn: occurrence => {
          if (['DELIVERYMAN', 'DRIVER'].includes(occurrence.visitorIdentification)) {
            return this.TYPES_LABEL[occurrence.visitorIdentification];
          }

          return `${occurrence.condoContacts[0]?.firstName} ${occurrence.condoContacts[0]?.lastName}`;
        }
      },
      {
        headerLabel: `Tipo do ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
        valueFn: occurrence => {
          if (['DELIVERYMAN', 'DRIVER'].includes(occurrence.visitorIdentification)) {
            return occurrence.description || 'Não informado';
          }

          return this.TYPES_LABEL[occurrence.condoContacts[0]?.type];
        }
      },
      {
        headerLabel: 'Rota',
        show: this.isEnabledRoute,
        valueFn: occurrence => `${occurrence.accessGroups?.map(groups => groups.name).join(', ') || 'Nenhuma'}`
      },
      {
        headerLabel: 'Veículo',
        valueTemplate: this.vehiclePlate,
        show: this.condo.hardwareParams.visitorsPlateAddedByResident
      },
      {
        headerLabel: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        valueFn: occurrence => occurrence.residence?.identification || 'Nenhuma'
      },
      {
        headerLabel: 'Período',
        valueFn: occurrence => `${moment(occurrence.startDate).format('DD/MM/YY')} - ${moment(occurrence.endDate).format('DD/MM/YY')}`
      }
    ];
  }

  onDateChange(dates) {
    if (dates && Array.isArray(dates)) {
      this.startDate = dates[0];
      this.endDate = dates[1];
      this.getData();
    }
  }

  clearFilters() {
    this.activeFilters = false;

    if (this.searchResidence.value !== null) {
      this.residencePicker.resetValue();
      this.searchResidence.setValue(null);
    }

    if (this.searchCondoContact.value !== null) {
      this.contactPicker.resetValue();
      this.searchCondoContact.setValue(null);
    }

    this.startDate = moment().startOf('day').toDate();
    this.endDate = moment().endOf('day').toDate();
    this.range = [this.startDate, this.endDate];
  }

  onCondoContactSelect(condoContact) {
    this.searchCondoContact.setValue(condoContact?._id || null);
    this.getData();
  }

  onClearSearchCondoContact() {
    this.searchCondoContact.setValue(null);
    this.getData();
  }

  onResidenceSelect(residence) {
    this.searchResidence.setValue(residence?._id || null);
    this.getData();
  }

  onSelect([selectedGateOccurrence]: GateOccurrence[]) {
    const isLiberationWithVisitorIdentification = selectedGateOccurrence?.visitorIdentification;
    let selected: GateOccurrence;
    if (isLiberationWithVisitorIdentification) {
      selected = this.occurrences.find((occurrence: GateOccurrence) => occurrence?._id === selectedGateOccurrence?._id) as GateOccurrence;
    } else {
      selected = this.occurrences.find(
        (occurrence: GateOccurrence) =>
          occurrence?._id === selectedGateOccurrence?._id &&
          occurrence?.condoContacts[0]?._id === selectedGateOccurrence?.condoContacts[0]?._id
      ) as GateOccurrence;
    }

    this.selectedOccurrences.splice(0, this.selectedOccurrences?.length);
    this.selectedOccurrences.push(selected);
    this.selectedOccurrence = this.selectedOccurrences[0];
    this.getOccurrenceDetails(selected);
  }

  showCommentsModal(occurrence) {
    this.commentModal.showModal(occurrence);
  }

  async createAccessFromOccurrence(occurrence: GateOccurrence, condoContact: CondoContact) {
    const access = new Access();
    access.condoContact = condoContact;
    access.source = Access.SOURCES.OCCURRENCE;
    access.status = Access.STATUS.PENDING;
    access.approvedBy = occurrence.createdBy;
    access.residence = occurrence.residence;
    access.occurrence = occurrence;

    const canCreateCondoContact = occurrence.visitorIdentification && !condoContact;
    if (canCreateCondoContact) {
      const newContact = new Promise<CondoContact>((resolve, reject) => {
        const unsubscribe$: Subject<boolean> = new Subject<boolean>();
        const initialState: Partial<ModalNewCondoContactComponent> = {
          condo: this.condo,
          user: this.user,
          newContactInfo: { type: occurrence.visitorIdentification, service: occurrence.description },
          callback: (contact: CondoContact) => {
            unsubscribe$.next(true);
            unsubscribe$.complete();
            resolve(contact);
          }
        };
        const modal = this.modalService.show(ModalNewCondoContactComponent, {
          initialState,
          class: 'modal-xl',
          ignoreBackdropClick: true
        });
        modal.onHide.pipe(takeUntil(unsubscribe$)).subscribe(() => {
          resolve(null);
          unsubscribe$.next(true);
          unsubscribe$.complete();
        });
      });
      access.condoContact = await newContact;
    }

    if (!access.condoContact) {
      this.toastr.error(
        `Nenhum ${this.condo.customLabels?.visitor?.singular} foi encontrado para registrar o acesso. Por favor, cadastre um ${this.condo.customLabels?.visitor?.singular}.`
      );
      return;
    }

    access.type = access.condoContact.type;
    const accessInfo: AccessInfo = { access };
    this.paramsService.add('NEW_ACCESS', accessInfo);
    return this.router.navigate(['gate', 'access']);
  }

  canCreateNewAccess(occurrence: GateOccurrence) {
    const today = moment();
    const isOnRange: boolean = today.isBetween(occurrence.startDate, occurrence.endDate);
    return !occurrence.isCanceled() && !occurrence.isClosed() && isOnRange;
  }

  openPermissionConfig() {
    const initialState = {
      condo: this.condo
    };
    this.modalService.show(ModalGatePermissionsConfigComponent, {
      initialState,
      class: 'modal-lg modal-xl',
      ignoreBackdropClick: true
    });
  }

  cancelAccessLiberation(occurrence: GateOccurrence) {
    const condoContacts = this.occurrences
      .filter((occurr: GateOccurrence) => occurr._id === occurrence._id && !!occurr.condoContacts?.length)
      .map((occurr: GateOccurrence) => occurr.condoContacts[0])
      .map(contact => contact.fullName)
      .join(', ');
    let text = `Você realmente deseja cancelar esta liberação de acesso dos ${
      this.condo.customLabels?.visitor?.plural || 'visitantes'
    }: ${condoContacts}`;

    if (occurrence.visitorIdentification) {
      text = `Você realmente deseja cancelar esta liberação de acesso do ${Contact.TYPES_LABEL[occurrence.visitorIdentification]}?`;
    }

    swal({
      title: `Cancelar liberação de acesso`,
      text,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    })
      .then(() => {
        this.occurrenceService.updateOcurrence(this.condo._id, occurrence._id, { status: 'CANCELED' }).subscribe(
          () => {
            this.canCancel = !(this.selectedOccurrence.status === 'CANCELED' || this.selectedOccurrence.status === 'CLOSED');
            this.selectedOccurrence = null;
            this.getData();
          },
          err => {
            this.toastr.error('Não foi possível cancelar a liberação. Tente novamente!', 'Liberação de acesso');
          }
        );
      })
      .catch(e => swal.noop());
  }

  createAuthorizedPeople() {
    const initialState = {
      condo: this.condo,
      callback: liberation => {
        this.getData();
        if (!liberation?.condoContacts?.length) {
          const link = createLinkToShareOccurrence(this.condo._id, liberation._id, this.themeService.activeTheme);
          this.modalService.show(ModalShareLinkComponent, {
            initialState: { link },
            ignoreBackdropClick: true,
            class: 'modal-lg'
          });
        }
      }
    };
    this.modalService.show(ModalCreateAuthorizedPersonComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }
  editLiberationModal(occurrence: GateOccurrence) {
    const initialState = {
      condo: this.condo,
      occurrence,
      callback: () => {
        this.selectedOccurrence = occurrence;
      }
    };
    this.modalService.show(ModalCreateAuthorizedPersonComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  shareViaWhatsapp(contact: CondoContact) {
    let type;
    if (this.condo.hardwareParams.visitorsQRCodeFromUser === 'ENABLED' || this.condo.hardwareParams.visitorPrintedQrCode) {
      type = 'QR';
    } else if (this.condo.hardwareParams.visitorsVirtualKeyFromUser === 'ENABLED') {
      type = 'VIRTUAL_KEY';
    } else if (this.condo.hardwareParams.visitorsFacialFromUser === 'ENABLED') {
      type = 'FACIAL';
    }

    if (!type) {
      this.toastr.error('Não foi possível compartilhar o QRCode, tente novamente');
      return;
    }

    const data = {
      type,
      condoContactId: contact._id,
      permissionId: this.selectedOccurrence._id
    };

    this.accessService
      .generateVisitorDeviceFromUser(this.condo._id, data)
      .pipe(timeout(10000))
      .subscribe({
        next: async (res: { serial: string }) => {
          const visitorUrl = this.themeService.activeTheme?.qrCodePageUrl || 'https://visitante.econdos.com.br';
          const url = `${visitorUrl}?serial=${res.serial}`;
          const whatsappBreakLineChar = '%0a';
          const startDateAccessKey = moment(this.selectedOccurrence.startDate).format('DD/MM/YYYY');
          const endDateAccessKey = moment(this.selectedOccurrence.endDate).format('DD/MM/YYYY');

          const days = this.selectedOccurrence.daysAllowed.reduce((acc, d) => {
            acc += `${WEEK_DAYS_LONG[d.day]}: ${d.startTime}-${d.endTime}${whatsappBreakLineChar}`;
            return acc;
          }, '');

          let message = `Olá ${contact.firstName}, você recebeu esta chave de acesso de ${this.user.firstName} para o(a) ${this.condo?.customLabels?.condo?.singular} ${this.condo.name}.`;
          message += whatsappBreakLineChar + whatsappBreakLineChar;
          message += `Esta chave é VÁLIDA ${
            startDateAccessKey != endDateAccessKey
              ? 'entre os dias ' + startDateAccessKey + ' e ' + endDateAccessKey
              : 'no dia ' + startDateAccessKey
          }.`;
          message += whatsappBreakLineChar + whatsappBreakLineChar;
          message += days;
          message += whatsappBreakLineChar;
          message += url;

          const shareUrl = `https://wa.me/?text=${message}`;

          try {
            window.open(shareUrl);
          } catch (e) {
            this.toastr.error('Não foi possível compartilhar, tente novamente');
          }
        },
        error: err => {
          const errorMessage = err?.originalError?.message || 'Não foi possível gerar a chave de acesso, tente novamente';
          this.toastr.error(errorMessage);
        }
      });
  }

  shareLink() {
    const link = createLinkToShareOccurrence(this.condo._id, this.selectedOccurrence._id, this.themeService.activeTheme);
    const initialState = { link };
    this.modalService.show(ModalShareLinkComponent, { initialState, ignoreBackdropClick: true, class: 'modal-lg' });
  }
}
