import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '@api/model/condo';
import { PrintContentService } from '../../services/print-content.service';
import { ThemeService } from '../../theme';

@Component({
  selector: 'app-modal-visitor-self-registration-qrcode',
  templateUrl: 'modal-visitor-self-registration-qrcode.component.html',
  styleUrls: ['modal-visitor-self-registration-qrcode.component.scss']
})
export class ModalVisitorSelfRegistrationQrcodeComponent implements OnInit, AfterViewInit {
  condo: Condo;

  hostUrl: string;

  qrdata: string = null;

  constructor(public bsModalRef: BsModalRef, private printContentService: PrintContentService, private themeService: ThemeService) {}

  ngOnInit() {
    if (this.hostUrl.includes('localhost')) {
      this.qrdata = `http://localhost:8100/self-registration?condo=${this.condo._id}`;
    } else {
      this.qrdata = `${this.themeService.activeTheme?.qrCodePageUrl || 'visitante.econdos.com.br'}/self-registration?condo=${
        this.condo._id
      }`;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.print('qrcode-print');
    }, 500);
  }

  print(elementId) {
    const printContents = document.getElementById(elementId).innerHTML;
    this.printContentService.print(printContents);
  }
}
