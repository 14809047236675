import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { CondoService } from '@api/service/condo.service';
import { Dependent } from '@api/model/dependent';
import { removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { DependentService } from '@api/service/dependent.service';
import { EcondosQuery } from '@api/model/query';

interface CondoUserAndSearchText {
  searchText: string;
  dependent: Dependent;
}

@Component({
  selector: 'app-dependent-auto-complete',
  templateUrl: 'dependent-auto-complete.component.html',
  styleUrls: ['dependent-auto-complete.component.scss']
})
export class DependentAutoCompleteComponent implements OnInit {
  @Output() selectItem: EventEmitter<Dependent | CondoUserAndSearchText> = new EventEmitter<Dependent | CondoUserAndSearchText>();
  @Output() inputText: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder;
  @Input() condo: Condo;
  @Input() typeaheadHideResultsOnBlur = false;
  @Input() adaptivePosition = false;
  @Input() showAsInputGroup = false;
  @Input() disabled = false;
  @Input() dependentsQuery: EcondosQuery = {};
  @Input() limitOfItems = 10;
  @Input() alsoReturnSearchText = false;
  @Input() dontShowEmptyResultAndReturnSearchText = false;

  status: Status = new Status();

  typeAheadDataSource$: Observable<any[]>;
  selectedLabel = '';
  selectedObject;
  searchText = '';

  isBusinessCondo = false;
  noResult = false;

  onTypeAheadSelect = evt => {
    this.selectedObject = evt.item;

    if (this.alsoReturnSearchText) {
      this.selectItem.emit({
        searchText: this.searchText,
        dependent: evt.item
      });
    } else {
      this.selectItem.emit(evt.item);
    }

    this.selectedLabel = this.selectedObject.name;
  };

  constructor(private condoService: CondoService, private dependentService: DependentService) {
    this.typeAheadDataSource$ = new Observable((observer: any) => {
      observer.next(this.selectedLabel);
    }).pipe(
      distinctUntilChanged(),
      filter((text: string) => (text || '').trim().length > 2),
      mergeMap((token: string) => {
        this.searchText = token;

        let terms: any = token.trim().toLowerCase();
        terms = terms.split(' ');
        terms = terms.map(word => removeAccents(word));
        const originalTerms = [...terms];

        terms = terms.map(replaceVowelsToAccentedVowels);
        const hasNumber = /\d/;
        const isId = hasNumber.test(token);

        let dependentQS: EcondosQuery = {
          $populate: [{ path: 'picture', select: 'thumbnail url type' }],
          $limit: 50
        };
        if (isId) {
          dependentQS.$or = [{ cpf: { $regex: token } }, { rg: { $regex: token } }];
        } else {
          dependentQS.name = { $regex: terms.join(' '), $options: 'i' };
        }
        dependentQS = { ...dependentQS, ...this.dependentsQuery };

        return this.dependentService.getDependents(this.condo._id, dependentQS).pipe(
          map(res => {
            const dependents = (res.dependents || []).map(d => ({
              ...d,
              fullName: d.name,
              residences: [d.residence]
            }));
            const result = [...dependents].sort((a, b) => {
              const aName = a.fullName.toLowerCase();
              const bName = b.fullName.toLowerCase();
              const aRelevance = originalTerms.reduce((acc, term) => (aName.includes(term) ? acc + 1 : acc), 0);
              const bRelevance = originalTerms.reduce((acc, term) => (bName.includes(term) ? acc + 1 : acc), 0);
              return bRelevance - aRelevance;
            });
            return result;
          }),
          catchError(e => {
            this.status.setAsError();
            return of([]);
          })
        );
      })
    );
  }

  ngOnInit() {
    const { dependent } = this.condo?.customLabels;
    this.placeholder = this.placeholder || `Buscar por um ${dependent.singular}`;
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
    this.inputText.emit(this.searchText);
  }

  changeTypeaheadLoading(isLoading: boolean): void {
    if (isLoading) {
      this.status.setAsDownloading();
    } else {
      if (!this.status.isError()) {
        this.status.setAsSuccess();
      }
    }
  }

  resetValue() {
    this.selectedLabel = '';
    this.selectedObject = null;
    this.selectItem.emit(null);
  }

  onInputBackspace(event) {
    const targetValue = event.target.value;

    if (!targetValue) {
      this.resetValue();
    }
  }
}
