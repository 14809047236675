import { File } from './file';
import { ContactID } from './contact/contact.id';
import { Condo } from './condo';
import { ResidenceBuilder } from './residence/residence.builder';
import { Residence } from './interface/residence';

export class CondoUser {
  public _id = '';
  public firstName = '';
  public lastName = '';
  public phones: Array<any> = [];
  public ids: Array<ContactID> = new Array<ContactID>();
  public birthDate: Date | string = null;
  public picture: any = null;
  public condos: any = [];
  public condosAdmin: any = [];
  public condosOwner: any = [];
  public condosRequester: any = [];
  public condosGatekeeper: any = [];
  public condosJanitor: any = [];
  public residencesUser: any = [];
  public residencesRequester: any = [];
  public residencesVoter: any = [];
  public email = '';
  public specialNeeds: boolean;
  public condoResidenceAttributes = [];
  public specialNeedsDetails: '';
  public hardwareParams: { intelbras: { id: number; pessoaId: number } };

  constructor(user?) {
    if (user) {
      if (typeof user === 'string') {
        this._id = user;
      } else {
        this._id = user._id || '';
        this.firstName = user.firstName || '';
        this.lastName = user.lastName || '';
        this.phones = user.phones || [];
        this.birthDate = user.birthDate || '';
        this.specialNeeds = user.specialNeeds || false;
        this.specialNeedsDetails = user.specialNeedsDetails || '';
        this.picture = user.picture ? new File(user.picture) : null;
        this.condos = user.condos ? user.condos.map(condo => new Condo(condo)) : [];
        this.condosAdmin = user.condosAdmin ? user.condosAdmin.map(condo => new Condo(condo)) : [];
        this.condosOwner = user.condosOwner ? user.condosOwner.map(condo => new Condo(condo)) : [];
        this.condosRequester = user.condosRequester ? user.condosRequester.map(condo => new Condo(condo)) : [];
        this.condosGatekeeper = user.condosGatekeeper ? user.condosGatekeeper.map(condo => new Condo(condo)) : [];
        this.condosJanitor = user.condosJanitor ? user.condosJanitor.map(condo => new Condo(condo)) : [];
        this.residencesUser = user.residencesUser || [];
        this.residencesRequester = user.residencesRequester || [];
        this.residencesVoter = user.residencesVoter || [];
        this.condoResidenceAttributes = user.condoResidenceAttributes || [];
        this.email = user.email || '';
        this.ids = user.ids ? user.ids.map(id => new ContactID(id)) : [];
        this.hardwareParams = user.hardwareParams || {};
      }
    }
  }

  get residences(): Array<Residence> {
    const residences = this.residencesUser ? this.residencesUser.slice() : [];
    this.residencesVoter.forEach(resVoter => {
      if (!residences.find(resUser => resUser._id == resVoter._id)) {
        residences.push(resVoter);
      }
    });
    return residences.map(res => ResidenceBuilder.build(res));
  }

  hasResidence() {
    return !!this.residences.length;
  }

  get residencesIdentification(): Array<string> {
    return this.residences.map(res => {
      return res.identification;
    });
  }

  get cpf() {
    if (!this.ids) {
      return null;
    }
    const cpf = this.ids.find(id => id.type == ContactID.TYPES.CPF);
    return cpf || null;
  }

  get formattedCpf() {
    const cpf = this.cpf;
    if (cpf && cpf.number) {
      let cpfNumber = cpf.number;
      cpfNumber = cpfNumber.toString();
      cpfNumber = cpfNumber.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito exceto o asterisco
      cpfNumber = cpfNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4'); // Formata para o padrão de CPF
      return cpfNumber;
    }
    return '';
  }

  get cnpj() {
    if (!this.ids) {
      return null;
    }
    const cnpj = this.ids.find(id => id.type == ContactID.TYPES.CNPJ);
    return cnpj || null;
  }

  get formattedCnpj() {
    const cnpj = this.cnpj;
    if (cnpj && cnpj.number) {
      let cnpjNumber = cnpj.number;
      cnpjNumber = cnpjNumber.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito e asterisco
      cnpjNumber = cnpjNumber.replace(/^([0-9*]{2})([0-9*])/, '$1.$2');
      cnpjNumber = cnpjNumber.replace(/^([0-9*]{2})\.([0-9*]{3})([0-9*])/, '$1.$2.$3');
      cnpjNumber = cnpjNumber.replace(/\.([0-9*]{3})([0-9*])/, '.$1/$2');
      cnpjNumber = cnpjNumber.replace(/([0-9*]{4})([0-9*])/, '$1-$2');
      return cnpjNumber;
    }
    return '';
  }

  set passport(value: ContactID) {
    const index = this.ids.findIndex(id => id.type === ContactID.TYPES.PASSPORT);

    if (index > -1) {
      this.ids[index] = value;
    } else {
      this.ids.push(value);
    }
  }

  get passport() {
    if (!this.ids) {
      return null;
    }
    const passport = this.ids.find(id => id.type == ContactID.TYPES.PASSPORT);
    return passport || null;
  }

  get rg() {
    if (!this.ids) {
      return null;
    }
    const rg = this.ids.find(id => id.type == ContactID.TYPES.RG);
    return rg || null;
  }

  set rg(value: ContactID) {
    const index = this.ids.findIndex(id => id.type === ContactID.TYPES.RG);

    if (index > -1) {
      this.ids[index] = value;
    } else {
      this.ids.push(value);
    }
  }

  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  get name() {
    return this.firstName + ' ' + this.lastName;
  }
}
