import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-modal-share-link',
  templateUrl: 'modal-share-link.html',
  styleUrls: ['./modal-share-link.scss']
})
export class ModalShareLinkComponent {
  public link?: string;
  public bodyMessage?: string;
  public whatsAppMessage?: string;

  condo: Condo;
  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private clipboard: ClipboardService,
    private utilService: UtilService
  ) {
    this.condo = this.utilService.getLocalCondo();
  }

  whatsappLink() {
    let message: string;
    if (this.whatsAppMessage) {
      message = this.whatsAppMessage;
    } else {
      message = `Olá, você recebeu uma solicitação para se cadastrar no condomínio ${this.condo.name} para facilitar o seu próximo acesso, \
      clique no link a seguir para ser direcionado a página:`;
    }

    const text = `${message} ${this.link}`;
    const whatsAppLink = `https://wa.me/?text=${encodeURIComponent(text)}`;

    try {
      window.open(whatsAppLink);
    } catch (e) {
      this.toastr.error('Não foi possível compartilhar o link, tente novamente');
    }
  }

  copyLinkToClipBoard() {
    this.clipboard.copy(this.link);
    this.toastr.success('Link copiado com sucesso!');
  }
}
