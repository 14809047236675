import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'barcodePipe', pure: false })
export class BarcodePipe implements PipeTransform {
  transform(value: string): string {
    try {
      let result = 
      value.slice(0, 5) + ' ' 
      + value.slice(5, 10) + ' ' 
      + value.slice(10, 15) + ' ' 
      + value.slice(15, 21) + ' ' 
      + value.slice(21, 26) + ' ' 
      + value.slice(26, 32) + ' ' 
      + value.slice(32, 33) + ' '
      + value.slice(33);
      return result;
    } catch (e){
      console.log('Error parsing value = ', value);
      console.log('Error = ', e);
      return '';
    }
  }
}
