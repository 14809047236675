import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UsbDeviceReaderComponent } from './usb-device-reader.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [UsbDeviceReaderComponent],
  exports: [UsbDeviceReaderComponent]
})
export class UsbDeviceReaderModule {}
