import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Notification } from '@api/model/notification';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-notification-image',
  standalone: true,
  templateUrl: 'notification-image.component.html',
  imports: [CommonModule, TooltipModule]
})
export class NotificationImageComponent implements OnInit {
  @Input() notification: Notification;
  src: string;
  tooltip: string;

  ngOnInit(): void {
    const showCondoNotificationTypes = [
      'RESERVATION-FROM-QUEUE-TO-APPROVED',
      'RESERVATION-FROM-QUEUE-TO-PENDING',
      'RESERVATION-QUEUE-POSITION-CHANGE'
    ];
    if (showCondoNotificationTypes.includes(this.notification.type)) {
      this.src = this.notification?.condo?.pictures[0]?.url || 'assets/img/default-condo-picture.png';
      this.tooltip = this.notification?.condo?.name;
    } else {
      this.src =
        this.notification?.condoContact?.picture?.url ||
        this.notification?.visitorRequest?.registeredContact?.picture?.url ||
        this.notification?.createdBy?.picture?.url ||
        this.notification?.dependent?.picture?.url ||
        '../../../assets/img/empty-user-picture.png';
      this.tooltip = !!this.notification?.visitorRequest?.registeredContact
        ? this.notification?.visitorRequest?.registeredContact?.firstName +
          ' ' +
          this.notification?.visitorRequest?.registeredContact?.lastName
        : !!this.notification?.dependent?.name
          ? this.notification?.dependent?.name
          : this.notification?.createdBy?.firstName + ' ' + this.notification?.createdBy?.lastName;
    }
  }
}
