import { Occurrence } from './interface/occurrence';
import { OccurrenceBuilder } from './occurrence/occurrence.builder';
import { Condo } from './condo';
import { Reservation } from './reservation';
import { Residence } from './interface/residence';
import { GateOccurrence } from './occurrence/occurrence.gate';
import { ResidenceBuilder } from './residence/residence.builder';
import { CondoContact } from './contact/condo.contact';
import { VisitorRequest } from '@api/model/interface/visitor.request';
import { DevicesRequest } from '@api/model/devices-requests';
import { Dependent } from '@api/model/dependent';

export class Notification {
  static TYPES = {
    OCCURRENCE_NEW: 'OCCURRENCE-NEW',
    COMMENT_NEW: 'COMMENT-NEW',
    OCCURRENCE_LIKE: 'OCCURRENCE-LIKE',
    COMMENT_LIKE: 'COMMENT-LIKE',
    OCCURRENCE_VOTE_FAVOR: 'OCCURRENCE-VOTE-FAVOR',
    OCCURRENCE_VOTE_AGAINST: 'OCCURRENCE-VOTE-AGAINST',
    OCCURRENCE_VOTE: 'OCCURRENCE-VOTE',
    USER_NEW_CONDO_REQUESTER: 'USER-NEW-CONDO-REQUESTER',
    USER_NEW_CONDO_USER: 'USER-NEW-CONDO-USER',
    USER_NEW_CONDO_ADMIN: 'USER-NEW-CONDO-ADMIN',
    USER_NEW_CONDO_OWNER: 'USER-NEW-CONDO-OWNER',
    USER_NEW_CONDO_GATEKEEPER: 'USER-NEW-CONDO-GATEKEEPER',
    USER_NEW_RESIDENCE_REQUESTER: 'USER-NEW-RESIDENCE-REQUESTER',
    USER_NEW_RESIDENCE_USER: 'USER-NEW-RESIDENCE-USER',
    USER_NEW_RESIDENCE_VOTER: 'USER-NEW-RESIDENCE-VOTER',
    USER_REMOVED: 'USER-REMOVED',
    USER_REMOVED_CONDO_ADMIN: 'USER-REMOVED-CONDO-ADMIN',
    USER_REMOVED_CONDO_GATEKEEPER: 'USER-REMOVED-CONDO-GATEKEEPER',
    USER_APPROVED: 'USER-APPROVED',
    RESERVATION_CREATED: 'RESERVATION-CREATED',
    RESERVATION_CANCELED: 'RESERVATION-CANCELED',
    RESERVATION_APPROVED: 'RESERVATION-APPROVED',
    RESERVATION_REJECTED: 'RESERVATION-REJECTED',
    ADVERTISE_NEW: 'ADVERTISE-NEW',
    MAINTENANCE_TICKET_NEW: 'MAINTENANCE-TICKET-NEW',
    MAINTENANCE_ACTION_EXECUTION_ALERT: 'MAINTENANCE-ACTION-EXECUTION-ALERT',
    LOST_AND_FOUND_NEW: 'LOST-AND-FOUND-NEW',
    GATE_NOTEBOOK_MESSAGE_NEW: 'GATE-NOTEBOOK-MESSAGE-NEW',
    DEPENDENT_NEW: 'DEPENDENT-NEW',
    VEHICLE_NEW: 'VEHICLE-NEW',
    PET_NEW: 'PET-NEW',
    COMMAND_PANEL_SIGNAL_STATUS: 'COMMAND-PANEL-SIGNAL-STATUS',
    DEPENDENT_ACCESS: 'DEPENDENT-ACCESS'
  };

  static SUB_TYPES = {
    NEW_ACCESS_OCCURRENCE: 'NEW-ACCESS-OCCURRENCE',
    NEW_DELIVERY_OCCURRENCE: 'NEW-DELIVERY-OCCURRENCE'
  };

  static FEED_NOTIFICATION_TYPES = [
    Notification.TYPES.OCCURRENCE_NEW,
    Notification.TYPES.COMMENT_NEW,
    Notification.TYPES.OCCURRENCE_LIKE,
    Notification.TYPES.OCCURRENCE_VOTE,
    Notification.TYPES.OCCURRENCE_VOTE_AGAINST,
    Notification.TYPES.OCCURRENCE_VOTE_FAVOR
  ];

  static GATE_NOTIFICATION_TYPES = [Notification.TYPES.OCCURRENCE_NEW, Notification.TYPES.COMMENT_NEW];

  static CONDO_RESIDENTS_NOTIFICATION_TYPES = [
    Notification.TYPES.USER_NEW_CONDO_OWNER,
    Notification.TYPES.USER_NEW_CONDO_ADMIN,
    Notification.TYPES.USER_NEW_CONDO_GATEKEEPER,
    Notification.TYPES.USER_REMOVED_CONDO_ADMIN,
    Notification.TYPES.USER_REMOVED_CONDO_GATEKEEPER,
    Notification.TYPES.USER_NEW_CONDO_REQUESTER,
    Notification.TYPES.USER_NEW_CONDO_USER,
    Notification.TYPES.USER_REMOVED
  ];

  static CONDO_RESIDENCES_NOTIFICATION_TYPES = [
    Notification.TYPES.USER_NEW_RESIDENCE_REQUESTER,
    Notification.TYPES.USER_NEW_RESIDENCE_USER,
    Notification.TYPES.USER_NEW_RESIDENCE_VOTER
  ];

  static RESERVATION_NOTIFICATION_TYPES = [
    Notification.TYPES.RESERVATION_CREATED,
    Notification.TYPES.RESERVATION_CANCELED,
    Notification.TYPES.RESERVATION_APPROVED,
    Notification.TYPES.RESERVATION_REJECTED
  ];

  static RESIDENCE_NOTIFICATION_TYPES = [
    Notification.TYPES.USER_NEW_RESIDENCE_REQUESTER,
    Notification.TYPES.USER_NEW_RESIDENCE_USER,
    Notification.TYPES.USER_NEW_RESIDENCE_VOTER
  ];

  static ADVERTISE_NOTIFICATION_TYPES = [Notification.TYPES.ADVERTISE_NEW];

  static MAINTENANCE_NOTIFICATIONS_TYPES = [Notification.TYPES.MAINTENANCE_TICKET_NEW];

  static LOST_AND_FOUND_TYPES = [Notification.TYPES.LOST_AND_FOUND_NEW];

  static RESIDENTS_NOTIFICATION_TYPES = [];

  static UPDATE_USER_NOTIFICATION_TYPES = ['USER-NEW-RESIDENCE-USER', 'USER-NEW-RESIDENCE-VOTER'];

  static IGNORED_NOTIFICATION_TYPES = [
    // Notification.TYPES.USER_REMOVED,
    // Notification.TYPES.USER_NEW_CONDO_OWNER,
    // Notification.TYPES.USER_NEW_CONDO_GATEKEEPER,
    // Notification.TYPES.USER_REMOVED_CONDO_GATEKEEPER,
    // Notification.TYPES.USER_NEW_CONDO_USER,
    // Notification.TYPES.USER_NEW_CONDO_ADMIN,
    // Notification.TYPES.USER_REMOVED_CONDO_ADMIN,
    // Notification.TYPES.OCCURRENCE_VOTE_AGAINST,
    // Notification.TYPES.OCCURRENCE_VOTE_FAVOR
  ];

  public _id: string;
  public comment: any;
  public condo: Condo;
  public createdAt: Date;
  public createdBy: any;
  public occurrence: Occurrence;
  public reservation: Reservation;
  public visitorRequest: VisitorRequest;
  public read: boolean;
  public type;
  public heading;
  public message;
  public subType;
  private _occurrenceLiker;
  private _commentLiker;
  private _condoUser;
  private _isPush: boolean;
  public residence: Residence;
  public deviceRequest: DevicesRequest;
  public dependent: Dependent;
  public condoContact: CondoContact;

  constructor(notification) {
    if (notification) {
      this._id = notification._id || '';
      this.comment = notification.comment || {};
      this.createdAt = notification.createdAt || new Date();
      this.createdBy = notification.createdBy || {};
      this.occurrence = notification.occurrence ? OccurrenceBuilder.build(notification.occurrence) : null;
      this.reservation = notification.reservation ? new Reservation(notification.reservation) : null;
      this.residence = notification.residence ? ResidenceBuilder.build(notification.residence) : null;
      this.read = notification.read || false;
      this.type = notification.type || '';
      this.heading = notification.heading || '';
      this.message = notification.message || '';
      this.subType = notification.subType || '';
      this.visitorRequest = notification.visitorRequest || '';
      this._occurrenceLiker = notification.occurrenceLiker || {};
      this._commentLiker = notification.commentLiker || {};
      this._condoUser = notification.condoUser || {};
      this._isPush = notification.isPush || false;
      this.deviceRequest = notification.deviceRequest || null;
      this.dependent = notification.dependent || {};
      this.condoContact = notification.condoContact ? new CondoContact(notification.condoContact) : null;

      if (typeof notification.condo === 'string') {
        this.condo = new Condo({ _id: notification.condo });
      }

      if (typeof notification.condo === 'object') {
        this.condo = new Condo(notification.condo);
      }
    }
  }

  shouldIgnoreThisNotificaion(condo: Condo): boolean {
    if (this.occurrence) {
      if (!condo.canCreatePublicOccurrence()) {
        if (this.occurrence.isPublicType()) {
          return true;
        }
      }
    }
    return Notification.IGNORED_NOTIFICATION_TYPES.indexOf(this.type) !== -1;
  }

  shouldUpdateUser(): boolean {
    return Notification.UPDATE_USER_NOTIFICATION_TYPES.indexOf(this.type) !== -1;
  }

  isFeedNotification(residences: Array<Residence>): boolean {
    if (this.occurrence instanceof GateOccurrence) {
      return Notification.FEED_NOTIFICATION_TYPES.indexOf(this.type) !== -1 && this.doesBelongToUserResidence(residences);
    }

    return Notification.FEED_NOTIFICATION_TYPES.indexOf(this.type) !== -1;
  }

  isReservationNotification(): boolean {
    return Notification.RESERVATION_NOTIFICATION_TYPES.indexOf(this.type) !== -1;
  }

  isCondoResidentsNotification(): boolean {
    return Notification.CONDO_RESIDENTS_NOTIFICATION_TYPES.indexOf(this.type) !== -1;
  }

  isCondoResidencesNotification(): boolean {
    return Notification.CONDO_RESIDENCES_NOTIFICATION_TYPES.indexOf(this.type) !== -1;
  }

  isGateNotification() {
    return Notification.GATE_NOTIFICATION_TYPES.indexOf(this.type) !== -1 && this.occurrence && this.occurrence.isGateType();
  }

  isAdvertiseNotification() {
    return Notification.ADVERTISE_NOTIFICATION_TYPES.indexOf(this.type) !== -1;
  }

  isResidenceNotification(residences: Array<Residence>): boolean {
    let residenceIndex;
    if (this.residence) {
      residenceIndex = residences.findIndex(r => this.residence.id === r._id);
    } else {
      const identification = this.message.substring(this.message.indexOf('"')).replace(/"/g, '');
      residenceIndex = residences.findIndex(res => res.identification == identification);
    }
    return Notification.RESIDENCE_NOTIFICATION_TYPES.indexOf(this.type) !== -1 && residenceIndex > -1;
  }

  isMaintenanceNotification() {
    return Notification.MAINTENANCE_NOTIFICATIONS_TYPES.indexOf(this.type) !== -1;
  }

  isUserApprovedOnCondo(): boolean {
    return this.type == Notification.TYPES.USER_APPROVED;
  }

  isFromCondo(condoId): boolean {
    return this.condo && (this.condo == condoId || this.condo.id == condoId);
  }

  isNewOccurrenceNotification() {
    return this.type == Notification.TYPES.OCCURRENCE_NEW;
  }

  isNewCommentNotification() {
    return this.type == Notification.TYPES.COMMENT_NEW;
  }

  isNewLikeNotification() {
    return this.type == Notification.TYPES.OCCURRENCE_LIKE;
  }

  doesBelongToUserResidence(residences: Array<Residence>): boolean {
    if (!this.occurrence || !this.occurrence.residence) {
      return true;
    }

    return residences.findIndex(res => res._id == this.occurrence.residence) !== -1;
  }

  /////// =========================GETTERS AND SETTTERS============\\\\\\\\\\\\

  public get id() {
    return this._id;
  }

  public set id(value) {
    this._id = value;
  }

  get occurrenceLiker() {
    return this._occurrenceLiker;
  }

  set occurrenceLiker(value) {
    this._occurrenceLiker = value;
  }

  get commentLiker() {
    return this._commentLiker;
  }

  set commentLiker(value) {
    this._commentLiker = value;
  }

  get condoUser() {
    return this._condoUser;
  }

  set condoUser(value) {
    this._condoUser = value;
  }

  get isPush(): boolean {
    return this._isPush;
  }

  set isPush(value: boolean) {
    this._isPush = value;
  }
}
