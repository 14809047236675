import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { ActuatorsRouteService } from '@api/service/hardware/actuators-route.service';
import { Actuator } from '@api/model/hardware/actuator';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalAddActuatorComponent } from '../../hardware/modal-add-actuator/modal-add-actuator.component';
import swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Status } from '@api/model/status';
import { catchError, filter, share, switchMap, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HardwareSocketService, SOCKET_CONNECTION_STATUS } from '../../services/hardware-socket.service';
import { UtilService } from '../../services/util.service';
import { Observable, of, Subject } from 'rxjs';
import { ModalActuateActuatorComponent } from '../modal-actuate-actuator/modal-actuate-actuator.component';
import { ModalActuateActuatorsRouteComponent } from '../modal-actuate-actuator-routes/modal-actuate-actuators-routes.component';
import { EcondosQuery } from '@api/model/query';
import { mapOrder } from '@api/utils';
import { ParamsService } from '@api/service/params.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { CondoServiceV2 } from '@api/serviceV2/condo.service';
import { SessionService } from '@api/service/session.service';
import { ActuatorsRoute } from '@api/model/hardware/actuators-route';
import { ModalCreateActuatorsRouteComponent } from 'app/hardware/modal-create-actuators-route/modal-create-actuators-route.component';

type actuatorActionParams =
  | 'CREATE_ACTUATOR'
  | 'UPDATE_ACTUATOR'
  | 'DELETE_ACTUATOR'
  | 'CREATE_ACTUATOR_ROUTE'
  | 'UPDATE_ACTUATOR_ROUTE'
  | 'DELETE_ACTUATOR_ROUTE'
  | 'REFRESH_ACTUATOR_ROUTE';

@Component({
  selector: 'app-actuators-panel',
  templateUrl: './actuators-panel.component.html',
  styleUrls: ['./actuators-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActuatorsPanelComponent implements OnDestroy {
  isVisible = false;

  permissions = {
    canEdit: true,
    canDelete: true,
    canRegister: true
  };

  user: User;
  condo: Condo;

  typeSearchActuators: 'todos' | 'rotas' = 'todos';
  actuators: Actuator[] = [];

  status: Status = new Status();

  enabled$: Observable<boolean>;
  $onDestroy: Subject<any> = new Subject();

  CONNECTION_STATUS = SOCKET_CONNECTION_STATUS;

  connectionStatus$ = this.hardwareSocketService.connectionStatus$.pipe(share());

  dragDelay = window?.innerWidth < 992 ? 500 : 0;

  actuatorsOrderKey: string;

  hasPermission = true;

  constructor(
    private actuatorService: ActuatorService,
    private actuatorsRouteService: ActuatorsRouteService,
    private modalService: BsModalService,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private hardwareSocketService: HardwareSocketService,
    private toastr: ToastrService,
    private paramsService: ParamsService,
    private condoServiceV2: CondoServiceV2,
    private sessionService: SessionService
  ) {
    this.condo = this.sessionService.condoValue;
    this.user = this.sessionService.userValue;

    this.paramsService.newParams$
      .pipe(
        takeUntil(this.$onDestroy),
        filter(
          p =>
            (this.typeSearchActuators === 'todos' && ['UPDATE_ACTUATOR', 'DELETE_ACTUATOR', 'CREATE_ACTUATOR'].includes(p.key)) ||
            (this.typeSearchActuators === 'rotas' &&
              ['CREATE_ACTUATOR_ROUTE', 'UPDATE_ACTUATOR_ROUTE', 'DELETE_ACTUATOR_ROUTE', 'REFRESH_ACTUATOR_ROUTE'].includes(p.key))
        )
      )
      .subscribe(({ key, value }: { key: actuatorActionParams; value: Actuator | ActuatorsRoute }) => {
        let index;
        switch (key) {
          case 'CREATE_ACTUATOR':
          case 'CREATE_ACTUATOR_ROUTE':
            this.actuators.unshift(value);
            this.cdr.detectChanges();
            break;
          case 'UPDATE_ACTUATOR':
          case 'UPDATE_ACTUATOR_ROUTE':
            index = this.actuators.findIndex(a => a._id === value._id);
            if (value.showInActuatorsPanel) {
              if (index > -1) {
                this.actuators[index] = value;
              } else {
                this.actuators.unshift(value);
              }
            } else if (index > -1) {
              this.actuators.splice(index, 1);
              this.actuators = [].concat(this.actuators);
            }
            this.cdr.detectChanges();
            break;
          case 'DELETE_ACTUATOR':
          case 'DELETE_ACTUATOR_ROUTE':
            index = this.actuators.findIndex(a => a._id === value._id);
            this.actuators.splice(index, 1);
            this.actuators = [].concat(this.actuators);
            this.cdr.detectChanges();
            break;
          case 'REFRESH_ACTUATOR_ROUTE':
            this.getCondoActuators(this.condo, this.typeSearchActuators);
            break;
          default:
            break;
        }
      });

    this.enabled$ = this.hardwareSocketService.isEnabled$.pipe(
      tap(isEnabled => {
        if (isEnabled) {
          this.user = this.utilService.getLocalUser();
          this.condo = (this.user && this.user.defaultCondo) || this.utilService.getLocalCondo();
          let actuatorOrderKey = this.typeSearchActuators === 'todos' ? 'actuators' : 'actuatorsRoute';
          this.actuatorsOrderKey = `${actuatorOrderKey}_${this.user._id}_${this.condo._id}`;
          this.getCondoActuators(this.condo, this.typeSearchActuators);
        } else {
          this.actuators = [];
          this.cdr.detectChanges();
        }
      })
    );

    if (this.user?.isOwner() || this.user?.isAdmin()) {
      this.permissions = { canEdit: true, canDelete: true, canRegister: true };
    } else if (this.user?.isGatekeeper()) {
      this.permissions = {
        canEdit: this.condo.hardwareParams.gatekeeperCanEditActuator,
        canDelete: this.condo.hardwareParams.gatekeeperCanDeleteActuator,
        canRegister: this.condo.hardwareParams.gatekeeperCanRegisterActuator
      };
    }
  }

  ngOnDestroy(): void {
    this.$onDestroy.next('');
    this.$onDestroy.complete();
  }

  getCondoActuators(condo, searchType: 'todos' | 'rotas' = 'todos', event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    this.typeSearchActuators = searchType;
    this.status.setAsDownloading();

    const params: EcondosQuery = {
      $populate: ['camera'],
      showInActuatorsPanel: true
    };

    const paramsRoute: EcondosQuery = {
      $populate: [{ path: 'selections', populate: { path: 'actuator', populate: 'camera' } }],
      showInActuatorsPanel: true
    };

    const permission = this.user.getPermissionValue({
      condoId: this.condo._id,
      permission: PERMISSIONS.actuatorsPanel.trigger
    });
    if (permission) {
      this.condoServiceV2
        .validateIPPermission(this.condo._id)
        .pipe(
          switchMap(() => {
            if (searchType === 'todos') {
              return this.actuatorService.getActuators(condo._id, params);
            } else {
              return this.actuatorsRouteService.getActuatorsRoute(condo._id, paramsRoute);
            }
          }),
          catchError(() => {
            this.hasPermission = false;
            return of({ actuators: [] });
          })
        )
        .subscribe({
          next: ({ actuators }) => {
            if (actuators.length >= 0) {
              this.hasPermission = true;
              let storedOrder = localStorage.getItem(this.actuatorsOrderKey);
              if (storedOrder) {
                storedOrder = JSON.parse(storedOrder);
                this.actuators = mapOrder(actuators, storedOrder, '_id');
              } else {
                this.actuators = actuators;
              }
              localStorage.setItem(this.actuatorsOrderKey, JSON.stringify(this.actuators.map(d => d._id)));
            }
            this.status.setAsSuccess();
            this.cdr.detectChanges();
          },
          error: err => {
            console.log(err);
            this.status.setAsError();
            this.cdr.detectChanges();
          }
        });
    } else {
      this.status.setAsError();
      this.cdr.detectChanges();
      this.hasPermission = false;
    }
  }

  public toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  createActuator() {
    const initialState = {
      condo: this.condo,
      user: this.user,
      callbacks: {
        success: actuator => {
          this.actuators.push(actuator);
          this.cdr.detectChanges();
        }
      }
    };
    this.modalService.show(ModalAddActuatorComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
  }

  createActuatorsRoute() {
    // Carrega a listagem de todos os acionadores do condomínio
    let actuatorsList = [];
    const query: EcondosQuery = {
      $populate: [
        { path: 'condo', select: 'name', populate: 'hardwareParams' },
        { path: 'condos', select: 'name' }
      ]
    };
    this.actuatorService
      .getActuators(this.condo._id, query)
      .pipe(timeout(10000), take(1))
      .subscribe({
        next: res => {
          actuatorsList = res.actuators.filter(actuator => {
            const actuatorFromCurrentCondo = actuator.condo._id === this.condo._id;
            const actuatorSharedFromOtherCondo = actuator.condo?.hardwareParams?.condosSharingHardware.includes(this.condo._id);
            return actuatorFromCurrentCondo || actuatorSharedFromOtherCondo;
          });

          // Abre o modal de edição da rota de acionadores
          const user = this.utilService.getLocalUser();
          const initialState = {
            condo: this.condo,
            actuatorsList,
            user: this.user,
            callbacks: {
              success: updatedActuator => {
                const actuators = [].concat(...this.actuators);
                const index = actuators.findIndex(a => a._id === updatedActuator._id);
                if (index !== -1) {
                  actuators[index] = updatedActuator;
                  this.actuators = actuators;
                }
                this.cdr.detectChanges();
              }
            }
          };
          this.modalService.show(ModalCreateActuatorsRouteComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
        },
        error: err => console.log(err)
      });
  }

  editActuator(actuator: Actuator) {
    const initialState = {
      condo: this.condo,
      actuator,
      user: this.user,
      callbacks: {
        success: updatedActuator => {
          const actuators = [].concat(...this.actuators);
          const index = actuators.findIndex(a => a._id === updatedActuator._id);
          if (index !== -1) {
            actuators[index] = updatedActuator;
            this.actuators = actuators;
          }
          this.cdr.detectChanges();
        }
      }
    };
    this.modalService.show(ModalAddActuatorComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
  }

  editActuatorsRoute(actuatorsRoute: ActuatorsRoute) {
    // Carrega a listagem de todos os acionadores do condomínio
    let actuatorsList = [];
    const query: EcondosQuery = {
      $populate: [
        { path: 'condo', select: 'name', populate: 'hardwareParams' },
        { path: 'condos', select: 'name' }
      ]
    };
    this.actuatorService
      .getActuators(this.condo._id, query)
      .pipe(timeout(10000), take(1))
      .subscribe({
        next: res => {
          actuatorsList = res.actuators.filter(actuator => {
            const actuatorFromCurrentCondo = actuator.condo._id === this.condo._id;
            const actuatorSharedFromOtherCondo = actuator.condo?.hardwareParams?.condosSharingHardware.includes(this.condo._id);
            return actuatorFromCurrentCondo || actuatorSharedFromOtherCondo;
          });

          // Abre o modal de edição da rota de acionadores
          const user = this.utilService.getLocalUser();
          const initialState = {
            condo: this.condo,
            actuatorsList,
            actuatorsRoute,
            user: this.user,
            callbacks: {
              success: updatedActuator => {
                const actuators = [].concat(...this.actuators);
                const index = actuators.findIndex(a => a._id === updatedActuator._id);
                if (index !== -1) {
                  actuators[index] = updatedActuator;
                  this.actuators = actuators;
                }
                this.cdr.detectChanges();
              }
            }
          };
          this.modalService.show(ModalCreateActuatorsRouteComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
        },
        error: err => console.log(err)
      });
  }

  actuate(actuator: Actuator) {
    const initialState = {
      condo: this.condo,
      actuator,
      user: this.user
    };
    this.modalService.show(ModalActuateActuatorComponent, {
      initialState,
      class: 'modal-lg modal-dialog-centered',
      animated: false
    });
  }

  actuateRoutes(actuator: ActuatorsRoute) {
    const initialState = {
      condo: this.condo,
      actuator,
      isActuatorsRoute: true,
      user: this.user
    };
    let modalActuatorRoutesModal = this.modalService.show(ModalActuateActuatorsRouteComponent, {
      initialState,
      class: 'modal-lg modal-dialog-centered',
      animated: false
    });

    modalActuatorRoutesModal.onHidden.subscribe(() => {
      modalActuatorRoutesModal = null;
    });
  }

  askToHideActuatorFromPanel(actuator: Actuator, index) {
    swal({
      title: 'Ocultar acionador?',
      text: `O acionador ${actuator.name} não poderá mais ser acessado pela botoeira`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, ocultar',
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: () => {
        return this.actuatorService
          .update(this.condo._id, actuator._id, { showInActuatorsPanel: false })
          .pipe(timeout(10000))
          .toPromise()
          .then(() => {
            this.actuators.splice(index, 1);
            this.cdr.detectChanges();
          })
          .catch(error => {
            console.log(error);
            return Promise.reject('Não foi possível ocultar este acionador, tente novamente.');
          });
      }
    });
  }

  askToHideActuatorsRouteFromPanel(actuator: ActuatorsRoute, index) {
    swal({
      title: 'Ocultar rota de acionador?',
      text: `A rota de acionador ${actuator.title} não poderá mais ser acessado pela botoeira`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, ocultar',
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: () => {
        return this.actuatorsRouteService
          .update(this.condo._id, actuator._id, { showInActuatorsPanel: false })
          .pipe(timeout(10000))
          .toPromise()
          .then(() => {
            this.actuators.splice(index, 1);
            this.cdr.detectChanges();
          })
          .catch(error => {
            console.log(error);
            return Promise.reject('Não foi possível ocultar esta rota de acionador, tente novamente.');
          });
      }
    });
  }

  drop(source, event: CdkDragDrop<string[]>) {
    moveItemInArray(source, event.previousIndex, event.currentIndex);
    localStorage.setItem(this.actuatorsOrderKey, JSON.stringify(source.map(d => d._id)));
  }

  reconnectSocket(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hardwareSocketService.initialize(this.user.token, this.condo._id);
  }
}
