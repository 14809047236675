import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { EcondosQuery } from '@api/model/query';
import { HardwareReader } from '@api/model/hardware-reader';
import * as qs from 'qs';

@Injectable()
export class HardwareReaderService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  get(condoId: string, query: EcondosQuery): Observable<{ count: number; hardwareReaders: HardwareReader[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/hardware-readers`, { params }).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        hardwareReaders: res.body as HardwareReader[]
      }))
    );
  }

  create(condoId: string, data) {
    return this.http.post(`${this.endPoint}${condoId}/hardware-readers`, data);
  }

  update(condoId: string, id: string, data) {
    return this.http.put(`${this.endPoint}${condoId}/hardware-readers/${id}`, data);
  }

  delete(condoId: string, id: string) {
    return this.http.delete(`${this.endPoint}${condoId}/hardware-readers/${id}`);
  }

  getById(condoId: string, id: string): Observable<HardwareReader> {
    return this.http.get(`${this.endPoint}${condoId}/hardware-readers/${id}`).pipe(map(res => res as HardwareReader));
  }

  checkLprConnection(reader: HardwareReader) {
    switch (reader.hardware) {
      case 'ALPHADIGI':
      case 'GAREN': {
        return this.http.get(`${this.constantService.getV2Endpoint()}integrations/plate-recognizer/alphadigi/keep-alive/${reader.serial}`);
      }
    }
  }
}
