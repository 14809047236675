import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
// Models
import { Vehicle } from '../../../api/model/vehicle';
import { Status } from '../../../api/model/status';
import { CondoVehicle } from '../../../api/model/condo.vehicle';
import { Condo } from '../../../api/model/condo';
// Services
import { FileService } from '../../../api/service/file.service';
import { getCarBrands, getMotoBrands, getBicycleBrands } from '../../../api/model/vehicle.constants';
import { VehicleService } from '../../../api/service/vehicle.service';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { debounceTime, takeUntil, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ModalPreviewWebcamComponent } from 'app/components/image-picker/modal-preview-webcam/modal-preview-webcam.component';
import { ModalPreviewCCTVComponent } from 'app/components/image-picker/modal-preview-cctv/modal-preview-cctv.component';
import { ResidentAutoCompleteComponent } from 'app/components/resident-auto-complete/resident-auto-complete.component';
import { conditionalValidator } from '@api/util/validators';

@Component({
  selector: 'vehicle-create-modal',
  templateUrl: 'vehicle.create.modal.html'
})
export class VehicleCreateModal implements OnDestroy {
  @ViewChild('vehicleCreateModal', { static: true }) public vehicleCreateModal: ModalDirective;
  @ViewChild(ResidentAutoCompleteComponent) vehicleUserPicker: ResidentAutoCompleteComponent;

  @Output() vehicleCreated = new EventEmitter();

  @Output() vehicleUpdated = new EventEmitter();

  @Input() showResidentPicker = false;

  form;

  condo: Condo;
  vehicle: CondoVehicle;
  VEHICLES_COLORS = CondoVehicle.VEHICLES_COLORS;
  VEHICLES_COLORS_LABEL = CondoVehicle.VEHICLES_COLORS_LABEL;
  VEHICLE_BRANDS = CondoVehicle.VEHICLE_BRANDS;
  vehicleBrandsOptions = [];

  // Form fields
  plate: AbstractControl;
  chassis: AbstractControl;
  model: AbstractControl;
  type: AbstractControl;
  brand: AbstractControl;
  color: AbstractControl;
  vehicleUser: AbstractControl;

  pictures = [];
  isUploading = false;

  uploadCallback = ([img]) => this.uploadImage(img);

  status: Status = new Status();

  // Constants
  TYPES = Vehicle.TYPES;
  VEHICLE_TYPES_KEYS = Object.keys(Vehicle.TYPES);
  CAR_BRANDS = getCarBrands();
  MOTO_BRANDS = getMotoBrands();
  BICYCLE_BRANDS = getBicycleBrands();
  VEHICLE_TYPES = {
    CAR: {
      label: 'Carro',
      brands: this.CAR_BRANDS
    },
    TRUCK: {
      label: 'Caminhão',
      brands: {}
    },
    MOTORCYCLE: {
      label: 'Moto',
      brands: this.MOTO_BRANDS
    },
    BICYCLE: {
      label: 'Bicicleta',
      brands: this.BICYCLE_BRANDS
    }
  };

  object;

  isSubmitting: boolean;

  pageTitle: string;
  buttonTitle: string;

  operation: Status = new Status();

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private vehicleService: VehicleService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private modalService: BsModalService
  ) {
    Object.keys(CondoVehicle.VEHICLE_BRANDS).map(brand => this.vehicleBrandsOptions.push(CondoVehicle.VEHICLE_BRANDS[brand]));
    this.condo = this.utilService.getLocalCondo();

    this.object = Object;

    this.setUpForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setUpForm() {
    this.form = this.formBuilder.group({
      plate: [
        '',
        Validators.compose([
          conditionalValidator(() => this.type.value !== 'BICYCLE' && !this.chassis.value, Validators.required),
          Validators.minLength(3),
          Validators.maxLength(7)
        ])
      ],
      chassis: [
        '',
        Validators.compose([
          conditionalValidator(() => !this.plate.value, Validators.required),
          Validators.minLength(7),
          Validators.maxLength(17)
        ])
      ],
      model: [''],
      type: [this.TYPES.CAR, Validators.required],
      brand: [''],
      color: [''],
      vehicleUser: ['']
    });

    this.plate = this.form.get('plate');
    this.chassis = this.form.get('chassis');
    this.model = this.form.get('model');
    this.type = this.form.get('type');
    this.brand = this.form.get('brand');
    this.color = this.form.get('color');
    this.vehicleUser = this.form.get('vehicleUser');

    this.plate.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
    });

    this.chassis.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
    });

    this.type.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
    });
  }

  closeModal(): void {
    this.vehicleCreateModal.hide();
    this.clear();
  }

  editVehicle(vehicle: CondoVehicle) {
    this.setPageTitle('Editar veículo');
    this.setButtonTitle('Salvar');

    this.setAsEditing();

    this.vehicle = vehicle || new CondoVehicle();
    this.pictures = this.vehicle.pictures || [];
    this.updateForm(this.vehicle);
    this.vehicleCreateModal.show();
  }

  clear() {
    this.operation = new Status();
    this.vehicle = null;
    this.setUpForm();
  }

  createVehicle() {
    this.setPageTitle('Criar veículo');
    this.setButtonTitle('Criar');

    this.vehicle = null;
    this.updateForm(new CondoVehicle());

    this.vehicleCreateModal.show();
  }

  updateForm(vehicle: CondoVehicle) {
    this.plate.setValue(vehicle.plate || '');
    this.chassis.setValue(vehicle.chassis || '');
    this.model.setValue(vehicle.model || '');
    this.type.setValue(vehicle.type || '');
    this.brand.setValue(vehicle.brand || '');
    this.color.setValue(vehicle.color || '');
    this.vehicleUser.setValue(vehicle.user || null);

    if (this.vehicleUser?.value) {
      this.vehicleUserPicker?.onTypeAheadSelect({ item: this.vehicleUser.value });
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const vehicle = new CondoVehicle();
      vehicle.plate = this.plate.value.toUpperCase();
      vehicle.chassis = this.chassis.value.toUpperCase();
      vehicle.model = this.model.value.toUpperCase();
      vehicle.type = this.type.value;
      vehicle.brand = this.brand.value;
      vehicle.color = this.color.value.toUpperCase();

      let vehicleId = '';

      if (this.pictures.length) {
        vehicle.pictures = this.pictures;
      }

      if (this.vehicleUser.value) {
        vehicle.user = this.vehicleUser.value;
      }

      if (this.vehicle) {
        vehicleId = this.vehicle._id || '';
      }

      this.showSpinner();
      this.getVehicleServiceMethod(vehicle, vehicleId)
        .pipe(timeout(10000))
        .subscribe(
          (resp: any) => {
            this.closeModal();
            this.hideSpinner();

            vehicle.id = vehicleId || resp._id;

            if (vehicleId.length) {
              this.vehicleUpdated.emit(vehicle);
            } else {
              this.vehicleCreated.emit(vehicle);
            }
          },
          err => {
            this.hideSpinner();

            swal({
              type: 'error',
              title: 'Ops',
              text: 'Não foi possível criar este veículo',
              confirmButtonText: 'OK'
            });
          }
        );
    } else {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).markAsTouched());
      this.toastr.warning('Preencha os campos obrigatórios');
    }
  }

  getVehicleServiceMethod(vehicle: CondoVehicle, vehicleId = '') {
    if (vehicleId.length) {
      return this.vehicleService.updateVehicle(this.condo.id, vehicleId, vehicle.createBackObject());
    }

    return this.vehicleService.createVehicle(this.condo.id, vehicle.createBackObject());
  }

  clearBrand(evt) {
    this.brand.setValue('');
  }

  showSpinner() {
    this.isSubmitting = true;
  }

  hideSpinner() {
    this.isSubmitting = false;
  }

  setPageTitle(title: string) {
    this.pageTitle = title;
  }

  setButtonTitle(title: string) {
    this.buttonTitle = title;
  }

  isEditing(): boolean {
    return this.operation.isEditing();
  }

  setAsEditing() {
    this.operation.setAsEditing();
  }

  onBrandSelect(event) {
    const brand = event.value;
    this.brand.setValue(brand);
  }

  onColorSelect(event) {
    const color = event.value;
    this.color.setValue(color);
  }

  onVehicleUserSelect(data) {
    if (data) {
      this.vehicleUser.setValue(data);
    } else {
      this.vehicleUser.setValue(null);
    }
  }

  onVehicleUserTypeaheadNoResult(text) {
    this.vehicleUser.setValue(null);
  }

  uploadImage(image) {
    if (image) {
      const formData = new FormData();
      formData.append(image.title || 'image', image);
      this.isUploading = true;
      this.fileService
        .uploadFilesFromFormData(formData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          ([img]) => {
            this.isUploading = false;
            this.pictures.push(img);
          },
          err => {
            this.isUploading = false;
            swal({
              type: 'error',
              title: 'Ops...',
              text: 'Não foi possível efetuar o upload da imagem, tente novamente',
              cancelButtonColor: '#f53d3d'
            });
          }
        );
    }
  }

  uploadBase64Url(data) {
    this.isUploading = true;
    this.fileService.uploadBase64(data).subscribe(
      ([img]) => {
        this.pictures.push(img);
      },
      err => {
        console.log(err);
      },
      () => {
        this.isUploading = false;
      }
    );
  }

  openWebCam() {
    const initialState = {
      useImageCropper: true,
      callback: base64 => {
        if (base64) {
          this.uploadBase64Url(base64);
        }
      }
    };
    this.modalService.show(ModalPreviewWebcamComponent, { initialState, ignoreBackdropClick: true });
  }

  openCCTVCam() {
    const initialState = {
      defaultImage: 'assets/img/empty-vehicle-picture.png',
      useImageCropper: true,
      defaultType: '',
      callback: base64 => {
        if (base64) {
          this.uploadBase64Url(base64);
        }
      }
    };
    this.modalService.show(ModalPreviewCCTVComponent, { initialState, ignoreBackdropClick: true });
  }
}
