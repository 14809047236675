import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TableColumnDefinition } from '../components/table/table.component';
import { User } from '@api/model/user';

export let generateReport = (
  title,
  fields: Array<{ label: string; value: string }>,
  data,
  user,
  params?: {
    orientation: 'LANDSCAPE' | 'PORTRAIT';
  }
) => {
  const content: any = [{ text: title, style: 'header' }];

  const tableHeader = [{ text: '#', style: 'itemsTableHeader' }];
  for (const field of fields) {
    tableHeader.push({ text: field.label, style: 'itemsTableHeader' });
  }

  const items = [];

  data.forEach((d, index) => {
    const line = [{ text: index + 1, alignment: 'right' }];
    for (const field of fields) {
      line.push(d[field.value]);
    }
    items.push(line);
  });

  content.push('\n');
  content.push({
    style: 'itemsTable',
    table: {
      body: [tableHeader].concat(items)
    },
    layout: {
      hLineWidth: (i, node) => {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        } else {
          return 1;
        }
      },
      vLineWidth: (i, node, other) => {
        return i === 0 || i === node.table.widths.length ? 0 : 0;
      },
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#333' : '#dddddd';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#333' : '#dddddd';
      }
    }
  });

  const now = new Date();

  const localeDate = `${now.toLocaleDateString()} ás ${now.toLocaleTimeString()}`;

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const pdf = pdfMake.createPdf({
    content: content,
    footer: {
      text: `Gerado através do eCondos web, por ${user.fullName} em ${localeDate}.`,
      alignment: 'center'
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 5]
      },
      itemsTable: {
        margin: [0, 5, 0, 0]
      },
      itemsTableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black'
      }
    },
    defaultStyle: {
      fontSize: 9
    },
    pageSize: 'A4',
    pageMargins: [20, 20, 20, 30]
  });
  if (params?.orientation) {
    pdf.docDefinition.pageOrientation = params.orientation.toLowerCase();
  } else {
    // Se não foi especificada uma orientação o sistema define de acordo com o número de colunas
    const orientation = fields?.length > 8 ? 'landscape' : 'portrait';
    pdf.docDefinition.pageOrientation = orientation;
  }
  pdf.download(`${title}.pdf`);
};

export const generateCsv = (fields: Array<{ label: string; value: string }>, data) => {
  let csvContent = fields.map(f => f.label).join(';');
  csvContent += '\n';

  data.forEach(user => {
    const line = [];
    for (const field of fields) {
      line.push(`"${user[field.value]?.toString().replace(/"/g, '""')}"`);
    }
    csvContent += line.join(';') + '\n';
  });

  const encodedUri = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
  return encodedUri;
};

export let generateCustomReport = (
  title,
  fields: Array<{ label: string; value: string }>,
  data,
  user,
  params: {
    orientation: 'LANDSCAPE' | 'PORTRAIT';
    widths?: (string | number)[];
  } = { orientation: 'PORTRAIT' }
) => {
  const content: any = [{ text: title, style: 'header' }];

  const tableHeader = [];
  for (const field of fields) {
    tableHeader.push({ text: field.label, style: 'itemsTableHeader' });
  }

  const items = [];

  data.forEach(d => {
    const line = [];
    line.push(
      ...d.map(
        (v: { value: string; numberOfColumns: number; numberOfRows: number; alignment: string; style: string; fillColor: string }) => ({
          text: v.value,
          colSpan: v.numberOfColumns,
          rowSpan: v.numberOfRows,
          alignment: v.alignment,
          style: v.style,
          fillColor: v.fillColor
        })
      )
    );
    items.push(line);
  });

  content.push('\n');
  const tableDefinition: any = {
    style: 'itemsTable',
    table: {
      body: [tableHeader].concat(items)
    }
  };
  if (params.widths) {
    tableDefinition.table.widths = params.widths;
  }
  content.push(tableDefinition);

  const now = new Date();

  const localeDate = `${now.toLocaleDateString()} ás ${now.toLocaleTimeString()}`;

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const pdf = pdfMake.createPdf({
    content: content,
    footer: {
      text: `Gerado através do eCondos web, por ${user.fullName} em ${localeDate}.`,
      alignment: 'center'
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 5]
      },
      itemsTable: {
        margin: [0, 5, 0, 0]
      },
      itemBold: {
        bold: true
      },
      itemsTableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black'
      }
    },
    defaultStyle: {
      fontSize: 9
    },
    pageSize: 'A4',
    pageMargins: [20, 20, 20, 30]
  });
  if (params && params.orientation) {
    pdf.docDefinition.pageOrientation = params.orientation.toLowerCase();
  }
  pdf.download(`${title}.pdf`);
};

type ReportFromTableComponentOptions = {
  title: string;
  columns: Array<Pick<TableColumnDefinition, 'valueKey' | 'valueFn'> & { headerLabel: string }>;
  data: any[];
  user: User;
  pageOrientation?: 'landscape' | 'portrait';
};

export const generateReportFromTableComponent = ({ title, columns, data, user, pageOrientation }: ReportFromTableComponentOptions) => {
  const content: any = [{ text: title, style: 'header' }];

  const tableHeader = [{ text: '#', style: 'itemsTableHeader' }];

  for (const column of columns) {
    tableHeader.push({ text: column.headerLabel, style: 'itemsTableHeader' });
  }

  const items = [];

  data.forEach((d, index) => {
    const line = [{ text: index + 1, alignment: 'right' }];

    for (const column of columns) {
      const value = (column.valueFn ? column.valueFn(d, index) : d[column.valueKey]) || '-';
      line.push(value);
    }

    items.push(line);
  });

  content.push('\n');
  content.push({
    style: 'itemsTable',
    table: {
      body: [tableHeader].concat(items)
    },
    layout: {
      hLineWidth: (i, node) => {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        } else {
          return 1;
        }
      },
      vLineWidth: (i, node, other) => {
        return i === 0 || i === node.table.widths.length ? 0 : 0;
      },
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#333' : '#dddddd';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#333' : '#dddddd';
      }
    }
  });

  const now = new Date();

  const localeDate = `${now.toLocaleDateString()} ás ${now.toLocaleTimeString()}`;

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const pdf = pdfMake.createPdf({
    content: content,
    footer: {
      text: `Gerado através do eCondos web, por ${user.fullName} em ${localeDate}.`,
      alignment: 'center'
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 5]
      },
      itemsTable: {
        margin: [0, 5, 0, 0]
      },
      itemsTableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black'
      }
    },
    defaultStyle: {
      fontSize: 9
    },
    pageSize: 'A4',
    pageMargins: [20, 20, 20, 30]
  });
  if (pageOrientation) {
    pdf.docDefinition.pageOrientation = pageOrientation;
  } else {
    // Se não foi especificada uma orientação o sistema define de acordo com o número de colunas
    const orientation = columns?.length > 8 ? 'landscape' : 'portrait';
    pdf.docDefinition.pageOrientation = orientation;
  }
  pdf.download(`${title}.pdf`);
};

export const generateCsvFromTableComponent = ({ columns, data }: Pick<ReportFromTableComponentOptions, 'columns' | 'data'>) => {
  let csvContent = columns.map(column => column.headerLabel).join(';');

  csvContent += '\n';

  data.forEach((d, index) => {
    const line = [];

    for (const column of columns) {
      const value = column.valueFn ? column.valueFn(d, index) : d[column.valueKey];
      line.push(`"${value?.toString().replace(/"/g, '""')}"`);
    }

    csvContent += line.join(';') + '\n';
  });

  const encodedUri = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
  return encodedUri;
};
