import { HttpService } from '../../../services/http.service';
import { ConstantService } from '../../../services/constant.service';
import { defaultIfEmpty, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Device, DEVICE_STATUS } from '@api/model/hardware/device';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { ControlIdService } from '@api/service/hardware/control-id.service';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { IntelbrasIncontrolService } from './intelbras-incontrol.service';
import { UtechService } from '@api/service/hardware/utech.service';
import { downloadDataInChunks } from '@api/utils';
import { AlphaDigiService } from '@api/service/hardware/alphadigi.service';
import { IntelbrasStandAloneService } from '@api/service/hardware/intelbras-stand-alone.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { ZktecoService } from '@api/service/hardware/zkteco.service';
import { GarenDeviceResult, GarenService } from '@api/service/hardware/garen.service';
import { SdkService, UpsertDeviceResponse } from '@api/service/hardware/sdk.service';
import { ActuatorData } from '@api/model/hardware/actuator-data';
import { Actuator } from '@api/model/hardware/actuator';
import { Deleted } from '@api/model/deleted';

type DeletedDevice = Device & Deleted;

@Injectable({ providedIn: 'root' })
export class HardwareDeviceService {
  protected endPoint;
  protected endPointV2;

  constructor(
    protected http: HttpService,
    protected controlIdService: ControlIdService,
    protected hikvisionService: HikvisionService,
    protected intelbrasIncontrolService: IntelbrasIncontrolService,
    protected intelbrasService: IntelbrasStandAloneService,
    protected utechService: UtechService,
    protected constantService: ConstantService,
    protected alphaDigiService: AlphaDigiService,
    protected zktecoService: ZktecoService,
    protected garenService: GarenService,
    protected sdkService: SdkService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
    this.endPointV2 = `${this.constantService.getV2Endpoint()}condos/`;
  }

  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; devices: Device[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/devices`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            devices: res.body.map(d => new Device(d))
          };
        })
      );
  }

  getDeleted(condoId: string, params: EcondosQuery): Observable<{ count: number; devices: DeletedDevice[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/devices/deleted`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          devices: res.body as DeletedDevice[]
        }))
      );
  }

  /** @depracated Use {@link get} with { formerResident: true } params instead  */
  getNonResidentVisitorsDevices(condoId: string, params = {}): Observable<{ devices: Device[]; count: number }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/devices/non-resident-contacts-devices`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            devices: res.body.map(d => new Device(d)),
            count: res.headers.get('count')
          };
        })
      );
  }

  /** @depracated Use {@link get} with { formerResident: true } params instead  */
  getNonResidentUsersDevices(condoId: string, params = {}): Observable<{ devices: Device[]; count: number }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/devices/non-resident-users-devices`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            devices: res.body.map(d => new Device(d)),
            count: res.headers.get('count')
          };
        })
      );
  }

  getAllCondoDevices(condoId: string, query: EcondosQuery) {
    return downloadDataInChunks<Device>(this.http, `${this.endPoint}${condoId}/devices`, query, {
      model: Device,
      numberOfRequests: 3
    });
  }

  create(condoId: string, device) {
    return this.http.post(`${this.endPoint}${condoId}/devices`, device).pipe(map(res => ({ ...device, ...res })));
  }

  import(condoId: string, device) {
    return this.http
      .post(`${this.endPoint}${condoId}/devices/import`, device)
      .pipe(map((res: { _id: string }) => ({ ...device, _id: res._id })));
  }

  importIntelbras(condoId: string, device: Device) {
    device.status = DEVICE_STATUS.SYNCED;
    return this.http
      .post(`${this.endPoint}${condoId}/devices/importIntelbras`, device)
      .pipe(map((res: { _id: string }) => ({ ...device, _id: res._id })));
  }

  update(condoId: string, deviceId: string, device) {
    return this.http.put(`${this.endPoint}${condoId}/devices/${deviceId}`, device).pipe(
      // Backend não retorna o template, então pegamos do próprio objeto enviado para o backend
      map(dev => ({ ...dev, template: device.template }))
    );
  }

  sync(condoId: string, deviceId: string, device: { internalId: number } = null) {
    return this.http.put(`${this.endPoint}${condoId}/devices/${deviceId}/sync`, device);
  }

  restore(condoId: string, deviceId: string) {
    return this.http.put(`${this.endPoint}${condoId}/devices/${deviceId}/restore`, {});
  }

  delete(condoId: string, device: Device) {
    switch (device.hardware) {
      case 'ALPHADIGI_LPR':
      case 'NICE_CONTROLLER':
      case 'LINEAR': {
        return this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`);
      }
      case 'CONTROL_ID': {
        return this.getById(condoId, device._id, { $populate: ['actuators'] }).pipe(
          switchMap(dev =>
            from(this.controlIdService.deleteDevice(dev)).pipe(
              catchError(err => {
                if (err?.message?.includes('User not found')) {
                  return of(dev);
                } else {
                  return throwError(() => `${err.message}`);
                }
              })
            )
          ),
          switchMap(() => this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`))
        );
      }
      case 'HIKVISION': {
        return this.getById(condoId, device._id, { $populate: ['actuators'] }).pipe(
          switchMap(dev => {
            if (dev.actuators.length > 0) {
              return this.hikvisionService.delete(dev);
            } else {
              return of([]);
            }
          }),
          mergeMap((res: any) => {
            if (res.length && res.some(result => !result.ok)) {
              const actuatorNames = res
                .filter(result => !result.ok)
                .map(result => result.actuator && result.actuator.name)
                .join(', ');
              return throwError(`Não foi possível remover o cadastro dos seguintes dispositivos: ${actuatorNames}`);
            } else {
              return of(res);
            }
          }),
          switchMap(() => this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`))
        );
      }
      case 'INTELBRAS': {
        return this.getById(condoId, device._id).pipe(
          switchMap(dev => this.intelbrasIncontrolService.deleteDevice(dev)),
          switchMap(() => this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`))
        );
      }
      case 'INTELBRAS_STAND_ALONE': {
        const $populate = [{ path: 'accessGroups', select: 'actuators', populate: ['actuators'] }, 'actuators'];
        return this.getById(condoId, device._id, { $populate }).pipe(
          switchMap(dev => this.intelbrasService.deleteDevice(dev)),
          switchMap(response => {
            const errors = response.filter(r => !r.success);
            if (errors.length) {
              const actuatorNames = errors.map(result => result.actuator && result.actuator.name).join(', ');
              return throwError(`Não foi possível remover o cadastro dos seguintes dispositivos: ${actuatorNames}`);
            } else {
              return of(response);
            }
          }),
          switchMap(() => this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`))
        );
      }
      case 'UTECH': {
        return this.getById(condoId, device._id, { $populate: ['actuators'] }).pipe(
          switchMap(dev => {
            if (dev.actuators.length > 0) {
              return this.utechService.delete(dev);
            } else {
              return of(dev);
            }
          }),
          mergeMap((res: any) => {
            if (res.some(result => !result.ok)) {
              const actuatorNames = res
                .filter(result => !result.ok)
                .map(result => result.actuator && result.actuator.name)
                .join(', ');
              return throwError(`Não foi possível remover o cadastro dos seguintes dispositivos: ${actuatorNames}`);
            } else {
              return res;
            }
          }),
          switchMap(() => this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`))
        );
      }
      case 'ALPHADIGI':
      case 'GAREN': {
        return this.getById(condoId, device._id, {
          $populate: [
            'actuators',
            {
              path: 'accessGroups',
              populate: ['actuators']
            }
          ]
        }).pipe(
          switchMap(dev => {
            let actuators: Actuator[] = (dev.accessGroups?.map(ag => ag.actuators) || []).reduce((acc, curr) => acc.concat(...curr), []);

            if (!actuators?.length) {
              if (!dev.actuators?.length) {
                return of([{ ok: true, error: '' }]);
              }

              actuators = dev.actuators;
            }

            const requests = actuators.map(actuator => this.garenService.deleteDevice(actuator, dev));
            return forkJoin(...requests);
          }),
          switchMap((data: GarenDeviceResult[]) => {
            const hasEveryDelteRequestFailed = data?.every(res => !res.ok);

            if (hasEveryDelteRequestFailed) {
              const actuatorNames = data
                .filter(res => !res.ok)
                .map(res => res.actuator && res.actuator.name)
                .join(', ');

              return throwError(`Não foi possível remover o cadastro dos seguintes dispositivos: ${actuatorNames}`);
            }

            return this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`);
          })
        );
      }
      case HARDWARES.ZKTECO: {
        const query: EcondosQuery = {
          $select: 'serial type owner.userName sequenceId',
          $populate: [
            { path: 'owner.user', select: 'firstName lastName' },
            { path: 'owner.dependent', select: 'name firstName lastName' },
            { path: 'owner.condoContact', select: 'firstName lastName' },
            {
              path: 'accessGroups',
              populate: [
                { path: 'timezone', select: 'sequenceId' },
                { path: 'actuators', select: 'name type accessType host port password hardware user serial' }
              ]
            }
          ]
        };
        return this.getById(condoId, device._id, query).pipe(
          switchMap(dev => this.zktecoService.deleteDevice(condoId, dev)),
          switchMap(() => this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`))
        );
      }

      case HARDWARES.ANY: {
        return this.deleteAnyDevice(condoId, device);
      }
    }
  }

  deleteAnyDevice(condoId: string, device: Device) {
    const $populate = [{ path: 'accessGroups', select: 'actuators', populate: ['actuators'] }, 'actuators'];
    return this.getById(condoId, device._id, { $populate }).pipe(
      switchMap(populatedDevice => {
        let requests = [];
        requests = requests.concat(this.sdkService.deleteDevice(populatedDevice));
        if (requests.length) {
          return forkJoin(...requests);
        } else {
          return of([]);
        }
      }),
      switchMap((response: any) => {
        // TODO Remover o cast para any quando possível
        const errors = response.filter(r => !r.success) as any;
        if (errors.length) {
          // Loga os errors originais para podermos analisar problemas nos clientes
          console.log(errors);
          const actuatorNames = errors.map(result => result.actuator && result.actuator.name).join(', ');
          return throwError(`Não foi possível remover o cadastro dos seguintes dispositivos: ${actuatorNames}`);
        } else {
          return of(response);
        }
      }),
      switchMap(() => this.http.delete(`${this.endPoint}${condoId}/devices/${device._id}`))
    );
  }

  getById(condoId: string, deviceId: string, query: EcondosQuery = {}): Observable<Device> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get(`${this.endPoint}${condoId}/devices/${deviceId}`, { params }).pipe(map(dev => new Device(dev)));
  }

  getActuatorData(condoId: string, deviceId: string, query: EcondosQuery = {}): Observable<Array<ActuatorData>> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .get(`${this.endPointV2}${condoId}/devices/${deviceId}/actuator-data`, { params })
      .pipe(map((res: any) => res.map(a => new ActuatorData(a))));
  }

  getDeviceTemplate(condoId: string, deviceId: string): Observable<{ template: string }> {
    return this.http.get(`${this.endPoint}${condoId}/devices/${deviceId}/template`) as Observable<{ template: string }>;
  }

  updateExpiredDevices(condoId: string, ids: string[]) {
    return this.http.post(`${this.endPoint}${condoId}/devices/expire-devices`, { ids });
  }

  saveOnHardware(device: Device) {
    let observables: Observable<UpsertDeviceResponse>[] = [];
    observables = observables.concat(this.sdkService.upsertDevice(device));
    return observables;
  }

  removeFromHardware(condoId: string, device: Device, actuators: Actuator[]) {
    const $populate = [{ path: 'accessGroups', select: 'actuators', populate: ['actuators'] }, 'actuators'];
    return this.getById(condoId, device._id, { $populate }).pipe(
      switchMap(populatedDevice => {
        const allDeviceActuators: Actuator[] = [];
        for (const accessGroup of device.accessGroups) {
          allDeviceActuators.push(...accessGroup.actuators);
        }
        let actuatorsToDelete = actuators;
        if (allDeviceActuators.length) {
          actuatorsToDelete = actuatorsToDelete.filter(actuator => {
            const accessGroupActuator = allDeviceActuators.find(a => {
              const idOrObjectA = a._id || a;
              const idOrObjectB = actuator._id || actuator;
              return idOrObjectA === idOrObjectB;
            });
            return !accessGroupActuator;
          });
        }
        const requests = [].concat(this.sdkService.deleteDevice(populatedDevice, actuatorsToDelete));
        return forkJoin(...requests).pipe(defaultIfEmpty([]));
      })
    );
  }

  bulkUnsyncDevices(condoId: string, devices: string[]) {
    return this.http.put(`${this.endPoint}${condoId}/devices/bulk-unsync`, { devices });
  }

  bulkUnSyncRemoteCheckDevices(condoId: string) {
    return this.http.put(`${this.endPoint}${condoId}/devices/bulk-unsync-remote-check-devices`, {});
  }

  checkIrregularDevices(condoId: string) {
    return this.http.get(`${this.endPoint}${condoId}/devices/check-resident-irregular-devices`);
  }

  alphadigiLprMassSync(condoId: string, devices: string[], actuatorsToAdd?: string[], actuatorsToRemove?: string[]) {
    return this.http.put(`${this.endPoint}${condoId}/devices/sync-mass`, {
      devices,
      actuatorsToAdd,
      actuatorsToRemove
    });
  }
}
