import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ThemeService } from '../theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@api/model/user';

@Injectable({ providedIn: 'root' })
export class WhitelabelFilterCondoInterceptor implements HttpInterceptor {
  constructor(private themeService: ThemeService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && req.url.includes('users/me?') && this.themeService?.activeTheme?.name !== 'econdos') {
          const whiteLabelId = this.themeService?.activeTheme?._id;
          const user: User = event.body;
          const isSystemAdmin = (user && user.isSystemAdmin) || false;
          if (whiteLabelId && !isSystemAdmin) {
            user.condos = (user.condos || []).filter(condo => condo.whiteLabel === whiteLabelId);
            user.condosAdmin = (user.condosAdmin || []).filter(condo => condo.whiteLabel === whiteLabelId);
            user.condosOwner = (user.condosOwner || []).filter(condo => condo.whiteLabel === whiteLabelId);
            user.condosJanitor = (user.condosJanitor || []).filter(condo => condo.whiteLabel === whiteLabelId);
            user.condosRequester = (user.condosRequester || []).filter(condo => condo.whiteLabel === whiteLabelId);
            user.condosGatekeeper = (user.condosGatekeeper || []).filter(condo => condo.whiteLabel === whiteLabelId);
          }
        }
        return event;
      })
    );
  }
}
