import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { EcondosQuery } from '../model/query';
import { WhiteLabel } from '../model/white-label';

@Injectable({ providedIn: 'root' })
export class WhiteLabelService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}white-labels/`;
  }

  get(params: EcondosQuery = {}): Observable<{ count: number; whiteLabels: WhiteLabel[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(this.endPoint, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          whiteLabels: res.body as WhiteLabel[]
        }))
      );
  }
}
