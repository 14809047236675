import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'upload-button',
  template: ` <button
      type="button"
      #uploadButton
      (click)="input.click()"
      [disabled]="disabled"
      class="btn {{ btnClass }}"
      [ngStyle]="{ display: displayButton ? 'inherit' : 'none' }">
      <i *ngIf="showIcon && iconOnLeft" [class]="iconClass" aria-hidden="true" [ngStyle]="{ 'margin-right': btnClass ? 0 : '0.5rem' }"></i>
      <span>{{ buttonText }}</span>
      <i *ngIf="showIcon && !iconOnLeft" [class]="iconClass" aria-hidden="true" [ngStyle]="{ 'margin-left': btnClass ? 0 : '0.5rem' }"></i>
    </button>
    <input
      type="file"
      (click)="input.value = null"
      (change)="filesAdded($event)"
      style="display: none"
      [multiple]="multiple"
      #input
      [accept]="fileTypes" />`
})

/**
 * Upload button component.
 *
 * As native input elements with type file are diffcult to style, it is common
 * practice to hide them and trigger the needed events manually as it done here.
 * A button is is used for originalUser interaction, next to the hidden input.
 */
export class UploadButton {
  @ViewChild('uploadButton', { static: true }) uploadButton: ElementRef;

  @Input()
  public showIcon?: Boolean = true;

  @Input()
  public multiple?: Boolean = true;

  @Input()
  public btnClass? = 'btn-default';

  @Input()
  public disabled: Boolean;

  @Input()
  // .pdf, .doc, .docx, .xsl, .xlsx, .ppt, .pptx, .pps, .ppsx, .txt, image/*, audio/*, video/*
  public fileTypes?: String = '.pdf, .doc, .docx, .xsl, .xlsx, .ppt, .pptx, .pps, .ppsx, .txt, image/*, audio/*, video/*';

  @Input()
  public buttonText?: String = '';

  @Input()
  private iconClass?: String = 'fa fa-plus';

  @Input()
  public iconOnLeft?: Boolean = false;

  @Input()
  public displayButton? = true;

  /**
   * The callback executed when files are selected, set by parent
   */
  @Input()
  private btnCallback: Function;

  /**
   * Native upload button (hidden)
   */
  @ViewChild('input', { static: true })
  private nativeInputBtn: ElementRef;

  /**
   * Constructor
   */
  constructor() {}

  /**
   * Callback which is executed after files from native popup are selected.
   * @param  {Event}    event change event containing selected files
   */
  public filesAdded(event: Event): void {
    const files: FileList = this.nativeInputBtn.nativeElement.files;
    if (files && files.length) {
      this.btnCallback(files);
    }
  }

  public triggerClick() {
    const uploadBtn: HTMLElement = this.uploadButton.nativeElement as HTMLElement;
    uploadBtn.click();
  }
}
