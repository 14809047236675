import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { ChecklistTemplate } from '@api/model/checklistTemplate';
import { map } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';

@Injectable()
export class ChecklistTemplateService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  get(condoId: string, query: EcondosQuery = {}): Observable<ChecklistTemplate[]> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .get(`${this.endPoint}${condoId}/checklist-template`, { params })
      .pipe(map((templates: any[]) => templates as ChecklistTemplate[]));
  }

  create(condoId: string, data) {
    return this.http.post(`${this.endPoint}${condoId}/checklist-template`, data);
  }

  update(condoId: string, checklistId: string, data) {
    return this.http.put(`${this.endPoint}${condoId}/checklist-template/${checklistId}`, data);
  }

  delete(condoId: string, checklistId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/checklist-template/${checklistId}`);
  }
}
