import { Injectable } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import { Alphadigi } from '@econdos/econdos-alphadigi';
import { defer, EMPTY, forkJoin, from, merge, Observable, of } from 'rxjs';
import { catchError, expand, map, reduce, scan, tap, timeout } from 'rxjs/operators';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import { ConstantService } from '../../../services/constant.service';
import { HttpClient } from '@angular/common/http';

interface MonitorInfo {
  host: string;
  port: number;
  url: string;
  userName: string;
  password: string;
  timeout: number;
  enabled: boolean;
}

interface DeviceFacial {
  fullName: string;
  econdosDeviceId: string;
  residence: string;
}

@Injectable({ providedIn: 'root' })
export class AlphaDigiService {
  // Variável utilizada como Singleton para instâncias da classe AlphaDigi
  private alphaDigiActuators = {};

  errorsTranslate = {
    'Timeout has occurred': 'Tempo de conexão excedido',
    'aggregate error': 'Erro ao sincronizar',
    'Network Error': 'Erro de rede'
  };
  constructor(
    public http?: HttpClient,
    public constantService?: ConstantService
  ) {}

  public clearDeviceCache(actuator: Actuator): void {
    if (actuator._id) {
      delete this.alphaDigiActuators[actuator._id];
    } else {
      this.alphaDigiActuators = {};
    }
  }

  private getAlphaDigiActuators(actuators: Actuator | Actuator[]) {
    if (!Array.isArray(actuators)) {
      actuators = [actuators];
    }
    return actuators.map(a => this.getAlphaDigi(a));
  }

  private getAlphaDigi(actuator: Actuator) {
    if (!this.alphaDigiActuators[actuator._id]) {
      this.alphaDigiActuators[actuator._id] = new Alphadigi(actuator);
    }
    return this.alphaDigiActuators[actuator._id];
  }

  public readDateTime(actuator: Actuator): Observable<{ _id: string; status: boolean }> {
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.getDateTime()).pipe(
      map((res: any) => ({ _id: actuator._id, status: true })),
      catchError(() => of({ _id: actuator._id, status: false }))
    );
  }

  public setDatetime(
    actuator: Actuator,
    dateTime?: Date,
    TimeFormat?: '24hour' | '12hour',
    SyncNTPFlag?: 'Sync' | 'NoSync',
    DateTimeFormat?: 'YYYYMMDDWhhmmss' | 'YYYYMMDDhhmmss' | 'MMDDYYYYWhhmmss' | 'MMDDYYYYhhmmss' | 'DDMMYYYYWhhmmss' | 'DDMMYYYYhhmmss'
  ): Observable<{ success: boolean }> {
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.setDatetime(dateTime, TimeFormat, SyncNTPFlag, DateTimeFormat)).pipe(
      map(({ success }) => ({ success })),
      catchError(() => of({ success: false }))
    );
  }

  public getStatus(actuator: Actuator): Observable<number> {
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.getStatus()).pipe(map((res: any) => res.ID));
  }

  public getInfo(actuator: Actuator): Observable<any> {
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.getInfo());
  }

  public getMonitorInfo(actuator: Actuator): Observable<MonitorInfo> {
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.getMonitorInfo()) as Observable<MonitorInfo>;
  }

  public activateMonitor(actuator: Actuator): Observable<boolean> {
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.activateMonitor()).pipe(map((res: any) => res.success));
  }

  public addFacial(actuator: Actuator, device: any): Observable<{ _id: string; success: boolean; reason?: string }> {
    const alphaDigi = this.getAlphaDigi(actuator);
    const filePath =
      device.owner?.picture?.url ||
      device.owner?.picture?.thumbnail ||
      device.owner?.user?.picture?.url ||
      device.owner?.user?.picture?.thumbnail;
    let name = device.userLabel;
    if (!name) {
      if (device.owner?.user) {
        name =
          device.userLabel ||
          device.owner.user?.name ||
          `${device.owner.user?.firstName} ${device.owner.user?.lastName}` ||
          device.owner.user?._id ||
          device.owner.user;
      } else if (device.owner?.dependent) {
        name = device.owner.dependent?.name || device.owner.dependent?._id || device.owner.dependent;
      } else {
        name = device?.owner?.userName || '';
      }
    }
    const data = {
      filePath,
      name,
      residence: device?.owner?.residence?.identification || '',
      econdosDeviceId: device?._id,
      idToUpdate: false
    };
    return from(alphaDigi.addFacial(data)).pipe(
      timeout(10000),
      map((res: any) => ({ _id: device._id, success: res.success })),
      catchError(reason => of({ reason: reason.message || reason, _id: device._id, success: false }))
    );
  }

  addVisitorFacial(device: Device, actuators: Actuator[], options = { entranceDuration: 5 }) {
    if (!device.owner?.condoContact) {
      throw new Error('Visitante não encontrado');
    }
    actuators = actuators || device.actuators || [];
    const { firstName, lastName, _id: visitorId } = device.owner.condoContact;
    const filePath = device.owner?.picture?.url || device.owner?.condoContact?.picture?.url;
    const validUntil = device.validUntil;
    const data = {
      visitorId,
      filePath,
      validUntil,
      entranceDuration: options.entranceDuration,
      visitorName: `${firstName} ${lastName}`,
      visitorResidence: device?.owner?.residence?.identification || '',
      remoteCheck: device.remoteCheck || false
    };

    const observables = actuators.map(actuator =>
      defer(() => this.getAlphaDigi(actuator).saveVisitorFacial(data)).pipe(
        map(({ success }) => ({ actuator, success })),
        catchError(err => of({ actuator, success: false, reason: err.message || err }))
      )
    );

    return forkJoin(
      merge(...observables, 3).pipe(
        scan((acc, curr: any) => {
          acc = acc.concat({ ...curr });
          return acc;
        }, [])
      )
    ).pipe(map(([result]) => result));
  }

  public updateFacial(actuator: Actuator, device: any): Observable<{ _id: string; success: boolean; reason?: string }> {
    const alphaDigi = this.getAlphaDigi(actuator);
    const econdosDeviceId = device?._id;
    const filePath =
      device.owner?.picture?.url ||
      device.owner?.picture?.thumbnail ||
      device.owner?.user?.picture?.url ||
      device.owner?.user?.picture?.thumbnail;
    let name = device.userLabel;
    if (!name) {
      if (device.owner?.user) {
        name =
          device.userLabel ||
          device.owner.user?.name ||
          `${device.owner.user?.firstName} ${device.owner.user?.lastName}` ||
          device.owner.user?._id ||
          device.owner.user;
      } else if (device.owner?.dependent) {
        name = device.owner.dependent?.name || device.owner.dependent?._id || device.owner.dependent;
      } else {
        name = device?.owner?.userName || '';
      }
    }
    const data = {
      filePath,
      name,
      residence: device?.owner?.residence?.identification || device?.residenceLabel || ''
    };
    return from(alphaDigi.updateFacialByEcondosDeviceId(econdosDeviceId, data)).pipe(
      timeout(10000),
      map((res: any) => ({ _id: econdosDeviceId, success: res.success, reason: res.message || '' })),
      catchError(reason => of({ reason: reason.message || reason, _id: econdosDeviceId, success: false }))
    );
  }

  public updateFacialById(
    actuator: Actuator,
    device: Device,
    facialId: number
  ): Observable<{ actuator: Actuator; ok: boolean; error?: string }> {
    const alphaDigi = this.getAlphaDigi(actuator);
    const econdosDeviceId = device?._id;
    const filePath =
      device.owner?.picture?.url ||
      device.owner?.picture?.thumbnail ||
      device.owner?.user?.picture?.url ||
      device.owner?.user?.picture?.thumbnail;
    const data = {
      econdosDeviceId,
      filePath,
      name: device?.owner?.userName || device?.userLabel || '',
      residence: device?.owner?.residence?.identification || device?.residenceLabel || ''
    };
    return from(alphaDigi.updateFacial(facialId, data)).pipe(
      timeout(10000),
      map((res: any) => ({ actuator, ok: res.success })),
      catchError(reason => of({ actuator, ok: false, error: reason }))
    );
  }

  public deleteFacial(actuator: Actuator, device: Device): Observable<{ _id: string; success: boolean; reason?: string }> {
    const alphaDigi = this.getAlphaDigi(actuator);

    const isVisitor = !!device.owner?.condoContact;
    const econdosDeviceId = isVisitor ? device.owner?.condoContact?._id : device?._id;
    return from(alphaDigi.deleteFacialByEcondosDeviceId(econdosDeviceId)).pipe(
      timeout(10000),
      map((res: any) => ({ _id: econdosDeviceId, success: res.success })),
      catchError(reason => of({ _id: econdosDeviceId, success: false, reason: reason.message || reason }))
    );
  }

  public getFacial(actuator: Actuator, device: Device): Observable<DeviceFacial | null> {
    const alphaDigi = this.getAlphaDigi(actuator);
    const econdosDeviceId = device?._id;
    return from(alphaDigi.searchFacialByEcondosDeviceId(econdosDeviceId)).pipe(
      map((res: any) => ({ econdosDeviceId, fullName: res.Name, residence: res.Address })),
      catchError(() => of(null))
    );
  }

  public trigger(actuator: Actuator): Observable<boolean> {
    const data = {
      door: actuator.output
    };
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.openDoor(data)).pipe(
      map((res: any) => res.success),
      catchError(() => of(false))
    );
  }

  public getCameraStream(actuator: Actuator): Observable<string> {
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.getCameraStream()) as Observable<string>;
  }

  public createDevice(device: Device): Observable<{ device: Device; results: { actuator: Actuator; ok: boolean; error?: any }[] }> {
    const actuators = device.actuators;
    let actuatorsToAdd;
    switch (device.type) {
      case DEVICE_TYPES.FACIAL:
        actuatorsToAdd = actuators.map(actuator =>
          this.addFacial(actuator, device).pipe(
            map(res => ({ actuator, ok: res.success, error: this.errorsTranslate[res.reason] || res.reason }))
          )
        );
        break;
    }
    const requests = [].concat(actuatorsToAdd);
    return forkJoin(requests).pipe(map((results: any) => ({ device, results })));
  }

  public syncDevice(condoActuators: Actuator[], device: Device): Observable<{ actuator: Actuator; ok: boolean; error?: string }>[] {
    const actuators = device.actuators;
    const deviceActuatorKeys = actuators.reduce((keys, ac) => {
      keys[ac._id] = ac;
      return keys;
    }, {});
    let actuatorsToUpdate, actuatorsToRemove;
    switch (device.type) {
      case DEVICE_TYPES.FACIAL:
        actuatorsToUpdate = condoActuators
          .filter(condoAc => deviceActuatorKeys[condoAc._id])
          .map(actuator =>
            this.updateFacial(actuator, device).pipe(
              map(res => ({
                actuator,
                ok: res.success,
                error: this.errorsTranslate[res.reason] || res.reason
              }))
            )
          );
        actuatorsToRemove = condoActuators
          .filter(condoAc => !deviceActuatorKeys[condoAc._id])
          .map(actuator =>
            this.deleteFacial(actuator, device).pipe(
              map(res => ({
                actuator,
                ok: res.success,
                error: this.errorsTranslate[res.reason] || res.reason
              }))
            )
          );
        break;
    }
    const requests = [].concat(actuatorsToUpdate, actuatorsToRemove);
    return requests;
  }

  public updateDevice(
    condoActuators: Actuator[],
    device: Device
  ): Observable<{ device: Device; results: { actuator: Actuator; ok: boolean; error?: any }[] }> {
    const requests = this.syncDevice(condoActuators, device);
    return forkJoin(requests).pipe(map((results: any) => ({ device, results })));
  }

  public getFacials(actuators: Actuator[]) {
    const alphaDigiActuators = this.getAlphaDigiActuators(actuators);
    const requests = alphaDigiActuators.map(alphaDigi => alphaDigi.getFacialsByGroupName());
    return merge(...requests, 1).pipe(tap(res => console.log(res)));
  }

  public deleteFacials(actuators: Actuator[]) {
    const alphaDigiActuators = this.getAlphaDigiActuators(actuators);
    const requests = alphaDigiActuators.map(alphaDigi => alphaDigi.deleteFacialsByGroupName());
    return merge(...requests, 1);
  }

  public deleteExpiredFacials(device: Device, actuators: Actuator[]) {
    const observables = actuators.map(actuator => this.deleteFacial(actuator, device).pipe(map(response => ({ ...response, actuator }))));

    return merge(...observables, 1);
  }

  public sendCommandToLpr(
    command: string,
    lprSerial: string,
    data: any = {}
  ): Observable<{ serial: string; action: string; success: boolean; data: any; error?: string }> {
    const url = `${this.constantService.getEndpoint()}integrations/plate-recognizer/alphadigi/sendCommand/${lprSerial}`;
    const body = { command, data };
    return this.http.post(url, body) as Observable<{ serial: string; action: string; success: boolean; data: any }>;
  }

  private generateRandomLprResponse(limit = 5) {
    const generateRandomPlate = (length = 7) => {
      return [...Array(length)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join('')
        .toUpperCase();
    };
    const data = new Array(Math.floor(Math.random() * limit + 1)).fill('').map(() => ({ carnum: generateRandomPlate() }));
    return of({
      serial: 'xxx',
      action: 'whiteList',
      success: true,
      data
    });
  }

  public getPlatesFromLpr(lprSerial: string): Observable<string[]> {
    const command = 'getWhitelistPlates';
    const getPlates$ = (page = 0, skip = 0, limit = 1000) =>
      // this.generateRandomLprResponse(limit)
      this.sendCommandToLpr(command, lprSerial, { skip: limit * page, limit }).pipe(
        map(({ success, error, data = [] }) => {
          return data.map(d => ({ plate: d.carnum, lprSerial }));
        }),
        map(plates => {
          const next = plates?.length === limit;
          return { plates, next, page };
        })
      );
    return getPlates$().pipe(
      expand(({ next, page }) => {
        if (next) {
          return getPlates$(page + 1);
        }
        return EMPTY;
      }),
      reduce((acc, { plates = [] }) => acc.concat(plates), [])
    );
  }

  public reboot(actuator: Actuator): Observable<any> {
    const alphaDigi = this.getAlphaDigi(actuator);
    return from(alphaDigi.reboot()).pipe(
      map((res: any) => ({
        success: res.success
      })),
      catchError(() => of({ success: false }))
    );
  }
}
