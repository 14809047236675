import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { catchError, distinctUntilChanged, filter, map, mergeMap, retry, switchMap, tap, timeout } from 'rxjs/operators';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import { Device } from '@api/model/hardware/device';
import swal from 'sweetalert2';
import { EcondosQuery } from '@api/model/query';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { User } from '@api/model/user';
import { IntelbrasStandAloneService } from '@api/service/hardware/intelbras-stand-alone.service';
import { AccessGroupService } from '@api/service/access-group.service';
import * as moment from 'moment';
import { AccessGroup } from '@api/model/hardware/access-group';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { SelectAccessGroupActuatorsComponent } from '../../components/select-access-group-actuators/select-access-group-actuators.component';
import { orderBy } from 'lodash';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { ModalCreateVehicleComponent } from '../../components/modal-create-vehicle/modal-create-vehicle.component';
import { IResidenceUser, USER_TYPE } from '@api/model/create-device/residence-user';

@Component({
  selector: 'app-modal-create-intelbras-stand-alone-device',
  templateUrl: 'modal-create-intelbras-stand-alone-device.component.html',
  styleUrls: ['modal-create-intelbras-stand-alone-device.component.scss']
})
export class ModalCreateIntelbrasStandAloneDeviceComponent implements OnInit, OnDestroy {
  @ViewChild('selectAccessGroupActuatorsComponent', { static: true }) selectAccessGroupActuators: SelectAccessGroupActuatorsComponent;

  condo: Condo;
  condoAccessGroups: AccessGroup[] = [];

  protected readonly USER_TYPE = USER_TYPE;

  TYPES = [
    { label: 'Facial', value: 'FACIAL' }
    // TODO implementar cartão
    // {label: 'Cartão/Chaveiro', value: 'CARD'}
  ];

  status: Status = new Status();

  device: Device;
  callbacks: { success?: (arg) => void; error?: (err) => void };

  form: UntypedFormGroup;

  type: AbstractControl;
  residence: AbstractControl;
  user: AbstractControl;
  userName: AbstractControl;
  picture: AbstractControl;
  serial: AbstractControl = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
  obs: AbstractControl;
  validFromDate: AbstractControl;
  validFromTime: AbstractControl;
  validUntilDate: AbstractControl;
  validUntilTime: AbstractControl;
  accessGroups: UntypedFormArray;
  vehicle: AbstractControl;

  residenceSearch;
  residenceUsers: IResidenceUser[] = [];
  residenceVehicles: CondoVehicle[];
  loadingResidences = false;

  residenceTypeAheadDataSource$: Observable<any>;

  selectedResidence: any;

  private subscriptions: Subscription = new Subscription();

  formIsDisabled = false;

  storageUser: { user: User; residence: Residence };

  getAccessGroups$: Observable<AccessGroup[]>;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private intelbrasService: IntelbrasStandAloneService,
    private deviceService: HardwareDeviceService,
    private actuatorService: ActuatorService,
    private accessGroupService: AccessGroupService,
    private toastrService: ToastrService,
    private modalService: BsModalService
  ) {
    this.form = this.fb.group({
      type: ['FACIAL', Validators.required],
      user: [null],
      userName: [''],
      residence: [null, [Validators.required]],
      picture: [null, Validators.required],
      validFromDate: [moment().startOf('d').format('YYYY-MM-DD'), Validators.required],
      validFromTime: ['00:00', Validators.required],
      validUntilDate: ['', Validators.required],
      validUntilTime: ['17:00', Validators.required],
      obs: [''],
      accessGroups: this.fb.array([new UntypedFormControl('', Validators.required)], Validators.required),
      vehicle: ['']
    });
    this.type = this.form.get('type');
    this.user = this.form.get('user');
    this.userName = this.form.get('userName');
    this.residence = this.form.get('residence');
    this.picture = this.form.get('picture');
    this.validFromDate = this.form.get('validFromDate');
    this.validFromTime = this.form.get('validFromTime');
    this.validUntilDate = this.form.get('validUntilDate');
    this.validUntilTime = this.form.get('validUntilTime');
    this.obs = this.form.get('obs');
    this.accessGroups = this.form.get('accessGroups') as UntypedFormArray;
    this.vehicle = this.form.get('vehicle');

    this.subscriptions.add(
      this.user.valueChanges.subscribe(user => {
        if (user === 'NEW_USER') {
          swal({
            title: 'Inserir nome',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            inputPlaceholder: 'Digite o nome...',
            showLoaderOnConfirm: true,
            preConfirm: name => {
              if (!name || !name.trim().length) {
                return Promise.reject(`Insira o nome`);
              } else {
                name = name.trim();
                return Promise.resolve(name);
              }
            }
          }).then(
            name => {
              this.userName.setValue(name);
              this.user.setValue('', { emitEvent: false });
            },
            () => {
              this.userName.setValue('');
              this.user.setValue('', { emitEvent: false });
            }
          );
        } else if (user?.type === 'DEPENDENT') {
          this.userName.setValue(user.name);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          }
          this.user.setValue('', { emitEvent: false });
        } else if (this.checkHasDeviceOwnerPicture()) {
          this.picture.setValue(this.device.owner.picture);
        } else if (user) {
          user = this.residenceUsers.find(u => u._id === user._id);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          } else {
            this.picture.setValue(null);
          }
        } else {
          this.picture.setValue(null);
        }
      })
    );

    this.subscriptions.add(
      this.type.valueChanges.subscribe(type => {
        if (type === 'FACIAL') {
          this.form.removeControl('serial');
          this.picture.setValidators([Validators.required]);
        } else {
          this.form.addControl('serial', this.serial);
          this.picture.clearValidators();
        }
        this.form.updateValueAndValidity({ emitEvent: false });
      })
    );

    this.initializeTypeAheads();
  }

  initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type voter users',
          $populate: [
            {
              path: 'users',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            },
            {
              path: 'voter',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            }
          ]
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(e => {
            this.status.setAsError();
            return from([]);
          })
        );
      })
    );
  }

  ngOnInit() {
    if (this.device) {
      this.formIsDisabled = true;
      this.type.setValue(this.device.type);
      this.obs.setValue(this.device.obs);
      if (this.device.owner) {
        if (this.device.owner.residence && this.device.owner.residence._id) {
          this.residence.setValue(this.device.owner.residence);
          const vehicleId: any = this.device?.owner?.condoVehicle?._id || this.device?.owner?.condoVehicle || '';
          const userId: any = (this.device.owner && this.device.owner.user && this.device.owner.user._id) || '';
          this.initializeFieldsFromResidence(this.device.owner.residence, { userId, vehicleId });
        }

        if (this.device.owner.condoVehicle && this.device.owner.condoVehicle._id) {
          this.residenceVehicles = [this.device.owner.condoVehicle];
          this.vehicle.setValue(this.device.owner.condoVehicle._id);
        }

        if (this.device.owner.user && this.device.owner.user._id) {
          const user = {
            name: `${this.device.owner.user.firstName} ${this.device.owner.user.lastName}`,
            _id: this.device.owner.user._id,
            picture: this.device.owner.user.picture,
            type: USER_TYPE.USER
          };
          this.residenceUsers = [user];
          this.user.setValue(user);
        } else {
          this.user.setValue('');
          if (this.device.owner.userName) {
            this.userName.setValue(this.device.owner.userName);
          } else if (this.device.hardwareAttributes && this.device.hardwareAttributes.rotulo) {
            this.userName.setValue(this.device.hardwareAttributes.rotulo);
          }
        }
        if (this.device.owner.picture && this.device.owner.picture._id) {
          this.picture.setValue(this.device.owner.picture);
        }

        if (this.device.accessGroups?.length) {
          this.accessGroups.clear();
          this.device.accessGroups.forEach(({ _id: accessGroupId }) => {
            this.addAccessGroup(accessGroupId);
          });
        }

        if (this.device.validFrom) {
          const validFrom = moment(this.device.validFrom);
          this.validFromDate.setValue(validFrom.format('YYYY-MM-DD'));
          this.validFromTime.setValue(validFrom.format('HH:mm'));
        }

        if (this.device.validUntil) {
          const validUntil = moment(this.device.validUntil);
          this.validUntilDate.setValue(validUntil.format('YYYY-MM-DD'));
          this.validUntilTime.setValue(validUntil.format('HH:mm'));
        }
      }
    } else if (this.selectedResidence) {
      this.residence.setValue(this.selectedResidence);
      this.initializeFieldsFromResidence(this.selectedResidence);
    }

    if (this.storageUser) {
      if (this.storageUser.residence) {
        this.residence.setValue(this.storageUser.residence);
        if (!this.storageUser.user) {
          this.initializeFieldsFromResidence(this.storageUser.residence);
        }
        this.user.setValue('');
      }
      if (this.storageUser.user) {
        this.user.setValue(this.storageUser.user._id);
        this.residenceUsers = [
          {
            name: this.storageUser.user.fullName,
            type: USER_TYPE.USER,
            _id: this.storageUser.user._id,
            picture: this.storageUser.user.picture
          }
        ];
        this.user.setValue(this.residenceUsers[0]);
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residence.setValue(residences[0]);
          this.getResidenceVehicles(residences[0]._id);
        }
        if (this.storageUser.user.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
      }
    }

    this.getAccessGroups$ = this.accessGroupService.get(this.condo._id, { $populate: ['actuators', 'timezone'] }).pipe(
      map(({ accessGroups }) => accessGroups),
      tap(accessGroups => (this.condoAccessGroups = accessGroups)),
      timeout(10000),
      retry(3)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addAccessGroup(accessGroupId = ''): void {
    this.accessGroups.push(new UntypedFormControl(accessGroupId, Validators.required));
  }

  public removeAccessGroup(index: number): void {
    this.accessGroups.removeAt(index);
  }

  buildDevice() {
    const type = this.type.value;

    const device: any = {
      type,
      hardware: HARDWARES.INTELBRAS_STAND_ALONE,
      obs: this.obs.value
    };

    const accessGroups = [...new Set(this.accessGroups.value)];
    device.accessGroups = accessGroups;

    if (this.residence.value) {
      device.owner = {
        residence: this.residence.value._id || this.residence.value
      };
      if (this.user.value) {
        device.owner.user = this.user.value._id || this.user.value;
      } else if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value._id || this.picture.value;
      }
      if (this.vehicle.value) {
        device.owner.condoVehicle = this.vehicle.value._id || this.vehicle.value;
      }
    }
    const validFromString = `${this.validFromDate.value} ${this.validFromTime.value}`;
    const validFrom = moment(validFromString, 'YYYY-MM-DD HH:mm').toISOString();

    const validUntilString = `${this.validUntilDate.value} ${this.validUntilTime.value}`;
    const validUntil = moment(validUntilString, 'YYYY-MM-DD HH:mm').toISOString();

    device.validFrom = validFrom;
    device.validUntil = validUntil;

    return device;
  }

  save(value) {
    if (this.form.valid) {
      const device = this.buildDevice();
      if (this.device) {
        this.updateDevice(device);
      } else {
        this.createDevice(device);
      }
    } else {
      this.toastrService.warning('Preencha todos os campos e adicione a foto da pessoa');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }

  createDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .create(this.condo._id, device)
      .pipe(
        map(({ _id }) => {
          const dev = {
            ...device,
            _id,
            owner: {
              user: this.user.value || null,
              picture: this.picture.value || null,
              residence: this.residence.value || null,
              userName: this.userName.value || '',
              condoVehicle: this.vehicle.value || null
            }
          };
          const accessGroupKeys = (this.accessGroups.value || []).reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
          }, {});
          const accessGroups = this.condoAccessGroups.filter(ag => accessGroupKeys[ag._id]);
          dev.accessGroups = accessGroups;
          return dev;
        }),
        switchMap(savedDevice =>
          this.intelbrasService.createDevice(savedDevice).pipe(
            switchMap(response => {
              const errors = response.filter(r => !r.success);
              if (errors.length) {
                const actuatorNames = errors.map(result => result.actuator && result.actuator.name).join(', ');
                swal({
                  type: 'error',
                  title: 'Falha ao cadastrar',
                  text: `Não foi possível cadastrar nos seguintes equipamentos: ${actuatorNames}. Verifique a qualidade da imagem e se os equipamentos estão conectados`
                });
                this.status.setAsError();
                return of({ ...savedDevice, status: 'UNSYNCED' });
              } else {
                return this.deviceService.update(this.condo._id, savedDevice?._id, { status: 'SYNCED' }).pipe(
                  catchError(() => {
                    this.status.setAsError();
                    return of({ ...savedDevice, status: 'UNSYNCED' });
                  }),
                  map(() => ({ ...savedDevice, status: 'SYNCED' })),
                  tap(() => this.status.setAsSuccess())
                );
              }
            })
          )
        )
      )
      .subscribe(
        (dev: any) => {
          if (this.callbacks?.success) {
            this.callbacks.success(new Device(dev));
          }
          this.bsModalRef.hide();
        },
        () => {
          this.status.setAsError();
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível cadastrar este dispositivo.'
          });
        }
      );
  }

  updateDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .update(this.condo._id, this.device._id, device)
      .pipe(
        map(() => {
          const dev = {
            ...device,
            _id: this.device._id,
            accessType: this.device.accessType,
            owner: {
              user: this.user.value || null,
              picture: this.picture.value || null,
              residence: this.residence.value || null,
              userName: this.userName.value || '',
              condoVehicle: this.vehicle.value || null
            }
          };
          const accessGroupKeys = (this.accessGroups.value || []).reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
          }, {});
          const accessGroups = this.condoAccessGroups.filter(ag => accessGroupKeys[ag._id]);
          dev.accessGroups = accessGroups;
          return dev;
        }),
        switchMap(savedDevice =>
          this.actuatorService
            .getActuators(this.condo._id, { hardware: HARDWARES.INTELBRAS_STAND_ALONE })
            .pipe(map(({ actuators }) => ({ actuators, savedDevice })))
        ),
        switchMap(({ savedDevice, actuators }) => {
          const observables = this.intelbrasService.syncDevice(actuators, savedDevice);
          return forkJoin(observables).pipe(
            // Necessary to convert array of array to just an array
            map((res: any) => res.flat(1)),
            switchMap(data => {
              const errors = data.filter(r => !r.success);
              if (errors.length) {
                const actuatorNames = errors.map(result => result.actuator && result.actuator.name).join(', ');
                swal({
                  type: 'error',
                  title: 'Falha ao cadastrar',
                  text: `Não foi possível cadastrar nos seguintes equipamentos: ${actuatorNames}. Verifique se os equipamentos estão conectados e a qualidade da imagem`
                });
                this.status.setAsError();
                return this.deviceService.update(this.condo._id, this.device._id, { status: 'UNSYNCED' }).pipe(
                  catchError(() => {
                    this.status.setAsError();
                    return of({ ...savedDevice, status: 'UNSYNCED' });
                  }),
                  map(() => ({ ...savedDevice, status: 'UNSYNCED' })),
                  tap(() => this.status.setAsError())
                );
              } else {
                return this.deviceService.update(this.condo._id, this.device._id, { status: 'SYNCED' }).pipe(
                  catchError(() => {
                    this.status.setAsError();
                    return of({ ...savedDevice, status: 'UNSYNCED' });
                  }),
                  map(() => ({ ...savedDevice, status: 'SYNCED' })),
                  tap(() => this.status.setAsSuccess())
                );
              }
            })
          );
        })
      )
      .subscribe(
        (dev: any) => {
          if (this.callbacks?.success) {
            this.callbacks.success(new Device(dev));
          }
          this.bsModalRef.hide();
        },
        e => {
          console.log(e);
          this.status.setAsError();
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível editar este dispositivo.'
          });
        }
      );
  }

  checkHasDeviceOwnerPicture() {
    return this.device?.owner?.picture ? true : false;
  }

  async onResidenceSelect(event) {
    const residence: Residence = event.item;
    this.residence.setValue(residence);
    const users = this.getResidenceUsers(residence);
    this.residenceUsers = [...users];
    const dependents = await this.getResidenceDependents(this.condo, residence);
    this.residenceUsers.push(...dependents);
    this.residenceUsers = orderBy(this.residenceUsers, [u => u.name.toLowerCase()]);
    this.user.setValue('');
    this.getResidenceVehicles(residence._id);
  }

  getResidenceUsers(residence) {
    return (residence.residenceUsers || []).map(u => ({
      type: 'USER',
      _id: u._id,
      name: `${u.firstName} ${u.lastName}`,
      picture: u.picture
    }));
  }

  async getResidenceDependents(condo, residence) {
    const dependentsParams = [];
    dependentsParams.push({ '$populate[0][path]': 'picture' });
    dependentsParams.push({ '$populate[0][select]': 'url thumbnail type format name' });
    dependentsParams.push({ $sort: 'name' });
    return await this.residenceService
      .getDependents(condo._id, residence._id, dependentsParams)
      .pipe(
        map(res =>
          res.dependents.map(dep => ({
            type: USER_TYPE.DEPENDENT,
            _id: dep._id,
            picture: dep.picture,
            name: dep.name
          }))
        )
      )
      .toPromise();
  }

  clearResidence() {
    this.residenceSearch = '';
    this.residence.setValue(null);
    this.residenceUsers = [];
    this.user.setValue(null);
  }

  initializeFieldsFromResidence(residence, selectedValues: { vehicleId?: string; userId?: string } = {}) {
    const residenceQuery: EcondosQuery = {
      $populate: [
        {
          path: 'users',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        },
        {
          path: 'voter',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        }
      ]
    };
    forkJoin([
      this.residenceService.getResidenceByIdWithParams(this.condo._id, residence._id, residenceQuery),
      this.residenceService.getVehicles(this.condo._id, residence._id)
    ])
      .pipe(timeout(15000))
      .subscribe(
        async ([residenceResponse, vehiclesResponse]) => {
          this.residenceUsers = this.getResidenceUsers(residenceResponse);
          this.residenceVehicles = vehiclesResponse.vehicles;
          const dependents = await this.getResidenceDependents(this.condo, residence);
          this.residenceUsers.push(...dependents);

          if (selectedValues.vehicleId) {
            this.vehicle.setValue(selectedValues.vehicleId);
          }

          if (selectedValues.userId) {
            const user = this.residenceUsers.find(u => u._id === selectedValues.userId);
            this.user.setValue(user);
          } else {
            this.user.setValue('');
          }
        },
        async err => {
          console.log(err);
          await swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível obter os dados necessários, verifique sua conexão e tente novamente.'
          });
          this.bsModalRef.hide();
        }
      );
  }

  public toggleAll(): void {
    this.selectAccessGroupActuators.toggleAll();
  }

  private getResidenceVehicles(residenceId: string) {
    this.residenceService.getVehicles(this.condo._id, residenceId).subscribe({
      next: ({ vehicles }) => {
        this.residenceVehicles = vehicles;
      },
      error: err => {
        console.log(err);
      }
    });
  }

  createVehicle(condo: Condo, user: User = null, residence: Residence = null) {
    const initialState = {
      condo,
      onCreate: (vehicle: CondoVehicle) => {
        this.residenceVehicles = [...this.residenceVehicles, vehicle];
        this.vehicle.setValue(vehicle._id);
      },
      ...(residence && { vehicleResidence: residence }),
      ...(user && { vehicleUser: user })
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState });
  }
}
