import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DEPENDENT_ALLOW_VISITORS, Dependent } from '@api/model/dependent';
import { Status } from '@api/model/status';
import { CondoService } from '@api/service/condo.service';
import { DependentService } from '@api/service/dependent.service';
import { Residence } from '@api/model/interface/residence';
import { ResidenceService } from '@api/service/residence.service';
import * as moment from 'moment';
import { filter, timeout } from 'rxjs/operators';
import { UtilService } from '../../services/util.service';
import { formatPhone } from '@api/util/formatters';
import { EcondosQuery } from '@api/model/query';
import { cpfValidator, personBirthDateValidator } from '@api/util/validators';
import { File } from '@api/model/file';
import { User } from '@api/model/user';
import { SessionService } from '@api/service/session.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-modal-create-dependent',
  templateUrl: 'modal-create-dependent.html',
  styleUrls: ['./modal-create-dependent.scss']
})
export class ModalCreateDependent implements OnInit {
  dependentResidence: Residence;

  isAdmin = false;
  condo = this.sessionService.condoValue;
  dependent: Dependent;
  user: User;

  maxDate = new Date();
  status: Status = new Status();

  DEPENDENT_ALLOW_VISITORS = DEPENDENT_ALLOW_VISITORS;
  ALLOW_VISITORS_OPTIONS_LABELS: Record<Dependent['allowVisitors'], string> = {
    NO: 'Não',
    ENTRY_AND_EXIT: `Permitir a entrada e saída de ${this.condo?.customLabels?.visitor?.plural || 'visitantes'}`,
    ONLY_ENTRY: `Permitir apenas a entrada de ${this.condo?.customLabels?.visitor?.plural || 'visitantes'}`,
    ONLY_EXIT: `Permitir apenas a saída de ${this.condo?.customLabels?.visitor?.plural || 'visitantes'}`
  };

  form: UntypedFormGroup;

  name: FormControl<string>;
  kinship: FormControl<string>;
  birthDate: FormControl<string>;
  rg: FormControl<string>;
  cpf: FormControl<string>;
  picture: FormControl<File>;
  specialNeeds: FormControl<boolean>;
  specialNeedsDetails: FormControl<string>;
  allowVisitors: FormControl<Dependent['allowVisitors']>;
  phone: FormControl<string>;
  residence: FormControl<Residence>;
  room: FormControl<string>;

  editField = {
    cpf: false,
    rg: false,
    phone: false,
    birthDate: false
  };
  isBusinessCondo = false;
  useNewPermissions = false;
  onCreate: Function = () => {};

  onUpdate: Function = () => {};

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private sessionService: SessionService,
    private condoService: CondoService,
    private dependentService: DependentService,
    private residenceService: ResidenceService,
    private utilService: UtilService,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      kinship: [''],
      residence: [null, Validators.required],
      picture: [null],
      specialNeeds: [false],
      specialNeedsDetails: [''],
      allowVisitors: ['NO'],
      room: ['']
    });

    const emptyorValidCpfValidator = (c: FormControl<string>) => {
      const cpf = c.value;
      if (!cpf) {
        return null;
      }
      return cpfValidator(c);
    };

    this.name = this.form.get('name') as FormControl<string>;
    this.birthDate = new FormControl<string>('', [Validators.minLength(10), Validators.maxLength(10), personBirthDateValidator]);
    this.kinship = this.form.get('kinship') as FormControl<string>;
    this.room = this.form.get('room') as FormControl<string>;
    this.rg = new FormControl<string>('', []) as FormControl<string>;
    this.cpf = new FormControl<string>('', [
      Validators.compose([Validators.minLength(14), Validators.maxLength(14), emptyorValidCpfValidator])
    ]);
    this.phone = new FormControl<string>('', [
      Validators.compose([Validators.minLength(14), Validators.maxLength(15)])
    ]) as FormControl<string>;
    this.residence = this.form.get('residence') as FormControl<Residence>;
    this.picture = this.form.get('picture') as FormControl<File>;
    this.allowVisitors = this.form.get('allowVisitors') as FormControl<Dependent['allowVisitors']>;
    this.specialNeeds = this.form.get('specialNeeds') as FormControl<boolean>;
    this.specialNeedsDetails = this.form.get('specialNeedsDetails') as FormControl<string>;
    this.specialNeeds.valueChanges.pipe(filter(v => !v)).subscribe(() => this.specialNeedsDetails.setValue(''));
    this.user = this.sessionService.userValue;
    this.isAdmin = this.user.isAdmin() || this.user.isOwner();
  }

  ngOnInit() {
    if (this.dependent) {
      this.name.setValue(this.dependent.name);
      this.rg.setValue(this.dependent.rg);
      this.cpf.setValue(this.dependent.cpf);
      this.birthDate.setValue(this.dependent.birthDate ? moment(this.dependent.birthDate).utc().format('YYYY-MM-DD') : '');
      this.phone.setValue(this.dependent.phone ? this.utilService.formatPhone(this.dependent.phone) : '');
      this.residence.setValue(this.dependent.residence);
      this.picture.setValue(this.dependent.picture);
      this.kinship.setValue(this.dependent.kinship);
      this.room.setValue(this.dependent.room);
      this.specialNeeds.setValue(this.dependent.specialNeeds);
      this.specialNeedsDetails.setValue(this.dependent.specialNeedsDetails);
      this.allowVisitors.setValue(this.dependent.allowVisitors);
    } else {
      this.form.addControl('birthDate', this.birthDate);
      this.form.addControl('cpf', this.cpf);
      this.form.addControl('rg', this.rg);
      this.form.addControl('phone', this.phone);
      this.form.addControl('allowVisitors', this.allowVisitors);
      this.form.updateValueAndValidity();
    }

    if (this.dependentResidence) {
      this.residence.setValue(this.dependentResidence);
    }

    this.isBusinessCondo = this.condo?.isBusinessCondo();
  }

  formatPhone() {
    let phoneNumber = this.phone.value;
    phoneNumber = this.utilService.formatPhone(phoneNumber);
    this.phone.setValue(phoneNumber);
  }

  selectResidence(residence) {
    this.residence.setValue(residence);
  }

  submit() {
    // if (this.form.valid) {
    //   this.status.setAsProcessing();
    //   const dependent: any = {
    //     picture: this.picture.value && this.picture.value._id || null,
    //     name: this.name.value,
    //     birthDate: this.birthDate.value || '',
    //     kinship: this.kinship.value || '',
    //     room: this.room.value || '',
    //     rg: this.rg.value || '',
    //     cpf: this.cpf.value || '',
    //     phone: this.phone.value ? this.phone.value.toString().replace(/[^0-9]/g, '') : '',
    //     specialNeeds: this.specialNeeds.value,
    //     specialNeedsDetails: this.specialNeedsDetails.value,
    //     residence: this.residence.value._id || this.dependentResidence._id
    //   };

    //   if (this.dependent) {
    //     this.update(dependent);
    //   } else {
    //     this.create(dependent);
    //   }

    // } else {
    //   for (const key of Object.keys(this.form.controls)) {
    //     this.form.get(key).markAsTouched();
    //   }
    //   this.toastr.warning('Preencha os campos obrigatórios.');
    // }

    if (this.form.valid) {
      this.status.setAsProcessing();
      const dependent: any = {
        name: this.name.value,
        specialNeeds: this.specialNeeds.value,
        specialNeedsDetails: this.specialNeedsDetails.value,
        allowVisitors: this.allowVisitors.value,
        birthDate: this.birthDate.value || '',
        kinship: this.kinship.value || '',
        room: this.room.value || '',
        rg: this.rg.value || '',
        cpf: this.cpf.value || ''
      };
      if (this.picture.value) {
        dependent.picture = this.picture.value._id;
      }
      if (this.phone.value) {
        dependent.phone = this.phone.value.toString().replace(/[^*\d]/g, '');
      } else {
        dependent.phone = '';
      }
      if (this.residence.value) {
        dependent.residence = this.residence.value._id;
      }

      if (this.dependent) {
        this.update(dependent);
      } else {
        this.create(dependent);
      }
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha os campos obrigatórios.');
    }
  }

  create(dependent) {
    let subscription;

    if (this.useNewPermissions) {
      subscription = this.residenceService.addDependent(this.condo._id, this.residence.value._id, dependent);
    } else {
      subscription = this.dependentService.create(this.condo._id, dependent);
    }
    subscription.subscribe(
      (res: any) => {
        const dep = new Dependent({
          ...dependent,
          residence: this.residence.value,
          picture: this.picture.value,
          _id: res._id
        });
        this.onCreate(dep);
        this.bsModalRef.hide();
        this.toastr.success('Registro cadastrado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        if (err.originalError.error.includes('Only a voter or resident')) {
          swal({
            type: 'error',
            title: 'Não foi possível efetuar o cadastro',
            text: `Você não tem permissão para criar ${this.condo.customLabels.dependent.plural} em ${this.condo.customLabels.residence.plural} das quais não pertence, contate um administrador`
          });
        } else {
          this.toastr.error('Não foi possível efetuar o cadastro, tente novamente');
        }
      }
    );
  }

  update(dependent) {
    let subscription;

    if (this.useNewPermissions) {
      subscription = this.residenceService.updateDependent(this.condo._id, this.dependentResidence._id, this.dependent._id, dependent);
    } else {
      subscription = this.dependentService.update(this.condo._id, this.dependent._id, dependent);
    }

    subscription.subscribe(
      (res: any) => {
        const dep = new Dependent({
          ...this.dependent,
          ...dependent,
          residence: this.residence.value,
          picture: this.picture.value
        });
        this.onUpdate(dep);
        this.bsModalRef.hide();
        this.toastr.success('Registro atualizado com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível atualizar o registro, tente novamente');
      }
    );
  }

  enableFieldToEdit(field: string) {
    this.editField[field] = !this.editField[field];

    if (!this.editField[field]) {
      this.returnMaskedValue(field);
      return;
    }

    this.unmaskField(field);
    this.form.addControl(field, this[field]);

    this.form.updateValueAndValidity();
  }

  formatCPF(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let cpf = input.value;
    cpf = cpf.replace(/[^*\d]/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    input.value = cpf;
  }

  unmaskField(field: string) {
    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    const callback = ({ data }) => {
      if (data) {
        if (field === 'birthDate') {
          this.birthDate.setValue(data ? moment(data).utc().format('YYYY-MM-DD') : '');
        } else if (field === 'phone') {
          this.phone.setValue(data ? formatPhone(data) : '');
        } else {
          this[field].setValue(data);
        }
      }
    };

    this.dependentService
      .getDependentUnmaskedField(this.condo._id, this.dependent._id, field, query)
      .pipe(timeout(10000))
      .subscribe(callback);
  }

  returnMaskedValue(field: string) {
    if (field === 'birthDate' && this.dependent.birthDate) {
      this.birthDate.setValue(this.dependent.birthDate ? moment(this.dependent.birthDate).utc().format('YYYY-MM-DD') : '');
    } else if (field === 'phone' && this.dependent.phone) {
      this.phone.setValue(this.dependent.phone ? formatPhone(this.dependent.phone) : '');
    } else {
      if (this.dependent[field]) {
        this[field].setValue(this.dependent[field]);
      }
    }
    this.form.removeControl(field);
    this.form.updateValueAndValidity();
  }
}
