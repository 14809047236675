import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Condo } from '@api/model/condo';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@api/service/user.service';
import { CondoService } from '@api/service/condo.service';
import { filter, timeout } from 'rxjs/operators';
import { formatCpf, formatPhone } from '@api/util/formatters';
import { enDateValidator, fullNameValidator } from '@api/util/validators';
import swal from 'sweetalert2';
import { ResidenceService } from '@api/service/residence.service';
import { User } from '@api/model/user';
import { ContactID } from '@api/model/contact/contact.id';
import * as moment from 'moment';
import { MARITAL_STATUS_LABEL } from '@api/model/constants';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-modal-edit-user',
  templateUrl: 'modal-edit-user.component.html',
  styleUrls: ['modal-edit-user.component.scss']
})
export class ModalEditUserComponent implements OnInit, OnDestroy {
  STATUS = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
  };

  status;
  condo: Condo;

  form: UntypedFormGroup;
  picture: AbstractControl;
  email: AbstractControl;
  name: AbstractControl;
  birthDate: AbstractControl;
  cpf: AbstractControl;
  rg: AbstractControl;
  phone: AbstractControl;
  occupation: AbstractControl;
  maritalStatus: AbstractControl;
  panicWord: AbstractControl;

  // User to edit
  user;

  MARITAL_STATUS = MARITAL_STATUS_LABEL;

  callback: (arg) => {};

  private subscriptions: Subscription = new Subscription();

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private condoService: CondoService,
    private residenceService: ResidenceService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.initializeForm();
    if (this.user) {
      const userCondos = [...this.user.condos, ...this.user.condosAdmin, ...this.user.condosOwner, ...this.user.condosJanitor];
      if (this.user.condosOwner.length || userCondos.length > 1) {
        swal({
          type: 'warning',
          text:
            'Este usuário não pode ser editado porque está presente em mais de um(a) ' +
            this.condo?.customLabels?.condo?.singular +
            ' e usa o aplicativo. Entre em contato diretamente com o usuário ou com o suporte eCondos para editar este usuário'
        });
        this.modalService.hide(1);
      } else {
        this.setFormValuesFromUser(this.user);
      }
    } else {
      this.bsModalRef.hide();
      this.toastrService.error('Nenhum usuário encontrado para edição');
    }
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      picture: [null],
      email: [{ value: '', disabled: false }, Validators.compose([Validators.email])],
      name: ['', Validators.compose([Validators.required, fullNameValidator])],
      birthDate: ['', enDateValidator],
      cpf: ['', Validators.compose([Validators.minLength(14), Validators.maxLength(14)])],
      rg: [''],
      phone: ['', Validators.compose([Validators.minLength(14), Validators.maxLength(15)])],
      occupation: [''],
      maritalStatus: [''],
      panicWord: ['', Validators.required]
    });

    this.picture = this.form.get('picture');
    this.email = this.form.get('email');
    this.name = this.form.get('name');
    this.birthDate = this.form.get('birthDate');
    this.cpf = this.form.get('cpf');
    this.rg = this.form.get('rg');
    this.phone = this.form.get('phone');
    this.occupation = this.form.get('occupation');
    this.maritalStatus = this.form.get('maritalStatus');
    this.panicWord = this.form.get('panicWord');

    this.subscriptions.add(
      this.cpf.valueChanges.pipe(filter(v => v)).subscribe(v => {
        const formatted = formatCpf(v);
        this.cpf.setValue(formatted, { emitEvent: false });
      })
    );

    this.subscriptions.add(
      this.phone.valueChanges.pipe(filter(v => v)).subscribe(v => {
        const formatted = formatPhone(v);
        this.phone.setValue(formatted, { emitEvent: false });
      })
    );
  }

  setFormValuesFromUser(user) {
    this.email.setValue(user.email);
    this.name.setValue(user.firstName + ' ' + user.lastName);
    this.occupation.setValue(user.occupation || '');
    this.maritalStatus.setValue(user.maritalStatus || '');
    this.phone.setValue(user.phones[0] || '');
    if (user.birthDate) {
      const birthDate = moment(user.birthDate).format('YYYY-MM-DD');
      this.birthDate.setValue(birthDate);
    }
    const cpf = user.cpf;
    if (cpf) {
      this.cpf.setValue(cpf.number);
    }
    const rg = user.rg;
    if (rg) {
      this.rg.setValue(rg.number);
    }
    if (user.picture) {
      this.picture.setValue(user.picture);
    }
    if (user.panicWord) {
      this.panicWord.setValue(user.panicWord);
    }
  }

  submit(values) {
    if (this.form.valid) {
      if (values.birhDate && !values.birhDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
        delete values.birthDate;
      }
      if (values.cpf) {
        values.cpf = values.cpf.replace(/\D/g, ''); // Remove tudo o que não é dígito
      }
      if (values.phone) {
        values.phone = values.phone.replace(/\D/g, ''); // Remove tudo o que não é dígito
      }
      const names = values.name.split(' ').filter(v => v.trim());
      const firstName = names.shift();
      const lastName = names.join(' ');
      const data: any = {
        firstName,
        lastName,
        email: values.email,
        phones: (values.phone && [values.phone]) || null,
        birthDate: values.birthDate || '',
        panicWord: values.panicWord || '',
        picture: values.picture?._id || null,
        occupation: values.occupation || '',
        maritalStatus: values.maritalStatus || ''
      };
      data.ids = [];
      if (values.cpf) {
        data.ids.push({ type: ContactID.TYPES.CPF, number: values.cpf });
      }
      if (values.rg) {
        data.ids.push({ type: ContactID.TYPES.RG, number: values.rg });
      }

      this.status = this.STATUS.LOADING;

      this.subscriptions.add(
        this.userService
          .updateUser(this.user._id, data)
          .pipe(timeout(10000))
          .subscribe(
            (res: any) => {
              this.status = this.STATUS.SUCCESS;
              this.bsModalRef.hide();
              this.toastrService.success('Usuário atualizado com sucesso');
              if (this.callback) {
                this.callback(new User({ firstName: values.firstName, lastName: values.lastName, _id: res._id }));
              }
            },
            err => {
              console.log(err);
              this.status = this.STATUS.ERROR;
              if (err.originalErrorMessage && err.originalErrorMessage.includes('E11000 duplicate')) {
                swal({
                  type: 'error',
                  title: 'E-mail já cadastrado',
                  text: 'Este e-mail já está cadastrado em outro usuário do sistema.'
                });
              } else {
                swal({
                  type: 'error',
                  title: 'Ops...',
                  text:
                    'Não foi possível atualizar o ' +
                    (this.condo?.customLabels?.resident?.singular || 'condômino') +
                    ', verifique sua conexão e tente novamente'
                });
              }
            }
          )
      );
    } else {
      Object.keys(values).forEach(key => this.form.get(key).markAsTouched());
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  defaultSortFunction = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    return 0;
  };
}
