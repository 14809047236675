import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../../services/util.service';
import { Construction, CONSTRUCTION_STATUS_LABEL } from '@api/model/construction';
import { Status } from '@api/model/status';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalCreateConstructionComponent } from '../../../components/modal-create-construction/modal-create-construction.component';
import { EcondosQuery } from '@api/model/query';
import { EcondosFilter } from '@api/model/filter';
import { ConstructionService } from '@api/service/construction.service';
import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { ModalFilterComponent } from '../../../components/modal-filter/modal-filter.component';
import { capitalize } from '@api/util/util';

interface FilteredConstruction extends Construction {
  expanded?: boolean;
}

@Component({
  selector: 'app-condo-constructions',
  templateUrl: './condo-constructions.component.html',
  styleUrls: ['./condo-constructions.component.scss']
})
export class CondoConstructionsComponent implements OnInit {
  user: User;
  condo: Condo;

  numberOfActiveFilters = 0;
  filters: EcondosFilter[] = [];
  currentSortData: { field: string; order: 'asc' | 'desc' | '' } = { field: 'name', order: 'asc' };
  itemsPerPage = 15;
  p = 0;
  totalData = 0;

  constructions: Construction[] = [];
  filteredConstructions: FilteredConstruction[] = [];

  CONSTRUCTION_STATUS_LABEL = CONSTRUCTION_STATUS_LABEL;
  status = new Status();

  initialQuery: EcondosQuery = {
    $populate: [
      { path: 'attachments', select: 'url thumbnail type format name' },
      { path: 'technicalAttachments', select: 'url thumbnail type format name' },
      { path: 'residence', select: 'identification condo block identification number' },
      { path: 'createdBy', select: 'firstName lastName' }
    ]
  };

  customQuery = { ...this.initialQuery };

  constructor(
    private utilService: UtilService,
    private modalService: BsModalService,
    private constructionService: ConstructionService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();

    this.filters = [
      {
        element: 'residence-picker',
        label: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        name: 'residence',
        params: {
          condo: this.condo,
          showAsInputGroup: true,
          typeaheadHideResultsOnBlur: true,
          placeholder: 'Digite um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade',
          adaptivePosition: true,
          clearInputTextAfterSelect: false
        },
        searchType: 'match'
      },
      {
        element: 'input',
        elementType: 'text',
        name: 'local',
        label: 'Local',
        placeholder: 'Busque pelo local da obra',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'responsible',
        label: 'Responsável',
        placeholder: 'Busque pelo nome do responsável',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'number',
        name: 'duration',
        label: 'Duração estimada',
        placeholder: 'Busque pela duração estimada',
        searchType: 'match'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'status',
        selectOptions: [
          { label: 'Todas as situações', value: '' },
          { label: 'Aguardando autorização', value: 'PENDING_AUTHORIZATION' },
          { label: 'Autorizada', value: 'AUTHORIZED' },
          { label: 'Em execução', value: 'UNDER_EXECUTION' },
          { label: 'Concluída', value: 'CONCLUDED' },
          { label: 'Cancelada', value: 'CANCELED' }
        ],
        label: 'Situação da obra',
        searchType: 'match'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'noise',
        selectOptions: [
          { label: 'Todas as opções', value: '' },
          { label: 'Sim', value: 'true' },
          { label: 'Não', value: 'false' }
        ],
        label: 'Faz barulho',
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'date',
        name: 'startDate',
        label: 'Data inicial da previsão de início',
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'date',
        name: 'startDateEnd',
        label: 'Data final da previsão de início',
        searchType: 'match'
      }
    ];
  }

  ngOnInit(): void {
    this.getData();
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        const startDate = filters.find(filter => filter.name === 'startDate')?.value;
        const startDateEnd = filters.find(filter => filter.name === 'startDateEnd')?.value;
        delete query.startDateEnd;

        if (query.responsible) {
          query.$or = [
            {
              'responsible.company.name': query.responsible
            },
            {
              'responsible.technicalManager.name': query.responsible
            }
          ];
          delete query.responsible;
        }

        query.startDate = {
          ...(startDate && { $gte: moment(startDate).startOf('day').utc().toISOString() }),
          ...(startDateEnd && { $lte: moment(startDateEnd).endOf('day').utc().toISOString() })
        };

        this.countActiveFilters(filters);
        this.filters = filters;
        this.customQuery = query;

        this.getData();
      }
    };

    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  clearFilters() {
    this.filters = this.filters.map(filter => {
      return {
        ...filter,
        value: '',
        valueLabel: ''
      };
    });
    this.numberOfActiveFilters = 0;
    this.customQuery = { ...this.initialQuery };
    this.getData();
  }

  removeIndividualFilter({ index }) {
    this.filters[index].value = '';
    this.filters[index].valueLabel = '';

    if (['startDateEnd'].includes(this.filters[index].name)) {
      delete this.customQuery.startDate;
    } else {
      delete this.customQuery[this.filters[index].name];
    }

    if (['responsible'].includes(this.filters[index].name)) {
      delete this.customQuery.$or;
    }

    this.numberOfActiveFilters--;
    this.getData();
  }

  create() {
    const initialState = {
      condo: this.condo,
      user: this.user,
      onCreate: (construction: Construction) => {
        this.filteredConstructions = [].concat(construction, this.filteredConstructions);
        this.constructions = [].concat(construction, this.constructions);
      }
    };
    this.modalService.show(ModalCreateConstructionComponent, { initialState, class: 'modal-lg' });
  }

  edit(construction: Construction) {
    const initialState = {
      construction,
      condo: this.condo,
      onUpdate: (c: Construction) => {
        let index = this.filteredConstructions.findIndex(v => v._id === c._id);
        this.filteredConstructions[index] = c;
        this.filteredConstructions = [].concat(this.filteredConstructions);
        index = this.constructions.findIndex(v => v._id === c._id);
        this.constructions[index] = c;
        this.constructions = [].concat(this.constructions);
      }
    };
    this.modalService.show(ModalCreateConstructionComponent, { initialState, class: 'modal-lg' });
  }

  askToDelete(construction: Construction) {
    const onPreConfirm = () => {
      return this.constructionService
        .delete(this.condo._id, construction._id)
        .pipe(timeout(10000))
        .toPromise()
        .catch(() => {
          return Promise.reject('Não foi possível excluir a obra, tente novamente.');
        });
    };
    const onSuccess = () => {
      this.toastrService.success('Obra removida com sucesso');
      let index = this.constructions.findIndex(c => c._id === construction._id);
      this.constructions.splice(index, 1);
      this.constructions = [].concat(this.constructions);
      index = this.filteredConstructions.findIndex(c => c._id === construction._id);
      this.filteredConstructions.splice(index, 1);
      this.filteredConstructions = [].concat(this.filteredConstructions);
    };

    swal({
      type: 'warning',
      title: 'Tem certeza que deseja remover esta obras?',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      showCancelButton: true,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: onPreConfirm
    }).then(onSuccess, console.error);
  }

  getData(page = 0, sortBy?) {
    const query = this.customQuery;

    if (sortBy) {
      if (this.currentSortData.field === sortBy) {
        this.currentSortData.order === 'asc' ? (this.currentSortData.order = 'desc') : (this.currentSortData.order = 'asc');
      } else {
        this.currentSortData.field = sortBy;
        this.currentSortData.order = 'asc';
      }
    } else {
      this.currentSortData = { field: 'createdAt', order: 'desc' };
    }

    query.$page = page;
    query.$limit = this.itemsPerPage;
    query.$sort = `${this.currentSortData.order === 'desc' ? '-' : ''}${this.currentSortData.field}`;

    this.status.setAsDownloading();
    this.constructionService
      .get(this.condo._id, query)
      .pipe(timeout(20000))
      .subscribe(
        ({ count, constructions }) => {
          this.totalData = count;
          this.constructions = constructions;
          this.filteredConstructions = [].concat(...constructions);
          this.status.setAsSuccess();
        },
        () => {
          this.status.setAsError();
        }
      );
  }
  private countActiveFilters(filters: any) {
    this.numberOfActiveFilters = filters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
  }
}
