import { Component, OnInit, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { CondoContact } from '@api/model/contact/condo.contact';
import { Occurrence } from '@api/model/interface/occurrence';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { EcondosQuery } from '@api/model/query';
import { User } from '@api/model/user';
import { OccurrenceServiceV2 } from '@api/serviceV2/occurrence.service';
import { ModalNewCondoContactComponent } from 'app/pages/gate/access.control/new.condo.contact/new.condo.contact.modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { tap, timeout } from 'rxjs/operators';
import { VisitorPickerComponent } from '../visitor-picker/visitor-picker';
import { CondoService } from '@api/service/condo.service';
import { UtilService } from '../../services/util.service';
import { NavbarService } from '../../pages/navbar/navbar.service';
import { SessionService } from '@api/service/session.service';

type LoadingStatus = 'LOADING' | 'SUCCESS' | 'ERROR';

@Component({
  selector: 'app-modal-select-condo-contact',
  templateUrl: 'modal-select-condo-contact.component.html',
  styleUrls: ['modal-select-condo-contact.component.scss']
})
export class ModalSelectCondoContactComponent implements OnInit {
  // ----- start - initial state -----//
  public condo: Condo;
  public user: User;
  public selectedContacts: CondoContact[] = [];
  public finishCondoContactSelectionCallback: (selectedContacts: CondoContact[]) => void;
  // ----- end - initial state -----//

  @ViewChild(VisitorPickerComponent) visitorPicker: VisitorPickerComponent;

  public lastAuthorizedVisitorsLoadingStatus: LoadingStatus = null;
  public lastAuthorizedVisitors: CondoContact[] = [];
  public selectedLastAuthorizedVisitors: CondoContact[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    public toastrService: ToastrService,
    public occurrenceService: OccurrenceServiceV2,
    public modalService: BsModalService,
    private condoService: CondoService,
    private utilService: UtilService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.getLastAuthorizedVisitors();
  }

  getLastAuthorizedVisitors(): void {
    this.lastAuthorizedVisitorsLoadingStatus = 'LOADING';

    const lastAuthorizedVisitorsQuery: EcondosQuery = {
      $select: 'condoContacts type',
      $populate: [
        {
          path: 'condoContacts',
          select: 'firstName lastName ids phones company service type createdAt updatedAt banned birthDate obs',
          populate: [
            { path: 'picture', select: 'url thumbnail' },
            {
              path: 'condoVehicles',
              select: 'plate model type residence parkingSpot createdBy'
            }
          ],
          match: {
            deleted: false
          }
        }
      ],
      $sort: '-createdAt',
      $limit: 20,
      type: Occurrence.GATE_TYPE,
      subType: GateOccurrence.ALLOW_ACCESS_TYPE,
      createdBy: this.user._id
    };

    const condoId = this.condo._id;

    const successCallback = (response: any) => {
      const occurrences = response.occurrences as GateOccurrence[];

      const lastAuthorizedVisitors = occurrences.reduce((accumulator, currentValue) => {
        const condoContacts = currentValue.condoContacts;

        for (const contact of condoContacts) {
          const contactIndex = accumulator.findIndex(visitor => visitor._id === contact._id);

          if (contactIndex === -1 && accumulator.length < 15) {
            accumulator.push(new CondoContact(contact));
          }
        }

        return accumulator;
      }, [] as CondoContact[]);

      this.lastAuthorizedVisitors = lastAuthorizedVisitors;

      this.lastAuthorizedVisitorsLoadingStatus = 'SUCCESS';
    };

    const errorCallback = (error: any) => {
      console.log('Error', error);

      this.lastAuthorizedVisitorsLoadingStatus = 'ERROR';
    };

    this.occurrenceService
      .getOccurrences(condoId, lastAuthorizedVisitorsQuery)
      .pipe(timeout(10000))
      .subscribe(successCallback, errorCallback);
  }

  selectLastAuthorizedVisitor(contact: CondoContact): void {
    this.selectedLastAuthorizedVisitors.push(contact);

    const updatedLastAuthorizedVisitors = this.lastAuthorizedVisitors.filter(visitor => visitor._id !== contact._id);

    this.lastAuthorizedVisitors = updatedLastAuthorizedVisitors;
  }

  onContactSelect(selectedContact: CondoContact): void {
    const isSelectedContactAlreadyInTheList = this.selectedContacts.some(contact => contact._id === selectedContact._id);

    if (selectedContact && !isSelectedContactAlreadyInTheList) {
      this.selectedContacts.push(selectedContact);
    } else {
      this.toastrService.warning('Essa pessoa já está na lista');
    }

    const isSelectedContactInLastAuthorizedList = this.lastAuthorizedVisitors.some(visitor => visitor._id === selectedContact._id);

    if (isSelectedContactInLastAuthorizedList) {
      this.selectLastAuthorizedVisitor(selectedContact);
    }
  }

  createContact(nameOrId): void {
    const newContactInfo = { nameOrId };

    const initialState = {
      condo: this.condo,
      newContactInfo,
      callback: (contact: CondoContact) => {
        this.onContactSelect(contact);
        this.visitorPicker.resetValue();
      }
    };

    const modalOptions = {
      initialState,
      class: 'modal-lg modal-xl',
      ignoreBackdropClick: true
    };

    const isGatekeeperOrAdmin = this.user.isGatekeeper() || this.user.isAdmin() || this.user.isOwner();
    if (!isGatekeeperOrAdmin) {
      this.condoService
        .getCondoById(this.condo._id, '?$select=generalParams')
        .pipe(
          tap(condo => {
            const localCondo = this.utilService.getLocalCondo();
            if (this.condo._id === localCondo._id) {
              localCondo.generalParams.accessLiberation = condo.generalParams.accessLiberation;
              this.utilService.saveLocalCondo(localCondo);
              this.condo = localCondo;
            }

            const localUser = this.utilService.getLocalUser();
            localUser.defaultCondo.generalParams.accessLiberation = condo.generalParams.accessLiberation;
            this.sessionService.setUser(localUser);
            this.user = localUser;
          })
        )
        .subscribe(
          () => {
            this.modalService.show(ModalNewCondoContactComponent, modalOptions);
          },
          () => {
            this.toastrService.error('Não foi possível carregar os dados necessários para criar um novo contato. Tente novamente.');
          }
        );
    } else {
      this.modalService.show(ModalNewCondoContactComponent, modalOptions);
    }
  }

  removeSelectedContact(contactToRemove: CondoContact): void {
    const updatedContacts = this.selectedContacts.filter(contact => contact._id !== contactToRemove._id);
    this.selectedContacts = updatedContacts;

    this.checkIfBelongsToLastAuthorizedVisitorsListAndReturnIt(contactToRemove);
  }

  checkIfBelongsToLastAuthorizedVisitorsListAndReturnIt(contact: CondoContact): void {
    const index = this.selectedLastAuthorizedVisitors.findIndex(visitor => visitor._id === contact._id);

    if (index > -1) {
      this.lastAuthorizedVisitors.push(this.selectedLastAuthorizedVisitors[index]);
    }
  }

  finishSelection(selectedContacts: CondoContact[]): void {
    this.finishCondoContactSelectionCallback(selectedContacts);
    this.bsModalRef.hide();
  }
}
