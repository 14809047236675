import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GateResidenceDetailsModalComponent } from './gate-residence-details.modal.component';
import { ThemeModule } from '../../theme';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipe/pipes.module';
import { MomentModule } from 'ngx-moment';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { GateResidenceDetailsAutorizedVisitorsComponent } from './gate-residence-details-authorized-visitors/gate-residence-details-autorized-visitors.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { GateResidenceDetailsDependentsComponent } from './gate-residence-details-dependents/gate-residence-details-dependents.component';
import { GateResidenceDetailsResidentsComponent } from './gate-residence-details-residents/gate-residence-details-residents.component';
import { GateResidenceDetailsVehiclesComponent } from './gate-residence-details-vehicles/gate-residence-details-vehicles.component';
import { GateResidenceDetailsDocumentsComponent } from './gate-residence-details-documents/gate-residence-details-documents.component';
import { RouterLink } from '@angular/router';
import { MessageListComponent } from '../../message-list/message-list.component';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ModalModule,
    ComponentsModule,
    ThemeModule,
    PipesModule,
    TooltipModule,
    MomentModule,
    PopoverModule,
    TabsModule,
    NgOptimizedImage,
    RouterLink,
    MessageListComponent,
    DirectivesModule
  ],
  declarations: [
    GateResidenceDetailsModalComponent,
    GateResidenceDetailsAutorizedVisitorsComponent,
    GateResidenceDetailsDependentsComponent,
    GateResidenceDetailsResidentsComponent,
    GateResidenceDetailsVehiclesComponent,
    GateResidenceDetailsDocumentsComponent
  ]
})
export class GateResidenceDetailsModalModule {}
