import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Status } from '@api/model/status';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { CondoService } from '@api/service/condo.service';
import { timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

interface IFormValue {
  residentCanViewVoter: boolean;
  voterCanAddResidenceUser: boolean;
}

@Component({
  selector: 'app-modal-residences-config',
  templateUrl: './modal-residences-config.component.html',
  styleUrls: ['./modal-residences-config.component.scss']
})
export class ModalResidencesConfigComponent implements OnInit {
  condo: Condo;
  status: Status = new Status();
  form: UntypedFormGroup;
  callbacks: { success?: (condo: Condo) => void; error?: () => void };
  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private condoService: CondoService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      residentCanViewVoter: [true, Validators.required],
      voterCanAddResidenceUser: [true, Validators.required]
    });
    if (this.condo.generalParams.residences) {
      const { residentCanViewVoter, voterCanAddResidenceUser } = this.condo.generalParams.residences;
      this.residentCanViewVoter.setValue(residentCanViewVoter ?? true);
      this.voterCanAddResidenceUser.setValue(voterCanAddResidenceUser ?? true);
    }
  }

  get residentCanViewVoter() {
    return this.form.get('residentCanViewVoter') as UntypedFormControl;
  }

  get voterCanAddResidenceUser() {
    return this.form.get('voterCanAddResidenceUser') as UntypedFormControl;
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  save(formValue: IFormValue) {
    const generalParams = { ...this.condo.generalParams, residences: formValue };
    if (formValue) {
      this.status.setAsProcessing();
      this.condoService
        .updateCondo(this.condo._id, { generalParams })
        .pipe(timeout(10000))
        .subscribe(
          () => {
            this.status.setAsSuccess();
            this.toastrService.success('Configurações salvas com sucesso!');
            const updatedCondo = new Condo({
              ...this.condo.createBackObject(),
              generalParams: { ...this.condo.generalParams, ...generalParams }
            });
            this.callbacks.success(updatedCondo);
            this.bsModalRef.hide();
          },
          () => {
            this.status.setAsError();
            this.toastrService.error('Não foi possível processar sua solicitação. Tente novamente!');
          }
        );
    }
  }

  toggleField(fieldName: string) {
    const formControl = this.form.get(fieldName);
    if (formControl.value) {
      formControl.setValue(false);
    } else {
      formControl.setValue(true);
    }
  }
}
