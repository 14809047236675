import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EcondosQuery } from '@api/model/query';
import { User } from '@api/model/user';
import { InControl } from '@econdos/econdos-intelbras';
import { ConstantService } from 'app/services/constant.service';
import { HttpService } from 'app/services/http.service';
import { BehaviorSubject, from, Observable, of, Subject, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  retry,
  switchMap,
  takeUntil,
  tap,
  timeout
} from 'rxjs/operators';
import * as qs from 'qs';
import * as moment from 'moment';
import { Device } from '@api/model/hardware/device';
import { Actuator } from '@api/model/hardware/actuator';
import { GroupType, TimeZone } from '@api/model/hardware/intelbras-interface';
import { Condo } from '@api/model/condo';
import { formatCpf } from '@api/util/formatters';
import { SessionService } from '@api/service/session.service';

@Injectable({ providedIn: 'root' })
export class IntelbrasIncontrolService {
  inControl: InControl;
  endpoint = '';

  connected$ = new BehaviorSubject(false);

  private checkInControlConnection$;
  private unsubscribe$: Subject<void>;

  constructor(
    private http: HttpService,
    private sessionService: SessionService,
    private constantService: ConstantService
  ) {
    this.endpoint = `${this.constantService.getV2Endpoint()}condos/`;
    this.sessionService.user$
      .pipe(
        // Check if user or user defaultCondo has changed
        distinctUntilChanged(
          (previous, curr) =>
            curr &&
            previous &&
            curr._id === previous._id &&
            curr.defaultCondo &&
            previous.defaultCondo &&
            curr.defaultCondo._id === previous.defaultCondo._id
        )
      )
      .subscribe(user => {
        if (user) {
          const hasAccess =
            (user.isOwner() || user.isAdmin() || user.isGatekeeper()) && user.defaultCondo && user.defaultCondo.isIntelbrasEnabled();
          if (hasAccess) {
            this.initialize(user.defaultCondo);
          } else {
            this.disconnect();
          }
        }
      });
  }

  initialize(condo: Condo) {
    this.unsubscribe$ = new Subject();
    this.checkInControlConnection$ = timer(1000, 300000)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() =>
          this.inControl
            ? this.checkConnection()
            : from(this.buildInControl(condo)).pipe(
                retry(3),
                tap(inControl => (this.inControl = inControl)),
                switchMap(inControl => this.checkConnection())
              )
        )
      )
      .subscribe(res => {
        if (res) {
          this.connected$.next(true);
        } else {
          this.connected$.next(false);
        }
      });
  }

  disconnect() {
    if (this.unsubscribe$) {
      this.unsubscribe$.next(null);
      this.unsubscribe$.complete();
    }
    this.checkInControlConnection$ = null;
    this.inControl = null;
    this.connected$.next(false);
  }

  async buildInControl(condo) {
    const inControlParams = {
      host: condo.intelbras?.host || 'localhost',
      port: condo.intelbras?.port || 4441,
      username: condo.intelbras?.username || 'admin',
      password: condo.intelbras?.password || 'admin',
      useHttps: condo.intelbras?.useHttps || false
    };
    if (condo.intelbras.host2 !== '') {
      inControlParams['host2'] = condo.intelbras?.host2 || 'localhost';
    }
    if (condo.intelbras.port2) {
      inControlParams['port2'] = condo.intelbras?.port2 || 4441;
    }
    return await new InControl(inControlParams);
  }

  checkConnection() {
    return from(this.inControl.getSystemInformation()).pipe(
      timeout(15000),
      catchError(() => of(false))
    );
  }

  async getUsers(query = null) {
    return await this.inControl.listUsers(query);
  }

  async updateUser(user) {
    return await this.inControl.saveUser(user);
  }

  async getVisitors() {
    return await this.inControl.getVisitors();
  }

  async getDevices() {
    return await this.inControl.listCredentials({ nivel: 'N' });
  }

  async getActuators() {
    return await this.inControl.getDevices();
  }

  async listDepartments() {
    return await this.inControl.getDepartments();
  }

  async getVisitorDevices() {
    return await this.inControl.listCredentials({ nivel: 'V' });
  }

  async listTimesZones() {
    return await this.inControl.listTimeZones();
  }

  async listGroupTypes() {
    return await this.inControl.listGroupTypes();
  }

  async createIncontrolUser(user) {
    return await this.inControl.saveUser(user);
  }

  async getIncontrolUserById(id: number) {
    return await this.inControl.getUserById(id);
  }

  getCondoDevices(condoId: string, params: EcondosQuery): Observable<{ count: number; devices: Device[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endpoint}${condoId}/devices`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        devices: res.body
      }))
    );
  }

  getUsersToSync(condoId: string): Observable<{ count: number; users: User[] }> {
    const options = {
      headers: new HttpHeaders(),
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endpoint}${condoId}/intelbras/usersToSync`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        users: res.body
      }))
    );
  }

  getUserSynced(condoId: string, pessoaId: number): Observable<User> {
    return this.http.get(`${this.endpoint}${condoId}/intelbras/getSynced/${pessoaId}`).pipe(
      map(res => {
        if (res) {
          return new User(res);
        } else {
          return null;
        }
      })
    );
  }

  async saveDevice(device, user) {
    switch (device.type) {
      case 'CARD': {
        return this.inControl.saveCard(user, device.serial, device.hardwareAttributes.incontrolId, false, device.cardSize);
      }
      case 'TA': {
        return this.inControl.saveTag(user, device.serial, device.hardwareAttributes.incontrolId, false, device.cardSize);
      }
      case 'SN': {
        return this.inControl.savePassword(user, device.serial, device.hardwareAttributes.incontrolId);
      }
      case 'BM': {
        return this.inControl.saveFingerprint(user, device, device.incontrolId);
      }
      case 'FACIAL': {
        return this.saveUser(user, device.owner.residence);
      }
    }
  }

  async getGroups() {
    return this.inControl.listAccessGroups();
  }

  getGroupById(id) {
    return this.inControl.getGroupById(id);
  }

  deleteDevice(device) {
    switch (device.type) {
      case 'CARD': {
        return this.inControl.removeCardByValue(device.serial);
      }
      case 'TA': {
        return this.inControl.removeTagByValue(device.serial);
      }
      case 'SN': {
        return this.inControl.removeCardByValue(device.serial);
      }
      case 'BM': {
        const id = device.hardwareAttributes?.incontrolId || device.internalIds?.[0];
        if (!id) {
          throw new Error('ID Incontrol não encontrado');
        }
        return this.inControl.deleteCredencial({ id });
      }
      case 'FACIAL': {
        return this.inControl.removeUserPhoto(device.owner.user?._id || device.owner.dependent);
      }
    }
  }

  async getUser(user) {
    let res = await this.inControl.listUsers({ campos_personalizados__campo_5: user?._id });
    if (!res.data?.length) {
      res = await this.inControl.listUsers({ pessoa__nome_completo: `${user.firstName} ${user.lastName}` });
    }
    if (!res.data?.length) {
      res = await this.inControl.listUsers({ pessoa__nome_completo: user.firstName });
    }
    return res.data;
  }

  async saveVisit(user, condo = null, group = null) {
    const expireTime = condo.hardwareParams.visitorsFacialFromGatekeeperDefaultTime;

    const visit = {
      data_inicial_validade: moment().toDate().getTime(),
      data_final_validade: moment().add(expireTime, 'minutes').toDate().getTime(),
      visitante: {
        id: user.externalId || null,
        name: user.fullName || `${user.firstName} ${user.lastName}`,
        filePath: user.picture?.url || null,
        veiculo: user.condoVehicle?.length ? user.condoVehicle[0] : null,
        group: group.intelbrasGroupId || null,
        rg: user.ids.find(e => e.type === 'RG')?.number || null,
        cpf: formatCpf(user.ids.find(e => e.type === 'CPF')?.number) || null
      },
      ativa: true
    };

    return await this.inControl.saveVisit(visit);
  }

  async deactivateVisit(device) {
    const visitId = device.hardwareAttributes.incontrolId;
    return await this.inControl.deactivateVisitById(visitId);
  }

  async saveUser(user, residence = null, group = null) {
    const id = user.id || '';
    user = {
      id,
      name: `${user.firstName} ${user.lastName}`,
      group,
      departamento: residence?.block || '',
      local_especifico: residence?.number || '',
      econdosId: user._id,
      filePath: user.picture?.url || null,
      type: user.type,
      estado: user.estado || false,
      data_demissao: moment(user.validUntil).toDate().getTime(),
      data_contratacao: new Date().getTime()
    };
    return await this.inControl.saveUser(user);
  }

  async createGroup(name) {
    return await this.inControl.createGroup({ nome_grupo: name });
  }

  changeConfig(condoId: string, config: { host?: string; port?: number; password?: string; enabled?: boolean }) {}

  createActuator(actuator: Actuator) {
    const inControlDevice = {
      nome: actuator.name,
      modelo_dispositivo: actuator.type,
      senha: actuator.password,
      ip: actuator.host,
      porta: actuator.port || ''
    };
    return from(this.inControl.createDevice(inControlDevice)).pipe(map((res: any) => res.data || res));
  }

  updateActuator(clientId, actuator: Actuator) {
    const inControlDevice = {
      nome: actuator.name,
      modelo_dispositivo: actuator.type,
      senha: actuator.password,
      ip: actuator.host,
      porta: actuator.port || ''
    };
    return from(this.inControl.updateDevice(clientId, inControlDevice)).pipe(map((res: any) => res.data || res));
  }

  removeActuator(clientId) {
    return from(this.inControl.removeDevice(clientId));
  }

  trigger(clientId, porta = 1) {
    return from(this.inControl.openDoor(clientId, porta));
  }

  async saveIntelbrasAccessGroup(
    accessGroup: { id?: number; name: string; timeZone: TimeZone; type: GroupType; actuators: Actuator[] },
    sync: boolean = false
  ) {
    const actuators = [];
    if (!sync) {
      for (const point of accessGroup.actuators) {
        await this.inControl.getAccessPointsByDeviceId(point.clientId).then(res => {
          actuators.push(...res);
        });
      }
    }
    const grupo_pontos_acesso = {
      pontos_acesso: actuators,
      nome_grupo: accessGroup.name,
      tipo_grupo: accessGroup.type,
      zona_tempo: accessGroup.timeZone
    };
    if (accessGroup.id) {
      grupo_pontos_acesso['id'] = accessGroup.id;
      return await this.inControl.updateGroup(grupo_pontos_acesso);
    } else {
      return await this.inControl.createGroup(grupo_pontos_acesso);
    }
  }

  async updateIntelbrasAccesGroup(grupo_pontos_acesso: {
    id: number;
    nome_grupo: string;
    zona_tempo: TimeZone;
    pontos_acesso: [{ id: number; clientId: number }];
    tipo_grupo: GroupType;
  }) {
    const actuators = [];
    for (const point of grupo_pontos_acesso.pontos_acesso) {
      const res = await this.inControl.getAccessPointsByDeviceId(point.clientId);
      if (res && res[0]) {
        actuators.push(res[0]);
      }
    }
    const data = {
      ...grupo_pontos_acesso,
      pontos_acesso: actuators
    };
    return await this.inControl.updateGroup(data);
  }

  async removeAccesGroupById(id: number) {
    return await this.inControl.removeGroupById(id);
  }

  getAccessPoints() {
    return from(this.inControl.getAccessPoints());
  }

  async removeUserPhoto(user) {
    return await this.inControl.removeUserPhoto(user);
  }

  testActuatorConnection(actuator) {
    const { clientId, _id } = actuator;
    let observable;
    if (this.inControl) {
      observable = from(this.inControl.testDeviceConnection(clientId));
    } else {
      // Serve para aguardar a conexão ser inicializada
      observable = this.connected$.pipe(
        filter(v => !!v),
        switchMap(v => from(this.inControl.testDeviceConnection(clientId)))
      );
    }
    return observable.pipe(
      timeout(10000),
      map(() => ({ _id, status: true })),
      catchError(() => of({ _id, status: false }))
    );
  }

  async getFingerprint(params) {
    return await this.inControl.getFingerprint(params);
  }

  async getTemplate(data) {
    return await this.inControl.getTemplate(data);
  }

  async expirePreviousVisit(id) {
    return await this.inControl.expirePreviousVisit({ visitante: { id } });
  }

  async getPhoto(id) {
    return await this.inControl.getPhoto(id);
  }

  get connected() {
    return this.connected$.getValue();
  }
}
