import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AuthService } from './services/auth.service';
import { CommentService } from '@api/service/comment.service';
import { CondoService } from '@api/service/condo.service';
import { FileService } from '@api/service/file.service';
import { HttpService } from './services/http.service';
import { NotificationService } from '@api/service/notification.service';
import { OccurrenceService } from '@api/service/occurrence.service';
import { ResidenceService } from '@api/service/residence.service';
import { ReservationService } from '@api/service/reservation.service';
import { UserService } from '@api/service/user.service';
import { SupplierService } from '@api/service/supplier.service';
import { UtilService } from './services/util.service';
// Pages
import { AppComponent } from './app.component';
import { LoginPageComponent } from './pages/login/login';
import { FeedPage } from './pages/feed/feed';
import { ReservateLocalPageComponent } from './pages/reservation/reservate.local';
import { ModalCreateReservationComponent } from './pages/reservation/modal/create.reservation.modal';
import { ParamsService } from '@api/service/params.service';
import { ModalConfirmComponent } from './pages/modal/confirm.modal';
import { UserPage } from './pages/user/user';
import { ModalCommentComponent } from './pages/modal/comment.modal';
import { ModalResidencePickerComponent } from './pages/modal/residence.picker/apartment.picker.modal/apartment.picker.modal';
import { ModalCloseAllowAccessComponent } from './pages/gate/modal/modal.close.allowaccess';
import { ModalCloseGenericComponent } from './pages/gate/modal/modal.close.generic';
import { ConstantService } from './services/constant.service';
import { ModalCloseDeliveryComponent } from './pages/gate/modal/modal.close.delivery';
import { ReportService } from '@api/service/report.service';
import { LoggedGuard } from './guards/logged.guard';
import { SignupGuard } from './guards/signup.guard';
import { ModalCloseNoiseComponent } from './pages/gate/modal/modal.close.noise';
import { ReservationHistoryComponent } from './pages/reservation/history/reservation.history';
import { routing } from './routes/app.routes';
import { NotFoundPage } from './pages/error/not.found';
import { HasAdminAccessGuard } from './guards/is.admin.or.owner.guard';
import { HasGatekeeperAccessGuard } from './guards/is.gatekeeper.guard';
import { CondoPageComponent } from './pages/condo/condo';
import { CondoResidencesComponent } from './pages/condo/residences/condo.residences';
import { CondoResidentsComponent } from './pages/condo/residents/condo.residents';
import { SignUpPage } from './pages/signup/signup';
import { SignUpStepOnePage } from './pages/signup/stepone/signup.stepone';
import { SignUpStepTwoPage } from './pages/signup/steptwo/signup.steptwo';
import { CondoConfigComponent } from './pages/condo/config/condo.config';
import { NavbarComponent } from './pages/navbar/navbar';
import { AdviceCreatorComponent } from './pages/occurrence.creator/advice/advice.occurrence.creator';
import { PublicCreatorComponent } from './pages/occurrence.creator/public/public.occurrence.creator';
import { PrivateCreatorComponent } from './pages/occurrence.creator/private/private.occurrence.creator';
import { CondosAutocompleteComponent } from './components/condos.autocomplete/condos.autocomplete';
import { VotingCreatorComponent } from './pages/occurrence.creator/voting/voting.occurrence.creator';

import { CreateCondoModal } from './pages/modal/create.condo.modal';
import { ResidencesPage } from './pages/residences/residences';
import { ResetPasswordComponent } from './pages/reset.password/reset.password';
import { UserActivation } from './pages/user.activation/user.activation';
import { CondoListComponent } from './pages/condo/condos/condo.list';
import { NotificationPanelComponent } from './components/notification.panel/notification.panel';
import { ModalCreateApartmentsComponent } from './pages/modal/create.apartments.modal';
import { HasNotGatekeeperAccessGuard } from './guards/is.not.gatekeeper.guard';
import { AccessControlComponent } from './pages/gate/access.control/access.control';
import { ModalChangePasswordComponent } from './pages/user/change.password/change.password.modal';
import { CondoPickerModalComponent } from './pages/modal/condo-picker-modal.component';
import { CondoVisitorsComponent } from './pages/gate/visitors/condo.visitors';
import { CondoKeysComponent } from './pages/gate/keys/condo-keys';
import { VehicleCreateModal } from './pages/modal/vehicle.create.modal/vehicle.create.modal';
import { CondoContactPickerModal } from './pages/modal/condo.contact.picker.modal/condo.contact.picker.modal';
import { CreateUserContactModalComponent } from './pages/modal/create.user.contact.modal/create-user-contact-modal.component';
import { GatePage } from './pages/gate/gate';
import { ModalNewCondoContactComponent } from './pages/gate/access.control/new.condo.contact/new.condo.contact.modal';
import { CondoContactService } from '@api/service/condo.contact.service';
import { UserContactService } from '@api/service/user.contact.service';
import { AccessService } from '@api/service/access.service';
import { ModalHousePickerComponent } from './pages/modal/residence.picker/house.picker.modal/house.picker.modal';
import { ResidencePickerComponent } from './pages/modal/residence.picker/residence.picker/residence.picker.component';
import { ResidentsAutocompleteComponent } from './pages/occurrence.creator/private/resident.autocomplete';
import { ModalResidenceAccessLogComponent } from './pages/modal/residence.access.log.modal/residence.access.log.modal';
import { CondoVehiclesComponent } from './pages/condo/vehicles/condo.vehicles';
import { VehicleService } from '@api/service/vehicle.service';
import { VehiclePickModal } from './pages/modal/vehicle.pick.modal/vehicle.pick.modal';
import { CreateLocalPageComponent } from './pages/reservation/create.local';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CondoSuppliersComponent } from './pages/condo/suppliers/condo.suppliers';
import { CreateIdComponent } from './components/create.id/create.id';
import { ChipIdComponent } from './components/chip.id/chip.id';
import { ModalNewCondoSupplierComponent } from './pages/condo/suppliers/new.condo.supplier/new.condo.supplier.modal';
import { ModalQRCodeComponent } from './pages/modal/qrcode/qrcode.modal';
import { AngularDraggableModule } from 'angular2-draggable';
import { ModalReservationDetailComponent } from './pages/reservation/history/reservation.detail.modal/reservation.detail.modal';
import { MomentModule } from 'ngx-moment';

import { FacebookService } from 'ngx-facebook';

import { CustomParamService } from '@api/service/custom.param.service';
import { PersonChipComponnent } from './components/chip.person/chip.person';
import { SalePage } from './pages/sale/sale';

import {
  AdFeatureGuard,
  DocumentsFeatureGuard,
  FinanceFeatureGuard,
  GateFeatureGuard,
  HardwareFeatureGuard,
  ReservationFeatureGuard
} from './guards/feature.guard';
import { AdminCondoPickerModal } from 'app/pages/login/admin.condo.picker.modal/admin.condo.picker.modal';
import { CondoPlansComponent } from './pages/condo/plans/condo.plans';
import { HasSystemAdminAccessGuard } from './guards/is.systemAdmin.guard';
import { SubscriptionPage } from './pages/sale/subscription/subscription';
import { CouponService } from '@api/service/coupons.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgSlimScrollModule } from 'ngx-slimscroll';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import localePtBr from '@angular/common/locales/pt';
import {
  AccessControlModuleGuard,
  AccessLiberationModuleGuard,
  AssetManagementModuleGuard,
  ClassificadosModuleGuard,
  CondoFaqModuleGuard,
  CondoRatingModuleGuard,
  DeliveriesModuleGuard,
  DocumentsModuleGuard,
  KeysModuleGuard,
  LostAndFoundsModuleGuard,
  ReservationsModuleGuard,
  VotingModuleGuard
} from './guards/activated-module.guard';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@environment';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './reducers';
import { StoreModule } from '@ngrx/store';
import { CustomSerializer } from './shared/utils';
import { ComponentsModule } from './components/components.module';
import { registerLocaleData } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { GateDeliveryListPage } from './pages/gate/gate.delivery.list/gate.delivery.list';
import { CreateGateDeliveryPage } from './pages/gate/create.gate.delivery/create.gate.delivery';
import { GatePermissionsListPage } from './pages/gate/gate.permissions.list/gate.permissions.list';
import { CreateReservationComponent } from './pages/reservation/create-reservation/create.reservation';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ModalLocalDescriptionComponent } from './pages/reservation/local.description.modal/local.description.modal';
import { ModalLocalTermsComponent } from './pages/reservation/local.terms.modal/local.terms.modal';
import { ModalCondoReservationConfigComponent } from './pages/reservation/global-reservation-config/condo-reservation-config.modal';
import { ReservationChecklistModalComponent } from './pages/reservation/reservation-checklist-modal/reservation-checklist-modal.component';
import { SideMenuComponent } from './pages/side-menu/side-menu.component';
import { AdviceCardComponent } from './pages/feed/advice-card/advice-card.component';
import { FeedCommentComponent } from './pages/feed/feed-comment/feed-comment.component';
import { FeedLikersComponent } from './pages/feed/feed-likers/feed-likers.component';
import { PrivateCardComponent } from './pages/feed/private-card/private-card.component';
import { VotingCardComponent } from './pages/feed/voting-card/voting-card.component';
import { KeyService } from '@api/service/key.service';
import { PipesModule } from './pipe/pipes.module';
import { KeyCardComponent } from './pages/gate/keys/key-card/key-card.component';
import { GateCardComponent } from './pages/feed/gate-card/gate-card.component';
import { NoCacheInterceptor } from './interceptors/no-cache.interceptor';
import { ModalSignature } from './pages/modal/signature/modal-signature';
import { FeedFilterComponent } from './pages/feed/feed-filter-component/feed-filter-component';
import { ModalVotingDetailsComponent } from './pages/modal/modal-voting-details/modal-voting-details.component';
import { NgChartsModule } from 'ng2-charts';
import { CondoDependentsComponent } from './pages/condo/dependents/condo-dependents';
import { DependentService } from '@api/service/dependent.service';
import { ModalCreateDependent } from './components/modal-create-dependent/modal-create-dependent';
import { PetService } from '@api/service/pet.service';
import { CondoPetsComponent } from './pages/condo/pets/condo-pets';
import { CondoIntegrationsComponent } from './pages/condo/integrations/condo-integrations.component';
import { ModalCreatePet } from './components/modal-create-pet/modal-create-pet';
import { ModalCreateVehicleComponent } from './components/modal-create-vehicle/modal-create-vehicle.component';
import { HardwareSocketService } from './services/hardware-socket.service';
import { LostAndFoundService } from '@api/service/lost-and-found.service';
import { ModalAddActuatorModule } from './hardware/modal-add-actuator/modal-add-actuator.module';
import { ModalCreateActuatorsRouteModule } from './hardware/modal-create-actuators-route/modal-create-actuators-route.module';
import { ModalBorrowKeyModule } from './pages/gate/keys/modal-borrow-key/modal-borrow-key.module';
import { ModalCreateKeyModule } from './pages/gate/keys/modal-create-key/modal-create-key.module';
import { ModalKeyHistoryModule } from './pages/gate/keys/modal-key-history/modal-key-history.module';
import { BlankPage } from './pages/blank/blank';
import { GateNotebookService } from '@api/service/gate-notebook.service';
import { ModalGateDeliveryConfigModule } from './pages/gate/modal-gate-delivery-config/modal-gate-delivery-config.module';
import { defineWhitelabel, ThemeModule, ThemeService } from './theme';
import { WhiteLabelInterceptor } from './interceptors/whitelabel.interceptor';
import { ModalNotificationManagerModule } from './pages/modal/notification.manager.modal/modal-notification-manager.module';
import { GateResidenceDetailsModalModule } from './pages/gate-residence-details.modal/gate-residence-details.modal.module';
import { GateResidentDetailsModalModule } from './pages/gate/gate-resident-details.modal/gate-resident-details.modal.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalGenerateQrcodeFromGatekeeperModule } from './pages/gate/modal/modal-generate-qrcode-from-gatekeeper/modal-generate-qrcode-from-gatekeeper.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ModalEditCommentModule } from './pages/feed/modal-edit-comment/modal-edit-comment.module';
import { ModalAddHardwareDeviceModule } from './hardware/modal-add-hardware-device/modal-add-hardware-device.module';
import { ModalGatePermissionsConfigModule } from './pages/gate/modal-gate-permissions-config/modal-gate-permissions-config.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { HardwareReaderService } from '@api/service/hardware-reader.service';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { QRCodeModule } from 'angularx-qrcode';
import { ModalPendingCondoApprovalModule } from './pages/signup/modal-pending-condo-approval/modal-pending-condo-approval.module';
import { AutosizeModule } from 'ngx-autosize';
import { ModalCreateUserStepsModule } from './components/modal-create-user-steps/modal-create-user-steps.module';
import { ModalResidentPickerModule } from './pages/modal/modal-resident-picker/modal-resident-picker.module';
import { ModalResidenceConfigModule } from './pages/condo/residences/modal-residence-config/modal-residence-config.module';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { ModalSelectIntelbrasIncontrolAccessPointModule } from './hardware/modal-select-intelbras-incontrol-access-point/modal-select-intelbras-incontrol-access-point.module';
import { ModalImportIntelbrasIncontrolVisitorsModule } from './pages/gate/visitors/modal-import-intelbras-incontrol-visitors/modal-import-intelbras-incontrol-visitors.module';
import { CreateResidenceModalModule } from './pages/modal/create-residence.modal/create-residence-modal.module';
import { ModalTerminatedContractsReportModule } from './pages/condo/residences/modal-terminated-contracts-report/modal-terminated-contracts-report.module';
import { ModalAccessControlConfigModule } from './pages/gate/modal-access-control-config/modal-access-control-config.module';
import { CondoFilesService } from '@api/serviceV2/condo.files.service';
import { CondoParkingSpotComponent } from './pages/condo/parking/condo-parking-spot';
import { IntercomPanelModule } from './intercom-panel/intercom-panel.module';
import { CallService } from './services/call.service';
import { AuthErrorInterceptor } from './interceptors/auth-error.interceptor';
import { CondoGroupsComponent } from './pages/condo/groups/condo-groups';
import { ModalCreateGroupComponent } from './components/modal-create-group/modal-create-group';
import { GroupService } from '@api/serviceV2/group.service';
import { GateChatModule } from './gate-chat/gate-chat.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MergeContactsComponent } from './pages/gate/merge-contacts/merge-contacts.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { WhitelabelFilterCondoInterceptor } from './interceptors/whitelabelFilterCondoInterceptor';
import { ModalResidenceHardwareEventsLogComponent } from './components/modal-residence-hardware-events-log/modal-residence-hardware-events-log';
import { FeaturesPageComponent } from './pages/features/features';
import { MoreOptionsPageComponent } from './pages/more-options/more-options';
import { DirectivesModule } from './directives/directives.module';
import { IsSmartLockerEnabledGuard } from './guards/is-smart-locker-enabled.guard';
import { IntercomComponent } from './pages/intercom/intercom';
import { ModalCreateExtensionComponent } from './components/modal-create-extension/modal-create-extension';
import { ModalCreateSipAccountComponent } from './components/modal-create-sip-account/modal-create-sip-account';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookieInterceptor } from './interceptors/cookie.interceptor';
import { CondoConstructionsComponent } from './pages/condo/condo-constructions/condo-constructions.component';
import { ModalCreateConstructionComponent } from './components/modal-create-construction/modal-create-construction.component';
import { ChecklistTemplateService } from '@api/service/checklistTemplate.service';
import { ModalConfigDeliveryTrackingModule } from './pages/gate/modal-config-delivery-tracking/modal-config-delivery-tracking.module';
import { ModalDeliveryTrackingHistoryModule } from './pages/gate/modal-delivery-tracking-history/modal-delivery-tracking-history.module';
import { IsGatekeeperOrJanitorGuard } from './guards/is.gatekeeper.or.janitor.guard';
import { ModalResidencesConfigComponent } from './pages/condo/residences/modal-residences-config/modal-residences-config.component';
import { NoCountInterceptor } from './interceptors/no-count.interceptor';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { CreateHardwareModalsModule } from './modals/create-hardware-modals/create-hardware-modals.module';
import { TableComponent } from './components/table/table.component';
import { ModalCloseMultipleDeliveriesComponent } from './pages/gate/modal/modal-close-multiple-deliveries/modal-close-multiple-deliveries-component';
import { HasGateAccessPermissionGuard } from './guards/has-gate-access-permission.guard';
import { ShareWithWhatsappButtonComponent } from './components/share-with-whatsapp-button/share-with-whatsapp-button.component';
import { ModalCondoContactDetailsComponent } from './pages/gate/visitors/modal-condo-contact-details/modal-condo-contact-details';
import { CondoAutocompleteComponent } from './components/condo-autocomplete/condo-autocomplete.component';
import { DeletedListComponent } from './components/deleted-list/deleted-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ModalActiveVisitorsConfigModule } from './pages/gate/modal-active-visitors-config/modal-active-visitors-config.module';
import { CondoSmartLockersComponent } from './pages/condo/condo-smart-lockers/condo-smart-lockers';
import { ModalEventAnnouncementBoardConfigModule } from './pages/features/event-announcement-board/modal-event-announcement-board-config/modal-event-announcement-board-config.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CustomRoleIpInterceptor } from './interceptors/custom-role-ip.interceptor';
import { MessageListComponent } from './message-list/message-list.component';
import { HasPermissionDirective } from './directives/has-permission.directive';

import 'iconify-icon';
import { IconifyComponent } from './web-components/iconify/iconify.component';
import { FullReportsPanelComponent } from './components/full-reports-panel/full-reports-panel.component';
import { AppResidenceLinkComponent } from './components/app-residence-link/app-residence-link.component';
import { CondoUsersService } from '@api/serviceV3/condo-users.service';
import { EnvironmentInterceptor } from './interceptors/environment.interceptor';
import { NotificationImageComponent } from './components/notification.panel/notification-image/notification-image.component';
import { CondoConfigExternalLinksComponent } from './pages/condo/config/components/condo-config-external-links/condo-config-external-links.component';
import { ModalCreateExternalLinkComponent } from './pages/condo/config/components/modal-create-external-link/modal-create-external-link.component';
import { OtherLocalModalComponent } from './pages/reservation/other.local.modal/other.local.modal';
import { LocalGroupsPageComponent } from './pages/reservation/local-groups.component/local-groups.component';
import { CreateLocalGroupPageComponent } from './pages/reservation/create-local-group.component/create-local-group.modal';
import { TimerCountComponent } from './components/gate-active-timer-count/timer-count';
import { MarketplaceService } from '@api/service/marketplace.service';
import { LoadChunkErrorHandler } from './handlers/load-chunk-error.handler';
import { WatchActuatorsService } from './services/watch-actuators.service';

// Bootstrap locale
defineLocale('pt-br', ptBrLocale);

// Angular locale
registerLocaleData(localePtBr);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SideMenuComponent,
    AdviceCardComponent,
    PrivateCardComponent,
    VotingCardComponent,
    GateCardComponent,
    FeedCommentComponent,
    FeedLikersComponent,
    LoginPageComponent,
    FeedPage,
    UserPage,
    CondoPageComponent,
    GatePage,
    CondoResidencesComponent,
    CondoResidentsComponent,
    CondoConfigComponent,
    CondoPlansComponent,
    CondoSuppliersComponent,
    CondoVehiclesComponent,
    CondoDependentsComponent,
    CondoPetsComponent,
    CondoIntegrationsComponent,
    CondoParkingSpotComponent,
    ReservateLocalPageComponent,
    LocalGroupsPageComponent,
    ModalLocalDescriptionComponent,
    OtherLocalModalComponent,
    ModalLocalTermsComponent,
    ReservationHistoryComponent,
    ModalCondoReservationConfigComponent,
    CreateLocalGroupPageComponent,
    ModalReservationDetailComponent,
    ReservationChecklistModalComponent,
    CreateReservationComponent,
    GateDeliveryListPage,
    CreateGateDeliveryPage,
    KeyCardComponent,
    GatePermissionsListPage,
    AdviceCreatorComponent,
    PublicCreatorComponent,
    PrivateCreatorComponent,
    VotingCreatorComponent,
    ResidentsAutocompleteComponent,
    CondosAutocompleteComponent,
    ModalCreateReservationComponent,
    ModalConfirmComponent,
    ModalCommentComponent,
    ModalResidencePickerComponent,
    ModalHousePickerComponent,
    ModalCloseAllowAccessComponent,
    ModalCloseDeliveryComponent,
    ModalCloseNoiseComponent,
    ModalCloseGenericComponent,
    ModalCreateApartmentsComponent,
    ModalChangePasswordComponent,
    ModalNewCondoContactComponent,
    ModalNewCondoSupplierComponent,
    ModalResidenceAccessLogComponent,
    ModalSignature,
    ModalVotingDetailsComponent,
    ModalCreateDependent,
    ModalCreatePet,
    ModalCreateVehicleComponent,
    CreateIdComponent,
    NotificationPanelComponent,
    CondoVisitorsComponent,
    CondoKeysComponent,
    CreateLocalPageComponent,
    NotFoundPage,
    BlankPage,
    SignUpPage,
    SignUpStepOnePage,
    SignUpStepTwoPage,
    CreateCondoModal,
    ResidencesPage,
    ResetPasswordComponent,
    UserActivation,
    CondoListComponent,
    AccessControlComponent,
    CondoPickerModalComponent,
    VehicleCreateModal,
    CondoContactPickerModal,
    CreateUserContactModalComponent,
    ResidencePickerComponent,
    VehiclePickModal,
    ChipIdComponent,
    ModalQRCodeComponent,
    PersonChipComponnent,
    SalePage,
    SubscriptionPage,
    AdminCondoPickerModal,
    FeedFilterComponent,
    CondoGroupsComponent,
    ModalCreateGroupComponent,
    MergeContactsComponent,
    ModalResidenceHardwareEventsLogComponent,
    ModalResidenceHardwareEventsLogComponent,
    FeaturesPageComponent,
    MoreOptionsPageComponent,
    CondoSmartLockersComponent,
    IntercomComponent,
    ModalCreateExtensionComponent,
    ModalCreateSipAccountComponent,
    CondoConstructionsComponent,
    ModalCreateConstructionComponent,
    ModalResidencesConfigComponent,
    ModalCloseMultipleDeliveriesComponent,
    ModalCondoContactDetailsComponent,
    DeletedListComponent,
    CondoConfigExternalLinksComponent,
    ModalCreateExternalLinkComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MomentModule,
    EditorModule,
    PipesModule,
    ComponentsModule,
    ScrollingModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      preventDuplicates: true
    }),
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    AlertModule.forRoot(),
    NgxDatatableModule,
    NgSlimScrollModule, // Dependência do picker de data
    InfiniteScrollModule,
    AngularDraggableModule,
    TypeaheadModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer, stateKey: 'router' }),
    routing,
    AutosizeModule,
    NgChartsModule,
    ModalAddActuatorModule,
    ModalCreateActuatorsRouteModule,
    ModalSelectIntelbrasIncontrolAccessPointModule,
    ModalCreateKeyModule,
    ModalBorrowKeyModule,
    ModalKeyHistoryModule,
    ModalGateDeliveryConfigModule,
    ModalConfigDeliveryTrackingModule,
    ModalDeliveryTrackingHistoryModule,
    ModalGatePermissionsConfigModule,
    ModalActiveVisitorsConfigModule,
    ModalAccessControlConfigModule,
    ModalEditCommentModule,
    ModalAddHardwareDeviceModule,
    ThemeModule,
    ModalNotificationManagerModule,
    GateResidenceDetailsModalModule,
    GateResidentDetailsModalModule,
    NgxPaginationModule,
    ModalGenerateQrcodeFromGatekeeperModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ContextMenuModule,
    QRCodeModule,
    ModalCreateUserStepsModule,
    CreateResidenceModalModule,
    ModalPendingCondoApprovalModule,
    ModalResidentPickerModule,
    ModalResidenceConfigModule,
    NgxTrimDirectiveModule,
    ModalImportIntelbrasIncontrolVisitorsModule,
    ModalTerminatedContractsReportModule,
    IntercomPanelModule,
    GateChatModule,
    DragDropModule,
    DirectivesModule,
    FontAwesomeModule,
    CreateHardwareModalsModule,
    TableComponent,
    MessageListComponent,
    ShareWithWhatsappButtonComponent,
    CondoAutocompleteComponent,
    FullCalendarModule,
    ModalEventAnnouncementBoardConfigModule,
    IconifyComponent,
    FullReportsPanelComponent,
    AppResidenceLinkComponent,
    HasPermissionDirective,
    NotificationImageComponent,
    TimerCountComponent
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    ConstantService,
    AuthService,
    CommentService,
    CondoService,
    FileService,
    CondoFilesService,
    HttpService,
    NotificationService,
    OccurrenceService,
    ResidenceService,
    ReservationService,
    ReportService,
    UserService,
    MarketplaceService,
    UtilService,
    ParamsService,
    LoggedGuard,
    HasSystemAdminAccessGuard,
    HasAdminAccessGuard,
    HasGatekeeperAccessGuard,
    HasNotGatekeeperAccessGuard,
    IsSmartLockerEnabledGuard,
    GateFeatureGuard,
    AdFeatureGuard,
    DocumentsFeatureGuard,
    ReservationFeatureGuard,
    FinanceFeatureGuard,
    SignupGuard,
    AccessControlModuleGuard,
    AccessLiberationModuleGuard,
    DeliveriesModuleGuard,
    VotingModuleGuard,
    KeysModuleGuard,
    ReservationsModuleGuard,
    DocumentsModuleGuard,
    ClassificadosModuleGuard,
    HardwareFeatureGuard,
    LostAndFoundsModuleGuard,
    CondoRatingModuleGuard,
    CondoFaqModuleGuard,
    AssetManagementModuleGuard,
    CondoContactService,
    UserContactService,
    AccessService,
    VehicleService,
    FacebookService,
    CouponService,
    CustomParamService,
    DependentService,
    PetService,
    KeyService,
    LostAndFoundService,
    HardwareSocketService,
    GateNotebookService,
    SupplierService,
    CondoUsersService,
    HardwareReaderService,
    IsGatekeeperOrJanitorGuard,
    HasGateAccessPermissionGuard,
    ChecklistTemplateService,
    { provide: LOCALE_ID, useValue: 'pt-br' },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WhiteLabelInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WhitelabelFilterCondoInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCountInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomRoleIpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EnvironmentInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: defineWhitelabel,
      deps: [ThemeService],
      multi: true
    },
    { provide: ErrorHandler, useClass: LoadChunkErrorHandler },
    CallService,
    GroupService,
    WatchActuatorsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
