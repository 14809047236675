import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { GateResidenceDetailsModalComponent } from '../../pages/gate-residence-details.modal/gate-residence-details.modal.component';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipe/pipes.module';
import { SessionService } from '@api/service/session.service';

@Component({
  standalone: true,
  selector: 'app-residence-link',
  templateUrl: 'app-residence-link.component.html',
  styleUrls: ['app-residence-link.component.scss'],
  imports: [CommonModule, PipesModule, RouterLink]
})
export class AppResidenceLinkComponent {
  @Input() condo = this.sessionService.condoValue;
  @Input() residence: Residence;
  @Input() cssClasses: string;
  @Input() showResidenceIcon = false;

  constructor(
    private modalService: BsModalService,
    private sessionService: SessionService
  ) {}

  showResidenceDetailsModal(residenceId: string) {
    const initialState = {
      condo: this.condo,
      residenceId: residenceId
    };
    this.modalService.show(GateResidenceDetailsModalComponent, {
      initialState,
      class: 'modal-lg modal-xl',
      ignoreBackdropClick: true
    });
  }
}
