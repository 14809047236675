/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

// Services
import swal from 'sweetalert2';
import { CommentService } from '../../api/service/comment.service';
import { UtilService } from '../../services/util.service';

import * as _ from 'lodash';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'modal-comment',
  templateUrl: 'comment.modal.html'
})
export class ModalCommentComponent {
  @ViewChild('commentModal', { static: true }) public commentModal: ModalDirective;
  @Input() showAsMessages = false;

  public occurrence: any;
  public comments = [];
  public queryString = [];
  public condo;
  public user;
  public newComment = '';
  public tempComment;
  public isAddingComment = false;

  public loadingStatus;
  public LOADING = 'loading';
  public SUCCESS = 'success';
  public ERROR = 'error';

  // Var to hold ID of comment being edited (if any)
  private editingCommentId: String;
  private commentDescriptionBackup;
  private isUpdatingComment: boolean;

  constructor(private commentService: CommentService, private utilService: UtilService) {
    this.queryString = [];
    this.queryString[this.queryString.length] = '$populate[1][path]=createdBy';
    this.queryString[this.queryString.length] = '$populate[1][select]=firstName lastName picture';
    this.queryString[this.queryString.length] = '$populate[1][populate][path]=picture';
    this.queryString[this.queryString.length] = '$populate[1][populate][select]=url publicId thumbnail';
    this.queryString[this.queryString.length] = '$sort=createdAt';
    this.queryString[this.queryString.length] = '$limit=50';

    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();
  }

  editComment(comment) {
    this.editingCommentId = comment._id;
    this.commentDescriptionBackup = comment.description;
  }

  cancelEditingComment(comment) {
    this.stopEditingComment();
    comment.description = this.commentDescriptionBackup;

    event.stopPropagation();
  }

  stopEditingComment() {
    this.editingCommentId = '';
  }

  editingCommentInputLostFocus(comment) {
    setTimeout(() => {
      comment.EditingCommentInputHasFocus = false;
    }, 100);
  }

  updateComment(comment) {
    this.isUpdatingComment = true;

    this.commentService.updateComment(this.condo._id, this.occurrence._id, comment._id, { description: comment.description }).subscribe(
      () => {
        this.isUpdatingComment = false;
        this.stopEditingComment();
      },
      err => {
        this.isUpdatingComment = false;

        swal({
          text: 'Não foi possível atualizar este comentário, tente novamente.'
        });
      }
    );
  }

  closeModal(): void {
    this.commentModal.hide();
  }

  showModal(occurrence) {
    this.comments = [];
    this.newComment = '';
    this.occurrence = occurrence;
    this.loadComments();

    this.editingCommentId = '';
    this.commentModal.show();
  }

  loadComments() {
    this.loadingStatus = this.LOADING;
    this.getComments()
      .pipe(timeout(10000))
      .subscribe(
        comments => {
          this.comments = comments;
          this.loadingStatus = this.SUCCESS;
        },
        err => {
          this.loadingStatus = this.ERROR;
        }
      );
  }

  getComments() {
    return this.commentService.getCommentsFromOccurrence(this.condo._id, this.occurrence['_id'], `?${this.queryString.join('&')}`);
  }

  addComment() {
    const comment = {
      description: this.newComment
    };
    this.tempComment = _.clone(comment);
    this.tempComment['createdBy'] = this.utilService.getLocalUser();
    this.isAddingComment = true;
    const condoId = this.condo._id ? this.condo._id : this.condo;
    this.commentService
      .createComment(condoId, this.occurrence._id, comment)
      .pipe(timeout(10000))
      .subscribe(
        (data: any) => {
          comment['createdBy'] = this.user;
          comment['createdAt'] = new Date();
          comment['_id'] = data._id;
          if (!this.occurrence.comments) {
            this.occurrence.comments = [];
          }
          this.occurrence.comments.push(comment);
          this.occurrence.commentsTotal = this.occurrence.commentsTotal || 0;
          this.occurrence.commentsTotal++;
          this.comments.push(comment);
          this.newComment = '';
          this.isAddingComment = false;
        },
        erro => {
          this.isAddingComment = false;
        }
      );
  }

  deleteComment(occurrence, comment) {
    swal({
      title: 'Deletar comentário',
      text: 'Tem certeza que deseja excluir esse comentário?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.commentService
          .deleteComment(this.condo._id, occurrence._id, comment._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível apagar este comentário, tente novamente.');
          });
      }
    }).then(
      success => {
        occurrence._commentsTotal--;
        this.comments.splice(this.comments.indexOf(comment), 1);
        swal({
          text: 'Comentário excluído com sucesso'
        });
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }
}
