import { File } from './file';
import { BackObject } from './interface/back.object';
import { User } from './user';
import { CondoUser } from '@api/model/condo-user';

const TYPES = {
  CAR: 'CAR',
  TRUCK: 'TRUCK',
  MOTORCYCLE: 'MOTORCYCLE',
  BICYCLE: 'BICYCLE'
};

const TYPES_LABEL = {
  CAR: 'Carro',
  TRUCK: 'Caminhão',
  MOTORCYCLE: 'Moto',
  BICYCLE: 'Bicicleta'
};

const VEHICLES_COLORS = {
  AMARELO: 'AMARELO',
  AZUL: 'AZUL',
  BEGE: 'BEGE',
  BRANCO: 'BRANCO',
  CINZA: 'CINZA',
  DOURADO: 'DOURADO',
  FANTASIA: 'FANTASIA',
  GRENA: 'GRENA',
  LARANJA: 'LARANJA',
  MARROM: 'MARROM',
  PRATA: 'PRATA',
  PRETO: 'PRETO',
  ROSA: 'ROSA',
  ROXO: 'ROXO',
  VERDE: 'VERDE',
  VERMELHO: 'VERMELHO'
};

const VEHICLES_COLORS_LABEL = {
  AMARELO: 'Amarelo',
  AZUL: 'Azul',
  BEGE: 'Bege',
  BRANCO: 'Branco',
  CINZA: 'Cinza',
  DOURADO: 'Dourado',
  FANTASIA: 'Fantasia',
  GRENA: 'Grena',
  LARANJA: 'Laranja',
  MARROM: 'Marrom',
  PRATA: 'Prata',
  PRETO: 'Preto',
  ROSA: 'Rosa',
  ROXO: 'Roxo',
  VERDE: 'Verde',
  VERMELHO: 'Vermelho'
};

const VEHICLE_BRANDS = {
  // ACURA: 'ACURA',
  // AGRALE: 'AGRALE',
  ALFA_ROMEO: 'ALFA ROMEO',
  // AM_GEN: 'AM GEN',
  // ASIA_MOTORS: 'ASIA MOTORS',
  ASTON_MARTIN: 'ASTON MARTIN',
  AUDI: 'AUDI',
  // BABY: 'BABY',
  BMW: 'BMW',
  // BRM :'BRM',
  BUGGY: 'BUGGY',
  // BUGRE: 'BUGRE',
  CADILLAC: 'CADILLAC',
  // CBT_JIPE: 'CBT JIPE',
  // CHANA: 'CHANA',
  // CHANGAN: 'CHANGAN',
  CHEVROLET: 'CHEVROLET',
  CHERY: 'CHERY',
  CHRYSLER: 'CHRYSLER',
  CITROEN: 'CITROEN',
  // CROSS_LANDER: 'CROSS LANDER',
  // DAEWOO: 'DAEWOO',
  // DAIHATSU: 'DAIHATSU',
  DAF: 'DAF',
  DODGE: 'DODGE',
  // EFFA: 'EFFA',
  // ENGESA: 'ENGESA',
  // ENVEMO: 'ENVEMO',
  FERRARI: 'FERRARI',
  FIAT: 'FIAT',
  // FIBRAVAN: 'FIBRAVAN',
  FORD: 'FORD',
  // FOTON: 'FOTON',
  // FYBER: 'FYBER',
  // GEELY: 'GEELY',
  GM: 'GM',
  // GREAT_WALL: 'GREAT WALL',
  // GURGEL: 'GURGEL',
  // HITECH_ELECTRIC: 'HITECH ELECTRIC',
  // HAFEI: 'HAFEI',
  HONDA: 'HONDA',
  HYUNDAI: 'HYUNDAI',
  IMPORTADO: 'IMPORTADO',
  // ISUZU: 'ISUZU',
  IVECO: 'IVECO',
  JAC: 'JAC',
  JAGUAR: 'JAGUAR',
  JEEP: 'JEEP',
  // JINBEI: 'JINBEI',
  // JPX: 'JPX',
  KIA: 'KIA',
  // LADA: 'LADA',
  LAMBORGHINI: 'LAMBORGHINI',
  LANDROVER: 'LAND ROVER',
  LEXUS: 'LEXUS',
  LIFAN: 'LIFAN',
  // LOBINI: 'LOBINI',
  LOTUS: 'LOTUS',
  // MAHINDRA: 'MAHINDRA',
  MASERATI: 'MASERATI',
  // MATRA: 'MATRA',
  MAZDA: 'MAZDA',
  MCLAREN: 'MCLAREN',
  MERCEDES: 'MERCEDES',
  // MERCURY: 'MERCURY',
  MG: 'MG',
  MINI: 'MINI',
  MITSUBISHI: 'MITSUBISHI',
  // MIURA: 'MIURA',
  MOTO: 'MOTO',
  NISSAN: 'NISSAN',
  VEICULO: 'VEICULO',
  PEUGEOT: 'PEUGEOT',
  // PLYMOUTH: 'PLYMOUTH',
  // PONTIAC: 'PONTIAC',
  PORSCHE: 'PORSCHE',
  // RAM: 'RAM',
  // RELY: 'RELY',
  RENAULT: 'RENAULT',
  ROLLS_ROYCE: 'ROLLS-ROYCE',
  ROVER: 'ROVER',
  // SAAB: 'SAAB',
  // SATURN: 'SATURN',
  // SEAT: 'SEAT',
  // SHINERAY: 'SHINERAY',
  SCANIA: 'SCANIA',
  SMART: 'SMART',
  // SSANGYONG: 'SSANGYONG',
  SUBARU: 'SUBARU',
  SUZUKI: 'SUZUKI',
  // TAC: 'TAC',
  TOYOTA: 'TOYOTA',
  TROLLER: 'TROLLER',
  VOLKSWAGEN: 'VOLKSWAGEN',
  VOLVO: 'VOLVO'
};

const MOTORCYCLE_BRANDS = {
  BMW: 'BMW',
  CALOI: 'CALOI',
  DAFRA: 'DAFRA',
  DUCATI: 'DUCATI',
  HARLEY_DAVIDSON: 'HARLEY-DAVIDSON',
  HONDA: 'HONDA',
  KASINSKI: 'KASINSKI',
  KAWASAKI: 'KAWASAKI',
  LIFAN: 'LIFAN',
  PEUGEOT: 'PEUGEOT',
  ROYAL_ENFIELD: 'ROYAL ENFIELD',
  SUNDOWN: 'SUNDOWN',
  SUZUKI: 'SUZUKI',
  TRIUMPH: 'TRIUMPH',
  WUYANG: 'WUYANG',
  YAMAHA: 'YAMAHA'
};
const BICYCLE_BRANDS = {
  BMC: 'BMC',
  BROMPTON: 'BROMPTON',
  CALOI: 'CALOI',
  CANNONDALE: 'CANNONDALE',
  CERVELO: 'CERVELO',
  COLLI: 'COLLI',
  DAHON: 'DAHON',
  DURBAN: 'DURBAN',
  FOCUS: 'FOCUS',
  GIANT: 'GIANT',
  GT: 'GT',
  GTSM1: 'GTSM1',
  HOUSTON_BIKE: 'HOUSTON_BIKE',
  KONA: 'KONA',
  LINUS: 'LINUS',
  MERIDA: 'MERIDA',
  MONARK: 'MONARK',
  NATHOR: 'NATHOR',
  RALEIGH: 'RALEIGH',
  SALSA: 'SALSA',
  SANTA_CRUZ: 'SANTA CRUZ',
  SCOTT: 'SCOTT',
  SENSE: 'SENSE',
  SOUL: 'SOUL',
  SURLY: 'SURLY',
  SPECIALIZED: 'SPECIALIZED',
  TOMASSO: 'TOMASSO',
  TREK: 'TREK',
  VERDEN_BIKES: 'VERDEN BIKES',
  WOOM: 'WOOM',
  YETI_CYCLES: 'YETI CYCLES'
};
export abstract class Vehicle implements BackObject {
  public static TYPES = TYPES;

  public static TYPES_LABEL = TYPES_LABEL;

  public static VEHICLES_COLORS = VEHICLES_COLORS;
  public static VEHICLES_COLORS_LABEL = VEHICLES_COLORS_LABEL;

  public static VEHICLE_BRANDS = VEHICLE_BRANDS;
  public static MOTORCYCLE_BRANDS = MOTORCYCLE_BRANDS;
  public static BICYCLE_BRANDS = BICYCLE_BRANDS;

  public _id = '';
  public plate = '';
  public chassis = '';
  public model = '';
  public type = TYPES.CAR;
  public brand = '';
  public color = '';
  private _pictures: Array<File> = new Array<File>();
  public createdBy: User = null;
  public createdAt = '';
  public user: CondoUser = null;

  constructor(vehicle?) {
    if (typeof vehicle == 'string') {
      this.id = vehicle;
    } else if (vehicle) {
      this.id = vehicle._id || this.id;
      this.plate = vehicle.plate || this.plate;
      this.chassis = vehicle.chassis || this.chassis;
      this.model = vehicle.model || this.model;
      this.type = vehicle.type || this.type;
      this.brand = vehicle.brand || this.brand;
      this.color = vehicle.color || this.color;
      this.pictures = vehicle.pictures ? vehicle.pictures.map(pic => new File(pic)) : this.pictures;
      this.createdBy = vehicle.createdBy ? new User(vehicle.createdBy) : this.createdBy;
      this.createdAt = vehicle.createdAt || this.createdAt;
      if (vehicle.user) {
        this.user = new CondoUser(vehicle.user);
      }
    }
  }

  createBackObject() {
    const vehicle = {
      plate: this.plate,
      chassis: this.chassis,
      model: this.model,
      type: this.type,
      brand: this.brand,
      color: this.color,
      pictures: this.pictures.map(pic => pic.id),
      user: null
    };

    if (this.user) {
      vehicle.user = this.user;
    }

    return vehicle;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get pictures(): File[] {
    return this._pictures;
  }

  set pictures(value: File[]) {
    this._pictures = value;
  }

  get typeLabel(): string {
    return Vehicle.TYPES_LABEL[this.type];
  }
}
