import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  readonly CACHE_DURATION_IN_MINUTES = 1;

  private cache = new Map<string, [Date, Observable<any>]>();

  getValue(key) {
    const cacheData = this.cache.get(key);
    if (!cacheData) {
      return null;
    }
    const [dueDate, value] = cacheData;
    if (moment(new Date()).isAfter(dueDate)) {
      return null;
    }

    return value;
  }

  setValue(key, value: Observable<any>) {
    const expires = moment(new Date()).add(this.CACHE_DURATION_IN_MINUTES, 'minutes').toDate();
    this.cache.set(key, [expires, value]);
  }

  clearCache() {
    this.cache.clear();
  }
}
