import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UtilService } from '../services/util.service';

@Injectable()
export class IsSmartLockerEnabledGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();

    if (condo.isCliqueRetireEnabled() || condo.isHandoverEnabled()) {
      return true;
    }

    this.router.navigate(['/notFound']);
    return false;
  }
}
