import { Local } from '@api/model/local';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';

export interface LocalGroup {
  _id?: string;
  name: string;
  condo?: Condo | null;
  description?: string;
  createdBy?: User | null;
  createdAt?: string;
  params: {
    canReserveMultipleLocals: boolean;
    limitOfMultipleReservations: number;
  };
  reservationLocals?: Local[];
}

export const BuildLocalGroup = (localGroup): LocalGroup => {
  if (localGroup) {
    return {
      _id: localGroup._id || '',
      condo: localGroup.condo ? new Condo(localGroup.condo) : null,
      name: localGroup.name || '',
      description: localGroup.description || '',
      createdBy: localGroup.createdBy ? new User(localGroup.createdBy) : null,
      createdAt: localGroup.createdAt || null,
      params: localGroup.params || {},
      reservationLocals: localGroup.reservationLocals ? localGroup.reservationLocals.map(local => new Local(local)) : []
    };
  }
};
