import { Component, ElementRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { ResidenceService } from '@api/service/residence.service';
import { UntypedFormControl } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { debounceTime, distinctUntilChanged, retry } from 'rxjs/operators';
import { capitalize } from '@api/util/util';

/**
 * Created by Rafael on 27/12/2016.
 */

@Component({
  selector: 'residences-autocomplete',
  host: {
    '(document:click)': 'handleClick($event)'
  },
  styles: [
    `
      .list-group {
        max-height: 300px;
        overflow-y: auto;
      }

      .list-group-item {
        cursor: pointer;
      }
    `
  ],
  template: `
    <div class="component-wrapper">
      <div class="input-wrapper">
        <input
          type="text"
          class=""
          (keyup.enter)="selectResidence($event)"
          [formControl]="searchTerm"
          (keyup.arrowUp)="decrementIndex($event)"
          (keyup.arrowDown)="incrementIndex($event)"
          [placeholder]="placeholder" />
      </div>
      <div class="list-group float-start" id="residence-autocomplete-list" style="position: absolute; z-index: 1;">
        <a *ngIf="!filteredList.length && searchTerm.value && !isLoading" class="list-group-item active">
          Nenhuma {{ customLabel }} encontrada
        </a>
        <a *ngIf="isLoading" class="list-group-item active">
          <i class="fa fa-spinner fa-spin fa-fw"></i>
          <span class="sr-only">Carregando...</span>Carregando...
        </a>
        <a
          (click)="select(residence)"
          *ngFor="let residence of filteredList; let i = index"
          class="list-group-item"
          [class.active]="i == activeIndex"
          >{{ customLabel }} {{ residence.identification }}</a
        >
      </div>
    </div>
  `
})
export class ResidencesAutocompleteComponent implements OnInit {
  @Output()
  onResidenceSelect = new EventEmitter();

  @Input()
  public condo: Condo;

  @Input()
  public placeholder = 'Unidade';

  public activeIndex = 0;

  public filteredList = [];
  public elementRef;

  public subscription;

  public isLoading = false;

  public searchTerm: UntypedFormControl;

  customLabel = '';

  constructor(myElement: ElementRef, private residenceService: ResidenceService) {
    this.elementRef = myElement;
    this.searchTerm = new UntypedFormControl();
    this.searchTerm.setValue('');

    this.searchTerm.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(token => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (token) {
        this.isLoading = true;
        return this.residenceService
          .searchByToken(this.condo._id, token)
          .pipe(distinctUntilChanged(), retry(2))
          .subscribe(
            ({ residences }) => {
              this.filteredList = residences;
              this.filteredList.sort(function (a: any, b: any) {
                return a.identification.length - b.identification.length || a.identification.localeCompare(b.identification);
              });
              this.activeIndex = 0;
              this.isLoading = false;
            },
            err => {
              this.isLoading = false;
              this.filteredList = new Array<Residence>();
            }
          );
      } else {
        this.filteredList = new Array<Residence>();
        this.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.placeholder = capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade';
    this.customLabel = `${capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade'}`;
  }

  incrementIndex(event) {
    this.activeIndex < this.filteredList.length - 1 ? this.activeIndex++ : (this.activeIndex = 0);
    document.getElementById('residence-autocomplete-list').scrollTop = 42 * (this.activeIndex - 1) - this.activeIndex;
  }

  decrementIndex(event) {
    this.activeIndex > 0 ? this.activeIndex-- : (this.activeIndex = this.filteredList.length - 1);
    document.getElementById('residence-autocomplete-list').scrollTop = 42 * (this.activeIndex - 1) - this.activeIndex;
  }

  selectResidence($event) {
    this.select(this.filteredList[this.activeIndex]);
  }

  select(residence) {
    if (!residence) {
      return;
    }
    this.searchTerm.setValue('');
    this.filteredList = [];
    this.onResidenceSelect.emit(residence);
  }

  handleClick(event) {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.filteredList = [];
    }
  }
}
