import * as moment from 'moment';
import { formatCallDuration } from '@api/util/formatters';
import { Condo } from './condo';
import { User } from './user';

export type CallStatus = 'DECLINED' | 'ANSWERED' | 'MISSED';
export type CallUserType = 'USER' | 'GATE' | 'SIP';
export type CallType = 'EFONE_TO_EFONE' | 'EFONE_TO_SIP' | 'SIP_TO_EFONE';

export interface SipUser {
  displayName: string;
  extension: string;
  domain: string;
}

export interface Call {
  _id: string;
  condo: Condo;
  type: CallType;
  calleeType: CallUserType;
  callee?: User;
  sipCallee?: SipUser;
  callerType: CallUserType;
  caller?: User;
  sipCaller?: SipUser;
  duration: number;
  formattedDuration?: string;
  status?: CallStatus;
  createdAt: Date;
}

export interface FormatedCall extends Call {
  otherUser: User;
  icon: { name: string; color: string };
  callTimeFromNow: string;
  statusLabel: string;
}

export const CALL_TYPES_ICONS = {
  OUTGOING: { name: 'phone-outgoing', color: '#2ecc71', label: 'Chamada feita' },
  OUTGOING_NOT_ANSWERED: { name: 'phone-outgoing', color: '#e74c3c', label: 'Chamada não atendida' },
  ANSWERED: { name: 'phone-incoming', color: '#2ecc71', label: 'Ligação atendida' },
  DECLINED: { name: 'phone-missed', color: '#e74c3c', label: 'Ligação recusada' },
  MISSED: { name: 'phone-incoming', color: '#e74c3c', label: 'Ligação perdida' }
};

export function BuildCall(call: any): Call {
  const buildedCallHistoryItem: Call = {
    _id: typeof call === 'string' ? call : call._id,
    condo: call.condo ? new Condo(call.condo) : ({} as Condo),
    type: call.type || 'EFONE_TO_EFONE',
    calleeType: call.calleeType || 'USER',
    callee: call.callee ? new User(call.callee) : ({} as User),
    sipCallee: call.sipCallee,
    callerType: call.calleeType || 'USER',
    caller: call.caller ? new User(call.caller) : ({} as User),
    sipCaller: call.sipCaller,
    duration: call.duration || 0,
    formattedDuration: formatCallDuration(call.duration || 0),
    status: call.status || '',
    createdAt: new Date(call.createdAt)
  };

  return buildedCallHistoryItem;
}

export function BuildFormatedCall(call: Call, userId: string): FormatedCall {
  const otherUser = call.callee._id === userId ? call.caller : call.callee;

  let icon;
  let statusLabel;

  if (call.caller._id === userId && call.status === 'ANSWERED') {
    icon = CALL_TYPES_ICONS.OUTGOING;
    statusLabel = 'Chamada feita';
  } else if (call.caller._id === userId && call.status !== 'ANSWERED') {
    icon = CALL_TYPES_ICONS.OUTGOING_NOT_ANSWERED;
    statusLabel = 'Chamada não atendida';
  } else if (call.status === 'DECLINED') {
    icon = CALL_TYPES_ICONS.DECLINED;
    statusLabel = 'Ligação recusada';
  } else if (call.status === 'MISSED') {
    icon = CALL_TYPES_ICONS.MISSED;
    statusLabel = 'Ligação perdida';
  } else {
    icon = CALL_TYPES_ICONS.ANSWERED;
    statusLabel = 'Ligação atendida';
  }

  const callTimeFromNow = moment(call.createdAt).fromNow();

  return { ...call, otherUser, icon, callTimeFromNow, statusLabel };
}

// TODO pensar numa maneira melhor de fazer o statusLabel
