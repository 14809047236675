export interface SystemLocalSettings {
  rtspServer?: {
    type: string;
    https: boolean;
    host: string;
    port: string;
  };
  updatedAt?: string;
  notifyVisitorExit?: boolean;
}

export const defaultSystemLocalSettings: SystemLocalSettings = {
  rtspServer: {
    type: 'V1',
    https: false,
    host: '',
    port: '1984'
  },
  notifyVisitorExit: true
};
