import { Component, Input, OnChanges } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnChanges {
  @Input() type: 'info' | 'error' = 'info';

  @Input() navigateBackRoute = '';
  @Input() icon = null;

  @Input() navigateBackRouteQueryParams: Record<string, string> = {};

  @Input() titleText = '';

  @Input() subtitle = '';
  @Input() emphasis: 'title' | 'subtitle' = 'title';

  @Input() badgeText = '';

  isFontAwesomeIcon = false;

  constructor(private location: Location) {}

  ngOnChanges(changes) {
    if (changes.icon) {
      this.isFontAwesomeIcon = typeof changes.icon.currentValue === 'string' && changes.icon.currentValue.startsWith('fa');
    }
  }
}
