import { Component, OnInit } from '@angular/core';
import { Notification } from '../../api/model/notification';
import { NotificationService } from '../../api/service/notification.service';
import { Router } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { User } from '../../api/model/user';
import swal from 'sweetalert2';
import { UserService } from '../../api/service/user.service';
import { ISlimScrollOptions, SlimScrollOptions } from 'ngx-slimscroll';
import { flatMap, retry, timeout } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalNotificationManagerComponent } from '../../pages/modal/notification.manager.modal/modal-notification-manager';
import { ParamsService } from '../../api/service/params.service';
import { LiberationPersonModalComponent } from '../../pages/visitor-liberation/liberation-person-modal/liberation-person-modal.component';

@Component({
  selector: 'notification-panel',
  templateUrl: 'notification.panel.html'
})
export class NotificationPanelComponent implements OnInit {
  public notifications: Array<Notification> = new Array<Notification>();

  public user: User;

  public LOADING = 'LOADING';
  public SUCCESS = 'SUCCESS';
  public ERROR = 'ERROR';
  public INIFINITING = 'INFINITING';

  public loadingStatus = '';

  public currentePage = 0;
  public hasMoreData = true;
  public numberOfNotificationsDownloadPerPage = 20;
  public totalOfNonReadNotifications = 0;

  public isHidden = true;

  private qs = [];

  opts: ISlimScrollOptions;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private utilService: UtilService,
    private modalService: BsModalService,
    private userService: UserService,
    private paramsService: ParamsService
  ) {
    this.qs[this.qs.length] = '$populate[0][path]=occurrence';
    this.qs[this.qs.length] = '$populate[0][select]=type residence';
    this.qs[this.qs.length] = '$populate[1][path]=createdBy';
    this.qs[this.qs.length] = '$populate[1][select]=firstName lastName picture';
    this.qs[this.qs.length] = '$populate[1][populate][path]=picture';
    this.qs[this.qs.length] = '$populate[1][populate][select]=url thumbnail';
    this.qs[this.qs.length] = '$populate[2][path]=dependent';
    this.qs[this.qs.length] = '$populate[2][select]=name picture';
    this.qs[this.qs.length] = '$populate[2][populate][path]=picture';
    this.qs[this.qs.length] = '$populate[2][populate][select]=url thumbnail';
    this.qs[this.qs.length] = '$populate[3][path]=visitorRequest';
    this.qs[this.qs.length] = '$populate[3][select]= _id registeredContact';
    this.qs[this.qs.length] = '$populate[3][populate][path]=registeredContact';
    this.qs[this.qs.length] = '$populate[3][populate][select]=firstName lastName picture';
    this.qs[this.qs.length] = '$populate[3][populate][populate][path]=picture';
    this.qs[this.qs.length] = '$populate[3][populate][populate][select]=url thumbnail';
    this.qs[this.qs.length] = '$populate[4][path]=condoContact';
    this.qs[this.qs.length] = '$populate[4][select]=firstName lastName picture';
    this.qs[this.qs.length] = '$populate[4][populate][path]=picture';
    this.qs[this.qs.length] = '$populate[4][populate][select]=url thumbnail';
    this.qs[this.qs.length] = `$limit=${this.numberOfNotificationsDownloadPerPage}`;
    this.qs[this.qs.length] = '$sort=-createdAt';
  }

  ngOnInit() {
    this.opts = new SlimScrollOptions();
    this.opts.barBackground = '#c4c9cc';
    this.opts.barWidth = '8';
    this.opts.gridBackground = '#d6dadc';
    this.opts.gridWidth = '4';
    this.opts.gridOpacity = '0.2';

    this.loadNotifications();
    setInterval(() => {
      this.loadNotifications();
    }, 300000);
  }

  getNotificationNumber() {
    return this.totalOfNonReadNotifications < 0 ? 0 : this.totalOfNonReadNotifications;
  }

  loadNotifications() {
    this.user = this.utilService.getLocalUser();
    if (this.user) {
      this.currentePage = 0;
      const successCallback = notifications => {
        this.loadingStatus = this.SUCCESS;
        this.notifications = this.filterNewOccurrenceNotificationsWithoutOccurrence(notifications);
        if (notifications.length >= 20) {
          this.hasMoreData = true;
        }
        this.currentePage++;
      };

      const errorCallback = error => {
        this.loadingStatus = this.ERROR;
      };

      this.loadingStatus = this.LOADING;
      this.getData(successCallback, errorCallback);
    }
  }

  getData(successCallback, errorCallback) {
    this.countNonReadNotification();
    this.notificationService.getUserNotifications('me', `?${this.qs.join('&')}&$page=${this.currentePage}`).subscribe({
      next: notifications => {
        successCallback(notifications);
      },
      error: err => {
        errorCallback(err);
      }
    });
  }

  countNonReadNotification() {
    this.notificationService
      .countUserNonReadNotifications('me')
      .pipe(retry(3), timeout(10000))
      .subscribe(
        count => {
          this.totalOfNonReadNotifications = count[0] || count[0] == 0 ? count[0] : count;
        },
        err => {
          this.totalOfNonReadNotifications = 0;
        }
      );
  }

  askToReadAllNotifications() {
    swal({
      type: 'question',
      text: `Deseja marcar todas notificações como lidas?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then(
      success => {
        this.readAllNotitications();
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  readAllNotitications() {
    this.notifications.forEach(notification => {
      notification.read = true;
    });
    const oldValue = this.totalOfNonReadNotifications;
    this.totalOfNonReadNotifications = 0;
    this.notificationService.markAllUserNotificationsAsRead('me').subscribe(
      () => {
        console.log('All notificarions read successfully');
      },
      err => {
        this.totalOfNonReadNotifications = oldValue;
      }
    );
  }

  readNotification(event, notification: Notification) {
    if (event) {
      // Necessary to not call (click) event on parent html element
      event.stopPropagation();
    }
    if (notification.read) {
      return;
    }
    notification.read = true;
    this.totalOfNonReadNotifications--;
    this.notificationService
      .markUserNotificationAsRead('me', notification._id)
      .pipe(timeout(8000), retry(3))
      .subscribe(
        () => {},
        () => {
          console.log('Error reading ' + notification._id);
          notification.read = false;
          this.totalOfNonReadNotifications++;
        }
      );
  }

  doInfinite() {
    const successCallback = notifications => {
      this.loadingStatus = this.SUCCESS;
      this.notifications = this.notifications.concat(this.filterNewOccurrenceNotificationsWithoutOccurrence(notifications));
      this.currentePage++;
      if (notifications.length < this.numberOfNotificationsDownloadPerPage) {
        this.hasMoreData = false;
      }
    };

    const errorCallback = error => {
      this.loadingStatus = this.ERROR;
    };

    this.loadingStatus = this.INIFINITING;
    this.getData(successCallback, errorCallback);
  }

  onNotificationClick(event, notification: Notification) {
    if (!notification) {
      return;
    }

    this.hide();
    this.readNotification(null, notification);
    if (notification.isFromCondo(this.user.defaultCondo.id)) {
      this.handleNotificationAction(notification);
    } else {
      this.changeSelectedCondo(notification, () => this.handleNotificationAction(notification));
    }
  }

  hide() {
    this.isHidden = true;
  }

  show() {
    this.isHidden = false;
  }

  toggle() {
    this.isHidden = !this.isHidden;
    if (!this.isHidden && this.notifications && !this.notifications.length) {
      this.loadNotifications();
    }
  }

  handleNotificationAction(notification) {
    switch (notification.type) {
      case 'OCCURRENCE-NEW':
        this.goToOcurrence(notification);
        break;
      case 'COMMENT-NEW':
        this.goToOcurrence(notification);
        break;
      case 'OCCURRENCE-LIKE':
        this.goToOcurrence(notification);
        break;
      case 'OCCURRENCE-VOTE':
        this.goToOcurrence(notification);
        break;
      case 'USER-NEW-CONDO-REQUESTER':
        this.router.navigate(['condo/residents']);
        break;
      case 'USER-NEW-CONDO-USER':
        this.router.navigate(['condo/residents']);
        break;
      case 'USER-NEW-CONDO-ADMIN':
        this.router.navigate(['condo/condos']);
        break;
      case 'USER-NEW-CONDO-OWNER':
        this.router.navigate(['condo/condos']);
        break;
      case 'USER-NEW-CONDO-GATEKEEPER':
        this.router.navigate(['condo/condos']);
        break;
      case 'USER-NEW-RESIDENCE-REQUESTER':
        if (this.user.isAdmin() || this.user.isOwner()) {
          this.router.navigate(['condo/residences']);
        } else {
          this.router.navigate(['residences']);
        }
        break;
      case 'USER-NEW-RESIDENCE-USER':
        if (this.user.isAdmin() || this.user.isOwner()) {
          this.router.navigate(['condo/residences']);
        } else {
          this.router.navigate(['residences']);
        }
        break;
      case 'USER-NEW-RESIDENCE-VOTER':
        if (this.user.isAdmin() || this.user.isOwner()) {
          this.router.navigate(['condo/residences']);
        } else {
          this.router.navigate(['residences']);
        }
        break;
      case 'USER-REMOVED':
        this.router.navigate(['condo/residents']);
        break;
      case 'USER-REMOVED-CONDO-ADMIN':
        this.router.navigate(['condo/condos']);
        break;
      case 'USER-REMOVED-CONDO-GATEKEEPER':
        this.router.navigate(['condo/condos']);
        break;
      case 'USER-APPROVED':
        if (this.user.isAdmin() || this.user.isOwner()) {
          this.router.navigate(['condo/residents']);
        } else {
          this.router.navigate(['feed/']);
        }
        break;
      case Notification.TYPES.DEPENDENT_NEW:
        this.router.navigate(['condo', 'dependents']);
        break;
      case Notification.TYPES.PET_NEW:
        this.router.navigate(['condo', 'pets']);
        break;
      case Notification.TYPES.VEHICLE_NEW:
        this.router.navigate(['condo', 'vehicles']);
        break;
      case 'RESERVATION-CREATED':
        this.router.navigate([`reservations/reservationHistory${notification.reservation ? '/' + notification.reservation.id : ''}`]);
        break;
      case Notification.TYPES.ADVERTISE_NEW:
        this.router.navigate(['features', 'classificados']);
        break;
      case Notification.TYPES.MAINTENANCE_TICKET_NEW:
        this.router.navigate(['maintenance', 'tickets']);
        break;
      case Notification.TYPES.MAINTENANCE_ACTION_EXECUTION_ALERT:
        this.router.navigate(['maintenance', 'plans']);
        break;
      case Notification.TYPES.LOST_AND_FOUND_NEW:
        this.router.navigate(['features', 'lost-and-founds']);
        break;
      case Notification.TYPES.GATE_NOTEBOOK_MESSAGE_NEW:
        this.router.navigate(['gate', 'notebook']);
        break;
      case Notification.TYPES.COMMAND_PANEL_SIGNAL_STATUS:
        this.router.navigate(['hardware', 'command-panels']);
        break;
      case 'VISITOR-SELF-REGISTRATION':
        const initialState: Partial<LiberationPersonModalComponent> = {
          visitorRequest: notification.visitorRequest,
          occurrence: notification.occurrence
        };
        this.modalService.show(LiberationPersonModalComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: false
        });
        break;
      case 'NEW-DEVICE-REQUEST':
        this.router.navigate([`features/device-requests/${notification.deviceRequest}`]);
        break;
      case 'DEPENDENT-ACCESS':
        this.paramsService.add('residence', notification.residence);
        this.router.navigate([`residences`]);
        break;
      case 'NEW-CONDO-REQUEST':
        this.router.navigate(['condo/residents']);
        break;
      case 'CONDO-REQUEST-APPROVED':
        this.router.navigate(['condo/residents']);
        break;
      default:
        break;
    }
  }

  filterNewOccurrenceNotificationsWithoutOccurrence(notifications) {
    const filteredNotifications = [];
    notifications.forEach(notification => {
      if (notification.type != 'OCCURRENCE-NEW' || (notification.type == 'OCCURRENCE-NEW' && notification.occurrence)) {
        filteredNotifications.push(notification);
      } else {
        // TODO apagar a notificação em vez de marcá-la como lida
        this.readNotification(null, notification);
      }
    });
    return filteredNotifications;
  }

  goToOcurrence(notification) {
    if (notification.occurrence && notification.occurrence.id) {
      if (
        notification.occurrence.isGateType() &&
        !notification.occurrence.residence &&
        (this.user.isOwner() || this.user.isAdmin() || this.user.isGatekeeper())
      ) {
        if (notification.subType === Notification.SUB_TYPES.NEW_DELIVERY_OCCURRENCE) {
          this.paramsService.add('deliveryId', notification.occurrence.id);
          this.router.navigate(['gate/deliveries']);
          return;
        }
        this.router.navigate(['gate']);
        return;
      } else if (notification.subType == 'NEW-DELIVERY-OCCURRENCE' && this.user.isJanitor()) {
        this.router.navigate(['gate', 'deliveries']);
        return;
      }
      this.router.navigate(['feed/' + notification.occurrence.id]);
    }
  }

  changeSelectedCondo(notification, callback?) {
    const condoId = notification.condo.id || notification.condo;

    const backupDefaultCondo = this.user.defaultCondo;

    const condos = [].concat(this.user.condos, this.user.condosOwner, this.user.condosAdmin, this.user.condosGatekeeper);
    if (notification.type === 'USER-APPROVED' || notification.type === 'CONDO-REQUEST-APPROVED-TO-USER') {
      condos.push({ _id: notification.condo.id });
    }

    const condo = condos.find(c => c._id == condoId);

    if (!condo) {
      swal({
        type: 'error',
        title: 'Ops...',
        text:
          'Parece que você não pertence mais no(a) ' +
          (condo?.customLabels?.condo?.singular || backupDefaultCondo?.customLabels?.condo?.singular) +
          ' desta notificação.'
      });
      return;
    }

    this.user.defaultCondo = condo;
    this.userService
      .changeUserDefaultCondo(this.user._id, condo._id)
      .pipe(
        timeout(10000),
        flatMap(() => this.userService.getMe().pipe(timeout(10000)))
      )
      .subscribe(
        (me: any) => {
          this.user = me;
          this.utilService.saveLocalUser(me);
          this.utilService.saveLocalCondo(me.defaultCondo);

          if (callback) {
            callback();
          }
        },
        err => {
          this.user.defaultCondo = backupDefaultCondo;
          swal({
            type: 'error',
            text:
              'Houve um problema ao realizar a troca do(a) ' +
              condo?.customLabels?.condo?.singular +
              '. \n Clique em OK para recarregar a página.'
          }).then(() => {
            window.location.reload();
          });
        }
      );
  }

  showNotificationManager() {
    const initialState = {
      user: this.user
    };
    this.modalService.show(ModalNotificationManagerComponent, { initialState, class: '', ignoreBackdropClick: true });
  }

  clearNotifications() {
    this.notifications = [];
    this.hide();
    this.totalOfNonReadNotifications = 0;
  }
}
