export class CondoCustomParam {
  public static TYPES = {
    FINANCE_TRANSACTION_CATEGORY_IN: 'FINANCE_TRANSACTION_CATEGORY_IN',
    FINANCE_TRANSACTION_CATEGORY_OUT: 'FINANCE_TRANSACTION_CATEGORY_OUT',
    CONDO_CHARGE_CATEGORY: 'CONDO_CHARGE_CATEGORY'
  };

  public _id: string = '';
  private _values: Array<string> = [];
  private _type: string = '';

  constructor(customParam?) {
    if (customParam) {
      this._id = customParam._id || '';
      this._values = customParam.values || [];
      this._type = customParam.type || '';
    }
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get values(): Array<string> {
    return this._values;
  }

  set values(value: Array<string>) {
    this._values = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }
}
