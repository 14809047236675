import { Component } from '@angular/core';

@Component({
  selector: 'spinner-component',
  styleUrls: ['./spinner.component.scss'],
  template: `
    <div class="spinner">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  `
})
export class SpinnerComponent {}
