import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({ providedIn: 'root' })
export class ConstantService {
  public static TEMPLATE_URL =
    'https://s3.amazonaws.com/econdos-staging/user/58123aa3bc50d9001084a8ad/efa4a100-a37e-11e7-a0a8-41b20c0eedf9/eCondos-Importacao.xlsx';
  public static ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=br.com.econdos.app&hl=en';
  public static IOS_APP_URL = 'https://appsto.re/br/URJmeb';

  public dynamicEnvironment: typeof environment;

  constructor() {}

  /**
   * @deprecated The method should not be used - Use getV2Endpoint instead
   */
  getEndpoint() {
    return environment.backendUrl;
  }
  getV2Endpoint() {
    return environment.backendUrlV2;
  }
  getV3Endpoint() {
    return environment.backendUrlV3;
  }

  setEnvironment(environment) {
    this.dynamicEnvironment = environment;
  }
}
