import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ThemeModule } from '../../theme';
import { ModalCreateDeviceComponent } from './modal-create-device.component';
import { ComponentsModule } from '../../components/components.module';
import { CreateDeviceBaseStepperComponent } from './create-device-base-stepper/create-device-base-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CreateDeviceStepComponent } from './create-device-step/create-device-step.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HardwareCardComponent } from './custom/hardware-card/hardware-card.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PipesModule } from '../../pipe/pipes.module';
import { LicensePlateComponent } from './custom/license-plate/license-plate.component';
import { AccessGroupPickerComponent } from './custom/access-group-picker/access-group-picker.component';
import { PasswordInputComponent } from './custom/password-input/password-input.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { QRCodeModule } from 'angularx-qrcode';
import { BiometricHandComponent } from '../../components/biometric-hand/biometric-hand.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    TypeaheadModule,
    ComponentsModule,
    ThemeModule,
    CdkStepperModule,
    FontAwesomeModule,
    PopoverModule,
    PipesModule,
    QRCodeModule,
    AngularSvgIconModule,
    BiometricHandComponent,
    TooltipModule
  ],
  declarations: [
    ModalCreateDeviceComponent,
    CreateDeviceBaseStepperComponent,
    CreateDeviceStepComponent,
    HardwareCardComponent,
    LicensePlateComponent,
    AccessGroupPickerComponent,
    PasswordInputComponent
  ],
  exports: [AccessGroupPickerComponent]
})
export class ModalCreateDeviceModule {}
