export function getCarBrands() {
  return {
    citroen: 'Citroën',
    fiat: 'Fiat',
    ford: 'Ford',
    'gm-chevrolet': 'GM - Chevrolet',
    honda: 'Honda',
    hyundai: 'Hyundai',
    jac: 'JAC',
    'kia-motors': 'Kia Motors',
    nissan: 'Nissan',
    peugeot: 'Peugeot',
    renault: 'Renault',
    toyota: 'Toyota',
    'vw-volkswagen': 'VW - VolksWagen',
    acura: 'Acura',
    agrale: 'Agrale',
    'alfa-romeo': 'Alfa Romeo',
    'am-gen': 'AM Gen',
    'asia-motors': 'Asia Motors',
    'aston-martin': 'ASTON MARTIN',
    audi: 'Audi',
    bmw: 'BMW',
    brm: 'BRM',
    buggy: 'Buggy',
    bugre: 'Bugre',
    cadillac: 'Cadillac',
    'cbt-jipe': 'CBT Jipe',
    chana: 'CHANA',
    changan: 'CHANGAN',
    chery: 'CHERY',
    chrysler: 'Chrysler',
    'cross-lander': 'Cross Lander',
    daewoo: 'Daewoo',
    daihatsu: 'Daihatsu',
    'dkw-vemag': 'Dkw Vemag',
    dodge: 'Dodge',
    effa: 'EFFA',
    engesa: 'Engesa',
    envemo: 'Envemo',
    ferrari: 'Ferrari',
    fibravan: 'Fibravan',
    foton: 'FOTON',
    fyber: 'Fyber',
    geely: 'GEELY',
    'great-wall': 'GREAT WALL',
    gurgel: 'Gurgel',
    hafei: 'HAFEI',
    infiniti: 'Infiniti',
    isuzu: 'Isuzu',
    jaguar: 'Jaguar',
    jeep: 'Jeep',
    jinbei: 'JINBEI',
    jpx: 'JPX',
    lada: 'Lada',
    lamborghini: 'LAMBORGHINI',
    'land-rover': 'Land Rover',
    landwind: 'Landwind',
    lexus: 'Lexus',
    lifan: 'LIFAN',
    lobini: 'LOBINI',
    lotus: 'Lotus',
    mahindra: 'Mahindra',
    maserati: 'Maserati',
    matra: 'Matra',
    mazda: 'Mazda',
    'mercedes-benz': 'Mercedes-Benz',
    mercury: 'Mercury',
    mg: 'MG',
    mini: 'MINI',
    mitsubishi: 'Mitsubishi',
    miura: 'Miura',
    plymouth: 'Plymouth',
    pontiac: 'Pontiac',
    porsche: 'Porsche',
    puma: 'Puma',
    ram: 'RAM',
    rely: 'RELY',
    'rolls-royce': 'Rolls-Royce',
    rover: 'Rover',
    saab: 'Saab',
    saturn: 'Saturn',
    seat: 'Seat',
    shineray: 'SHINERAY',
    smart: 'smart',
    ssangyong: 'SSANGYONG',
    subaru: 'Subaru',
    suzuki: 'Suzuki',
    tac: 'TAC',
    tesla: 'Tesla',
    troller: 'Troller',
    volvo: 'Volvo',
    wake: 'Wake',
    walk: 'Walk'
  };
}

export function getMotoBrands() {
  return {
    adly: 'ADLY',
    agrale: 'AGRALE',
    amazonas: 'AMAZONAS',
    aprilia: 'APRILIA',
    atala: 'ATALA',
    bajaj: 'BAJAJ',
    benelli: 'Benelli',
    beta: 'BETA',
    bimota: 'BIMOTA',
    bmw: 'BMW',
    brandy: 'BRANDY',
    brava: 'BRAVA',
    brp: 'BRP',
    buell: 'BUELL',
    bueno: 'BUENO',
    bycristo: 'byCristo',
    cagiva: 'CAGIVA',
    caloi: 'CALOI',
    daelim: 'DAELIM',
    dafra: 'DAFRA',
    dayang: 'DAYANG',
    dayun: 'DAYUN',
    derbi: 'DERBI',
    ducati: 'DUCATI',
    emme: 'EMME',
    fox: 'FOX',
    fym: 'FYM',
    garinni: 'GARINNI',
    'gas-gas': 'GAS GAS',
    green: 'GREEN',
    haobao: 'HAOBAO',
    'harley-davidson': 'HARLEY-DAVIDSON',
    hartford: 'HARTFORD',
    hero: 'HERO',
    honda: 'HONDA',
    husaberg: 'HUSABERG',
    husqvarna: 'HUSQVARNA',
    indian: 'INDIAN',
    iros: 'IROS',
    'jiapeng-volcano': 'JIAPENG VOLCANO',
    johnnypag: 'JOHNNYPAG',
    jonny: 'JONNY',
    kahena: 'KAHENA',
    kasinski: 'KASINSKI',
    kawasaki: 'KAWASAKI',
    kimco: 'KIMCO',
    ktm: 'KTM',
    laquila: "L'AQUILA",
    landum: 'LANDUM',
    lavrale: 'LAVRALE',
    lerivo: 'LERIVO',
    lifan: 'LIFAN',
    'lon-v': 'Lon-V',
    'magrao-triciclos': 'MAGRÃO TRICICLOS',
    malaguti: 'Malaguti',
    miza: 'MIZA',
    'moto-guzzi': 'MOTO GUZZI',
    motocar: 'MOTOCAR',
    motorino: 'MOTORINO',
    mrx: 'MRX',
    'mv-agusta': 'MV AGUSTA',
    mvk: 'MVK',
    orca: 'ORCA',
    pegassi: 'PEGASSI',
    peugeot: 'PEUGEOT',
    piaggio: 'PIAGGIO',
    'regal-raptor': 'REGAL RAPTOR',
    riguete: 'RIGUETE',
    'royal-enfield': 'Royal Enfield',
    sanyang: 'SANYANG',
    shineray: 'SHINERAY',
    siamoto: 'SIAMOTO',
    sundown: 'SUNDOWN',
    suzuki: 'SUZUKI',
    targos: 'TARGOS',
    tiger: 'TIGER',
    traxx: 'TRAXX',
    triumph: 'TRIUMPH',
    vento: 'VENTO',
    wuyang: 'WUYANG',
    yamaha: 'YAMAHA'
  };
}

export function getBicycleBrands() {
  return {
    bmc: 'BMC',
    brompton: 'BROMPTON',
    caloi: 'CALOI',
    cannondale: 'CANNONDALE',
    cervelo: 'CERVELO',
    colli: 'COLLI',
    dahon: 'DAHON',
    durban: 'DURBAN',
    focus: 'FOCUS',
    giant: 'GIANT',
    gt: 'GT',
    gtsm1: 'GTSM1',
    'houston-bike': 'HOUSTON_BIKE',
    kona: 'KONA',
    linus: 'LINUS',
    merida: 'MERIDA',
    monark: 'MONARK',
    nathor: 'NATHOR',
    raleigh: 'RALEIGH',
    salsa: 'SALSA',
    'santa-cruz': 'SANTA CRUZ',
    scott: 'SCOTT',
    sense: 'SENSE',
    soul: 'SOUL',
    surly: 'SURLY',
    specialized: 'SPECIALIZED',
    tomasso: 'TOMASSO',
    trek: 'TREK',
    'verden-bikes': 'VERDEN BIKES',
    woom: 'WOOM',
    'yeti-cycles': 'YETI CYCLES'
  };
}
