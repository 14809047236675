import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { CondoVehicle } from '@api/model/condo.vehicle';
import swal from 'sweetalert2';

@Component({
  selector: 'app-vehicle-color-auto-complete',
  templateUrl: 'vehicle-color-auto-complete.component.html',
  styleUrls: ['vehicle-color-auto-complete.component.scss']
})
export class VehicleColorAutoCompleteComponent {
  @Output() selectItem: EventEmitter<Residence> = new EventEmitter<Residence>();
  @Input() placeholder = 'Buscar cor';
  @Input() disabled = false;
  @Input() container = 'body';

  selectedColor = '';
  VEHICLES_COLORS: string[] = [];

  noResult = false;

  onTypeAheadSelect = evt => {
    this.selectedColor = evt;

    if (evt.value === '+ OUTRA COR') {
      swal({
        title: 'Inserir cor',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Salvar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputPlaceholder: 'Digite a cor...',
        showLoaderOnConfirm: true,
        preConfirm: color => {
          if (!color || !color.trim().length) {
            return Promise.reject(`Insira a cor`);
          } else {
            color = color.trim();
            return Promise.resolve(color);
          }
        }
      }).then(
        color => {
          evt.value = color;

          this.selectItem.emit(evt);
        },
        () => {
          evt.value = '';

          this.selectItem.emit(evt);
        }
      );
    } else {
      this.selectItem.emit(evt);
    }

    this.resetValue();
  };

  constructor() {
    this.VEHICLES_COLORS = Object.keys(CondoVehicle.VEHICLES_COLORS).map(color => CondoVehicle.VEHICLES_COLORS[color]);
    this.VEHICLES_COLORS.unshift('+ OUTRA COR');
  }

  resetValue() {
    this.selectedColor = '';
  }

  typeaheadOnBlur(event: any): void {
    this.resetValue();
  }
}
