import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReservationService } from '@api/service/reservation.service';

import swal from 'sweetalert2';
import { catchError, filter, map, mergeMap, switchMap, tap, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Local } from '@api/model/local';
import { LocalGroup } from '@api/model/local-group';
import { Condo } from '@api/model/condo';
import { SessionService } from '@api/service/session.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EcondosQuery } from '@api/model/query';
import { EMPTY, from, merge, noop, Observable } from 'rxjs';
import { Reservation } from '@api/model/reservation';

interface ReservationLocalFormGroup {
  name: FormControl<string>;
  description: FormControl<string>;
  params: FormGroup<{
    canReserveMultipleLocals: FormControl<boolean>;
    limitOfMultipleReservations: FormControl<number>;
  }>;
  reservationLocals: FormControl<Local[]>;
}

@Component({
  selector: 'app-create-reservation-local-group',
  templateUrl: 'create-local-group.modal.html'
})
export class CreateLocalGroupPageComponent implements OnInit {
  reservationLocalGroup: LocalGroup;
  availableLocals: Local[];

  localForm: FormGroup<ReservationLocalFormGroup>;
  name: ReservationLocalFormGroup['name'];
  description: ReservationLocalFormGroup['description'];
  canReserveMultipleLocals: FormControl<boolean>;
  limitOfMultipleReservations: FormControl<number>;
  reservationLocals: ReservationLocalFormGroup['reservationLocals'];

  selectedReservationLocals: FormControl<string[]>;

  condo: Condo;

  isSubmiting = false;

  DEFAULT_PARAMS = {
    canReserveMultipleLocals: true,
    limitOfMultipleReservations: 0
  };

  callbacks: { success?: (arg) => void; error?: (err) => {} };

  constructor(
    private sessionService: SessionService,
    private toastr: ToastrService,
    private reservationService: ReservationService,
    private bsModalRef: BsModalRef
  ) {
    this.condo = this.sessionService.condoValue;

    this.localForm = new FormGroup<ReservationLocalFormGroup>({
      name: new FormControl('', Validators.compose([Validators.required, Validators.minLength(3)])),
      description: new FormControl(''),
      params: new FormGroup({
        canReserveMultipleLocals: new FormControl(this.DEFAULT_PARAMS.canReserveMultipleLocals, Validators.required),
        limitOfMultipleReservations: new FormControl(this.DEFAULT_PARAMS.limitOfMultipleReservations, Validators.required)
      }),
      reservationLocals: new FormControl([])
    });

    this.name = this.localForm.controls.name;
    this.description = this.localForm.controls.description;
    this.canReserveMultipleLocals = this.localForm.controls.params.controls.canReserveMultipleLocals;
    this.limitOfMultipleReservations = this.localForm.controls.params.controls.limitOfMultipleReservations;
    this.reservationLocals = this.localForm.controls.reservationLocals;
    this.selectedReservationLocals = new FormControl(['']);

    this.selectedReservationLocals.valueChanges.subscribe(value => {
      const selectedLocals = this.availableLocals.filter(local => value.includes(local._id));
      this.reservationLocals.setValue(selectedLocals);
    });
  }

  ngOnInit() {
    if (this.reservationLocalGroup) {
      this.name.setValue(this.reservationLocalGroup.name);
      this.description.setValue(this.reservationLocalGroup.description);
      this.canReserveMultipleLocals.setValue(
        this.reservationLocalGroup.params?.canReserveMultipleLocals === false
          ? false
          : this.reservationLocalGroup.params?.canReserveMultipleLocals || this.DEFAULT_PARAMS.canReserveMultipleLocals
      );
      this.limitOfMultipleReservations.setValue(
        this.reservationLocalGroup.params?.limitOfMultipleReservations || this.DEFAULT_PARAMS.limitOfMultipleReservations
      );
      this.reservationLocals.setValue(this.reservationLocalGroup.reservationLocals);
      this.selectedReservationLocals.setValue(
        this.reservationLocalGroup.reservationLocals.map(local => local._id),
        { emitEvent: false }
      );
    }
  }

  saveOrUpdate() {
    if (!this.localForm.valid) {
      for (const key of Object.keys(this.localForm.controls)) {
        this.localForm.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha todos os campos obrigatórios');
      return;
    }
    const localGroupData = this.localForm.value;
    const dataToSend = {
      name: localGroupData.name,
      description: localGroupData.description,
      params: {
        canReserveMultipleLocals: localGroupData.params.canReserveMultipleLocals,
        limitOfMultipleReservations: localGroupData.params.limitOfMultipleReservations
      },
      reservationLocals: localGroupData.reservationLocals
    };
    if (this.reservationLocalGroup) {
      this.askToCancelAffectedReservations(dataToSend);
    } else {
      this.createLocal(dataToSend);
    }
  }

  createLocal(localGroupData: LocalGroup) {
    this.isSubmiting = true;
    this.reservationService
      .createReservationLocalGroup(this.condo._id, localGroupData)
      .pipe(timeout(10000))
      .subscribe({
        next: ({ localGroupId }) => {
          localGroupData._id = localGroupId;
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(localGroupData);
          }
          this.closeModal();
        },
        error: err => {
          this.isSubmiting = false;
          swal({
            type: 'error',
            title: `${err.messageTitle || 'Ops...'} `,
            text: `${err.message || 'Não foi possível criar o grupo, tente novamente...'}`,
            cancelButtonColor: '#f53d3d'
          });
        }
      });
  }

  updateLocal(localGroupData: LocalGroup) {
    this.isSubmiting = true;
    this.reservationService
      .updateReservationLocalGroup(this.condo._id, this.reservationLocalGroup._id, localGroupData)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          localGroupData._id = this.reservationLocalGroup._id;
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(localGroupData);
          }
          this.closeModal();
        },
        error: err => {
          this.isSubmiting = false;
          swal({
            type: 'error',
            title: `${err.messageTitle || 'Ops...'} `,
            text: `${err.message || 'Não foi possível atualizar este grupo, tente novamente...'}`,
            cancelButtonColor: '#f53d3d'
          });
        }
      });
  }

  private getAllReservations(query: EcondosQuery = {}): Observable<Reservation[]> {
    return this.reservationService.getReservationsReport(this.condo._id, query).pipe(
      timeout(15000),
      mergeMap(([finalResult]) => merge(finalResult)),
      map(({ data }) => data as Reservation[])
    );
  }

  askToCancelAffectedReservations(localGroup: LocalGroup) {
    const removedGroups = this.reservationLocalGroup?.reservationLocals.filter(
      local => !localGroup?.reservationLocals?.some(groupLocal => local._id === groupLocal._id)
    );
    if (!removedGroups.length) {
      this.updateLocal(localGroup);
      return;
    }
    const query: EcondosQuery = {
      otherReservationLocals: { $in: removedGroups.map(local => local._id) }
    };
    this.getAllReservations(query)
      .pipe(
        switchMap(reservations => {
          if (!reservations.length) {
            this.updateLocal(localGroup);
            return EMPTY;
          }
          return from(
            swal({
              type: 'warning',
              title: 'Existem reservas ativas para os locais do grupo',
              text:
                'Se você continuar sem cancelar as reservas ativas, o sistema poderá criar reservas com horários conflitantes!\n' +
                'Deseja cancelar as reservas ativas antes de prosseguir?\n' +
                'Digite "cancelar reservas ativas" para confirmar sua ação.',
              showCancelButton: true,
              input: 'text',
              inputPlaceholder: 'cancelar reservas ativas',
              confirmButtonText: 'Cancelar reservas ativas',
              confirmButtonColor: '#f53d3d',
              cancelButtonClass: 'btn-outline-danger',
              cancelButtonText: 'Continuar',
              reverseButtons: true,
              showLoaderOnConfirm: true,
              preConfirm: async input => {
                input = (input || '').toString().trim().toLowerCase();
                if (!input || input !== 'cancelar reservas ativas') {
                  return Promise.reject(`Digite "cancelar reservas ativas" para confirmar sua ação`);
                }
                return Promise.resolve(reservations);
              }
            })
          ).pipe(
            catchError(() => {
              swal({
                type: 'question',
                text: `Certifique-se que grupo ${this.reservationLocalGroup.name} não possui nenhuma reserva ativa antes de editar. Se houver, o sistema poderá criar reservas com horários conflitantes`,
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#32DB64',
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Voltar',
                reverseButtons: true
              }).then(
                () => {
                  this.updateLocal(localGroup);
                },
                error => {
                  console.log(error);
                }
              );
              return EMPTY;
            })
          );
        }),
        filter((reservations: Reservation[]) => !!reservations?.length),
        switchMap((reservations: Reservation[]) => {
          return this.reservationService
            .bulkCancelReservationsWithGroup(
              this.condo._id,
              this.reservationLocalGroup._id,
              reservations.map(reservation => reservation._id)
            )
            .pipe(tap(() => this.updateLocal(localGroup)));
        })
      )
      .subscribe(noop);
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
