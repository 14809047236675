import { Injectable } from '@angular/core';
// @ts-ignore
import Peer from 'peerjs';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpService } from './http.service';
import { ConstantService } from './constant.service';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'app/services/util.service';
import { HardwareSocketService } from 'app/services/hardware-socket.service';
import { User } from '@api/model/user';
import { CondoCustomLabelPipe } from '../pipe/condo-custom-label.pipe';
import { Condo } from '@api/model/condo';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BuildCall, Call, CallUserType, CallStatus } from '@api/model/call';
import { SessionService } from '@api/service/session.service';

export type CallEventType = 'CREATED' | 'RECEIVED' | 'ANSWERED' | 'ESTABLISHED' | 'HANGUP';

export interface RegisterCallHistory {
  _id?: string;
  condo: string;
  calleeType: CallUserType;
  callee: string;
  caller: string;
  duration: number;
  status: CallStatus;
}

export interface IntercomUserData {
  _id: string;
  firstName: string;
  lastName: string;
  picture?: {
    url: string;
  };
}

interface CallEventData {
  call?: Call;
  caller?: IntercomUserData;
  callee?: IntercomUserData;
  answeredBy?: IntercomUserData;
  userId?: string;
}

export interface CallEvent {
  type: CallEventType;
  data?: CallEventData;
}

interface IntercomSocketEventsHandlers {
  [eventName: string]: (data: CallEventData) => void;
}

@Injectable()
export class CallService {
  private user: User;

  private isIntercomEnabledBs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isIntercomEnabled$ = this.isIntercomEnabledBs.asObservable().pipe(distinctUntilChanged());

  private peer;
  private mediaCall;

  private localStreamBs: BehaviorSubject<MediaStream> = new BehaviorSubject(null);
  public localStream$ = this.localStreamBs.asObservable();

  private remoteStreamBs: BehaviorSubject<MediaStream> = new BehaviorSubject(null);
  public remoteStream$ = this.remoteStreamBs.asObservable();

  private onCallEventBs: BehaviorSubject<CallEvent> = new BehaviorSubject(null);
  public onCallEvent$ = this.onCallEventBs.asObservable();

  private intercomSocketEventsHandlers: IntercomSocketEventsHandlers = {};

  private readonly endPoint;

  public userStream: MediaStream = null;

  private peerOpenS = new Subject();
  public peerOpen$ = this.peerOpenS.asObservable();

  private handleICEConnectionStateChangeEvent = event => {
    const callHasEnded =
      this.mediaCall.peerConnection.iceConnectionState === 'closed' ||
      this.mediaCall.peerConnection.iceConnectionState === 'failed' ||
      this.mediaCall.peerConnection.iceConnectionState === 'disconnected';

    if (callHasEnded) {
      this.onCallEventBs.next({ type: 'HANGUP' });
    }
  };

  constructor(
    private toastrService: ToastrService,
    private sessionService: SessionService,
    private http: HttpService,
    private constantService: ConstantService,
    private hardwareSocketService: HardwareSocketService,
    private condoCustomLabelPipe: CondoCustomLabelPipe
  ) {
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;

    this.user = this.sessionService.userValue;

    this.initializeChatSocketEventsHandlers();

    this.hardwareSocketService.onIntercomData$.subscribe(({ event, data }) => {
      if (event) {
        this.intercomSocketEventsHandlers[event](data);
      }
    });
  }

  public initialize(user: User, condo: Condo) {
    if (user) {
      const isIntercomEnabledOnCondo = condo?.params?.intercom === 'ENABLED';
      this.isIntercomEnabledBs.next(isIntercomEnabledOnCondo);
    }
  }

  public terminate() {
    this.isIntercomEnabledBs.next(false);
  }

  private initializeChatSocketEventsHandlers() {
    this.intercomSocketEventsHandlers['newIntercomIncomingCall'] = (data: CallEventData) => {
      const callStatus = this.onCallEventBs?.value?.type;

      if (callStatus !== 'ANSWERED' && callStatus !== 'ESTABLISHED') {
        this.onCallEventBs.next({ type: 'RECEIVED', data });
      } else {
        const residentLabel = this.condoCustomLabelPipe.transform('resident');
        const callerName = `${data.caller.firstName} ${data.caller.lastName}`;

        this.toastrService.info(`Você recebeu uma nova ligação do ${residentLabel} ${callerName} durante a chamada atual.`);
      }
    };

    this.intercomSocketEventsHandlers['intercomCallHangup'] = () => {
      this.onCallEventBs.next({ type: 'HANGUP' });
    };

    this.intercomSocketEventsHandlers['intercomCallAttendedByGateKeeper'] = (data: CallEventData) => {
      const wasAnsweredByMe = data.answeredBy._id === this.user._id;

      if (!wasAnsweredByMe) {
        this.toastrService.info(`Chamada atendida por ${data.answeredBy.firstName} ${data.answeredBy.lastName}`);

        this.onCallEventBs.next({ type: 'HANGUP' });
      }
    };
  }

  public call(data: CallEventData) {
    this.onCallEventBs.next({ type: 'CREATED', data });
  }

  public initPeer(): string {
    if (!this.peer || this.peer.disconnected) {
      const peerJsOptions = {
        debug: 3,
        secure: true,
        host: 'peerjs-server-efone.herokuapp.com',
        port: 443,
        config: {
          iceServers: [
            {
              urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302']
            }
          ]
        }
      };
      try {
        const id = `ECONDOS_${this.sessionService.userValue._id}`;
        this.peer = new Peer(id, peerJsOptions);

        this.peer.on('open', () => {
          this.peerOpenS.next(true);
        });
        return id;
      } catch (error) {
        console.error(error);
      }
    }
  }

  public async answer(remotePeerId: string) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      this.userStream = stream;

      const connection = this.peer.connect(remotePeerId);
      connection.on('error', err => {
        console.error(err);
        this.toastrService.error(err);
      });

      this.mediaCall = this.peer.call(remotePeerId, stream);

      if (!this.mediaCall) {
        const errorMessage = 'Não foi possível estabelecer a conexão';
        this.toastrService.error(errorMessage);
        throw new Error(errorMessage);
      }

      this.localStreamBs.next(stream);

      this.onCallEventBs.next({ type: 'ANSWERED' });

      this.mediaCall.on('stream', remoteStream => {
        this.remoteStreamBs.next(remoteStream);
      });

      this.mediaCall.on('error', err => {
        this.toastrService.error(err);
        console.error(err);
        this.onCallEventBs.next({ type: 'HANGUP' });
      });

      this.mediaCall.on('close', () => this.onCallClose());

      this.mediaCall.peerConnection.oniceconnectionstatechange = this.handleICEConnectionStateChangeEvent;
    } catch (ex) {
      this.handleException(ex);
    }
  }

  public async initializeCallEstablishedHandler() {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      this.userStream = mediaStream;

      this.localStreamBs.next(mediaStream);

      this.peer.on('call', async call => {
        this.onCallEstablished(call, mediaStream);
      });
    } catch (ex) {
      this.handleException(ex);
    }
  }

  private onCallEstablished(call, mediaStream: MediaStream) {
    this.mediaCall = call;
    this.onCallEventBs.next({ type: 'ESTABLISHED' });

    this.mediaCall.answer(mediaStream);

    this.mediaCall.on('stream', remoteStream => {
      this.remoteStreamBs.next(remoteStream);
    });

    this.mediaCall.on('error', err => {
      this.toastrService.error(err);
      this.onCallEventBs.next({ type: 'HANGUP' });
      console.error(err);
    });

    this.mediaCall.on('close', () => this.onCallClose());

    this.mediaCall.peerConnection.oniceconnectionstatechange = this.handleICEConnectionStateChangeEvent;
  }

  private onCallClose() {
    this.remoteStreamBs?.value?.getTracks().forEach(track => {
      track.stop();
    });

    this.localStreamBs?.value?.getTracks().forEach(track => {
      track.stop();
    });
  }

  public closeMediaCall() {
    this.mediaCall?.close();

    if (!this.mediaCall) {
      this.onCallClose();
    }

    this.onCallEventBs.next({ type: 'HANGUP' });
  }

  public destroyPeer() {
    this.mediaCall?.close();
    this.peer?.disconnect();
    this.peer?.destroy();
  }

  public notifyIncomingCall(condoId: string, to: string): Observable<{ callId: string }> {
    return this.http
      .post(`${this.endPoint}${condoId}/intercom/notify-incoming-call`, { to })
      .pipe(map((response: any) => ({ callId: response._id })));
  }

  public notifyCallHangup(condoId: string, to: string, call: RegisterCallHistory) {
    return this.http.post(`${this.endPoint}${condoId}/intercom/notify-call-hangup/${call._id}`, { to, call });
  }

  public notifyCallAttendedByGateKeeper(condoId: string) {
    return this.http.post(`${this.endPoint}${condoId}/intercom/notify-call-attended-by-gatekeeper`, {});
  }

  public getCallHistory(condoId: string, query: EcondosQuery = {}): Observable<{ count: number; history: Call[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/intercom/history`, options).pipe(
      map((response: any) => ({
        count: response.headers.get('count'),
        history: response.body.map((historyItem: any) => BuildCall(historyItem))
      }))
    );
  }

  private handleException(ex) {
    console.error(ex);

    if (ex.name === 'NotAllowedError' || ex.name === 'NotFoundError') {
      this.toastrService.error(
        'Sem o acesso ao microfone do dispositivo, a utilização do interfone não será possível.',
        'Microfone inexistente ou acesso negado!'
      );
    } else {
      this.toastrService.error(ex);
    }

    this.onCallEventBs.next({ type: 'HANGUP' });
  }
}
