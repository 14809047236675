import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { setTheme } from 'ngx-bootstrap/utils';
import * as moment from 'moment';
import { NavbarService } from './pages/navbar/navbar.service';
import { UserService } from './api/service/user.service';
import { filter, map, retry, switchMap, tap, timeout } from 'rxjs/operators';
import { environment } from '@environment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { WorkerService } from './services/worker.service';
import { noop, timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { CallService } from './services/call.service';
import { ChatServiceV2 } from '@api/serviceV2/chat.service';
import { SessionService } from '@api/service/session.service';
import { UtilService } from './services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public showNavBar: Boolean;

  public isIOS = false;
  public isAndroid = false;

  env = environment;

  initFileSize: number;
  hasNewUpdate = false;

  constructor(
    public router: Router,
    private navbarService: NavbarService,
    private sessionService: SessionService,
    private userService: UserService,
    private utilService: UtilService,
    private localeService: BsLocaleService,
    private workerService: WorkerService,
    @Inject(DOCUMENT) private document: Document,
    public callService: CallService,
    public chatService: ChatServiceV2
  ) {
    setTheme('bs5');
    this.localeService.use('pt-br');
    moment.locale('pt-br');

    this.updateLocalUser();
    this.navbarService.showNavBarEmitter.subscribe(mode => {
      // mode will be null the first time it is created, so you need to igonore it when null
      if (mode !== null) {
        setTimeout(() => {
          this.showNavBar = mode;
        });
      } else {
        setTimeout(() => {
          this.showNavBar = true;
        });
      }
    });
  }

  // TODO Utilizar futuramente para verificar se acessa via mobile e exibir mensagem com link para app
  ngOnInit() {
    // Mobiles removed (iPad|Opera Mini|IEMobile|webOS|Mobile)
    const iosExp = new RegExp('iPhone');
    const androidExp = new RegExp('Android|Windows Phone|BlackBerry');

    if (androidExp.test(navigator.userAgent)) {
      this.isAndroid = true;
    }

    if (iosExp.test(navigator.userAgent)) {
      this.isIOS = true;
    }

    /**
     * Removido para teste em reduzir custos de servidor
     */
    // Observable que verifica se há atualização a partir de um arquivo no diretório root do projeto
    // timer(900000, 7200000)
    //   .pipe(
    //     filter(() => environment.production),
    //     map(() => (this.document.querySelectorAll('script[src*="main"]')[0] as any)?.src ?? ''),
    //     filter(value => !!value),
    //     switchMap((src: string) => this.workerService.getLatestVersion(src)),
    //     tap(res => (!res.error && this.initFileSize === undefined ? (this.initFileSize = res.fileSize) : null)),
    //     filter((res: any) => res.fileSize !== this.initFileSize && !res.error),
    //     tap(() => (this.hasNewUpdate = true))
    //   )
    //   .subscribe(noop);
  }

  // Método utilizado para atualizar o usuário sempre que a página for recarregada através de F5.
  updateLocalUser() {
    if (this.sessionService.userValue) {
      this.userService
        .getMe()
        .pipe(retry(2), timeout(10000))
        .subscribe(
          user => {
            this.sessionService.setUser(user);
            this.sessionService.setCondo(user.defaultCondo);
            if (user.token) {
              this.utilService.saveLocalToken(user.token);
            }
          },
          err => {
            console.log(err);
            console.log('Timeout on user update');
          }
        );
    }
  }

  // Método utilizado para forçar atualização na página sem usar o cache
  updateAndForceReload() {
    this.hasNewUpdate = false;
    window.location.href = window.location.href;
  }
}
