/**
 * Created by Rafael on 18/12/2018.
 */
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Local } from '@api/model/local';

@Component({
  selector: 'app-modal-local-description',
  templateUrl: './local.description.modal.html',
  styleUrls: ['./local.description.modal.scss']
})
export class ModalLocalDescriptionComponent {
  reservationLocal: Local;

  constructor(public bsModalRef: BsModalRef) {}

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
