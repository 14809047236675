import { Component, AfterContentInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { ConstantService } from '../../services/constant.service';

@Component({
  selector: 'app-suggestion-panel',
  templateUrl: 'app.suggestion.panel.html'
})
export class AppSuggestionComponent implements AfterContentInit {
  private appUrl: string;
  public shouldShow: boolean;

  constructor(private appComponent: AppComponent) {}

  ngAfterContentInit(): void {
    if (this.appComponent.isIOS) {
      this.shouldShow = true;
      this.appUrl = ConstantService.IOS_APP_URL;
    }

    if (this.appComponent.isAndroid) {
      this.shouldShow = true;
      this.appUrl = ConstantService.ANDROID_APP_URL;
    }
  }

  hide() {
    this.shouldShow = false;
  }

  show() {
    this.shouldShow = true;
  }
}
