import { Component, ViewChild, Output, EventEmitter, Input, ApplicationRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UtilService } from '../../../services/util.service';
import { User } from '../../../api/model/user';
import { CondoService } from '../../../api/service/condo.service';
import { CondoContact } from '../../../api/model/contact/condo.contact';
import { ContactID } from '../../../api/model/contact/contact.id';
import { UntypedFormControl } from '@angular/forms';
import { CondoContactService } from '../../../api/service/condo.contact.service';
import { Condo } from '../../../api/model/condo';
import { debounceTime, distinctUntilChanged, timeout, timeoutWith } from 'rxjs/operators';

@Component({
  selector: 'modal-condo-contact-picker',
  animations: [
    trigger('checkAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0.5, 0.5)', opacity: 0 }),
        animate('200ms', style({ transform: 'scale(1,1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1, 1)', opacity: 1 }),
        animate('100ms', style({ transform: 'scale(0.1, 0.1)', opacity: 0 }))
      ])
    ])
  ],
  templateUrl: 'condo.contact.picker.modal.html'
})
export class CondoContactPickerModal {
  @ViewChild('condoContactPickerModal', { static: true }) public condoContactPickerModal: ModalDirective;

  @Output() onVisitorSelect = new EventEmitter();

  @Input() condo: Condo = this.utilService.getLocalCondo();

  public contacts = new Array<CondoContact>();
  public filteredContacts = new Array<CondoContact>();
  public selectedContact = new CondoContact();

  public user: User;

  public numberOfContacts;

  public loadingStatus;
  public LOADING = 'loading';
  public SUCCESS = 'success';
  public ERROR = 'error';

  private searchTerm: UntypedFormControl;

  constructor(
    private condoService: CondoService,
    private utilService: UtilService,
    private applicationRef: ApplicationRef,
    private condoContactService: CondoContactService
  ) {
    this.user = this.utilService.getLocalUser();
    this.loadUsers();

    this.searchTerm = new UntypedFormControl();

    this.searchTerm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      let val = term.trim();
      val = this.utilService.removeAccents(val);

      const temp = this.contacts.filter(contact => {
        let name = `${contact.firstName} ${contact.lastName}`;
        name = this.utilService.removeAccents(name);

        return !val || (name && name.toLowerCase().indexOf(val) !== -1);
      });
      this.filteredContacts = temp;
    });
  }

  private loadUsers() {
    this.loadingStatus = this.LOADING;
    this.condoContactService
      .getCondoContacts(this.condo._id, this.getQueryString())
      .pipe(timeout(10000))
      .subscribe(
        contacts => {
          (contacts || []).sort(function (u1: any, u2: any) {
            return ''.concat(u1.firstName, ' ', u1.lastName).localeCompare(''.concat(u2.firstName, ' ', u2.lastName));
          });
          this.contacts = contacts;
          this.numberOfContacts = contacts.length;
          this.loadingStatus = this.SUCCESS;
          this.filteredContacts = this.contacts;
        },
        err => {
          this.loadingStatus = this.ERROR;
        }
      );
  }

  selectUser(user) {
    if (this.selectedContact._id == user) {
      this.clearSelectedUsers();
    } else {
      this.selectedContact = user;
    }
  }

  getQueryString() {
    const qs = [];

    qs[qs.length] = '?';
    qs[qs.length] = '$populate[0][path]=picture';
    qs[qs.length] = '$populate[0][select]=thumbnail url';
    qs[qs.length] = '$populate[1][path]=ids.pictures';
    qs[qs.length] = '$populate[1][select]=url thumbnail type name format';
    qs[qs.length] = '$populate[2][path]=condoVehicles';
    qs[qs.length] = '$populate[2][select]=plate model type brand color pictures';
    qs[qs.length] = '$populate[2][populate][path]=pictures';
    qs[qs.length] = '$populate[2][populate][select]=url thumbnail type name format';

    return qs.join('&');
  }

  clearSelectedUsers() {
    this.selectedContact = new CondoContact();
  }

  closeModal(): void {
    this.clearSelectedUsers();
    this.condoContactPickerModal.hide();
  }

  showModal() {
    this.loadUsers();
    this.condoContactPickerModal.show();
  }

  confirmSelection() {
    this.onVisitorSelect.emit(this.selectedContact);
    this.closeModal();
  }

  getIDType(type) {
    let label;

    label = ContactID.TYPES_LABEL[type];

    if (label) {
      return label;
    }

    return type;
  }
}
