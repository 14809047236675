import { Component, TemplateRef, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { User } from '../../../api/model/user';
import { CondoContact } from '../../../api/model/contact/condo.contact';
import { Contact } from '../../../api/model/interface/contact';
import { CondoContactServiceV2 } from '../../../api/serviceV2/condo.contact.service';
import { ModalNewCondoSupplierComponent } from './new.condo.supplier/new.condo.supplier.modal';
import { LightboxComponent } from '../../../components/lightbox/lightbox';
import { timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { EcondosFilter } from '@api/model/filter';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { ContactID } from '@api/model/contact/contact.id';
import { TableColumnDefinition, TableComponent, TableStatus } from 'app/components/table/table.component';
import { ClearFiltersEventData, RemoveFilterEventData } from 'app/components/applied-filters/applied-filters.component';
import { Observable } from 'rxjs';
@Component({
  selector: 'condo-suppliers',
  templateUrl: 'condo.suppliers.html',
  styleUrls: ['./condo.suppliers.scss']
})
export class CondoSuppliersComponent implements OnInit {
  @ViewChild(ModalNewCondoSupplierComponent, { static: true }) newSupplierModal: ModalNewCondoSupplierComponent;

  @ViewChild(LightboxComponent, { static: true }) lightbox: LightboxComponent;
  @ViewChild('suppliersTable', { static: true }) suppliersTable: TableComponent;
  @ViewChild('companySupplierTemplate', { static: true }) companySupplierTemplate: TemplateRef<any>;
  @ViewChild('phoneSupplierTemplate', { static: true }) phoneSupplierTemplate: TemplateRef<any>;
  @ViewChild('emailSupplierTemplate', { static: true }) emailSupplierTemplate: TemplateRef<any>;
  @ViewChild('vehicleSupplierTemplate', { static: true }) vehicleSupplierTemplate: TemplateRef<any>;
  @ViewChild('documentSupplierTemplate', { static: true }) documentSupplierTemplate: TemplateRef<any>;

  public user: User;

  @Output() hasChanged = new EventEmitter();

  public condo;

  DOC_TYPES_LABELS = ContactID.TYPES_LABEL;
  private suppliers: Array<Contact> = new Array<Contact>();
  public filteredSuppliers: Array<Contact> = new Array<Contact>();

  public selectedSuppliers: Array<Contact> = new Array<Contact>();
  public selectedSupplier: CondoContact;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<Contact>[] = [];

  // Just to control pagination on template

  totalSuppliersCount = 0;

  initialQuery: EcondosQuery = {
    $select: 'firstName lastName phones company emails condoVehicles picture ids',
    $populate: [
      { path: 'picture', select: 'thumbnail url' },
      { path: 'ids.pictures', select: 'url thumbnail type name format' },
      {
        path: 'condoVehicles',
        select: 'plate model type brand color pictures',
        populate: [{ path: 'pictures', select: 'url thumbnail type name format' }]
      }
    ],
    type: Contact.TYPES.SUPPLIER,
    deleted: false,
    $and: [],
    $or: []
  };

  customQuery = { ...this.initialQuery };

  filters: EcondosFilter[] = [];
  isFiltered = false;

  constructor(private condoContactService: CondoContactServiceV2, private utilService: UtilService, private modalService: BsModalService) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();

    this.filters = [
      {
        element: 'input',
        elementType: 'text',
        elementSize: 'medium',
        name: 'company',
        label: 'Empresa',
        placeholder: 'Busque pela empresa',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementType: 'text',
        elementSize: 'medium',
        name: 'phones',
        label: 'Telefone',
        icon: 'phone',
        placeholder: 'Busque pelo telefone',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementType: 'text',
        elementSize: 'medium',
        name: 'name',
        label: 'Nome',
        icon: 'user',
        placeholder: 'Busque pelo nome',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementType: 'text',
        elementSize: 'medium',
        name: 'emails',
        label: 'Email',
        icon: 'envelope',
        placeholder: 'Busque pelo email',
        searchType: 'regex'
      },
      {
        element: 'vehicles-picker',
        elementSize: 'medium',
        name: 'condoVehicles',
        label: 'Veículo',
        params: {
          placeholder: 'Busque pela placa do veículo',
          condo: this.condo,
          filterCriteria: 'VISITORS_ONLY',
          showIcon: true
        },
        searchType: 'match'
      },
      {
        element: 'input',
        elementType: 'text',
        elementSize: 'medium',
        name: 'ids',
        icon: 'id-card',
        label: 'Documento',
        placeholder: 'Busque pelo documento',
        searchType: 'regex'
      }
    ];
  }

  ngOnInit() {
    this.tableColumns = [
      { columnId: 'company', headerLabel: 'Empresa', valueTemplate: this.companySupplierTemplate, sortable: true },
      { columnId: 'phones', headerLabel: 'Telefone', valueTemplate: this.phoneSupplierTemplate, sortable: true },
      { columnId: 'firstName', headerLabel: 'Nome', valueFn: supplier => supplier.firstName, sortable: true },
      { columnId: 'emails', headerLabel: 'Email', valueTemplate: this.emailSupplierTemplate, sortable: true },
      { columnId: 'condoVehicles', headerLabel: 'Veículos', valueTemplate: this.vehicleSupplierTemplate, sortable: true },
      { columnId: 'ids', headerLabel: 'Documentos', valueTemplate: this.documentSupplierTemplate, sortable: true },
      {
        type: 'actions',
        headerLabel: 'Ações',
        actionsButtons: [
          { icon: 'fa-pencil-square-o', title: 'Editar fornecedor', handler: (supplier: Contact) => this.editSupplier(supplier) },
          { icon: 'fa-trash', title: 'Excluir fornecedor', handler: (supplier: Contact) => this.askToDeleteSupplier(supplier) }
        ]
      }
    ];

    this.getData();
  }

  getData({ page = 0, token = '' } = {}) {
    const query = this.customQuery;
    let observable$: Observable<{ count: number; contacts: CondoContact[] }>;

    const { pageSize, sortedColumn, sortOrder } = this.suppliersTable.getCurrentState();

    query.$page = page;
    query.$limit = pageSize;

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    if (token) {
      observable$ = this.condoContactService.searchByToken(this.condo._id, token, query);
    } else {
      delete query.token;
      observable$ = this.condoContactService.getContacts(this.condo._id, query);
    }

    this.status = 'LOADING';

    observable$.pipe(timeout(10000)).subscribe(
      response => {
        this.totalSuppliersCount = response.count;
        const suppliers = response.contacts;
        this.suppliers = suppliers;
        this.filteredSuppliers = [].concat(this.suppliers);
        this.status = 'SUCCESS';
      },
      err => {
        this.status = 'ERROR';
        this.filteredSuppliers = this.suppliers = new Array<CondoContact>();
      }
    );
  }

  askToDeleteSupplier(supplierToDelete) {
    swal({
      text: `Deseja remover ${supplierToDelete.firstName} ${supplierToDelete.lastName} dos fornecedores?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.condoContactService
          .deleteCondoContact(this.condo._id, supplierToDelete.id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível excluir o fornecedor, tente novamente...');
          });
      }
    }).then(
      user => {
        this.selectedSupplier = null;
        this.getData();
        swal({
          title: 'Fornecedor removido com sucesso!'
        });
        this.suppliers = this.suppliers.filter(supplier => supplier._id !== supplierToDelete._id);

        this.hasChanged.emit();
      },
      error => {
        console.log(error);
      }
    );
  }

  showCreateSupplier() {
    this.newSupplierModal.showModal();
  }

  editSupplier(visitor) {
    this.newSupplierModal.showModal(visitor);
  }

  hideCreateSupplier() {
    this.newSupplierModal.closeModal();
  }

  onCondoSupplierCreated(condoContact: CondoContact) {
    this.suppliers.unshift(condoContact);
    this.selectedSupplier = condoContact;
    this.selectedSuppliers = [this.selectedSupplier];
    this.hasChanged.emit();
    this.getData();
  }

  onCondoSupplierUpdated(condoContact: CondoContact) {
    this.getData();
    this.hasChanged.emit();
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        const name = filters.find(filter => filter.name === 'name')?.value;
        let token = '';
        if (name) {
          delete query.name;
          token = name;
        }

        const ids = filters.find(filter => filter.name === 'ids')?.value;
        if (ids) {
          delete query.ids;
          token = (token || '').concat(ids);
        }

        this.filters = filters;
        this.isFiltered = this.filters.some(filter => !!filter.value);
        this.customQuery = query;
        this.suppliersTable.resetState({ currentPage: true });
        this.getData({ token });
      }
    };

    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  handleRemoveIndividualFilter({ filters, removedFilterName }: RemoveFilterEventData) {
    this.filters = filters;
    this.isFiltered = this.filters.some(filter => !!filter.value);

    delete this.customQuery[removedFilterName];
    this.getData();
  }

  handleClearFilters({ filters }: ClearFiltersEventData) {
    this.filters = filters;
    this.isFiltered = false;
    this.customQuery = { ...this.initialQuery };
    this.getData();
  }

  handleRefreshData() {
    const { currentPage } = this.suppliersTable.getCurrentState();
    this.getData({ page: currentPage - 1 });
  }

  revealData(field: string, supplier) {
    supplier.isDataMasked[field] = false;

    let isFieldAlreadyUnmasked: boolean;

    if (field === 'ids') {
      isFieldAlreadyUnmasked = !supplier.ids
        .map(id => id.number)
        .toString()
        .includes('*');
    } else {
      isFieldAlreadyUnmasked = !supplier[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    if (field === 'ids') {
      query.$populate = [{ path: 'ids.pictures', select: 'url thumbnail' }];
    }

    const callback = ({ data }) => (supplier[field] = data);

    this.condoContactService
      .getCondoContactUnmaskedField(this.condo._id, supplier._id, field, query)
      .pipe(timeout(10000))
      .subscribe(callback);
  }
}
