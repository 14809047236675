/**
 * Created by Max on 28/12/2016.
 */
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ParamsService } from '../api/service/params.service';
import { UtilService } from '../services/util.service';

@Injectable()
export class SignupGuard implements CanActivate {
  constructor(private paramsService: ParamsService, private router: Router, private utilService: UtilService) {}

  canActivate() {
    if (!!(this.paramsService.get('userData') && (this.utilService.getLocalAuthorizationHeader() || this.utilService.getLocalToken()))) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
