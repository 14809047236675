/**
 * Created by Rafael on 20/01/2017.
 */
import { Contact } from '../interface/contact';
import { UserContact } from './user.contact';
import { CondoContact } from './condo.contact';

export class ContactBuilder {
  static build(contact): Contact {
    if (!contact || (!contact.user && !contact.condo)) return contact;
    if (contact.condo) return new CondoContact(contact);
    if (contact.user) return new UserContact(contact);
  }
}
