import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../../components/table/table.component';
import { EcondosQuery } from '@api/model/query';
import { takeUntil, timeout } from 'rxjs/operators';
import { CachedVisitorsRequestsService } from '../cached-resident-requests.service';
import { Subject } from 'rxjs';
import { CacheService } from '@api/service/cache/cache.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gate-resident-details-residences',
  templateUrl: './gate-resident-details-residences.component.html'
})
export class GateResidentDetailsResidencesComponent implements OnInit, OnDestroy {
  @Input() condo: Condo;
  @Input() user: User;

  @Output() navigateToResidenceDetails: EventEmitter<string> = new EventEmitter();

  @ViewChild('residencesTable', { static: true }) residencesTable: TableComponent;
  @ViewChild('residenceIdentification', { static: true }) residenceIdentification: TemplateRef<any>;

  residences: Residence[] = [];
  count: number = 0;

  tableColumns: TableColumnDefinition<Residence>[] = [];
  status: TableStatus;
  paginate = false;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private router: Router, private cachedResident: CachedVisitorsRequestsService, private cacheService: CacheService) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        headerLabel: 'Identificação',
        valueTemplate: this.residenceIdentification
      }
    ];

    this.getData({ page: 0 });
  }

  ngOnDestroy(): void {
    this.cacheService.clearCache();
    this.unsubscribe.unsubscribe();
  }

  getData({ page = 0 }: { page: number }) {
    this.status = 'LOADING';

    const { pageSize, sortOrder, sortedColumn } = this.residencesTable.getCurrentState();

    const query: EcondosQuery = {
      $select: 'condos residencesVoter residencesUser residencesRequester',
      $populate: [
        { path: 'residencesVoter', select: 'identification condo type' },
        { path: 'residencesUser', select: 'identification condo type' },
        { path: 'residencesRequester', select: 'identification condo type' }
      ],
      $page: page,
      $limit: pageSize,
      _id: this.user._id
    };

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'asc' ? '-' : ''}${sortedColumn}`;
    }

    return this.cachedResident
      .getUserById(this.condo.id, query)
      .pipe(timeout(15000), takeUntil(this.unsubscribe))
      .subscribe({
        next: res => {
          this.user = res.users[0];

          const residencesUser = this.user?.residencesUser;
          const residecensVoter = this.user?.residencesVoter;

          this.residences = residencesUser.concat(residecensVoter);
          this.count = res.count;

          this.status = 'SUCCESS';
        },
        error: err => {
          console.log(err);
          this.status = 'ERROR';
        }
      });
  }

  isResidenceOwner(residence: Residence) {
    return this.user.residencesVoter.findIndex(res => res._id === residence._id) > -1;
  }

  isResidenceUser(residence: Residence) {
    return this.user.residencesUser.findIndex(res => res._id === residence._id) > -1;
  }

  residenceInfo(residence: Residence) {
    this.navigateToResidenceDetails.emit(residence._id);
  }
}
