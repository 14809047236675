import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, from, Observable, of, Subject, Subscription } from 'rxjs';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { catchError, distinctUntilChanged, filter, map, mergeMap, retry, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import swal from 'sweetalert2';
import { EcondosQuery } from '@api/model/query';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { User } from '@api/model/user';
import { AccessGroup } from '@api/model/hardware/access-group';
import { AccessGroupService } from '@api/service/access-group.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { HardwareSocketService } from '../../services/hardware-socket.service';
import { ZktecoService } from '@api/service/hardware/zkteco.service';
import { FileService } from '@api/service/file.service';
import * as moment from 'moment';
import { webSocket } from 'rxjs/webSocket';
import { orderBy } from 'lodash';
import { USER_TYPE } from '@api/model/create-device/residence-user';

interface AccessGroupItem extends AccessGroup {
  isSelected?: boolean;
}

@Component({
  selector: 'app-modal-create-zkteco-device',
  templateUrl: 'modal-create-zkteco-device.component.html',
  styleUrls: ['modal-create-zkteco-device.component.scss']
})
export class ModalCreateZktecoDeviceComponent implements OnInit, OnDestroy {
  condo: Condo;
  condoAccessGroups: AccessGroupItem[] = [];

  protected readonly USER_TYPE = USER_TYPE;

  TYPES = [
    { label: 'Facial', value: 'FACIAL' },
    { label: 'Cartão/Chaveiro', value: 'CARD' },
    { label: 'Biometria', value: 'BM' },
    { label: 'Senha', value: 'SN' },
    { label: 'QRCode', value: DEVICE_TYPES.QR }
  ];

  status: Status = new Status();

  device: Device;
  callbacks: { success?: (arg) => void; error?: (err) => void };

  form: UntypedFormGroup;

  type: AbstractControl;
  residence: AbstractControl;
  user: AbstractControl;
  userName: AbstractControl;
  picture: AbstractControl;
  serial: AbstractControl = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
  accessGroups: AbstractControl;
  obs: AbstractControl;
  template: AbstractControl = new UntypedFormControl('', Validators.required);
  validFromDate: AbstractControl;
  validUntilDate: AbstractControl;

  residenceSearch;
  residenceUsers: { name: string; type: string; _id: string; picture: any }[] = [];
  loadingResidences = false;

  residenceTypeAheadDataSource$: Observable<any>;

  selectedResidence;

  private subscriptions: Subscription = new Subscription();

  formIsDisabled = false;

  storageUser: { user: User; residence: Residence };

  getAccessGroups$: Observable<AccessGroup[]>;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private deviceService: HardwareDeviceService,
    private actuatorService: ActuatorService,
    private accessGroupService: AccessGroupService,
    private toastrService: ToastrService,
    private zktecoService: ZktecoService,
    private fileService: FileService,
    private hardwareSocketService: HardwareSocketService
  ) {
    this.form = this.fb.group({
      type: ['', Validators.required],
      user: [null],
      userName: [''],
      residence: [null, [Validators.required]],
      picture: [null],
      accessGroups: [[], Validators.required],
      obs: [''],
      validFromDate: [moment().startOf('d').format('YYYY-MM-DD'), Validators.required],
      validUntilDate: ['', Validators.required]
    });
    this.type = this.form.get('type');
    this.user = this.form.get('user');
    this.userName = this.form.get('userName');
    this.residence = this.form.get('residence');
    this.picture = this.form.get('picture');
    this.accessGroups = this.form.get('accessGroups');
    this.obs = this.form.get('obs');
    this.validFromDate = this.form.get('validFromDate');
    this.validUntilDate = this.form.get('validUntilDate');

    this.subscriptions.add(
      this.user.valueChanges.subscribe(user => {
        if (user === 'NEW_USER') {
          swal({
            title: 'Inserir nome',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            inputPlaceholder: 'Digite o nome...',
            showLoaderOnConfirm: true,
            preConfirm: name => {
              if (!name || !name.trim().length) {
                return Promise.reject(`Insira o nome`);
              } else {
                name = name.trim();
                return Promise.resolve(name);
              }
            }
          }).then(
            name => {
              this.userName.setValue(name);
              this.user.setValue('', { emitEvent: false });
            },
            () => {
              this.userName.setValue('');
              this.user.setValue('', { emitEvent: false });
            }
          );
        } else if (user?.type === 'DEPENDENT') {
          this.userName.setValue(user.name);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          }
          this.user.setValue('', { emitEvent: false });
        } else if (this.device?.owner?.picture) {
          this.picture.setValue(this.device.owner.picture);
        } else if (user) {
          user = this.residenceUsers.find(u => u._id === user._id);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          } else {
            this.picture.setValue(null);
          }
        } else {
          this.picture.setValue(null);
        }
      })
    );

    this.subscriptions.add(
      this.type.valueChanges.subscribe(type => {
        if (type === 'FACIAL') {
          this.form.removeControl('template');
          this.form.removeControl('serial');
          this.picture.setValidators([Validators.required]);
        } else if (type === 'CARD') {
          this.form.removeControl('template');
          this.form.addControl('serial', this.serial);
          this.picture.clearValidators();
        } else if (type === 'BM') {
          this.form.removeControl('serial');
          this.form.addControl('template', this.template);
        } else {
          this.form.removeControl('template');
          this.form.removeControl('serial');
          this.picture.clearValidators();
        }
        this.form.updateValueAndValidity({ emitEvent: false });
      })
    );
    this.initializeTypeAheads();
  }

  initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type voter users',
          $populate: [
            {
              path: 'users',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            },
            {
              path: 'voter',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            }
          ]
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(e => {
            this.status.setAsError();
            return from([]);
          })
        );
      })
    );
  }

  ngOnInit() {
    if (this.device) {
      this.formIsDisabled = true;
      this.type.setValue(this.device.type);
      this.serial.setValue(this.device.serial);
      this.obs.setValue(this.device.obs);
      this.obs.enable();
      if (this.device.owner) {
        if (this.device.owner.residence && this.device.owner.residence._id) {
          this.residence.setValue(this.device.owner.residence);
          const userId: any = (this.device.owner && this.device.owner.user && this.device.owner.user._id) || '';
          this.initializeFieldsFromResidence(this.device.owner.residence, { userId });
        }
        if (this.device.owner.user && this.device.owner.user._id) {
          const user = {
            name: `${this.device.owner.user.firstName} ${this.device.owner.user.lastName}`,
            _id: this.device.owner.user._id,
            picture: this.device.owner.user.picture,
            type: 'USER'
          };
          this.residenceUsers = [user];
          this.user.setValue(user);
        } else {
          this.user.setValue('');
          if (this.device.owner.userName) {
            this.userName.setValue(this.device.owner.userName);
          } else if (this.device.hardwareAttributes && this.device.hardwareAttributes.rotulo) {
            this.userName.setValue(this.device.hardwareAttributes.rotulo);
          }
        }
        if (this.device.owner.picture && this.device.owner.picture._id) {
          this.picture.setValue(this.device.owner.picture);
        }

        if (this.device.validFrom) {
          const validFrom = moment(this.device.validFrom);
          this.validFromDate.setValue(validFrom.format('YYYY-MM-DD'));
        }

        if (this.device.validUntil) {
          const validUntil = moment(this.device.validUntil);
          this.validUntilDate.setValue(validUntil.format('YYYY-MM-DD'));
        }
      }
      if (this.device.type === 'BM') {
        this.deviceService
          .getDeviceTemplate(this.condo._id, this.device._id)
          .pipe(retry(3))
          .subscribe(({ template }) => {
            this.template.setValue(template);
          });
      }
    } else if (this.selectedResidence) {
      this.residence.setValue(this.selectedResidence);
      this.initializeFieldsFromResidence(this.selectedResidence);
    }

    if (this.storageUser) {
      if (this.storageUser.residence) {
        this.residence.setValue(this.storageUser.residence);
        this.initializeFieldsFromResidence(this.storageUser.residence);
        this.user.setValue('');
      }
      if (this.storageUser.user) {
        this.user.setValue(this.storageUser.user._id);
        this.residenceUsers = [
          {
            name: this.storageUser.user.fullName,
            type: 'USER',
            _id: this.storageUser.user._id,
            picture: this.storageUser.user.picture
          }
        ];
        this.user.setValue(this.residenceUsers[0]);
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residence.setValue(residences[0]);
        }
        if (this.storageUser.user.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
      }
    }

    this.getAccessGroups$ = this.accessGroupService.get(this.condo._id).pipe(
      map(({ accessGroups }) => accessGroups),
      tap(accessGroups => (this.condoAccessGroups = accessGroups)),
      tap(() => {
        this.device?.accessGroups?.forEach(accessGroup => {
          this.toggleAccessGroupSelection(accessGroup);
        });
      }),
      timeout(10000),
      retry(3)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleAccessGroupSelection(accessGroup: AccessGroupItem): void {
    const accessGroupIndex = this.accessGroups.value.findIndex(group => group._id === accessGroup._id);

    if (accessGroupIndex === -1) {
      this.condoAccessGroups = this.condoAccessGroups.map(group => {
        if (group._id === accessGroup._id) {
          return { ...group, isSelected: true };
        }

        return group;
      });

      this.accessGroups.setValue([...this.accessGroups.value, accessGroup]);
    } else {
      const updatedAccessGroups = this.accessGroups.value;
      updatedAccessGroups.splice(accessGroupIndex, 1);

      this.condoAccessGroups = this.condoAccessGroups.map(group => {
        if (group._id === accessGroup._id) {
          return { ...group, isSelected: false };
        }

        return group;
      });

      this.accessGroups.setValue(updatedAccessGroups);
    }
  }

  buildDevice() {
    const type = this.type.value;

    const accessGroups = this.accessGroups.value.map((group: AccessGroup) => group._id);

    const device: any = { type, hardware: HARDWARES.ZKTECO, accessGroups, obs: this.obs.value };

    if (this.residence.value) {
      device.owner = {
        residence: this.residence.value._id || this.residence.value
      };
      if (this.user.value) {
        device.owner.user = this.user.value._id || this.user.value;
      } else if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value._id || this.picture.value;
      }
    }
    if (this.type.value === 'BM' && this.template.value) {
      device.template = this.template.value;
    }
    if (this.serial.value) {
      device.serial = this.serial.value;
    }
    const validFromString = `${this.validFromDate.value}`;
    const validFrom = moment(validFromString, 'YYYY-MM-DD').toISOString();

    const validUntilString = `${this.validUntilDate.value}`;
    const validUntil = moment(validUntilString, 'YYYY-MM-DD').toISOString();

    device.validFrom = validFrom;
    device.validUntil = validUntil;

    return device;
  }

  save(value) {
    const device = this.buildDevice();
    if (this.form.valid) {
      if (this.device) {
        this.updateDevice(device);
      } else {
        this.createDevice(device);
      }
    } else {
      this.toastrService.warning('Preencha todos os campos e adicione a foto da pessoa');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }

  createDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .create(this.condo._id, device)
      .pipe(
        timeout(15_0000),
        tap(createdDevice => (device = createdDevice)),
        mergeMap((d: Device) => this.zktecoService.addDevice(this.condo._id, { ...device, d })),
        mergeMap((res: any) => {
          if (!res) {
            return of(device);
          }
          if (res && res.some(response => !response.success)) {
            return of(res);
          } else {
            return this.deviceService.update(this.condo._id, device._id, { status: 'SYNCED' }).pipe(mergeMap(_ => of(res)));
          }
        })
      )
      .subscribe(
        (res: any) => {
          swal.close();
          if (this.callbacks?.success) {
            this.callbacks.success(new Device(device));
          }
          this.bsModalRef.hide();
          if (res && res.length && res.some(response => !response.success)) {
            swal({
              type: 'error',
              title: 'Ops...',
              text: 'Não foi possível cadastrar em todos equipamentos, verifique a conexão deles e tente novamente'
            });
          }
        },
        () => {
          this.status.setAsError();
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível cadastrar este dispositivo.'
          });
        }
      );
  }

  updateDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .update(this.condo._id, this.device._id, device)
      .pipe(
        timeout(15_000),
        mergeMap((d: Device) => this.zktecoService.addDevice(this.condo._id, d)),
        mergeMap((res: any) => {
          if (!res) {
            return of(device);
          }
          const status = res.some(response => !response.success) ? 'UNSYNCED' : 'SYNCED';
          return this.deviceService.update(this.condo._id, this.device._id, { status }).pipe(mergeMap(_ => of(res)));
        })
      )
      .subscribe(
        (res: any) => {
          swal.close();
          if (this.callbacks?.success) {
            this.callbacks.success(new Device(device));
          }
          if (res && res.length && res.some(response => !response.success)) {
            swal({
              type: 'error',
              title: 'Ops...',
              text: 'Não foi possível atualizar o dispositivo em todos equipamentos, verifique a conexão deles e tente novamente'
            });
          }
          this.bsModalRef.hide();
        },
        () => {
          this.status.setAsError();
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível editar este dispositivo.'
          });
        }
      );
  }

  async onResidenceSelect(event) {
    const residence: Residence = event.item;
    this.residence.setValue(residence);
    const users = this.getResidenceUsers(residence);
    this.residenceUsers = [...users];
    const dependents = await this.getResidenceDependents(this.condo, residence);
    this.residenceUsers.push(...dependents);
    this.residenceUsers = orderBy(this.residenceUsers, [u => u.name.toLowerCase()]);
    this.user.setValue('');
  }

  getResidenceUsers(residence) {
    return (residence.residenceUsers || []).map(u => ({
      type: 'USER',
      _id: u._id,
      name: `${u.firstName} ${u.lastName}`,
      picture: u.picture
    }));
  }

  async getResidenceDependents(condo, residence) {
    const dependentsParams = [];
    dependentsParams.push({ '$populate[0][path]': 'picture' });
    dependentsParams.push({ '$populate[0][select]': 'url thumbnail type format name' });
    dependentsParams.push({ $sort: 'name' });
    const dependents = await this.residenceService
      .getDependents(condo._id, residence._id, dependentsParams)
      .pipe(
        map(res =>
          res.dependents.map(dep => ({
            type: 'DEPENDENT',
            _id: dep._id,
            picture: dep.picture,
            name: dep.name
          }))
        )
      )
      .toPromise();
    return dependents;
  }

  clearResidence() {
    this.residenceSearch = '';
    this.residence.setValue(null);
    this.residenceUsers = [];
    this.user.setValue(null);
  }

  initializeFieldsFromResidence(residence, selectedValues: { vehicleId?: string; userId?: string } = {}) {
    const residenceQuery: EcondosQuery = {
      $populate: [
        {
          path: 'users',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        },
        {
          path: 'voter',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        }
      ]
    };
    forkJoin([
      this.residenceService.getResidenceByIdWithParams(this.condo._id, residence._id, residenceQuery),
      this.residenceService.getVehicles(this.condo._id, residence._id)
    ])
      .pipe(timeout(15000))
      .subscribe(
        async ([residenceResponse, vehiclesResponse]) => {
          this.residenceUsers = this.getResidenceUsers(residenceResponse);
          const dependents = await this.getResidenceDependents(this.condo, residence);
          this.residenceUsers.push(...dependents);
          if (selectedValues.userId) {
            const user = this.residenceUsers.find(u => u._id === selectedValues.userId);
            this.user.setValue(user);
          } else {
            this.user.setValue('');
          }
        },
        async err => {
          console.log(err);
          await swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível obter os dados necessários, verifique sua conexão e tente novamente.'
          });
          this.bsModalRef.hide();
        }
      );
  }

  async collectCard() {
    const actuators = await this.actuatorService
      .getActuators(this.condo._id, { hardware: 'ZKTECO' })
      .pipe(
        map(v => v.actuators),
        timeout(10000),
        retry(3)
      )
      .toPromise();
    if (!actuators || !actuators.length) {
      swal({
        type: 'error',
        title: 'Equipamentos não cadastrados',
        text: 'Não existem equipamentos cadastrados no seu condomínio. Antes de cadastrar um cartão é necessário você cadastrar os equipamentos'
      });
      return;
    }
    let selectedActuator;
    if (actuators.length > 1) {
      const inputOptions: any = actuators.reduce(
        (acc, curr) => {
          acc[curr._id] = curr.name;
          return acc;
        },
        { ALL: 'Todos' }
      );

      const actuatorId = await swal({
        inputOptions,
        title: 'Equipamento',
        text: 'Selecione o equipamento desejado para a leitura do cartão',
        input: 'select',
        inputPlaceholder: 'Selecione',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputValidator: async res => {
          if (res) {
            await Promise.resolve();
          } else {
            return Promise.reject('Selecione um equipamento!');
          }
        }
      });
      if (actuatorId === 'ALL') {
        selectedActuator = actuatorId;
      } else {
        selectedActuator = actuators.find(a => a._id === actuatorId);
      }
    } else {
      selectedActuator = actuators[0];
    }

    const destroy$ = new Subject();

    this.hardwareSocketService.onData$
      .pipe(
        timeout(60000),
        filter(
          ({ condoId, hardwareEvent }) =>
            condoId === this.condo._id &&
            hardwareEvent &&
            hardwareEvent.eventType === 'OTHER' &&
            hardwareEvent.serial &&
            !hardwareEvent.deviceType
        ),
        filter(
          data => selectedActuator === 'ALL' || (data.hardwareEvent.actuator?._id || data.hardwareEvent.actuator) === selectedActuator._id
        ),
        map(res => ({ ...res.hardwareEvent })),
        map(res => (res && res.serial) || ''),
        takeUntil(destroy$),
        take(1),
        tap(() => {
          swal.clickConfirm();
        })
      )
      .subscribe(
        serial => {
          this.toastrService.success(`Cartão ${serial} lido com sucesso`);
          this.serial.setValue(serial);
          destroy$.next(null);
          destroy$.complete();
        },
        err => {
          swal({
            type: 'error',
            title: 'Erro na leitura',
            text: 'Não foi possível realizar a leitura do cartão'
          });
          destroy$.next(null);
          destroy$.complete();
          console.log(err);
        }
      );

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Passe o cartão na leitora ${selectedActuator.name || ''}`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(e => {
      console.log(e);
      destroy$.next(null);
      destroy$.complete();
    });
  }

  async collectFingerprint() {
    const actuators = await this.actuatorService
      .getActuators(this.condo._id, { hardware: 'ZKTECO' })
      .pipe(
        map(v => v.actuators),
        timeout(10000),
        retry(3)
      )
      .toPromise();
    let selectedActuator;
    if (actuators.length) {
      const inputOptions: any = actuators.reduce(
        (acc, curr) => {
          acc[curr._id] = curr.name;
          return acc;
        },
        { TABLE_REGISTER: 'Cadastrador de mesa' }
      );

      const actuatorId = await swal({
        inputOptions,
        title: 'Equipamento',
        text: 'Selecione o equipamento desejado para a leitura da biometria',
        input: 'select',
        inputPlaceholder: 'Selecione',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputValidator: async res => {
          if (res) {
            return Promise.resolve();
          } else {
            return Promise.reject('Selecione um equipamento!');
          }
        }
      });
      if (actuatorId === 'TABLE_REGISTER') {
        selectedActuator = 'TABLE_REGISTER';
      } else {
        selectedActuator = actuators.find(a => a._id === actuatorId);
      }
    } else {
      selectedActuator = 'TABLE_REGISTER';
    }

    if (selectedActuator === 'TABLE_REGISTER') {
      this.collectFingerprintFromTableRegistrator();
    } else {
      this.template.setValue('');

      const destroy$ = new Subject();

      this.hardwareSocketService.onData$
        .pipe(
          timeout(60000),
          filter(
            event =>
              event.condoId === this.condo._id &&
              event.command === 'eventSentAutomatically' &&
              !!event.hardwareEvent &&
              !!event.hardwareEvent.template
          ),
          map(({ hardwareEvent: { template } }) => template),
          takeUntil(destroy$),
          take(1),
          tap(() => {
            swal.clickConfirm();
          })
        )
        .subscribe(
          data => {
            this.toastrService.success(`Biometria coletada com sucesso`);
            this.template.setValue(data);
            destroy$.next(null);
            destroy$.complete();
          },
          err => {
            swal({
              type: 'error',
              title: 'Erro na leitura',
              text: 'Não foi possível realizar a coleta da biometria'
            });
            destroy$.next(null);
            destroy$.complete();
            console.log(err);
          }
        );

      this.zktecoService.enrollBioFingerprint(selectedActuator).subscribe(
        (result: any) => {
          if (!result?.success) {
            const text = result?.message || 'Não foi possível realizar a coleta da biometria';
            swal({
              type: 'error',
              title: 'Erro na leitura',
              text
            });
            destroy$.next(null);
            destroy$.complete();
          }
        },
        () => {
          swal({
            type: 'error',
            title: 'Erro na leitura',
            text: 'Não foi possível realizar a coleta da biometria'
          });
          destroy$.next(null);
          destroy$.complete();
        }
      );

      swal({
        type: 'info',
        title: 'Aguardando leitura',
        text: `Coloque o dedo no equipamento ${selectedActuator.name}`,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar'
      }).catch(e => {
        console.log(e);
        destroy$.next(null);
        destroy$.complete();
      });
    }
  }

  collectFingerprintFromTableRegistrator() {
    const socketUrl = 'ws://localhost:5677/';
    const wsConnection$ = webSocket({ url: socketUrl });
    wsConnection$
      .pipe(
        filter(data => !!data),
        tap((data: any) => {
          switch (data.step) {
            case 1: {
              swal({
                type: 'info',
                title: 'Coloque o dedo novamente',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Cancelar'
              }).catch(() => wsConnection$.complete());
              break;
            }
            case 2: {
              swal({
                type: 'info',
                title: 'Coloque o dedo mais uma vez',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Cancelar'
              }).catch(() => wsConnection$.complete());
              break;
            }
            case 3: {
              swal.clickConfirm();
              this.toastrService.success('Biometria coletada com sucesso');
              break;
            }
          }
        }),
        timeout(30000),
        filter(data => data.step === 3),
        map(response => response.data && response.data.codigo_digital)
      )
      .subscribe(
        fingerprint => {
          this.template.setValue(fingerprint);
          wsConnection$.complete();
        },
        err => {
          console.log(err);
          if (err?.name === 'TimeoutError') {
            swal({
              type: 'error',
              title: 'Falha ao coletar biometria',
              text: 'Tempo máximo de coleta excedido, comece o processo novamente.'
            });
          } else if (err?.type === 'error' && err?.srcElement?.url === socketUrl) {
            swal({
              type: 'warning',
              html: `
                  <p>Para utilizar o cadastrador de mesa é necessário instalar o driver da cadastradora.</p>
                  <p>
                    Faça o download do driver de comunicação com a cadastradora de mesa<a
                    href="https://files.econdos.com.br/drivers/Driver_biometria_intelbras.rar">
                    clicando aqui
                    </a>
                  </p>
                  <p>Reinicie o processo após a instalação do driver</p>
          `
            });
          } else {
            swal({
              type: 'error',
              title: 'Falha ao coletar biometria',
              text: 'Não foi possível completar o processo de coleta, inicie novamente'
            });
          }
          wsConnection$.complete();
        }
      );

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Coloque o dedo na cadastradora de mesa`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(() => wsConnection$.complete());
  }

  public generateRandomSerial(length): void {
    const serial = [...Array(length)]
      .map(() => Math.floor(Math.random() * 10).toString())
      .join('')
      .toUpperCase();
    this.serial.setValue(serial);
  }

  // async collectFace() {
  //   const actuators = this.condoActuators;
  //   if (!actuators || !actuators.length) {
  //     swal({
  //       type: 'error',
  //       title: 'Equipamentos não cadastrados',
  //       text: `Não existem equipamentos cadastrados no seu ${this.condo?.customLabels?.condo?.singular}. Antes de cadastrar uma facial é necessário você cadastrar os equipamentos`
  //     })
  //     return;
  //   }
  //   let selectedActuator;
  //   if (actuators.length > 1) {
  //     const inputOptions: any = actuators.reduce((acc, curr) => {
  //       acc[curr._id] = curr.name
  //       return acc;
  //     }, {});
  //
  //     const actuatorId = await swal({
  //       inputOptions,
  //       title: 'Equipamento',
  //       text: 'Selecione o equipamento desejado para a leitura da biometria',
  //       input: 'select',
  //       inputPlaceholder: 'Selecione',
  //       showCancelButton: true,
  //       confirmButtonText: 'Confirmar',
  //       confirmButtonColor: '#32DB64',
  //       cancelButtonColor: '#f53d3d',
  //       cancelButtonText: 'Cancelar',
  //       reverseButtons: true,
  //       inputValidator: async (res) => {
  //         if (res) {
  //           return Promise.resolve();
  //         } else {
  //           return Promise.reject('Selecione um equipamento!');
  //         }
  //       },
  //     });
  //     selectedActuator = actuators.find(a => a._id === actuatorId)
  //   } else {
  //     selectedActuator = actuators[0];
  //   }
  //
  //   this.picture.setValue(null);
  //
  //   const destroy$ = new Subject();
  //
  //   this.hardwareSocketService.onData$.pipe(
  //     timeout(60000),
  //     filter((command) => command.condoId === this.condo._id && command.command === 'unregisteredBioData' && command.value),
  //     map(({value}) => `data:image/jpeg;base64,${value}`),
  //     switchMap(image => this.fileService.uploadBase64(image)),
  //     map(([file]) => file),
  //     takeUntil(destroy$),
  //     take(1),
  //     tap(() => {
  //       swal.clickConfirm();
  //     })
  //   ).subscribe((image: File) => {
  //     this.toastrService.success(`Facial capturada com sucesso`);
  //     this.template.setValue(image);
  //     destroy$.next(null);
  //     destroy$.complete();
  //   }, err => {
  //     swal({
  //       type: 'error',
  //       title: 'Erro na leitura',
  //       text: 'Não foi possível realizar a captura da facial'
  //     })
  //     destroy$.next(null);
  //     destroy$.complete();
  //     console.log(err);
  //   })
  //
  //   const commandName = 'enrollBio';
  //   const input = {
  //     type: 'PALM',
  //     userId: 1,
  //     retry: 0
  //   };
  //   this.zktecoService.sendCommand(this.condo._id, selectedActuator._id, commandName, input, 60000).subscribe(([command]) => {
  //     if (!command?.result?.success) {
  //       const text = command?.result?.message || 'Não foi possível realizar a captura da facial';
  //       swal({
  //         type: 'error',
  //         title: 'Erro na leitura',
  //         text
  //       })
  //       destroy$.next(null);
  //       destroy$.complete();
  //     }
  //   }, () => {
  //     swal({
  //       type: 'error',
  //       title: 'Erro na leitura',
  //       text: 'Não foi possível realizar a captura da facial'
  //     })
  //     destroy$.next(null);
  //     destroy$.complete();
  //   })
  //
  //   swal({
  //     type: 'info',
  //     title: 'Aguardando leitura...',
  //     text: `Coloque o rosto próximo a tela do equipamento ${selectedActuator.name}`,
  //     showCancelButton: true,
  //     showConfirmButton: false,
  //     cancelButtonColor: '#f53d3d',
  //     cancelButtonText: 'Cancelar',
  //   }).catch(e => {
  //     console.log(e);
  //     destroy$.next(null)
  //     destroy$.complete();
  //   });
  // }
}
