import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { ReservationService } from '@api/service/reservation.service';
import { UtilService } from '../../services/util.service';
import { ParamsService } from '@api/service/params.service';
import { User } from '@api/model/user';
import { LightboxComponent } from '../../components/lightbox/lightbox';
import { Local } from '@api/model/local';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, timeout } from 'rxjs/operators';
import { Status } from '@api/model/status';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';

@Component({
  templateUrl: './reservate.local.html',
  styleUrls: ['./reservate.local.scss']
})
export class ReservateLocalPageComponent implements OnInit {
  @ViewChild(LightboxComponent, { static: true }) lightboxModal: LightboxComponent;

  public locals;
  public filteredLocals;

  public loadStatus: Status = new Status();

  public shouldGetData = true;

  public searchTerm: UntypedFormControl;

  public user: User;
  public condo: Condo;

  public ENABLE_DISABLE_UTILS = {
    AVAILABLE: {
      method: 'disableReservationlocal',
      actionSheetLabel: 'Desabilitar',
      icon: 'hide-circle',
      status: 'AVAILABLE',
      otherStatus: 'DISABLED'
    },
    DISABLED: {
      method: 'enableReservationlocal',
      actionSheetLabel: 'Habilitar',
      icon: 'checkmark-circle',
      status: 'DISABLED',
      otherStatus: 'AVAILABLE'
    }
  };

  constructor(
    private reservationService: ReservationService,
    private utilService: UtilService,
    private router: Router,
    private route: ActivatedRoute,
    private paramsService: ParamsService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();

    this.filteredLocals = this.locals = [];

    this.searchTerm = new UntypedFormControl();

    this.searchTerm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      const val = term.trim().toLowerCase();
      this.filteredLocals = this.locals.filter(local => {
        return !val || (local.name && local.name.toLowerCase().indexOf(val) !== -1);
      });
    });
  }

  ngOnInit() {
    if (this.shouldGetData || !this.locals.length || this.loadStatus.isError()) {
      this.getData();
    }
  }

  getData() {
    this.loadStatus.setAsDownloading();

    const query: EcondosQuery = {
      $select:
        'pictures rulesAttachments checklist penaltyWarningText capacity createdBy name description params rules rulesAttachments status value penalties extraFees',
      $populate: [
        { path: 'pictures', select: 'url thumbnail type format name videoThumbnail' },
        { path: 'rulesAttachments', select: 'url thumbnail type format name videoThumbnail' },
        { path: 'periods', select: 'endHour startHour weekDay' },
        { path: 'penalties', select: 'time value' }
      ],
      deleted: false
    };

    if (this.user.isUser()) {
      query['params.residentCanReserve'] = true;
    }

    this.reservationService.getReservationLocals(this.condo._id, query).subscribe({
      next: locals => {
        this.locals = locals.locals.sort((a: Local, b: Local) => {
          if (a.isDisabled() || b.isDisabled()) {
            return a.status.localeCompare(b.status);
          } else {
            return a.name.localeCompare(b.name);
          }
        });

        this.filteredLocals = this.locals;
        this.shouldGetData = false;
        this.loadStatus.setAsSuccess();
        this.searchTerm.setValue('', { emitEvent: false });
      },
      error: () => {
        this.loadStatus.setAsError();
      }
    });
  }

  askToEnableOrDisableLocal(local: Local) {
    swal({
      type: 'question',
      text: `Deseja ${local.isAvailable() ? 'desabilitar' : 'habilitar'} o local ${local.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.enableOrDisableLocal(local)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject(`Não foi possível ${local.isAvailable() ? 'desabilitar' : 'habilitar'} este local, tente novamente...`);
          });
      }
    }).then(
      success => {
        console.log(success);
        local.isAvailable() ? local.disableLocal() : local.enableLocal();
        swal({
          type: 'success',
          text: `O local foi ${local.isAvailable() ? 'habilitado' : 'desabilitado'} com sucesso`
        });
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  enableOrDisableLocal(local) {
    return this.reservationService[this.ENABLE_DISABLE_UTILS[local.status].method](this.condo._id, local._id);
  }

  reserveLocal(local) {
    this.paramsService.add(local._id, local);
    this.router.navigate(['reserve', local._id], { relativeTo: this.route });
  }

  createLocal() {
    this.paramsService.add('reservationLocals', this.locals);
    this.router.navigate(['createLocal'], { relativeTo: this.route });
  }

  goToHistory() {
    this.router.navigate(['reservationHistory'], { relativeTo: this.route });
  }

  goToLocalGroups() {
    this.router.navigate(['localGroups'], { relativeTo: this.route });
  }

  editLocal(local) {
    this.paramsService.add(local._id, local);
    this.paramsService.add(
      'reservationLocals',
      this.locals.filter(reservationLocal => reservationLocal._id !== local._id)
    );
    this.router.navigate(['editLocal', local._id], { relativeTo: this.route });
  }

  remove(local) {
    swal({
      type: 'warning',
      title: `Apagar local ${local.name}`,
      text: 'Deseja realmente apagar este local?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.reservationService
          .deleteReservationLocal(this.condo._id, local._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível apagar este local, tente novamente...');
          });
      }
    }).then(
      success => {
        console.log(success);
        swal({
          title: 'Local removido!',
          text: 'O local foi removido com sucesso',
          type: 'success'
        }).then(() => this.getData());
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  deleteLocal(local) {
    this.reservationService
      .deleteReservationLocal(this.condo._id, local._id)
      .pipe(timeout(10000))
      .subscribe(() => {
        this.getData();
      });
  }

  showImagesViewer(pictures) {
    this.lightboxModal.showImagesViewer(pictures);
  }
}
