import { Injectable } from '@angular/core';
import { CondoContactService } from '@api/service/condo.contact.service';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';

@Injectable({ providedIn: 'root' })
export class CondoContactServiceV3 extends CondoContactService {
  constructor(protected http: HttpService, protected constantService: ConstantService) {
    super(http, constantService);
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }
}
