import { File } from '../file';
import { BackObject } from '../interface/back.object';
/**
 * Created by Rafael on 21/01/2017.
 */
export class ContactID implements BackObject {
  static TYPES = {
    RG: 'RG',
    CPF: 'CPF',
    DRIVER_LICENCE: 'DRIVER_LICENCE',
    PASSPORT: 'PASSPORT',
    CNPJ: 'CNPJ',
    RA: 'RA',
    OTHER: 'OTHER'
  };

  static TYPES_LABEL = {
    RG: 'RG',
    CPF: 'CPF',
    DRIVER_LICENCE: 'CNH',
    PASSPORT: 'Passaporte',
    CNPJ: 'CNPJ',
    RA: 'RA',
    OTHER: 'Outro'
  };

  private _number;
  private _type;
  private _pictures: File[];

  constructor(id?) {
    if (id) {
      this._number = id.number || '';
      this._type = id.type || '';
      this._pictures = id.pictures ? id.pictures.map(pic => new File(pic)) : [];
    } else {
      this.construcEmptyObject();
    }
  }

  construcEmptyObject() {
    this._number = '';
    this._type = '';
    this._pictures = new Array<File>();
  }

  get typeLabel(): string {
    return ContactID.TYPES_LABEL[this.type];
  }

  get number() {
    return this._number;
  }

  set number(value) {
    this._number = value;
  }

  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }

  get pictures(): File[] {
    return this._pictures;
  }

  set pictures(value: File[]) {
    this._pictures = value;
  }

  createBackObject() {
    let idToSend = {
      type: this.type,
      number: this.number,
      pictures: this.pictures.map(pic => pic.id)
    };
    return idToSend;
  }
}
