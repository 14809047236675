/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HouseResidence } from '../../../../api/model/residence/residence.house';
import { ModalHousePickerComponent } from '../house.picker.modal/house.picker.modal';
import { ModalResidencePickerComponent } from '../apartment.picker.modal/apartment.picker.modal';
import { Condo } from '../../../../api/model/condo';

@Component({
  selector: 'residence-picker',
  templateUrl: 'residence.picker.component.html'
})
export class ResidencePickerComponent {
  @ViewChild(ModalResidencePickerComponent) apartmentPickerModal: ModalResidencePickerComponent;

  @ViewChild(ModalHousePickerComponent) housePickerModal: ModalHousePickerComponent;

  @Output() onResidencesSelect = new EventEmitter();

  @Input() selectedResidences: Array<HouseResidence> = new Array<HouseResidence>();

  @Input() disabledResidences: Array<HouseResidence> = new Array<HouseResidence>();

  @Input() singleSelect: boolean = false;

  @Input() shouldReload: boolean = false;

  @Input() condo: Condo = new Condo();

  pickResidence() {
    if (this.condo.isApartmentCondo()) this.apartmentPickerModal.showModal();
    if (this.condo.isHouseCondo()) this.housePickerModal.showModal();
  }

  onResidenceSelect(residences) {
    this.onResidencesSelect.emit(residences);
  }
}
