import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PermissionKey } from '@api/model/custom-role/custom-role-permissions';
import { SessionService } from '@api/service/session.service';
import { validateUserPermissions } from 'utils/validateUserPermissions';

interface RouteData {
  permissions: PermissionKey[] | PermissionKey | string;
}

@Injectable({
  providedIn: 'root'
})
export class PermittedGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const user = this.sessionService.userValue;
    const condo = this.sessionService.condoValue;

    let canAccess = false;

    if (user && condo) {
      const { permissions } = route.data as RouteData;
      canAccess = validateUserPermissions(user, condo, permissions);
    }

    if (!canAccess) {
      this.router.navigate(['/no-permission-error']);
    }

    return canAccess;
  }
}
