import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Status } from '../../../api/model/status';
import { UtilService } from '../../../services/util.service';
import { UntypedFormControl } from '@angular/forms';
import { ModalCondoCreateKey } from './modal-create-key/modal.create.key';
import { ModalCondoBorrowKey } from './modal-borrow-key/modal.borrow.key';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Condo } from '@api/model/condo';
import swal from 'sweetalert2';
import { KeyService } from '@api/service/key.service';
import { Key, KEY_STATUS } from '@api/model/key';
import { ToastrService } from 'ngx-toastr';
import { User } from '@api/model/user';
import { Subscription } from 'rxjs';
import { sortFunction } from '@api/util/util';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'app-condo-keys',
  templateUrl: 'condo-keys.html',
  styleUrls: ['./condo-keys.scss']
})
export class CondoKeysComponent implements OnInit, OnDestroy {
  STATUS = KEY_STATUS;

  SORT = {
    codeAsc: {
      label: 'Código crescente',
      value: 0,
      icon: 'fa-sort-numeric-asc',
      field: 'code'
    },
    codeDesc: {
      label: 'Código decrescente',
      value: 1,
      icon: 'fa-sort-numeric-desc',
      field: 'code'
    },
    nameAsc: {
      label: 'Nome crescente',
      value: 2,
      icon: 'fa-sort-alpha-asc',
      field: 'name'
    },
    nameDesc: {
      label: 'Nome decrescente',
      value: 3,
      icon: 'fa-sort-alpha-desc',
      field: 'name'
    }
  };

  selectedSort = this.SORT.codeAsc;

  keys: Array<Key> = [];
  filteredKeys: Array<Key> = [];

  loadStatus: Status = new Status();

  searchTerm: UntypedFormControl;

  condo: Condo;
  user: User;

  subscriptions: Subscription = new Subscription();

  constructor(
    private sessionService: SessionService,
    private keyService: KeyService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {
    this.user = this.sessionService.userValue;
    this.condo = this.sessionService.condoValue;

    this.filteredKeys = this.keys = [];

    this.searchTerm = new UntypedFormControl();

    this.subscriptions.add(
      this.searchTerm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(v => {
        this.filterData(v);
      })
    );
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  filterData(term) {
    if (term) {
      term = term.toUpperCase();
      this.filteredKeys = this.keys.filter(key => {
        const fields = [key.name, key.code].filter(v => v).map(v => v.toString().toUpperCase());
        return fields.some(f => f.includes(term));
      });
    } else {
      this.filteredKeys = [].concat(this.keys);
    }
  }

  sortData(data, sortOpion) {
    let field;
    switch (sortOpion) {
      case this.SORT.codeAsc: {
        field = this.SORT.codeAsc.field;
        data.sort((a, b) => sortFunction(a[field], b[field]));
        break;
      }
      case this.SORT.codeDesc: {
        field = this.SORT.codeDesc.field;
        data.sort((a, b) => sortFunction(b[field], a[field]));
        break;
      }
      case this.SORT.nameAsc: {
        field = this.SORT.nameAsc.field;
        data.sort((a, b) => sortFunction(a[field], b[field]));
        break;
      }
      case this.SORT.nameDesc: {
        field = this.SORT.nameDesc.field;
        data.sort((a, b) => sortFunction(b[field], a[field]));
        break;
      }
      default: {
        field = this.SORT.codeAsc.field;
        data.sort((a, b) => sortFunction(a[field], b[field]));
        break;
      }
    }
  }

  getData() {
    const params = [];

    params.push({ '$populate[0][path]': 'residence' });
    params.push({ '$populate[0][select]': 'identification' });
    this.loadStatus.setAsDownloading();
    this.subscriptions.add(
      this.keyService.getKeys(this.condo.id, params).subscribe(
        res => {
          this.keys = res.keys.sort((a, b) => a.name.localeCompare(b.name));
          this.sortData(this.keys, -1);
          this.filteredKeys = [].concat(...this.keys);
          this.loadStatus.setAsSuccess();
        },
        err => {
          console.log(err);
          this.loadStatus.setAsError();
        }
      )
    );
  }

  openDialog(key) {
    if (key.status === this.STATUS.AVAILABLE) {
      this.borrowKey(key);
    } else {
      this.returnKey(key);
    }
  }

  private borrowKey(key) {
    const initialState = {
      condo: this.condo,
      key,
      onKeyBorrow: ({ obs, residence, person }) => {
        const borrowedKey = { ...key, residence, person, borrowDate: new Date(), status: this.STATUS.UNAVAILABLE };
        let index = this.keys.findIndex(k => k.id === borrowedKey.id);
        this.keys[index] = borrowedKey;
        index = this.filteredKeys.findIndex(k => k.id === borrowedKey.id);
        this.filteredKeys[index] = borrowedKey;
      }
    };
    this.modalService.show(ModalCondoBorrowKey, { initialState });
  }

  private returnKey(key) {
    swal({
      title: 'Devolução de Chave',
      type: 'question',
      text: 'Deseja confirmar a devolução da chave ' + key.name,
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      inputPlaceholder: 'Digite aqui uma observação se necessário...',
      showLoaderOnConfirm: true,
      preConfirm: obs => {
        return this.keyService
          .returnKey(this.condo.id, key.id, obs)
          .pipe()
          .toPromise()
          .then(() => {
            const updateKey = { ...key, status: this.STATUS.AVAILABLE, person: '', residence: null, borrowDate: null };
            let index = this.keys.findIndex(k => k.id === updateKey.id);
            this.keys[index] = updateKey;
            index = this.filteredKeys.findIndex(k => k.id === updateKey.id);
            this.filteredKeys[index] = updateKey;
          })
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível registrar a devolução da chave, tente novamente.');
          });
      }
    }).then(
      () => {
        this.toastr.success('Devolução registrada com sucesso');
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  createkey() {
    const initialState = {
      condo: this.condo,
      callbacks: {
        success: key => {
          this.keys.push(key);
          this.filteredKeys.push(key);
        }
      }
    };
    this.modalService.show(ModalCondoCreateKey, { initialState, class: 'modal-sm' });
  }

  updateKey(key) {
    const initialState = {
      condo: this.condo,
      key,
      callbacks: {
        success: value => {
          let index = this.keys.findIndex(k => k._id === value._id);
          this.keys[index] = value;
          index = this.filteredKeys.findIndex(k => k._id === value._id);
          this.filteredKeys[index] = value;
        }
      }
    };
    this.modalService.show(ModalCondoCreateKey, { initialState, class: 'modal-sm' });
  }

  onKeyDeleted(key: Key) {
    let index = this.keys.findIndex(k => k._id === key._id);
    this.keys.splice(index, 1);
    index = this.filteredKeys.findIndex(k => k._id === key._id);
    this.filteredKeys.splice(index, 1);
    this.toastr.success('Chave excluída com sucesso!');
  }
}
