import { NgModule } from '@angular/core';
import { ModalImportIntelbrasIncontrolVisitorsComponent } from './modal-import-intelbras-incontrol-visitors.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../../theme';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipesModule } from '../../../../pipe/pipes.module';

@NgModule({
  imports: [ModalModule, ReactiveFormsModule, FormsModule, CommonModule, ThemeModule, ProgressbarModule, NgxPaginationModule, PipesModule],
  declarations: [ModalImportIntelbrasIncontrolVisitorsComponent]
})
export class ModalImportIntelbrasIncontrolVisitorsModule {}
