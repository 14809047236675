import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Dependent } from '@api/model/dependent';
import { DependentService } from '@api/service/dependent.service';
import { ResidenceService } from '@api/service/residence.service';
import { Subject } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import { ModalCreateDependent } from '../modal-create-dependent/modal-create-dependent';
import swal from 'sweetalert2';
import { debounceTime, takeUntil, timeout } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../../services/util.service';
import { EcondosQuery } from '@api/model/query';
import { capitalize, removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';
import { FormControl } from '@angular/forms';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

@Component({
  selector: 'app-dependent-list',
  templateUrl: 'dependent-list.html',
  styleUrls: ['./dependent-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DependentListComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  residence: Residence;
  @Input()
  dependents: Dependent[] = [];
  @Input()
  condo: Condo;
  @Input()
  user: User;

  @Input() useNewPermissions = false;

  @Output()
  dependentToUser: EventEmitter<{ condoId: string; userId: string; dependentId: string }> = new EventEmitter();
  @Output()
  dependentLoaded: EventEmitter<Dependent[]> = new EventEmitter();

  @ViewChild('dependentTemplate', { static: true }) dependentTemplate: TemplateRef<any>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<any>;
  @ViewChild('dependentTable', { static: true }) dependentTable: TableComponent;

  hasDeleteAccess = false;
  hasUpdateAccess = false;
  hasCreateAccess = false;

  isBusinessCondo = false;
  isAdmin: boolean = false;
  page = 0;
  countData: number = 0;
  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<Dependent>[] = [];
  public unsubscribe$ = new Subject();
  public searchToken = new FormControl('');
  constructor(
    private residenceService: ResidenceService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private dependentService: DependentService,
    private modalService: BsModalService
  ) {
    this.searchToken.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(token => {
      this.getData({ token });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  ngOnInit() {
    this.tableColumns = [
      {
        columnId: 'note',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.dependentTemplate,
        sortable: false
      }
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo && changes.residence && changes.user) {
      const user: User = changes.user.currentValue;
      const residence: Residence = changes.residence.currentValue;
      const condo = changes.condo.currentValue;
      this.isBusinessCondo = condo?.isBusinessCondo();
      this.isAdmin = user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id);
      this.hasCreateAccess =
        user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id) || residence.isOwner(user._id) || residence.isUser(user._id);
      this.hasDeleteAccess =
        user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id) || residence.isOwner(user._id) || residence.isUser(user._id);
      this.hasUpdateAccess =
        user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id) || residence.isOwner(user._id) || residence.isUser(user._id);
      if (this.useNewPermissions) {
        this.populatePermissions(condo);
      }

      if (!this.dependents.length) {
        this.getData();
      }
    }
  }

  populatePermissions(condo: Condo) {
    this.hasDeleteAccess = this.user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.userResidences.dependents.remove });
    this.hasUpdateAccess = this.user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.userResidences.dependents.update });
    this.hasCreateAccess = this.user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.userResidences.dependents.add });
  }
  getData({ page = 0, token = this.searchToken.value } = {}) {
    this.status = 'LOADING';
    this.cdr.detectChanges();
    const { pageSize } = this.dependentTable.getCurrentState();

    const dependentQuery: EcondosQuery = {
      $select: 'name residence rg cpf phone email birthDate kinship allowVisitors specialNeeds specialNeedsDetails room',
      $populate: [
        { path: 'picture', select: 'url thumbnail type format name' },
        { path: 'residence', select: 'identification' }
      ],
      $sort: 'name',
      residence: this.residence._id,
      $page: page,
      $limit: pageSize
    };

    if (token) {
      const terms = token
        .split(' ')
        .map(word => removeAccents(word))
        .map(word => replaceVowelsToAccentedVowels(word));

      const nameAnd = [];
      terms.forEach(term => {
        nameAnd.push({
          $or: [{ name: { $regex: term, $options: 'i' } }]
        });
      });
      dependentQuery.$and = nameAnd;
    }
    let observable;
    if (this.useNewPermissions) {
      observable = this.residenceService.getResidenceDependents(this.condo._id, this.residence._id, dependentQuery);
    } else {
      dependentQuery.residence = this.residence._id;
      observable = this.dependentService.getDependents(this.condo._id, dependentQuery);
    }
    observable.subscribe({
      next: res => {
        this.status = 'SUCCESS';
        this.dependents = res.dependents || [];
        this.countData = res.count;
        this.dependentLoaded.emit(this.dependents);
        this.cdr.detectChanges();
      },
      error: () => {
        this.status = 'ERROR';
        this.cdr.detectChanges();
      }
    });
  }

  create() {
    const initialState = {
      condo: this.condo,
      dependentResidence: this.residence,
      useNewPermissions: this.useNewPermissions,
      onCreate: (dependent: Dependent) => {
        this.countData = +this.countData + 1;
        this.dependents = [].concat(dependent, this.dependents);
        this.cdr.detectChanges();
        this.dependentLoaded.emit(this.dependents);
      }
    };
    this.modalService.show(ModalCreateDependent, { initialState, class: 'modal-lg' });
  }

  edit(dependent: Dependent) {
    const initialState = {
      condo: this.condo,
      dependentResidence: this.residence,
      dependent,
      useNewPermissions: this.useNewPermissions,
      onUpdate: updatedDependent => {
        const index = this.dependents.findIndex(d => d._id === dependent._id);
        this.dependents[index] = updatedDependent;
        this.dependents = [].concat(this.dependents);
        this.cdr.detectChanges();
        this.dependentLoaded.emit(this.dependents);
      }
    };
    this.modalService.show(ModalCreateDependent, { initialState, class: 'modal-lg' });
  }

  delete(dependent: Dependent) {
    const onPreConfirm = () => {
      let observable;
      if (this.useNewPermissions) {
        observable = this.residenceService.removeDependent(this.condo._id, this.residence._id, dependent._id);
      } else {
        observable = this.dependentService.delete(this.condo._id, dependent._id);
      }
      return observable
        .pipe(timeout(10000))
        .toPromise()
        .catch(err => {
          let message = 'Não foi possível excluir o registro, tente novamente...';
          if (err?.originalError?.error?.includes('A condo user cant delete a dependent with registered devices')) {
            message =
              'Este dependente possui dispositivo(s) de acesso e não pode ser removido. Procure a administração para solicitar sua exclusão.';
          }
          return Promise.reject(message);
        });
    };
    const onSuccess = () => {
      const index = this.dependents.findIndex(d => d._id === dependent._id);
      this.dependents.splice(index, 1);
      this.dependents = [].concat(this.dependents);
      this.countData = this.countData - 1;
      this.cdr.detectChanges();
      this.toastr.success('Registro removido com sucesso');
      this.dependentLoaded.emit(this.dependents);
    };
    swal({
      type: 'question',
      text: `Deseja remover o ${this.condo.customLabels.dependent.singular} ${dependent.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: onPreConfirm
    }).then(onSuccess, console.error);
  }

  revealData(field: string, dependent) {
    dependent.isDataMasked[field] = false;

    if (!dependent[field].toString().includes('*') && !dependent[field].toString().includes('3000')) {
      return;
    }

    let query: EcondosQuery = {
      $select: field,
      $and: []
    };

    const callback = ({ data }) => {
      dependent[field] = data;

      this.cdr.detectChanges();
    };

    const errorCallback = error => {
      if (error) {
        this.toastr.warning(
          `Somente ${this.condo.customLabels.voter.singular} que também é ${this.condo.customLabels.resident.singular} pode realizar essa ação.`
        );
      }
    };

    this.dependentService.getDependentUnmaskedField(this.condo._id, dependent._id, field, query).pipe(timeout(10000)).subscribe({
      next: callback,
      error: errorCallback
    });
  }

  convertDependentToUser(dependent: Dependent) {
    this.dependentService
      .convertDependentToUser(this.condo._id, dependent._id)
      .pipe(timeout(10000))
      .subscribe(
        (userId: any) => {
          const index = this.dependents.findIndex(d => d._id === dependent._id);
          this.dependents.splice(index, 1);
          this.dependents = [].concat(this.dependents);
          this.cdr.detectChanges();
          this.toastr.success(
            `${
              capitalize(this.condo?.customLabels?.dependent?.singular) || (this.condo.isBusinessCondo() ? 'Funcionário' : 'Dependente')
            } convertido com sucesso`
          );
          this.dependentToUser.emit({
            condoId: this.condo._id,
            dependentId: dependent._id,
            userId: userId._id
          });
        },
        error => {
          this.toastr.error(
            `Não foi possível converter ${
              this.condo?.customLabels?.dependent?.singular || (this.condo.isBusinessCondo() ? 'funcionário' : 'dependente')
            } em usuário`
          );
          console.log(error);
        }
      );
  }

  protected readonly PERMISSIONS = PERMISSIONS;
}
