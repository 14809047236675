import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { EcondosFilter } from '@api/model/filter';
import { Contact } from '@api/model/interface/contact';
import { Residence } from '@api/model/interface/residence';
import { EcondosQuery } from '@api/model/query';
import { User } from '@api/model/user';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ResidenceAutoCompleteComponent } from '../residence-auto-complete/residence-auto-complete.component';
import { ResidentAutoCompleteComponent } from '../resident-auto-complete/resident-auto-complete.component';
import { VehiclesPickerComponent } from '../vehicles-picker/vehicles-picker.component';
import { VisitorPickerComponent } from '../visitor-picker/visitor-picker';

@Component({
  selector: 'app-modal-filter',
  templateUrl: 'modal-filter.component.html'
})
export class ModalFilterComponent implements OnInit, AfterViewInit {
  filters: EcondosFilter[];
  initialQuery: EcondosQuery;
  title: string;
  callback;
  disableClearFiltersButton = false;

  @ViewChild(ResidenceAutoCompleteComponent) residencePicker: ResidenceAutoCompleteComponent;
  @ViewChild(VehiclesPickerComponent) vehiclesPicker: VehiclesPickerComponent;
  @ViewChild(ResidentAutoCompleteComponent) userPicker: ResidentAutoCompleteComponent;
  @ViewChild(VisitorPickerComponent) visitorPicker: VisitorPickerComponent;

  filterForm: UntypedFormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    // O cloneDeep foi utilizado para não alterar as variáveis passadas por referência
    this.initialQuery = cloneDeep(this.initialQuery);

    this.filterForm = this.formBuilder.group(
      this.filters.reduce((accumulator, currentValue) => {
        if (currentValue.element === 'button-select') {
          accumulator[currentValue.name] = [];
        } else {
          accumulator[currentValue.name] = [''];
        }

        return accumulator;
      }, {})
    );
  }

  ngAfterViewInit() {
    this.setInitialFilterValues(this.filters);
  }

  setInitialFilterValues(filters: EcondosFilter[]) {
    filters.forEach(currentFilter => {
      if (currentFilter.value) {
        this.filterForm.get(currentFilter.name).setValue(currentFilter.value);

        const residencePicker = filters.find(filter => filter.element === 'residence-picker');
        if (residencePicker?.value) {
          this.residencePicker?.onTypeAheadSelect({ item: residencePicker.value });
        }

        const vehiclePicker = filters.find(filter => filter.element === 'vehicles-picker');
        if (vehiclePicker?.value) {
          this.vehiclesPicker?.selectVehicle(vehiclePicker.value);
        }

        const userPicker = filters.find(filter => filter.element === 'user-picker');
        if (userPicker?.value) {
          this.userPicker?.onTypeAheadSelect({ item: userPicker.value });
        }

        const visitorPicker = filters.find(filter => filter.element === 'visitor-picker');
        if (visitorPicker?.value) {
          this.visitorPicker?.selectContact(visitorPicker.value);
        }
      }
    });
  }

  returnQuery() {
    const query = this.buildQuery(this.initialQuery, this.filterForm.value);
    const filters = this.setFiltersValues(this.filters, this.filterForm.value);
    this.callback({
      query,
      filters
    });
    this.bsModalRef.hide();
  }

  buildQuery(query: EcondosQuery = {}, filterOptions): EcondosQuery {
    Object.keys(filterOptions).forEach(option => {
      if (this.filterForm.get(option).value) {
        let value;

        if (Array.isArray(this.filterForm.get(option).value)) {
          value = this.filterForm.get(option).value;
        } else if (typeof this.filterForm.get(option).value === 'object') {
          value = this.filterForm.get(option).value._id;
        } else {
          value = this.filterForm.get(option).value;
        }

        if (this.filters.find(filter => filter.name === option).searchType === 'match') {
          query[option] = value;
        } else if (this.filters.find(filter => filter.name === option).searchType === 'regex') {
          query[option] = {
            $regex: value,
            $options: 'i'
          };
        }
      }
    });

    return query;
  }

  setFiltersValues(filters: EcondosFilter[], formValues): EcondosFilter[] {
    const updatedFilters = filters.map(filter => {
      return {
        ...filter,
        value: formValues[filter.name],
        valueLabel: this.getValueLabelFromFilter(filter, formValues[filter.name])
      };
    });

    return updatedFilters;
  }

  getValueLabelFromFilter(filter: EcondosFilter, formValue): string {
    if (filter.element === 'residence-picker') {
      return formValue?.identification || '';
    } else if (filter.element === 'button-select') {
      return Array.isArray(formValue)
        ? formValue
            ?.map(value => {
              const option: any = filter.buttonSelectOptions.options.find((option: any) => option.value === value);
              return option.label;
            })
            .join(', ')
        : '';
    } else if (filter.element === 'vehicles-picker') {
      return formValue?.model + ' - ' + formValue?.plate;
    } else if (filter.element === 'user-picker') {
      return formValue?.fullName;
    } else if (filter.element === 'select') {
      return filter.selectOptions.find(opt => opt.value === formValue)?.label;
    } else if (filter.elementType === 'date') {
      return moment(formValue).utc().format('DD/MM/YYYY');
    } else if (filter.element === 'visitor-picker') {
      return formValue?.fullName;
    } else {
      return formValue;
    }
  }

  clearFilters() {
    Object.keys(this.filterForm.value).forEach(filter => {
      this.filterForm.get(filter).setValue('');
    });
    const filters = this.setFiltersValues(this.filters, this.filterForm.value);

    this.callback({
      query: this.initialQuery,
      filters
    });
    this.bsModalRef.hide();
  }

  onResidenceSelect(residence: Residence, formControlName: string) {
    this.filterForm.get(formControlName).setValue(residence);
  }

  onVehicleSelect(vehicle: CondoVehicle, formControlName: string) {
    this.filterForm.get(formControlName).setValue(vehicle);
  }

  onUserSelected(user: User, formControlName: string) {
    this.filterForm.get(formControlName).setValue(user);
  }

  onContactSelect(contact: Contact, formControlName: string) {
    this.filterForm.get(formControlName).setValue(contact);
  }
}
