import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CondoContact } from '@api/model/contact/condo.contact';
import { CondoVehicle } from '@api/model/condo.vehicle';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { validatePlate } from '@api/util/util';
import { lastValueFrom } from 'rxjs';
import { VehicleService } from '@api/service/vehicle.service';
import { Condo } from '@api/model/condo';
import { map } from 'rxjs/operators';
import { User } from '@api/model/user';
import { ModalNewCondoContactComponent } from '../../pages/gate/access.control/new.condo.contact/new.condo.contact.modal';
import { CondoContactService } from '@api/service/condo.contact.service';

@Component({
  templateUrl: './modal-visitor-plates-editor.component.html',
  selector: 'app-modal-visitor-plates-editor',
  styleUrls: ['./modal-visitor-plates-editor.component.scss']
})
export class ModalVisitorPlatesEditorComponent implements OnInit {
  condoContact: CondoContact;
  condo: Condo;
  user: User;

  isAdmin = false;

  constructor(
    private bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private vehicleService: VehicleService,
    private condoContactService: CondoContactService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.user.isOwner() || this.user.isAdmin();
  }

  dismiss() {
    this.bsModalRef.hide();
  }

  async deleteVehicle(vehicle: CondoVehicle, index) {
    await swal({
      type: 'question',
      text: 'Você tem certeza que deseja remover este veículo',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#f53d3d',
      showCancelButton: true,
      cancelButtonText: 'Não',
      reverseButtons: true,
      preConfirm: () => {
        return lastValueFrom(this.vehicleService.deleteVehicleFromCondo(this.condo._id, vehicle._id)).catch(() => {
          return Promise.reject('Não foi possível remover o veículo');
        });
      }
    })
      .then(() => {
        this.condoContact.condoVehicles.splice(index, 1);
        this.toastrService.success('Placa removida com sucesso');
      })
      .catch(swal.noop);
  }

  editVehicle(vehicle: CondoVehicle) {
    swal({
      title: `Editar placa de veículo do ${this.condo?.customLabels?.visitor?.singular || 'visitante'}`,
      text: `Digite a placa do veículo. Utilize apenas dígitos e letras, evite traços.`,
      input: 'text',
      confirmButtonText: 'Salvar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      inputPlaceholder: 'Ex: XXX9999',
      inputAutoTrim: true,
      showLoaderOnConfirm: true,
      inputValue: vehicle.plate,
      preConfirm: async plate => {
        if (validatePlate(plate)) {
          return lastValueFrom(this.vehicleService.updateVehicle(this.condo._id, vehicle._id, { plate }).pipe(map(() => plate)));
        } else {
          return Promise.reject(`Placa inválida: ${plate || 'vazia'}, digite uma placa válida no fomato ABC1E23 ou ABC1234`);
        }
      }
    })
      .then(plate => {
        vehicle.plate = plate;
        this.toastrService.success('Placa editada com sucesso');
      })
      .catch(swal.noop);
  }

  addVisitorVehicle(visitor: CondoContact) {
    swal({
      title: `Cadastrar veículo para ${this.condo?.customLabels?.visitor?.singular || 'visitante'}`,
      text: `Digite a placa do novo veículo para ${visitor.fullName}. Utilize apenas dígitos e letras, evite traços.`,
      input: 'text',
      confirmButtonText: 'Salvar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      inputPlaceholder: 'Ex: XXX9999',
      inputAutoTrim: true,
      showLoaderOnConfirm: true,
      preConfirm: async plate => {
        if (validatePlate(plate)) {
          return this.createVisitorVehicle(plate, visitor);
        } else {
          return Promise.reject(`Placa inválida: ${plate || 'vazia'}, digite uma placa válida no fomato ABC1E23 ou ABC1234`);
        }
      }
    })
      .then(message => {
        this.toastrService.success(message);
      })
      .catch(swal.noop);
  }

  async createVisitorVehicle(plate: string, visitor: CondoContact) {
    if (plate) {
      const visitorVehiclesPlates = visitor.condoVehicles.map(condoVehicle => condoVehicle.plate.replace(/[^a-zA-Z0-9]/gm, ''));

      const leanPlate = plate.replace(/[^a-zA-Z0-9]/gm, '');

      if (visitorVehiclesPlates.indexOf(leanPlate) >= 0) {
        return Promise.reject(`Esse ${this.condo?.customLabels?.visitor?.singular || 'visitante'} já possui um veículo com esta placa.`);
      }

      const data = {
        plate: leanPlate,
        condo: this.condo._id,
        createdBy: this.user._id
      };

      let vehicleId: string;

      try {
        const query = { plate: leanPlate };

        const [vehicle] = await lastValueFrom(this.vehicleService.getVehicles(this.condo._id, query).pipe(map(({ vehicles }) => vehicles)));

        if (vehicle) {
          vehicleId = vehicle._id;
        } else {
          const response: any = await lastValueFrom(this.vehicleService.createVehicle(this.condo._id, data));
          vehicleId = response._id;
        }

        const newVehicle = new CondoVehicle({ _id: vehicleId, ...data });

        const visitorData = { condoVehicles: [...visitor.condoVehicles, newVehicle] };

        await lastValueFrom(this.condoContactService.updateCondoContact(this.condo._id, visitor._id, visitorData));

        visitor.condoVehicles.push(newVehicle);

        return Promise.resolve('Placa cadastrada com sucesso');
      } catch (err) {
        console.error(err);
        const message = err.originalError?.message || 'Ocorreu um erro ao salvar o veículo. Verifique sua conexão e tente novamente.';
        return Promise.reject(message);
      }
    }
  }
}
