export const TOOLBAR_CONFIG: {
  headerToolbar: { left: string; center: string; right: string };
  buttonText: { day: string; month: string; next: string; prev: string; today: string; week: string; list: string };
} = {
  headerToolbar: {
    left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    center: 'title',
    right: 'prev today next'
  },
  buttonText: {
    day: 'Dia',
    month: 'Mês',
    next: 'Próximo',
    prev: 'Anterior',
    today: 'Atual',
    week: 'Semana',
    list: 'Lista'
  }
};

export const TRANSLATION_CONFIG: { locale: 'pt-br'; timeZone: string; allDayText: string; moreLinkText: string } = {
  locale: 'pt-br',
  timeZone: 'America/Sao_Paulo',
  allDayText: 'Todo o dia',
  moreLinkText: 'mais'
};

export const THEME_CONFIG: {
  initialView: string;
  contentHeight: string;
  themeSystem: 'standard';
  navLinks: boolean;
  views: {
    dayGrid: { dayMaxEvents: number };
    day: { dayMaxEvents: number };
    week: { dayMaxEvents: number };
  };
} = {
  initialView: 'dayGridMonth',
  contentHeight: 'auto',
  themeSystem: 'standard',
  navLinks: true,
  views: {
    day: { dayMaxEvents: 6 },
    dayGrid: { dayMaxEvents: 6 },
    week: { dayMaxEvents: 6 }
  }
};
