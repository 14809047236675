import { Occurrence } from '@api/model/interface/occurrence';
import { CondoContact } from '@api/model/contact/condo.contact';
import { Residence } from '@api/model/interface/residence';
import { User } from '@api/model/user';
import { OccurrenceBuilder } from '@api/model/occurrence/occurrence.builder';
import { ResidenceBuilder } from '@api/model/residence/residence.builder';

export interface VisitorRequest {
  _id: string;
  registeredContact: CondoContact;
  condoContact: CondoContact;
  occurrence: Occurrence;
  approvedAt: string;
  approvedBy: User;
  rejectedBy: User;
  status: 'ACCEPTED' | 'REJECTED' | 'PENDING' | 'CANCELED' | 'CLOSED';
  rejectedAt: string;
  fingerprint: string;
  residence: Residence;
}

export const BuildVisitorRequest = (data): VisitorRequest => {
  if (data) {
    return {
      _id: typeof data._id == 'string' ? data._id : data,
      registeredContact: data.registeredContact || null,
      condoContact: data.condoContact ? new CondoContact(data.condoContact) : null,
      occurrence: data.occurrence ? OccurrenceBuilder.build(data.occurrence) : null,
      approvedAt: data.approvedAt || '',
      approvedBy: data.approvedBy ? new User(data.approvedBy) : null,
      rejectedBy: data.rejectedBy ? new User(data.approvedBy) : null,
      status: data.status,
      rejectedAt: data.rejectedAt || '',
      fingerprint: data.fingerprint || '',
      residence: data.residence ? ResidenceBuilder.build(data.residence) : null
    };
  }
};
