import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { ResidenceBuilder } from '@api/model/residence/residence.builder';
import { File } from '@api/model/file';

export type ConstructionFormData = Omit<Construction, '_id' | 'createdBy' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'condo'>;
export enum CONSTRUCTION_STATUS {
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  APPROVED = 'APPROVED',
  DOING = 'DOING',
  DONE = 'DONE',
  CANCELED = 'CANCELED'
}

export enum CONSTRUCTION_STATUS_LABEL {
  WAITING_APPROVAL = 'Esperando Aprovação',
  APPROVED = 'Aprovada',
  DOING = 'Em Andamento',
  DONE = 'Concluída',
  CANCELED = 'Cancelada'
}

export class Company {
  name?: string;
  cnpj?: string;
  email?: string;
  phone?: string;

  constructor(company) {
    this.name = company.name || '';
    this.cnpj = company.cnpj || '';
    this.email = company.email || '';
    this.phone = company.phone || '';
  }
}

export enum TECHNICAL_MANAGER_TYPES {
  ENGINEER = 'ENGINEER',
  ARCHITECT = 'ARCHITECT'
}

export enum TECHNICAL_MANAGER_TYPES_LABEL {
  ENGINEER = 'engenheiro',
  ARCHITECT = 'arquiteto'
}

export class TechnicalManager {
  name: string;
  cpf: string;
  email: string;
  phone: string;
  profession: TECHNICAL_MANAGER_TYPES;
  crea: string;

  constructor(technicalManager) {
    this.name = technicalManager.name || '';
    this.cpf = technicalManager.cpf || '';
    this.email = technicalManager.email || '';
    this.phone = technicalManager.phone || '';
    this.profession = technicalManager.profession || '';
    this.crea = technicalManager.crea || '';
  }
}

export class Construction {
  _id: string;
  condo: Condo;
  residence: Residence;
  status: string;
  startDate: string;
  duration: number;
  local: string;
  attachments: File[];
  createdBy: User;
  createdAt: string;
  updatedBy: User;
  updatedAt: string;
  responsible: {
    technicalManager?: TechnicalManager;
    company?: Company;
  };
  noise: boolean = false;
  description: string;
  technicalAttachments: File[];

  constructor(construction) {
    if (typeof construction === 'string') {
      this._id = construction;
    } else {
      this._id = construction._id || '';
      this.condo = new Condo(construction.condo);
      this.residence = ResidenceBuilder.build(construction.residence);
      this.status = construction.status || '';
      this.startDate = construction.startDate || '';
      this.duration = construction.duration || 0;
      this.local = construction.local || '';
      this.attachments = (construction.attachments && construction.attachments.map(file => new File(file))) || [];
      this.technicalAttachments =
        (construction.technicalAttachments && construction.technicalAttachments.map(file => new File(file))) || [];
      this.createdBy = new User(construction.createdBy) || null;
      this.createdAt = construction.createdAt || '';
      this.updatedBy = new User(construction.updatedBy) || null;
      this.updatedAt = construction.updatedAt || '';
      const responsible = { company: null, technicalManager: null };
      if (construction.responsible) {
        const { company, technicalManager } = construction.responsible;
        if (company) {
          responsible.company = new Company(construction.responsible.company);
        }
        if (technicalManager) {
          responsible.technicalManager = new TechnicalManager(construction.responsible.technicalManager);
        }
      }
      this.responsible = responsible;
      this.description = construction.description || '';
      this.noise = construction.noise || false;
    }
  }
}
