import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '@api/model/condo';
import { UntypedFormControl } from '@angular/forms';
import { KeyService } from '@api/service/key.service';
import { Status } from '@api/model/status';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'key-history-modal',
  templateUrl: 'modal-key-history.html',
  styleUrls: ['./modal-key-history.scss']
})
export class ModalKeyHistory implements OnInit {
  condo: Condo;
  key: any;
  status: Status = new Status();

  startDate = new UntypedFormControl(moment().startOf('month').toDate());
  endDate = new UntypedFormControl(new Date());
  history = [];

  constructor(public bsModalRef: BsModalRef, private keyService: KeyService, private toastr: ToastrService) {}

  ngOnInit() {
    this.status.setAsDownloading();
    this.getData();
  }

  getData() {
    const params = [];
    params.push({ 'createdAt[$gte]': moment(this.startDate.value).startOf('day').utc().toISOString() });
    params.push({ 'createdAt[$lte]': moment(this.endDate.value).endOf('day').utc().toISOString() });
    params.push({ $sort: '-createdAt' });

    this.status.setAsDownloading();
    this.keyService.getKeyHistory(this.condo._id, this.key._id, params).subscribe(
      res => {
        if (Array.isArray(res)) {
          this.history = res;
          this.status.setAsSuccess();
        } else {
          this.status.setAsError();
        }
      },
      err => {
        this.toastr.error('Não foi possível buscar os dados. Tente novamente');
      }
    );
  }
}
