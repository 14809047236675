import { Residence } from '../interface/residence';

/**
 * Created by Rafael on 07/12/2016.
 */

export class HouseResidence extends Residence {
  public zipCode;
  public address;
  public block: string;
  public lot: string;

  static constructHouseIdentification(address: string, number: string | number) {
    return address + ', ' + number;
  }

  constructor(residence) {
    super(residence);
    this.zipCode = residence.zipCode;
    this.address = residence.address;
    this.block = residence.block;
    this.lot = residence.lot;
  }
}
