import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ResidenceService } from '../../api/service/residence.service';
import { Subscription } from 'rxjs';
import { Residence } from '../../api/model/interface/residence';
import swal from 'sweetalert2';
import { map, mergeMap, timeout } from 'rxjs/operators';
import { Condo } from '../../api/model/condo';
import { User } from '../../api/model/user';
import { ToastrService } from 'ngx-toastr';
import { File } from '@api/model/file';
import { FileService } from '@api/service/file.service';
import { EcondosQuery } from '@api/model/query';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';

@Component({
  selector: 'app-residence-documents-list',
  templateUrl: 'residence-documents-list.component.html',
  styleUrls: ['residence-documents-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResidenceDocumentsListComponent implements OnChanges, OnDestroy, OnInit {
  @Input()
  residence: Residence;
  @Input()
  documents: File[] = [];
  @Input()
  condo: Condo;
  @Input()
  user: User;

  @Output()
  documentsChanged: EventEmitter<File[]> = new EventEmitter();

  @ViewChild('documentTemplate', { static: true }) documentTemplate: TemplateRef<File>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<File>;
  @ViewChild('documentsTable', { static: true }) documentsTable: TableComponent;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<File>[] = [];
  subscription: Subscription = new Subscription();

  hasWriteAccess = false;
  countData: number = 0;

  allowedFiles = '.pdf, .doc, .docx, .xsl, .xlsx, .ppt, .pptx, .pps, .ppsx, .txt, image/*';

  callback;

  constructor(
    private residenceService: ResidenceService,
    private toastr: ToastrService,
    private fileService: FileService,
    private cdr: ChangeDetectorRef
  ) {
    this.callback = ([file]) => this.uploadFile(file);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo && changes.residence && changes.user) {
      const user: User = changes.user.currentValue;
      const condo = changes.condo.currentValue;
      const residence: Residence = changes.residence.currentValue;
      this.hasWriteAccess =
        user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id) || residence.isOwner(user._id) || residence.isUser(user._id);
      if (this.documents.length === 0 || changes.residence || changes.condo) {
        this.getData();
      }
    }
  }
  ngOnInit(): void {
    this.tableColumns = [
      {
        columnId: 'document',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.documentTemplate,
        sortable: false
      }
    ];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getData({ page = 0 } = {}) {
    this.status = 'LOADING';
    const { pageSize } = this.documentsTable.getCurrentState();
    const query: EcondosQuery = {
      $select: 'documents name tags thumbnail url type',
      $populate: ['documents'],
      $page: page,
      $limit: pageSize
    };
    this.residenceService.getDocuments(this.condo._id, this.residence._id, query).subscribe({
      next: res => {
        this.documents = res.files.map(d => new File(d));
        this.countData = res.count;
        this.documents = [].concat(this.documents);
        this.status = 'SUCCESS';
        this.documentsChanged.emit(this.documents);
        this.cdr.detectChanges();
      },
      error: () => {
        this.status = 'ERROR';
        this.cdr.detectChanges();
      }
    });
  }
  remove(document: File) {
    swal({
      type: 'question',
      text: `Deseja remover o document ${document.tags[0] || document.name} ?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.residenceService
          .removeDocument(this.condo._id, this.residence._id, document._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível remover o documento, tente novamente...');
          });
      }
    }).then(
      () => {
        this.toastr.success('Documento removido com sucesso');
        const index = this.documents.findIndex(d => d._id === document._id);
        this.documents.splice(index, 1);
        this.documents = [].concat(this.documents);
        this.countData = this.countData - 1;
        this.status = 'SUCCESS';
        this.cdr.detectChanges();
        this.documentsChanged.emit(this.documents);
      },
      error => {
        console.log(error);
      }
    );
  }

  uploadFile(file) {
    swal({
      title: 'Digite o nome do documento',
      type: 'info',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      inputPlaceholder: 'Ex: Exame médico para piscina, Contrato de reserva, etc...',
      showLoaderOnConfirm: true,
      preConfirm: name => {
        const formData = new FormData();
        formData.append(name || 'file', file);
        return this.fileService
          .uploadFilesFromFormData(formData)
          .pipe(
            mergeMap(([f]) => this.fileService.setFileTags(f._id, [name]).pipe(map(() => f))),
            mergeMap(f =>
              this.residenceService.addDocument(this.condo._id, this.residence._id, f._id).pipe(
                map(() => {
                  f.tags = [name];
                  return f;
                })
              )
            )
          )
          .toPromise()
          .then(f => {
            this.documents = [].concat(this.documents, f);
            this.countData = +this.countData + 1;
            this.status = 'SUCCESS';
            this.cdr.detectChanges();
            this.documentsChanged.emit(this.documents);
          })
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível adicionar o documento');
          });
      }
    }).then(
      () => {
        this.toastr.success('Documento adicionado  com sucesso');
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }
}
