import * as moment from 'moment';
import { User } from './user';
import { File } from './file';
import { Local, LocalExtraFee } from './local';

export class Reservation {
  // TODO NO projeto web refatorar estes status para o novo adicionado mais abaixo. No projeto mobile já está correto
  static APPROVED_STATUS = 'APPROVED';
  static DONE_STATUS = 'DONE';
  static PENDING_STATUS = 'PENDING';
  static CANCELED_STATUS = 'CANCELED';
  static CANCELED_WITH_PENALTY_STATUS = 'CANCELED_WITH_PENALTY';

  static STATUS = {
    APPROVED: 'APPROVED',
    DONE: 'DONE',
    PENDING: 'PENDING',
    CANCELED: 'CANCELED',
    CANCELED_WITH_PENALTY: 'CANCELED_WITH_PENALTY',
    IN_QUEUE: 'IN_QUEUE'
  };

  static STATUS_LABEL = {
    APPROVED: 'Aprovada',
    DONE: 'Concluída',
    PENDING: 'Pendente',
    CANCELED: 'Cancelada',
    CANCELED_WITH_PENALTY: 'Cancelada com multa',
    IN_QUEUE: 'Na fila'
  };
  public _id: string;
  private _condo: any;
  private _reservationLocal: any;
  private _residence: any;
  private _status: any;
  private _canceledBy: any;
  private _canceledAt: any;
  private _approvedBy: any;
  private _approvedAt: any;
  private _date: Date;
  private _startDate: Date;
  private _endDate: Date;
  private _period: string;
  private _createdBy: any;
  private _createdAt: any;
  private _updatedBy: any;
  private _value: number;
  private _extraFees: LocalExtraFee[];
  observation: string;
  positionInQueue: number;
  checkIn: {
    items: Array<{ item: string; status: boolean; description: string }>;
    checkedAt: any;
    checkedBy: User;
    signature?: File;
  };
  checkOut: {
    items: Array<{ item: string; status: boolean; description: string }>;
    checkedAt: any;
    checkedBy: User;
    signature?: File;
  };
  otherReservationLocals: Local[];
  createNewAccessLiberation: boolean;

  public totalValue = 0;

  constructor(reservation) {
    if (typeof reservation == 'string') {
      this._id = reservation;
    } else if (reservation) {
      this._id = reservation._id;
      this._condo = reservation.condo;
      this._reservationLocal = reservation.reservationLocal;
      this._residence = reservation.residence;
      this._status = reservation.status;
      this._canceledBy = reservation.canceledBy;
      this._canceledAt = reservation.canceledAt;
      this._approvedBy = reservation.approvedBy;
      this._approvedAt = reservation.approvedAt;
      this._date = reservation.date;
      this._startDate = reservation.startDate;
      this._endDate = reservation.endDate;
      this._period = reservation.period;
      this._createdBy = reservation.createdBy ? new User(reservation.createdBy) : null;
      this._createdAt = reservation.createdAt;
      this._updatedBy = reservation.updatedBy;
      this._value = reservation.value;
      this.positionInQueue = reservation.positionInQueue;
      this._extraFees = reservation.extraFees || [];
      this.checkIn = reservation.checkIn;
      this.checkOut = reservation.checkOut;
      this.observation = reservation.observation;

      let totalValue = this.value || 0;

      if (this._extraFees?.length) {
        this._extraFees.forEach(fee => {
          totalValue += fee.value || 0;
        });
      }

      this.totalValue = totalValue;
      this.otherReservationLocals = reservation.otherReservationLocals || [];
      this.createNewAccessLiberation = reservation.createNewAccessLiberation || false;
    }
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get condo(): any {
    return this._condo;
  }

  set condo(value: any) {
    this._condo = value;
  }

  get reservationLocal(): any {
    return this._reservationLocal;
  }

  set reservationLocal(value: any) {
    this._reservationLocal = value;
  }

  get residence(): any {
    return this._residence;
  }

  set residence(value: any) {
    this._residence = value;
  }

  get status(): any {
    return this._status;
  }

  set status(value: any) {
    this._status = value;
  }

  get canceledBy(): any {
    return this._canceledBy;
  }

  set canceledBy(value: any) {
    this._canceledBy = value;
  }

  get approvedBy(): any {
    return this._approvedBy;
  }

  set approvedBy(value: any) {
    this._approvedBy = value;
  }

  get approvedAt(): any {
    return this._approvedAt;
  }

  set approvedAt(value: any) {
    this._approvedAt = value;
  }

  get date(): Date {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;
  }

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;
  }

  get period(): string {
    return this._period;
  }

  set period(value: string) {
    this._period = value;
  }

  get createdBy(): any {
    return this._createdBy;
  }

  set createdBy(value: any) {
    this._createdBy = value;
  }

  get updatedBy(): any {
    return this._updatedBy;
  }

  set updatedBy(value: any) {
    this._updatedBy = value;
  }

  get canceledAt(): any {
    return this._canceledAt;
  }

  set canceledAt(value: any) {
    this._canceledAt = value;
  }

  get createdAt(): any {
    return this._createdAt;
  }

  set createdAt(value: any) {
    this._createdAt = value;
  }

  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
  }

  get extraFees(): LocalExtraFee[] {
    return this._extraFees;
  }

  set extraFees(value: LocalExtraFee[]) {
    this._extraFees = value;
  }

  get reservationDate() {
    return moment(this.startDate).utc().format('DD/MM/YY - HH:mm') + ' até ' + moment(this.endDate).utc().format('HH:mm');
  }

  get statusLabel() {
    return Reservation.STATUS_LABEL[this.status];
  }

  isCanceled() {
    return this.status == Reservation.CANCELED_STATUS || this.status == Reservation.CANCELED_WITH_PENALTY_STATUS;
  }

  isApproved() {
    return this.status == Reservation.APPROVED_STATUS;
  }

  isPending() {
    return this.status == Reservation.PENDING_STATUS;
  }

  isDone() {
    return this.status == Reservation.DONE_STATUS;
  }
}
