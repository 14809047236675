import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from '../../../theme';
import { ComponentsModule } from '../../../components/components.module';
import { PipesModule } from '../../../pipe/pipes.module';
import { MomentModule } from 'ngx-moment';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { GateResidentDetailsModalComponent } from './gate-resident-details.modal.component';
import { GateResidentDetailsResidencesComponent } from './gate-resident-details-residences/gate-resident-details-residences.component';
import { GateResidentAuthorizedVisitorsComponent } from './gate-resident-authorized-visitors/gate-resident-authorized-visitors.component';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ModalModule,
    ComponentsModule,
    ThemeModule,
    PipesModule,
    TooltipModule,
    MomentModule,
    PopoverModule,
    TabsModule,
    NgOptimizedImage,
    DirectivesModule
  ],
  declarations: [GateResidentDetailsModalComponent, GateResidentDetailsResidencesComponent, GateResidentAuthorizedVisitorsComponent]
})
export class GateResidentDetailsModalModule {}
