import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { BuildExtension, Extension, ExtensionOnlyWithId, EXTENSIONS_TYPE, EXTENSIONS_TYPE_LABEL } from '@api/model/extension';
import { EcondosQuery } from '@api/model/query';
import { SipAccount } from '@api/model/sip-account';
import { Status } from '@api/model/status';
import { VirtualKey } from '@api/model/virtual-key';
import { VirtualKeyService } from '@api/service/virtual-key.service';
import { CreateExtensionDTO, ExtensionsService } from '@api/serviceV2/extensions.service';
import { SipAccountsService } from '@api/serviceV2/sip-accounts.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { ModalCreateSipAccountComponent } from '../modal-create-sip-account/modal-create-sip-account';

@Component({
  selector: 'app-modal-create-extension',
  templateUrl: 'modal-create-extension.html'
})
export class ModalCreateExtensionComponent implements OnInit {
  public condo: Condo;
  public sipAccountsOptions: SipAccount[] = [];
  public virtualKeysOptions: VirtualKey[] = [];

  public extension: Extension;

  public status = new Status();
  public sipAccountsLoadingStatus = new Status();
  public virtualKeysLoadingStatus = new Status();

  public extensionForm = this.formBuilder.group({
    sipAccount: ['', Validators.required],
    number: ['', Validators.required],
    name: ['', Validators.required],
    type: [EXTENSIONS_TYPE.CONDO, Validators.required],
    virtualKeys: []
  });

  public extensionsTypes = Object.keys(EXTENSIONS_TYPE).map(extTypeKey => ({
    value: EXTENSIONS_TYPE[extTypeKey],
    label: EXTENSIONS_TYPE_LABEL[extTypeKey]
  }));

  public onCreate: (extension: Extension) => void = () => {};
  public onUpdate: (extension: Extension) => void = () => {};

  public defaultErrorCallback = (err: any) => {
    console.log({ err });
    this.status.setAsError();

    if (err.message === 'Esse registro já está cadastrado no sistema') {
      swal({
        type: 'error',
        title: 'Ramal já cadastrado',
        text: `O ramal ${this.number.value} já está cadastrado para a conta SIP.`
      });
    } else {
      this.toastrService.error('Não foi possível salvar o ramal, tente novamente');
    }
  };

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private extensionsService: ExtensionsService,
    private sipAccountsService: SipAccountsService,
    private virtualKeyService: VirtualKeyService,
    private toastrService: ToastrService,
    private modalService: BsModalService
  ) {}

  public ngOnInit(): void {
    if (this.extension) {
      this.extensionForm.setValue({
        sipAccount: this.extension.sipAccount._id || '',
        number: this.extension.number || '',
        name: this.extension.name || '',
        type: this.extension.type || '',
        virtualKeys: this.extension.virtualKeys.map(virtualKey => virtualKey._id) || []
      });
    }

    this.getSipAccounts();
    this.getVirtualKeys();
  }

  public getSipAccounts(): void {
    this.sipAccountsLoadingStatus.setAsDownloading();

    const query: EcondosQuery = {
      $select: 'name',
      $sort: 'name'
    };

    this.sipAccountsService.getSipAccounts(this.condo.id, query).subscribe(
      ({ sipAccounts }) => {
        this.sipAccountsOptions = sipAccounts;

        if (this.sipAccountsOptions.length) {
          this.sipAccount.setValue(sipAccounts[0]._id);
        }

        this.sipAccountsLoadingStatus.setAsSuccess();
      },
      err => {
        console.error(err);
        this.sipAccountsLoadingStatus.setAsError();
      }
    );
  }

  public getVirtualKeys(): void {
    this.virtualKeysLoadingStatus.setAsDownloading();

    this.virtualKeyService.get(this.condo.id).subscribe(
      ({ virtualKeys }) => {
        this.virtualKeysOptions = virtualKeys;
        this.virtualKeysLoadingStatus.setAsSuccess();
      },
      err => {
        console.error(err);
        this.virtualKeysLoadingStatus.setAsError();
      }
    );
  }

  public handleSubmit(): void {
    if (this.extensionForm.valid) {
      this.status.setAsProcessing();

      const extensionToSave: CreateExtensionDTO = {
        sipAccount: this.sipAccount.value || '',
        number: this.number.value || '',
        name: this.name.value || '',
        type: this.type.value || '',
        virtualKeys: this.virtualKeys.value || []
      };

      if (this.extension) {
        this.updateExtension(extensionToSave);
      } else {
        this.createExtension(extensionToSave);
      }
    } else {
      for (const key of Object.keys(this.extensionForm.controls)) {
        this.extensionForm.get(key).markAsTouched();
      }
      this.toastrService.warning('Preencha os campos obrigatórios.');
    }
  }

  private mapSipAccount(sipAccountId: string): SipAccount {
    return this.sipAccountsOptions.find(sipAccount => sipAccount._id === sipAccountId);
  }

  private mapVirtualKeys(virtualKeysIds: string[]): VirtualKey[] {
    return this.virtualKeysOptions.filter(virtualKey => virtualKeysIds.includes(virtualKey._id));
  }

  public createExtension(extensionData: CreateExtensionDTO): void {
    const successCallback = (response: ExtensionOnlyWithId) => {
      const sipAccount = this.mapSipAccount(extensionData.sipAccount);
      const virtualKeys = this.mapVirtualKeys(extensionData.virtualKeys);

      const createdExtension = BuildExtension({
        ...extensionData,
        ...response,
        sipAccount,
        virtualKeys,
        createdAt: moment().toISOString()
      });

      this.onCreate(createdExtension as Extension);
      this.bsModalRef.hide();
      this.toastrService.success('Ramal cadastrado com sucesso.');
    };

    this.extensionsService.createExtension(this.condo._id, extensionData).subscribe(successCallback, this.defaultErrorCallback);
  }

  public updateExtension(extensionData: CreateExtensionDTO): void {
    const successCallback = () => {
      const sipAccount = this.mapSipAccount(extensionData.sipAccount);
      const virtualKeys = this.mapVirtualKeys(extensionData.virtualKeys);

      const createdExtension = BuildExtension({ ...this.extension, ...extensionData, sipAccount, virtualKeys });

      this.onUpdate(createdExtension as Extension);
      this.bsModalRef.hide();
      this.toastrService.success('Ramal atualizado com sucesso.');
    };

    this.extensionsService
      .updateExtension(this.condo._id, this.extension._id, extensionData)
      .subscribe(successCallback, this.defaultErrorCallback);
  }

  public handleOpenCreateSipAccountModal(): void {
    const initialState = {
      condo: this.condo,
      onCreate: (createdSipAccount: SipAccount) => {
        this.sipAccountsOptions = [createdSipAccount];
        this.sipAccount.setValue(createdSipAccount._id);
      }
    };

    this.modalService.show(ModalCreateSipAccountComponent, { initialState, ignoreBackdropClick: true });
  }

  public get sipAccount(): AbstractControl {
    return this.extensionForm.get('sipAccount');
  }

  public get number(): AbstractControl {
    return this.extensionForm.get('number');
  }

  public get name(): AbstractControl {
    return this.extensionForm.get('name');
  }

  public get type(): AbstractControl {
    return this.extensionForm.get('type');
  }

  public get virtualKeys(): AbstractControl {
    return this.extensionForm.get('virtualKeys');
  }
}
