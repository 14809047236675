import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'tag-input-component',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent {
  KEYS = {
    ENTER: 13,
    TAB: 9,
    BACKSPACE: 8,
    UP_ARROW: 38,
    DOWN_ARROW: 40,
    ESC: 27
  };

  @ViewChild('tagInput', { static: true }) inputRef: ElementRef;

  value = '';

  @Input()
  values: any[] = [];

  @Input()
  placeholder = 'Digite aqui...';

  @Input()
  canAdd = true;

  @Input()
  canRemove = true;

  @Input()
  inputType = 'text';

  @Input()
  showTip = true;

  @Input()
  formatter = (value, keyCode) => value;

  @Output()
  handleValueChange = new EventEmitter();

  onKeyDown(event) {
    const { keyCode } = event;

    switch (keyCode) {
      case this.KEYS.TAB:
      case this.KEYS.ENTER:
        this.handleDelimiter(event);
        break;
      case this.KEYS.BACKSPACE:
        this.handleBackspace(event);
        break;
      default:
        break;
    }
  }

  onKeyUp(event) {
    const { keyCode } = event;

    switch (keyCode) {
      case this.KEYS.TAB:
      case this.KEYS.ENTER:
      case this.KEYS.BACKSPACE:
        break;
      default:
        this.inputRef.nativeElement.value = this.formatter(this.inputRef.nativeElement.value, keyCode);
        break;
    }
  }

  handleDelimiter(event) {
    const { value } = this.inputRef.nativeElement;
    if (value.length > 0) {
      event.preventDefault();
      this.addTag(value);
      this.handleValueChange.emit();
    }
  }

  addTag(value) {
    if (this.canAdd) {
      this.values.push(value);
      this.handleValueChange.emit();
      this.resetInput();
    }
  }

  resetInput() {
    this.inputRef.nativeElement.value = '';
    this.handleValueChange.emit();
  }

  handleBackspace(event) {
    const value = this.inputRef.nativeElement.value;
    if (value.length === 0) {
      event.preventDefault();
      if (this.canRemove) {
        this.values.pop();
        this.handleValueChange.emit();
      }
    }
  }

  onCloseClick(evt, i) {
    this.values.splice(i, 1);
    this.handleValueChange.emit();
  }

  onWrapperClick(evt) {
    if (evt && evt.target && evt.target.className === 'tags-wrapper') {
      this.inputRef.nativeElement.focus();
    }
  }
  addValue() {
    this.inputRef.nativeElement.value = this.formatter(this.inputRef.nativeElement.value, '');
    const { value } = this.inputRef.nativeElement;
    if (value.length > 0) {
      this.addTag(value);
      this.handleValueChange.emit();
    }
  }
}
