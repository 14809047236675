import { Component, Input, OnInit } from '@angular/core';
import { Company, TECHNICAL_MANAGER_TYPES_LABEL, TechnicalManager } from '@api/model/construction';

@Component({
  selector: 'app-construction-responsible-field',
  templateUrl: './construction-responsible-field.component.html',
  styleUrls: ['./construction-responsible-field.component.scss']
})
export class ConstructionResponsibleFieldComponent implements OnInit {
  @Input()
  technicalManager: TechnicalManager;
  @Input()
  company: Company;

  title: string;

  TECHNICAL_MANAGER_TYPES_LABEL = TECHNICAL_MANAGER_TYPES_LABEL;

  ngOnInit(): void {
    if (this.technicalManager?.name && this.company?.name) {
      this.title = this.technicalManager?.name + ' - ' + this.company?.name;
    } else if (!this.technicalManager?.name && this.company?.name) {
      this.title = this.company.name;
    } else if (this.technicalManager?.name && !this.company?.name) {
      this.title = this.technicalManager.name;
    }
  }
}
