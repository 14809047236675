import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { Residence } from '@api/model/interface/residence';
import { EcondosQuery } from '@api/model/query';
import { SmartLocker, SMART_LOCKER_TYPE, SMART_LOCKER_TYPE_LABEL } from '@api/model/smart-locker';
import { Status } from '@api/model/status';
import { User } from '@api/model/user';
import { SmartLockerGetResidentsReturnBody, SmartLockerService, SmartLockerUser } from '@api/serviceV3/smart-locker/smart-locker.service';
import { UtilService } from 'app/services/util.service';
import { capitalize } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, merge, noop, of } from 'rxjs';
import { catchError, retry, takeUntil, tap, timeout, timeoutWith } from 'rxjs/operators';
import swal from 'sweetalert2';

type CondoResident = Omit<SmartLockerUser, 'externalId'> & {
  enabled: boolean;
};

@Component({
  selector: 'app-condo-smart-lockers',
  templateUrl: 'condo-smart-lockers.html',
  styleUrls: ['./condo-smart-lockers.scss']
})
export class CondoSmartLockersComponent implements OnInit, OnDestroy {
  user: User;
  condo: Condo;

  smartLockersLoadingStatus = new Status();
  smartLockers: SmartLocker[] = [];
  selectedSmartLocker = new FormControl<SmartLocker>(null);
  selectedSmartLockerLabel = null;
  SMART_LOCKER_TYPE = SMART_LOCKER_TYPE;
  SMART_LOCKER_TYPE_LABEL = SMART_LOCKER_TYPE_LABEL;

  residentsLoadingStatus = new Status();
  smartLockerResidents: SmartLockerUser[] = [];
  condoResidents: CondoResident[] = [];

  userBeingAddedToSmartLocker: CondoResident = null;
  userBeingRemovedFromSmartLocker: SmartLockerUser = null;

  hasInvalidCondoResidents = false;
  smartLockerRequiredData: Record<SMART_LOCKER_TYPE, string> = {
    CLIQUE_RETIRE: 'Nome, E-mail, Telefone e Unidade',
    HANDOVER: 'Nome, E-mail e Unidade',
    LOCKIN: ''
  };

  private unsubscribe$ = new Subject();

  constructor(private utilService: UtilService, private smartLockerService: SmartLockerService, private toastr: ToastrService) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.user.defaultCondo;

    this.selectedSmartLocker.valueChanges.subscribe(value => {
      this.unsubscribe$.next(null);
      this.selectedSmartLockerLabel = SMART_LOCKER_TYPE_LABEL[this.selectedSmartLocker.value.type];
      this.getResidents();
    });
  }

  ngOnInit() {
    this.getSmartLockers();
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.next(null);
      this.unsubscribe$.complete();
    }
  }

  getSmartLockers() {
    this.smartLockersLoadingStatus.setAsDownloading();

    const query: EcondosQuery = {
      $select: 'condo name type externalId',
      type: {
        $in: []
      }
    };

    if (this.condo.isCliqueRetireEnabled()) {
      query.type.$in.push(SMART_LOCKER_TYPE.CLIQUE_RETIRE);
    }

    if (this.condo.isHandoverEnabled()) {
      query.type.$in.push(SMART_LOCKER_TYPE.HANDOVER);
    }

    this.smartLockerService.getSmartLockers(this.condo._id, query).subscribe({
      next: response => {
        this.smartLockers = response.smartLockers;
        this.smartLockersLoadingStatus.setAsSuccess();
      },
      error: error => {
        this.smartLockersLoadingStatus.setAsError();
        this.toastr.error('Ocorreu um erro ao tentar buscar os armários inteligentes cadastrados. Tente novamente mais tarde.');
      }
    });
  }

  getResidents() {
    this.residentsLoadingStatus.setAsDownloading();

    const successCallback = (response: SmartLockerGetResidentsReturnBody) => {
      this.smartLockerResidents = response.smartLockerResidents;
      this.condoResidents = [];

      for (const condoResident of response.condoResidents) {
        const condoResidentResidences = condoResident.getResidences();

        if (!condoResidentResidences.length) {
          this.addResidentToCondoResidentsArray(condoResident);
          continue;
        }

        for (const residence of condoResidentResidences) {
          this.addResidentToCondoResidentsArray(condoResident, residence);
        }
      }

      this.sortCondoResidentsArray();
      this.sortSmartLockerResidentsArray();
      this.checkIfThereAreAnyInvalidCondoResidents();

      this.residentsLoadingStatus.setAsSuccess();
    };

    const errorCallback = error => {
      this.residentsLoadingStatus.setAsError();

      this.showErrorMessage(error, 'Ocorreu um erro ao tentar buscar os cadastros. Tente novamente mais tarde.');
    };

    this.smartLockerService
      .getResidents(this.condo._id, this.selectedSmartLocker.value)
      .pipe(takeUntil(this.unsubscribe$), timeoutWith(20_000, ErrorBuilder.throwTimeoutError()))
      .subscribe({ next: successCallback, error: errorCallback });
  }

  addResidentToCondoResidentsArray(condoResident: User, residence?: Partial<Residence>) {
    let isResidentAlreadyAtSmartLocker = false;

    if (residence) {
      isResidentAlreadyAtSmartLocker = this.smartLockerResidents.some(
        smartLockerUser => smartLockerUser.externalId === condoResident._id && smartLockerUser.residence.externalId === residence?._id
      );
    }

    if (!isResidentAlreadyAtSmartLocker) {
      const condoUserPhone = condoResident.phones.find(phone => !!phone);

      const resident: Partial<CondoResident> = {
        id: condoResident._id,
        name: `${condoResident.firstName} ${condoResident.lastName}`,
        email: condoResident.email,
        phone: condoUserPhone,
        pcd: condoResident.specialNeeds,
        enabled: false
      };

      if (residence) {
        resident.residence = {
          id: residence.id,
          externalId: residence.id,
          name: residence.number,
          block: {
            name: residence.address || residence.block
          }
        };

        switch (this.selectedSmartLocker.value.type) {
          case SMART_LOCKER_TYPE.CLIQUE_RETIRE:
            resident.enabled = !!resident.name && !!resident.email && !!resident.phone;
            break;

          case SMART_LOCKER_TYPE.HANDOVER:
            resident.enabled = !!resident.name && !!resident.email;
            break;
        }
      }

      this.condoResidents.push(resident as CondoResident);
    }
  }

  sortSmartLockerResidentsArray() {
    this.smartLockerResidents.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  sortCondoResidentsArray() {
    this.condoResidents.sort((a, b) => {
      const bHasResidenceAndADoesnt = !a.residence && b.residence;
      const bHasNameAndADoesnt = !a.name && b.name;
      const bHasEmailAndADoesnt = !a.email && b.email;
      const bHasPhoneAndADoesnt = !a.phone && b.phone;

      if (bHasResidenceAndADoesnt || bHasNameAndADoesnt || bHasEmailAndADoesnt || bHasPhoneAndADoesnt) {
        return 1;
      }

      const aHasResidenceAndBDoesnt = a.residence && !b.residence;
      const aHasNameAndBDoesnt = a.name && !b.name;
      const aHasEmailAndBDoesnt = a.email && !b.email;
      const aHasPhoneAndBDoesnt = a.phone && !b.phone;

      if (aHasResidenceAndBDoesnt || aHasNameAndBDoesnt || aHasEmailAndBDoesnt || aHasPhoneAndBDoesnt) {
        return -1;
      }

      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase

      return nameA.localeCompare(nameB);
    });
  }

  checkIfThereAreAnyInvalidCondoResidents() {
    this.hasInvalidCondoResidents = this.condoResidents.some(resident => !resident.enabled);
  }

  createSmartLockerResident(smartLockerResident: SmartLockerUser, condoResident: CondoResident) {
    const isNewSmartLockerResidentAlreadyInTheArray = this.smartLockerResidents.some(
      resident => resident.id === smartLockerResident.id && resident.residence.id === smartLockerResident.residence.id
    );

    if (!isNewSmartLockerResidentAlreadyInTheArray) {
      this.smartLockerResidents.push(smartLockerResident);
    }

    this.condoResidents = this.condoResidents.filter(
      resident => !(resident.id === condoResident.id && resident.residence.externalId === condoResident.residence.externalId)
    );

    this.sortSmartLockerResidentsArray();
  }

  handleSelectSmartLocker(smartLocker: SmartLocker) {
    if (this.selectedSmartLocker.value?._id !== smartLocker._id) {
      this.selectedSmartLocker.setValue(smartLocker);
    }
  }

  handleCreateResidentAtSmartLocker(resident: CondoResident) {
    const missingRequiredData: string[] = [];

    if (!resident.residence) {
      missingRequiredData.push('Unidade');
    }

    if (!resident.name) {
      missingRequiredData.push('Nome');
    }

    if (!resident.email) {
      missingRequiredData.push('E-mail');
    }

    if (!resident.phone && this.selectedSmartLocker.value.type === SMART_LOCKER_TYPE.CLIQUE_RETIRE) {
      missingRequiredData.push('Telefone');
    }

    if (missingRequiredData.length) {
      const htmlText = missingRequiredData.reduce((acc, cur) => (acc += `- ${cur}<br>`), '');

      swal({
        type: 'error',
        titleText: 'Cadastro incompleto',
        html: `O cadastro de "${resident.name}" está incompleto para cadastrá-lo(a) no(a) ${this.selectedSmartLockerLabel}.<br><br>Dados faltantes:<br>${htmlText}`
      });

      return;
    }

    this.userBeingAddedToSmartLocker = resident;

    this.smartLockerService
      .createResident(this.condo._id, this.selectedSmartLocker.value, resident.residence.id, resident)
      .pipe(timeoutWith(25_000, ErrorBuilder.throwTimeoutError()))
      .subscribe({
        next: createdSmartLockerUser => {
          this.createSmartLockerResident(createdSmartLockerUser, resident);

          this.toastr.success('Adicionado com sucesso!');
          this.userBeingAddedToSmartLocker = null;
        },
        error: error => {
          console.log(error);
          this.showErrorMessage(
            error,
            `Ocorreu um erro ao tentar cadastrar o ${this.condo?.customLabels?.resident?.singular || 'usuário'} "${resident.name}" no(a) ${
              this.selectedSmartLockerLabel
            }. Tente novamente mais tarde.`
          );

          this.userBeingAddedToSmartLocker = null;
        }
      });
  }

  removeSmartLockerResident(smartLockerResident: SmartLockerUser, condoUser: User) {
    this.smartLockerResidents = this.smartLockerResidents.filter(
      resident =>
        !(
          resident.externalId === smartLockerResident.externalId &&
          resident.residence.externalId === smartLockerResident.residence.externalId
        )
    );

    if (smartLockerResident.externalId) {
      this.condoResidents = this.condoResidents.filter(
        condoResident =>
          !(
            condoResident.id === smartLockerResident.externalId &&
            condoResident.residence.externalId === smartLockerResident.residence.externalId
          )
      );

      const condoUserResidences = condoUser.getResidences();

      const condoUserResidence = condoUserResidences.find(residence => residence._id === smartLockerResident.residence.externalId);

      this.addResidentToCondoResidentsArray(condoUser, condoUserResidence);
      this.sortCondoResidentsArray();
      this.checkIfThereAreAnyInvalidCondoResidents();
    }
  }

  handleRemoveResidentFromSmartLocker(resident: SmartLockerUser) {
    this.userBeingRemovedFromSmartLocker = resident;

    this.smartLockerService
      .deleteResident(this.condo._id, this.selectedSmartLocker.value, resident.id)
      .pipe(timeoutWith(25_000, ErrorBuilder.throwTimeoutError()))
      .subscribe({
        next: user => {
          this.removeSmartLockerResident(resident, user);

          this.toastr.success('Removido com sucesso');
          this.userBeingRemovedFromSmartLocker = null;
        },
        error: error => {
          this.showErrorMessage(
            error,
            `Ocorreu um erro ao tentar remover o ${this.condo?.customLabels?.residence?.singular || 'usuário'} "${resident.name}" do(a) ${
              this.selectedSmartLockerLabel
            }. Tente novamente mais tarde.`
          );

          this.userBeingRemovedFromSmartLocker = null;
        }
      });
  }

  async handleCreateAllValidCondoResidentsAtSmartLocker() {
    const confirmCreateAllResidents = await swal({
      type: 'question',
      titleText: 'Deseja continuar?',
      text: `Somente ${
        this.condo?.customLabels?.resident?.plural || 'usuários'
      } com o cadastro válido serão adicionados aos armários do(a) ${
        this.selectedSmartLockerLabel
      }. Os demais serão ignorados. Deseja continuar?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).catch(swal.noop);

    if (!confirmCreateAllResidents) {
      return;
    }

    const residentsToCreateAtSmartLockerServices = this.condoResidents.filter(resident => resident.enabled);

    const requests = residentsToCreateAtSmartLockerServices.map(resident =>
      this.smartLockerService
        .createResident(this.condo._id, this.selectedSmartLocker.value, resident.residence.id, resident)
        .pipe(tap(response => this.createSmartLockerResident(response, resident)))
    );

    this.processCreateCondoResidentsAtSmartLockerRequests(requests, 'create');
  }

  async handleRemoveAllCondoResidentsAtSmartLocker() {
    const confirmRemoveAllResidents = await swal({
      type: 'question',
      titleText: 'Deseja continuar?',
      text: `Todos os(as) ${this.condo?.customLabels?.resident?.plural || 'usuários'} serão removidos dos armários do(a) ${
        this.selectedSmartLockerLabel
      }. Deseja continuar?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).catch(swal.noop);

    if (!confirmRemoveAllResidents) {
      return;
    }

    const requests = this.smartLockerResidents.map(resident =>
      this.smartLockerService
        .deleteResident(this.condo._id, this.selectedSmartLocker.value, resident.id)
        .pipe(tap(user => this.removeSmartLockerResident(resident, user)))
    );

    this.processCreateCondoResidentsAtSmartLockerRequests(requests, 'remove');
  }

  processCreateCondoResidentsAtSmartLockerRequests(requests, action: 'create' | 'remove') {
    const requestsErrors = [];

    requests = requests.map(request =>
      request.pipe(
        retry(2),
        timeout(60_000),
        catchError(error => {
          requestsErrors.push(request);
          return of();
        })
      )
    );

    swal({
      type: 'info',
      title: `${action === 'create' ? 'Cadastrando' : 'Removendo'} ${
        this.condo?.customLabels?.resident?.plural || 'usuários'
      } dos armários do(a) ${this.selectedSmartLockerLabel}`,
      text: `0 de ${requests.length} ${action === 'create' ? 'cadastrados(as)' : 'removidos(as)'}`,
      allowEscapeKey: false,
      allowOutsideClick: false
    });

    swal.showLoading();

    let counter = 0;

    const nextCallback = () => {
      counter += 1;

      if (!requestsErrors.length && counter === requests.length) {
        swal({
          type: 'success',
          title: `${capitalize(this.condo?.customLabels?.resident?.plural || 'usuários')} ${
            action === 'create' ? 'cadastrados(as)' : 'removidos(as)'
          } com sucesso`
        });
      } else {
        swal.getContent().textContent = `${counter} de ${requests.length} ${action === 'create' ? 'cadastrados(as)' : 'removidos(as)'}`;
      }
    };

    const completeCallback = async () => {
      if (requestsErrors.length) {
        const result = await swal({
          text: `Houveram ${requestsErrors.length} erros na operação`,
          type: 'info',
          showCancelButton: true,
          confirmButtonText: 'Tentar novamente',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        });

        if (result === true) {
          this.processCreateCondoResidentsAtSmartLockerRequests(requestsErrors, action);
        }
      }
    };

    merge(...requests, 2).subscribe({
      next: nextCallback,
      error: noop,
      complete: completeCallback
    });
  }

  showErrorMessage(error, defaultErrorMessage: string) {
    const { message, smartLockerMessage } = error?.originalError || {};

    const errorMessage = message || defaultErrorMessage;

    let html = `<p>${errorMessage}</p>`;

    if (smartLockerMessage) {
      html += `<p><small><strong>Mensagem de erro no(a) ${this.selectedSmartLockerLabel}: </strong><pre>${smartLockerMessage}</pre></small></p>`;
    }

    swal({
      type: 'error',
      titleText: 'Ocorreu um erro',
      html
    });
  }
}
