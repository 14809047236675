import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-share-with-whatsapp-button',
  templateUrl: 'share-with-whatsapp-button.component.html',
  styleUrls: ['share-with-whatsapp-button.component.scss'],
  standalone: true,
  imports: [TooltipModule]
})
export class ShareWithWhatsappButtonComponent {
  @Input() phone: string;
  @Input() title: string;

  constructor(private toastrService: ToastrService) {}

  startWhatsAppConversation(phone: string) {
    const whatsAppLink = `https://wa.me/55${phone}`;
    try {
      window.open(whatsAppLink);
    } catch (e) {
      this.toastrService.error('Não foi possível iniciar a conversa. Tente novamente');
    }
  }
}
