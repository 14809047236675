import { BuildChatRoom, ChatRoom } from './chat-room';
import { Condo } from './condo';
import { User } from './user';

export interface ChatMessage {
  _id?: string;
  condo?: Partial<Condo>;
  chatRoom?: Partial<ChatRoom>;
  createdBy?: Partial<User>;
  messageBody?: string;
  replyTo?: Partial<ChatMessage>;
  readBy?: Partial<User>[];
  createdAt?: string;
  updatedAt?: string;
}

export const BuildChatMessage = (message): ChatMessage => {
  if (typeof message == 'string') {
    return {
      _id: message
    };
  } else if (message) {
    const chatMessage: ChatMessage = {
      _id: message._id || '',
      condo: message.condo ? new Condo(message.condo) : null,
      chatRoom: message.chatRoom ? BuildChatRoom(message.chatRoom) : null,
      createdBy: message.createdBy ? new User(message.createdBy) : null,
      messageBody: message.messageBody || '',
      replyTo: message.replyTo ? BuildChatMessage(message.replyTo) : null,
      readBy: [],
      createdAt: message.createdAt || null,
      updatedAt: message.updatedAt || null
    };

    if (message.readBy?.length) {
      chatMessage.readBy = message.readBy.map(user => new User(user));
    }

    return chatMessage;
  }
};
