/**
 * Created by Rafael on 26/02/2017.
 */
import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { File } from '../../api/model/file';
import { ContactID } from '../../api/model/contact/contact.id';
import { ModalWebCam } from '../../pages/modal/webcam/webcam.modal';
import { OccurrenceFileUploader } from '../../pages/occurrence.creator/occurrence.creator.file.list/files.scroll';

@Component({
  selector: 'create-id-component',
  templateUrl: 'create.id.html'
})
export class CreateIdComponent {
  @ViewChild(ModalWebCam, { static: true }) public webcamModal: ModalWebCam;
  @ViewChild(OccurrenceFileUploader) public filesScroll: OccurrenceFileUploader;
  @Input() addImage = true;
  @Input() ID_TYPES = Object.keys(ContactID.TYPES);
  @Output() onIdCreate = new EventEmitter();
  @Output() onIdEdit = new EventEmitter();
  @Output() onIdCreationCancel = new EventEmitter();

  public pictures: Array<File> = [];
  public idForm: UntypedFormGroup;
  public type: AbstractControl;
  public number: AbstractControl;
  public ID_LABELS = ContactID.TYPES_LABEL;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.idForm = this.formBuilder.group({
      type: [ContactID.TYPES.RG, Validators.compose([Validators.required])],
      number: ['', Validators.compose([Validators.required])]
    });

    this.type = this.idForm.controls['type'];
    this.number = this.idForm.controls['number'];
  }

  showCam() {
    this.webcamModal.showModal();
  }

  addFromFile() {
    this.filesScroll.pickFile();
  }

  cancelCreation() {
    this.onIdCreationCancel.emit();
  }

  createId() {
    if (this.idForm.invalid) {
      this.touchFields();
      return;
    }
    const id = new ContactID();
    id.type = this.type.value;
    id.number = this.number.value;
    id.pictures = this.pictures;
    this.onIdCreate.emit(id);
  }

  touchFields() {
    this.type.markAsTouched();
    this.number.markAsTouched();
  }
}
