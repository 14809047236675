import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IntercomPanelComponent } from './intercom-panel.component';

@NgModule({
  declarations: [IntercomPanelComponent],
  imports: [CommonModule, DragDropModule],
  exports: [IntercomPanelComponent]
})
export class IntercomPanelModule {}
