import { NgModule } from '@angular/core';
import { DateInputDirective } from './date-input.directive';
import { LogViewedDataDirective } from './log-viewed-data.directive';
import { DatetimeLocalInputISOstringDirective } from './datetime-local-input-isostring.directive';
import { PreventDoubleClickDirective } from './prevent-double-click.directive';
import { ImageViewerDirective } from '../components/file-preview/image-viewer.directive';

@NgModule({
  imports: [],
  declarations: [
    DateInputDirective,
    LogViewedDataDirective,
    PreventDoubleClickDirective,
    DatetimeLocalInputISOstringDirective,
    ImageViewerDirective
  ],
  exports: [
    DateInputDirective,
    LogViewedDataDirective,
    DatetimeLocalInputISOstringDirective,
    PreventDoubleClickDirective,
    ImageViewerDirective
  ]
})
export class DirectivesModule {}
