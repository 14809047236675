import { PipesModule } from './../../../../pipe/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalCondoCreateKey } from './modal.create.key';
import { ThemeModule } from '../../../../theme';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ModalModule, PipesModule, TooltipModule, ThemeModule],
  declarations: [ModalCondoCreateKey]
})
export class ModalCreateKeyModule {}
