import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import { FileElement } from './file-element';
import { catchError, ignoreElements, shareReplay, timeout } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { LogViewedDataDirective } from '../../directives/log-viewed-data.directive';
import { Condo } from '@api/model/condo';

@Component({
  selector: 'app-file-explorer',
  templateUrl: 'file-explorer.component.html',
  styleUrls: ['file-explorer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileExplorerComponent implements OnDestroy {
  @ViewChildren(LogViewedDataDirective) logViewedDataDirectives: QueryList<LogViewedDataDirective>;

  unsubscribe$ = new Subject();
  _fileElements: Observable<FileElement[]>;
  fileElementsError$;
  @Input() canNavigateUp: boolean;
  @Input() breadcrumb: FileElement[] = [];
  @Input() canAddFile: boolean;
  @Input() canAddFolder: boolean;
  @Input() canEdit: boolean;
  @Input() canRemove: boolean;
  @Input() trigger: string = 'onViewed';

  @Output() addFile = new EventEmitter();
  @Output() addFolder = new EventEmitter();
  @Output() showInfo = new EventEmitter();
  @Output() showViews = new EventEmitter();
  @Output() dropFiles = new EventEmitter<FileList>();
  @Output() folderAdded = new EventEmitter<{ name: string }>();
  @Output() elementRemoved = new EventEmitter<FileElement>();
  @Output() elementRenamed = new EventEmitter<FileElement>();
  @Output() elementEdit = new EventEmitter<FileElement>();
  @Output() elementMoved = new EventEmitter<{
    element: FileElement;
    moveTo: FileElement;
  }>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() navigatedUp = new EventEmitter();
  @Output() navigatedTo = new EventEmitter<FileElement | undefined>();

  @Input()
  set fileElements(fileElements: Observable<FileElement[]>) {
    this._fileElements = fileElements?.pipe(timeout(10000), shareReplay(1));
    this.fileElementsError$ = this._fileElements.pipe(
      ignoreElements(),
      catchError(err => of(err))
    );
  }

  constructor() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  retry() {
    this.fileElements = this._fileElements;
  }

  navigate(element: FileElement) {
    if (element.isFolder) {
      this.navigatedDown.emit(element);
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', element.url);
      link.click();
    }

    this.activeManualViewed(element);
  }

  isFile(fileElement: FileElement): boolean {
    return !fileElement.isFolder;
  }

  isFolder(fileElement: FileElement): boolean {
    return fileElement.isFolder;
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  moveElement(element: FileElement, moveTo: FileElement) {
    this.elementMoved.emit({ element: element, moveTo: moveTo });
  }

  moveToParent(element: FileElement) {
    const parent = this.breadcrumb[this.breadcrumb.length - 2];
    this.moveElement(element, parent);
  }

  checkVisibility(element) {
    const checkFunction = (value) =>  element.isFolder && element.id !== value.id;
    return checkFunction;
  }

  activeManualViewed(element: FileElement) {
    const directive = this.logViewedDataDirectives.find(dir => dir.documentId === element.id);
    directive?.manualActivate();
  }
}
