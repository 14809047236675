import { Injectable } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import { defer, from, Observable, of } from 'rxjs';
import { Lpr } from '@econdos/econdos-intelbras';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IntelbrasLprService {
  private intelbrasLprDevices = {};

  constructor() {}

  clearDeviceCache(actuatorId?: string) {
    if (actuatorId) {
      delete this.intelbrasLprDevices[actuatorId];
    } else {
      this.intelbrasLprDevices = {};
    }
  }

  public getDevices(actuators: Actuator | Actuator[]) {
    if (!Array.isArray(actuators)) {
      actuators = [actuators];
    }
    return actuators.map(a => this.getDevice(a));
  }

  public getDevice(actuator: Actuator) {
    if (!this.intelbrasLprDevices[actuator._id]) {
      this.intelbrasLprDevices[actuator._id] = new Lpr(actuator);
    }
    return this.intelbrasLprDevices[actuator._id];
  }

  addPlate(plate: string, lprActuator: Actuator) {
    const lpr = this.getDevice(lprActuator);

    const PlateNumber = (plate || '').toUpperCase();
    return defer(() => from(lpr.addPlateOnWhitelist({ PlateNumber })));
  }

  deletePlate(plate: string, lprActuator: Actuator) {
    const lpr = this.getDevice(lprActuator);

    const PlateNumber = (plate || '').toUpperCase();
    return defer(() => from(lpr.deletePlateOnWhitelist({ PlateNumber })));
  }

  trigger(lprActuator: Actuator): Observable<any> {
    const lpr = this.getDevice(lprActuator);
    return defer(() => from(lpr.openDoor({ channel: lprActuator.output })));
  }

  checkConnection(lprActuator: Actuator): Observable<{ _id: string; status: boolean }> {
    const lpr = this.getDevice(lprActuator);
    return from(lpr.getDateTime()).pipe(
      map(() => ({ _id: lprActuator._id, status: true })),
      catchError(() => of({ _id: lprActuator._id, status: false }))
    );
  }
}
