/**
 * Created by Rafael on 17/12/2018.
 */
import { Component, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { UtilService } from '../../../services/util.service';
import { ReservationService } from '@api/service/reservation.service';
import { Condo } from '@api/model/condo';
import { ActivatedRoute, Router } from '@angular/router';
import { ParamsService } from '@api/service/params.service';
import { Reservation } from '@api/model/reservation';
import { Status } from '@api/model/status';
import { CalendarMonthViewDay } from 'angular-calendar';
import { ModalCreateReservationComponent } from '../modal/create.reservation.modal';
import { Local } from '@api/model/local';
import { ToastrService } from 'ngx-toastr';
import { User } from '@api/model/user';
import { EcondosQuery } from '@api/model/query';
import { switchMap } from 'rxjs/operators';
import { LocalGroup } from '@api/model/local-group';

@Component({
  selector: 'app-create-reservation',
  templateUrl: './create.reservation.html',
  styleUrls: ['./create.reservation.scss']
})
export class CreateReservationComponent {
  @ViewChild(ModalCreateReservationComponent, { static: true }) createReservationModal: ModalCreateReservationComponent;

  reservationsStatus: Status = new Status();
  reservationLocal: any;
  reservationLocals: Local[] = [];
  reservations: Array<Reservation> = [];
  reservationsByDate = {};
  reservationLocalGroup: LocalGroup[] = [];
  condo: Condo;
  user: User;

  // Calendar
  minDate: Date;
  maxDate: Date;
  prevBtnDisabled = true;
  nextBtnDisabled = false;

  viewDate: Date = new Date();

  constructor(
    private utilService: UtilService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private paramsService: ParamsService,
    private reservationService: ReservationService
  ) {
    const localId = this.route.snapshot.params['localId'];
    this.reservationLocal = this.paramsService.getAndClear(localId);
    this.reservationLocals = this.paramsService.getAndClear('reservationLocals');

    if (this.reservationLocal) {
      this.condo = this.utilService.getLocalCondo();
      this.user = this.utilService.getLocalUser();
      this.getData();
      this.minDate = moment().add(moment.duration(this.reservationLocal.params.minTimeToReserve)).toDate();
      this.maxDate = moment().add(moment.duration(this.reservationLocal.params.maxTimeToReserve)).toDate();
      this.viewDate = this.minDate;
    } else {
      this.router.navigate(['reservations']);
    }
  }

  getData() {
    const query: EcondosQuery = {
      reservationLocal: this.reservationLocal._id,
      status: { $in: ['APPROVED', 'PENDING', 'DONE', 'IN_QUEUE'] },
      startDate: { $gte: moment().startOf('day').toISOString() },
      $sort: '-createdAt'
    };
    const groupQuery: EcondosQuery = {
      $select: 'name reservationLocals params',
      $populate: [
        {
          path: 'reservationLocals',
          select: 'capacity createdBy name description penalties extraFees periods value status params rules rulesAttachments',
          populate: [{ path: 'rulesAttachments', select: 'url thumbnail type format name videoThumbnail' }]
        }
      ],
      reservationLocals: this.reservationLocal._id,
      deleted: false
    };

    this.reservationsStatus.setAsDownloading();

    this.reservationService
      .getReservationLocalGroups(this.condo._id, groupQuery)
      .pipe(
        switchMap(({ localGroups }) => {
          if (localGroups.length) {
            this.reservationLocalGroup = localGroups;
            this.reservationLocals = this.reservationLocalGroup.reduce((acc, curr) => {
              acc = [...acc, ...curr.reservationLocals];
              return acc;
            }, []);
            const localIds = this.reservationLocals.map(local => local._id);
            query.reservationLocal = { $in: localIds };
            this.reservationLocals = this.reservationLocals.filter(local => local.status === 'AVAILABLE');
          }
          return this.reservationService.getReservations(this.condo._id, query);
        })
      )
      .subscribe({
        next: ({ reservations }) => {
          this.reservations = reservations;
          this.reservationsByDate = this.reservations.reduce((acc, curr) => {
            const date = moment(curr.startDate).format('DD/MM');
            acc[date] ? acc[date].push(curr) : (acc[date] = [curr]);
            return acc;
          }, {});
          this.reservationsStatus.setAsSuccess();
        },
        error: () => {
          this.reservationsStatus.setAsError();
        }
      });
  }

  increment(): void {
    this.changeDate(moment(this.viewDate).add(1, 'month').toDate());
  }

  decrement(): void {
    this.changeDate(moment(this.viewDate).subtract(1, 'month').toDate());
  }

  today(): void {
    this.changeDate(new Date());
  }

  dateIsOnValidRange(date: Date): boolean {
    const momentDate = moment(date);
    return momentDate.isSameOrAfter(moment(this.minDate), 'day') && momentDate.isSameOrBefore(moment(this.maxDate), 'day');
  }

  dateIsBlocked(date: Date): boolean {
    const day = moment(date).format('DD/MM');
    const { blockedDays } = this.reservationLocal.params;
    return (blockedDays || []).findIndex(d => d === day) !== -1;
  }

  dateIsReserved(date: Date): boolean {
    const availablePeriods = this.filterAvailablePeriodsByReservations(date);
    return availablePeriods.length === 0;
  }

  filterAvailablePeriodsByReservations(date) {
    let availablePeriods = [...this.reservationLocal.periods];
    availablePeriods = this.filterPeriodsByWeekDay(availablePeriods, date);
    if (moment(date).isSame(this.minDate, 'day')) {
      availablePeriods = this.filterAvailablePeriodsOnMinDate(availablePeriods);
    }
    const d = moment(date).format('DD/MM');
    if (this.reservationLocalGroup?.length && this.reservationsByDate[d]?.length) {
      availablePeriods = this.filterConflictingPeriods(availablePeriods, this.reservationsByDate[d]);
    } else {
      availablePeriods = availablePeriods.filter(
        p => (this.reservationsByDate[d] || []).findIndex(reservation => reservation.period === p._id) === -1
      );
    }

    return availablePeriods;
  }

  filterReservedPeriodsByReservations(date) {
    let reservedPeriods = [...this.reservationLocal.periods];
    reservedPeriods = this.filterPeriodsByWeekDay(reservedPeriods, date);
    if (moment(date).isSame(this.minDate, 'day')) {
      reservedPeriods = this.filterAvailablePeriodsOnMinDate(reservedPeriods);
    }
    const d = moment(date).format('DD/MM');
    reservedPeriods = reservedPeriods.filter(p => (this.reservationsByDate[d] || []).some(reservation => reservation.period === p._id));
    return reservedPeriods;
  }

  filterConflictingPeriods(
    firstList: {
      startHour: string;
      endHour: string;
      weekDay: number;
    }[],
    secondList: Reservation[]
  ) {
    return firstList.filter(period1 => {
      return !secondList.some(period2 => this.isPeriodConflicted(period1, period2));
    });
  }

  isPeriodConflicted(period1: { startHour: string; endHour: string; weekDay: number }, period2: Reservation): boolean {
    const start1 = period1.startHour;
    const end1 = period1.endHour;
    const start2 = moment.utc(period2.startDate).format('HH:mm');
    const end2 = moment.utc(period2.endDate).format('HH:mm');
    return start1 < end2 && end1 > start2;
  }

  filterAvailablePeriodsOnMinDate(periods) {
    periods = this.filterPeriodsByWeekDay(periods, this.minDate);
    periods = periods.filter(p => {
      const endOfPeriod = p.endHour.split(':');
      const dateToCheck = moment(this.minDate).hour(endOfPeriod[0]).minute(endOfPeriod[1]);
      return dateToCheck.isAfter(this.minDate);
    });
    return periods;
  }

  filterPeriodsByWeekDay(periods, date) {
    const weekDay = moment(date).day();
    periods = periods.filter(p => weekDay === p.weekDay);
    return periods;
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateChanged();
  }

  dateChanged(): void {
    this.prevBtnDisabled = !this.dateIsOnValidRange(moment(this.viewDate).subtract(1, 'month').endOf('month').toDate());
    this.nextBtnDisabled = !this.dateIsOnValidRange(moment(this.viewDate).add(1, 'month').startOf('month').toDate());

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (this.dateIsBlocked(day.date)) {
        day.cssClass = 'cal-blocked';
      } else if (
        !this.dateIsOnValidRange(day.date) ||
        (moment(day.date).isSame(this.minDate, 'day') &&
          this.filterAvailablePeriodsOnMinDate([...this.reservationLocal.periods]).length === 0) ||
        this.filterPeriodsByWeekDay([...this.reservationLocal.periods], day.date).length === 0
      ) {
        day.cssClass = 'cal-disabled';
      } else if (this.dateIsReserved(day.date)) {
        day.cssClass = this.reservationLocal.params.isQueueEnabled ? 'cal-queue' : 'cal-reserved';
      } else {
        day.cssClass = 'cal-enabled';
      }
    });
  }

  onDayClick({ day }) {
    this.showCreateReservationModal(this.reservationLocal, day.date);
  }

  showCreateReservationModal(reservationLocal: Local, date: Date) {
    let availablePeriods = this.filterAvailablePeriodsByReservations(date);
    let reservedPeriods = this.filterReservedPeriodsByReservations(date);
    if (moment(date).isSame(this.minDate, 'day')) {
      availablePeriods = this.filterAvailablePeriodsOnMinDate(availablePeriods);
    }
    availablePeriods = availablePeriods.sort((a, b) => a.startHour.localeCompare(b.startHour) || a.endHour.localeCompare(b.endHour));
    this.reservationLocals = this.reservationLocals?.filter(local => local._id !== reservationLocal._id);

    this.createReservationModal.showModal({
      reservationLocal,
      periods: availablePeriods,
      reservedPeriods,
      reserveDate: date.toISOString(),
      reservationLocals: this.reservationLocals,
      reservationLocalGroup: this.reservationLocalGroup
    });
  }

  onReservationCreated() {
    if (this.user.isGatekeeper()) {
      this.router.navigate(['reservations']);
    } else {
      this.router.navigate(['reservations/reservationHistory']);
    }
    this.toastr.success('Reserva solicitada com sucesso');
  }
}
