import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '../../../../api/model/condo';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CondoService } from '../../../../api/service/condo.service';
import { KeyService } from '../../../../api/service/key.service';
import { Status } from '../../../../api/model/status';
import { ToastrService } from 'ngx-toastr';
import { Key } from '../../../../api/model/key';

@Component({
  selector: 'condo-borrow-keys',
  templateUrl: 'modal.borrow.key.html',
  styleUrls: ['./modal.borrow.key.scss']
})
export class ModalCondoBorrowKey implements OnInit {
  condo: Condo;
  key: Key;
  status: Status = new Status();

  residenceUsers: String[] = [];
  residenceUsersStatus: Status = new Status();

  form: UntypedFormGroup;
  residence: AbstractControl;
  person: AbstractControl;
  obs: AbstractControl;

  onKeyBorrow: Function = () => {};

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private condoService: CondoService,
    private keyService: KeyService,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group({
      residence: [],
      person: ['', Validators.required],
      obs: ['', Validators.minLength(2)]
    });
    this.person = this.form.get('person');
    this.obs = this.form.get('obs');
    this.residence = this.form.get('residence');

    this.residence.valueChanges.subscribe(res => {
      if (res) {
        this.loadResidenceUsers(res);
      } else {
        this.residenceUsers = [];
      }
    });
  }

  loadResidenceUsers(residence) {
    const params = {
      '$or[0][residencesVoter]': [residence._id],
      '$or[1][residencesUser]': [residence._id]
    };
    this.residenceUsersStatus.setAsDownloading();
    this.condoService.getCondoUsers(this.condo._id, params).subscribe(
      users => {
        this.residenceUsers = users.map(u => u.firstName + ' ' + u.lastName);
        this.residenceUsersStatus.setAsSuccess();
      },
      err => {
        console.log(err);
        this.residenceUsers = [];
        this.residenceUsersStatus.setAsError();
      }
    );
  }

  ngOnInit() {}

  selectResidence(residence) {
    this.residence.setValue(residence);
  }

  save(value) {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const data: any = {};
      const fields = Object.keys(value).filter(key => value[key]);
      for (const field of fields) {
        data[field] = value[field];
      }
      if (value.residence) {
        data.residence = value.residence._id;
      }
      this.keyService.borrowKey(this.condo.id, this.key.id, data).subscribe(
        res => {
          this.onKeyBorrow(value);
          this.bsModalRef.hide();
          this.toastr.success('Retirada registrada com sucesso');
        },
        err => {
          console.log(err);
          this.toastr.error('Não foi possível registrar a retirada, tente novamente');
        }
      );
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha a pessoa que está retirando a chave');
    }
  }
}
