/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { ReservationService } from '@api/service/reservation.service';
import { ParamsService } from '@api/service/params.service';

import swal from 'sweetalert2';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Local, LocalExtraFee } from '@api/model/local';
import { EMPTY, from, merge, noop, Observable, Subject } from 'rxjs';
import { Reservation } from '@api/model/reservation';
import { EcondosQuery } from '@api/model/query';
import { ReservationServiceV2 } from '@api/serviceV2/reservation.service';
import { conditionalValidator } from '@api/util/validators';

interface Penalty {
  time: number;
  value: string;
}

interface Period {
  startHour: string;
  endHour: string;
  weekDay: number;
}

interface ReservationLocal {
  name: FormControl<string>;
  rules: FormControl<string>;
  description: FormControl<string>;
  value: FormControl<string>;
  capacity?: FormControl<number>;
  penalties?: FormControl<Penalty[]>;
  penaltyWarningText?: FormControl<string>;
  pictures?: FormControl<File[]>;
  rulesAttachments?: FormControl<File[]>;
  checklist?: FormControl<string[]>;
  periods?: FormControl<Period[]>;

  defaulterCannotReserve?: FormControl<boolean>;
  residentCanReserve?: FormControl<boolean>;
  blockedDays?: FormControl<string[]>;
  needApproval?: FormControl<boolean>;
  createAccessLiberationLink?: FormControl<boolean>;
  minTimeToReserve?: FormControl<number>;
  maxTimeToReserve?: FormControl<number>;
  useCondoReservationQuota?: FormControl<boolean>;
  isQueueEnabled?: FormControl<boolean>;
  residenceReservationQueueLimit?: FormControl<number>;
  maxActiveReservations?: FormControl<number>;
  activeReservationPeriod?: FormControl<number>;

  extraFees: FormArray;
}

@Component({
  selector: 'app-create-reservation-local',
  templateUrl: 'create.local.html'
})
export class CreateLocalPageComponent implements OnInit, OnDestroy {
  private takeUntilDestroyed$: Subject<boolean> = new Subject();

  isCreatingNewLocal;

  reservationLocal: Local;

  localForm: FormGroup<ReservationLocal>;
  name: FormControl<string>;
  rules: FormControl<string>;
  description: FormControl<string>;
  penaltyWarningText?: FormControl<string>;
  value: FormControl<string>;
  capacity?: FormControl<number>;
  penalties?: FormControl<Penalty[]>;

  defaulterCannotReserve?: FormControl<boolean>;
  residentCanReserve?: FormControl<boolean>;
  needApproval?: FormControl<boolean>;
  minTimeToReserve?: FormControl<number>;
  maxTimeToReserve?: FormControl<number>;
  useCondoReservationQuota?: FormControl<boolean>;
  createAccessLiberationLink?: FormControl<boolean>;
  isQueueEnabled?: ReservationLocal['isQueueEnabled'];
  residenceReservationQueueLimit?: ReservationLocal['residenceReservationQueueLimit'];
  maxActiveReservations?: FormControl<number>;
  activeReservationPeriod?: FormControl<number>;

  extraFees: FormArray;

  isUploading = false;
  isUploadingRules = false;
  triedToSubmit = false;

  condo;
  attachments = [];
  blockedDays = [];
  checklist = [];
  rulesAttachments = [];
  periods = [];
  periodToAdd = {
    startHour: moment().hour(6).minutes(0).toDate(),
    endHour: moment().hour(22).minutes(0).toDate(),
    weekDay: 0
  };
  endTimeInvalid = false;

  WEEK_DAYS = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

  isSubmiting = false;

  isInGroup = false;

  DEFAULT_PARAMS = {
    defaulterCannotReserve: true,
    residentCanReserve: true,
    blockedDays: [],
    needApproval: true,
    createAccessLiberationLink: false,
    minTimeToReserve: 0,
    maxTimeToReserve: moment.duration(180, 'day').asDays(),
    useCondoReservationQuota: true,
    isQueueEnabled: false,
    residenceReservationQueueLimit: null,
    maxActiveReservations: 1,
    activeReservationPeriod: 0
  };

  dateInputFormatter = (value, keyCode) => {
    let isShift = false;
    if (keyCode == 16) {
      isShift = true;
    }
    // Allow only Numeric Keys.
    if (
      ((keyCode >= 48 && keyCode <= 57) || keyCode == 8 || keyCode <= 37 || keyCode <= 39 || (keyCode >= 96 && keyCode <= 105)) &&
      isShift == false
    ) {
      if (value.length == 2 && keyCode != 8) {
        value += '/';
      }
    }
    value = value.replace(/[^0-9\/]/g, '');
    if (value.length > 5) {
      value = value.substr(0, 5);
    }

    if (value.length === 5) {
      const date = moment(value, 'DD/MM');
      if (!date.isValid()) {
        value = '';
      }
    }
    return value;
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private router: Router,
    private toastr: ToastrService,
    private reservationService: ReservationService,
    private reservationServiceV2: ReservationServiceV2,
    private paramsService: ParamsService,
    private route: ActivatedRoute
  ) {
    const localId = this.route.snapshot.params['localId'];
    this.reservationLocal = this.paramsService.get(localId);

    if (this.reservationLocal?._id) {
      const params = this.reservationLocal.params || {};
      this.isCreatingNewLocal = false;
      this.periods = this.reservationLocal.periods
        ? this.reservationLocal.periods.reduce((acc, curr) => {
            acc[curr.weekDay] = acc[curr.weekDay] || [];
            acc[curr.weekDay].push(curr);
            return acc;
          }, [])
        : [];
      const penalties = (this.reservationLocal.penalties || []).map(p => ({
        time: moment.duration(p.time).asDays(),
        value: p.value
      }));
      this.localForm = formBuilder.group({
        name: new FormControl(this.reservationLocal.name, Validators.compose([Validators.required])),
        rules: new FormControl(this.reservationLocal.rules, Validators.compose([Validators.required])),
        description: new FormControl(this.reservationLocal.description, Validators.compose([Validators.required, Validators.minLength(3)])),
        value: new FormControl(
          this.utilService.formatCurrencyToShow(this.reservationLocal.value.toString()),
          Validators.compose([Validators.required, Validators.minLength(4)])
        ),
        capacity: new FormControl(this.reservationLocal.capacity || '', Validators.compose([Validators.required, Validators.min(1)])),
        penalties: new FormControl(penalties),
        penaltyWarningText: new FormControl(this.reservationLocal.penaltyWarningText || ''),
        defaulterCannotReserve: new FormControl(
          params.defaulterCannotReserve === false ? false : params.defaulterCannotReserve || this.DEFAULT_PARAMS.defaulterCannotReserve,
          Validators.required
        ),
        residentCanReserve: new FormControl(
          params.residentCanReserve === false ? false : params.residentCanReserve || this.DEFAULT_PARAMS.residentCanReserve,
          Validators.required
        ),
        needApproval: new FormControl(
          params.needApproval === false ? false : params.needApproval || this.DEFAULT_PARAMS.needApproval,
          Validators.required
        ),
        createAccessLiberationLink: new FormControl(
          params.createAccessLiberationLink === false
            ? false
            : params.createAccessLiberationLink || this.DEFAULT_PARAMS.createAccessLiberationLink,
          Validators.required
        ),
        minTimeToReserve: new FormControl(
          params.minTimeToReserve || params.minTimeToReserve === 0
            ? moment.duration(params.minTimeToReserve).asDays()
            : this.DEFAULT_PARAMS.minTimeToReserve,
          Validators.required
        ),
        maxTimeToReserve: new FormControl(
          params.maxTimeToReserve || params.maxTimeToReserve === 0
            ? moment.duration(params.maxTimeToReserve).asDays()
            : this.DEFAULT_PARAMS.maxTimeToReserve,
          Validators.required
        ),
        useCondoReservationQuota: new FormControl(
          params.useCondoReservationQuota === false
            ? false
            : params.useCondoReservationQuota || this.DEFAULT_PARAMS.useCondoReservationQuota,
          Validators.required
        ),
        isQueueEnabled: new FormControl(
          params.isQueueEnabled === false ? false : params.isQueueEnabled || this.DEFAULT_PARAMS.isQueueEnabled,
          Validators.required
        ),
        residenceReservationQueueLimit: new FormControl(
          params.isQueueEnabled ? params.residenceReservationQueueLimit : this.DEFAULT_PARAMS.residenceReservationQueueLimit,
          conditionalValidator(() => params.isQueueEnabled, Validators.compose([Validators.required, Validators.min(1)]))
        ),
        maxActiveReservations: new FormControl(
          params.maxActiveReservations === 0 ? 0 : params.maxActiveReservations || this.DEFAULT_PARAMS.maxActiveReservations,
          Validators.required
        ),
        activeReservationPeriod: new FormControl(
          params.activeReservationPeriod || params.activeReservationPeriod === 0
            ? moment.duration(params.activeReservationPeriod).asHours()
            : this.DEFAULT_PARAMS.activeReservationPeriod,
          Validators.required
        ),
        extraFees: new FormArray([])
      });
      this.attachments = this.reservationLocal.pictures;
      this.rulesAttachments = this.reservationLocal.rulesAttachments;
      this.checklist = this.reservationLocal.checklist;
      this.blockedDays = params.blockedDays || this.DEFAULT_PARAMS.blockedDays;
    } else {
      this.isCreatingNewLocal = true;
      this.localForm = formBuilder.group({
        name: new FormControl('', Validators.compose([Validators.required])),
        rules: new FormControl('', Validators.compose([Validators.required])),
        description: new FormControl('', Validators.compose([Validators.required, Validators.minLength(3)])),
        value: new FormControl('', Validators.compose([Validators.required, Validators.minLength(4)])),
        capacity: new FormControl('', Validators.required),
        defaulterCannotReserve: new FormControl(this.DEFAULT_PARAMS.defaulterCannotReserve, Validators.required),
        residentCanReserve: new FormControl(this.DEFAULT_PARAMS.residentCanReserve, Validators.required),
        needApproval: new FormControl(this.DEFAULT_PARAMS.needApproval, Validators.required),
        createAccessLiberationLink: new FormControl(this.DEFAULT_PARAMS.createAccessLiberationLink, Validators.required),
        minTimeToReserve: new FormControl(this.DEFAULT_PARAMS.minTimeToReserve, Validators.required),
        maxTimeToReserve: new FormControl(this.DEFAULT_PARAMS.maxTimeToReserve, Validators.required),
        useCondoReservationQuota: new FormControl(this.DEFAULT_PARAMS.useCondoReservationQuota, Validators.required),
        isQueueEnabled: new FormControl(this.DEFAULT_PARAMS.isQueueEnabled, Validators.required),
        residenceReservationQueueLimit: new FormControl(this.DEFAULT_PARAMS.residenceReservationQueueLimit),
        maxActiveReservations: new FormControl(this.DEFAULT_PARAMS.maxActiveReservations, Validators.required),
        activeReservationPeriod: new FormControl(this.DEFAULT_PARAMS.activeReservationPeriod, Validators.required),
        penalties: new FormArray([]),
        penaltyWarningText: new FormControl(''),
        extraFees: new FormArray([])
      });
    }

    this.name = this.localForm.get('name') as FormControl;
    this.rules = this.localForm.get('rules') as FormControl;
    this.description = this.localForm.get('description') as FormControl;
    this.value = this.localForm.get('value') as FormControl;
    this.capacity = this.localForm.get('capacity') as FormControl;
    this.penalties = this.localForm.get('penalties') as FormControl;
    this.penaltyWarningText = this.localForm.get('penaltyWarningText') as FormControl;
    this.defaulterCannotReserve = this.localForm.get('defaulterCannotReserve') as FormControl;
    this.residentCanReserve = this.localForm.get('residentCanReserve') as FormControl;
    this.needApproval = this.localForm.get('needApproval') as FormControl;
    this.createAccessLiberationLink = this.localForm.get('createAccessLiberationLink') as FormControl;
    this.minTimeToReserve = this.localForm.get('minTimeToReserve') as FormControl;
    this.maxTimeToReserve = this.localForm.get('maxTimeToReserve') as FormControl;
    this.useCondoReservationQuota = this.localForm.get('useCondoReservationQuota') as FormControl;
    this.isQueueEnabled = this.localForm.get('isQueueEnabled') as FormControl;
    this.residenceReservationQueueLimit = this.localForm.get('residenceReservationQueueLimit') as FormControl;
    this.maxActiveReservations = this.localForm.get('maxActiveReservations') as FormControl;
    this.activeReservationPeriod = this.localForm.get('activeReservationPeriod') as FormControl;
    this.extraFees = this.localForm.get('extraFees') as FormArray;

    this.condo = this.utilService.getLocalCondo();

    if (this.reservationLocal?.extraFees?.length) {
      this.reservationLocal.extraFees.forEach(fee => this.addExtraFee(fee));
    }

    this.isQueueEnabled.valueChanges.pipe(takeUntil(this.takeUntilDestroyed$)).subscribe(value => {
      if (value) {
        this.residenceReservationQueueLimit.setValidators(Validators.compose([Validators.required, Validators.min(1)]));
      } else {
        this.residenceReservationQueueLimit.clearValidators();
      }
      this.residenceReservationQueueLimit.updateValueAndValidity();
    });

    this.value.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.takeUntilDestroyed$)).subscribe(v => {
      const formatted = this.utilService.formatCurrency(v);
      this.value.setValue(formatted, { emitEvent: false });
    });
  }

  ngOnInit(): void {
    if (!this.isCreatingNewLocal) {
      const query: EcondosQuery = {
        reservationLocals: this.reservationLocal._id,
        deleted: false
      };
      this.reservationService.getReservationLocalGroups(this.condo._id, query).subscribe({
        next: ({ localGroups }) => {
          if (localGroups.length) {
            this.isInGroup = true;
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.takeUntilDestroyed$.next(true);
    this.takeUntilDestroyed$.complete();
  }

  public addExtraFee(value?: LocalExtraFee): void {
    function currencyValidator(): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const value = (control.value?.toString() || '').replace('.', ',');

        if (value) {
          const regex = new RegExp('^(([\\d]{1,10})(\\,([\\d]{1,2}))?)$');
          return regex.test(value) ? null : { currency: true };
        }

        return null;
      };
    }

    const newExtraFee = new UntypedFormGroup({
      name: new UntypedFormControl(value?.name || '', Validators.required),
      value: new UntypedFormControl(value?.value || '', [Validators.required, currencyValidator()])
    });

    this.extraFees.push(newExtraFee);
  }

  public removeExtraFee(index: number): void {
    this.extraFees.removeAt(index);
  }

  addPeriod() {
    if (!this.endTimeInvalid) {
      const periodToAdd = {
        startHour: moment(this.periodToAdd.startHour).format('HH:mm'),
        endHour: moment(this.periodToAdd.endHour).format('HH:mm'),
        weekDay: this.periodToAdd.weekDay
      };

      this.periods[this.periodToAdd.weekDay] = this.periods[this.periodToAdd.weekDay] || [];

      this.periods[this.periodToAdd.weekDay].push(periodToAdd);

      const diff = moment(this.periodToAdd.endHour).diff(moment(this.periodToAdd.startHour), 'hour');
      if (moment(this.periodToAdd.endHour).hour() + diff >= 23) {
        this.periodToAdd.weekDay = this.periodToAdd.weekDay >= 6 ? 0 : parseInt(<any>this.periodToAdd.weekDay, 10) + 1;
      } else {
        const newInitHour = this.periodToAdd.endHour;
        const endHourIncrement = moment(this.periodToAdd.endHour).diff(moment(this.periodToAdd.startHour), 'hours', true);
        const newEndHour = moment(newInitHour).add(endHourIncrement, 'hours').toDate();
        this.periodToAdd.startHour = newInitHour;

        if (moment(newEndHour).isSameOrBefore(moment().endOf('day').minute(59), 'minute')) {
          this.periodToAdd.endHour = newEndHour;
        } else {
          this.periodToAdd.endHour = moment().hour(23).minute(59).toDate();
        }
      }
    }
  }

  onEndHourChange(endTime: any) {
    const hour = endTime.getHours();
    const minute = endTime.getMinutes();
    this.endTimeInvalid = hour === 0 && minute === 0;
  }

  setEndTime(hour: number, minute: number) {
    this.periodToAdd.endHour = moment().hour(hour).minutes(minute).toDate();
  }

  copyPreviousDayPeriods(index) {
    this.periods[index] = [].concat(
      this.periods[index - 1].map(p => ({
        startHour: p.startHour,
        endHour: p.endHour,
        weekDay: index
      }))
    );
  }

  addPenalty(timeInput, valueInput) {
    const time = timeInput.value;
    const value = valueInput.value;
    if (!time || time == 0 || !value || value == 0 || time < 0 || value < 0) {
      this.toastr.error('Preencha o valor e a quantidade de dias');
    } else {
      if (this.penalties.value.findIndex(p => p.time === time) !== -1) {
        this.toastr.warning('Já existe uma multa para este prazo');
      } else {
        this.penalties.value.push({ time, value });
        timeInput.value = '';
        timeInput.focus();
        valueInput.value = '';
      }
    }
  }

  backToReservateLocal() {
    this.router.navigate(['/reservations']);
  }

  createDataToSave() {
    let blockedDays = this.blockedDays;
    blockedDays = blockedDays
      .filter((item, pos) => blockedDays.indexOf(item) == pos)
      .sort((a, b) => moment(a, 'DD/MM').dayOfYear() - moment(b, 'DD/MM').dayOfYear());

    return {
      condo: this.condo._id,
      name: this.name.value,
      rules: this.rules.value,
      description: this.description.value,
      value: this.value.value
        .replace(/[^.,0-9]/g, '')
        .replace('.', '')
        .replace(',', '.'),
      capacity: this.capacity.value,
      checklist: this.checklist || [],
      status: this.reservationLocal && this.reservationLocal.status ? this.reservationLocal.status : Local.STATUS_AVAILABLE,
      periods: this.periods.reduce((acc, curr) => {
        acc.push(...curr);
        return acc;
      }, []),
      pictures: this.attachments.map(att => att._id),
      rulesAttachments: this.rulesAttachments.map(att => att._id),
      penalties: this.penalties.value.map(p => ({
        value: p.value,
        time: moment.duration(parseFloat(p.time.toString()), 'days').asMilliseconds()
      })),
      penaltyWarningText: this.penaltyWarningText.value,
      extraFees: this.extraFees.value,
      params: {
        minTimeToReserve: moment.duration(this.minTimeToReserve.value, 'day').asMilliseconds(),
        maxTimeToReserve: moment.duration(this.maxTimeToReserve.value, 'day').asMilliseconds(),
        residentCanReserve: this.residentCanReserve.value,
        defaulterCannotReserve: this.defaulterCannotReserve.value,
        blockedDays: blockedDays,
        needApproval: this.needApproval.value,
        createAccessLiberationLink: this.createAccessLiberationLink.value,
        useCondoReservationQuota: this.useCondoReservationQuota.value,
        isQueueEnabled: this.isQueueEnabled.value,
        residenceReservationQueueLimit: this.residenceReservationQueueLimit.value,
        maxActiveReservations: this.maxActiveReservations.value,
        activeReservationPeriod: moment.duration(this.activeReservationPeriod.value, 'hour').asMilliseconds()
      }
    };
  }

  saveOrUpdate() {
    this.triedToSubmit = true;
    if (this.localForm.valid && this.periods.length && this.periods.some(p => p.length)) {
      const localData = this.createDataToSave();
      if (this.reservationLocal) {
        const periods = [];
        this.periods.forEach(p => periods.push(...p));
        const newPeriods = periods.reduce((acc, curr) => {
          acc[curr._id] = curr;
          return acc;
        }, {});
        const removedPeriods = this.reservationLocal.periods.filter(period => !newPeriods[period._id]);
        if (removedPeriods?.length) {
          this.askToCancelAffectedReservations(removedPeriods, localData);
        } else if (this.reservationLocal?.params?.isQueueEnabled && localData.params?.isQueueEnabled === false) {
          this.askToDeleteInQueueReservations(this.reservationLocal, localData);
        } else {
          this.updateLocal(localData);
        }
      } else {
        this.createLocal(localData);
      }
    } else {
      for (const key of Object.keys(this.localForm.controls)) {
        this.localForm.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha todos os campos obrigatórios');
    }
  }

  askToCancelAffectedReservations(removedPeriods: any, localData: Record<string, any>) {
    this.getAllReservations({ period: { $in: removedPeriods.map((period: any) => period._id) } })
      .pipe(
        switchMap(reservations => {
          if (!reservations.length) {
            this.updateLocal(localData);
            return EMPTY;
          }

          return from(
            swal({
              type: 'warning',
              title: 'Existem reservas ativas para os horários alterados',
              text:
                'Se você continuar sem cancelar as reservas ativas, o sistema poderá criar reservas com horários conflitantes!\n' +
                'Deseja cancelar as reservas ativas antes de prosseguir?\n' +
                'Digite "cancelar reservas ativas" para confirmar sua ação.',
              showCancelButton: true,
              input: 'text',
              inputPlaceholder: 'cancelar reservas ativas',
              confirmButtonText: 'Cancelar reservas ativas',
              confirmButtonColor: '#f53d3d',
              cancelButtonClass: 'btn-outline-danger',
              cancelButtonText: 'Continuar',
              reverseButtons: true,
              showLoaderOnConfirm: true,
              preConfirm: async input => {
                input = (input || '').toString().trim().toLowerCase();
                if (!input || input !== 'cancelar reservas ativas') {
                  return Promise.reject(`Digite "cancelar reservas ativas" para confirmar sua ação`);
                }
                return Promise.resolve(reservations);
              }
            })
          ).pipe(
            catchError(() => {
              swal({
                type: 'question',
                text: `Certifique-se que espaço ${localData.name} não possui nenhuma reserva ativa antes de editar. Se houver, o sistema poderá criar reservas com horários conflitantes`,
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#32DB64',
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Voltar',
                reverseButtons: true
              }).then(
                () => {
                  this.updateLocal(localData);
                },
                error => {
                  console.log(error);
                }
              );
              return EMPTY;
            })
          );
        }),
        filter((reservations: Reservation[]) => !!reservations?.length),
        switchMap((reservations: Reservation[]) =>
          this.reservationService
            .bulkCancelReservations(
              this.condo._id,
              this.reservationLocal._id,
              reservations.map(reservation => reservation._id)
            )
            .pipe(tap(() => this.updateLocal(localData)))
        )
      )
      .subscribe(noop);
  }

  createLocal(localdata) {
    this.isSubmiting = true;

    this.reservationService
      .createReservationLocal(this.condo._id, localdata)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          this.backToReservateLocal();
        },
        error: err => {
          this.isSubmiting = false;
          swal({
            type: 'error',
            title: `${err.messageTitle || 'Ops...'} `,
            text: `${err.message || 'Não foi possível criar o local, tente novamente...'}`,
            cancelButtonColor: '#f53d3d'
          });
        }
      });
  }

  updateLocal(localdata) {
    this.isSubmiting = true;
    localdata['_id'] = this.reservationLocal._id;
    this.reservationService
      .updateReservationLocal(this.condo._id, this.reservationLocal._id, localdata)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          this.backToReservateLocal();
        },
        error: err => {
          this.isSubmiting = false;
          swal({
            type: 'error',
            title: `${err.messageTitle || 'Ops...'} `,
            text: `${err.message || 'Não foi possível atualizar este local, tente novamente...'}`,
            cancelButtonColor: '#f53d3d'
          });
        }
      });
  }

  private getAllReservations(query: EcondosQuery = {}): Observable<Reservation[]> {
    return this.reservationServiceV2.getReservationsReport(this.condo._id, query).pipe(
      timeout(15000),
      mergeMap(([finalResult]) => merge(finalResult)),
      map(({ data }) => data as Reservation[])
    );
  }

  private askToDeleteInQueueReservations(reservationLocal: Local, localData: Record<string, any>) {
    this.getAllReservations({
      reservationLocal: reservationLocal._id,
      status: 'IN_QUEUE'
    })
      .pipe(
        switchMap(reservations => {
          if (!reservations.length) {
            this.updateLocal(localData);
            return EMPTY;
          }

          return from(
            swal({
              type: 'warning',
              title: 'Existem reservas na fila de espera para o local',
              text:
                'Se você continuar sem cancelar as reservas na fila de espera, será necessário cancelá-las manualmente!\n' +
                'Deseja remover as reservas em fila de espera antes de prosseguir?\n' +
                'Digite "remover as reservas na fila de espera" para confirmar sua ação.',
              showCancelButton: true,
              input: 'text',
              inputPlaceholder: 'remover as reservas na fila de espera',
              confirmButtonText: 'Remover as reservas na fila',
              confirmButtonColor: '#f53d3d',
              cancelButtonClass: 'btn-outline-danger',
              cancelButtonText: 'Continuar',
              reverseButtons: true,
              showLoaderOnConfirm: true,
              preConfirm: async input => {
                input = (input || '').toString().trim().toLowerCase();
                if (!input || input !== 'remover as reservas na fila de espera') {
                  return Promise.reject(`Digite "remover as reservas em fila de espera" para confirmar sua ação`);
                }
                return Promise.resolve(reservations);
              }
            })
          ).pipe(
            catchError(() => {
              swal({
                type: 'question',
                text: `Certifique-se que espaço ${localData.name} não possui nenhuma reserva na fila de espera antes de editar.`,
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#32DB64',
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Voltar',
                reverseButtons: true
              }).then(
                () => {
                  this.updateLocal(localData);
                },
                error => {
                  console.log(error);
                }
              );
              return EMPTY;
            })
          );
        }),
        filter((reservations: Reservation[]) => !!reservations?.length),
        switchMap((reservations: Reservation[]) =>
          this.reservationService
            .bulkCancelReservations(
              this.condo._id,
              this.reservationLocal._id,
              reservations.map(reservation => reservation._id)
            )
            .pipe(tap(() => this.updateLocal(localData)))
        )
      )
      .subscribe(noop);
  }
}
