/**
 * Created by Rafael on 12/12/2016.
 */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import swal from 'sweetalert2';
import { UtilService } from '../../../services/util.service';
import { OccurrenceService } from '@api/service/occurrence.service';
import { Occurrence } from '@api/model/interface/occurrence';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { File } from '@api/model/file';
import { retry, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CondoService } from '@api/service/condo.service';
import { Status } from '@api/model/status';
import { ModalSignature } from '../../modal/signature/modal-signature';
import {
  ModalPreviewWebcamComponent
} from '../../../components/image-picker/modal-preview-webcam/modal-preview-webcam.component';
import { FileService } from '@api/service/file.service';
import { EcondosQuery } from '@api/model/query';

@Component({
  selector: 'modal-close-delivery',
  templateUrl: 'modal.close.delivery.html',
  styleUrls: ['modal.close.delivery.scss']
})
export class ModalCloseDeliveryComponent {
  @ViewChild('closeDeliveryModal', { static: true }) public closeDeliveryModal: ModalDirective;
  // Ocorrência original fornecida através do @Input
  @Input() public originalOccurrence: GateOccurrence;
  // Evento disparado quando o dado da ocorrência é alterado
  @Output() dataChanged = new EventEmitter();

  // Objeto responsável pelo conteúdo do modal
  public occurrence: GateOccurrence;

  public occurrenceForm: UntypedFormGroup;
  public closureDetails: AbstractControl;
  public receivedBy: AbstractControl;

  public condo;
  public user;
  public isSubmiting = false;

  attachments = [];

  signature;

  residenceUsers: string[] = [];
  usersStatus: Status = new Status();

  constructor(
    private occurrenceService: OccurrenceService,
    private utilService: UtilService,
    private condoService: CondoService,
    private modalService: BsModalService,
    private fileService: FileService,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();

    this.occurrenceForm = formBuilder.group({
      closureDetails: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      receivedBy: ['', Validators.compose([Validators.required, Validators.minLength(3)])]
    });
    this.closureDetails = this.occurrenceForm.get('closureDetails');
    this.receivedBy = this.occurrenceForm.get('receivedBy');
  }

  closeModal(): void {
    this.closeDeliveryModal.hide();
  }

  showModal() {
    this.resetFields();

    this.occurrence = _.cloneDeep(this.originalOccurrence);
    this.loadResidenceUsers();
    this.attachments = this.occurrence.subTypeAttachments ? this.occurrence.subTypeAttachments.map(file => new File(file)) : [];
    this.signature = null;
    this.closeDeliveryModal.show();
  }

  loadResidenceUsers() {
    this.usersStatus.setAsDownloading();
    const residencesId = this.occurrence.viewers.residences.map(r => r._id || r);
    const query: EcondosQuery = {
      $or: [{ residencesVoter: { $in: residencesId } }, { residencesUser: { $in: residencesId } }]
    };
    this.condoService
      .getCondoResidents(this.condo._id, query)
      .pipe(timeout(10000), retry(2))
      .subscribe({
        next: ({ users }) => {
          this.residenceUsers = users.map(u => u.firstName + ' ' + u.lastName);
          this.usersStatus.setAsSuccess();
        },
        error: err => {
          this.residenceUsers = [];
          this.usersStatus.setAsError();
        }
      });
  }

  resetFields() {
    this.closureDetails.markAsUntouched();
    this.closureDetails.markAsPristine();
    this.closureDetails.setValue('A encomenda/objeto foi retirada(o).');
    this.receivedBy.markAsUntouched();
    this.receivedBy.markAsPristine();
    this.receivedBy.setValue('');
    this.residenceUsers = [];
    this.usersStatus = new Status();
  }

  closeOccurrence() {
    if (this.occurrenceForm.invalid) {
      this.closureDetails.markAsTouched();
      this.receivedBy.markAsTouched();
      return;
    }

    swal({
      type: 'question',
      title: `Recebimento de encomenda`,
      text: 'Deseja realmente encerrar este recebimento de encomenda?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then(
      success => {
        this.saveOccurrence();
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  saveOccurrence(customErrorCallback?) {
    this.occurrence.status = Occurrence.STATUS.CLOSED;
    this.occurrence.closingDetails = this.closureDetails.value;
    this.occurrence.receivedBy = this.receivedBy.value;
    this.occurrence.subTypeAttachments = this.attachments;
    if (this.signature) {
      this.occurrence.signature = this.signature;
    }
    this.isSubmiting = true;

    const updatedOccurrence: any = {
      status: this.occurrence.status,
      closingDetails: this.occurrence.closingDetails,
      receivedBy: this.occurrence.receivedBy,
      subTypeAttachments: this.occurrence.subTypeAttachments.map(att => att.id)
    };

    if (this.signature) {
      updatedOccurrence.signature = this.signature._id;
    }

    this.occurrenceService
      .updateOcurrence(this.condo._id, this.occurrence.id, updatedOccurrence)
      .pipe(timeout(10000))
      .subscribe(
        resp => {
          this.isSubmiting = false;
          this.dataChanged.emit(this.occurrence);
          this.toastr.success('Aviso de encomenda encerrado com sucesso');
          this.closeModal();
        },
        err => {
          this.isSubmiting = false;
          swal({
            title: `Ops...`,
            text: 'Não foi possível atualizar, tente novamente.',
            cancelButtonColor: '#f53d3d'
          });
        }
      );
  }

  showCam() {
    const initialState = {
      callback: base64 => {
        if (base64) {
          this.uploadBase64Url(base64);
        }
      }
    };
    this.modalService.show(ModalPreviewWebcamComponent, { initialState, ignoreBackdropClick: true });
  }

  uploadBase64Url(data) {
    this.fileService.uploadBase64(data).subscribe(
      ([img]) => {
        this.attachments.push(img);
      },
      err => {
        console.log(err);
        swal({ type: 'error', text: 'Não foi possível anexar sua imagem, verifique sua conexão e tente novamente...' });
      }
    );
  }

  sign() {
    const initialState = {
      condo: this.condo,
      callback: data => {
        if (data && data.signature) {
          this.signature = data.signature;
        }
      }
    };
    this.modalService.show(ModalSignature, { initialState });
  }
}
