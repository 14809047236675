/**
 * Created by Max on 02/01/2017.
 */
import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';

// Services
import swal from 'sweetalert2';
import { UtilService } from '../../services/util.service';
import { HttpService } from '../../services/http.service';
import { FileService } from '../../api/service/file.service';
import { CondoService } from '../../api/service/condo.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

// Models
import { Condo } from '../../api/model/condo';
import { debounceTime, distinctUntilChanged, timeout } from 'rxjs/operators';
import { Error } from '@api/model/error/error';

@Component({
  selector: 'create-condo-modal',
  templateUrl: 'create.condo.modal.html',
  styleUrls: ['./create.condo.modal.scss']
})
export class CreateCondoModal {
  onCondoCreationSuccess: Function = () => {};

  public form;
  public name: AbstractControl;
  public cnpj: AbstractControl;
  public address: AbstractControl;
  public number: AbstractControl;
  public extensionLine: AbstractControl;
  public city: AbstractControl;
  public zipCode: AbstractControl;
  public phone: AbstractControl;
  public state: AbstractControl;
  public type: AbstractControl;

  public uf_list;
  public tipos;
  public tiposKeys;

  public isSubmitting: boolean;

  // Condo picture upload related vars
  public isUploading: boolean;
  public defaultCondoPictureUrl: string;
  public uploadedCondoPicture;
  public uploadFileCallback: Function;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private httpService: HttpService,
    private fileService: FileService,
    private condoService: CondoService
  ) {
    this.initializeForm();
    this.tipos = Condo.condoTypes;
    this.tiposKeys = Object.keys(this.tipos);

    this.defaultCondoPictureUrl = '../../../../assets/img/default-condo-picture.png';

    this.uploadFileCallback = files => {
      const file = files[0];
      this.isUploading = true;

      const formData = new FormData();
      formData.append(file.name || 'file', file);

      this.fileService.uploadFilesFromFormData(formData).subscribe(
        resp => {
          this.uploadedCondoPicture = resp[0];
          this.isUploading = false;
        },
        err => {
          this.uploadedCondoPicture = undefined;
          this.isUploading = false;
        }
      );
    };
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      cnpj: ['', Validators.compose([Validators.minLength(18), Validators.maxLength(18)])],
      zipCode: ['', Validators.compose([Validators.required])],
      address: ['', Validators.compose([Validators.required])],
      number: ['', Validators.compose([Validators.required])],
      extensionLine: [''],
      city: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])]
    });
    this.uf_list = this.utilService.getUfList();

    this.name = this.form.controls['name'];
    this.cnpj = this.form.controls['cnpj'];
    this.zipCode = this.form.controls['zipCode'];
    this.address = this.form.controls['address'];
    this.number = this.form.controls['number'];
    this.extensionLine = this.form.controls['extensionLine'];
    this.city = this.form.controls['city'];
    this.state = this.form.controls['state'];
    this.type = this.form.controls['type'];
    this.phone = this.form.controls['phone'];

    this.cnpj.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(cnpj => {
      if (!cnpj) {
        return;
      }
      cnpj = cnpj.trim();
      this.cnpj.setValue(this.utilService.formatCnpj(cnpj), { emitEvent: false });
    });

    this.phone.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(phone => {
      if (!phone) {
        return;
      }
      phone = phone.trim();
      this.phone.setValue(this.utilService.formatPhone(phone), { emitEvent: false });
    });
  }

  formatCep() {
    const zipCode = this.zipCode.value;

    if (zipCode.toString().length > 0) {
      const formattedZipCode = this.utilService.formatCep(zipCode);
      this.zipCode.setValue(formattedZipCode);
    }
  }

  consultarCEP() {
    if (this.zipCode.value.length < 9) {
      return;
    }

    this.httpService.consultarCEP(this.zipCode.value.replace('-', '')).subscribe(
      data => {
        if (!('erro' in data)) {
          this.address.setValue(data.logradouro && data.bairro ? data.logradouro + ', ' + data.bairro : '');
          this.city.setValue(data.localidade || '');
          this.state.setValue(data.uf || '');
        } else {
          this.address.setValue('');
          this.city.setValue('');
          this.state.setValue('');
        }
        this.address.markAsTouched();
        this.city.markAsTouched();
        this.state.markAsTouched();
      },
      err => {
        this.address.setValue('');
        this.city.setValue('');
        this.state.setValue('');
      }
    );
  }

  onSubmit() {
    if (this.form.valid) {
      const condoData: any = {
        name: this.name.value,
        address: this.address.value,
        number: this.number.value,
        extensionLine: this.extensionLine.value,
        city: this.city.value,
        zipCode: this.zipCode.value,
        phones: [this.phone.value.replace(/[^0-9]/g, '')],
        state: this.state.value,
        type: this.type.value,
        pictures: this.uploadedCondoPicture ? [this.uploadedCondoPicture._id] : []
      };
      if (this.cnpj.value) {
        condoData.cnpj = this.cnpj.value.replace(/[^0-9]/g, '');
      }

      this.isSubmitting = true;
      this.condoService
        .createCondo(condoData)
        .pipe(timeout(10000))
        .subscribe(
          (resp: any) => {
            condoData._id = resp._id;
            condoData.pictures = this.uploadedCondoPicture ? [this.uploadedCondoPicture] : [];
            const user = this.utilService.getLocalUser();
            if (user) {
              condoData.createdBy = user;
            }
            this.onCondoCreationSuccess(new Condo(condoData));
            this.bsModalRef.hide();
          },
          (err: Error) => {
            this.isSubmitting = false;
            let errorMessage = '';
            if (err && err.originalError) {
              try {
                const errorMessageString = JSON.stringify(err.originalError);
                if (errorMessageString.includes('is not unique')) {
                  errorMessage =
                    'Já existe um(a) condomínio cadastrado com essas informações. Tente procurar por seu condomínio novamente.';
                } else {
                  errorMessage = err.message;
                }
              } catch (e) {
                errorMessage = err.message;
              }
            }

            swal({
              type: 'error',
              title: err.messageTitle || `Problema ao criar`,
              text:
                errorMessage ||
                'Não foi possível criar o condomínio, tente novamente.' + '\nSe o problema persistir entre em contato com o suporte',
              cancelButtonColor: '#f53d3d'
            });
          }
        );
    } else {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).markAsTouched());
    }
  }
}
