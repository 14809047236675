import { Component } from '@angular/core';
import { Condo } from '@api/model/condo';
import { MARITAL_STATUS_LABEL } from '@api/model/constants';
import { EcondosQuery } from '@api/model/query';
import { User } from '@api/model/user';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-modal-select-user',
  templateUrl: 'modal-select-user.component.html',
  styleUrls: ['modal-select-user.component.scss']
})
export class ModalSelectUserComponent {
  selectedUser: User;
  condo: Condo;

  userIdsToHide: string[] = [];
  multipleSelection = false;
  selectedUsers: User[] = [];
  query: EcondosQuery = {};

  MARITAL_STATUS_LABEL = MARITAL_STATUS_LABEL;

  callback: { success?: Function; error?: Function };
  constructor(public bsModalRef: BsModalRef) {}

  selectUser(user) {
    this.selectedUser = user;
  }

  changeUser() {
    if (this.callback) {
      this.callback.success(this.selectedUser);
    }
    this.bsModalRef.hide();
  }
}
