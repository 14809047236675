import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { ControlIdService } from '@api/service/hardware/control-id.service';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { UtechService } from '@api/service/hardware/utech.service';
import { AlphaDigiService } from '@api/service/hardware/alphadigi.service';
import { IntelbrasStandAloneService } from '@api/service/hardware/intelbras-stand-alone.service';
import { ZktecoService } from '@api/service/hardware/zkteco.service';
import { GarenService } from '@api/service/hardware/garen.service';
import { SdkService } from '@api/service/hardware/sdk.service';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';

@Injectable({ providedIn: 'root' })
export class HardwareDeviceServiceV2 extends HardwareDeviceService {
  constructor(
    protected http: HttpService,
    protected controlIdService: ControlIdService,
    protected hikvisionService: HikvisionService,
    protected intelbrasIncontrolService: IntelbrasIncontrolService,
    protected intelbrasService: IntelbrasStandAloneService,
    protected utechService: UtechService,
    protected constantService: ConstantService,
    protected alphaDigiService: AlphaDigiService,
    protected zktecoService: ZktecoService,
    protected garenService: GarenService,
    protected sdkService: SdkService
  ) {
    super(
      http,
      controlIdService,
      hikvisionService,
      intelbrasIncontrolService,
      intelbrasService,
      utechService,
      constantService,
      alphaDigiService,
      zktecoService,
      garenService,
      sdkService
    );
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;
  }

  getDeviceTemplate(condoId: string, deviceId: string): Observable<{ template: string }> {
    const query: EcondosQuery = { $select: 'template' };
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get(`${this.endPoint}${condoId}/devices/${deviceId}/template`, { params }) as Observable<{ template: string }>;
  }
}
