import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { Actuator } from '@api/model/hardware/actuator';
import { Status } from '@api/model/status';
import { ActuatorsRoute } from '@api/model/hardware/actuators-route';

export class ActuatorsRouteForm {
  title: FormControl<string>;
  typeIcon: FormControl<string>;
  selections: FormArray<FormGroup<SelectionForm>>;
  addTextBeforeAction: FormControl<boolean>;
  actionText: FormControl<string>;
  _id: FormControl<string>;
  condo: FormControl<string>;
  showInActuatorsPanel: FormControl<boolean>;
}

export class SelectionForm {
  type: FormControl<string>;
  actuator: FormControl<string | Actuator>;
  timer: FormControl<number>;
}
@Component({
  selector: 'app-modal-create-actuators-route',
  templateUrl: './modal-create-actuators-route.component.html',
  styleUrls: ['./modal-create-actuators-route.component.scss']
})
export class ModalCreateActuatorsRouteComponent implements OnInit {
  condo: Condo;
  user: User;

  actuatorsList: Actuator[] = [];
  actuatorsRoute: ActuatorsRoute;

  currentActuatorRouteForm: FormGroup;
  hasTimer = false;
  status: Status = new Status();
  typeIcons = ['male', 'user', 'users', 'car', 'motorcycle', 'truck', 'volume-up', 'power-off', 'repeat'];

  title: ActuatorsRouteForm['title'];
  typeIcon: ActuatorsRouteForm['typeIcon'];

  callbacks: { success?: (arg) => void; error?: (err) => void };

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  get selections(): FormArray<FormGroup<SelectionForm>> {
    return this.currentActuatorRouteForm.controls.selections as FormArray<FormGroup<SelectionForm>>;
  }

  ngOnInit(): void {
    this.currentActuatorRouteForm = this.fb.group({
      title: ['', Validators.required],
      typeIcon: ['male', Validators.required],
      selections: this.fb.array([], Validators.required),
      addTextBeforeAction: [false],
      actionText: [''],
      _id: [],
      condo: [this.condo._id],
      showInActuatorsPanel: [true]
    });

    this.title = this.currentActuatorRouteForm.get('title') as UntypedFormControl;
    this.typeIcon = this.currentActuatorRouteForm.get('typeIcon') as UntypedFormControl;

    if (this.actuatorsRoute) {
      this.populateForm();
      this.checkForTimer();
      this.cdr.detectChanges();
    }
  }

  private populateForm(): void {
    this.currentActuatorRouteForm.patchValue({ ...this.actuatorsRoute });

    this.actuatorsRoute.selections.forEach(selection => {
      this.addSelection(selection);
    });
  }

  private checkForTimer(): void {
    this.hasTimer = this.actuatorsRoute.selections.some(selection => selection.type === 'timer');
  }

  addSelection(selection): void {
    console.log(selection);
    this.selections.push(
      this.fb.group({
        type: [selection.type, Validators.required],
        actuator: [selection.type === 'timer' ? null : selection.actuator?._id || selection.actuator || ''],
        timer: [selection.timer || null]
      })
    );
  }

  removeSelection(index: number): void {
    const selectionType = this.selections.at(index).value.type;
    if (selectionType === 'timer') {
      this.hasTimer = false;
    }
    this.selections.removeAt(index);
  }

  canAddTimer(): boolean {
    const lastSelection = this.selections.at(this.selections.length - 1);
    return this.selections.length > 0 && lastSelection.value.type !== 'timer';
  }

  save(): void {
    this.selections.controls = this.selections.controls.filter(control => control.value.actuator !== null || control.value.timer !== null);
    this.currentActuatorRouteForm.controls.selections.setValue(this.selections.value);
    if (this.currentActuatorRouteForm.invalid) {
      this.currentActuatorRouteForm.markAllAsTouched();
      return;
    }
    const formValue = this.currentActuatorRouteForm.value;
    this.callbacks?.success?.(formValue);
    this.bsModalRef.hide();
  }
}
