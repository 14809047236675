import { Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { UtilService } from '../../../services/util.service';
import swal, { SweetAlertInputOptions, SweetAlertOptions } from 'sweetalert2';
import { User } from '@api/model/user';
import { CondoService, UpdateUserRoles } from '@api/service/condo.service';
import { UserService } from '@api/service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Condo } from '@api/model/condo';
import { UntypedFormControl } from '@angular/forms';
import { Status } from '@api/model/status';
import { firstValueFrom, forkJoin, from, lastValueFrom, noop, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  timeout,
  timeoutWith
} from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { MARITAL_STATUS_LABEL } from '@api/model/constants';
import { ResidenceService } from '@api/service/residence.service';
import { EcondosQuery } from '@api/model/query';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalCreateUserStepsComponent } from 'app/components/modal-create-user-steps/modal-create-user-steps.component';
import { CallService } from '../../../services/call.service';
import { capitalize, replaceVowelsToAccentedVowels, sortFunction } from '@api/util/util';
import { EcondosFilter } from '@api/model/filter';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { OccurrenceService } from '@api/service/occurrence.service';
import { Occurrence } from '@api/model/interface/occurrence';
import * as qs from 'qs';
import { CondoUsersService } from '@api/serviceV3/condo-users.service';
import { ToastrService } from 'ngx-toastr';
import { CondoRequestService } from '@api/service/condo-request.service';
import { CondoRequest, getRequestRolesLabels } from '@api/model/condo-request';

@Component({
  selector: 'app-condo-residents',
  templateUrl: './condo.residents.html'
})
export class CondoResidentsComponent implements OnInit {
  @ViewChild('residentsTable', { static: true }) residentsTable;
  @ViewChild('userTemplate', { static: true }) userTemplate: TemplateRef<any>;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  onDataChanged = new EventEmitter();

  filters: EcondosFilter[] = [];
  customQuery: Object;

  public windowWidth = window.innerWidth;
  public user: User;
  public condo: Condo;
  public residences: Residence[];
  public columns = [];
  public users: Array<User> = [];
  public filteredUsers: Array<User> = [];
  public requesters: Array<User> = [];
  public selectedUsers: Array<User> = [];
  public selectedUser: User;
  public status = new Status();
  public selectedResidences: Array<Residence> = new Array<Residence>();

  public blockMobileUserAccess: boolean;
  public blockUserAccess: boolean;

  numberOfActiveFilters = 0;

  public methods = {};
  public messages = {
    emptyMessage: '',
    totalMessage: 'usuário(s)'
  };

  searchTerm: UntypedFormControl;
  readonly pageLimit = 20;
  hasMoreUsersToLoad = false;
  totalNumberOfResidents = 0;
  isPaginating = false;

  MARITAL_STATUS_LABEL = MARITAL_STATUS_LABEL;

  isAdminOrOwner = false;

  residenceRequester: {
    residence: { _id: string; identification: string };
    role: string;
  };
  residenceRequesterStatus: Status = new Status();
  selectedResidenceRequester: UntypedFormControl = new UntypedFormControl(undefined);
  selectedRoleRequester: UntypedFormControl = new UntypedFormControl(undefined);

  ROLES_REQUESTER_LABEL;

  shouldMaskData = {
    email: false,
    phones: false,
    ids: false
  };

  unmaskedData = {
    email: '',
    phones: ['', '', ''],
    ids: ''
  };
  residenceUserLabel = {};

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  roleOptions: Record<keyof Omit<typeof User.ROLES, 'REQUESTER'>, string>;

  roleUserProp: Record<keyof Omit<typeof User.ROLES, 'REQUESTER'>, string> = {
    OWNER: 'condosOwner',
    ADMIN: 'condosAdmin',
    GATEKEEPER: 'condosGatekeeper',
    JANITOR: 'condosJanitor',
    USER: 'condos'
  };

  canRegisterFacialFromResidents = false;
  condoRequests: CondoRequest[] = [];

  constructor(
    private condoService: CondoService,
    private utilService: UtilService,
    private userService: UserService,
    private router: Router,
    private modalService: BsModalService,
    private condoUsers: CondoUsersService,
    private residenceService: ResidenceService,
    private route: ActivatedRoute,
    private callService: CallService,
    private deviceService: HardwareDeviceService,
    private occurrenceService: OccurrenceService,
    private toastr: ToastrService,
    private condoRequestService: CondoRequestService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();

    this.isAdminOrOwner = this.user.isAdminOnCondo(this.condo._id) || this.user.isOwnerOnCondo(this.condo._id);

    const hasFacialManufacturerEnabled =
      this.condo.isHikvisionEnabled() ||
      this.condo.isControlIdEnabled() ||
      this.condo.isAlphaDigiEnabled() ||
      this.condo.isGarenEnabled() ||
      this.condo.isBravasEnabled() ||
      this.condo.isIntelbrasStandAloneEnabled() ||
      this.condo.isZktecoEnabled();

    this.canRegisterFacialFromResidents = hasFacialManufacturerEnabled && this.isAdminOrOwner;

    this.roleOptions = {
      OWNER: capitalize(this.condo?.customLabels?.owner?.singular) || 'Síndico',
      ADMIN: capitalize(this.condo?.customLabels?.admin?.singular) || 'Administrador',
      GATEKEEPER: capitalize(this.condo?.customLabels?.gatekeeper?.singular) || 'Porteiro',
      JANITOR: capitalize(this.condo?.customLabels?.janitor?.singular) || 'Zelador',
      USER: capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino'
    };

    this.ROLES_REQUESTER_LABEL = getRequestRolesLabels(this.condo);

    this.methods[User.ROLES.OWNER] = this.condoService.changeCondoOwner;
    this.methods[User.ROLES.ADMIN] = this.condoService.addAdminToCondo;
    this.methods[User.ROLES.GATEKEEPER] = this.condoService.addGatekeeperToCondo;
    this.methods[User.ROLES.USER] = this.condoService.addCondoUser;

    this.searchTerm = new UntypedFormControl();

    this.searchTerm.valueChanges
      .pipe(
        map(v => v.toString().trim()),
        filter(v => {
          const length = v.length;
          // Filtra busca em branco ou com mais que 2 caracteres
          return !length || length > 2;
        }),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => {
        if (!searchTerm) {
          this.downloadData(0, true, true);
          return;
        }

        let terms = searchTerm.trim().toLowerCase();
        terms = terms.split(' ');
        terms = terms.map(word => this.utilService.removeAccents(word));
        terms = terms.map(replaceVowelsToAccentedVowels);

        const temp = [];
        terms.forEach((term, i) => {
          temp[temp.length] = `$and[${i}][$or][0][firstName][$regex]=${term}`;
          temp[temp.length] = `$and[${i}][$or][0][firstName][$options]=i`;
          temp[temp.length] = `$and[${i}][$or][1][lastName][$regex]=${term}`;
          temp[temp.length] = `$and[${i}][$or][1][lastName][$options]=i`;
        });

        const qs = this.getQueryString() + '&' + temp.join('&');

        this.status.setAsDownloading();
        this.condoService
          .getUsersFromCondo(this.condo._id, qs)
          .pipe(timeout(15000))
          .subscribe(
            users => {
              this.status.setAsSuccess();
              this.filteredUsers = users;
              if (!this.filteredUsers.length) {
                this.messages.emptyMessage = 'Usuário não encontrado. Tente novamente!';
              } else {
                this.sortFilteredUsers();
              }
              this.totalNumberOfResidents = users.length;
            },
            () => {
              this.status.setAsError();
              this.filteredUsers = this.users;
            }
          );
      });

    this.filters = [
      {
        element: 'input',
        elementType: 'text',
        name: 'ids',
        icon: 'id-card',
        label: 'Documento',
        placeholder: 'Busque pelo documento',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementType: 'text',
        name: 'emails',
        label: 'Email',
        icon: 'envelope',
        placeholder: 'Busque pelo email',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementType: 'text',
        name: 'phones',
        icon: 'phone',
        label: 'Telefone',
        placeholder: 'Busque pelo telefone',
        searchType: 'regex'
      },
      {
        element: 'residence-picker',
        label: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        name: 'residence',
        params: {
          condo: this.condo,
          showAsInputGroup: true,
          typeaheadHideResultsOnBlur: true,
          placeholder: `Buscar ${this.condo?.customLabels?.residence?.singular || 'unidade'}`,
          adaptivePosition: true,
          clearInputTextAfterSelect: false
        },
        searchType: 'match'
      },
      {
        element: 'button-select',
        label: 'Perfis',
        name: 'roles',
        buttonSelectOptions: {
          options: Object.entries(this.roleOptions).map(role => ({
            value: role[0],
            label: role[1]
          })),
          optionLabel: 'label',
          optionValue: 'value',
          optionTitle: 'label',
          minSelect: 0
        },
        searchType: 'match'
      }
    ];
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'Usuário',
        flexGrow: 1,
        cellTemplate: this.userTemplate
      }
    ];

    this.downloadData(0, true, true);
  }

  fillResidenceUserLabels(residences: Residence[]) {
    this.residenceUserLabel = residences.reduce((accumulator, current) => {
      return {
        ...accumulator,
        [current.id]: {
          label: this.selectedUser.condoResidenceAttributes.find(attr => attr.residence === current.id)?.userLabel
        }
      };
    }, {});
  }

  getQueryString(isEcondosQuery: true): EcondosQuery;
  getQueryString(isEcondosQuery: false): string;
  getQueryString(isEcondosQuery?: boolean): EcondosQuery | string;
  getQueryString(isEcondosQuery: boolean) {
    const query: EcondosQuery = {
      $populate: [
        { path: 'picture', select: 'thumbnail url type' },
        { path: 'residencesVoter', select: 'identification condo type' },
        { path: 'residencesUser', select: 'identification condo type' },
        { path: 'residencesRequester', select: 'identification condo type' },
        { path: 'createdBy', select: 'firstName lastName fullName' }
      ],
      $sort: 'firstName lastName'
    };

    const $and = [];

    for (const q in this.customQuery) {
      if (q === 'ids') {
        query.ids = { $elemMatch: { number: { $regex: `^${this.customQuery[q].$regex}` } } };
      } else if (q === 'residence') {
        const $or = [{ residencesUser: this.customQuery[q] }, { residencesVoter: this.customQuery[q] }];
        $and.push({ $or });
      } else if (q === 'roles') {
        if (this.customQuery[q].length) {
          const $or = this.customQuery[q].map(role => {
            return { [this.roleUserProp[role]]: this.condo.id };
          });
          $and.push({ $or });
        }
      } else {
        query[q] = {
          $regex: this.customQuery[q].$regex,
          $options: 'i'
        };
      }
    }

    if ($and.length) {
      query.$and = $and;
    }
    if (isEcondosQuery) {
      return query;
    } else {
      return '?' + qs.stringify(query, { encode: false });
    }
  }

  goToResidence(residence?: Residence) {
    this.router.navigate(['condo', 'residences', residence._id]);
  }

  onScroll() {
    // When searching, there is no scroll
    if (this.searchTerm.value) {
      return;
    }

    if (!this.hasMoreUsersToLoad) {
      return;
    }

    // Must subtract requesters length to paginate correctly , due to, users query only search by users that are not requester
    const currentPage = Math.ceil((this.users.length - this.requesters.length) / this.pageLimit);
    const lastIndex = this.residentsTable.bodyComponent.indexes.last;
    const percentScrolled = lastIndex / this.users.length;

    if (!this.status.isDownloading() && percentScrolled > 0.9) {
      this.isPaginating = true;
      this.downloadData(currentPage, false, false);
    }
  }

  refreshData() {
    this.searchTerm.setValue('');
    this.downloadData(0, true, true);
  }

  downloadData(pageNumber = 0, shouldGetRequesters?: boolean, shouldCountUsers?: boolean) {
    if (!pageNumber) {
      if (this.residentsTable && this.residentsTable.bodyComponent && this.residentsTable.bodyComponent.updateOffsetY) {
        this.residentsTable.bodyComponent.updateOffsetY(0);
      }
    }
    const requestersQs: EcondosQuery = {
      $select: 'user role condo status residence createdBy',
      $populate: [
        {
          path: 'user',
          select:
            'firstName lastName email ids phones picture createdBy createdAt birthDate occupation specialNeeds maritalStatus residenceRequester condosRequester',
          populate: [
            { path: 'picture', select: 'url thumbnail' },
            { path: 'createdBy', select: 'firstName lastName fullName' },
            { path: 'ids', select: 'number type' }
          ]
        },
        { path: 'residence', select: 'identification' }
      ],
      status: 'PENDING'
    };

    let qs: EcondosQuery = this.getQueryString(true);
    qs.$page = pageNumber;
    qs.$limit = this.pageLimit;
    qs.condosRequester = { $ne: this.condo.id };

    const observables: { [key: string]: any } = {};

    const counterObservable = this.condoService
      .getNumberOfUsersFromCondo(this.condo.id)
      .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()));

    const usersObservable = this.condoService
      .getCondoResidents(this.condo._id, qs)
      .pipe(timeoutWith(20000, ErrorBuilder.throwTimeoutError()));

    observables['usersResponse'] = usersObservable;
    if (shouldGetRequesters && (this.user.isAdminOnCondo(this.condo._id) || this.user.isOwnerOnCondo(this.condo._id))) {
      const requestersObservable = this.condoRequestService
        .get(this.condo._id, requestersQs)
        .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()));
      observables['requesters'] = requestersObservable;
    }
    if (shouldCountUsers) {
      observables['countUser'] = counterObservable;
    }
    const subscription = forkJoin(observables);
    this.status.setAsDownloading();

    subscription.pipe(timeoutWith(25000, ErrorBuilder.throwTimeoutError())).subscribe({
      next: (resp: any) => {
        const { usersResponse, requesters, countUser } = resp;
        const users = <Array<User>>(usersResponse.users || []);
        if (shouldCountUsers) {
          this.totalNumberOfResidents = countUser;
        }
        if (shouldGetRequesters && (this.user.isAdminOnCondo(this.condo._id) || this.user.isOwnerOnCondo(this.condo._id))) {
          const usersWithoutCondosRequester = requesters?.condoRequests.filter(
            (request: CondoRequest) => !request.user?.condosRequester.length
          );
          this.totalNumberOfResidents = this.totalNumberOfResidents + usersWithoutCondosRequester.length;

          const newRequesters = requesters?.condoRequests || [];
          this.condoRequests = newRequesters;
          this.requesters = newRequesters.map(requester => new User(requester.user));
        }
        const residentId = this.route.snapshot.params['residentId'];

        const requester = this.requesters.find(requester => requester._id === residentId);
        if (pageNumber == 0) {
          this.filteredUsers = this.users = [].concat(this.requesters, users);
          if ((residentId || this.selectedUser) && !requester) {
            const id = this.selectedUser ? this.selectedUser.id : residentId;
            this.getSingleResident(id);
          } else {
            this.filterSelectedUser(requester);
          }
        } else {
          this.users = [...this.users, ...users];
          this.filteredUsers = this.users;
        }
        this.sortFilteredUsers();
        this.hasMoreUsersToLoad = users.length >= this.pageLimit;

        this.isPaginating = false;
        this.status.setAsSuccess();
        if (users.length == 0) {
          this.messages.emptyMessage = `Nenhum usuário cadastrado neste(a) ${this.condo?.customLabels?.condo?.singular}.`;
        }
      },
      error: () => {
        this.status.setAsError();
        this.messages.emptyMessage = 'Não foi possível buscar os usuários. Verifique sua conexão e tente novamente!';
        this.filteredUsers = this.users = [];
        this.selectedUser = null;
        this.totalNumberOfResidents = this.totalNumberOfResidents || 0;
        this.isPaginating = false;
      }
    });
  }

  getSingleResident(residentId: string) {
    this.condoService
      .getCondoUserById(this.condo._id, residentId, this.getQueryString(false))
      .pipe(timeout(10000))
      .subscribe(resident => {
        this.filterSelectedUser(resident);
      });
  }

  isCondoRequester(user: User) {
    return this.condoRequests.some(r => r.user._id === user._id);
  }

  private filterSelectedUser(user: User) {
    this.selectedUsers.splice(0, this.selectedUsers.length);
    this.selectedUsers.push(user);
    this.selectedUser = this.selectedUsers[0];
    this.residences = this.selectedUser?.getResidences() || [];
    if (this.residences?.length) {
      this.fillResidenceUserLabels(this.residences);
    }
    const selectedUserCondoRequest = this.condoRequests.find(condoRequest => condoRequest.user._id === this.selectedUser?._id);
    const userResidenceRequester = selectedUserCondoRequest?.residence ? selectedUserCondoRequest : null;

    const condo = userResidenceRequester?.condo?._id || userResidenceRequester?.condo;
    if (condo === this.condo._id) {
      this.getResidence(this.selectedUser);
    } else {
      this.residenceRequester = null;
      this.selectedRoleRequester.setValue(null);
      this.selectedResidenceRequester.setValue(null);
    }

    if (user && (this.user.isSystemAdmin || this.isAdminOrOwner)) {
      this.condoUsers.getById(this.condo.id, user.id).subscribe({
        next: ({ blockMobileUserAccess, blockUserAccess }) => {
          this.blockMobileUserAccess = blockMobileUserAccess || false;
          this.blockUserAccess = blockUserAccess || false;
        },
        error: err => {
          this.toastr.error('Falha na busca dos usuários');
          console.log(err);
        }
      });
    }
    this.sortFilteredUsers();
  }

  sortFilteredUsers() {
    if (this.searchTerm.value) {
      this.filteredUsers = this.filteredUsers.sort((a, b) => sortFunction(a.firstName, b.firstName));
    } else {
      if (this.selectedUser && !this.filteredUsers.some(u => u._id === this.selectedUser?._id)) {
        this.filteredUsers = [this.selectedUser, ...this.filteredUsers];
      }
      this.filteredUsers = this.filteredUsers.filter(
        filteredUser => !this.requesters.some(requester => requester?._id === filteredUser._id)
      );
      this.filteredUsers = this.filteredUsers.sort((a, b) => sortFunction(a.firstName, b.firstName));
      this.filteredUsers = [...this.requesters, ...this.filteredUsers];
    }
  }

  toggleUserAccess(whichAccess: 'blockUserAccess' | 'blockMobileUserAccess') {
    const data = {
      blockUserAccess: this.blockUserAccess,
      blockMobileUserAccess: this.blockMobileUserAccess
    };

    const isBlockUserAccess = whichAccess === 'blockUserAccess';

    let option: string;
    if (isBlockUserAccess) {
      option = this.blockUserAccess === true ? 'bloquear' : 'desbloquear';
    } else {
      option = this.blockMobileUserAccess === true ? 'bloquear' : 'desbloquear';
    }

    const message = isBlockUserAccess ? 'totalmente o acesso ao sistema' : 'o acesso ao mobile';

    swal({
      type: 'warning',
      title: ` ${capitalize(option) || 'Bloquear'} acesso?`,
      text: `Tem certeza que deseja ${option} ${message} do usuário ${this.selectedUser.firstName}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          return await firstValueFrom(this.condoUsers.updateCondoUser(this.condo.id, this.selectedUser.id, data).pipe(timeout(10000)));
        } catch (err) {
          console.log(err);
          this.toastr.error('Não foi possível atualizar este usuário');
        }
      }
    }).then(
      () => {
        this.toastr.success('Opções de acesso do usuário atualizadas com sucesso');
      },
      () => {
        isBlockUserAccess ? (this.blockUserAccess = !this.blockUserAccess) : (this.blockMobileUserAccess = !this.blockMobileUserAccess);
        console.log('Clicked cancel');
      }
    );
  }

  onSelect({ selected }) {
    const user = selected?.[0];
    this.filterSelectedUser(user);
  }

  getResidence(user: User) {
    const condoRequest = this.condoRequests.find(condoRequest => condoRequest.user._id === user._id);
    const userResidenceRequester = condoRequest.residence;

    const residenceIdRequester = this.condoRequests.find(condoRequest => condoRequest.user._id === user._id)?.residence?._id;

    const roleRequester = userResidenceRequester && condoRequest.role;
    if (!!residenceIdRequester) {
      this.residenceRequesterStatus.setAsSuccess();
      this.residenceRequester = { residence: userResidenceRequester, role: roleRequester };
      this.selectedRoleRequester.setValue(roleRequester);
      this.selectedResidenceRequester.setValue(userResidenceRequester);
    } else {
      this.residenceRequesterStatus.setAsError();
      this.residenceRequester = { residence: undefined, role: undefined };
      this.selectedRoleRequester.setValue(undefined);
      this.selectedResidenceRequester.setValue(undefined);
    }
  }

  askToChangeUserRole(condo: Condo) {
    const user = new User(this.selectedUser);

    let htmlRoleOptions = '';

    Object.keys(this.roleOptions).forEach(currentRole => {
      htmlRoleOptions += `
        <span class='mx-3 mt-3' style='white-space: nowrap; display: inline-block;'>
          <input type='checkbox' value='${currentRole}' id='role-option-${currentRole}'>
          <label for='role-option-${currentRole}'>${this.roleOptions[currentRole]}</label>
        </span>
      `;
    });

    swal({
      title: 'Selecione o novo perfil',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      html: htmlRoleOptions,
      onBeforeOpen: (modalElement: HTMLElement) => {
        const inputOwnerRole = modalElement.querySelector(`input#role-option-${User.ROLES.OWNER}`) as HTMLInputElement;
        inputOwnerRole.checked = user.isOwnerOnCondo(condo._id);

        const inputAdminRole = modalElement.querySelector(`input#role-option-${User.ROLES.ADMIN}`) as HTMLInputElement;
        inputAdminRole.checked = user.isAdminOnCondo(condo._id);

        const inputGatekeeperRole = modalElement.querySelector(`input#role-option-${User.ROLES.GATEKEEPER}`) as HTMLInputElement;
        inputGatekeeperRole.checked = user.isGatekeeperOnCondo(condo._id);

        const inputJanitorRole = modalElement.querySelector(`input#role-option-${User.ROLES.JANITOR}`) as HTMLInputElement;
        inputJanitorRole.checked = user.isJanitorOnCondo(condo._id);

        const inputUserRole = modalElement.querySelector(`input#role-option-${User.ROLES.USER}`) as HTMLInputElement;
        inputUserRole.checked = user.isUserOnCondo(condo._id);
      },
      focusConfirm: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const userRoles: string[] = [];

        const inputOwnerRole = swal.getContent().querySelector(`input#role-option-${User.ROLES.OWNER}`) as HTMLInputElement;

        if (inputOwnerRole?.checked) {
          userRoles.push(User.ROLES.OWNER);
        }

        const inputAdminRole = swal.getContent().querySelector(`input#role-option-${User.ROLES.ADMIN}`) as HTMLInputElement;

        if (inputAdminRole?.checked) {
          userRoles.push(User.ROLES.ADMIN);
        }

        const inputGatekeeperRole = swal.getContent().querySelector(`input#role-option-${User.ROLES.GATEKEEPER}`) as HTMLInputElement;

        if (inputGatekeeperRole?.checked) {
          userRoles.push(User.ROLES.GATEKEEPER);
        }

        const inputJanitorRole = swal.getContent().querySelector(`input#role-option-${User.ROLES.JANITOR}`) as HTMLInputElement;

        if (inputJanitorRole?.checked) {
          userRoles.push(User.ROLES.JANITOR);
        }

        const inputUserRole = swal.getContent().querySelector(`input#role-option-${User.ROLES.USER}`) as HTMLInputElement;

        if (inputUserRole?.checked) {
          userRoles.push(User.ROLES.USER);
        }

        if (!userRoles.length) {
          return Promise.reject('Selecione uma opção');
        }

        return Promise.resolve(userRoles);
      }
    })
      .then((userRolesToSet: string[]) => {
        const setAsUser = userRolesToSet.some(role => role === User.ROLES.USER);
        const setAsJanitor = userRolesToSet.some(role => role === User.ROLES.JANITOR);
        const setAsGatekeeper = userRolesToSet.some(role => role === User.ROLES.GATEKEEPER);
        const setAsAdmin = userRolesToSet.some(role => role === User.ROLES.ADMIN);
        const setAsOwner = userRolesToSet.some(role => role === User.ROLES.OWNER);

        const roles: UpdateUserRoles = {
          setAsUser,
          setAsJanitor,
          setAsGatekeeper,
          setAsAdmin,
          setAsOwner
        };

        const isResidentTheSameAsLoggedUser = this.selectedUser._id === this.user._id;

        const successCallback = (updatedUser: User) => {
          this.onDataChanged.emit();

          this.selectedUser = new User(updatedUser);

          this.fillResidenceUserLabels(this.selectedUser.getResidences());

          if (isResidentTheSameAsLoggedUser) {
            this.changeLoggedUserRole();
          } else {
            swal({
              type: 'success',
              text: 'Perfil alterado com sucesso!'
            });
          }
        };

        const errorCallback = () => {
          swal({
            type: 'error',
            text: 'Não foi possível alterar o perfil deste usuário. Tente novamente, por favor.'
          });
        };

        this.condoService
          .changeUserRoles(this.condo._id, user.id, roles)
          .pipe(
            timeout(10000),
            mergeMap(() => {
              if (isResidentTheSameAsLoggedUser) {
                return this.userService.getMe();
              } else {
                return this.condoService.getCondoUserById(this.condo.id, this.selectedUser.id, this.getQueryString(false));
              }
            })
          )
          .subscribe({
            next: successCallback,
            error: errorCallback
          });
      })
      .catch(swal.noop);
  }

  changeLoggedUserRole() {
    this.userService
      .getMe()
      .pipe(timeout(10000))
      .subscribe(user => {
        this.user = user;
        this.utilService.saveLocalUser(user);
      });

    swal({
      type: 'success',
      text: 'Perfil alterado com sucesso, você será encaminhado para página inicial para completar o processo,'
    }).then(() => {
      this.router.navigate(['/feed']);
    });
  }

  askToRemoveUser(user) {
    const deviceQuery: EcondosQuery = {
      accessType: 'RESIDENT',
      status: { $in: ['ACTIVE', 'SYNCED', 'UNSYNCED', 'OPENED'] },
      'owner.user': user.id
    };
    const occurrenceQuery: EcondosQuery = {
      type: 'GATE',
      subType: 'ACCESS',
      status: { $in: ['OPENED', 'PENDING'] },
      createdBy: user.id
    };

    const onRemoveUser = async (deleteDevice = false) => {
      try {
        return await lastValueFrom(this.condoService.removeUserFromCondo(this.condo.id, user.id, deleteDevice).pipe(timeout(10000)));
      } catch (err) {
        throw `Não foi possível remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'}, tente novamente...`;
      }
    };

    const onRemoveOccurrencesAndUser = (occurrences: Occurrence[], userId: string) => {
      const deleteOccurrencesSubscriptionArray = occurrences.map(o =>
        this.occurrenceService.cancelOcurrence(this.condo.id, o.id).pipe(timeout(10000))
      );
      return forkJoin(deleteOccurrencesSubscriptionArray)
        .pipe(mergeMap(() => this.condoService.removeUserFromCondo(this.condo.id, userId, true).pipe(timeout(10000))))
        .toPromise()
        .catch(() => {
          return Promise.reject(
            `Não foi possível remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'}, tente novamente.`
          );
        });
    };
    const onSuccess = success => {
      this.filteredUsers = this.filteredUsers.filter(user => user._id !== this.selectedUser._id);
      this.totalNumberOfResidents = this.totalNumberOfResidents - 1;
      this.selectedUser = null;
      this.onDataChanged.emit();
      swal({
        type: 'success',
        title: `${this.condo?.customLabels?.resident?.singular || 'Condômino'} removido do(a) ${
          this.condo?.customLabels?.condo?.singular || 'condomínio'
        } com sucesso!`
      }).catch(console.error);
    };

    forkJoin([this.deviceService.get(this.condo.id, deviceQuery), this.occurrenceService.getOccurrences(this.condo.id, occurrenceQuery)])
      .pipe(timeout(10000))
      .subscribe(
        ([deviceResponse, occurrenceResponse]) => {
          const deviceCount = Number(deviceResponse.count);
          const occurrenceCount = Number(occurrenceResponse.count);
          const sweetSettings: SweetAlertOptions = {
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#f53d3d',
            showConfirmButton: true,
            confirmButtonText: 'Confirmo e aceito os riscos',
            confirmButtonColor: '#32DB64',
            showLoaderOnConfirm: true,
            reverseButtons: true
          };
          const sweetAlertOptions: SweetAlertInputOptions = {
            DEL_USER_AND_ACCESSES: `Remover ${this.condo?.customLabels?.resident?.singular || 'condômino'} e cancelar liberações`,
            DEL_ONLY_USER: `Apenas remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'}`
          };

          const sweetAlertOptionsDevices: SweetAlertInputOptions = {
            DEL_USER_AND_DEVICES: `Remover ${this.condo?.customLabels?.resident?.singular || 'condômino'} e excluir os dispositivos do mesmo`,
            DEL_ONLY_USER: `Apenas remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'}`
          };
          if (deviceCount === 0 && occurrenceCount === 0) {
            swal({
              ...sweetSettings,
              type: 'question',
              title: `Remover ${user.firstName} ${user.lastName}`,
              text: `Realmente deseja remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'}} do ${
                this.condo?.customLabels?.condo?.singular || 'condomínio'
              }?`,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
              preConfirm: onRemoveUser
            })
              .then(onSuccess)
              .catch(console.error);
          }
          if (deviceCount > 0 && occurrenceCount === 0) {
            swal({
              ...sweetSettings,
              type: 'warning',
              title: 'Dispositivos encontrados',
              text: `Este ${
                this.condo?.customLabels?.resident?.singular || 'condômino'
              } possui dispositivos associados a ele. Você pode remover o ${
                this.condo?.customLabels?.resident?.singular || 'condômino'
              } junto com os dispositivos ou apenas remover o  ${this.condo?.customLabels?.resident?.singular || 'condômino'} do(a) ${
                this.condo?.customLabels?.condo?.singular || 'condomínio'
              }.`,
              input: 'radio',
              inputOptions: sweetAlertOptionsDevices,
              preConfirm: selection => {
                switch (selection) {
                  case 'DEL_USER_AND_DEVICES':
                    return onRemoveUser(true);
                  case 'DEL_ONLY_USER':
                    return onRemoveUser();
                  default:
                    return Promise.reject('Nenhuma opção selecionada.');
                }
              }
            })
              .then(onSuccess)
              .catch(console.error);
          }
          if (deviceCount === 0 && occurrenceCount > 0) {
            swal({
              ...sweetSettings,
              type: 'warning',
              title: 'Liberações de acesso encontradas',
              text: `Este ${
                this.condo?.customLabels?.resident?.singular || 'condômino'
              } possui liberações de acesso em aberto. Você pode remover o ${
                this.condo?.customLabels?.resident?.singular || 'condômino'
              } e cancelar suas liberações ou apenas remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'} do ${
                this.condo?.customLabels?.condo?.singular || 'condomínio'
              }.`,
              input: 'radio',
              inputOptions: sweetAlertOptions,
              preConfirm: selection => {
                switch (selection) {
                  case 'DEL_ONLY_USER':
                    return onRemoveUser();
                  case 'DEL_USER_AND_ACCESSES':
                    return onRemoveOccurrencesAndUser(occurrenceResponse.occurrences, user.id);
                  default:
                    return Promise.reject('Nenhuma opção selecionada.');
                }
              }
            })
              .then(onSuccess)
              .catch(console.error);
          }
          if (deviceCount > 0 && occurrenceCount > 0) {
            swal({
              ...sweetSettings,
              type: 'warning',
              title: 'Dispositivos e liberações de acesso encontrados',
              text: `Este ${
                this.condo?.customLabels?.resident?.singular || 'condômino'
              } possui dispositivos e liberações de acesso associados a ele. Você pode remover o ${
                this.condo?.customLabels?.resident?.singular || 'condômino'
              } e cancelar suas liberações ou apenas remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'} do ${
                this.condo?.customLabels?.condo?.singular || 'condomínio'
              }.`,
              input: 'radio',
              inputOptions: sweetAlertOptions,
              preConfirm: selection => {
                switch (selection) {
                  case 'DEL_ONLY_USER':
                    return onRemoveUser();
                  case 'DEL_USER_AND_ACCESSES':
                    return onRemoveOccurrencesAndUser(occurrenceResponse.occurrences, user.id);
                  default:
                    return Promise.reject('Nenhuma opção selecionada.');
                }
              }
            })
              .then(onSuccess)
              .catch(console.error);
          }
        },
        error => {
          if (error.originalErrorMessage === 'You must be a gatekeeper to execute this action') {
            swal({
              type: 'error',
              title: `Não foi possivel excluir este ${this.condo?.customLabels?.resident?.singular || 'condômino'}`,
              text: `Este ${
                this.condo?.customLabels?.resident?.singular || 'condômino'
              } possui ocorrências ou dispositivos vinculados e não pode ser apagado por esse usuário, contate o administrador do sistema.`
            });
          }
        }
      );
  }

  inviteUser(condo: Condo) {
    const callback = user => {
      this.filteredUsers.push(user);
      this.totalNumberOfResidents = this.totalNumberOfResidents + 1;
    };
    const initialState = { condo, callback };
    this.modalService.show(ModalCreateUserStepsComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  editUser(user, condo: Condo) {
    const callback = user => {
      this.filteredUsers = this.filteredUsers.map(filteredUser => (filteredUser._id === user._id ? user : filteredUser));
      this.selectedUser = user;
    };
    const initialState = { condo, user, callback };
    this.modalService.show(ModalCreateUserStepsComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  approveUserOnCondo(requester) {
    let text;
    if (this.selectedResidenceRequester.value && this.selectedRoleRequester.value) {
      const condoText = `Deseja realmente aceitar ${requester.firstName} ${requester.lastName} como usuário deste(a) ${this.condo?.customLabels?.condo?.singular} e `;
      const residenceText = `"${this.ROLES_REQUESTER_LABEL[this.selectedRoleRequester.value]}" da(e) ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      } ${this.selectedResidenceRequester.value.identification}?`;
      text = condoText + residenceText;
    } else {
      text = `Deseja realmente aceitar ${requester.firstName} ${requester.lastName} como um usuário sem ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      } neste(a) ${this.condo?.customLabels?.condo?.singular}?`;
    }
    swal({
      type: 'question',
      title: 'Aceitar usuário',
      text,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.approveUser(requester);
      }
    }).then(
      residence => {
        this.onDataChanged.emit();
        this.filteredUsers = this.filteredUsers.filter(filteredUser => filteredUser._id !== requester._id);
        this.requesters = this.requesters.filter(userRequester => userRequester._id !== requester._id);
        this.getSingleResident(this.selectedUser.id);
        swal({
          type: 'success',
          title: (capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino') + ' aceito com sucesso!'
        });
      },
      () => {}
    );
  }

  approveUser(requester) {
    const condoRequest = this.condoRequests.find(request => request.user._id === requester._id);
    this.condoRequests = this.condoRequests.filter(request => request.user._id !== requester._id);
    let observable;
    if (condoRequest.role && this.selectedRoleRequester.value !== condoRequest.role) {
      observable = this.condoRequestService
        .update(this.condo._id, condoRequest._id, {
          role: this.selectedRoleRequester.value,
          residence: this.selectedResidenceRequester.value
        })
        .pipe(switchMap(() => this.condoRequestService.approve(this.condo._id, condoRequest._id).pipe(timeout(10000))))
        .toPromise()
        .catch(err => {
          return Promise.reject(
            err.message ||
              'Não foi possível aceitar o usuário e adicioná-lo em um(a) ' + this.condo?.customLabels?.residence?.singular ||
              'unidade' + '. Tente novamente!'
          );
        });
    } else {
      observable = this.condoRequestService
        .approve(this.condo._id, condoRequest._id)
        .pipe(timeout(10000))
        .toPromise()
        .catch(err => {
          return Promise.reject(
            err.message ||
              'Não foi possível aceitar o usuário e adicioná-lo em um(a) ' + this.condo?.customLabels?.residence?.singular ||
              'unidade' + '. Tente novamente!'
          );
        });
    }
    return observable;
  }

  rejectUserOnCondo(requester) {
    swal({
      type: 'question',
      title: `Rejeitar usuário`,
      text: `Você realmente deseja recusar o usuário ${requester.firstName} ${requester.lastName} a entrar neste(a) ${this.condo?.customLabels?.condo?.singular}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const condoRequest = this.condoRequests.find(request => request.user._id === requester._id);
        return this.condoRequestService
          .reject(this.condo._id, condoRequest._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject('Não foi possível rejeitar o pedido, tente novamente...');
          });
      }
    }).then(
      () => {
        this.onDataChanged.emit();
        this.condoRequests = this.condoRequests.filter(request => request.user._id !== requester._id);
        this.filteredUsers = this.filteredUsers.filter(filteredUser => filteredUser._id !== requester._id);
        this.requesters = this.requesters.filter(userRequester => userRequester._id !== requester._id);
        this.selectedUser = null;
        this.totalNumberOfResidents--;
        swal({
          type: 'success',
          title: 'Pedido rejeitado com sucesso!'
        });
      },
      () => {}
    );
  }

  isResidenceOwner(residence: Residence, user: User) {
    return user.residencesVoter.findIndex(res => res._id == residence._id) > -1;
  }

  isResidenceUser(residence: Residence, user: User) {
    return user.residencesUser.findIndex(res => res._id == residence._id) > -1;
  }

  resetUserPassword(user) {
    const userCondos = [...user.condos, ...user.condosAdmin, ...user.condosOwner, ...user.condosJanitor, ...user.condosGatekeeper];
    const isAdminInAllCondos = userCondos.every(
      condo =>
        this.user.condosAdmin.some(condoadmin => condoadmin._id == condo._id) ||
        this.user.condosOwner.some(condoowner => condoowner._id === condo._id)
    );
    if (!isAdminInAllCondos && userCondos.length > 1) {
      swal({
        type: 'warning',
        text: `Este usuário está presente em mais de um(a) ${this.condo?.customLabels?.condo?.singular} e usa o aplicativo. Entre em contato diretamente com o usuário ou com o suporte eCondos para alterar a senha deste usuário`
      });
    } else {
      swal({
        type: 'question',
        title: 'Resetar senha',
        text: `Gostaria realmente de resetar a senha do usuário ${user.firstName} ${user.lastName}?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.userService
            .resetPassword(user._id)
            .pipe(timeout(10000))
            .toPromise()
            .catch(() => Promise.reject('Não foi possível resetar a senha deste usuário.'));
        }
      }).then(
        res => {
          swal({
            type: 'success',
            title: 'Senha resetada',
            html: `A nova senha do usuário é <b styles='color: red'>${res.password}</b>`
          });
        },
        () => {
          // Clicked cancel
        }
      );
    }
  }

  revealData(field: string, user) {
    let isFieldAlreadyUnmasked: boolean;

    if (field === 'ids') {
      isFieldAlreadyUnmasked = !user.ids
        .map(id => id.number)
        .toString()
        .includes('*');
    } else if (field === 'birthDate') {
      isFieldAlreadyUnmasked = !user.birthDate.includes('3000');
    } else {
      isFieldAlreadyUnmasked = !user[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    if (field === 'ids') {
      query.$populate = [{ path: 'ids.pictures', select: 'url thumbnail' }];
    }

    const callback = ({ data }) => {
      user[field] = data;
      user.isDataMasked[field] = false;
    };

    this.condoService
      .getCondoResidentUnmaskedField(this.condo._id, query, this.selectedUser._id, field)
      .pipe(timeout(10000))
      .subscribe(callback);
  }

  initiateIntercomCall(user: User): void {
    this.callService.call({
      callee: {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        picture: user.picture
      }
    });
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: {},
      callback: ({ query, filters }) => {
        this.countActiveFilters(filters);
        this.filters = filters;
        this.customQuery = query;
        this.downloadData(0, false, false);
      }
    };

    this.modalService.show(ModalFilterComponent, {
      initialState,
      class: 'modal-md'
    });
  }

  countActiveFilters(filters: EcondosFilter[]) {
    this.numberOfActiveFilters = filters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }

  clearFilters() {
    this.filters = this.filters.map(values => {
      return {
        ...values,
        value: '',
        valueLabel: ''
      };
    });
    this.customQuery = null;
    this.numberOfActiveFilters = 0;
    this.downloadData();
  }

  removeIndividualFilter({ index }) {
    this.filters[index].value = '';
    this.filters[index].valueLabel = '';

    delete this.customQuery[this.filters[index].name];

    this.numberOfActiveFilters--;
    this.downloadData(0, false, false);
  }

  askToRemoveAllResidents() {
    const unsubscribe: Subject<void> = new Subject();
    const text = `Você realmente deseja excluir TODOS ${
      this.condo?.customLabels?.resident?.plural?.toUpperCase() || 'MORADORES'
    }? Digite "excluir tudo" no campo abaixo para confirmar sua ação. Lembre-se que esta ação não pode ser desfeita.`;
    swal({
      type: 'warning',
      title: 'Excluir todos ' + this.condo?.customLabels?.resident?.plural || 'moradores',
      text,
      showCancelButton: true,
      input: 'text',
      inputPlaceholder: 'excluir tudo',
      confirmButtonText: 'Excluir ' + this.condo?.customLabels?.resident?.plural || 'moradores',
      confirmButtonColor: '#f53d3d',
      cancelButtonClass: 'btn-outline-danger',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async input => {
        input = (input || '').toString().trim().toLowerCase();
        if (!input || input !== 'excluir tudo') {
          return Promise.reject(`Digite "excluir tudo" para confirmar sua ação`);
        } else {
          const users = await this.getResidentsRecursively();
          const onlyUsers = users.filter(u => u.isUserOnCondo(this.condo._id));
          const results = [];
          const requests = onlyUsers.map(user => {
            return this.condoService.removeUserFromCondo(this.condo._id, user._id, true).pipe(
              timeout(10000),
              tap(() => {
                results.push({ [user._id]: true });
                swal.getTitle().textContent = `Processando: ${results.length}/${requests.length}`;
              }),
              catchError(() => {
                results.push({ [user._id]: false });
                return of(null);
              })
            );
          });
          await from(requests).pipe(mergeAll(5), takeUntil(unsubscribe)).toPromise();
          return Promise.resolve(results);
        }
      }
    })
      .then(() => {
        this.selectedUser = null;
        this.downloadData(0, true, true);
        swal({
          type: 'success',
          title: `${capitalize(this.condo?.customLabels?.resident?.plural) || 'moradores'} excluídos(as) com sucesso!`
        });
      })
      .catch(() => {
        this.selectedUser = null;
        this.downloadData(0, true, true);
        unsubscribe.next(null);
        unsubscribe.complete();
      });
  }

  async getResidentsRecursively(page = 0, size = 100) {
    const users = await this.condoService
      .getCondoResidents(this.condo._id, {
        $select: '_id condos',
        $limit: size,
        $page: page
      })
      .pipe(map(res => res.users))
      .toPromise();
    if (users.length < size) {
      return users;
    } else {
      return users.concat(...(await this.getResidentsRecursively(++page, size)));
    }
  }
  isRequester(condo: Condo) {
    if (!!this.selectedUser) {
      return this.condoRequests.some(request => {
        return (request?.condo?._id || request.condo) === condo._id && (request?.user?._id || request?.user) === this.selectedUser._id;
      });
    }
    return false;
  }

  askToConvertToDependent(user: User) {
    const residentLabel = this.condo?.customLabels?.resident?.singular || 'morador';
    const dependentLabel = this.condo?.customLabels?.dependent?.singular || 'dependente';
    const condoLabel = this.condo?.customLabels?.condo?.singular || 'condominio';
    const html = `Você realmente deseja converter ${residentLabel} em ${dependentLabel}? O sistema irá remover ${residentLabel} do ${condoLabel} e criá-lo novamente como ${dependentLabel}.<br>
            Isto significa que ${residentLabel} será convertido em ${dependentLabel}, o que pode impactar em suas ações,
            como liberação temporária de visitantes, ocorrências, encomendas, dispositivos e outros vínculos.`;

    const convertDependent$ = this.condoService.convertUserToDependent(this.condo._id, user._id).pipe(
      switchMap(() => this.condoService.removeUserFromCondo(this.condo._id, user._id)),
      tap(() => {
        this.filteredUsers = this.filteredUsers.filter(user => user._id !== this.selectedUser._id);
        this.totalNumberOfResidents = this.totalNumberOfResidents - 1;
        this.selectedUser = null;
        this.onDataChanged.emit();
      })
    );

    swal({
      type: 'info',
      title: `Converter em ${dependentLabel}`,
      html,
      confirmButtonText: 'Confirmo e aceito os riscos',
      confirmButtonColor: '#32DB64',
      showCancelButton: true,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () =>
        lastValueFrom(convertDependent$).catch(() =>
          Promise.reject(`Não foi possível converter ${residentLabel} em ${dependentLabel}, tente novamente...`)
        )
    })
      .then(() => {
        swal({
          type: 'success',
          title: `${capitalize(this.condo?.customLabels?.resident?.singular || 'condômino')} convertido para ${this.condo?.customLabels?.dependent?.singular || 'dependente'} com sucesso!`
        }).catch(console.error);
      })
      .catch(noop);
  }
}
