import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Service
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';

// Model
import { CondoVehicle } from '../model/condo.vehicle';
import { map } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { downloadDataInChunks } from '@api/utils';

@Injectable()
export class VehicleService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /**
   * @deprecated Use getVehicles instead.
   */
  getVehiclesFromCondo(condoId: string, queryString?: string): Observable<Array<CondoVehicle>> {
    return this.http
      .get(this.endPoint + condoId + '/vehicles/' + (queryString || ''))
      .pipe(map((vehicles: any) => vehicles.map(u => new CondoVehicle(u))));
  }

  getVehicles(condoId: string, params: EcondosQuery): Observable<{ count: number; vehicles: CondoVehicle[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/vehicles`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          if (params.$count) {
            return {
              count: res.body,
              vehicles: []
            };
          }

          return {
            count: res.headers.get('count'),
            vehicles: res.body.map(vehicle => new CondoVehicle(vehicle))
          };
        })
      );
  }

  createVehicle(condoId: string, vehicle: any) {
    return this.http.post(this.endPoint + condoId + '/vehicles', vehicle);
  }

  updateVehicle(condoId: string, vehicleId: string, vehicle: any) {
    return this.http.put(this.endPoint + condoId + '/vehicles/' + vehicleId, vehicle);
  }

  deleteVehicleFromCondo(condoId: string, vehicleId: string) {
    return this.http.delete(this.endPoint + condoId + '/vehicles/' + vehicleId);
  }

  updateAddToALPR(condoId: string, vehicleId: string, serialNumbers: string[]) {
    return this.http.put(`${this.endPoint}${condoId}/vehicles/${vehicleId}/alpr/add`, { serialNumbers });
  }

  updateRemoveFromALPR(condoId: string, vehicleId: string, serialNumbers: string[]) {
    return this.http.put(`${this.endPoint}${condoId}/vehicles/${vehicleId}/alpr/delete`, { serialNumbers });
  }

  clearSerialNumbersOfVehicles(condoId: string) {
    return this.http.put(`${this.endPoint}${condoId}/vehicles/alpr/reset`, {});
  }

  getVehiclesInChunks(condoId: string, params: EcondosQuery = {}) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/vehicles`, params, { model: CondoVehicle });
  }
}
