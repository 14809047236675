import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Pet } from '../model/pet';
import { downloadDataInChunks } from '@api/utils';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';

@Injectable()
export class PetService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /**
   * @deprecated Use getPets instead.
   */
  get(condoId: string, params: Array<{ [key: string]: string }> = []): Observable<{ count: number; pets: Pet[] }> {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/pets`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            pets: res.body.map(p => new Pet(p))
          };
        })
      );
  }

  getPets(condoId: string, params: EcondosQuery): Observable<{ count: number; pets: Pet[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/pets`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          pets: res.body.map(pet => new Pet(pet))
        }))
      );
  }

  create(condoId: string, pet: Pet) {
    return this.http.post(`${this.endPoint}${condoId}/pets`, pet);
  }

  update(condoId: string, petId: string, pet: Pet) {
    return this.http.put(`${this.endPoint}${condoId}/pets/${petId}`, pet);
  }

  delete(condoId: string, petId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/pets/${petId}`);
  }

  getById(condoId: string, petId: string): Observable<Pet> {
    return this.http.get(`${this.endPoint}${condoId}/pets/${petId}`).pipe(map(v => new Pet(v)));
  }

  getPetsReport(condoId: string, query: EcondosQuery) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/pets`, query, { model: Pet });
  }
}
