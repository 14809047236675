import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../../services/http.service';
import { Note } from '@api/model/note';
import { Observable } from 'rxjs';
import { ConstantService } from '../../services/constant.service';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { map } from 'rxjs/operators';
import { Deleted } from '@api/model/deleted';

type DeletedNote = Note & Deleted;

@Injectable({ providedIn: 'root' })
export class NoteService {
  private readonly endpoint: string;

  constructor(protected http: HttpService, private constantService: ConstantService) {
    this.endpoint = `${this.constantService.getEndpoint()}condos/`;
  }

  create(condoId: string, note: Note) {
    return this.http.post(`${this.endpoint}${condoId}/notes/`, note);
  }

  update(condoId: string, noteId: string, note: Note) {
    return this.http.put(`${this.endpoint}${condoId}/notes/${noteId}`, note);
  }

  getDeleted(condoId: string, params: EcondosQuery): Observable<{ count: number; notes: DeletedNote[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endpoint}${condoId}/notes/deleted`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          notes: res.body as DeletedNote[]
        }))
      );
  }

  getById(condoId: string, noteId: string, query: EcondosQuery = {}): Observable<Note | Error> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endpoint}${condoId}/notes/${noteId}`, options);
  }

  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; data: Note[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endpoint}${condoId}/notes/`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        data: res.body
      }))
    );
  }

  delete(condoId: string, noteId: string) {
    return this.http.delete(`${this.endpoint}${condoId}/notes/${noteId}`);
  }

  restore(condoId: string, noteId: string) {
    return this.http.put(`${this.endpoint}${condoId}/notes/${noteId}/restore`, {});
  }
}
