import { Component, OnInit } from '@angular/core';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from '@api/model/user';

@Component({
  selector: 'app-modal-gate-delivery-config',
  templateUrl: 'modal-gate-delivery-config.component.html',
  styleUrls: ['modal-gate-delivery-config.component.scss']
})
export class ModalGateDeliveryConfigComponent implements OnInit {
  condo: Condo;
  user: User;
  status: Status = new Status();
  form: UntypedFormGroup;
  deliveryDefaultMessage: AbstractControl;

  constructor(private formBuilder: UntypedFormBuilder, public bsModalRef: BsModalRef, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    const description = localStorage.getItem(`econdos:${this.condo._id}:deliveryDefaultMessage`) || '';
    this.form = this.formBuilder.group({
      deliveryDefaultMessage: [description, Validators.minLength(3)]
    });
    this.deliveryDefaultMessage = this.form.get('deliveryDefaultMessage');
  }

  storeDefaultMessage(message) {
    const storeKey = `econdos:${this.condo._id}:deliveryDefaultMessage`;
    if (message) {
      localStorage.setItem(storeKey, message);
    } else {
      localStorage.removeItem(storeKey);
    }
  }

  submit() {
    if (this.form.valid) {
      const deliveryDefaultMessage = this.deliveryDefaultMessage.value.trim();
      this.storeDefaultMessage(deliveryDefaultMessage);
      this.toastr.success('Texto padrão atualizado com sucesso');
      this.closeModal();
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.error('Formulário inválido');
    }
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
