import { Injectable } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import ControlId from '@econdos/econdos-control-id';
import { defer, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ControlIdService {
  // Variável utilizada como Singleton para instâncias da classe ControlID
  private cidActuators = {};

  constructor() {}

  clearDeviceCache(actuatorId?: string) {
    if (actuatorId) {
      delete this.cidActuators[actuatorId];
    } else {
      this.cidActuators = {};
    }
  }

  public getCids(actuators: Actuator | Actuator[]) {
    if (!Array.isArray(actuators)) {
      actuators = [actuators];
    }
    return actuators.map(a => this.getCid(a));
  }

  public getCid(actuator: Actuator) {
    if (!this.cidActuators[actuator._id]) {
      this.cidActuators[actuator._id] = new ControlId(actuator);
    }
    return this.cidActuators[actuator._id];
  }

  async registerDevice(condoActuators: Actuator[], device: Device, previousDevice: Device | null = null) {
    const userId = device?.owner?.user?._id;
    const visitorId = device?.owner?.condoContact?._id;
    const userName = device?.owner?.userName;
    const registration = (userId && `user:${userId}`) || (visitorId && `visitor:${visitorId}`) || (userName && `userName:${userName}`);
    if (!registration) {
      throw new Error('Empty user and visitor');
    }
    const name = device?.owner?.user?.fullName || device?.owner?.condoContact?.fullName || device?.owner?.userName;
    const pictureData =
      device.owner?.picture?.url ||
      device.owner?.picture?.thumbnail ||
      device.owner?.user?.picture?.url ||
      device.owner?.user?.picture?.thumbnail ||
      device.owner?.condoContact?.picture?.url ||
      device.owner?.condoContact?.picture?.thumbnail;
    const serial = device.serial;
    const serialType = device.hardwareAttributes?.serial || '';
    const actuators = [];
    const actuatorsToDelete = [];
    const deviceActuatorsIds = device.actuators.map(a => a._id || a);
    /* Filtra os acionadores se for um dispositivo facial, garantindo que não serão enviadas requisições da facial para outros equipamentos */
    if (device.type === DEVICE_TYPES.FACIAL) {
      condoActuators = condoActuators.filter(actuator => actuator.type === 'iDFace');
    }

    for (const actuator of condoActuators) {
      if (deviceActuatorsIds.includes(actuator._id)) {
        actuators.push(actuator);
      } else {
        actuatorsToDelete.push(actuator);
      }
    }

    if (actuatorsToDelete.length) {
      await this.deleteDeviceFromRegistration(device, actuatorsToDelete)
    }

    // const cidsToDelete = this.getCids(actuatorsToDelete);
    // if (device.actuatorAttributes?.length) {
    //   await Promise.all(
    //     cidsToDelete.map(cid => {
    //       const actuatorId = cid.actuatorId;
    //       const attributes = device.actuatorAttributes.find(at => at.actuator === actuatorId);
    //       if (attributes) {
    //         if (attributes.card_id) {
    //           return cid.removeCardById(attributes.card_id);
    //         }
    //         if (attributes.template_id) {
    //           return cid.removeTemplateById(attributes.template_id);
    //         }
    //         if (device.type === 'FACIAL') {
    //           return cid.removeFacialByUserId(attributes.user_id);
    //         }
    //       }
    //     })
    //   );
    // }

    const cids = this.getCids(actuators);

    /*
     * Check exist previous device. If it exists, it must be removed from "control id"
     * Device that remove: Cards and templates. Because they have an id (card_id or template_id) saved in "econdos"
     */
    if (previousDevice) {
      await this.deleteDeviceFromRegistration(previousDevice)
    }

    // if (previousDevice?.actuatorAttributes?.length) {
    //   const actuatorAttributesMap = previousDevice.actuatorAttributes.reduce((acc, curr) => {
    //     acc[curr.actuator] = curr;
    //     return acc;
    //   }, {});
    //   const cidsToRemovePreviousDevicePromises = cids.map(cid => {
    //     const actuatorAttribute = actuatorAttributesMap[cid.actuatorId];
    //     if (actuatorAttribute) {
    //       if (actuatorAttribute.card_id) {
    //         return cid.removeCardById(actuatorAttribute.card_id);
    //       }
    //       if (actuatorAttribute.template_id) {
    //         return cid.removeTemplateById(actuatorAttribute.template_id);
    //       }
    //     }
    //   });
    //   try {
    //     await Promise.all(cidsToRemovePreviousDevicePromises);
    //   } catch (e) {
    //     await this.deleteDeviceFromRegistration(previousDevice);
    //   }
    // }

    let result;
    switch (device.type) {
      case 'TP':
      case 'SN':
      case 'CARD': {
        if (!serial) {
          throw new Error('Empty serial');
        }

        result = await Promise.all(cids.map(cid => cid.saveCard(registration, serial, serialType, name)));
        device.actuatorAttributes = result.map(({ id, user_id, actuatorId }) => {
          return {
            actuator: actuatorId,
            card_id: id,
            user_id
          };
        });
        break;
      }
      case 'BM': {
        if (!device.template) {
          throw new Error('Empty template');
        }
        result = await Promise.all(
          cids.map(cid => cid.saveTemplate(name, registration, device.template, device.panic, device.actuatorAttributes, pictureData))
        );
        device.actuatorAttributes = result.map(({ id, user_id, actuatorId }) => {
          return {
            actuator: actuatorId,
            template_id: id,
            user_id
          };
        });
        break;
      }
      case 'FACIAL': {
        if (!pictureData) {
          throw new Error('Foto não encontrada');
        }
        const picture = {
          data: pictureData
        };
        const userData = {
          name,
          registration,
          picture,
          ...(device.validFrom && { validFrom: device.validFrom }),
          ...(device.validUntil && { validUntil: device.validUntil }),
          isVisitor: !!visitorId
        };
        result = await Promise.all(cids.map(cid => cid.saveFacial(userData)));
        if (result.some(res => !res.success)) {
          const errors = result.filter(res => !res.success).map(res => `Acionador ${res.actuatorName || ''}: ${res.reason}`);
          throw new Error(errors.join(', '));
        }
        device.actuatorAttributes = result.map(({ id, user_id, actuatorId }) => {
          return {
            actuator: actuatorId,
            user_id
          };
        });
      }
    }
    // InternalIds sao usados para identificar o usuario no evento de log de acesso no backend
    device.internalIds = result.map(r => `${r.actuatorId}:${r.user_id}`);
    return device;
  }

  async deleteDevice(device: Device, params: { actuators?: Actuator[] } = {}) {
    const actuators = params.actuators || device.actuators;

    // if (device.actuatorAttributes && device.actuatorAttributes.length > 0) {
    //   const cids = this.getCids(actuators);
    //   await Promise.all(
    //     cids.map(cid => {
    //       const actuatorId = cid.actuatorId;
    //       const { card_id, template_id, user_id } = device.actuatorAttributes.find(at => at.actuator === actuatorId) || {};
    //       if (card_id) {
    //         return cid.removeCardById(card_id);
    //       }
    //       if (template_id) {
    //         return cid.removeTemplateById(template_id);
    //       }
    //       if (device.type === 'FACIAL') {
    //         return cid.removeFacialByUserId(user_id);
    //       }
    //     })
    //   );
    // }

    return this.deleteDeviceFromRegistration(device, actuators);
  }

  private async deleteDeviceFromRegistration(device: Device, actuatorsToDelete?: Actuator[]): Promise<void> {
    // Try to remove credential by user registration ensuring user id
    const userId = device?.owner?.user?._id;
    const visitorId = device?.owner?.condoContact?._id;
    const userName = device?.owner?.userName;
    const registration = (userId && `user:${userId}`) || (visitorId && `visitor:${visitorId}`) || (userName && `userName:${userName}`);
    if (!registration) {
      throw new Error('Empty user and visitor');
    }

    const actuators = actuatorsToDelete || device.actuators;
    const cids = this.getCids(actuators);
    const cidsToRemovePreviousDeviceCredentialPromises = cids.map(cid => {
      return cid.removeUserCredential({
        registration,
        type: device.type,
        serial: device.serial,
        template: device.template,
        serialType: device.hardwareAttributes.serial
      });
    });
    await Promise.all(cidsToRemovePreviousDeviceCredentialPromises);
  }

  async registerFingerprintRemotely(actuator: Actuator) {
    const cid = this.getCid(actuator);
    const resp = await cid.readTemplate();
    return resp.template;
  }

  trigger(actuator: Actuator, params: { door?: number; relayTime?: number; direction?: 'clockwise' | 'anticlosewise' | 'both' } = {}) {
    const cid = this.getCid(actuator);
    return from(cid.openDoor(params));
  }

  checkConnection(actuator: Actuator) {
    const cid = this.getCid(actuator);
    return from(cid.login()).pipe(
      timeout(10000),
      map(() => ({ _id: actuator._id, status: true })),
      catchError(() => of({ _id: actuator._id, status: false }))
    );
  }

  collectCard(actuator: Actuator) {
    const cid = this.getCid(actuator);
    return from(cid.readCard()).pipe(map((res: any) => res.card_value));
  }

  collectFace(actuator: Actuator): Observable<{ image: string }> {
    const cid = this.getCid(actuator);
    return from(cid.readFace()) as Observable<{ image: string }>;
  }

  cancelRemoteEnroll(actuator: Actuator) {
    const cid = this.getCid(actuator);
    return from(cid.cancelRemoteEnroll());
  }

  findAndUpdateUserDevices(condoActuators: Actuator[], device: Device) {
    const userId = device.owner?.user?._id || '';
    const visitorId = device.owner?.user?._id || '';
    const userName = device.owner?.userName || '';
    const registration = (userId && `user:${userId}`) || (visitorId && `visitor:${visitorId}`) || (userName && `userName:${userName}`);

    const name = device?.owner?.user?.fullName || device?.owner?.userName || device?.owner?.condoContact?.fullName;
    const pictureUrl = device.owner?.picture?.url || device.owner?.picture?.thumbnail;
    const serialNumber = device.serial;
    const serialType = device.hardwareAttributes?.serial || '';

    const deviceType = device.type;
    const panic = device.panic || false;
    const template = device.template || '';

    const deviceActuatorKeys = device.actuators?.reduce((acc, curr) => {
      acc[curr._id] = curr;
      return acc;
    }, {});
    const actuators = condoActuators.filter(condoActuator => deviceActuatorKeys[condoActuator._id]);

    if (!actuators || !actuators.length) {
      throw new Error('Actuators not found');
    }

    const requests = [];
    for (const actuator of actuators) {
      let templateId;
      if (device.actuatorAttributes && device.actuatorAttributes.length) {
        if (device.actuatorAttributes.some(attr => attr?.template_id)) {
          const templateData = device.actuatorAttributes?.find(attr => attr?.actuator === actuator?._id && attr?.template_id);
          templateId = templateData && templateData.template_id;
        }
      }
      let params: any = {
        registration,
        deviceType,
        name,
        pictureUrl,
        panic
      };
      if ([DEVICE_TYPES.CARD, DEVICE_TYPES.TP].includes(deviceType)) {
        params = { ...params, serialType, serialNumber };
      }
      if ([DEVICE_TYPES.BM].includes(deviceType)) {
        params = { ...params, template, templateId };
      }
      const request$ = from(defer(() => this.getCid(actuator)?.findAndUpdateUserDevices(params))).pipe(
        map((v: any) => ({ ...v, success: true })),
        catchError(err => of({ actuatorId: actuator?._id, success: false, message: err?.message }))
      );
      requests.push(request$);
    }

    return forkJoin(...requests);
  }

  destroyAllObjects(actuator) {
    const cid = this.getCid(actuator);
    return from(cid.destroyAllObjects());
  }

  getBackup(actuator, withoutPicture = false) {
    const cid = this.getCid(actuator);
    return from(cid.getBackup(withoutPicture));
  }
  setDateTime(actuator, dateIsoString?: string) {
    const cid = this.getCid(actuator);
    return from(cid.setDateTime(dateIsoString)).pipe(
      map(() => ({ success: true })),
      catchError(() => of({ success: false }))
    );
  }

  reboot(actuator: Actuator) {
    const cid = this.getCid(actuator);
    return from(cid.reboot()).pipe(
      timeout(10000),
      map(() => ({ _id: actuator._id, success: true })),
      catchError(() => of({ _id: actuator._id, success: false }))
    );
  }

  updateFacialById() {}
}
