import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { timeout } from 'rxjs/operators';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-modal-select-intelbras-incontrol-access-point',
  templateUrl: 'modal-select-intelbras-incontrol-access-point.component.html',
  styleUrls: ['modal-select-intelbras-incontrol-access-point.component.scss']
})
export class ModalSelectIntelbrasIncontrolAccessPointComponent implements OnInit {
  dataStatus;
  STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  };

  callbacks: { success?: (ap) => void };

  accessPoints: any[] = [];

  intelbrasActuatorIds = {};

  itemsPerPage = 10;
  p = 1;

  constructor(
    public bsModalRef: BsModalRef,
    private actuatorService: ActuatorService,
    private intelbrasService: IntelbrasIncontrolService,
    private utilService: UtilService
  ) {}

  async ngOnInit() {
    this.getIntelBrasActuators();
    this.getAccessPoints();
  }

  getAccessPoints() {
    this.dataStatus = this.STATUS.LOADING;
    this.intelbrasService
      .getAccessPoints()
      .pipe(timeout(10000))
      .subscribe(
        (aps: any[]) => {
          this.accessPoints = aps?.map(({ dispositivo, num_porta }) => ({
            clientId: dispositivo.id,
            name: dispositivo.nome,
            output: num_porta,
            host: dispositivo.ip,
            port: dispositivo.porta,
            type: dispositivo.modelo_dispositivo?.nome_modelo,
            password: dispositivo.senha
          }));
          this.dataStatus = this.STATUS.SUCCESS;
        },
        error => {
          this.accessPoints = [];
          this.dataStatus = this.STATUS.ERROR;
        }
      );
  }

  selectAccessPoint(ap) {
    if (ap) {
      if (this.callbacks && this.callbacks.success) {
        this.callbacks.success(ap);
      }
      this.bsModalRef.hide();
    }
  }

  getIntelBrasActuators() {
    const condoId = this.utilService.getLocalCondo()?._id;
    this.actuatorService
      .getActuators(condoId, { $select: 'clientId output', hardware: 'INTELBRAS' })
      .pipe(timeout(10000))
      .subscribe(
        ({ actuators }) => {
          this.intelbrasActuatorIds = actuators?.reduce((acc, curr) => {
            acc[curr.clientId] = { id: curr.clientId, output: curr.output };
            return acc;
          }, {});
        },
        () => {
          this.intelbrasActuatorIds = {};
        }
      );
  }
}
