import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { NavbarService } from '../navbar/navbar.service';
import { UserService } from '../../api/service/user.service';
import { AuthService } from '../../services/auth.service';

import swal from 'sweetalert2';
import { debounceTime, takeUntil, timeout, timeoutWith } from 'rxjs/operators';
import { ThemeService } from '../../theme';
import { Subject } from 'rxjs';

@Component({
  selector: 'reset-password-component',
  templateUrl: './reset.password.html',
  styleUrls: ['./reset.password.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public form;
  public password: AbstractControl;
  public passwordConfirm: AbstractControl;
  public isSubmitting: boolean;

  public token: string;

  showPassword = false;
  showPasswordConfirm = false;
  logoUrl = '';
  private _destroy$ = new Subject();

  constructor(
    private navbarService: NavbarService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private themeService: ThemeService,
    private authService: AuthService
  ) {
    this.form = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      passwordConfirm: ['']
    });
    this.password = this.form.controls['password'];
    this.passwordConfirm = this.form.controls['passwordConfirm'];

    this.password.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.passwordConfirm.updateValueAndValidity();
    });

    this.passwordConfirm.setValidators(
      Validators.compose([
        Validators.required,
        (c: UntypedFormControl) => {
          if (c.value == this.password.value) {
            return null;
          }
          return { validatorPassword: { valid: false } };
        }
      ])
    );

    this.token = this.route.snapshot.params['activationKey'];

    if (!(this.token && this.token.length)) {
      this.goToLoginPage();
    }

    this.themeService
      .getActiveTheme()
      .pipe(takeUntil(this._destroy$))
      .subscribe(theme => {
        if (theme && theme.logo) {
          this.logoUrl = theme.logo;
        } else {
          this.logoUrl = 'assets/img/logo.png';
        }
      });
  }

  ngOnInit(): void {
    this.navbarService.hideNavBar();
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.password.markAsTouched();
      this.form.passwordConfirm.markAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.userService
      .changeUserPassword(this.password.value, this.token)
      .pipe(timeout(10000))
      .subscribe(
        data => {
          this.authService.unregisterSession().then(() => {
            swal({
              type: 'success',
              title: 'Senha alterada',
              text: 'Sua senha foi alterada com sucesso. Faça login utilizando-a.'
            });
            this.goToLoginPage();
          });
        },
        err => {
          this.isSubmitting = false;
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Problema ao alterar sua senha.\nTente novamente.'
          });
        }
      );
  }

  goToLoginPage() {
    this.router.navigate(['/']);
  }
}
