import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// Model
import { User } from '../../api/model/user';
import swal from 'sweetalert2';
// Service
import { UserService } from '../../api/service/user.service';
import { UtilService } from '../../services/util.service';
import { FileService } from '../../api/service/file.service';

import * as moment from 'moment';
import { ModalChangePasswordComponent } from './change.password/change.password.modal';
import { ContactID } from '../../api/model/contact/contact.id';
import { debounceTime, distinctUntilChanged, filter, mergeMap, timeout } from 'rxjs/operators';
import { MARITAL_STATUS_LABEL } from '../../api/model/constants';
import { iif, of, Subscription } from 'rxjs';
import { formatCnpj, formatCpf } from '@api/util/formatters';
import { cnpjValidator } from '@api/util/validators';

@Component({
  templateUrl: './user.html',
  styleUrls: ['./user.scss']
})
export class UserPage implements OnInit, OnDestroy {
  @ViewChild(ModalChangePasswordComponent, { static: true }) changePasswordModal: ModalChangePasswordComponent;

  MARITAL_STATUS = MARITAL_STATUS_LABEL;

  userForm: UntypedFormGroup;
  firstName: AbstractControl;
  lastName: AbstractControl;
  birthDate: AbstractControl;
  phone: AbstractControl;
  cpf: AbstractControl;
  cnpj: AbstractControl;
  rg: AbstractControl;
  passport: AbstractControl;
  ra: AbstractControl;
  email: AbstractControl;
  maritalStatus: AbstractControl;
  occupation: AbstractControl;
  panicWord: AbstractControl;
  specialNeeds: AbstractControl;
  specialNeedsDetails: AbstractControl;
  picture: AbstractControl;

  user: User;
  isSubmiting;

  maxDate = new Date();

  subscriptions: Subscription = new Subscription();

  condoIsBusiness = false;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private fileService: FileService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condoIsBusiness = this.utilService.getLocalCondo()?.isBusinessCondo() || false;

    let date;
    if (this.user.birthDate) {
      date = moment(this.user?.birthDate).toDate();
    }

    const minAgeValidator = (c: UntypedFormControl) => {
      return c.value && moment().diff(c.value, 'years') >= 18 ? null : { minimumAge: { valid: false } };
    };

    const cpfValidator = (c: UntypedFormControl) => {
      return c.value && this.utilService.validateCpf(c.value) ? null : { validCpf: { valid: false } };
    };

    this.userForm = formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])],
      birthDate: [date || '', Validators.compose([Validators.required, minAgeValidator])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])],
      cpf: [''],
      cnpj: [''],
      rg: [''],
      email: [''],
      passport: [''],
      ra: [''],
      maritalStatus: [''],
      occupation: [''],
      panicWord: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])],
      specialNeeds: [false],
      specialNeedsDetails: [''],
      picture: [null]
    });

    this.firstName = this.userForm.get('firstName');
    this.lastName = this.userForm.get('lastName');
    this.birthDate = this.userForm.get('birthDate');
    this.phone = this.userForm.get('phone');
    this.cpf = this.userForm.get('cpf');
    this.cnpj = this.userForm.get('cnpj');
    this.rg = this.userForm.get('rg');
    this.passport = this.userForm.get('passport');
    this.ra = this.userForm.get('ra');
    this.email = this.userForm.get('email');
    this.maritalStatus = this.userForm.get('maritalStatus');
    this.occupation = this.userForm.get('occupation');
    this.panicWord = this.userForm.get('panicWord');
    this.specialNeeds = this.userForm.get('specialNeeds');
    this.specialNeedsDetails = this.userForm.get('specialNeedsDetails');
    this.picture = this.userForm.get('picture');

    this.subscriptions.add(this.specialNeeds.valueChanges.pipe(filter(v => !v)).subscribe(() => this.specialNeedsDetails.setValue('')));

    this.subscriptions.add(
      this.cpf.valueChanges.subscribe(v => {
        if (v.length) {
          this.cpf.setValidators([cpfValidator]);
        } else {
          this.cpf.clearValidators();
        }
        const formatted = formatCpf(v);
        this.cpf.setValue(formatted, { emitEvent: false });
      })
    );

    this.subscriptions.add(
      this.cnpj.valueChanges.subscribe(v => {
        if (v.length) {
          this.cnpj.setValidators([cnpjValidator]);
        } else {
          this.cnpj.clearValidators();
        }
        const formatted = formatCnpj(v);
        this.cnpj.setValue(formatted, { emitEvent: false });
      })
    );
  }

  ngOnInit() {
    this.picture.setValue(this.user?.picture || null);
    this.firstName.setValue(this.user?.firstName);
    this.lastName.setValue(this.user?.lastName);
    // O birth date é inicializado pelo birthDateOptions através do atributo minDate
    // this.birthDate.setValue(date);
    this.maritalStatus.setValue(this.user?.maritalStatus);
    this.occupation.setValue(this.user?.occupation);
    this.panicWord.setValue(this.user?.panicWord);
    this.specialNeeds.setValue(this.user?.specialNeeds);
    this.specialNeedsDetails.setValue(this.user?.specialNeedsDetails);
    const phone = this.user.phones[0] ? this.user?.phones[0] : '';
    this.phone.setValue(this.formatPhoneToShow(phone));

    if (this.user?.cpf && this.user?.cpf?.number) {
      this.cpf.setValue(this.user.cpf.number);
    }

    if (this.user?.cnpj && this.user?.cnpj?.number) {
      this.cnpj.setValue(this.user.cnpj.number);
    }

    if (this.user?.rg && this.user?.rg?.number) {
      this.rg.setValue(this.user.rg.number);
    }

    if (this.user?.passport && this.user?.passport?.number) {
      this.passport.setValue(this.user.passport.number);
    }

    if (this.user?.ra && this.user?.ra?.number) {
      this.ra.setValue(this.user.ra.number);
    }

    this.email.setValue(this.user?.email);
    this.email.disable();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  formatPhone() {
    let phoneNumber = this.phone.value;
    phoneNumber = this.utilService.formatPhone(phoneNumber);
    this.phone.setValue(phoneNumber);
  }

  formatPhoneToShow(pn) {
    return this.utilService.formatPhoneToShow(pn);
  }

  updateUser(): void {
    if (this.userForm.valid) {
      this.isSubmiting = true;
      this.user.firstName = this.firstName.value;
      this.user.lastName = this.lastName.value;
      this.user.birthDate = this.birthDate.value;
      if (this.maritalStatus.value) {
        this.user.maritalStatus = this.maritalStatus.value;
      }
      this.user.panicWord = this.panicWord.value;
      this.user.occupation = this.occupation.value;
      this.user.specialNeeds = this.specialNeeds.value;
      this.user.specialNeedsDetails = this.specialNeedsDetails.value;
      this.user.phones = this.phone.value ? [this.phone.value.toString().replace(/[^0-9]/g, '')] : []; // Remove todos caracteres não numéricos do telefone
      if (this.picture.value) {
        this.user.picture = this.picture.value || null;
      }

      if (this.cpf.value) {
        this.user.cpf = new ContactID({
          number: this.cpf.value,
          type: ContactID.TYPES.CPF
        });
      }

      if (this.cnpj.value) {
        this.user.cnpj = new ContactID({
          number: this.cnpj.value,
          type: ContactID.TYPES.CNPJ
        });
      }

      if (this.rg.value) {
        this.user.rg = new ContactID({
          number: this.rg.value,
          type: ContactID.TYPES.RG
        });
      }

      if (this.passport.value) {
        this.user.passport = new ContactID({
          number: this.passport.value,
          type: ContactID.TYPES.PASSPORT
        });
      }

      if (this.ra.value) {
        this.user.ra = new ContactID({
          number: this.ra.value,
          type: ContactID.TYPES.RA
        });
      }

      const userData: any = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        birthDate: this.user.birthDate,
        occupation: this.user.occupation,
        panicWord: this.user.panicWord,
        specialNeeds: this.user.specialNeeds,
        specialNeedsDetails: this.user.specialNeedsDetails,
        phones: this.user.phones,
        picture: this.user.picture ? this.user.picture._id : null,
        ids: this.user.ids.map(id => id.createBackObject())
      };

      if (this.maritalStatus.value) {
        userData.maritalStatus = this.user.maritalStatus;
      }

      this.userService
        .updateUser(this.user.id, userData)
        .pipe(timeout(10000))
        .subscribe(
          () => {
            this.utilService.saveLocalUser(this.user);
            this.isSubmiting = false;
            swal({
              type: 'success',
              text: 'Usuário atualizado com sucesso'
            });
          },
          err => {
            this.isSubmiting = false;
            swal({
              type: 'error',
              title: `Ops...`,
              text: 'Não foi possível atualizar o usuário, tente novamente',
              cancelButtonColor: '#f53d3d'
            });
          }
        );
    } else {
      Object.keys(this.userForm.controls).forEach(key => this.userForm.get(key).markAsTouched());
    }
  }

  showChangePassword() {
    this.changePasswordModal.showModal();
  }

  onDateChange(date) {
    this.birthDate.markAsTouched();
  }
}
