import { Component, OnDestroy, OnInit } from '@angular/core';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { NavbarService } from '../../../navbar/navbar.service';
import { Status } from '@api/model/status';
import { UntypedFormBuilder, FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilService } from '../../../../services/util.service';
import { Subject } from 'rxjs';
import { KeyValue } from '@angular/common';
import { User } from '@api/model/user';
import { Actuator } from '@api/model/hardware/actuator';

type ParamConfig = {
  icon: string;
  label: string;
  description: string;
  paramName: string;
};

type ParamsSection = {
  title: string;
  icon: string;
  tag: string;
  params: Record<string, ParamConfig>;
};
interface ActuatorChecked extends Actuator {
  checked: boolean;
  label: string;
}
@Component({
  selector: 'app-modal-event-announcement-board-config',
  templateUrl: 'modal-event-announcement-board-config.component.html',
  styleUrls: ['modal-event-announcement-board-config.component.scss']
})
export class ModalEventAnnouncementBoardConfigComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    textToSpeech: this.formBuilder.group({
      voice: new FormControl<string>(''),
      pitch: new FormControl<number>(1),
      rate: new FormControl<number>(1),
      volume: new FormControl<number>(1)
    }),
    boardConfig: this.formBuilder.group({
      sortOrder: new FormControl<string>('first'),
      displayInColumns: new FormControl<boolean>(false),
      forwardList: new FormControl<boolean>(true)
    })
  });

  public condo: Condo = this.utilService.getLocalCondo();
  public user: User;
  public voices: SpeechSynthesisVoice[] = [];
  selectedVoice: SpeechSynthesisVoice;
  public CONDO_SECTION_PARAMS: Record<string, ParamsSection>;
  public selectedParamsSection: keyof typeof this.CONDO_SECTION_PARAMS = 'boardConfig';

  status: Status = new Status();

  private unsubscribe: Subject<void> = new Subject();
  callback: (any) => void;

  constructor(
    private condoService: CondoService,
    private navbarService: NavbarService,
    private formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private utilService: UtilService
  ) {
    this.user = this.utilService.getLocalUser();
    this.setParamsSection();
  }

  setParamsSection() {
    this.CONDO_SECTION_PARAMS = {
      textToSpeech: {
        title: 'Leitura de texto',
        icon: 'volume-off',
        tag: 'textToSpeech',
        params: {
          voice: {
            icon: 'bullhorn',
            label: 'Leitura do anúncio dos eventos',
            description: ` A voz de leitura que será carregada por padrão ao abrir o quadro de eventos.`,
            paramName: 'voice'
          },
          pitch: {
            icon: 'music',
            label: 'Equalizador de voz de leitura',
            description: ` Controla a equalização da voz de leitura do anúncio dos eventos.`,
            paramName: 'pitch'
          },
          rate: {
            icon: 'angle-double-right',
            label: 'Velocidade da leitura',
            description: ` Controla a velocidade da leitura do anúncio dos eventos.`,
            paramName: 'rate'
          },
          volume: {
            icon: 'volume-up',
            label: 'Controle de volume da leitura',
            description: ` Controla o volume da leitura do anúncio dos eventos.`,
            paramName: 'volume'
          }
        }
      },
      boardConfig: {
        title: 'Configuração do quadro',
        icon: 'cogs',
        tag: 'boardConfig',
        params: {
          sortOrder: {
            icon: 'sort-amount-desc',
            label: 'Ordenação dos eventos',
            description: ` Controla a ordenação dos eventos.`,
            paramName: 'sortOrder'
          },
          displayInColumns: {
            icon: 'columns',
            label: 'Separar quadro em colunas',
            description: `Controla se o quadro irá ser dividido entre colunas dos acionadores ativos.`,
            paramName: 'displayInColumns'
          },
          forwardList: {
            icon: 'files-o',
            label: 'Coluna de encaminhados',
            description: ` Ativa ou desativa a coluna de encaminhados.`,
            paramName: 'forwardList'
          }
        }
      }
    };
  }

  ngOnInit(): void {
    const config = JSON.parse(localStorage.getItem(`econdos-app:${this.condo._id}:${this.user._id}:eventAnnouncementBoard`));
    this.form.patchValue({
      textToSpeech: {
        pitch: config?.textToSpeech?.pitch || 1,
        rate: config?.textToSpeech?.rate || 1,
        volume: config?.textToSpeech?.volume || 1,
        voice: config?.textToSpeech?.voice || this.voices[0] || null
      },
      boardConfig: {
        sortOrder: config?.boardConfig?.sortOrder || 'first',
        displayInColumns: config?.boardConfig?.displayInColumns || false,
        forwardList: config?.boardConfig?.forwardList
      }
    });
    this.selectedVoice = this.voices.find(voice => voice.name === config?.textToSpeech?.voice) || this.voices[0];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  submit() {
    if (this.form.valid) {
      const localStorageValue = localStorage.getItem(`econdos-app:${this.condo._id}:${this.user._id}:eventAnnouncementBoard`);
      let localStorageValueJSON = localStorageValue ? JSON.parse(localStorageValue) : {};
      localStorageValueJSON.textToSpeech = this.form.value.textToSpeech;
      localStorageValueJSON.boardConfig = this.form.value.boardConfig;
      localStorage.setItem(`econdos-app:${this.condo._id}:${this.user._id}:eventAnnouncementBoard`, JSON.stringify(localStorageValueJSON));
    } else {
      this.form.markAllAsTouched();
    }
    this.callback(this.form.value);
    this.bsModalRef.hide();
  }
  selectVoice(voice: SpeechSynthesisVoice) {
    this.selectedVoice = voice;
    this.form.get('textToSpeech').setValue({ ...this.form.get('textToSpeech').value, voice: voice.name });
  }

  sectionSortFunction(akv: KeyValue<string, any>, bkv: KeyValue<string, any>) {
    return akv.value.label.localeCompare(bkv.value.label);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  handleSelectParamsSection(sectionKey: typeof this.selectedParamsSection) {
    this.selectedParamsSection = sectionKey;
  }
}
