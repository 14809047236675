/*
 params: {
 commentsModeration: {
 type: String,
 default: 'CONDO_DEFAULT',
 enum: [ 'CONDO_DEFAULT', 'NONE', 'REQUIRED' ]
 }
 },
 */
import { GateOccurrence } from './occurrence.gate';
import { Occurrence } from '../interface/occurrence';
import { PublicOccurrence } from './occurrence.public';
import { AdviceOccurrence } from './occurrence.advice';
import { VotingOccurrence } from './occurrence.voting';
import { PrivateOccurrence } from './occurrence.private';

export class OccurrenceBuilder {
  static build(occurrence): Occurrence {
    try {
      switch (occurrence.type) {
        case Occurrence.GATE_TYPE:
          return new GateOccurrence(occurrence);
        case Occurrence.PUBLIC_TYPE:
          return new PublicOccurrence(occurrence);
        case Occurrence.ADVICE_TYPE:
          return new AdviceOccurrence(occurrence);
        case Occurrence.VOTING_TYPE:
          return new VotingOccurrence(occurrence);
        case Occurrence.PRIVATE_TYPE:
          return new PrivateOccurrence(occurrence);
        default:
          return null;
      }
    } catch (error) {
      console.log('Occurrence build error');
      console.log('Error = ', error);
      console.log('Occurrence = ', occurrence);
      return null;
    }
  }
}
