import { Injectable } from '@angular/core';
import { ElectronService, EVENT_NAMES } from './electron.service';

@Injectable({ providedIn: 'root' })
export class PrintContentService {
  constructor(private electronService: ElectronService) {}

  print(contents: any, config: { height: number; width: number; title?: string } = { height: 450, width: 700, title: '' }) {
    if (this.electronService.isElectron()) {
      this.electronService.emit(EVENT_NAMES.PRINT_WINDOW, { contents, config });
    } else {
      const popupWin = window.open('', '_blank', `top=0,left=0,width=${config.width},height=${config.height}`);
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
        <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
        <link rel="stylesheet" type="text/css" href="../assets/css/qrcode.css" />
        <link rel="stylesheet" type="text/css" href="../assets/css/reports.css" />
        <script src="https://kit.fontawesome.com/bd7dc04cf7.js" crossorigin="anonymous"></script>
        </head>
        <body onload="window.print(); setTimeout(()=>{ window.close(); }, 0)">
          ${contents}
        </body>
      </html>`);
      if (config.title) {
        popupWin.document.title = config.title;
      }
      popupWin.document.close();
    }
  }
}
