import { Component, OnInit, ViewChild } from '@angular/core';

import swal from 'sweetalert2';
import { OccurrenceService } from '../../../api/service/occurrence.service';
import { OccurrenceBuilder } from '../../../api/model/occurrence/occurrence.builder';
import { Occurrence } from '../../../api/model/interface/occurrence';
import { UntypedFormBuilder } from '@angular/forms';
import { Residence } from '../../../api/model/interface/residence';
import { OccurrenceCreator } from '../occurrence.creator';
import { ResidenceBuilder } from '../../../api/model/residence/residence.builder';
import { OccurrenceFileUploader } from '../occurrence.creator.file.list/files.scroll';

import * as moment from 'moment';
import { User } from '../../../api/model/user';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'private-creator',
  inputs: ['user', 'condo'],
  outputs: ['onOccurrenceCreated', 'onOccurrenceUpdated'],
  templateUrl: 'private.occurrence.creator.html'
})
export class PrivateCreatorComponent extends OccurrenceCreator implements OnInit {
  @ViewChild(OccurrenceFileUploader) occurrenceFileUploader: OccurrenceFileUploader;

  public selectedResidence;

  residences: Array<Residence> = new Array<Residence>();
  users: Array<User> = new Array<User>();

  constructor(formBuilder: UntypedFormBuilder, private occurrenceService: OccurrenceService) {
    super(formBuilder);
  }

  ngOnInit() {
    this.initSelectedResidence();
  }

  initSelectedResidence() {
    this.selectedResidence = this.user.getResidences()[0]._id || this.user.getResidences()[0];
  }

  initializeForm() {
    super.initializeForm();
    this.residences = [];
    this.users = [];
  }

  hideOccurrenceCreator() {
    if (this.isEditingOccurrence) {
      this.initSelectedResidence();
    }
    super.hideOccurrenceCreator();
  }

  editOccurrence(occurrence) {
    super.editOccurrence(occurrence);
    this.selectedResidence = occurrence.residence._id ? occurrence.residence._id : occurrence.residence;
    if (occurrence.viewers) {
      this.residences = occurrence.viewers.residences ? occurrence.viewers.residences.map(res => ResidenceBuilder.build(res)) : [];
      this.users = occurrence.viewers.users ? occurrence.viewers.users.map(user => new User(user)) : [];
    }
  }

  addResidence(residence: Residence) {
    if (this.residences.some(res => res.id == residence.id)) {
      return;
    }
    this.residences.push(residence);
  }

  setResidences(residences: Array<Residence>) {
    this.residences = residences;
  }

  addUser(selectedUser: User) {
    if (this.users.some(user => user.id == selectedUser.id)) {
      return;
    }
    this.users.push(selectedUser);
  }

  setUsers(users: Array<User>) {
    this.users = users;
  }

  removeResidence(residence, index) {
    this.residences.splice(index, 1);
  }

  removeUser(user, index) {
    this.users.splice(index, 1);
  }

  canSubmit() {
    if (!this.occurrenceForm.valid) {
      this.title.markAsTouched();
      this.description.markAsTouched();
      return false;
    }
    return true;
  }

  buildOccurrence() {
    const occurrence: any = {
      title: this.title.value,
      description: this.description.value,
      type: Occurrence.PRIVATE_TYPE,
      pictures: this.attachments.map(att => att._id),
      residence: this.selectedResidence._id ? this.selectedResidence._id : this.selectedResidence,
      viewers: {
        residences: this.residences.map(res => res.id),
        users: this.users.map(user => user.id)
      }
    };
    return occurrence;
  }

  updateOccurrence() {
    if (!this.canSubmit() || !this.editingOccurrence) {
      return;
    }

    this.isSubmiting = true;

    const occurrence = this.buildOccurrence();
    const occurrenceId = this.editingOccurrence.id;
    this.occurrenceService
      .updateOcurrence(this.condo._id, occurrenceId, occurrence)
      .pipe(timeout(10000))
      .subscribe(
        resp => {
          this.editingOccurrence.title = this.title.value;
          this.editingOccurrence.description = this.description.value;
          this.editingOccurrence.pictures = this.attachments;
          this.editingOccurrence.residence = this.user.getResidences().find(res => res._id == occurrence.residence);
          this.editingOccurrence.viewers.residences = this.residences;
          this.editingOccurrence.viewers.users = this.users;
          this.onOccurrenceUpdated.emit(this.editingOccurrence);
          this.isSubmiting = false;
          this.cancelEditing();
          this.initSelectedResidence();
        },
        err => {
          this.isSubmiting = false;
          swal({
            type: 'error',
            text: 'Não foi possível editar a ocorrência, tente novamente'
          });
        }
      );
  }

  createOccurrence() {
    if (!this.canSubmit()) {
      return;
    }
    this.isSubmiting = true;

    const occurrence = this.buildOccurrence();
    occurrence.params = {
      commentsModeration: 'NONE'
    };

    this.occurrenceService
      .createOccurrence(this.condo._id, occurrence)
      .pipe(timeout(10000))
      .subscribe(
        (resp: any) => {
          // Adiciona os atributos na ocorrência para que a mesma seja exibida corretamente pela feed sem a necessidade de buscar os dados no servidor
          occurrence._id = resp._id;
          occurrence.condo = this.condo;
          occurrence.createdBy = this.user;
          occurrence.createdAt = moment().utc().toDate();
          occurrence.pictures = this.attachments;
          occurrence.residence = this.user.getResidences().find(res => res._id == occurrence.residence);
          occurrence.viewers.residences = this.residences;
          occurrence.viewers.users = this.users;
          this.onOccurrenceCreated.emit(OccurrenceBuilder.build(occurrence));

          this.initializeForm();
          this.attachments = [];
          this.residences = new Array<Residence>();
          this.showing = false;
          this.isSubmiting = false;
        },
        err => {
          this.isSubmiting = false;
          swal({
            type: 'error',
            text: 'Não foi possível criar a ocorrência, tente novamente'
          });
        }
      );
  }

  uploadFile() {
    this.occurrenceFileUploader.pickFile();
  }
}
