import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../../theme';
import { ModalGenerateQrcodeFromGatekeeperComponent } from './modal-generate-qrcode-from-gatekeeper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PrintContentService } from '../../../../services/print-content.service';
import { PipesModule } from 'app/pipe/pipes.module';

@NgModule({
  imports: [ModalModule, CommonModule, FormsModule, ReactiveFormsModule, QRCodeModule, PipesModule, ThemeModule],
  declarations: [ModalGenerateQrcodeFromGatekeeperComponent],
  providers: [PrintContentService]
})
export class ModalGenerateQrcodeFromGatekeeperModule {}
