import { Injectable } from '@angular/core';
import { User } from '@api/model/user';
import { defaultSettingsValues, UserLocalSettings } from '@api/model/user-local-settings';
import * as moment from 'moment';
import { SessionService } from '@api/service/session.service';

type AvailableSettings = keyof Omit<UserLocalSettings, 'updatedAt'>;

type Setting<T extends AvailableSettings> = {
  key: T;
  value: UserLocalSettings[T];
};

@Injectable({ providedIn: 'root' })
export class UserLocalSettingsService {
  private STORAGE_KEY = '';

  public userLocalSettings: UserLocalSettings = null;

  constructor(private sessionService: SessionService) {
    const user = this.sessionService.userValue;

    if (user) {
      this.userLocalSettings = this.getUserLocalSettings(user);
    }

    this.sessionService.user$.subscribe(user => {
      if (user !== null) {
        this.userLocalSettings = this.getUserLocalSettings(user);
      }
    });
  }

  /**
   * This method returns the entire object of user local settings.
   * @param user - User
   */
  public getUserLocalSettings(user: User): UserLocalSettings {
    this.STORAGE_KEY = `econdos.userPreferences.${user._id}`;

    const settings = localStorage.getItem(this.STORAGE_KEY);

    if (settings) {
      return JSON.parse(settings) as UserLocalSettings;
    }

    return {};
  }

  /**
   * This method return the specified setting's value. In case it has no value, returns the setting's default value.
   * @param key - AvailableSettings
   */
  public getSetting<T extends AvailableSettings>(key: T): UserLocalSettings[T] {
    return this.userLocalSettings[key] ?? defaultSettingsValues[key];
  }

  /**
   * This method saves the specified setting
   * @param key - AvailableSettings
   * @param value - AvailableSettings[key]
   */
  public saveSetting<T extends AvailableSettings>({ key, value }: Setting<T>): void {
    this.userLocalSettings[key] = value;
    this.userLocalSettings.updatedAt = moment().toISOString();
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.userLocalSettings));
  }

  /**
   * This method clears the specified setting value.
   * @param key - AvailableSettings
   */
  public removeSetting<T extends AvailableSettings>({ key }: Pick<Setting<T>, 'key'>): void {
    this.userLocalSettings[key] = null;
    this.userLocalSettings.updatedAt = moment().toISOString();
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.userLocalSettings));
  }
}
