import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cpfPipe' })
export class CpfPipe implements PipeTransform {
  transform(cpf: string = '') {
    try {
      cpf = cpf.toString();
      cpf = cpf.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito exceto o asterisco
      cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4'); // Formata para o padrão de CPF

      return cpf;
    } catch (e) {
      console.log(e);
      return '';
    }
  }
}
