import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private _condoSubject: BehaviorSubject<Condo>;
  public selectedCondo$: Observable<Condo>;

  private _userSubject: BehaviorSubject<User>;
  public user$: Observable<User>;

  constructor() {
    const condoString = localStorage.getItem('econdos.condo');
    if (condoString) {
      let condo = JSON.parse(condoString.toString());
      condo = new Condo(condo);
      this._condoSubject = new BehaviorSubject<Condo>(condo);
    } else {
      this._condoSubject = new BehaviorSubject<Condo>(null);
    }

    this.selectedCondo$ = this._condoSubject.asObservable();

    const userString = localStorage.getItem('econdos.user');
    if (userString) {
      let user = JSON.parse(userString.toString());
      user = new User(user);
      this._userSubject = new BehaviorSubject<User>(user);
    } else {
      this._userSubject = new BehaviorSubject<User>(null);
    }
    this.user$ = this._userSubject.asObservable();
  }

  setCondo(condo: Condo) {
    let originalCondo = condo.createBackObject ? condo.createBackObject() : condo;
    originalCondo = JSON.parse(JSON.stringify(originalCondo));
    localStorage.setItem('econdos.condo', JSON.stringify(originalCondo));
    const c = condo.createBackObject ? condo : new Condo(condo);
    this._condoSubject.next(c);
  }

  get condoValue(): Condo {
    return this._condoSubject.value;
  }

  get userValue(): User {
    return this._userSubject.value;
  }

  setUser(user: User) {
    let originalUser;
    if (user instanceof User) {
      user.filterUserResidencesByCondo();
      originalUser = user.createBackObject();
    } else {
      originalUser = user;
    }
    originalUser = JSON.parse(JSON.stringify(originalUser));
    localStorage.setItem('econdos.user', JSON.stringify(originalUser));
    const u = user instanceof User ? user : new User(user);
    this._userSubject.next(u);
  }

  clearUser() {
    localStorage.removeItem('econdos.user');
    this._userSubject.next(null);
  }

  clearCondo() {
    localStorage.removeItem('econdos.condo');
    this._condoSubject.next(null);
  }

  logout() {
    this.clearUser();
    this.clearCondo();
  }
}
