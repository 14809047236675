import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// Services
import swal from 'sweetalert2';
import { CondoService } from '../../../api/service/condo.service';
import { UserService } from '../../../api/service/user.service';
import { AuthService } from '../../../services/auth.service';
import { ParamsService } from '../../../api/service/params.service';
// Model
import { Condo } from '../../../api/model/condo';
import { CondosAutocompleteComponent } from '../../../components/condos.autocomplete/condos.autocomplete';
import { CreateCondoModal } from '../../modal/create.condo.modal';
import { User } from '../../../api/model/user';
import { switchMap, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '../../../api/model/error/error.builder';
import { ModalPendingCondoApprovalComponent } from '../modal-pending-condo-approval/modal-pending-condo-approval';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EcondosQuery } from '@api/model/query';
import { CondoRequestService } from '@api/service/condo-request.service';
import { CondoRequest } from '@api/model/condo-request';
import { Residence } from '@api/model/interface/residence';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

interface FormData {
  isCondoVoter: FormControl<boolean>;
  isCondoUser: FormControl<boolean>;
}
@Component({
  selector: 'signup-steptwo-form',
  templateUrl: 'signup.steptwo.html',
  styleUrls: ['signup.steptwo.scss']
})
export class SignUpStepTwoPage implements OnInit {
  @Input() public userData: User;
  @ViewChild(CondosAutocompleteComponent, { static: true }) condosAutocomplete: CondosAutocompleteComponent;

  public searchTerm = '';
  public isSubmitting: boolean;

  public selectedCondo: Condo;
  public condos: Array<Condo> = new Array<Condo>();
  public filteredCondos: Array<Condo> = new Array<Condo>();

  public defaultCondoPictureUrl;

  public onCondoCreation: Function;

  public selectedCondoToUpdate: Condo;
  public selectedUserToUpdate: User;

  condoRequest: CondoRequest;

  constructor(
    private condoService: CondoService,
    private router: Router,
    private paramsService: ParamsService,
    private modalService: BsModalService,
    private userService: UserService,
    private authService: AuthService,
    private condoRequestService: CondoRequestService
  ) {
    this.defaultCondoPictureUrl = 'assets/img/default-condo-picture.png';

    this.onCondoCreation = condoData => {
      this.condos.push(condoData);
      this.condosAutocomplete.select(condoData);
      // New condos add user as owner automatically
      this.userData.condosOwner.push(condoData);
      console.log(condoData);
    };
  }

  ngOnInit() {
    this.getAllCondos();
  }

  wasCondoNotFound() {
    return this.condosAutocomplete.wasCondoNotFound;
  }

  createCondo() {
    const initialState = {
      onCondoCreationSuccess: condoData => this.onCondoCreation(condoData)
    };
    this.modalService.show(CreateCondoModal, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  getAllCondos() {
    const query: EcondosQuery = {
      $select: 'name address city state pictures customLabels params',
      $populate: [{ path: 'pictures', select: 'url thumbnail type name format' }],
      'params.searchable': { $ne: 'DISABLED' }
    };
    this.condoService
      .getCondos(query)
      .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        res => {
          const userCondosIds = this.userData.getAllCondos().map(condo => condo._id);
          const data = res.condos.filter(condo => userCondosIds.indexOf(condo._id) == -1);
          this.filteredCondos = this.condos = data;
        },
        err => {
          swal({
            title: 'Problema ao carregar condomínios',
            text: 'Verifique sua conexão e tente novamente.',
            cancelButtonColor: '#f53d3d'
          });
        }
      );
  }

  presentErrorAlert(title, msg) {
    swal({
      type: 'error',
      title: title,
      text: msg,
      cancelButtonColor: '#f53d3d'
    });
  }

  redirect() {
    this.paramsService.clear('userData');
    if (!this.selectedCondo.needAccessRequest() || this.userData.condosOwner.find(c => c.id === this.selectedCondo.id)) {
      this.router.navigate(['/feed']);
    } else {
      const initialState = {
        condo: this.selectedCondoToUpdate,
        user: this.selectedUserToUpdate,
        condoRequest: this.condoRequest
      };
      this.modalService.show(ModalPendingCondoApprovalComponent, { initialState, class: 'modal-md' });
      this.goBackToLogin();
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    this.userData.defaultCondo = this.selectedCondo;
    this.selectedCondoToUpdate = this.selectedCondo;
    this.selectedUserToUpdate = this.userData;
    let subscription;
    const userIsOwnerOnSelectedCondo = this.userData.condosOwner?.find((condo: Condo) => condo._id == this.selectedCondo._id);
    if (userIsOwnerOnSelectedCondo) {
      // Executa o getMe para trazer todos os dados do condo populados
      subscription = this.authService.login(this.userData.email, this.userData.password);
      if (this.userData.token) {
        subscription = this.authService.loginWithToken(this.userData.token);
      } else {
        subscription = this.authService.login(this.userData.email, this.userData.password);
      }
    } else if (!this.selectedCondo.needAccessRequest()) {
      subscription = this.condoService.addCondoUser(this.selectedCondo.id, this.userData.id).pipe(
        switchMap(res => this.userService.getMe()),
        timeoutWith(10000, ErrorBuilder.throwTimeoutError())
      );
    } else {
      const condoRequest: CondoRequest = {
        status: 'PENDING',
        user: this.userData,
        attachments: [],
        obs: ''
      };
      // Adiciona o user ao condo e obtem os dados do user atualizados com os condos populados
      subscription = this.condoRequestService.create(this.selectedCondo.id, condoRequest).pipe(
        switchMap(({ condoRequestId }) => {
          this.condoRequest = { ...condoRequest, _id: condoRequestId };
          return this.userService.getMe();
        }),
        timeoutWith(10000, ErrorBuilder.throwTimeoutError())
      );
    }
    subscription.subscribe(
      user => {
        const email = this.userData.email;
        const password = this.userData.password;
        this.userData = user;
        this.authService.registerSession(this.userData, email, password);
        // Se o usuário foi adicionado diretamente ou possui algum outro condo já aprovado
        this.selectedCondo = user.getAllCondos().find(c => c.id === this.selectedCondo.id) || this.selectedCondo;
        if (!this.condoRequest) {
          this.userData.defaultCondo = user.getAllCondos().find(c => c.id === this.selectedCondo.id);
        }
        this.redirect();
      },
      () => {
        // Não foi possível add o user
        this.presentErrorAlert('Problema ao adicionar usuário ao condomínio', 'Entre com seu usuário e senha para continuar.');
        this.paramsService.clear('userData');
        this.router.navigate(['/']);
      }
    );
  }

  condoSelected(condo) {
    this.selectedCondo = condo;
  }

  async goBackToLogin() {
    await this.authService.unregisterSession();
    this.router.navigate(['/']);
  }
}
