import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../theme';
import { ModalShowManufacturerChoiceComponent } from './modal-show-manufacturer-choice.component';

@NgModule({
  imports: [ModalModule, CommonModule, ThemeModule],
  declarations: [ModalShowManufacturerChoiceComponent]
})
export class ModalShowManufacturerChoiceModule {}
