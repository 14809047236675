import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { EcondosQuery } from '@api/model/query';
import { Occurrence } from '@api/model/interface/occurrence';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { CachedVisitorsRequestsService } from '../cached-resident-requests.service';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../../components/table/table.component';
import { map, takeUntil, timeout } from 'rxjs/operators';
import { capitalize } from '@api/util/util';
import * as moment from 'moment';
import { Contact } from '@api/model/interface/contact';
import { Access } from '@api/model/access';
import { AccessInfo } from '../../access.control/access.control';
import { ParamsService } from '@api/service/params.service';
import { CondoContact } from '@api/model/contact/condo.contact';
import { Subject } from 'rxjs';
import { ModalNewCondoContactComponent } from '../../access.control/new.condo.contact/new.condo.contact.modal';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-gate-resident-authorized-visitors',
  templateUrl: './gate-resident-authorized-visitors.component.html'
})
export class GateResidentAuthorizedVisitorsComponent implements OnInit {
  @Input() condo: Condo;
  @Input() user: User;

  @Output() navigateToAccessControl = new EventEmitter<void>();

  @ViewChild('lastLiberationVisitorsTable', { static: true }) lastLiberationVisitorsTable: TableComponent;
  @ViewChild('vehiclePlate', { static: true }) vehiclePlate: TemplateRef<any>;
  @ViewChild('actionButtons', { static: true }) actionButtons: TemplateRef<any>;

  public TYPES_LABEL = Contact.TYPES_LABEL;

  lastAuthorizedVisitors: GateOccurrence[] = [];
  totalData = 0;

  tableColumns: TableColumnDefinition<GateOccurrence>[] = [];

  status: TableStatus = 'LOADING';
  paginate = false;

  constructor(
    private cachedRequester: CachedVisitorsRequestsService,
    private paramsService: ParamsService,
    private toastrService: ToastrService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        headerLabel: 'Pessoa',
        columnId: 'lastAuthorizedVisitors',
        valueFn: occurrence => {
          if (occurrence.visitorIdentification) {
            return Contact.TYPES_LABEL[occurrence.visitorIdentification] || 'Não informado';
          }

          return `${occurrence.condoContacts[0]?.firstName} ${occurrence.condoContacts[0]?.lastName}`;
        },
        sortable: true
      },
      {
        headerLabel: 'Tipo do Visitante',
        valueFn: occurrence => {
          if (occurrence.visitorIdentification) {
            return occurrence.description || 'Não informado';
          }

          return this.TYPES_LABEL[occurrence.condoContacts[0]?.type];
        }
      },
      {
        headerLabel: 'Veículo',
        valueTemplate: this.vehiclePlate,
        show: this.condo.hardwareParams.visitorsPlateAddedByResident && !!this.totalData
      },
      {
        headerLabel: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        valueFn: occurrence => occurrence.residence?.identification || 'Nenhuma'
      },
      {
        headerLabel: 'Período',
        valueFn: occurrence => `${moment(occurrence.startDate).format('DD/MM/YY')} - ${moment(occurrence.endDate).format('DD/MM/YY')}`
      },
      {
        headerLabel: 'Ações',
        valueTemplate: this.actionButtons
      }
    ];

    this.getData({ page: 0 });
  }

  getData({ page = 0 }: { page: number }) {
    const { pageSize, sortOrder, sortedColumn } = this.lastLiberationVisitorsTable.getCurrentState();

    const lastAuthorizedVisitorsQuery: EcondosQuery = {
      $select: 'residence status startDate endDate condoContacts type createdBy occurrence visitorIdentification description',
      $populate: [
        { path: 'residence', select: 'identification' },
        {
          path: 'createdBy',
          select: 'firstName lastName',
          populate: { path: 'picture', select: 'url thumbnail type name format' }
        },
        {
          path: 'condoContacts',
          select:
            'firstName lastName condoVehicles picture ids phones type condo company service banned banReason bannedAt bannedBy externalId obs condoVehicles',
          populate: [
            {
              path: 'condoVehicles',
              select: 'plate model type brand color pictures',
              populate: [
                {
                  path: 'pictures',
                  select: 'url thumbnail type name format'
                }
              ]
            },
            { path: 'picture', select: 'url thumbnail type name format' },
            { path: 'ids.pictures', select: 'url thumbnail type format name' },
            { path: 'bannedBy', select: 'firstName lastName' }
          ]
        }
      ],

      $page: page,
      $limit: pageSize,
      type: Occurrence.GATE_TYPE,
      subType: GateOccurrence.ALLOW_ACCESS_TYPE,
      status: Occurrence.STATUS.OPENED,
      createdBy: this.user._id
    };

    const today = moment().toISOString(true);

    lastAuthorizedVisitorsQuery.startDate = { $lte: moment(today).endOf('day').toISOString(true) };
    lastAuthorizedVisitorsQuery.endDate = { $gte: moment(today).startOf('day').toISOString(true) };

    if (typeof sortedColumn === 'string') {
      lastAuthorizedVisitorsQuery.$sort = `${sortOrder === 'asc' ? '-' : ''}${sortedColumn}`;
    }

    this.status = 'LOADING';
    this.cachedRequester
      .getOccurrences(this.condo._id, lastAuthorizedVisitorsQuery)
      .pipe(
        timeout(15_000),
        map(({ count, occurrences }) => {
          const reducedOccurrences = occurrences.reduce((acc, curr) => {
            const occurrence = curr as GateOccurrence;

            if (occurrence.condoContacts && occurrence.condoContacts?.length > 1) {
              let contacts;

              contacts = occurrence.condoContacts;

              const elements = contacts.map(contact => {
                const clone = Object.assign(Object.create(Object.getPrototypeOf(occurrence)), occurrence);
                clone.condoContacts = [contact];
                return clone;
              });
              acc.push(...elements);
            } else if ((occurrence.condoContacts && occurrence.condoContacts?.length === 1) || occurrence.visitorIdentification) {
              acc.push(occurrence);
            }

            return acc;
          }, []);

          return { occurrences: reducedOccurrences };
        })
      )
      .subscribe({
        next: ({ occurrences }) => {
          this.paginate = occurrences.length >= this.lastLiberationVisitorsTable.getCurrentState()?.pageSize;
          this.lastAuthorizedVisitors = occurrences;

          this.lastAuthorizedVisitors.sort((a, b) => {
            const comparison = (a.condoContacts[0]?.firstName || a.visitorIdentification).localeCompare(
              b.condoContacts[0]?.firstName || b.visitorIdentification
            );
            return sortOrder === 'asc' ? comparison : -comparison;
          });

          this.status = 'SUCCESS';
        },
        error: err => {
          this.status = 'ERROR';
          console.log(err);
        }
      });
  }

  async registerAccessFromPermission(authorization) {
    const access = new Access();
    access.condoContact = authorization.condoContacts?.[0];
    access.source = Access.SOURCES.OCCURRENCE;
    access.status = Access.STATUS.PENDING;
    access.approvedBy = authorization.createdBy;
    access.residence = authorization.residence;
    access.occurrence = authorization;

    const canCreateCondoContact = !access.condoContact && !!authorization.visitorIdentification;
    if (canCreateCondoContact) {
      const newContact = new Promise<CondoContact>((resolve, reject) => {
        const unsubscribe$: Subject<boolean> = new Subject<boolean>();
        const initialState: Partial<ModalNewCondoContactComponent> = {
          condo: this.condo,
          newContactInfo: { type: authorization.visitorIdentification, service: authorization.description },
          callback: (contact: CondoContact) => {
            unsubscribe$.next(true);
            unsubscribe$.complete();
            resolve(contact);
          }
        };
        const modal = this.modalService.show(ModalNewCondoContactComponent, {
          initialState,
          class: 'modal-xl',
          ignoreBackdropClick: true
        });
        modal.onHide.pipe(takeUntil(unsubscribe$)).subscribe(() => {
          resolve(null);
          unsubscribe$.next(true);
          unsubscribe$.complete();
        });
      });
      access.condoContact = await newContact;
    }

    if (!access.condoContact) {
      this.toastrService.error(
        `Nenhum ${this.condo.customLabels?.visitor?.singular} foi encontrado para registrar o acesso. Por favor, cadastre um ${this.condo.customLabels?.visitor?.singular}.`
      );
      return;
    }

    access.type = access.condoContact.type;
    const accessInfo: AccessInfo = { access };
    this.paramsService.add('NEW_ACCESS', accessInfo);
    this.navigateToAccessControl.emit();
  }
}
