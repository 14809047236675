import { Component } from '@angular/core';

@Component({
  template: ''
})

// This component is used to redirect user properly in some cases.
// It's is used when user is redirect to the same URL that user is on redirect
// Source: http://tech-blog.maddyzone.com/angularjs/javascript/how-reload-current-route-angular2
export class BlankPage {}
