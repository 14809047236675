import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { buildGroup, Group } from '../model/group';
import { CondoUser } from '@api/model/condo-user';
import { User } from '@api/model/user';
import { downloadDataInChunks } from '@api/utils';

type UserAndCondoUser = User | CondoUser;

@Injectable()
export class GroupService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;
  }

  get(condoId: string, params: EcondosQuery): Observable<{ count: number; groups: Group[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/groups`, options).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count'),
          groups: res.body.map(gr => buildGroup(gr))
        };
      })
    );
  }

  create(condoId: string, group: Group) {
    return this.http.post(`${this.endPoint}${condoId}/groups`, group);
  }

  update(condoId: string, groupId: string, group: any) {
    return this.http.put(`${this.endPoint}${condoId}/groups/${groupId}`, group);
  }

  delete(condoId: string, groupId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/groups/${groupId}`);
  }

  getById(condoId: string, groupId: string): Observable<Group> {
    return this.http.get(`${this.endPoint}${condoId}/groups/${groupId}`).pipe(map(gr => buildGroup(gr)));
  }

  getGroupMembers(condoId: string, groupId: string, params: EcondosQuery): Observable<{ count: number; groupMembers: CondoUser[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/groups/${groupId}/members`, options).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count'),
          groupMembers: res.body.map(data => new CondoUser(data.member))
        };
      })
    );
  }

  getGroupMembersInChunks(condoId: string, groupId: string, query: EcondosQuery = {}) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/groups/${groupId}/members`, query, {
      numberOfRequests: 4,
      transformFn: data => new User(data.member)
    });
  }

  addMemberToGroup(condoId: string, groupId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/groups/${groupId}/members/${userId}`, {});
  }

  addMembersToGroup(condoId: string, groupId: string, usersIds: UserAndCondoUser['_id'] | Array<UserAndCondoUser['_id']>) {
    return this.http.put(`${this.endPoint}${condoId}/groups/${groupId}/members`, { users: usersIds });
  }

  removeGroupMember(condoId: string, groupId: string, userId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/groups/${groupId}/members/${userId}`);
  }
  removeGroupMembers(condoId: string, groupId: string, usersIds: UserAndCondoUser['_id'] | Array<UserAndCondoUser['_id']>) {
    return this.http.put(`${this.endPoint}${condoId}/groups/${groupId}/members/remove/many`, { users: usersIds });
  }
}
