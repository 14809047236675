import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from '../../../theme';
import { ModalAccessControlConfigComponent } from './modal-access-control-config.component';
import { PipesModule } from 'app/pipe/pipes.module';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ModalModule, PipesModule, ThemeModule],
  declarations: [ModalAccessControlConfigComponent]
})
export class ModalAccessControlConfigModule {}
