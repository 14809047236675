import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LogViewedData } from '@api/model/log-viewed-data';

@Injectable({ providedIn: 'root' })
export class LogViewedDataService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  create(condoId: string, model: string, documentId: string) {
    return this.http.post(`${this.endPoint}${condoId}/log-viewed-data`, { model, documentId });
  }

  get(condoId: string, params: EcondosQuery): Observable<{ count: number; views: LogViewedData[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/log-viewed-data`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        views: res.body
      }))
    );
  }
}
