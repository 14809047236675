import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, noop, Observable, of, Subscription } from 'rxjs';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { catchError, distinctUntilChanged, filter, map, mergeMap, retry, switchMap, timeout } from 'rxjs/operators';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { Device, DEVICE_STATUS } from '@api/model/hardware/device';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { ResponseError } from '@api/model/error/response.error';
import { EcondosQuery } from '@api/model/query';
import { Actuator } from '@api/model/hardware/actuator';
import { ControlIdService } from '@api/service/hardware/control-id.service';
import { User } from '@api/model/user';
import { FileService } from '@api/service/file.service';
import { HttpClient } from '@angular/common/http';
import { orderBy } from 'lodash';
import * as moment from 'moment';
import { maxDateValidator } from '@api/util/validators';
import { ModalCreateVehicleComponent } from '../../components/modal-create-vehicle/modal-create-vehicle.component';
import { SelectAccessGroupActuatorsComponent } from '../../components/select-access-group-actuators/select-access-group-actuators.component';

interface IActuator extends Actuator {
  otherActuatorsIds?: string[];
}

@Component({
  selector: 'app-modal-create-controlid-device',
  templateUrl: 'modal-create-controlid-device.component.html',
  styleUrls: ['modal-create-controlid-device.component.scss']
})
export class ModalCreateControlIdDeviceComponent implements OnInit, OnDestroy {
  @ViewChild('selectAccessGroupActuatorsComponent', { static: true }) selectAccessGroupActuators: SelectAccessGroupActuatorsComponent;
  condo: Condo;

  TYPES = [
    { label: 'Cartão ou chaveiro', value: 'CARD' },
    { label: 'Senha', value: 'SN' },
    { label: 'Tag adesiva', value: 'TP' },
    { label: 'Biometria', value: 'BM' },
    { label: 'Facial', value: 'FACIAL' },
    { label: 'QR Code', value: 'QR' }
  ];

  status: Status = new Status();

  device: Device;
  callbacks: { success?: (arg) => void; error?: (err) => void };

  form: UntypedFormGroup;

  type: AbstractControl;
  serial: AbstractControl = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
  serialType: AbstractControl = new UntypedFormControl('WG');
  residence: AbstractControl;
  vehicle: AbstractControl;
  user: AbstractControl;
  userName: AbstractControl;
  picture: AbstractControl;
  panic: AbstractControl;
  obs: AbstractControl;
  validFrom: FormControl<string>;
  validUntil: FormControl<string>;
  template: UntypedFormControl = new UntypedFormControl('', Validators.required);
  actuators: UntypedFormArray;

  residenceSearch;
  residenceUsers = [];
  loadingResidences = false;
  residenceVehicles: CondoVehicle[] = [];

  residenceTypeAheadDataSource$: Observable<any>;

  // panic: boolean;

  selectedResidence;

  private subscriptions: Subscription = new Subscription();

  remoteActuator: Actuator = null;

  storageUser: { user: User; residence: Residence };

  public actuatorsToRegister: IActuator[] = [];
  public actuatorsToRemove: IActuator[] = [];
  public storedActuators: IActuator[] = [];
  loadedActuators: Actuator[] = [];

  // O equipamento não aceita datas acima muito no futuro devido a limitação de como ele sava a data em formato de inteiro
  // Foi testado 2035 e 2040, em 2040 o equipamento já não salva
  readonly CONTROL_ID_MAX_DATE = '2035-12-31';

  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private controlIdService: ControlIdService,
    private deviceService: HardwareDeviceService,
    private fileService: FileService,
    private toastrService: ToastrService,
    private http: HttpClient
  ) {
    this.form = this.fb.group({
      type: ['', Validators.required],
      user: [null],
      userName: [''],
      residence: [null, [Validators.required]],
      vehicle: [''],
      picture: [null],
      actuators: this.fb.array([]),
      panic: [false],
      validFrom: ['', [maxDateValidator(this.CONTROL_ID_MAX_DATE)]],
      validUntil: ['', [maxDateValidator(this.CONTROL_ID_MAX_DATE)]],
      obs: ['']
    });
    this.type = this.form.get('type');
    this.user = this.form.get('user');
    this.userName = this.form.get('userName');
    this.residence = this.form.get('residence');
    this.vehicle = this.form.get('vehicle');
    this.picture = this.form.get('picture');
    this.panic = this.form.get('panic');
    this.obs = this.form.get('obs');
    this.validFrom = this.form.get('validFrom') as FormControl;
    this.validUntil = this.form.get('validUntil') as FormControl;

    this.subscriptions.add(
      this.serial.valueChanges.pipe(filter(v => !(v || '').length && !this.form.contains('serialType'))).subscribe(() => {
        this.serialType.setValue('WG');
        this.form.addControl('serialType', this.serialType);
        Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
      })
    );

    this.subscriptions.add(
      this.type.valueChanges.subscribe(type => {
        if (type === 'BM') {
          this.form.removeControl('serial');
          this.form.removeControl('serialType');
          this.form.addControl('template', this.template);
          this.form.addControl('panic', this.panic);
          this.picture.clearValidators();
        } else if (type === 'FACIAL') {
          this.form.removeControl('serial');
          this.form.removeControl('serialType');
          this.form.removeControl('template');
          this.form.removeControl('panic');
          this.picture.setValidators([Validators.required]);
        } else if (type === 'QR') {
          this.form.removeControl('serial');
          this.form.removeControl('serialType');
          this.form.removeControl('template');
          this.form.addControl('panic', this.panic);
        } else {
          this.form.addControl('serial', this.serial);
          this.form.addControl('serialType', this.serialType);
          this.form.addControl('panic', this.panic);
          if (type === 'SN') {
            this.serialType.setValue('');
          }
          this.form.removeControl('template');
          this.picture.clearValidators();
        }
        Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
      })
    );

    this.subscriptions.add(
      this.user.valueChanges.subscribe(userId => {
        if (userId === 'NEW_USER') {
          swal({
            title: 'Inserir nome',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            inputPlaceholder: 'Digite o nome...',
            showLoaderOnConfirm: true,
            preConfirm: name => {
              if (!name || !name.trim().length) {
                return Promise.reject(`Insira o nome`);
              } else {
                name = name.trim();
                return Promise.resolve(name);
              }
            }
          }).then(
            name => {
              this.userName.setValue(name);
              this.user.setValue('');
            },
            () => {
              this.userName.setValue('');
              this.user.setValue('');
            }
          );
        } else {
          // Verificar se tem foto cadastrada. Se não, pega foto do usuario logado
          if (this.checkHasDeviceOwnerPicture()) {
            this.picture.setValue(this.device.owner.picture);
          } else if (userId) {
            const user = this.residenceUsers.find(u => u._id === userId);
            if (user && user.picture && user.picture._id) {
              this.picture.setValue(user.picture);
            } else {
              this.picture.setValue(null);
            }
          } else {
            this.picture.setValue(null);
          }
        }
      })
    );

    this.initializeTypeAheads();
  }

  initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type voter users',
          $populate: [
            {
              path: 'users',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            },
            {
              path: 'voter',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            }
          ]
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(() => {
            this.status.setAsError();
            return of([]);
          })
        );
      })
    );
  }

  checkHasDeviceOwnerPicture() {
    return !!this.device?.owner?.picture;
  }

  ngOnInit() {
    if (this.device) {
      this.type.disable();
      this.serial.disable();
      this.type.setValue(this.device.type);
      this.serial.setValue(this.device.serial);
      this.serialType.setValue(this.device.hardwareAttributes?.serial || '');
      this.panic.setValue(this.device.panic || false);
      this.obs.setValue(this.device.obs || '');

      if (this.device.validFrom) {
        const validFrom = moment(this.device.validFrom);
        this.validFrom.setValue(validFrom.format('YYYY-MM-DD'));
      }

      if (this.device.validUntil) {
        const validUntil = moment(this.device.validUntil);
        this.validUntil.setValue(validUntil.format('YYYY-MM-DD'));
      }

      this.obs.enable();
      if (this.device.owner) {
        if (this.device.owner.residence && this.device.owner.residence._id) {
          this.residence.setValue(this.device.owner.residence);
          const vehicleId: any =
            this.device.owner &&
            ((this.device.owner.condoVehicle && this.device.owner.condoVehicle._id) || this.device.owner.condoVehicle || '');
          const userId: any = (this.device.owner && this.device.owner.user && this.device.owner.user._id) || '';
          this.initializeFieldsFromResidence(this.device.owner.residence, { userId, vehicleId });
        }
        if (this.device.owner.condoVehicle && this.device.owner.condoVehicle._id) {
          this.residenceVehicles = [this.device.owner.condoVehicle];
          this.vehicle.setValue(this.device.owner.condoVehicle._id);
        } else {
          this.vehicle.setValue('');
        }
        if (this.device.owner.user && this.device.owner.user._id) {
          this.residenceUsers = [this.device.owner.user];
          this.storageUser = { user: new User(this.device.owner.user), residence: this.device.owner?.residence };
          this.userName.setValue(`${this.device.owner.user.firstName} ${this.device.owner.user.lastName}`);
        } else {
          this.user.setValue('');
          if (this.device.owner.userName) {
            this.userName.setValue(this.device.owner.userName);
          } else if (this.device.hardwareAttributes && this.device.hardwareAttributes.rotulo) {
            this.userName.setValue(this.device.hardwareAttributes.rotulo);
          }
        }
        if (this.device.owner.picture && this.device.owner.picture._id) {
          this.picture.setValue(this.device.owner.picture);
        }
        if (this.device.actuators) {
          this.storedActuators = this.device.actuators;
        }
      }
      if (this.device.type === 'BM') {
        this.deviceService
          .getDeviceTemplate(this.condo._id, this.device._id)
          .pipe(retry(3))
          .subscribe(({ template }) => {
            this.template.setValue(template);
          });
      }
    } else if (this.selectedResidence) {
      this.residence.setValue(this.selectedResidence);
      this.residence.disable();
      this.initializeFieldsFromResidence(this.selectedResidence);
    }
    if (this.storageUser) {
      if (this.storageUser.residence) {
        this.residence.setValue(this.storageUser.residence);
        this.residence.disable();
        if (!this.storageUser.user) {
          this.initializeFieldsFromResidence(this.storageUser.residence);
        }
        this.user.setValue('');
      }
      if (this.storageUser.user) {
        this.residenceUsers = [this.storageUser.user];
        this.user.setValue(this.storageUser.user._id);
        this.user.disable();
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residence.setValue(residences[0]);
          this.getResidenceVehicles(residences[0].id);
        }
        if (this.storageUser.user.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async save() {
    if (this.form.valid) {
      if (this.type.value === 'QR') {
        return this.saveQrCodeDevice();
      }

      await this.saveDevice();
    } else {
      this.toastrService.warning('Preencha todos os campos');
      this.form.markAllAsTouched();
    }
  }

  async saveDevice() {
    try {
      if (this.type.value === 'FACIAL') {
        const filteredNotFacialActuators = this.actuatorsToRegister.filter(actuator => actuator.type !== 'iDFace');
        if (filteredNotFacialActuators?.length) {
          swal({
            type: 'warning',
            title: 'Equipamentos inválidos',
            html: `
              Existem equipamentos selecionados que não suportam reconhecimento facial: ${filteredNotFacialActuators
                .map(ac => ac.name)
                .join(', ')}<br>
              <b>Desmarque eles e tente novamente!</b>`
          });
          return;
        }
      }
      let device = this.depopulateDevice();

      device.status = DEVICE_STATUS.UNSYNCED;
      this.status.setAsProcessing();

      let id;
      let previousDevice; // For update serial and template devices
      if (this.device?._id) {
        // Allowed type devices to send previousDevice
        if (['SN', 'CARD', 'TP', 'BM'].includes(device.type)) {
          previousDevice = { ...this.device };
        }

        await this.deviceService.update(this.condo._id, device._id, device).toPromise();
        id = this.device._id;
      } else {
        const dev = await this.deviceService.create(this.condo._id, device).toPromise();
        id = dev?._id;
      }
      device._id = id;
      device = this.populateDevice(device);

      const res = await this.controlIdService.registerDevice(
        [...this.actuatorsToRegister, ...this.actuatorsToRemove],
        device,
        previousDevice
      );
      if (res.internalIds.length) {
        device.internalIds = res.internalIds;
        device.status = DEVICE_STATUS.SYNCED;
        await this.deviceService.update(this.condo._id, device._id, device).toPromise();
        device.actuators = this.actuatorsToRegister;
        this.toastrService.success('Dispositivo registrado com sucesso');
      } else {
        swal({
          type: 'error',
          title: 'Dispositivo não sincronizado em todos equipamentos',
          text: `Não foi possível efetuar a operação nos seguintes equipamentos: ${res.actuators.map(ac => ac.name).join(', ')}`
        });
      }

      this.status.setAsSuccess();
      this.bsModalRef.hide();
      if (this.callbacks && this.callbacks.success) {
        this.callbacks.success(device);
      }
    } catch (err) {
      this.status.setAsError();
      if (
        err instanceof ResponseError &&
        !err.message &&
        err.originalError &&
        err.originalError.error &&
        err.originalError.error.includes('Device is already registered')
      ) {
        swal({
          type: 'error',
          title: 'Serial já cadastrado',
          text: `Já existe um dispositivo com este serial cadastrado em seu(sua) ${this.condo?.customLabels?.condo?.singular}.`
        });
      } else if (this.type.value === 'FACIAL') {
        let errors = (err.message || '').split(',').map(v => v.trim());
        errors = [...new Set(errors)].map((e, index) => `<p>${index + 1} - ${e}</p>`);
        const errorReason = errors.join('');
        console.log({ errors, errorReason });

        swal({
          type: 'error',
          title: 'Não foi possível cadastrar a facial',
          html: `
            <div>Foram encontrados os seguintes problemas:</div>
            ${errorReason}
`
        });
      } else {
        swal({
          type: 'error',
          title: 'Ops...',
          text: 'Não foi possível cadastrar este dispositivo.'
        });
      }
    }
  }

  saveQrCodeDevice(): void {
    this.status.setAsProcessing();

    const device = this.depopulateDevice();

    let subscription: Observable<any>;
    if (this.device?._id) {
      subscription = this.deviceService.update(this.condo._id, device._id, device);
    } else {
      subscription = this.deviceService.create(this.condo._id, device).pipe(
        switchMap(({ _id: deviceId }) => this.deviceService.update(this.condo._id, deviceId, { status: 'SYNCED' })),
        map(dev => this.populateDevice(dev))
      );
    }

    subscription.pipe(timeout(10000)).subscribe(
      successDevice => {
        this.status.setAsSuccess();
        this.toastrService.success('Dispositivo registrado com sucesso');

        if (this.callbacks && this.callbacks.success) {
          this.callbacks.success(successDevice);
        }
        this.bsModalRef.hide();
      },
      error => {
        console.log(error);
        this.status.setAsError();
        swal({
          type: 'error',
          title: 'Ops...',
          text: 'Não foi possível cadastrar este dispositivo.'
        });
      }
    );
  }

  onResidenceSelect(event: { item: Residence }) {
    const residence = event.item;
    this.residence.setValue(residence);
    this.residenceUsers = residence.residenceUsers;
    this.residenceUsers = orderBy(this.residenceUsers, [u => u.fullName.toLowerCase()]);
    this.user.setValue('');
    this.vehicle.setValue('');
    this.getResidenceVehicles(residence.id);
  }

  getResidenceVehicles(residenceId: string) {
    this.residenceService.getVehicles(this.condo._id, residenceId).subscribe(
      res => {
        this.residenceVehicles = res.vehicles;
      },
      err => {
        console.log(err);
      }
    );
  }

  clearResidence() {
    this.residenceSearch = '';
    this.residence.setValue(null);
    this.residenceUsers = [];
    this.user.setValue(null);
    this.residenceVehicles = [];
  }

  populateDevice(device) {
    device = { ...device };
    const type = device.type;
    const serial = device.serial;
    if (type !== 'BM') {
      device.serial = serial;
    }
    if (this.type.value === 'BM' && this.template.value) {
      device.template = this.template.value;
    }
    if (device.owner) {
      if (this.residence.value) {
        device.owner.residence = this.residence.value;
      }
      if (this.user.value) {
        device.owner.user = this.residenceUsers.find(u => u._id === this.user.value);
      }
      if (this.vehicle.value) {
        device.owner.condoVehicle = this.residenceVehicles.find(v => v._id === this.vehicle.value);
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value;
      }
      if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }
    }
    return device;
  }

  depopulateDevice() {
    const type = this.type.value;
    const serial = this.serial.value;
    const device: any = Object.assign(this.device || {}, {
      type,
      hardware: 'CONTROL_ID',
      actuators: this.actuatorsToRegister,
      panic: this.form.get('panic')?.value,
      obs: this.obs.value,
      validFrom: this.validFrom.value ? moment(this.validFrom.value, 'YYYY-MM-DD').startOf('d').toISOString() : null,
      validUntil: this.validUntil.value ? moment(this.validUntil.value, 'YYYY-MM-DD').endOf('d').toISOString() : null
    });
    if (this.type.value === 'BM' && this.template.value) {
      device.template = this.template.value;
    }
    if (!['FACIAL', 'BM', 'QR'].includes(type)) {
      device.serial = serial;
      device.hardwareAttributes = { serial: this.serialType.value };
    }
    delete device.createdBy;
    delete device.createdAt;
    if (this.residence.value) {
      device.owner = {
        residence: this.residence.value._id
      };
      if (this.user.value) {
        device.owner.user = this.user.value;
      } else if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }
      if (this.vehicle.value) {
        device.owner.condoVehicle = this.vehicle.value;
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value._id;
      }
    }
    this.device = device;
    return device;
  }

  initializeFieldsFromResidence(residence, selectedValues: { vehicleId?: string; userId?: string } = {}) {
    const residenceQuery: EcondosQuery = {
      $populate: [
        {
          path: 'users',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        },
        {
          path: 'voter',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        }
      ]
    };
    forkJoin(
      this.residenceService.getResidenceByIdWithParams(this.condo._id, residence._id, residenceQuery),
      this.residenceService.getVehicles(this.condo._id, residence._id)
    )
      .pipe(timeout(15000))
      .subscribe(
        ([residenceResponse, vehiclesResponse]) => {
          this.residenceUsers = residenceResponse.residenceUsers;
          this.residenceVehicles = vehiclesResponse.vehicles;
          this.vehicle.setValue(selectedValues.vehicleId || '');
          this.user.setValue(selectedValues.userId || '');
        },
        async err => {
          console.log(err);
          await swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível obter os dados necessários, verifique sua conexão e tente novamente.'
          });
          this.bsModalRef.hide();
        }
      );
  }

  onCheckboxChange(e) {
    const checkArray: UntypedFormArray = this.actuators;

    if (e.target.checked) {
      checkArray.push(new UntypedFormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  async readFingerPrintTemplate(actuator) {
    this.template.setValue(undefined);

    // Verificar se a pessoa escolheu o cadastrador de mesa usb ou não
    if (actuator === 'TABLE_REGISTER') {
      this.collectFingerprintFromTableRegister();
    } else {
      const options: SweetAlertOptions = {
        title: 'Dedo 1',
        text: 'Coloque o dedo na leitora',
        showConfirmButton: false,
        showCancelButton: true,
        imageUrl: 'assets/svg/fingerprint.svg',
        imageWidth: 200,
        imageHeight: 200
      };
      swal(options);
      try {
        this.template.setValue(await this.controlIdService.registerFingerprintRemotely(actuator));
      } finally {
        swal.close();
      }
    }
  }

  async collectFingerprintFromTableRegister() {
    // Essa url vem do projeto de comunicação com a iDBio
    // https://gitlab.com/econdos/econdos-idbio
    const iDBioServiceUrl = 'http://localhost:5095/idbio';
    swal({
      type: 'info',
      title: 'Aguarde um momento...',
      text: `Verificando conexão com iDBio`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        swal.showLoading();
      }
    });
    const serviceIsOnline = await this.http
      .get<{ status: 'online' | 'offline' }>(`${iDBioServiceUrl}/status`)
      .pipe(
        timeout(2000),
        map(({ status }) => status === 'online'),
        catchError(() => of(false))
      )
      .toPromise();
    if (serviceIsOnline) {
      swal({
        type: 'info',
        title: 'Aguardando coleta',
        text: `Coloque e tire o dedo na 4 (quatro) vezes na cadastradora de mesa`,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar'
      }).catch(() => {});
      const { success, template, logs } = await this.http
        .get<{ template: string; success: boolean; logs: string[] }>(`${iDBioServiceUrl}/capture`)
        .pipe(
          timeout(30_000),
          catchError(e => {
            console.log(e);
            return of({ success: false, template: '', logs: ['Tempo de captura excedido', e.toString()] });
          })
        )
        .toPromise();
      if (success) {
        this.toastrService.success('Biometria coletada com sucesso');
        swal.close();
        console.log('logs da iDBio', logs);
        this.template.setValue(template);
      } else {
        swal({
          type: 'error',
          title: 'Falha na captura',
          text: 'Não foi possível concluir o processo de captura, veja os logs para mais informações',
          confirmButtonText: 'Ver logs',
          cancelButtonText: 'Ok',
          showCancelButton: true,
          reverseButtons: false,
          showConfirmButton: true
        })
          .then(() => {
            swal({
              type: 'info',
              title: 'Logs da captura',
              html: logs.join('<br/>')
            });
          })
          .catch(() => {});
      }
    } else {
      swal({
        type: 'question',
        text: 'Você tentou cadastrar uma biometria nos últimos 30 segundos?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCancelButton: true
      })
        .then(() => {
          swal({
            type: 'warning',
            text: 'Aguarde 30 segundos até a iDBio encerrar o processo de cadastro anterior e tente novamente'
          });
        })
        .catch(e => {
          console.log(e);
          swal({
            type: 'warning',
            html: `
              <p>Para utilizar o cadastrador de mesa é necessário instalar o driver da cadastradora.</p>
              <p>
                Faça o download do driver de comunicação com a cadastradora de mesa iDBio<a
                href="https://files.econdos.com.br/drivers/iDBio-service.zip">
                clicando aqui
                </a>
              </p>
              <p>Reinicie o processo após a instalação do driver</p>
          `
          });
        });
    }
  }

  async collectCard() {
    const actuators = [...this.actuatorsToRegister, ...this.actuatorsToRemove];
    if (!actuators || !actuators.length) {
      swal({
        type: 'error',
        title: 'Equipamentos não cadastrados',
        text: `Não existem equipamentos cadastrados no seu(sua) ${this.condo?.customLabels?.condo?.singular}. Antes de cadastrar um cartão é necessário você cadastrar os equipamentos`
      });
      return;
    }
    let selectedActuator;
    if (actuators.length > 1) {
      const inputOptions: any = actuators.reduce((acc, curr) => {
        acc[curr._id] = curr.name;
        return acc;
      }, {});

      const actuatorId = await swal({
        inputOptions,
        title: 'Equipamento',
        text: 'Selecione o equipamento desejado para a leitura do cartão',
        input: 'select',
        inputPlaceholder: 'Selecione',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputValidator: async res => {
          if (res) {
            Promise.resolve();
          } else {
            return Promise.reject('Selecione um equipamento!');
          }
        }
      });
      selectedActuator = actuators.find(a => a._id === actuatorId);
    } else {
      selectedActuator = actuators[0];
    }

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Passe o cartão na leitora ${selectedActuator.name}`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(e => {
      console.log('canceled', e);
    });

    this.controlIdService.collectCard(selectedActuator).subscribe(
      serial => {
        this.toastrService.success(`Cartão ${serial} lido com sucesso`);
        this.serial.setValue(serial);
        this.serialType.setValue('');
        Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
        swal.clickConfirm();
      },
      err => {
        console.log(err);
        swal.close();
        swal({
          type: 'error',
          title: 'Erro na leitura',
          text: 'Não foi possível realizar a leitura do cartão'
        });
      }
    );
  }

  async collectFace() {
    const actuators = this.loadedActuators.filter(actuator => actuator.type === 'iDFace').sort((a, b) => a.name.localeCompare(b.name));

    if (!actuators || !actuators.length) {
      swal({
        type: 'error',
        title: 'Equipamentos não cadastrados',
        text: `Não existem acionadores cadastrados. Antes de cadastrar uma facial é necessário você cadastrar os acionadores.`
      });
      return;
    }

    const actuatorsMap = actuators.reduce((acc, actuator) => {
      acc[actuator._id] = actuator;
      return acc;
    }, {});
    const inputOptions: any = actuators.reduce((acc, actuator) => {
      acc[actuator._id] = actuator.name;
      return acc;
    }, {});

    const actuatorId = await swal({
      type: 'question',
      title: 'Selecionar equipamento',
      text: `Selecione a idFace que você deseja utilizar para capturar a face da pessoa`,
      input: 'select',
      inputOptions,
      inputPlaceholder: 'Selecione ao equipamento',
      inputValidator: value => {
        return new Promise(resolve => {
          if (value) {
            resolve();
          } else {
            resolve(Promise.reject('Selecione um equipamento para o registro e tente novamente!'));
          }
        });
      },
      showCancelButton: true,
      confirmButtonText: 'Capturar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).catch(() => swal.noop());

    if (actuatorId) {
      const actuator = actuatorsMap[actuatorId];

      swal({
        title: 'Registrando imagem...',
        text: 'A pessoa deve ficar na frente do equipamento e aguardar a contagem regressiva',
        type: 'info',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false
      }).catch(() => {
        this.controlIdService.cancelRemoteEnroll(actuator).pipe(retry(3)).subscribe(noop);
      });

      this.controlIdService
        .collectFace(actuator)
        .pipe(
          timeout(60000),
          map(({ image }) => `data:image/jpeg;base64,${image}`),
          switchMap(image => this.fileService.uploadBase64(image)),
          map(([file]) => file)
        )
        .subscribe(
          (image: File) => {
            this.picture.setValue(image);
            swal.clickConfirm();
            this.toastrService.success('Facial capturada com sucesso');
          },
          err => {
            swal.close();
            swal({
              type: 'error',
              title: 'Ops...',
              text: 'Não foi possível realizar a captura da face. Verifique a conexão com o equipamento e tente novamente.'
            });
            console.log(err);
          }
        );
    }
  }

  formatSerial() {
    const current = this.serial.value;
    this.serial.setValue(current.toUpperCase(), { emitEvent: false });
  }

  toggleAll() {
    this.selectAccessGroupActuators.toggleAll();
  }

  createVehicle(condo: Condo, user: User = null, residence: Residence = null) {
    const initialState = {
      condo,
      onCreate: (condoVehicle: CondoVehicle) => {
        this.residenceVehicles = [...this.residenceVehicles, condoVehicle];
        this.vehicle.setValue(condoVehicle._id);
      },
      ...(residence && { vehicleResidence: residence }),
      ...(user && { vehicleUser: user })
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState });
  }
}
