import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// Services
import { UtilService } from '../../../services/util.service';
import { NavbarService } from '../../navbar/navbar.service';
import { UserService } from '@api/service/user.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { ContactID } from '@api/model/contact/contact.id';
import { User } from '@api/model/user';
import { debounceTime, distinctUntilChanged, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { AuthService } from '../../../services/auth.service';
import { fullNameValidator } from '@api/util/validators';

@Component({
  selector: 'signup-stepone-form',
  templateUrl: './signup.stepone.html'
})
export class SignUpStepOnePage implements OnInit {
  @Input() userData: User;
  @Output() onStepOneSuccess = new EventEmitter();

  form: UntypedFormGroup;
  name: AbstractControl;
  birthDate: AbstractControl;
  phone: AbstractControl;
  cpf: AbstractControl;
  picture: AbstractControl;

  isSubmitting: boolean;

  maxDate = moment().toDate();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private navbarService: NavbarService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.navbarService.hideNavBar();
    this.setUpForm();
  }

  setUpForm() {
    const dateValidator = (c: UntypedFormControl) => {
      return c.value && moment().diff(c.value, 'years') >= 18 ? null : { minimumAge: { valid: false } };
    };

    const maximumDateValidator = (c: UntypedFormControl) => {
      return c.value && moment().diff(c.value, 'years') <= 110 ? null : { maximumAge: { valid: false } };
    };

    const cpfValidator = (c: UntypedFormControl) => {
      return c.value && this.utilService.validateCpf(c.value) ? null : { validCpf: { valid: false } };
    };

    const fullName = this.userData.firstName ? this.userData.firstName + ' ' + this.userData.lastName : '';

    this.form = this.formBuilder.group({
      name: [fullName, Validators.compose([Validators.required, fullNameValidator])],
      birthDate: [
        this.userData.birthDate ? moment(this.userData.birthDate).toDate() : null,
        Validators.compose([Validators.required, dateValidator, maximumDateValidator])
      ],
      phone: [
        this.utilService.formatPhoneToShow(this.userData.phone),
        Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])
      ],
      cpf: [
        this.userData.cpf ? this.utilService.formatCpf(this.userData.cpf.number) : '',
        Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15), cpfValidator])
      ],
      picture: [this.userData.picture ? this.userData.picture : null, Validators.required]
    });

    this.name = this.form.get('name');
    this.birthDate = this.form.get('birthDate');
    this.phone = this.form.get('phone');
    this.cpf = this.form.get('cpf');
    this.picture = this.form.get('picture');

    this.cpf.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(cpf => {
      if (!cpf) {
        return;
      }
      this.cpf.setValue(this.utilService.formatCpf(cpf), { emitEvent: false });
    });

    this.phone.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(phone => {
      if (!phone) {
        return;
      }
      this.phone.setValue(this.utilService.formatPhone(phone), { emitEvent: false });
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const controlKey of Object.keys(this.form.controls)) {
        this.form.get(controlKey).markAsTouched();
      }
      return;
    }

    this.isSubmitting = true;

    const cpfID = new ContactID();
    cpfID.type = ContactID.TYPES.CPF;
    cpfID.number = this.cpf.value.toString().replace(/[^0-9]/g, '');

    const names = this.name.value.split(' ').filter(v => v.trim());
    const firstName = names.shift();
    const lastName = names.join(' ');
    this.userData.firstName = firstName;
    this.userData.lastName = lastName;
    this.userData.birthDate = this.birthDate.value;
    this.userData.phones = [this.phone.value.toString().replace(/[^0-9]/g, '')];
    this.userData.ids = [cpfID];
    this.userData.picture = this.picture.value || null;
    this.userData.signupStep = 2;

    const serverData: any = {};
    serverData.firstName = this.userData.firstName;
    serverData.lastName = this.userData.lastName;
    serverData.birthDate = this.userData.birthDate;
    serverData.phones = this.userData.phones;
    serverData.ids = this.userData.ids.map(id => id.createBackObject());
    if (this.userData.picture) {
      serverData.picture = this.userData.picture.id;
    }
    serverData.signupStep = 2;

    this.userService
      .updateUser(this.userData._id, serverData)
      .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        resp => {
          this.onStepOneSuccess.emit(this.userData);
          this.isSubmitting = false;
        },
        err => {
          swal({
            title: 'Problema ao submeter',
            text: 'Verifique sua conexão e tente novamente.',
            cancelButtonColor: '#f53d3d'
          });
          this.isSubmitting = false;
        }
      );
  }

  async goBackToLogin() {
    await this.authService.unregisterSession();
    this.router.navigate(['/']);
  }
}
