import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalResidenceConfigComponent } from './modal-residence-config.component';
import { ThemeModule } from '../../../../theme';
import { PipesModule } from '../../../../pipe/pipes.module';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ModalModule, PipesModule, ThemeModule],
  declarations: [ModalResidenceConfigComponent]
})
export class ModalResidenceConfigModule {}
