import { Injectable } from '@angular/core';
import { Reservation } from '../model/reservation';
import * as moment from 'moment';
import { Vehicle } from '@api/model/vehicle';
import { Pet, PET_SIZES_LABELS, PET_SPECIES_LABELS } from '@api/model/pet';
import { formatPhone } from '@api/util/formatters';
import { Dependent } from '@api/model/dependent';
import { User } from '@api/model/user';
import { PrintContentService } from '../../services/print-content.service';
import { UtilService } from 'app/services/util.service';
import { Condo } from '@api/model/condo';
import { capitalize } from '@api/util/util';
import { ElectronService } from '../../services/electron.service';

@Injectable()
export class ReportService {
  condo: Condo;

  constructor(
    private printContentService: PrintContentService,
    private utilService: UtilService,
    private electronService: ElectronService
  ) {
    this.condo = this.utilService.getLocalCondo();
  }

  /*============================================
 ============= DELIVERIES ==================
 ==========================================*/

  printDeliveryTag(deliveryTag: DeliveryTag, qrCodeContent) {
    let content = '';

    const trackingNumbers = deliveryTag.trackingNumbers.join(', ');

    let identificationFontSize = '0.8rem';
    const identificationSize = deliveryTag.residence?.identification?.length || 0;
    if (identificationSize < 13) {
      identificationFontSize = '2.4rem';
    } else if (identificationSize < 20) {
      identificationFontSize = '1.8rem';
    } else if (identificationSize < 30) {
      identificationFontSize = '1.2rem';
    }

    const destPersonSize = deliveryTag.destPerson?.length || 0;
    if (destPersonSize > 50) {
      let splittedFullname = deliveryTag.destPerson.split(' ');
      if (splittedFullname.length > 2) {
        const firstName = splittedFullname.shift();
        const lastName = splittedFullname.pop();
        splittedFullname = splittedFullname.map(name => name[0] + '.');
        splittedFullname.unshift(firstName);
        splittedFullname.push(lastName);
        deliveryTag.destPerson = splittedFullname.join(' ');
      }
    }

    content += `
      <div style="max-width: 100%">
        <div style="margin: 0; outline: 0; box-sizing: border-box; padding: 1rem; width: 30.236rem; height: 11.339rem; display: flex; flex-direction: column; align-items: center; justify-content: flex-start">
          <main style="width: 100%; height: 100%; display: flex; gap: 0.8rem">
            <section class="qr-code-container" style="margin-top: 0.2rem">
              ${qrCodeContent}
            </section>

            <section style="margin-top: 0.4rem; flex: 1; display: flex; flex-direction: column">
              <header style="font-family: sans-serif; font-weight: bold; display: flex; flex-direction: column">
                <h1 style="margin: 0.2rem 0 0 0; font-size: ${identificationFontSize}">${deliveryTag.residence.identification}</h1>
                <h2 style="font-family: sans-serif; font-size: var(--font-size-xxs); font-weight: bold; margin: 0.2rem 0 0 0">
                  <i class="fa fa-fw fa-tag"></i>
                  <span>${deliveryTag.protocol}</span>
                </h2>
              </header>

              <footer style="font-family: sans-serif; font-weight: normal; display: flex; flex-direction: column">
                <p style="margin: 0.2rem 0 0 0; font-size: var(--font-size-xxs)">
                  <i class="fa fa-fw fa-user"></i>
                  <span>${deliveryTag.destPerson || 'Não definido'}</span>
                </p>
                <p style="margin: 0.2rem 0 0 0; font-size: var(--font-size-xxs);">
                  <i class="fa fa-fw fa-calendar"></i>
                  <span>${moment(deliveryTag.createdAt).format('DD/MM/YY HH:mm')}</span>
                </p>
              </footer>
            </section>
          </main>

          <footer style="display: flex; flex-direction: column; align-items: center">
            <p style="font-family: sans-serif; font-size: 1rem; font-weight: normal; display: flex; gap: 0.4rem">
              <span>Rastreio:</span>
              <span>${trackingNumbers || 'Nenhum'}</span>
            </p>
          </footer>
        </div>
      </div>
    `;

    this.printContentService.print(content);
  }

  /*============================================
   ============= RESERVATIONS ==================
   ==========================================*/

  printImportReport(tableData, condoName) {
    let html = '';
    html += `<div class="import-report-print">`;

    html += `<div class="report-title">
        Relatório de importação de usuários no(a) ${this.condo?.customLabels?.condo?.singular} ${condoName}
      </div>`;
    html += `
     <table class="report-table">
     <thead>
        <tr>
          <th>
            Nome
          </th>
          <th>
            E-mail
          </th>
          <th>
            CPF
          </th>
          <th>
            Telefone
          </th>
          <th>
            Perfil no(a) ${this.condo?.customLabels?.condo?.singular}
          </th>
          <th>
            É ${this.condo?.customLabels?.voter?.singular || 'proprietário'}?
          </th>
          <th>
            É ${this.condo?.customLabels?.resident?.singular || 'condômino'}?
          </th>
          <th>
            Tipo da(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'}
          </th>
          <th>
            Número
          </th>
          <th>
            Bloco
          </th>
          <th>
            Rua
          </th>
          <th>
            CEP
          </th>
        </tr>
      </thead>
    <tbody>
    `;

    for (let line of tableData) {
      // Removes first line
      line = line.splice(0, 1);

      html += this.buildImportReportLine(line);
    }

    html += `
      </tbody>
     </table>
    `;

    html += `</div>`;
    this.printContentService.print(html);
  }

  private buildImportReportLine(data) {
    let line = '';

    line += `<tr class="report-table">`;

    for (const cell of data) {
      line += `
        <td>
          ${cell.length ? cell : '---'}
        </td>`;
    }

    line += `</tr>`;

    return line;
  }

  buildReservationReport(pdfMake, pdfFonts, reservationReportData: ReservationReportData) {
    const data = reservationReportData;
    const reservations = data.reservations;
    const reportTitle = `Relatório de reservas de ${moment(data.initialDate).utc().format('D/MM/YYYY')} até ${moment(data.finalDate)
      .utc()
      .format('D/MM/YYYY')}`;

    const content = [];
    if (data.condoName) {
      content.push({ text: data.condoName, style: 'title' });
    }
    content.push({ text: reportTitle, style: 'header' });

    // Total reservation line
    const total = reservations.reduce((acc, res) => {
      if (res.isApproved() || res.isDone() || res.isCanceled()) {
        acc += res.value;
      }
      return acc;
    }, 0);

    const items = reservations.map(r => {
      return [
        r.createdBy.firstName + ' ' + r.createdBy.lastName.split(' ').pop(),
        r.residence?.identification || '-',
        r.reservationLocal.name,
        r.statusLabel,
        // r.reservationDate.replace(' - ', '\n'),
        r.reservationDate,
        r.value ? this.formatCurrency(r.value.toFixed(2)) : ' - '
      ];
    });

    items.push(['TOTAL', '', '', '', '', this.formatCurrency(total.toFixed(2))]);

    content.push({
      text:
        `Aprovadas: ${reservations.filter(r => r.status == Reservation.STATUS.APPROVED).length} | ` +
        `Pendentes: ${data.reservations.filter(r => r.status == Reservation.STATUS.PENDING).length} | ` +
        `Canceladas: ${data.reservations.filter(r => r.status == Reservation.STATUS.CANCELED).length} | ` +
        `Canceladas com multa: ${reservations.filter(r => r.status == Reservation.STATUS.CANCELED_WITH_PENALTY).length} | ` +
        `Concluídas: ${reservations.filter(r => r.status == Reservation.STATUS.DONE).length}.`,
      style: 'subheader'
    });

    content.push({
      style: 'itemsTable',
      table: {
        widths: ['*', 85, 75, 45, 130, 50],
        body: [
          [
            { text: 'Reservado por', style: 'itemsTableHeader' },
            { text: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade', style: 'itemsTableHeader' },
            { text: 'Local', style: 'itemsTableHeader' },
            { text: 'Status', style: 'itemsTableHeader' },
            { text: 'Data', style: 'itemsTableHeader' },
            { text: 'Valor', style: 'itemsTableHeader' }
          ]
        ].concat(items)
      }
    });

    content.push({
      text: 'Obs: Os valores contabilizados são referentes às reservas aprovadas, canceladas e já concluídas contidas neste relatório.',
      style: 'ps_line'
    });

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    return pdfMake.createPdf({
      content: content,
      styles: {
        title: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        header: {
          fontSize: 13,
          margin: [0, 0, 0, 5]
        },
        subheader: {
          fontSize: 11,
          margin: [0, 10, 0, 5]
        },
        itemsTable: {
          margin: [0, 5, 0, 15]
        },
        itemsTableHeader: {
          bold: true,
          fontSize: 9,
          color: 'black'
        },
        ps_line: {
          fontSize: 8,
          margin: [-5, 0, 0, 0],
          alignment: 'right'
        }
      },
      defaultStyle: {
        fontSize: 9
      },
      pageSize: 'A4'
    });
  }

  /*============================================
 ============= RESIDENCE REPORT ==================
 ==========================================*/

  generateVoterTable(voter: User) {
    let html = '';
    html += `
    <table class="report-table">
        <thead>
            <tr>
                <th class="text-bold active" colspan="5">${capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário'}</th>
            </tr>
            <tr>
                <th class="text-bold">Nome</th>
                <th class="text-bold">Telefone</th>
<!--            <th class="text-bold">CPF</th>-->
                <th class="text-bold" colspan="2">E-mail</th>
            </tr>
        </thead>
        <tbody>
    `;
    if (voter) {
      const voterPicture = (voter.picture && voter.picture.thumbnail) || './../../../assets/img/empty-user-picture.png';
      html += `
            <tr>
                <th>
                    <img class="user-picture" src="${voterPicture}"> ${voter.firstName + ' ' + voter.lastName}
                </th>
                <th>${voter.phone ? formatPhone(voter.phone) : 'Não informado'}</th>
        <!--            <th>${voter.cpf || 'Não informado'}</th>-->
                <th>${voter.email || 'Não informado'}</th>
            </tr>
       `;
    } else {
      html += `
            <tr>
                <th colspan="4">Nenhum ${this.condo?.customLabels?.voter?.singular || 'proprietário'} cadastrado na(o) ${
                  this.condo?.customLabels?.residence?.singular || 'unidade'
                }</th>
            </tr>
       `;
    }
    html += `
        </tbody>
    </table>
    `;

    return html;
  }

  generateResidentsTable(residents: User[]) {
    let html = '';
    html += `
    <table class="report-table">
        <thead>
            <tr>
                <th class="text-bold active" colspan="5">${capitalize(this.condo.customLabels.resident.plural) || 'Condômino'}</th>
            </tr>
            <tr>
                <th class="text-bold">Nome</th>
                <th class="text-bold">Telefone</th>
<!--            <th class="text-bold">CPF</th>-->
                <th class="text-bold" colspan="2">E-mail</th>
            </tr>
        </thead>
        <tbody>
    `;

    if (residents.length) {
      for (const resident of residents) {
        const residentPicture = (resident.picture && resident.picture.thumbnail) || './../../../assets/img/empty-user-picture.png';
        html += `
            <tr>
                <th>
                    <img class="user-picture" src="${residentPicture}"> ${resident.firstName + ' ' + resident.lastName}
                </th>
                <th>${resident.phone ? formatPhone(resident.phone) : 'Não informado'}</th>
<!--            <th>${resident.cpf || 'Não informado'}</th>-->
                <th>${resident.email || 'Não informado'}</th>
            </tr>
        `;
      }
    } else {
      html += `
            <tr>
                <th colspan="4">Não existem usuários cadastrados na(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'}</th>
            </tr>
        `;
    }

    html += `
        </tbody>
    </table>
    `;

    return html;
  }

  generateDependentsTable(dependents: Dependent[]) {
    let html = '';
    html += `
    <table class="report-table">
        <thead>
            <tr>
                <th class="text-bold active" colspan="5">Dependentes</th>
            </tr>
            <tr>
                <th class="text-bold">Nome</th>
                <th class="text-bold">Telefone</th>
<!--            <th class="text-bold">CPF</th>-->
                <th class="text-bold" colspan="2">Parentesco</th>
            </tr>
        </thead>
        <tbody>
    `;

    if (dependents.length) {
      for (const dependent of dependents) {
        const dependentPicture = (dependent.picture && dependent.picture.thumbnail) || './../../../assets/img/empty-user-picture.png';
        html += `
            <tr>
                <th>
                    <img class="user-picture" src="${dependentPicture}"> ${dependent.name}
                </th>
                <th>${dependent.phone ? formatPhone(dependent.phone) : 'Não informado'}</th>
<!--                <th>${dependent.cpf || 'Não informado'}</th>-->
                <th>${dependent.kinship || 'Não informado'}</th>
            </tr>
        `;
      }
    } else {
      html += `
            <tr>
                <th colspan="4">Não existem dependentes cadastrados na(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'}</th>
            </tr>
      `;
    }

    html += `
        </tbody>
    </table>
    `;

    return html;
  }

  generatePetsTable(pets: Pet[]) {
    let html = '';
    html += `
    <table class="report-table">
        <thead>
            <tr>
                <th  class="text-bold active" colspan="5">Pets</th>
            </tr>
            <tr>
                <th class="text-bold">Nome</th>
                <th class="text-bold">Espécie</th>
                <th class="text-bold">Raça</th>
                <th class="text-bold">Porte</th>
            </tr>
        </thead>
        <tbody>
    `;

    if (pets.length) {
      for (const pet of pets) {
        html += `
            <tr>
                <th>${pet.name || 'Não informado'}</th>
                <th>${PET_SPECIES_LABELS[pet.specie]}</th>
                <th>${pet.breed || 'Não informado'}</th>
                <th>${PET_SIZES_LABELS[pet.size] || 'Não informado'}</th>
            </tr>
        `;
      }
    } else {
      html += `
            <tr>
                <th colspan="4">Não existem pets cadastrados na(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'}</th>
            </tr>
      `;
    }

    html += `
        </tbody>
    </table>
    `;

    return html;
  }

  generateVehiclesTable(vehicles: Vehicle[]) {
    let html = '';
    html += `
    <table class="report-table">
        <thead>
            <tr>
                <th class="text-bold active" colspan="5">Veículos</th>
            </tr>
            <tr>
                <th class="text-bold">Marca</th>
                <th class="text-bold">Modelo</th>
                <th class="text-bold">Cor</th>
                <th class="text-bold">Placa</th>
                <th class="text-bold">Tipo</th>
            </tr>
        </thead>
        <tbody>
    `;

    if (vehicles.length) {
      for (const vehicle of vehicles) {
        html += `
            <tr>
                <th>${vehicle.brand || 'Não informado'}</th>
                <th>${vehicle.model || 'Não informado'}</th>
                <th>${vehicle.color || 'Não informado'}</th>
                <th>${vehicle.plate}</th>
                <th>${vehicle.typeLabel}</th>
            </tr>
        `;
      }
    } else {
      html += `
            <tr>
                <th colspan="5">Não existem veículos cadastrados na(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'}</th>
            </tr>
      `;
    }

    html += `
        </tbody>
    </table>
    `;

    return html;
  }

  printResidenceDetails(tableData: {
    residenceIdentification: string;
    voter: User;
    residents: User[];
    dependents: Dependent[];
    pets: Pet[];
    vehicles: Vehicle[];
  }) {
    let html = '';
    html += `
    <div class="residence-details-report">
        <div class="report-title">Relatório da(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'} ${
          tableData.residenceIdentification
        }</div>
    `;

    html += this.generateVoterTable(tableData.voter);
    html += this.generateResidentsTable(tableData.residents);
    html += this.generateDependentsTable(tableData.dependents);
    html += this.generateVehiclesTable(tableData.vehicles);
    html += this.generatePetsTable(tableData.pets);

    html += `
    </div>
    `;

    this.printContentService.print(html, {
      width: 800,
      height: 800,
      title: `RelatorioUnidade${tableData.residenceIdentification}`
    });
  }

  //  ---------------

  formatCurrency(value: any = '') {
    try {
      let tmp = value + '';
      tmp = tmp.replace(/\D/g, '');
      tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
      if (tmp.length > 6) {
        tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
      }
      return 'R$ ' + tmp;
    } catch (e) {
      console.log(e);
      return 'R$ 0,00';
    }
  }
}

export class ReservationReportData {
  condoName: string;
  initialDate: string;
  finalDate: string;
  reservations: Array<Reservation>;

  constructor(initialData, finalDate, reservations: Array<Reservation>, condoName?: string) {
    this.initialDate = initialData;
    this.finalDate = finalDate;
    this.reservations = reservations;
    this.condoName = condoName || '';
  }
}

export interface DeliveryTag {
  _id: string;
  residence: { id: string; identification: string };
  createdAt: string;
  trackingNumbers?: string[];
  protocol: string;
  destPerson?: string;
}
