import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { File } from '../model/file';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { map } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { environment } from '@environment';

@Injectable()
export class FileService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}files/`;
  }

  getAllFiles(queryString?: string): Observable<File[]> {
    return this.http.get(this.endPoint + (queryString || '')).pipe(map((files: any) => files.map(file => new File(file))));
  }

  createFile(file: any) {
    return this.http.post(this.endPoint, file);
  }

  getFilesSignature(queryString?: string) {
    return this.http.get(this.endPoint + '/signature' + (queryString || ''));
  }

  deleteFile(fileId: string) {
    return this.http.delete(this.endPoint + fileId);
  }
  deleteCondoFile(condoId: string, fileId: string) {
    return this.http.delete(`${this.constantService.getV2Endpoint()}condos/${condoId}/files/${fileId}`);
  }

  /**
   * @deprecated Use getById instead.
   */
  getFileById(fileId: string, queryString?: string): Observable<File> {
    return this.http.get(`${this.endPoint}${fileId}${queryString || ''}`) as any;
  }

  getById(fileId: string, query: EcondosQuery): Observable<File> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get(`${this.endPoint}${fileId}`, { params }).pipe(map(f => new File(f)));
  }

  updateFile(fileId: string, file: any) {
    return this.http.put(this.endPoint + fileId, file);
  }

  updateCondoFile(condoId: string, fileId: string, file: any) {
    return this.http.put(`${this.constantService.getV2Endpoint()}condos/${condoId}/files/${fileId}`, file);
  }

  // Método removido devido a dependência do plugin FileTransfer
  // uploadFile(filePath: string, fileName: string) {
  //   return this.http.uploadFile(`${this.endPoint}upload`, filePath, fileName);
  // }

  uploadFilesFromFormData(formData, query = null) {
    let url = `${this.endPoint}upload`;
    if (query) {
      const qs: any = Object.entries(query)
        .map(entry => entry.join('='))
        .join('&');
      url += `?${qs}`;
    }
    return this.http.post(url, formData).pipe(map((files: any) => files.map(file => new File(file))));
  }

  uploadFiles(files, query = null) {
    const formData = new FormData();
    for (const file of files) {
      formData.append(file.name || 'file' + new Date().getTime(), file);
    }
    return this.uploadFilesFromFormData(formData, query);
  }

  setFileTags(fileId: string, tags: string[]) {
    return this.http.put(`${this.endPoint}${fileId}`, { tags });
  }

  uploadBase64(base64, query = null) {
    const file = this.dataURItoBlob(base64);
    return this.uploadFiles([file], query);
  }

  public dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
  }

  handleError(error) {
    return throwError(error.json().error || 'Server error');
  }

  uploadCondoFilesFromFormData(condoId: string, formData: FormData) {
    const url = `${environment.backendUrl}condos/${condoId}/files/upload`;
    return this.http.post(url, formData).pipe(map((files: any) => files.map(file => new File(file))));
  }

  uploadCondoFiles(condoId: string, files: any[], query: EcondosQuery = {}) {
    const formData = new FormData();
    for (const file of files) {
      formData.append(file.name || 'file' + new Date().getTime(), file);
    }
    return this.uploadCondoFilesFromFormData(condoId, formData);
  }
}
