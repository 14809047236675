import { Actuator } from '@api/model/hardware/actuator';

const simpleErrorFeedbackTemplate = `
  <div>
    <strong class="fw-bold">$actuatorName: </strong>$message
  </div>
`;

const detailedErrorFeedbackTemplate = `
  <details>
    <summary>
      <strong class="fw-bold">$actuatorName: </strong>$message
    </summary>

    <div class="bg-neutral-100 border p-2 rounded text-sm m-0">
      <p class="mb-1"># Erro gerado pelo dispositivo</p>
      <pre class="mb-0">$hardwareData</pre>
    </div>
  </details>
`;

export const buildHardwareSyncErrorFeedbackHtml = (errorsResponses: Array<{ error?: any; actuator: Actuator }>): string => {
  const errorsHtml = errorsResponses
    .map(({ actuator, error }) => {
      const hasHardwareData = !!error.hardwareData;

      const isHardwareDataAnArray = hasHardwareData && Array.isArray(error.hardwareData);

      const isHardwareDataAnEmptyArray = isHardwareDataAnArray && !error.hardwareData?.length;

      const isHardwareDataAnEmptyObject =
        !isHardwareDataAnArray && typeof error.hardwareData === 'object' && !Object.keys(error.hardwareData).length;

      if (!isHardwareDataAnEmptyArray && !isHardwareDataAnEmptyObject) {
        return detailedErrorFeedbackTemplate
          .replace('$actuatorName', actuator?.name || '')
          .replace('$message', error?.message || 'Causa desconhecida')
          .replace('$hardwareData', JSON.stringify(error.hardwareData, null, 2).trim());
      }

      return simpleErrorFeedbackTemplate
        .replace('$actuatorName', actuator?.name || '')
        .replace('$message', error?.message || 'Causa desconhecida');
    })
    .join('<br>');

  return `
    <div class="text-start">
      <p>Ocorreram os seguintes problemas:</p>
      <div>${errorsHtml}</div>
    </div>
  `;
};
