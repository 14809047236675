import { Occurrence } from '../interface/occurrence';
import { User } from '../user';
/**
 * Created by Rafael on 23/12/2016.
 */

export class PublicOccurrence extends Occurrence {
  constructor(occurrence?) {
    super(occurrence);
  }

  //Usuário que criou e não fechada.
  canEdit(user: User = new User()): boolean {
    return this.isCreatedBy(user.id) && !this.isClosed();
  }

  //Admin ou criador e não fechada
  canDelete(user: User = new User()): boolean {
    return (user.isOwner() || user.isAdmin() || this.isCreatedBy(user.id)) && !this.isClosed();
  }

  //Ningúem
  canCancel(user: User = new User()): boolean {
    return false;
  }

  //Admin ou criador e não fechada
  canClose(user: User = new User()): boolean {
    return (user.isOwner() || user.isAdmin() || this.isCreatedBy(user.id)) && !this.isClosed();
  }
}
