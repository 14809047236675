import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Condo } from '@api/model/condo';
import { OccurrenceService } from '@api/service/occurrence.service';
import { User } from '@api/model/user';
import { ToastrService } from 'ngx-toastr';
import { FeedCommentComponent } from '../feed-comment/feed-comment.component';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { WEEK_DAYS_LONG } from '@api/util/constants';
import { Occurrence } from '@api/model/interface/occurrence';
import swal from 'sweetalert2';
import { timeout } from 'rxjs/operators';

import * as moment from 'moment';
import { ThemeService } from 'app/theme';
import { CondoContact } from '@api/model/contact/condo.contact';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalShareLinkComponent } from '../../../components/modal-share-link/modal-share-link';
import { createLinkToShareOccurrence } from '@api/utils';
import { ModalShowDataViewersComponent } from '../../../components/modal-show-data-viewers/modal-show-data-viewers.component';
import { AccessService } from '@api/service/access.service';

@Component({
  selector: 'app-gate-card',
  templateUrl: './gate-card.component.html',
  styleUrls: ['./gate-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateCardComponent implements OnChanges {
  DELIVERY_TYPE = GateOccurrence.DELIVERY_TYPE;
  ALLOW_ACCESS_TYPE = GateOccurrence.ALLOW_ACCESS_TYPE;

  STATUS_VALUES = {
    OPENED: {
      labelColor: '#387ef5',
      labelClass: 'badge-primary'
    },
    CLOSED: {
      labelColor: '#32DB64',
      labelClass: 'badge-success'
    },
    CANCELED: {
      labelColor: '#f53d3d',
      labelClass: 'badge-danger'
    }
  };

  @ViewChild(FeedCommentComponent, { static: true }) commentsComponent;

  @Input()
  occurrence: GateOccurrence;

  @Input()
  condo: Condo;

  @Input()
  user: User;

  @Output()
  delete: EventEmitter<GateOccurrence> = new EventEmitter<GateOccurrence>();

  @Output()
  edit: EventEmitter<GateOccurrence> = new EventEmitter<GateOccurrence>();

  isAdmin = false;

  canEdit = false;
  canDelete = false;
  canCancel = false;
  borderClass: string;

  canSelfRegistrate: boolean;
  isPermittedShare: boolean;

  type;

  data: any = {};

  commentsTotal = 0;
  WEEK_DAYS = WEEK_DAYS_LONG;

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private occurrenceService: OccurrenceService,
    private accessService: AccessService,
    private themeService: ThemeService,
    private modalService: BsModalService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.isAdmin = changes.user?.currentValue?.isAdmin() || changes.user?.currentValue?.isOwner();

    const occurrence: GateOccurrence = changes.occurrence?.currentValue;
    const condo: Condo = changes.condo?.currentValue || changes.condo?.previousValue || this.condo;

    if (occurrence.isOpened()) {
      this.borderClass = 'border-opened';
    } else if (occurrence.isClosed()) {
      this.borderClass = 'border-closed';
    } else if (occurrence.isCanceled()) {
      this.borderClass = 'border-canceled';
    }

    if (this.user.isAdmin() || this.user.isOwner()) {
      this.canSelfRegistrate = this.condo.generalParams.accessLiberation.visitorSelfRegistration;
    } else if (this.user.isGatekeeper()) {
      this.canSelfRegistrate =
        this.condo.generalParams.accessLiberation.gateKeeperCanShareVisitorPermissionLink &&
        this.condo.generalParams.accessLiberation.visitorSelfRegistration;
    } else {
      this.canSelfRegistrate =
        this.condo.generalParams.accessLiberation.visitorSelfRegistration && occurrence.createdBy._id === this.user._id;
    }

    this.isPermittedShare =
      occurrence.condoContacts.length > 0 &&
      this.condo?.generalParams?.accessLiberation?.gateKeeperCanShareVisitorPermissionLink &&
      this.condo?.hardwareParams?.visitorsFacialFromUser === 'DISABLED' &&
      (this.condo.hardwareParams.visitorsQRCodeFromUser === 'ENABLED' ||
        this.condo.hardwareParams.visitorPrintedQrCode ||
        this.condo.hardwareParams.visitorsVirtualKeyFromUser === 'ENABLED') &&
      occurrence.createdBy._id === this.user._id;

    this.canEdit = occurrence.isAllowAccessType() && occurrence.canEdit(changes.user?.currentValue, condo);
    this.canDelete = occurrence.canDelete(changes.user?.currentValue);
    this.canCancel = occurrence.isAllowAccessType() && occurrence.canCancel(changes.user?.currentValue);
    this.data.createdBy = `${occurrence.createdBy?.firstName} ${occurrence.createdBy?.lastName} ${
      occurrence.residence ? ' - ' + occurrence.residence?.identification : ''
    }`;
    this.data.createdAt = occurrence.createdAt;
    this.data.title = occurrence.title;
    this.data.description = occurrence.description;
    this.data.type = occurrence.subType;
    this.data.userPicture = occurrence.createdBy?.picture?.url || 'assets/img/empty-user-picture.png';
    this.data.userPictureThumbnail = occurrence.createdBy?.picture?.thumbnail || 'assets/img/empty-user-picture.png';
    this.data.timeAgo = occurrence.title;
    this.data.accessGroups = occurrence.accessGroups;
    this.data.protocol = occurrence.protocol;
    this.data.statusLabel = occurrence.statusLabel;
    this.data.status = occurrence.status;
    this.data.isClosed = occurrence.isClosed();
    this.data.canceledBy = occurrence?.canceledBy || occurrence?.updatedBy;
    this.data.canceledAt = moment(occurrence?.canceledAt || occurrence?.updatedAt).format('DD/MM/YYYY - HH:mm');
    this.data.isCanceled = occurrence.isCanceled();
    this.data.isEdited = occurrence?.createdAt < occurrence?.updatedAt;
    const now = new Date();
    this.data.isValidPermission = now >= new Date(occurrence.startDate) && now <= new Date(occurrence.endDate);

    if (occurrence.isAllowAccessType()) {
      if (occurrence.condoContacts.length) {
        this.data.contacts = occurrence.condoContacts;
      } else if (occurrence.userContacts.length) {
        this.data.contacts = occurrence.userContacts;
      }

      this.data.startDate = moment(occurrence.startDate).format('DD/MM/YYYY');
      this.data.endDate = moment(occurrence.endDate).format('DD/MM/YYYY');
      this.data.daysAllowed = occurrence.daysAllowed || [];
      this.data.shouldCall = occurrence.shouldCall;
    } else if (occurrence.isDeliveryType()) {
      this.data.closingDetails = occurrence.closingDetails;
      this.data.receivedBy = occurrence.receivedBy;
      this.data.trackingNumbers = occurrence.trackingNumbers || [];
    }
    this.commentsTotal = changes.occurrence?.currentValue?.commentsTotal;
  }

  loadComments() {
    this.commentsComponent.loadComments();
  }

  onCommentCreated() {
    this.commentsTotal++;
    this.cdr.detectChanges();
  }

  onCommentDeleted() {
    this.commentsTotal--;
    this.cdr.detectChanges();
  }

  cancelOccurrence(occurrence: GateOccurrence) {
    swal({
      type: 'question',
      title: `Cancelar liberação de acesso`,
      text: `Deseja realmente cancelar esta liberação?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.occurrenceService
          .cancelOcurrence(this.condo.id, occurrence._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível cancelar, tente novamente.');
          });
      }
    }).then(
      () => {
        occurrence.status = Occurrence.STATUS.CANCELED;
        this.data.status = occurrence.status;
        this.data.statusLabel = occurrence.statusLabel;
        this.data.canceledBy = this.user;
        this.data.canceledAt = moment(new Date()).format('DD/MM/YYYY - HH:mm');
        this.borderClass = 'border-canceled';
        this.canCancel = false;
        this.canDelete = false;
        this.canEdit = false;
        this.cdr.detectChanges();
        this.toastr.success(`Liberação Cancelada com sucesso!`);
      },
      () => {
        console.log('clicked cancel');
      }
    );
  }

  shareViaWhatsapp(contact: CondoContact) {
    let type;
    if (this.condo.hardwareParams.visitorsQRCodeFromUser === 'ENABLED' || this.condo.hardwareParams.visitorPrintedQrCode) {
      type = 'QR';
    } else if (this.condo.hardwareParams.visitorsVirtualKeyFromUser === 'ENABLED') {
      type = 'VIRTUAL_KEY';
    } else if (this.condo.hardwareParams.visitorsFacialFromUser === 'ENABLED') {
      type = 'FACIAL';
    }

    if (!type) {
      this.toastr.error('Não foi possível compartilhar o QRCode, tente novamente');
      return;
    }

    const data = {
      type,
      condoContactId: contact._id,
      permissionId: this.occurrence._id
    };

    this.accessService
      .generateVisitorDeviceFromUser(this.condo._id, data)
      .pipe(timeout(10000))
      .subscribe({
        next: async (res: { serial: string }) => {
          if (type === 'FACIAL' && res?.serial) {
            this.toastr.success(`Facial criada com sucesso!`);
          } else {
            const visitorUrl = this.themeService.activeTheme?.qrCodePageUrl || 'https://visitante.econdos.com.br';
            const url = `${visitorUrl}?serial=${res.serial}`;
            const whatsappBreakLineChar = '%0a';

            const days = this.occurrence.daysAllowed.reduce((acc, d) => {
              acc += `${WEEK_DAYS_LONG[d.day]}: ${d.startTime}-${d.endTime}${whatsappBreakLineChar}`;
              return acc;
            }, '');

            let message = `Olá ${contact.firstName}, você recebeu esta chave de acesso de ${this.user.firstName} para o(a) ${this.condo?.customLabels?.condo?.singular} ${this.condo.name}.`;
            message += whatsappBreakLineChar + whatsappBreakLineChar;
            message += `Esta chave é VÁLIDA ${
              this.data.startDate != this.data.endDate
                ? 'entre os dias ' + this.data.startDate + ' e ' + this.data.endDate
                : 'no dia ' + this.data.startDate
            }.`;
            message += whatsappBreakLineChar + whatsappBreakLineChar;
            message += days;
            message += whatsappBreakLineChar;
            message += url;

            const shareUrl = `https://wa.me/?text=${message}`;

            try {
              window.open(shareUrl);
            } catch (e) {
              this.toastr.error('Não foi possível compartilhar e/ou criar o dispositivo, tente novamente');
            }
          }
        },
        error: err => {
          const errorMessage = err?.originalError?.message || 'Não foi possível gerar a chave de acesso, tente novamente';
          this.toastr.error(errorMessage);
        }
      });
  }

  shareLink() {
    const link = createLinkToShareOccurrence(this.condo._id, this.occurrence._id, this.themeService.activeTheme);
    const initialState = { link };
    this.modalService.show(ModalShareLinkComponent, { initialState, ignoreBackdropClick: true, class: 'modal-lg' });
  }

  showDataViewersModal() {
    const initialState = {
      condo: this.condo,
      model: 'occurrence',
      documentId: this.occurrence._id
    };
    this.modalService.show(ModalShowDataViewersComponent, { initialState, class: 'modal-lg' });
  }
}
