import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { from, Observable, Subscriber } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { Condo } from '@api/model/condo';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { CondoServiceV2 } from '@api/serviceV2/condo.service';
import { CommonModule } from '@angular/common';
import { IconifyComponent } from '../../web-components/iconify/iconify.component';

@Component({
  selector: 'app-condo-autocomplete',
  templateUrl: './condo-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CondoAutocompleteComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CondoAutocompleteComponent
    }
  ],
  standalone: true,
  imports: [CommonModule, TypeaheadModule, FormsModule, IconifyComponent]
})
export class CondoAutocompleteComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator {
  @Input() label = '';
  @Input() condosToHide = [];
  @Output() createCondo = new EventEmitter();

  dataSource$: Observable<Condo[]>;
  textSearch;

  condo: Condo;

  status: 'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS' = 'IDLE';

  noResult = false;

  touched = false;

  isDisabled = false;

  isValid = true;

  onChange = value => {};

  onTouched = () => {};

  constructor(private condoService: CondoServiceV2) {}

  ngOnInit(): void {
    this.dataSource$ = new Observable((observer: Subscriber<string>) => observer.next(this.textSearch)).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      tap(() => this.markAsTouched()),
      mergeMap((search: string) => {
        const query: EcondosQuery = {
          $select: 'name address city state',
          $populate: [{ path: 'pictures', select: 'thumbnail url' }],
          name: { $regex: search, $options: 'i' },
          $sort: 'name city',
          'params.searchable': { $ne: 'DISABLED' }
        };
        if (this.condosToHide) {
          query._id = { $nin: this.condosToHide };
        }
        return this.condoService.getCondos(query).pipe(
          map(res => res.condos),
          catchError(e => {
            this.status = 'ERROR';
            return from([]);
          })
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {}

  onSelect(event: TypeaheadMatch): void {
    if (!this.isDisabled) {
      this.condo = event.item;
      this.markAsTouched();
      this.onChange(this.condo);
    }
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
  }

  clearValue() {
    this.condo = null;
    this.textSearch = '';
    this.noResult = false;
    this.onChange(null);
  }

  // Angular form methods
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(condo: Condo): void {
    this.condo = condo;
    if (condo) {
      this.textSearch = condo.name || '';
    } else {
      this.textSearch = '';
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const residence = control.value;
    if (!this.textSearch && residence) {
      this.isValid = false;
      return {
        residenceNotSelected: {
          residence
        }
      };
    } else {
      this.isValid = true;
      return null;
    }
  }
}
