import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { CondoContact } from '@api/model/contact/condo.contact';
import { CondoContactService } from '@api/service/condo.contact.service';
import { CondoContactServiceV3 } from '@api/serviceV3/condo.contact.service';
import { UtilService } from '../../services/util.service';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { EcondosQuery } from '@api/model/query';

@Component({
  selector: 'condo-contact-picker',
  templateUrl: 'condo-contact-picker.html',
  styleUrls: ['./condo-contact-picker.scss']
})
export class CondoContactPickerComponent {
  @Output() onContactSelect: EventEmitter<CondoContact> = new EventEmitter<CondoContact>();
  @Output() onNewContactClick: EventEmitter<any> = new EventEmitter();
  @Input() placeholder = 'Busque aqui';
  @Input() limit: number;

  status: Status = new Status();

  typeAheadDataSource$: Observable<any>;
  selectedLabel = '';
  selectedObject;
  condoContacts: Array<CondoContact> = [];

  noResult = false;

  private _condo: Condo;

  onTypeAheadSelect = evt => {
    this.selectedObject = evt.item;
    this.selectedLabel = this.getContactNameLabel(evt.item);
    this.onContactSelect.emit(evt.item);
  };

  constructor(
    private condoContactService: CondoContactService,
    private utilService: UtilService,
    private condoContactServiceV3: CondoContactServiceV3
  ) {
    this._condo = this.utilService.getLocalCondo();

    this.typeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.selectedLabel);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 3),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select:
            'firstName lastName banned banReason bannedAt type company service emails birthDate phones specialNeeds specialNeedsDetails',
          $populate: [
            { path: 'picture', select: 'thumbnail url' },
            {
              path: 'ids',
              select: 'typeLabel number',
              populate: [{ path: 'pictures', select: 'url thumbnail type name format' }]
            },
            {
              path: 'condoVehicles',
              select: 'plate model type brand color pictures',
              populate: [{ path: 'pictures', select: 'url thumbnail type name format' }]
            },
            { path: 'bannedBy', select: 'firstName lastName' }
          ],
          $sort: 'firstName lastName',
          $limit: this.limit || 50,
          deleted: false
        };

        return this.condoContactServiceV3.searchByToken(this._condo._id, token, query).pipe(map(r => r.contacts));
      }),
      catchError(e => {
        this.status.setAsError();
        return of([]);
      })
    );
  }

  getContactNameLabel(contact) {
    if (contact) {
      return contact.company && contact.fullName ? contact.fullName + ' - ' + contact.company : contact.company || contact.fullName;
    } else {
      return '';
    }
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
  }

  changeTypeaheadLoading(isLoading: boolean): void {
    if (isLoading) {
      this.status.setAsDownloading();
    } else {
      if (!this.status.isError()) {
        this.status.setAsSuccess();
      }
    }
  }
}
