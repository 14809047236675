import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { EcondosQuery } from '@api/model/query';
import { Status } from '@api/model/status';
import { ResidenceServiceV2 } from '@api/serviceV2/residence.service';
import { capitalize } from '@api/util/util';
import { AgGridAngular } from 'ag-grid-angular';
import { UtilService } from 'app/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { merge, noop, of, Subject } from 'rxjs';
import { catchError, ignoreElements, mergeMap, retry, takeUntil, tap, timeout } from 'rxjs/operators';

import swal from 'sweetalert2';

@Component({
  selector: 'residences-ideal-fractions-edit',
  templateUrl: 'residences-ideal-fractions-edit.html',
  styleUrls: ['residences-ideal-fractions-edit.scss']
})
export class FractionsComponent implements OnInit, OnDestroy {
  @ViewChild('filter', { static: true }) filter;
  @ViewChild('agGrid') agGrid: AgGridAngular;

  condo: Condo;
  columnDefs: {}[] = [];
  rowData: {}[] = [];
  selectedRows = [];

  status = new Status();
  totalData = 0;
  totalFraction = 0;

  csvExport;

  TYPES = {
    HOUSE: 'Casa',
    APARTMENT: 'Apartamento',
    BUSINESS: 'Comercial',
    OTHER: 'Outro'
  };

  loadingStatus: 'LOADING' | 'SUCCESS' | 'ERROR';
  progress = 0;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(private residenceService: ResidenceServiceV2, private utilService: UtilService, private toastr: ToastrService) {
    this.condo = this.utilService.getLocalCondo();

    const floatingFilter = {
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        filterOptions: ['contains'],
        suppressAndOrCondition: true,
        debounceMs: 200
      }
    };

    this.columnDefs = [
      {
        headerName: '#',
        field: 'index',
        width: 120,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        editable: false,
        ...floatingFilter
      },
      {
        headerName: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        field: 'residence',
        resizable: true,
        flex: 2,
        ...floatingFilter
      },
      { headerName: 'Bloco', field: 'block', resizable: true, flex: 2, ...floatingFilter },
      { headerName: 'Número', field: 'number', resizable: true, flex: 1, ...floatingFilter },
      { headerName: 'Tipo', field: 'type', resizable: true, flex: 2, ...floatingFilter },
      { headerName: 'Fração ideal (%)', field: 'fraction', resizable: true, editable: true, flex: 2 }
    ];
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getData() {
    this.progress = 0;

    const query: EcondosQuery = {
      $select: 'identification address block number type fraction',
      $sort: 'identification'
    };

    this.status.setAsDownloading();

    this.residenceService
      .getResidencesReport(this.condo._id, query)
      .pipe(
        timeout(15000),
        mergeMap(([finalResult, progress]) =>
          merge(
            progress.pipe(
              tap((value: number) => {
                this.progress = value;
              }),
              ignoreElements()
            ),
            finalResult
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        ({ count, data: residences }) => {
          this.totalData = count;
          this.rowData = residences.map((residence, index) => ({
            index: ++index,
            id: residence._id,
            block: residence.block,
            number: residence.number,
            fraction: residence.fraction,
            type: this.TYPES[residence.type],
            residence: residence.identification
          }));

          this.totalFractionValue();
          this.status.setAsSuccess();
        },
        (err: Error) => {
          this.status.setAsError();
          this.rowData = [];
        }
      );
  }

  onCellValueChanged(event) {
    this.totalFractionValue();

    if (this.totalFraction <= 100) {
      this.residenceService.updateResidence(this.condo._id, event.data.id, event.data).subscribe(
        () => this.toastr.success('Fração ideal editada com sucesso!'),
        err => this.toastr.error(err.message || 'Não foi possivel alterar fração ideal')
      );
    } else {
      this.toastr.warning('Não é permitido valor total acima de 100% para fração ideal, a última alteração foi cancelada');
    }
  }

  // dados pra exportar o csv
  onGridReady(event) {
    this.csvExport = event.api;
  }

  // csv
  onBtnExport() {
    this.csvExport.exportDataAsCsv();
  }

  totalFractionValue() {
    this.totalFraction = 0;
    this.rowData.forEach((v: Residence) => (v.fraction ? (this.totalFraction += Number(v.fraction)) : 0));
  }

  async onSelectedRows() {
    this.selectedRows = this.agGrid.api.getSelectedNodes().map(node => node.data);

    if (!this.selectedRows.length) {
      return this.toastr.error(
        'É necessário selecionar ao menos um(a) ' + this.condo?.customLabels?.residence?.singular ||
          'unidade' + ' para editar fraçào ideal em massa'
      );
    }

    const fraction = await swal({
      title: 'Alterar fração ideal',
      text: 'Informe a fração que deseja aplicar às(os) ' + this.condo?.customLabels?.residence?.plural || 'unidades' + ' selecionadas',
      type: 'info',
      input: 'number',
      inputAttributes: {
        min: '0',
        max: '100'
      },
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      inputValue: 0,
      inputPlaceholder: 'Ex: 100%',
      showLoaderOnConfirm: true
    }).catch(() => console.log('Clicked Cancel'));

    if (!fraction) {
      return;
    }

    const confirmFractionUpdate = await swal({
      title: `Tem certeza que deseja continuar?`,
      text: `Ao continuar, você irá aplicar a fração ideal de ${fraction}% às(os) ${
        this.condo?.customLabels?.residence?.plural || 'unidades'
      } selecionadas`,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    });

    if (!confirmFractionUpdate) {
      return;
    }

    const requests = this.selectedRows.map(residence =>
      this.residenceService.updateResidence(this.condo._id, residence.id, { fraction }).pipe(
        tap(() => {
          residence.fraction = fraction;
          this.agGrid.api.applyTransaction({ update: [residence] });
        })
      )
    );

    this.proccessRequest(requests);
  }

  proccessRequest(requests, applyPipe = true) {
    const errors = [];

    if (applyPipe) {
      requests = requests.map(request =>
        request.pipe(
          retry(2),
          timeout(10000),
          catchError(err => {
            errors.push(request);
            return of();
          })
        )
      );
    }

    swal({
      type: 'info',
      title: 'Atualizando ' + this.condo?.customLabels?.residence?.plural || 'unidades',
      text: `0 de ${requests.length} atualizados`
    });
    swal.showLoading();

    let counter = 0;

    merge(...requests, 2).subscribe(
      () => {
        ++counter;
        if (!errors.length && counter === requests.length) {
          swal({
            type: 'success',
            title: 'Dados atualizados com sucesso'
          });
        } else {
          swal.getContent().textContent = `${counter} de ${requests.length} atualizados`;
        }
      },
      noop,
      async () => {
        if (errors.length) {
          const result = await swal({
            text: `Houveram ${errors.length} erros na atualização`,
            type: 'info',
            showCancelButton: true,
            confirmButtonText: 'Tentar novamente',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
          });
          if (result === true) {
            this.proccessRequest(errors, false);
          }
        }
      }
    );
  }
}
