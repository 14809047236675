import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface CondoUser {
  condo?: string;
  user?: string;
  customRoles?: string;
  blockUserAccess?: boolean;
  blockMobileUserAccess?: boolean;
}

@Injectable()
export class CondoUsersService {
  protected endPoint: string;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }

  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; data: CondoUser[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/condo-users`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        data: res.body
      }))
    );
  }

  getById(condoId: string, userId: string, params: EcondosQuery = {}): Observable<CondoUser> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/condo-users/${userId}`, options).pipe(map((res: any) => res.body as CondoUser));
  }

  updateCondoUser(condoId: string, userId: string, params: { blockUserAccess: boolean; blockMobileUserAccess: boolean }) {
    return this.http.put(`${this.endPoint}${condoId}/condo-users/${userId}`, params);
  }
}
