import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Occurrence } from '@api/model/interface/occurrence';
import { Condo } from '@api/model/condo';
import { DeliveryTrackingHistory } from '@api/model/delivery-tracking-history';
import { EcondosQuery } from '@api/model/query';

import { DeliveryTrackingHistoryService } from '@api/serviceV3/delivery-tracking-history.service';

type RequestStatus = 'LOADING' | 'SUCCESS' | 'ERROR';

@Component({
  selector: 'app-modal-delivery-tracking-history',
  templateUrl: './modal-delivery-tracking-history.component.html',
  styleUrls: ['./modal-delivery-tracking-history.component.scss']
})
export class ModalDeliveryTrackingHistoryComponent implements OnInit {
  public condo: Condo;
  public delivery: Occurrence;

  public history: DeliveryTrackingHistory[] = [];

  public loadHistoryStatus: RequestStatus;
  constructor(private deliveryTrackingHistoryService: DeliveryTrackingHistoryService, private modalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.getDeliveryTrackingHistory();
  }

  public async getDeliveryTrackingHistory(): Promise<void> {
    this.loadHistoryStatus = 'LOADING';

    try {
      const query = this.getDeliveryTrackingHistoryQuery();

      const history = await this.deliveryTrackingHistoryService.getByDeliveryId(this.condo._id, this.delivery._id, query).toPromise();
      this.history = history;

      this.loadHistoryStatus = 'SUCCESS';
    } catch (error) {
      this.loadHistoryStatus = 'ERROR';

      console.error(error);
    }
  }

  public closeModal(): void {
    this.modalRef.hide();
  }

  private getDeliveryTrackingHistoryQuery(): EcondosQuery {
    const query: EcondosQuery = {
      $select: ['condo', 'residence', 'user', 'delivery', 'date', 'sequence', 'obs', 'createdBy', 'createdAt'].join(' '),
      $populate: [
        { path: 'condo', select: 'name' },
        { path: 'residence', select: 'type identification voter' },
        { path: 'delivery', select: 'type subType title createdAt' },
        {
          path: 'sequence',
          select: 'condo step title description shouldCloseDelivery shouldNotifyUser createdBy createdAt',
          options: { withDeleted: true }
        },
        { path: 'user', select: 'firstName lastName' },
        { path: 'createdBy', select: 'firstName lastName' }
      ],
      $sort: '-createdAt'
    };

    return query;
  }
}
