import { Component, OnInit, ViewChild } from '@angular/core';

import swal from 'sweetalert2';
import { OccurrenceService } from '../../../api/service/occurrence.service';
import { OccurrenceBuilder } from '../../../api/model/occurrence/occurrence.builder';
import { Occurrence } from '../../../api/model/interface/occurrence';
import { UntypedFormBuilder } from '@angular/forms';
import { OccurrenceCreator } from '../occurrence.creator';
import { OccurrenceFileUploader } from '../occurrence.creator.file.list/files.scroll';
import * as moment from 'moment';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'public-creator',
  inputs: ['user', 'condo'],
  outputs: ['onOccurrenceCreated', 'onOccurrenceUpdated'],
  templateUrl: 'public.occurrence.creator.html'
})
export class PublicCreatorComponent extends OccurrenceCreator implements OnInit {
  @ViewChild(OccurrenceFileUploader) occurrenceFileUploader: OccurrenceFileUploader;

  public selectedResidence;
  residences = [];

  constructor(formBuilder: UntypedFormBuilder, private occurrenceService: OccurrenceService) {
    super(formBuilder);
  }

  ngOnInit() {
    this.residences = this.user.getResidences();
    this.initSelectedResidence();
  }

  initSelectedResidence() {
    this.selectedResidence = this.user.getResidences()[0]._id || this.user.getResidences()[0];
  }

  hideOccurrenceCreator() {
    if (this.isEditingOccurrence) {
      this.initSelectedResidence();
    }
    super.hideOccurrenceCreator();
  }

  editOccurrence(occurrence) {
    super.editOccurrence(occurrence);
    this.selectedResidence = occurrence.residence._id ? occurrence.residence._id : occurrence.residence;
  }

  canSubmit() {
    if (!this.occurrenceForm.valid) {
      this.title.markAsTouched();
      this.description.markAsTouched();
      return false;
    }
    return true;
  }

  buildOccurrence() {
    const occurrence: any = {
      title: this.title.value,
      description: this.description.value,
      type: Occurrence.PUBLIC_TYPE,
      pictures: this.attachments.map(att => att._id),
      residence: this.selectedResidence._id ? this.selectedResidence._id : this.selectedResidence
    };
    return occurrence;
  }

  updateOccurrence() {
    if (!this.canSubmit()) {
      return;
    }
    if (!this.editingOccurrence) {
      return;
    }

    this.isSubmiting = true;

    const occurrence = this.buildOccurrence();

    const occurrenceId = this.editingOccurrence.id;
    this.occurrenceService
      .updateOcurrence(this.condo._id, occurrenceId, occurrence)
      .pipe(timeout(10000))
      .subscribe(
        resp => {
          this.editingOccurrence.title = this.title.value;
          this.editingOccurrence.description = this.description.value;
          this.editingOccurrence.pictures = this.attachments;
          this.editingOccurrence.residence = this.user.getResidences().find(res => res._id == occurrence.residence);
          this.onOccurrenceUpdated.emit(this.editingOccurrence);
          this.isSubmiting = false;
          this.cancelEditing();
          this.initSelectedResidence();
        },
        err => {
          this.isSubmiting = false;
          swal({
            text: 'Não foi possível editar a ocorrência, tente novamente'
          });
        }
      );
  }

  createOccurrence() {
    if (!this.canSubmit()) {
      return;
    }

    this.isSubmiting = true;

    const occurrence = this.buildOccurrence();

    this.occurrenceService
      .createOccurrence(this.condo._id, occurrence)
      .pipe(timeout(10000))
      .subscribe(
        (resp: any) => {
          // Adiciona os atributos na ocorrência para que a mesma seja exibida corretamente pela feed sem a necessidade de buscar os dados no servidor
          occurrence._id = resp._id;
          occurrence.condo = this.condo;
          occurrence.createdBy = this.user;
          occurrence.createdAt = moment().utc().toDate();
          occurrence.pictures = this.attachments;
          occurrence.residence = this.user.getResidences().find(res => res._id == occurrence.residence);
          this.onOccurrenceCreated.emit(OccurrenceBuilder.build(occurrence));
          this.initializeForm();
          this.initSelectedResidence();
          this.showing = false;
          this.isSubmiting = false;
        },
        err => {
          this.isSubmiting = false;
          swal({
            text: 'Não foi possível criar a ocorrência, tente novamente'
          });
        }
      );
  }

  uploadFile() {
    this.occurrenceFileUploader.pickFile();
  }
}
