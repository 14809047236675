import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';

@Component({
  selector: 'admin-condo-picker-modal',
  templateUrl: 'admin.condo.picker.modal.html',
  styleUrls: ['./admin.condo.picker.modal.scss']
})
export class AdminCondoPickerModal {
  @Output() onCondoSelect = new EventEmitter();
  @ViewChild('condoFilterInput') searchInput;

  condos: Condo[] = [];
  defaultCondo: Condo;
  searchTerm: UntypedFormControl = new UntypedFormControl();
  callback;

  constructor(private condoService: CondoService, private modalService: BsModalService, public bsModalRef: BsModalRef) {
    this.searchTerm.valueChanges
      .pipe(
        map(v => v.trim()),
        filter(v => v.length > 1),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(value => {
          const terms = value
            .split(' ')
            .map(word => removeAccents(word))
            .map(word => replaceVowelsToAccentedVowels(word))
            .join(' ');

          return this.condoService.getCondos({
            $populate: [{ path: 'whiteLabel', select: 'icon' }],
            name: { $regex: terms, $options: 'i' },
            $sort: 'name'
          });
        })
      )
      .subscribe(({ condos, count }) => {
        this.condos = condos;
      });

    // Just to auto focus search input
    this.modalService.onShown
      .pipe(
        tap(() => {
          if (this.searchInput?.nativeElement) {
            this.searchInput.nativeElement.focus();
          }
        })
      )
      .subscribe();
  }

  onSubmit(condo: Condo) {
    this.condoService.getCondoById(condo._id, '?$populate=pictures').subscribe(condo => {
      this.onCondoSelect.emit(condo);
      if (this.callback) {
        this.callback(condo);
      }
      this.bsModalRef.hide();
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
