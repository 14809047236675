import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, noop, Observable, Subject } from 'rxjs';
import { HttpService } from './http.service';
import { Condo } from '../api/model/condo';
import * as socketIo from 'socket.io-client';
import * as customParser from 'socket.io-json-parser';
import swal from 'sweetalert2';
import { distinctUntilChanged, filter, map, scan, startWith, switchMap, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';
import { loadUserPreferences, UserPreferences } from '../shared/utils';
import { BuildHardwareEvent } from '../pages/gate/gate-events/gate-events-interfaces/hardware-event-builders';
import { EVENT_TYPES } from '../pages/gate/gate-events/gate-events-constants/constants';
import { HardwareWarningEvent } from '../pages/gate/gate-events/gate-events-interfaces/hardware-warning-event';
import { HardwarePanicEvent } from '../pages/gate/gate-events/gate-events-interfaces/hardware-panic-event';
import { HardwareResidentEvent } from '../pages/gate/gate-events/gate-events-interfaces/hardware-resident-event';
import { HardwareVisitorEvent } from '../pages/gate/gate-events/gate-events-interfaces/hardware-visitor-event';
import { HardwareUnknownPlateEvent } from 'app/pages/gate/gate-events/gate-events-interfaces/hardware-plate-recognizer-event';
import { User } from '@api/model/user';
import { Actuator } from '@api/model/hardware/actuator';
import { SessionService } from '@api/service/session.service';

export enum SOCKET_CONNECTION_STATUS {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  RECONNECTING = 'RECONNECTING',
  NOT_ALIVE = 'NOT_ALIVE'
}

export enum SOCKET_DISCONNECT_REASONS {
  TRANSPORT_ERROR = 'transport error',
  SERVER_DISCONNECT = 'server namespace disconnect',
  CLIENT_DISCONNECT = 'io client disconnect',
  PING_TIMEOUT = 'ping timeout',
  TRANSPORT_CLOSE = 'transport close'
}

@Injectable()
export class HardwareSocketService {
  EVENT_TYPES = EVENT_TYPES;

  private socketIO;
  private initialized = false;
  private _isEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isEnabled$: Observable<boolean> = this._isEnabled.asObservable();

  onConnect$: Subject<any> = new Subject();
  onDisconnect$: Subject<any> = new Subject();
  private _onData$: Subject<any> = new Subject();
  onData$: Observable<any> = this._onData$.asObservable();
  private _connectionStatus$: BehaviorSubject<SOCKET_CONNECTION_STATUS> = new BehaviorSubject(SOCKET_CONNECTION_STATUS.DISCONNECTED);
  connectionStatus$: Observable<SOCKET_CONNECTION_STATUS> = this._connectionStatus$.asObservable();
  private _gateEvents$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  gateEvents$: Observable<any> = this._gateEvents$.asObservable();
  private _onError$: Subject<any> = new Subject();
  onError$: Observable<any> = this._onError$.asObservable();
  private debugger$: Subject<any> = new Subject();
  onDebug$: Observable<any> = this.debugger$.asObservable();

  private _onIntercomData$: Subject<any> = new Subject();
  public onIntercomData$: Observable<any> = this._onIntercomData$.asObservable();

  private _onChatData$: Subject<any> = new Subject();
  onChatData$: Observable<any> = this._onChatData$.asObservable();

  private subscribedCondos = [];

  private resetKeepAlive$ = new Subject();

  private unsubscribe: Subject<void> = new Subject();

  private debug = false;

  private notificationsPreferences: any = {};

  constructor(
    private http: HttpService,
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {
    this.sessionService.user$
      .pipe(
        // Check if user or user defaultCondo has changed
        distinctUntilChanged(
          (previous, curr) =>
            curr &&
            previous &&
            curr._id === previous._id &&
            curr.defaultCondo &&
            previous.defaultCondo &&
            curr.defaultCondo._id === previous.defaultCondo._id
        )
      )
      .subscribe(user => {
        if (user) {
          const hasAccess =
            (user.isOwner() || user.isAdmin() || user.isGatekeeper()) && user.defaultCondo && user.defaultCondo.isHardwareEnabled();
          if (hasAccess) {
            this.unsubscribe.next(null);
            const userPreferences = loadUserPreferences(user._id);
            this.notificationsPreferences = userPreferences.gateEventsNotification || {};
            this.initialize(user.token, user.defaultCondo._id);
            this.unsubscribeAllCondos();
            this.subscribeCondo(user.defaultCondo);
            this.subscribeAlerts();
            this._isEnabled.next(true);
          } else {
            this._isEnabled.next(false);
            this.disconnect();
          }
        }
      });
  }

  updateNotificationPreferences(userPreferences: UserPreferences) {
    this.notificationsPreferences = userPreferences.gateEventsNotification;
  }

  initialize(accessToken, condoId: string) {
    // Initialize only when it is not initialized or when connection status is disconnected
    if (accessToken && (!this.initialized || this._connectionStatus$.value === SOCKET_CONNECTION_STATUS.DISCONNECTED)) {
      this._connectionStatus$.next(SOCKET_CONNECTION_STATUS.CONNECTING);
      this.socketIO = socketIo(environment.socketUrl, {
        parser: customParser,
        transports: ['websocket'],
        forceNew: true,
        reconnectionAttempts: Infinity,
        reconnection: true,
        reconnectionDelay: 2000,
        timeout: 10000
      });

      this.socketIO.on('unauthorized', err => this.handleUnauthorized(err));
      this.socketIO.on('connect', () => {
        this.initialized = true;
        this.socketIO.emit('authentication', { accessToken });
        this.onConnect$.next(SOCKET_CONNECTION_STATUS.CONNECTED);
        // Initialize as not alive to check if linear is connected
        this._connectionStatus$.next(SOCKET_CONNECTION_STATUS.NOT_ALIVE);
        this.checkConnection(condoId);
      });
      this.socketIO.on('disconnect', reason => this.handleDisconnection(reason));
      this.socketIO.on('reconnecting', attemptNumber => {
        this._connectionStatus$.next(SOCKET_CONNECTION_STATUS.RECONNECTING);
        console.log('RECONNECTING', attemptNumber);
      });
      this.socketIO.on('error', error => this.handleError(error));
      this.socketIO.on('data', data => this.handleData(data));
      this.socketIO.on('DEBUG', data => this.handleDebug(data));
      this.socketIO.on('chat', data => this.handleChatData(data));
      this.socketIO.on('keep-alive', alive => this.resetKeepAlive(alive || {}));
      this.socketIO.on('intercom', data => this.handleIntercomData(data));
      // this.socketIO.on('post', data => {
      //   console.log('post', data)
      //   this._onData$.next(data);
      // });

      this.initializeKeepAliveTimer();
    }
  }

  disconnect() {
    if (this.socketIO) {
      this.socketIO.disconnect();
    }
    this.unsubscribeAllCondos();
    this.unsubscribe.next(null);
  }

  sendCommand(condoId, command, input, pages?, hardware: 'linear' | 'nice-controller' = 'linear', actuators?: Actuator[]) {
    return this.http.post(`${environment.backendUrl}condos/${condoId}/${hardware}`, {
      command,
      input,
      pages,
      actuators
    });
  }

  sendLinearCommand(condoId, command, input, params: { pages?: string; linearId?: string }) {
    const body: any = {
      command,
      input,
      ...(params?.pages && { page: params.pages }),
      ...(params?.linearId && { linearId: params.linearId })
    };

    return this.http.post(`${environment.backendUrl}condos/${condoId}/linear`, body);
  }

  sendCommandViaServerMode(
    condoId: string,
    commandParams: {
      command: string;
      input: any;
      pages?: any;
      createdBy: User;
    }
  ) {
    return this.http.post(`${environment.backendUrl}condos/${condoId}/linear/server`, commandParams);
  }

  subscribeCondo(condo: Condo) {
    const condoIndex = this.subscribedCondos.findIndex(c => c === condo._id);
    if (condoIndex === -1) {
      this.subscribedCondos.push(condo._id);
    }
  }

  unsubscribeCondo(condo: Condo) {
    const condoIndex = this.subscribedCondos.findIndex(c => c === condo._id);
    this.subscribedCondos = this.subscribedCondos.filter(id => id !== condo._id);
  }

  unsubscribeAllCondos() {
    this.subscribedCondos = [];
  }

  private handleDisconnection(reason: SOCKET_DISCONNECT_REASONS) {
    console.log('DISCONNECTED BY', reason);
    this.onDisconnect$.next(SOCKET_CONNECTION_STATUS.DISCONNECTED);
    this._connectionStatus$.next(SOCKET_CONNECTION_STATUS.DISCONNECTED);
  }

  private handleData(data) {
    const condoId = data.condoId;
    const isSubscribed = this.subscribedCondos.findIndex(id => id === condoId) !== -1;
    if (isSubscribed) {
      this.log(data, `data from ${condoId}`);
      this._onData$.next(data);
      this.resetKeepAlive({ condoId });
    }
  }

  private handleDebug(data: any) {
    this.debugger$.next(data);
  }

  private handleError(error) {
    this._onError$.next(error);
    console.log('error', error);
  }

  private handleIntercomData(data) {
    this._onIntercomData$.next(data);
    this.log(data, `data from ${data.condoId}`);
  }

  private handleChatData(data) {
    this._onChatData$.next(data);
    this.log(data, `data from ${data.condoId}`);
  }

  private handleUnauthorized(err) {
    console.error('There was an error with the authentication:', err.message);
    swal({
      type: 'error',
      title: 'Falha de conexão com hardware',
      text: 'Não foi possível conectar com o hardware de seu condomínio devido a um problema de autenticação, atualize a página para tentar novamente. Caso o problema persista, entre em contato com o suporte do eCondos.'
    });
  }

  private initializeKeepAliveTimer(period = 360000) {
    this.resetKeepAlive$
      .pipe(
        startWith(undefined),
        tap(status => {
          if (status === SOCKET_CONNECTION_STATUS.CONNECTED) {
            this._connectionStatus$.next(SOCKET_CONNECTION_STATUS.CONNECTED);
          }
        }),
        switchMap(() =>
          interval(period).pipe(
            tap(() => {
              if (this._connectionStatus$.value === SOCKET_CONNECTION_STATUS.CONNECTED) {
                this._connectionStatus$.next(SOCKET_CONNECTION_STATUS.NOT_ALIVE);
              }
            })
          )
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe(noop);
  }

  public resetKeepAlive({ condoId }) {
    const isSubscribed = this.subscribedCondos.findIndex(id => id === condoId) !== -1;
    if (isSubscribed) {
      this.resetKeepAlive$.next(SOCKET_CONNECTION_STATUS.CONNECTED);
    }
  }

  checkConnection(condoId) {
    this.onData$
      .pipe(
        filter(response => response && response.command === 'readDateTime' && response.condoId === condoId && response.result),
        take(1),
        timeout(10000)
      )
      .subscribe(
        response => {
          this._connectionStatus$.next(SOCKET_CONNECTION_STATUS.CONNECTED);
        },
        err => {
          console.log(err);
          if (this._connectionStatus$.value !== SOCKET_CONNECTION_STATUS.DISCONNECTED) {
            this._connectionStatus$.next(SOCKET_CONNECTION_STATUS.NOT_ALIVE);
          }
        }
      );
    // Dispatch a read date time commando on linear just to check if it is connected
    this.sendCommand(condoId, 'readDateTime', null).subscribe(noop);
  }

  subscribeAlerts() {
    this.onData$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          data =>
            ['eventSentAutomatically', 'residentPrintedQrCode', 'visitorPrintedQrCode'].includes(data.command) &&
            data.hardwareEvent?._id &&
            data.hardwareEvent?.typeCode !== 12 &&
            data.result?.tipoEventoNum !== 12
        ),
        map(data => BuildHardwareEvent(data)),
        // TODO Voltar o filter quando resolver o problema do registeredAt
        // filter(event => {
        //   const filterDate = moment().subtract(30, 'm');
        //   If para evitar que eventos antigos fiquem poluindo a interface do sistema
        // return filterDate.isBefore(event.registeredAt || event.createdAt);
        // }),
        tap(event => {
          const isEventEnabled = this.isEventEnabled(event.eventType);
          if (isEventEnabled) {
            this.showEvent(event);
          }
        }),
        scan((acc, current) => {
          if (acc.length >= 21) {
            acc.pop();
          }
          return [current, ...acc];
        }, [])
      )
      .subscribe(events => this._gateEvents$.next(events));
  }

  isEventEnabled(eventType: string) {
    const isWarning = eventType === EVENT_TYPES.WARNING;
    const isResident = eventType === EVENT_TYPES.RESIDENT;
    const isPanic = eventType === EVENT_TYPES.PANIC;
    const isUknownPlate = eventType === EVENT_TYPES.UNKNOWN_PLATE;
    const isVisitor = eventType === EVENT_TYPES.VISITOR;
    const isOthers = eventType === EVENT_TYPES.OTHER;
    const isTrigger = eventType === EVENT_TYPES.TRIGGER;
    let eventEnabled = false;
    if (isWarning) {
      eventEnabled = this.notificationsPreferences.warnings;
    } else if (isResident) {
      eventEnabled = this.notificationsPreferences.resident;
    } else if (isPanic) {
      eventEnabled = this.notificationsPreferences.panic;
    } else if (isUknownPlate) {
      eventEnabled = this.notificationsPreferences.unknownPlate;
    } else if (isVisitor) {
      eventEnabled = this.notificationsPreferences.visitor;
    } else if (isOthers) {
      eventEnabled = this.notificationsPreferences.others;
    } else if (isTrigger) {
      eventEnabled = this.notificationsPreferences.trigger;
    }
    return eventEnabled;
  }

  showEvent(event) {
    const eventType = event.eventType || event.type;
    switch (eventType) {
      case EVENT_TYPES.PANIC:
        this.createPanicHtmlTemplate(event);
        break;
      case EVENT_TYPES.WARNING:
        this.createWarningHtmlTemplate(event);
        break;
      case EVENT_TYPES.RESIDENT:
        this.createResidentHtmlTemplate(event);
        break;
      case EVENT_TYPES.VISITOR:
        this.createVisitorHtmlTemplate(event);
        break;
      case EVENT_TYPES.UNKNOWN_PLATE:
        this.createUnknownPlatetHtmlTemplate(event);
        break;
      case EVENT_TYPES.OTHER:
        this.createOtherEventHtmlTemplate(event);
        break;
      case EVENT_TYPES.TRIGGER:
        this.createTriggerEventHtmlTemplate(event);
        break;
    }
  }

  private createWarningHtmlTemplate(event: HardwareWarningEvent) {
    const htmlToast = `
      <div class="wrapper">
        <div class="text-wrapper">
          <div class="title">
            ${event.label}
          </div>
          <div class="message">
            <h5><i class="fa fa-globe"></i> ${event.local}</h5>
          </div>
        <div>
      </div>
      `;
    this.toastr
      .warning(htmlToast, '', {
        positionClass: 'toast-top-right',
        enableHtml: true,
        toastClass: `ngx-toastr hardware-warning-toast`
      })
      .onTap.pipe(take(1))
      .subscribe(() => noop());
  }

  private createPanicHtmlTemplate(event: HardwarePanicEvent) {
    const htmlToast = this.createResidentInfo(event);
    this.toastr
      .info(htmlToast, '', {
        // positionClass: 'toast-top-full-width',
        positionClass: 'toast-top-right',
        enableHtml: true,
        toastClass: 'ngx-toastr hardware-event-toast panic'
      })
      .onTap.pipe(take(1))
      .subscribe(() => noop());
  }

  private createResidentHtmlTemplate(event: HardwareResidentEvent) {
    const htmlToast = this.createResidentInfo(event);
    this.toastr
      .info(htmlToast, '', {
        // positionClass: 'toast-top-full-width',
        positionClass: 'toast-top-right',
        enableHtml: true,
        toastClass: 'ngx-toastr hardware-event-toast'
      })
      .onTap.pipe(take(1))
      .subscribe(() => noop());
  }

  private createVisitorHtmlTemplate(event: HardwareVisitorEvent) {
    const visitorText = '<i class="fa fa-user"></i> ' + event.visitor?.name;
    const residenceText = '<i class="fa fa-home"></i> ' + (event.residence?.identification || 'Condomínio');
    const localText = '<i class="fa fa-globe"></i> ' + event.local;
    const labelText = `<i class="fa fa-exchange"></i> ${event.label}`;
    const htmlToast = `
      <div class="wrapper">
        <img src="${event.picture?.thumbnail || './assets/img/empty-user-picture.png'}" class="user-image">
        <div class="text-wrapper">
          <div class="message">
            <div>${visitorText}</div>
            <div>${residenceText}</div>
            <div>${localText}</div>
            <div>${labelText}</div>
          </div>
        <div>
      </div>
      `;
    this.toastr
      .info(htmlToast, '', {
        // positionClass: 'toast-top-full-width',
        positionClass: 'toast-top-right',
        enableHtml: true,
        toastClass: 'ngx-toastr hardware-event-toast'
      })
      .onTap.pipe(take(1))
      .subscribe(() => noop());
  }

  private createUnknownPlatetHtmlTemplate(event: HardwareUnknownPlateEvent) {
    const htmlToast = `
      <div class="wrapper">
        <div class="text-wrapper">
          <div class="title">
            ${event.label}
          </div>
          <div class="">
            <h5><i class="fa fa-globe"></i> ${event.local}</h5>
            <h5><i class="fa fa-car"></i> ${event.serial}</h5>
          </div>
        <div>
      </div>
      `;
    this.toastr
      .info(htmlToast, '', {
        // positionClass: 'toast-top-full-width',
        positionClass: 'toast-top-right',
        enableHtml: true,
        toastClass: 'ngx-toastr hardware-event-toast panic'
      })
      .onTap.pipe(take(1))
      .subscribe(() => noop());
  }

  private createOtherEventHtmlTemplate(event: any) {
    const labelText = event.label;
    const localText = event.local;
    const htmlToast = `
      <div class="wrapper">
        <img src="${event.picture?.thumbnail || './assets/img/empty-user-picture.png'}" class="user-image">
        <div class="text-wrapper">
          <div class="message">
            <div>
              ${labelText}
            </div>
            <div>
              <i class="fa fa-globe"></i> ${localText}
            </div>
          </div>
        <div>
      </div>
      `;
    this.toastr
      .info(htmlToast, '', {
        // positionClass: 'toast-top-full-width',
        positionClass: 'toast-top-right',
        enableHtml: true,
        toastClass: 'ngx-toastr hardware-event-toast'
      })
      .onTap.pipe(take(1))
      .subscribe(() => noop());
  }

  private createTriggerEventHtmlTemplate(event: any) {
    const labelText = event.label;
    const localText = event.local;
    const htmlToast = `
      <div class="wrapper">
        <div class="text-wrapper">
          <div class="message">
            <div>
              ${labelText}
            </div>
            <div>
              <i class="fa fa-globe"></i> ${localText}
              <p><i class="fa fa-user"></i> ${event?.user?.firstName} ${event?.user?.lastName}</p>
            </div>
          </div>
        <div>
      </div>
      `;
    this.toastr
      .info(htmlToast, '', {
        // positionClass: 'toast-top-full-width',
        positionClass: 'toast-top-right',
        enableHtml: true,
        toastClass: 'ngx-toastr hardware-event-toast'
      })
      .onTap.pipe(take(1))
      .subscribe(() => noop());
  }

  private createResidentInfo(event: any) {
    const vehicleTypeIcon = {
      CAR: 'fa-car',
      MOTORCYCLE: 'fa-motorcycle',
      TRUCK: 'fa-truck',
      BICYCLE: 'fa-bicycle'
    };

    const userText = '<i class="fa fa-user"></i> ' + ((event.user && event.user.name) || 'Não cadastrada');
    const residenceText = '<i class="fa fa-home"></i> ' + ((event.residence && event.residence.identification) || 'Não cadastrada');
    const contentText = `${userText} ${userText && residenceText ? ' - ' : ''} ${residenceText}`;
    const vehicleText = `${event.vehicle ? (event.vehicle.model || event.vehicle.brand) + ' ' + event.vehicle.plate : 'Nenhum'}`;
    const vehicleIcon = event.vehicle ? vehicleTypeIcon[event.vehicle.type] : '';
    const vehicleHtml = event.vehicle
      ? `
            <div>
              <i class="fa ${vehicleIcon}"></i> ${vehicleText || ''}
            </div>`
      : '';
    const htmlToast = `
      <div class="wrapper">
        <img src="${event.picture?.thumbnail || './assets/img/empty-user-picture.png'}" class="user-image">
        <div class="text-wrapper">
          <div class="message">
            <div>
              ${contentText}
            </div>
            ${vehicleHtml}
            <div>
              <i class="fa fa-globe"></i> ${event.local}
            </div>
          </div>
        <div>
      </div>
      `;
    return htmlToast;
  }

  log(data, title = '') {
    if (this.debug) {
      console.log(title, data);
    }
  }

  public toggleDebugMode() {
    if (this.debug) {
      this.toastr.warning('Modo debug desativado');
      this.debug = false;
    } else {
      this.toastr.warning('Modo debug ativado');
      this.debug = true;
    }
  }
}
