import { Condo } from '../condo';
import { Actuator } from '@api/model/hardware/actuator';
import { Device, DEVICE_STATUS, DEVICE_STATUS_LABEL } from '@api/model/hardware/device';

export class ActuatorData {
  public _id?: string;
  public sequenceId?: number;
  public condo?: Condo;
  public actuator?: Actuator;
  public device?: Device;
  public serial?: string;
  public operation?: string;
  public validFrom?: Date;
  public validUntil?: Date;
  public status?: DEVICE_STATUS;
  public statusLabel?: DEVICE_STATUS_LABEL;
  public afterSyncStatus?: DEVICE_STATUS;
  public afterSyncStatusLabel?: DEVICE_STATUS_LABEL;
  public error?: string;
  public success?: boolean;
  public createdAt?: Date;
  public updatedAt?: Date;

  constructor(actuatorData: Partial<ActuatorData> = {}) {
    return {
      _id: typeof actuatorData === 'string' ? actuatorData : actuatorData._id || '',
      sequenceId: actuatorData.sequenceId || null,
      condo: actuatorData.condo ? new Condo(actuatorData.condo) : null,
      actuator: actuatorData.actuator || null,
      device: actuatorData.device ? new Device(actuatorData.device) : null,
      serial: actuatorData.serial || null,
      operation: actuatorData.operation || null,
      validFrom: actuatorData.validFrom ? new Date(actuatorData.validFrom) : null,
      validUntil: actuatorData.validUntil ? new Date(actuatorData.validUntil) : null,
      status: DEVICE_STATUS[actuatorData.status] || null,
      statusLabel: DEVICE_STATUS_LABEL[actuatorData.status] || null,
      afterSyncStatus: DEVICE_STATUS[actuatorData.afterSyncStatus] || null,
      afterSyncStatusLabel: DEVICE_STATUS_LABEL[actuatorData.afterSyncStatus] || null,
      error: actuatorData.error || null,
      success: actuatorData.success || null,
      createdAt: actuatorData.createdAt ? new Date(actuatorData.createdAt) : null,
      updatedAt: actuatorData.updatedAt ? new Date(actuatorData.updatedAt) : null
    };
  }
}
