/**
 * Created by Rafael on 13/12/2016.
 */
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UtilService } from '../services/util.service';

@Injectable()
export class HasNotGatekeeperAccessGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const user = this.utilService.getLocalUser();
    if (!user.isGatekeeper()) {
      return true;
    }
    this.router.navigate(['/notFound']);
    return false;
  }
}
