import { Injectable } from '@angular/core';
import { ConstantService } from '../../services/constant.service';
import { HttpService } from '../../services/http.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AcceptedTermsService {
  private readonly endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}accepted-terms`;
  }

  checkUserUseTerms() {
    return this.http.get(`${this.endPoint}`).pipe(
      map((response: any) => ({
        accepted: response.accepted ?? false,
        enabled: response.enabled ?? false,
        useTerms: response.useTerms || undefined
      }))
    );
  }

  acceptUseTerms(useTermsId: string) {
    return this.http.post(`${this.endPoint}/agree/${useTermsId}`, {});
  }
}
