import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { PipesModule } from '../../../pipe/pipes.module';
import { ThemeModule } from '../../../theme';
import { ComponentsModule } from '../../../components/components.module';

import { ModalDeliveryTrackingHistoryComponent } from './modal-delivery-tracking-history.component';

import { DeliveryTrackingHistoryService } from '@api/serviceV3/delivery-tracking-history.service';
import { MomentModule } from 'ngx-moment';

@NgModule({
  declarations: [ModalDeliveryTrackingHistoryComponent],
  imports: [CommonModule, PipesModule, ThemeModule, TooltipModule, ComponentsModule, MomentModule],
  providers: [DeliveryTrackingHistoryService]
})
export class ModalDeliveryTrackingHistoryModule {}
