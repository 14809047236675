import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
// Model
import { User } from '../model/user';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { ErrorBuilder } from '../model/error/error.builder';
import { Condo } from '../model/condo';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as qs from 'qs';
import { EcondosQuery } from '@api/model/query';
import { BuildCondoRequest, CondoRequest } from '@api/model/condo-request';
@Injectable()
export class UserService {
  private endPoint;

  constructor(private http: HttpService, private originalHttp: HttpClient, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}users/`;
  }

  getUserQS(): string {
    const query: EcondosQuery = {
      $populate: [
        { path: 'defaultCondo', populate: { path: 'pictures' } },
        { path: 'residencesUser', select: 'identification condo block identification number requesters users voter' },
        { path: 'residencesVoter', select: 'identification condo block identification number requesters users voter' },
        { path: 'picture' },
        {
          path: 'condosAdmin',
          // TODO precisamos refator para que o defaultCondo seja sempre populado pelo backend para podermos diminuir o select dos arrays de condomínios
          // select: 'name whiteLabel',
          populate: [{ path: 'pictures' }]
        },
        {
          path: 'condosGatekeeper',
          // TODO precisamos refator para que o defaultCondo seja sempre populado pelo backend para podermos diminuir o select dos arrays de condomínios
          // select: 'name whiteLabel',
          populate: [{ path: 'pictures' }]
        },
        {
          path: 'condosOwner',
          // TODO precisamos refator para que o defaultCondo seja sempre populado pelo backend para podermos diminuir o select dos arrays de condomínios
          // select: 'name whiteLabel',
          populate: [{ path: 'pictures' }]
        },
        {
          path: 'condosJanitor',
          // TODO precisamos refator para que o defaultCondo seja sempre populado pelo backend para podermos diminuir o select dos arrays de condomínios
          // select: 'name whiteLabel',
          populate: [{ path: 'pictures' }]
        },
        {
          path: 'condos',
          // TODO precisamos refator para que o defaultCondo seja sempre populado pelo backend para podermos diminuir o select dos arrays de condomínios
          // select: 'name whiteLabel',
          populate: [{ path: 'pictures' }]
        },
        { path: 'condosRequester', select: 'name whiteLabel', populate: [{ path: 'pictures' }] },
        { path: 'residencesRequester', select: 'condo' }
      ]
    };

    const queryParams = qs.stringify(query, { encode: false });
    return queryParams;
  }

  getMe(queryString = this.getUserQS()): Observable<User> {
    queryString = '?' + queryString;
    return this.http.get(`${this.endPoint}me${queryString}`).pipe(map(user => new User(user)));
  }

  getAllUsers(queryString?: string): Observable<User[]> {
    return this.http.get(this.endPoint + (queryString || '')).pipe(map((users: any) => users.map(u => new User(u))));
  }

  createUser(user: any) {
    return this.http.post(this.endPoint, user);
  }

  getUserByVendorCode(indicationCode: string, queryString = '') {
    indicationCode = indicationCode.toUpperCase();
    let qs = queryString.replace('?', '');
    qs = `?vendorCode=${indicationCode}` + (qs ? '&' + qs : '');
    return this.http.get(`${this.endPoint}${qs}`).pipe(
      map(res => {
        if (res instanceof Array) {
          return res[0] ? new User(res[0]) : null;
        } else {
          return null;
        }
      })
    );
  }

  getUserVendorIndications(): Observable<User[]> {
    return this.http.get(this.endPoint + 'me/vendor/indications').pipe(map((users: any) => users.map(u => new User(u))));
  }

  deleteUser(userId: string) {
    return this.http.delete(this.endPoint + userId);
  }

  updateUser(userId: string, user: any) {
    return this.http.put(this.endPoint + userId, user);
  }

  changeUserDefaultCondo(userId: string, condoId: string) {
    return this.http.put(this.endPoint + userId + '/defaultCondo/' + condoId, {});
  }

  setUserAsVendor(userId: string, vendorCode: string, indicationCode?: string) {
    let body;
    if (indicationCode) {
      body = { vendorCode, indicationCode };
    } else {
      body = { vendorCode };
    }
    return this.http.put(this.endPoint + userId + '/vendor/', body);
  }

  activateUser(userId: string) {
    return this.http.put(this.endPoint + userId + '/activate/', {});
  }

  resetUserPassword(userId: string, queryString = '') {
    return this.http.get(`${this.endPoint}${userId}/reset/${queryString}`);
  }

  changeUserPassword(newPassword: string, activationKey: string) {
    return this.http.put(`${this.endPoint}activate?activationKey=${activationKey}`, { password: newPassword });
  }

  changePassword(data: { oldPassword: string; newPassword: string; confirmPassword: string }) {
    return this.http.put(`${this.endPoint}me/password`, data);
  }

  activateUserWithToken(activationKey: string) {
    return this.http.put(`${this.endPoint}activate?activationKey=${activationKey}`, {});
  }

  sendActivationEmail(userId: string) {
    return this.originalHttp.get(this.endPoint + userId + '/reactivate/');
  }

  logout() {
    return this.http.get(`${this.constantService.getEndpoint()}auth/logout/`).pipe(catchError(this.handleError));
  }

  login(queryString = this.getUserQS()): Observable<User> {
    queryString = '?' + queryString;
    return this.http.get(`${this.constantService.getEndpoint()}auth/basic${queryString}`).pipe(map(user => new User(user)));
  }

  loginWithSocial(socialName: string, socialToken: string) {
    return this.originalHttp
      .get(`${this.constantService.getEndpoint()}auth/${socialName}/token?access_token=${socialToken}&${this.getUserQS()}`, {
        withCredentials: true
      })
      .pipe(
        map(res => new User(res)),
        catchError(this.handleError)
      );
  }

  getVendorSubscriptions(userId: string): Observable<Condo[]> {
    return this.http
      .get(`${this.endPoint}${userId}/vendor/subscriptions`)
      .pipe(map((condos: any) => condos.map(condo => new Condo(condo))));
  }

  resetPassword(userId: string): Observable<{ password: number }> {
    return this.http.get(`${this.endPoint}${userId}/resetPassword`) as Observable<{ password: number }>;
  }

  handleError(error) {
    return throwError(ErrorBuilder.build(error));
  }

  getCondoRequests(userId: string, params: EcondosQuery = {}): Observable<{ count: number; condoRequests: CondoRequest[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    return this.http
      .getWithFullResponse(`${this.endPoint}${userId}/condoRequests`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({ count: res.headers.get('count'), condoRequests: res.body.map(data => BuildCondoRequest(data)) })),
        catchError((e: any) => {
          console.log(e);
          return throwError(e);
        })
      );
  }
  updateCondoRequest(userId: string, condoRequestId: string, data: { role: string; residence: string }): Observable<any> {
    return this.http.put(`${this.endPoint}${userId}/condoRequests/${condoRequestId}`, data);
  }
}
