import { Condo } from 'app/api/model/condo';
import { User } from '@api/model/user';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { Residence } from '@api/model/interface/residence';
import { VehicleService } from '@api/service/vehicle.service';
import { ModalCreateVehicleComponent } from '../../modal-create-vehicle/modal-create-vehicle.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
  selector: 'step-vehicle',
  templateUrl: 'step-vehicle.html',
  styleUrls: ['step-vehicle.scss']
})
export class StepVehicleComponent implements OnChanges {
  @Input()
  globals: any;

  @Input()
  index: number;

  @Output()
  goToResidences: EventEmitter<number> = new EventEmitter<number>();

  user: User;
  condo: Condo;
  tabs: { residence: Residence; vehicles: CondoVehicle[] }[] = [];

  loadingStatus;
  LOADING = 'LOADING';
  ERROR = 'ERROR';
  SUCCESS = 'SUCCESS';

  messages = {
    emptyMessage: '',
    totalMessage: 'veículo(s)'
  };

  isLprEnabled = false;

  constructor(
    private vehicleService: VehicleService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.index?.currentValue === this.globals.pages?.VEHICLE) {
      this.user = this.globals.user;
      this.condo = this.globals.condo;
      const residences = this.globals.residences || [];
      this.isLprEnabled = this.condo.isLprEnabled() && this.condo.generalParams?.alprParams?.registerResidentVehicleOffline;
      if (residences.length) {
        this.downloadData(residences);
      } else {
        this.tabs = [];
      }
    }
  }

  createVehicle(residence) {
    const initialState = {
      condo: this.condo,
      vehicleResidence: residence,
      onCreate: vehicle => {
        const residenceIndex = this.tabs.findIndex(tab => tab.residence._id === residence._id);
        this.tabs[residenceIndex].vehicles.push(vehicle);
      }
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState, ignoreBackdropClick: true });
  }

  downloadData(residences) {
    this.loadingStatus = this.LOADING;
    const qs = [];
    qs[qs.length] = `?$populate[0][select]=identification type`;
    residences.map((res, i) => {
      qs[qs.length] = `$and[0][$or][${i}][residence]=${res._id}`;
    });
    qs[qs.length] = `$populate[0][path]=residence`;

    this.vehicleService
      .getVehiclesFromCondo(this.condo._id, qs.join('&'))
      .pipe(timeout(10000))
      .subscribe(
        vehicles => {
          this.loadingStatus = this.SUCCESS;
          this.filterVehicles(vehicles);
        },
        err => {
          this.loadingStatus = this.ERROR;
          this.messages.emptyMessage = 'Não foi possível buscar os veículos. Verifique sua conexão e tente novamente!';
          this.tabs = [];
        }
      );
  }

  filterVehicles(vehicles: CondoVehicle[]) {
    const residences = this.globals.residences.reduce((acc, curr) => {
      acc[curr._id] = { residence: curr, vehicles: [] };
      return acc;
    }, {});
    vehicles.forEach(vehicle => {
      residences[vehicle.residence._id].vehicles.push(vehicle);
    });
    this.tabs = Object.keys(residences).map(key => residences[key]);
  }

  editVehicle(vehicle, residence) {
    const initialState = {
      condo: this.condo,
      vehicleResidence: residence,
      vehicle,
      onUpdate: vehicleUpdated => {
        this.updateResidenceVehicle(this.tabs, residence, vehicleUpdated);
        this.toastr.success('Veículo atualizado com sucesso');
      }
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState, ignoreBackdropClick: true });
  }

  deleteVehicle(vehicle, residence) {
    let deleteIdentification = vehicle.plate ? `placa ${vehicle.plate}` : `chassi ${vehicle.chassis}`;

    swal({
      type: 'question',
      text: `Deseja remover o veículo de ${deleteIdentification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.vehicleService
          .deleteVehicleFromCondo(this.condo._id, vehicle._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível excluir o veículo, tente novamente...');
          });
      }
    }).then(
      () => {
        this.deleteResidenceVehicle(this.tabs, residence, vehicle);
        this.toastr.success('Veículo removido com sucesso');
      },
      error => {
        console.log(error);
      }
    );
  }

  updateResidenceVehicle(array: any[], residence: any, vehicle: any) {
    const residenceIndex = array.findIndex((item: any) => (item.residence?._id || item._id) === residence._id);
    const vehicles = array[residenceIndex].vehicles;
    const vehicleIndex = vehicles.findIndex((v: any) => v._id == vehicle._id);
    if (vehicleIndex > -1) {
      array[residenceIndex].vehicles[vehicleIndex] = vehicle;
    } else {
      array[residenceIndex].vehicles.push(vehicle);
    }
  }

  deleteResidenceVehicle(array: any[], residence: any, vehicle: any) {
    const residenceIndex = array.findIndex((item: any) => (item.residence?._id || item._id) === residence._id);
    const vehicles = array[residenceIndex].vehicles;
    const vehicleIndex = vehicles.findIndex((v: any) => v._id == vehicle._id);
    this.tabs[residenceIndex].vehicles.splice(vehicleIndex, 1);
  }
}
