import { Component, ViewChild } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { CondoService } from '../../../api/service/condo.service';
import { Condo } from '../../../api/model/condo';
import { timeout } from 'rxjs/operators';
import { Status } from '../../../api/model/status';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'condo-reservation-config-modal',
  templateUrl: 'condo-reservation-config.modal.html',
  styleUrls: ['./condo-reservation-config.modal.scss']
})
export class ModalCondoReservationConfigComponent {
  @ViewChild('modal', { static: true }) modalRef: ModalDirective;

  condo: Condo;
  status: Status = new Status();
  form: UntypedFormGroup;
  numberOfSimultaneousReservations: AbstractControl;
  timeOfSimultaneousReservations: AbstractControl;
  simultaneousReservationsPeriodIsFixed: AbstractControl;

  constructor(
    private condoService: CondoService,
    private sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private utilService: UtilService
  ) {}

  initializeForm() {
    const params: any = this.condo.reservationParams || {};
    const timeOfSimultaneousReservations = Math.round(moment.duration(params.timeOfSimultaneousReservations).asMonths());
    this.form = this.formBuilder.group({
      numberOfSimultaneousReservations: [
        params.numberOfSimultaneousReservations,
        Validators.compose([Validators.required, Validators.min(1)])
      ],
      timeOfSimultaneousReservations: [
        timeOfSimultaneousReservations,
        Validators.compose([Validators.required, Validators.min(1), Validators.max(12), Validators.maxLength(2)])
      ],
      simultaneousReservationsPeriodIsFixed: [params.simultaneousReservationsPeriodIsFixed, Validators.required]
    });
    this.numberOfSimultaneousReservations = this.form.get('numberOfSimultaneousReservations');
    this.timeOfSimultaneousReservations = this.form.get('timeOfSimultaneousReservations');
    this.simultaneousReservationsPeriodIsFixed = this.form.get('simultaneousReservationsPeriodIsFixed');
  }

  submit() {
    if (this.form.valid) {
      const timeOfSimultaneousReservations = moment.duration(this.timeOfSimultaneousReservations.value, 'month').asMilliseconds();
      const reservationParams = {
        numberOfSimultaneousReservations: this.numberOfSimultaneousReservations.value,
        timeOfSimultaneousReservations: timeOfSimultaneousReservations,
        simultaneousReservationsPeriodIsFixed: this.simultaneousReservationsPeriodIsFixed.value
      };
      const condo = {
        reservationParams
      };
      this.status.setAsProcessing();
      this.condoService
        .updateCondo(this.condo._id, condo)
        .pipe(timeout(10000))
        .subscribe(
          () => {
            this.condo.reservationParams = reservationParams;
            if (this.condo._id == this.utilService.getLocalCondo()._id) {
              this.utilService.saveLocalCondo(this.condo);
            }
            const updatedUser = this.utilService.getLocalUser();
            updatedUser.defaultCondo.reservationParams = reservationParams;
            this.sessionService.setUser(updatedUser);
            this.toastr.success('Parâmetros de reserva atualizados com sucesso');
            this.status.setAsSuccess();
            this.closeModal();
          },
          err => {
            this.status.setAsError();
            swal({
              type: 'error',
              text: 'Não foi possível atualizar os parâmetros, tente novamente'
            });
          }
        );
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.error('Formulário inválido');
    }
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  showModal() {
    this.condo = this.utilService.getLocalCondo();
    this.initializeForm();
    this.modalRef.show();
  }
}
