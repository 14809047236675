import { ModalShowManufacturerChoiceModule } from './../../hardware/modal-show-manufacturer-choice/modal-show-manufacturer-choice.module';
import { ModalAddHardwareDeviceModule } from './../../hardware/modal-add-hardware-device/modal-add-hardware-device.module';
import { ModalCreateControlIdDeviceModule } from './../../hardware/modal-create-controlid-device/modal-create-controlid-device.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalCreateUserStepsComponent } from './modal-create-user-steps.component';
import { StepUserComponent } from './step-user/step-user';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ComponentsModule } from '../components.module';
import { StepResidenceComponent } from './step-residence/step-residence';
import { BaseStepper } from './base-stepper/base-stepper';
import { StepDeviceComponent } from './step-device/step-device';
import { ThemeModule } from '../../theme';
import { StepVehicleComponent } from './step-vehicle/step-vehicle';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalCreateHikvisionDeviceModule } from 'app/hardware/modal-create-hikvision-device/modal-create-hikvision-device.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'app/pipe/pipes.module';

@NgModule({
  declarations: [
    ModalCreateUserStepsComponent,
    BaseStepper,
    StepUserComponent,
    StepResidenceComponent,
    StepDeviceComponent,
    StepVehicleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdkStepperModule,
    ComponentsModule,
    ThemeModule,
    PipesModule,
    NgxDatatableModule,
    ModalShowManufacturerChoiceModule,
    ModalCreateHikvisionDeviceModule,
    ModalCreateControlIdDeviceModule,
    ModalAddHardwareDeviceModule,
    TabsModule.forRoot(),
    TooltipModule
  ],
  exports: [
    ModalCreateUserStepsComponent,
    BaseStepper,
    StepUserComponent,
    StepResidenceComponent,
    StepDeviceComponent,
    StepVehicleComponent
  ]
})
export class ModalCreateUserStepsModule {}
