import { AfterViewInit, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { Device } from '@api/model/hardware/device';
import { PrintContentService } from '../../../../services/print-content.service';

@Component({
  selector: 'app-modal-generate-qrcode-from-gatekeeper',
  templateUrl: 'modal-generate-qrcode-from-gatekeeper.component.html',
  styleUrls: ['modal-generate-qrcode-from-gatekeeper.component.scss']
})
export class ModalGenerateQrcodeFromGatekeeperComponent implements AfterViewInit {
  condo: Condo;
  condoContactLabel: string;
  condoVehicleLabel: string;
  approvedByName: string;
  residenceLabel: string;
  device: Device;

  status: Status = new Status();

  constructor(public bsModalRef: BsModalRef, private printContentService: PrintContentService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.print('qrcode-print');
    }, 500);
  }

  print(elementId) {
    const printContents = document.getElementById(elementId).innerHTML;
    this.printContentService.print(printContents);
  }
}
