import { WhiteLabel } from '@api/model/white-label';

export const econdosTheme: WhiteLabel = {
  name: 'econdos',
  logo: 'assets/img/logo.png',
  logomarca: 'assets/img/logomarca.png',
  favIcon: 'assets/img/icon.png',
  pageTitle: 'eCondos',
  cssVariables: {
    // Navbar
    '--navbar-background': '#6d28d9',
    '--navbar-text': '#FFFFFF',
    '--navbar-hover-background': '#36008c',
    '--navbar-hover-text': '#fff',

    // Modals
    '--modal-header-background': '#6d28d9',
    '--modal-header-text': '#232323',

    // Variables
    '--primary': '#6d28d9',
    '--primary-darker': '#36008c',
    '--on-primary': '#FFFFFF',
    '--background': '#f6f7f9',
    '--on-background': '#000'
  }
};
