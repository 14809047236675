import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatMessage } from '@api/model/chat-message';
import { ChatRoom } from '@api/model/chat-room';
import { EcondosQuery } from '@api/model/query';
import { User } from '@api/model/user';
import { ChatServiceV2 } from '@api/serviceV2/chat.service';
import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
  selector: 'gate-chat-message',
  templateUrl: 'gate-chat-message.html',
  styleUrls: ['gate-chat-message.scss']
})
export class GateChatMessageComponent {
  @Input() chatMessage: ChatMessage = null;
  @Input() user: User = null;
  @Input() selectedChatRoom: ChatRoom = null;
  @Input() fontSize: 'normal' | 'large' | 'extra-large' = 'normal';
  @Input() onReadByButtonClick: (chatMessage: ChatMessage) => void = () => {};
  @Output() onReplyToActionClick: EventEmitter<ChatMessage> = new EventEmitter();

  expandReplyedMessage = false;

  readByAlreadyPopulated = false;
  readByLoadingStatus: 'LOADING' | 'SUCCESS' | 'ERROR';

  isUserOnReadByArray = false;

  constructor(private chatService: ChatServiceV2) {}

  replyMessage(chatMessage: ChatMessage) {
    this.onReplyToActionClick.emit(chatMessage);
  }

  showReadByPopover(chatMessage: ChatMessage) {
    if (!this.readByAlreadyPopulated) {
      this.readByLoadingStatus = 'LOADING';

      const query: EcondosQuery = {
        $select: 'readBy chatRoom',
        $populate: [{ path: 'readBy', select: 'firstName lastName' }],
        _id: chatMessage._id,
        condo: chatMessage.condo._id,
        chatRoom: chatMessage.chatRoom._id
      };

      this.chatService
        .getChatMessages(chatMessage.condo._id, chatMessage.chatRoom._id, query)
        .pipe(timeout(10_000))
        .subscribe(
          response => {
            this.readByLoadingStatus = 'SUCCESS';
            this.readByAlreadyPopulated = true;

            const [responseChatMessage] = response.chatMessages;

            if (responseChatMessage) {
              this.onReadByButtonClick(responseChatMessage);
            }
          },
          error => {
            this.readByLoadingStatus = 'ERROR';

            swal({
              type: 'error',
              text: 'Não foi possível criar a solicitação de dispositivos de acesso, tente novamente.'
            });
          }
        );
    }
  }
}
