import { NgModule } from '@angular/core';
import { ModalPendingCondoApprovalComponent } from './modal-pending-condo-approval';
import { ThemeModule } from '../../../theme';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ComponentsModule } from '../../../components/components.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PipesModule } from '../../../pipe/pipes.module';

@NgModule({
  imports: [
    ThemeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    PipesModule,
    ComponentsModule,
    ThemeModule,
    TypeaheadModule
  ],
  declarations: [ModalPendingCondoApprovalComponent]
})
export class ModalPendingCondoApprovalModule {}
