import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@api/model/user';

export interface HandoverBlock {
  id: string;
  name: string;
}

export interface HandoverResidence {
  id: string;
  name: string;
  partnerRefCode: string;
}

export interface HandoverUser {
  id: string;
  name: string;
  email: string;
  phone?: string;
  residence: HandoverResidence;
  block: HandoverBlock;
  idSubTeam: string;
  idSubTeamUnits: string;
  idSubTeamSubUnits: string;
  partnerRefCode?: string;
}

export interface HandoverGetResidentsReturnBody {
  handoverResidentsCount: number;
  handoverResidents: HandoverUser[];
  condoResidentsCount: number;
  condoResidents: User[];
}

export type HandoverCreateResidentDTO = Pick<HandoverUser, 'id' | 'name' | 'email' | 'phone'>;

@Injectable({ providedIn: 'root' })
export class HandoverService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }

  getResidents(condoId: string, smartLockerId: string): Observable<HandoverGetResidentsReturnBody> {
    const options = {
      headers: new HttpHeaders(),
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/smart-locker/handover/${smartLockerId}/residents`, options).pipe(
      map((res: any) => {
        const condoResidents = res.body.condoResidents.map(resident => new User(resident));

        return { ...res.body, condoResidents };
      })
    );
  }

  createResident(
    condoId: string,
    smartLockerId: string,
    residenceId: string,
    resident: HandoverCreateResidentDTO
  ): Observable<HandoverUser> {
    return this.http
      .post(`${this.endPoint}${condoId}/smart-locker/handover/${smartLockerId}/residents/${residenceId}`, resident)
      .pipe(map((res: HandoverUser) => res));
  }

  deleteResident(condoId: string, smartLockerId: string, handoverResidentId: string): Observable<User> {
    return this.http
      .delete(`${this.endPoint}${condoId}/smart-locker/handover/${smartLockerId}/residents/${handoverResidentId}`)
      .pipe(map((res: User) => new User(res)));
  }
}
