import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, repeatWhen, retry, share, takeUntil, tap, timeout } from 'rxjs/operators';
import { AccessGroupService } from '@api/service/access-group.service';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { UtilService } from '../../services/util.service';
import { Condo } from '@api/model/condo';
import { AccessGroup } from '@api/model/hardware/access-group';
import { Actuator } from '@api/model/hardware/actuator';
import { FormControl, FormGroup } from '@angular/forms';
import { EcondosQuery } from '@api/model/query';
import { Status } from '@api/model/status';

interface ActuatorSync extends Actuator {
  otherIds?: string[];
}
@Component({
  selector: 'app-select-access-group-actuators',
  templateUrl: './select-access-group-actuators.component.html'
})
export class SelectAccessGroupActuatorsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() actuatorsQuery: EcondosQuery | null = null;
  @Input() storedActuators?: Actuator[] = [];

  @Output() actuatorsToRegister: EventEmitter<Actuator[]> = new EventEmitter<Actuator[]>();
  @Output() actuatorsToRemove: EventEmitter<Actuator[]> = new EventEmitter<Actuator[]>();
  @Output() selectAccessGroup: EventEmitter<AccessGroup> = new EventEmitter<AccessGroup>();
  @Output() actuatorsLoaded: EventEmitter<Actuator[]> = new EventEmitter<Actuator[]>();

  private condo: Condo;

  public getData$: Observable<{ accessGroups: AccessGroup[]; actuators: Actuator[] }>;
  public selectedAccessGroup: FormControl = new FormControl('');
  public checkedActuators: FormGroup = new FormGroup({});

  public actuators: ActuatorSync[] = [];
  public accessGroups: AccessGroup[] = [];

  public dataStatus: Status = new Status();

  private destroyed$: Subject<boolean> = new Subject<boolean>();
  private refresh$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly accessGroupService: AccessGroupService,
    private readonly actuatorService: ActuatorService,
    private readonly utilService: UtilService
  ) {
    this.condo = this.utilService.getLocalCondo();

    this.selectedAccessGroup.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(accessGroupId => {
      if (accessGroupId) {
        const accessGroup = this.accessGroups.find(ag => ag._id === accessGroupId);
        this.selectAccessGroup.emit(accessGroup);
        const actuators = accessGroup.actuators;
        for (const actuatorId of actuators) {
          if (!this.checkedActuators.get(actuatorId)?.value) {
            const actuator = this.actuators.find(a => a._id === actuatorId);
            if (actuator) {
              this.toggleActuator(actuator);
            }
          }
        }

        for (const actuator of this.actuators) {
          const belongsToAccessGroup = actuators.some(actuatorId => actuatorId === actuator._id);
          if (this.checkedActuators.get(actuator._id)?.value && !belongsToAccessGroup) {
            this.toggleActuator(actuator);
          }
        }
      } else {
        this.uncheckAll();
      }
    });

    this.checkedActuators.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        map(value => {
          const actuatorsToRegister = this.actuators.filter(ac => value[ac._id]);
          const actuatorsToRemove = this.actuators.filter(
            ac => !value[ac._id] && this.storedActuators.some(stored => stored._id === ac._id)
          );
          return { actuatorsToRegister, actuatorsToRemove };
        })
      )
      .subscribe(({ actuatorsToRegister, actuatorsToRemove }) => {
        this.actuatorsToRegister.emit(actuatorsToRegister);
        this.actuatorsToRemove.emit(actuatorsToRemove);
      });
  }

  ngOnInit(): void {
    this.getData$ = forkJoin({
      accessGroups: this.accessGroupService.get(this.condo._id).pipe(
        map(({ accessGroups }) => accessGroups),
        tap(accessGroups => (this.accessGroups = accessGroups)),
        timeout(10000),
        retry(3)
      ),
      actuators: this.actuatorService.getActuators(this.condo._id, this.actuatorsQuery || {}).pipe(
        map(({ actuators }) => actuators),
        tap(actuators => {
          if (this.actuatorsQuery.hardware === 'CONTROL_ID') {
            actuators.forEach(actuator => {
              if (actuator.type === 'iDBox') {
                const index = this.actuators.findIndex(
                  a =>
                    (a.port && a.host && a.port === actuator.port && a.host === actuator.host) ||
                    (a.port2 && a.host2 && a.port2 === actuator.port2 && a.host2 === actuator.host2)
                );
                if (index === -1) {
                  this.actuators.push(actuator);
                } else {
                  let name = this.actuators[index].name;
                  name += `, ${actuator.name}`;
                  this.actuators[index].name = name;
                  if (!this.actuators[index].otherIds) {
                    this.actuators[index].otherIds = [];
                  }
                  if (this.checkedActuators.get(actuator._id)?.value) {
                    this.checkedActuators.removeControl(actuator._id);
                    this.checkedActuators.setControl(this.actuators[index]._id, new FormControl(true));
                  }
                  this.actuators[index].otherIds.push(actuator._id);
                }
              } else {
                this.actuators.push(actuator);
              }
            });
          } else {
            this.actuators = actuators;
          }
          this.actuatorsLoaded.emit(this.actuators);
          if (Object.keys(this.checkedActuators.controls).length) {
            this.checkedActuators.setValue(this.checkedActuators.value);
          }
        }),
        timeout(10000),
        retry(3)
      )
    }).pipe(
      catchError(() => {
        this.dataStatus.setAsError();
        return of(null);
      }),
      tap(res => {
        if (res) {
          this.dataStatus.setAsSuccess();
          this.setAccessGroup();
        }
      }),
      repeatWhen(() => this.refresh$),
      share()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.storedActuators?.currentValue?.length) {
      const acts = changes.storedActuators.currentValue;
      for (const act of acts) {
        this.checkedActuators.setControl(act._id, new FormControl(true));
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  private uncheckAll(): void {
    this.actuators.forEach(ac => {
      if (this.checkedActuators.get(ac._id)) {
        this.checkedActuators.setControl(ac._id, new FormControl(false));
      }
    });
  }

  private setAccessGroup(): void {
    const checkedActuators = this.actuators.filter(ac => this.checkedActuators.get(ac._id)?.value);
    const currentAccessGroup = this.accessGroups.find(
      accessGroup =>
        accessGroup.actuators?.length === checkedActuators?.length &&
        accessGroup.actuators?.every(actuator => {
          return this.checkedActuators.get(actuator._id || actuator)?.value || false;
        })
    );
    if (currentAccessGroup) {
      this.selectedAccessGroup.setValue(currentAccessGroup._id);
    } else {
      this.selectedAccessGroup.setValue('', { emitEvent: false });
    }
  }

  public toggleActuator(actuator: Actuator, checkAccessGroup = false) {
    const isChecked = this.checkedActuators.get(actuator._id)?.value || false;
    if (isChecked) {
      this.checkedActuators.setControl(actuator._id, new FormControl(false));
    } else {
      this.checkedActuators.setControl(actuator._id, new FormControl(true));
    }
    if (checkAccessGroup) {
      this.setAccessGroup();
    }
  }

  public toggleAll(): void {
    if (this.actuators.every(ac => this.checkedActuators.get(ac._id))) {
      this.actuators.forEach(ac => {
        if (!this.checkedActuators.get(ac._id)) {
          return;
        }
        this.toggleActuator(ac, true);
      });
    } else {
      this.actuators.forEach(ac => {
        if (this.checkedActuators.get(ac._id)) {
          return;
        }
        this.toggleActuator(ac, true);
      });
    }
  }

  public refreshData() {
    this.dataStatus.setAsProcessing();
    this.refresh$.next(true);
  }
}
