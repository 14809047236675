import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { from, Observable, Subscriber } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../../services/util.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { CondoContactServiceV2 } from '@api/serviceV2/condo.contact.service';
import { CondoContact } from '@api/model/contact/condo.contact';
import { CreateUserContactModalComponent } from 'app/pages/modal/create.user.contact.modal/create-user-contact-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-authorized-person-autocomplete',
  templateUrl: './authorized-person-auto-complete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AuthorizedPersonAutoCompleteComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AuthorizedPersonAutoCompleteComponent
    }
  ]
})
export class AuthorizedPersonAutoCompleteComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label = '';
  @Output() createPerson = new EventEmitter();

  person: CondoContact;
  dataSource$: Observable<any>;
  condo: Condo;

  textSearch = '';
  status: 'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS' = 'IDLE';
  noResult = false;

  touched = false;
  isDisabled = false;
  isValid = true;

  placeholder;

  onChange = value => {};

  onTouched = () => {};

  constructor(private condoContactService: CondoContactServiceV2, private utilService: UtilService, private modalService: BsModalService) {}

  ngOnInit(): void {
    this.condo = this.utilService.getLocalCondo();

    this.placeholder = `Busque pela pessoa`;

    this.dataSource$ = new Observable((observer: Subscriber<string>) => observer.next(this.textSearch)).pipe(
      distinctUntilChanged(),
      tap(() => this.markAsTouched()),
      switchMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'firstName lastName ids phones emails picture type birthDate',
          $populate: [{ path: 'picture', select: 'url thumbnail type format name' }],
          $sort: 'firstName lastName'
        };

        let request;
        if ((token || '').toString().trim().length > 0) {
          request = this.condoContactService.searchByToken(this.condo._id, token, query);
        } else {
          query.$limit = 15;
          query.$page = 0;
          request = this.condoContactService.search(this.condo._id, query);
        }
        return request.pipe(
          map((res: any) => {
            return res.contacts.map(d => ({ ...d, name: `${d.firstName} ${d.lastName}`, picture: d.picture }));
          }),
          catchError(e => {
            this.status = 'ERROR';
            return from([]);
          })
        );
      })
    );
  }

  onSelect(event: TypeaheadMatch): void {
    if (!this.isDisabled) {
      this.person = new CondoContact(event.item);
      this.markAsTouched();
      this.onChange(this.person);
    }
  }

  editPerson() {
    const callback = (condoContact: CondoContact) => {
      this.person = condoContact;
      this.onChange(this.person);
    };

    const initialState: Partial<CreateUserContactModalComponent> = {
      contact: this.person,
      callback
    };

    this.modalService.show(CreateUserContactModalComponent, { initialState });
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
  }

  clearValue() {
    this.person = null;
    this.textSearch = '';
    this.noResult = false;
    this.onChange(null);
  }

  // Angular form methods
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(person: CondoContact): void {
    this.person = person;
    this.textSearch = this.person?.fullName || '';
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const person = control.value;
    if (!this.textSearch && person) {
      this.isValid = false;
      return {
        personNotSelected: {
          person
        }
      };
    } else {
      this.isValid = true;
      return null;
    }
  }
}
