import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { noop, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, exhaustMap, filter, takeUntil, tap, timeout } from 'rxjs/operators';
import swal from 'sweetalert2';
import { Actuator } from '@api/model/hardware/actuator';
import { Condo } from '@api/model/condo';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { User } from '@api/model/user';
import { CondoServiceV2 } from '@api/serviceV2/condo.service';

@Component({
  templateUrl: 'modal-actuate-actuator.component.html',
  styleUrls: ['modal-actuate-actuator.component.scss']
})
export class ModalActuateActuatorComponent implements OnInit, OnDestroy {
  condo: Condo;
  user: User;
  actuator: Actuator;
  actuationStatus;

  defaultImage = 'assets/img/no-image.jpg';

  showInstructions = false;

  isElectron = false;

  actuate$: Subject<{ actuator: Actuator; params: any }> = new Subject();

  private unsubscribe: Subject<void> = new Subject();

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.actuationStatus !== 'PROCESSING' && event && (event.key === 'Enter' || event.keyCode === 13)) {
      this.actuate(this.actuator, this.condo);
    }
  }

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private http: HttpClient,
    private toastrService: ToastrService,
    private actuatorService: ActuatorService
  ) {
    this.isElectron = this.userAgentIsElectron();
  }

  ngOnInit() {
    this.actuate$
      .asObservable()
      .pipe(
        filter(data => !!data.actuator),
        tap(data => (this.actuationStatus = 'PROCESSING')),
        exhaustMap(data => {
          let observable;
          if (data.params?.command) {
            const command = data.params.command;
            if (command === 'LOCK') {
              observable = this.actuatorService.lock(data.actuator);
            } else {
              observable = this.actuatorService.unlock(data.actuator);
            }
          } else {
            observable = this.actuatorService.trigger(data.actuator, data.params);
          }
          return observable.pipe(
            timeout(10000),
            catchError(err => {
              console.log(err);
              swal({
                type: 'error',
                text: this.getTextFromHardware(data.actuator)
              });
              this.actuationStatus = 'ERROR';
              return of(new Error());
            }),
            tap(value => {
              if (!(value instanceof Error)) {
                this.toastrService.success('Comando enviado');
                this.actuationStatus = 'SUCCESS';
                if (!this.actuator.camera) {
                  this.hide();
                }
              }
            })
          );
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(noop);
  }

  getTextFromHardware(actuator: Actuator) {
    let text = 'Não foi possível enviar o comando para o';

    switch (actuator.hardware) {
      case 'LINEAR':
        text = text + ' módulo guarita';
        break;
      case 'CONTROL_ID':
        text = text + ' Control ID';
        break;
      case 'HIKVISION':
        text = text + ' Hikvision';
        break;
      case HARDWARES.BRAVAS:
        text = text + ' equipamento';
        break;
      default:
        text = 'Não foi possível processar a requisição';
    }
    return text;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  checkIfProxyIsRunning() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.http
      .get('http://localhost:5050/api.econdos.com.br', { headers, responseType: 'text' })
      .pipe(timeout(8000))
      .subscribe(
        res => {
          swal({
            type: 'success',
            text: 'O eCondos câmera proxy está funcionando corretamente, verifique se as câmeras estão configuradas corretamente e tente novamente'
          });
        },
        err => {
          console.log(err);
          swal({
            type: 'error',
            text: 'Parece que o eCondos câmera proxy não está em execução, verifique as instruções para executá-lo e tente novamente'
          });
        }
      );
  }

  actuate(actuator, condo, params = {}) {
    actuator.condo = condo;

    try {
      this.actuatorService.registerTriggerEvent(actuator).subscribe({ next: noop, error: noop });
    } catch (e) {
      console.log(e);
    }

    this.actuate$.next({ actuator, params });
  }

  // actuateViaServerMode(actuator, condo) {
  //   const user = this.sessionService.user;
  //   this.actuationStatus = 'PROCESSING';
  //   let input;
  //   let command;
  //   // If output is bigger than 4, it should used advanced trigger command to actuate auxiliars output
  //   if (actuator.output > 4) {
  //     input = {
  //       tipo_disp: actuator.type,
  //       num_disp: actuator.number,
  //       gera_evt: true,
  //       rele: actuator.output,
  //       tempo: 1,
  //     };
  //     command = 'triggerReceiverAdvanced';
  //   } else {
  //     command = 'triggerReceiver';
  //     input = {
  //       tipo_disp: actuator.type,
  //       num_disp: actuator.number,
  //       num_saida: actuator.output,
  //       gera_evt: true
  //     };
  //   }
  //
  //   this.hardwareSocketService.sendCommandViaServerMode(condo._id, {
  //     command, input,
  //     createdBy: user
  //   }).pipe(timeout(10000)).subscribe(res => {
  //     this.toastrService.success('Comando enviado');
  //     this.actuationStatus = 'SUCCESS';
  //     if (!actuator.camera) {
  //       this.hide();
  //     }
  //   }, err => {
  //     swal({
  //       type: 'error',
  //       text: 'Não foi possível enviar o comando para o módulo guarita'
  //     });
  //     this.actuationStatus = 'ERROR';
  //     console.log(err);
  //   });
  //
  // }

  userAgentIsElectron() {
    const userAgent = window && window.navigator && window.navigator.userAgent;
    return userAgent.toLowerCase().includes('electron');
  }

  hide() {
    this.bsModalRef.hide();
  }
}
