import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalActiveVisitorsConfigComponent } from './modal-active-visitors-config.component';
import { ThemeModule } from '../../../theme';
import { PipesModule } from 'app/pipe/pipes.module';
import { ComponentsModule } from '../../../components/components.module';

@NgModule({
  imports: [FormsModule, CommonModule, PipesModule, ReactiveFormsModule, ModalModule, ThemeModule, ComponentsModule],
  declarations: [ModalActiveVisitorsConfigComponent]
})
export class ModalActiveVisitorsConfigModule {}
