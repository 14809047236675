import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { AccessGroup } from '@api/model/hardware/access-group';
import { Device, DEVICE_TYPES_LABEL } from '@api/model/hardware/device';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { Residence } from '@api/model/interface/residence';
import { EcondosQuery } from '@api/model/query';
import { AccessGroupService } from '@api/service/access-group.service';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { ResidenceService } from '@api/service/residence.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

type RequestStatus = 'LOADING' | 'SUCCESS' | 'ERROR';

interface DeviceOption extends Device {
  isSelected: boolean;
}

@Component({
  selector: 'app-modal-bulk-access-group-assignment',
  templateUrl: 'modal-bulk-access-group-assignment.component.html',
  styleUrls: ['modal-bulk-access-group-assignment.component.scss']
})
export class ModalBulkAccessGroupAssignmentComponent implements OnInit, OnDestroy {
  public condo: Condo;
  public accessGroupList: AccessGroup[] = [];
  public selectedAccessGroup: AccessGroup;

  public DEVICE_TYPES_LABEL = DEVICE_TYPES_LABEL;
  public ACTION_LABEL = {
    ADD: 'Adicionar',
    REMOVE: 'Remover'
  };

  public actionControl = new UntypedFormControl('ADD');
  public selectedAccessGroupControl = new UntypedFormControl('');
  public residenceSearchControl = new UntypedFormControl('');

  public bulkAssignAccessGroupStatus: RequestStatus;
  public loadDataStatus: RequestStatus;
  public devices: DeviceOption[] = [];

  private unsubscribe = new Subject();
  selectResidenceQuery: EcondosQuery = {
    $select:
      'block identification lot number address type users company lineOfWork extensionLine phones voter specialNeeds specialNeedsDetails',
    $populate: [
      {
        path: 'voter',
        select: 'firstName lastName picture phones',
        populate: { path: 'picture', select: 'url thumbnail' }
      }
    ]
  };


  isAllDevicesSelected = false;
  constructor(
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private residencesService: ResidenceService,
    private devicesService: HardwareDeviceService,
    private accessGroupService: AccessGroupService
  ) {}

  public ngOnInit(): void {
    if (this.selectedAccessGroup) {
      this.selectedAccessGroupControl.setValue(this.selectedAccessGroup._id);
    }

    if (!this.selectedAccessGroupControl.value) {
      this.residenceSearchControl.disable();
    }

    this.actionControl.valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), debounceTime(500)).subscribe(action => {
      if (action && this.selectedAccessGroupControl.value && this.residenceSearchControl.value) {
        this.getData(this.residenceSearchControl.value);
      }
    });

    this.residenceSearchControl.valueChanges
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), debounceTime(500))
      .subscribe(value => {
        if (value) {
          this.getData(value);
        } else {
          this.reset();
        }
      });

    this.selectedAccessGroupControl.valueChanges
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), debounceTime(500))
      .subscribe(accessGroupId => {
        if (accessGroupId) {
          this.residenceSearchControl.enable();
        } else {
          this.residenceSearchControl.disable();
        }

        if (accessGroupId && this.actionControl.value && this.residenceSearchControl.value) {
          this.getData(this.residenceSearchControl.value);
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public reset(): void {
    this.devices = [];
    this.loadDataStatus = undefined;
  }

  public getData(residence: Residence) {
    this.loadDataStatus = 'LOADING';

    this.getDevices(residence).subscribe(
      devices => {
        this.devices = devices;
        this.loadDataStatus = 'SUCCESS';
      },
      error => {
        console.error(error);
        this.toastrService.error('Ocorreu um erro ao carregar os dados da busca. Por favor tente novamente.', 'Erro inesperado');
        this.loadDataStatus = 'ERROR';
      }
    );
  }

  private getDevices(residence: Residence): Observable<DeviceOption[]> {
    const devicesQuery: EcondosQuery = {
      $select: 'owner accessGroups hardware serial type',
      $populate: [
        { path: 'owner.residence', select: 'identification lot block number address company lessee type' },
        { path: 'owner.user', select: 'firstName lastName' },
        { path: 'owner.condoContact', select: 'firstName lastName' },
        { path: 'owner.dependent', select: 'name' },
        {
          path: 'accessGroups',
          select: 'name actuators timezone',
          populate: [
            { path: 'actuators', select: 'name type username password host port host2 port2 hardware condo' },
            { path: 'timezone', select: 'name sequenceId' }
          ]
        }
      ],
      hardware: { $in: [HARDWARES.UTECH, HARDWARES.CONTROL_ID, HARDWARES.HIKVISION] },
      accessType: 'RESIDENT',
      'owner.residence': residence._id
    };

    if (this.selectedAccessGroupControl.value && this.actionControl.value === 'ADD') {
      devicesQuery['accessGroups'] = { $nin: [this.selectedAccessGroupControl.value] };
    } else if (this.selectedAccessGroupControl.value && this.actionControl.value === 'REMOVE') {
      devicesQuery['accessGroups'] = { $in: [this.selectedAccessGroupControl.value] };
    }

    return this.devicesService.get(this.condo._id, devicesQuery).pipe(
      map(response => {
        const devicesOptions: DeviceOption[] = response.devices.map(device => ({ ...device, isSelected: true }));
        return devicesOptions;
      })
    );
  }

  public selectDevice(device: DeviceOption, index: number): void {
    this.devices[index].isSelected = !device.isSelected;
    this.isAllDevicesSelected = this.devices.every(d => d.isSelected);
  }

  public async bulkAssignAccessGroup(): Promise<void> {
    this.bulkAssignAccessGroupStatus = 'LOADING';

    try {
      const devicesToUpdate = this.devices.filter(device => device.isSelected);
      const devicesIds = devicesToUpdate.map(device => device._id);

      switch (this.actionControl.value) {
        case 'ADD':
          await this.accessGroupService.bulkAddToDevices(this.condo._id, this.selectedAccessGroupControl.value, devicesIds).toPromise();
          break;

        case 'REMOVE':
          await this.accessGroupService
            .bulkRemoveFromDevices(this.condo._id, this.selectedAccessGroupControl.value, devicesIds)
            .toPromise();
          break;

        default:
          console.log('Invalid option');
          break;
      }

      this.devices = this.devices.filter(device => !devicesIds.includes(device._id));

      this.toastrService.success('O grupo de acesso foi atribuído aos dispositivos listados com sucesso', 'Atribuição concluída');

      this.bulkAssignAccessGroupStatus = 'SUCCESS';
    } catch (error) {
      console.error(error);

      this.toastrService.error('Ocorreu um erro durante a atribuição do grupo de acesso aos dispositivos', 'Erro inesperado');

      this.bulkAssignAccessGroupStatus = 'ERROR';
    }
  }

  public get countSelectedDevices(): number {
    return this.devices.filter(device => device.isSelected).length;
  }

  toggleDevicesSelection() {
    const anyDeviceIsNotSelected = this.devices.some(device => device.isSelected === false);
    this.devices.forEach(device => {
      device.isSelected = anyDeviceIsNotSelected;
    });
    this.isAllDevicesSelected = this.devices.every(d => d.isSelected);
  }
}
