import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { CondoService } from '@api/service/condo.service';
import { UtilService } from '../../../services/util.service';
import { isAdminOnCondo, isOwnerOnCondo } from '@api/utils';
import { timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import swal from 'sweetalert2';
import { EcondosQuery } from '@api/model/query';

@Component({
  selector: 'app-modal-resident-picker',
  templateUrl: 'modal-resident-picker.component.html',
  styleUrls: ['modal-resident-picker.component.scss']
})
export class ModalResidentPickerComponent implements OnInit {
  user: User;
  condo: Condo;

  userIdsToHide: string[] = [];
  multipleSelection = false;

  callbacks: { success?: Function; error?: Function };

  selectedUsers: User[] = [];

  hasWriteAccess = false;

  query: EcondosQuery = {};

  constructor(public bsModalRef: BsModalRef, private condoService: CondoService, private utilService: UtilService) {}

  ngOnInit() {
    this.user = this.utilService.getLocalUser();
    if (!this.condo) {
      this.condo = this.utilService.getLocalCondo();
    }
    this.hasWriteAccess = isAdminOnCondo(this.user, this.condo._id) || isOwnerOnCondo(this.user, this.condo._id);
    if (this.userIdsToHide.length) {
      const _id: any = {
        $nin: this.userIdsToHide
      };
      this.query._id = _id;
    }
  }

  removeUser(userId) {
    const index = this.selectedUsers.findIndex(user => user._id === userId);
    this.selectedUsers.splice(index, 1);
    const index2 = this.query._id?.$nin?.findIndex(userQueryId => userQueryId === userId);
    this.query._id?.$nin?.splice(index2, 1);
  }

  selectUser(user) {
    if (user) {
      this.selectedUsers.push(user);
      this.query._id?.$nin?.push(user._id);
    }
  }

  confirmSelection() {
    if (this.selectedUsers.length) {
      this.callbacks.success(this.selectedUsers);
      this.bsModalRef.hide();
    }
  }

  inviteUser() {
    swal({
      title: 'Convidar usuário',
      showCancelButton: true,
      confirmButtonText: 'Convidar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      html: `
      <form>
          <div class="form-group">
            <label for="firstName" class="float-start">Nome*</label>
            <input id="firstName" class="form-control" autofocus >
          </div>
          <div class="form-group">
            <label for="email" class="float-start">Email*</label>
            <input id="email" type="email" class="form-control">
          </div>
      </form>
      `,
      preConfirm: () => {
        const name = (<HTMLInputElement>document.getElementById('firstName')).value;
        const names = name.split(' ').filter(v => v.trim());
        const firstName = names.shift();
        const lastName = names.join(' ');
        const email = (<HTMLInputElement>document.getElementById('email')).value;
        let errorMessage = '';

        if (!firstName || !firstName.trim().length) {
          errorMessage += 'Nome é obrigatório e deve conter mais que 3 caracteres';
        }

        if (!email || !email.trim().length || !this.utilService.validEmail(email)) {
          if (errorMessage) {
            errorMessage += '<br/>';
          }
          errorMessage += 'Endereço de email inválido';
        }

        if (errorMessage) {
          return Promise.reject(errorMessage);
        }

        const user = {
          firstName: firstName,
          lastName: lastName ? lastName.trim() : '',
          email: email.toLowerCase().trim(),
          signupStep: 0
        };

        if (!lastName) {
          delete user.lastName;
        }

        return this.condoService
          .addUserToCondo(this.condo._id, user)
          .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
          .toPromise()
          .catch((err: any) => {
            let msg = '';
            try {
              if (err.originalErrorMessage.includes('User already is condo user')) {
                msg = `Usuário já existente no(a) ${this.condo?.customLabels?.condo?.singular}.`;
              }
            } catch (err) {
              msg = 'Não foi possível convidar o usuário, tente novamente...';
            }
            return Promise.reject(msg);
          });
      }
    })
      .then(() => {
        swal({
          text: 'Usuário convidado com sucesso',
          type: 'success'
        });
      })
      .catch(swal.noop);
  }
}
