import { Condo } from './condo';
import { BuildDeliveryTrackingSequence, DeliveryTrackingSequence } from './delivery-tracking-sequence';
import { Occurrence } from './interface/occurrence';
import { Residence } from './interface/residence';
import { OccurrenceBuilder } from './occurrence/occurrence.builder';
import { ResidenceBuilder } from './residence/residence.builder';
import { User } from './user';

export interface DeliveryTrackingHistory {
  _id: string;
  condo: Condo;
  residence: Residence;
  user: User;
  delivery: Occurrence;
  date: Date;
  sequence: DeliveryTrackingSequence;
  obs: string;
  createdBy: User;
  createdAt: Date;
}

export function BuildDeliveryTrackingHistory(data: any): DeliveryTrackingHistory {
  const buildedDeliveryTrackingSequence: DeliveryTrackingHistory = {
    _id: typeof data === 'string' ? data : data._id,
    condo: data.condo ? new Condo(data.condo) : ({} as Condo),
    residence: data.residence ? ResidenceBuilder.build(data.residence) : ({} as Residence),
    user: data.createdBy ? new User(data.user) : ({} as User),
    delivery: data.delivery ? OccurrenceBuilder.build(data.delivery) : ({} as Occurrence),
    date: new Date(data.date),
    sequence: data.sequence ? BuildDeliveryTrackingSequence(data.sequence) : ({} as DeliveryTrackingSequence),
    obs: data.obs,
    createdBy: data.createdBy ? new User(data.createdBy) : ({} as User),
    createdAt: new Date(data.createdAt)
  };

  return buildedDeliveryTrackingSequence;
}
