import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { tap, timeout } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { EcondosQuery } from '@api/model/query';
import { ResidenceServiceV2 } from '@api/serviceV2/residence.service';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';
import { Note } from '@api/model/note';
import { NoteService } from '@api/service/note.service';

@Component({
  selector: 'app-note-list',
  templateUrl: 'note-list.html',
  styleUrls: ['./note-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteListComponent implements OnChanges, OnDestroy, OnInit {
  @Input()
  residence: Residence;
  @Input()
  condo: Condo;
  @Input()
  user: User;

  @Output()
  noteUpdated: EventEmitter<Note[]> = new EventEmitter();

  @ViewChild('noteTemplate', { static: true }) noteTemplate: TemplateRef<any>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<any>;
  @ViewChild('notesTable', { static: true }) notesTable: TableComponent;
  subscription: Subscription = new Subscription();

  userData: User;

  page = 0;
  countData: number = 0;

  tableColumns: TableColumnDefinition<Note>[] = [];
  status: TableStatus = 'LOADING';

  documents: { [key: string]: { rg: string; cpf: string } } = {};
  notes: Note[] = [];

  constructor(private residenceService: ResidenceServiceV2, private noteService: NoteService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        columnId: 'note',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.noteTemplate,
        sortable: false
      }
    ];
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getData({ page = 0 } = {}) {
    this.status = 'LOADING';
    const query: EcondosQuery = {
      $select: 'text createdBy createdAt updatedBy updatedAt residence',
      $populate: [
        { path: 'createdBy', select: 'firstName lastName' },
        { path: 'updatedBy', select: 'firstName lastName' }
      ],
      residence: this.residence._id,
      $sort: { createdAt: -1 }
    };
    const { pageSize } = this.notesTable.getCurrentState();
    query.$page = page;
    query.$limit = pageSize;
    this.noteService.get(this.condo._id, query).subscribe({
      next: response => {
        this.countData = response.count;
        this.notes = [].concat(response.data);
        this.status = 'SUCCESS';
        this.cdr.detectChanges();
      },
      error: () => {
        this.status = 'ERROR';
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo && changes.residence && changes.user) {
      this.getData();
    }
  }
  async createNote() {
    const note = { residence: this.residence._id, createdBy: this.user };
    const swalOptions: SweetAlertOptions = {
      title: 'Adicionar Nota',
      input: 'textarea',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#F53D3D',
      confirmButtonText: 'Criar',
      confirmButtonColor: '#32DB64',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: text => {
        return this.noteService
          .create(this.condo._id, { ...note, text })
          .pipe(
            timeout(10000),
            tap((res: any) => {
              this.notes.unshift({ ...note, text, _id: res._id, createdAt: new Date() });
              this.notes = [].concat(this.notes);
              this.countData = +this.countData + 1;
              this.cdr.detectChanges();
            })
          )
          .toPromise()
          .then(({ _id }) => _id)
          .catch(error => {
            swal('Requisição falhou', error, 'error');
          });
      }
    };
    await swal(swalOptions).catch(swal.noop);
  }

  async editNote(note: Note) {
    const swalOptions: SweetAlertOptions = {
      input: 'textarea',
      inputValue: note.text,
      text: `Editar nota?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: text => {
        return this.noteService
          .update(this.condo._id, note._id, { text, updatedBy: this.user })
          .pipe(
            timeout(10000),
            tap(() => {
              const index = this.notes.findIndex(n => n._id === note._id);
              if (index > -1) {
                this.notes[index] = { ...note, text, updatedBy: this.user, updatedAt: new Date() };
                this.notes = [].concat(this.notes);
                this.cdr.detectChanges();
              }
            })
          )
          .toPromise()
          .then(() => 'success')
          .catch(err => {
            console.log(err);
            swal('Não foi possível excluir a nota, tente novamente...');
          });
      }
    };
    await swal(swalOptions).catch(swal.noop);
  }

  async deleteNote(note: Note) {
    const swalOptions: SweetAlertOptions = {
      type: 'question',
      text: `Deseja realmente remover esta nota?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.noteService
          .delete(this.condo._id, note._id)
          .pipe(
            timeout(10000),
            tap(() => {
              const index = this.notes.findIndex(n => n._id === note._id);
              if (index > -1) {
                this.notes.splice(index, 1);
                this.notes = [].concat(this.notes);
                this.countData = this.countData - 1;
                this.cdr.detectChanges();
              }
            })
          )
          .toPromise()
          .then(() => 'success')
          .catch(err => {
            console.log(err);
            swal('Não foi possível excluir a nota, tente novamente...');
          });
      }
    };

    await swal(swalOptions).catch(swal.noop);
  }
}
