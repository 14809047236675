import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { UtilService } from 'app/services/util.service';
import { ResidenceAutoCompleteComponent } from '../residence-auto-complete/residence-auto-complete.component';
import { ResidenceService } from '@api/service/residence.service';
import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';
import { File } from '@api/model/file';
import { DevicesRequestServiceV2 } from '@api/serviceV2/devices-requests.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { DEVICES_REQUEST_STATUS, DEVICES_REQUEST_STATUS_LABEL, DevicesRequest } from '@api/model/devices-requests';
import { Observable } from 'rxjs';
import { Residence } from '@api/model/interface/residence';

export interface AccessDevicesHardware {
  hardware: string;
  enabled?: boolean;
  availableTypes: string[];
}

@Component({
  selector: 'app-create-devices-request-modal',
  templateUrl: 'modal-create-devices-request.component.html',
  styleUrls: ['modal-create-devices-request.component.scss']
})
export class ModalCreateDevicesRequestComponent implements OnInit, AfterViewInit {
  condo: Condo;
  user: User;
  devicesRequest: DevicesRequest;

  DEVICES_REQUEST_STATUS = DEVICES_REQUEST_STATUS;
  DEVICES_REQUEST_STATUS_LABEL = DEVICES_REQUEST_STATUS_LABEL;

  isAdmin = false;

  form: UntypedFormGroup;
  residence: AbstractControl;
  resident: AbstractControl;
  vehicle: AbstractControl;
  type: AbstractControl;
  quantity: AbstractControl;
  requestDescription: AbstractControl;
  requestStatus: AbstractControl;

  facialFiles: Array<File> = [];

  @ViewChild(ResidenceAutoCompleteComponent) residencePicker: ResidenceAutoCompleteComponent;

  residenceUsers = [];
  residenceVehicles = [];
  deviceTypes: string[] = [];
  deviceTypesLabels = {
    RF: 'Controle de veículo',
    TA: 'Tag ativa',
    CT: 'Chaveiro pedestre',
    BM: 'Biometria',
    TP: 'Tag sem parar (veículo)',
    SN: 'Senha',
    CARD: 'Cartão',
    FACIAL: 'Facial',
    QR: 'QR Code',
    VEHICLE_PLATE: 'Placa'
  };

  callbacks: { success?: Function; error?: Function };

  status: '' | 'PROCESSING' | 'SUCCESS' | 'ERROR' = '';
  isUploading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private residenceService: ResidenceService,
    private devicesRequestService: DevicesRequestServiceV2
  ) {
    if (!this.user) {
      this.user = this.utilService.getLocalUser();
    }
    this.isAdmin = this.user.isAdmin() || this.user.isOwner();
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      residence: ['', Validators.required],
      resident: ['', Validators.required],
      vehicle: [''],
      type: ['', Validators.required],
      quantity: [1, [Validators.required, Validators.min(1)]],
      requestDescription: [''],
      requestStatus: ['']
    });

    this.residence = this.form.get('residence');
    this.resident = this.form.get('resident');
    this.vehicle = this.form.get('vehicle');
    this.type = this.form.get('type');
    this.quantity = this.form.get('quantity');
    this.requestDescription = this.form.get('requestDescription');
    this.requestStatus = this.form.get('requestStatus');

    if (this.devicesRequest) {
      this.resident.setValue(this.devicesRequest.user);
      this.resident.disable();
      this.facialFiles = this.devicesRequest.picture ? [this.devicesRequest.picture] : [];
      this.type.setValue(this.devicesRequest.type);
      this.quantity.setValue(this.devicesRequest.quantity);
      this.requestDescription.setValue(this.devicesRequest.requestDescription);
    }
  }

  ngOnInit(): void {
    this.user = this.utilService.getLocalUser();
    this.initializeForm();
    this.setCondoAvailableDeviceTypes();
  }

  ngAfterViewInit(): void {
    let userResidence: Residence;
    if (this.devicesRequest) {
      userResidence = this.devicesRequest.residence;
    } else {
      const userResidences = this.user.getResidences();
      if (userResidences?.length === 1) {
        userResidence = userResidences[0];
      }
    }
    if (userResidence) {
      this.residencePicker?.onTypeAheadSelect({ item: userResidence });
    }
  }

  setCondoAvailableDeviceTypes() {
    const accessDevicesHardwares: AccessDevicesHardware[] = [
      {
        hardware: HARDWARES.LINEAR,
        enabled: this.condo.linear.enabled || false,
        availableTypes: ['RF', 'CT', 'BM', 'TP']
      },
      {
        hardware: HARDWARES.CONTROL_ID,
        enabled: this.condo.controlId.enabled || false,
        availableTypes: ['BM', 'CARD', 'TP', 'FACIAL', 'CT', 'RF']
      },
      {
        hardware: HARDWARES.HIKVISION,
        enabled: this.condo.hikvision.enabled || false,
        availableTypes: ['FACIAL', 'QR', 'BM','TA']
      },
      {
        hardware: HARDWARES.UTECH,
        enabled: this.condo.utech.enabled || false,
        availableTypes: ['CARD', 'QR', 'RF', 'BM']
      },
      {
        hardware: HARDWARES.INTELBRAS,
        enabled: this.condo.intelbras.enabled || false,
        availableTypes: ['BM', 'FACIAL']
      },
      {
        hardware: HARDWARES.ALPHADIGI,
        enabled: this.condo.alphadigi.enabled || false,
        availableTypes: ['FACIAL', 'QR', 'VEHICLE_PLATE']
      },
      {
        hardware: HARDWARES.BRAVAS,
        enabled: this.condo.bravas.enabled || false,
        availableTypes: ['CARD', 'RF', 'SN', 'FACIAL']
      },
      {
        hardware: HARDWARES.GAREN,
        enabled: this.condo.garen.enabled || false,
        availableTypes: ['FACIAL', 'CARD', 'QR', 'SN']
      },
      {
        hardware: HARDWARES.INTELBRAS_STAND_ALONE,
        enabled: this.condo.intelbrasStandAlone.enabled || false,
        availableTypes: ['FACIAL', 'VEHICLE_PLATE']
      },
      {
        hardware: HARDWARES.ZKTECO,
        enabled: this.condo.zkteco.enabled || false,
        availableTypes: ['BM', 'FACIAL', 'CARD', 'QR', 'SN']
      }
    ];

    accessDevicesHardwares.forEach(accessDevicesHardware => {
      if (accessDevicesHardware.enabled) {
        accessDevicesHardware.availableTypes.forEach((type: string) => {
          if (!this.deviceTypes.includes(type)) {
            this.deviceTypes.push(type);
          }
        });
      }
    });
  }

  onResidenceSelect(residence) {
    this.residence.markAsTouched();
    if (residence) {
      this.residence.setValue(residence);
      if (this.devicesRequest?.residence) {
        this.residence.disable();
      }
      this.getResidentsAndVehiclesFromResidence(residence);
    }
  }

  getResidentsAndVehiclesFromResidence(residence) {
    this.residenceService
      .getUsersFromResidence(this.condo._id, residence._id)
      .pipe(timeout(10000))
      .subscribe(({ users }) => {
        this.residenceUsers = users;
      });

    this.residenceService
      .getVehicles(this.condo._id, residence._id)
      .pipe(timeout(10000))
      .subscribe(response => {
        this.residenceVehicles = response.vehicles;
        if (this.devicesRequest?.condoVehicle) {
          this.vehicle.setValue(this.devicesRequest.condoVehicle._id);
        }
      });
  }

  createDevicesRequest() {
    if (this.form.valid) {
      this.status = 'PROCESSING';

      const devicesRequest = {
        residence: this.residence.value,
        user: this.resident.value,
        ...(this.facialFiles.length ? { picture: this.facialFiles[0] } : { picture: null }),
        ...(this.vehicle.value && { condoVehicle: this.vehicle.value }),
        ...(this.requestStatus.value && { requestStatus: this.requestStatus.value, requestStatusDescription: '' }),
        type: this.type.value,
        quantity: this.quantity.value,
        requestDescription: this.requestDescription.value
      };

      let request: Observable<any>;

      if (this.devicesRequest) {
        request = this.devicesRequestService.updateDevicesRequest(this.condo._id, this.devicesRequest._id, devicesRequest);
      } else {
        request = this.devicesRequestService.createDevicesRequest(this.condo._id, devicesRequest);
      }

      request.pipe(timeout(10000)).subscribe(
        (response: { _id: string }) => {
          this.status = 'SUCCESS';

          if (this.callbacks && this.callbacks.success) {
            const updatedDeviceRequest = new DevicesRequest({
              ...this.devicesRequest,
              ...devicesRequest,
              updatedBy: this.user,
              updatedAt: new Date(),
              ...(response._id && { _id: response._id })
            });
            this.callbacks.success(updatedDeviceRequest);
          }

          this.bsModalRef.hide();
          this.toastr.success(`Solicitação de dispositivos de acesso ${this.devicesRequest ? 'atualizada' : 'criada'} com sucesso!`);
        },
        err => {
          this.status = 'ERROR';

          swal({
            type: 'error',
            text: `Não foi possível ${
              this.devicesRequest ? 'atualizar' : 'criar'
            } a solicitação de dispositivos de acesso, tente novamente.`
          });
        }
      );
    } else {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).markAsTouched());
    }
  }
}
