import { Condo } from './condo';
import { User } from './user';

export enum SMART_LOCKER_TYPE {
  CLIQUE_RETIRE = 'CLIQUE_RETIRE',
  LOCKIN = 'LOCKIN',
  HANDOVER = 'HANDOVER'
}

export enum SMART_LOCKER_TYPE_LABEL {
  CLIQUE_RETIRE = 'Clique Retire',
  LOCKIN = 'Lockin',
  HANDOVER = 'Handover'
}

export interface SmartLocker {
  _id?: string;
  externalId?: string;
  condo?: Partial<Condo>;
  name?: string;
  type?: SMART_LOCKER_TYPE;
  type_label?: SMART_LOCKER_TYPE_LABEL;
  createdBy?: Partial<User>;
  createdAt?: Date;
  updatedAt?: Date;
}

export const BuildSmartLocker = (smartLocker): SmartLocker => {
  if (typeof smartLocker == 'string') {
    return { _id: smartLocker };
  }

  if (smartLocker) {
    return {
      _id: smartLocker._id || '',
      externalId: smartLocker.externalId || '',
      condo: smartLocker.condo ? new Condo(smartLocker.condo) : null,
      name: smartLocker.name || '',
      type: smartLocker.type ? SMART_LOCKER_TYPE[smartLocker.type] : null,
      type_label: smartLocker.type ? SMART_LOCKER_TYPE_LABEL[smartLocker.type] : null,
      createdBy: smartLocker.createdBy ? new User(smartLocker.createdBy) : null,
      createdAt: new Date(smartLocker.createdAt) || null,
      updatedAt: new Date(smartLocker.updatedAt) || null
    };
  }
};
