import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'app/components/components.module';
import { GateChatGetOrCreateChatRoomComponent } from './get-or-create-chat-room/get-or-create-chat-room';
import { GateChatPanelComponent } from './gate-chat.panel/gate-chat.panel';
import { GateChatMessageComponent } from './gate-chat-message/gate-chat-message';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PipesModule } from '../pipe/pipes.module';

@NgModule({
  declarations: [GateChatPanelComponent, GateChatGetOrCreateChatRoomComponent, GateChatMessageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    PopoverModule,
    DragDropModule,
    InfiniteScrollModule,
    PipesModule
  ],
  exports: [GateChatPanelComponent]
})
export class GateChatModule {}
