import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { Observable, of } from 'rxjs';
import { ResidenceService } from '@api/service/residence.service';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { Condo } from '@api/model/condo';
import { UntypedFormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GateResidenceDetailsModalComponent } from 'app/pages/gate-residence-details.modal/gate-residence-details.modal.component';

@Component({
  selector: 'select-residence-input-component',
  templateUrl: './select-residence-input.component.html',
  styleUrls: ['./select-residence-input.component.scss']
})
export class SelectResidenceInputComponent implements OnInit {
  @Input()
  condo: Condo;

  @Input()
  selectedResidence: UntypedFormControl = new UntypedFormControl(undefined);

  @Input()
  placeholder = 'Busque por unidade';

  @Input()
  canRemove = true;

  @Input()
  query: EcondosQuery = {};

  @Input()
  canOpenResidenceDetailsModal = false;

  @Output()
  public onResidenceSelected: EventEmitter<Residence> = new EventEmitter<Residence>();

  residenceSearch = '';
  loadingResidences = false;
  residenceTypeAheadDataSource$: Observable<any>;

  residenceStatus;
  STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  };

  constructor(private residenceService: ResidenceService, private modalService: BsModalService) {}

  ngOnInit() {
    this.placeholder = `Buscar ${this.condo?.customLabels?.residence?.singular || 'unidade'}`;
    this.initializeTypeAhead();
  }

  initializeTypeAhead() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter((text: string) => !!(text || '').trim()),
      mergeMap((token: string) => {
        let query: EcondosQuery = {
          $select: 'block identification lot number address type users',
          $or: [],
          $populate: [
            { path: 'users', select: 'firstName lastName picture phones efone', populate: { path: 'picture', select: 'url thumbnail' } }
          ],
          $limit: 50
        };
        const query2 = { ...this.query };
        if (query2.$and?.length) {
          query.$and = [...query.$and, ...query2.$and];
          delete query2.$and;
        }
        if (query2.$populate?.length) {
          query.$populate = [...query.$populate, ...query2.$populate];
          delete query2.$populate;
        }
        if (query2.$or?.length) {
          query.$or = [...query.$or, ...query2.$or];
          delete query2.$or;
        }
        query = {
          ...query,
          ...query2
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          map(residences => {
            const collator = new Intl.Collator('pt-br', {
              numeric: true,
              sensitivity: 'base',
              caseFirst: 'false',
              usage: 'sort'
            });
            return residences.sort((a, b) => collator.compare(a.identification, b.identification));
          }),
          catchError(e => {
            this.residenceStatus = this.STATUS.ERROR;
            return of([]);
          })
        );
      })
    );
  }

  onResidenceDelete() {
    this.selectedResidence.setValue(undefined);
    this.onResidenceSelected.emit(null);
  }

  onResidenceSelect(evt) {
    const residence: Residence = evt.item;
    this.selectedResidence.setValue(residence);
    this.residenceSearch = '';
    this.onResidenceSelected.emit(evt.item);
  }

  openResidenceDetailsModal(residenceId: string) {
    if (residenceId) {
      const initialState = { condo: this.condo, residenceId };

      this.modalService.show(GateResidenceDetailsModalComponent, {
        initialState,
        class: 'modal-lg modal-xl',
        ignoreBackdropClick: true
      });
    }
  }
}
