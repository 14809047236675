import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UtilService } from '../services/util.service';

@Injectable()
export class HasGateAccessPermissionGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this.utilService.getLocalUser();

    const defaultCanAccess = user.isGatekeeper() || user.isAdmin() || user.isOwner();

    const deliveryRoutePathValidator = /deliver(y|ies)?/i;
    if (deliveryRoutePathValidator.test(state.url)) {
      return user.isJanitor() || defaultCanAccess;
    }

    if (defaultCanAccess) {
      return true;
    }
    this.router.navigate(['/notFound']);
    return false;
  }
}
