import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalNotificationManagerComponent } from './modal-notification-manager';
import { ComponentsModule } from '../../../components/components.module';
import { ThemeModule } from '../../../theme';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ModalModule, ComponentsModule, TooltipModule, ThemeModule],
  declarations: [ModalNotificationManagerComponent]
})
export class ModalNotificationManagerModule {}
