import { NgModule } from '@angular/core';
import { ModalResidentPickerComponent } from './modal-resident-picker.component';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../theme';
import { ComponentsModule } from '../../../components/components.module';
import { PipesModule } from '../../../pipe/pipes.module';

@NgModule({
  imports: [CommonModule, ThemeModule, ComponentsModule, PipesModule],
  declarations: [ModalResidentPickerComponent]
})
export class ModalResidentPickerModule {}
