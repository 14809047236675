import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'datePipe' })
export class DatePipe implements PipeTransform {
  constructor() {}

  transform(value: string, convertToUTC: Boolean, format?: string): string {
    let momentDate;
    momentDate = convertToUTC ? moment(new Date(value)).utc() : moment(new Date(value));
    if (momentDate.isValid()) return momentDate.format(format);
    else return value;
  }
}
