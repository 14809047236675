import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserContact } from '../model/contact/user.contact';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { map } from 'rxjs/operators';

@Injectable()
export class UserContactService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}users/`;
  }

  getUserContacts(userId: string, queryString?: string): Observable<UserContact[]> {
    return this.http
      .get(`${this.endPoint}${userId}/contacts${queryString || ''}`)
      .pipe(map((contacts: any) => contacts.map(contact => new UserContact(contact))));
  }

  getUserContactById(userId: string, contactId: string, queryString?: string) {
    return this.http
      .get(`${this.endPoint}${userId}/contacts/${contactId}${queryString || ''}`)
      .pipe(map(contact => new UserContact(contact)));
  }

  createUserContact(userId: string, contact: any) {
    return this.http.post(`${this.endPoint}${userId}/contacts`, contact);
  }

  deleteUserContact(userId: string, contactId: string) {
    return this.http.delete(`${this.endPoint}${userId}/contacts/${contactId}`);
  }

  updateUserContact(userId: string, contactId: string, contact: any) {
    return this.http.put(`${this.endPoint}${userId}/contacts/${contactId}`, contact);
  }
}
