import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class CustomRoleIpInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const url = request.url || '';
        if (
          err.status === 403 &&
          url.includes('/condos') &&
          err.error.error.includes('Your IP address are not allowed to access this resource')
        ) {
          this.router.navigate(['/no-custom-role-ip-error'], { state: { error: err.error } });
          return of(err);
        } else {
          return throwError(err);
        }
      })
    );
  }
}
