/**
 * Created by Rafael on 20/01/2017.
 */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
// Forms
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
// Components
import { UploadButton } from '../../../../components/upload-button';
// Services
import { CondoContactServiceV2 } from '../../../../api/serviceV2/condo.contact.service';
import swal from 'sweetalert2';
import { FileService } from '../../../../api/service/file.service';
import { UtilService } from '../../../../services/util.service';
// Models
import { File } from '../../../../api/model/file';
import { ContactID } from '../../../../api/model/contact/contact.id';
import { Vehicle } from '../../../../api/model/vehicle';
import { Condo } from '../../../../api/model/condo';
import { User } from '../../../../api/model/user';
import { CondoContact } from '../../../../api/model/contact/condo.contact';
import { Contact } from '../../../../api/model/interface/contact';
// Modals
import { ModalWebCam } from '../../../modal/webcam/webcam.modal';
import { VehicleCreateModal } from '../../../modal/vehicle.create.modal/vehicle.create.modal';
import { CondoVehicle } from '../../../../api/model/condo.vehicle';
import { CreateIdComponent } from '../../../../components/create.id/create.id';
import { EcondosQuery } from '@api/model/query';
import * as moment from 'moment';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'modal-new-condo-supplier',
  templateUrl: 'new.condo.supplier.modal.html'
})
export class ModalNewCondoSupplierComponent {
  @ViewChild('userImagePicker', { static: true }) userImageUploadButton: UploadButton;
  @ViewChild('vehicleCreateModal', { static: true }) vehicleCreateModal: VehicleCreateModal;
  @ViewChild('createIdComponent') createIdComponent: CreateIdComponent;

  @ViewChild(ModalWebCam, { static: true }) webcamModal: ModalWebCam;

  @ViewChild('newSupplierModal', { static: true }) public newSupplierModal: ModalDirective;

  @Input() condo: Condo;
  @Input() user: User;

  @Output() onCondoSupplierCreated = new EventEmitter();
  @Output() onCondoSupplierUpdated = new EventEmitter();

  contactForm: UntypedFormGroup;
  name: AbstractControl;
  company: AbstractControl;
  phone: AbstractControl;
  email: AbstractControl;
  vehicles: AbstractControl;
  uploadFileCallback;

  ids: Array<ContactID> = [];

  webcamCallback: Function;
  userImageWebcamCallback: Function;

  supplierToEdit: CondoContact;
  isSubmiting = false;

  uploadContactImageCallback;
  isUploadingContactImage = false;
  userPicture: File = null;

  isCreatingId = false;

  VEHICLE_TYPES = Vehicle.TYPES;

  // Vehicle vars
  onVehicleSelectedCallback: Function;

  editField = {
    email: false,
    ids: false,
    phone: false
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private fileService: FileService,
    private condoContactService: CondoContactServiceV2
  ) {
    this.initializeForm();
    this.initializeCallbacks();
  }

  initializeForm() {
    this.contactForm = this.formBuilder.group({
      name: [''],
      company: [''],
      phone: [''],
      email: [''],
      vehicles: [[]]
    });

    this.name = this.contactForm.get('name');
    this.company = this.contactForm.get('company');
    this.phone = this.contactForm.get('phone');
    this.email = this.contactForm.get('email');
    this.vehicles = this.contactForm.get('vehicles');
  }

  updateFormValues(contact?) {
    this.contactForm.reset();
    if (contact) {
      const phone = contact.phones && contact.phones[0] ? contact.phones[0] : '';
      const email = contact.emails && contact.emails[0] ? contact.emails[0] : '';

      this.name.setValue(contact.fullName || '');
      this.company.setValue(contact.company || '');
      this.phone.setValue(phone || '');
      this.email.setValue(email || '');
      this.vehicles.setValue(contact.condoVehicles || []);
      this.ids = contact.ids || [];
    } else {
      this.name.setValue('');
      this.company.setValue('');
      this.phone.setValue('');
      this.email.setValue('');
      this.vehicles.setValue([]);
      this.ids = [];
    }
  }

  initializeCallbacks() {
    const contactImageSuccessCallback = response => {
      this.userPicture = response[0];
    };

    const contactImageErrorCallback = err => {
      this.isUploadingContactImage = false;
    };

    this.uploadContactImageCallback = files => {
      this.isUploadingContactImage = true;
      this.uploadFiles(files, contactImageSuccessCallback, contactImageErrorCallback);
    };

    this.userImageWebcamCallback = image => {
      this.userPicture = image;
    };

    this.onVehicleSelectedCallback = vehicle => {
      const index = this.vehicles.value.findIndex(obj => vehicle._id == obj._id);

      if (index > -1) {
        this.vehicles.value[index] = vehicle;
      } else {
        this.vehicles.value.push(vehicle);
      }

      if (this.supplierToEdit) {
        this.supplierToEdit.condoVehicles = this.vehicles.value;
        this.onCondoSupplierUpdated.emit(this.supplierToEdit);
      }
    };
  }

  initializeImages(contact?) {
    if (contact) {
      this.userPicture = contact.picture || null;
    } else {
      this.userPicture = null;
    }
    this.isUploadingContactImage = false;
  }

  formatPhone() {
    let phoneNumber = this.phone.value;
    phoneNumber = this.utilService.formatPhone(phoneNumber);
    this.phone.setValue(phoneNumber);
  }

  createNewSupplierToSend() {
    const supplier = new CondoContact();
    const splittedNames = (this.name.value || '').match(/\S+/g) || [];
    const firstName = splittedNames.shift() || '';
    const lastName = splittedNames.join(' ') || '';
    supplier.firstName = firstName;
    supplier.lastName = lastName;
    supplier.ids = this.ids;
    supplier.emails = [this.email.value];
    // Remove todos caracteres não numéricos do telefone[this.phone.value];
    supplier.phones = this.phone.value ? [this.phone.value.toString().replace(/[^0-9]/g, '')] : [];
    supplier.picture = this.userPicture;
    supplier.type = Contact.TYPES.SUPPLIER;
    supplier.condoVehicles = this.vehicles.value;
    supplier.condo = this.condo;
    supplier.company = this.company.value;
    return supplier;
  }

  updateSupplierToEdit() {
    const splittedNames = (this.name.value || '').match(/\S+/g) || [];
    const firstName = splittedNames.shift() || '';
    const lastName = splittedNames.join(' ') || '';
    this.supplierToEdit.firstName = firstName;
    this.supplierToEdit.lastName = lastName;
    this.supplierToEdit.ids = this.ids;
    this.supplierToEdit.emails = [this.email.value];
    // Remove todos caracteres não numéricos do telefone[this.phone.value];
    this.supplierToEdit.phones = this.phone.value ? [this.phone.value.toString().replace(/[^0-9]/g, '')] : [];
    this.supplierToEdit.picture = this.userPicture;
    this.supplierToEdit.type = Contact.TYPES.SUPPLIER;
    this.supplierToEdit.condoVehicles = this.vehicles.value;
    this.supplierToEdit.condo = this.condo;
    this.supplierToEdit.company = this.company.value;
    return this.supplierToEdit;
  }

  createSupplier() {
    if (this.contactForm.invalid || (!this.name && !this.company.value)) {
      this.name.markAsTouched();
      this.company.markAsTouched();
      return;
    }

    this.isSubmiting = true;
    let contact;
    let request;
    if (this.supplierToEdit) {
      contact = this.updateSupplierToEdit();
      request = this.condoContactService.updateCondoContact(this.condo._id, contact.id, contact.createBackObject());
    } else {
      contact = this.createNewSupplierToSend();
      request = this.condoContactService.createCondoContact(this.condo._id, contact.createBackObject());
    }

    request.subscribe(
      createdContactId => {
        if (createdContactId && createdContactId._id) {
          contact.id = createdContactId._id;
        }
        if (this.supplierToEdit) {
          this.onCondoSupplierUpdated.emit(contact);
        } else {
          this.onCondoSupplierCreated.emit(contact);
        }
        this.isSubmiting = false;
        this.closeModal();
      },
      err => {
        this.isSubmiting = false;
        swal({
          text: `Não foi possível ${this.supplierToEdit ? 'atualizar' : 'criar'} o usuário, tente novamente`
        });
      }
    );
  }

  uploadFiles(files, successCallback, errorCallback) {
    for (const f of files) {
      const formData = new FormData();
      formData.append(f.title || 'file', f);

      this.fileService.uploadFilesFromFormData(formData).subscribe(
        response => {
          successCallback(response);
        },
        err => {
          errorCallback(err);
        }
      );
    }
  }

  pickUserImage() {
    this.userImageUploadButton.triggerClick();
  }

  pickImageFromWebcam(callback) {
    this.webcamCallback = callback;
    this.webcamModal.showModal();
  }

  closeModal(): void {
    this.newSupplierModal.hide();
  }

  showModal(contact?) {
    if (contact) {
      contact = _.cloneDeep(contact);
    }
    this.updateFormValues(contact);
    this.initializeImages(contact);
    this.supplierToEdit = contact || null;
    this.isCreatingId = false;
    this.newSupplierModal.show();
  }

  removeVehicle(vehicle: CondoVehicle) {
    this.vehicles.value.splice(this.vehicles.value.indexOf(vehicle), 1);
  }

  editVehicle(vehicle: CondoVehicle) {
    this.vehicleCreateModal.editVehicle(vehicle);
  }

  createNewVehicle() {
    this.vehicleCreateModal.createVehicle();
  }

  onLoad(event, img) {
    this.isUploadingContactImage = false;
  }

  onError(event, img) {
    this.isUploadingContactImage = false;
  }

  enableFieldToEdit(field: string) {
    this.editField[field] = !this.editField[field];

    if (this.editField[field]) {
      this.unmaskField(field);
      this.contactForm.addControl(field, this[field]);
    } else {
      this.returnMaskedValue(field);

      if (field === 'id') {
        this.contactForm.removeControl('documentNumber');
      } else {
        this.contactForm.removeControl(field);
      }
    }

    this.contactForm.updateValueAndValidity();
  }

  unmaskField(field: string) {
    let fieldToSearch = '';
    switch (field) {
      case 'phone':
        fieldToSearch = 'phones';
        break;

      case 'email':
        fieldToSearch = 'emails';
        break;
    }

    const query: EcondosQuery = {
      $select: fieldToSearch,
      $and: []
    };

    let callback;

    switch (field) {
      case 'email':
        callback = ({ data }) => {
          if (data[0]) {
            this.email.setValue(data[0]);
          }
        };
        break;

      case 'phone':
        callback = ({ data }) => {
          if (data[0]) {
            this.phone.setValue(this.utilService.formatPhone(data[0]));
          }
        };
        break;
    }

    this.condoContactService
      .getCondoContactUnmaskedField(this.condo._id, this.supplierToEdit._id, fieldToSearch, query)
      .pipe(timeout(10000))
      .subscribe(callback);
  }

  returnMaskedValue(field: string) {
    switch (field) {
      case 'email':
        if (this.supplierToEdit.email) {
          this.email.setValue(this.supplierToEdit.email);
        }
        break;

      case 'phone':
        if (this.supplierToEdit.phones[0]) {
          this.phone.setValue(this.utilService.formatPhone(this.supplierToEdit.phones[0]));
        }
        break;
    }
  }
}
