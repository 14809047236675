import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UtilService } from '../services/util.service';

@Injectable({ providedIn: 'root' })
export class HasAdminOrOwnerOrUserAccessGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const user = this.utilService.getLocalUser();
    if (user.isUser() || user.isAdmin() || user.isOwner()) {
      return true;
    }

    this.router.navigate(['/notFound']);
    return false;
  }
}
