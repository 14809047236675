import { createFeatureSelector } from '@ngrx/store';
import { Params, RouterStateSnapshot } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface State {
  router: RouterReducerState<RouterStateUrl>;
}

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}

export interface UserPreferences {
  shouldAlarm: boolean;
  gateEventsNotification: {
    warnings: boolean;
    resident: boolean;
    panic: boolean;
    unknownPlate: boolean;
    visitor: boolean;
    others: boolean;
    trigger: boolean;
  };

  gateEventsScreenDisplay: {
    warning: boolean;
    resident: boolean;
    panic: boolean;
    unknownPlate: boolean;
    visitor: boolean;
    other: boolean;
    trigger: boolean;
  };
}

export const setUserPreferences = (userId: string, preferences: UserPreferences) => {
  localStorage.setItem(`econdos:${userId}_preferences`, JSON.stringify(preferences));
};

export const loadUserPreferences = (userId: string): UserPreferences => {
  const preferencesString = localStorage.getItem(`econdos:${userId}_preferences`) || '{}';
  const preferences = JSON.parse(preferencesString);
  const userPreferences: UserPreferences = {
    shouldAlarm: preferences.shouldAlarm ? preferences.shouldAlarm : false,
    gateEventsNotification: {
      trigger: preferences.gateEventsNotification ? preferences.gateEventsNotification.trigger : true,
      others: preferences.gateEventsNotification ? preferences.gateEventsNotification.others : true,
      warnings: preferences.gateEventsNotification ? preferences.gateEventsNotification.warnings : true,
      panic: preferences.gateEventsNotification ? preferences.gateEventsNotification.panic : true,
      resident: preferences.gateEventsNotification ? preferences.gateEventsNotification.resident : true,
      unknownPlate: preferences.gateEventsNotification ? preferences.gateEventsNotification.unknownPlate : true,
      visitor: preferences.gateEventsNotification ? preferences.gateEventsNotification.visitor : true
    },
    gateEventsScreenDisplay: {
      trigger: preferences.gateEventsScreenDisplay ? preferences.gateEventsScreenDisplay.trigger : true,
      other: preferences.gateEventsScreenDisplay ? preferences.gateEventsScreenDisplay.other : true,
      warning: preferences.gateEventsScreenDisplay ? preferences.gateEventsScreenDisplay.warning : true,
      panic: preferences.gateEventsScreenDisplay ? preferences.gateEventsScreenDisplay.panic : true,
      resident: preferences.gateEventsScreenDisplay ? preferences.gateEventsScreenDisplay.resident : true,
      unknownPlate: preferences.gateEventsScreenDisplay ? preferences.gateEventsScreenDisplay.unknownPlate : true,
      visitor: preferences.gateEventsScreenDisplay ? preferences.gateEventsScreenDisplay.visitor : true
    }
  };
  return userPreferences;
};
