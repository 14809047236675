import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '@api/model/condo';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { Device, DEVICE_STATUS_LABEL } from '@api/model/hardware/device';
import { WEEK_DAYS } from '@api/util/constants';
import { ClipboardService } from 'ngx-clipboard';
import { File as EcondosFile } from '@api/model/file';
import { ActuatorData } from '@api/model/hardware/actuator-data';
import { TableColumnDefinition, TableStatus } from '../table/table.component';
import * as moment from 'moment';

type DeviceOwner = {
  name: string;
  picture: EcondosFile;
  personCustomLabel: string;
  type: 'RESIDENT' | 'DEPENDENT' | 'VISITOR';
};

type ActuatorInfo = {
  name: string;
  typeIcon?: string;
  toAdd: boolean;
  toRemove: boolean;
  operation: string;
  date: Date;
  success: boolean;
  error: string;
};

const query = {
  $select: 'actuator device serial operation validFrom validUntil status afterSyncStatus error success device updatedAt',
  $populate: [
    { path: 'actuator', select: 'name' },
    {
      path: 'device',
      select: 'permission status afterSyncStatus',
      populate: [
        { path: 'permission', select: 'status' },
        {
          path: 'owner.user',
          select: 'firstName lastName',
          populate: [{ path: 'picture', select: 'url thumbnail' }],
          options: { withDeleted: true }
        },
        {
          path: 'owner.dependent',
          select: 'name picture',
          populate: [{ path: 'picture', select: 'url thumbnail' }],
          options: { withDeleted: true }
        },
        { path: 'owner.picture', select: 'url thumbnail' },
        {
          path: 'owner.condoContact',
          select: 'firstName lastName',
          populate: [{ path: 'picture', select: 'url thumbnail' }],
          options: { withDeleted: true }
        },
        { path: 'owner.residence', select: 'identification' }
      ]
    }
  ]
};

const operationNames = {
  removeFacialJob: 'Remover facial',
  addFacialJob: 'Adicionar facial',
  updateFacialJob: 'Atualizar facial',
  INSERT: 'Adicionar placa',
  DELETE: 'Remover placa'
};

@Component({
  templateUrl: 'modal-device-sync-information.component.html',
  styleUrls: ['modal-device-sync-information.component.scss']
})
export class ModalDeviceSyncInformationComponent implements OnInit {
  @ViewChild('actuatorCellTemplate', { static: true }) actuatorCellTemplate: TemplateRef<any>;

  condo: Condo;

  storedDevice: Device;
  device: Device;

  actuatorData: ActuatorData[] = [];
  actuatorsInfo: ActuatorInfo[] = [];

  STATUS = DEVICE_STATUS_LABEL;
  WEEK_DAYS = WEEK_DAYS;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<ActuatorInfo>[] = [];

  public FACIAL_JOB_NAMES = operationNames;

  deviceOwner: DeviceOwner = null;

  constructor(
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private deviceService: HardwareDeviceService,
    private clipboard: ClipboardService
  ) {}

  ngOnInit() {
    this.device = this.storedDevice;

    this.tableColumns = [
      { headerLabel: 'Acionador', headerClass: 'text-sm', valueTemplate: this.actuatorCellTemplate },
      {
        headerLabel: 'Pendências',
        headerClass: 'text-sm',
        valueFn: actuatorInfo => {
          if (!actuatorInfo.success) {
            if (actuatorInfo.toRemove) {
              return 'Removendo...';
            } else if (actuatorInfo.toAdd) {
              return 'Adicionando...';
            } else {
              return '';
            }
          } else {
            return 'Concluído';
          }
        },
        cellClass: 'text-sm'
      },
      {
        headerLabel: 'Operação',
        headerClass: 'text-sm',
        valueFn: actuatorInfo => this.FACIAL_JOB_NAMES[actuatorInfo.operation] || '-',
        cellClass: 'text-sm'
      },
      {
        headerLabel: 'Data',
        headerClass: 'text-sm',
        valueFn: actuatorInfo => (actuatorInfo.date ? moment(actuatorInfo.date).format('DD/MM/YY - HH:mm') : '-'),
        cellClass: 'text-sm',
        width: '6.5rem'
      },
      {
        headerLabel: 'Status',
        headerClass: 'text-sm',
        type: 'badge',
        valueFn: actuatorInfo => (actuatorInfo.success ? 'Sucesso' : 'Erro'),
        cellClassFn: actuatorInfo => (actuatorInfo.success ? 'badge-subtle-success' : 'badge-subtle-danger')
      },
      {
        headerLabel: 'Mensagem',
        headerClass: 'text-sm',
        valueFn: actuatorInfo => (actuatorInfo.success ? 'Sucesso' : actuatorInfo.error || '-'),
        cellClass: 'text-sm'
      }
    ];

    if (this.device) {
      let deviceOwnerName: string;
      let deviceOwnerPicture: EcondosFile;
      let deviceOwnerPersonCustomLabel: string;
      let deviceOwnerType: DeviceOwner['type'];

      if (this.device.owner.user) {
        deviceOwnerName = `${this.device.owner.user.firstName || ''} ${this.device.owner.user.lastName || ''}`.trim();
        deviceOwnerPicture = this.device.owner.user.picture;
        deviceOwnerPersonCustomLabel = this.condo.customLabels.resident.singular || 'condômino';
        deviceOwnerType = 'RESIDENT';
      } else if (this.device.owner.condoContact) {
        deviceOwnerName = `${this.device.owner.condoContact.firstName || ''} ${this.device.owner.condoContact.lastName || ''}`.trim();
        deviceOwnerPicture = this.device.owner.condoContact.picture;
        deviceOwnerPersonCustomLabel = this.condo.customLabels.visitor.singular || 'visitante';
        deviceOwnerType = 'VISITOR';
      } else if (this.device.owner.dependent) {
        deviceOwnerName = this.device.owner.dependent.name;
        deviceOwnerPicture = this.device.owner.dependent.picture;
        deviceOwnerPersonCustomLabel = this.condo.customLabels.dependent.singular || 'dependente';
        deviceOwnerType = 'DEPENDENT';
      } else {
        deviceOwnerName = this.device.owner.userName || 'Não informado';
        deviceOwnerPicture = this.device.owner.picture;
        deviceOwnerPersonCustomLabel = this.condo.customLabels.resident.singular || 'condômino';
        deviceOwnerType = 'RESIDENT';
      }

      this.deviceOwner = {
        name: deviceOwnerName,
        picture: deviceOwnerPicture,
        personCustomLabel: deviceOwnerPersonCustomLabel,
        type: deviceOwnerType
      };
    }

    if (this.actuatorData?.length) {
      this.actuatorsInfo = this.transformActuatorData(this.actuatorData);
      this.status = 'SUCCESS';
    } else {
      this.getDeviceInfo();
    }
  }

  protected getDeviceInfo() {
    this.status = 'LOADING';

    if (this.deviceOwner.type === 'RESIDENT' || this.deviceOwner.type === 'DEPENDENT') {
      query['success'] = false;
    }

    this.deviceService.getActuatorData(this.condo._id, this.storedDevice._id, query).subscribe({
      next: actsData => {
        this.actuatorsInfo = this.transformActuatorData(actsData);
        this.status = 'SUCCESS';
      },
      error: err => {
        this.status = 'ERROR';
        console.log(err);
      }
    });
  }

  private transformActuatorData(actuatorData: ActuatorData[]): ActuatorInfo[] {
    return actuatorData.map(a => {
      return {
        name: a.actuator?.name || 'Não encontrado',
        typeIcon: a.actuator?.typeIcon,
        toAdd: a.operation === 'addFacialJob',
        toRemove: a.operation === 'removeFacialJob',
        operation: a.operation,
        date: a.updatedAt,
        success: a.success,
        error: a.error
      };
    });
  }

  protected hide() {
    this.bsModalRef.hide();
  }

  protected copySerial(serial: string): void {
    this.clipboard.copy(serial);
    this.toastrService.success(`Serial ${serial} copiado!`);
  }
}
