import { Component, OnInit } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { CameraService } from '@api/service/camera.service';
import { Camera } from '@api/model/camera';
import { FeedbackContainerComponent } from '../components/feedback-container/feedback-container.component';
import { EcondosQuery } from '@api/model/query';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'app-cameras',
  templateUrl: './cameras.component.html',
  styleUrls: ['./cameras.component.scss'],
  standalone: true,
  imports: [ComponentsModule, CommonModule, FeedbackContainerComponent]
})
export class CamerasComponent implements OnInit {
  cameras: Camera[] = [];

  status: 'SUCCESS' | 'ERROR' | 'LOADING';

  constructor(private sessionService: SessionService, private cameraService: CameraService) {}

  ngOnInit(): void {
    this.getCameras();
  }

  getCameras() {
    this.status = 'LOADING';
    const condo = this.sessionService.condoValue;
    const query: EcondosQuery = {
      $sort: 'name'
    };
    this.cameraService.get(condo._id, query).subscribe({
      next: ({ cameras }) => {
        this.cameras = cameras;
        this.status = 'SUCCESS';
      },
      error: err => {
        this.status = 'ERROR';
      }
    });
  }
}
