import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { takeUntil } from 'rxjs/operators';
import swal from 'sweetalert2';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UtilService } from 'app/services/util.service';

@Component({
  templateUrl: 'modal-preview-webcam.component.html',
  styleUrls: ['modal-preview-webcam.component.scss']
})
export class ModalPreviewWebcamComponent implements OnInit, OnDestroy {
  useImageCropper: Boolean = true;
  webcams: MediaDeviceInfo[] = [];
  faceCrop: UntypedFormControl = new UntypedFormControl(false);
  private selectWebcam$: Subject<string> = new Subject<string>();
  private trigger: Subject<void> = new Subject<void>();
  selectedWebCam: UntypedFormControl = new UntypedFormControl('');

  activeHelpMask = false;

  private unsubscribe: Subject<void> = new Subject();

  callback: (base64, crop) => void;

  preview;
  croppedImage: any;

  constructor(public bsModalRef: BsModalRef, private utilService: UtilService) {}

  ngOnInit() {
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.webcams = [];
      this.webcams = mediaDevices && mediaDevices.filter(d => d.kind === 'videoinput');
      if (this.webcams.length) {
        this.selectedWebCam.setValue(this.webcams[0].deviceId, { emitEvent: false });
        this.selectWebcam$.next(this.webcams[0].deviceId);
      }
    });

    this.selectedWebCam.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(cam => {
      this.selectWebcam$.next(cam);
    });
    if (this.useImageCropper) {
      const localImageCroppper = this.utilService.getLocalUseImageCropper() || false;
      this.faceCrop.setValue(localImageCroppper);
      this.faceCrop.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        this.utilService.saveLocalUseImageCropper(value);
        if (!value) {
          this.croppedImage = null;
        }
      });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public get selectedWebcam(): Observable<string> {
    return this.selectWebcam$.asObservable();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public triggerWebCamSnapshot(): void {
    if (this.activeHelpMask) {
      this.activeHelpMask = false;
    }

    this.trigger.next(null);
  }

  public handleSnapshotFromWebCam(data) {
    this.preview = data.imageAsDataUrl;
  }

  submit(preview) {
    if (this.callback) {
      this.callback(preview, this.faceCrop.value);
      this.bsModalRef.hide();
    }
  }

  public handleInitError(err: WebcamInitError): void {
    let error = '';
    /* handle the error */
    if (err.mediaStreamError.name == 'NotFoundError' || err.mediaStreamError.name == 'DevicesNotFoundError') {
      error =
        'Nenhum dispositivo de captura encontrado, verifique se há algum dispositivo instalado, se ele está ativado e tente novamente.';
    } else if (err.mediaStreamError.name == 'NotReadableError' || err.mediaStreamError.name == 'TrackStartError') {
      // webcam or mic are already in use
      error = 'Sua câmera está em uso por outra aplicação, encerre essa aplicação e tente novamente.';
    } else if (err.mediaStreamError.name == 'OverconstrainedError' || err.mediaStreamError.name == 'ConstraintNotSatisfiedError') {
      // constraints can not be satisfied by avb. devices
      error =
        'Parece que sua cÂmera não cumpre os requisitos mínimos de hardware para essa aplicação. ' +
        'Tente reiniciar o navegador e tentar novamente. Se o problema persisitr, contate o suporte';
    } else if (err.mediaStreamError.name == 'NotAllowedError' || err.mediaStreamError.name == 'PermissionDeniedError') {
      // permission denied in browser
      error =
        'Permissão de acesso a câmera negada. Seu navegador está bloqueando o acesso a câmera, libere a permissão para acesso a câmera e tente novamente.';
    } else if (err.mediaStreamError.name == 'TypeError' || err.mediaStreamError.name == 'TypeError') {
      // empty constraints object
    } else {
      // other errors
      error =
        'Não é possível utilizar sua câmera, reinicie o navegador e tente novamente. Se o problema persistir, anote o error a seguir e contate o suporte: ' +
        err.toString();
    }
    swal({ type: 'error', text: error || err.message });
  }
}
