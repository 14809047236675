import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type BiometricHandFinger =
  | 'LEFT_LITTLE_FINGER'
  | 'LEFT_RING_FINGER'
  | 'LEFT_MIDDLE_FINGER'
  | 'LEFT_INDEX_FINGER'
  | 'LEFT_THUMB'
  | 'RIGHT_LITTLE_FINGER'
  | 'RIGHT_RING_FINGER'
  | 'RIGHT_MIDDLE_FINGER'
  | 'RIGHT_INDEX_FINGER'
  | 'RIGHT_THUMB';

@Component({
  selector: 'app-biometric-hand',
  standalone: true,
  imports: [],
  templateUrl: './biometric-hand.component.html',
  styleUrls: ['./biometric-hand.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BiometricHandComponent
    }
  ]
})
export class BiometricHandComponent implements ControlValueAccessor {
  @Input() side: 'left' | 'right' = 'left';

  selectedFinger: BiometricHandFinger = null;

  touched = false;

  isDisabled = false;

  isValid = true;

  onChange = value => {};

  onTouched = () => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: BiometricHandFinger): void {
    this.selectedFinger = value;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  handleClick(value: BiometricHandFinger) {
    if (!this.isDisabled) {
      this.markAsTouched();

      this.selectedFinger = value;

      this.onChange(this.selectedFinger);
    }
  }
}
