import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Camera } from '@api/model/camera';
import { from, of, Subject } from 'rxjs';
import { catchError, expand, filter, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { createImageFromBlob, isElectron } from '@api/utils';
import { HttpCameraService } from './http-camera.service';

@Component({
  selector: 'app-http-camera-viewer',
  templateUrl: './http-camera-viewer.component.html',
  styleUrls: ['./http-camera-viewer.component.scss']
})
export class HttpCameraViewerComponent implements OnChanges, OnDestroy {
  @ViewChild('componentWrapper', { static: false }) componentWrapper;
  @ViewChild('imageElement', { static: true }) imageElement;

  @Input() camera: Camera | null = null;
  @Input() fitParentSize = true;
  @Input() displayCameraName = true;

  @Input() placeholderHeight = '100%';
  @Input() placeholderWidth = '100%';

  @Input() containerStyle: { [key: string]: string } = { height: '100%', 'max-height': '100%' };

  @Input() rotate = 0;

  @Output() handleSnapshot: EventEmitter<string> = new EventEmitter();
  @Output() handleSuccessLoad = new EventEmitter();
  @Output() handleError: EventEmitter<any> = new EventEmitter();
  @Output() handleClick: EventEmitter<Camera> = new EventEmitter();

  public activeHelpMask = false;

  public status: 'PROCESSING' | 'SUCCESS' | 'ERROR';

  private isElectron;
  private unsubscribe: Subject<void> = new Subject();

  public snapshot$;
  public snapshotData: string;
  public imgStyle: { [key: string]: string } = {};

  constructor(private httpCameraService: HttpCameraService) {
    this.isElectron = isElectron();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.camera?.currentValue) {
      if (changes.camera.previousValue && changes.camera.previousValue._id !== changes.camera.currentValue._id) {
        this.unsubscribe.next(null);
      }
      const camera = changes.camera.currentValue;
      this.status = 'PROCESSING';
      let url;
      if (this.isElectron) {
        url = camera.snapshotUrl;
      } else {
        url = `http://localhost:5050/${camera.snapshotUrl}`;
      }
      const observable = this.httpCameraService.get(camera._id, url).pipe(
        timeout(12000),
        catchError(err => {
          this.status = 'ERROR';
          this.handleError.emit(err);
          return of(null);
        }),
        filter(res => res),
        switchMap((response: any) => from(createImageFromBlob(response))),
        tap(data => {
          this.status = 'SUCCESS';
          this.snapshotData = data;
        })
      );
      this.snapshot$ = observable.pipe(
        tap(() => {
          if (this.fitParentSize) {
            this.imgStyle.height = '100%';
            this.imgStyle.width = '100%';
          }
          this.handleSuccessLoad.emit();
        }),
        catchError(e => {
          console.log(e);
          this.status = 'ERROR';
          return of('assets/img/no-image.jpg');
        }),
        expand(() => observable),
        takeUntil(this.unsubscribe)
      );
    }
  }

  public ngOnDestroy(): void {
    this.destroy();
    this.unsubscribe.complete();
  }

  private destroy() {
    this.unsubscribe.next(null);
  }

  public takeSnapshot() {
    this.handleSnapshot.next(this.snapshotData);
    return this.snapshotData;
  }

  click() {
    this.handleClick.next(this.camera);
  }
}
