import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UtilService } from '../services/util.service';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { PermissionKey } from '@api/model/custom-role/custom-role-permissions';
import { validateUserPermissions } from 'utils/validateUserPermissions';

@Directive({
  selector: '[appHasPermission]',
  standalone: true
})
export class HasPermissionDirective {
  condo: Condo;
  user: User;

  @Input() set appHasPermission(permissionsToValidate: PermissionKey[] | PermissionKey | string) {
    const showElement = validateUserPermissions(this.user, this.condo, permissionsToValidate);
    this.toggleElementVisibility(showElement);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private utilService: UtilService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.user.defaultCondo;
  }

  private toggleElementVisibility(showElement: boolean) {
    if (showElement) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
