import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { User } from '@api/model/user';
import { firstValueFrom, forkJoin, merge, Observable, Subject } from 'rxjs';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { debounceTime, ignoreElements, map, mergeMap, retry, switchMap, takeUntil, tap, timeout, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { Group } from '@api/model/group';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalCreateGroupComponent } from '../../../components/modal-create-group/modal-create-group';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { EcondosQuery } from '@api/model/query';
import { CondoUser } from '@api/model/condo-user';
import { GroupService } from '@api/serviceV2/group.service';
import { CondoService } from '@api/service/condo.service';
import { Residence } from '@api/model/interface/residence';
import { FormControl } from '@angular/forms';

interface UserData {
  name: string;
  _id: string;
  picture: string;
  residences: string;
}
@Component({
  selector: 'app-condo-groups',
  templateUrl: 'condo-groups.html',
  styleUrls: ['./condo-groups.scss']
})
export class CondoGroupsComponent implements OnInit, OnDestroy {
  user: User;
  condo: Condo;

  originalGroupMembers: Array<UserData['_id']> = [];

  groups: Array<Group> = [];
  totalOfGroups = 0;
  selectedGroup: Group;

  otherUsers: UserData[] = [];
  filteredOtherUsers: UserData[] = [];

  groupMembers: Array<UserData> = [];
  filteredGroupMembers: Array<UserData> = [];

  selectedGroupTotalOfMembers = 0;

  groupNameToFilter = '';

  permissions: { canEditMembers: boolean; canEditGroups: boolean } = { canEditMembers: false, canEditGroups: false };

  groupsLoadingStatus = new Status();
  loadingGroupsProgress = 0;
  groupMembersLoadingStatus = new Status();
  loadingMembersProgress = 0;
  loadingOtherUsersProgress = 0;

  selectedUsers: Record<string, UserData> = {};
  selectedUsersFromGroup: Record<string, UserData> = {};

  unsubscribe$ = new Subject<void>();

  SOURCES = {
    USERS: 'USERS',
    USERS_GROUP: 'USERS_GROUP'
  };

  otherUsersSearchTerm = new FormControl('');
  groupMembersSearchTerm = new FormControl('');

  constructor(
    private groupService: GroupService,
    private utilService: UtilService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private condoService: CondoService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();
  }

  ngOnInit() {
    this.permissions.canEditGroups = this.user.isAdmin() || this.user.isOwner();
    this.getGroups();
    this.groupMembersSearchTerm.valueChanges.pipe(debounceTime(500)).subscribe(searchTerm => {
      if (searchTerm) {
        this.filteredGroupMembers = this.groupMembers.filter(user => this.filterUser({ user, searchTerm }));
      } else {
        this.filteredGroupMembers = this.groupMembers;
      }
    });

    this.otherUsersSearchTerm.valueChanges.pipe(debounceTime(500)).subscribe(searchTerm => {
      if (searchTerm) {
        this.filteredOtherUsers = this.otherUsers.filter(user => this.filterUser({ user, searchTerm }));
      } else {
        this.filteredOtherUsers = this.otherUsers;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getGroups() {
    const query: EcondosQuery = {
      $select: 'name description icon',
      $sort: 'name'
    };

    if (this.groupNameToFilter) {
      query.name = { $regex: this.groupNameToFilter, $options: 'i' };
    }

    this.groupsLoadingStatus.setAsDownloading();

    this.groupService
      .get(this.condo._id, query)
      .pipe(timeoutWith(20000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        ({ count, groups }) => {
          this.totalOfGroups = count;
          this.groups = groups;
          this.groupsLoadingStatus.setAsSuccess();
        },
        err => {
          this.groupsLoadingStatus.setAsError();
        }
      );
  }

  getGroupMembers() {
    this.loadingMembersProgress = 1;
    this.loadingOtherUsersProgress = 1;
    this.groupMembersLoadingStatus.setAsDownloading();

    const query: EcondosQuery = {
      $populate: [
        {
          path: 'member',
          select: 'firstName lastName picture residencesUser',
          populate: [
            { path: 'picture', select: 'url thumbnail type name format' },
            { path: 'residencesUser', select: 'identification' }
          ]
        }
      ],

      condo: this.condo._id,
      group: this.selectedGroup._id
    };
    const userQuery: EcondosQuery = {
      $select: 'firstName lastName',
      $populate: [
        { path: 'residencesUser', select: 'identification' },
        { path: 'residencesVoter', select: 'identification' },
        { path: 'picture', select: 'name type url thumbnail' }
      ]
    };

    return this.groupService
      .getGroupMembersInChunks(this.condo._id, this.selectedGroup._id, query)
      .pipe(
        timeout(10_000),
        mergeMap(([finalResult, progress]) =>
          merge(
            progress.pipe(
              tap((value: number) => {
                this.loadingMembersProgress = value;
              }),
              ignoreElements()
            ),
            finalResult
          )
        ),
        switchMap(finalResult => {
          return this.condoService.getAllCondoResidents(this.condo._id, userQuery).pipe(
            timeout(10_000),
            mergeMap(([residentResult, progress]) =>
              merge(
                progress.pipe(
                  tap((value: number) => {
                    this.loadingOtherUsersProgress = value;
                  }),
                  ignoreElements()
                ),
                residentResult
              )
            ),
            takeUntil(this.unsubscribe$),
            map(residentResult => ({ residentResult, finalResult }))
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        ({ residentResult, finalResult }) => {
          this.selectedGroupTotalOfMembers = finalResult.count;
          this.groupMembers = finalResult.data.map(user => this.buildUserWithResidences(user));
          this.filteredGroupMembers = this.groupMembers;
          this.groupMembersLoadingStatus.setAsSuccess();

          const membersId = this.groupMembers.map(user => user._id);
          const otherUsers = residentResult.data.filter(user => !membersId.includes(user._id));
          this.otherUsers = otherUsers.map(user => this.buildUserWithResidences(user));
          this.filteredOtherUsers = this.otherUsers;
          this.originalGroupMembers = this.groupMembers.map(user => user._id);
        },
        err => {
          console.log(err);
          this.groupMembersLoadingStatus.setAsError();
        }
      );
  }

  create() {
    const initialState = {
      condo: this.condo,
      onCreate: group => {
        this.getGroups();
      }
    };
    this.modalService.show(ModalCreateGroupComponent, { initialState });
  }

  edit(group: Group) {
    const initialState = {
      condo: this.condo,
      group,
      onUpdate: updatedGroup => {
        this.getGroups();
        this.onSelectGroup(updatedGroup);
      }
    };
    this.modalService.show(ModalCreateGroupComponent, { initialState });
  }

  save() {
    const usersToInsert = this.groupMembers.filter(user => !this.originalGroupMembers.includes(user._id)).map(user => user._id);
    const usersToRemove = this.originalGroupMembers.filter(
      originalGroupUser => !this.groupMembers.map(user => user._id).includes(originalGroupUser)
    );

    if (!usersToInsert.length && !usersToRemove.length) {
      return swal({
        type: 'info',
        title: 'Adicionar/remover no grupo de usuários',
        html: `
         Não existem alterações para serem salvas
        `
      });
    }

    swal({
      type: 'question',
      title: 'Adicionar/remover no grupo de usuários',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      html: `
       ${usersToInsert ? 'Adicionar ' + usersToInsert.length + ' usuário(s)' : ''} <br/>
       ${usersToRemove ? 'Remover ' + usersToRemove.length + ' usuário(s)' : ''} <br/>
       Deseja fazer isso?
      `,
      preConfirm: async () => {
        const requests: Observable<Object | Error>[] = [];

        if (usersToInsert.length) {
          requests.push(this.groupService.addMembersToGroup(this.condo._id, this.selectedGroup._id, usersToInsert));
        }

        if (usersToRemove.length) {
          requests.push(this.groupService.removeGroupMembers(this.condo._id, this.selectedGroup._id, usersToRemove));
        }

        try {
          await firstValueFrom(forkJoin(requests).pipe(timeout(10_000)));
        } catch (err) {
          console.log(err);

          return swal({
            type: 'error',
            title: 'Ops...',
            text: 'Ocorreu um erro ao tentar salvar as alterações. Tente novamente, por favor.'
          });
        }
      }
    })
      .then(
        () => {
          this.originalGroupMembers = this.originalGroupMembers.filter(userId => !usersToRemove.includes(userId));
          this.originalGroupMembers = [...this.originalGroupMembers, ...usersToInsert];

          this.otherUsersSearchTerm.setValue('');
          this.groupMembersSearchTerm.setValue('');

          this.toastr.success('Grupo atualizado com sucesso');
        },
        error => {
          console.log(error);
          if (error !== 'cancel') {
            swal({
              type: 'error',
              title: 'Ops',
              text: 'Não foi possível atualizar o grupo de usuários'
            });
          }
        }
      )
      .catch(e => {});
  }

  async askToDeleteGroup(group: Group) {
    try {
      await swal({
        type: 'question',
        text: `Você realmente deseja deletar o grupo ${group.name}? Escreva "deletar grupo" para confirmar a sua ação.`,
        showCancelButton: true,
        input: 'text',
        inputValue: '',
        inputPlaceholder: 'deletar grupo',
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async input => {
          input = (input || '').toString().trim().toLowerCase();
          if (!input || input !== 'deletar grupo') {
            return Promise.reject(`Para deletar você deve preencher o campo com a frase de validação`);
          } else {
            return this.groupService
              .delete(this.condo._id, group._id)
              .pipe(timeout(10000))
              .toPromise()
              .catch(err => {
                console.log(err);
                return Promise.reject('Não foi possível excluir o grupo, tente novamente...');
              });
          }
        }
      }).then(
        () => {
          this.toastr.success('Grupo removido com sucesso');
          this.getGroups();
        },
        error => {
          console.log(error);
        }
      );
    } catch (err) {
      return;
    }
  }

  askToDeleteMember(member: UserData) {
    const memberId = [];
    memberId.push(member._id);
    swal({
      type: 'question',
      text: `Deseja remover este membro?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.groupService
          .removeGroupMember(this.condo._id, this.selectedGroup._id, member._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível excluir o membro, tente novamente...');
          });
      }
    }).then(
      () => {
        this.toastr.success('Membro removido com sucesso');

        this.groupMembers = this.groupMembers.filter(groupMember => groupMember._id !== member._id);

        this.selectedGroupTotalOfMembers -= 1;
      },
      error => {
        this.toastr.error('Ocorreu um erro ao tentar remover esse membro. Tente novamente mais tarde.');
      }
    );
  }

  private buildUserWithResidences(user: User | CondoUser): UserData {
    const residencesDictionary: Record<Residence['_id'], Residence> = [...user.residencesUser, ...user.residencesVoter].reduce(
      (acc, cur) => {
        acc[cur._id] = cur;
        return acc;
      },
      {}
    );

    const residences = Object.values(residencesDictionary)
      .map(residence => residence.identification)
      .join(', ');

    return {
      _id: user._id,
      name: user.firstName + ' ' + user.lastName,
      picture: user.picture?.url,
      residences
    };
  }

  onSelectGroup(selected: Group) {
    this.selectedGroup = selected;
    this.groupMembersSearchTerm.setValue('');
    this.otherUsersSearchTerm.setValue('');
    this.getGroupMembers();
    this.permissions.canEditMembers = this.selectedGroup && (this.user.isAdmin() || this.user.isOwner());
  }

  addAllUsersToGroup() {
    this.groupMembers = [...this.filteredOtherUsers, ...this.groupMembers];
    this.filteredGroupMembers = [...this.filteredOtherUsers, ...this.filteredGroupMembers];
    this.otherUsers = this.otherUsers.filter(user => !this.filteredOtherUsers.some(filteredUser => user._id === filteredUser._id));
    this.filteredOtherUsers = [];
    this.selectedUsers = {};
    this.otherUsersSearchTerm.setValue('');
  }

  addUserToGroup() {
    const usersToAdd = Object.values(this.selectedUsers);
    this.groupMembers = [...usersToAdd, ...this.groupMembers];
    this.filteredGroupMembers = [...usersToAdd, ...this.filteredGroupMembers];
    this.otherUsers = this.otherUsers.filter(d => !this.selectedUsers[d._id]);
    this.filteredOtherUsers = this.filteredOtherUsers.filter(d => !this.selectedUsers[d._id]);
    this.selectedUsers = {};
  }

  removeUserFromGroup() {
    const usersToRemove = Object.values(this.selectedUsersFromGroup);
    this.otherUsers = [...usersToRemove, ...this.otherUsers];
    this.filteredOtherUsers = [...usersToRemove, ...this.filteredOtherUsers];
    this.groupMembers = this.groupMembers.filter(d => !this.selectedUsersFromGroup[d._id]);
    this.filteredGroupMembers = this.filteredGroupMembers.filter(d => !this.selectedUsersFromGroup[d._id]);
    this.selectedUsersFromGroup = {};
  }

  removeAllUsersFromGroup() {
    this.otherUsers = [...this.otherUsers, ...this.filteredGroupMembers];
    this.filteredOtherUsers = this.otherUsers;
    this.groupMembers = this.groupMembers.filter(user => !this.filteredGroupMembers.some(filteredUser => user._id === filteredUser._id));
    this.filteredGroupMembers = this.groupMembers;
    this.selectedUsersFromGroup = {};
    this.groupMembersSearchTerm.setValue('');
  }

  selectUser(user, source) {
    let selectedUsersSource;
    if (source === this.SOURCES.USERS) {
      selectedUsersSource = this.selectedUsers;
    } else {
      selectedUsersSource = this.selectedUsersFromGroup;
    }
    if (selectedUsersSource[user._id]) {
      delete selectedUsersSource[user._id];
    } else {
      selectedUsersSource[user._id] = user;
    }
  }

  private filterUser({ user, searchTerm }: { user: UserData; searchTerm: string }) {
    const name = `${user.name || ''}`.toLowerCase();
    return name.includes(searchTerm.toLowerCase()) || user.residences.toLowerCase().includes(searchTerm.toLowerCase());
  }
}
