import { User } from '../user';
import { Contact } from '../interface/contact';

export class UserContact extends Contact {
  private _user: User;

  constructor(userContact?) {
    super(userContact);
    if (userContact) {
      this._user = userContact.user ? (userContact.user.createBackObject ? userContact.user : new User(userContact.user)) : null;
    } else {
      this.construcEmptyObject();
    }
  }

  construcEmptyObject() {
    super.construcEmptyObject();
    this._user = null;
  }

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
  }

  createBackObject() {
    const obj = super.createBackObject();
    return obj;
  }
}
