import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildChatRoom, ChatRoom } from '@api/model/chat-room';
import { BuildChatMessage, ChatMessage } from '@api/model/chat-message';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, tap, timeout } from 'rxjs/operators';
import { ConstantService } from '../../services/constant.service';
import { HttpService } from '../../services/http.service';
import { HardwareSocketService } from 'app/services/hardware-socket.service';
import * as moment from 'moment';
import { noop } from 'rxjs';
import { UtilService } from 'app/services/util.service';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';

interface ChatSocketEventsHandlers {
  [eventName: string]: (data: any) => void;
}
interface ChatRoomSocket {
  [chatRoomId: string]: {
    chatRoom: ChatRoom;
    chatMessages: ChatMessage[];
  };
}

@Injectable({ providedIn: 'root' })
export class ChatServiceV2 {
  protected endPoint: string;

  user: User;

  chatSocketEventsHandlers: ChatSocketEventsHandlers = {};
  chats: ChatRoomSocket = {};
  selectedChatRoom: ChatRoom = null;
  limitOfChatMessagesPerPage = 10;
  hasMoreChatMessages = false;
  clearInactiveChats$;

  totalOfUserUnreadMessages = 0;

  private _isChatEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isChatEnabled$ = this._isChatEnabled.asObservable().pipe(distinctUntilChanged());

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService,
    protected hardwareSocketService: HardwareSocketService,
    private utilService: UtilService
  ) {
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;

    this.user = this.utilService.getLocalUser();

    this.hardwareSocketService.onChatData$.subscribe(({ event, data, condoId }) => {
      if (event && this.chatSocketEventsHandlers[event]) {
        this.chatSocketEventsHandlers[event](data);
      }
    });

    // TODO se tudo tiver certo com as mudanças recentes de melhorias, podemos apagar esse "limpador" de chats de vez
    // this.initializeClearInactiveChatsInterval();
  }

  clearCurrentChatSession() {
    this.chats = {};
    this.selectedChatRoom = null;
    this.hasMoreChatMessages = false;
    this.totalOfUserUnreadMessages = 0;
  }

  initialize(user: User, condo: Condo) {
    if (user) {
      const isChatAgent = user.isGatekeeperOnCondo(condo?._id) || user.isOwnerOnCondo(condo?._id) || user.isAdminOnCondo(condo?._id);
      const isGateChatEnabledOnCondo = condo?.params?.gateChat === 'ENABLED';

      this._isChatEnabled.next(isChatAgent && isGateChatEnabledOnCondo);
    }
  }

  terminate() {
    this._isChatEnabled.next(false);
  }

  initializeChatSocketEventsHandlers({ onNewMessageEvent }) {
    this.chatSocketEventsHandlers.newMessage = newMessage => {
      const messageChat = this.chats[newMessage.chatRoom._id];

      if (messageChat) {
        messageChat.chatMessages = [...(messageChat?.chatMessages ?? []), BuildChatMessage(newMessage)];
        messageChat.chatRoom.lastMessageAt = new Date().toISOString();
        messageChat.chatRoom.lastMessageText = newMessage?.messageBody || '';
        if (this.selectedChatRoom?._id === messageChat.chatRoom?._id) {
          onNewMessageEvent(newMessage);
        } else {
          messageChat.chatRoom.userUnreadMessagesCount++;
          this.totalOfUserUnreadMessages++;
        }

        this.chats[newMessage.chatRoom._id] = { ...messageChat };
      } else {
        const query = {
          $select: 'residence user identification condo userUnreadMessagesCount agentUnreadMessagesCount lastMessageText lastMessageTextAt',
          $populate: [
            { path: 'residence', select: 'identification' },
            {
              path: 'user',
              select: 'firstName lastName',
              populate: {
                path: 'picture',
                select: 'url thumbnail type name format'
              }
            }
          ]
        };

        this.getChatById(newMessage.condo._id, newMessage.chatRoom._id, query)
          .pipe(timeout(10000))
          .subscribe(chatRoom => {
            this.chats[chatRoom._id] = {
              chatRoom,
              chatMessages: [BuildChatMessage(newMessage)]
            };

            this.totalOfUserUnreadMessages++;
          });
      }
    };

    const addUserToReadByArrayIfNeeded = (socketChatMessage, currentChatMessage: ChatMessage): ChatMessage => {
      const isReadFromLoggedUser = socketChatMessage.readBy._id === this.user._id;
      const isReadFromSameUserAsMessageCreator = socketChatMessage.readBy._id === currentChatMessage.createdBy._id;

      if (!isReadFromLoggedUser && !isReadFromSameUserAsMessageCreator) {
        let messageAlreadyReadByUser = false;

        if (currentChatMessage.readBy?.length) {
          messageAlreadyReadByUser = currentChatMessage.readBy.some(user => user._id === socketChatMessage.readBy._id);
        }

        if (!messageAlreadyReadByUser) {
          currentChatMessage.readBy = [...(currentChatMessage.readBy ?? []), socketChatMessage.readBy];
        }
      }

      return currentChatMessage;
    };

    const readMessages = (chatMessage, wich: 'user' | 'agent', readAll = false) => {
      if (!this.selectedChatRoom) {
        return;
      }

      const currentChat = this.chats[chatMessage.chatRoom._id];

      if (currentChat) {
        const currentUnreadMessagesCount = currentChat.chatRoom[`${wich}UnreadMessagesCount`];

        currentChat.chatRoom[`${wich}UnreadMessagesCount`] = readAll ? 0 : currentUnreadMessagesCount - 1;

        if (wich === 'user') {
          this.totalOfUserUnreadMessages -= readAll ? currentUnreadMessagesCount : 1;
        }

        if (chatMessage._id) {
          currentChat.chatMessages = currentChat.chatMessages.map(message => {
            if (message._id === chatMessage._id) {
              return addUserToReadByArrayIfNeeded(chatMessage, message);
            }

            return message;
          });
        } else {
          currentChat.chatMessages = currentChat.chatMessages.map(message => addUserToReadByArrayIfNeeded(chatMessage, message));
        }

        this.chats[currentChat.chatRoom._id] = currentChat;
      }
    };

    this.chatSocketEventsHandlers.readUserMessage = chatMessage => {
      readMessages(chatMessage, 'user');
    };

    this.chatSocketEventsHandlers.readAllUserMessages = chatMessage => {
      readMessages(chatMessage, 'user', true);
    };

    this.chatSocketEventsHandlers.readAgentMessage = chatMessage => {
      readMessages(chatMessage, 'agent');
    };

    this.chatSocketEventsHandlers.readAllAgentMessages = chatMessage => {
      readMessages(chatMessage, 'agent', true);
    };
  }

  initializeClearInactiveChatsInterval() {
    const twoHoursInMilliseconds = 60 * 1000 * 60 * 2;

    this.clearInactiveChats$ = interval(twoHoursInMilliseconds)
      .pipe(
        tap(() => {
          const keys = Object.keys(this.chats);

          keys.forEach(key => {
            const diff = moment().diff(moment(this.chats[key].chatRoom.updatedAt), 'seconds');

            if (diff > twoHoursInMilliseconds) {
              if (this.chats[key].chatRoom._id === this.selectedChatRoom._id) {
                this.selectedChatRoom = null;
              }

              delete this.chats[key];
            }
          });
        })
      )
      .subscribe(noop);
  }

  loadChatsWithUnreadMessages(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; chats: ChatRoom[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/chats/with-unread-messages`, options).pipe(
      map((response: any) => {
        return {
          count: response.headers.get('count'),
          chats: response.body.map(chatRoom => BuildChatRoom(chatRoom))
        };
      }),
      tap(({ count, chats }) => {
        this.chats = chats.reduce(
          (accumulator, current) => {
            if (accumulator[current._id]) {
              return accumulator;
            }

            accumulator[current._id] = {
              chatRoom: BuildChatRoom(current),
              chatMessages: []
            };

            return accumulator;
          },
          { ...this.chats }
        );

        this.totalOfUserUnreadMessages = chats.reduce((accumulator, current) => {
          return accumulator + current.userUnreadMessagesCount;
        }, 0);
      })
    );
  }

  getChatById(condoId: string, chatRoomId: string, params: EcondosQuery = {}): Observable<ChatRoom> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/chats/${chatRoomId}`, options).pipe(
      map((response: any) => {
        return BuildChatRoom(response.body);
      })
    );
  }

  getOrCreateChatRoom(condoId: string, identification: string): Observable<ChatRoom> {
    const currentChat: any = Object.keys(this.chats).find(id => this.chats[id].chatRoom.identification === identification);

    if (currentChat) {
      this.selectedChatRoom = this.chats[currentChat].chatRoom;
      this.loadChatMessages(this.selectedChatRoom.condo._id, this.selectedChatRoom._id);
      return of(currentChat);
    }

    return this.http.get(`${this.endPoint}${condoId}/chats/get-or-create/${identification}`).pipe(
      map((response: any) => {
        return BuildChatRoom(response);
      }),
      tap(chatRoom => {
        this.chats[chatRoom._id] = {
          chatRoom,
          chatMessages: []
        };

        this.selectedChatRoom = this.chats[chatRoom._id].chatRoom;
        this.loadChatMessages(this.selectedChatRoom.condo._id, this.selectedChatRoom._id);
        this.readAllMessages(this.selectedChatRoom.condo._id, this.selectedChatRoom._id).subscribe(noop);
      })
    );
  }

  getChatMessages(condoId: string, chatId: string, params: EcondosQuery = {}): Observable<{ count: number; chatMessages: ChatMessage[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/chats/${chatId}/messages`, options).pipe(
      map((response: any) => {
        return {
          count: response.headers.get('count'),
          chatMessages: response.body.map(chatMessage => BuildChatMessage(chatMessage))
        };
      })
    );
  }

  loadChatMessages(condoId: string, chatRoomId: string, { paginate = false, successCallback = () => {}, errorCallback = () => {} } = {}) {
    const chat = this.chats[chatRoomId];

    const query: EcondosQuery = {
      $select: 'condo chatRoom createdBy messageBody replyTo readBy createdAt',
      $populate: [
        {
          path: 'createdBy',
          select: 'firstName lastName condos condosAdmin condosOwner condosJanitor condosGatekeeper',
          populate: {
            path: 'picture',
            select: 'url thumbnail type name format'
          }
        },
        {
          path: 'replyTo',
          select: 'createdBy messageBody createdAt',
          populate: {
            path: 'createdBy',
            select: 'firstName lastName',
            populate: {
              path: 'picture',
              select: 'url thumbnail type name format'
            }
          }
        }
      ],
      condo: condoId,
      chatRoom: chatRoomId,
      $sort: '-createdAt',
      $limit: this.limitOfChatMessagesPerPage
    };

    if (paginate) {
      const oldestMessage = chat.chatMessages[0];
      const limitDate = oldestMessage.createdAt;

      query.createdAt = { $lt: limitDate };
    }

    this.getChatMessages(condoId, chatRoomId, query)
      .pipe(
        timeout(10_000),
        tap(({ count, chatMessages }) => {
          const parsedChatMessages = chatMessages.map(message => {
            return {
              ...message,
              readBy: message.readBy.filter(readBy => readBy._id !== this.user._id)
            };
          });

          if (paginate) {
            this.chats[chatRoomId] = { ...chat, chatMessages: [...parsedChatMessages.reverse(), ...chat.chatMessages] };
          } else {
            this.chats[chatRoomId] = { ...chat, chatMessages: parsedChatMessages.reverse() };
          }

          this.hasMoreChatMessages = chatMessages.length < count;
        })
      )
      .subscribe(successCallback, errorCallback);
  }

  createChatMessage(condoId: string, requestForm: any, chatRoomId?: string) {
    return this.http.post(`${this.endPoint}${condoId}/chats/${chatRoomId || this.selectedChatRoom._id}/new-message`, requestForm).pipe(
      tap(() => {
        if (this.selectedChatRoom) {
          this.selectedChatRoom = BuildChatRoom({
            ...this.selectedChatRoom,
            lastMessageAt: new Date().toISOString(),
            lastMessageText: requestForm.messageBody
          });
          const currentChat = this.chats[this.selectedChatRoom._id];
          this.chats[this.selectedChatRoom._id] = { ...currentChat, chatRoom: this.selectedChatRoom };
        }
      })
    );
  }

  readMessage(condoId: string, chatId: string, chatMessageId: string): Observable<{}> {
    return this.http.put(`${this.endPoint}${condoId}/chats/${chatId}/read-message/${chatMessageId}`, {});
  }

  readAllMessages(condoId: string, chatId: string): Observable<{}> {
    return this.http.put(`${this.endPoint}${condoId}/chats/${chatId}/read-all-messages`, {});
  }

  getAllMessages(condoId: string, residenceId: string, params: EcondosQuery = {}): Observable<ChatMessage[]> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/chats/${residenceId}/residence-messages`, options).pipe(
      map((response: any) => {
        return response.body.map(chatMessage => BuildChatMessage(chatMessage));
      })
    );
  }
}
