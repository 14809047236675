/**
 * Created by Rafael on 01/12/2016.
 */
import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ResidenceService } from '@api/service/residence.service';
import { UtilService } from '../../../../services/util.service';
import { Residence } from '@api/model/interface/residence';
import { HouseResidence } from '@api/model/residence/residence.house';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';

@Component({
  selector: 'modal-house-picker',
  templateUrl: 'house.picker.modal.html'
})
export class ModalHousePickerComponent {
  @ViewChild('housePickerModal', { static: true }) public housePickerModal: ModalDirective;

  @Output() onResidencesSelect = new EventEmitter();

  @Input() selectedResidences: Array<HouseResidence> = new Array<HouseResidence>();

  @Input() disabledResidences: Array<HouseResidence> = new Array<HouseResidence>();

  @Input() singleSelect = false;

  @Input() shouldReload = false;

  public pickedResidences: Array<HouseResidence> = new Array<HouseResidence>();

  public condo;
  public user;

  public selectedStreet;

  public housesByStreet;
  public housesByStreetKeys;
  public filteredHousesByStreetKeys;

  public numberOfResidences;

  public loadingStatus;
  public LOADING = 'LOADING';
  public SUCCESS = 'SUCCESS';
  public ERROR = 'ERROR';

  public searchTerm;

  constructor(private residenceService: ResidenceService, private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();

    this.searchTerm = new UntypedFormControl();

    this.searchTerm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      let val = term.trim();
      val = this.utilService.removeAccents(val);
      val = val.toLowerCase();
      const temp = this.housesByStreetKeys.filter(street => {
        return !val || street.indexOf(val) !== -1;
      });
      this.filteredHousesByStreetKeys = temp;
    });

    this.initializeModal();
  }

  getStreetKey(res: Residence) {
    return this.utilService.removeAccents((<HouseResidence>res).address.toUpperCase());
  }

  getQuery(): EcondosQuery {
    const query: EcondosQuery = {
      $sort: 'block',
      type: Residence.TYPES.HOUSE,
      $select: 'identification block number address zipCode lot type'
    };
    return query;
  }

  loadResidences() {
    this.loadingStatus = this.LOADING;

    this.residenceService
      .getResidences(this.condo._id, this.getQuery())
      .pipe(
        timeout(10000),
        map(({ residences }) => residences)
      )
      .subscribe(
        houses => {
          houses.sort(function (res1: any, res2: any) {
            return res1.number - res2.number;
          });

          houses.forEach(res => {
            const street = this.getStreetKey(res);
            if (this.housesByStreet.indexOf(street) == -1) {
              this.housesByStreet[street] = { street: (<HouseResidence>res).address, houses: [] };
            }
          });

          houses.forEach(res => {
            const street = this.getStreetKey(res);
            this.housesByStreet[street].houses.push(res);
          });

          this.housesByStreetKeys = Object.keys(this.housesByStreet);
          this.housesByStreetKeys.sort();
          this.filteredHousesByStreetKeys = this.housesByStreetKeys;

          this.loadingStatus = this.SUCCESS;
          this.shouldReload = false;
          this.numberOfResidences = houses.length || 0;
        },
        err => {
          this.loadingStatus = this.ERROR;
        }
      );
  }

  checkResidence(residence) {
    if (this.isDisabledResidence(residence)) {
      return;
    }
    const index = this.pickedResidences.findIndex(res => {
      return res.equals(residence);
    });
    if (index > -1) {
      this.pickedResidences.splice(index, 1);
    } else {
      if (this.singleSelect) {
        this.pickedResidences.splice(0, this.pickedResidences.length);
      }
      this.pickedResidences.push(residence);
    }
  }

  closeModal(): void {
    this.housePickerModal.hide();
  }

  showModal(modalConfig?) {
    this.initializeModal();
    if (
      this.shouldReload ||
      this.loadingStatus != this.SUCCESS ||
      (this.loadingStatus == this.SUCCESS && !this.housesByStreetKeys.length)
    ) {
      this.loadResidences();
    }
    this.housePickerModal.show();
  }

  initializeModal() {
    if (this.loadingStatus != this.SUCCESS) {
      this.housesByStreet = [];
      this.housesByStreetKeys = [];
      this.filteredHousesByStreetKeys = [];
      this.pickedResidences = [];
    }
    this.selectedStreet = '';
    this.pickedResidences = this.selectedResidences.map(res => {
      return new HouseResidence(res);
    });
  }

  isSelectedResidence(res) {
    return this.pickedResidences.some(residence => {
      return res.equals(residence);
    });
  }

  isDisabledResidence(res) {
    return this.disabledResidences.some(residence => {
      return res.equals(residence);
    });
  }

  selectBlock(block) {
    this.selectedStreet = block;
  }

  clearPickedResidences() {
    this.pickedResidences.splice(0, this.pickedResidences.length);
  }

  confirmSelection() {
    this.closeModal();
    this.onResidencesSelect.emit(this.pickedResidences);
  }
}
