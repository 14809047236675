import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { Residence } from '@api/model/interface/residence';
import { CondoService } from '@api/service/condo.service';
import { ResidenceService } from '@api/service/residence.service';

interface ResidenceAndSearchText {
  searchText: string;
  residence: Residence;
}

@Component({
  selector: 'app-residence-auto-complete',
  templateUrl: 'residence-auto-complete.component.html',
  styleUrls: ['residence-auto-complete.component.scss']
})
export class ResidenceAutoCompleteComponent implements OnInit {
  @Output() selectItem: EventEmitter<Residence | ResidenceAndSearchText> = new EventEmitter<Residence | ResidenceAndSearchText>();
  @Output() inputText: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder = 'Buscar unidade';
  @Input() condo: Condo;
  @Input() typeaheadHideResultsOnBlur = false;
  @Input() adaptivePosition = false;
  @Input() showAsInputGroup = false;
  @Input() disabled = false;
  @Input() alsoReturnSearchText = false;
  @Input() clearInputTextAfterSelect = true;
  @Input() dontShowEmptyResultAndReturnSearchText = false;

  status: Status = new Status();

  typeAheadDataSource$: Observable<any>;
  @Input() selectedLabel = '';
  selectedObject;
  residences: Residence[] = [];
  searchText = '';

  noResult = false;

  onTypeAheadSelect = evt => {
    this.selectedObject = evt.item;

    if (this.alsoReturnSearchText) {
      this.selectItem.emit({
        searchText: this.searchText,
        residence: evt.item
      });
    } else {
      this.selectItem.emit(evt.item);
    }

    this.selectedLabel = this.getResidenceIdentificationLabel(evt.item);

    if (this.clearInputTextAfterSelect) {
      this.resetValue();
    }
  };

  constructor(private condoService: CondoService, private residenceService: ResidenceService) {}

  ngOnInit() {
    this.placeholder = `Buscar ${this.condo?.customLabels?.residence?.singular || 'unidade'}`;
    this.typeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.selectedLabel);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      mergeMap((token: string) => {
        this.searchText = token;

        return this.residenceService.searchByToken(this.condo._id, token, { $limit: 50 }).pipe(
          map(res => res.residences),
          map(residences => {
            const collator = new Intl.Collator('pt-br', {
              numeric: true,
              sensitivity: 'base',
              caseFirst: 'false',
              usage: 'sort'
            });
            return residences.sort((a, b) => collator.compare(a.identification, b.identification));
          }),
          catchError(e => {
            this.status.setAsError();
            return of([]);
          })
        );
      })
    );
  }

  getResidenceIdentificationLabel(residence: Residence) {
    if (residence) {
      return residence.identification;
    } else {
      return '';
    }
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
    this.inputText.emit(this.searchText);
  }

  changeTypeaheadLoading(isLoading: boolean): void {
    if (isLoading) {
      this.status.setAsDownloading();
    } else {
      if (!this.status.isError()) {
        this.status.setAsSuccess();
      }
    }
  }

  resetValue() {
    this.selectedLabel = '';
    this.selectedObject = null;
    this.noResult = false;
    this.status = new Status();
    this.selectItem.emit(null);
  }

  typeaheadOnBlur(event: any): void {
    if (!this.typeaheadHideResultsOnBlur) {
      this.onTypeAheadSelect(event);
    }
  }

  onInputBackspace(event) {
    const targetValue = event.target.value;

    if (!targetValue) {
      this.resetValue();
    }
  }
}
