import { Condo } from './condo';
import { User } from './user';

export interface SipAccount {
  _id: string;
  condo: Condo;
  name: string;
  host: string;
  port: string;
  prefix?: string;
  createdBy?: User;
  createdAt?: string;
  updatedAt?: string;
}

export const BuildSipAccount = (sipAccount: Partial<SipAccount>): SipAccount => {
  return {
    _id: typeof sipAccount === 'string' ? sipAccount : sipAccount._id,
    condo: sipAccount.condo ? new Condo(sipAccount.condo) : null,
    name: sipAccount.name || '',
    host: sipAccount.host || '',
    port: sipAccount.port || '5060',
    prefix: sipAccount.prefix || '',
    createdBy: sipAccount.createdBy ? new User(sipAccount.createdBy) : null,
    createdAt: sipAccount.createdAt || null,
    updatedAt: sipAccount.updatedAt || null
  };
};
