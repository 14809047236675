import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalEditCommentComponent } from './modal-edit-comment.component';
import { ComponentsModule } from '../../../components/components.module';
import { ThemeModule } from '../../../theme';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AutosizeModule } from 'ngx-autosize';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    TypeaheadModule,
    ComponentsModule,
    TooltipModule,
    ThemeModule,
    AngularSvgIconModule,
    AutosizeModule
  ],
  declarations: [ModalEditCommentComponent],
  providers: []
})
export class ModalEditCommentModule {}
