import { Component, OnInit, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';
import { User } from 'app/api/model/user';
import { TableColumnDefinition, TableComponent, TableStatus } from 'app/components/table/table.component';
import { UtilService } from 'app/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { CameraService } from '@api/service/camera.service';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { Observable, Subscription, lastValueFrom, map, timeout } from 'rxjs';
import { DEVICE_TYPES_LABEL } from '@api/model/hardware/hardware-constants';
import { NoteService } from '@api/service/note.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { capitalize } from '@api/util/util';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'app-deleted-list',
  templateUrl: 'deleted-list.component.html',
  styleUrls: ['deleted-list.component.scss']
})
export class DeletedListComponent implements OnInit {
  @ViewChild('table', { static: true }) table: TableComponent;
  registerForm: FormGroup;

  tableColumnDefinition: TableColumnDefinition[];
  defaultTableColumnDefinition: TableColumnDefinition[] = [
    {
      headerLabel: '#',
      type: 'index'
    },
    {
      columnId: 'name',
      headerLabel: 'Nome',
      valueFn: deletedItem => deletedItem.name || 'Não informado'
    },
    { columnId: 'deletedBy', headerLabel: 'Deletado por', valueFn: deletedItem => deletedItem.deletedBy?.firstName },
    {
      columnId: 'deletedAt',
      headerLabel: 'Deletado em',
      valueFn: deletedItem => (deletedItem.deletedAt ? moment(deletedItem.deletedAt).format('DD/MM/YYYY - HH:mm') : '-'),
      sortable: true
    },
    {
      type: 'actions',
      headerLabel: 'Ações',
      actionsButtons: [
        {
          icon: 'fa-undo',
          title: 'Restaurar',
          handler: deletedItem => this.handleRestore(deletedItem)
        }
      ]
    }
  ];

  isAppTableVisible: boolean = false;
  selectedStartDate: FormControl;
  selectedEndDate: FormControl;
  getData: Observable<{ count: number; data: any[] }>;
  restoreService;
  DEVICE_TYPES_LABEL = DEVICE_TYPES_LABEL;

  condo: Condo;
  user: User;
  deleted = [];

  totalDeletedCount = 0;
  isFiltered = false;
  isAdmin = false;

  initialQuery: EcondosQuery = {
    $populate: [
      {
        path: 'deletedBy',
        select: 'firstName'
      }
    ],
    $select: ' name deletedAt deletedBy text',
    $and: [],
    $or: [],
    $sort: '-deletedAt'
  };

  subscriptions: Subscription = new Subscription();
  searchInput = new UntypedFormControl();
  selectedOption;

  status: TableStatus = 'LOADING';

  constructor(
    private sessionService: SessionService,
    private actuatorService: ActuatorService,
    private cameraService: CameraService,
    private deviceService: HardwareDeviceService,
    private noteService: NoteService,
    private toastr: ToastrService
  ) {
    this.registerForm = new FormGroup({
      selectedOption: new FormControl('default'),
      selectedStartDate: new FormControl(null),
      selectedEndDate: new FormControl(null)
    });
    this.user = this.sessionService.userValue;
    this.isAdmin = this.user.isAdmin() || this.user.isOwner();
    this.condo = this.sessionService.condoValue;
    this.selectedOption = this.registerForm.get('selectedOption')?.value;
  }

  ngOnInit() {
    this.tableColumnDefinition = [...this.defaultTableColumnDefinition];

    this.handleUserSelect();
  }

  updateTableColumnDefinition(selectedOption: string) {
    this.tableColumnDefinition = this.defaultTableColumnDefinition.map(column => ({ ...column }));

    if (selectedOption === 'cameras') {
      this.tableColumnDefinition = [...this.defaultTableColumnDefinition];
    }

    if (selectedOption === 'actuators') {
      this.tableColumnDefinition = [...this.defaultTableColumnDefinition];
    }

    if (selectedOption === 'devices') {
      const textColumn = {
        columnId: 'type',
        headerLabel: 'Tipo',
        valueFn: deletedItem => DEVICE_TYPES_LABEL[deletedItem.type] || 'Não informado',
        sortable: true
      };
      const nameColumnIndex = this.tableColumnDefinition.findIndex(column => column.columnId === 'name');

      if (nameColumnIndex !== -1) {
        this.tableColumnDefinition.splice(nameColumnIndex, 1, textColumn);
      }

      this.tableColumnDefinition.splice(
        2,
        0,

        {
          columnId: 'serial',
          headerLabel: 'Serial',
          valueFn: deletedItem => deletedItem.serial || 'Não informado'
        },
        {
          columnId: 'owner.residence',
          headerLabel: 'Unidade',
          valueFn: deletedItem => {
            let name: string;

            if (deletedItem.owner?.user?.firstName) {
              name = deletedItem.owner.user.firstName + ' ' + (deletedItem.owner.user?.lastName || '');
            } else if (deletedItem.owner?.dependent?.name) {
              name = deletedItem.owner.dependent.name;
            } else if (deletedItem.owner?.userName) {
              name = deletedItem.owner.userName;
            }

            const residenceIdentification =
              deletedItem.owner?.residence?.identification || `${capitalize(this.condo.customLabels.residence.singular)} não informado(a)`;

            const userName = name || `${capitalize(this.condo.customLabels.resident.singular)} não informado(a)`;

            return `${residenceIdentification} - ${userName}`;
          }
        },
        {
          columnId: 'createdBy',
          headerLabel: 'Criado por',
          valueFn: deletedItem => deletedItem.createdBy?.firstName || 'Não informado',
          sortable: true
        },
        {
          columnId: 'createdAt',
          headerLabel: 'Criado em',
          valueFn: deletedItem => moment(deletedItem.createdAt).format('DD/MM/YYYY - HH:mm') || '-',
          sortable: true
        }
      );
    }
    if (selectedOption === 'notes') {
      const textColumn = {
        columnId: 'text',
        headerLabel: 'Recado',
        valueFn: deletedItem => deletedItem.text || 'Não informado'
      };
      const nameColumnIndex = this.tableColumnDefinition.findIndex(column => column.columnId === 'name');

      if (nameColumnIndex !== -1) {
        this.tableColumnDefinition.splice(nameColumnIndex, 1, textColumn);
      }

      this.tableColumnDefinition.splice(
        2,
        0,
        {
          columnId: 'residence',
          headerLabel: 'Unidade',
          valueFn: deletedItem => deletedItem.residence?.identification || 'Não informado',
          sortable: true
        },
        {
          columnId: 'createdBy',
          headerLabel: 'Criado por',
          valueFn: deletedItem => deletedItem.createdBy?.firstName || 'Não informado',
          sortable: true
        },
        {
          columnId: 'createdAt',
          headerLabel: 'Criado em',
          valueFn: deletedItem => moment(deletedItem.createdAt).format('DD/MM/YYYY - HH:mm') || '-',
          sortable: true
        }
      );
    }
  }

  handleUserSelect({ page = 0 } = {}) {
    const selectedOption = this.registerForm.get('selectedOption')?.value;
    const { pageSize } = this.table.getCurrentState();
    this.initialQuery.$limit = pageSize;
    this.initialQuery.$page = page;
    this.initialQuery.$and = [];

    this.updateTableState();

    const selectedStartDate = this.registerForm.get('selectedStartDate').value;
    const selectedEndDate = this.registerForm.get('selectedEndDate').value;

    if (this.initialQuery.$and.length === 0) {
      this.initialQuery.$and.push({ deletedAt: {} });
    }

    if (selectedStartDate && selectedEndDate) {
      const startOfDay = moment(selectedStartDate).startOf('day');
      const endOfDay = moment(selectedEndDate).endOf('day');
      this.initialQuery.$and.push({
        deletedAt: { $gte: startOfDay.toDate(), $lte: endOfDay.toDate() }
      });
    } else if (selectedStartDate) {
      const startOfDay = moment(selectedStartDate).startOf('day');
      this.initialQuery.$and.push({ deletedAt: { $gte: startOfDay.toDate() } });
    } else if (selectedEndDate) {
      const endOfDay = moment(selectedEndDate).endOf('day');
      this.initialQuery.$and.push({ deletedAt: { $lte: endOfDay.toDate() } });
    }

    let deviceQuery;
    let customQuery;
    let noteQuery;

    switch (selectedOption) {
      case 'cameras':
        customQuery = { ...this.initialQuery };
        if (this.initialQuery.$and.length === 0) {
          this.initialQuery.$and.push({ deletedAt: {} });
        }
        this.restoreService = this.cameraService;
        this.getData = this.cameraService
          .getDeleted(this.condo._id, customQuery)
          .pipe(map(({ count, cameras }) => ({ count, data: cameras })));
        break;
      case 'actuators':
        customQuery = { ...this.initialQuery };
        if (this.initialQuery.$and.length === 0) {
          this.initialQuery.$and.push({ deletedAt: {} });
        }
        this.restoreService = this.actuatorService;
        this.getData = this.actuatorService
          .getDeleted(this.condo._id, customQuery)
          .pipe(map(({ count, actuators }) => ({ count, data: actuators })));
        break;
      case 'devices':
        deviceQuery = { ...this.initialQuery };
        deviceQuery.$populate = [
          {
            path: 'owner.residence',
            select: 'identification'
          },
          {
            path: 'owner.user',
            select: 'firstName lastName'
          },
          {
            path: 'owner.dependent',
            select: 'name'
          },
          {
            path: 'deletedBy',
            select: 'firstName'
          },
          {
            path: 'createdBy',
            select: 'firstName'
          }
        ];
        deviceQuery.$select = 'deletedAt deletedBy createdBy createdAt serial type accessType owner.userName';
        deviceQuery.accessType = 'RESIDENT';
        this.restoreService = this.deviceService;
        this.getData = this.deviceService
          .getDeleted(this.condo._id, deviceQuery)
          .pipe(map(({ count, devices }) => ({ count, data: devices })));
        break;
      case 'notes':
        noteQuery = { ...this.initialQuery };
        noteQuery.$populate = [
          {
            path: 'deletedBy',
            select: 'firstName'
          },
          {
            path: 'residence',
            select: 'identification'
          },
          {
            path: 'createdBy',
            select: 'firstName'
          }
        ];
        noteQuery.$select = 'deletedAt deletedBy text residence createdBy createdAt';
        this.restoreService = this.noteService;
        this.getData = this.noteService.getDeleted(this.condo._id, noteQuery).pipe(map(({ count, notes }) => ({ count, data: notes })));
        break;
      default:
        break;
    }

    this.updateTableColumnDefinition(selectedOption);
    this.getAllDeleted();
    this.isAppTableVisible = selectedOption !== 'default';
  }

  updateTableState() {
    if (this.registerForm.get('selectedOption')?.value !== 'default') {
      const { sortedColumn, sortOrder } = this.table.getCurrentState();
      if (typeof sortedColumn === 'string') {
        this.initialQuery.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
      }
    }
  }

  getAllDeleted() {
    if (this.registerForm.get('selectedOption').value !== 'default') {
      this.status = 'LOADING';
      this.getData.subscribe({
        next: response => {
          this.totalDeletedCount = response.count;
          this.deleted = response.data;
          this.status = 'SUCCESS';
        },
        error: () => {
          this.status = 'ERROR';
          this.toastr.error('Não foi possível consultar os dados. Tente novamente');
        }
      });
    }
  }

  handleRestore(restoreDeletedItem) {
    if (this.status === 'LOADING') {
      return;
    }

    let restoreItemText = '';
    let restoreItem;

    switch (this.registerForm.get('selectedOption')?.value) {
      case 'cameras':
        restoreItem = this.cameraService.restore(this.condo._id, restoreDeletedItem._id).pipe(timeout(10000));
        restoreItemText = restoreDeletedItem.name || 'a câmera';
        break;
      case 'actuators':
        restoreItem = this.actuatorService.restore(this.condo._id, restoreDeletedItem._id).pipe(timeout(10000));
        restoreItemText = restoreDeletedItem.name || 'o acionador';
        break;
      case 'devices':
        restoreItem = this.deviceService.restore(this.condo._id, restoreDeletedItem._id).pipe(timeout(10000));
        restoreItemText = `${DEVICE_TYPES_LABEL[restoreDeletedItem.type]} ` + restoreDeletedItem.serial || 'o dispositivo';
        break;
      case 'notes':
        restoreItem = this.noteService.restore(this.condo._id, restoreDeletedItem._id).pipe(timeout(10000));
        restoreItemText = restoreDeletedItem.text || 'o recado';
        break;
      default:
        break;
    }

    swal({
      type: 'question',
      text: `Deseja restaurar ${restoreItemText} ?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return lastValueFrom(restoreItem)
          .then(() => {
            if (this.registerForm.get('selectedOption')?.value === 'devices') {
              setTimeout(() => {
                swal({
                  type: 'warning',
                  text: 'Cadastro restaurado. Verifique a necessidade de sincronização no sistema.'
                });
              }, 500);
            }
            this.toastr.success('Restaurado com sucesso');
            this.deleted = this.deleted.filter(item => item._id !== restoreDeletedItem._id);
            this.totalDeletedCount--;
          })
          .catch(err => {
            console.log(err);
            throw new Error('Não foi possível restaurar, tente novamente...');
          });
      }
    });
  }

  handleRefreshData() {
    const { currentPage } = this.table.getCurrentState();
    this.handleUserSelect({ page: currentPage - 1 });
  }

  clearFilters() {
    const oldStartDate = this.registerForm.get('selectedStartDate').value;
    const oldEndDate = this.registerForm.get('selectedEndDate').value;

    this.registerForm.get('selectedStartDate').setValue(null);
    this.registerForm.get('selectedEndDate').setValue(null);

    const newStartDate = this.registerForm.get('selectedStartDate').value;
    const newEndDate = this.registerForm.get('selectedEndDate').value;

    if (oldStartDate !== newStartDate || oldEndDate !== newEndDate) {
      this.handleUserSelect();
    }
  }
}
