import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { LostAndFound, lostAndFoundToBackObject } from '../model/lost-and-found';
import { File } from '../model/file';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';

@Injectable()
export class LostAndFoundService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /** @Deprecated Use getLostAndFounds instead */
  getLostAndFound(
    condoId: string,
    params: Array<{ [key: string]: string }> = []
  ): Observable<{ count: number; lostAndFounds: LostAndFound[] }> {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/lost-and-founds`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            lostAndFounds: res.body.map(v => ({ ...v, picture: new File(v.picture) }))
          };
        })
      );
  }

  getLostAndFounds(condoId: string, query: EcondosQuery): Observable<{ count: number; lostAndFounds: LostAndFound[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/lost-and-founds`, { params: httpParams }).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count') || null,
          lostAndFounds: res.body.map(lostAndFound => ({
            ...lostAndFound,
            pictures: (lostAndFound.pictures || []).map(picture => new File(picture))
          }))
        };
      })
    );
  }

  createLostAndFound(condoId: string, lostAndFound: LostAndFound) {
    return this.http
      .post(`${this.endPoint}${condoId}/lost-and-founds`, lostAndFoundToBackObject(lostAndFound))
      .pipe(map((res: { _id: string }) => ({ ...lostAndFound, _id: res._id })));
  }

  updateLostAndFound(condoId: string, lostAndFoundId: string, lostAndFound: LostAndFound) {
    return this.http.put(`${this.endPoint}${condoId}/lost-and-founds/${lostAndFoundId}`, lostAndFoundToBackObject(lostAndFound));
  }

  deleteLostAndFound(condoId: string, lostAndFoundId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/lost-and-founds/${lostAndFoundId}`);
  }

  withDrawLostAndFound(condoId: string, lostAndFoundId: string, data: { withdrawBy: string }) {
    return this.http.put(`${this.endPoint}${condoId}/lost-and-founds/${lostAndFoundId}/withdraw`, data);
  }
}
