import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
// Model
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { ErrorBuilder } from '../model/error/error.builder';
import { Coupon } from '../model/coupon';
import { map } from 'rxjs/operators';

@Injectable()
export class CouponService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}users/`;
  }

  getUserCoupons(userId: string, queryString?: string): Observable<Coupon[]> {
    return this.http
      .get(this.endPoint + userId + '/coupons' + (queryString || ''))
      .pipe(map((coupons: any) => coupons.map(c => new Coupon(c))));
  }

  createCoupon(userId: string, coupon: any) {
    return this.http.post(this.endPoint + userId + '/coupons', coupon);
  }

  enableCoupon(userId: string, couponId: string) {
    return this.http.put(`${this.endPoint}${userId}/coupons/${couponId}/enable`, {});
  }

  disableCoupon(userId: string, couponId: string) {
    return this.http.put(`${this.endPoint}${userId}/coupons/${couponId}/disable`, {});
  }

  deleteCoupon(userId: string, couponId: string) {
    return this.http.delete(this.endPoint + userId + '/coupons/' + couponId);
  }

  updateCoupon(userId: string, couponId: string, coupon: any) {
    return this.http.put(this.endPoint + userId + '/coupons/' + couponId, coupon);
  }

  getCouponByCode(condoId: string, couponCode: string, queryString?: string): Observable<Coupon> {
    return this.http
      .get(`${this.constantService.getEndpoint()}condos/${condoId}/coupon/${couponCode}${queryString || ''}`)
      .pipe(map(c => new Coupon(c)));
  }

  getCouponById(condoId: string, couponId: string, queryString?: string): Observable<Coupon> {
    return this.http
      .get(`${this.constantService.getEndpoint()}condos/${condoId}/coupon/${couponId}/${queryString || ''}`)
      .pipe(map(c => new Coupon(c)));
  }

  handleError(error) {
    return throwError(ErrorBuilder.build(error));
  }
}
