import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs/operators';
import { serialToWiegand } from '../../api/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-usb-device-reader',
  templateUrl: 'usb-device-reader.component.html',
  styleUrls: ['./usb-device-reader.component.scss']
})
export class UsbDeviceReaderComponent {
  // TODO typar o event emitter
  @Output()
  read: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;

  type: AbstractControl;
  serial: AbstractControl;
  counter: AbstractControl;

  private subscriptions: Subscription = new Subscription();

  constructor(public bsModalRef: BsModalRef, private fb: UntypedFormBuilder, private toastrService: ToastrService) {
    this.form = this.fb.group({
      type: ['', Validators.required],
      serial: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(10)])],
      counter: ['', Validators.required]
    });

    this.counter = this.form.get('counter');
    this.serial = this.form.get('serial');
    this.type = this.form.get('type');

    this.subscriptions.add(
      this.serial.valueChanges
        .pipe(
          filter(v => v),
          map(v => v.replace(/[^abcdefABCDEF\d]/g, '').toUpperCase())
        )
        .subscribe(v => {
          this.serial.setValue(v, { emitEvent: false });
        })
    );

    this.subscriptions.add(
      this.counter.valueChanges.subscribe(val => {
        // Replaces all non hexadecimal characters
        this.counter.setValue((val || '').replace(/[^abcdefABCDEF\d]/g, ''), { emitEvent: false });
      })
    );
  }

  readDevice() {
    if (this.form.valid) {
      const values = this.form.value;
      const device: any = values;
      if (device.type === 3) {
        device.serial = device.serial.substring(device.serial.length - 6);
      }
      device.wiegand = serialToWiegand(device.serial);
      this.read.emit(device);
      this.form.reset();
    } else {
      this.toastrService.error('Todos os três campos da leitura devem estar preenchidos');
    }
  }
}
