import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DependentService } from '@api/service/dependent.service';
import { forkJoin, Subject, switchMap } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import swal from 'sweetalert2';
import { debounceTime, takeUntil, timeout, timeoutWith } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../../services/util.service';
import { EcondosQuery } from '@api/model/query';
import { capitalize, removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { Error } from '@api/model/error/error';
import { ModalResidentPickerComponent } from '../../pages/modal/modal-resident-picker/modal-resident-picker.component';
import { ResidenceServiceV2 } from '@api/serviceV2/residence.service';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';
import { CondoService } from '@api/service/condo.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-resident-list',
  templateUrl: 'resident-list.html',
  styleUrls: ['./resident-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResidentListComponent implements OnChanges, OnDestroy, OnInit {
  @Input()
  residence: Residence;
  @Input()
  residents: User[] = [];
  @Input()
  condo: Condo;
  @Input()
  user: User;
  @Input()
  showIds = false;
  @Input()
  showPhones = false;
  @Input()
  showTooltipUserData: true;
  @Input()
  linkToResident = false;

  @Output()
  residentUpdated: EventEmitter<User[]> = new EventEmitter();
  @Output()
  residentAsVoter: EventEmitter<User> = new EventEmitter();

  @ViewChild('residentNameCellTemplate', { static: true }) residentNameCellTemplate: TemplateRef<any>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<any>;
  @ViewChild('residentsTable', { static: true }) residentsTable: TableComponent;
  public unsubscribe$ = new Subject();
  today = new Date();
  userData: User;
  public searchToken = new FormControl('');
  hasWriteAccess = false;
  page = 0;
  countData: number = 0;
  pageSize: number;

  isBusinessCondo = false;
  isAdmin: boolean = false;
  userPickerMultipleSelection = false;
  userIdsToHide: Array<string> = [];
  userSelectCallback;
  tableColumns: TableColumnDefinition<User>[] = [];
  status: TableStatus = 'LOADING';

  documents: { [key: string]: { rg: string; cpf: string } } = {};
  usersResidenceUserLabel = {};
  constructor(
    private residenceService: ResidenceServiceV2,
    private toastr: ToastrService,
    public utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private dependentService: DependentService,
    private modalService: BsModalService,
    private condoService: CondoService,
    private router: Router
  ) {
    this.searchToken.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(token => {
      this.getData({ token });
    });
  }

  ngOnInit(): void {
    this.tableColumns = [
      {
        columnId: 'name',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.residentNameCellTemplate,
        sortable: false
      }
    ];
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getData({ page = 0, token = this.searchToken.value } = {}) {
    this.status = 'LOADING';
    this.cdr.detectChanges();
    const query: EcondosQuery = {
      $select: 'picture firstName lastName residencesUser condoResidenceAttributes',
      $populate: [{ path: 'picture', select: 'url thumbnail' }],
      $sort: 'firstName',
      residencesRequester: { $ne: this.residence._id },
      residencesUser: { $in: [this.residence._id] }
    };
    if (this.showIds) {
      query.$select = query.$select + ' ids';
      query.$populate.push({ path: 'ids', select: 'type number' });
    }
    if (this.showPhones) {
      query.$select = query.$select + ' phones';
    }
    if (token) {
      const terms = token
        .split(' ')
        .map(word => removeAccents(word))
        .map(word => replaceVowelsToAccentedVowels(word));

      const nameAnd = [];
      terms.forEach(term => {
        nameAnd.push({
          $or: [{ firstName: { $regex: term, $options: 'i' } }, { lastName: { $regex: term, $options: 'i' } }]
        });
      });
      query.$and = nameAnd;
    }

    const { pageSize } = this.residentsTable.getCurrentState();
    this.pageSize = pageSize;
    query.$page = page;
    query.$limit = pageSize;
    this.residenceService
      .getUsersFromResidence(this.condo._id, this.residence.id, { $count: true })
      .pipe(
        switchMap(res => {
          this.countData = res.count;
          return this.residenceService.getUsersFromResidence(this.condo._id, this.residence.id, query);
        })
      )
      .subscribe({
        next: response => {
          response.users.map(user => {
            user.isDataMasked = {
              ids: true,
              phones: true
            };
            let cpf,
              rg = '';
            user.ids.map(id => {
              if (id.type === 'CPF') cpf = id.number;
              if (id.type === 'RG') rg = id.number;
            });
            this.documents[user._id] = { rg, cpf };
          });
          this.status = 'SUCCESS';
          this.residents = response.users;
          this.residentUpdated.emit(this.residents);
          this.fillResidenceUserLabels(this.residents);
        },
        error: () => {
          this.status = 'ERROR';
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.residents.length == 0 || changes.residence || changes.condo) {
      this.getData();
    }
    if (changes.condo && changes.residence && changes.user) {
      const user: User = changes.user.currentValue;
      const condo = changes.condo.currentValue;
      const residence: Residence = changes.residence.currentValue;
      this.isBusinessCondo = condo?.isBusinessCondo();
      this.hasWriteAccess =
        user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id) || residence.isOwner(user._id) || residence.isUser(user._id);
      this.isAdmin = user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id);
    }
  }
  onShownTooltip(user: User) {
    if (this.user.isOwnerOnCondo(this.condo._id) || this.user.isAdminOnCondo(this.condo._id)) {
      this.userData = undefined;

      this.condoService
        .getCondoUserById(this.condo.id, user._id)
        .pipe(timeout(10000))
        .subscribe(
          userData => {
            this.userData = userData;
            this.cdr.detectChanges();
          },
          () => {
            this.userData = user;
            this.cdr.detectChanges();
          }
        );
    }
  }
  goToResident(resident: User) {
    this.router.navigate(['condo/residents/' + resident.id]);
  }
  revealData(field: string, user) {
    user.isDataMasked[field] = false;

    if (!user[field].toString().includes('*')) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    const callback = ({ data }) => {
      user[field] = data;
      this.cdr.detectChanges();
    };

    this.condoService.getCondoResidentUnmaskedField(this.condo._id, query, user._id, field).pipe(timeout(10000)).subscribe(callback);
  }

  fillResidenceUserLabels(users) {
    this.usersResidenceUserLabel = users.reduce((accumulator, current) => {
      return {
        ...accumulator,
        [current.id]: {
          label: current.condoResidenceAttributes.find(attr => attr.residence === this.residence._id)?.userLabel
        }
      };
    }, {});
  }

  isVoter(residence) {
    return residence.voter && residence.voter._id == this.user.id;
  }
  selectUserSToAddOnResidence(residence: Residence) {
    this.userPickerMultipleSelection = true;
    this.userIdsToHide = residence.users.map(user => user._id);
    this.userSelectCallback = selectedUsers => {
      this.addUsersOnResidence(selectedUsers, residence);
    };
    this.selectUser();
  }
  addUsersOnResidence(usersToAdd: Array<User>, residence: Residence) {
    // Obtém a instãncia nativa do sweet alert. A abstração do plugin instalado não permite controle total sobre o modal
    swal({
      title: 'Adicionando ' + (this.condo?.customLabels?.resident?.plural || 'condôminos'),
      type: 'info',
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    swal.showLoading();

    forkJoin(
      usersToAdd.map(user => {
        return this.residenceService.approveUserOnResidence(this.condo._id, residence.id, user.id);
      })
    )
      .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        () => {
          this.countData = +this.countData + 1;
          this.residents = this.residents.concat(usersToAdd);
          this.residentUpdated.emit(this.residents);
          swal.close();
          swal({
            type: 'success',
            title: (capitalize(this.condo?.customLabels?.resident?.plural) || 'Condôminos') + ' adicionados com sucesso',
            showCancelButton: false,
            confirmButtonText: 'Fechar'
          });
        },
        (err: Error) => {
          let msg;

          if (err.originalErrorMessage.includes('Only a voter or resident or condo admin can execute this action')) {
            msg =
              'Você não possui privilégios suficientes para adicionar um ' +
              (this.condo?.customLabels?.resident?.singular || 'condômino') +
              '.';
          } else {
            msg =
              err.message ||
              'Não foi possível adicionar um ou mais ' +
                (this.condo?.customLabels?.resident?.plural || 'condôminos') +
                ', tente adicionar novamente';
          }

          swal.close();
          swal({
            type: 'error',
            title: err.messageTitle || 'Erro ao adicionar ' + (this.condo?.customLabels?.resident?.plural || 'condôminos'),
            text: msg,
            showCancelButton: false,
            confirmButtonText: 'Fechar'
          });
        }
      );
  }
  selectUser() {
    const initialState: any = {
      condo: this.condo,
      userIdsToHide: this.userIdsToHide,
      multipleSelection: this.userPickerMultipleSelection,
      callbacks: {
        success: selectedUsers => {
          if (selectedUsers.length) {
            this.userSelectCallback(selectedUsers);
          }
        }
      }
    };
    this.modalService.show(ModalResidentPickerComponent, { initialState, class: 'modal-md', ignoreBackdropClick: true });
  }

  askToRemoverUserFromResidence(user, residence) {
    swal({
      type: 'question',
      title: `Excluir ${this.condo?.customLabels?.resident?.singular || 'condômino'} da(o) ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      }?`,
      text: `Realmente deseja excluir ${user.firstName} ${user.lastName} da(o) ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      } ${residence.identification}? Obs: Ele ainda terá acesso ao sistema.`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.removeUserFromResidence(user, residence)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err =>
            Promise.reject(
              err?.originalError?.message ||
                `Não foi possível remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'}. Tente novamente`
            )
          );
      }
    }).then(
      () => {
        this.countData = +this.countData - 1;
        this.residents = this.residents.filter(u => u._id != user._id);
        this.residentUpdated.emit(this.residents);
        swal({
          type: 'success',
          title: '' + (capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino') + ' excluído com sucesso!'
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  removeUserFromResidence(user: User, residence: Residence) {
    return this.residenceService.removeUserFromResidence(this.condo._id, residence._id, user._id);
  }
  askToSetUserAsVoter(user: User, residence: Residence) {
    swal({
      type: 'question',
      title: `Definir ${this.condo?.customLabels?.voter?.singular || 'proprietário'}`,
      text: `Deseja definir ${user.firstName} ${user.lastName} como ${this.condo?.customLabels?.voter?.singular || 'proprietário'} da(o) ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      } ${residence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.setUserAsVoter(user, residence)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject(
              `Não foi possível alterar o ${this.condo?.customLabels?.voter?.singular || 'proprietário'}, tente novamente.`
            );
          });
      }
    }).then(
      () => {
        this.residents = this.residents.filter(u => u._id != user._id);
        this.residentUpdated.emit(this.residents);
        this.residentAsVoter.emit(user);
        swal({
          type: 'success',
          title: `${capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário'} alterado com sucesso!`
        });
      },
      () => {}
    );
  }

  setUserAsVoter(user, residence: Residence) {
    return this.residenceService.changeResidenceVoter(this.condo._id, residence.id, user._id);
  }
}
