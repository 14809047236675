import { Component } from '@angular/core';

// Services
import { ParamsService } from '../../api/service/params.service';
import { Router } from '@angular/router';

// Models
import {User} from '../../api/model/user';
import {takeUntil} from 'rxjs/operators';
import {ThemeService} from '../../theme';
import {Subject} from 'rxjs';
import {UtilService} from '../../services/util.service';

@Component({
  templateUrl: './signup.html'
})
export class SignUpPage {
  public logoUrl = '';
  public selectedTab: number;
  public userData: User;

  public tabs = {
    PERSONAL_INFORMATION: 1,
    CONDO_SELECTION: 2
  };

  private _destroy$ = new Subject();

  constructor(private paramsService: ParamsService, private themeService: ThemeService, private router: Router) {
    this.logoUrl = 'assets/img/logo.png';

    this.userData = this.paramsService.get('userData');
    this.selectedTab = this.userData.isOnSignUpStepTwo() ? 2 : 1;

    this.themeService
      .getActiveTheme()
      .pipe(takeUntil(this._destroy$))
      .subscribe(theme => {
        if (theme && (theme.banner || theme.logo)) {
          this.logoUrl = theme.banner || theme.logo;
        } else {
          this.logoUrl = 'assets/img/logo.png';
        }
      });
  }

  onStepOneSuccess(user: User) {
    this.userData = user;
    if (user.isOnSignUpStepTwo()) {
      this.setTab('CONDO_SELECTION');
    } else {
      this.router.navigate(['/feed']);
    }
  }

  setTab(tabsKey) {
    this.selectedTab = this.tabs[tabsKey] || 1;
  }

  goBackToLogin() {
    this.router.navigate(['/']);
  }
}
