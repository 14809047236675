export const EVENT_TYPES = {
  PANIC: 'PANIC',
  WARNING: 'WARNING',
  RESIDENT: 'RESIDENT',
  VISITOR: 'VISITOR',
  UNKNOWN_PLATE: 'UNKNOWN_PLATE',
  OTHER: 'OTHER',
  TRIGGER: 'TRIGGER'
};

export const EVENT_TYPES_LABEL = {
  PANIC: 'Pânico',
  WARNING: 'Alerta',
  RESIDENT: 'Condômino',
  VISITOR: 'Visitante',
  UNKNOWN_PLATE: 'Reconhecimento de placa',
  OTHER: 'Outro',
  TRIGGER: 'Acionamento'
};
