import { NgModule } from '@angular/core';
import { ComponentsModule } from '../../components/components.module';
import { ModalAddHardwareDeviceModule } from '../../hardware/modal-add-hardware-device/modal-add-hardware-device.module';
import { ModalCreateControlIdDeviceModule } from '../../hardware/modal-create-controlid-device/modal-create-controlid-device.module';
import { ModalCreateHikvisionDeviceModule } from '../../hardware/modal-create-hikvision-device/modal-create-hikvision-device.module';
import { ModalCreateUtechDeviceModule } from '../../hardware/modal-create-utech-device/modal-create-utech-device.module';
import { ModalCreateAlphadigiDeviceModule } from '../../hardware/modal-create-alphadigi-device/modal-create-alphadigi-device.module';
import { ModalCreateIntelbrasStandAloneDeviceModule } from '../../hardware/modal-create-intelbras-stand-alone-device/modal-create-intelbras-stand-alone-device.module';
import { ModalCreateZktecoDeviceModule } from '../../hardware/modal-create-zkteco-device/modal-create-zkteco-device.module';
import { ModalCreateGarenDeviceModule } from '../../hardware/modal-create-garen-device/modal-create-garen-device.module';
import { ModalCreateDeviceModule } from '../../hardware/modal-create-device/modal-create-device.module';
import { ModalCreateIntelbrasIncontrolDeviceModule } from '../../hardware/modal-create-intelbras-incontrol-device/modal-create-intelbras-incontrol-device.module';
import { ModalCreateNiceControllerDeviceModule } from '../../hardware/modal-create-nice-controller-device/modal-create-nice-controller-device.module';
import { ModalCreateVisitorPlateDeviceModule } from '../../hardware/modal-create-visitor-plate-device/modal-create-visitor-plate-device.module';

@NgModule({
  imports: [
    ComponentsModule,
    ModalAddHardwareDeviceModule,
    ModalCreateControlIdDeviceModule,
    ModalCreateHikvisionDeviceModule,
    ModalCreateUtechDeviceModule,
    ModalCreateAlphadigiDeviceModule,
    ModalCreateIntelbrasStandAloneDeviceModule,
    ModalCreateZktecoDeviceModule,
    ModalCreateGarenDeviceModule,
    ModalCreateDeviceModule,
    ModalCreateIntelbrasIncontrolDeviceModule,
    ModalCreateNiceControllerDeviceModule,
    ModalCreateVisitorPlateDeviceModule
  ]
})
export class CreateHardwareModalsModule {}
