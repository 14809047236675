import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Local } from '@api/model/local';
import { TableColumnDefinition, TableComponent } from '../../../components/table/table.component';
import { formatCurrencyToShow } from '@api/util/formatters';

@Component({
  selector: 'app-other-local-description',
  templateUrl: './other.local.modal.html',
  styleUrls: ['./other.local.modal.scss']
})
export class OtherLocalModalComponent implements OnInit {
  @ViewChild('penaltiesCellTemplate', { static: true }) penaltiesCellTemplate: TemplateRef<any>;
  @ViewChild('termsCellTemplate', { static: true }) termsCellTemplate: TemplateRef<any>;
  @ViewChild('descriptionCellTemplate', { static: true }) descriptionCellTemplate: TemplateRef<any>;
  @ViewChild('localsTable', { static: true }) localsTable: TableComponent;

  reservationLocals: Local[];
  tableColumns: TableColumnDefinition<Local>[] = [];
  callbacks: { success?: (ap) => void };
  constructor(public bsModalRef: BsModalRef) {}

  closeModal(): void {
    this.bsModalRef.hide();
  }

  ngOnInit() {
    this.tableColumns = [
      {
        headerLabel: 'Nome',
        valueKey: 'name'
      },
      {
        headerLabel: 'Valor inicial',
        valueFn: reservationLocal => formatCurrencyToShow(reservationLocal.value.toString())
      },
      {
        headerLabel: 'Lotação máxima',
        valueKey: 'capacity'
      },
      {
        headerLabel: 'Multas de cancelamento',
        valueTemplate: this.penaltiesCellTemplate
      },
      {
        headerLabel: 'Descrição',
        valueTemplate: this.descriptionCellTemplate
      },
      {
        headerLabel: 'Termos de reserva',
        valueTemplate: this.termsCellTemplate
      }
    ];
  }

  showLocalDescription(local: Local) {
    if (this.callbacks && this.callbacks.success) {
      this.callbacks.success({ local, description: true });
    }
  }

  showLocalTerms(local: Local) {
    if (this.callbacks && this.callbacks.success) {
      this.callbacks.success({ local, terms: true });
    }
  }
}
