import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { from, Observable, Subscriber } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../services/util.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DependentServiceV2 } from '@api/serviceV2/dependent.service';
import { CondoContactServiceV2 } from '@api/serviceV2/condo.contact.service';
import { CondoServiceV2 } from '@api/serviceV2/condo.service';
import { removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { CondoUser } from '@api/model/condo-user';
import { Dependent } from '@api/model/dependent';
import { CondoContact } from '@api/model/contact/condo.contact';

@Component({
  selector: 'app-person-autocomplete',
  templateUrl: './person-auto-complete.component.html',
  styleUrls: ['person-auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PersonAutoCompleteComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PersonAutoCompleteComponent
    }
  ]
})
export class PersonAutoCompleteComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @Input() label = '';

  @Input() personType: 'RESIDENT' | 'VISITOR' | 'DEPENDENT' = 'RESIDENT';
  @Input() typeaheadMinLength = 3;
  @Input() customQuery: EcondosQuery;
  @Input() shouldValidate = true;

  person: any;
  dataSource$: Observable<any>;
  condo: Condo;

  textSearch = '';
  status: 'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS' = 'IDLE';
  noResult = false;

  touched = false;
  isDisabled = false;
  isValid = true;

  placeholder;

  onChange = value => {};

  onTouched = () => {};

  constructor(
    private condoContactService: CondoContactServiceV2,
    private dependentService: DependentServiceV2,
    private condoService: CondoServiceV2,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.condo = this.utilService.getLocalCondo();

    this.buildPlaceHolder(this.personType);

    this.dataSource$ = new Observable((observer: Subscriber<string>) => observer.next(this.textSearch)).pipe(
      distinctUntilChanged(),
      tap(() => this.markAsTouched()),
      mergeMap((token: string) => {
        let terms: any = token.trim().toLowerCase();
        terms = terms.split(' ');
        terms = terms.map(word => removeAccents(word));
        terms = terms.map(replaceVowelsToAccentedVowels);

        let observable;
        switch (this.personType) {
          case 'DEPENDENT': {
            const dependentQuery: EcondosQuery = {
              $select: 'name residence',
              $populate: [
                { path: 'picture', select: 'url thumbnail type format name' },
                { path: 'residence', select: 'identification' }
              ],
              $sort: 'name',
              name: { $regex: token, $options: 'i' },
              ...(this.customQuery || {})
            };
            dependentQuery.$or = terms.map(t => ({ name: { $regex: t, $options: 'i' } }));
            observable = this.dependentService.getDependents(this.condo._id, dependentQuery);
            break;
          }
          case 'VISITOR': {
            const visitorQuery: EcondosQuery = {
              $select: 'firstName lastName',
              $populate: [{ path: 'picture', select: 'url thumbnail type format name' }],
              $sort: 'firstName lastName',
              ...(this.customQuery || {})
            };
            observable = this.condoContactService.searchByToken(this.condo._id, token, visitorQuery);
            break;
          }
          case 'RESIDENT':
          default: {
            const residentQuery: EcondosQuery = {
              $select: 'firstName lastName residencesUser',
              $populate: [
                { path: 'picture', select: 'url thumbnail type format name' },
                { path: 'residencesUser', select: 'identification' }
              ],
              $sort: 'firstName lastName',
              ...(this.customQuery || {})
            };
            const $and = [];
            for (const term of terms) {
              $and.push({ $or: [{ firstName: { $regex: term, $options: 'i' } }, { lastName: { $regex: term, $options: 'i' } }] });
            }
            residentQuery.$and = $and;
            observable = this.condoService.getCondoResidents(this.condo._id, residentQuery);
            break;
          }
        }

        return observable.pipe(
          map((res: any) => {
            switch (this.personType) {
              case 'DEPENDENT': {
                return res.dependents;
              }
              case 'VISITOR': {
                return res.contacts.map(d => ({ ...d, name: `${d.firstName} ${d.lastName}`, picture: d.picture }));
              }
              case 'RESIDENT':
              default: {
                const usersObj = res.users.reduce((acc, cur) => {
                  acc[cur._id] = cur;
                  return acc;
                }, {} as Record<string, any>);

                const usersArray = Object.values(usersObj) as any[];

                return usersArray.map(d => ({ ...d, name: `${d.firstName} ${d.lastName}`, picture: d.picture }));
              }
            }
          }),
          catchError(e => {
            this.status = 'ERROR';
            return from([]);
          })
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.personType?.currentValue) {
      const { currentValue } = changes.personType;
      this.buildPlaceHolder(currentValue);
    }
  }

  onSelect(event: TypeaheadMatch): void {
    if (!this.isDisabled) {
      this.person = event.item;
      this.markAsTouched();
      this.onChange(this.person);
    }
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
  }

  clearValue() {
    this.person = null;
    this.textSearch = '';
    this.noResult = false;
    this.onChange(null);
  }

  // Angular form methods
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(person: CondoUser | Dependent | CondoContact): void {
    if (person) {
      let anyPerson = person as any;
      if (!anyPerson.name) {
        anyPerson = { ...person, name: `${anyPerson.firstName} ${anyPerson.lastName}` };
      }
      this.person = anyPerson;
      this.textSearch = anyPerson.name;
    } else {
      this.person = person;
      this.textSearch = '';
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.shouldValidate) {
      return null;
    }
    const person = control.value;
    if (!this.textSearch && person) {
      this.isValid = false;
      return {
        personNotSelected: {
          person
        }
      };
    } else {
      this.isValid = true;
      return null;
    }
  }
  buildPlaceHolder(personType) {
    switch (personType) {
      case 'DEPENDENT': {
        this.placeholder = `Busque por ${this.condo?.customLabels?.dependent?.singular || 'dependente'}`;
        break;
      }
      case 'VISITOR': {
        this.placeholder = `Busque por visitante`;
        break;
      }
      case 'RESIDENT':
      default: {
        this.placeholder = `Busque por ${this.condo?.customLabels?.resident?.singular}`;
        break;
      }
    }
  }
  resetStatus() {
    this.noResult = false;
    this.status = 'IDLE';
  }
}
