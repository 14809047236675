import { Injectable } from '@angular/core';
import { CacheService } from '@api/service/cache/cache.service';
import { EcondosQuery } from '@api/model/query';
import { OccurrenceService } from '@api/service/occurrence.service';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Occurrence } from '@api/model/interface/occurrence';
import { Dependent } from '@api/model/dependent';
import { User } from '@api/model/user';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { ResidenceServiceV3 } from '@api/serviceV3/residence.service';
import { ResidenceServiceV2 } from '@api/serviceV2/residence.service';
import { Residence } from '@api/model/interface/residence';
import { ResidenceService } from '@api/service/residence.service';

@Injectable({ providedIn: 'root' })
export class CachedRequestsService {
  constructor(
    private cacheService: CacheService,
    private occurrenceService: OccurrenceService,
    private residenceServiceV1: ResidenceService,
    private residenceServiceV2: ResidenceServiceV2,
    private residenceServiceV3: ResidenceServiceV3
  ) {}

  getOccurrences(condoId: string, params: EcondosQuery) {
    const key = this.buildCacheKey('occurrences', params);
    return this.buildCachedResponse<{ count: number; occurrences: Occurrence[] }>(
      key,
      this.occurrenceService.getOccurrences(condoId, params)
    );
  }

  private buildCachedResponse<T>(key: string, observable: Observable<T>): Observable<T> {
    let cachedObservable$ = this.cacheService.getValue(key);
    if (!cachedObservable$) {
      cachedObservable$ = observable.pipe(shareReplay(1));
      this.cacheService.setValue(key, cachedObservable$);
    }
    return cachedObservable$;
  }

  private buildCacheKey(method: string, query: EcondosQuery) {
    const stringified = JSON.stringify(query);
    return `${method}:${stringified}`;
  }

  getDependents(condo: string, residence: string, params: Array<{ [key: string]: string }>) {
    const key = this.buildCacheKey('dependents', params);
    return this.buildCachedResponse<{ count: number; dependents: Dependent[] }>(
      key,
      this.residenceServiceV1.getDependents(condo, residence, params)
    );
  }

  getUsersFromResidence(condo: string, residence: string, query: EcondosQuery) {
    const key = this.buildCacheKey('residents', query);
    return this.buildCachedResponse<{ users: User[]; count?: number }>(
      key,
      this.residenceServiceV3.getUsersFromResidence(condo, residence, query)
    );
  }

  getUsersFromResidenceCount(condo: string, residence: string, query: EcondosQuery) {
    const key = this.buildCacheKey('residents-count', query);
    return this.buildCachedResponse(key, this.residenceServiceV2.getUsersFromResidence(condo, residence, query));
  }

  getResidenceById(condo: string, residence: string, query: EcondosQuery) {
    const key = this.buildCacheKey('residence', query);
    return this.buildCachedResponse<Residence>(key, this.residenceServiceV2.getResidenceByIdWithParams(condo, residence, query));
  }

  getVehicles(condo: string, residence: string, query: EcondosQuery) {
    const key = this.buildCacheKey('vehicles', query);
    return this.buildCachedResponse<{ count: number; vehicles: CondoVehicle[] }>(
      key,
      this.residenceServiceV3.getVehicles(condo, residence, query)
    );
  }
}
