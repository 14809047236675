import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, noop, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import swal from 'sweetalert2';
import { UtilService } from '../services/util.service';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  readonly whitelist = ['api-staging2.econdos.com.br', 'api-staging.econdos.com.br', 'api.econdos.com.br', 'reports.econdos.com.br'];

  private clearSession$: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthService, private utilService: UtilService) {
    this.clearSession$
      .pipe(
        filter(v => v),
        filter(() => !!utilService.getLocalToken()),
        switchMap(() => from(this.authService.unregisterSession())),
        tap(() => {
          swal({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Sua sessão expirou. Faça o login novamente!',
            showCancelButton: false,
            confirmButtonColor: '#28a745',
            confirmButtonText: 'Ok'
          })
            .then(() => location.reload())
            .catch(() => location.reload());
        })
      )
      .subscribe(() => noop, noop);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const url = request.url || '';
        if (err.status === 401 && !url.includes('users/me') && this.whitelist.some(white => url.includes(white))) {
          // auto logout if 401 response returned from api
          this.clearSession$.next(true);
          return of(err);
        } else {
          return throwError(err);
        }
      })
    );
  }
}
