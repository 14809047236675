import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { User } from '@api/model/user';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../components/table/table.component';
import { Residence } from '@api/model/interface/residence';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';
import { UtilService } from '../../../services/util.service';
import { map, tap, timeout } from 'rxjs/operators';
import { CachedRequestsService } from '../cached-requests.service';
import { CondoService } from '@api/service/condo.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-gate-residence-details-residents',
  templateUrl: './gate-residence-details-residents.component.html'
})
export class GateResidenceDetailsResidentsComponent implements OnInit {
  @Input() residence: Residence;
  @Input() condo: Condo;

  residenceUsers: User[] = [];

  tableColumns: TableColumnDefinition<User>[] = [];

  @ViewChild('residentsTable', { static: true }) residentsTable: TableComponent;
  @ViewChild('nameCellTemplate', { static: true }) nameCellTemplate: TemplateRef<any>;
  @ViewChild('residenceAttributeCellTemplate', { static: true }) residenceAttributeCellTemplate: TemplateRef<any>;
  @ViewChild('telephone1CellTemplate', { static: true }) telephone1CellTemplate: TemplateRef<any>;
  @ViewChild('telephone2CellTemplate', { static: true }) telephone2CellTemplate: TemplateRef<any>;
  @ViewChild('telephone3CellTemplate', { static: true }) telephone3CellTemplate: TemplateRef<any>;

  status: TableStatus = 'LOADING';
  paginate = false;
  totalData = 0;

  constructor(private utilService: UtilService, private cachedRequestsService: CachedRequestsService, private condoService: CondoService) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        columnId: 'firstName',
        sortable: true,
        headerLabel: 'Nome',
        valueTemplate: this.nameCellTemplate
      },
      {
        headerLabel: 'Atribuição',
        valueTemplate: this.residenceAttributeCellTemplate
      },
      {
        headerLabel: 'Telefone',
        valueTemplate: this.telephone1CellTemplate
      },
      { headerLabel: 'Telefone 2', valueTemplate: this.telephone2CellTemplate },
      { headerLabel: 'Telefone 3', valueTemplate: this.telephone3CellTemplate }
    ];
    this.getData({ page: 0 });
  }

  getData({ page = 0 }) {
    const { pageSize, sortedColumn, sortOrder } = this.residentsTable.getCurrentState();

    const query: EcondosQuery = {
      $select: 'firstName lastName picture phones specialNeeds specialNeedsDetails condoResidenceAttributes',
      $page: page,
      $limit: pageSize || 15,
      $populate: [
        {
          path: 'picture',
          select: 'url thumbnail type name format'
        }
      ]
    };

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    let requests = [];
    requests.push(
      this.cachedRequestsService.getUsersFromResidence(this.condo._id, this.residence._id, query).pipe(map(({ users }) => users))
    );
    if (page === 0) {
      const countQuery = {
        $count: true
      };
      requests.push(
        this.cachedRequestsService
          .getUsersFromResidenceCount(this.condo._id, this.residence._id, countQuery)
          .pipe(map(({ count }) => count))
      );
    }

    forkJoin(requests)
      .pipe(
        map((response: any) => {
          return {
            users: response[0].map(user => {
              const tag = user.condoResidenceAttributes.find(att => {
                return att.residence === this.residence._id;
              });
              user.condoResidenceAttributes = tag ? [tag] : [];
              return new User(user);
            }),
            count: response[1] || null
          };
        })
      )
      .subscribe({
        next: ({ users, count }) => {
          this.residenceUsers = users;
          if (page === 0) {
            this.paginate = count >= pageSize;
            this.totalData = count;
          }

          this.status = 'SUCCESS';
        },
        error: () => {
          this.status = 'ERROR';
        }
      });
  }

  revealData(field: string, user) {
    user.isDataMasked[field] = false;

    let isFieldAlreadyUnmasked: boolean;

    if (field === 'ids') {
      isFieldAlreadyUnmasked = !user.ids
        .map(id => id.number)
        .toString()
        .includes('*');
    } else {
      isFieldAlreadyUnmasked = !user[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    if (field === 'ids') {
      query.$populate = [{ path: 'ids.pictures', select: 'url thumbnail' }];
    }

    const callback = ({ data }) => (user[field] = data);

    this.condoService.getCondoResidentUnmaskedField(this.condo._id, query, user._id, field).pipe(timeout(10000)).subscribe(callback);
  }
}
