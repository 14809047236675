import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';

import { OccurrenceService } from '../../../api/service/occurrence.service';
import { OccurrenceBuilder } from '../../../api/model/occurrence/occurrence.builder';
import { Occurrence } from '../../../api/model/interface/occurrence';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { OccurrenceFileUploader } from '../occurrence.creator.file.list/files.scroll';
import { OccurrenceCreator } from '../occurrence.creator';
import swal from 'sweetalert2';

import * as moment from 'moment';
import { timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'voting-creator',
  templateUrl: 'voting.occurrence.creator.html',
  styleUrls: ['voting.occurrence.creator.scss']
})
export class VotingCreatorComponent extends OccurrenceCreator implements OnInit {
  @ViewChild(OccurrenceFileUploader) occurrenceFileUploader: OccurrenceFileUploader;
  @Input()
  condo: Condo;

  @Input()
  user: User;

  @Output()
  onOccurrenceCreated = new EventEmitter();

  @Output()
  onOccurrenceUpdated = new EventEmitter();

  startDate: AbstractControl;
  endDate: AbstractControl;
  voteType: AbstractControl;
  choiceToAdd: AbstractControl;
  defaulterCanVote: AbstractControl;

  dateForm: UntypedFormGroup;
  choiceForm: UntypedFormGroup;
  minDate = new Date();

  voteChoices = [];

  isCreatingOccurrence = false;

  constructor(formBuilder: UntypedFormBuilder, private occurrenceService: OccurrenceService, private toastr: ToastrService) {
    super(formBuilder);
  }

  ngOnInit() {}

  initializeForm() {
    super.initializeForm();

    this.voteType = new UntypedFormControl('', Validators.required);
    this.defaulterCanVote = new UntypedFormControl(true);

    this.dateForm = this.formBuilder.group({
      startDate: [{ value: new Date(), disabled: true }, Validators.compose([Validators.required])],
      endDate: ['', Validators.compose([Validators.required])]
    });

    this.startDate = this.dateForm.get('startDate');
    this.endDate = this.dateForm.get('endDate');

    this.dateForm.addControl('voteType', this.voteType);

    this.choiceForm = this.formBuilder.group({
      choiceToAdd: ['', Validators.compose([Validators.required])]
    });

    this.choiceToAdd = this.choiceForm.controls['choiceToAdd'];
    this.voteChoices = [];
  }

  editOccurrence(occurrence: Occurrence): void {
    super.editOccurrence(occurrence);

    this.startDate.setValue(new Date(this.editingOccurrence.votingFrom));
    this.endDate.setValue(new Date(this.editingOccurrence.votingUntil));

    this.editingOccurrence.votes.map((voteChoice: any) => {
      this.voteChoices.push(voteChoice);
    });

    this.voteType.setValue(this.editingOccurrence.voteType);
    this.voteType.disable();

    this.defaulterCanVote.setValue(this.editingOccurrence.defaulterCanVote);
    this.defaulterCanVote.disable();
  }

  checkStatus() {
    if (this.endDate.status === 'INVALID') {
      this.endDate.setValue('');
    }
  }

  canSubmit() {
    if (!this.occurrenceForm.valid) {
      this.title.markAsTouched();
      this.description.markAsTouched();
      return false;
    }

    if (!this.dateForm.valid) {
      this.startDate.markAsTouched();
      this.endDate.markAsTouched();
      this.voteType.markAsTouched();
      return false;
    }

    if (this.voteChoices.length < 2) {
      return false;
    }

    return true;
  }

  createOccurrence() {
    if (!this.canSubmit()) {
      this.checkStatus();
      this.toastr.error('Preencha todos os dados');
      return;
    }

    this.isSubmiting = true;

    const occurrence: any = {
      title: this.title.value,
      description: this.description.value,
      type: Occurrence.VOTING_TYPE,
      voteType: this.voteType.value,
      pictures: this.attachments.map(att => att._id),
      votingFrom: moment(this.startDate.value).startOf('day').toISOString(),
      votingUntil: moment(this.endDate.value).endOf('day').toISOString(),
      votes: this.voteChoices
    };
    if (this.voteType.value === 'RESIDENCE') {
      occurrence.defaulterCanVote = this.defaulterCanVote.value;
    }

    this.occurrenceService
      .createOccurrence(this.condo._id, occurrence)
      .pipe(timeout(10000))
      .subscribe(
        (resp: any) => {
          this.initializeForm();
          occurrence._id = resp._id;
          occurrence.condo = this.condo;
          occurrence.createdBy = this.user;
          occurrence.createdAt = moment().utc().toDate();
          occurrence.pictures = this.attachments;
          occurrence.isVoting = true;
          this.onOccurrenceCreated.emit(OccurrenceBuilder.build(occurrence));
          this.attachments = [];
          this.isCreatingOccurrence = false;
          this.isSubmiting = false;
          this.showing = false;
        },
        err => {
          this.isSubmiting = false;
          swal({
            type: 'error',
            text: 'Não foi possível criar a enquete, tente novamente'
          });
        }
      );
  }

  uploadFile() {
    this.occurrenceFileUploader.pickFile();
  }

  // Não existe edição de enquete, porém o método tem de ser implementado devido ao uso da super classe
  updateOccurrence() {
    if (!this.canSubmit() || !this.editingOccurrence) {
      this.toastr.error('Preencha todos os dados');
      return;
    }

    this.isSubmiting = true;
    const occurrence: any = {
      title: this.title.value,
      description: this.description.value,
      type: Occurrence.VOTING_TYPE,
      voteType: this.voteType.value,
      pictures: this.attachments.map(att => att._id),
      votingFrom: moment(this.startDate.value).startOf('day').toISOString(),
      votingUntil: moment(this.endDate.value).endOf('day').toISOString(),
      votes: this.voteChoices
    };
    if (this.voteType.value === 'RESIDENCE') {
      occurrence.defaulterCanVote = this.defaulterCanVote.value;
    }
    const occurrenceId = this.editingOccurrence.id;

    this.occurrenceService
      .updateOcurrence(this.condo._id, occurrenceId, occurrence)
      .pipe(timeout(10000))
      .subscribe(
        res => {
          this.editingOccurrence.title = occurrence.title;
          this.editingOccurrence.description = occurrence.description;
          this.editingOccurrence.votingUntil = occurrence.votingUntil;
          this.editingOccurrence.votes = occurrence.votes;
          this.editingOccurrence.pictures = this.attachments;
          this.editingOccurrence.isVoting = moment().isBetween(occurrence.votingFrom, occurrence.votingUntil);
          this.onOccurrenceUpdated.emit(this.editingOccurrence);
          this.isSubmiting = false;
          this.cancelEditing();
        },
        (err: Error) => {
          this.isSubmiting = false;
          swal({
            text: 'Não foi possível editar a ocorrência, tente novamente'
          });
        }
      );
  }

  addVoteChoice() {
    const choiceRepetition = this.voteChoices.some(choice => choice.text.toLowerCase() === this.choiceToAdd.value.toLowerCase());
    if (!choiceRepetition) {
      if (this.choiceToAdd.valid && !this.isEditingOccurrence) {
        this.voteChoices.push({ text: this.choiceToAdd.value });
        this.choiceToAdd.reset();
      } else {
        this.choiceToAdd.markAsTouched();
      }
    } else {
      this.choiceToAdd.markAsTouched();
      swal({
        type: 'error',
        title: 'Erro',
        text: 'Esta alternativa já existe.'
      });
    }
  }

  removeVoteChoice(index: number) {
    this.voteChoices.splice(index, 1);
  }
}
