import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { CondoService } from '@api/service/condo.service';
import { UserService } from '@api/service/user.service';
import { ResidenceService } from '@api/service/residence.service';
import { CondoContactService } from '@api/service/condo.contact.service';
import { catchError, retry, switchMap, tap } from 'rxjs/operators';
import { defer, from, merge, noop, of } from 'rxjs';
import { ContactID } from '@api/model/contact/contact.id';
import swal from 'sweetalert2';
import { CondoContact } from '@api/model/contact/condo.contact';
import { FileService } from '@api/service/file.service';
import { createImageFromBlob } from '@api/utils';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { VehicleService } from '@api/service/vehicle.service';
import { EcondosQuery } from '@api/model/query';
import { Condo } from '@api/model/condo';

@Component({
  selector: 'app-modal-import-intelbras-incontrol-visitors',
  templateUrl: 'modal-import-intelbras-incontrol-visitors.component.html',
  styleUrls: ['modal-import-intelbras-incontrol-visitors.component.scss']
})
export class ModalImportIntelbrasIncontrolVisitorsComponent implements OnInit {
  STATUS = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
  };

  status = null;

  STEPVISITOR = {
    total: 0,
    withFacial: 0,
    withVehicle: 0,
    duplicateds: 0,
    totalToImport: 0,
    progress: 0,
    withoutFacial: 0,
    visitorsToImport: [],
    feedbackMessage: '',
    status: '',
    withErrors: []
  };

  callback;
  visitorsToImport;
  visitors;
  condo: Condo;
  user;
  p = 1;
  currentStep = 0;

  constructor(
    public bsModalRef: BsModalRef,
    private deviceService: HardwareDeviceService,
    private intelbrasService: IntelbrasIncontrolService,
    private condoService: CondoService,
    private userService: UserService,
    private residenceService: ResidenceService,
    private condoContactService: CondoContactService,
    private fileService: FileService,
    private modalService: BsModalService,
    private vehicleService: VehicleService
  ) {}

  ngOnInit() {}

  async nextStep() {
    this.status = this.STATUS.LOADING;
    if (this.currentStep !== 1 && !this.visitorsToImport) {
      this.getEcondosVisitors();
      this.visitorsToImport = await this.getVisitors();
      this.refreshUsersCounters();
      this.status = this.STATUS.SUCCESS;
    }
    if (this.currentStep == 1 && this.visitorsToImport) {
      this.importData(this.visitorsToImport);
    }
    this.currentStep++;
  }

  previousStep() {
    this.currentStep--;
  }

  refreshUsersCounters() {
    this.STEPVISITOR.withVehicle = this.visitorsToImport.filter(
      u => u.pessoa?.veiculo && u.pessoa?.veiculo?.placa_letra && u.pessoa?.veiculo?.placa_numero
    )?.length;
    this.STEPVISITOR.withFacial = this.visitorsToImport.filter(u => u.pessoa?.imagem?.id)?.length;
    this.STEPVISITOR.withoutFacial = this.visitorsToImport.filter(u => u.pessoa?.imagem === null)?.length;
    this.STEPVISITOR.total = this.visitorsToImport?.length;
  }

  removeVisitor(visitante) {
    const index = this.visitorsToImport.findIndex(v => v.id === visitante.id);
    this.visitorsToImport.splice(index, 1);
    this.refreshUsersCounters();
  }

  importData(visitantes) {
    const total = visitantes.length;
    let done = 0;
    this.STEPVISITOR.status = this.STATUS.LOADING;
    this.STEPVISITOR.progress = 0;
    const observables = visitantes.map(visitante =>
      of(visitante).pipe(
        switchMap(data => {
          let visitorExists = this.visitors.find(
            v =>
              v.externalId == data.id ||
              v.ids.find(id => id.type === 'RG' && id.number === data.rg?.replace(/[\.-]/g, '')) ||
              v.ids.find(id => id.type === 'CPF' && id.number === data.cpf?.replace(/[\.-]/g, ''))
          );
          const names = (data.pessoa?.nome_completo || '').split(' ').filter(v => v.trim());
          const firstName = names.shift();
          const lastName = names.join(' ');
          const phones = [];
          const ids = [];
          const condoVehicles = [];
          const type = CondoContact.TYPES.VISITOR;
          const externalId = data.id;
          if (data.rg) {
            ids.push({ type: ContactID.TYPES.RG, number: data.rg });
          }
          if (data.cpf) {
            ids.push({ type: ContactID.TYPES.CPF, number: data.cpf });
          }
          if (
            data.pessoa?.veiculo &&
            (data.pessoa?.veiculo?.placa || (data.pessoa?.veiculo?.placa_letra && data.pessoa?.veiculo?.placa_numero))
          ) {
            const vehicle = new CondoVehicle();
            vehicle.plate =
              data.pessoa?.veiculo?.placa?.toUpperCase() ||
              data.pessoa?.veiculo?.placa_letra?.toUpperCase() + data.pessoa?.veiculo?.placa_numero?.toUpperCase();
            vehicle.model = data.pessoa?.veiculo?.modelo?.toUpperCase() || '';
            vehicle.type = CondoVehicle.TYPES.CAR;
            vehicle.brand = data.pessoa?.veiculo?.marca?.descricao?.toUpperCase() || '';
            vehicle.color = data.pessoa?.veiculo?.cor?.toUpperCase() || '';
            // const vehicleResponse: any = await this.vehicleService.createVehicle(this.condo.id, vehicle.createBackObject()).toPromise()
            // condoVehicles.push(vehicleResponse._id)
            // this.getVehicleServiceMethod(vehicle, vehicleId).pipe(timeout(10000)).subscribe((resp: any) => {
            this.vehicleService.createVehicle(this.condo.id, vehicle.createBackObject()).subscribe(
              (res: any) => {
                condoVehicles.push(res._id);
              },
              err => {
                this.STEPVISITOR.withErrors.push({ error: err, visitor: names });
              }
            );
          }
          const visitor = { firstName, lastName, ids, type, phones, condoVehicles, externalId };
          if (data.pessoa?.imagem) {
            return from(this.intelbrasService.getPhoto(data.pessoa.imagem.id)).pipe(
              switchMap((res: any) => {
                return from(createImageFromBlob(res));
              }),
              switchMap((res: any) => {
                return defer(() => this.fileService.uploadBase64(res, { faceCrop: true })).pipe(
                  catchError(e => {
                    this.STEPVISITOR.withErrors.push({ error: e, visitor: names });
                    return of(e);
                  })
                );
              }),
              switchMap((arquivoEcondos: any) => {
                visitor['picture'] = arquivoEcondos[0]._id;
                if (visitorExists) {
                  return this.condoContactService.updateCondoContact(this.condo._id, visitorExists._id, visitor);
                } else {
                  return this.condoContactService.createCondoContact(this.condo._id, visitor).pipe(
                    retry(2),
                    catchError(e => {
                      this.STEPVISITOR.withErrors.push({ error: e, visitor: names });
                      return of(e);
                    })
                  );
                }
              })
            );
          } else {
            if (visitorExists) {
              return this.condoContactService.updateCondoContact(this.condo._id, visitorExists._id, visitor);
            } else {
              return this.condoContactService.createCondoContact(this.condo._id, visitor).pipe(
                retry(2),
                catchError(e => {
                  this.STEPVISITOR.withErrors.push({ error: e, visitor: names });
                  return of(e);
                })
              );
            }
          }
        }),
        tap(() => {
          this.STEPVISITOR.progress = parseInt(((done / (total || 1)) * 100).toFixed(0), 10);
          this.STEPVISITOR.feedbackMessage = `Importando ${done} de ${total} visitantes - ${this.STEPVISITOR.progress}%`;
          done++;
        })
      )
    );
    merge(...observables, 2).subscribe(
      noop,
      err => {
        console.log(err);
        this.status = this.STATUS.ERROR;
      },
      () => {
        if (this.status !== this.STATUS.ERROR) {
          this.status = this.STATUS.SUCCESS;
          this.STEPVISITOR.status = this.STATUS.SUCCESS;
          swal({
            type: 'success',
            text: 'Dados importados com sucesso!'
          });
          // if (this.callback) {
          //   this.callback();
          // }
        }
      }
    );
  }

  async getVisitors() {
    return await this.intelbrasService.getVisitors();
  }

  getEcondosVisitors() {
    const query: EcondosQuery = {
      $select: 'firstName lastName emails phones type picture ids condoVehicles birthDate company service externalId',
      deleted: false
    };
    return this.condoContactService.getContacts(this.condo._id, query).subscribe(res => {
      this.visitors = res.contacts;
    });
  }

  saveVisitors() {}

  done() {
    // if (this.callbacks && this.callbacks.success) {
    //   this.callbacks.success();
    // }
    this.bsModalRef.hide();
  }
}
