import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { CondoService } from '@api/service/condo.service';
import { UtilService } from 'app/services/util.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'app-modal-access-control-config',
  templateUrl: 'modal-access-control-config.component.html',
  styleUrls: ['modal-access-control-config.component.scss']
})
export class ModalAccessControlConfigComponent implements OnInit {
  condo: Condo;
  accessControlConfigForm: UntypedFormGroup;
  gateKeeperMustRegisterVisitorDocument: AbstractControl;
  gateKeeperMustRegisterResidence: AbstractControl;

  status: Status = new Status();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private condoService: CondoService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    const paramGateKeeperMustRegisterVisitorDocument = this.condo?.generalParams?.accessLiberation?.gateKeeperMustRegisterVisitorDocument;
    const paramGateKeeperMustRegisterResidence = this.condo?.generalParams?.accessLiberation?.gateKeeperMustRegisterResidence;

    this.accessControlConfigForm = this.formBuilder.group({
      gateKeeperMustRegisterVisitorDocument: [paramGateKeeperMustRegisterVisitorDocument || false],
      gateKeeperMustRegisterResidence: [paramGateKeeperMustRegisterResidence || false]
    });

    this.gateKeeperMustRegisterVisitorDocument = this.accessControlConfigForm.get('gateKeeperMustRegisterVisitorDocument');
    this.gateKeeperMustRegisterResidence = this.accessControlConfigForm.get('gateKeeperMustRegisterResidence');
  }

  submit() {
    if (this.accessControlConfigForm.valid) {
      const accessLiberation = {
        ...this.condo.generalParams.accessLiberation,
        gateKeeperMustRegisterVisitorDocument: this.gateKeeperMustRegisterVisitorDocument.value || false,
        gateKeeperMustRegisterResidence: this.gateKeeperMustRegisterResidence.value || false
      };

      const generalParams = {
        ...this.condo.generalParams,
        accessLiberation
      };

      this.status.setAsProcessing();

      this.condoService
        .updateCondo(this.condo._id, { generalParams })
        .pipe(timeout(10000))
        .subscribe(
          () => {
            this.condo.generalParams.accessLiberation = accessLiberation;

            if (this.condo._id == this.utilService.getLocalCondo()._id) {
              this.utilService.saveLocalCondo(this.condo);
            }

            const updatedUser = this.utilService.getLocalUser();
            updatedUser.defaultCondo.generalParams.accessLiberation = accessLiberation;

            this.sessionService.setUser(updatedUser);

            this.toastr.success('Parâmetros de controle de acesso atualizados com sucesso.');

            this.status.setAsSuccess();

            this.bsModalRef.hide();
          },
          error => {
            this.status.setAsError();
            swal({
              type: 'error',
              text: 'Não foi possível atualizar os parâmetros, tente novamente.'
            });
          }
        );
    } else {
      this.accessControlConfigForm.markAllAsTouched();
    }
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
