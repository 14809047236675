/*
 Classe utilizada para representar os dados obitos da busca de CEP
 URL do serviço: http://viacep.com.br/ws/12310043/json/
 Exemplo de json de resposta
 {
 bairro: "Parque dos Príncipes",
 cep: "12310-043",
 complemento: "",
 gia: "3920",
 ibge: "3524402",
 localidade: "Jacareí",
 logradouro: "Rua Imperatriz Elizabeth da Áustria",
 uf: "SP",
 unidade: ""
 }
 */

// private _neighborhood: string ='';
// private _zipCode: number = null;
// private _complement: string = '';
// private _city: string = '';
// private _street: string = '';
// private _state: string = '';

export class Address {
  private _bairro: string = '';
  private _cep: number = null;
  private _complemento: string = '';
  private _localidade: string = '';
  private _logradouro: string = '';
  private _uf: string = '';

  constructor(addressJson) {
    if (addressJson) {
      this._bairro = addressJson.bairro;
      this._cep = addressJson.cep;
      this._complemento = addressJson.complemento;
      this._localidade = addressJson.localidade;
      this._logradouro = addressJson.logradouro;
      this._uf = addressJson.uf;
    }
  }

  hasData() {
    return !!(this.logradouro || this.bairro || this.localidade);
  }

  get bairro(): string {
    return this._bairro;
  }

  set bairro(value: string) {
    this._bairro = value;
  }

  get cep(): number {
    return this._cep;
  }

  set cep(value: number) {
    this._cep = value;
  }

  get complemento(): string {
    return this._complemento;
  }

  set complemento(value: string) {
    this._complemento = value;
  }

  get localidade(): string {
    return this._localidade;
  }

  set localidade(value: string) {
    this._localidade = value;
  }

  get logradouro(): string {
    return this._logradouro;
  }

  set logradouro(value: string) {
    this._logradouro = value;
  }

  get uf(): string {
    return this._uf;
  }

  set uf(value: string) {
    this._uf = value;
  }
}
