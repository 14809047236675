import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsbDeviceReaderModule } from '../usb-device-reader/usb-device-reader.module';
import { ComponentsModule } from '../../components/components.module';
import { ThemeModule } from '../../theme';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalCreateActuatorsRouteComponent } from './modal-create-actuators-route.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '../../pipe/pipes.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    UsbDeviceReaderModule,
    TypeaheadModule,
    ComponentsModule,
    PipesModule,
    TooltipModule,
    ThemeModule,
    AngularSvgIconModule,
    ButtonsModule
  ],
  declarations: [ModalCreateActuatorsRouteComponent]
})
export class ModalCreateActuatorsRouteModule {}
