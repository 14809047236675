import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { TableColumnDefinition, TableComponent } from '../../../components/table/table.component';

import { File } from '@api/model/file';
import { EcondosQuery } from '@api/model/query';
import { map, timeout } from 'rxjs/operators';
import { CachedRequestsService } from '../cached-requests.service';

@Component({
  selector: 'app-gate-residence-details-document',
  templateUrl: './gate-residence-details-documents.component.html'
})
export class GateResidenceDetailsDocumentsComponent implements OnInit {
  @Input() condo: Condo;
  @Input() residence: Residence;

  tableColumns: TableColumnDefinition<File>[] = [];

  @ViewChild('documentsTable', { static: true }) documentsTable: TableComponent;
  @ViewChild('nameCellTemplate', { static: true }) nameCellTemplate: TemplateRef<any>;
  status: 'LOADING' | 'ERROR' | 'SUCCESS';

  documents: File[] = [];

  constructor(private cachedRequestsService: CachedRequestsService) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        headerLabel: 'Nome',
        valueTemplate: this.nameCellTemplate
      }
    ];
    this.getData();
  }
  getData() {
    const econdosQuery: EcondosQuery = {
      $populate: [
        {
          path: 'documents',
          select: 'url thumbnail type format name'
        }
      ],
      $select: 'documents',
      $sort: 'createdAt'
    };

    this.status = 'LOADING';
    return this.cachedRequestsService
      .getResidenceById(this.condo._id, this.residence._id, econdosQuery)
      .pipe(
        timeout(15000),
        map(({ documents }) => documents.map(doc => new File(doc)))
      )
      .subscribe({
        next: documents => {
          this.documents = documents;
          this.status = 'SUCCESS';
        },
        error: err => (this.status = 'ERROR')
      });
  }
}
