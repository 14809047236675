import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Status } from 'app/api/model/status';
import { Condo } from '@api/model/condo';
import { ResidenceService } from '@api/service/residence.service';
import { timeout } from 'rxjs/operators';
import { UserService } from '@api/service/user.service';
import { UtilService } from '../../../services/util.service';
import { User } from '@api/model/user';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { capitalize } from '@api/util/util';
import { CONDO_REQUEST_ROLES, CondoRequest } from '@api/model/condo-request';
import { Residence } from '@api/model/interface/residence';

interface FormData {
  isCondoVoter: FormControl<boolean>;
  isCondoUser: FormControl<boolean>;
}
@Component({
  selector: 'modal-pending-condo-approval',
  templateUrl: 'modal-pending-condo-approval.html',
  styleUrls: ['modal-pending-condo-approval.scss']
})
export class ModalPendingCondoApprovalComponent implements OnInit {
  condo: Condo;
  user: User;
  condoRequests: CondoRequest[];
  selectedCondoRequest: CondoRequest;
  condoRequest: CondoRequest;
  residenceSearch: UntypedFormControl = new UntypedFormControl(undefined);
  imageSrc;

  residenceRequest: FormControl<Pick<Residence, '_id' | 'identification'>> = new FormControl(undefined);
  roleRequest: FormControl<keyof typeof CONDO_REQUEST_ROLES> = new FormControl('RESIDENT', [Validators.required]);

  residencesIdentification: { _id: string; identification: string }[] = [];
  residencesTypeAheadLimit = 0;

  hasResidenceRequester = false;

  residenceRequesterStatus: Status = new Status();

  voterCustomLabel: string;
  residentCustomLabel: string;
  form: FormGroup<FormData>;
  isCondoVoter: FormControl<boolean>;
  isCondoUser: FormControl<boolean>;
  isFormValid = true;

  constructor(
    public bsModalRef: BsModalRef,
    public userService: UserService,
    public utilService: UtilService,
    public toastService: ToastrService,
    public residenceService: ResidenceService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      isCondoVoter: [false],
      isCondoUser: [false]
    });
    this.isCondoUser = this.form.controls.isCondoUser;
    this.isCondoVoter = this.form.controls.isCondoVoter;
  }

  ngOnInit() {
    if (!!this.condoRequest) {
      this.selectedCondoRequest = this.condoRequest;
    } else {
      this.selectedCondoRequest = this.condoRequests[0];
      this.condo = this.selectedCondoRequest.condo;
    }

    this.imageSrc =
      this.condo && this.condo.pictures && this.condo.pictures.length > 0
        ? this.condo.pictures[0].url
        : 'assets/img/default-condo-picture.png';

    this.residenceRequest.setValue(this.selectedCondoRequest.residence);
    this.roleRequest.setValue(this.selectedCondoRequest.role);
    const condoRequestId = this.user?.residenceRequester?.condo || this.selectedCondoRequest?.residence?.condo;
    if (!!condoRequestId) {
      this.hasResidenceRequester = condoRequestId === this.condo.id;
    } else {
      this.hasResidenceRequester = false;
      this.getResidencesIdentification();
    }

    this.voterCustomLabel = capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário';
    this.residentCustomLabel = capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino';
  }

  getResidencesIdentification() {
    this.residenceService
      .getResidencesIdentification(this.condo._id)
      .pipe(timeout(10000))
      .subscribe(
        ({ count, residencesIdentification }) => {
          this.residencesIdentification = residencesIdentification;
          this.residencesTypeAheadLimit = residencesIdentification.length;
        },
        error => {
          console.log(error);
        }
      );
  }

  requestResidenceAndRoleInCondo() {
    if (this.residenceRequest.value && !this.isCondoVoter.value && !this.isCondoUser.value) {
      this.isFormValid = false;
      return;
    } else {
      this.isFormValid = true;

      if (this.isCondoUser.value && this.isCondoVoter.value) {
        this.roleRequest.setValue('VOTER_AND_RESIDENT');
      } else if (this.isCondoVoter.value) {
        this.roleRequest.setValue('VOTER');
      } else {
        this.roleRequest.setValue('RESIDENT');
      }

      if (this.residenceRequest.value && this.roleRequest.valid) {
        this.residenceRequesterStatus.setAsProcessing();

        const condoRequest: { residence: string; role: string } = {
          residence: this.residenceRequest.value._id,
          role: this.roleRequest.value
        };

        this.userService
          .updateCondoRequest(this.condo._id, this.selectedCondoRequest._id, condoRequest)
          .pipe(timeout(10000))
          .subscribe(
            () => {
              this.residenceRequesterStatus.setAsSuccess();
              this.hasResidenceRequester = true;
              this.toastService.success(
                `Sua solicitação de ${
                  this.condo?.customLabels?.residence?.singular || 'unidade'
                } foi encaminhada para a administração do(a) ${this.condo?.customLabels?.condo?.singular} ${
                  this.condo.name
                } e estará sujeita a análise`,
                'Solicitação realizada'
              );
            },
            error => {
              this.residenceRequesterStatus.setAsError();
              this.toastService.error(
                `Não foi possível encaminhar sua solicitação no(a) ${this.condo?.customLabels?.condo?.singular}. Tente novamente!`
              );
            }
          );
      } else {
        this.roleRequest.markAsTouched();
      }
    }
  }
  onSelectResidence(evt) {
    const residence: { _id: string; identification: string } = evt.item;
    this.residenceRequest.setValue(residence);
  }
}
