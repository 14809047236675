import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '../../api/model/condo';
import { Status } from '../../api/model/status';
import { CondoService } from '../../api/service/condo.service';
import { Residence } from '../../api/model/interface/residence';
import { ResidenceService } from '../../api/service/residence.service';
import { KeyValue } from '@angular/common';
import { ParkingSpot } from '@api/model/parking-spot';
import { ParkingSpotService } from '@api/service/parking-spot.service';

@Component({
  selector: 'modal-create-parking-spot',
  templateUrl: 'modal-create-parking-spot.html',
  styleUrls: ['./modal-create-parking-spot.scss']
})
export class ModalCreateParkingSpot implements OnInit {
  condo: Condo;
  parkingSpot: ParkingSpot;
  status: Status = new Status();
  residences: Residence[];

  form: UntypedFormGroup;
  name: AbstractControl;
  obs: AbstractControl;
  isVisitorSpot: AbstractControl;
  isAvailable: AbstractControl;
  residence: AbstractControl;

  onCreate: Function = () => {};

  onUpdate: Function = () => {};

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private condoService: CondoService,
    private parkingSpotService: ParkingSpotService,
    private residenceService: ResidenceService,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      obs: [''],
      isVisitorSpot: [false, Validators.required],
      isAvailable: [true, Validators.required],
      residence: [null]
    });

    this.name = this.form.get('name');
    this.obs = this.form.get('obs');
    this.isVisitorSpot = this.form.get('isVisitorSpot');
    this.isAvailable = this.form.get('isAvailable');
    this.residence = this.form.get('residence');
  }

  ngOnInit() {
    if (this.parkingSpot) {
      this.name.setValue(this.parkingSpot.name);
      this.obs.setValue(this.parkingSpot.obs);
      this.isVisitorSpot.setValue(this.parkingSpot.isVisitorSpot);
      this.isAvailable.setValue(this.parkingSpot.isAvailable);
      this.residence.setValue(this.parkingSpot.residence);
    }
  }

  selectResidence(residence) {
    this.residence.setValue(residence);
  }

  submit() {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const parkingSpot: any = {
        name: this.name.value,
        obs: this.obs.value,
        isVisitorSpot: this.isVisitorSpot.value,
        isAvailable: this.isAvailable.value
      };
      if (!this.isVisitorSpot.value) {
        parkingSpot.residence = this.residence.value._id;
      }

      if (this.parkingSpot) {
        this.update(parkingSpot);
      } else {
        this.create(parkingSpot);
      }
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha os campos obrigatórios.');
    }
  }

  create(parkingSpot) {
    this.parkingSpotService.create(this.condo._id, parkingSpot).subscribe(
      (res: any) => {
        const v = new ParkingSpot({ ...parkingSpot, _id: res._id });
        this.onCreate(v);
        this.bsModalRef.hide();
        this.toastr.success('Vaga cadastrada com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível cadastrar a vaga, tente novamente');
      }
    );
  }

  update(parkingSpot) {
    this.parkingSpotService.update(this.condo._id, this.parkingSpot._id, parkingSpot).subscribe(
      (res: any) => {
        if (parkingSpot.isVisitorSpot) {
          parkingSpot.identification = '';
        }

        const v = new ParkingSpot({
          ...this.parkingSpot,
          ...parkingSpot
        });
        this.onUpdate(v);
        this.bsModalRef.hide();
        this.toastr.success('Vaga atualizada com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível atualizar a vaga, tente novamente');
      }
    );
  }

  defaultSortFunction = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    return 0;
  };
}
