import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-skeleton',
  styleUrls: ['./skeleton.component.scss'],
  template: `<div
    class="skeleton"
    [style.height]="height"
    [style.width]="width"
    *ngFor="let i of lines"
    [style.margin-bottom]="numberOfLines > 1 ? spacing : '0'"
    [style.border-radius]="rounded ? '999999px' : '4px'"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonComponent implements OnInit, OnChanges {
  @Input() numberOfLines = 1;
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() spacing = '4px';
  @Input() rounded = false;
  @Input() randomWidth = false;

  lines = [];

  ngOnInit(): void {
    this.lines = Array(parseInt(this.numberOfLines.toString(), 10)).fill('');

    if (this.randomWidth) {
      this.width = `${this.generateRandomNumberBetween(3, 10) * 10}%`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.lines = Array(changes.numberOfLines?.currentValue || this.numberOfLines || 1).fill('');
  }

  generateRandomNumberBetween(min = 1, max = 10) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
