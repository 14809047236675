import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { Condo } from '@api/model/condo';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ResidenceService } from '@api/service/residence.service';
import { Receipt } from '@api/model/receipt';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { EcondosQuery } from '@api/model/query';
import { TableColumnDefinition, TableStatus } from '../table/table.component';
import { timeout } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-receipt-list',
  templateUrl: 'receipt-list.component.html',
  styleUrls: ['./receipt-list.component.scss']
})
export class ReceiptListComponent implements OnInit {
  @Input() residence: Residence;
  @Input() condo: Condo;

  @ViewChild('valueTemplate', { static: true }) valueTemplate: TemplateRef<any>;
  @ViewChild('codeTemplate', { static: true }) codeTemplate: TemplateRef<any>;

  receipts: Receipt[] = [];
  receiptsError: string;
  status: TableStatus;
  tableColumns: TableColumnDefinition<Receipt>[] = [];

  constructor(
    private residenceService: ResidenceService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private clipboard: ClipboardService,
    private bsModalRef: BsModalRef
  ) {}

  ngOnInit() {
    const getDueDateValue = (receipt: Receipt) => {
      const diff = Math.ceil((new Date(receipt.dueDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
      const status = diff < 0 ? 'Vencido' : diff === 0 ? 'Vence hoje' : `Vence em ${diff} dia(s)`;
      const text = `${moment(receipt.dueDate).format('DD/MM/YY')} - ${status}`;
      return text;
    };
    const getDueDateClass = (receipt: Receipt) => {
      const diff = moment(receipt.dueDate).diff(new Date(), 'days');
      return diff < 0 ? 'text-danger' : diff < 2 ? 'text-warning' : 'text-neutral';
    };

    this.tableColumns = [
      { columnId: 'receipt', headerLabel: 'Identificação', valueKey: 'receipt' },
      { columnId: 'dueDate', headerLabel: 'Vencimento', valueKey: 'dueDate', valueFn: getDueDateValue, cellClassFn: getDueDateClass },
      { columnId: 'value', headerLabel: 'Valor', valueKey: 'value', valueTemplate: this.valueTemplate },
      { columnId: 'boleto', headerLabel: 'Código', valueTemplate: this.codeTemplate },
      {
        columnId: 'actions',
        headerLabel: 'Ações',
        type: 'actions',
        actionsButtons: [
          {
            icon: 'fa-copy',
            title: 'Clique para copiar o código do boleto',
            handler: receipt => this.copyBarcode(receipt),
            show: receipt => !!!receipt.type
          },
          {
            icon: 'fa-file-pdf-o',
            title: 'Clique para visualizar o boleto em PDF',
            handler: receipt => this.showPdf(receipt),
            show: receipt => !!!receipt.type
          },
          {
            icon: 'fa-file-pdf-o',
            title: 'Acessar fatura online',
            handler: receipt => this.showLink(receipt),
            show: receipt => receipt.type === 'superlogica'
          }
        ]
      }
    ];
    this.getReceipts();
  }

  getReceipts(args = { page: 1 }) {
    this.status = 'LOADING';
    const query: EcondosQuery = {
      $page: args.page
    };
    this.residenceService
      .getReceipts(this.condo._id, this.residence._id, query)
      .pipe(timeout(10000))
      .subscribe({
        next: res => {
          this.receipts = res;
          this.status = 'SUCCESS';
          this.changeColumnVisibility();
        },
        error: err => {
          this.status = 'ERROR';
          this.receiptsError = err.originalError.message || 'Não foi possível buscar os dados. Verifique sua conexão e tente novamente';
        }
      });
  }

  copyBarcode(receipt: Receipt) {
    this.clipboard.copy(receipt.boleto);
    this.toastrService.success(`Código de barras ${receipt.boleto} copiado!`);
  }

  async showPdf(receipt: Receipt) {
    let pdfWindow = window.open('');
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(receipt.base64) + "'></iframe>"
    );
  }

  async showLink(receipt: Receipt) {
    window.open(receipt.link, '_blank');
  }

  changeColumnVisibility(): void {
    this.receipts.map(receipt => {
      if (receipt.link) {
        this.tableColumns.find(col => col.columnId === 'boleto').show = false;
      }
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
