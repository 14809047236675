import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Optional, Output, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html'
})
// http://prideparrot.com/blog/archive/2019/2/applying_validation_custom_form_component
export class AppInputComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  @Input() label = '';
  @Input() placeholder = '';

  @Input() leftIcon = '';
  @Input() rightIcon = '';
  @Input() showClearButton = false;
  @Input() type: 'date' | 'datetime-local' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' = 'text';
  @Input() validate = true;
  @Input() showErrorMessages = true;
  @Input() readonly = false;
  @Input() maxLength = '';
  @Input() autocomplete: 'on' | 'off' | string;
  @Input() useIconfy = false;
  _errorMessages: Record<string, string | (() => string)> = {};

  @Output() keyupEvent = new EventEmitter<KeyboardEvent>();

  value;

  touched = false;

  isDisabled = false;

  showAsInputGroup = false;

  @Input() set errorMessages(value: Record<string, string>) {
    this._errorMessages = { ...this._errorMessages, ...value };
  }

  public onChangeFn = (_: any) => {};

  public onTouchedFn = () => {};

  constructor(
    @Self() @Optional() public control: NgControl,
    private readonly elementRef: ElementRef
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const appendOrPrepend = this.elementRef.nativeElement?.querySelector('*[input-prepend], *[input-append]');
    const leftIcon = changes.leftIcon ? changes.leftIcon.currentValue : this.leftIcon;
    const rightIcon = changes.rightIcon ? changes.rightIcon.currentValue : this.rightIcon;
    const showClearButton = changes.showClearButton ? changes.showClearButton.currentValue : this.showClearButton;

    this.showAsInputGroup = !!(leftIcon || rightIcon || showClearButton || appendOrPrepend);
  }

  ngAfterViewInit(): void {
    this.showAsInputGroup = !!(
      this.elementRef.nativeElement?.querySelector('*[input-prepend], *[input-append]') ||
      this.leftIcon ||
      this.rightIcon ||
      this.showClearButton
    );

    this._errorMessages.required = this._errorMessages?.required || `${this.label} é obrigatório(a).`;
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  clearValue() {
    this.writeValue('');
    this.onChangeFn('');
  }

  // Angular form methods
  registerOnChange(onChange: any): void {
    this.onChangeFn = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouchedFn = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(text: string): void {
    this.value = text;
  }

  onChange() {
    this.onChangeFn(this.value);
    const errors = this.control?.errors;
    if (errors?.minlength) {
      this._errorMessages.minlength =
        this._errorMessages.minlength || `Comprimento mínimo é de ${errors.minlength.requiredLength} caracteres`;
    }
    if (errors?.maxlength) {
      this._errorMessages.maxlength =
        this._errorMessages.maxlength || `Comprimento máximo é de ${errors.maxlength.requiredLength} caracteres`;
    }
    if (errors?.min) {
      this._errorMessages.min = this._errorMessages.min || `O valor mínimo é ${errors.min.min}`;
    }
    if (errors?.max) {
      this._errorMessages.max = this._errorMessages.max || `O valor máximo é ${errors.max.max}`;
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouchedFn();
      this.touched = true;
    }
  }
}
