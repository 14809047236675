import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';

@Injectable({ providedIn: 'root' })
export class UseTermsService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}use-terms`;
  }

  getActive() {
    return this.http.get(`${this.endPoint}/active`);
  }
}
