import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { ReservationService } from '@api/service/reservation.service';
import { User } from '@api/model/user';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap, timeout } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';
import { LocalGroup } from '@api/model/local-group';
import { SessionService } from '@api/service/session.service';
import { Local } from '@api/model/local';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CreateLocalGroupPageComponent } from '../create-local-group.component/create-local-group.modal';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../components/table/table.component';
import { EMPTY, from, merge, noop, Observable } from 'rxjs';
import { Reservation } from '@api/model/reservation';

@Component({
  templateUrl: './local-groups.component.html',
  styleUrls: ['./local-groups.component.scss']
})
export class LocalGroupsPageComponent implements OnInit {
  @ViewChild('localGroupsTable', { static: false }) localGroupsTable: TableComponent;

  localGroups: LocalGroup[];
  filteredLocalGroups: LocalGroup[] = [];
  reservationLocals: Local[];

  loadStatus: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<LocalGroup>[] = [];

  shouldGetData = true;

  searchTerm = new FormControl('');

  user: User;
  condo: Condo;

  constructor(
    private reservationService: ReservationService,
    private sessionService: SessionService,
    private modalService: BsModalService
  ) {
    this.user = this.sessionService.userValue;
    this.condo = this.sessionService.condoValue;

    this.searchTerm = new FormControl();

    this.searchTerm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      const val = term.trim().toLowerCase();
      this.filteredLocalGroups = this.localGroups.filter(local => {
        return !val || (local.name && local.name.toLowerCase().indexOf(val) !== -1);
      });
    });
  }

  ngOnInit() {
    this.tableColumns = [
      {
        columnId: 'name',
        headerLabel: 'Nome do grupo',
        valueKey: 'name',
        sortable: false
      },
      {
        columnId: 'description',
        headerLabel: 'Descrição',
        valueKey: 'description',
        sortable: false
      },
      {
        type: 'actions',
        headerLabel: 'Ações',
        actionsButtons: [
          { icon: 'fa-pencil', title: 'Editar', handler: (group: LocalGroup) => this.createLocalGroup(group) },
          { icon: 'fa-trash', title: 'Remover', handler: (group: LocalGroup) => this.askToCancelAffectedReservations(group) }
        ]
      }
    ];
    this.getData();
  }

  getData() {
    this.loadStatus = 'LOADING';

    const query: EcondosQuery = {
      $select: 'id name description reservationLocals params',
      $populate: [
        {
          path: 'reservationLocals',
          select: 'name'
        }
      ],
      deleted: false
    };

    const localQuery: EcondosQuery = {
      $select: 'name',
      deleted: false,
      'params.isQueueEnabled': { $ne: true }
    };

    if (this.user.isUser()) {
      localQuery['params.residentCanReserve'] = true;
    }

    this.reservationService
      .getReservationLocals(this.condo._id, localQuery)
      .pipe(
        switchMap(({ locals }) => {
          this.reservationLocals = locals;
          return this.reservationService.getReservationLocalGroups(this.condo._id, query);
        })
      )
      .subscribe({
        next: ({ localGroups }) => {
          this.localGroups = localGroups.sort((a: LocalGroup, b: LocalGroup) => {
            return a.name.localeCompare(b.name);
          });
          this.filteredLocalGroups = this.localGroups;
          this.shouldGetData = false;
          this.loadStatus = 'SUCCESS';
          this.searchTerm.setValue('', { emitEvent: false });
        },
        error: () => {
          this.loadStatus = 'ERROR';
        }
      });
  }

  createLocalGroup(reservationLocalGroup: LocalGroup) {
    const localsWithGroupIds = this.localGroups.reduce((acc, curr) => {
      if (curr._id !== reservationLocalGroup?._id) {
        acc = [...acc, ...curr.reservationLocals.map(local => local._id || local)];
      }
      return acc;
    }, []);
    const availableLocals = this.reservationLocals.filter(local => !localsWithGroupIds.includes(local._id));
    if (!availableLocals?.length && !reservationLocalGroup) {
      swal({
        type: 'error',
        title: 'Não existem locais sem grupo',
        text: 'Não existe nenhum local de reserva sem grupo para adicionar ao grupo novo, primeiro crie locais novos ou remova de outros grupos.'
      });
      return;
    }
    const initialState = {
      availableLocals,
      reservationLocalGroup,
      callbacks: {
        success: (localGroup: LocalGroup) => {
          if (reservationLocalGroup) {
            this.localGroups = this.localGroups.map(local => (local._id === localGroup._id ? localGroup : local));
            this.filteredLocalGroups = this.filteredLocalGroups.map(local => (local._id === localGroup._id ? localGroup : local));
          } else {
            this.localGroups = [...this.localGroups, localGroup];
            this.filteredLocalGroups = [...this.filteredLocalGroups, localGroup];
          }
        }
      }
    };
    this.modalService.show(CreateLocalGroupPageComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  remove(localGroup: LocalGroup) {
    this.reservationService
      .deleteReservationLocalGroup(this.condo._id, localGroup._id)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          this.localGroups = this.localGroups.filter(local => local._id !== localGroup._id);
          this.filteredLocalGroups = this.filteredLocalGroups.filter(local => local._id !== localGroup._id);
        },
        error: () => {
          return Promise.reject('Não foi possível apagar este grupo, tente novamente...');
        }
      });
  }

  private getAllReservations(query: EcondosQuery = {}): Observable<Reservation[]> {
    return this.reservationService.getReservationsReport(this.condo._id, query).pipe(
      timeout(15000),
      mergeMap(([finalResult]) => merge(finalResult)),
      map(({ data }) => data as Reservation[])
    );
  }

  askToCancelAffectedReservations(localGroup: LocalGroup) {
    if (!localGroup?.reservationLocals?.length) {
      swal({
        type: 'question',
        text: `Você deseja excluir o grupo ${localGroup.name}`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).then(
        () => {
          this.remove(localGroup);
        },
        error => {
          console.log(error);
        }
      );
      return;
    }
    const query: EcondosQuery = {
      otherReservationLocals: { $in: localGroup.reservationLocals.map(local => local._id) }
    };
    this.getAllReservations(query)
      .pipe(
        switchMap(reservations => {
          if (!reservations.length) {
            this.remove(localGroup);
            return EMPTY;
          }
          return from(
            swal({
              type: 'warning',
              title: 'Existem reservas ativas para os locais do grupo',
              text:
                'Se você continuar sem cancelar as reservas ativas, o sistema poderá criar reservas com horários conflitantes!\n' +
                'Deseja cancelar as reservas ativas antes de prosseguir?\n' +
                'Digite "cancelar reservas ativas" para confirmar sua ação.',
              showCancelButton: true,
              input: 'text',
              inputPlaceholder: 'cancelar reservas ativas',
              confirmButtonText: 'Cancelar reservas ativas',
              confirmButtonColor: '#f53d3d',
              cancelButtonClass: 'btn-outline-danger',
              cancelButtonText: 'Continuar',
              reverseButtons: true,
              showLoaderOnConfirm: true,
              preConfirm: async input => {
                input = (input || '').toString().trim().toLowerCase();
                if (!input || input !== 'cancelar reservas ativas') {
                  return Promise.reject(`Digite "cancelar reservas ativas" para confirmar sua ação`);
                }
                return Promise.resolve(reservations);
              }
            })
          ).pipe(
            catchError(() => {
              swal({
                type: 'question',
                text: `Certifique-se que grupo ${localGroup.name} não possui nenhuma reserva ativa antes de apagar. Se houver, o sistema poderá criar reservas com horários conflitantes`,
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#32DB64',
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Voltar',
                reverseButtons: true
              }).then(
                () => {
                  this.remove(localGroup);
                },
                error => {
                  console.log(error);
                }
              );
              return EMPTY;
            })
          );
        }),
        filter((reservations: Reservation[]) => !!reservations?.length),
        switchMap((reservations: Reservation[]) => {
          return this.reservationService
            .bulkCancelReservationsWithGroup(
              this.condo._id,
              localGroup._id,
              reservations.map(reservation => reservation._id)
            )
            .pipe(tap(() => this.remove(localGroup)));
        })
      )
      .subscribe(noop);
  }
}
