import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactID } from '../../api/model/contact/contact.id';

@Component({
  selector: 'id-chip-component',
  styleUrls: ['chip.id.scss'],
  template: `
    <div class="chip">
      <span class="highlight" *ngIf="!id?.pictures?.length">
        <i class="fa fa-id-card"></i>
      </span>
      <img class="highlight isLink" [src]="id?.pictures[0].thumbnail" *ngIf="id?.pictures?.length" (click)="imageClick()" />
      <span>
        <span style="font-weight: bold"> {{ id.typeLabel }}: </span>
        {{ id.number }}
      </span>
      <span *ngIf="canClose" class="closebtn" (click)="close()">
        <i class="fa fa-times"></i>
      </span>
      <span *ngIf="canEdit" class="editbtn" (click)="edit()">
        <i class="fa fa-pencil"></i>
      </span>
    </div>
  `
})
export class ChipIdComponent {
  @Input()
  public id: ContactID = null;
  @Input()
  canClose = false;
  @Input()
  canEdit = false;
  @Output()
  onCloseClick = new EventEmitter();
  @Output()
  onEditClick = new EventEmitter();
  @Output()
  onImageClick = new EventEmitter();

  close() {
    this.onCloseClick.emit(this.id);
  }

  edit() {
    this.onEditClick.emit(this.id);
  }

  imageClick() {
    this.onImageClick.emit(this.id);
  }
}
