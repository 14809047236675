import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-button-select',
  template: `
    <div class="form-group" [class.has-error]="touched && !isValid" [class.has-success]="touched && isValid">
      <label *ngIf="label">{{ label }}</label>
      <ng-content select="[label-append]"></ng-content>
      <p *ngIf="message">{{ message }}</p>
      <div class="wrapper">
        <button
          [title]="optionTitle ? option[optionTitle] : ''"
          class="btn {{ selectedValuesObject[optionValue ? option[optionValue] : option] ? 'btn-primary' : 'btn-default' }}"
          *ngFor="let option of options"
          type="button"
          (click)="handleClick(option)"
          [disabled]="isDisabled">
          <i class="fa fa-{{ option[optionIcon] }} me-1" *ngIf="optionIcon && option[optionIcon]"></i>
          <span>{{ optionLabel ? option[optionLabel] : option }}</span>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['button-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ButtonSelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ButtonSelectComponent
    }
  ]
})
export class ButtonSelectComponent implements ControlValueAccessor, Validator {
  @Input()
  options: string[] | number[] | Object[];

  @Input()
  optionLabel: string;

  @Input()
  optionValue: string;

  @Input()
  optionTitle: string;

  @Input()
  optionIcon?: string;

  @Input()
  label = '';

  @Input()
  minSelect = 1;

  @Input()
  message = '';

  @Input()
  selectMode: 'single' | 'multiple' = 'multiple';

  selectedValues = [];
  selectedValuesObject = {};

  touched = false;

  isDisabled = false;

  isValid = true;

  onChange = (_values: any[]) => {};

  onTouched = () => {};

  handleClick(value) {
    if (!this.isDisabled) {
      this.markAsTouched();
      const values = this.selectedValues || [];
      const isSelected = values?.some(v => (this.optionValue ? value[this.optionValue] === v : value === v));
      if (isSelected) {
        if (this.selectMode === 'multiple') {
          this.selectedValues = values.filter(v => (this.optionValue ? value[this.optionValue] !== v : value !== v)).sort();
        } else {
          this.selectedValues = [];
        }
      } else {
        if (this.selectMode === 'multiple') {
          this.selectedValues = [...values, this.optionValue ? value[this.optionValue] : value].sort();
        } else {
          this.selectedValues = [this.optionValue ? value[this.optionValue] : value];
        }
      }
      this.setValuesObject(this.selectedValues);
      this.onChange(this.selectedValues);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(values: any[]): void {
    this.selectedValues = values;
    this.setValuesObject(values);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const values = control.value;
    if (values?.length < this.minSelect) {
      this.isValid = false;
      return {
        mustHaveValue: {
          values
        }
      };
    } else {
      this.isValid = true;
      return null;
    }
  }

  setValuesObject(value = []) {
    this.selectedValuesObject = (value || []).reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }
}
