import { CanActivate, Router } from '@angular/router';
import { UtilService } from '../services/util.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AccessControlModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isAccessControlDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class AccessLiberationModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isAccessLiberationDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class DeliveriesModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isDeliveriesDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class KeysModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isKeysDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class VotingModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isVotingDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class ReservationsModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isReservationsDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class DocumentsModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isDocumentsDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class ClassificadosModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isClassificadosDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class MaintenanceModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isMaintenanceDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class MaintenanceTicketsModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isMaintenanceTicketsDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class LostAndFoundsModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isLostAndFoundsDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class CondoRatingModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isCondoRatingDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class CondoFaqModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isCondoFaqDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class PetsModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isPetsDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class DependentsModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    if (!condo || condo.isDependentsDisabled()) {
      this.router.navigate(['/notFound']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class AssetManagementModuleGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}

  canActivate() {
    const condo = this.utilService.getLocalCondo();
    const user = this.utilService.getLocalUser();

    const isAssetManagementEnabled = condo?.isAssetManagementEnabled();
    const hasUserPermission = user && (user.isJanitor() || user.isGatekeeper() || user.isAdmin() || user.isOwner());

    if (!isAssetManagementEnabled || !hasUserPermission) {
      this.router.navigate(['/notFound']);
      return false;
    }

    return true;
  }
}
