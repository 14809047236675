import { Condo } from './condo';
import { BuildSipAccount, SipAccount } from './sip-account';
import { User } from './user';
import { VirtualKey } from './virtual-key';

export enum EXTENSIONS_TYPE {
  CONDO = 'CONDO'
  // RESIDENCE = 'RESIDENCE'
}

export enum EXTENSIONS_TYPE_LABEL {
  CONDO = 'Condomínio'
  // RESIDENCE = 'Unidade'
}

export interface Extension {
  _id: string;
  condo: Condo;
  sipAccount: SipAccount;
  name: string;
  number: string;
  type: EXTENSIONS_TYPE;
  type_label: EXTENSIONS_TYPE_LABEL;
  virtualKeys: VirtualKey[];
  createdBy?: User;
  createdAt?: string;
  updatedAt?: string;
}

export type ExtensionOnlyWithId = Pick<Extension, '_id'>;

export const BuildExtension = (extension): ExtensionOnlyWithId | Extension => {
  if (typeof extension == 'string') {
    return {
      _id: extension
    };
  }

  if (extension) {
    return {
      _id: extension._id || '',
      condo: extension.condo ? new Condo(extension.condo) : ({} as Condo),
      sipAccount: extension.sipAccount ? (BuildSipAccount(extension.sipAccount) as SipAccount) : ({} as SipAccount),
      name: extension.name || '',
      number: extension.number || '',
      type: extension.type ? EXTENSIONS_TYPE[extension.type] : null,
      type_label: extension.type ? EXTENSIONS_TYPE_LABEL[extension.type] : null,
      virtualKeys: extension.virtualKeys || [],
      createdBy: extension.createdBy ? new User(extension.createdBy) : ({} as User),
      createdAt: extension.createdAt || null,
      updatedAt: extension.updatedAt || null
    };
  }
};
