import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { Occurrence } from '@api/model/interface/occurrence';

interface Option {
  icon: string;
  label: string;
  filterOption: string;
}

interface OccurrenceTypes {
  ADVICE?: 'ADVICE';
  PRIVATE?: 'PRIVATE';
  VOTING?: 'VOTING';
  GATE?: 'GATE';
}

@Component({
  selector: 'app-feed-filter-component',
  templateUrl: 'feed-filter-component.html',
  styleUrls: ['feed-filter-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedFilterComponent implements OnChanges {
  @Input() user: User;

  @Input() condo: Condo;

  @Input() selectedFilter: string;

  @Input() searchIsActive: boolean;

  @Input() occurrenceTypes: OccurrenceTypes = {};

  @Output() onFilterChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() onSearchClick: EventEmitter<string> = new EventEmitter<string>();

  public options: Option[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && changes.condo) {
      this.options = [];

      if (this.occurrenceTypes?.ADVICE) {
        this.options.push({ icon: 'fa-newspaper-o', label: 'Avisos', filterOption: Occurrence.ADVICE_TYPE });
      }
      if (this.occurrenceTypes?.VOTING) {
        this.options.push({ icon: 'fa-check-square-o', label: 'Enquetes', filterOption: Occurrence.VOTING_TYPE });
      }
      if (this.occurrenceTypes?.PRIVATE) {
        this.options.push({ icon: 'fa-book', label: 'Ocorrências', filterOption: Occurrence.PRIVATE_TYPE });
      }
      if (this.occurrenceTypes?.GATE) {
        this.options.push({ icon: 'fa-building-o', label: 'Portaria', filterOption: Occurrence.GATE_TYPE });
      }
    }
  }
}
