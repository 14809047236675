import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { map, takeUntil } from 'rxjs/operators';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import * as moment from 'moment';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';
import { Occurrence } from '@api/model/interface/occurrence';
import { ContactID } from '@api/model/contact/contact.id';
import { CondoContact } from '@api/model/contact/condo.contact';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../components/table/table.component';
import { AccessInfo } from '../../gate/access.control/access.control';
import { ParamsService } from '@api/service/params.service';
import { CachedRequestsService } from '../cached-requests.service';
import { Access } from '@api/model/access';
import { Contact } from '@api/model/interface/contact';
import { Subject } from 'rxjs';
import { ModalNewCondoContactComponent } from '../../gate/access.control/new.condo.contact/new.condo.contact.modal';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';

interface AutorizationData {
  _id: string;
  name: string;
  ids: ContactID[];
  description: string;
  shouldCall: boolean;
  startTime: string;
  endTime: string;
  isValidTime: boolean;
  occurrence: GateOccurrence;
  condoContact: CondoContact;
}

@Component({
  selector: 'app-gate-residence-details-autorized-visitors',
  templateUrl: './gate-residence-details-autorized-visitors.component.html'
})
export class GateResidenceDetailsAutorizedVisitorsComponent implements OnInit {
  @Input() residence: Residence;
  @Input() condo: Condo;

  @Output() navigateToAccessControl = new EventEmitter<void>();

  @ViewChild('authorizedVisitorsTable', { static: true }) authorizedVisitorsTable: TableComponent;
  @ViewChild('personNameCellTemplate', { static: true }) personNameCellTemplate: TemplateRef<any>;
  @ViewChild('personIdsCellTemplate', { static: true }) personIdsCellTemplate: TemplateRef<any>;
  @ViewChild('actionButtons', { static: true }) actionButtons: TemplateRef<any>;
  @ViewChild('periodTemplate', { static: true }) periodTemplate: TemplateRef<any>;
  @ViewChild('shouldCallTemplate', { static: true }) shouldCallTemplate: TemplateRef<any>;

  permissions: AutorizationData[] = [];
  count: number = 0;

  tableColumns: TableColumnDefinition<AutorizationData>[] = [];
  status: TableStatus = 'LOADING';
  paginate = false;

  types = ContactID.TYPES_LABEL;

  constructor(
    private paramsService: ParamsService,
    private cachedRequestsService: CachedRequestsService,
    private toastService: ToastrService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        headerLabel: 'Nome',
        columnId: 'permissions',
        sortable: true,
        valueTemplate: this.personNameCellTemplate
      },
      {
        headerLabel: 'Documentos',
        valueTemplate: this.personIdsCellTemplate
      },
      {
        headerLabel: 'Período',
        valueTemplate: this.periodTemplate
      },
      {
        headerLabel: 'Interfonar',
        valueTemplate: this.shouldCallTemplate
      },
      {
        headerLabel: 'Ações',
        valueTemplate: this.actionButtons
      }
    ];

    this.getData({ page: 0 });
  }

  getData({ page = 0 }: { page: number }) {
    const weekDays = { 0: 'SUNDAY', 1: 'MONDAY', 2: 'TUESDAY', 3: 'WEDNESDAY', 4: 'THURSDAY', 5: 'FRIDAY', 6: 'SATURDAY' };
    const today = weekDays[moment().day()];

    const { pageSize, sortOrder, sortedColumn } = this.authorizedVisitorsTable.getCurrentState();

    const permissionsQuery: EcondosQuery = {
      $page: page,
      $limit: pageSize,
      $and: [
        { type: Occurrence.GATE_TYPE },
        { subType: GateOccurrence.ALLOW_ACCESS_TYPE },
        { startDate: { $lte: moment().startOf('day').toISOString() } },
        { endDate: { $gte: moment().endOf('day').toISOString() } },
        { residence: this.residence._id },
        { status: Occurrence.STATUS.OPENED },
        { 'daysAllowed.day': today }
      ],
      $populate: [
        {
          path: 'createdBy',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail type name format' }
        },
        {
          path: 'userContacts',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail type name format' }
        },
        {
          path: 'condoContacts',
          select:
            'firstName lastName condoVehicles picture ids phones type condo company service banned banReason bannedAt bannedBy externalId obs',
          populate: [
            { path: 'picture', select: 'url thumbnail type name format' },
            { path: 'condoVehicles', select: 'plate model type brand color' },
            { path: 'ids.pictures', select: 'url thumbnail type format name' },
            { path: 'bannedBy', select: 'firstName lastName' }
          ]
        },
        {
          path: 'condoVehicles',
          select: 'plate model type brand color',
          populate: { path: 'picture', select: 'url thumbnail type name format' }
        },
        { path: 'pictures', select: 'url thumbnail type name format' }
      ]
    };

    if (typeof sortedColumn === 'string') {
      permissionsQuery.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    this.cachedRequestsService
      .getOccurrences(this.condo._id, permissionsQuery)
      .pipe(
        map(res => ({
          count: res.count,
          permissions: res.occurrences
            .map((occur: GateOccurrence) => {
              const condoContacts = occur.condoContacts;
              const todayPermission = occur.daysAllowed.find(d => d.day === today);
              const [startHour, startMinute] = todayPermission.startTime.split(':');
              const [endHour, endMinute] = todayPermission.endTime.split(':');
              const startDate = moment().hour(parseInt(startHour, 10)).minute(parseInt(startMinute, 10));
              const endDate = moment().hour(parseInt(endHour, 10)).minute(parseInt(endMinute, 10));
              const isValidTime = moment().isBetween(startDate, endDate);
              const data = [];
              occur.residence = this.residence;
              data.push(
                ...condoContacts.map(contact => ({
                  _id: contact._id,
                  name: contact.firstName + ' ' + contact.lastName,
                  ids: contact.ids,
                  description: occur.description,
                  shouldCall: occur.shouldCall,
                  startTime: todayPermission.startTime,
                  endTime: todayPermission.endTime,
                  isValidTime,
                  occurrence: <GateOccurrence>occur,
                  condoContact: contact
                }))
              );
              if (occur.visitorIdentification) {
                data.push({
                  name: Contact.TYPES_LABEL[occur.visitorIdentification],
                  ids: [],
                  description: occur.description,
                  shouldCall: occur.shouldCall,
                  startTime: todayPermission.startTime,
                  endTime: todayPermission.endTime,
                  isValidTime,
                  occurrence: <GateOccurrence>occur,
                  person: Contact.TYPES_LABEL[occur.visitorIdentification]
                });
              }
              return data;
            })
            .flat()
            .sort((d1, d2) => (d1.person?.name || '').localeCompare(d2.person?.name || ''))
        }))
      )
      .subscribe({
        next: ({ permissions, count }) => {
          this.paginate = count >= this.authorizedVisitorsTable.getCurrentState()?.pageSize;
          this.count = count;
          this.permissions = permissions;
          this.status = 'SUCCESS';

          this.permissions.sort((a, b) => {
            const comparison = a.name.localeCompare(b.name);
            return sortOrder === 'asc' ? comparison : -comparison;
          });
        },
        error: () => {
          this.status = 'ERROR';
        }
      });
  }

  async registerAccessFromPermission(authorization: AutorizationData) {
    const access = new Access();
    access.condoContact = authorization.condoContact;
    access.source = Access.SOURCES.OCCURRENCE;
    access.status = Access.STATUS.PENDING;
    access.approvedBy = authorization.occurrence.createdBy;
    access.residence = authorization.occurrence.residence || this.residence;
    access.occurrence = authorization.occurrence;

    const canCreateCondoContact = !access.condoContact && !!authorization.occurrence?.visitorIdentification;
    if (canCreateCondoContact) {
      const newContact = new Promise<CondoContact>((resolve, reject) => {
        const unsubscribe$: Subject<boolean> = new Subject<boolean>();
        const initialState: Partial<ModalNewCondoContactComponent> = {
          condo: this.condo,
          newContactInfo: { type: authorization.occurrence.visitorIdentification, service: authorization.occurrence?.description },
          callback: (contact: CondoContact) => {
            unsubscribe$.next(true);
            unsubscribe$.complete();
            resolve(contact);
          }
        };
        const modal = this.modalService.show(ModalNewCondoContactComponent, {
          initialState,
          class: 'modal-xl',
          ignoreBackdropClick: true
        });
        modal.onHide.pipe(takeUntil(unsubscribe$)).subscribe(() => {
          resolve(null);
          unsubscribe$.next(true);
          unsubscribe$.complete();
        });
      });
      access.condoContact = await newContact;
    }

    if (!access.condoContact) {
      this.toastService.error(
        `Nenhum ${this.condo.customLabels?.visitor?.singular} foi encontrado para registrar o acesso. Por favor, cadastre um ${this.condo.customLabels?.visitor?.singular}.`
      );
      return;
    }

    access.type = access.condoContact.type;
    const accessInfo: AccessInfo = { access };
    this.paramsService.add('NEW_ACCESS', accessInfo);
    this.navigateToAccessControl.emit();
  }

  registerAccessFromNewContact(contactInfo, residence: Residence) {
    const accessInfo: AccessInfo = { nameOrId: contactInfo, residence };
    this.paramsService.add('NEW_ACCESS', accessInfo);
    this.navigateToAccessControl.emit();
  }

  registerAccessOnContactSelect(condoContact: CondoContact, residence: Residence) {
    const accessInfo: AccessInfo = { contact: condoContact, residence };
    this.paramsService.add('NEW_ACCESS', accessInfo);
    this.navigateToAccessControl.emit();
  }
}
