import { Residence } from '../interface/residence';

/**
 * Created by Rafael on 07/12/2016.
 */

export class ApartmentResidence extends Residence {
  public block;

  constructor(residence) {
    super(residence);
    this.block = residence.block;
  }
}
