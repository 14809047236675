import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const url = req.url || '';

    if (url.includes('econdos.com.br/api/v1') || url.includes('localhost')) {
      const httpRequest = req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache').set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      });
      return next.handle(httpRequest);
    } else {
      return next.handle(req); // do nothing
    }
  }
}
