import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Condo } from '../../../api/model/condo';
import { UtilService } from '../../../services/util.service';
import { CommentService } from '../../../api/service/comment.service';
import { OccurrenceService } from '../../../api/service/occurrence.service';
import { User } from '../../../api/model/user';
import { catchError, exhaustMap, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { of, Subject } from 'rxjs';
import { Occurrence } from '../../../api/model/interface/occurrence';
import { EcondosQuery } from '@api/model/query';

type Status = 'LOADING' | 'ERROR' | 'SUCCESS';

@Component({
  selector: 'app-feed-likers',
  templateUrl: './feed-likers.component.html',
  styleUrls: ['./feed-likers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedLikersComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  occurrence: Occurrence;

  @Input()
  condo: Condo;

  @Input()
  user: User;

  @Output()
  like: EventEmitter<Occurrence> = new EventEmitter<Occurrence>();

  @Output()
  dislike: EventEmitter<Occurrence> = new EventEmitter<Occurrence>();

  likeSubject = new Subject();
  likeSubscritpion$;

  likers;
  isLiked = false;

  likersDownloadStatus: Status = null;
  areLikersDownloaded = false;

  constructor(
    private utilService: UtilService,
    private commentService: CommentService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private occurrenceService: OccurrenceService
  ) {}

  ngOnInit() {
    this.likeSubscritpion$ = this.likeSubject
      .pipe(
        exhaustMap(() => {
          this.areLikersDownloaded = false;
          const index = this.occurrence.likers.findIndex(liker => this.user.id == liker._id);
          const hasLiked = index > -1;
          let subscription;
          if (hasLiked) {
            this.occurrence.likers.splice(index, 1);
            this.isLiked = false;
            this.likers = this.occurrence.likers || [];
            subscription = this.occurrenceService.dislikeOcurrence(this.condo?._id, this.occurrence?._id).pipe(
              catchError(() => {
                this.occurrence.likers.splice(index, 0, this.user);
                this.isLiked = true;
                this.likers = this.occurrence.likers || [];
                this.cdr.detectChanges();
                return of('');
              })
            );
          } else {
            this.occurrence.likers.unshift(this.user);
            this.isLiked = true;
            this.likers = this.occurrence.likers || [];
            subscription = this.occurrenceService.likeOcurrence(this.condo?._id, this.occurrence?._id).pipe(
              catchError(() => {
                this.occurrence.likers?.shift();
                this.isLiked = false;
                this.likers = this.occurrence.likers || [];
                this.cdr.detectChanges();
                return of('');
              })
            );
          }
          return subscription.pipe();
        })
      )
      .subscribe(() => {
        if (this.isLiked) {
          this.like.emit(this.occurrence);
        } else {
          this.dislike.emit(this.occurrence);
        }
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.likeSubscritpion$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.likers = changes.occurrence?.currentValue?.likers || [];
    this.isLiked = changes.occurrence?.currentValue?.likers.find(liker => this.user?._id == liker._id);
  }

  getLikers() {
    if (this.areLikersDownloaded) {
      return;
    }

    this.likersDownloadStatus = 'LOADING';

    const query: EcondosQuery = {
      $select: 'title description status residence createdAt type viewers.residences likers',
      $populate: [{ path: 'likers', select: 'firstName lastName' }],
      _id: this.occurrence._id
    };

    this.occurrenceService
      .getOccurrences(this.condo._id, query)
      .pipe(timeout(10_000))
      .subscribe(
        response => {
          const [occurrence] = response.occurrences;
          this.likers = occurrence?.likers;
          this.isLiked = occurrence?.likers.find(liker => this.user?._id == liker._id);
          this.likersDownloadStatus = 'SUCCESS';
          this.areLikersDownloaded = true;
          this.cdr.detectChanges();
        },
        error => {
          this.likersDownloadStatus = 'ERROR';
          this.cdr.detectChanges();
        }
      );
  }
}
