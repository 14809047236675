import { File } from './file';

export interface LocalExtraFee {
  name: string;
  value: number;
}

export class Local {
  static STATUS_AVAILABLE = 'AVAILABLE';
  static STATUS_DISABLED = 'DISABLED';

  public _id: string;
  private _name: string;
  private _rules: string;
  private _rulesAttachments: any;
  private _description: string;
  private _value: number;
  private _periods: any;
  private _status: string;
  private _pictures: any;
  private _deleted: boolean;
  private _deletedBy: any;
  private _deletedAt: any;
  private _createdBy: any;
  private _updatedBy: any;
  private _penalties: Array<any>;
  public penaltyWarningText: string;
  private _params: any;
  private _capacity: number;
  private _extraFees: LocalExtraFee[];
  checklist: Array<string>;

  constructor(local) {
    this._id = local._id || '';
    this._name = local.name || '';
    this._rules = local.rules || '';
    this._description = local.description || '';
    this._value = local.value || 0;
    this._periods = local.periods || [];
    this._status = local.status || '';
    this._pictures = local && local.pictures ? local.pictures.map(pic => new File(pic)) : [];
    this._rulesAttachments = local && local.rulesAttachments ? local.rulesAttachments.map(att => new File(att)) : [];
    this._deleted = local.deleted || false;
    this._deletedBy = local.deletedBy || {};
    this._deletedAt = local.deletedAt || {};
    this._createdBy = local.createdBy || {};
    this._updatedBy = local.updatedBy || {};
    this._params = local.params || {};
    this._capacity = local.capacity;
    this._penalties = local.penalties || [];
    this.penaltyWarningText = local.penaltyWarningText || '';
    this._extraFees = local.extraFees || [];
    this.checklist = local.checklist || [];
  }

  isAvailable() {
    return !this.deleted && this.status == Local.STATUS_AVAILABLE;
  }

  isDisabled() {
    return !this.deleted && this.status == Local.STATUS_DISABLED;
  }

  enableLocal() {
    this.status = Local.STATUS_AVAILABLE;
  }

  disableLocal() {
    this.status = Local.STATUS_DISABLED;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get rules(): string {
    return this._rules;
  }

  set rules(value: string) {
    this._rules = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
  }

  get periods(): any {
    return this._periods;
  }

  set periods(value: any) {
    this._periods = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  get pictures(): any {
    return this._pictures;
  }

  set pictures(value: any) {
    this._pictures = value;
  }

  get rulesAttachments(): any {
    return this._rulesAttachments;
  }

  set rulesAttachments(value: any) {
    this._rulesAttachments = value;
  }

  get deleted(): boolean {
    return this._deleted;
  }

  set deleted(value: boolean) {
    this._deleted = value;
  }

  get deletedBy(): any {
    return this._deletedBy;
  }

  set deletedBy(value: any) {
    this._deletedBy = value;
  }

  get deletedAt(): any {
    return this._deletedAt;
  }

  set deletedAt(value: any) {
    this._deletedAt = value;
  }

  get createdBy(): any {
    return this._createdBy;
  }

  set createdBy(value: any) {
    this._createdBy = value;
  }

  get updatedBy(): any {
    return this._updatedBy;
  }

  set updatedBy(value: any) {
    this._updatedBy = value;
  }

  get params(): any {
    return this._params;
  }

  set params(value: any) {
    this._params = value;
  }

  get capacity(): number {
    return this._capacity;
  }

  set capacity(value: number) {
    this._capacity = value;
  }

  get penalties(): Array<any> {
    return this._penalties;
  }

  set penalties(value: Array<any>) {
    this._penalties = value;
  }

  get extraFees(): LocalExtraFee[] {
    return this._extraFees;
  }

  set extraFees(value: LocalExtraFee[]) {
    this._extraFees = value;
  }
}
