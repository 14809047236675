import { Component } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { timeout } from 'rxjs/operators';
import { capitalize } from '@api/util/util';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '@api/service/session.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

type ParamConfig = {
  icon?: string;
  label: string;
  description?: string;
  true?: any;
  false?: any;
  value?: any;
  toggleValue?: any;
  originalValue?: any;
  paramName?: string;
  isSubSectionTitle?: boolean;
};

type ParamsSection = {
  title: string;
  icon: string;
  params?: Record<string, ParamConfig>;
};

@Component({
  selector: 'app-condo-config',
  templateUrl: 'condo.config.html'
})
export class CondoConfigComponent {
  public condo = this.sessionService.condoValue;
  public user = this.sessionService.userValue;

  public isSubmitting = false;

  public CONDO_SECTION_PARAMS: Record<string, ParamsSection> = {
    cadastro: {
      title: 'Cadastro',
      icon: 'fa:plus-square',
      params: {
        NEW_USERS_NEED_REQUEST: {
          icon: 'fa:check',
          label: 'Precisa de aprovação',
          description: `Quando ativado todos novos ${this.condo?.customLabels?.resident?.plural} precisarão ser aceitos pela administração do(a) ${this.condo?.customLabels?.condo?.singular}.`,
          true: 'NEED_REQUEST',
          false: 'NO_NEED_REQUEST',
          value: '',
          toggleValue: '',
          originalValue: '',
          paramName: 'newUsers'
        },
        SEARCHABLE: {
          icon: 'fa:search',
          label: 'Busca para novos usuários',
          description: `Habilita ou desabilita a visualização do(a) ${this.condo?.customLabels?.condo?.singular} quando novos usuários se cadastram.`,
          true: Condo.PARAMS.SEARCHABLE.ENABLED,
          false: Condo.PARAMS.SEARCHABLE.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.SEARCHABLE.KEY
        }
      }
    },
    unidades: {
      title: 'Unidades',
      icon: 'fa:home',
      params: {
        RESIDENCE_CREATION: {
          icon: 'fa:plus',
          label: `Liberar a criação de ${this.condo?.customLabels?.residence?.singular || 'unidade'}`,
          description: `Quando ativado, a criação de ${
            this.condo?.customLabels?.residence?.singular || 'unidade'
          } será livre para qualquer usuário.`,
          true: 'PERMITTED',
          false: 'PROHIBITED',
          value: '',
          toggleValue: '',
          originalValue: '',
          paramName: 'residenceCreation'
        },
        RESIDENCE_HARDWARE_EVENTS_HISTORY: {
          icon: 'fa:history',
          label: `Histórico de acesso de moradores de ${this.condo?.customLabels?.residence?.singular || 'unidade'}`,
          description: `Habilita ou desabilita o histórico de acesso de moradores de ${
            this.condo?.customLabels?.residence?.singular || 'unidade'
          }.`,
          true: Condo.PARAMS.RESIDENCE_HARDWARE_EVENTS_HISTORY.ENABLED,
          false: Condo.PARAMS.RESIDENCE_HARDWARE_EVENTS_HISTORY.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.RESIDENCE_HARDWARE_EVENTS_HISTORY.KEY
        },
        DEPENDENT_ACCESS_NOTIFICATION: {
          icon: 'fa:exclamation',
          label: `Notificação de entrada de ${this.condo?.customLabels?.dependent?.singular || 'dependente'}`,
          description: `Habilita ou desabilita o envio de notificações para os ${
            this.condo?.customLabels?.resident?.plural || 'moradores'
          } da ${this.condo?.customLabels?.residence?.singular || 'unidade'} quando um ${
            this.condo?.customLabels?.dependent?.singular || 'dependente'
          } entra ou sai do ${this.condo?.customLabels?.condo?.singular}.`,
          true: Condo.PARAMS.DEPENDENTS_ACCESS_NOTIFICATION.ENABLED,
          false: Condo.PARAMS.DEPENDENTS_ACCESS_NOTIFICATION.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.DEPENDENTS_ACCESS_NOTIFICATION.KEY
        }
      }
    },
    feed: {
      title: 'Feed',
      icon: 'fa:list-alt',
      params: {
        VOTING: {
          icon: 'fa:check-square',
          label: 'Enquetes',
          description: 'Habilita ou desabilita o módulo de enquetes.',
          true: Condo.PARAMS.VOTING.ENABLED,
          false: Condo.PARAMS.VOTING.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.VOTING.KEY
        },
        ADVICES: {
          icon: 'fa:newspaper-o',
          label: 'Avisos',
          description: `Habilita ou desabilita o módulo de avisos do(a) ${this.condo?.customLabels?.condo?.singular || 'condomínio'}.`,
          true: Condo.PARAMS.ADVICES.ENABLED,
          false: Condo.PARAMS.ADVICES.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.ADVICES.KEY
        },
        COMMENT_MODERATION: {
          icon: 'fa:comments',
          label: 'Liberar comentários',
          description: 'Quando ativado os comentários serão livres para qualquer usuário.',
          true: 'NONE',
          false: 'REQUIRED',
          value: '',
          toggleValue: '',
          originalValue: '',
          paramName: 'commentsModeration'
        },
        occurrenceSection: { label: 'Ocorrências', isSubSectionTitle: true },
        OCCURRENCES: {
          icon: 'fa:book',
          label: 'Ocorrências',
          description: `Habilita ou desabilita o módulo de ocorrências do(a) ${this.condo?.customLabels?.condo?.singular || 'condomínio'}.`,
          true: Condo.PARAMS.OCCURRENCES.ENABLED,
          false: Condo.PARAMS.OCCURRENCES.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.OCCURRENCES.KEY
        },
        // PUBLIC_OCCURRENCE_CREATION: {
        //   label: 'Liberar criação de ocorrência pública',
        //   description: 'Quando ativado a visualização e criação de ocorrências públicas estarão liberadas para qualquer usuário do condomínio',
        //   true: 'ANYONE',
        //   false: 'ONLY_ADMINS',
        //   value: '',
        //   toggleValue: '',
        //   originalValue: '',
        //   paramName: 'publicOccurrencesCreation'
        // },
        PRIVATE_OCCURRENCE_CREATION: {
          icon: 'fa:users',
          label: 'Liberar criação de ocorrência entre ' + (this.condo?.customLabels?.resident?.plural || 'condôminos') + '',
          description: `Quando ativado, ${
            this.condo?.customLabels?.resident?.plural || 'condôminos'
          } podem criar ocorrências privadas para outros ${
            this.condo?.customLabels?.resident?.plural || 'condôminos'
          }, quando desativado apenas ${
            this.condo?.customLabels?.admin?.plural || 'administradores'
          } podem criar ocorrências privadas para ${this.condo?.customLabels?.resident?.plural || 'condôminos'}.`,
          true: 'ANYONE',
          false: 'ONLY_ADMINS',
          value: '',
          toggleValue: '',
          originalValue: '',
          paramName: 'privateOccurrencesCreation'
        },
        RESIDENTS_OCCURRENCES_CREATION: {
          icon: 'fa:user-plus',
          label: `Liberar criação de ocorrências por ${this.condo?.customLabels?.resident?.plural || 'condôminos'}`,
          description: `Quando ativado, ${this.condo?.customLabels?.resident?.plural || 'condôminos'} podem criar ocorrências.`,
          true: 'ENABLED',
          false: 'DISABLED',
          value: '',
          toggleValue: '',
          originalValue: '',
          paramName: 'residentsOccurrencesCreation'
        }
      }
    },
    recursos: {
      title: 'Recursos',
      icon: 'fa:cogs',
      params: {
        DEVICE_ACCESS_REQUEST: {
          icon: 'fa:microchip',
          label: 'Solicitações de dispositivos de acesso',
          description: 'Habilita ou desabilita o módulo de solicitações de dispositivos de acesso.',
          true: Condo.PARAMS.DEVICE_ACCESS_REQUEST.ENABLED,
          false: Condo.PARAMS.DEVICE_ACCESS_REQUEST.DISABLED,
          value: '',
          toggleValue: '',
          originalValue: '',
          paramName: Condo.PARAMS.DEVICE_ACCESS_REQUEST.KEY
        },
        DOCUMENTS: {
          icon: 'fa:folder',
          label: 'Documentos',
          description: 'Habilita ou desabilita o módulo de documentos.',
          true: Condo.PARAMS.DOCUMENTS.ENABLED,
          false: Condo.PARAMS.DOCUMENTS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.DOCUMENTS.KEY
        },
        ADS: {
          icon: 'fa:shopping-cart',
          label: 'Classificados',
          description: 'Habilita ou desabilita o módulo de classificados.',
          true: Condo.PARAMS.ADS.ENABLED,
          false: Condo.PARAMS.ADS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.ADS.KEY
        },
        LOST_AND_FOUNDS: {
          icon: 'fa:search',
          label: 'Achados e perdidos',
          description: 'Habilita ou desabilita o módulo de achados e perdidos.',
          true: Condo.PARAMS.LOST_AND_FOUNDS.ENABLED,
          false: Condo.PARAMS.LOST_AND_FOUNDS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.LOST_AND_FOUNDS.KEY
        },
        CONDO_RATING: {
          icon: 'fa:star',
          label: 'Avaliações',
          description: `Habilita ou desabilita o módulo de avaliações do(a) ${this.condo?.customLabels?.condo?.singular || 'condomínio'}.`,
          true: Condo.PARAMS.CONDO_RATING.ENABLED,
          false: Condo.PARAMS.CONDO_RATING.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.CONDO_RATING.KEY
        },
        CONDO_FAQ: {
          icon: 'fa:question',
          label: 'Perguntas frequentes',
          description: `Habilita ou desabilita o módulo de perguntas frequentes do(a) ${
            this.condo?.customLabels?.condo?.singular || 'condomínio'
          }.`,
          true: Condo.PARAMS.CONDO_FAQ.ENABLED,
          false: Condo.PARAMS.CONDO_FAQ.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.CONDO_FAQ.KEY
        },
        ASSET_MANAGEMENT: {
          icon: 'fa:archive',
          label: 'Controle de ativos',
          description: 'Habilita ou desabilita o módulo de controle de ativos.',
          true: Condo.PARAMS.ASSET_MANAGEMENT.ENABLED,
          false: Condo.PARAMS.ASSET_MANAGEMENT.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.ASSET_MANAGEMENT.KEY
        },
        PETS: {
          icon: 'fa:paw',
          label: 'Pets',
          description: 'Habilita ou desabilita o módulo de pets.',
          true: Condo.PARAMS.PETS.ENABLED,
          false: Condo.PARAMS.PETS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.PETS.KEY
        },
        DEPENDENTS: {
          icon: 'fa:child',
          label: capitalize(this.condo?.customLabels?.dependent?.plural || 'Dependentes'),
          description: `Habilita ou desabilita o módulo de ${this.condo?.customLabels?.dependent?.plural || 'dependentes'}.`,
          true: Condo.PARAMS.DEPENDENTS.ENABLED,
          false: Condo.PARAMS.DEPENDENTS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.DEPENDENTS.KEY
        },
        CONSTRUCTIONS: {
          icon: 'fa:exclamation-triangle',
          label: 'Obras',
          description: 'Habilita ou desabilita o módulo de obras.',
          true: Condo.PARAMS.CONSTRUCTIONS.ENABLED,
          false: Condo.PARAMS.CONSTRUCTIONS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.CONSTRUCTIONS.KEY
        },
        RESERVATIONS: {
          icon: 'fa:calendar',
          label: 'Reservas',
          description: 'Habilita ou desabilita o módulo de reservas.',
          true: Condo.PARAMS.RESERVATIONS.ENABLED,
          false: Condo.PARAMS.RESERVATIONS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.RESERVATIONS.KEY
        },
        EVENT_ANNOUNCEMENT_BOARD: {
          icon: 'fa:desktop',
          label: 'Quadro de anúncios de eventos',
          description: 'Habilita ou desabilita o módulo do quadro de anúncios de eventos.',
          true: Condo.PARAMS.EVENT_ANNOUNCEMENT_BOARD.ENABLED,
          false: Condo.PARAMS.EVENT_ANNOUNCEMENT_BOARD.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.EVENT_ANNOUNCEMENT_BOARD.KEY
        }
      }
    },
    financeiro: {
      title: 'Financeiro',
      icon: 'fa:usd',
      params: {
        FINANCE_MODULE: {
          icon: 'fa:usd',
          label: 'Financeiro',
          description: 'Habilita ou desabilita o módulo financeiro.',
          true: Condo.PARAMS.FINANCE_MODULE.ENABLED,
          false: Condo.PARAMS.FINANCE_MODULE.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.FINANCE_MODULE.KEY
        },
        FINANCE: {
          icon: 'fa:users',
          label: `Financeiro disponível para ${this.condo?.customLabels?.resident?.plural || 'condôminos'}`,
          description: `Se habilitado, o módulo financeiro estará visível para todos os(as) ${
            this.condo?.customLabels?.resident?.plural || 'condôminos'
          } do(a) ${this.condo?.customLabels?.condo?.singular || 'condomínio'}.`,
          true: Condo.PARAMS.FINANCE.ANYONE,
          false: Condo.PARAMS.FINANCE.ONLY_ADMINS,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.FINANCE.KEY
        }
      }
    },
    portaria: {
      title: 'Portaria',
      icon: 'fa:building',
      params: {
        ACCESS_CONTROL: {
          icon: 'fa:exchange',
          label: 'Controle de acesso',
          description: 'Habilita ou desabilita o módulo de controle de acesso.',
          true: Condo.PARAMS.ACCESS_CONTROL.ENABLED,
          false: Condo.PARAMS.ACCESS_CONTROL.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.ACCESS_CONTROL.KEY
        },
        ACCESS_LIBERATION: {
          icon: 'fa:id-card',
          label: 'Liberação de visitantes',
          description: 'Habilita ou desabilita o módulo de liberação de visitantes.',
          true: Condo.PARAMS.ACCESS_LIBERATION.ENABLED,
          false: Condo.PARAMS.ACCESS_LIBERATION.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.ACCESS_LIBERATION.KEY
        },
        DELIVERIES: {
          icon: 'fa:archive',
          label: 'Recebimento de encomendas',
          description: 'Habilita ou desabilita o módulo de recebimento de encomendas.',
          true: Condo.PARAMS.DELIVERIES.ENABLED,
          false: Condo.PARAMS.DELIVERIES.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.DELIVERIES.KEY
        },
        DELIVERIES_TRACKING: {
          icon: 'fa:truck',
          label: 'Rastreamento de encomendas',
          description: 'Habilita ou desabilita o módulo de rastreamento de encomendas.',
          true: Condo.PARAMS.DELIVERIES_TRACKING.ENABLED,
          false: Condo.PARAMS.DELIVERIES_TRACKING.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.DELIVERIES_TRACKING.KEY
        },
        KEYS: {
          icon: 'fa:key',
          label: 'Painel de chaves',
          description: 'Habilita ou desabilita o módulo do painel de chaves para a portaria.',
          true: Condo.PARAMS.KEYS.ENABLED,
          false: Condo.PARAMS.KEYS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.KEYS.KEY
        },
        GATE_NOTEBOOK: {
          icon: 'fa:book',
          label: 'Livro da portaria',
          description: `Habilita ou desabilita o uso do livro de anotaçõesna na portaria (este livro é visível apenas para o(a) ${
            this.condo?.customLabels.owner.singular || 'síndico'
          }, ${this.condo?.customLabels.admin.plural || 'admnistradores'} e ${
            this.condo?.customLabels.gatekeeper.plural || 'porteiros'
          } do(a) ${this.condo?.customLabels?.condo?.singular || 'condomínio'}).`,
          true: Condo.PARAMS.GATE_NOTEBOOK.ENABLED,
          false: Condo.PARAMS.GATE_NOTEBOOK.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.GATE_NOTEBOOK.KEY
        },
        GATE_CHAT: {
          icon: 'fa:comment',
          label: 'Chat com a portaria',
          description: 'Habilita ou desabilita o módulo de chat com a portaria.',
          true: Condo.PARAMS.GATE_CHAT.ENABLED,
          false: Condo.PARAMS.GATE_CHAT.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.GATE_CHAT.KEY
        }
      }
    },
    manutencao: {
      title: 'Manutenção',
      icon: 'fa:wrench',
      params: {
        MAINTENANCE: {
          icon: 'fa:wrench',
          label: 'Manutenção',
          description: 'Habilita ou desabilita o módulo de controle de manutenção.',
          true: Condo.PARAMS.MAINTENANCE.ENABLED,
          false: Condo.PARAMS.MAINTENANCE.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.MAINTENANCE.KEY
        },
        MAINTENANCE_TICKETS: {
          icon: 'fa:ticket',
          label: 'Chamados de manutenção',
          description: 'Habilita ou desabilita o módulo de chamados de manutenção.',
          true: Condo.PARAMS.MAINTENANCE_TICKETS.ENABLED,
          false: Condo.PARAMS.MAINTENANCE_TICKETS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.MAINTENANCE_TICKETS.KEY
        },
        MAINTENANCE_TICKETS_VISIBILITY: {
          icon: 'fa:eye',
          label: `${capitalize(
            this.condo?.customLabels?.resident?.singular || 'Condômino'
          )} poderá visualizar todos os chamados de manutenção`,
          description: `Quando habilitado, o(a) ${
            this.condo?.customLabels?.resident?.singular || 'condômino'
          } poderá ver todos chamados de manutenção. Quando desabilitado, o(a) ${
            this.condo?.customLabels?.resident?.singular || 'condômino'
          } irá visualizar somente os chamados de manutenção que ele criou.`,
          true: Condo.PARAMS.MAINTENANCE_TICKETS_VISIBILITY.ANYONE,
          false: Condo.PARAMS.MAINTENANCE_TICKETS_VISIBILITY.RESTRICTED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.MAINTENANCE_TICKETS_VISIBILITY.KEY
        }
      }
    },
    hardware: {
      title: 'Hardware',
      icon: 'fa:microchip',
      params: {
        VIRTUAL_KEY: {
          icon: 'fa:key',
          label: 'Chave virtual',
          description: `Habilita ou desabilita o uso da chave virtual por ${this.condo?.customLabels?.resident?.plural || 'condôminos'}.`,
          true: Condo.PARAMS.VIRTUAL_KEY.ENABLED,
          false: Condo.PARAMS.VIRTUAL_KEY.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.VIRTUAL_KEY.KEY
        },
        CAMERAS: {
          icon: 'fa:camera',
          label: `Câmeras para ${this.condo?.customLabels?.resident?.singular || 'condômino'}`,
          description: `Habilita ou desabilita a visualização das câmeras liberadas para ${
            this.condo?.customLabels?.resident?.plural || 'condôminos'
          } no aplicativo.`,
          true: Condo.PARAMS.CAMERAS.ENABLED,
          false: Condo.PARAMS.CAMERAS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.CAMERAS.KEY
        },
        PANIC_BUTTON: {
          icon: 'fa:exclamation-triangle',
          label: 'Botão de pânico',
          description: 'Habilita ou desabilita o botão de pânico.',
          true: Condo.PARAMS.PANIC_BUTTON.ENABLED,
          false: Condo.PARAMS.PANIC_BUTTON.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.PANIC_BUTTON.KEY
        },
        ALARM_CENTRALS: {
          icon: 'ri:alarm-warning-fill',
          label: 'Centrais de alarme',
          description: 'Habilita ou desabilita as centrais de alarme.',
          true: Condo.PARAMS.ALARM_CENTRALS.ENABLED,
          false: Condo.PARAMS.ALARM_CENTRALS.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.ALARM_CENTRALS.KEY
        }
      }
    },
    telefonia: {
      title: 'Telefonia',
      icon: 'fa:phone',
      params: {
        INTERCOM: {
          icon: 'fa:phone',
          label: 'Intercom',
          description: 'Habilita ou desabilita o módulo de interfone.',
          true: Condo.PARAMS.INTERCOM.ENABLED,
          false: Condo.PARAMS.INTERCOM.DISABLED,
          value: '',
          toggleValue: '',
          paramName: Condo.PARAMS.INTERCOM.KEY
        }
      }
    }
  };

  public selectedParamsSection: keyof typeof this.CONDO_SECTION_PARAMS = 'cadastro';

  constructor(
    private condoService: CondoService,
    private sessionService: SessionService,
    private utilService: UtilService,
    private toastrService: ToastrService
  ) {
    if (this.user.isSystemAdmin) {
      this.CONDO_SECTION_PARAMS.relatorios = {
        title: 'Relatórios',
        icon: 'fa:files-o',
        params: {
          VEHICLE_HISTORY_REPORT: {
            icon: 'fa:wpforms',
            label: 'Histórico de vagas',
            description: 'Habilita ou desabilita o uso do histórico de vagas.',
            true: Condo.PARAMS.VEHICLE_HISTORY_REPORT.ENABLED,
            false: Condo.PARAMS.VEHICLE_HISTORY_REPORT.DISABLED,
            value: '',
            toggleValue: '',
            paramName: Condo.PARAMS.VEHICLE_HISTORY_REPORT.KEY
          }
        }
      };
    }

    const hasViewExternalLinksPermissions = this.user.getPermissionValue({
      condoId: this.condo._id,
      permission: PERMISSIONS.externalLinks.view
    });

    if (hasViewExternalLinksPermissions) {
      this.CONDO_SECTION_PARAMS.linksExternos = {
        title: 'Links externos',
        icon: 'fa:external-link'
      };
    }

    this.setParams();
  }

  setParams() {
    if (this.condo.params) {
      const condoParams = this.condo.params;

      for (const sectionKey in this.CONDO_SECTION_PARAMS) {
        const section = this.CONDO_SECTION_PARAMS[sectionKey];

        for (const key in section.params) {
          if (section.params.hasOwnProperty(key)) {
            const param = section.params[key];

            param.value = condoParams[param.paramName];
            param.originalValue = param.value === param.true;
            param.toggleValue = param.value === param.true;
          }
        }
      }
    }
  }

  handleSelectParamsSection(sectionKey: typeof this.selectedParamsSection) {
    this.selectedParamsSection = sectionKey;
  }

  handleToggleParam(value, paramKey: string) {
    this.CONDO_SECTION_PARAMS[this.selectedParamsSection].params[paramKey].toggleValue = value;
    this.toggleParamSubmit();
  }

  toggleParamSubmit() {
    const params = {};

    // Constrói o objeto com todos parâmetros do condo
    for (const sectionKey in this.CONDO_SECTION_PARAMS) {
      const section = this.CONDO_SECTION_PARAMS[sectionKey];

      for (const key in section.params) {
        if (section.params.hasOwnProperty(key)) {
          const param = section.params[key];
          params[param.paramName] = param[param.toggleValue];
        }
      }
    }

    this.isSubmitting = true;

    this.condoService
      .updateCondo(this.condo._id, { params })
      .pipe(timeout(10_000))
      .subscribe({
        next: () => {
          this.condo.params = params;
          if (this.condo._id == this.utilService.getLocalCondo()._id) {
            this.utilService.saveLocalCondo(this.condo);
          }
          const updatedUser = this.utilService.getLocalUser();
          updatedUser.defaultCondo.params = params;
          this.sessionService.setUser(updatedUser);
          this.setParams();
          this.isSubmitting = false;
          this.toastrService.success('Parâmetro atualizado com sucesso.');
        },
        error: () => {
          this.setParams();
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: 'Não foi possível atualizar os parâmetros, tente novamente'
          });
        }
      });
  }

  defaultSortFunction(): number {
    return 0;
  }
}
