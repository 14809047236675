import { ApartmentResidence } from './residence.apartment';
import { HouseResidence } from './residence.house';
import { Residence } from '../interface/residence';
import { BusinessResidence } from '@api/model/residence/residence.business';

export class ResidenceBuilder {
  static build(residence): Residence {
    if (!residence) {
      return null;
    }
    if (residence instanceof Residence || residence instanceof ApartmentResidence || residence instanceof HouseResidence) {
      return residence;
    }
    switch (residence.type) {
      case Residence.TYPES.APARTMENT: {
        return new ApartmentResidence(residence);
      }
      case Residence.TYPES.HOUSE: {
        return new HouseResidence(residence);
      }
      case Residence.TYPES.BUSINESS: {
        return new BusinessResidence(residence);
      }
      default:
        return residence;
    }
  }
}
