import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { FileService } from '@api/service/file.service';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'modal-signature',
  templateUrl: 'modal-signature.html',
  styleUrls: ['modal-signature.scss']
})
export class ModalSignature implements OnInit, AfterViewInit {
  @ViewChild('canvas', { static: true }) signaturePadElement;
  signaturePad: any;

  condo: Condo;
  callback: Function;

  status: Status = new Status();

  constructor(
    public bsModalRef: BsModalRef,
    private fileService: FileService,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.init();
  }

  init() {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    canvas.width = Math.min(window.innerWidth - 70, 500);
    canvas.height = Math.min(window.innerHeight - 100, 350);
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      maxWidth: 2,
      backgroundColor: 'rgb(255,255,255)'
    });
    this.signaturePad.clear();
  }

  clear() {
    this.signaturePad.clear();
  }

  save() {
    if (this.signaturePad.isEmpty()) {
      this.toastrService.error('Preencha a assinatura');
    } else {
      this.status.setAsProcessing();
      const base64 = this.signaturePad.toDataURL('image/jpeg');
      const blob = this.fileService.dataURItoBlob(base64);
      this.fileService.uploadFiles([blob]).subscribe(
        ([signature]) => {
          this.status.setAsDone();
          this.callback({ signature, base64 });
          this.bsModalRef.hide();
        },
        err => {
          // Error toast
          this.status.setAsError();
          this.toastr.error('Não foi possível salvar a assinatura, verifique sua conexão e tente novamente...');
        }
      );
    }
  }
}
