import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Status } from '@api/model/status';
import { ChecklistTemplateService } from '@api/service/checklistTemplate.service';
import { Condo } from '@api/model/condo';
import { ChecklistTemplate } from '@api/model/checklistTemplate';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-gate-notebook-checklist',
  templateUrl: './modal-gate-notebook-checklist.component.html',
  styleUrls: ['./modal-gate-notebook-checklist.component.scss']
})
export class ModalGateNotebookChecklistComponent implements OnInit {
  condo: Condo;
  checklists: ChecklistTemplate[] = [];
  onSuccess: (templates: ChecklistTemplate[]) => void;

  status: Status = new Status();
  form: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private checklistTemplateService: ChecklistTemplateService,
    private toastrService: ToastrService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      checklist: this.fb.array([])
    });
  }

  get checklistArray() {
    return this.form.get('checklist') as FormArray;
  }

  ngOnInit(): void {
    if (this.checklists && this.checklists.length > 0) {
      this.checklists.forEach(template => {
        this.addChecklist(template);
      });
    } else {
      this.addChecklist();
    }
  }
  addChecklist({ name, items, _id }: ChecklistTemplate = {}) {
    const formGroup = this.fb.group({
      _id: [_id || ''],
      name: [name || '', Validators.compose([Validators.required, Validators.minLength(3)])],
      itemName: [''],
      items: this.fb.array([])
    });
    if (items && items.length > 0) {
      const itemsControl = formGroup.get('items') as FormArray;
      items.forEach(value => {
        itemsControl.push(new FormControl(value));
      });
    }
    this.checklistArray.push(formGroup);
  }

  submit() {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const formChecklistsIds: string[] = this.form.value.checklist.map(({ _id }) => _id);
      const checklistsIds = this.checklists.map(({ _id }) => _id);

      const toRemove = this.checklists.filter(toBeRemoveId => !formChecklistsIds.includes(toBeRemoveId._id));
      const toUpdate = this.form.value.checklist
        .filter(toBeUpdatedId => checklistsIds.includes(toBeUpdatedId._id))
        .map(toBeUpdated => ({ name: toBeUpdated.name, items: toBeUpdated.items, _id: toBeUpdated._id }));
      const toCreate = this.form.value.checklist
        .filter(toBeCreated => !toBeCreated._id)
        .map(toBeCreated => ({ name: toBeCreated.name, items: toBeCreated.items }));

      if ([...toRemove, ...toUpdate, ...toCreate].length === 0) {
        this.toastrService.error('Nenhuma ação é necessária');
        this.status.setAsError();
        return;
      }

      const toDeleteRequests = toRemove.map(checklist => this.checklistTemplateService.delete(this.condo._id, checklist._id));
      const toUpdateRequests = toUpdate.map(checklist =>
        this.checklistTemplateService.update(this.condo._id, checklist._id, checklist).pipe(map(() => checklist))
      );
      const toCreateRequests = toCreate.map(checklist =>
        this.checklistTemplateService.create(this.condo._id, checklist).pipe(map(({ _id }: any) => ({ ...checklist, _id })))
      );

      const requests = [...toDeleteRequests, ...toUpdateRequests, ...toCreateRequests];

      forkJoin(requests).subscribe(
        response => {
          if (this.onSuccess) {
            this.onSuccess(response.filter(v => v));
          }
          this.status.setAsSuccess();
          this.toastrService.success('Checklist salva com sucesso.');
          this.bsModalRef.hide();
        },
        () => {
          this.status.setAsError();
          this.toastrService.error('Ocorreu um erro ao salva a checklist. Tente novamente');
        }
      );
    } else {
      this.form.markAsTouched();
      this.toastrService.warning('Preencha os campos obrigatórios');
    }
  }

  itemNameControl(index) {
    return this.checklistArray.controls[index].get('itemName') as FormControl;
  }
  itemsControlArray(index) {
    return this.checklistArray.controls[index].get('items') as FormArray;
  }

  addItemOnChecklist(index) {
    const { value } = this.itemNameControl(index);
    const array = this.itemsControlArray(index);
    array.push(new FormControl(value));
    this.itemNameControl(index).reset('');
  }

  removeItemFromChecklist(checklistIndex: number, itemIndex: number) {
    const items = this.itemsControlArray(checklistIndex);
    items.removeAt(itemIndex);
  }

  removeChecklist(index: number) {
    this.checklistArray.removeAt(index);
  }

  getData(index) {
    const formGroup = this.checklistArray.controls[index] as FormGroup;
    const { value } = formGroup.controls.items as FormArray;
    return value;
  }

  checkData(index) {
    const nameControl = this.checklistArray.controls[index].get('name');
    console.log({ nameControl });
  }
}
