import { CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-create-device-base-stepper',
  templateUrl: 'create-device-base-stepper.component.html',
  styleUrls: ['create-device-base-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: CreateDeviceBaseStepperComponent }]
})
export class CreateDeviceBaseStepperComponent extends CdkStepper implements OnChanges {
  @Input()
  activeClass = 'active';

  @Output()
  onStepChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onCannotChangeToStep: EventEmitter<number> = new EventEmitter<number>();

  // https://indepth.dev/posts/1284/building-a-custom-stepper-using-angular-cdk
  // https://material.angular.io/guide/creating-a-custom-stepper-using-the-cdk-stepper
  ngOnChanges(changes: SimpleChanges) {}

  onClick(index: number): void {
    if (index > 0) {
      const steps = this.steps.toArray();
      const previousStep = steps[index - 1];
      const stepControl = previousStep.stepControl;
      if (stepControl.valid) {
        this.selectedIndex = index;
        this.onStepChange.emit(index);
      } else {
        stepControl.markAllAsTouched();
        this.onCannotChangeToStep.emit(index);
      }
    } else {
      this.selectedIndex = index;
      this.onStepChange.emit(index);
    }
  }
}
