import { Condo } from './condo';
import { User } from './user';
import { File } from './file';
import { BackObject } from './interface/back.object';
import { Serializable } from './interface/serializable';

export class Comment implements BackObject, Serializable {
  public _id = '';
  private _condo: Condo = null;
  private _description: string = '';
  private _pictures: File[] = new Array<File>();
  private _likers: User[] = new Array<User>();
  private _visible: boolean = true;
  private _createdBy: User = null;
  private _updatedBy: User = null;
  private _moderatedBy: User = null;
  private _createdAt: string = '';

  constructor(comment) {
    if (comment) {
      this._id = comment._id;
      this._condo = comment.condo ? new Condo(comment.condo) : this._condo;
      this._description = comment.description || '';
      this._pictures = comment.pictures ? comment.pictures.map(pic => new File(pic)) : this._pictures;
      this._likers = comment.likers ? comment.likers.map(user => new User(user)) : this._likers;
      this._visible = comment.visible || true;
      this._createdBy = comment.createdBy ? new User(comment.createdBy) : this._createdBy;
      this._updatedBy = comment.updatedBy ? new User(comment.updatedBy) : this._updatedBy;
      this._moderatedBy = comment.moderatedBy ? new User(comment.moderatedBy) : this._moderatedBy;
      this._createdAt = comment.createdAt || this._createdAt;
    }
  }

  serialize() {
    const comment: any = {};
    comment.description = this.description || '';
    return comment;
  }

  createBackObject() {
    const comment = {
      condo: this.condo.id,
      description: this.description,
      pictures: this.pictures.map(pic => pic.id),
      likers: this.likers.map(u => u.id),
      visible: this.visible,
      createdBy: this.createdBy.id,
      updatedBy: this.updatedBy.id,
      moderatedBy: this.moderatedBy.id
    };

    return comment;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get condo(): Condo {
    return this._condo;
  }

  set condo(value: Condo) {
    this._condo = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get pictures(): File[] {
    return this._pictures;
  }

  set pictures(value: File[]) {
    this._pictures = value;
  }

  get likers(): User[] {
    return this._likers;
  }

  set likers(value: User[]) {
    this._likers = value;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }

  get createdBy(): User {
    return this._createdBy;
  }

  set createdBy(value: User) {
    this._createdBy = value;
  }

  get updatedBy(): User {
    return this._updatedBy;
  }

  set updatedBy(value: User) {
    this._updatedBy = value;
  }

  get moderatedBy(): User {
    return this._moderatedBy;
  }

  set moderatedBy(value: User) {
    this._moderatedBy = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }
}
