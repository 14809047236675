import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'chip-component',
  styleUrls: ['./chip.component.scss'],
  template: `
    <div class="chip">
      <i class="{{ icon }} me-2" *ngIf="icon"></i>
      <span class="chip-text">
        {{ type === 'object' ? value[field] : value }}
      </span>
      <span *ngIf="canClose" class="closebtn" (click)="close()">
        <i class="fa fa-times"></i>
      </span>
    </div>
  `
})
export class ChipComponent {
  @Input()
  type: 'object' | 'string' = 'string';
  @Input()
  field = '';
  @Input()
  icon: String = '';
  @Input()
  value: String | Object = '';
  @Input()
  canClose = false;
  @Output()
  onCloseClick = new EventEmitter();

  close() {
    this.onCloseClick.emit(this.value);
  }
}
