import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilService } from '../../../app/services/util.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { sipIcon } from 'assets/svg/custom-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface Tab {
  name: string;
  label: string;
  icon?: string;
  customIcon?: IconDefinition;
  enabled: boolean;
}

@Component({
  templateUrl: 'intercom.html',
  styleUrls: ['intercom.scss'],
  selector: 'app-intercom-tabs'
})
export class IntercomComponent implements OnDestroy {
  public TABS: Tab[] = [];
  public selectedTab: string;

  private unsubscribe: Subject<void> = new Subject();

  isAdmin: boolean = false;

  constructor(private router: Router, private utilService: UtilService) {
    const condo = this.utilService.getLocalCondo();
    const user = this.utilService.getLocalUser();

    this.isAdmin = user.isAdmin() || user.isOwner();

    this.TABS = this.buildIntercomTabs();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event: any) => {
        const url = event.url;
        if (url === '/intercom' && this.TABS.length) {
          this.router.navigate(['intercom', 'calls-history']);
        } else if (url !== '/intercom' && url.includes('intercom')) {
          //do nothing
        } else {
          this.router.navigate(['notFound']);
        }
        this.setSelectedTabBasedOnURL(url);
      });
  }

  buildIntercomTabs(): Tab[] {
    const allTabs: Tab[] = [
      {
        name: 'calls-history',
        label: 'Histórico de chamadas',
        icon: 'clock-o',
        enabled: true
      },
      {
        name: 'extensions',
        label: 'Ramais',
        icon: 'phone-square',
        enabled: this.isAdmin
      },
      {
        name: 'sip-accounts',
        label: 'Conta SIP',
        customIcon: sipIcon,
        enabled: this.isAdmin
      }
    ];

    return allTabs;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setSelectedTabBasedOnURL(url = this.router.url): void {
    const tabsRoutes = this.TABS.map(tab => tab.name);

    tabsRoutes.forEach(route => {
      if (url.includes(route)) {
        this.selectedTab = route;
      }
    });
  }

  navigate(route = this.selectedTab): void {
    if (!this.router.url.includes(route)) {
      this.router.navigate([`intercom/${route}`]);
    }
  }
}
