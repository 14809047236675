/**
 * Created by Rafael on 12/12/2016.
 */
import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GateOccurrence } from '../../../api/model/occurrence/occurrence.gate';
import swal from 'sweetalert2';
import { UtilService } from '../../../services/util.service';
import { OccurrenceService } from '../../../api/service/occurrence.service';
import * as _ from 'lodash';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'modal-close-allowaccess',
  templateUrl: 'modal.close.allowaccess.html'
})
export class ModalCloseAllowAccessComponent {
  @ViewChild('closeAllowAccessModal', { static: true }) public closeAllowAccessModal: ModalDirective;

  // Ocorrência original fornecida através do @Input
  @Input() public originalOccurrence: GateOccurrence;
  // Evento deve ser disparado quando o dado da ocorrência é alterado
  @Output() dataChanged = new EventEmitter();

  @Input() public occurrence: GateOccurrence;

  public condo;
  public user;
  public isSubmiting = false;

  constructor(private occurrenceService: OccurrenceService, private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();
  }

  closeModal(): void {
    this.closeAllowAccessModal.hide();
  }

  showModal() {
    this.initializeModal();
    this.closeAllowAccessModal.show();
  }

  initializeModal() {
    this.occurrence = _.cloneDeep(this.originalOccurrence);
  }

  closeOccurrence() {
    swal({
      title: `Deseja realmente fechar a ocorrência?`,
      text: 'Depois de fechada esta ocorrência não poderá ser editada, apenas visualizada',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then(
      success => {
        // this.occurrence.status = Occurrence.STATUS.CLOSED;
        this.saveOccurrence();
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  updateOccurrence() {
    // this.occurrence.status = Occurrence.STATUS.OPENED;
    this.saveOccurrence();
  }

  saveOccurrence() {
    this.isSubmiting = true;
    this.occurrenceService
      .updateOcurrence(this.condo._id, this.occurrence.id, this.occurrence)
      .pipe(timeout(10000))
      .subscribe(
        resp => {
          this.isSubmiting = false;
          this.dataChanged.emit(this.occurrence);
          this.closeModal();
        },
        err => {
          this.isSubmiting = false;
          swal({
            title: `Ops...`,
            text: 'Não foi possível atualizar a ocorrência, tente novamente.',
            cancelButtonColor: '#f53d3d'
          });
        }
      );
  }
}
