import { query } from '@angular/animations';
import { TagInputComponent } from './../../../components/tag-input/tag-input.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../services/util.service';
import { OccurrenceService } from '@api/service/occurrence.service';
import swal from 'sweetalert2';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { Occurrence } from '@api/model/interface/occurrence';
import { ParamsService } from '@api/service/params.service';
import { Condo } from '@api/model/condo';
import { ResidencePickerComponent } from '../../modal/residence.picker/residence.picker/residence.picker.component';
import { timeout } from 'rxjs/operators';
import { ModalPreviewWebcamComponent } from '../../../components/image-picker/modal-preview-webcam/modal-preview-webcam.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileService } from '@api/service/file.service';
import { Subscription } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import { DependentService } from '@api/service/dependent.service';
import { ToastrService } from 'ngx-toastr';
import { Dependent } from '@api/model/dependent';
import { User } from '@api/model/user';
import { ResidentAutoCompleteComponent } from 'app/components/resident-auto-complete/resident-auto-complete.component';
import { ResidenceServiceV3 } from '@api/serviceV3/residence.service';
import { EcondosQuery } from '@api/model/query';

@Component({
  templateUrl: 'create.gate.delivery.html',
  styleUrls: ['./create.gate.delivery.scss']
})
export class CreateGateDeliveryPage implements OnInit, OnDestroy {
  @ViewChild(ResidencePickerComponent, { static: true }) residencePicker: ResidencePickerComponent;

  @ViewChild(TagInputComponent, { static: true }) tagInput: TagInputComponent;

  @ViewChild(ResidentAutoCompleteComponent, { static: true }) residentAutoComplete: ResidentAutoCompleteComponent;

  public occurrence;

  public occurrenceForm: UntypedFormGroup;
  public title: AbstractControl;
  public description: AbstractControl;

  public trackingNumbers = [];
  public trackingNumberToAdd = '';

  tags = [];

  public selectedResidences;

  public triedToSubmit = false;

  public isSubmiting = false;
  public isUploading = false;

  public isEditingOccurrence = false;

  public condo: Condo;

  public attachments = [];

  public deliveryDefaultMessage;
  public user: User;

  condoIsHouse = false;

  public selectedResidence: UntypedFormControl = new UntypedFormControl(undefined);
  public destPerson: UntypedFormControl = new UntypedFormControl(undefined);

  subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private router: Router,
    private paramsService: ParamsService,
    private modalService: BsModalService,
    private fileService: FileService,
    private occurrenceService: OccurrenceService,
    private route: ActivatedRoute,
    private dependentService: DependentService,
    private toastrService: ToastrService,
    private residenceServiceV3: ResidenceServiceV3
  ) {
    const occurrenceId = this.route.snapshot.params['id'];
    this.occurrence = this.paramsService.get(occurrenceId);
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();
    this.condoIsHouse = this.condo.type === 'HOUSE';

    if (this.occurrence && this.occurrence.id) {
      this.isEditingOccurrence = true;

      this.occurrenceForm = this.formBuilder.group({
        title: [this.occurrence.title, Validators.compose([Validators.required])],
        description: [this.occurrence.description, Validators.compose([Validators.required, Validators.minLength(3)])],
        destPerson: []
      });

      this.selectedResidences = this.occurrence.viewers ? this.occurrence.viewers.residences : [];

      this.attachments = this.occurrence.pictures || [];

      if (this.occurrence.isDeliveryType()) {
        this.trackingNumbers = this.occurrence.trackingNumbers || [];
        this.tags = this.occurrence.tags || [];
      }

      if (this.occurrence.destUser) {
        this.occurrenceForm.get('destPerson').setValue({ person: this.occurrence.destUser, type: 'RESIDENT' }, { emitEvent: false });
      }

      if (this.occurrence.destDependent) {
        this.occurrenceForm.get('destPerson').setValue({ person: this.occurrence.destDependent, type: 'DEPENDENT' }, { emitEvent: false });
      }
    } else {
      let defaultMessage = '';
      const storedMessage = localStorage.getItem(`econdos:${this.condo._id}:deliveryDefaultMessage`);
      if (storedMessage) {
        defaultMessage = storedMessage;
      } else {
        defaultMessage =
          'Chegou encomenda para sua(seu) ' + this.condo?.customLabels?.residence?.singular ||
          'unidade' + ', compareça a portaria para retirá-la.';
      }

      this.occurrenceForm = formBuilder.group({
        title: ['Recebimento de encomenda', Validators.compose([Validators.required])],
        description: [defaultMessage, Validators.compose([Validators.required, Validators.minLength(3)])],
        portariaType: [GateOccurrence.DELIVERY_TYPE, Validators.compose([Validators.required])],
        destPerson: ['']
      });
      this.selectedResidences = [];
    }

    this.title = this.occurrenceForm.get('title');
    this.description = this.occurrenceForm.get('description');
    this.destPerson = this.occurrenceForm.get('destPerson') as UntypedFormControl;

    this.subscriptions.add(
      this.selectedResidence.valueChanges.subscribe((residence: Residence) => {
        this.selectedResidences = [];

        if (residence && residence._id) {
          this.selectedResidences.push(residence);
        }
      })
    );

    if (this.occurrence && !this.condoIsHouse) {
      const firstResidenceOfResidences = this.selectedResidences[0];
      this.selectedResidence.setValue(firstResidenceOfResidences);
    }
  }

  public ngOnInit(): void {
    if (this.occurrence?.destUser && !this.user.isOnlyJanitor()) {
      this.residentAutoComplete.onTypeAheadSelect({ item: this.occurrence.destUser });
    }

    if (this.occurrence?.destDependent) {
      this.residentAutoComplete.onTypeAheadSelect({ item: this.occurrence.destDependent });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectHouse() {
    this.residencePicker.pickResidence();
  }

  pushTrackingNumber() {
    if (this.trackingNumberToAdd.length < 1) {
      return;
    }
    this.trackingNumbers.push(this.trackingNumberToAdd.toUpperCase());
    this.trackingNumberToAdd = '';
  }

  createOccurrence(): void {
    const gateOccurrence = new GateOccurrence();

    this.triedToSubmit = true;
    if (this.occurrenceForm.valid && this.selectedResidences.length && !this.isUploading) {
      gateOccurrence.title = this.title.value;
      gateOccurrence.description = this.description.value;
      gateOccurrence.type = Occurrence.GATE_TYPE;
      gateOccurrence.subType = GateOccurrence.DELIVERY_TYPE;
      gateOccurrence.pictures = this.attachments;

      const destPerson = this.destPerson.value;
      if (destPerson?.person?._id && destPerson?.type === 'RESIDENT') {
        gateOccurrence.destUser = { _id: destPerson.person._id } as User;
      } else {
        gateOccurrence.destUser = null;
      }

      if (destPerson?.person?._id && destPerson?.type === 'DEPENDENT') {
        gateOccurrence.destDependent = { _id: destPerson.person._id } as Dependent;
      } else {
        gateOccurrence.destDependent = null;
      }

      // Verifica se o usuario digitou algo, se sim executar a função, se não o botão de adicionar não funciona.
      if (this.trackingNumberToAdd.length > 1) {
        this.pushTrackingNumber();
      }
      gateOccurrence.trackingNumbers = this.trackingNumbers;

      this.tagInput.handleDelimiter(event);

      gateOccurrence.tags = this.tags;

      // Popula a lista de viewers apenas com os ID's das residências
      this.selectedResidences.forEach(residence => {
        gateOccurrence.viewers.residences.push(residence._id);
      });

      let subscription;
      if (this.isEditingOccurrence) {
        subscription = this.occurrenceService.updateOcurrence(this.condo._id, this.occurrence._id, gateOccurrence.createBackObject());
      } else {
        subscription = this.occurrenceService.createOccurrence(this.condo._id, gateOccurrence.createBackObject());
      }

      // Envia a ocorrencia
      this.isSubmiting = true;
      subscription.subscribe(
        resp => {
          this.router.navigate(['/gate/deliveries']);
        },
        err => {
          this.isSubmiting = false;
          swal({
            title: `Ops...`,
            text: 'Não foi possível criar a ocorrência, tente novamente',
            cancelButtonColor: '#f53d3d'
          });
        }
      );
    } else {
      // Caso botao seja clicado e form ainda inválido
      if (!this.title.valid) {
        this.title.markAsTouched();
      }

      if (!this.description.valid) {
        this.description.markAsTouched();
      }
    }
  }

  setSelectedResidences(selectedResidences) {
    this.selectedResidences = selectedResidences;
  }

  removeHouse(residence, index) {
    this.selectedResidences.splice(index, 1);
  }

  showCam() {
    const initialState = {
      callback: base64 => {
        if (base64) {
          this.uploadBase64Url(base64);
        }
      }
    };
    this.modalService.show(ModalPreviewWebcamComponent, { initialState, ignoreBackdropClick: true });
  }

  uploadBase64Url(data) {
    this.subscriptions.add(
      this.fileService.uploadBase64(data).subscribe(
        ([img]) => {
          this.attachments.push(img);
        },
        err => {
          console.log(err);
          swal({ type: 'error', text: 'Não foi possível anexar sua imagem, verifique sua conexão e tente novamente...' });
        }
      )
    );
  }

  public onSelectDestPerson(destPerson: any): void {
    this.selectedResidences = [];
    this.selectedResidence.setValue(null);

    if (!destPerson) {
      this.destPerson.setValue(undefined);
      return;
    }

    this.destPerson.setValue({ person: destPerson, type: destPerson.name ? 'DEPENDENT' : 'RESIDENT' });

    if (destPerson.residence?._id) {
      this.selectedResidence.setValue(destPerson.residence);
    } else if (destPerson.residencesUser?.length) {
      const [firstResidence, ...remainingResidences] = destPerson.residencesUser;
      this.selectedResidence.setValue(firstResidence);

      if (this.condoIsHouse) {
        this.selectedResidences.push(...remainingResidences);
      }
    } else if (destPerson.residencesVoter?.length) {
      const firstResidence = destPerson.residencesVoter;
      const query: EcondosQuery = {
        $select: 'identification'
      };

      this.residenceServiceV3.getResidenceByIdWithParams(this.condo._id, firstResidence, query).subscribe(residence => {
        this.selectedResidence.setValue({ id: residence._id, identification: residence.identification, _id: residence._id });
      });
    }
  }
}
