import { Injectable } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import { Condo } from '@api/model/condo';
import * as HikvisionDevice from '@econdos/econdos-hikvision';
import { forkJoin, from, merge, Observable, of } from 'rxjs';
import { Device } from '@api/model/hardware/device';
import { catchError, map, timeout } from 'rxjs/operators';
import { UtilService } from '../../../services/util.service';

@Injectable({ providedIn: 'root' })
export class HikvisionService {
  // Variável utilizada como Singleton para instâncias da classe Hikvision
  private hikActuators = {};
  condo: Condo;

  constructor(private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
  }

  clearDeviceCache(actuatorId?: string) {
    if (actuatorId) {
      delete this.hikActuators[actuatorId];
    } else {
      this.hikActuators = {};
    }
  }

  public getDevices(actuators: Actuator | Actuator[]) {
    if (!Array.isArray(actuators)) {
      actuators = [actuators];
    }
    return actuators.map(a => this.getDevice(a));
  }

  public getDevice(actuator: Actuator) {
    if (!this.hikActuators[actuator._id]) {
      this.hikActuators[actuator._id] = new HikvisionDevice(actuator);
    }
    return this.hikActuators[actuator._id];
  }

  getBackup(actuator: Actuator, onlyWithFacials = true) {
    return from(this.getDevice(actuator).getBackup(onlyWithFacials));
  }

  get(actuator: Actuator, data: { limit?: number; offset?: number; query: any }) {}

  create(
    actuators: Actuator[],
    data: Device,
    options?: Partial<{
      entranceDuration: number;
    }>
  ): Observable<[{ actuator: Actuator; ok: boolean }]> {
    const calls = [];
    actuators = actuators.filter(a => !!a);
    for (const actuator of actuators) {
      const hik = this.getDevice(actuator);
      const userType = data.owner.condoContact ? 'visitor' : 'normal';
      const owner = data.owner.user || data.owner.dependent || data.owner.condoContact || null;
      const filePath = (data.owner.picture && data.owner.picture.url) || (owner && owner.picture && owner.picture.url);
      let name = owner?.name || `${(owner && owner.firstName) || ''} ${(owner && owner.lastName) || ''}`;
      if (!name.trim() && data.owner && data.owner.userName) {
        name = data.owner.userName;
      }
      if (data.owner.residence && data.owner.residence.identification) {
        name += ` - ${data.owner.residence.identification}`;
      }
      const beginTime = data.validFrom;
      const endTime = data.validUntil;
      let credits = 0;
      if (!data.permission && this.condo?.hardwareParams?.visitorsFacialFromGatekeeperDefaultCredits && userType === 'visitor') {
        credits = this.condo?.hardwareParams?.visitorsFacialFromGatekeeperDefaultCredits;
      } // 0 is for resident
      switch (data.type) {
        case 'SN': {
          const password = data.serial;
          calls.push(
            from(hik.addUser({ id: data.serial, name, userType, password, maxOpenDoorTime: credits })).pipe(
              map(() => ({ ok: true, actuator: actuator })),
              catchError(e => {
                console.log(e);
                return of({ ok: false, actuator: actuator });
              })
            )
          );
          break;
        }
        case 'FACIAL': {
          calls.push(
            from(
              hik.addFacial({
                id: data.serial,
                name,
                userType,
                filePath,
                beginTime,
                endTime,
                maxOpenDoorTime: credits,
                entranceDuration: options?.entranceDuration
              })
            ).pipe(
              map(() => ({ ok: true, actuator: actuator })),
              catchError(e => {
                console.log(e);
                return of({ ok: false, actuator: actuator, error: e });
              })
            )
          );
          break;
        }
      }
    }

    if (calls.length) {
      return forkJoin(calls) as any;
    } else {
      return from([]);
    }
  }

  update(data: Device, actuators: Actuator[]): Observable<[{ actuator: Actuator; ok: boolean }]> {
    return this.create(actuators, data);
  }

  delete(device: Device, actuators?: Actuator[]): Observable<[{ actuator: Actuator; ok: boolean }]> {
    let results = [];
    if (!actuators) {
      actuators = device.actuators;
    }
    results = actuators.map(actuator => {
      const hikDevice = this.getDevice(actuator);
      return from(hikDevice.deleteUser({ id: device.serial })).pipe(
        map(() => ({ ok: true, actuator: actuator })),
        catchError(e => of({ ok: false, actuator: actuator }))
      );
    });
    return forkJoin(results) as any;
  }

  post() {}

  trigger(actuator: Actuator) {
    const hikvisionDevice = this.getDevice(actuator);
    return from(hikvisionDevice.openDoor());
  }

  remoteCheck(actuator: Actuator) {
    try {
      const hikvisionDevice = this.getDevice(actuator);
      return from(hikvisionDevice.configRemoteAccess(actuator.remoteCheck)).pipe(
        timeout(10_000),
        catchError(() => of({ status: false })),
        map(() => ({ status: true }))
      );
    } catch (e) {
      return of({ status: false, error: e.message });
    }
  }

  getTime(actuator: Actuator) {
    try {
      const hikvisionDevice = this.getDevice(actuator);
      return from(hikvisionDevice.getTime()).pipe(
        timeout(10000),
        map(localTime => ({ _id: actuator._id, status: true, localTime })),
        catchError(() => of({ _id: actuator._id, status: false }))
      );
    } catch (e) {
      return of({ _id: actuator._id, status: false });
    }
  }
  setDatetime(actuator: Actuator, date?: string) {
    try {
      const hikvisionDevice = this.getDevice(actuator);
      return from(hikvisionDevice.setDatetime(date)).pipe(timeout(10000));
    } catch (e) {
      return of({ success: false });
    }
  }

  activateMonitor(actuator: Actuator, condo: Condo) {
    actuator.condo = condo;
    const hikvisionDevice = this.getDevice(actuator);
    return from(hikvisionDevice.configEvents());
  }

  collectFace(actuator) {
    const hikvisionDevice = new HikvisionDevice(actuator);
    return from(hikvisionDevice.captureFaceDataBase64());
  }

  removeExpiredVisitors(actuators: Actuator[], ids: string[]) {
    const results = actuators.map(actuator => {
      try {
        const hikDevice = this.getDevice(actuator);
        return from(hikDevice.deleteUsers(ids)).pipe(
          map(() => ({ ok: true, actuator: actuator })),
          catchError(e => of({ ok: false, actuator: actuator }))
        );
      } catch (err) {
        return of({ ok: false, actuator: actuator });
      }
    });
    return merge(...results);
  }

  removeExpiredDevices(actuators) {
    const results = actuators.map(actuator => {
      try {
        const hikvisionDevice = this.getDevice(actuator);
        return from(hikvisionDevice.removeExpiredDevicesV2()).pipe(
          map(() => ({ ok: true, actuator })),
          catchError(() => of({ ok: false, actuator }))
        );
      } catch (e) {
        return of({ ok: false, actuator });
      }
    });
    return merge(...results);
  }

  reboot(actuator: Actuator) {
    try {
      const hikvisionDevice = this.getDevice(actuator);
      return from(hikvisionDevice.reboot()).pipe(
        timeout(10000),
        map(success => ({ _id: actuator._id, success })),
        catchError(() => of({ _id: actuator._id, success: false }))
      );
    } catch (e) {
      return of({ _id: actuator._id, success: false });
    }
  }
}
