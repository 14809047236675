import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalEventAnnouncementBoardConfigComponent } from './modal-event-announcement-board-config.component';
import { ThemeModule } from '../../../../theme';
import { PipesModule } from 'app/pipe/pipes.module';
import { ComponentsModule } from 'app/components/components.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    PipesModule,
    ReactiveFormsModule,
    ModalModule,
    ThemeModule,
    ComponentsModule,
    TimepickerModule,
    BsDropdownModule
  ],
  declarations: [ModalEventAnnouncementBoardConfigComponent]
})
export class ModalEventAnnouncementBoardConfigModule {}
