import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LogViewedData } from '@api/model/log-viewed-data';
import { LogViewedDataService } from '@api/service/log-viewed-data.service';
import { EcondosQuery } from '@api/model/query';
import { timeout } from 'rxjs/operators';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';

@Component({
  selector: 'app-modal-show-data-viewers',
  templateUrl: './modal-show-data-viewers.component.html',
  styleUrls: ['./modal-show-data-viewers.component.scss']
})
export class ModalShowDataViewersComponent implements OnInit {
  @ViewChild('viewersTable', { static: true }) viewsTable: TableComponent;
  @ViewChild('viewersCreatedAtCellTemplate', { static: true }) viewersCreatedAtCellTemplate: TemplateRef<any>;
  @ViewChild('viewersUpdatedAtCellTemplate', { static: true }) viewersUpdatedAtCellTemplate: TemplateRef<any>;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<LogViewedData>[] = [];

  @Input()
  condo: Condo;

  @Input()
  model: string;

  @Input()
  documentId: string;

  views: LogViewedData[] = [];

  totalViewsCount = 0;

  initialQuery: EcondosQuery = {
    $select: 'user createdAt updatedAt',
    $populate: [
      {
        path: 'user',
        select: 'firstName lastName picture',
        populate: {
          path: 'picture',
          select: 'url thumbnail'
        }
      }
    ]
  };

  constructor(
    private bsModalRef: BsModalRef,
    private logViewedDataService: LogViewedDataService
  ) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        headerLabel: '#',
        type: 'index'
      },
      {
        type: 'avatar',
        columnId: 'user',
        headerLabel: 'Nome',
        avatar: view => ({
          name: `${view.user?.firstName} ${view.user?.lastName}`,
          picture: view.user?.picture,
          size: 'medium'
        })
      },
      {
        columnId: 'createdAt',
        headerLabel: 'Primeira visualização',
        valueTemplate: this.viewersCreatedAtCellTemplate,
        sortable: true
      },
      {
        columnId: 'updatedAt',
        headerLabel: 'Última visualização',
        valueTemplate: this.viewersUpdatedAtCellTemplate,
        sortable: true
      }
    ];
    this.getData();
  }

  getData({ page = 0 } = {}) {
    const query = this.initialQuery;

    const { pageSize, sortedColumn, sortOrder } = this.viewsTable.getCurrentState();

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    query.$page = page;
    query.$limit = pageSize;

    query.documentId = this.documentId;
    query.model = this.model;

    this.status = 'LOADING';
    this.logViewedDataService
      .get(this.condo._id, query)
      .pipe(timeout(10000))
      .subscribe({
        next: ({ count, views }) => {
          this.totalViewsCount = count;
          this.views = views;
          this.status = 'SUCCESS';
        },
        error: () => {
          this.status = 'ERROR';
          this.views = [];
        }
      });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
