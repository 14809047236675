import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GateNotebookEvent } from '../model/gate-notebook-event';
import * as qs from 'qs';
import { EcondosQuery } from '../model/query';

@Injectable()
export class GateNotebookService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  getGateNotebookEvents(
    condoId: string,
    params: EcondosQuery = {}
  ): Observable<{ count: number; gateNotebookEvents: GateNotebookEvent[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/gate-notebook`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          gateNotebookEvents: res.body
        }))
      );
  }

  create(
    condoId: string,
    event: {
      description: string;
      type: 'POST_SHIFT_ENTRANCE' | 'POST_SHIFT_EXIT' | 'MESSAGE';
      priority?: 'URGENT' | 'HIGH' | 'MODERATE' | 'LOW' | 'ROUTINE';
      files?: any;
      checklists?: {
        checklist: { name: string; items: { obs: string; status: Boolean; item: string }[] }[];
        status: Boolean;
      };
    }
  ): Observable<GateNotebookEvent> {
    return this.http.post(`${this.endPoint}${condoId}/gate-notebook`, event).pipe(
      map((data: { _id: string }) => ({
        _id: data._id,
        description: event.description,
        type: event.type,
        priority: event.priority,
        createdAt: new Date().toISOString()
      }))
    );
  }

  getById(condoId: string, id: string): Observable<GateNotebookEvent> {
    return this.http.get(`${this.endPoint}${condoId}/gate-notebook/${id}`) as Observable<GateNotebookEvent>;
  }
}
