import { VehicleService } from '@api/service/vehicle.service';

import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';

@Injectable({ providedIn: 'root' })
export class VehicleServiceV2 extends VehicleService {
  constructor(protected http: HttpService, protected constantService: ConstantService) {
    super(http, constantService);
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;
  }
}
