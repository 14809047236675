import { Condo } from './condo';
import { File } from './file';
import { User } from './user';

export type ExternalLinkQueryParam = Record<'name' | 'value', string>;

export type ExternalLink = {
  _id: string;
  condo: Condo | null;
  title: string;
  icon?: string;
  picture: File | null;
  url: string;
  queryParams: ExternalLinkQueryParam[];
  includeUserId: boolean;
  preBuiltUrl?: string;
  createdBy: User | null;
  createdAt: Date | null;
  updatedBy: User | null;
  updatedAt: Date | null;
};

export const BuildExternalLink = (data: Partial<ExternalLink>): ExternalLink => {
  return {
    _id: typeof data === 'string' ? data : data._id,
    condo: data.condo ? new Condo(data.condo) : null,
    title: data.title || '',
    icon: data.icon || '',
    picture: data.picture ? new File(data.picture) : null,
    url: data.url || '',
    queryParams: data.queryParams || [],
    includeUserId: data.includeUserId ?? false,
    preBuiltUrl: data.preBuiltUrl || null,
    createdBy: data.createdBy ? new User(data.createdBy) : null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedBy: data.updatedBy ? new User(data.updatedBy) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
};

export const buildExternalLinkUrl = (externalLink: ExternalLink, { user }: { user?: User } = {}) => {
  let url = externalLink.preBuiltUrl;

  if (externalLink.includeUserId && user) {
    const userIdQueryParam = `userId=${user._id}`;
    url += externalLink.queryParams.length ? `&${userIdQueryParam}` : `?${userIdQueryParam}`;
  }

  return url;
};
