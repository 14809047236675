import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ThemeService } from './theme.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { shade, tint } from 'polished';

const COLOR_SHADES = {
  50: 0.95, // 95%
  100: 0.9, // 90%
  200: 0.7, // 70%
  300: 0.5, // 50%
  400: 0.3, // 30%,
  500: null,
  600: 0.1, // 10%
  700: 0.3, // 30%
  800: 0.5, // 50%
  900: 0.7 // 70%
};

@Directive({
  selector: '[theme]'
})
export class ThemeDirective implements OnInit, OnDestroy {
  private theme = null;

  private _destroy$ = new Subject();

  constructor(private _elementRef: ElementRef, private _themeService: ThemeService) {}

  ngOnInit() {
    this._themeService
      .getActiveTheme()
      .pipe(takeUntil(this._destroy$))
      .subscribe(theme => {
        this.theme = theme;
        this.updateTheme(this.theme);
      });
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  setColorShadesForWhiteLabel(theme, cssVariable: string): string {
    let cssVariablesString = '';

    const colorShades = Object.keys(COLOR_SHADES).map(shadeKey => {
      const key = `${cssVariable}-${shadeKey}`;

      let value = (theme.cssVariables[cssVariable] || '').trim();

      if (shadeKey < '500') {
        value = tint(COLOR_SHADES[shadeKey], value);
      } else if (shadeKey > '500') {
        value = shade(COLOR_SHADES[shadeKey], value);
      }

      return { key, value };
    });

    for (const shadeColor of colorShades) {
      cssVariablesString += `${shadeColor.key}: ${shadeColor.value};`;
    }

    return cssVariablesString;
  }

  updateTheme(theme) {
    if (theme.cssVariables) {
      let cssVariablesString = '';

      // project properties onto the element
      for (const key of Object.keys(theme.cssVariables)) {
        cssVariablesString += `${key}: ${theme.cssVariables[key]};`;
      }

      cssVariablesString += this.setColorShadesForWhiteLabel(theme, '--primary');
      cssVariablesString += this.setColorShadesForWhiteLabel(theme, '--on-primary');

      const headTag = document.getElementsByTagName('head')[0];
      const styleTag = document.createElement('style');

      styleTag.innerHTML = `:root { ${cssVariablesString} }`;
      headTag.appendChild(styleTag);
    }
  }
}
