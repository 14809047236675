import { Injectable } from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { downloadDataInChunks } from '@api/utils';
import { HttpService } from '../../services/http.service';
import { EcondosQuery } from '@api/model/query';
import { map, mergeMap, startWith, takeUntil, tap, timeout } from 'rxjs/operators';
import { SessionService } from '@api/service/session.service';
import { generateCsvFromTableComponent, generateReportFromTableComponent } from '../../reports/report-generator';
import { TableColumnDefinition } from '../../components/table/table.component';
import { ToastrService } from 'ngx-toastr';
import { generateRandomHexString } from '@api/util/util';

export interface FullReportParams<T = any> {
  transformFn?: (data: any) => T;
  query?: EcondosQuery;
  url: string;
  name: string;
  columns: TableColumnDefinition<T>[];
}

export interface FullReport {
  name: string;
  columns: TableColumnDefinition[];
  _id: string;
  progress: number;
  data: any[];
  cancel: (index: number) => void;
}

@Injectable({ providedIn: 'root' })
export class FullReportsService {
  reports: Observable<FullReport>[] = [];

  inProgressReports = 0;

  constructor(
    protected http: HttpService,
    private sessionService: SessionService,
    private toastService: ToastrService
  ) {}

  createReport<T extends object = any>(params: FullReportParams<T>) {
    if (this.inProgressReports >= 2) {
      this.toastService.error('Você já possui 2 relatórios em andamento. Aguarde a finalização de um deles para criar um novo.');
      return;
    }

    this.inProgressReports++;

    const options = {
      ...(params.transformFn ? { transformFn: params.transformFn } : {}),
      numberOfRequests: 1,
      timeout: 20_000,
      retries: 3,
      ignoreLoadingBar: true
    };

    const { query, url, name, columns } = params;
    query.$limit = 15;

    const reportId = generateRandomHexString(24);

    const unsubscribe$ = new Subject();

    const report$ = downloadDataInChunks(this.http, url, query, options).pipe(
      timeout(15_000),
      mergeMap(([finalResult, progress]) =>
        merge(
          progress.pipe(mergeMap(progress => of({ progress, result: null }))),
          finalResult.pipe(
            tap(() => this.decrementInProgressReports()),
            mergeMap(result => of({ result, progress: 100 }))
          )
        )
      ),
      takeUntil(unsubscribe$),
      map(res => {
        const result = {
          name,
          columns,
          _id: reportId,
          progress: res.progress || 0,
          data: res.result?.data || [],
          cancel: (index: number) => {
            unsubscribe$.next('');
            unsubscribe$.complete();
            this.decrementInProgressReports();
            this.clearReport(index);
          }
        };
        return result;
      }),
      startWith({ name, columns, _id: reportId, progress: 0, data: [], cancel: () => {} })
    );
    this.reports.push(report$);
  }

  clearReport(index: number) {
    this.reports = this.reports.filter((_, i) => i !== index);
  }

  clearAllReports() {
    this.reports = [];
  }

  getColumnsToExport(columns: TableColumnDefinition[]) {
    return columns
      .filter(column => column.show && column.headerLabel && (column.valueKey || column.valueFn))
      .map(column => ({ headerLabel: column.headerLabel, valueKey: column.valueKey, valueFn: column.valueFn }));
  }

  handleExportAsPdf(report: FullReport) {
    const columnsToExport = this.getColumnsToExport(report.columns);
    const user = this.sessionService.userValue;
    generateReportFromTableComponent({
      title: report.name,
      columns: columnsToExport,
      data: report.data,
      user: user
    });
  }

  handleExportAsExcel(report: FullReport) {
    const columnsToExport = this.getColumnsToExport(report.columns);

    const encodedUri = generateCsvFromTableComponent({ columns: columnsToExport, data: report.data });

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${report.name}.csv`);
    link.click();
  }

  private decrementInProgressReports() {
    if (this.inProgressReports > 0) {
      this.inProgressReports--;
    }
  }
}
