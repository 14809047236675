import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { Device } from '@api/model/hardware/device';
import { Status } from '@api/model/status';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { catchError, filter, map, mergeMap, switchMap, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { forkJoin, from, iif, noop, of, Subject, Subscription, throwError } from 'rxjs';
import swal from 'sweetalert2';
import { Residence } from '@api/model/interface/residence';
import { ModalAddIncontrolUserComponent } from '../modal-add-incontrol-user/modal-add-incontrol-user.component';
import { ResidenceService } from '@api/service/residence.service';
import { User } from '@api/model/user';
import { DependentService } from '@api/service/dependent.service';
import { HardwareSocketService } from '../../services/hardware-socket.service';
import { EcondosQuery } from '@api/model/query';
import * as moment from 'moment';
import { ModalCreateUserStepsComponent } from '../../components/modal-create-user-steps/modal-create-user-steps.component';
import { conditionalValidator } from '@api/util/validators';
import { webSocket } from 'rxjs/webSocket';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-modal-create-intelbras-incontrol-device',
  templateUrl: 'modal-create-intelbras-incontrol-device.component.html',
  styleUrls: ['modal-create-intelbras-incontrol-device.component.scss']
})
export class ModalCreateIntelbrasIncontrolDeviceComponent implements OnInit {
  FINGER_NAMES = {
    0: 'Polegar Direito',
    1: 'Indicador Direito',
    2: 'Médio Direito',
    3: 'Anelar Direito',
    4: 'Dedinho Direito',
    5: 'Polegar Esquerdo',
    6: 'Indicador Esquerdo',
    7: 'Médio Esquerdo',
    8: 'Anelar Esquerdo',
    9: 'Dedinho Esquerdo'
  };

  condo: Condo;

  device: Device;
  status: Status = new Status();
  form: UntypedFormGroup;
  user: AbstractControl;
  type: AbstractControl;
  matricula: AbstractControl;
  name: AbstractControl;
  serial: AbstractControl;
  group: AbstractControl;
  picture: AbstractControl;
  storageResidence: AbstractControl;
  registerer: AbstractControl;
  panicFinger: AbstractControl;
  validUntil: AbstractControl;
  activatedStatus: AbstractControl;
  fingerTemplate: AbstractControl;
  fingerModel: AbstractControl;
  finger: AbstractControl;
  obs: AbstractControl;

  groups: { id: number; nome_grupo: string }[] = [];
  userDisabled = false;
  residenceSelected: Residence;
  residence: UntypedFormControl = new UntypedFormControl();
  storageResidences: Residence[];

  subscriptions: Subscription = new Subscription();

  events: any[] = [];

  registerers;

  inControlUserList;
  userSelected;
  groupSelected;
  residenceVoter;

  isLoading = false;
  syncingDevices = {};
  SYNC_STATUS = {
    SYNCING: 'SYNCING',
    SYNCED: 'SYNCED',
    ERROR: 'ERROR'
  };

  fingers = [0, 1, 2, 3, 4, 5, 7, 8, 9];
  availableFingers = [];

  storedFingerStatus: Status = new Status();
  storedActuatorStatus: Status = new Status();

  callbacks;

  storageUser: { user: User; residence: Residence };

  today = moment().format('YYYY-MM-DDTHH:mm');

  selectQuery: EcondosQuery = {
    $select: 'block identification lot number address type users voter',
    $and: [],
    $or: [],
    $populate: [
      {
        path: 'users',
        select: 'firstName lastName picture phones',
        populate: { path: 'picture', select: 'url thumbnail' }
      },
      {
        path: 'voter',
        select: 'firstName lastName picture phones',
        populate: { path: 'picture', select: 'url thumbnail' }
      }
    ]
  };

  public cardSize: UntypedFormControl = new UntypedFormControl(26);

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private intelbrasService: IntelbrasIncontrolService,
    private deviceService: HardwareDeviceService,
    private modalService: BsModalService,
    private residenceService: ResidenceService,
    private dependentService: DependentService,
    private hardwareSocketService: HardwareSocketService
  ) {
    this.form = this.formBuilder.group({
      user: [''],
      type: ['', Validators.required],
      matricula: [''],
      picture: [null, Validators.required],
      name: [''],
      serial: [''],
      group: ['', Validators.required],
      registerer: [''],
      storageResidence: [''],
      panicFinger: [false],
      validUntil: [moment().year(2099).format('YYYY-MM-DDTHH:mm')],
      activatedStatus: [true],
      fingerTemplate: [null, conditionalValidator(() => this.type.value == 'BM', Validators.required)],
      fingerModel: ['', conditionalValidator(() => this.type.value == 'BM', Validators.required)],
      finger: ['', conditionalValidator(() => this.type.value == 'BM', Validators.required)],
      obs: ['']
    });
    this.user = this.form.get('user');
    this.type = this.form.get('type');
    this.type.disable();
    this.matricula = this.form.get('matricula');
    this.picture = this.form.get('picture');
    this.name = this.form.get('name');
    this.serial = this.form.get('serial');
    this.group = this.form.get('group');
    this.storageResidence = this.form.get('storageResidence');
    this.registerer = this.form.get('registerer');
    this.panicFinger = this.form.get('panicFinger');
    this.validUntil = this.form.get('validUntil');
    this.activatedStatus = this.form.get('activatedStatus');
    this.fingerTemplate = this.form.get('fingerTemplate');
    this.finger = this.form.get('finger');
    this.fingerModel = this.form.get('fingerModel');
    this.obs = this.form.get('obs');

    this.user.valueChanges.subscribe(async user => {
      this.type.setValue('');
      this.isLoading = true;
      if (this.checkHasDeviceOwnerPicture()) {
        this.picture.setValue(this.device.owner.picture);
      } else if (user && user !== 'NEW_USER') {
        user = this.residenceSelected.users.find(u => u._id === this.user.value);
        this.type.enable();
        if (user?.picture?._id && user?.picture?.url) {
          this.picture.setValue(user.picture);
        } else {
          this.picture.setValue(null);
        }
      } else {
        this.type.disable();
        this.picture.setValue(null);
      }
      this.inControlUserList = [];
      if (user) {
        if (user === 'NEW_USER') {
          this.createUser();
        } else {
          this.getSyncedUsers();
        }
      }
      this.isLoading = false;
    });

    this.type.valueChanges
      .pipe(
        filter(type => type === 'BM'),
        tap(() => Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }))),
        switchMap(() => forkJoin({ actuators: this.actuators$(), fingers: this.fingerIds$() }))
      )
      .subscribe(
        ({ actuators, fingers }) => {
          this.registerers = actuators;
          this.storedActuatorStatus.setAsSuccess();

          this.availableFingers = this.fingers.filter(f => !fingers.includes(f));
          this.storedFingerStatus.setAsSuccess();
          const img = { ...this.picture.value };
          this.picture.clearValidators();
          this.picture.reset();
          this.picture.setValue(img);
        },
        () => {
          this.registerers = [];
          this.storedActuatorStatus.setAsError();
          this.availableFingers = [];
          this.storedFingerStatus.setAsError();
        }
      );

    this.type.valueChanges
      .pipe(
        filter(type => type !== 'BM'),
        tap(() => Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false })))
      )
      .subscribe(type => {
        const img = { ...this.picture.value };
        if (type == 'FACIAL') {
          this.picture.setValidators(Validators.required);
        } else {
          this.picture.clearValidators();
        }
        this.picture.reset(img);
      });
  }

  async ngOnInit() {
    if (this.device) {
      this.residenceSelected = this.device.owner.residence;
      this.residence.setValue(this.residenceSelected);
      if (this.residenceSelected) {
        this.isLoading = true;
        const dependents = await this.getResidenceDependents(this.condo, this.residenceSelected);
        if (dependents && dependents.length) {
          this.residenceSelected?.users.push(...dependents);
        }
        this.user.setValue(this.device.owner?.user?._id || this.device.owner?.dependent?._id || null);
        this.userSelected = this.residenceSelected?.users.find(u => u._id === this.user.value);
        if (!this.userSelected && (this.device?.owner?.user || this.device?.owner?.dependent)) {
          this.residenceSelected.users.push(this.device?.owner?.user || this.device?.owner?.dependent);
          this.userSelected = this.device?.owner?.user || this.device?.owner?.dependent;
        }
        if (this.userSelected) {
          this.getSyncedUsers();
        }
      } else {
        this.user.setValue(this.device.owner?.user || this.device.owner?.dependent || null);
      }
      this.picture.setValue(this.device.owner.picture || null);
      this.type.setValue(this.device.type === 'CT' ? 'CARD' : this.device.type);
      if (this.type.value === 'FACIAL') {
        this.picture.setValidators(Validators.required);
      }
      this.serial.setValue(this.device.serial);
      this.obs.setValue(this.device.obs);
      this.obs.enable();
      this.group.setValue(this.device.hardwareAttributes?.grupo || '');
      this.panicFinger.setValue(this.device.panic || false);
      this.validUntil.setValue(
        this.device.validUntil ? moment(this.device.validUntil).format('YYYY-MM-DDTHH:mm') : moment().year(2099).format('YYYY-MM-DDTHH:mm')
      );
      if (this.inControlUserList[0]) {
        this.activatedStatus.setValue(this.inControlUserList[0].estado);
      } else {
        this.activatedStatus.setValue(this.device.hardwareAttributes?.incontrolStatus);
      }
    }

    if (this.storageUser) {
      const residence = this.storageUser.residence || this.storageUser.user?.defaultResidence;
      if (residence) {
        this.residenceSelected = residence;
        this.storageResidence.setValue(residence._id);
        this.residence.setValue(residence);
        this.residence.disable();
        this.isLoading = true;
        const dependents = await this.getResidenceDependents(this.condo, this.residenceSelected);
        if (dependents && dependents.length) {
          this.residenceSelected?.users.push(...dependents);
        }
        this.user.setValue('');
        /// quando aberto modal pelo componente DeviceList na tela de unidades
        if (!this.storageUser.user) {
          this.storageResidences = [residence];
          this.storageResidence.disable();
        }
      }
      if (this.storageUser.user) {
        this.storageResidences = this.storageUser.user.getResidences();
        this.user.setValue(this.storageUser.user._id || null);
        this.user.disable();
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residenceSelected = residences[0];
          this.residence.setValue(residences[0]);
        }
        if (this.storageUser.user.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
      }
    }
  }

  async save(values) {
    if (this.form.valid) {
      const device: any = {
        hardware: 'INTELBRAS',
        owner: {
          user: this.userSelected?.type !== 'DEPENDENT' ? this.user.value : null,
          residence: this.residenceSelected?._id || null,
          picture: this.picture.value?._id ? this.picture.value : null,
          dependent: this.userSelected?.type === 'DEPENDENT' ? this.user.value : null
        },
        type: this.type.value,
        serial: this.serial.value,
        obs: this.obs.value,
        hardwareAttributes: {
          grupo: this.group.value,
          incontrolStatus: this.activatedStatus.value
        },
        validUntil: this.validUntil.value ? moment(this.validUntil.value).toDate() : moment().year(2099).toDate()
      };
      if (this.type.value === 'BM') {
        device.template = this.fingerTemplate.value;
        device.panic = this.panicFinger.value;
        device.hardwareAttributes.incontrolFingerModel = this.fingerModel?.value;
      }
      if (!this.device) {
        this.createDevice(device);
      } else {
        this.updateDevice({
          ...device,
          _id: this.device._id,
          hardwareAttributes: {
            ...device.hardwareAttributes,
            incontrolId: this.device?.hardwareAttributes?.incontrolId
          }
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  createUser() {
    const callback = user => {
      if (user) {
        this.residenceSelected.users.push(user);
        this.user.setValue(user._id);
      }
    };
    const initialState = { condo: this.condo, residence: this.residenceSelected, callback };
    this.modalService.show(ModalCreateUserStepsComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  createDevice(device) {
    this.status.setAsProcessing();
    let saved;
    // Cria o device
    this.deviceService
      .create(this.condo._id, device)
      .pipe(
        map((res: { _id: string }) => {
          let storedDevice;
          storedDevice = { ...device, _id: res._id };
          saved = storedDevice;
          if (storedDevice.owner) {
            if (this.user.value) {
              if (this.userSelected.type === 'DEPENDENT') {
                const userID = this.user.value && this.user.value !== '' ? this.user.value : this.userSelected._id;
                storedDevice.owner.dependent = this.residenceSelected?.users.find(u => u._id === userID) || null;
              } else {
                storedDevice.owner.user = this.residenceSelected?.users.find(u => u._id === this.user.value) || null;
              }
              storedDevice.owner.residence = this.residenceSelected;
            }
            if (this.picture.value) {
              storedDevice.owner.picture = this.picture.value;
            }
          }
          return storedDevice;
        }),
        // Cria/atualiza o usuaria no incontrol
        switchMap(async (savedDevice: any) => {
          if (savedDevice) {
            let picture = null;
            if (savedDevice.type === 'FACIAL') {
              picture = savedDevice.owner.picture;
            }
            this.groupSelected = this.groups.filter(g => g.id == this.group.value);
            if (!this.groupSelected || !this.groupSelected?.length) {
              this.groupSelected = [];
            }
            let user;
            if (this.inControlUserList?.length == 1 && this.inControlUserList[0]?.campos_personalizados.campo_5 == this.userSelected._id) {
              const userToSync = {
                id: this.inControlUserList[0].id,
                firstName: this.inControlUserList[0].pessoa?.nome_completo,
                lastName: '',
                _id: this.userSelected._id,
                residenceToSync: {
                  block: this.inControlUserList[0].departamento?.nome,
                  number: this.inControlUserList[0].local_especifico
                },
                type: this.userSelected.type,
                picture,
                validUntil: savedDevice.validUntil,
                estado: savedDevice.hardwareAttributes?.incontrolStatus
              };
              user = await this.intelbrasService.saveUser(userToSync, userToSync.residenceToSync, this.groupSelected[0]?.nome_grupo);
            } else {
              this.userSelected.picture = picture;
              this.userSelected.validUntil = savedDevice.validUntil;
              this.userSelected.estado = savedDevice.hardwareAttributes?.incontrolStatus;
              user = await this.intelbrasService.saveUser(this.userSelected, this.residenceSelected, this.groupSelected[0]?.nome_grupo);
            }
            if (this.userSelected.type === 'DEPENDENT') {
              this.dependentService.update(this.condo._id, this.userSelected._id, { externalId: user.id }).subscribe(noop, noop);
            }
            if (savedDevice.type !== 'FACIAL') {
              let incontrolCredential: any = {};
              if (savedDevice.type === 'BM') {
                incontrolCredential.template = this.fingerTemplate.value;
                incontrolCredential.type = savedDevice.type;
                incontrolCredential.coacao = this.panicFinger.value || false;
                incontrolCredential.fingerid = this.finger.value;
                const fingerModel = savedDevice.hardwareAttributes.incontrolFingerModel || 'BIOT';
                incontrolCredential.modelo = { id: fingerModel, descricao: fingerModel };
                savedDevice.hardwareAttributes.incontrolFingerId = this.finger.value;
              } else {
                incontrolCredential = savedDevice;
                incontrolCredential.cardSize = this.cardSize.value;
              }
              return await this.intelbrasService.saveDevice(incontrolCredential, user).then(res => {
                const data = { ...savedDevice, hardwareAttributes: { ...savedDevice.hardwareAttributes } };
                data.hardwareAttributes.incontrolId = res.id;
                if (savedDevice.type == 'BM') {
                  data.internalIds = res.id ? [res.id] : [];
                }
                return data;
              });
            } else {
              return {
                ...savedDevice,
                hardwareAttributes: { ...savedDevice.hardwareAttributes, incontrolId: user.pessoa?.imagem?.id }
              };
            }
          }
          return of(savedDevice);
        }),
        // Atualiza device com id do incontrol
        switchMap((savedDevice: any) => {
          const hardwareAttributes = { ...savedDevice.hardwareAttributes };
          const internalIds = savedDevice.internalIds || [];
          if (savedDevice) {
            return this.deviceService.update(this.condo._id, savedDevice._id, { hardwareAttributes, internalIds }).pipe(
              map(() => ({
                ...savedDevice,
                status: 'SYNCED'
              }))
            );
          } else {
            return of(savedDevice);
          }
        })
      )
      .subscribe(
        (data: any) => {
          // Retorna de callback device criado
          let storedDevice;
          if (data.results && data.results.every(res => res.ok)) {
            storedDevice = data.device;
          } else {
            storedDevice = data;
          }
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(new Device(storedDevice));
          }
          this.status.setAsSuccess();
          this.bsModalRef.hide();
          this.toastrService.success('Dispositivo cadastrado com sucesso');
        },
        err => {
          this.status.setAsError();
          if (saved) {
            this.deviceService.delete(this.condo._id, saved).subscribe(() => {});
          }
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: err.message || 'Não foi possível cadastrar este dispositivo.'
          });
        }
      );
  }

  updateDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .update(this.condo._id, device._id, device)
      .pipe(
        map(() => {
          let storedDevice;
          storedDevice = { ...device };
          if (storedDevice.owner && this.user.value && this.residenceSelected) {
            if (this.userSelected.type === 'DEPENDENT') {
              storedDevice.owner.dependent = this.residenceSelected?.users.find(u => u._id === this.user.value) || null;
            } else {
              storedDevice.owner.user = this.residenceSelected?.users.find(u => u._id === this.user.value) || null;
            }
            storedDevice.owner.residence = this.residenceSelected;
            if (this.picture.value) {
              device.owner.picture = this.picture.value;
            }
          }
          return storedDevice;
        }),
        switchMap(async (savedDevice: any) => {
          if (
            savedDevice?.owner?.user &&
            !savedDevice?.owner?.dependent &&
            savedDevice?.owner?.user?._id !== this.device?.owner?.user?._id
          ) {
            if (this.device.owner.dependent?._id) {
              await this.intelbrasService.removeUserPhoto(this.device.owner.dependent._id);
            }
            if (this.device.owner.user?._id) {
              await this.intelbrasService.removeUserPhoto(this.device.owner.user._id);
            }
            await this.intelbrasService.removeUserPhoto(savedDevice.owner.user._id);
          } else if (
            savedDevice?.owner?.dependent &&
            !savedDevice?.owner?.user &&
            savedDevice?.owner?.dependent?._id !== this.device?.owner?.dependent?._id
          ) {
            if (this.device.owner.dependent?._id) {
              await this.intelbrasService.removeUserPhoto(this.device.owner.dependent._id);
            }
            if (this.device.owner.user?._id) {
              await this.intelbrasService.removeUserPhoto(this.device.owner.user._id);
            }
            await this.intelbrasService.removeUserPhoto(savedDevice.owner.dependent._id);
          }
          return savedDevice;
        }),
        switchMap(async (savedDevice: any) => {
          if (savedDevice) {
            this.groupSelected = this.groups.filter(g => g.id == this.group.value);
            if (!this.groupSelected || !this.groupSelected?.length) {
              this.groupSelected = [];
            }
            let user;
            let picture = null;
            if (savedDevice.type === 'FACIAL') {
              picture = savedDevice.owner.picture;
            }
            if (this.inControlUserList?.length == 1 && this.inControlUserList[0]?.campos_personalizados.campo_5 == this.userSelected._id) {
              const userToSync = {
                id: this.inControlUserList[0].id,
                firstName: this.inControlUserList[0].pessoa?.nome_completo,
                lastName: '',
                _id: this.userSelected._id,
                residenceToSync: {
                  block: this.inControlUserList[0].departamento?.nome,
                  number: this.inControlUserList[0].local_especifico
                },
                picture,
                validUntil: savedDevice.validUntil,
                estado: savedDevice.hardwareAttributes?.incontrolStatus
              };
              user = await this.intelbrasService.saveUser(userToSync, userToSync.residenceToSync, this.groupSelected[0]?.nome_grupo);
            } else {
              this.userSelected.picture = picture;
              this.userSelected.validUntil = savedDevice.validUntil;
              this.userSelected.estado = savedDevice.hardwareAttributes?.incontrolStatus;
              user = await this.intelbrasService.saveUser(this.userSelected, this.residenceSelected, this.groupSelected[0]?.nome_grupo);
            }
            if (savedDevice.type !== 'FACIAL') {
              if (['TA', 'CARD'].includes(savedDevice.type)) {
                savedDevice.cardSize = this.cardSize.value;
              }
              return await this.intelbrasService.saveDevice(savedDevice, user).then(res => {
                const data = { ...savedDevice, hardwareAttributes: { ...savedDevice.hardwareAttributes } };
                if (savedDevice.type == 'BM') {
                  data.internalIds = res.id ? [res.id] : [];
                } else {
                  data.hardwareAttributes.incontrolId = res.id;
                }
                return data;
              });
            } else {
              return {
                ...savedDevice,
                hardwareAttributes: { ...savedDevice.hardwareAttributes, incontrolId: user.pessoa?.imagem?.id }
              };
            }
          }
          return of(savedDevice);
        }),
        switchMap((savedDevice: any) => {
          if (savedDevice) {
            return this.deviceService.sync(this.condo._id, savedDevice._id).pipe(
              map(() => ({
                ...savedDevice,
                status: 'SYNCED'
              }))
            );
          } else {
            return of(savedDevice);
          }
        })
      )
      .subscribe(
        (data: any) => {
          let storedDevice;
          if (data.results && data.results.every(res => res.ok)) {
            storedDevice = data.device;
          } else {
            storedDevice = data;
          }
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(new Device(storedDevice));
          }
          this.status.setAsSuccess();
          this.bsModalRef.hide();
          this.toastrService.success('Dispositivo atualizado com sucesso');
        },
        err => {
          this.status.setAsError();
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: err.message || 'Não foi possível atualizar este dispositivo.'
          });
        }
      );
  }

  selectedResident(resident) {
    this.user.setValue(resident);
    this.userDisabled = !!resident;
  }

  async onResidenceSelected(event) {
    if (!event) {
      this.user.setValue(null);
    }
    this.residenceSelected = event;
    if (this.residenceSelected?.voter) {
      this.residenceVoter = this.residenceSelected.voter;
      const isUser = this.residenceSelected?.users.some(user => user._id === this.residenceSelected.voter._id);
      if (!isUser) {
        this.residenceSelected.users = [].concat([this.residenceSelected.voter], this.residenceSelected.users);
      }
    }
    if (this.residenceSelected && this.residenceSelected.users) {
      const dependents = await this.getResidenceDependents(this.condo, event);
      if (dependents) {
        this.residenceSelected.users.push(...dependents);
        this.residenceSelected.users = orderBy(this.residenceSelected.users, [u => u.name.toLowerCase()]);
      }
    }
  }

  async getResidenceDependents(condo, residence) {
    const dependentsParams = [];
    dependentsParams.push({ '$populate[0][path]': 'picture' });
    dependentsParams.push({ '$populate[0][select]': 'url thumbnail type format name' });
    dependentsParams.push({ $sort: 'name' });
    if (residence.users) {
      residence.users.map((u, i) => {
        dependentsParams.push({ ['_id[$nin][' + i + ']']: u._id });
      });
    }
    return await this.residenceService
      .getDependents(condo._id, residence._id, dependentsParams)
      .pipe(
        map(res =>
          res.dependents.map(dep => {
            const names = (dep.name || '').split(' ').filter(v => v.trim());
            const firstName = names.shift();
            const lastName = names.join(' ');
            return {
              type: 'DEPENDENT',
              _id: dep._id,
              picture: dep.picture,
              name: dep.name,
              firstName,
              lastName
            };
          })
        )
      )
      .toPromise();
  }

  async searchInControlUser(user) {
    this.isLoading = true;
    const users = await this.intelbrasService.getUser(user);
    this.isLoading = false;
    return users;
  }

  findIncontrolUsers(user) {
    return this.intelbrasService.getUser(user);
  }

  async getSyncedUsers() {
    this.userSelected = this.residenceSelected.users.find(u => u._id === this.user?.value);
    if (this.userSelected) {
      this.isLoading = true;
      const users = await this.findIncontrolUsers(this.userSelected);
      if (users?.length) {
        this.inControlUserList = users.filter(e => e.campos_personalizados?.campo_5 === this.userSelected._id);
      }
      this.isLoading = false;
    }
  }

  async syncUserInControl(inControlUser) {
    this.syncingDevices[inControlUser.id] = this.SYNC_STATUS.SYNCING;
    const userToSync = {
      id: inControlUser.id,
      firstName: inControlUser.pessoa?.nome_completo,
      lastName: '',
      _id: this.userSelected._id,
      residenceToSync: {
        block: inControlUser.departamento?.nome,
        number: inControlUser.local_especifico
      }
    };
    this.groupSelected = this.groups.filter(g => g.id == this.group.value);
    if (!this.groupSelected || !this.groupSelected?.length) {
      this.groupSelected = [];
    }
    await this.intelbrasService
      .saveUser(userToSync, userToSync.residenceToSync, inControlUser.pessoa?.grupo?.nome_grupo)
      .then(async res => {
        this.inControlUserList = await this.searchInControlUser(this.userSelected);
        this.syncingDevices[inControlUser.id] = this.SYNC_STATUS.SYNCED;
      });
  }

  async unsyncUserInControl(inControlUser) {
    this.syncingDevices[inControlUser.id] = this.SYNC_STATUS.SYNCING;
    const userToSync = {
      id: inControlUser.id,
      firstName: inControlUser.pessoa?.nome_completo,
      lastName: '',
      _id: null,
      residenceToSync: {
        block: inControlUser.departamento?.nome,
        number: inControlUser.local_especifico
      }
    };
    this.groupSelected = this.groups.filter(g => g.id == this.group.value);
    await this.intelbrasService
      .saveUser(userToSync, userToSync.residenceToSync, inControlUser.pessoa?.grupo?.nome_grupo)
      .then(async res => {
        this.inControlUserList = await this.searchInControlUser(this.userSelected);
        this.syncingDevices[inControlUser.id] = this.SYNC_STATUS.SYNCED;
      });
  }

  async saveUserInControl() {
    swal({
      type: 'question',
      title: 'Criar usuário no InControl',
      text: 'Deseja criar o usuário selecionado no InControl?',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonColor: 'var(--green-500)',
      confirmButtonText: 'Salvar',
      cancelButtonColor: 'var(--red-500)',
      cancelButtonText: 'Cancelar',
      preConfirm: async () => {
        this.groupSelected = this.groups.filter(g => g.id == this.group.value);
        return await this.intelbrasService.saveUser(this.userSelected, this.residenceSelected).then(async res => {
          this.inControlUserList = await this.searchInControlUser(this.userSelected);
        });
      }
    });
  }

  checkHasDeviceOwnerPicture() {
    return this.device?.owner?.picture ? true : false;
  }

  async createGroupInControl() {
    swal({
      type: 'question',
      input: 'text',
      title: 'Criar grupo',
      text: 'Preencha com o nome do grupo que deseja criar:',
      showCancelButton: true,
      showCloseButton: true,
      inputAutoTrim: true,
      inputPlaceholder: 'Nome do grupo',
      reverseButtons: true,
      confirmButtonColor: 'var(--green-500)',
      confirmButtonText: 'Salvar',
      cancelButtonColor: 'var(--red-500)',
      cancelButtonText: 'Cancelar',
      preConfirm: async name => {
        this.group.setValue(
          await this.intelbrasService.createGroup(name).then(res => {
            this.groups.push(res.data);
            return res.data.id;
          })
        );
      }
    });
  }

  openUserAddModal(user) {
    const initialState = {
      user,
      callbacks: {
        success: userIncontrol => {
          this.inControlUserList = userIncontrol;
        }
      }
    };
    this.modalService.show(ModalAddIncontrolUserComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  async collectFingerprint() {
    const actuators = this.registerers;
    let selectedActuator;
    if (actuators.length) {
      const inputOptions: any = actuators.reduce(
        (acc, curr) => {
          acc[curr.id] = curr.nome;
          return acc;
        },
        { TABLE_REGISTER: 'Cadastrador de mesa' }
      );

      try {
        const actuatorId = await swal({
          inputOptions,
          title: 'Equipamento',
          text: 'Selecione o equipamento desejado para a leitura da biometria',
          input: 'select',
          inputPlaceholder: 'Selecione',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          confirmButtonColor: '#32DB64',
          cancelButtonColor: '#f53d3d',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          inputValidator: async res => {
            if (res) {
              return Promise.resolve();
            } else {
              return Promise.reject('Selecione um equipamento!');
            }
          }
        });
        if (actuatorId === 'TABLE_REGISTER') {
          selectedActuator = 'TABLE_REGISTER';
        } else {
          selectedActuator = actuators.find(a => a.id == actuatorId);
        }
      } catch (e) {
        selectedActuator = null;
      }
    } else {
      selectedActuator = 'TABLE_REGISTER';
    }

    if (selectedActuator) {
      if (selectedActuator === 'TABLE_REGISTER') {
        this.collectFingerprintFromTableRegister();
      } else {
        this.fingerTemplate.setValue('');

        const destroyed$ = new Subject();
        const operacao = this.makeid(5);
        const params = {
          dedo: this.finger.value,
          actuatorId: selectedActuator?.id,
          operacao
        };

        const socketUrl = 'wss://localhost:4443/';
        const wsConnection$ = webSocket({ url: socketUrl });

        const onData$ = wsConnection$.pipe(
          timeout(60000),
          map((res: { evento: any }) => res.evento),
          filter((evento: any) => evento.detalhe == 'OnEnrollFinger' && evento.cod_operacao === operacao),
          take(1),
          switchMap(({ cod_operacao, pin }) => {
            swal.showLoading();
            swal.getContent().textContent = `Finalizando processo, aguarde...`;
            swal.getCancelButton().style.display = 'none';
            return this.getTemplate$(selectedActuator.id, cod_operacao == params.operacao, pin);
          })
        );
        let currentPin;
        from(this.intelbrasService.getFingerprint(params))
          .pipe(
            tap(res => {
              currentPin = res.pin;
            }),
            switchMap(() => onData$),
            tap(() => {
              swal.hideLoading();
              swal.clickConfirm();
            }),
            catchError(err => {
              if (err.title !== 'DUPLICATE_FINGERPRINT') {
                err = { title: 'OTHER', message: 'Não foi possível obter a biometria. Tente novamente!' };
              }
              return of(err);
            }),
            takeUntil(destroyed$)
          )
          .subscribe(async (res: any) => {
            if (res?.operacao === operacao) {
              this.fingerTemplate.setValue(res.fingerprint?.template);
              this.fingerModel.setValue(res.fingerprint?.modelo?.id);
            } else {
              if (res.title == 'OTHER' && currentPin) {
                await this.finishRegistrationStep(selectedActuator.id, currentPin);
              }
              swal({
                text: res.message,
                type: 'error',
                title: 'Erro na leitura'
              });
            }
            destroyed$.next(null);
            destroyed$.complete();
          });

        try {
          await swal({
            type: 'info',
            title: 'Aguardando leitura',
            text: `Coloque o dedo no equipamento ${selectedActuator?.nome}`,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar'
          });
        } catch (err) {
          if (err == 'cancel') {
            await this.finishRegistrationStep(selectedActuator.id, currentPin);
          }
        } finally {
          destroyed$.next(null);
          destroyed$.complete();
        }
      }
    }
  }

  getTemplate$(actuatorId, sucesso, pin, finish = false) {
    return from(
      this.intelbrasService.getTemplate({
        data: {
          pin: pin,
          dedo: this.finger.value,
          fingerprint: '',
          sucesso
        },
        actuatorId
      })
    ).pipe(
      timeout(60000),
      mergeMap(res =>
        iif(
          () => sucesso,
          of(res),
          finish
            ? of(false)
            : throwError({
                title: 'DUPLICATE_FINGERPRINT',
                message: 'Biometria duplicada ou não foi possível ler a biometria. Tente novamente!'
              })
        )
      )
    );
  }

  actuators$() {
    this.storedActuatorStatus.setAsProcessing();
    return from(this.intelbrasService.getActuators());
  }

  fingerIds$() {
    const query: EcondosQuery = {
      $select: 'hardwareAttributes',
      type: 'BM',
      hardware: 'INTELBRAS',
      'hardwareAttributes.incontrolFingerId': { $gt: 0 },
      [this.userSelected?.type === 'DEPENDENT' ? 'owner.dependent' : 'owner.user']: this.userSelected?._id
    };
    this.storedFingerStatus.setAsProcessing();
    return this.deviceService
      .get(this.condo._id, query)
      .pipe(map(res => res.devices?.map(device => device.hardwareAttributes.incontrolFingerId)));
  }

  async finishRegistrationStep(actuatorId, pin?) {
    const res = await this.getTemplate$(actuatorId, false, pin || 0, true).toPromise();
    return res;
  }

  getActuators() {
    this.actuators$().subscribe(
      actuators => {
        this.registerers = actuators;
        this.storedActuatorStatus.setAsSuccess();
      },
      error => {
        this.registerers = [];
        this.storedActuatorStatus.setAsError();
      }
    );
  }

  getFingerIds() {
    this.fingerIds$().subscribe(
      fingerIds => {
        this.availableFingers = this.fingers.filter(f => !fingerIds.includes(f));
        this.storedFingerStatus.setAsSuccess();
      },
      error => {
        this.availableFingers = [];
        this.storedFingerStatus.setAsError();
      }
    );
  }

  makeid(length) {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      if (i === 0) {
        result.push(characters.charAt(Math.floor(Math.random() * 25)));
      } else if (i === 1) {
        result.push(characters.charAt(Math.floor(Math.random() * 35)));
      } else {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
      }
    }
    return result.join('');
  }

  collectFingerprintFromTableRegister() {
    const socketUrl = 'ws://localhost:5677/';
    const wsConnection$ = webSocket({ url: socketUrl });
    wsConnection$
      .pipe(
        filter(data => !!data),
        tap((data: any) => {
          switch (data.step) {
            case 1: {
              swal({
                type: 'info',
                title: 'Coloque o dedo novamente',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Cancelar'
              }).catch(() => wsConnection$.complete());
              break;
            }
            case 2: {
              swal({
                type: 'info',
                title: 'Coloque o dedo mais uma vez',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Cancelar'
              }).catch(() => wsConnection$.complete());
              break;
            }
            case 3: {
              swal.clickConfirm();
              this.toastrService.success('Biometria coletada com sucesso');
              break;
            }
          }
        }),
        timeout(30000),
        filter(data => data.step === 3),
        map(response => ({ fingerprint: response.data?.codigo_digital, model: response.modelo }))
      )
      .subscribe(
        ({ fingerprint, model }) => {
          this.fingerTemplate.setValue(fingerprint);
          this.fingerModel.setValue(model);
          wsConnection$.complete();
        },

        err => {
          console.log(err);
          if (err?.name === 'TimeoutError') {
            swal({
              type: 'error',
              title: 'Falha ao coletar biometria',
              text: 'Tempo máximo de coleta excedido, comece o processo novamente.'
            });
          } else if (err?.type === 'error' && err?.srcElement?.url === socketUrl) {
            swal({
              type: 'warning',
              html: `
                  <p>Para utilizar o cadastrador de mesa é necessário instalar o driver da cadastradora.</p>
                  <p>
                    Faça o download do driver de comunicação com a cadastradora de mesa Intelbras <a
                    href="https://files.econdos.com.br/drivers/Driver_biometria_intelbras.rar">
                    clicando aqui
                    </a>
                  </p>
                  <p>Reinicie o processo após a instalação do driver</p>
          `
            });
          } else {
            swal({
              type: 'error',
              title: 'Falha ao coletar biometria',
              text: 'Não foi possível completar o processo de coleta, inicie novamente'
            });
          }
          wsConnection$.complete();
        }
      );

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Coloque o dedo na cadastradora de mesa`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(() => wsConnection$.complete());
  }
}
