import { Residence } from './interface/residence';
import { ResidenceBuilder } from './residence/residence.builder';

export enum KEY_STATUS {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

export interface Key {
  _id?: string;
  id?: string;
  code?: string;
  name?: string;
  gate?: boolean;
  status?: KEY_STATUS;
  createdAt?: string;
  residence?: Residence;
  person?: string;
  borrowDate?: string;
}

export const BuildKey = (key): Key => {
  if (key) {
    if (typeof key === 'string') {
      return {
        _id: key,
        id: key
      };
    } else {
      const myKey: Key = {
        _id: key._id,
        id: key._id,
        code: key.code,
        name: key.name,
        gate: key.gate,
        status: key.status,
        createdAt: key.createdAt,
        residence: key.residence ? ResidenceBuilder.build(key.residence) : null,
        person: key.person,
        borrowDate: key.borrowDate
      };
      return myKey;
    }
  }
};
