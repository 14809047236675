import { Component, OnInit } from '@angular/core';
import { Condo } from '@api/model/condo';
import { DEVICE_TYPES_LABEL } from '@api/model/hardware/device';
import { Residence } from '@api/model/interface/residence';
import { EcondosQuery } from '@api/model/query';
import { Status } from '@api/model/status';
import { ResidenceServiceV2 } from '@api/serviceV2/residence.service';
import { EVENT_TYPES_LABEL } from 'app/pages/gate/gate-events/gate-events-constants/constants';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'modal-residence-hardware-events-log',
  templateUrl: 'modal-residence-hardware-events-log.html'
})
export class ModalResidenceHardwareEventsLogComponent implements OnInit {
  private condo: Condo;
  public residence: Residence;

  public status = new Status();
  public itemsPerPage = 15;
  public page = 1;

  public eventsTotal = 0;
  public events: any[] = [];

  constructor(public bsModalRef: BsModalRef, private residenceService: ResidenceServiceV2) {}

  public ngOnInit(): void {
    this.getData();
  }

  public getData(page = 0): void {
    this.status.setAsDownloading();

    const query: EcondosQuery = {
      $page: page,
      $limit: this.itemsPerPage,
      $sort: '-createdAt'
    };

    const successCallback = data => {
      this.eventsTotal = data.count || this.eventsTotal;
      this.events = this.transformHardwareEvents(data.events);
      this.status.setAsSuccess();
    };

    const errorCallback = err => {
      console.log(err);
      this.status.setAsError();
    };

    this.residenceService.getHardwareEvents(this.condo._id, this.residence._id, query).subscribe(successCallback, errorCallback);
  }

  transformHardwareEvents(events: any[]) {
    return events.map(evt => {
      const event: any = {};

      if (evt.user) {
        event.user = evt.user ? evt.user.firstName + ' ' + evt.user.lastName : '-';
      } else if (evt.createdBy) {
        event.user = evt.createdBy ? evt.createdBy.firstName + ' ' + evt.createdBy.lastName : '-';
      } else {
        event.user = '-';
      }

      event.vehicle = evt.vehicle ? evt.vehicle.brand + '-' + evt.vehicle.plate : '-';
      event.type = EVENT_TYPES_LABEL[evt.eventType] || evt.eventType || '-';
      event.deviceType = DEVICE_TYPES_LABEL[evt.deviceType] || evt.deviceType || '-';
      event.serial = evt.serial || '-';
      event.registeredAt = moment(evt.createdAt).format('DD/MM/YY - HH:mm:ss');
      event.local = evt.local || '-';
      event.eventName = evt.label || '-';
      event._id = evt._id;

      return event;
    });
  }
}
