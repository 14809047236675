import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { interval, noop, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface CacheEntry {
  value: number;
  expiry: number;
}
@Injectable()
export class NoCountInterceptor implements HttpInterceptor {
  private cache = new Map<string, CacheEntry>();

  CACHE_EXPIRATION_TIME = 1000 * 60 * 5; // 5 minutes

  clearCache$: Observable<number>;

  constructor() {
    this.clearCache$ = interval(this.CACHE_EXPIRATION_TIME).pipe(
      tap(() => {
        this.cache.forEach((value, key) => {
          if (value.expiry < Date.now()) {
            this.cache.delete(key);
          }
        });
      })
    );

    this.clearCache$.subscribe(() => noop);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isGetRequest = request.method === 'GET';
    const isApiRequest = request.url.includes('econdos.com.br/api/');
    let countParam = request.params.get('$count');
    if (!countParam) {
      const url = request.url;
      const urlParams = new URLSearchParams(url.split('?')[1]);
      countParam = urlParams.get('$count');
    }
    let pageParam = request.params.get('$page');
    if (!pageParam) {
      const url = request.url;
      const urlParams = new URLSearchParams(url.split('?')[1]);
      pageParam = urlParams.get('$page');
    }
    if (isGetRequest && isApiRequest && !countParam && pageParam) {
      const params: HttpParams = request.params;
      const paramsArray = params.keys().map(key => ({ key, value: params.get(key) }));
      let cacheKey;

      if (paramsArray.length > 0) {
        // Pega todos parâmetros da requisição, exceto o $page
        const paramsWithoutPage = paramsArray.filter(param => param.key !== '$page');
        // Cria a chave do cache com a url e os parâmetros sem o $page
        cacheKey = request.url + paramsWithoutPage.map(param => `${param.key}=${param.value}`).join('');
      } else {
        const urlString = request.url;
        const url = new URL(urlString);
        url.searchParams.delete('$page');
        cacheKey = url.toString();
      }

      const { value: cachedCount } = this.cache.get(cacheKey) || {};

      // Se o count já estiver no cache, adiciona seta o $count para false para que o backend não faça o count novamente
      const isCached = cachedCount !== undefined;
      if (pageParam !== '0' && isCached) {
        request = request.clone({
          params: request.params.set('$count', 'false')
        });
        return next.handle(request).pipe(
          map((response: HttpEvent<any>) => {
            if (response instanceof HttpResponse) {
              response = response.clone({ headers: response.headers.set('count', cachedCount.toString()) });
            }
            return response;
          })
        );
      }

      return next.handle(request).pipe(
        map(event => {
          if (event instanceof HttpResponse) {
            const count = event.headers.get('count');
            if (count) {
              this.cache.set(cacheKey, { value: parseInt(count, 10), expiry: Date.now() + this.CACHE_EXPIRATION_TIME });
            }
          }
          return event;
        })
      );
    }

    return next.handle(request);
  }
}
