import { Component, ViewChild, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilService } from '../../../../services/util.service';
import { timeout } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { CondoContact } from '@api/model/contact/condo.contact';
import { VehicleCreateModal } from '../../../modal/vehicle.create.modal/vehicle.create.modal';
import { ContactID } from '@api/model/contact/contact.id';
import { Vehicle } from '@api/model/vehicle';
import { Subject } from 'rxjs';
import { FileService } from '@api/service/file.service';
import { ToastrService } from 'ngx-toastr';
import { CondoContactService } from '@api/service/condo.contact.service';
import { EcondosQuery } from '@api/model/query';
import * as moment from 'moment';
import { capitalize, sortFunction } from '@api/util/util';
import { AccessServiceV2 } from '@api/serviceV2/access.service';

@Component({
  selector: 'app-modal-condo-contact-details',
  templateUrl: 'modal-condo-contact-details.html',
  styleUrls: ['modal-condo-contact-details.scss']
})
export class ModalCondoContactDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('vehicleCreateModal', { static: true }) vehicleCreateModal: VehicleCreateModal;

  @Input() condo: Condo;
  @Input() user: User;

  callback: (condoContact) => void;

  documentFrontPicture;
  documentBackPicture;

  condoContact: CondoContact;
  isSubmitting = false;

  loadStatus = false;
  phones;
  ID_LABELS = ContactID.TYPES_LABEL;
  VEHICLE_TYPES = Vehicle.TYPES;
  accessCount;

  fields;
  fieldsKeys;
  filteredData;
  sort: {
    field: string;
    order: 'asc' | 'desc' | '';
  } = { field: 'name', order: '' };
  contactTypes: { key: string; value: string }[] = [];

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private utilService: UtilService,
    private bsModalRef: BsModalRef,
    private fileService: FileService,
    private toaster: ToastrService,
    private condoContactService: CondoContactService,
    private cdr: ChangeDetectorRef,
    private accessServiceV2: AccessServiceV2
  ) {
    this.fields = {
      visitorType: { value: 'visitorType', label: 'Tipo da visita', checked: true },
      residence: { value: 'residence', label: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade', checked: true },
      vehicle: { value: 'vehicle', label: 'Veículo', checked: true },
      type: { value: 'type', label: 'Tipo de acesso', checked: true },
      dateTime: { value: 'dateTime', label: 'Data e hora', checked: true, sortFunction: this.sortDateTime },
      approvedBy: { value: 'approvedBy', label: 'Liberado por', checked: true },
      createdBy: { value: 'createdBy', label: 'Registrado por', checked: true }
    };
    this.fieldsKeys = Object.keys(this.fields);
  }

  ngOnInit(): void {
    if (!this.user) {
      this.user = this.utilService.getLocalUser();
    }
    const isGatekeeperOrAdmin = this.user.isGatekeeper() || this.user.isAdmin() || this.user.isOwner();
    const { availableVisitorTypes } = this.condo.generalParams.accessLiberation;
    if (isGatekeeperOrAdmin) {
      Object.keys(availableVisitorTypes).map(key => (this.contactTypes[key] = CondoContact.TYPES_LABEL[key]));
    } else {
      Object.keys(availableVisitorTypes)
        .filter(key => availableVisitorTypes[key])
        .map(key => (this.contactTypes[key] = CondoContact.TYPES_LABEL[key]));
    }
    if (this.condoContact.birthDate) {
      this.condoContact.birthDate = moment(this.condoContact.birthDate).format('DD/MM/YYYY');
    }
    if (this.condoContact.ids && this.condoContact.ids.length) {
      const contactId = this.condoContact.ids.find(id => id.type === ContactID.TYPES.RG) || this.condoContact.ids[0];
      if (contactId.pictures && contactId.pictures.length) {
        this.documentFrontPicture = contactId.pictures[0] || null;
        this.documentBackPicture = contactId.pictures[1] || null;
      }
    }
    this.phones = this.condoContact.phones;

    let query: EcondosQuery = {
      $select: 'accessType type inDate outDate obs approvedByName',
      $populate: [
        { path: 'condoContact', select: 'firstName lastName specialNeedsDetails specialNeeds service' },
        { path: 'user', select: 'firstName lastName' },
        { path: 'userContact', select: 'firstName lastName' },
        { path: 'residence', select: 'identification type number' },
        { path: 'approvedBy', select: 'firstName lastName' },
        { path: 'condoVehicle', select: 'plate model' },
        { path: 'createdBy', select: 'firstName lastName' }
      ],
      $sort: '-createdAt',
      condoContact: this.condoContact._id,
      $limit: 10
    };

    this.accessServiceV2
      .getAccesses(this.condo._id, query)
      .pipe(timeout(15000))
      .subscribe(({ count, accesses }) => {
        this.accessCount = count;
        this.filteredData = this.transformResultAndShowData(accesses);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  sortData(data, field: string, changeOrder = true) {
    if (changeOrder) {
      if (this.sort.field !== field) {
        this.sort.field = field;
        this.sort.order = 'asc';
      } else {
        this.sort.order === 'asc' ? (this.sort.order = 'desc') : (this.sort.order = 'asc');
      }
    }

    if (this.fields[field] && this.fields[field].sortFunction) {
      const sort = this.fields[field].sortFunction;
      data.sort((a, b) => (this.sort.order === 'asc' ? sort(a[field], b[field]) : sort(b[field], a[field])));
    } else {
      data.sort((a, b) => (this.sort.order === 'asc' ? sortFunction(a[field], b[field]) : sortFunction(b[field], a[field])));
    }
  }

  sortDateTime(a, b) {
    const dateFormat = 'DD/MM/YYYY - HH:mm:ss';
    a = moment(a, dateFormat).toISOString();
    b = moment(b, dateFormat).toISOString();

    return b.localeCompare(a);
  }

  transformResultAndShowData(accesses) {
    return accesses
      .map((v, index) => {
        let visitor = {
          visitorType: v.typeLabel,
          residence: v.residence ? v.residence.identification : capitalize(this.condo?.customLabels?.condo?.singular),
          vehicle: v.condoVehicle ? (v.condoVehicle.model ? v.condoVehicle.model + ' - ' : '') + v.condoVehicle.plate : '-'
        };
        if ((!!accesses[0]?.inDate && index == 0) || (!!v?.inDate && !!accesses[index - 1]?.inDate)) {
          return {
            ...visitor,
            inApprovedBy: v.approvedByName || (v.approvedBy && v.approvedBy.fullName) || '-',
            inCreatedBy: v.createdBy ? `${v.createdBy?.firstName} ${v.createdBy?.lastName}` : 'Equipamento',
            inDate: moment(v.inDate).format('DD/MM/YYYY - HH:mm:ss')
          };
        } else if (!!v?.outDate && !!accesses[index + 1]?.outDate) {
          return {
            ...visitor,
            outApprovedBy: v.approvedByName || (v.approvedBy && v.approvedBy.fullName) || '-',
            outCreatedBy: v.createdBy ? `${v.createdBy?.firstName} ${v.createdBy?.lastName}` : 'Equipamento',
            outDate: moment(v.outDate).format('DD/MM/YYYY - HH:mm:ss')
          };
        } else if (!!v?.outDate && !!accesses[index + 1]?.inDate) {
          return {
            ...visitor,
            inApprovedBy:
              accesses[index + 1].approvedByName || (accesses[index + 1].approvedBy && accesses[index + 1].approvedBy.fullName) || '-',
            outApprovedBy: v.approvedByName || (v.approvedBy && v.approvedBy.fullName) || '-',
            inCreatedBy: v.createdBy ? `${v.createdBy?.firstName} ${v.createdBy?.lastName}` : 'Equipamento',
            outCreatedBy: accesses[index + 1].createdBy
              ? `${accesses[index + 1].createdBy?.firstName} ${accesses[index + 1].createdBy?.lastName}`
              : 'Equipamento',
            outDate: moment(v.outDate).format('DD/MM/YYYY - HH:mm:ss'),
            inDate: moment(accesses[index + 1].inDate).format('DD/MM/YYYY - HH:mm:ss')
          };
        } else {
          return false;
        }
      })
      .filter(v => v)
      .filter((v, index) => index < 5);
  }

  unmaskField(field: string) {
    let fieldToSearch = '';
    if (field === 'phone') {
      fieldToSearch = 'phones';
    }
    if (field === 'email') {
      fieldToSearch = 'emails';
    }
    if (field === 'id') {
      fieldToSearch = 'ids';
    }
    if (field === 'birthDate') {
      fieldToSearch = field;
    }

    const query: EcondosQuery = {
      $select: fieldToSearch,
      $and: []
    };

    let callback;

    switch (field) {
      case 'email':
        callback = ({ data }) => {
          if (data[0]) {
            this.condoContact.emails = data;
          }
        };
        break;

      case 'phone':
        callback = ({ data }) => {
          this.phones = data.map(phone => this.utilService.formatPhoneToShow(phone));
        };
        break;

      case 'id':
        callback = ({ data }) => {
          this.condoContact.ids = data;
        };
        break;

      case 'birthDate':
        callback = ({ data }) => {
          if (data) {
            this.condoContact.birthDate = moment(data).format('YYYY-MM-DD');
          }
        };
        break;
    }

    this.condoContactService
      .getCondoContactUnmaskedField(this.condo._id, this.condoContact._id, fieldToSearch, query)
      .pipe(timeout(10000))
      .subscribe(callback);
  }
}
