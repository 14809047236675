/**
 * Created by Rafael on 24/12/2016.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SessionService } from '@api/service/session.service';

@Injectable({ providedIn: 'root' })
export class NavbarService {
  private _showNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public showNavBarEmitter: Observable<boolean> = this._showNavBar.asObservable().pipe(distinctUntilChanged());

  constructor(sessionService: SessionService) {
    sessionService.user$.subscribe(user => {
      const showNavbar = user && !user.isOnSignUpStepOne() && !user.isOnSignUpStepTwo();
      if (showNavbar) {
        this.showNavBar();
      } else {
        this.hideNavBar();
      }
    });
  }

  showNavBar() {
    this._showNavBar.next(true);
  }

  hideNavBar() {
    this._showNavBar.next(false);
  }

}
