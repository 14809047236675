import { Construction, ConstructionFormData } from './../model/construction';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmergencyContact, Residence } from '../model/interface/residence';
import { ResidenceBuilder } from '../model/residence/residence.builder';
import { User } from '../model/user';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { map } from 'rxjs/operators';
import { Dependent } from '../model/dependent';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Pet } from '../model/pet';
import { CondoVehicle } from '../model/condo.vehicle';
import { EcondosQuery } from '../model/query';
import * as qs from 'qs';
import { downloadDataInChunks } from '@api/utils';
import { HardwareEvent } from '@api/model/hardware/hardware-event';
import { Receipt } from '@api/model/receipt';
import { File } from '../model/file';

@Injectable()
export class ResidenceService {
  protected endPoint;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /**
   * @deprecated
   * Use getUsersFromResidence instead.
   */
  getResidenceUsers(condoId: string, residenceId: string, queryString?: string): Observable<Array<User>> {
    return this.http
      .get(this.endPoint + condoId + '/residences/' + residenceId + '/users' + (queryString || ''))
      .pipe(map((users: any) => users.map(u => new User(u))));
  }

  getUsersFromResidence(condoId: string, residenceId: string, params: EcondosQuery = {}) {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/residences/${residenceId}/users`, options).pipe(
      map((response: any) => {
        if (params.$count) {
          return {
            count: response.body.length,
            users: []
          };
        } else {
          return {
            users: response.body.map(u => new User(u))
          };
        }
      })
    );
  }

  removeUserFromResidence(condoId: string, residenceId: string, userId: string) {
    return this.http.delete(this.endPoint + condoId + '/residences/' + residenceId + '/users/' + userId);
  }

  addUserToResidence(condoId: string, residenceId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/users/${userId}`, {});
  }

  approveUserOnResidence(condoId: string, residenceId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/users/${userId}/approve`, {});
  }

  rejectUserOnResidence(condoId: string, residenceId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/users/${userId}/reject`, {});
  }

  changeResidenceVoter(condoId: string, residenceId: string, userId: string) {
    return this.http.put(this.endPoint + condoId + '/residences/' + residenceId + '/users/' + userId + '/voter', {});
  }

  removeVoterFromResidence(condoId: string, residenceId: string) {
    return this.http.delete(this.endPoint + condoId + '/residences/' + residenceId + '/voter/');
  }

  /**
   * @deprecated Use getResidences instead.
   */
  getResidencesFromCondo(condoId: string, queryString?: string): Observable<Array<Residence>> {
    return this.http
      .get(this.endPoint + condoId + '/residences' + (queryString || ''))
      .pipe(map((residences: any) => residences.map(res => ResidenceBuilder.build(res))));
  }

  getResidences(condoId: string, query: EcondosQuery): Observable<{ count: number; residences: Residence[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/residences`, {
        params
      })
      .pipe(
        map((res: any) => {
          if (query.$count) {
            return {
              count: res.body,
              residences: []
            };
          } else {
            return {
              count: res.headers.get('count'),
              residences: res.body.map(r => ResidenceBuilder.build(r))
            };
          }
        })
      );
  }

  searchByToken(condoId: string, token: string, query: EcondosQuery = {}): Observable<{ count: number; residences: Residence[] }> {
    query.token = token;
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/residences/searchToken`, { params }).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        residences: res.body.map(r => ResidenceBuilder.build(r))
      }))
    );
  }

  getResidencesIdentification(
    condoId: string
  ): Observable<{ count: number; residencesIdentification: { _id: string; identification: string }[] }> {
    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/residences/identification`).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        residencesIdentification: res.body
      }))
    );
  }

  getNumberOfResidencesFromCondo(condoId: string): Observable<any> {
    return this.http.get(this.endPoint + condoId + '/residences' + '?$count=true');
  }

  countCondoResidences(condoId: string): Observable<number> {
    return this.http.get(this.endPoint + condoId + '/residences?$count=true') as Observable<number>;
  }

  createResidenceOnCondo(condoId: string, residence: any) {
    return this.http.post(this.endPoint + condoId + '/residences', residence);
  }

  deleteResidenceFromCondo(condoId: string, residenceId: string) {
    return this.http.delete(this.endPoint + condoId + '/residences/' + residenceId);
  }

  /**
   * @deprecated Use getResidenceByIdWithParams instead.
   */
  getResidenceById(condoId: string, residenceId: string, queryString?: string): Observable<Residence> {
    return this.http
      .get(this.endPoint + condoId + '/residences/' + residenceId + (queryString || ''))
      .pipe(map(residence => ResidenceBuilder.build(residence)));
  }

  getResidenceByIdWithParams(condoId: string, residenceId: string, params: EcondosQuery): Observable<Residence> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .get(this.endPoint + condoId + '/residences/' + residenceId, { params: httpParams })
      .pipe(map(residence => ResidenceBuilder.build(residence)));
  }

  updateResidence(condoId: string, residenceId: string, residence: any) {
    return this.http.put(this.endPoint + condoId + '/residences/' + residenceId, residence);
  }

  addDependent(condoId: string, residenceId: string, dependent: any) {
    return this.http.post(`${this.endPoint}${condoId}/residences/${residenceId}/dependents`, dependent);
  }

  updateDependent(condoId: string, residenceId: string, dependentId: string, dependent: any) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/dependents/${dependentId}`, dependent);
  }

  removeDependent(condoId: string, residenceId: string, dependentId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/residences/${residenceId}/dependents/${dependentId}`);
  }

  /**
   * @Deprecated use getResidenceDependents
   * @param condoId
   * @param residenceId
   * @param params
   */
  getDependents(
    condoId: string,
    residenceId: string,
    params: Array<{ [key: string]: string }> = []
  ): Observable<{ count: number; dependents: Dependent[] }> {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/residences/${residenceId}/dependents`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            dependents: res.body.map(dep => new Dependent(dep))
          };
        })
      );
  }

  getResidenceDependents(
    condoId: string,
    residenceId: string,
    query: EcondosQuery = {}
  ): Observable<{ count: number; dependents: Dependent[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/residences/${residenceId}/dependents`, { params }).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count'),
          dependents: res.body.map(dep => new Dependent(dep))
        };
      })
    );
  }

  addPet(condoId: string, residenceId: string, pet: any) {
    return this.http.post(`${this.endPoint}${condoId}/residences/${residenceId}/pets`, pet);
  }

  updatePet(condoId: string, residenceId: string, petId: string, pet: any) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/pets/${petId}`, pet);
  }

  removePet(condoId: string, residenceId: string, petId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/residences/${residenceId}/pets/${petId}`);
  }

  getPets(condoId: string, residenceId: string, params: Array<{ [key: string]: string }> = []): Observable<{ count: number; pets: Pet[] }> {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/residences/${residenceId}/pets`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            pets: res.body.map(p => new Pet(p))
          };
        })
      );
  }

  addVehicle(condoId: string, residenceId: string, vehicle: any) {
    return this.http.post(`${this.endPoint}${condoId}/residences/${residenceId}/vehicles`, vehicle);
  }

  updateVehicle(condoId: string, residenceId: string, vehicleId: string, vehicle: any) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/vehicles/${vehicleId}`, vehicle);
  }

  removeVehicle(condoId: string, residenceId: string, vehicleId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/residences/${residenceId}/vehicles/${vehicleId}`);
  }

  getVehicles(
    condoId: string,
    residenceId: string,
    query: { [key: string]: string }[] | EcondosQuery = []
  ): Observable<{ count: number; vehicles: CondoVehicle[] }> {
    let params;
    if (Array.isArray(query)) {
      params = new HttpParams();
      for (const param of query as any) {
        params = params.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
      }
    } else {
      params = new HttpParams({ fromString: qs.stringify(query) });
    }
    const options = {
      params,
      observe: 'response' as 'body'
    };

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/residences/${residenceId}/vehicles`, options).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count'),
          vehicles: res.body.map(v => new CondoVehicle(v))
        };
      })
    );
  }

  getDocuments(condoId: string, residenceId: string, query: EcondosQuery): Observable<{ count: number; files: File[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params: params,
      observe: 'response' as 'body'
    };
    return this.http
      .get(`${this.endPoint}${condoId}/residences/${residenceId}/documents`, options)
      .pipe(map((res: any) => ({ count: res.headers.get('count'), files: res.body.files || res.body })));
  }

  addDocument(condoId: string, residenceId: string, fileId: string | string[]) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/documents`, { documents: [].concat(fileId) });
  }

  removeDocument(condoId: string, residenceId: string, documentId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/residences/${residenceId}/documents/${documentId}`);
  }

  getResidencesReport(condoId: string, query: EcondosQuery) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/residences`, query, {});
  }

  getHardwareEvents(
    condoId: string,
    residenceId: string,
    params: EcondosQuery = {}
  ): Observable<{ count: number; events: HardwareEvent[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http
      .get(`${this.endPoint}${condoId}/residences/${residenceId}/hardware-events`, options)
      .pipe(map((res: any) => ({ count: res.headers.get('count'), events: res.body })));
  }

  updatePanicEvent(condoId: string, residenceId: string, residentCanUsePanicEvent: boolean) {
    return this.http.put(this.endPoint + condoId + '/residences/' + residenceId + '/panic-events', { residentCanUsePanicEvent });
  }

  updateEmergencyContact(condoId: string, residenceId: string, emergencyContacts: EmergencyContact[]) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/emergency-contacts`, { emergencyContacts });
  }

  createConstruction(condoId: string, residenceId: string, construction: ConstructionFormData): Observable<any> {
    return this.http.post(`${this.endPoint}${condoId}/residences/${residenceId}/constructions`, construction);
  }

  updateConstruction(condoId: string, residenceId: string, constructionId: string, construction: ConstructionFormData) {
    return this.http.put(`${this.endPoint}${condoId}/residences/${residenceId}/constructions/${constructionId}`, construction);
  }

  deleteConstruction(condoId: string, residenceId: string, constructionId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/residences/${residenceId}/constructions/${constructionId}`);
  }

  getConstructions(
    condoId: string,
    residenceId: string,
    query: EcondosQuery
  ): Observable<{ count: number; constructions: Construction[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/residences/${residenceId}/constructions`, {
        params
      })
      .pipe(
        map((res: any) => {
          if (query.$count) {
            return {
              count: res.headers.get('count'),
              constructions: []
            };
          } else {
            return {
              count: res.headers.get('count'),
              constructions: res.body.map(r => new Construction(r))
            };
          }
        })
      );
  }

  getConstructionByIdWithParams(
    condoId: string,
    residenceId: string,
    constructionId: string,
    params: EcondosQuery
  ): Observable<Construction> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .get(`${this.endPoint}${condoId}/residences/${residenceId}/constructions/${constructionId}`, { params: httpParams })
      .pipe(map(construction => new Construction(construction)));
  }

  getReceipts(condoId: string, residenceId: string, query: EcondosQuery = {}): Observable<Receipt[]> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get(`${this.endPoint}${condoId}/residences/${residenceId}/2aViaBoleto`, { params }) as Observable<Receipt[]>;
  }

  getResidenceUsersReport(condoId: string, residenceId: string, query: EcondosQuery = {}) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/residences/${residenceId}/users`, query, { model: User });
  }

  getResidenceVehiclesReport(condoId: string, residenceId: string, query: EcondosQuery = {}) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/residences/${residenceId}/vehicles`, query, { model: CondoVehicle });
  }

  getResidenceDependentsReport(condoId: string, residenceId: string, query: EcondosQuery = {}) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/residences/${residenceId}/dependents`, query, { model: Dependent });
  }
}
