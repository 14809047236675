import { UtilService } from '../../../services/util.service';
import { Component, OnInit } from '@angular/core';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { DatePipe, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { WEEK_DAYS } from '@api/util/constants';
import { Contact } from '@api/model/interface/contact';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ComponentsModule } from 'app/components/components.module';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { catchError, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CondoContact } from '@api/model/contact/condo.contact';
import { of } from 'rxjs';
import { EcondosQuery } from '@api/model/query';
import { VisitorRequest } from '@api/model/interface/visitor.request';
import { VisitorSelfLiberationService } from '@api/serviceV3/visitor-self-liberation-service';
import { DirectivesModule } from '../../../directives/directives.module';
import { ContactID } from '@api/model/contact/contact.id';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'app-liberation-info-modal',
  templateUrl: './liberation-person-modal.component.html',
  styleUrls: ['./liberation-person-modal.component.scss'],
  imports: [
    DatePipe,
    NgForOf,
    NgIf,
    AccordionModule,
    ComponentsModule,
    NgTemplateOutlet,
    TooltipModule,
    NgSwitch,
    NgSwitchCase,
    DirectivesModule
  ],
  standalone: true
})
export class LiberationPersonModalComponent implements OnInit {
  WEEK_DAYS = WEEK_DAYS;
  public condo: Condo;
  public user: User;
  public occurrence;
  public visitorRequest;

  ID_LABELS = ContactID.TYPES_LABEL;

  registeredContact: CondoContact;

  availableFields: Condo['generalParams']['accessLiberation']['availableFields'];
  contactLabels = Contact.TYPES_LABEL;

  status: 'ACCEPTED' | 'REJECTED' | 'PENDING' | 'CANCELED' | 'CLOSED';
  loadStatus: 'LOADING' | 'LOADED' | 'ERROR' = 'LOADING';

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private sessionService: SessionService,
    private toastr: ToastrService,
    protected utilService: UtilService,
    private visitorService: VisitorSelfLiberationService
  ) {
    this.user = this.sessionService.userValue;
    this.condo = this.sessionService.condoValue;
  }

  ngOnInit() {
    this.availableFields = this.condo?.generalParams?.accessLiberation?.availableFields;

    const query: EcondosQuery = {
      $select: 'occurrence registeredContact approvedAt rejectedAt status',
      $populate: [{ path: 'registeredContact', select: 'picture', populate: [{ path: 'picture', select: 'url thumbnail' }] }],
      _id: this.visitorRequest._id
    };
    this.visitorService.getVisitorRequest(this.condo.id, query).subscribe((visitorRequest: VisitorRequest[]) => {
      this.registeredContact = visitorRequest[0].registeredContact;
      this.loadStatus = 'LOADED';
      if (this.occurrence.status === 'CANCELED') {
        this.status = 'CLOSED';
      } else {
        this.status = visitorRequest[0].status;
      }
    });
  }
  rejectVisitor(visitorRequest) {
    return this.visitorService.rejectVisitorRequest(this.condo.id, visitorRequest._id).pipe(
      timeout(10000),
      catchError(() => {
        this.toastr.error('Não foi possivel atualizar o visitante');
        return of(false);
      })
    );
  }
  askToRejectVisitor() {
    swal({
      type: 'question',
      title: `Recusar pedido da liberação`,
      text: `Deseja realmente recusar esse visitante da liberação de acesso?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.rejectVisitor(this.visitorRequest)
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível cancelar, tente novamente.');
          });
      }
    }).then(
      () => {
        this.toastr.success(`Pessoa recusada com sucesso!`);
        this.modalService.hide();
      },
      () => {
        console.log('clicked cancel');
      }
    );
  }

  askToAddVisitorToLiberation() {
    swal({
      type: 'question',
      title: `Aceitar pessoa na liberação`,
      text: `Deseja realmente aceitar essa pessoa na liberação de acesso?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.visitorService
          .acceptVisitorRequest(this.condo.id, this.visitorRequest._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível salvar, tente novamente.');
          });
      }
    }).then(
      () => {
        this.modalService.hide();
      },
      () => {
        console.log('clicked cancel');
      }
    );
  }
}
