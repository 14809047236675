import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyPipe' })
export class CurrencyPipe implements PipeTransform {
  transform(
    value: number = 0,
    currencySign: string = 'R$ ',
    decimalLength: number = 2,
    chunkDelimiter: string = '.',
    decimalDelimiter: string = ',',
    chunkLength: number = 3
  ): string {
    try {
      const result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
      const num = value.toFixed(Math.max(0, ~~decimalLength));
      return (
        currencySign + (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter)
      );
    } catch (e) {
      console.log(e);
      return currencySign + '0' + decimalDelimiter + '00';
    }
  }
}
