import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DevicesRequest } from '@api/model/devices-requests';
import { EcondosQuery } from '@api/model/query';
import { ConstantService } from 'app/services/constant.service';
import { HttpService } from 'app/services/http.service';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DevicesRequestServiceV2 {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;
  }

  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; devicesRequests: DevicesRequest[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/device-requests`, options).pipe(
      map((response: any) => {
        return {
          count: response.headers.get('count'),
          devicesRequests: response.body.map(t => new DevicesRequest(t))
        };
      })
    );
  }

  getDevicesRequestById(condoId: string, devicesRequestId: string, params: EcondosQuery = {}): Observable<DevicesRequest> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/device-requests/${devicesRequestId}`, options).pipe(
      map((response: any) => {
        return new DevicesRequest(response.body);
      })
    );
  }

  approveDevicesRequest(condoId: string, devicesRequestId: string, requestStatusDescription: string): Observable<{}> {
    return this.http.put(`${this.endPoint}${condoId}/device-requests/${devicesRequestId}/approve`, { requestStatusDescription });
  }

  rejectDevicesRequest(condoId: string, devicesRequestId: string, requestStatusDescription: string): Observable<{}> {
    return this.http.put(`${this.endPoint}${condoId}/device-requests/${devicesRequestId}/reject`, { requestStatusDescription });
  }

  cancelDevicesRequest(condoId: string, devicesRequestId: string, requestStatusDescription: string): Observable<{}> {
    return this.http.put(`${this.endPoint}${condoId}/device-requests/${devicesRequestId}/cancel`, { requestStatusDescription });
  }

  createDevicesRequest(condoId: string, devicesRequest: DevicesRequest): Observable<{}> {
    return this.http.post(`${this.endPoint}${condoId}/device-requests`, devicesRequest);
  }
  updateDevicesRequest(condoId: string, devicesRequestId: string, data: DevicesRequest): Observable<{}> {
    return this.http.put(`${this.endPoint}${condoId}/device-requests/${devicesRequestId}`, data);
  }
}
