import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';
import { ChatServiceV2 } from '@api/serviceV2/chat.service';
import { ResidenceServiceV2 } from '@api/serviceV2/residence.service';
import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';
import { ResidenceAutoCompleteComponent } from '../../components/residence-auto-complete/residence-auto-complete.component';

type LoadingStatus = 'LOADING' | 'PROCESSING' | 'SUCCESS' | 'ERROR';

@Component({
  selector: 'get-or-create-chat-room',
  templateUrl: 'get-or-create-chat-room.html',
  styleUrls: ['get-or-create-chat-room.scss']
})
export class GateChatGetOrCreateChatRoomComponent {
  @Input() condo: Condo;
  @Input() fontSize: 'normal' | 'large' | 'extra-large' = 'normal';
  @Output() onCloseButtonClick: EventEmitter<null> = new EventEmitter();
  @Output() onChatRoomSelectCallback: EventEmitter<null> = new EventEmitter();
  @Output() onChatRoomSelectSuccessCallback: EventEmitter<null> = new EventEmitter();
  @Output() onChatRoomSelectErrorCallback: EventEmitter<null> = new EventEmitter();

  getOrCreateChatForm: UntypedFormGroup;
  residence: AbstractControl;
  resident: AbstractControl;
  @ViewChild(ResidenceAutoCompleteComponent) residencePicker: ResidenceAutoCompleteComponent;
  residenceUsers = [];
  getResidenceUsersLoadingStatus: LoadingStatus;
  getOrCreateChatLoadingStatus: LoadingStatus;

  constructor(private formBuilder: UntypedFormBuilder, private residenceService: ResidenceServiceV2, public chatService: ChatServiceV2) {
    this.initializeForm();
  }

  hide() {
    this.onCloseButtonClick.emit();
  }

  initializeForm() {
    this.getOrCreateChatForm = this.formBuilder.group({
      residence: ['', Validators.required],
      resident: ['', Validators.required]
    });

    this.residence = this.getOrCreateChatForm.get('residence');
    this.resident = this.getOrCreateChatForm.get('resident');
  }

  resetForm() {
    this.residence.setValue('');
    this.residence.markAsUntouched();
    this.residencePicker.resetValue();
    this.resident.setValue('');
    this.resident.markAsUntouched();
  }

  onResidenceSelect(residence) {
    this.residence.setValue(residence?._id || '');

    if (residence) {
      this.getResidenceUsersLoadingStatus = 'LOADING';
      this.residence.markAsTouched();

      const query: EcondosQuery = {
        $select: 'firstName lastName',
        $populate: [
          {
            path: 'picture',
            select: 'url thumbnail type name format'
          }
        ]
      };

      this.residenceService
        .getUsersFromResidence(this.condo._id, residence._id, query)
        .pipe(timeout(10_000))
        .subscribe(
          ({ users }) => {
            this.getResidenceUsersLoadingStatus = 'SUCCESS';
            this.residenceUsers = users;
          },
          error => {
            this.getResidenceUsersLoadingStatus = 'ERROR';
            this.residenceUsers = [];
          }
        );
    } else {
      this.residenceUsers = [];
    }
  }

  getOrCreateChat(resident) {
    this.resident.setValue(resident._id);

    if (this.getOrCreateChatForm.valid) {
      this.getOrCreateChatLoadingStatus = 'PROCESSING';

      this.onChatRoomSelectCallback.emit();

      const identification = `${this.condo._id}-${this.residence.value}-${this.resident.value}`;

      this.chatService
        .getOrCreateChatRoom(this.condo._id, identification)
        .pipe(timeout(10000))
        .subscribe(
          (response: { _id: string }) => {
            this.getOrCreateChatLoadingStatus = 'SUCCESS';
            this.resetForm();
            this.onChatRoomSelectSuccessCallback.emit();
          },
          error => {
            this.getOrCreateChatLoadingStatus = 'ERROR';
            this.onChatRoomSelectErrorCallback.emit();

            swal({
              type: 'error',
              text: error.originalError?.message
                ? error.originalError.message
                : 'Não foi possível buscar a conversa, verifique sua conexão e tente novamente'
            });
          }
        );
    } else {
      Object.keys(this.getOrCreateChatForm.controls).forEach(key => this.getOrCreateChatForm.get(key).markAsTouched());
    }
  }
}
