import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from '@api/service/session.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

@Injectable({ providedIn: 'root' })
export class HardwareRoutesGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private router: Router
  ) {}

  canActivate() {
    const user = this.sessionService.userValue;
    const condo = this.sessionService.condoValue;

    const hasDefaultRolePermission = user.isOwnerOnCondo(condo.id) || user.isAdminOnCondo(condo.id) || user.isGatekeeperOnCondo(condo.id);
    const hasAlarmCentralViewPermission = user.getPermissionValue({ condoId: condo.id, permission: PERMISSIONS.alarmCentrals.view });
    const isAlarmCentralsEnabledOnCondo = !condo.isAlarmCentralDisabled();

    if (hasDefaultRolePermission || (hasAlarmCentralViewPermission && isAlarmCentralsEnabledOnCondo)) {
      return true;
    }

    this.router.navigate(['/no-permission-error']);
    return false;
  }
}
