import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ChatRoom } from '@api/model/chat-room';
import { ChatMessage } from '@api/model/chat-message';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { ChatServiceV2 } from '@api/serviceV2/chat.service';
import { UtilService } from 'app/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { noop, Observable } from 'rxjs';
import { HardwareSocketService, SOCKET_CONNECTION_STATUS } from '../../services/hardware-socket.service';
import { KeyValue } from '@angular/common';
import * as moment from 'moment';

interface SendMessageRequestForm {
  messageBody: string;
  replyTo?: string;
}

type LoadingStatus = 'LOADING' | 'PROCESSING' | 'SUCCESS' | 'ERROR' | 'INFINITING';

@Component({
  selector: 'app-gate-chat-panel',
  templateUrl: 'gate-chat.panel.html',
  styleUrls: ['gate-chat.panel.scss']
})
export class GateChatPanelComponent implements OnDestroy {
  isHidden = true;

  condo: Condo;
  user: User;

  @ViewChild('chatMessagesContainer') chatMessagesContainer: ElementRef;

  chatRoomsDefaultQuery: EcondosQuery = {};

  chatRoomsLoadingStatus: LoadingStatus;
  chatMessagesListLoadingStatus: LoadingStatus;

  messageBodyInput = new UntypedFormControl('', Validators.required);
  replyTo: ChatMessage = null;
  suggestedMessages: string[] = [];
  sendingMessageStatus: LoadingStatus;
  @ViewChild('sendMessageButton') sendMessageButton: ElementRef;
  @ViewChild('messageBodyTextarea') messageBodyTextarea: ElementRef;

  showGetOrCreateChatForm = false;
  getOrCreateChatLoadingStatus: LoadingStatus;

  showNewMessageIndicator = false;
  newMessagesIndicated: ChatMessage[] = [];

  fontSize: 'normal' | 'large' | 'extra-large' = 'normal';
  connectionStatus$: Observable<SOCKET_CONNECTION_STATUS>;

  page = 0;
  limit = 20;
  hasMoreData = true;

  today = moment().format('YYYY-MM-DD');

  constructor(
    private toastrService: ToastrService,
    private utilService: UtilService,
    public chatService: ChatServiceV2,
    private hardwareSocketService: HardwareSocketService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.user.defaultCondo;
    this.connectionStatus$ = this.hardwareSocketService.connectionStatus$;

    this.chatRoomsDefaultQuery = {
      $select: 'residence user identification condo userUnreadMessagesCount agentUnreadMessagesCount lastMessageAt lastMessageText',
      $populate: [
        { path: 'residence', select: 'identification' },
        {
          path: 'user',
          select: 'firstName lastName',
          populate: {
            path: 'picture',
            select: 'url thumbnail type name format'
          }
        }
      ],
      condo: this.condo._id,
      type: 'GATE'
    };

    const onNewMessageEvent = (chatMessage: ChatMessage) => {
      const shouldScroll = this.isTheEndOfChatMessagesContainer();
      const isMessageFromOtherUser = chatMessage.createdBy?._id !== this.user?._id;

      if (isMessageFromOtherUser) {
        if (shouldScroll) {
          setTimeout(() => this.scrollToMessage(chatMessage._id), 0);
        } else {
          this.showNewMessageIndicator = true;
          this.newMessagesIndicated.push(chatMessage);
        }
      }
    };

    this.chatService.initializeChatSocketEventsHandlers({ onNewMessageEvent });

    this.suggestedMessages = ['👍', '👎', 'Tá certo!', 'Obrigado'];

    this.loadChatsWithUserUnreadMessages(this.page);
  }

  ngOnDestroy(): void {
    this.chatService.clearCurrentChatSession();
  }

  toggle() {
    if (this.isHidden) {
      this.isHidden = false;
    } else {
      this.isHidden = true;
      this.toggleSelectedChat();
      this.showGetOrCreateChatForm = false;
    }
  }

  clearChats() {
    this.chatService.chats = {};
    this.chatService.selectedChatRoom = null;
    this.replyTo = null;
    this.showNewMessageIndicator = false;
    this.newMessagesIndicated = [];
    this.isHidden = true;
  }

  loadChatsWithUserUnreadMessages(page = 0) {
    this.chatRoomsLoadingStatus = page === 0 ? 'LOADING' : 'INFINITING';

    const query = {
      ...this.chatRoomsDefaultQuery,
      $sort: '-userUnreadMessagesCount -lastMessageAt',
      $limit: this.limit,
      $page: page
    };

    this.chatService.loadChatsWithUnreadMessages(this.condo._id, query).subscribe(
      response => {
        this.chatRoomsLoadingStatus = 'SUCCESS';
        this.page++;
        if (response.chats.length < this.limit) {
          this.hasMoreData = false;
        }
      },
      err => {
        this.chatRoomsLoadingStatus = 'ERROR';
      }
    );
  }

  toggleGetOrCreateChatForm() {
    this.showGetOrCreateChatForm = !this.showGetOrCreateChatForm;
  }

  onChatRoomSelectCallback() {
    this.chatMessagesListLoadingStatus = 'LOADING';
  }

  onChatRoomSelectSuccessCallback() {
    this.chatMessagesListLoadingStatus = 'SUCCESS';
    this.toggleGetOrCreateChatForm();

    setTimeout(() => {
      this.messageBodyTextarea.nativeElement.focus();
      this.scrollToMessage();
    }, 2_000);
  }

  onChatRoomSelectErrorCallback() {
    this.chatMessagesListLoadingStatus = 'ERROR';
  }

  toggleSelectedChat(chat: ChatRoom = null) {
    if (this.chatService.selectedChatRoom?._id === chat?._id) {
      return;
    }

    this.replyTo = null;
    this.showNewMessageIndicator = false;
    this.newMessagesIndicated = [];

    if (!chat) {
      this.chatService.selectedChatRoom = null;
      return;
    }

    const successCallback = () => {
      this.chatMessagesListLoadingStatus = 'SUCCESS';
      this.readAllMessages(this.chatService.selectedChatRoom._id);
      setTimeout(() => this.scrollToMessage(), 0);
    };
    const errorCallback = () => (this.chatMessagesListLoadingStatus = 'ERROR');

    this.chatService.selectedChatRoom = chat;
    this.chatMessagesListLoadingStatus = 'LOADING';
    this.chatService.loadChatMessages(this.condo._id, chat._id, { successCallback, errorCallback });

    setTimeout(() => {
      this.messageBodyTextarea.nativeElement.focus();
    }, 0);
  }

  loadMoreMessages() {
    this.chatMessagesListLoadingStatus = 'LOADING';

    const successCallback = () => {
      this.chatMessagesListLoadingStatus = 'SUCCESS';

      const totalOfUnreadMessages =
        this.chatService.selectedChatRoom.agentUnreadMessagesCount + this.chatService.selectedChatRoom.userUnreadMessagesCount;

      if (totalOfUnreadMessages > 0) {
        this.readAllMessages(this.chatService.selectedChatRoom._id);
      }
    };
    const errorCallback = () => (this.chatMessagesListLoadingStatus = 'ERROR');

    this.chatService.loadChatMessages(this.condo._id, this.chatService.selectedChatRoom._id, {
      paginate: true,
      successCallback,
      errorCallback
    });
  }

  readAllMessages(chatRoomId) {
    this.chatService.readAllMessages(this.condo._id, chatRoomId).pipe(timeout(10_000)).subscribe(noop);
  }

  increaseFontSize() {
    switch (this.fontSize) {
      case 'normal':
        this.fontSize = 'large';
        break;
      case 'large':
        this.fontSize = 'extra-large';
        break;
    }
  }

  decreaseFontSize() {
    switch (this.fontSize) {
      case 'extra-large':
        this.fontSize = 'large';
        break;
      case 'large':
        this.fontSize = 'normal';
        break;
    }
  }

  replyMessage(chatMessage: ChatMessage) {
    this.replyTo = chatMessage;
  }

  sendMessage() {
    if (this.messageBodyInput.value) {
      this.createChatMessage(this.messageBodyInput.value);
    }
  }

  sendSuggestedMessage(suggestedMessageBody: string) {
    this.createChatMessage(suggestedMessageBody);
  }

  createChatMessage(messageBody: string) {
    this.sendingMessageStatus = 'PROCESSING';
    this.messageBodyInput.disable();
    this.sendMessageButton.nativeElement.disabled = true;

    const requestForm: SendMessageRequestForm = { messageBody };

    if (this.replyTo) {
      requestForm.replyTo = this.replyTo._id;
    }

    this.chatService
      .createChatMessage(this.condo._id, requestForm)
      .pipe(timeout(10000))
      .subscribe(
        response => {
          this.sendingMessageStatus = 'SUCCESS';
          this.replyTo = null;
          this.messageBodyInput.setValue('');
          this.messageBodyInput.enable();
          this.messageBodyTextarea.nativeElement.focus();
          this.sendMessageButton.nativeElement.disabled = false;
          this.scrollToMessage();
          this.readAllMessages(this.chatService.selectedChatRoom._id);
        },
        error => {
          this.sendingMessageStatus = 'ERROR';
          this.toastrService.error('Não foi possível enviar a mensagem, verifique sua conexão e tente novamente.');
          this.messageBodyInput.enable();
          this.messageBodyTextarea.nativeElement.focus();
          this.sendMessageButton.nativeElement.disabled = false;
        }
      );
  }

  scrollToMessage(chatMessageId: string = null) {
    const chatMessagesContainer = this.chatMessagesContainer.nativeElement;
    const chatMessages = Array.from(chatMessagesContainer.children);
    const alignToTop = false;

    if (!chatMessageId && !!chatMessages.length) {
      chatMessagesContainer.children[chatMessages.length - 1].scrollIntoView(alignToTop);
      return;
    }

    const chatMessageIndex = chatMessages.findIndex((element: any) => element.id === chatMessageId);

    if (chatMessageIndex >= 0) {
      chatMessagesContainer.children[chatMessageIndex].scrollIntoView(alignToTop);
    }
  }

  isTheEndOfChatMessagesContainer(): boolean {
    const chatMessagesContainer = this.chatMessagesContainer.nativeElement;

    const scrollableAreaHeight = chatMessagesContainer.scrollHeight - chatMessagesContainer.clientHeight;
    const scrollableAreaHeightOffset = scrollableAreaHeight - 15;
    const scrollPosition = chatMessagesContainer.scrollTop;

    return scrollPosition >= scrollableAreaHeightOffset;
  }

  goToNewMessageIndicated() {
    const newMessagesCount = this.newMessagesIndicated.length;
    if (newMessagesCount) {
      this.scrollToMessage(this.newMessagesIndicated[newMessagesCount - 1]._id);
      this.readAllMessages(this.newMessagesIndicated[newMessagesCount - 1].chatRoom._id);

      this.showNewMessageIndicator = false;
      this.newMessagesIndicated = [];
    }
  }

  onChatMessagesContainerScroll() {
    if (this.showNewMessageIndicator) {
      const shouldHideNewMessageIndicator = this.isTheEndOfChatMessagesContainer();

      if (shouldHideNewMessageIndicator) {
        this.showNewMessageIndicator = false;
        this.newMessagesIndicated = [];
      }
    }
  }

  onReadByButtonClick(chatMessage: ChatMessage) {
    const currentChat = this.chatService.chats[chatMessage.chatRoom._id];
    const currentChatMessageIndex = currentChat.chatMessages.findIndex(message => message._id === chatMessage._id);
    const currentChatMessage = currentChat.chatMessages[currentChatMessageIndex];

    const chatMessageOwnerId = currentChatMessage.createdBy._id;
    const currentLoggedUserId = this.user._id;

    const messageReadBy = chatMessage.readBy.filter(reader => reader._id !== chatMessageOwnerId && reader._id !== currentLoggedUserId);

    currentChat.chatMessages[currentChatMessageIndex].readBy = messageReadBy;
    this.chatService.chats[chatMessage.chatRoom._id] = currentChat;
  }

  reconnectSocket() {
    this.hardwareSocketService.initialize(this.user.token, this.condo._id);
  }

  chatSortFunction = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    if (akv.value.chatRoom?.userUnreadMessagesCount > bkv.value.chatRoom?.userUnreadMessagesCount) {
      return -1;
    } else if (akv.value.chatRoom?.userUnreadMessagesCount < bkv.value.chatRoom?.userUnreadMessagesCount) {
      return 1;
    } else if (akv.value.chatRoom?.lastMessageAt > bkv.value.chatRoom?.lastMessageAt) {
      return -1;
    } else if (akv.value.chatRoom?.lastMessageAt < bkv.value.chatRoom?.lastMessageAt) {
      return 1;
    } else {
      return 0;
    }
  };

  doInfinite() {
    this.loadChatsWithUserUnreadMessages(this.page);
  }
}
