import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Construction } from '@api/model/construction';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class ConstructionService {
  private endPoint;
  constructor(private http: HttpService, private constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  create(condoId: string, construction): Observable<any> {
    return this.http.post(`${this.endPoint}${condoId}/constructions`, construction);
  }

  update(condoId: string, constructionId: string, construction: Construction) {
    return this.http.put(`${this.endPoint}${condoId}/constructions/${constructionId}`, construction);
  }

  delete(condoId: string, constructionId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/constructions/${constructionId}`);
  }

  get(condoId: string, query: EcondosQuery): Observable<{ count: number; constructions: Construction[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/constructions`, {
        params
      })
      .pipe(
        map((res: any) => {
          if (query.$count) {
            return {
              count: res.headers.get('count'),
              constructions: []
            };
          } else {
            return {
              count: res.headers.get('count'),
              constructions: res.body.map(r => new Construction(r))
            };
          }
        })
      );
  }

  getByIdWithParams(condoId: string, constructionId: string, params: EcondosQuery): Observable<Construction> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .get(`${this.endPoint}${condoId}/constructions/${constructionId}`, { params: httpParams })
      .pipe(map(construction => new Construction(construction)));
  }
}
