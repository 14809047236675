import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Residence } from '@api/model/interface/residence';
import { Condo } from '@api/model/condo';
import { Dependent } from '@api/model/dependent';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../components/table/table.component';
import { UtilService } from '../../../services/util.service';
import { capitalize } from '@api/util/util';
import { CachedRequestsService } from '../cached-requests.service';
import { EcondosQuery } from '@api/model/query';
import { timeout } from 'rxjs';
import { DependentServiceV2 } from '@api/serviceV2/dependent.service';

@Component({
  selector: 'app-gate-residence-details-dependents',
  templateUrl: './gate-residence-details-dependents.component.html'
})
export class GateResidenceDetailsDependentsComponent implements OnInit {
  @Input() residence: Residence;
  @Input() condo: Condo;

  @ViewChild('dependentsTable', { static: true }) dependentsTable: TableComponent;
  @ViewChild('nameCellTemplate', { static: true }) nameCellTemplate: TemplateRef<any>;
  @ViewChild('phoneCellTemplate', { static: true }) phoneCellTemplate: TemplateRef<any>;

  dependents: Dependent[] = [];
  count = 0;

  tableColumns: TableColumnDefinition<Dependent>[] = [];
  status: TableStatus = 'LOADING';
  paginate = false;

  constructor(
    private utilService: UtilService,
    private cachedRequestsService: CachedRequestsService,
    private dependentService: DependentServiceV2
  ) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        sortable: true,
        columnId: 'name',
        headerLabel: 'Nome',
        valueTemplate: this.nameCellTemplate
      },
      {
        headerLabel: 'Parentesco',
        valueFn: rowData => capitalize(rowData.kinship) || 'Não informado'
      },
      {
        headerLabel: 'RG',
        valueFn: rowData => this.utilService.formatRg(rowData.rg) || 'Não informado'
      },
      {
        headerLabel: 'CPF',
        valueFn: rowData => this.utilService.formatCpf(rowData.cpf) || 'Não informado'
      },
      {
        headerLabel: 'Telefone',
        // valueFn: rowData => this.utilService.formatPhone(rowData.phone) || 'Não informado'
        valueTemplate: this.phoneCellTemplate
      }
    ];
    this.getData({ page: 0 });
  }

  getData({ page = 0 }: { page: number }) {
    const { pageSize, sortedColumn, sortOrder } = this.dependentsTable.getCurrentState();

    const params = [];
    params.push({ $select: 'name rg cpf phone kinship specialNeeds specialNeedsDetails picture room' });
    params.push({ '$populate[0][path]': 'picture' });
    params.push({ '$populate[0][select]': 'url thumbnail type format name' });
    params.push({ $page: page });
    params.push({ $limit: pageSize || 15 });

    if (typeof sortedColumn === 'string') {
      params.push({ $sort: `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}` });
    }

    this.cachedRequestsService.getDependents(this.condo._id, this.residence._id, params).subscribe({
      next: ({ dependents, count }) => {
        this.paginate = count >= pageSize;
        this.count = count;
        this.dependents = dependents;
        this.status = 'SUCCESS';
      },
      error: () => (this.status = 'ERROR')
    });
  }

  handleRevealData(field: string, dependent) {
    dependent.isDataMasked[field] = false;

    let isFieldAlreadyUnmasked: boolean;

    if (field === 'birthDate') {
      isFieldAlreadyUnmasked = !dependent.birthDate.toString().includes('3000');
    } else {
      isFieldAlreadyUnmasked = !dependent[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    const callback = ({ data }) => (dependent[field] = data);

    this.dependentService.getDependentUnmaskedField(this.condo._id, dependent._id, field, query).pipe(timeout(10000)).subscribe(callback);
  }
}
