/**
 * Created by rafael on 10/05/2017.
 */
export enum STATUS {
  Success,
  Downloading,
  Refreshing,
  Error
}

export enum MARITAL_STATUS {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  SEPARATED = 'SEPARATED',
  DIVORCED = 'DIVORCED',
  WIDOWER = 'WIDOWER'
}

export enum MARITAL_STATUS_LABEL {
  SINGLE = 'Solteiro',
  MARRIED = 'Casado',
  SEPARATED = 'Separado',
  DIVORCED = 'Divorciado',
  WIDOWER = 'Viúvo'
}
