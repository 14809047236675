import { Injectable } from '@angular/core';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { iif, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkerService {
  constructor(private http: HttpClient) {}

  getLatestVersion(url: string): Observable<any> {
    return this.http
      .get(url, { responseType: 'blob', headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', ignoreLoadingBar: '' } })
      .pipe(
        mergeMap((res: Blob) =>
          iif(
            () => ['application/javascript', 'text/javascript'].includes(res.type),
            of({ url, fileSize: res.size, error: false }),
            of({ url, fileSize: res.size, error: false })
          )
        ),
        catchError(() => of({ url, fileSize: null, error: true }))
      );
  }
}
