import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcondosFilter } from '@api/model/filter';
import { cloneDeep } from 'lodash';

/**
 * The remove filter event data definition.
 */
export type RemoveFilterEventData = {
  /**
   * The updated filters array.
   */
  filters: EcondosFilter[];
  /**
   * The EcondosFilter's value prop that defines the filter.
   */
  removedFilterName: EcondosFilter['name'];
  /**
   * The removed filter index inside filters array.
   */
  removedFilterIndex: number;
};

/**
 * The clear filters event data definition.
 */
export type ClearFiltersEventData = {
  /**
   * The updated filters array.
   */
  filters: EcondosFilter[];
};

@Component({
  standalone: true,
  selector: 'app-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss'],
  imports: [CommonModule]
})
export class AppliedFiltersComponent implements OnChanges {
  /**
   * An array of the applied filters.
   */
  @Input() filters: EcondosFilter[] = [];

  /**
   * Emits whenever an individual filter is removed.
   */
  @Output() removeFilter = new EventEmitter<RemoveFilterEventData>();

  /**
   * Emits whenever all filters are cleared.
   */
  @Output() clearFilters = new EventEmitter<ClearFiltersEventData>();

  copiedFilters: EcondosFilter[] = [];
  appliedFiltersCount = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      this.copiedFilters = cloneDeep(this.filters);
      this.countAppliedFilters();
    }
  }

  handleRemoveFilter({ filter, index }: { filter: EcondosFilter; index: number }) {
    this.copiedFilters[index].value = '';
    this.copiedFilters[index].valueLabel = '';

    this.countAppliedFilters();
    this.removeFilter.emit({ filters: this.copiedFilters, removedFilterName: filter.name, removedFilterIndex: index });
  }

  handleClearFilters() {
    this.copiedFilters = this.copiedFilters.map(filter => ({ ...filter, value: '', valueLabel: '' }));
    this.countAppliedFilters();
    this.clearFilters.emit({ filters: this.copiedFilters });
  }

  countAppliedFilters() {
    this.appliedFiltersCount = this.copiedFilters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }
}
