import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as qs from 'qs';
import { BuildExternalLink, ExternalLink } from '@api/model/external-link';
import { Condo } from '@api/model/condo';
import { File } from '@api/model/file';

export type SaveExternalLinkDTO = Pick<ExternalLink, 'title' | 'url' | 'queryParams' | 'includeUserId'> & {
  icon?: string;
  picture?: File;
};

@Injectable({ providedIn: 'root' })
export class ExternalLinksService {
  protected endPoint = `${this.constantService.getV3Endpoint()}condos/`;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {}

  getExternalLinks(
    condoId: Condo['_id'],
    query: EcondosQuery = {}
  ): Observable<{
    count: number;
    externalLinks: ExternalLink[];
  }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/external-links`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        externalLinks: res.body.map(externalLink => BuildExternalLink(externalLink))
      }))
    );
  }

  createExternalLink(condoId: Condo['_id'], externalLink: SaveExternalLinkDTO) {
    return this.http
      .post(`${this.endPoint}${condoId}/external-links`, externalLink)
      .pipe(map((response: Pick<ExternalLink, '_id'>) => response));
  }

  updateExternalLink(condoId: Condo['_id'], externalLinkId: ExternalLink['_id'], externalLink: SaveExternalLinkDTO) {
    return this.http.put(`${this.endPoint}${condoId}/external-links/${externalLinkId}`, externalLink);
  }

  deleteExternalLink(condoId: Condo['_id'], externalLinkId: ExternalLink['_id']) {
    return this.http.delete(`${this.endPoint}${condoId}/external-links/${externalLinkId}`);
  }
}
