import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { Subscription } from 'rxjs';
import { EcondosQuery } from '@api/model/query';
import { Residence } from '@api/model/interface/residence';
import { Condo } from '@api/model/condo';
import { ChatServiceV2 } from '@api/serviceV2/chat.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ComponentsModule } from '../components/components.module';
import { GateChatModule } from '../gate-chat/gate-chat.module';
import { AutosizeModule } from 'ngx-autosize';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { ChatMessage } from '@api/model/chat-message';
import { User } from '@api/model/user';
import { timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DirectivesModule } from '../directives/directives.module';

interface SendMessageRequestForm {
  messageBody: string;
  replyTo?: string;
}

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
  imports: [
    CommonModule,
    NgForOf,
    NgIf,
    BsDropdownModule,
    ComponentsModule,
    GateChatModule,
    AutosizeModule,
    NgxTrimDirectiveModule,
    ReactiveFormsModule,
    FormsModule,
    MomentModule,
    DirectivesModule
  ],
  standalone: true
})
export class MessageListComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  residence: Residence;
  @Input()
  condo: Condo;
  @Input()
  user: User;

  @Input()
  isVisibleHeader: boolean = true;
  @Input()
  chatOptions: boolean = false;

  subscription: Subscription = new Subscription();

  @ViewChild('messagesWrapper', { read: ElementRef, static: true }) messagesWrapper: ElementRef;

  status: 'LOADING' | 'ERROR' | 'SUCCESS';
  sendingMessageStatus: 'LOADING' | 'PROCESSING' | 'SUCCESS' | 'ERROR' | 'INFINITING';

  page = 0;
  countData: number = 0;

  messages: ChatMessage[] = [];
  messageId: string;

  chatRoomId: string;

  uniqueUsers: Partial<User>[] = [];

  selectedUserId: string;
  selectedUserTouched: boolean = false;

  messageResponse: string;

  constructor(
    private chatService: ChatServiceV2,
    private toastrService: ToastrService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo && changes.residence && changes.user) {
      this.getData();
    }
  }

  getData({ page = 0 } = {}) {
    this.status = 'LOADING';

    const query: EcondosQuery = {
      $populate: [
        {
          path: 'createdBy',
          select: 'firstName lastName condos condosAdmin condosOwner condosJanitor condosRequester condosGatekeeper'
        },
        {
          path: 'chatRoom',
          select: 'user identification lastMessageAt',
          populate: {
            path: 'user',
            select: 'firstName lastName picture',
            populate: {
              path: 'picture',
              select: 'url thumbnail type name format'
            }
          }
        }
      ],
      $limit: 30
    };

    this.chatService.getAllMessages(this.condo._id, this.residence._id, query).subscribe({
      next: res => {
        this.messages = res.sort((a, b) => (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any));

        this.uniqueUsers = Array.from(new Set(res.map(message => message.chatRoom.user._id))).map(
          userId => res.find(message => message.chatRoom.user._id === userId).chatRoom.user
        );

        this.status = 'SUCCESS';
      },
      error: err => {
        this.status = 'ERROR';
        console.error(err);
      }
    });
  }

  selectUser(userId: string, chatRoomId: string, messageId?: string) {
    this.selectedUserId = userId;

    if (messageId) {
      this.messageId = messageId;
    }
    this.chatRoomId = chatRoomId;
  }

  sendMessage() {
    this.sendingMessageStatus = 'PROCESSING';

    if (!this.chatRoomId) {
      this.messages.find(message => {
        if (message.chatRoom.user._id === this.selectedUserId) {
          this.chatRoomId = message.chatRoom._id;
        }
      });
    }

    if (this.selectedUserId) {
      const requestForm: SendMessageRequestForm = { messageBody: this.messageResponse };

      if (this.messageId) {
        requestForm.replyTo = this.messageId;
      }

      this.chatService
        .createChatMessage(this.condo._id, requestForm, this.chatRoomId)
        .pipe(timeout(10000))
        .subscribe({
          next: () => {
            this.messageId = null;
            this.chatRoomId = null;
            this.messageResponse = '';

            this.sendingMessageStatus = 'SUCCESS';
            this.getData();
          },
          error: err => {
            this.toastrService.error('Não foi possível enviar a mensagem, verifique sua conexão e tente novamente.');

            this.sendingMessageStatus = 'ERROR';
            console.error(err);
          }
        });
    }
  }
}
