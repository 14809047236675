import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, Subject, Subscription, noop } from 'rxjs';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { catchError, distinctUntilChanged, filter, map, mergeMap, retry, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { Device, DEVICE_STATUS } from '@api/model/hardware/device';
import swal from 'sweetalert2';
import { ResponseError } from '@api/model/error/response.error';
import { EcondosQuery } from '@api/model/query';
import { Actuator } from '@api/model/hardware/actuator';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { User } from '@api/model/user';
import { UtechService } from '@api/service/hardware/utech.service';
import * as moment from 'moment';
import { HardwareSocketService } from '../../services/hardware-socket.service';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-modal-create-utech-device',
  templateUrl: 'modal-create-utech-device.component.html',
  styleUrls: ['modal-create-utech-device.component.scss']
})
export class ModalCreateUtechDeviceComponent implements OnInit, OnDestroy {
  condo: Condo;
  condoActuators: Actuator[] = [];

  HOURS = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];
  MINUTES = ['00', '15', '30', '45'];

  WEEK_DAYS = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

  TYPES = [
    // Mifire ou NCF
    { label: 'Cartão ou chaveiro', value: 'CARD' },
    { label: 'Controle', value: 'RF' },
    { label: 'Senha', value: 'SN' },
    { label: 'QR Code', value: 'QR' },
    { label: 'Biometria', value: 'BM' }
  ];

  status: Status = new Status();

  device: Device;
  callbacks: { success?: (arg) => void; error?: (err) => void };

  form: UntypedFormGroup;

  type: AbstractControl;
  serial: AbstractControl = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
  residence: AbstractControl;
  vehicle: AbstractControl;
  user: AbstractControl;
  userName: AbstractControl;
  picture: AbstractControl;
  panic: AbstractControl;
  template: AbstractControl = new UntypedFormControl('', Validators.required);
  validUntil: AbstractControl = new UntypedFormControl('');
  actuators: UntypedFormArray;
  obs: AbstractControl;

  // Used on view to easily check if an actuator is checked or not
  checkedActuators = {};

  residenceSearch;
  residenceUsers = [];
  loadingResidences = false;
  residenceVehicles: CondoVehicle[] = [];

  residenceTypeAheadDataSource$: Observable<any>;

  selectedResidence;

  private subscriptions: Subscription = new Subscription();

  storageUser: { user: User; residence: Residence };

  customPermissions = false;
  utechPermissions = ['1-5 07:00-18:30', '6-6 07:00-12:00'];
  permissionsFormArray: UntypedFormArray = new UntypedFormArray([]);

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private hardwareSocketService: HardwareSocketService,
    private utechService: UtechService,
    private deviceService: HardwareDeviceService,
    private actuatorService: ActuatorService,
    private toastrService: ToastrService
  ) {
    this.form = this.fb.group({
      type: ['', Validators.required],
      user: [null],
      userName: [''],
      residence: [null, [Validators.required]],
      vehicle: [''],
      picture: [null],
      actuators: this.fb.array([]),
      panic: [false],
      obs: ['']
    });
    this.type = this.form.get('type');
    this.user = this.form.get('user');
    this.userName = this.form.get('userName');
    this.residence = this.form.get('residence');
    this.vehicle = this.form.get('vehicle');
    this.picture = this.form.get('picture');
    this.panic = this.form.get('panic');
    this.actuators = this.form.get('actuators') as UntypedFormArray;
    this.obs = this.form.get('obs');

    this.subscriptions.add(
      this.type.valueChanges.subscribe(type => {
        if (type === 'BM') {
          this.form.removeControl('serial');
          this.form.addControl('template', this.template);
        } else {
          this.form.addControl('serial', this.serial);
          this.form.removeControl('template');
        }
        this.form.updateValueAndValidity();
      })
    );

    this.subscriptions.add(
      this.user.valueChanges.subscribe(userId => {
        if (userId === 'NEW_USER') {
          swal({
            title: 'Inserir nome',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            inputPlaceholder: 'Digite o nome...',
            showLoaderOnConfirm: true,
            preConfirm: name => {
              if (!name || !name.trim().length) {
                return Promise.reject(`Insira o nome`);
              } else {
                name = name.trim();
                return Promise.resolve(name);
              }
            }
          }).then(
            name => {
              this.userName.setValue(name);
              this.user.setValue('');
            },
            () => {
              this.userName.setValue('');
              this.user.setValue('');
            }
          );
        } else {
          // Verificar se tem foto cadastrada. Se não, pega foto do usuario logado
          if (this.checkHasDeviceOwnerPicture()) {
            this.picture.setValue(this.device.owner.picture);
          } else if (userId) {
            const user = this.residenceUsers.find(u => u._id === userId);
            if (user && user.picture && user.picture._id) {
              this.picture.setValue(user.picture);
            } else {
              this.picture.setValue(null);
            }
          } else {
            this.picture.setValue(null);
          }
        }
      })
    );

    this.initializeTypeAheads();
  }

  loadUtechActuators() {
    this.actuatorService
      .getActuators(this.condo._id, { hardware: 'UTECH' })
      .pipe(retry(3))
      .subscribe(
        actuatorsResponse => {
          this.condoActuators = actuatorsResponse.actuators;
        },
        err => {
          // TODO tratar casos de erro
          console.log(err);
        }
      );
  }

  initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type voter users',
          $populate: [
            { path: 'users', select: 'firstName lastName picture phones', populate: { path: 'picture', select: 'url thumbnail' } },
            { path: 'voter', select: 'firstName lastName picture phones', populate: { path: 'picture', select: 'url thumbnail' } }
          ]
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(e => {
            this.status.setAsError();
            return of([]);
          })
        );
      })
    );
  }

  checkHasDeviceOwnerPicture() {
    return this.device?.owner?.picture ? true : false;
  }

  ngOnInit() {
    this.loadUtechActuators();

    if (this.device) {
      this.form.disable();
      this.serial.disable();
      this.type.setValue(this.device.type);
      this.serial.setValue(this.device.serial);
      this.panic.setValue(this.device.panic || false);
      this.obs.setValue(this.device.obs);
      this.obs.enable();
      if (this.device.owner) {
        if (this.device.owner.residence && this.device.owner.residence._id) {
          this.residence.setValue(this.device.owner.residence);
          const vehicleId: any =
            this.device.owner &&
            ((this.device.owner.condoVehicle && this.device.owner.condoVehicle._id) || this.device.owner.condoVehicle || '');
          const userId: any = (this.device.owner && this.device.owner.user && this.device.owner.user._id) || '';
          this.initializeFieldsFromResidence(this.device.owner.residence, { userId, vehicleId });
        }
        if (this.device.owner.condoVehicle && this.device.owner.condoVehicle._id) {
          this.residenceVehicles = [this.device.owner.condoVehicle];
          this.vehicle.setValue(this.device.owner.condoVehicle._id);
        } else {
          this.vehicle.setValue('');
        }
        if (this.device.owner.user && this.device.owner.user._id) {
          this.residenceUsers = [this.device.owner.user];
          this.user.setValue(this.device.owner.user._id);
        } else {
          this.user.setValue('');
          if (this.device.owner.userName) {
            this.userName.setValue(this.device.owner.userName);
          } else if (this.device.hardwareAttributes && this.device.hardwareAttributes.rotulo) {
            this.userName.setValue(this.device.hardwareAttributes.rotulo);
          }
        }
        if (this.device.owner.picture && this.device.owner.picture._id) {
          this.picture.setValue(this.device.owner.picture);
        }
        if (this.device.actuators) {
          this.device.actuators.forEach(actuator => {
            const fc = new UntypedFormControl(actuator._id);
            this.actuators.push(fc);
            this.checkedActuators[actuator._id] = true;
          });
        }
      }
      if (this.device.hardwareAttributes?.utechPermissions?.length) {
        this.customPermissions = true;
        this.utechPermissions = this.device.hardwareAttributes.utechPermissions;
      }
      if (this.device.validUntil) {
        this.validUntil.setValue(moment(this.device.validUntil).format('YYYY-MM-DD'));
      }
      if (this.device.type === 'BM') {
        this.deviceService
          .getDeviceTemplate(this.condo._id, this.device._id)
          .pipe(retry(3))
          .subscribe(({ template }) => {
            this.template.setValue(template);
          });
      }
    } else if (this.selectedResidence) {
      this.residence.setValue(this.selectedResidence);
      this.residence.disable();
      this.initializeFieldsFromResidence(this.selectedResidence);
    }
    if (this.storageUser) {
      if (this.storageUser.residence) {
        this.residence.setValue(this.storageUser.residence);
        this.residence.disable();
        if (!this.storageUser.user) {
          this.initializeFieldsFromResidence(this.storageUser.residence);
        }
        this.user.setValue('');
      }
      if (this.storageUser.user) {
        this.user.setValue(this.storageUser.user._id);
        this.residenceUsers = [this.storageUser.user];
        this.user.setValue(this.residenceUsers[0]?._id);
        this.user.disable();
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residence.setValue(residences[0]);
          this.getResidenceVehicles(residences[0].id);
        }
        if (this.storageUser.user.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
      }
    }

    for (const permission of this.utechPermissions) {
      try {
        // Example: 1-5 08:00-18:00
        // http://wiki.utech.com.br/wiki/index.php/MPI/Permiss%C3%B5esDeAcesso
        const [dayPart, timePart] = permission.split(' ');
        const [startDay, endDay] = dayPart.split('-');
        const [startTime, endTime] = timePart.split('-');
        const [startHour, startMinute] = startTime.split(':');
        const [endHour, endMinute] = endTime.split(':');
        const form = this.fb.group({
          startDay: [startDay, Validators.required],
          endDay: [endDay, Validators.required],
          startHour: [startHour, Validators.required],
          startMinute: [startMinute, Validators.required],
          endHour: [endHour, Validators.required],
          endMinute: [endMinute, Validators.required]
        });
        this.permissionsFormArray.push(form);
      } catch (e) {
        console.log(e);
      }
    }
  }

  addCustomPermission() {
    this.permissionsFormArray.push(
      this.fb.group({
        startDay: ['0', Validators.required],
        endDay: ['6', Validators.required],
        startHour: ['07', Validators.required],
        startMinute: ['00', Validators.required],
        endHour: ['18', Validators.required],
        endMinute: ['00', Validators.required]
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async save() {
    if (this.form.valid) {
      await this.saveDevice();
    } else {
      this.toastrService.warning('Preencha todos os campos');
      this.form.markAllAsTouched();
    }
  }

  async saveDevice() {
    try {
      let device = this.depopulateDevice();
      device.status = DEVICE_STATUS.UNSYNCED;
      if (this.customPermissions && this.permissionsFormArray.length) {
        const permissions = this.permissionsFormArray.value.map(values => {
          const { startDay, endDay, startHour, startMinute, endHour, endMinute } = values;
          return `${startDay}-${endDay} ${startHour}:${startMinute}-${endHour}:${endMinute}`;
        });
        device.hardwareAttributes = device.hardwareAttributes || {};
        device.hardwareAttributes.utechPermissions = permissions;
      }
      this.status.setAsProcessing();
      let id;
      if (this.device?._id) {
        await this.deviceService.update(this.condo._id, this.device._id, device).toPromise();
        id = this.device._id;
      } else {
        const dev = await this.deviceService.create(this.condo._id, device).toPromise();
        id = dev._id;
      }
      device._id = id;
      device = this.populateDevice(device);

      const results = await this.utechService.registerDevice(this.condoActuators, device).toPromise();
      const actuatorsWithProblem = results && results.filter(r => !r.ok).map(r => r.actuator);
      if (actuatorsWithProblem && actuatorsWithProblem.length) {
        // Não conseguiu executar a ação em todos acionadores, por isso o device fica como não sincronizado
        const actuatorsWithProblemName = actuatorsWithProblem.map(a => a.name);
        swal({
          type: 'error',
          title: 'Dispositivo não sincronziado em todos equipamentos',
          text: `Não foi possível efetuar a operação nos seguintes equipamentos: ${actuatorsWithProblemName.join(', ')}`
        });
      } else {
        // Cadastrou em todos acionadores sem problema
        device.status = DEVICE_STATUS.SYNCED;
        await this.deviceService.update(this.condo._id, device._id, { status: DEVICE_STATUS.SYNCED }).toPromise();
        this.toastrService.success('Dispositivo registrado com sucesso');
      }
      this.status.setAsSuccess();
      this.bsModalRef.hide();
      if (this.callbacks && this.callbacks.success) {
        this.callbacks.success(device);
      }
    } catch (err) {
      console.log(err);
      this.status.setAsError();
      if (
        err instanceof ResponseError &&
        !err.message &&
        err.originalError &&
        err.originalError.error &&
        err.originalError.error.includes('Device is already registered')
      ) {
        swal({
          type: 'error',
          title: 'Serial já cadastrado',
          text: `Já existe um dispositivo com este serial cadastrado em seu(sua) ${this.condo?.customLabels?.condo?.singular}.`
        });
      } else {
        swal({
          type: 'error',
          title: err.messageTitle || 'Ops...',
          text: err.message || 'Não foi possível cadastrar este dispositivo.'
        });
      }
    }
  }

  onResidenceSelect(event) {
    const residence: Residence = event.item;
    this.residence.setValue(residence);
    this.residenceUsers = residence.residenceUsers;
    this.residenceUsers = orderBy(this.residenceUsers, [u => u.fullName.toLowerCase()]);
    this.user.setValue('');
    this.vehicle.setValue('');
    this.getResidenceVehicles(residence.id);
  }

  getResidenceVehicles(residenceId: string) {
    this.residenceService.getVehicles(this.condo._id, residenceId).subscribe(
      res => {
        this.residenceVehicles = res.vehicles;
      },
      err => {
        console.log(err);
      }
    );
  }

  clearResidence() {
    this.residenceSearch = '';
    this.residence.setValue(null);
    this.residenceUsers = [];
    this.user.setValue(null);
    this.residenceVehicles = [];
  }

  populateDevice(device) {
    device = { ...device };
    if (device.owner) {
      if (this.residence.value) {
        device.owner.residence = this.residence.value;
      }
      if (this.user.value) {
        device.owner.user = this.residenceUsers.find(u => u._id === this.user.value);
      }
      if (this.vehicle.value) {
        device.owner.condoVehicle = this.residenceVehicles.find(v => v._id === this.vehicle.value);
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value;
      }
    }
    device.actuators = this.condoActuators.filter(actuator => this.checkedActuators[actuator._id]);
    return device;
  }

  depopulateDevice() {
    const type = this.type.value;
    const serial = this.serial.value;
    const device: any = {
      type,
      serial,
      hardware: 'UTECH',
      actuators: this.actuators.value,
      panic: this.panic.value,
      obs: this.obs.value
    };
    if (this.customPermissions && this.validUntil.value) {
      device.validUntil = moment(this.validUntil.value).endOf('d').toISOString();
    } else {
      device.validUntil = '';
    }
    if (this.type.value === 'BM' && this.template.value) {
      device.template = this.template.value;
    }
    if (this.residence.value) {
      device.owner = {
        residence: this.residence.value._id
      };
      if (this.user.value) {
        device.owner.user = this.user.value;
      } else if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }
      if (this.vehicle.value) {
        device.owner.condoVehicle = this.vehicle.value;
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value._id;
      }
    }
    return device;
  }

  initializeFieldsFromResidence(residence, selectedValues: { vehicleId?: string; userId?: string } = {}) {
    const residenceQuery: EcondosQuery = {
      $populate: [
        { path: 'users', select: 'firstName lastName picture', populate: { path: 'picture', select: 'url thumbnail format name' } },
        { path: 'voter', select: 'firstName lastName picture', populate: { path: 'picture', select: 'url thumbnail format name' } }
      ]
    };
    forkJoin([
      this.residenceService.getResidenceByIdWithParams(this.condo._id, residence._id, residenceQuery),
      this.residenceService.getVehicles(this.condo._id, residence._id)
    ])
      .pipe(timeout(15000))
      .subscribe(
        ([residenceResponse, vehiclesResponse]) => {
          this.residenceUsers = residenceResponse.residenceUsers;
          this.residenceVehicles = vehiclesResponse.vehicles;
          this.vehicle.setValue(selectedValues.vehicleId || '');
          this.user.setValue(selectedValues.userId || '');
        },
        async err => {
          console.log(err);
          await swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível obter os dados necessários, verifique sua conexão e tente novamente.'
          });
          this.bsModalRef.hide();
        }
      );
  }

  toggleActuator(actuator: Actuator) {
    const checkArray: UntypedFormArray = this.actuators;
    const isChecked = checkArray.value.includes(actuator._id);
    if (isChecked) {
      let i = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == actuator._id) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    } else {
      checkArray.push(new UntypedFormControl(actuator._id));
    }
    this.checkedActuators = checkArray.value.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }

  generateRandomSerial(length) {
    const chars = [...'abcdefghijklmnopqrstuvxzwyABCDEFGHIJKLMNOPQRSTUVXZWY0123456789!@#$%&*()'];
    const serial = [...Array(length)].map(i => chars[Math.floor(Math.random() * chars.length)]).join(``);
    this.serial.setValue(serial);
  }

  async collectCard() {
    const actuators = this.condoActuators;
    if (!actuators || !actuators.length) {
      swal({
        type: 'error',
        title: 'Equipamentos não cadastrados',
        text: `Não existem equipamentos cadastrados no seu(sua) ${this.condo?.customLabels?.condo?.singular}. Antes de cadastrar um cartão é necessário você cadastrar os equipamentos`
      });
      return;
    }
    let selectedActuator;
    if (actuators.length > 1) {
      const inputOptions: any = actuators.reduce(
        (acc, curr) => {
          acc[curr._id] = curr.name;
          return acc;
        },
        { ALL: 'Todos' }
      );

      const actuatorId = await swal({
        inputOptions,
        title: 'Equipamento',
        text: 'Selecione o equipamento desejado para a leitura do cartão',
        input: 'select',
        inputPlaceholder: 'Selecione',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputValidator: async res => {
          if (res) {
            Promise.resolve();
          } else {
            return Promise.reject('Selecione um equipamento!');
          }
        }
      });
      if (actuatorId === 'ALL') {
        selectedActuator = actuatorId;
      } else {
        selectedActuator = actuators.find(a => a._id === actuatorId);
      }
    } else {
      selectedActuator = actuators[0];
    }

    const destroy$ = new Subject();

    this.hardwareSocketService.onData$
      .pipe(
        timeout(60000),
        filter(
          data =>
            data.condoId === this.condo._id &&
            data.hardwareEvent &&
            data.hardwareEvent.eventType === 'OTHER' &&
            data.hardwareData &&
            data.hardwareData.card &&
            data.hardwareData.state === 'blocked'
        ),
        filter(data => selectedActuator === 'ALL' || data.hardwareEvent.actuator === selectedActuator._id),
        map(res => ({ ...res.hardwareEvent })),
        map(res => (res && res.serial) || ''),
        takeUntil(destroy$),
        take(1),
        tap(() => {
          swal.clickConfirm();
        })
      )
      .subscribe(
        serial => {
          this.toastrService.success(`Cartão ${serial} lido com sucesso`);
          this.serial.setValue(serial);
          destroy$.next(null);
          destroy$.complete();
        },
        err => {
          swal({
            type: 'error',
            title: 'Erro na leitura',
            text: 'Não foi possível realizar a leitura do cartão'
          });
          destroy$.next(null);
          destroy$.complete();
          console.log(err);
        }
      );

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Passe o cartão na leitora ${selectedActuator.name || ''}`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(e => {
      console.log(e);
      destroy$.next(null);
      destroy$.complete();
    });
  }

  async collectFingerprint() {
    const actuators = this.condoActuators;
    if (!actuators || !actuators.length) {
      swal({
        type: 'error',
        title: 'Equipamentos não cadastrados',
        text: `Não existem equipamentos cadastrados no seu ${this.condo?.customLabels?.condo?.singular}. Antes de cadastrar um cartão é necessário você cadastrar os equipamentos`
      });
      return;
    }
    let selectedActuator;
    if (actuators.length > 1) {
      const inputOptions: any = actuators.reduce((acc, curr) => {
        acc[curr._id] = curr.name;
        return acc;
      }, {});

      const actuatorId = await swal({
        inputOptions,
        title: 'Equipamento',
        text: 'Selecione o equipamento desejado para a leitura da biometria',
        input: 'select',
        inputPlaceholder: 'Selecione',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputValidator: async res => {
          if (res) {
            Promise.resolve();
          } else {
            return Promise.reject('Selecione um equipamento!');
          }
        }
      });
      selectedActuator = actuators.find(a => a._id === actuatorId);
    } else {
      selectedActuator = actuators[0];
    }

    this.template.setValue('');

    const destroy$ = new Subject();

    this.hardwareSocketService.onData$
      .pipe(
        timeout(60000),
        filter(command => command.condoId === this.condo._id && command.command === 'readFingerprint'),
        takeUntil(destroy$),
        take(1),
        tap(data => {
          swal.clickConfirm();
        })
      )
      .subscribe(
        data => {
          // Remove o usuário temporário que foi criado para coletar o template da biometria
          this.utechService.deleteFromActuatorById(selectedActuator, tempUserId).pipe(retry(3)).subscribe(noop, noop);
          if (data.state && data.state === 'success') {
            this.toastrService.success(`Biometria coletada com sucesso`);
            this.template.setValue(data.fingerprint);
          } else {
            let text = '';
            switch (data.state) {
              case 'duplicated': {
                text = 'Esta biometria já está cadastrada';
                break;
              }
              case 'timeout': {
                text = 'Tempo limite excedido. Reinicie o processo de captura';
                break;
              }
              case 'enroll': {
                text = 'Não foi possível realizar a coleta da biometria';
                break;
              }
              default: {
                text = 'Não foi possível realizar a coleta da biometria';
                break;
              }
            }

            swal({
              text,
              type: 'error',
              title: 'Erro na leitura'
            });
          }
          destroy$.next(null);
          destroy$.complete();
        },
        err => {
          swal({
            type: 'error',
            title: 'Erro na leitura',
            text: 'Não foi possível realizar a coleta da biometria'
          });
          destroy$.next(null);
          destroy$.complete();
          console.log(err);
        }
      );

    let tempUserId;
    try {
      tempUserId = await this.utechService.collectFingerPrint(selectedActuator);
      // Código usado para simular o envio dos comandos para o equipamento
      // tempUserId = await new Promise((resolve, reject) => setTimeout(() => {
      //   resolve({id: 100})
      //   reject('Simulando erro')
      // }, Math.random() * 2000))
    } catch (e) {
      console.log(e);
      swal({
        type: 'error',
        title: 'Falha na comunicação',
        text: 'Não foi possível enviar o comando para o equipamento.'
      });
      return;
    }

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Coloque o dedo no equipamento ${selectedActuator.name}`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(e => {
      console.log(e);
      destroy$.next(null);
      destroy$.complete();
    });
  }

  toggleAll() {
    if (this.condoActuators.every(ac => this.checkedActuators[ac._id])) {
      this.condoActuators.forEach(ac => {
        if (!this.checkedActuators[ac._id]) {
          return;
        }
        this.toggleActuator(ac);
      });
    } else {
      this.condoActuators.forEach(ac => {
        if (this.checkedActuators[ac._id]) {
          return;
        }
        this.toggleActuator(ac);
      });
    }
  }
}
