import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { Injectable } from '@angular/core';
import { BuildVisitorRequest, VisitorRequest } from '@api/model/interface/visitor.request';

@Injectable({ providedIn: 'root' })
export class VisitorSelfLiberationService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }
  getVisitorRequest(condoId: string, params: EcondosQuery): Observable<VisitorRequest[]> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .get(`${this.endPoint}${condoId}/visitor-self-liberation`, { params: httpParams })
      .pipe(map((visitors: VisitorRequest[]) => visitors.map(visitor => BuildVisitorRequest(visitor))));
  }
  acceptVisitorRequest(condoId: string, visitorId: string) {
    return this.http.post(`${this.endPoint}${condoId}/visitor-self-liberation/${visitorId}/accept`, {});
  }
  rejectVisitorRequest(condoId: string, visitorId: string) {
    return this.http.post(`${this.endPoint}${condoId}/visitor-self-liberation/${visitorId}/reject`, {});
  }
}
