import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Comment } from '../model/comment';
import { ConstantService } from '../../services/constant.service';
import { HttpService } from '../../services/http.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CommentService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.http = http;

    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  getCommentsFromOccurrence(condoId: string, occurrenceId: string, queryString?: string): Observable<Comment[]> {
    return this.http
      .get(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/comments' + (queryString || ''))
      .pipe(map((comments: any) => comments.map(c => new Comment(c))));
  }

  createComment(condoId: string, occurrenceId: string, comment: any) {
    return this.http.post(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/comments', comment);
  }

  deleteComment(condoId: string, occurrenceId: string, commentId: string) {
    return this.http.delete(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/comments/' + commentId);
  }

  getCommentById(condoId: string, occurrenceId: string, commentId: string, queryString?: string): Observable<Comment> {
    return this.http.get(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/comments/' + commentId + (queryString || '')) as any;
  }

  updateComment(condoId: string, occurrenceId: string, commentId: string, comment: any) {
    return this.http.put(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/comments/' + commentId, comment);
  }

  dislikeComment(condoId: string, occurrenceId: string, commentId: string, comment: any) {
    return this.http.delete(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/comments/' + commentId + '/like');
  }

  likeComment(condoId: string, occurrenceId: string, commentId: string, comment: any) {
    return this.http.put(this.endPoint + condoId + '/occurrences/' + occurrenceId + '/comments/' + commentId + '/like', {});
  }
}
