import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ThemeService } from '../theme';

@Injectable({ providedIn: 'root' })
export class WhiteLabelInterceptor implements HttpInterceptor {
  constructor(private themeService: ThemeService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const url = request.url || '';
    const whiteLabel = this.themeService.activeTheme;
    if (
      whiteLabel &&
      whiteLabel.name !== 'econdos' &&
      (url.includes('econdos.com.br/api/') || url.includes('localhost') || url.includes('127.0.0.1'))
    ) {
      request = request.clone({ headers: request.headers.set('x-white-label', whiteLabel._id) });
    }
    return next.handle(request);
  }
}
