import { HttpService } from '../../services/http.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import * as qs from 'qs';
import { ParkingSpot } from '@api/model/parking-spot';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ParkingSpotService {
  private endPoint;

  constructor(private http: HttpService) {
    this.endPoint = `${environment.backendUrl}condos/`;
  }
  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; parkingSpots: ParkingSpot[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/parking-spot`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          parkingSpots: res.body.map(p => new ParkingSpot(p))
        }))
      );
  }

  getHistory(condoId: string, params: EcondosQuery = {}) {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/parking-spot-history`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        parkingSpotsHistory: res.body
      }))
    );
  }

  create(condoId: string, parkingSpot: ParkingSpot) {
    return this.http.post(`${this.endPoint}${condoId}/parking-spot`, parkingSpot);
  }

  update(condoId: string, parkingSpotId: string, parkingSpot: ParkingSpot) {
    return this.http.put(`${this.endPoint}${condoId}/parking-spot/${parkingSpotId}`, parkingSpot);
  }

  delete(condoId: string, parkingSpotId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/parking-spot/${parkingSpotId}`);
  }
}
