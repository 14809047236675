import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-license-plate',
  template: `
    <div class="plate">
      <div class="header">
        <img class="mercosul" src="./assets/img/logo-mercosul-branco.png" />
        <span class="country-name">BRASIL</span>
        <img class="flag" src="./assets/img/brazil.png" />
      </div>
      <div class="plate-body">
        <div class="left">
          <i class="fa fa-qrcode"></i>
          <div class="country-code">BR</div>
        </div>
        <h1 class="m-0 number">{{ plate || 'BRA2E22' }}</h1>
      </div>
    </div>
  `,
  styleUrls: ['license-plate.component.scss']
})
export class LicensePlateComponent implements OnChanges {
  @Input()
  plate = 'BEE4R22';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {}
}
