import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import * as moment from 'moment';
import { Condo } from '@api/model/condo';

@Component({
  selector: 'app-days-allowed-picker',
  templateUrl: 'days-allowed-picker.component.html',
  styleUrls: ['days-allowed-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DaysAllowedPickerComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DaysAllowedPickerComponent
    }
  ]
})
export class DaysAllowedPickerComponent implements OnChanges, ControlValueAccessor, Validator {
  @Input()
  condo: Condo;

  @Input()
  starDate = new Date().toISOString();

  @Input()
  endDate = new Date().toISOString();

  @Input()
  defaultStartHour = 8;

  @Input()
  defaultStartMinute = 0;

  @Input()
  defaultEndHour = 17;

  @Input()
  defaultEndMinute = 0;

  @Input()
  minimalSelecteDays = 1;

  @ViewChild('setFullDayButton') setFullDayButton!: ElementRef<HTMLButtonElement>;

  WEEK_DAYS;

  availableDays: AvailableDayInterface[] = [];

  selectedDays: DayAllowed[] = [];

  touched = false;
  isDisabled = false;
  isValid = true;

  onChange = values => {};

  onTouched = () => {};

  handleChanges(dayAllowedPickerInterface: AvailableDayInterface) {
    if (!this.isDisabled) {
      this.markAsTouched();
      if (dayAllowedPickerInterface.checked) {
        this.selectedDays = [
          ...this.selectedDays,
          {
            day: dayAllowedPickerInterface.day,
            startTime: moment(dayAllowedPickerInterface.startTime).format('HH:mm'),
            endTime: moment(dayAllowedPickerInterface.endTime).format('HH:mm')
          }
        ];
      } else {
        this.selectedDays = this.selectedDays.filter(v => v.day !== dayAllowedPickerInterface.day);
      }
      this.onChange(this.selectedDays);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.WEEK_DAYS) {
      this.WEEK_DAYS = [
        {
          index: 0,
          day: 'SUNDAY',
          label: 'Domingo',
          checked: true,
          startTime: moment().hour(this.defaultStartHour).minute(this.defaultStartMinute).toDate(),
          endTime: moment().hour(this.defaultEndHour).minute(this.defaultEndMinute).toDate()
        },
        {
          index: 1,
          day: 'MONDAY',
          label: 'Segunda',
          checked: true,
          startTime: moment().hour(this.defaultStartHour).minute(this.defaultStartMinute).toDate(),
          endTime: moment().hour(this.defaultEndHour).minute(this.defaultEndMinute).toDate()
        },
        {
          index: 2,
          day: 'TUESDAY',
          label: 'Terça',
          checked: true,
          startTime: moment().hour(this.defaultStartHour).minute(this.defaultStartMinute).toDate(),
          endTime: moment().hour(this.defaultEndHour).minute(this.defaultEndMinute).toDate()
        },
        {
          index: 3,
          day: 'WEDNESDAY',
          label: 'Quarta',
          checked: true,
          startTime: moment().hour(this.defaultStartHour).minute(this.defaultStartMinute).toDate(),
          endTime: moment().hour(this.defaultEndHour).minute(this.defaultEndMinute).toDate()
        },
        {
          index: 4,
          day: 'THURSDAY',
          label: 'Quinta',
          checked: true,
          startTime: moment().hour(this.defaultStartHour).minute(this.defaultStartMinute).toDate(),
          endTime: moment().hour(this.defaultEndHour).minute(this.defaultEndMinute).toDate()
        },
        {
          index: 5,
          day: 'FRIDAY',
          label: 'Sexta',
          checked: true,
          startTime: moment().hour(this.defaultStartHour).minute(this.defaultStartMinute).toDate(),
          endTime: moment().hour(this.defaultEndHour).minute(this.defaultEndMinute).toDate()
        },
        {
          index: 6,
          day: 'SATURDAY',
          label: 'Sábado',
          checked: true,
          startTime: moment().hour(this.defaultStartHour).minute(this.defaultStartMinute).toDate(),
          endTime: moment().hour(this.defaultEndHour).minute(this.defaultEndMinute).toDate()
        }
      ];
    }
    this.availableDays = this.getDaysFromPeriod(this.starDate, this.endDate);
    this.selectedDays = this.availableDays
      .filter(v => v.checked)
      .map(dayAllowed => ({
        startTime: dayAllowed.startTime,
        day: dayAllowed.day,
        endTime: dayAllowed.endTime
      }));
    this.onChange(this.selectedDays);
  }

  getDaysFromPeriod(startDate, endDate) {
    startDate = moment(startDate).startOf('day');
    endDate = moment(endDate).endOf('day');
    const diff = endDate.diff(startDate, 'day') + 1;
    let days;
    if (diff < 0) {
      days = [];
    } else if (diff >= 7) {
      days = [...this.WEEK_DAYS].map(item => Object.assign({}, item));
    } else {
      days = [];
      for (let i = 0; i < diff; i++) {
        days.push(this.WEEK_DAYS[moment(startDate).add(i, 'day').day()]);
      }
      days = [...days].map(item => Object.assign({}, item));
    }
    return days;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(values: DayAllowed[]): void {
    this.selectedDays = values;
    const selectedDaysObject = values.reduce((acc, curr) => {
      acc[curr.day] = curr;
      return acc;
    }, {});

    this.availableDays = this.availableDays.map(v => {
      const dayAllowed = selectedDaysObject[v.day];
      return {
        ...v,
        startTime: dayAllowed ? moment(dayAllowed.startTime, 'HH:mm').toDate() : v.startTime,
        endTime: dayAllowed ? moment(dayAllowed.endTime, 'HH:mm').toDate() : v.endTime,
        checked: selectedDaysObject[v.day]
      };
    });
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const values: DayAllowed[] = control.value;
    const errors: any = {};

    if (values?.length < this.minimalSelecteDays) {
      this.isValid = false;
      errors.minimalSelectedDays = {
        values
      };
    }

    for (const day of values) {
      const startTime = moment(day.startTime, 'HH:mm');
      const endTime = moment(day.endTime, 'HH:mm');
      if (startTime.isSameOrAfter(endTime)) {
        this.isValid = false;
        errors.timeRangeInvalid = errors.timeRangeInvalid || [];
        errors.timeRangeInvalid.push({
          day: day.day,
          startTime: day.startTime,
          endTime: day.endTime
        });
      }
    }

    this.isValid = Object.keys(errors).length === 0;
    return this.isValid ? null : errors;
  }

  checkDay(dayAllowed: AvailableDayInterface, index: number, checked: boolean) {
    dayAllowed = { ...dayAllowed, checked };
    this.availableDays[index] = dayAllowed;
    this.handleChanges(dayAllowed);
  }

  changeStartTime(dayAllowed: AvailableDayInterface, index: number, date: Date) {
    if (index === 0) {
      this.handleFullDayButtonStatus(dayAllowed);
    }

    const selectedDayIndex = this.selectedDays.findIndex(d => dayAllowed.day === d.day);
    if (selectedDayIndex !== -1) {
      this.selectedDays[selectedDayIndex] = {
        ...this.selectedDays[selectedDayIndex],
        startTime: moment(date).format('HH:mm')
      };
      this.onChange(this.selectedDays);
    }
  }

  changeEndTime(dayAllowed: AvailableDayInterface, index: number, date: Date) {
    const updatedDate = new Date(date);
    if (date.getHours() + date.getMinutes() === 0) {
      updatedDate.setHours(23);
      updatedDate.setMinutes(59);
      dayAllowed.endTime = updatedDate;
    }

    if (index === 0) {
      this.handleFullDayButtonStatus(dayAllowed);
    }

    const selectedDayIndex = this.selectedDays.findIndex(d => dayAllowed.day === d.day);
    if (selectedDayIndex !== -1) {
      this.selectedDays[selectedDayIndex] = {
        ...this.selectedDays[selectedDayIndex],
        endTime: moment(updatedDate).format('HH:mm')
      };
      this.onChange(this.selectedDays);
    }
  }

  applyYesterdaySchedule(day: AvailableDayInterface, index: number) {
    const yesterday = this.availableDays[index - 1];
    day.startTime = yesterday.startTime;
    day.endTime = yesterday.endTime;
  }

  setFullDay(day: AvailableDayInterface) {
    day.startTime = moment(day.startTime).startOf('day').toDate();
    day.endTime = moment(day.endTime).endOf('day').toDate();
    this.setFullDayButton.nativeElement.disabled = true;
  }
  isSetFullDay(day: AvailableDayInterface) {
    const { endTime, startTime } = day;
    const endTimeHour = new Date(endTime).getHours();
    const endTimeMinute = new Date(endTime).getMinutes();
    const startTimeHour = new Date(startTime).getHours();
    const startTimeMinute = new Date(startTime).getMinutes();
    return endTimeHour === 23 && endTimeMinute === 59 && startTimeHour === 0 && startTimeMinute === 0;
  }

  hasError(day: AvailableDayInterface, errorType: string): boolean {
    const dayErrors = this.selectedDays.find(d => d.day === day.day);
    if (!dayErrors) return false;

    const startTime = moment(dayErrors.startTime, 'HH:mm');
    const endTime = moment(dayErrors.endTime, 'HH:mm');

    if (errorType === 'timeRangeInvalid') {
      return startTime.isSameOrAfter(endTime);
    }
    return false;
  }

  private handleFullDayButtonStatus(day: AvailableDayInterface) {
    this.setFullDayButton.nativeElement.disabled = this.isSetFullDay(day);
  }
}

interface AvailableDayInterface {
  day: string;
  label: string;
  checked: boolean;
  startTime: Date | string;
  endTime: Date | string;
}

interface DayAllowed {
  day: string;
  startTime: Date | string;
  endTime: Date | string;
}
