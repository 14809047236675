import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, tap, timeout } from 'rxjs/operators';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import swal from 'sweetalert2';
import { EcondosQuery } from '@api/model/query';
import { Actuator } from '@api/model/hardware/actuator';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { User } from '@api/model/user';
import { AlphaDigiService } from '@api/service/hardware/alphadigi.service';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { orderBy } from 'lodash';
import { IResidenceUser, USER_TYPE } from '@api/model/create-device/residence-user';
import { ModalCreateVehicleComponent } from '../../components/modal-create-vehicle/modal-create-vehicle.component';

@Component({
  selector: 'app-modal-create-alphadigi-device',
  templateUrl: 'modal-create-alphadigi-device.component.html',
  styleUrls: ['modal-create-alphadigi-device.component.scss']
})
export class ModalCreateAlphadigiDeviceComponent implements OnInit, OnDestroy {
  condo: Condo;
  condoActuators: Actuator[] = [];

  protected readonly USER_TYPE = USER_TYPE;

  TYPES = [
    { label: 'Facial', value: DEVICE_TYPES.FACIAL },
    { label: 'QRCode', value: DEVICE_TYPES.QR }
  ];

  status: Status = new Status();

  device: Device;
  callbacks: { success?: (arg) => void; error?: (err) => void };

  form: UntypedFormGroup;

  type: AbstractControl;
  residence: AbstractControl;
  user: AbstractControl;
  userName: AbstractControl;
  picture: AbstractControl;
  condoVehicle: AbstractControl;
  actuators: UntypedFormArray;
  obs: AbstractControl;
  serial: AbstractControl;

  // Used on view to easily check if an actuator is checked or not
  checkedActuators = {};

  residenceSearch;
  residenceUsers: IResidenceUser[] = [];
  loadingResidences = false;

  residenceTypeAheadDataSource$: Observable<any>;

  selectedResidence;

  private subscriptions: Subscription = new Subscription();

  remoteActuator: Actuator = null;

  formIsDisabled = false;

  storageUser: { user: User; residence: Residence; condoVehicle: CondoVehicle };

  public hardware: 'ALPHADIGI' | 'ALPHADIGI_LPR' = 'ALPHADIGI';

  residenceVehicles: CondoVehicle[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    private residenceService: ResidenceService,
    private alphadigiService: AlphaDigiService,
    private deviceService: HardwareDeviceService,
    private actuatorService: ActuatorService,
    private toastrService: ToastrService
  ) {
    this.form = this.fb.group({
      type: ['', Validators.required],
      user: [null],
      userName: [''],
      residence: [null, [Validators.required]],
      picture: [null, Validators.required],
      condoVehicle: [null],
      actuators: this.fb.array([]),
      obs: [''],
      serial: ['']
    });
    this.type = this.form.get('type');
    this.user = this.form.get('user');
    this.userName = this.form.get('userName');
    this.residence = this.form.get('residence');
    this.picture = this.form.get('picture');
    this.actuators = this.form.get('actuators') as UntypedFormArray;
    this.obs = this.form.get('obs');
    this.serial = this.form.get('serial');
    this.condoVehicle = this.form.get('condoVehicle');

    this.subscriptions.add(
      this.user.valueChanges.subscribe(user => {
        if (user === 'NEW_USER') {
          swal({
            title: 'Inserir nome',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            inputPlaceholder: 'Digite o nome...',
            showLoaderOnConfirm: true,
            preConfirm: name => {
              if (!name || !name.trim().length) {
                return Promise.reject(`Insira o nome`);
              } else {
                name = name.trim();
                return Promise.resolve(name);
              }
            }
          }).then(
            name => {
              this.userName.setValue(name);
              this.user.setValue('', { emitEvent: false });
            },
            () => {
              this.userName.setValue('');
              this.user.setValue('', { emitEvent: false });
            }
          );
        } else if (user?.type === 'DEPENDENT') {
          this.userName.setValue(user.name);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          }
          this.user.setValue('', { emitEvent: false });
        } else if (this.checkHasDeviceOwnerPicture()) {
          this.picture.setValue(this.device.owner.picture);
        } else if (user) {
          user = this.residenceUsers.find(u => u._id === user._id);
          if (user?.picture?._id) {
            this.picture.setValue(user.picture);
          } else {
            this.picture.setValue(null);
          }
        } else {
          this.picture.setValue(null);
        }
      })
    );

    this.subscriptions.add(
      this.type.valueChanges.subscribe(type => {
        if (type === 'VEHICLE_PLATE') {
          this.condoVehicle.setValidators(Validators.required);
          this.picture.clearValidators();
          this.hardware = 'ALPHADIGI_LPR';
        } else {
          this.condoVehicle.clearValidators();
          this.picture.setValidators(Validators.required);
          this.hardware = 'ALPHADIGI';
        }
        this.loadAlphaDigiActuators();
        this.condoVehicle.updateValueAndValidity();
        if (this.residence.value?._id) {
          this.getResidenceVehicles(this.residence.value?._id);
        }
        Object.keys(this.form.controls).forEach(key => this.form.get(key).updateValueAndValidity({ emitEvent: false }));
      })
    );

    this.initializeTypeAheads();
  }

  loadAlphaDigiActuators() {
    this.actuatorService.getActuators(this.condo._id, { hardware: this.hardware }).subscribe(
      ({ actuators }) => {
        this.condoActuators = actuators;
      },
      err => {
        // TODO tratar casos de erro
        console.log(err);
      }
    );
  }

  getResidenceVehicles(residenceId: string) {
    if (this.hardware === 'ALPHADIGI_LPR') {
      this.residenceService.getVehicles(this.condo._id, residenceId).subscribe(
        res => {
          this.residenceVehicles = res.vehicles;
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.condoVehicle.setValue(null);
      this.condoVehicle.clearValidators();
      this.residenceVehicles = [];
    }
  }

  initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter(token => (token || '').toString().trim().length > 0),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type voter users',
          $populate: [
            {
              path: 'users',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            },
            {
              path: 'voter',
              select: 'firstName lastName picture phones',
              populate: { path: 'picture', select: 'url thumbnail' }
            }
          ]
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(e => {
            this.status.setAsError();
            return from([]);
          })
        );
      })
    );
  }

  ngOnInit() {
    const { generalParams: { alprParams: { registerResidentVehicleOffline = false } = {} } = {} } = this.condo;

    if (registerResidentVehicleOffline) {
      this.TYPES.push({ label: 'Placa', value: DEVICE_TYPES.VEHICLE_PLATE });
    }

    if (this.device) {
      if (this.device.type === DEVICE_TYPES.VEHICLE_PLATE) {
        this.TYPES.push({ label: 'Placa', value: DEVICE_TYPES.VEHICLE_PLATE });
      }
      this.formIsDisabled = true;
      this.type.setValue(this.device.type);
      this.obs.setValue(this.device.obs);
      this.serial.setValue(this.device.serial);
      this.obs.enable();
      if (this.device.owner) {
        if (this.device.owner.residence && this.device.owner.residence._id) {
          this.residence.setValue(this.device.owner.residence);
          const userId: any = (this.device.owner && this.device.owner.user && this.device.owner.user._id) || '';
          const vehicleId: any =
            this.device.owner &&
            ((this.device.owner.condoVehicle && this.device.owner.condoVehicle._id) || this.device.owner.condoVehicle || '');
          this.initializeFieldsFromResidence(this.device.owner.residence, { userId, vehicleId });
        }
        if (this.device.owner.condoVehicle && this.device.owner.condoVehicle._id) {
          this.residenceVehicles = [this.device.owner.condoVehicle];
          this.condoVehicle.setValue(this.device.owner.condoVehicle._id);
          this.condoVehicle.disable();
          this.residence.disable();
          this.type.disable();
        } else {
          this.condoVehicle.setValue('');
        }
        if (this.device.owner.user && this.device.owner.user._id) {
          const user = {
            name: `${this.device.owner.user.firstName} ${this.device.owner.user.lastName}`,
            _id: this.device.owner.user._id,
            picture: this.device.owner.user.picture,
            type: USER_TYPE.USER
          };
          this.residenceUsers = [user];
          this.user.setValue(user, { emitEvent: false });
        } else {
          this.user.setValue('', { emitEvent: false });
          if (this.device.owner.userName) {
            this.userName.setValue(this.device.owner.userName);
          } else if (this.device.hardwareAttributes && this.device.hardwareAttributes.rotulo) {
            this.userName.setValue(this.device.hardwareAttributes.rotulo);
          }
        }
        if (this.device.owner.picture && this.device.owner.picture._id) {
          this.picture.setValue(this.device.owner.picture);
        }
        this.device.actuators.forEach(({ _id: actuatorId }) => {
          const fc = new UntypedFormControl(actuatorId);
          this.actuators.push(fc);
          this.checkedActuators[actuatorId] = true;
        });
      }
    } else if (this.selectedResidence) {
      this.residence.setValue(this.selectedResidence);
      this.initializeFieldsFromResidence(this.selectedResidence);
    }

    if (this.storageUser) {
      if (this.storageUser.residence) {
        this.residence.setValue(this.storageUser.residence);
        if (!this.storageUser.user) {
          this.initializeFieldsFromResidence(this.storageUser.residence);
        }
        this.user.setValue('');
      }
      if (this.storageUser.user) {
        this.user.setValue(this.storageUser.user._id);
        this.residenceUsers = [
          {
            name: this.storageUser.user.fullName,
            type: USER_TYPE.USER,
            _id: this.storageUser.user._id,
            picture: this.storageUser.user.picture
          }
        ];
        this.user.setValue(this.residenceUsers[0]);
        const residences = this.storageUser.user.getResidences();
        if (residences && residences.length && !this.storageUser.residence) {
          this.residence.setValue(residences[0]);
        }
        if (this.storageUser.user.picture) {
          this.picture.setValue(this.storageUser.user.picture);
        }
        if (this.storageUser.condoVehicle) {
          this.condoVehicle.setValue(this.residenceVehicles.find(v => v._id === this.condoVehicle.value?._id));
          this.condoVehicle.disable();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  buildDevice() {
    const type = this.type.value;
    const device: any = {
      type,
      hardware: this.hardware,
      actuators: this.actuators.value,
      obs: this.obs.value,
      serial: this.serial.value
    };

    if (this.residence.value) {
      device.owner = {
        residence: this.residence.value._id || this.residence.value
      };
      if (this.user.value) {
        device.owner.user = this.user.value._id || this.user.value;
      } else if (this.userName.value) {
        device.owner.userName = this.userName.value;
      }
      if (this.picture.value) {
        device.owner.picture = this.picture.value._id || this.picture.value;
      }
      if (this.condoVehicle.value) {
        device.owner.condoVehicle = this.condoVehicle.value?._id || this.condoVehicle.value;
        const residenceVehicle = this.residenceVehicles.find(v => v._id === (this.condoVehicle.value?._id || this.condoVehicle.value));
        device.serial = residenceVehicle.plate;
      }
    }
    return device;
  }

  save(value) {
    if (this.form.valid) {
      const device = this.buildDevice();
      if (this.device) {
        this.updateDevice(device);
      } else {
        this.createDevice(device);
      }
    } else {
      this.toastrService.warning('Preencha todos os campos e adicione a foto da pessoa');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }

  private handleFacialDevice(savedDevice): Observable<any> {
    return this.alphadigiService.createDevice(savedDevice).pipe(
      switchMap(data => {
        if (data?.results?.every(res => res.ok)) {
          return this.deviceService.update(this.condo._id, data.device?._id, { status: 'SYNCED' }).pipe(
            catchError(() => {
              this.status.setAsError();
              return of({ ...data.device, status: 'UNSYNCED' });
            }),
            map(() => ({ ...data.device, status: 'SYNCED' })),
            tap(() => this.status.setAsSuccess())
          );
        } else {
          const errMessages = data?.results
            ?.filter(res => !res.ok)
            .map(res => `${res.actuator?.name}: ${res.error}`)
            .join('<br>');
          swal({
            type: 'error',
            title: 'Não foi possível cadastrar nos equipamentos',
            html: `<div>${errMessages}</div>`
          });
          this.status.setAsError();
          return of({ ...data.device, status: 'UNSYNCED' });
        }
      })
    );
  }

  createDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .create(this.condo._id, device)
      .pipe(
        map(({ _id, status }) => {
          const actuators = device.actuators?.map(acId => this.condoActuators.find(a => a._id === acId));
          return {
            ...device,
            _id,
            actuators,
            status,
            owner: {
              user: this.user.value || null,
              picture: this.picture.value || null,
              residence: this.residence.value || null,
              userName: this.userName.value || ''
            }
          };
        }),
        switchMap(savedDevice => {
          const type = savedDevice.type;
          if (type === DEVICE_TYPES.FACIAL) {
            return this.handleFacialDevice(savedDevice);
          } else {
            const hardware = savedDevice.hardware;
            let observable;
            switch (hardware) {
              case HARDWARES.ALPHADIGI_LPR:
                observable = of(savedDevice);
                break;
              default:
                observable = of(savedDevice).pipe(
                  switchMap(({ _id: deviceId }) => this.deviceService.update(this.condo._id, deviceId, { status: 'SYNCED' }))
                );
            }

            return observable;
          }
        })
      )
      .subscribe(
        (dev: any) => {
          if (dev.owner) {
            if (this.residence.value) {
              dev.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              const populatedUser = this.residenceUsers.find(u => u._id === this.user.value?._id);
              const [firstName, lastName] = populatedUser.name?.split(' ');
              dev.owner.user = { firstName, lastName, _id: populatedUser._id, picture: populatedUser.picture };
            }
            if (this.picture.value) {
              dev.owner.picture = this.picture.value;
            }
            if (this.condoVehicle.value) {
              dev.owner.condoVehicle = this.residenceVehicles.find(
                v => v._id === (this.condoVehicle.value?._id || this.condoVehicle.value)
              );
            }
          }
          if (this.callbacks?.success) {
            dev = new Device(dev);
            this.callbacks.success(dev);
          }
          this.bsModalRef.hide();
        },
        error => {
          this.status.setAsError();
          if (device.hardware === HARDWARES.ALPHADIGI_LPR) {
            return this._handleAlphadigiLprError(error);
          }
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível cadastrar este dispositivo.'
          });
        }
      );
  }

  updateDevice(device) {
    this.status.setAsProcessing();
    this.deviceService
      .update(this.condo._id, this.device._id, device)
      .pipe(
        map(() => {
          const actuators = device.actuators?.map(acId => this.condoActuators.find(a => a._id === acId));
          return {
            ...device,
            _id: this.device._id,
            actuators,
            owner: {
              user: this.user.value || null,
              picture: this.picture.value || null,
              residence: this.residence.value || null,
              userName: this.userName.value || ''
            }
          };
        }),
        switchMap(savedDevice =>
          savedDevice.type === DEVICE_TYPES.FACIAL
            ? this.alphadigiService.updateDevice(this.condoActuators, savedDevice).pipe(
                switchMap(data => {
                  if (data?.results?.every(res => res.ok)) {
                    return this.deviceService.update(this.condo._id, data.device?._id, { status: 'SYNCED' }).pipe(
                      catchError(() => {
                        this.status.setAsError();
                        return of({ ...data.device, status: 'UNSYNCED' });
                      }),
                      map(() => ({ ...data.device, status: 'SYNCED' })),
                      tap(() => this.status.setAsSuccess())
                    );
                  } else {
                    const errMessages = data?.results
                      ?.filter(res => !res.ok)
                      .map(res => `${res.actuator?.name}: ${res.error}`)
                      .join('<br>');
                    swal({
                      type: 'error',
                      title: 'Não foi possível atualizar nos equipamentos',
                      html: `<div>${errMessages}</div>`
                    });
                    return this.deviceService.update(this.condo._id, data.device?._id, { status: 'UNSYNCED' }).pipe(
                      catchError(() => {
                        this.status.setAsError();
                        return of({ ...data.device, status: 'UNSYNCED' });
                      }),
                      map(() => ({ ...data.device, status: 'UNSYNCED' })),
                      tap(() => this.status.setAsError())
                    );
                  }
                })
              )
            : savedDevice.hardware === HARDWARES.ALPHADIGI_LPR
            ? of(savedDevice)
            : of(savedDevice).pipe(
                switchMap(({ _id: deviceId }) =>
                  this.deviceService.update(this.condo._id, deviceId, { status: 'SYNCED' }).pipe(
                    map(() => ({
                      ...savedDevice,
                      status: 'SYNCED'
                    }))
                  )
                )
              )
        )
      )
      .subscribe(
        (dev: any) => {
          if (dev.owner) {
            if (this.residence.value) {
              dev.owner.residence = this.residence.value;
            }
            if (this.user.value) {
              const populatedUser = this.residenceUsers.find(u => u._id === this.user.value?._id);
              const [firstName, lastName] = populatedUser.name?.split(' ');
              dev.owner.user = { firstName, lastName, _id: populatedUser._id, picture: populatedUser.picture };
            }
            if (this.picture.value) {
              dev.owner.picture = this.picture.value;
            }
          }
          if (this.callbacks?.success) {
            this.callbacks.success(new Device(dev));
          }
          this.bsModalRef.hide();
        },
        error => {
          this.status.setAsError();
          if (device.hardware === HARDWARES.ALPHADIGI_LPR) {
            return this._handleAlphadigiLprError(error);
          }
          swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível editar este dispositivo.'
          });
        }
      );
  }

  checkHasDeviceOwnerPicture() {
    return this.device?.owner?.picture ? true : false;
  }

  async onResidenceSelect(event) {
    const residence: Residence = event.item;
    this.residence.setValue(residence);
    const users = this.getResidenceUsers(residence);
    this.residenceUsers = [...users];
    const dependents = await this.getResidenceDependents(this.condo, residence);
    this.residenceUsers.push(...dependents);
    this.residenceUsers = orderBy(this.residenceUsers, [u => u.name.toLowerCase()]);
    this.user.setValue('');
    this.getResidenceVehicles(residence._id);
  }

  getResidenceUsers(residence) {
    return (residence.residenceUsers || []).map(u => ({
      type: 'USER',
      _id: u._id,
      name: `${u.firstName} ${u.lastName}`,
      picture: u.picture
    }));
  }

  async getResidenceDependents(condo, residence) {
    const dependentsParams = [];
    dependentsParams.push({ '$populate[0][path]': 'picture' });
    dependentsParams.push({ '$populate[0][select]': 'url thumbnail type format name' });
    dependentsParams.push({ $sort: 'name' });
    const dependents = await this.residenceService
      .getDependents(condo._id, residence._id, dependentsParams)
      .pipe(
        map(res =>
          res.dependents.map(dep => ({
            type: USER_TYPE.DEPENDENT,
            _id: dep._id,
            picture: dep.picture,
            name: dep.name
          }))
        )
      )
      .toPromise();
    return dependents;
  }

  clearResidence() {
    this.residenceSearch = '';
    this.residence.setValue(null);
    this.residenceUsers = [];
    this.user.setValue(null);
  }

  initializeFieldsFromResidence(residence, selectedValues: { vehicleId?: string; userId?: string } = {}) {
    const residenceQuery: EcondosQuery = {
      $populate: [
        {
          path: 'users',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        },
        {
          path: 'voter',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail format name' }
        }
      ]
    };
    forkJoin([
      this.residenceService.getResidenceByIdWithParams(this.condo._id, residence._id, residenceQuery),
      this.residenceService.getVehicles(this.condo._id, residence._id)
    ])
      .pipe(timeout(15000))
      .subscribe(
        async ([residenceResponse, vehiclesResponse]) => {
          this.residenceUsers = this.getResidenceUsers(residenceResponse);
          const dependents = await this.getResidenceDependents(this.condo, residence);
          this.residenceUsers.push(...dependents);
          this.residenceVehicles = vehiclesResponse.vehicles;
          this.condoVehicle.setValue(selectedValues.vehicleId || '');
          if (selectedValues.userId) {
            const user = this.residenceUsers.find(u => u._id === selectedValues.userId);
            this.user.setValue(user);
          } else {
            this.user.setValue('');
          }
        },
        async err => {
          console.log(err);
          await swal({
            type: 'error',
            title: 'Ops...',
            text: 'Não foi possível obter os dados necessários, verifique sua conexão e tente novamente.'
          });
          this.bsModalRef.hide();
        }
      );
  }

  toggleActuator(actuator: Actuator) {
    const checkArray: UntypedFormArray = this.actuators;
    const isChecked = checkArray.value.includes(actuator._id);
    if (isChecked) {
      let i = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == actuator._id) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    } else {
      checkArray.push(new UntypedFormControl(actuator._id));
    }
    this.checkedActuators = checkArray.value.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }

  toggleAll() {
    if (this.condoActuators.every(ac => this.checkedActuators[ac._id])) {
      this.condoActuators.forEach(ac => {
        if (!this.checkedActuators[ac._id]) {
          return;
        }
        this.toggleActuator(ac);
      });
    } else {
      this.condoActuators.forEach(ac => {
        if (this.checkedActuators[ac._id]) {
          return;
        }
        this.toggleActuator(ac);
      });
    }
  }

  createVehicle(condo: Condo, user: User = null, residence: Residence = null) {
    const initialState = {
      condo,
      onCreate: (vehicle: CondoVehicle) => {
        this.residenceVehicles = [...this.residenceVehicles, vehicle];
        this.condoVehicle.setValue(vehicle._id);
      },
      ...(residence && { vehicleResidence: residence }),
      ...(user && { vehicleUser: user })
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState });
  }

  private _handleAlphadigiLprError(error: any) {
    const { message = 'Não foi possível salvar este dispositivo.' } = error?.originalError || {};
    return swal({
      type: 'error',
      title: 'Ops...',
      html: `
        ${message}
        <br>
        <br>
        <strong>Verifique a conexão dos equipamentos com o servidor e tente novamente!</strong>
      `
    });
  }
}
