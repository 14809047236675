import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Camera } from '@api/model/camera';
import { loadPlayer, Player } from 'rtsp-relay/browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-rtsp-camera-viewer',
  templateUrl: './rtsp-camera-viewer.component.html',
  styleUrls: ['./rtsp-camera-viewer.component.scss']
})
export class RtspCameraViewerComponent implements OnChanges, OnDestroy {
  @ViewChild('componentWrapper', { static: false }) componentWrapper;
  @ViewChild('canvasWrapper', { static: false }) canvasWrapper;

  @Input() camera: Camera | null = null;
  @Input() fitParentSize = true;
  @Input() displayCameraName = true;

  @Input() placeholderHeight = '100%';
  @Input() placeholderWidth = '100%';

  @Input() containerStyle: { [key: string]: string } = { height: '100%', 'max-height': '100%' };
  @Input() rotate = 0;

  @Output() handleSnapshot: EventEmitter<string> = new EventEmitter();
  @Output() handleSuccessLoad = new EventEmitter();
  @Output() handleError: EventEmitter<any> = new EventEmitter();
  @Output() handleClick: EventEmitter<Camera> = new EventEmitter();

  players: { [key: string]: { player: Player; destroyed: boolean; canvas: HTMLCanvasElement } } = {};
  currentPlayer = '';
  public status: 'PROCESSING' | 'SUCCESS' | 'ERROR';

  public activeHelpMask: FormControl<boolean> = new FormControl(false);

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.activeHelpMask.valueChanges.subscribe({
      next: () => {
        this.loadCamera(this.camera);
      },
      error: err => {
        console.log(err);
        this.handleError.emit(err);
        this.status = 'ERROR';
      }
    });
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    // Quando a pessoa retorna para a janela, reconstruímos o player para eliminarmos o problema de atraso no video
    if (!this.document.hidden) {
      this.loadCamera(this.camera);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.camera) {
      if (changes.camera.currentValue) {
        this.loadCamera(changes.camera.currentValue);
      } else {
        this.unloadCamera();
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  private unloadCamera() {
    this.destroy();
  }

  private destroy() {
    Object.entries(this.players).forEach(([id, playerInfo]) => {
      if (playerInfo.player) {
        playerInfo.player.destroy();
      } else {
        playerInfo.destroyed = true;
      }
    });
  }

  private loadCamera(camera: Camera) {
    this.status = 'PROCESSING';
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.http
      .get('http://localhost:5051', { headers, responseType: 'text' })
      .pipe(timeout(3000))
      .subscribe({
        next: res => {
          this.loadRtspPlayer(camera.rtspUrl);
        },
        error: err => {
          console.log(err);
          this.handleError.emit(err);
          this.status = 'ERROR';
        }
      });
  }

  destroyOldPlayers() {
    Object.entries(this.players).forEach(([id, playerInfo]) => {
      if (playerInfo.player) {
        playerInfo.player.destroy();
        delete this.players[id];
      } else {
        playerInfo.destroyed = true;
      }
      this.renderer.removeChild(this.canvasWrapper, playerInfo.canvas);
      if (playerInfo.destroyed && playerInfo.canvas && !playerInfo.player) {
        delete this.players[id];
      }
    });
  }

  private loadRtspPlayer(rtspUrl: string) {
    setTimeout(() => {
      this.destroyOldPlayers();

      const chars = [...'abcdefghijklmnopqrstuvxzwyABCDEFGHIJKLMNOPQRSTUVXZWY0123456789'];
      const length = 8;
      const playerId = [...Array(length)].map(() => chars[Math.floor(Math.random() * chars.length)]).join(``);

      const canvas = this.renderer.createElement('canvas');
      this.renderer.setStyle(canvas, 'width', '100%');
      this.renderer.setStyle(canvas, 'border-radius', '4px');
      this.renderer.setStyle(canvas, 'vertical-align', 'bottom');
      this.renderer.setProperty(canvas, 'id', playerId);
      this.renderer.appendChild(this.canvasWrapper.nativeElement, canvas);
      this.players[playerId] = { canvas, player: null, destroyed: false };
      this.currentPlayer = playerId;

      const RTSP_SERVER_URL = 'ws://localhost:5051/';
      loadPlayer({
        canvas,
        url: `${RTSP_SERVER_URL}api/stream?url=${btoa(rtspUrl)}`,
        videoBufferSize: 1024 * 1024 * 32,
        disableGl: true,
        disconnectThreshold: 15000,
        onDisconnect: player => {
          if (playerId === this.currentPlayer) {
            this.handleError.emit();
            this.status = 'ERROR';
          }
          // Esse if é utilizado para destruir câmeras que estão offline e não carregam com sucesso
          if ((player as any).isPlaying) {
            player.destroy();
          }
        }
      })
        .then(player => {
          if (this.players[playerId]) {
            if (this.players[playerId]?.destroyed) {
              player.destroy();
              delete this.players[playerId];
            } else {
              this.players[playerId].player = player;
            }

            if (this.fitParentSize) {
              this.renderer.setStyle(canvas, 'max-height', '100%');
              this.renderer.setStyle(canvas, 'max-width', '100%');
            } else {
              if (this.containerStyle['max-height']) {
                this.renderer.setStyle(canvas, 'max-height', this.containerStyle['max-height']);
              }
            }
          }
          if (this.currentPlayer === playerId) {
            this.status = 'SUCCESS';
            this.handleSuccessLoad.emit();
            this.cdr.markForCheck();
          }
        })
        .catch(e => {
          console.error(e);
        });
    }, 0);
  }

  public takeSnapshot() {
    const base64Image = this.players[this.currentPlayer].canvas.toDataURL();
    this.handleSnapshot.next(base64Image);
    return base64Image;
  }

  public click() {
    this.handleClick.next(this.camera);
  }
}
