import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as qs from 'qs';

import { HttpService } from 'app/services/http.service';

import { environment } from '@environment';
import { EcondosQuery } from '@api/model/query';
import { BuildDeliveryTrackingSequence, DeliveryTrackingSequence } from '@api/model/delivery-tracking-sequence';

export interface CreateDeliveryTrackingSequenceDto {
  condo: string;
  createdBy: string;
  title: string;
  description?: string;
  shouldCloseDelivery: boolean;
  shouldNotifyUser: boolean;
  step: number;
}

@Injectable({ providedIn: 'root' })
export class DeliveryTrackingSequenceService {
  private readonly endpoint: string;

  constructor(private http: HttpService) {
    this.endpoint = `${environment.backendUrlV3}condos/`;
  }

  public get(condoId: string, query: EcondosQuery = {}): Observable<DeliveryTrackingSequence[]> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = { params, observe: 'response' as 'body' };

    return this.http
      .get(`${this.endpoint}${condoId}/delivery-tracking/sequence`, options)
      .pipe(map((res: any) => (res.body.length ? res.body.map((data: any) => BuildDeliveryTrackingSequence(data)) : [])));
  }

  public create(condoId: string, data: CreateDeliveryTrackingSequenceDto): Observable<string> {
    return this.http.post(`${this.endpoint}${condoId}/delivery-tracking/sequence`, data).pipe(map((res: any) => res._id));
  }

  public update(condoId: string, sequenceId: string, data: Partial<DeliveryTrackingSequence>): Observable<Object | Error> {
    return this.http.put(`${this.endpoint}${condoId}/delivery-tracking/sequence/${sequenceId}`, data);
  }

  public moveUp(condoId: string, sequenceId: string): Observable<Object | Error> {
    return this.http.put(`${this.endpoint}${condoId}/delivery-tracking/sequence/${sequenceId}/move-up`, {});
  }

  public moveDown(condoId: string, sequenceId: string): Observable<Object | Error> {
    return this.http.put(`${this.endpoint}${condoId}/delivery-tracking/sequence/${sequenceId}/move-down`, {});
  }

  public delete(condoId: string, sequenceId: string): Observable<Object | Error> {
    return this.http.delete(`${this.endpoint}${condoId}/delivery-tracking/sequence/${sequenceId}`);
  }
}
