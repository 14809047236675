// @ts-ignore
import { InjectionToken } from '@angular/core';

export const InputPasswordComponentConfigToken = new InjectionToken<InputPasswordComponentConfig>('InputPasswordComponentConfig');

export interface InputPasswordComponentConfig {
  codeLength?: number;
  inputType?: string;
  initialFocusField?: number;
  isCharsCode?: boolean;
  isCodeHidden?: boolean;
  isPrevFocusableAfterClearing?: boolean;
  isFocusingOnLastByClickIfFilled?: boolean;
  code?: string | number;
  disabled?: boolean;
  autocapitalize?: string;
}

// @ts-ignore
export const defaultComponentConfig: InputPasswordComponentConfig = {
  codeLength: 4,
  inputType: 'tel',
  initialFocusField: undefined,
  isCharsCode: false,
  isCodeHidden: false,
  isPrevFocusableAfterClearing: true,
  isFocusingOnLastByClickIfFilled: false,
  code: undefined,
  disabled: false,
  autocapitalize: undefined
};
