import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { UtilService } from '../services/util.service';
import { Observable } from 'rxjs';

@Injectable()
export class IsGatekeeperOrJanitorGuard implements CanActivate {
  constructor(private utilService: UtilService, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.utilService.getLocalUser();
    if (user.isJanitor() || user.isGatekeeper() || user.isAdmin() || user.isOwner()) {
      return true;
    }
    this.router.navigate(['/notFound']);
    return false;
  }
}
