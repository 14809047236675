export const environment = {
  production: false,
  name: 'DEV',
  // backendUrl: 'https://api.dev.econdos.com.br/api/v1/',
  // backendUrlV2: 'https://api.dev.econdos.com.br/api/v2/',
  // backendUrlV3: 'https://api.dev.econdos.com.br/api/v3/',
  // reportsUrl: 'https://api.dev.econdos.com.br/api/v1/',
  // reportsUrlV2: 'https://api.dev.econdos.com.br/api/v2/',
  // visitorUrl: 'https://dev-visitantes.econdos.com.br',
  // socketUrl: 'wss://api.dev.econdos.com.br'
  backendUrl: 'https://api-staging.econdos.com.br/api/v1/',
  backendUrlV2: 'https://api-staging.econdos.com.br/api/v2/',
  backendUrlV3: 'https://api-staging.econdos.com.br/api/v3/',
  reportsUrl: 'https://api-staging.econdos.com.br/api/v1/',
  reportsUrlV2: 'https://api-staging.econdos.com.br/api/v2/',
  visitorUrl: 'https://dev-visitantes.econdos.com.br',
  socketUrl: 'wss://api-staging.econdos.com.br'
};
