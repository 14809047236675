import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// Model
import { Notification } from '../model/notification';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { map, retry, timeout } from 'rxjs/operators';

@Injectable()
export class NotificationService {
  private endPoint;

  constructor(
    private http: HttpService,
    private constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}users/`;
  }

  getUserNotifications(userId: string, queryString: string = ''): Observable<Notification[]> {
    return this.http
      .get(`${this.endPoint}${userId}/notifications${queryString}`)
      .pipe(map((notifications: any) => notifications.map(not => new Notification(not))));
  }

  getMyNotifications() {
    let qs: any = [];
    qs[qs.length] = '$populate[0][path]=occurrence';
    qs[qs.length] = '$populate[0][select]=type subType residence viewers.residences';
    qs[qs.length] = '$populate[1][path]=createdBy';
    qs[qs.length] = '$populate[1][select]=firstName lastName picture';
    qs[qs.length] = '$populate[1][populate][0][path]=picture';
    qs[qs.length] = '$populate[1][populate][0][select]=url thumbnail';
    qs[qs.length] = 'read=false';
    qs = '?' + qs.join('&');
    return this.getUserNotifications('me', qs);
  }

  countUserNonReadNotifications(userId: string): Observable<number> {
    return this.http.get(`${this.endPoint}${userId}/notifications?read=false&$count=true`) as any;
  }

  markAllUserNotificationsAsRead(userId: string, queryString?: string) {
    return this.http.put(`${this.endPoint}${userId}/notifications`, {});
  }

  getUserNotificationById(userId: string, notificationId: string, queryString?: string): Observable<Notification> {
    return this.http.get(`${this.endPoint}${userId}/notifications/${notificationId}${queryString || ''}`) as any;
  }

  markUserNotificationAsRead(userId: string, notificationId: string) {
    const body = {
      _id: {
        $in: [notificationId]
      }
    };
    return this.http.put(`${this.endPoint}${userId}/notifications/`, body);
  }

  readNotifications(userId: string, notifications: any[]) {
    if (!notifications || !notifications.length) return;
    const body = {
      _id: {
        $in: notifications
      }
    };
    this.http.put(`${this.endPoint}${userId}/notifications/`, body).pipe(timeout(10000), retry(3)).subscribe();
  }

  readNotification(userId: string, notificationId: string) {
    return this.readNotifications(userId, [notificationId]);
  }

  registerUserOnOneSignal(user) {
    if (window['OneSignal']) {
      const userData = {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email
      };
      window['OneSignal'].sendTags(userData);
    }
  }

  unregisterUserOnOneSignal() {
    if (window['OneSignal']) {
      window['OneSignal'].push(function () {
        const keys = ['_id', 'firstName', 'lastName', 'phones', 'email', 'phone'];
        window['OneSignal'].deleteTags(keys);
      });
    }
  }

  handleError(error) {
    return throwError(error.json().error || 'Server error');
  }
}
