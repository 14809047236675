import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-hardware-card',
  template: `
    <div class="hardware-card position-relative">
      <div class="hole"></div>
      <div class="codes-wrapper">
        <div title="Serial" class="text-uppercase">S: {{ serial || '000000' }}</div>
        <div title="Decimal" class="text-uppercase">D: {{ decimal || '000000' }}</div>
        <div title="Wiegand" class="text-uppercase">W: {{ wiegand || '000 00000' }}</div>
      </div>
    </div>
  `,
  styleUrls: ['hardware-card.component.scss']
})
export class HardwareCardComponent implements OnChanges {
  @Input()
  decimal = '000000';

  @Input()
  serial = '000000';

  @Input()
  wiegand = '000 00000';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {}
}
