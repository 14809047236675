import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CondoCustomParam } from '../model/condo.custom.param';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomParamService {
  private endPoint;

  constructor(private http: HttpService, private constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  getCondoCustomParamByType(condoId: string, paramType: string, queryString?: string): Observable<CondoCustomParam> {
    if (queryString) {
      queryString += `&type=${paramType}`;
    } else {
      queryString = `?type=${paramType}`;
    }
    return this.http
      .get(`${this.endPoint}${condoId}/custom-params${queryString}`)
      .pipe(map((params: any) => params.map(customParam => new CondoCustomParam(customParam))[0] || null));
  }

  getCondoCustomParamsByTypes(condoId: string, paramTypes: Array<string>): Observable<CondoCustomParam[]> {
    let qs = '?';
    qs += paramTypes.map((type, i) => `$or[${i}][type]=${type}`).join('&');

    return this.http
      .get(`${this.endPoint}${condoId}/custom-params${qs}`)
      .pipe(map((params: any) => params.map(customParam => new CondoCustomParam(customParam))));
  }

  getCondoCustomParamById(condoId: string, customParamId: string, queryString?: string) {
    return this.http
      .get(`${this.endPoint}${condoId}/custom-params/${customParamId}${queryString || ''}`)
      .pipe(map(customParam => new CondoCustomParam(customParam)));
  }

  createCondoCustomParam(condoId: string, customParam: { type: string; values: Array<string> }) {
    return this.http.post(`${this.endPoint}${condoId}/custom-params`, customParam);
  }

  deleteCondoCustomParam(condoId: string, customParamId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/custom-params/${customParamId}`);
  }

  updateCondoCustomParam(condoId: string, customParamId: string, customParam: any) {
    return this.http.put(`${this.endPoint}${condoId}/custom-params/${customParamId}`, customParam);
  }
}
