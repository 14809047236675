import { Residence } from '../interface/residence';

export class BusinessResidence extends Residence {
  public cnpj;
  public cpf;
  public company;
  public phones;
  public lineOfWork;
  public emails: string[];

  constructor(residence) {
    super(residence);
    this.cnpj = residence.cnpj;
    this.cpf = residence.cpf;
    this.company = residence.company;
    this.phones = residence.phones || [];
    this.emails = residence.emails || [];
    this.lineOfWork = residence.lineOfWork;
  }
}
