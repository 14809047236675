import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpCameraService {
  observables: { [key: string]: Observable<Blob> } = {};

  constructor(protected http: HttpClient) {}

  get(cameraId: string, url: string): Observable<any> {
    if (!this.observables[cameraId]) {
      this.observables[cameraId] = this.http
        .get(url, {
          responseType: 'blob',
          headers: { ignoreLoadingBar: '' }
        })
        .pipe(share());
    }
    return this.observables[cameraId];
  }

  deleteFromCache(cameraId: string) {
    delete this.observables[cameraId];
  }
}
