import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { EcondosQuery } from '@api/model/query';
import { VehicleServiceV2 } from '@api/serviceV2/vehicle.service';
import { merge, noop, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
  timeout
} from 'rxjs/operators';

@Component({
  selector: 'app-vehicles-picker',
  templateUrl: 'vehicles-picker.component.html',
  styleUrls: ['./vehicles-picker.component.scss']
})
export class VehiclesPickerComponent implements OnDestroy {
  @Input() placeholder = 'Busque aqui';
  @Input() condo: Condo;
  @Input() filterCriteria: 'USERS_ONLY' | 'VISITORS_ONLY' | 'BOTH' = 'BOTH';
  @Output() onVehicleSelect: EventEmitter<CondoVehicle> = new EventEmitter<CondoVehicle>();
  @Input() showIcon = false;

  @ViewChild('vehiclesList') vehiclesList;

  STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  };
  searchStatus = null;

  searchText: UntypedFormControl = new UntypedFormControl('');

  vehicles: CondoVehicle[] = [];

  activeIndex = 0;

  unsubscribe$ = new Subject();
  _search$ = new Subject();
  search$ = merge(
    this._search$.asObservable().pipe(
      throttleTime(500),
      map(value => ({ value, source: 'Enter' }))
    ),
    this.searchText.valueChanges.pipe(
      tap(() => {
        this.vehicles = [];
        this.searchStatus = null;
      }),
      debounceTime(1000),
      map(value => ({ value, source: 'Search' }))
    )
  );

  isVehicleSelected = false;

  constructor(private vehicleServiceV2: VehicleServiceV2) {
    this.search$
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((previous, current) => current.source === 'Search' && previous.value === current.value),
        map(value => value.value),
        filter(value => !!value),
        switchMap(token => {
          this.vehicles = [];
          this.searchStatus = this.STATUS.LOADING;

          const query: EcondosQuery = {
            $select: 'type brand model plate color',
            $populate: [{ path: 'residence', select: 'identification type' }],
            $sort: 'plate',
            $limit: 50
          };

          if (this.searchText.value.length > 7) {
            query.chassis = { $regex: this.searchText.value, $options: 'i' };
          }

          if (this.searchText.value.length < 7) {
            query.plate = { $regex: this.searchText.value, $options: 'i' };
          }

          if (this.searchText.value.length === 7) {
            const $or = [];

            $or.push({ plate: { $regex: this.searchText.value, $options: 'i' } });
            $or.push({ chassis: { $regex: this.searchText.value, $options: 'i' } });

            query.$or = $or;
          }

          if (this.filterCriteria === 'USERS_ONLY') {
            query.residence = { $gt: '000000000000000000000000' };
          }

          if (this.filterCriteria === 'VISITORS_ONLY') {
            query.residence = 'null';
          }

          return this.vehicleServiceV2.getVehicles(this.condo._id, query).pipe(
            map(v => ({ ...v, error: false })),
            catchError(err => {
              this.searchStatus = this.STATUS.ERROR;
              return of({ vehicles: [], error: true });
            })
          );
        }),
        tap(({ vehicles, error }) => {
          this.vehicles = vehicles;
          if (error) {
            this.searchStatus = this.STATUS.ERROR;
          } else {
            this.searchStatus = this.STATUS.SUCCESS;
          }
        })
      )
      .subscribe(noop);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getData() {
    this.searchStatus = this.STATUS.LOADING;

    const query: EcondosQuery = {
      $select: 'type brand model plate color',
      $populate: [{ path: 'residence', select: 'identification type' }],
      plate: { $regex: this.searchText.value, $options: 'i' }
    };

    if (this.filterCriteria === 'USERS_ONLY') {
      query.residence = { $ne: 'null' };
    }

    if (this.filterCriteria === 'VISITORS_ONLY') {
      query.residence = { $gt: '000000000000000000000000' };
    }

    this.vehicleServiceV2
      .getVehicles(this.condo._id, query)
      .pipe(timeout(15000))
      .subscribe(
        ({ vehicles }) => {
          this.vehicles = vehicles;
          this.searchStatus = this.STATUS.SUCCESS;
        },
        error => {
          this.searchStatus = this.STATUS.ERROR;
        }
      );
  }

  selectVehicle(vehicle: CondoVehicle) {
    this.searchText.setValue(`${vehicle.plate} - ${vehicle.brand} - ${vehicle.model}`, { emitEvent: false });
    this.onVehicleSelect.emit(vehicle);
    this.vehicles = [];
    this.isVehicleSelected = true;
  }

  clearSelection() {
    this.searchText.setValue('');
    this.isVehicleSelected = false;
    this.onVehicleSelect.emit(null);
  }

  pressEnter(event) {
    if (this.vehicles.length) {
      this.selectVehicle(this.vehicles[this.activeIndex]);
    } else {
      this._search$.next(this.searchText.value);
    }
  }

  selectIndex(index) {
    this.activeIndex = index;
  }

  incrementIndex(event) {
    this.activeIndex < this.vehicles.length - 1 ? this.activeIndex++ : (this.activeIndex = 0);
    this.vehiclesList?.nativeElement?.children[this.activeIndex].scrollIntoViewIfNeeded();
  }

  decrementIndex(event) {
    this.activeIndex > 0 ? this.activeIndex-- : (this.activeIndex = this.vehicles.length - 1);
    this.vehiclesList?.nativeElement?.children[this.activeIndex].scrollIntoViewIfNeeded();
  }
}
