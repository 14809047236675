import { Condo } from '@api/model/condo';
import { PermissionKey } from '@api/model/custom-role/custom-role-permissions';
import { User } from '@api/model/user';

const validatePermissionsStrings = (user: User, condo: Condo, permissionsToValidate: PermissionKey[]) => {
  let missingPermissions: PermissionKey[] = [];

  if (user && condo) {
    missingPermissions = permissionsToValidate.filter(
      permission =>
        !user.getPermissionValue({
          condoId: condo._id,
          permission
        })
    );
  }

  return !missingPermissions.length;
};

const evalConditionString = (user: User, condo: Condo, condition: string) => {
  // Regex para buscar segmentos de texto no formato `${string}.${string}.+` e ignora se for somente `${string}`.
  const regex = /[A-Za-z]+\.[A-Za-z]+(?:\.[A-Za-z]+)*/g;
  let modifiedString = condition;

  // Encontre todos os segmentos correspondentes na string original
  const matches = condition.match(regex);

  if (matches) {
    // Itere pelos segmentos encontrados
    for (const match of matches) {
      // Pega o valor da permissão
      const permissionValue = user.getPermissionValue({
        condoId: condo._id,
        permission: match as PermissionKey
      });

      modifiedString = modifiedString.replace(match, permissionValue.toString());
    }
  }

  return !!eval(modifiedString);
};

export const validateUserPermissions = (user: User, condo: Condo, permissionsToValidate: PermissionKey[] | PermissionKey | string) => {
  if (Array.isArray(permissionsToValidate)) {
    return validatePermissionsStrings(user, condo, permissionsToValidate);
  }

  return evalConditionString(user, condo, permissionsToValidate);
};
