import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ResidenceService } from '../../api/service/residence.service';
import { Subscription } from 'rxjs';
import { EmergencyContact, Residence } from '../../api/model/interface/residence';
import swal from 'sweetalert2';
import { timeout } from 'rxjs/operators';
import { Condo } from '../../api/model/condo';
import { User } from '../../api/model/user';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilService } from 'app/services/util.service';
import { ModalCreateEmergencyContact } from '../modal-create-emergency-contact/modal-create-emergency-contact';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';

@Component({
  selector: 'app-residence-emergency-contact-list',
  templateUrl: 'residence-emergency-contact-list.html',
  styleUrls: ['residence-emergency-contact-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResidenceEmergencyContactList implements OnChanges, OnDestroy, OnInit {
  @Input()
  residence: Residence;
  @Input()
  emergencyContacts: EmergencyContact[];
  @Input()
  condo: Condo;
  @Input()
  user: User;

  @Output()
  emergencyContactsChange: EventEmitter<EmergencyContact[]> = new EventEmitter();

  @ViewChild('emergencyContactTemplate', { static: true }) emergencyContactTemplate: TemplateRef<EmergencyContact>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<EmergencyContact>;
  @ViewChild('emergencyContactsTable', { static: true }) emergencyContactsTable: TableComponent;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<EmergencyContact>[] = [];
  subscription: Subscription = new Subscription();

  today = new Date();

  hasWriteAccess = false;

  constructor(
    private residenceService: ResidenceService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.tableColumns = [
      {
        columnId: 'contact',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.emergencyContactTemplate,
        sortable: false
      }
    ];
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo && changes.residence && changes.user) {
      const user: User = changes.user.currentValue;
      const condo = changes.condo.currentValue;
      const residence: Residence = changes.residence.currentValue;
      this.hasWriteAccess =
        user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id) || residence.isOwner(user._id) || residence.isUser(user._id);
    }
  }

  create() {
    const initialState = {
      condo: this.condo,
      residence: this.residence,
      emergencyContacts: this.emergencyContacts,
      onCreate: emergencyContacts => {
        this.emergencyContacts = [].concat(emergencyContacts);
        this.emergencyContactsChange.emit(emergencyContacts);
      }
    };
    this.modalService.show(ModalCreateEmergencyContact, { initialState, ignoreBackdropClick: true });
  }

  edit(index: number) {
    const initialState = {
      condo: this.condo,
      residence: this.residence,
      emergencyContacts: this.emergencyContacts,
      index,
      onUpdate: emergencyContacts => {
        this.emergencyContacts = [].concat(emergencyContacts);
        this.emergencyContactsChange.emit(emergencyContacts);
      }
    };
    this.modalService.show(ModalCreateEmergencyContact, { initialState, ignoreBackdropClick: true });
  }

  delete(index: number) {
    swal({
      type: 'question',
      text: `Deseja remover o ${this.emergencyContacts[index].name} da lista de contato de emergência?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.emergencyContacts.splice(index, 1);
        return this.residenceService
          .updateEmergencyContact(this.condo._id, this.residence._id, this.emergencyContacts)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível excluir o contato, tente novamente');
          });
      }
    }).then(() => {
      this.emergencyContacts = [].concat(this.emergencyContacts);
      this.toastr.success('Contato removido com sucesso');
      this.emergencyContactsChange.emit(this.emergencyContacts);
    });
  }
}
