import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CondoContact } from '../model/contact/condo.contact';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { map } from 'rxjs/operators';
import { EcondosQuery } from '../model/query';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { downloadDataInChunks } from '../utils';
import { ContactID } from '@api/model/contact/contact.id';

@Injectable()
export class CondoContactService {
  protected endPoint;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /** @Deprecated Use getContacts instead */
  getCondoContacts(condoId: string, queryString?: string): Observable<CondoContact[]> {
    return this.http
      .get(`${this.endPoint}${condoId}/contacts${queryString || ''}`)
      .pipe(map((contacts: any) => contacts.map(contact => new CondoContact(contact))));
  }

  getContacts(condoId: string, params: EcondosQuery): Observable<{ count: number; contacts: CondoContact[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/contacts`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          contacts: res.body.map(contact => new CondoContact(contact))
        }))
      );
  }

  search(condoId: string, query: EcondosQuery): Observable<{ count: number; contacts: CondoContact[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });

    const endpoint = `${this.endPoint}${condoId}/contacts/search`;

    return this.http.getWithFullResponse(endpoint, { params: httpParams }).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        contacts: res.body.map(contact => new CondoContact(contact))
      }))
    );
  }

  searchByToken(condoId: string, token: string, query: EcondosQuery): Observable<{ count: number; contacts: CondoContact[] }> {
    query.token = token;
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/contacts/searchToken`, {
        params
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          contacts: res.body.map(contact => new CondoContact(contact))
        }))
      );
  }

  searchByDocument(
    condoId: string,
    docType: keyof typeof ContactID.TYPES,
    docNumber: string,
    query: EcondosQuery
  ): Observable<{ count: number; contacts: CondoContact[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/contacts/searchByDocument/${docType}/${docNumber}`, { params }).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        contacts: res.body.map(contact => new CondoContact(contact))
      }))
    );
  }

  getContactsReport(condoId: string, query: EcondosQuery) {
    return downloadDataInChunks(this.http, `${this.endPoint}${condoId}/contacts`, query, { model: CondoContact, numberOfRequests: 4 });
  }

  getCondoContactById(condoId: string, contactId: string, query?: EcondosQuery) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get(`${this.endPoint}${condoId}/contacts/${contactId}`, { params }).pipe(map(contact => new CondoContact(contact)));
  }

  getCondoContactUnmaskedField(condoId: string, contactId: string, field: string, params: EcondosQuery = {}): Observable<{ data: any }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/contacts/${contactId}/lgpd/${field}`, options).pipe(
      map((res: any) => ({
        data: res.body[field]
      }))
    );
  }

  createCondoContact(condoId: string, contact: any) {
    return this.http.post(`${this.endPoint}${condoId}/contacts`, contact);
  }

  deleteCondoContact(condoId: string, contactId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/contacts/${contactId}`);
  }

  updateCondoContact(condoId: string, contactId: string, contact: any) {
    return this.http.put(`${this.endPoint}${condoId}/contacts/${contactId}`, contact);
  }

  banCondoContact(condoId: string, contactId: string, banReason = '') {
    return this.http.put(`${this.endPoint}${condoId}/contacts/${contactId}/ban`, { banReason });
  }

  unbanCondoContact(condoId: string, contactId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/contacts/${contactId}/ban`);
  }

  mergeCondoContacts(condoId: string, data: { baseContact: any; mergingContacts: string[] }) {
    return this.http.put(`${this.endPoint}${condoId}/contacts/mergeContact`, data);
  }
}
