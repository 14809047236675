import { Construction } from '@api/model/construction';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EmergencyContact, Residence } from '@api/model/interface/residence';
import { ResidenceService } from '@api/service/residence.service';
import { UtilService } from '../../../services/util.service';
import swal, { SweetAlertInputOptions } from 'sweetalert2';
import { User } from '@api/model/user';
import { firstValueFrom, forkJoin, from, lastValueFrom, merge, of, Subject, Subscription } from 'rxjs';
import { ModalCreateApartmentsComponent } from '../../modal/create.apartments.modal';
import { Condo } from '@api/model/condo';
import { UntypedFormControl } from '@angular/forms';
import { ApartmentResidence } from '@api/model/residence/residence.apartment';
import { HouseResidence } from '@api/model/residence/residence.house';
import { Vehicle } from '@api/model/vehicle';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { Status } from '@api/model/status';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CondoService } from '@api/service/condo.service';
import { formatCep, formatCnpj, formatCpf } from '@api/util/formatters';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  timeout
} from 'rxjs/operators';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { Dependent } from '@api/model/dependent';
import { Pet } from '@api/model/pet';
import { EcondosQuery } from '@api/model/query';
import { File } from '@api/model/file';
import { ReportService } from '@api/service/report.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalResidentPickerComponent } from '../../modal/modal-resident-picker/modal-resident-picker.component';
import { ModalResidenceConfigComponent } from './modal-residence-config/modal-residence-config.component';
import { ModalCreateResidenceComponent } from '../../modal/create-residence.modal/create-residence-modal.component';
import { BusinessResidence } from '@api/model/residence/residence.business';
import { ModalTerminatedContractsReportComponent } from './modal-terminated-contracts-report/modal-terminated-contracts-report.component';
import { capitalize } from '@api/util/util';
import { ModalResidencesConfigComponent } from './modal-residences-config/modal-residences-config.component';
import { ResidenceServiceV3 } from '@api/serviceV3/residence.service';
import { ReceiptListComponent } from 'app/components/receipt-list/receipt-list.component';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

@Component({
  selector: 'app-condo-residences',
  templateUrl: 'condo.residences.html',
  styleUrls: ['./condo.residences.scss']
})
export class CondoResidencesComponent implements OnInit, OnDestroy {
  @ViewChild('iconTemplate') iconTemplate: TemplateRef<any>;
  @ViewChild('identificationTemplate', { static: true }) identificationTemplate: TemplateRef<any>;
  @ViewChild('voterTemplate') voterTemplate: TemplateRef<any>;
  @ViewChild('usersTemplate', { static: true }) usersTemplate: TemplateRef<any>;

  @ViewChild('residencesTable', { static: true }) residencesTable;

  @ViewChild(ModalCreateApartmentsComponent, { static: true }) createResidencesModal: ModalCreateApartmentsComponent;

  user: User;

  condo: Condo;

  columns = [];

  userPickerMultipleSelection = false;
  userIdsToHide: Array<string> = [];

  residences: Array<Residence> = [];
  filteredResidences: Array<Residence> = [];
  residencesWithRequester: Array<Residence> = [];

  selectedResidences: Array<Residence> = [];
  selectedResidence: BusinessResidence | HouseResidence | ApartmentResidence;

  status = new Status();
  defaulterStatus = new Status();
  rentedStatus = new Status();
  detailsStatus = new Status();

  isPaginating = false;

  formatCep = formatCep;
  formatCnpj = formatCnpj;
  formatCpf = formatCpf;

  // Variável responsável por conter o callback desejado após a seleção de algum usuário no modal de seleção de usuários
  userSelectCallback;

  messages;

  searchTerm: UntypedFormControl;
  fullUserData: User;

  totalNumberOfResidences = 0;
  allResidences: any[] = [];
  readonly pageLimit = 50;
  residenceQS = [];

  // Dependents
  dependents: Dependent[] = [];
  pets: Pet[] = [];
  vehicles: CondoVehicle[] = [];
  constructions: Construction[] = [];

  selectSubject: Subject<string> = new Subject();

  hasWriteAccess = false;

  condoPermissions = {
    isApartment: false,
    isHouse: false,
    isBusiness: false,
    isPetsDisabled: false,
    isDependentsDisabled: false,
    isConstructionsDisabled: false,
    isBoletoDisabled: false
  };

  types = {
    isApartment: false,
    isHouse: false,
    isBusiness: false
  };

  voterResidenceUserLabel: string;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private residenceServiceV3: ResidenceServiceV3,
    private residenceService: ResidenceService,
    private utilService: UtilService,
    private condoService: CondoService,
    private reportService: ReportService,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: HardwareDeviceService
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();
    this.messages = {
      emptyMessage: '',
      totalMessage: this.condo?.customLabels?.residence?.singular || 'unidade' + '(s)'
    };

    this.hasWriteAccess = this.user.isAdminOnCondo(this.condo._id) || this.user.isOwnerOnCondo(this.condo._id);
    this.condoPermissions = {
      isApartment: this.condo.isApartmentCondo(),
      isHouse: this.condo.isHouseCondo(),
      isBusiness: this.condo.isBusinessCondo(),
      isPetsDisabled: this.condo.isPetsDisabled(),
      isDependentsDisabled: this.condo.isDependentsDisabled(),
      isConstructionsDisabled: this.condo.isConstructionsDisabled(),
      isBoletoDisabled: !this.condo.isBoletoEnabled()
    };
    this.searchTerm = new UntypedFormControl();
    this.searchTerm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(token => {
      if (!token) {
        this.downloadData(0, true);
        return;
      }
      this.status.setAsDownloading();
      const query: EcondosQuery = { $limit: 50 };
      this.residenceService
        .searchByToken(this.condo._id, token, query)
        .pipe(timeout(10000))
        .subscribe(
          response => {
            this.status.setAsSuccess();
            this.filteredResidences = response.residences;
            this.totalNumberOfResidences = response.residences.length;
          },
          () => {
            this.status.setAsError();
            this.filteredResidences = this.residences;
          }
        );
    });

    this.subscriptions.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(async (evt: NavigationEnd) => {
        const urlParams = evt.urlAfterRedirects.split('/').filter(v => v);
        if (urlParams[0] === 'condo' && urlParams[1] === 'residences' && urlParams[2]) {
          this.getSingleResidence(urlParams[2]);
        }
      })
    );
  }

  ngOnInit() {
    this.columns = [
      {
        prop: 'qtPeople',
        minWidth: 70,
        width: 80,
        maxWidth: 80,
        name: 'Pessoas',
        cellTemplate: this.usersTemplate
      },
      {
        prop: 'identification',
        name: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        cellTemplate: this.identificationTemplate
      }
    ];

    this.subscriptions.add(
      this.selectSubject
        .pipe(
          tap(() => this.detailsStatus.setAsDownloading()),
          switchMap(residenceId => {
            const petsParams = [];
            petsParams.push({ '$populate[0][path]': 'picture' });
            petsParams.push({ '$populate[0][select]': 'url thumbnail type format name' });
            petsParams.push({ $sort: 'name' });
            const residenceQuery: EcondosQuery = {
              $populate: [
                {
                  path: 'requesters',
                  select: 'firstName lastName picture',
                  populate: {
                    path: 'picture',
                    select: 'url thumbnail'
                  }
                },
                {
                  path: 'voter',
                  select: 'firstName lastName email picture phones condoResidenceAttributes',
                  populate: {
                    path: 'picture',
                    select: 'url thumbnail'
                  }
                }
              ]
            };
            const constructionQuery: EcondosQuery = {
              $populate: [
                { path: 'attachments', select: 'url thumbnail type format name' },
                { path: 'technicalAttachments', select: 'url thumbnail type format name' },
                { path: 'createdBy', select: 'firstName lastName' }
              ]
            };

            return forkJoin([
              this.residenceService.getResidenceByIdWithParams(this.condo._id, residenceId, residenceQuery),
              this.residenceService.getConstructions(this.condo._id, residenceId, constructionQuery)
            ]).pipe(
              timeout(15000),
              catchError(() => {
                this.detailsStatus.setAsError();
                return of(null);
              })
            );
          })
        )
        .subscribe((res: [Residence, { constructions: Construction[] }]) => {
          if (res) {
            const residence = res[0];
            const constructions = res[1].constructions;
            this.selectedResidence = residence;
            this.fillResidenceUserLabels(this.selectedResidence.voter);
            this.types = {
              isApartment: this.selectedResidence?.type === Residence.TYPES.APARTMENT,
              isBusiness: this.selectedResidence?.type === Residence.TYPES.BUSINESS,
              isHouse: this.selectedResidence?.type === Residence.TYPES.HOUSE
            };
            this.constructions = constructions;

            this.detailsStatus.setAsSuccess();
          }
        })
    );

    this.downloadData(0, true);
  }

  populateVehicles(vehicles) {
    this.vehicles = vehicles;
  }

  fillResidenceUserLabels(voter) {
    if (voter) {
      this.voterResidenceUserLabel = voter.condoResidenceAttributes.find(attr => attr.residence === this.selectedResidence.id)?.userLabel;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getSingleResidence(residenceId: string) {
    this.selectSubject.next(residenceId);
  }

  setDefaulter(residence: Residence) {
    this.defaulterStatus.setAsDownloading();
    this.residenceService
      .updateResidence(this.condo.id, residence.id, { defaulter: residence.defaulter })
      .pipe(timeout(10000))
      .subscribe(
        () => {
          this.defaulterStatus.setAsSuccess();
        },
        () => {
          this.defaulterStatus.setAsError();
          residence.defaulter = !residence.defaulter;
        }
      );
  }

  toggleRented(residence: Residence) {
    this.rentedStatus.setAsDownloading();
    this.residenceService
      .updateResidence(this.condo._id, residence.id, { rented: residence.rented })
      .pipe(timeout(10000))
      .subscribe(
        () => {
          this.rentedStatus.setAsSuccess();
        },
        () => {
          this.rentedStatus.setAsError();
          residence.rented = !residence.rented;
        }
      );
  }

  onScroll() {
    // When searching, there is no scroll
    if (this.searchTerm.value) {
      return;
    }

    // Already downloaded all data available
    if (this.residences.length >= this.totalNumberOfResidences) {
      return;
    }

    // Must subtract requesters length to paginate correctly , due to, residences query only search by residences without requesters
    const currentPage = Math.ceil((this.residences.length - this.residencesWithRequester.length) / this.pageLimit);
    const lastIndex = this.residencesTable.bodyComponent.indexes.last;
    const percentScrolled = lastIndex / this.residences.length;

    if (!this.status.isDownloading() && percentScrolled > 0.9) {
      this.isPaginating = true;
      this.downloadData(currentPage, false);
    }
  }

  goToResident(resident: User) {
    this.router.navigate(['condo/residents/' + resident.id]);
  }

  goToVehicles(vehicle: Vehicle) {
    if (this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper()) {
      this.router.navigate(['condo/vehicles/' + vehicle.id]);
    }
  }

  onDocumentChange(documents: File[]) {
    this.selectedResidence.documents = documents;
  }

  refreshData() {
    this.searchTerm.setValue('');
    this.downloadData(0, true);
  }

  downloadData(pageNumber = 0, shouldGetRequesters?: boolean) {
    if (!pageNumber) {
      if (this.residencesTable && this.residencesTable.bodyComponent && this.residencesTable.bodyComponent.updateOffsetY) {
        this.residencesTable.bodyComponent.updateOffsetY(0);
      }
    }

    const qs: EcondosQuery = {
      $page: pageNumber,
      $limit: this.pageLimit,
      requesters: '[]'
    };
    const residencesObservable = this.residenceService
      .getResidences(this.condo.id, qs)
      .pipe(timeout({ each: 20000, with: () => ErrorBuilder.throwTimeoutError() }));

    const observables = [residencesObservable];
    if (shouldGetRequesters) {
      const requestersParams: EcondosQuery = {
        requesters: { $ne: '[]' }
      };
      const requestersObservable = this.residenceService
        .getResidences(this.condo.id, requestersParams)
        .pipe(timeout({ each: 10000, with: () => ErrorBuilder.throwTimeoutError() }));
      observables.push(requestersObservable);
    }
    const subscription = forkJoin(...observables);
    this.status.setAsDownloading();

    subscription
      .pipe(timeout({ each: 20000, with: () => ErrorBuilder.throwTimeoutError() }))
      .subscribe(([residencesResponse, requestesResponse]) => {
        if (pageNumber === 0) {
          this.totalNumberOfResidences = Number(residencesResponse?.count || 0) + Number(requestesResponse?.count || 0);
        }

        let residences = residencesResponse?.residences || [];

        residences = residences.sort(function (a: any, b: any) {
          return a.identification.length - b.identification.length || a.identification.localeCompare(b.identification);
        });

        const requesters = requestesResponse?.residences || [];

        if (shouldGetRequesters) {
          this.residencesWithRequester = requesters;
        }

        if (pageNumber == 0) {
          this.filteredResidences = this.residences = [].concat(this.residencesWithRequester, residences);
        } else {
          this.residences = [...this.residences, ...residences];
          this.filteredResidences = this.residences;
        }
        const residenceId = this.route.snapshot.params['residenceId'];

        if (residenceId) {
          this.getSingleResidence(residenceId);
        }

        this.status.setAsSuccess();
        if (residences.length == 0) {
          this.messages.emptyMessage =
            'Nenhum(a) ' + this.condo?.customLabels?.residence?.singular ||
            'unidade' + ' cadastrada neste(a) ' + this.condo?.customLabels?.condo?.singular;
        }
        this.isPaginating = false;
      });
  }

  onSelect({ selected }) {
    this.selectedResidences.splice(0, this.selectedResidences.length);
    this.selectedResidences.push(...selected);
    this.selectedResidence = this.selectedResidences[0];
    this.fillResidenceUserLabels(this.selectedResidence.voter);
    this.getSingleResidence(this.selectedResidence._id);
  }

  askToSetUserAsVoter(user, residence) {
    swal({
      type: 'question',
      title: `Definir ${this.condo?.customLabels?.voter?.singular || 'proprietário'}`,
      text: `Deseja definir ${user.firstName} ${user.lastName} como ${this.condo?.customLabels?.voter?.singular || 'proprietário'} da(o) ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      } ${residence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.setUserAsVoter(user, residence)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject(
              'Não foi possível alterar o ' + (this.condo?.customLabels?.voter?.singular || 'proprietário') + ', tente novamente...'
            );
          });
      }
    }).then(
      () => {
        this.downloadData(0, true);
        this.getSingleResidence(residence._id);
        swal({
          type: 'success',
          title: `${capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário'} alterado com sucesso!`
        });
      },
      () => {}
    );
  }

  setUserAsVoter(user, residence: Residence) {
    return this.residenceService.changeResidenceVoter(this.condo._id, residence.id, user._id).pipe(
      timeout(10000),
      flatMap(() => {
        return this.residenceService.getResidenceById(this.condo._id, residence.id, this.residenceQS.join('&')).pipe(timeout(10000));
      })
    );
  }

  askToRemoverUserFromResidence(user, residence) {
    const sweetAlertOptions: SweetAlertInputOptions = {
      DEL_USER_RESIDENT: `Remover apenas da(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'}`,
      DEL_USER_CONDO: `Remover da(o) ${this.condo?.customLabels?.condo?.singular || 'Condomínio'}`
    };

    swal({
      title: `Como ${user.firstName + ' ' + user.lastName} será removido?`,
      type: 'question',
      input: 'radio',
      inputOptions: sweetAlertOptions,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,

      preConfirm: selection => {
        switch (selection) {
          case 'DEL_USER_RESIDENT':
            return lastValueFrom(
              this.removeUserFromResidence(user, residence).pipe(
                timeout(10000),
                map((value: any) => {
                  return Promise.resolve(value);
                }),
                catchError(err => {
                  return Promise.reject(
                    err?.originalError?.message ||
                      `Não foi possível remover o ${this.condo?.customLabels?.resident?.singular || 'condômino'}. Tente novamente`
                  );
                })
              )
            );

          case 'DEL_USER_CONDO':
            const deviceQuery: EcondosQuery = {
              accessType: 'RESIDENT',
              status: { $in: ['ACTIVE', 'SYNCED', 'UNSYNCED', 'OPENED'] },
              'owner.user': user
            };
            const observable = this.deviceService.get(this.condo.id, deviceQuery);
            return lastValueFrom(observable)
              .then((value: any) => {
                if (+value.count === 0) {
                  return firstValueFrom(
                    this.condoService.removeUserFromCondo(this.condo._id, user._id, true).pipe(
                      timeout(10000),
                      map((value: any) => {
                        return value;
                      }),
                      catchError(err => {
                        return Promise.reject(
                          err?.originalError?.message ||
                            `Não foi possível remover completamente o ${
                              this.condo?.customLabels?.resident?.singular || 'condômino'
                            }. Tente novamente`
                        );
                      })
                    )
                  ).then(() => {
                    swal({
                      type: 'success',
                      title: '' + (capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino') + ' excluído com sucesso!'
                    });
                    this.downloadData(0, true);
                    this.getSingleResidence(residence._id);
                  });
                } else {
                  return new Promise(() => {
                    swal({
                      type: 'warning',
                      title: 'Dispositivos encontrados',
                      text: `Este ${
                        this.condo?.customLabels?.resident?.singular || 'condômino'
                      } possui dispositivos associados a ele. Ao remover este ${this.condo?.customLabels?.resident?.singular || 'condômino'}
            do ${this.condo?.customLabels?.condo?.singular || 'condomínio'}, os dispositivos de acesso serão mantidos.`,
                      showCancelButton: true,
                      confirmButtonText: 'Sim',
                      confirmButtonColor: '#32DB64',
                      cancelButtonColor: '#f53d3d',
                      cancelButtonText: 'Não',
                      reverseButtons: true
                    }).then(() => {
                      return firstValueFrom(
                        this.condoService.removeUserFromCondo(this.condo._id, user._id, true).pipe(
                          timeout(10000),
                          map((value: any) => {
                            return value;
                          }),
                          catchError(err => {
                            return Promise.reject(
                              err?.originalError?.message ||
                                `Não foi possível remover completamente o ${
                                  this.condo?.customLabels?.resident?.singular || 'condômino'
                                }. Tente novamente`
                            );
                          })
                        )
                      ).then(() => {
                        swal({
                          type: 'success',
                          title: '' + (capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino') + ' excluído com sucesso!'
                        });
                        this.downloadData(0, true);
                        this.getSingleResidence(residence._id);
                      });
                    });
                  });
                }
              })
              .catch(err => {
                return Promise.reject(
                  err?.originalError?.message ||
                    `Não foi possível remover completamente o ${
                      this.condo?.customLabels?.resident?.singular || 'condômino'
                    }. Tente novamente`
                );
              });
          default:
            return Promise.reject('Nenhuma opção selecionada.');
        }
      }
    }).then(
      () => {
        swal({
          type: 'success',
          title: '' + (capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino') + ' excluído com sucesso!'
        });

        this.downloadData(0, true);
        this.getSingleResidence(residence._id);
      },
      err => {
        console.log(err);
      }
    );
  }

  removeUserFromResidence(user: User, residence: Residence) {
    return this.residenceService.removeUserFromResidence(this.condo._id, residence._id, user._id).pipe(
      flatMap(() => {
        return this.residenceService.getResidenceById(this.condo._id, residence._id, this.residenceQS.join('&'));
      })
    );
  }

  askToApproveUserOnResidence(requester, residence: Residence) {
    swal({
      title: `Aceitar ${this.condo?.customLabels?.resident?.singular || 'condômino'}`,
      type: 'question',
      text: `Deseja realmente aceitar ${requester.firstName} ${requester.lastName} como ${
        this.condo?.customLabels?.resident?.singular || 'condômino'
      } da(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'} ${residence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.approveUserOnResidence(requester, residence)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject(
              'Não foi possível aceitar o ' + (this.condo?.customLabels?.resident?.singular || 'condômino') + ', tente novamente...'
            );
          });
      }
    }).then(
      () => {
        this.downloadData(0, true);
        this.getSingleResidence(residence._id);
        swal({
          type: 'success',
          title: (capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino') + ' aceito com sucesso!'
        });
      },
      () => {}
    );
  }

  approveUserOnResidence(requester, residence: Residence) {
    if (residence.hasUsers()) {
      return this.residenceService.approveUserOnResidence(this.condo._id, residence.id, requester._id).pipe(
        flatMap(() => {
          return this.residenceService.getResidenceById(this.condo._id, residence.id, this.residenceQS.join('&'));
        })
      );
    } else {
      return this.setUserAsVoter(requester, residence);
    }
  }

  askToRejectUserOnResidence(requester, residence) {
    swal({
      type: 'question',
      title: 'Rejeitar ' + (this.condo?.customLabels?.resident?.singular || 'condômino'),
      text: `Deseja realmente rejeitar o pedido de ${requester.firstName} ${requester.lastName} para ser ${
        this.condo?.customLabels?.resident?.singular || 'condômino'
      } na(o) ${this.condo?.customLabels?.resident?.singular || 'condômino'} ${residence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.rejectRequesterOnResidence(requester, residence)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject('Não foi possível rejeitar o pedido, tente novamente...');
          });
      }
    }).then(
      () => {
        this.downloadData(0, true);
        this.getSingleResidence(residence._id);
        swal({
          type: 'success',
          title: 'Pedido rejeitado com sucesso!'
        });
      },
      () => {}
    );
  }

  rejectRequesterOnResidence(requester, residence) {
    return this.residenceService.rejectUserOnResidence(this.condo._id, residence.id, requester._id).pipe(
      flatMap(() => {
        return this.residenceService.getResidenceById(this.condo._id, residence._id, this.residenceQS.join('&'));
      })
    );
  }

  selectUserToSetAsVoter(residence: Residence) {
    this.userPickerMultipleSelection = false;
    this.userIdsToHide = residence.voter ? [residence.voter._id] : [];
    this.userSelectCallback = selectedUsers => {
      if (selectedUsers && selectedUsers[0]) {
        this.askToSetUserAsVoter(selectedUsers[0], residence);
      }
    };
    this.selectUser();
  }

  askToRemoveVoterFromResidence(residence) {
    swal({
      type: 'question',
      title: `Remover ${this.condo?.customLabels?.voter?.singular || 'proprietário'}`,
      text: 'Tem certeza que deseja remover o proprietário desta(e) ' + this.condo?.customLabels?.residence?.singular || 'unidade',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.residenceService
          .removeVoterFromResidence(this.condo._id, residence._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => {
            return Promise.reject(
              `Não foi possível remover o ${this.condo?.customLabels?.voter?.singular || 'proprietário'}. Tente novamente`
            );
          });
      }
    }).then(
      () => {
        this.downloadData(0, true);
        this.getSingleResidence(residence._id);
        swal({
          type: 'success',
          text: `${capitalize(this.condo?.customLabels?.voter?.singular) || 'Proprietário'} removido com sucesso`
        });
      },
      () => {}
    );
  }

  addUsersOnResidence(usersToAdd: Array<User>, residence: Residence) {
    swal({
      title: 'Adicionando ' + (this.condo?.customLabels?.resident?.plural || 'condôminos'),
      type: 'info',
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    swal.showLoading();

    forkJoin(
      usersToAdd.map(user => {
        return this.residenceService.approveUserOnResidence(this.condo._id, residence.id, user.id);
      })
    )
      .pipe(timeout(10000))
      .subscribe(
        () => {
          this.downloadData(0, true);
          this.getSingleResidence(residence._id);
          swal.close();
          swal({
            type: 'success',
            title: (capitalize(this.condo?.customLabels?.resident?.plural) || 'Condôminos') + ' adicionados com sucesso',
            showCancelButton: false,
            confirmButtonText: 'Fechar'
          });
        },

        () => {
          this.downloadData(0, true);
          swal.close();
          swal({
            type: 'error',
            title: 'Erro ao adicionar ' + (this.condo?.customLabels?.resident?.plural || 'condôminos'),
            text:
              'Não foi possível adicionar uma ou mais ' +
              (this.condo?.customLabels?.resident?.plural || 'condôminos') +
              ', tente adicionar novamente',
            showCancelButton: false,
            confirmButtonText: 'Fechar'
          });
        }
      );
  }

  selectUser() {
    const initialState: any = {
      condo: this.condo,
      userIdsToHide: this.userIdsToHide,
      multipleSelection: this.userPickerMultipleSelection,
      callbacks: {
        success: selectedUsers => {
          if (selectedUsers.length) {
            this.userSelectCallback(selectedUsers);
          }
        }
      }
    };
    this.modalService.show(ModalResidentPickerComponent, { initialState, class: 'modal-md', ignoreBackdropClick: true });
  }

  showCreateResidencesModal() {
    this.createResidencesModal.showModal();
  }

  createResidence() {
    const initialState = {
      condo: this.condo,
      callback: residence => {
        if (residence?._id) {
          this.downloadData(0, true);
        }
      }
    };
    this.modalService.show(ModalCreateResidenceComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
  }

  editResidence(residence) {
    const initialState = {
      condo: this.condo,
      editingResidence: residence,
      callback: updatedResidence => {
        if (updatedResidence?._id) {
          this.downloadData(0, true);
          this.getSingleResidence(updatedResidence._id);
        }
      }
    };
    this.modalService.show(ModalCreateResidenceComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
  }

  askToRemoveResidence(residence) {
    swal({
      type: 'question',
      title: `Excluir ${this.condo?.customLabels?.residence?.singular || 'unidade'} ${residence.identification}`,
      text:
        'Deseja realmente excluir a(o) ' + this.condo?.customLabels?.residence?.singular ||
        'unidade' + ' do(a) ' + this.condo?.customLabels?.condo?.singular + '?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.residenceService
          .deleteResidenceFromCondo(this.condo._id, residence.id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(() => Promise.reject('Não foi possível realizar a exclusão, tente novamente...'));
      }
    }).then(
      () => {
        this.selectedResidence = null;
        this.downloadData(0, true);
        swal({
          type: 'success',
          title: 'Exclusão realizada com sucesso'
        });
      },
      () => {}
    );
  }

  askToRemoveAllResidences() {
    const unsubscribe: Subject<void> = new Subject();
    const text = `Você realmente deseja excluir TODAS AS(OS) ${
      this.condo?.customLabels?.residence?.plural?.toUpperCase() || 'UNIDADES'
    }? Digite "excluir tudo" no campo abaixo para confirmar sua ação. Lembre-se que esta ação não pode ser desfeita.`;
    swal({
      type: 'warning',
      title: 'Excluir todas as(os) ' + this.condo?.customLabels?.residence?.plural || 'unidades',
      text,
      showCancelButton: true,
      input: 'text',
      inputPlaceholder: 'excluir tudo',
      confirmButtonText: 'Excluir ' + this.condo?.customLabels?.residence?.plural || 'unidades',
      confirmButtonColor: '#f53d3d',
      cancelButtonClass: 'btn-outline-danger',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async input => {
        input = (input || '').toString().trim().toLowerCase();
        if (!input || input !== 'excluir tudo') {
          return Promise.reject(`Digite "excluir tudo" para confirmar sua ação`);
        } else {
          const residences = await this.getResidencesRecursively();
          const results = [];
          const requests = residences.map(residence => {
            return this.residenceService.deleteResidenceFromCondo(this.condo._id, residence._id).pipe(
              timeout(10000),
              tap(() => {
                results.push({ [residence._id]: true });
                swal.getTitle().textContent = `Processando: ${results.length}/${requests.length}`;
              }),
              catchError(() => {
                results.push({ [residence._id]: false });
                return of(null);
              })
            );
          });
          await from(requests).pipe(mergeAll(5), takeUntil(unsubscribe)).toPromise();
          return Promise.resolve(results);
        }
      }
    })
      .then(() => {
        this.selectedResidence = null;
        this.downloadData(0, true);
        swal({
          type: 'success',
          title: `${capitalize(this.condo?.customLabels?.resident?.plural) || 'Unidades'} excluídas com sucesso!`
        });
      })
      .catch(() => {
        this.selectedResidence = null;
        this.downloadData(0, true);
        unsubscribe.next(null);
        unsubscribe.complete();
      });
  }

  async getResidencesRecursively(page = 0, size = 500) {
    const residences = await this.residenceService
      .getResidences(this.condo._id, {
        $select: '_id',
        $limit: size,
        $page: page
      })
      .pipe(map(res => res.residences))
      .toPromise();
    if (residences.length < size) {
      return residences;
    } else {
      return residences.concat(...(await this.getResidencesRecursively(++page, size)));
    }
  }

  onShownTooltip(user: User) {
    if (this.user.isOwnerOnCondo(this.condo._id) || this.user.isAdminOnCondo(this.condo._id)) {
      this.fullUserData = undefined;

      this.condoService
        .getCondoUserById(this.condo.id, user._id)
        .pipe(timeout(10000))
        .subscribe(
          userData => {
            this.fullUserData = userData;
          },
          () => {
            this.fullUserData = user;
          }
        );
    }
  }

  cleanResidence(residence: Residence) {
    swal({
      type: 'warning',
      title: `Esvaziar ${this.condo?.customLabels?.residence?.singular || 'Unidade'}`,
      text: `Esta ação removerá o ${this.condo?.customLabels?.voter?.singular || 'proprietário'} e todos ${
        this.condo?.customLabels?.resident?.plural || 'condôminos'
      }, veículos, ${
        this.condo.customLabels.dependent.plural || (this.condo.isBusinessCondo() ? 'funcionários' : 'dependentes')
      }, pets e documentos da(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'}. Deseja realmente esvaziar ${
        this.condo?.customLabels?.residence?.singular || 'unidade'
      } ${residence.identification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const users = [].concat(residence.users, residence.requesters);

        const observables = users.map(user =>
          this.residenceService.removeUserFromResidence(this.condo._id, residence._id, user._id).pipe(timeout(10000))
        );
        if (residence.voter) {
          observables.push(this.residenceService.removeVoterFromResidence(this.condo.id, residence.id).pipe(timeout(10000)));
        }

        this.vehicles.forEach((vehicle: CondoVehicle) => {
          observables.push(this.residenceService.removeVehicle(this.condo._id, residence._id, vehicle._id));
        });

        this.dependents.forEach((dep: Dependent) => {
          observables.push(this.residenceService.removeDependent(this.condo._id, residence._id, dep._id));
        });

        this.pets.forEach((pet: Pet) => {
          observables.push(this.residenceService.removePet(this.condo._id, residence._id, pet._id));
        });

        this.selectedResidence.documents.forEach((file: File) => {
          observables.push(this.residenceService.removeDocument(this.condo._id, residence._id, file._id));
        });

        if (observables.length) {
          return forkJoin(observables)
            .toPromise()
            .catch(() => {
              this.downloadData(0, true);
              return Promise.reject(`Não foi possível realizar a operação, tente novamente...`);
            });
        } else {
          return Promise.reject(`Não há nada que possa ser esvaziado`);
        }
      }
    }).then(
      () => {
        this.downloadData(0, true);
        this.getSingleResidence(residence._id);
        swal({
          type: 'success',
          title: `Operação realizada com sucesso!`
        });
      },
      () => {}
    );
  }

  onResidenceCreation(residence: Residence) {
    this.downloadData(0, true);
    this.getSingleResidence(residence._id);
  }

  onDependentToUser(dependentInfo: { condoId: string; userId: string; dependentId: string }) {
    const { condoId, userId } = dependentInfo;
    this.condoService
      .getCondoUserById(condoId, userId)
      .pipe(timeout(10000), take(1))
      .subscribe(user => {
        this.selectedResidence.users = [].concat(this.selectedResidence.users, user);
      });
  }
  onDependentLoaded(dependents: Dependent[]) {
    this.dependents = dependents;
  }

  onUserSetAsVoter(user: User) {
    this.selectedResidence.voter = user;
  }

  onResidentsUpdated(residents: User[]) {
    this.selectedResidence.users = residents;
  }

  onEmergencyContactChange(emergencyContacts: EmergencyContact[]) {
    this.selectedResidence.emergencyContacts = [...emergencyContacts];
  }

  async printResidenceDetails() {
    this.downloadResidenceDetailsInChunks().subscribe({
      next: ({ users, dependents, vehicles }) => {
        const tableData: any = {
          residenceIdentification: this.selectedResidence.identification,
          voter: this.selectedResidence.voter,
          residents: users,
          dependents: dependents,
          pets: this.pets,
          vehicles: vehicles
        };

        this.reportService.printResidenceDetails(tableData);
      },
      error: () => {
        swal({
          type: 'error',
          title: 'Oops...',
          text: 'Houve um erro ao gerar o relatório, tente novamente!'
        });
      }
    });
  }

  private downloadResidenceDetailsInChunks() {
    const userQuery: EcondosQuery = {
      residencesUser: { $in: [this.selectedResidence._id] },
      $select: 'firstName lastName phones email emails picture',
      $populate: [{ path: 'picture', select: 'url thumbnail' }],
      $sort: 'firstName'
    };
    const users$ = this.residenceService.getResidenceUsersReport(this.condo._id, this.selectedResidence._id, userQuery).pipe(
      mergeMap(([finalResult, progress]) => merge(finalResult)),
      map((response: any) => response.data as User[])
    );

    const dependentQuery: EcondosQuery = {
      $select: 'name picture phone cpf kinship',
      $populate: [{ path: 'picture', select: 'url thumbnail' }],
      $sort: 'firstName'
    };
    const dependents$ = this.residenceService.getResidenceDependentsReport(this.condo._id, this.selectedResidence._id, dependentQuery).pipe(
      mergeMap(([finalResult, progress]) => merge(finalResult)),
      map((response: any) => response.data as Dependent[])
    );

    const vehicleQuery: EcondosQuery = {
      $select: 'brand model color plate type'
    };
    const vehicles$ = this.residenceService.getResidenceVehiclesReport(this.condo._id, this.selectedResidence._id, vehicleQuery).pipe(
      mergeMap(([finalResult, progress]) => merge(finalResult)),
      map((response: any) => response.data as CondoVehicle[])
    );

    return forkJoin({
      users: users$,
      dependents: dependents$,
      vehicles: vehicles$
    }).pipe(timeout(15_0000));
  }

  openResidenceConfig(residence) {
    const initialState = {
      residence,
      callbacks: {
        success: params => {
          if (params) {
            this.selectedResidence.params = params;
          }
        }
      }
    };
    this.modalService.show(ModalResidenceConfigComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
  }

  revealData(field: string, user) {
    user.isDataMasked[field] = false;

    if (!user[field].toString().includes('*')) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    const callback = ({ data }) => (user[field] = data);

    this.condoService.getCondoResidentUnmaskedField(this.condo._id, query, user._id, field).pipe(timeout(10000)).subscribe(callback);
  }

  showTerminatedContracts() {
    const initialState = {
      callbacks: {
        success: residenceId => {
          if (residenceId) {
            this.downloadData(0, true);
            this.getSingleResidence(residenceId);
          }
        }
      }
    };
    this.modalService.show(ModalTerminatedContractsReportComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  handleConstructionCreated(construction: Construction) {
    this.constructions = [].concat(construction, this.constructions);
  }

  handleConstructionUpdated(construction: Construction) {
    const index = this.constructions.findIndex(d => d._id === construction._id);
    this.constructions[index] = construction;
    this.constructions = [].concat(this.constructions);
  }

  handleConstructionDeleted(construction: Construction) {
    const index = this.constructions.findIndex(d => d._id === construction._id);
    this.constructions.splice(index, 1);
    this.constructions = [].concat(this.constructions);
  }
  openResidencesConfig(condo: Condo) {
    const initialState = {
      condo,
      callbacks: {
        success: (updatedCondo: Condo) => {
          this.condo = updatedCondo;
          this.utilService.saveLocalCondo(updatedCondo);
        }
      }
    };
    this.modalService.show(ModalResidencesConfigComponent, { initialState });
  }

  showReceipts() {
    const initialState = {
      condo: this.condo,
      residence: this.selectedResidence
    };
    this.modalService.show(ReceiptListComponent, { initialState, class: 'modal-xl' });
  }

  onPetsLoaded(pets: Pet[]) {
    this.pets = pets;
  }

  protected readonly PERMISSIONS = PERMISSIONS;
}
