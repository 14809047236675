import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ThemeModule } from 'app/theme';
import { ModalCreateResidenceComponent } from './create-residence-modal.component';
import { AutosizeModule } from 'ngx-autosize';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '../../../../app/pipe/pipes.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalModule, PipesModule, ThemeModule, AutosizeModule, TooltipModule],
  declarations: [ModalCreateResidenceComponent]
})
export class CreateResidenceModalModule {}
