/**
 * Created by Rafael on 27/01/2017.
 * Baseado no projeto https://github.com/Martaver/ng2-photobooth
 *
 */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileService } from '../../../api/service/file.service';
import swal from 'sweetalert2';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime } from 'rxjs/operators';
import { DeviceService } from '../../../services/devices.service';
import { DeviceSelectComponent } from '../../../components/device-select/device-select.component';

export enum State {
  Recording,
  Confirming
}

@Component({
  styles: [
    `
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      canvas {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `
  ],
  selector: 'modal-webcam',
  templateUrl: 'webcam.modal.html'
})
export class ModalWebCam implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('webcamModal', { static: true }) public webcamModal: ModalDirective;

  @ViewChild(DeviceSelectComponent, { static: true }) deviceSelectComponent: DeviceSelectComponent;

  @Output() onPhotoTaken = new EventEmitter();
  @Input() useSmallModal = false;

  public State = State;
  state: State = State.Recording;
  video: any;
  canvas: any;
  navigator: any;
  window: any;
  imgSrc: string;
  private context: CanvasRenderingContext2D;

  isUploading = false;

  subscriptions: Subscription = new Subscription();

  devices: MediaDeviceInfo[] = [];

  selectedDevice: string;

  error;

  constructor(public element: ElementRef, private zone: NgZone, private fileService: FileService, private deviceService: DeviceService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.deviceService.$devicesUpdated.pipe(debounceTime(350)).subscribe(async deviceListPromise => {
        this.devices = await deviceListPromise;
        this.handleDeviceAvailabilityChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.window = window;
    this.navigator = window.navigator;
    this.video = this.element.nativeElement.querySelector('#recorder').querySelector('video');
    this.navigator.getUserMedia =
      this.navigator.getUserMedia || this.navigator.webkitGetUserMedia || this.navigator.mozGetUserMedia || this.navigator.msGetUserMedia;
    if (this.window.stream) {
      this.releaseVideo();
      this.releaseStream();
    }
  }

  private initCam(deviceId = this.deviceSelectComponent.selectedId) {
    if (this.window.stream) {
      this.releaseVideo();
      this.releaseStream();
    }

    const constraints: any = {
      audio: false,
      video: true
    };

    if (deviceId) {
      constraints.video = { deviceId: { exact: deviceId } };
      this.selectedDevice = deviceId;
    }

    this.error = '';

    this.navigator.getUserMedia(
      constraints,
      stream => {
        this.window.stream = stream; // make stream available to console
        if (this.navigator.mozGetUserMedia) {
          this.video.mozSrcObject = stream;
        } else {
          try {
            this.video.srcObject = stream;
          } catch (e) {
            const vendorURL = window.URL || this.window.webkitURL;
            this.video.src = vendorURL.createObjectURL(stream);
          }
        }
        this.video.play();
      },
      err => {
        console.log('navigator.getUserMedia error: ', err);

        /* handle the error */
        if (err.name == 'NotFoundError' || err.name == 'DevicesNotFoundError') {
          this.error =
            'Nenhum dispositivo de captura encontrado, verifique se há algum dispositivo instalado, se ele está ativado e tente novamente.';
        } else if (err.name == 'NotReadableError' || err.name == 'TrackStartError') {
          // webcam or mic are already in use
          this.error = 'Sua câmera está em uso por outra aplicação, encerre essa aplicação e tente novamente.';
        } else if (err.name == 'OverconstrainedError' || err.name == 'ConstraintNotSatisfiedError') {
          // constraints can not be satisfied by avb. devices
          this.error =
            'Parece que sua cÂmera não cumpre os requisitos mínimos de hardware para essa aplicação. ' +
            'Tente reiniciar o navegador e tentar novamente. Se o problema persisitr, contate o suporte';
        } else if (err.name == 'NotAllowedError' || err.name == 'PermissionDeniedError') {
          // permission denied in browser
          this.error =
            'Permissão de acesso a câmera negada. Seu navegador está bloqueando o acesso a câmera, libere a permissão para acesso a câmera e tente novamente.';
        } else if (err.name == 'TypeError' || err.name == 'TypeError') {
          // empty constraints object
        } else {
          // other errors
          this.error =
            'Não é possível utilizar sua câmera, reinicie o navegador e tente novamente. Se o problema persistir, anote o error a seguir e contate o suporte: ' +
            err.toString();
        }
        swal({
          type: 'error',
          text: this.error
        });
      }
    );
    this.start();
  }

  public start() {
    this.state = State.Recording;
  }

  public snapshot() {
    if (this.window.stream) {
      this.canvas = this.element.nativeElement.querySelector('#recorder').querySelector('canvas');
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;

      this.context = this.canvas.getContext('2d');
      this.context.drawImage(this.video, 0, 0);

      // For some reason, image/jpeg is much more efficient than png.
      // Theory 1 : It could be that toDataURL is encoding a jpeg & compressing.
      // Theory 2 : It could have been that prevously when saving as png, when we upload a jpeg it gets decompressed and saved as a png and got huge.
      this.imgSrc = this.canvas.toDataURL('image/jpeg');

      this.state = this.State.Confirming;
      this.releaseVideo();
      this.releaseStream();
    }
  }

  confirmPhoto() {
    if (this.state == State.Confirming) {
      this.canvas.toBlob(blob => {
        this.zone.run(() => {
          const formData = new FormData();
          formData.append('photo', blob);
          this.isUploading = true;

          this.fileService.uploadFilesFromFormData(formData).subscribe(
            response => {
              console.log(response);
              this.onPhotoTaken.emit(response[0]);
              this.closeModal();
            },
            err => {
              console.log(err);
              this.isUploading = false;
            }
          );
        });
      });
    }
  }

  takeAnotherPhoto() {
    this.state = this.State.Recording;
    this.initCam();
  }

  private releaseStream() {
    if (this.window.stream) {
      if (this.window.stream.stop) {
        // Old, deprecated way of stopping the stream using MediaStream object.
        this.window.stream.stop();
      } else {
        // New track-based approach to managing audio & video streams.
        this.window.stream.getAudioTracks().forEach(function (track) {
          track.stop();
        });

        this.window.stream.getVideoTracks().forEach(function (track) {
          track.stop();
        });
      }

      this.window.stream = null;
    }
  }

  private releaseVideo() {
    this.video.pause();
    // this.video.src = null;
    // When video.src is null, it continues triyng to play the video and generates null requests on network.
    // In case it causes problem, just change vide.src to null again
    this.video.src = '';
    if (this.video.hasOwnProperty('mozSrcObject')) this.video['mozSrcObject'] = null;
  }

  closeModal(): void {
    this.webcamModal.hide();
    this.releaseVideo();
    this.releaseStream();
  }

  showModal() {
    this.isUploading = false;
    this.initCam();
    this.webcamModal.show();
  }

  onHide(event) {
    this.releaseVideo();
    this.releaseStream();
  }

  onCameraChange(device: MediaDeviceInfo) {
    this.initCam(device.deviceId);
  }

  private handleDeviceAvailabilityChanges() {
    if (this.devices && this.devices.length && this.selectedDevice) {
      let videoDevice = this.devices.find(d => d.deviceId === this.selectedDevice);
      if (!videoDevice) {
        videoDevice = this.devices.find(d => d.kind === 'videoinput');
        if (videoDevice) {
          this.video.selectedId = videoDevice.deviceId;
          this.initCam(videoDevice.deviceId);
        }
      }
    }
  }
}
