import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalTerminatedContractsReportComponent } from './modal-terminated-contracts-report.component';
import { ThemeModule } from '../../../../theme';
import { PipesModule } from '../../../../pipe/pipes.module';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, PipesModule, ModalModule, ThemeModule],
  declarations: [ModalTerminatedContractsReportComponent]
})
export class ModalTerminatedContractsReportModule {}
