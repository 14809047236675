import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { noop, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, exhaustMap, filter, tap, timeout } from 'rxjs/operators';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { Actuator } from '@api/model/hardware/actuator';
import { Condo } from '@api/model/condo';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { User } from '@api/model/user';
import { ActuatorsRoute } from '@api/model/hardware/actuators-route';

@Component({
  templateUrl: 'modal-actuate-actuators-routes.component.html',
  styleUrls: ['modal-actuate-actuators-routes.component.scss']
})
export class ModalActuateActuatorsRouteComponent implements OnInit, OnDestroy, AfterViewInit {
  private actuarorDivScroll: HTMLElement | null = null;

  condo: Condo;
  user: User;
  actuator: ActuatorsRoute;
  actuationStatus;

  currentIndex: number = -1;
  currentStatusActuator: any = [];

  defaultImage = 'assets/img/no-image.jpg';

  isElectron = false;

  actuate$: Subject<{ actuator: Actuator; params: any }> = new Subject();

  private unsubscribe: Subject<void> = new Subject();

  modalIsOpen = true;
  convertRemToPx = parseFloat(getComputedStyle(document.documentElement).fontSize);

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.actuationStatus !== 'PROCESSING' && event && (event.key === 'Enter' || event.keyCode === 13)) {
      this.actuate(this.actuator, this.condo);
    }
  }

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private http: HttpClient,
    private toastrService: ToastrService,
    private actuatorService: ActuatorService
  ) {
    this.isElectron = this.userAgentIsElectron();
  }

  ngOnInit() {
    this.actuate$
      .asObservable()
      .pipe(
        filter(data => !!data.actuator),
        tap(data => (this.actuationStatus = 'PROCESSING')),
        exhaustMap(data => {
          let observable;
          if (data.params?.command) {
            const command = data.params.command;
            if (command === 'LOCK') {
              observable = this.actuatorService.lock(data.actuator);
            } else {
              observable = this.actuatorService.unlock(data.actuator);
            }
          } else {
            observable = this.actuatorService.trigger(data.actuator, data.params);
          }
          return observable.pipe(
            timeout(10000),
            catchError(err => {
              console.log(err);
              swal({
                type: 'error',
                text: this.getTextFromHardware(data.actuator)
              });
              this.actuationStatus = 'ERROR';
              return of(new Error());
            }),
            tap(value => {
              if (!(value instanceof Error)) {
                this.toastrService.success('Comando enviado');
                this.actuationStatus = 'SUCCESS';
              }
            })
          );
        })
      )
      .subscribe(noop);
  }

  ngOnDestroy() {
    this.currentStatusActuator = [];
    const interval = setInterval(() => {
      if (
        (!this.modalIsOpen && this.currentIndex === this.actuator.selections.length - 1 && this.actuationStatus === 'SUCCESS') ||
        (!this.modalIsOpen && this.actuationStatus === 'ERROR')
      ) {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
        clearInterval(interval);
      }
    }, 3000);
  }

  ngAfterViewInit() {
    this.actuarorDivScroll = document.getElementById('actuator-div-scroll');
  }

  getTextFromHardware(actuator: Actuator) {
    let text = 'Não foi possível enviar o comando para o';

    switch (actuator.hardware) {
      case 'LINEAR':
        text = text + ' módulo guarita';
        break;
      case 'CONTROL_ID':
        text = text + ' Control ID';
        break;
      case 'HIKVISION':
        text = text + ' Hikvision';
        break;
      case HARDWARES.BRAVAS:
        text = text + ' equipamento';
        break;
      default:
        text = 'Não foi possível processar a requisição';
    }
    return text;
  }

  checkIfProxyIsRunning() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.http
      .get('http://localhost:5050/api.econdos.com.br', { headers, responseType: 'text' })
      .pipe(timeout(8000))
      .subscribe(
        res => {
          swal({
            type: 'success',
            text: 'O eCondos câmera proxy está funcionando corretamente, verifique se as câmeras estão configuradas corretamente e tente novamente'
          });
        },
        err => {
          console.log(err);
          swal({
            type: 'error',
            text: 'Parece que o eCondos câmera proxy não está em execução, verifique as instruções para executá-lo e tente novamente'
          });
        }
      );
  }

  actuate(actuator, condo, params = {}) {
    actuator.condo = condo;
    try {
      this.actuatorService.registerTriggerEvent(actuator).subscribe({ next: noop, error: noop });
    } catch (e) {
      console.log(e);
    }
    this.actuate$.next({ actuator, params });
  }

  async actuateRoutes(actuator, condo, params = {}) {
    actuator.condo = condo;
    let i = 0;
    for (const item of actuator.selections) {
      this.currentIndex = i;

      // Timeout do observable é de 10 segundos, damos um tempo de 10 segundos para o comando ser processado e depois continuamos ou paramos
      if (this.actuationStatus === 'PROCESSING') await this.wait(10 * 1000);

      if (i > 0 && this.actuationStatus == 'SUCCESS') this.changeStatus(actuator.selections[i - 1], 'SUCCESS');
      if (i > 0 && this.actuationStatus == 'ERROR') this.changeStatus(actuator.selections[i - 1], 'ERROR');
      if (this.actuationStatus === 'ERROR') break;

      this.changeStatus(item, 'PROCESSING');
      if (item.type === 'actuator') await this.actuate(item.actuator, this.condo);
      else if (item.type === 'timer') {
        this.changeStatus(item, 'PROCESSING');
        await this.wait(item.timer * 1000).then(() => this.changeStatus(item, 'SUCCESS'));
      }

      // Validação específica para o caso de um item ser um atuador e o próximo ser um timer
      if (this.actuationStatus === 'ERROR') break;

      const scrollAmount = 17 * this.convertRemToPx;
      this.actuarorDivScroll.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      i++;
    }
  }

  async askToActuateRoutes(actuator, condo, params = {}) {
    if (!actuator.addTextBeforeAction) return this.actuateRoutes(actuator, condo, params);
    const swalBaseConfig: SweetAlertOptions = {
      text: `${actuator.actionText}`,
      type: 'question',
      title: 'Atenção',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Acionar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    };
    swal(swalBaseConfig).then(
      () => {
        this.actuateRoutes(actuator, condo, params);
      },
      err => console.log(err)
    );
  }

  changeStatus(step, status) {
    const _step = { ...step };
    _step.status = status;
    if (status === 'PROCESSING') _step.initTime = new Date();
    if (status === 'SUCCESS') _step.endTime = new Date();
    if (status === 'ERROR') _step.errorTime = new Date();

    if (_step.type === 'timer') {
      this.timer(_step);
    }
    this.currentStatusActuator[_step._id] = _step;
  }

  timer(step) {
    step.timeRemaining = step.timer;
    let interval = setInterval(() => {
      step.timeRemaining--;
      if (step.timeRemaining <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  wait(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  userAgentIsElectron() {
    const userAgent = window && window.navigator && window.navigator.userAgent;
    return userAgent.toLowerCase().includes('electron');
  }

  hide() {
    this.bsModalRef.hide();
    this.modalIsOpen = false;
  }
}
