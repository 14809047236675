import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

/** Inject With Credentials into the request */
@Injectable()
export class CookieInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;
    if (url.includes('econdos.com.br') || url.includes('localhost:8080') || url.includes('127.0.0.1:8080')) {
      req = req.clone({
        withCredentials: true
      });
    }
    return next.handle(req);
  }
}
