import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsbDeviceReaderModule } from '../usb-device-reader/usb-device-reader.module';
import { ComponentsModule } from '../../components/components.module';
import { ThemeModule } from '../../theme';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalCreateUtechDeviceComponent } from './modal-create-utech-device.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PipesModule } from '../../pipe/pipes.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    UsbDeviceReaderModule,
    TypeaheadModule,
    ComponentsModule,
    TooltipModule,
    ThemeModule,
    PipesModule,
    AngularSvgIconModule,
    TimepickerModule
  ],
  declarations: [ModalCreateUtechDeviceComponent]
})
export class ModalCreateUtechDeviceModule {}
