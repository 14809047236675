import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalKeyHistory } from './modal-key-history';
import { ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from '../../../../theme';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PipesModule } from 'app/pipe/pipes.module';

@NgModule({
  imports: [CommonModule, BsDatepickerModule, ReactiveFormsModule, ModalModule, PipesModule, ThemeModule],
  declarations: [ModalKeyHistory]
})
export class ModalKeyHistoryModule {}
