import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { ExternalLink, ExternalLinkQueryParam } from '@api/model/external-link';
import { File as EcondosFile } from '@api/model/file';
import { Status } from '@api/model/status';
import { FileService } from '@api/service/file.service';
import { ExternalLinksService, SaveExternalLinkDTO } from '@api/serviceV3/external-links.service';
import { readFileAsDataURL, urlValidator } from '@api/util/util';
import { UploadButton } from 'app/components/upload-button';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable, timeout } from 'rxjs';

type ExternalLinkQueryForm = Record<keyof ExternalLinkQueryParam, FormControl<string>>;
type ExternalLinkIllustration = 'icon' | 'picture';

type ExternalLinkIllustrationOption = { label: string; value: ExternalLinkIllustration };

type ExternalLinkForm = {
  title: FormControl<string>;
  url: FormControl<string>;
  externalLinkIllustration: FormControl<ExternalLinkIllustration>;
  icon: FormControl<string>;
  picture: FormControl<EcondosFile>;
  queryParams: FormArray<FormGroup<ExternalLinkQueryForm>>;
  includeUserId: FormControl<boolean>;
};

@Component({
  selector: 'app-modal-create-external-link',
  templateUrl: 'modal-create-external-link.component.html'
})
export class ModalCreateExternalLinkComponent implements OnInit {
  @ViewChild(UploadButton, { static: true }) uploadBtn: UploadButton;

  public condo: Condo = null;
  public externalLink: ExternalLink = null;
  public onSaveExternalLink: () => void = null;

  protected status = new Status();
  protected uploadPictureStatus = new Status();

  protected icons = ['external-link', 'male', 'user', 'users', 'car', 'motorcycle', 'truck', 'volume-up', 'power-off', 'repeat'];

  protected externalLinkIllustrationOptions: ExternalLinkIllustrationOption[] = [
    { label: 'Ícone', value: 'icon' },
    { label: 'Imagem', value: 'picture' }
  ];

  protected externalLinkForm = new FormGroup<ExternalLinkForm>({
    title: new FormControl('', Validators.required),
    url: new FormControl('', urlValidator),
    externalLinkIllustration: new FormControl('icon'),
    icon: new FormControl(null),
    picture: new FormControl(null),
    queryParams: new FormArray([]),
    includeUserId: new FormControl(false)
  });

  protected externalLinkIllustration = this.externalLinkForm.controls.externalLinkIllustration;
  protected icon = this.externalLinkForm.controls.icon;
  protected picture = this.externalLinkForm.controls.picture;
  protected queryParams = this.externalLinkForm.controls.queryParams;

  protected externalIconUploadedPicture: string = null;
  protected uploadButtonCallback = async ([image]: [File]) => await this.onPickImageFromFileSystem(image);

  constructor(
    public bsModalRef: BsModalRef,
    private externalLinksService: ExternalLinksService,
    private toastrService: ToastrService,
    private fileService: FileService
  ) {
    this.externalLinkIllustration.valueChanges.subscribe(value => {
      this.icon.setValue(null);
      this.picture.setValue(null);
    });
  }

  public async ngOnInit() {
    if (this.externalLink) {
      this.externalLinkForm.controls.title.setValue(this.externalLink.title);
      this.externalLinkForm.controls.url.setValue(this.externalLink.url);
      this.externalLinkForm.controls.includeUserId.setValue(this.externalLink.includeUserId);
      this.externalLink.queryParams.forEach(param => this.addQueryParam(param));

      if (this.externalLink.icon) {
        this.externalLinkForm.controls.externalLinkIllustration.setValue('icon');
        this.externalLinkForm.controls.icon.setValue(this.externalLink.icon);
      } else if (this.externalLink.picture) {
        this.externalLinkForm.controls.externalLinkIllustration.setValue('picture');
        this.externalLinkForm.controls.picture.setValue(this.externalLink.picture);
        this.externalIconUploadedPicture = this.externalLink.picture.url;
      }
    }

    if (!this.queryParams.value.length) {
      this.addQueryParam();
    }
  }

  protected addQueryParam(value?: ExternalLinkQueryParam) {
    const newExtraFee = new FormGroup<ExternalLinkQueryForm>({
      name: new FormControl(value?.name || ''),
      value: new FormControl(value?.value || '')
    });

    this.queryParams.push(newExtraFee);
  }

  protected handleRemoveQueryParam(index: number) {
    this.queryParams.removeAt(index);
  }

  protected handleSubmit() {
    if (this.externalLinkForm.invalid) {
      for (const key of Object.keys(this.externalLinkForm.controls)) {
        this.externalLinkForm.get(key).markAsTouched();
      }

      this.toastrService.warning('Preencha todos os campos obrigatórios');

      return;
    }

    this.status.setAsProcessing();

    const queryParams: ExternalLinkQueryParam[] = this.externalLinkForm.value.queryParams
      .filter(({ name, value }) => !!name && !!value)
      .map(({ name, value }) => ({ name, value }));

    const data: SaveExternalLinkDTO = {
      title: this.externalLinkForm.value.title,
      url: this.externalLinkForm.value.url,
      icon: this.externalLinkForm.value.icon,
      picture: this.externalLinkForm.value.picture,
      includeUserId: this.externalLinkForm.value.includeUserId,
      queryParams
    };

    let requestObservable: Observable<any>;

    if (this.externalLink) {
      requestObservable = this.externalLinksService.updateExternalLink(this.condo._id, this.externalLink._id, data);
    } else {
      requestObservable = this.externalLinksService.createExternalLink(this.condo._id, data);
    }

    requestObservable.pipe(timeout(10_000)).subscribe({
      next: () => {
        if (this.onSaveExternalLink) {
          this.onSaveExternalLink();
        }

        this.bsModalRef.hide();
        this.toastrService.success('Link externo cadastrado com sucesso.');
      },
      error: err => {
        const errorMessage = err?.originalError?.message || 'Não foi possível salvar o link externo. Tente novamente, por favor.';
        this.toastrService.error(errorMessage);
      }
    });
  }

  protected handleSelectPicture() {
    this.uploadBtn.triggerClick();
  }

  protected handleRemoveSelectedPicture() {
    this.picture.setValue(null);
    this.externalIconUploadedPicture = null;
  }

  protected handleChangeSelectedPicture() {
    this.handleRemoveSelectedPicture();
    this.handleSelectPicture();
  }

  protected async onPickImageFromFileSystem(image: File) {
    this.uploadPictureStatus.setAsProcessing();

    const formData = new FormData();
    formData.append(image.name || 'file', image);

    this.fileService.uploadFilesFromFormData(formData).subscribe({
      next: async ([uploadedPicture]) => {
        this.picture.setValue(uploadedPicture);

        const imageUrl = await readFileAsDataURL(image);
        this.externalIconUploadedPicture = imageUrl;

        this.uploadPictureStatus.setAsSuccess();
      },
      error: err => {
        this.handleRemoveSelectedPicture();
        this.uploadPictureStatus.setAsError();
      }
    });
  }
}
