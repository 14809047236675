import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '@api/model/condo';
import { VotingOccurrence } from '@api/model/occurrence/occurrence.voting';
import { OccurrenceService } from '@api/service/occurrence.service';
import { Status } from '@api/model/status';
import { EcondosQuery } from '@api/model/query';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-modal-voting-details',
  templateUrl: 'modal-voting-details.component.html',
  styleUrls: ['./modal-voting-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalVotingDetailsComponent implements OnInit {
  condo: Condo;
  voting: VotingOccurrence;
  status: Status = new Status();

  votes: { text: string; numberOfVotes: number; votes: string }[] = [];

  datasets: any[];
  labels: any[];
  options: ChartOptions<'pie'> = {
    responsive: false
  };

  constructor(public bsModalRef: BsModalRef, private occurrenceService: OccurrenceService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.status.setAsDownloading();
    const query: EcondosQuery = {
      $populate: [
        { path: 'votes.residences', select: 'identification' },
        { path: 'votes.users', select: 'firstName lastName' }
      ]
    };
    this.occurrenceService.getById(this.condo._id, this.voting._id, query).subscribe({
      next: voting => {
        this.votes = (<VotingOccurrence>voting).votes.map(vote =>
          (<VotingOccurrence>voting).voteType === 'USER'
            ? {
                text: vote.text,
                votes: vote.users.map(u => u.firstName + ' ' + u.lastName).join(', '),
                numberOfVotes: vote.users.length
              }
            : {
                text: vote.text,
                votes: vote.residences.map(res => res.identification).join(', '),
                numberOfVotes: vote.residences.length
              }
        );
        this.labels = this.votes.map((v, index) => `${index + 1}. ${v.text}`);
        this.datasets = [
          {
            data: this.votes.map(v => [v.numberOfVotes])
          }
        ];
        this.status.setAsSuccess();
        this.cdr.detectChanges();
      },
      error: err => {
        console.log(err);
        this.status.setAsError();
      }
    });
  }
}
