import { NgModule } from '@angular/core';
import { CurrencyPipe } from './currency.pipe';
import { DatePipe } from './date.pipe';
import { PhonePipe } from './phone.pipe';
import { CpfPipe } from './cpf.pipe';
import { CondoCustomLabelPipe } from './condo-custom-label.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { SanitizeHtmlPipe } from './sanitize.pipe';
import { BarcodePipe } from './barcode.pipe';

@NgModule({
  imports: [],
  declarations: [PhonePipe, CurrencyPipe, CondoCustomLabelPipe, DatePipe, SanitizeHtmlPipe, CpfPipe, LinkifyPipe, BarcodePipe],
  exports: [PhonePipe, CurrencyPipe, CondoCustomLabelPipe, DatePipe, CpfPipe, SanitizeHtmlPipe, LinkifyPipe, BarcodePipe],
  providers: [CondoCustomLabelPipe]
})
export class PipesModule {}
