import { Component, Input, OnInit } from '@angular/core';
import { CalendarEvent } from '../../api/model/calendar-reservation-links';
import * as moment from 'moment';
import 'moment-timezone';
import { SessionService } from '@api/service/session.service';

const TimeFormats: { [key: string]: string } = {
  dateTimeUTC: 'YYYYMMDDTHHmmssZ',
  dateTimeLocal: 'YYYY-MM-DDTHH:mm:ss',
  allDay: 'YYYYMMDD'
};

@Component({
  selector: 'app-calendar-reservation-links',
  templateUrl: './app-calendar-reservation-links.component.html',
  styleUrls: ['./app-calendar-reservation-links.component.scss']
})
export class CalendarReservationLinksComponent implements OnInit {
  @Input() event: CalendarEvent;
  @Input() displayMode: 'link' | 'button' | 'button-icon' | 'button-text' | 'button-icon-rounded' | 'icon' | 'link' = 'button';
  @Input() allowedCalendars: string[] = ['google', 'outlook', 'office365', 'all'];
  @Input() timeZone: string = 'America/Sao_Paulo';
  calendarLinks: { [key: string]: string };

  constructor(private sessionService: SessionService) {}

  ngOnInit() {
    if (!this.event) {
      throw new Error('Os dados do evento são obrigatórios');
    }

    if (this.allowedCalendars.includes('all')) {
      this.allowedCalendars = ['google', 'outlook', 'office365'];
    }

    if (!this.timeZone) {
      const user = this.sessionService.userValue;
      this.timeZone = user.defaultCondo?.timeZone || 'America/Sao_Paulo';
    }

    const event = { ...this.event, timeZone: this.timeZone };

    this.calendarLinks = {
      google: this.google(event),
      outlook: this.outlook(event),
      office365: this.office365(event)
    };
  }

  getCalendarName(calendar: string): string {
    const names = {
      google: 'Google Calendar',
      outlook: 'Outlook',
      office365: 'Office 365'
    };
    return names[calendar];
  }

  getCalendarIcon(calendar: string): string {
    const icons = {
      google: 'google-agenda.svg',
      outlook: 'outlook-logo.svg',
      office365: 'office-365.svg'
    };
    return icons[calendar];
  }

  /**
   * Métodos para gerar links de reserva para calendários específicos.
   */
  private formatTimes({ startTime, endTime }: { startTime: moment.Moment; endTime: moment.Moment }, dateTimeFormat: string) {
    const format = TimeFormats[dateTimeFormat];
    return { start: startTime.utc().format(format), end: endTime.utc().format(format) };
  }

  private formaterForCalendar(event: any) {
    const { start, end, duration, ...rest } = event;
    const startTime = moment.tz(start, this.timeZone);
    const endTime = end
      ? moment.tz(end, this.timeZone)
      : event.allDay
        ? startTime.clone().add(1, 'day')
        : duration && duration.length === 2
          ? startTime.clone().add(Number(duration[0]), duration[1])
          : moment();

    return { ...rest, startTime, endTime };
  }

  public google(calendarEvent: CalendarEvent) {
    const event = this.formaterForCalendar(calendarEvent);
    const { start, end } = this.formatTimes(event, event.allDay ? 'allDay' : 'dateTimeUTC');
    const details = {
      action: 'TEMPLATE',
      text: event.title,
      details: event.description,
      location: event.location,
      trp: event.busy,
      dates: `${start}/${end}`,
      recur: event.rRule ? `RRULE:${event.rRule}` : '',
      add: event.guests ? event.guests.join() : ''
    };
    return `https://calendar.google.com/calendar/render?${new URLSearchParams(details).toString()}`;
  }

  public outlook(calendarEvent: CalendarEvent) {
    const event = this.formaterForCalendar(calendarEvent);
    const { start, end } = this.formatTimes(event, 'dateTimeLocal');
    const details = {
      path: '/calendar/action/compose',
      rru: 'addevent',
      startdt: `${start}`, // Add Z to indicate UTC timezone
      enddt: `${end}`, // Add Z to indicate UTC timezone
      subject: event.title,
      body: event.description,
      location: event.location,
      allday: event.allDay || false,
      startTimeZone: event.timeZone || this.timeZone,
      endTimeZone: event.timeZone || this.timeZone
    };
    return `https://outlook.live.com/calendar/action/compose?${new URLSearchParams(details).toString()}`;
  }

  public office365(calendarEvent: CalendarEvent) {
    return this.outlook(calendarEvent);
  }

  public office365Mobile(calendarEvent: CalendarEvent) {
    return this.outlook(calendarEvent);
  }
}
