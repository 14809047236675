import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as qs from 'qs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

import { ConstantService } from '../../services/constant.service';
import { HttpService } from '../../services/http.service';

import { EcondosQuery } from '@api/model/query';
import { BuildDeliveryTrackingHistory, DeliveryTrackingHistory } from '@api/model/delivery-tracking-history';

export interface CreateDeliveryTrackingHistoryDto {
  condo: string;
  residence: string;
  user: string;
  date: Date;
  sequence: string;
  obs: string;
  createdBy: string;
}

@Injectable()
export class DeliveryTrackingHistoryService {
  private readonly endpoint: string;

  constructor(private http: HttpService, protected constantService: ConstantService) {
    this.endpoint = `${this.constantService.getV3Endpoint()}condos/`;
  }

  public getByDeliveryId(condoId: string, deliveryId: string, query: EcondosQuery = {}): Observable<DeliveryTrackingHistory[]> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = { params, observe: 'response' as 'body' };

    return this.http
      .get(`${this.endpoint}${condoId}/delivery-tracking/${deliveryId}/history`, options)
      .pipe(map((res: any) => (res.body.length ? res.body.map((data: any) => BuildDeliveryTrackingHistory(data)) : [])));
  }

  public create(condoId: string, deliveryId: string, data: CreateDeliveryTrackingHistoryDto): Observable<string> {
    return this.http.post(`${this.endpoint}${condoId}/delivery-tracking/${deliveryId}/history`, data).pipe(map((res: any) => res._id));
  }
}
