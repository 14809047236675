import { HttpService } from 'app/services/http.service';
import { Injectable } from '@angular/core';
import { ConstantService } from 'app/services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as qs from 'qs';
import { BuildCamerasGroup, CamerasGroup, CamerasGroupOnlyWithId, GroupCameraType } from '@api/model/cameras-group';

export interface GetCamerasGroupsResponse {
  count: number;
  camerasGroups: CamerasGroup[];
}

export type CreateCamerasGroupDTO = Pick<CamerasGroup, 'name'> & {
  cameras: Array<{
    type: GroupCameraType;
    camera: string;
  }>;
};

@Injectable({ providedIn: 'root' })
export class CamerasGroupsService {
  protected endPoint: string;

  constructor(protected http: HttpService, protected constantService: ConstantService) {
    this.http = http;
    this.endPoint = `${this.constantService.getV2Endpoint()}condos/`;
  }

  getCamerasGroups(condoId: string, params: EcondosQuery = {}): Observable<GetCamerasGroupsResponse> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/cameras-group`, options).pipe(
      map((res: any) => ({
        count: parseInt(res.headers.get('count'), 10),
        camerasGroups: res.body.map(camerasGroup => BuildCamerasGroup(camerasGroup))
      }))
    );
  }

  createCamerasGroup(condoId: string, camerasGroup: CreateCamerasGroupDTO) {
    return this.http
      .post(`${this.endPoint}${condoId}/cameras-group`, camerasGroup)
      .pipe(map((response: CamerasGroupOnlyWithId) => response));
  }

  updateCamerasGroup(condoId: string, camerasGroupId: string, camerasGroup: CreateCamerasGroupDTO) {
    return this.http.put(`${this.endPoint}${condoId}/cameras-group/${camerasGroupId}`, camerasGroup);
  }

  deleteCamerasGroup(condoId: string, camerasGroupId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/cameras-group/${camerasGroupId}`);
  }
}
