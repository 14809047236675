import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { distinctUntilChanged, interval, map } from 'rxjs';

@Component({
  selector: 'app-timer-count',
  templateUrl: 'timer-count.component.html',
  styleUrls: ['timer-count.component.scss'],
  standalone: true,
  imports: [AsyncPipe]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TimerCountComponent implements OnInit {
  @Input() activeTimer: Date;

  timeFromNow;

  ngOnInit(): void {
    this.timeFromNow = interval(1000).pipe(
      map(() => {
        const duration = moment.duration(moment().diff(this.activeTimer));
        return `${duration.days()}D ${duration.hours()}H ${duration.minutes()}m ${duration.seconds()}s`;
      }),
      distinctUntilChanged()
    );
  }
}
