export enum DAYS_OF_WEEK {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY'
}

export enum DAYS_OF_WEEK_LABEL {
  SUNDAY = 'Domingo',
  MONDAY = 'Segunda-feira',
  TUESDAY = 'Terça-feira',
  WEDNESDAY = 'Quarta-feira',
  THURSDAY = 'Quinta-feira',
  FRIDAY = 'Sexta-feira',
  SATURDAY = 'Sábado'
}

export const DAYS_OF_WEEK_FROM_INDEX = {
  0: DAYS_OF_WEEK.SUNDAY,
  1: DAYS_OF_WEEK.MONDAY,
  2: DAYS_OF_WEEK.TUESDAY,
  3: DAYS_OF_WEEK.WEDNESDAY,
  4: DAYS_OF_WEEK.THURSDAY,
  5: DAYS_OF_WEEK.FRIDAY,
  6: DAYS_OF_WEEK.SATURDAY
};

export const DAYS_OF_WEEK_INDEX = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};

export interface DayAllowed {
  day: DAYS_OF_WEEK;
  startTime: string;
  endTime: string;
}

export interface Timezone {
  _id?: string;
  name: string;
  description?: string;
  daysAllowed: DayAllowed[];
  createdAt?: string;
  createdBy?: string;
  condo?: string;
  sequenceId?: number;
}
