import { Condo } from './condo';
import { User } from './user';

export interface DeliveryTrackingSequence {
  _id: string;
  condo: Condo;
  step: number;
  title: string;
  description?: string;
  shouldCloseDelivery: boolean;
  shouldNotifyUser: boolean;
  createdBy: User;
  createdAt: Date;
}

export function BuildDeliveryTrackingSequence(data: any): DeliveryTrackingSequence {
  const buildedDeliveryTrackingSequence: DeliveryTrackingSequence = {
    _id: typeof data === 'string' ? data : data._id,
    condo: data.condo ? new Condo(data.condo) : ({} as Condo),
    step: data.step,
    title: data.title,
    description: data.description,
    shouldCloseDelivery: data.shouldCloseDelivery || false,
    shouldNotifyUser: data.shouldNotifyUser || false,
    createdBy: data.createdBy ? new User(data.createdBy) : ({} as User),
    createdAt: new Date(data.createdAt)
  };

  return buildedDeliveryTrackingSequence;
}
