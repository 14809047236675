import { Injectable } from '@angular/core';
import { User } from '../api/model/user';
import { Condo } from '../api/model/condo';
import { SessionService } from '@api/service/session.service';

@Injectable()
export class UtilService {
  constructor(private sessionService: SessionService) {}

  capitalize(string) {
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  validEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  /**
   * @Deprecated Use sessionService.userValue instead
   */
  getLocalUser() {
    return this.sessionService.userValue;
  }

  /**
   * @Deprecated Use sessionService.setUser instead
   * @param user
   */
  saveLocalUser(user: User) {
    this.sessionService.setUser(user);
  }

  /**
   * @Deprecated Use sessionService.clearUser instead
   */
  removeLocalUser() {
    this.sessionService.clearUser();
  }

  /**
   * @Deprecated Use sessionService.condoValue instead
   */
  getLocalCondo(): Condo {
    return this.sessionService.condoValue;
  }

  /**
   * @Deprecated Use sessionService.setCondo instead
   * @param condo
   */
  saveLocalCondo(condo: Condo) {
    if (condo) {
      this.sessionService.setCondo(condo);
    }
  }

  /**
   * @Deprecated Use sessionService.clearCondo instead
   */
  removeLocalCondo() {
    this.sessionService.clearCondo();
  }

  saveLocalAuthorizationHeader(username, password) {
    username = username.toLowerCase().trim();
    localStorage.setItem('econdos.headerAuthorization', btoa(username + ':' + password));
    this.saveLocalUsername(username);
  }

  saveLocalToken(token) {
    localStorage.setItem('econdos.token', token);
  }

  getLocalToken() {
    return localStorage.getItem('econdos.token');
  }

  getLocalAuthorizationHeader() {
    return localStorage.getItem('econdos.headerAuthorization') || '';
  }

  removeLocalAuthorizationHeader() {
    localStorage.removeItem('econdos.headerAuthorization');
    this.removeLocalUsername();
  }

  removeLocalToken() {
    localStorage.removeItem('econdos.token');
  }

  saveLocalUsername(username) {
    localStorage.setItem('econdos.username', btoa(username));
  }

  getLocalUsername() {
    return localStorage.getItem('econdos.username') || '';
  }

  removeLocalUsername() {
    localStorage.removeItem('econdos.username');
  }

  removeAccents(s = '') {
    let r = s.toLowerCase();
    r = r.replace(new RegExp('\\s', 'g'), '');
    r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
    r = r.replace(new RegExp('æ', 'g'), 'ae');
    r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
    r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
    r = r.replace(new RegExp('ñ', 'g'), 'n');
    r = r.replace(new RegExp('[òóôõö]', 'g'), 'o');
    r = r.replace(new RegExp('œ', 'g'), 'oe');
    r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
    r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
    // r = r.replace(new RegExp('ç', 'g'), 'c');
    // r = r.replace(new RegExp('\\W', 'g'), '');
    return r;
  }

  formatPhone(phone) {
    if (phone) {
      phone = phone.toString();
      phone = phone.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito exceto o asterisco
      if (phone.substr(0, 4) === '0800') {
        phone = phone.replace(/^(\d{4})(\d{3})(\d{4})/g, '$1 $2 $3');
      } else {
        phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2'); // Coloca parênteses em volta dos dois primeiros dígitos
        phone = phone.replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
      }
    }
    return phone;
  }

  formatCnpj(cnpj) {
    if (cnpj) {
      cnpj = cnpj.toString();
      cnpj = cnpj.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito exceto o asterisco
      cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '$1.$2.$3/$4-');
    }
    return cnpj;
  }

  formatCep(cep) {
    if (cep) {
      cep = cep.toString();
      cep = cep.replace(/\D/g, ''); // Remove tudo o que não é dígito
      cep = cep.replace(/(\d)(\d{3})$/, '$1-$2'); // Coloca hífen separando os 2 grupos de dígitos
    }
    return cep;
  }

  formatCpf(cpf) {
    if (cpf) {
      cpf = cpf.toString();
      cpf = cpf.replace(/\D/g, ''); // Remove tudo o que não é dígito
      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    }
    return cpf;
  }

  maskInit(string, max = 4) {
    if (string) {
      const count = Math.max(Math.ceil(string.length / 2), string.length - max);
      const regex = new RegExp(`^.{${count}}`, 'g');
      const value = ''.padStart(count, '*');

      return String(string).replace(regex, value);
    }
  }

  maskMiddle(string) {
    return string
      .split('')
      .map((c, i) => (i >= string.length / 4 && i < string.length - string.length / 4 ? '*' : c))
      .join('');
  }

  validateCpf(cpf) {
    cpf = cpf.toString();
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
  }

  formatRg(v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');
    return v;
  }

  formatPhoneToShow(pn) {
    if (pn) {
      pn = pn.toString();
      pn = pn.slice(0, pn.length - 4) + '-' + pn.slice(pn.length - 4);
      pn = pn.slice(0, 2) + ' ' + pn.slice(2);
      pn = pn.slice(0, 2) + ')' + pn.slice(2);
      pn = pn.slice(0, 0) + '(' + pn.slice(0);
    }
    return pn;
  }

  formatCnpjToShow(cnpj) {
    if (cnpj) {
      cnpj = cnpj.toString();
      cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '$1.$2.$3/$4-');
    }
    return cnpj;
  }

  formatCurrencyToShow(value: string) {
    if (value) {
      value = value.toString().replace('.', ',');

      if (value.includes(',')) {
        if (value.length > 6) value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
      } else {
        value = value + ',00';

        if (value.length > 6) value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
      }

      return `R$ ${value}`;
    }

    return 'R$ 0,00';
  }

  formatCurrency(value = '') {
    try {
      let tmp = value + '';
      const isNegativeValue = tmp.indexOf('-') != -1;
      tmp = tmp.replace(/\D/g, '');
      tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
      if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
      if (isNegativeValue && tmp) tmp = '-' + tmp;
      return tmp ? 'R$ ' + tmp : isNegativeValue ? 'R$ -' : '';
    } catch (e) {
      console.log(e);
      return 'R$ 0,00';
    }
  }

  validateBrazilianDateFormat(date: string) {
    // Expressão regular gerada pelo chat gpt para validar data no formato DD/MM/YYYY
    const regex = /^((0?[1-9]|[12][0-9]|3[01])\/(0?[13578]|1[02])|(0?[1-9]|[12][0-9]|30)\/(0?[469]|11)|(0?[1-9]|1\d|2[0-8])\/0?2)\/\d{4}$/;
    return regex.test(date);
  }

  getUfList() {
    return [
      'AC',
      'AL',
      'AM',
      'AP',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MG',
      'MS',
      'MT',
      'PA',
      'PB',
      'PE',
      'PI',
      'PR',
      'RJ',
      'RN',
      'RS',
      'RO',
      'RR',
      'SC',
      'SE',
      'SP',
      'TO'
    ];
  }

  saveLocalCamera(cam, name = '') {
    localStorage.setItem('econdos.camera', cam);
  }

  getLocalCamera() {
    return localStorage.getItem('econdos.camera');
  }

  saveLocalDefaultIncontrolGroup(group) {
    localStorage.setItem('econdos.incontrolGroup', group);
  }

  getLocalDefaultIncontrolGroup() {
    return localStorage.getItem('econdos.incontrolGroup');
  }

  saveLocalUseImageCropper(value) {
    localStorage.setItem('econdos.useImageCropper', value);
  }

  getLocalUseImageCropper() {
    return JSON.parse(localStorage.getItem('econdos.useImageCropper'));
  }
}
