import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ResidenceService } from '@api/service/residence.service';
import { Subject } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import swal from 'sweetalert2';
import { debounceTime, takeUntil, timeout } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { ModalCreateVehicleComponent } from '../modal-create-vehicle/modal-create-vehicle.component';
import { VehicleService } from '@api/service/vehicle.service';
import { EcondosQuery } from '@api/model/query';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';
import { FormControl } from '@angular/forms';
import { removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: 'vehicle-list.html',
  styleUrls: ['./vehicle-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() residence: Residence;
  @Input() vehicles: CondoVehicle[] = [];
  @Input() condo: Condo;
  @Input() user: User;
  @Input() showAddVehicleButton = true;
  @Input() customQuery: EcondosQuery = null;
  @Input() listTitle = 'Veículos';
  @Input() emptyFeedbackTitle = 'Nenhum veículo encontrado';
  @Input() emptyFeedbackMessage = '';
  @Input() useNewPermissions = false;

  @Output()
  loaded: EventEmitter<CondoVehicle[]> = new EventEmitter();

  @ViewChild('vehicleTemplate', { static: true }) vehicleTemplate: TemplateRef<CondoVehicle>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<CondoVehicle>;
  @ViewChild('vehiclesTable', { static: true }) vehiclesTable: TableComponent;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<CondoVehicle>[] = [];
  public unsubscribe$ = new Subject();
  public searchToken = new FormControl('');

  hasDeleteAccess = false;
  hasUpdateAccess = false;
  hasCreateAccess = false;

  VEHICLE_TYPES = CondoVehicle.TYPES;

  page = 0;
  countData: number = 0;
  BRANDS = {
    ...CondoVehicle.VEHICLE_BRANDS,
    ...CondoVehicle.MOTORCYCLE_BRANDS,
    ...CondoVehicle.BICYCLE_BRANDS
  };

  constructor(
    private residenceService: ResidenceService,
    private toastr: ToastrService,
    private vehicleService: VehicleService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef
  ) {
    this.searchToken.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(token => {
      this.getData({ token });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo || changes.residence || changes.user) {
      const user: User = changes.user.currentValue;
      const condo: Condo = changes.condo.currentValue;
      const residence = changes.residence.currentValue;

      this.hasCreateAccess =
        user?.isAdminOnCondo(condo?._id) ||
        user?.isOwnerOnCondo(condo?._id) ||
        residence?.isOwner(user?._id) ||
        residence?.isUser(user?._id);
      this.hasDeleteAccess = this.hasCreateAccess;
      this.hasUpdateAccess = user?.isAdminOnCondo(condo?._id) || user?.isOwnerOnCondo(condo?._id);
      if (this.useNewPermissions) {
        this.populatePermissions(condo);
      }

      if (!this.vehicles.length || changes.condo || changes.residence) {
        this.getData();
      }
    }
  }
  ngOnInit(): void {
    this.tableColumns = [
      {
        columnId: 'note',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.vehicleTemplate,
        sortable: false
      }
    ];
  }

  populatePermissions(condo: Condo) {
    this.hasDeleteAccess = this.user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.userResidences.vehicles.remove });
    this.hasUpdateAccess = this.user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.userResidences.vehicles.update });
    this.hasCreateAccess = this.user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.userResidences.vehicles.create });
  }

  getData({ page = 0, token = this.searchToken.value } = {}) {
    this.status = 'LOADING';
    this.cdr.detectChanges();
    const { pageSize } = this.vehiclesTable.getCurrentState();
    let vehiclesQuery: EcondosQuery = {
      $select: 'plate chassis model type brand color pictures residence user',
      $populate: [
        { path: 'pictures', select: 'url thumbnail type name format' },
        { path: 'user', select: 'firstName lastName' }
      ],
      $page: page,
      $limit: pageSize
    };

    if (token) {
      const terms = token
        .split(' ')
        .map(word => removeAccents(word))
        .map(word => replaceVowelsToAccentedVowels(word));

      const nameAnd = [];
      terms.forEach(term => {
        nameAnd.push({
          $or: [{ plate: { $regex: term, $options: 'i' } }]
        });
      });
      vehiclesQuery.$and = nameAnd;
    }

    if (this.customQuery) {
      vehiclesQuery = { ...vehiclesQuery, ...this.customQuery };
    }

    const observable = this.residence?._id
      ? this.residenceService.getVehicles(this.condo._id, this.residence._id, vehiclesQuery)
      : this.vehicleService.getVehicles(this.condo._id, vehiclesQuery);

    observable.subscribe({
      next: res => {
        this.status = 'SUCCESS';
        this.vehicles = res.vehicles;
        this.loaded.emit(this.vehicles);
        this.countData = res.count;
        this.cdr.detectChanges();
      },
      error: () => {
        this.status = 'ERROR';
        this.cdr.detectChanges();
      }
    });
  }

  create() {
    const initialState = {
      condo: this.condo,
      vehicleResidence: this.residence,
      onCreate: vehicle => {
        this.vehicles = [].concat(vehicle, this.vehicles);
        this.countData = +this.countData + 1;
        this.cdr.detectChanges();
        this.loaded.emit(this.vehicles);
      }
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState, ignoreBackdropClick: true });
  }

  edit(vehicle: CondoVehicle) {
    const initialState = {
      condo: this.condo,
      vehicleResidence: this.residence,
      vehicle,
      onUpdate: updatedVehicle => {
        const index = this.vehicles.findIndex(p => p._id === vehicle._id);
        this.vehicles[index] = updatedVehicle;
        this.vehicles = [].concat(this.vehicles);
        this.cdr.detectChanges();
        this.loaded.emit(this.vehicles);
      }
    };
    this.modalService.show(ModalCreateVehicleComponent, { initialState, ignoreBackdropClick: true });
  }

  delete(vehicle: CondoVehicle) {
    let deleteIdentification = vehicle.plate ? `placa ${vehicle.plate}` : `chassi ${vehicle.chassis}`;

    swal({
      type: 'question',
      text: `Deseja remover o veículo com ${deleteIdentification}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.residenceService
          .removeVehicle(this.condo._id, this.residence._id, vehicle._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            const msg = err?.originalError?.message || 'Não foi possível excluir o veículo, tente novamente...';
            return Promise.reject(msg);
          });
      }
    }).then(
      () => {
        const index = this.vehicles.findIndex(p => p._id === vehicle._id);
        this.vehicles.splice(index, 1);
        this.vehicles = [].concat(this.vehicles);
        this.countData = this.countData - 1;
        this.cdr.detectChanges();
        this.toastr.success('Veículo removido com sucesso');
        this.loaded.emit(this.vehicles);
      },
      error => {
        console.log(error);
      }
    );
  }
}
