import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { IntercomUserData, CallEvent, CallEventType, CallService, RegisterCallHistory } from '../services/call.service';
import { UtilService } from 'app/services/util.service';
import { noop } from 'lodash';
import { filter, take, tap } from 'rxjs/operators';
import { User } from '@api/model/user';
import { Call, CallStatus } from '@api/model/call';

@Component({
  selector: 'app-intercom-panel',
  templateUrl: 'intercom-panel.component.html',
  styleUrls: ['intercom-panel.component.scss']
})
export class IntercomPanelComponent implements OnDestroy {
  private peerId: string;
  private condo: Condo;
  private user: User;

  private RING_TONE_FILE_SRC = 'assets/sounds/classic_phone_ringtone_2.mp3';
  private RING_BACK_FILE_SRC = 'assets/sounds/ringback-tone-1.m4a';

  public isHidden = true;
  public isMinimized = false;

  public callDuration = 0;
  public call: Call | null;
  public caller: IntercomUserData = null;
  public callee: IntercomUserData = null;
  public callStatus: CallEventType | 'CONNECTING' = null;

  public ringTone: HTMLAudioElement;
  public ringBack: HTMLAudioElement;
  public isMuted = false;

  @ViewChild('localVideo') localVideo: ElementRef;
  @ViewChild('remoteVideo') remoteVideo: ElementRef;

  constructor(private callService: CallService, private utilService: UtilService) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.user.defaultCondo;

    this.initializeCallEventHandlers();
  }

  public ngOnDestroy(): void {
    this.remoteVideo.nativeElement.ontimeupdate = null;
    this.stopAndDestroyRingToneAudio('ringTone');
    this.stopAndDestroyRingToneAudio('ringBack');
    this.callService.destroyPeer();
  }

  private initializeCallEventHandlers(): void {
    this.callService.onCallEvent$.pipe(filter(res => !!res)).subscribe(async (event: CallEvent) => {
      switch (event.type) {
        case 'CREATED': {
          this.callee = event.data.callee;
          this.caller = this.user;
          this.callStatus = 'CREATED';
          this.initializeCallModule();
          this.togglePanel();
          this.stopAndDestroyRingToneAudio('ringTone');
          this.stopAndDestroyRingToneAudio('ringBack');
          await this.notifyCall(event.data.callee._id);
          setTimeout(() => {
            this.playRingToneAudio('ringBack', this.RING_BACK_FILE_SRC);
          }, 1500);
          break;
        }

        case 'RECEIVED': {
          this.call = event.data.call;
          this.caller = event.data.caller;
          this.callee = this.user;
          this.callStatus = 'RECEIVED';
          this.togglePanel();
          this.playRingToneAudio('ringTone', this.RING_TONE_FILE_SRC);
          break;
        }

        case 'ANSWERED': {
          this.callStatus = 'ANSWERED';
          this.stopAndDestroyRingToneAudio('ringTone');
          break;
        }

        case 'ESTABLISHED': {
          this.callStatus = 'ESTABLISHED';
          this.stopAndDestroyRingToneAudio('ringBack');
          break;
        }

        case 'HANGUP': {
          this.callStatus = 'HANGUP';
          this.stopAndDestroyRingToneAudio('ringTone');
          this.stopAndDestroyRingToneAudio('ringBack');

          setTimeout(() => {
            this.destroyPeerAndClosePanel();
          }, 2000);

          this.isMuted = false;

          break;
        }
      }
    });
  }

  private initializeCallModule(): void {
    this.peerId = this.callService.initPeer();

    // This must only be executed when the user is creating a call
    if (this.callStatus === 'CREATED') {
      this.callService.initializeCallEstablishedHandler();
    }

    console.log(
      '===========================================\n',
      `This peer id = ${this.peerId}`,
      '\n==========================================='
    );

    this.initializeCallDurationTimer();

    this.initializeLocalAndRemoteMediaStreams();
  }

  private initializeCallDurationTimer(): void {
    this.remoteVideo.nativeElement.ontimeupdate = () => {
      const duration = Math.floor(this.remoteVideo.nativeElement.currentTime);
      const durationInMilliseconds = duration * 1000;
      this.callDuration = durationInMilliseconds;
    };
  }

  private initializeLocalAndRemoteMediaStreams(): void {
    this.callService.localStream$.pipe(filter(res => !!res)).subscribe(stream => (this.localVideo.nativeElement.srcObject = stream));

    this.callService.remoteStream$.pipe(filter(res => !!res)).subscribe(stream => (this.remoteVideo.nativeElement.srcObject = stream));
  }

  public notifyCall(remotePeerId: string): Promise<{ callId: string }> {
    return this.callService
      .notifyIncomingCall(this.condo._id, remotePeerId)
      .pipe(tap(({ callId }) => (this.call = { _id: callId } as Call)))
      .toPromise();
  }

  public answer(remotePeerId: string) {
    this.callService.notifyCallAttendedByGateKeeper(this.condo._id).subscribe(noop);

    this.callService.peerOpen$.pipe(take(1)).subscribe(isOpen => {
      this.callService.answer(`ECONDOS_${remotePeerId}`);
    });

    this.initializeCallModule();

    this.callStatus = 'CONNECTING';
  }

  public getCallData(): RegisterCallHistory {
    let status: CallStatus;
    switch (this.callStatus) {
      case 'CREATED':
        status = 'MISSED';
        break;

      case 'RECEIVED':
        status = 'DECLINED';
        break;

      default:
        status = 'ANSWERED';
        break;
    }

    const data: RegisterCallHistory = {
      condo: this.condo?._id,
      calleeType: 'USER',
      callee: this.callee?._id,
      caller: this.caller?._id,
      duration: this.callDuration / 1000 || 0,
      status
    };

    return data;
  }

  public hangup(): void {
    const callerOrCallee = this.user._id === this.caller._id ? this.callee._id : this.caller._id;

    const callData = this.getCallData();
    const call = { ...this.call, ...callData };

    this.callService.notifyCallHangup(this.condo._id, callerOrCallee, call).subscribe(noop);

    this.callService.closeMediaCall();
  }

  public destroyPeerAndClosePanel(): void {
    this.caller = null;
    this.callee = null;
    this.callDuration = 0;
    this.callService.destroyPeer();
    this.togglePanel();
  }

  private togglePanel(): void {
    if (this.callStatus !== 'HANGUP') {
      this.isHidden = false;
    } else {
      this.isHidden = true;
    }
  }

  private async playRingToneAudio(audioElement: 'ringTone' | 'ringBack', audioFileSrc: string): Promise<void> {
    try {
      this[audioElement] = new Audio();
      this[audioElement].src = audioFileSrc;
      this[audioElement].loop = true;
      this[audioElement].volume = audioElement === 'ringBack' ? 0.2 : 1;
      this[audioElement].load();
      await this[audioElement].play();
    } catch (error) {
      console.log(error);
    }
  }

  private stopAndDestroyRingToneAudio(audioElement: string): void {
    if (this[audioElement]) {
      this[audioElement].pause();
      this[audioElement].currentTime = 0;
      this[audioElement] = null;
    }
  }

  public minimizeOrMaximizePanel(): void {
    this.isMinimized = !this.isMinimized;
  }

  public toggleMuted(): void {
    const [audioTrack] = this.callService.userStream.getAudioTracks();
    audioTrack.enabled = !audioTrack.enabled;
    this.isMuted = !this.isMuted;
  }
}
