import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'iconify',
  template: `
    <iconify-icon
      [icon]="icon"
      [inline]="inline"
      [attr.flip]="flip"
      [attr.rotate]="rotate"
      [attr.width]="size"
      [attr.height]="size"
      preserveAspectRatio="xMidYMid meet"></iconify-icon>
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IconifyComponent {
  @Input() icon: string;
  @Input() inline = true;
  @Input() flip: 'vertical' | 'horizontal' | 'horizontal,vertical';
  @Input() rotate: '90deg' | '180deg' | '270deg' | 'none';
  @Input() size: `${number}rem`;
}
