import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FileService } from '@api/service/file.service';
import { File } from '@api/model/file';
import { UploadButton } from '../upload-button';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { takeUntil } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilService } from '../../services/util.service';
import { ModalPreviewWebcamComponent } from './modal-preview-webcam/modal-preview-webcam.component';
import { ModalPreviewCCTVComponent } from './modal-preview-cctv/modal-preview-cctv.component';
import { cctvIcon, webcamIcon } from 'assets/svg/custom-icons';
import { ModalImageCropperComponent } from '../modal-image-cropper/modal-image-cropper.component';

@Component({
  selector: 'app-image-picker',
  templateUrl: 'image-picker.html',
  styleUrls: ['image-picker.scss']
})
export class ImagePickerComponent implements OnDestroy, OnInit {
  @ViewChild(UploadButton, { static: true }) uploadBtn: UploadButton;

  @Input()
  defaultImage = 'assets/img/empty-user-picture.png';

  @Input()
  defaultCCTVCamType = '';

  condo: Condo;

  @Output()
  imageChange: EventEmitter<File> = new EventEmitter();

  @Input()
  image: File;

  @Input()
  fromFile = true;

  @Input()
  fromWebCam = true;

  @Input()
  fromCCTV = false;

  @Input()
  canDelete = true;

  @Input()
  disabled = false;

  @Input()
  query = null;

  @Input()
  faceCrop = false;

  @Input()
  faceIsRequired = false;

  @Input()
  imageCropper = false;

  uploading = false;

  private unsubscribe: Subject<void> = new Subject();

  uploadCallback = ([img]) => this.uploadImage(img);

  public cctvIcon = cctvIcon;
  public webcamIcon = webcamIcon;

  constructor(private fileService: FileService, private modalService: BsModalService, private utilService: UtilService) {
    this.condo = this.utilService.getLocalCondo();
  }

  ngOnInit() {
    this.faceIsRequired = this.faceIsRequired && (!this.condo || this.condo?.generalParams?.faceIsRequiredOnFileUpload);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  pickImageFromFile() {
    this.uploadBtn.triggerClick();
  }

  uploadImage(image) {
    if (image) {
      this.faceCrop ? { faceCrop: this.faceCrop } : null
      const formData = new FormData();
      formData.append(image.title || 'image', image);
      this.uploading = true;
      this.fileService
        .uploadFilesFromFormData(formData, {
          faceCrop: this.faceCrop,
          faceIsRequired: this.faceIsRequired
        })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          ([img]) => {
            this.uploading = false;
            this.image = img;
            this.imageChange.emit(img);
          },
          err => {
            this.uploading = false;
            swal({
              type: 'error',
              title: `Ops...`,
              text: (this.faceIsRequired && err?.originalError && err.originalError.error) ? err.originalError.error : 'Não foi possível efetuar o upload da imagem, tente novamente',
              cancelButtonColor: '#f53d3d'
            });
          }
        );
    }
  }

  uploadBase64Url(data) {
    this.uploading = true;
    this.fileService.uploadBase64(data, {
      faceCrop: this.faceCrop,
      faceIsRequired: this.faceIsRequired
    }).subscribe(
      ([img]) => {
        this.image = img;
        this.imageChange.emit(img);
      },
      err => {
        console.log(err);
        if(this.faceIsRequired && err?.originalError && err.originalError.error) {
          swal({
            type: 'error',
            title: `Ops...`,
            text: (this.faceIsRequired && err?.originalError && err.originalError.error) ? err.originalError.error : 'Não foi possível efetuar o upload da imagem, tente novamente',
            cancelButtonColor: '#f53d3d'
          });
          this.uploading = false;
        }
      },
      () => {
        this.uploading = false;
      }
    );
  }

  openWebCam() {
    const initialState = {
      useImageCropper: this.imageCropper,
      callback: (base64, faceCrop = false) => {
        if (base64) {
          this.faceCrop = (this.faceIsRequired && !this.imageCropper)? this.faceIsRequired : faceCrop;
          this.uploadBase64Url(base64);
        }
      }
    };
    this.modalService.show(ModalPreviewWebcamComponent, { initialState, ignoreBackdropClick: true });
  }

  openCCTVCam() {
    const initialState = {
      defaultImage: this.defaultImage,
      useImageCropper: this.imageCropper,
      defaultType: this.defaultCCTVCamType,
      callback: (base64, faceCrop = false) => {
        if (base64) {
          this.faceCrop = (this.faceIsRequired && !this.imageCropper)? this.faceIsRequired : faceCrop;
          this.uploadBase64Url(base64);
        }
      }
    };
    this.modalService.show(ModalPreviewCCTVComponent, { initialState, ignoreBackdropClick: true });
  }

  handleCropSnapshotImage(): void {
    const initialState = {
      base64ImageToCrop: this.image.url,
      onCropImage: (croppedBase64Image: string) => {
        if (croppedBase64Image) {
          this.uploadBase64Url(croppedBase64Image);
        }
      }
    };

    this.modalService.show(ModalImageCropperComponent, { initialState, ignoreBackdropClick: true });
  }
}
