import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Condo } from '@api/model/condo';
import { DeliveryTrackingSequence } from '@api/model/delivery-tracking-sequence';
import { CreateDeliveryTrackingSequenceDto, DeliveryTrackingSequenceService } from '@api/serviceV3/delivery-tracking-sequence.service';
import { EcondosQuery } from '@api/model/query';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@api/model/user';

type RequestStatus = 'LOADING' | 'SUCCESS' | 'ERROR';

interface FormValue {
  title: string;
  description: string;
  shouldCloseDelivery: boolean;
  shouldNotifyUser: boolean;
}

@Component({
  selector: 'app-modal-config-delivery-tracking',
  templateUrl: './modal-config-delivery-tracking.component.html',
  styleUrls: ['./modal-config-delivery-tracking.component.scss']
})
export class ModalConfigDeliveryTrackingComponent implements OnInit {
  public condo: Condo;
  public user: User;

  public deliveryTrackingSequence: DeliveryTrackingSequence[] = [];
  public loadDeliveryTrackingSequenceStatus: RequestStatus;
  public createDeliveryTrackingSequenceStatus: RequestStatus;
  public updateDeliveryTrackingSequenceStatus: RequestStatus;
  public deleteDeliveryTrackingSequenceStatus: RequestStatus;
  public moveDeliveryTrackingSequenceStatus: RequestStatus;

  public sequenceToEdit: DeliveryTrackingSequence;

  public form: UntypedFormGroup;

  constructor(
    private modalRef: BsModalRef,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private deliveryTrackingSequenceService: DeliveryTrackingSequenceService
  ) {}

  public ngOnInit(): void {
    this.getCondoDeliveryTrackingSequence();
    this.initForm();
  }

  private getQuery(): EcondosQuery {
    const query: EcondosQuery = {
      $select: ['condo', 'step', 'title', 'description', 'shouldCloseDelivery', 'shouldNotifyUser', 'createdBy', 'createdAt'].join(' '),
      $populate: [
        { path: 'condo', select: 'name' },
        { path: 'createdBt', select: 'firstName lastName' }
      ],
      $sort: 'step'
    };

    return query;
  }

  public async getCondoDeliveryTrackingSequence(): Promise<void> {
    this.loadDeliveryTrackingSequenceStatus = 'LOADING';

    const query = this.getQuery();

    try {
      const trackingSequence = await this.deliveryTrackingSequenceService.get(this.condo._id, query).toPromise();

      this.deliveryTrackingSequence = trackingSequence;

      this.loadDeliveryTrackingSequenceStatus = 'SUCCESS';
    } catch (error) {
      console.error(error);

      this.toastrService.error(
        'Ocorreu um erro ao tentar recuperar as etapas de rastreamento de encomendas do condomínio',
        'Erro inesperado'
      );

      this.loadDeliveryTrackingSequenceStatus = 'ERROR';
    }
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      shouldCloseDelivery: [false, Validators.required],
      shouldNotifyUser: [false, Validators.required]
    });
  }

  public async create(values: FormValue): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    this.createDeliveryTrackingSequenceStatus = 'LOADING';

    try {
      const data: CreateDeliveryTrackingSequenceDto = {
        ...values,
        condo: this.condo._id,
        step: this.deliveryTrackingSequence.length + 1,
        createdBy: this.user._id
      };

      const id = await this.deliveryTrackingSequenceService.create(this.condo._id, data).toPromise();

      const newSequence: DeliveryTrackingSequence = {
        ...data,
        condo: this.condo,
        createdBy: this.user,
        createdAt: new Date(),
        _id: id
      };

      this.deliveryTrackingSequence.push(newSequence);

      this.createDeliveryTrackingSequenceStatus = 'SUCCESS';

      this.resetForm();
    } catch (error) {
      console.error(error);

      this.toastrService.error('Ocorreu um erro ao cadastrar a etapa de rastreamento de encomendas do condomínio', 'Erro inesperado');

      this.createDeliveryTrackingSequenceStatus = 'ERROR';
    }
  }

  public async update(values: FormValue): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    this.updateDeliveryTrackingSequenceStatus = 'LOADING';

    try {
      await this.deliveryTrackingSequenceService.update(this.condo._id, this.sequenceToEdit._id, values).toPromise();

      const updatedSequence: DeliveryTrackingSequence = { ...this.sequenceToEdit, ...values };

      const sequenceIndex = this.deliveryTrackingSequence.findIndex(sequence => sequence._id === this.sequenceToEdit._id);

      this.deliveryTrackingSequence[sequenceIndex] = updatedSequence;

      this.resetForm();

      this.updateDeliveryTrackingSequenceStatus = 'SUCCESS';

      this.resetForm();
    } catch (error) {
      console.error(error);

      this.toastrService.error('Ocorreu um erro ao atualizar a etapa de rastreamento', 'Erro inesperado');

      this.updateDeliveryTrackingSequenceStatus = 'ERROR';
    }
  }

  public async delete(sequenceToDelete: DeliveryTrackingSequence): Promise<void> {
    if (this.deleteDeliveryTrackingSequenceStatus === 'LOADING') {
      this.toastrService.warning(`Por favor, aguarde. A etapa ${sequenceToDelete.step} está sendo excluída...`);
      return;
    }

    this.deleteDeliveryTrackingSequenceStatus = 'LOADING';

    try {
      await this.deliveryTrackingSequenceService.delete(this.condo._id, sequenceToDelete._id).toPromise();

      const sequenceIndex = this.deliveryTrackingSequence.findIndex(sequence => sequence._id === sequenceToDelete._id);

      this.deliveryTrackingSequence.splice(sequenceIndex, 1);

      this.deliveryTrackingSequence = this.deliveryTrackingSequence.map((sequence, index) => ({ ...sequence, step: index + 1 }));

      this.deleteDeliveryTrackingSequenceStatus = 'SUCCESS';
    } catch (error) {
      console.error(error);

      this.toastrService.error('Ocorreu um erro ao excluir a etapa de rastreamento', 'Erro inesperado');

      this.deleteDeliveryTrackingSequenceStatus = 'ERROR';
    }
  }

  public async moveUp(sequenceToMove: DeliveryTrackingSequence): Promise<void> {
    this.moveDeliveryTrackingSequenceStatus = 'LOADING';

    try {
      await this.deliveryTrackingSequenceService.moveUp(this.condo._id, sequenceToMove._id).toPromise();

      const sequenceIndex = this.deliveryTrackingSequence.findIndex(sequence => sequence._id === sequenceToMove._id);
      const tempSequence = this.deliveryTrackingSequence[sequenceIndex - 1];

      tempSequence.step += 1;
      sequenceToMove.step -= 1;

      this.deliveryTrackingSequence[sequenceIndex] = tempSequence;
      this.deliveryTrackingSequence[sequenceIndex - 1] = sequenceToMove;

      this.moveDeliveryTrackingSequenceStatus = 'SUCCESS';
    } catch (error) {
      console.error(error);

      this.toastrService.error('Ocorreu um erro ao mover a etapa de rastreamento para cima', 'Erro inesperado');

      this.moveDeliveryTrackingSequenceStatus = 'ERROR';
    }
  }

  public async moveDown(sequenceToMove: DeliveryTrackingSequence): Promise<void> {
    this.moveDeliveryTrackingSequenceStatus = 'LOADING';

    try {
      await this.deliveryTrackingSequenceService.moveDown(this.condo._id, sequenceToMove._id).toPromise();

      const sequenceIndex = this.deliveryTrackingSequence.findIndex(sequence => sequence._id === sequenceToMove._id);
      const tempSequence = this.deliveryTrackingSequence[sequenceIndex + 1];

      tempSequence.step -= 1;
      sequenceToMove.step += 1;

      this.deliveryTrackingSequence[sequenceIndex] = tempSequence;
      this.deliveryTrackingSequence[sequenceIndex + 1] = sequenceToMove;

      this.moveDeliveryTrackingSequenceStatus = 'SUCCESS';
    } catch (error) {
      console.error(error);

      this.toastrService.error('Ocorreu um erro ao mover a etapa de rastreamento para cima', 'Erro inesperado');

      this.moveDeliveryTrackingSequenceStatus = 'ERROR';
    }
  }

  public selectSequenceToEdit(sequence: DeliveryTrackingSequence): void {
    this.sequenceToEdit = sequence;

    this.title.setValue(sequence.title);
    this.description.setValue(sequence.description);
    this.shouldCloseDelivery.setValue(sequence.shouldCloseDelivery);
    this.shouldNotifyUser.setValue(sequence.shouldNotifyUser);
  }

  public closeModal(): void {
    this.modalRef.hide();
  }

  private resetForm(): void {
    this.form.reset({ title: '', description: '', shouldCloseDelivery: false, shouldNotifyUser: false });
    this.form.markAsUntouched();

    this.sequenceToEdit = undefined;
  }

  public get title(): AbstractControl {
    return this.form.get('title');
  }

  public get description(): AbstractControl {
    return this.form.get('description');
  }

  public get shouldCloseDelivery(): AbstractControl {
    return this.form.get('shouldCloseDelivery');
  }

  public get shouldNotifyUser(): AbstractControl {
    return this.form.get('shouldNotifyUser');
  }
}
