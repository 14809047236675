import { Injectable } from '@angular/core';
import { iif, Observable, of, throwError } from 'rxjs';
import { Address } from '../api/model/address';
import { ErrorBuilder } from '../api/model/error/error.builder';
import { Error } from '../api/model/error/error';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, mergeMap, timeout } from 'rxjs/operators';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient) {}

  getWithCustomHeader(url, headers: HttpHeaders) {
    return this.http
      .get(encodeURI(url), {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }

  createAuthorizationHeader(headers: HttpHeaders) {
    const token = localStorage.getItem('econdos.token');
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    } else {
      const authorizationString = localStorage.getItem('econdos.headerAuthorization');
      headers = headers.append('Authorization', 'Basic ' + authorizationString);
    }
    return headers;
  }

  consultarCEP(cep) {
    const headers = new HttpHeaders();
    const options = { headers: headers };
    return this.http.get('https://viacep.com.br/ws/' + cep + '/json/', options).pipe(
      timeout(10000),
      mergeMap((res: any) => iif(() => res.erro, throwError({ message: 'zipCode invalid' }), of(new Address(res))))
    );
  }

  get(url, options: { headers?: HttpHeaders; params?: HttpParams } = {}) {
    options.headers = this.createAuthorizationHeader(options.headers || new HttpHeaders());
    return this.http.get(encodeURI(url), options).pipe(catchError(this.handleError));
  }

  getWithFullResponse(url, options: { headers?: HttpHeaders; params?: HttpParams } = {}) {
    options.headers = this.createAuthorizationHeader(options.headers || new HttpHeaders());
    const overOptions = {
      ...options,
      observe: 'response'
    };
    return this.http.get(encodeURI(url), overOptions as any).pipe(catchError(this.handleError));
  }

  post(url, data, headers?) {
    headers = this.createAuthorizationHeader(headers || new HttpHeaders());
    return this.http
      .post(url, data, {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }

  put(url, data, headers?) {
    headers = this.createAuthorizationHeader(headers || new HttpHeaders());
    return this.http
      .put(url, data, {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }

  delete(url, headers?: HttpHeaders) {
    headers = this.createAuthorizationHeader(headers || new HttpHeaders());
    return this.http
      .delete(url, {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }

  handleError(error): Observable<Error> {
    return throwError(ErrorBuilder.build(error));
  }
}
